import { useState } from "react";
import { useFetchUserNotificationsQuery, useReadNotificationMutation, queryTypes } from "../../../services/usernotifications";

const useNotifications = (queryType = queryTypes.CLIENT) => {

    const [page, setPage] = useState(1);
    const { data, isLoading, isError, error, isFetching, refetch } = useFetchUserNotificationsQuery({ page, queryType }, { refetchOnMountOrArgChange: true });
    const { pagination, results } = data || {};
    const { totalCount, totalPages } = pagination || {};
    const [triggerRead] = useReadNotificationMutation();


    const loadNextPage = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        if (page === totalPages) return;

        setPage(prev => prev + 1);
    };

    const read = ({ notificationId, isDone, noFurtherActionRequired }) => {
        return new Promise((resolve, reject) => {
            return triggerRead({ notificationId, isDone, noFurtherActionRequired, page, queryType })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        if (page > 1) {
            setPage(_ => 1);
        }
        else {
            refetch();
        }
    };

    return {
        results: results || [],
        isLoading: isLoading || isFetching,
        isFetching,
        isError,
        error,
        totalPages,
        totalCount,
        page,
        read,
        reload,
        loadNextPage
    };
};

export default useNotifications;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const assetProviderApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        fetchAssetProviders: build.query({
            query: ({ page = 1, limit = 50, fetchType = "list", sort = "ProviderName" }) => ({
                url: `api/assets/provider?page=${page}&limit=${limit}&fetchType=${fetchType}&sort=${sort}`
            })
        }),
        fetchAssetProvidersForGrid: build.query({
            query: ({ filter, next = null, limit = 25, sort = "ProviderName+ASC" }) => ({
                url: `api/assets/provider/grid?limit=${limit}${next ? `&next=${next}` : ""}`,
                method: 'POST',
                body: {
                    ...filter,
                    sort
                }
            }),
            serializeQueryArgs: ({ endpointName }) => endpointName,
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious, sort: currentSort } = currentCache || {};
                const { next, previous, sort } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous || sort !== currentSort;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage, sort: currSort } = currentArg || { next: 1 };
                const { next: prevPage, sort: prevSort } = previousArg || { next: 0 };
                return currPage !== prevPage || currSort !== prevSort;
            },
        }),
        createAssetProvider: build.mutation({
            query: ({ providerName, organisationId, fundManagerCode }) => ({
                url: 'api/assets/provider',
                method: 'POST',
                body: {
                    fundManagerCode,
                    organisationId,
                    providerName
                }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                try {
                    dispatch(wealthProApi.util.updateQueryData(
                        'fetchAssetProvidersForGrid',
                        {},
                        ({ results, totalCount, ...rest }) => ({
                            ...rest,
                            results: [data, ...results],
                            totalCount: totalCount + 1
                        })
                    ))
                }
                catch { }
            }
        }),
        deleteAssetProvider: build.mutation({
            query: ({ providerId }) => ({
                url: `api/assets/provider/${providerId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ providerId }, { dispatch, queryFulfilled }) {
                const patch = dispatch(wealthProApi.util.updateQueryData(
                    'fetchAssetProvidersForGrid',
                    {},
                    ({ results, totalCount, ...rest }) => ({
                        ...rest,
                        results: results.filter(x => x.providerId !== providerId),
                        totalCount: totalCount - 1
                    })
                ));

                try {
                    await queryFulfilled;
                }
                catch {
                    patch.undo();
                }
            }
        }),
        patchAssetProvider: build.mutation({
            query: ({ providerId, operations }) => ({
                url: `api/assets/provider/${providerId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ providerId, operations }, { dispatch, queryFulfilled }) {
                const patch = dispatch(wealthProApi.util.updateQueryData(
                    'fetchAssetProvidersForGrid',
                    {},
                    ({ results }) => {
                        let item = results.find(x => x.providerId === providerId);
                        applyPatch(item, operations, true);
                    }
                ));

                try {
                    await queryFulfilled;
                }
                catch {
                    patch.undo();
                }
            }
        })
    })
});

export const {
    useLazyFetchAssetProvidersQuery,
    useLazyFetchAssetProvidersForGridQuery,
    useCreateAssetProviderMutation,
    useDeleteAssetProviderMutation,
    usePatchAssetProviderMutation
} = assetProviderApiEndpoints;
import { wealthProApi } from "../../redux/api";
import { applyPatch } from "fast-json-patch";

const serviceTermsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchClientServiceLevelAgreement: builder.mutation({
            query: ({
                clientServiceAgreementId,
                masterAccountId,
                operations,
            }) => ({
                url: `api/review/client-sla/${clientServiceAgreementId}`,
                method: "PATCH",
                body: operations,
            }),
            async onQueryStarted(
                { clientServiceAgreementId, masterAccountId, operations },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data: clientServiceTerms } = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData(
                            "fetchClientServiceTerms",
                            { masterAccountId },
                            (data) => {
                                // applyPatch(data, operations, true);
                                return clientServiceTerms;
                            }
                        )
                    );
                } catch {
                    //result.undo();
                }
            },
        }),
        createClientServiceLevelAgreement: builder.mutation({
            query: ({ masterAccountId }) => ({
                url: `api/review/client-sla/${masterAccountId}`,
                method: "POST",
                body: {},
            }),
            invalidatesTags: ["clientServiceTerms"],
        }),
        renewTerms: builder.mutation({
            query: ({ clientServiceLevelAgreementId, masterAccountId }) => ({
                url: `api/review/client-sla/${clientServiceLevelAgreementId}`,
                method: "PUT",
                body: {},
            }),
            invalidatesTags: ["clientServiceTerms"],
        }),
    }),
});

export const {
    usePatchClientServiceLevelAgreementMutation,
    useCreateClientServiceLevelAgreementMutation,
    useRenewTermsMutation,
} = serviceTermsApiEndpoints;

import { AdvicePendingApprovalPage } from "../pages/administration";

const AdministrationAdviceRoutes = {
    path: 'advice/*',
    pathBase: '/administration/advice',
    defaultRoute: 'pending',
    roles: [],
    children: [
        {
            path: 'pending',
            element: AdvicePendingApprovalPage
        }
    ]
};

export default AdministrationAdviceRoutes;
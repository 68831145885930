import { wealthProApi } from '../../redux/api';

const defWrapperApiEndpoints = wealthProApi.enhanceEndpoints({})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchTransactionsDefWrappers: builder.query({
            query: (listType) => `api/valuations/defwrappers${listType ? `?listType=${listType}` : ""}`,
        })
    })
});

export const {
    useFetchTransactionsDefWrappersQuery,
} = defWrapperApiEndpoints;
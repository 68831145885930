import _ from "lodash";
import React from "react";
import { usePatchServiceLevelAgreementMutation } from "../../../services/clientserviceterms";

const EditSlaContext = React.createContext();

export const useEditSla = () => React.useContext(EditSlaContext);

export const EditSlaProvider = ({children, showModalOnSlaSet = true}) => {

    const [serviceLevelAgreement, _setSetServiceLevelAgreement] = React.useState(null);
    const [showModal, _setShowModal] = React.useState(false);
    const [patch, { isLoading, ...patchResults }] = usePatchServiceLevelAgreementMutation();

    const clear = () => {
        _setSetServiceLevelAgreement(_ => null);
        _setShowModal(_ => false);
    };

    const saveChanges = (operations) => {
        const { agreementId } = serviceLevelAgreement;
        return new Promise((resolve, reject) => {
            return patch({ agreementId: agreementId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const setServiceLevelAgreement = (serviceLevelAgreement) => {

        _setSetServiceLevelAgreement(_ => serviceLevelAgreement);
        if (showModalOnSlaSet === true) {
            _setShowModal(_ => true);
        }
    };

    return (
        <EditSlaContext.Provider value={{ 
            clear,
            isLoading,
            isModalActive: showModal,
            patch: { isLoading, ...patchResults },
            serviceLevelAgreement,
            saveChanges,
            setServiceLevelAgreement
        }}>
            {children}
        </EditSlaContext.Provider>
    );
}
import styled from "styled-components";
import { ThemedSpan } from "../../../components/utilities";
import { IconButton } from "../../../components";
import React, { useState } from "react";
import CreateMailModal from "../../createmailmodal";
import { useClientContext } from "../../../hooks/ClientContext";

const ConversationListHeader = styled.div`
    background-color: ${props => props.theme.primary};
    color: white;
`;

const ClientEmailConversationPreviewListHeader = () => {

    const { id } = useClientContext();
    const [showCreateModal, setShowCreateModal] = useState(false);

    return <React.Fragment>
        <CreateMailModal
            masterAccountId={id}
            show={showCreateModal}
            onHide={() => setShowCreateModal(false)}
        />
        <ConversationListHeader className="row h-100 border d-flex m-0">
            <div className="col d-flex align-items-center">
                <ThemedSpan variant="light" className="h-4 ps-0">Conversations</ThemedSpan>
            </div>
            <div className="col-auto d-flex align-items-center">
                <IconButton
                    icon="fa-plus"
                    variant="success"
                    onClick={() => setShowCreateModal(true)}
                />
            </div>
        </ConversationListHeader>
    </React.Fragment>;
};

export default ClientEmailConversationPreviewListHeader;
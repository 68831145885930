import React from "react";
import toast from "react-hot-toast";
import classNames from "classnames";
import FileDisplay from "./FileDisplay";
import { useDropzone } from "react-dropzone";
import { acceptedFileTypes } from "../helpers/uploads";
import { documentFunctions, documentFunctionSelectors } from "../helpers/constants";
import { getDocumentTypeFromMimeType } from "./forms/selects/DocumentTypesSelect";

export const defaultUploadProps = {
    accept: acceptedFileTypes
};

const FileUploader = ({ 
    onFilesChanged = () => {},
    showAdditionalProps = true,
    uploadProps = defaultUploadProps
}) => {
    const [filesToAdd, setFilesToAdd] = React.useState([]);
    const [fileDataToAdd, setFileDataToAdd] = React.useState([]);

    const onDrop = React.useCallback((acceptedFiles) => {
        setFilesToAdd(prev => [...prev, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ 
        ...uploadProps, 
        onDrop,
        validator: (file) => {
            if (filesToAdd.findIndex(el => el.name === file.name) >= 0) {
                let errorMsg = {
                    code: 'file-name-exists',
                    message: `You are already trying to upload a file with name: '${file.name}'`
                };

                toast.error(errorMsg.message);
                return errorMsg;
            }
            return null;
        }
    });

    const onFileDataChanged = (id, prop, value) => {
        const nextFileData = fileDataToAdd.map((el, i) => {
            if (el.id === id) {
                return {
                    ...el,
                    [prop]: value
                };
            }
            else {
                return el;
            }
        });
        setFileDataToAdd(_ => nextFileData);
        onFilesChanged({ files: filesToAdd, info: nextFileData });
    };

    const onRemoveAttachment = (file) => {
        setFileDataToAdd(prev => prev.filter(x => x.id !== file.name));
        setFilesToAdd(prev => prev.filter(x => x !== file));
    };

    React.useEffect(() => {
        if (!filesToAdd || filesToAdd.length === 0) {
            setFileDataToAdd(_ => []);
            onFilesChanged({ files: [], info: [] });
            return;
        }

        const info = [ ...filesToAdd.map((el) => {
            const rec = filesToAdd.find(i => i.id === el.name);
            if (rec) {
                return rec;
            }
            else {
                var docType = getDocumentTypeFromMimeType(el.type);
                return {
                    id: el.name,
                    fileName: el.name,
                    description: '',
                    type: docType?.value ?? 8,
                    typeIcon: docType?.icon ?? 'file',
                    ['function']: documentFunctions.other,
                    selector: documentFunctionSelectors.other
                };
            }
        })];

        setFileDataToAdd(_ => info);
        onFilesChanged({ files: filesToAdd, info });
    }, [ filesToAdd ]);

    return (
        <div className="d-flex flex-column">
            <div {...getRootProps({ className: 'dropzone d-flex mb-2 border border-3 border-opacity-10' })}>
                <input {...getInputProps()} multiple />
                <div className="d-flex flex-fill justify-content-center align-items-center py-3">
                    <p className="m-0">Drag and drop attachments here or click to browse for files.</p>
                </div>
            </div>
            <div className={classNames({
                "row": !showAdditionalProps,
                "d-flex flex-column": showAdditionalProps
            })}>
                {filesToAdd.map((file, index) => (
                    <div key={index} className={classNames({
                        "col-4": !showAdditionalProps,
                        "": showAdditionalProps
                    })}>
                        <FileDisplay
                            file={file}
                            fileData={showAdditionalProps === true ? fileDataToAdd.find(el => el.id === file.name) : null}
                            fileDataIcon={fileDataToAdd.find(el => el.id === file.name)?.typeIcon ?? 'file'}
                            onClearFile={onRemoveAttachment}
                            onFileDataChanged={onFileDataChanged}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileUploader;
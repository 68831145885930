import OidcTokenClient from "../helpers/OidcTokenClient";

export const oidcClient = new OidcTokenClient();

// TODO: set the token within the request
const oidcTokenMiddleware = (oidcClient) => {
    return (store) => {
        return (next) => (action) => {
            return next(action);
        };
    };
};

export default oidcTokenMiddleware;
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AccordionBody, AccordionHeader, AccordionListItem, ThemedSpan } from "../../../components/utilities";
import FileInput from "../components/FileInput";
import useFundDetails from "../hooks/useFundDetails";
import { useLazyFetchChartFileQuery, usePublishChartMutation } from "../../../services/assets";

const PerformanceChart = () => {
    const [, { assetGroup }] = useFundDetails();
    const fileInputRef = useRef(null);

    const [imageSrc, setImageSrc] = useState(null);
    const [displayText, setDisplayText] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [fetchChartTrigger] = useLazyFetchChartFileQuery();

    const parseResponse = useCallback((response) => {
        // Decode the base64-encoded image data
        const imageBase64 = response.imageData;

        // Create an image source from the base64 data
        const imageSrc = `data:image/png;base64,${imageBase64}`;

        setImageSrc(imageSrc);
        setDisplayText(response.displayText);
    }, []);

    useEffect(() => {
        if (assetGroup?.publishedChart != null) {
            fetchChartTrigger({ assetGroupId: assetGroup?.assetGroupId })
                .unwrap()
                .then(parseResponse);
        }
    }, [assetGroup?.assetGroupId, assetGroup?.publishedChart, fetchChartTrigger, parseResponse]);

    const [publishChartTrigger] = usePublishChartMutation();

    const uploadFile = useCallback((file) => {
        setErrorMessage(null);
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            publishChartTrigger({ assetGroupId: assetGroup?.assetGroupId, file: formData })
                .unwrap()
                .then(parseResponse)
                .catch((err) => {
                    if (err.status === 415) {// Invalid File Type
                        setErrorMessage(err.data.message);
                    } else {
                        setErrorMessage(err.data);
                    }
                    reject(err);
                })
                .then(resolve, reject);
        });
    }, [assetGroup?.assetGroupId, parseResponse, publishChartTrigger]);

    const hideFileInput = useMemo(() =>
        assetGroup?.publishedChart == null && imageSrc == null,
        [assetGroup?.publishedChart, imageSrc]);

    return <AccordionListItem eventKey="performance-chart">
        <AccordionHeader>Performance Chart</AccordionHeader>
        <AccordionBody>
            {hideFileInput
                && <p className="pt-3">
                    No Performance Chart has been uploaded for this fund. Please click <button className="anchor" onClick={() => fileInputRef.current.click()}>here</button> to upload a file.
                </p>}

            <div className="d-flex flex-row">
                <span className={hideFileInput ? "d-none" : "d-inline-block"}>
                    <FileInput
                        ref={fileInputRef}
                        // value={assetGroup?.publishedChart}
                        uploadFile={uploadFile}
                    />
                </span>
                <ThemedSpan variant="muted" className="ps-2 my-auto">{displayText ?? ""}</ThemedSpan>
            </div>

            {errorMessage != null && <div className="d-flex justify-content-around">
                <p className="text-danger">{errorMessage}</p>
            </div>}

            {imageSrc != null && <div className="d-flex justify-content-around">
                <img
                    src={imageSrc}
                    alt="Current Performance Chart"
                />
            </div>}


        </AccordionBody>
    </AccordionListItem>
}

export default PerformanceChart;
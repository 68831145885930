import { wealthProApi } from "../../redux/api";

const userEmailContactApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["EmailContacts"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchUserEmailContacts: builder.query({
            query: ({ userId, searchText, page, limit, sort }) => {
                if (page || limit) {
                    return `api/clientdocuments/users/${userId}/emailcontacts?searchText=${searchText}&page=${page ?? 1}&limit=${limit ?? 50}&sort=${sort ?? "ContactName, ContactEmailAddress"}`;
                }

                return `api/clientdocuments/users/${userId}/emailcontacts?sort=${sort ?? "ContactName, ContactEmailAddress"}`;
            },
            providesTags: ['EmailContacts']
        }),
        patchUserEmailContact: builder.mutation({
            query: ({ id, operations }) => ({
                url: `api/clientdocuments/emailcontacts/${id}`,
                method: "PATCH",
                body: operations
            }),
            invalidatesTags: ["EmailContacts"]
        }),
        deleteUserEmailContact: builder.mutation({
            query: ({ id }) => ({
                url: `api/clientdocuments/emailcontacts/${id}`,
                method: "DELETE"
            })
        })
    })
});

export const {
    useFetchUserEmailContactsQuery,
    useLazyFetchUserEmailContactsQuery,
    useDeleteUserEmailContactMutation,
    usePatchUserEmailContactMutation
} = userEmailContactApiEndpoints;
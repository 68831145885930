import _ from "lodash";
import React from "react";
import { usePatchInvestmentTransactionMutation, useDuplicateTransactionMutation, useCreateTransactionMutation } from "../../../services/valuations";


const EditTransactionContext = React.createContext();

export const useEditTransaction = () => React.useContext(EditTransactionContext);

export const EditTransactionProvider = ({children}) => {

    const [transaction, _setTransaction] = React.useState(null);
    const [showEditModal, _setShowEditModal] = React.useState(false);
    const [showDuplicateModal, _setShowDuplicateModal] = React.useState(false);
    const [patch, {isLoading, isError: isErrorPatching, ...patchResults}] = usePatchInvestmentTransactionMutation();    
    const [duplicate, {isLoading: isDuplicateLoading, ...duplicateResults}] = useDuplicateTransactionMutation();    
    const [create, {isLoading: isCreateLoading, ...createResults}] = useCreateTransactionMutation();    

    const clear = () => {
        _setTransaction(_ => null);
        _setShowEditModal(_ => false);
        _setShowDuplicateModal(_ => false);
    };

    const saveChanges = (operations) => {
        const { investmentTransactionId, masterAccountId } = transaction;
        return new Promise((resolve, reject) => {
            return patch({ investmentTransactionId: investmentTransactionId, masterAccountId: masterAccountId, operations: operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const saveExternalChanges = (investmentTransactionId, masterAccountId, operations) => {
        return new Promise((resolve, reject) => {
            return patch({ investmentTransactionId: investmentTransactionId, masterAccountId: masterAccountId, operations: operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const duplicateTransaction = (data) => {
        return new Promise((resolve, reject) => {
            return create(data).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    }

    const setEditTransaction = (transaction) => {
        _setTransaction(_ => transaction);
        _setShowEditModal(true);
    };

    const setDuplicateTransaction = (transaction) => {
        _setTransaction(_ => transaction);
        _setShowDuplicateModal(true);
    };

    return (
        <EditTransactionContext.Provider value={{ 
            clear,
            isLoading,
            isEditModalActive: showEditModal,
            isDuplicateModalActive: showDuplicateModal,
            patch: { isLoading, ...patchResults },
            transaction,
            saveChanges,
            saveExternalChanges,
            duplicateTransaction,
            isCreateLoading,
            setEditTransaction,
            setDuplicateTransaction
        }}>
            {children}
        </EditTransactionContext.Provider>
    );
};
import React from "react";
import { VirtualTable } from "../../../components/tables";
import { ClientTransactionEditModal } from "../../clienttransactionsedit";
import { useFilterContext } from "../../../hooks/FilterContext";
import ClientTransactionsTableRow from "./ClientTransactionsTableRow";
import { StandardTableSortablePropertyHeader, ThemedTableHeaderActionColumn } from "../../../components/tables/StandardTable";
import useTransactions from "../hooks/useTransactions";
import { IconButton } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { useAuth } from "react-oidc-context";
import { useEditTransaction } from "../../clienttransactionsedit/hooks/EditTransactionContext";
import DuplicateTransactionModal from "../../clienttransactionsduplicatemodal/components/DuplicateTransactionModal";
import toast from "react-hot-toast";

const ClientTransactionsGrid = ({ masterAccountId, loadingLines = 4 }) => {

    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;

    const canEdit = roles.includes("transaction_write");

    const {
        results,
        sort,
        totalCount,
        error,
        isNextPageLoading,
        isError,
        loadMore,
        isRowLoaded,
        sortTable,
        createTransaction,
        isCreating
    } = useTransactions(masterAccountId);

    const { clear, isLoading, isEditModalActive, isDuplicateModalActive, transaction, saveChanges, duplicateTransaction, isCreateLoading } = useEditTransaction();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>
    }

    return (
        <>  
            <ClientTransactionEditModal
                transaction={transaction}
                show={isEditModalActive}
                isLoading={isLoading}
                handleSave={(ops) => saveChanges(ops).then(clear)}
                handleClose={clear}
            />

            <DuplicateTransactionModal
                transaction={transaction}
                show={isDuplicateModalActive}
                isLoading={isCreateLoading}
                handleSave={(ops) => duplicateTransaction(ops).then(clear)}
                handleClose={clear}
            />

            <VirtualTable
                enableScrollTracking={false}
                row={ClientTransactionsTableRow}
                header={
                    <thead>
                        <tr>
                            <StandardTableSortablePropertyHeader
                                property="designation.designation"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Designation
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="investmentOperation"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Operation
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="productName"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Product
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="longAssetName"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Asset
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="custodyPartner.providerName"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Custody Partner
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="status"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Status
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="custodianAccountReference"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Custodian Ref
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="quantity"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Quantity
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="value"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Value
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="investmentDate"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Date
                            </StandardTableSortablePropertyHeader>
                            {canEdit &&
                            <ThemedTableHeaderActionColumn className="sticky-header align-middle text-center action-col">  
                                <IconButton className="btn-sm" icon="fa-plus" onClick={createTransaction}  disabled={isCreating}/>
                            </ThemedTableHeaderActionColumn>}
                        </tr>
                    </thead>
                }
                footer={
                    <div className="p-2 p-sticky">
                        <ThemedSpan>{totalCount} Transaction(s)</ThemedSpan>
                    </div>
                }
                error={error}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                isLoading={isNextPageLoading}
                itemCount={totalCount === results.length ? totalCount : results.length + loadingLines}
                itemData={results}
            />
        </>
    );
};

export default ClientTransactionsGrid;

import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const corporateActionPairsApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        createCorporateActionPairing: build.mutation({
            query: ({ organisationId, divisionId }) => ({
                url: 'api/assets/corporateActionPair',
                method: 'POST',
                body: {
                    organisationId,
                    divisionId
                }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                try {
                    dispatch(wealthProApi.util.updateQueryData(
                        'fetchCorporateActionPairings',
                        {},
                        ({ results, totalCount, ...rest }) => ({
                            ...rest,
                            results: [data, ...results],
                            totalCount: totalCount + 1
                        })
                    ))
                }
                catch { }
            }
        }),
        deleteCorporateActionPairing: build.mutation({
            query: ({ pairId }) => ({
                url: `api/assets/corporateActionPair/${pairId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ pairId }, { dispatch, queryFulfilled }) {
                const patch = dispatch(wealthProApi.util.updateQueryData(
                    'fetchCorporateActionPairings',
                    {},
                    ({ results, totalCount, ...rest }) => ({
                        ...rest,
                        results: results.filter(x => x.pairId !== pairId),
                        totalCount: totalCount - 1
                    })
                ));

                try {
                    await queryFulfilled;
                }
                catch {
                    patch.undo();
                }
            }
        }),
        fetchCorporateActionPairings: build.query({
            query: ({ next = null, limit = 25, sort = "ActionDate+DESC", filter }) => ({
                url: `api/assets/corporateActionPair/grid?limit=${limit}${next ? `&next=${next}` : ""}`,
                method: 'POST',
                body: {
                    ...filter,
                    sort
                }
            }),
            serializeQueryArgs: ({ endpointName }) => endpointName,
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        patchCorporateActionPairing: build.mutation({
            query: ({ pairId, operations }) => ({
                url: `api/assets/corporateActionPair/${pairId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ pairId, operations }, { dispatch, queryFulfilled }) {
                const patch = dispatch(wealthProApi.util.updateQueryData(
                    'fetchCorporateActionPairings',
                    {},
                    ({ results }) => {
                        var item = results.find(x => x.pairId === pairId);
                        applyPatch(item, operations, true);
                    }
                ));

                try {
                    await queryFulfilled;
                }
                catch {
                    patch.undo();
                }
            }
        })
    })
});

export const {
    useCreateCorporateActionPairingMutation,
    useDeleteCorporateActionPairingMutation,
    useLazyFetchCorporateActionPairingsQuery,
    usePatchCorporateActionPairingMutation
} = corporateActionPairsApiEndpoints;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { FormCheck } from "../../../components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfirmForRestrictedContactModal = ({ show, sendFn, isUpdating }) => {

    return <Modal show={show} centered backdrop="static">
        <Modal.Header>
            <Modal.Title>Restricted Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex">
                <FontAwesomeIcon className="text-warning pt-2 me-4" size="3x" icon="triangle-exclamation" />
                <div>
                    <p>One of the recipients selected is marked as a "restricted" email contact.</p>
                    <p>Would you still like to file this email in WealthPro?</p>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" disabled={isUpdating} onClick={() => sendFn(false)}>
                Send
            </Button>
            <Button variant="warning" disabled={isUpdating} onClick={() => sendFn(true)}>
                Send and File
            </Button>
        </Modal.Footer>
    </Modal>
};

export default ConfirmForRestrictedContactModal;
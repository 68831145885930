import FormSelect from "../FormSelect";
import { useEffect, useMemo } from "react";
import { useFetchDelegatedUsersQuery } from "../../../services/identity";
import { useLazyFetchMessageUsersForMasterAccountQuery } from "../../../services/messages/messagesMasterAccountsApiEndpoints";
import { GenericUserSelectOption } from "./GenericUserSelect";
import { DelegatedAccessTypes } from "../../../helpers/constants";

const MessageUserDelegatesSelect = ({ 
    masterAccountId, 
    delegateAccessType = DelegatedAccessTypes.readMessages,
    defaultValue, 
    className, 
    onChange, 
    onBlur, 
    options, 
    ...rest 
}) => {
    const { data: users, isFetching: isFetchingUsers } = useFetchDelegatedUsersQuery({ accessType: delegateAccessType })
    const [trigger, { data, isFetching }] = useLazyFetchMessageUsersForMasterAccountQuery();

    const _options = useMemo(() => {
        let opts = [];
        
        if (data && Array.isArray(data) && data.length > 0) {
            opts = [...opts, {
                label: 'Master Account',
                options: data.filter((el) => el.userType === 0)
            }];
        }
        
        if (users && Array.isArray(users) && users.length > 0) {
            opts = [...opts, {
                label: 'System Users',
                options: [...users]
            }];
        }

        return opts;
    }, [ data, users ]);

    useEffect(() => {
        masterAccountId && trigger({ masterAccountId });
    }, [masterAccountId]);

    return <FormSelect
        className={className}
        defaultValue={defaultValue}
        isLoadingOptions={isFetchingUsers || isFetching}
        onBlur={onBlur}
        onChange={onChange}
        options={_options}
        components={{
            Option: GenericUserSelectOption
        }}
        {...rest}
    />;
};

export default MessageUserDelegatesSelect;
import InvestmentCommitteePrdCommentaryLayout from "../layouts/InvestmentCommitteePrdCommentaryLayout";
import { NavigationTabs } from "../components/navigation";
import { PrdCommentaryPage } from "../pages/investment-committee";

const InvestmentCommitteePrdCommentaryRoutes = {
    path: 'prdcommentary/*',
    pathBase: '/investment-committee/prdcommentary',
    defaultRoute: 'coverletter',
    roles: [],
    element: InvestmentCommitteePrdCommentaryLayout,
    children: [
        {
            path: ':commentaryType',
            element: PrdCommentaryPage
        }
    ]
};

const InvestmentCommitteePrdCommentaryTabRoutes = {
    ...InvestmentCommitteePrdCommentaryRoutes,
    children: [
        {
            path: 'coverletter',
            icon: 'fa-envelope-open-text',
            label: 'Cover Letter'
        },
        {
            path: 'economiccontext',
            icon: 'fa-earth-europe',
            label: 'Economic Context'
        },
        {
            path: 'discussionbullets',
            icon: 'fa-list-ul',
            label: 'Discussion Bullets'
        },
        {
            path: 'portfoliospecific',
            icon: 'fa-chart-line',
            label: 'Portfolio Specific'
        }
    ]
}

export const InvestmentCommitteePrdCommentaryRouteTabs = () => <NavigationTabs pathBase={InvestmentCommitteePrdCommentaryTabRoutes.pathBase} options={InvestmentCommitteePrdCommentaryTabRoutes}  />

export default InvestmentCommitteePrdCommentaryRoutes;
import moment from "moment";
import Moment from "react-moment";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import styled from "styled-components";
import { lighten } from "polished";
import { NumericFormat } from "react-number-format";

const StyledRow = styled.tr`
    ${props => props.active ? `background-color: ${lighten(0.5, props.theme.primary)};` : ""}

    &:hover {
        cursor: pointer;
    }
`;

const TransactionPotentialMatchesGridRow = ({ data }) => {

    const { selectedApplicationTransactionId, setSelectedApplicationTransactionId } = useCustodianTransactionMatching();

    const {
        assetName,
        designation,
        matchScore,
        matchTypeDescription,
        sedol,
        transactionAmount,
        transactionDate,
        transactionId,
        transactionTypeText,
        transactionQuantity
    } = data;

    const handleOnClick = () => {
        if (selectedApplicationTransactionId === transactionId) {
            return setSelectedApplicationTransactionId(null);
        }

        setSelectedApplicationTransactionId(transactionId);
    };

    return (
        <StyledRow active={selectedApplicationTransactionId === transactionId} onClick={handleOnClick}>
            <td>{moment(transactionDate).isValid() ? <Moment date={transactionDate} format="DD/MM/YYYY" /> : <span className="px-2">-</span>}</td>
            <td>{transactionTypeText}</td>
            <td>{sedol} - {assetName}</td>
            <td>{designation}</td>
            <td>{transactionQuantity}</td>
            <td>{transactionAmount}</td>
            <td><NumericFormat displayType="text" value={matchScore} decimalScale={2}/></td>
            <td>{matchTypeDescription}</td>
        </StyledRow>
    );
};

export default TransactionPotentialMatchesGridRow;
import { wealthProApi } from '../../redux/api';

const masterAccountDocumentEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({ 
        fetchMasterAccountDocuments: builder.query({
            query: ({ 
                masterAccountId, 
                projectId = null,
                page = 1, 
                limit = 50,
                ignoreReports = true
            }) => `api/clientdocuments/masteraccounts/${masterAccountId}/documents?projectId=${projectId}&page=${page}&limit=${limit}&ignoreReports=${ignoreReports === true ? 'true' : 'false'}`,
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            }
        }),
        fetchMasterAccountAmlDocuments: builder.query({
            query: ({ masterAccountId, searchTerm, page = 1, limit = 50, fetchType = "List" }) => `api/clientdocuments/masteraccounts/${masterAccountId}/amlDocuments?searchTerm=${searchTerm}&page=${page}&limit=${limit}&fetchType=${fetchType}`,
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            }
        }),
        fetchMasterAccountDocumentsForSlug: builder.query({
            query: ({ masterAccountId, slug, searchTerm, page = 1, limit = 50, fetchType = "List" }) => `api/clientdocuments/masteraccountdocuments/${masterAccountId}/documents/${slug}/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}&fetchType=${fetchType}`,
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            }
        }),
        searchMasterAccountDocumentsForSelect: builder.query({
            query: ({ masterAccountId, slug, searchTerm, page = 1, limit = 50, fetchType = "List" }) => `api/clientdocuments/masteraccountdocuments/${masterAccountId}/documents/search?slug=${slug}&searchTerm=${searchTerm}&page=${page}&limit=${limit}&fetchType=${fetchType}`,
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            }
        })
    })
});

export const {
    useFetchMasterAccountDocumentsQuery,
    useLazyFetchMasterAccountDocumentsQuery,
    useLazyFetchMasterAccountAmlDocumentsQuery,
    useLazyFetchMasterAccountDocumentsForSlugQuery,
    useLazySearchMasterAccountDocumentsForSelectQuery
} = masterAccountDocumentEndpoints;
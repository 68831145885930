import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";

const instructionEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createInstruction: builder.mutation({
            query: ({ newId, typeId, sheetId }) => ({
                url: `api/recommendations/InstructionHeader/${newId}`,
                method: "PATCH",
                body: [patchReplace("businessSheetId", sheetId), patchReplace("instructionTypeId", typeId)]
            }),
            async onQueryStarted({ newId, typeId, sheetId }, { dispatch, queryFulfilled }) {
                try {
                    const createResult = dispatch(wealthProApi.util.updateQueryData('fetchBizSheet', { bizSheetId: sheetId }, draft => {
                        draft.instructions = [...draft.instructions, {
                            id: newId.replaceAll(/-/g, ''),
                            instructionTypeId: typeId.replaceAll(/-/g, ''),
                            businessSheetId: sheetId.replaceAll(/-/g, '')
                        }];
                    }));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error creating Instruction in service", err);
                        createResult.undo();
                    }
                } catch (err) {
                    console.error("Error creating Instruction in cache", err);
                }
            }
        }),
        fetchInstruction: builder.query({
            query: ({ instructionId }) => `api/recommendations/InstructionHeader/${instructionId}`
        }),
        patchInstruction: builder.mutation({
            query: ({ instructionId, operations }) => ({
                url: `api/recommendations/InstructionHeader/${instructionId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ instructionId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId }, (draft) => {
                        applyPatch(draft, operations);
                    }))

                    try {
                        await queryFulfilled;
                    } catch (ex) {
                        console.error(ex, "Error updating service");
                        patchResult.undo();
                    }
                } catch (ex) {
                    console.error(ex, "Error updating cache");
                }
            }
        }),
        deleteInstruction: builder.mutation({
            query: ({ instructionId }) => ({
                url: `api/recommendations/InstructionHeader/${instructionId}`,
                method: "DELETE"
            }),
            async onQueryStarted({ instructionId, sheetId }, { dispatch, queryFulfilled }) {
                try {
                    const createResult = dispatch(wealthProApi.util.updateQueryData('fetchBizSheet', { bizSheetId: sheetId }, draft => {
                        draft.instructions = draft.instructions.filter(x => x.id !== instructionId);
                    }));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting Instruction in service", err);
                        createResult.undo();
                    }
                } catch (err) {
                    console.error("Error deleting Instruction in cache", err);
                }
            }
        }),
        fetchInvest: builder.query({
            query: ({ instructionId }) => `api/recommendations/InstructionHeader/${instructionId}/Invest`
        }),
        fetchDivest: builder.query({
            query: ({ instructionId }) => `api/recommendations/InstructionHeader/${instructionId}/Divest`
        }),
        fetchStandaloneFees: builder.query({
            query: ({ instructionId }) => `api/recommendations/InstructionHeader/${instructionId}/StandaloneFees`
        }),
        fetchPolicy: builder.query({
            query: ({ instructionId }) => `api/recommendations/InstructionHeader/${instructionId}/Policy`
        }),
        fetchRecommendationProductCosts: builder.query({
            query: ({ instructionId, listType, section }) => `api/recommendations/InstructionHeader/${instructionId}/ProductCosts/${section}${listType ? `listType=${listType}` : ""}`
        }),
        saveRecommendationProductCosts: builder.mutation({
            query: ({ instructionId, updatedList }) => ({
                url: `api/recommendations/InstructionHeader/${instructionId}/ProductCosts`,
                method: "POST",
                body: updatedList
            }),
            async onQueryStarted({ instructionId, section }, { dispatch, queryFulfilled }) {
                // Pessimistically update the cached Instruction
                try {
                    const { data } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId }, (draft) => {
                        draft.productCosts = data;
                        return draft;
                    }));
                    dispatch(wealthProApi.util.updateQueryData("fetchRecommendationProductCosts", { instructionId, section }, (draft) => {
                        draft = data;
                        return draft;
                    }));
                } catch (error) {
                    console.error(error);
                }
            }
        }),
        linkProductCosts: builder.mutation({
            query: ({ instructionId, productId, section }) => ({
                url: `api/recommendations/InstructionHeader/${instructionId}/ProductCosts/${section}/Product/${productId}`,
                method: "POST"
            }),
            async onQueryStarted({ instructionId, section }, { dispatch, queryFulfilled }) {
                try {
                    // Optimistic update to clear existing data
                    const setListResult = dispatch(
                        wealthProApi.util.updateQueryData("fetchRecommendationProductCosts", { instructionId, section }, (draft) => {
                            draft = [];
                            return draft;
                        })
                    );
                    try {
                        // Pessimistic update to change cached list
                        const { data: updatedList } = await queryFulfilled;
                        dispatch(wealthProApi.util.updateQueryData("fetchRecommendationProductCosts", { instructionId, section }, (draft) => {
                            draft = updatedList;
                            return draft;
                        }));
                    } catch {
                        setListResult.undo();
                    }
                } catch (error) {
                    console.error("Error adding those products' costs to the instruction", error)
                }
            }
        }),
        applyProductCosts: builder.mutation({
            query: ({ instructionId }) => ({
                url: `api/recommendations/InstructionHeader/${instructionId}`,
                method: "PATCH",
                body: [patchReplace("applyCosts", true)]
            })
        })
    })
})

export const {
    useCreateInstructionMutation,
    useLazyFetchInstructionQuery,
    usePatchInstructionMutation,
    useDeleteInstructionMutation,
    useLazyFetchInvestQuery,
    useLazyFetchDivestQuery,
    useLazyFetchPolicyQuery,
    useFetchStandaloneFeesQuery,
    useLazyFetchRecommendationProductCostsQuery,
    useSaveRecommendationProductCostsMutation,
    useLinkProductCostsMutation,
    useApplyProductCostsMutation
} = instructionEndpoints
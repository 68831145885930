import _ from "lodash";
import React, { useContext } from "react";
import sitemap from "../data/sitemap.json";
import { useAuth } from "react-oidc-context";

const NavigationContext = React.createContext();

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;

    const mySiteMap = React.useMemo(() => {
        //order the site map by the display order
        sitemap.data = sitemap.data.sort((a, b) => a.displayOrder - b.displayOrder);
        
        var result = sitemap.data.filter(({ isActive, requiredRoles }) => isActive === true && (!requiredRoles || requiredRoles.length === 0 || _.some(roles, item => requiredRoles.includes(item))));

        return result;

    }, [ roles, sitemap ]);

    return <NavigationContext.Provider value={{
        navigation: mySiteMap
    }}>
        {children}
    </NavigationContext.Provider>
};
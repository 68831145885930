import { FormSelect } from "../../../components/forms";

const ServiceSchedulingTypesSelect = ({ options, ...rest }) => {
    return (
        <FormSelect 
            options={[
                { label: 'Automated', value: 0 },
                { label: 'Manual', value: 1 },
                { label: 'No Scheduling Required', value: 2 }
            ]}
            {...rest}
        />
    );
};

export default ServiceSchedulingTypesSelect;
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useCreateCalendarEventMutation, useDeleteCalendarEventMutation, useLazyGetMasterAccountEventPreferencesQuery, usePatchCalendarEventMutation } from "../../../services/usercalendar";

export const MeetingLocations = {
    Home: 0,
    Office: 1,
    Phone: 2,
    Video: 3,
    None: 4
};

export const MeetingTypes = {
    Review: 0,
    "Regular Update": 1,
    Advice: 2,
    Seminar: 3,
    Training: 4,
    "Internal Staff": 5
};


const useEvents = (queryProps = {}) => {

    const { selectedCalendarUserId: userId } = usePersonalOutlookContext();
    const [createTrigger, createResult] = useCreateCalendarEventMutation();
    const [deleteTrigger, deleteResult] = useDeleteCalendarEventMutation();
    const [getAccountEventPreferencesTrigger, accountEventPreferencesResult] = useLazyGetMasterAccountEventPreferencesQuery();
    const [updateTrigger, updateResult] = usePatchCalendarEventMutation();

    const createEvent = (request, formData) => {
        return new Promise((resolve, reject) => {
            return createTrigger({ request, formData, userId: request.organizerId, ...queryProps })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const cancelEvent = (id) => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ id, userId, ...queryProps })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const getMasterAccountEventPreferences = (masterAccountId) => {
        return new Promise((resolve, reject) => {
            return getAccountEventPreferencesTrigger({masterAccountId})
                .unwrap()
                .then(resolve, reject);
        });
    }

    const updateEvent = (id, request) => {
        return new Promise((resolve, reject) => {
            return updateTrigger({ id, request, userId, ...queryProps })
                .unwrap()
                .then(resolve, reject);
        });
    };

    return {
        createResult,
        updateResult,
        createEvent,
        cancelEvent,
        getMasterAccountEventPreferences,
        accountEventPreferencesResult,
        cancelResult: deleteResult,
        updateEvent
    }
};

export default useEvents;
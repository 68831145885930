import { Modal } from "react-bootstrap";
import { Button } from "../../components";
import { usePersonalOutlookContext } from "../../hooks/PersonalOutlookContext";
import { useForm } from "react-hook-form";
import React, { useEffect, useMemo } from "react";
import { ConfirmModal } from "../../components/modals";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemedSpan } from "../../components/utilities";
import toast from "react-hot-toast";
import { useAuth } from "react-oidc-context";
import DraftMailForm from "./components/DraftMailForm";
import useCreateMail from "./hooks/useCreateMail";
import { MailSendType } from "../../hooks/PersonalOutlookContext";
import { useLazyGetMailDelegatedAccessRightsForUserQuery } from "../../services/useremails";
import ConfirmForRestrictedContactModal from "./components/ConfirmForRestrictedContactModal";

export {
    DraftMailForm
};

const CreateMailModal = ({
    initialAttachments = [],
    initialFrom,
    initialToRecipients = [],
    initialCcRecipients = [],
    initialBccRecipients = [],
    parentMessageId, // used for forwarding/replying
    sendType = MailSendType.Default,
    masterAccountId,
    show,
    onHide,
    ...props
}) => {

    const { user: { profile } } = useAuth();
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [showConfirmFileModal, setShowConfirmFileModal] = useState(false);
    const [getMailDelegateRights, mailDelegateRightsResult] = useLazyGetMailDelegatedAccessRightsForUserQuery();

    const {
        createDraftMail,
        createDraftMailResult: { isLoading, isUpdating },
    } = useCreateMail(parentMessageId);

    const { sendDraftEmail } = usePersonalOutlookContext();

    const { data: mailDelegateRights } = mailDelegateRightsResult || {};

    const formDefaultValues = useMemo(() => ({
        attachments: initialAttachments,
        from: initialFrom ?? profile.sub.replace(/-/g, ""),
        subject: null,
        body: null,
        masterAccountId: masterAccountId,
        projectId: null,
        bccRecipients: initialBccRecipients,
        ccRecipients: initialCcRecipients,
        toRecipients: initialToRecipients,
    }), [initialAttachments, initialFrom, profile.sub, masterAccountId, initialBccRecipients, initialCcRecipients, initialToRecipients]);

    const formProperties = useForm({ values: formDefaultValues });

    const { watch, reset, formState: { errors } } = formProperties;

    const currentValues = watch();
    const currentFrom = watch("from");

    const hasRestrictedRecipient = useMemo(() => {
        return currentValues.toRecipients.some(x => x.data?.isRestricted)
            || currentValues.ccRecipients.some(x => x.data?.isRestricted)
            || currentValues.bccRecipients.some(x => x.data?.isRestricted)
    }, [currentValues]);

    const handleClose = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        reset({
            attachments: [],
            from: null,
            subject: null,
            body: null,
            masterAccountId: null,
            projectId: null,
            bccRecipients: [],
            ccRecipients: [],
            toRecipients: [],
        });
        setShowConfirmFileModal(false);
        setShowConfirmCancelModal(false);
        onHide();
    };

    const mapRecipeint = (listViewModel) => ({
        contactName: listViewModel.data?.contactName ?? listViewModel.label,
        contactEmailAddress: listViewModel.data?.contactEmailAddress ?? listViewModel.value,
        isNew: listViewModel["__isNew__"]
    });

    const convertRequest = () => {
        switch (sendType) {
            case MailSendType.Default: return convertToFormData();
            case MailSendType.Forward: return convertToForwardRequest();
            case MailSendType.Reply: return convertToReplyRequest();
            case MailSendType.ReplyAll: return convertToReplyRequest();
        }
    };

    const convertToFormData = () => {

        const newFormData = new FormData();
        const { from, subject, body, masterAccountId, projectId, bccRecipients, ccRecipients, toRecipients, attachments } = currentValues;


        attachments?.forEach((file, index) => newFormData.append("file" + index, file, file.name));

        newFormData.append("from", JSON.stringify(from));
        newFormData.append("subject", subject);
        newFormData.append("body", body);

        if (toRecipients && toRecipients !== null && toRecipients.length !== 0) {
            newFormData.append("toRecipients", JSON.stringify(toRecipients.map(mapRecipeint)));
        }

        if (ccRecipients && ccRecipients !== null && ccRecipients.length !== 0) {
            ccRecipients.forEach((recipient, index) => newFormData.append(`ccRecipients[${index}]`, JSON.stringify(mapRecipeint(recipient))));
        }

        if (bccRecipients && bccRecipients !== null && bccRecipients.length !== 0) {
            bccRecipients.forEach((recipient, index) => newFormData.append(`bccRecipients[${index}]`, JSON.stringify(mapRecipeint(recipient))));
        }

        if (projectId && projectId !== null) {
            newFormData.append('projectId', projectId);
        }

        return newFormData;
    };

    const convertToForwardRequest = () => {
        return {
            comment: currentValues.body,
            toRecipients: currentValues.toRecipients.map(mapRecipeint),
            masterAccountId
        };
    };

    const convertToReplyRequest = () => {
        const newFormData = new FormData();
        const { body, projectId, bccRecipients, ccRecipients, toRecipients, attachments } = currentValues;

        attachments?.forEach((file, index) => newFormData.append("file" + index, file, file.name));
        newFormData.append("comment", body);

        if (toRecipients && toRecipients !== null && toRecipients.length !== 0) {
            newFormData.append("toRecipients", JSON.stringify(toRecipients.map(mapRecipeint)));
        }

        if (ccRecipients && ccRecipients !== null && ccRecipients.length !== 0) {
            ccRecipients.forEach((recipient, index) => newFormData.append(`ccRecipients[${index}]`, JSON.stringify(mapRecipeint(recipient))));
        }

        if (bccRecipients && bccRecipients !== null && bccRecipients.length !== 0) {
            bccRecipients.forEach((recipient, index) => newFormData.append(`bccRecipients[${index}]`, JSON.stringify(mapRecipeint(recipient))));
        }

        if (projectId && projectId !== null) {
            newFormData.append('projectId', projectId);
        }

        return newFormData;
    };

    const onSaveDraft = (showToast = true) => {
        var requestBody = convertRequest();

        if (!showToast) { // being called from send
            return createDraftMail(requestBody, sendType);
        }

        toast.promise(createDraftMail(requestBody, sendType), {
            loading: "Saving draft...",
            success: () => {
                handleClose();
                return "Draft saved!";
            },
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                return `There was a problem saving the draft. ${display}`;
            }
        });
    };

    const onSubmit = (forceFile = false) => {

        toast.promise(onSaveDraft(false).then(({ id }) => {
            sendDraftEmail({ id, userId: currentFrom?.value ?? currentFrom, masterAccountId, forceFile });
        }), {
            loading: "Sending email...",
            success: () => {
                handleClose();
                return "Email sent!";
            },
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                return `There was a problem sending the email. ${display}`;
            }
        });
    };

    useEffect(() => {
        currentFrom && getMailDelegateRights({ userId: currentFrom?.value ?? currentFrom });
    }, [currentFrom]);

    return <Modal size={"xl"} keyboard={false} backdrop="static" centered show={show} onHide={handleClose}>
        <Modal.Header>{sendType === MailSendType.Forward
            ? <ThemedSpan>Forward Email</ThemedSpan>
            : <ThemedSpan>Create {sendType === MailSendType.Default ? "Email" : "Reply"}</ThemedSpan>}
        </Modal.Header>
        <form>
            <Modal.Body>
                <DraftMailForm
                    isForward={sendType === MailSendType.Forward}
                    isReply={sendType === MailSendType.Reply || sendType === MailSendType.ReplyAll}
                    errors={errors}
                    {...formProperties}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button className="me-auto" variant="danger" onClick={() => setShowConfirmCancelModal(true)}>
                    <ThemedSpan variant="light" className="me-2">Cancel</ThemedSpan>
                    <FontAwesomeIcon icon="trash" />
                </Button>
                <Button onClick={() => onSaveDraft()} disabled={isUpdating || isLoading}>
                    <ThemedSpan variant="light" className="me-2">Save as Draft</ThemedSpan>
                    <FontAwesomeIcon icon="save" />
                </Button>
                {mailDelegateRights?.sendMail && <Button onClick={hasRestrictedRecipient ? () => setShowConfirmFileModal(true) : () => onSubmit()} disabled={isUpdating || isLoading}>
                    <ThemedSpan variant="light" className="me-2">Send</ThemedSpan>
                    <FontAwesomeIcon icon="paper-plane" />
                </Button>}
            </Modal.Footer>
        </form>
        <ConfirmModal
            message={<>
                <ThemedSpan>Are you sure you want to cancel changes?</ThemedSpan>
                <br />
                <br />
                <ThemedSpan>This will lose any unsaved progress!</ThemedSpan>
            </>}
            show={showConfirmCancelModal}
            handleClose={() => setShowConfirmCancelModal(false)}
            handleConfirm={handleClose}
        />
        <ConfirmForRestrictedContactModal
            show={showConfirmFileModal}
            sendFn={onSubmit}
            onClose={() => setShowConfirmFileModal(false)}
            isUpdating={isUpdating || isLoading}
        />
    </Modal>;
};

export default CreateMailModal;
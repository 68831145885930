import React from "react";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useLazySearchUserDelegatesQuery } from "../../../services/identity";

const UserDelegatesContext = React.createContext();

export const useUserDelegatesData = () => React.useContext(UserDelegatesContext);

export const UserDelegatesDataProvider = ({ children }) => {
    // setup state and our query state (does not load by default (lazy))
    const [sort, setSort] = React.useState("CreatedDate+desc");
    const [pageLimit, setPageLimit] = React.useState(50);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [selected, setSelected] = React.useState(null);
    const [isClearing, setIsClearing] = React.useState(false);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const { filter, isReady } = useFilterContext();
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazySearchUserDelegatesQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results, newRecordsSinceCache } = data || { pagination: { page: 1, pageSize: pageLimit, totalCount: 0, totalPages: 1 }, results: [], newRecordsSinceCache: 0 };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true;

    const clear = () => {
        if (isNextPageLoading || !isReady) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        setIsClearing(_ => true);
        load({
            ...filter,
            cacheKey: newCacheKey,
            searchTerm,
            sort,
            page: 1,
            limit: pageLimit
        }).unwrap().then(_ => {
            setCacheKey(_prev => newCacheKey);
            setIsLoadingState(_prev => false);
            setIsClearing(_prev => false);
        });
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading || !isReady) {
            return;
        }

        setIsLoadingState(_ => true);
        load({ 
            ...filter,
            cacheKey, 
            searchTerm,
            sort,
            page: page + 1, 
            limit: pageLimit,   
        }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
        });
    };

    const sortTable = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";
        let [sortProperty, direction] = sort.split("+");

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
        clear();
    };

    React.useEffect(clear, [ isReady, filter, searchTerm, sort ]);

    return (
        <UserDelegatesContext.Provider value={{
            ...loadResults,
            clear,
            data: isClearing ? [] : results,
            hasMore,
            isFetching,
            isLoading,
            isNextPageLoading,
            isNewRecords: newRecordsSinceCache > 0,
            isRowLoaded,
            limit: pageLimit,
            loadMore: isNextPageLoading ? () => {} : loadMore, 
            newRecordsSinceCache,
            page,
            pagination,
            searchTerm,
            selected,
            selectDelegate: (i) => setSelected(_ => i),
            setSearchTerm,
            sort,
            sortTable,
            totalCount,
            totalPages
        }}>
            {children}
        </UserDelegatesContext.Provider>
    );
};
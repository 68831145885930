import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { useClientSearch } from "../contexts/ClientSearchContext";

const StyledSearchInput = styled(Form.Control)`
    padding-left: 1.9rem;
    background-image: url("/images/search.png");
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: calc(.9rem + 0.075rem) calc(.9rem + 0.075rem);
`;

const SearchDropdownToggle = React.forwardRef(({ onToggle, show, ...rest }, ref) => {
    const { searchTerm, setSearchTerm } = useClientSearch();
    
    const handleOnValueChanged = (e) => setSearchTerm(_ => e.target.value);

    return <StyledSearchInput
        ref={ref}
        name="account-search-form-input"
        defaultValue={searchTerm}
        placeholder="Search for a client"
        onChange={handleOnValueChanged}
        {...rest}
    />
});

export default SearchDropdownToggle;
import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { variantToColour } from "../../helpers/theme";
import { NavLink as BootstrapNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavLink = styled(BootstrapNavLink)`
    cursor: pointer;
    color: ${props => variantToColour(props)};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    &:hover {
        color: ${props => darken(props.theme.linkDarkenAmount, variantToColour(props))};
    }

    &:active {
        color: ${props => darken(props.theme.linkDarkenAmount, variantToColour(props))};
    }

    &:focus {
        color: ${props => darken(props.theme.linkDarkenAmount, variantToColour(props))};
    }
`;

export const ActionIconNavLink = ({ icon, size = "md", variant = "muted", ...rest }) => {
    return <NavLink variant={variant} {...rest}>
        <FontAwesomeIcon icon={icon} />
    </NavLink>
};

export default NavLink;
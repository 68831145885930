import React, { useMemo } from "react";
import CashSourceRow from "./CashSourceRow";
import { ThemedSpan } from "../../../components/utilities";
import { NumericFormat } from "react-number-format";
import InstructionRow from "./InstructionRow";
import InstructionLabelRow from "./InstructionLabelRow";

const RaiseFeeCash = ({ data, updateFn, cashSourceUpdateFn, setTotalCashRaised }) => {

    const totalCashValue = useMemo(() => data.cashFromClientAmount + data.fromSalesAmount, [data]);

    const remainingBalance = useMemo(() => {
        return totalCashValue - (
            data.intermediationFeesAmount
            + data.adviceFeesAmount
            + data.administrationFeesAmount
        );
    }, [data]);

    return <React.Fragment>
        <div className="container-fluid mb-4">
            <CashSourceRow
                data={data}
                updateFn={updateFn}
                label={"Direct from Client"}
                appliesProperty={"cashFromClientApplies"}
                cashAmountProperty={"cashFromClientAmount"}
                receivedDateProperty={"cashFromClientReceivedDate"}
                receivedConfirmedByNameProperty={"cashFromClientReceivedConfirmedByName"}
                commentProperty={"cashFromClientComment"}
                receivedConfirmedSetDateProperty={"cashFromClientReceivedConfirmedSetDate"}
            />
            <CashSourceRow
                label={"From Sales"}
                data={data}
                updateFn={updateFn}
                appliesProperty={"fromSalesApplies"}
                cashAmountProperty={"fromSalesAmount"}
                receivedDateProperty={"fromSalesReceivedDate"}
                receivedConfirmedByNameProperty={"fromSalesReceivedConfirmedByName"}
                commentProperty={"fromSalesComment"}
                receivedConfirmedSetDateProperty={"fromSalesReceivedConfirmedSetDate"}
            />
            <div className="row mt-3">
                <div className="col-2">
                    <b><ThemedSpan>Total Cash</ThemedSpan></b>
                </div>
                <div className="col-2 text-end">
                    <b><NumericFormat value={totalCashValue} prefix={"£"} thousandSeparator displayType="text" decimalScale={2} fixedDecimalScale /></b>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Intermediation Fees"}
                appliesProperty={"intermediationFeesApply"}
                cashAmountProperty={"intermediationFeesAmount"}
                dateInstructedProperty={"intermediationFeesInstructedDate"}
                instructedDateSetProperty={"intermediationFeesInstructedSetDate"}
                instructedByProperty={"intermediationFeesInstructedByName"}
                dateConfirmedProperty={"intermediationFeesConfirmedDate"}
                confirmedDateSetProperty={"intermediationFeesConfirmedSetDate"}
                confirmedByProperty={"intermediationFeesConfirmedByName"}
            />
            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Advice Fees"}
                appliesProperty={"adviceFeesApply"}
                cashAmountProperty={"adviceFeesAmount"}
                dateInstructedProperty={"adviceFeesInstructedDate"}
                instructedDateSetProperty={"adviceFeesInstructedSetDate"}
                instructedByProperty={"adviceFeesInstructedByName"}
                dateConfirmedProperty={"adviceFeesConfirmedDate"}
                confirmedDateSetProperty={"adviceFeesConfirmedSetDate"}
                confirmedByProperty={"adviceFeesConfirmedByName"}
            />
            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Administration Fees"}
                appliesProperty={"administrationFeesApply"}
                cashAmountProperty={"administrationFeesAmount"}
                dateInstructedProperty={"administrationFeesInstructedDate"}
                instructedDateSetProperty={"administrationFeesInstructedSetDate"}
                instructedByProperty={"administrationFeesInstructedByName"}
                dateConfirmedProperty={"administrationFeesConfirmedDate"}
                confirmedDateSetProperty={"administrationFeesConfirmedSetDate"}
                confirmedByProperty={"administrationFeesConfirmedByName"}
            />
            <InstructionLabelRow
                className={"mt-5 mb-2"}
                label={<ThemedSpan variant={remainingBalance !== 0 ? "danger" : null}>Remaining Balance</ThemedSpan>}
                value={<NumericFormat
                    className={remainingBalance !== 0 ? "text-danger" : null}
                    value={remainingBalance}
                    prefix={"£"}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />}
            />
        </div>
    </React.Fragment>


};

export default RaiseFeeCash;
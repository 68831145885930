import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, DateInput, FormInput, FormLabel, FormSelect, RichTextEditor } from "../../../components/forms";
import { BooleanSelect } from "../../../components/forms/selects";
import useClientAccounts from "../hooks/useClientAccounts";
import useProductTypes from "../hooks/useProductTypes";

const PolicyCover = ({ policy, showBorder, isLoading, updatePolicy, deletePolicy, isPatching, updatePolicyCover, updatePolicyClients }) => {

    const { associatedCoverTypes, isLoadingAssociatedCoverTypes, productTypes, isLoadingProductTypes } = useProductTypes(policy.productTypeId);
    const { data: clients, isLoading: isLoadingClients } = useClientAccounts({ fetchType: 'List' });
    const { isEmployeeBenefit, isPremiumRelevant, isTermRelevant, isRenewalRelevant, isTrustRelevant, isValueRelevant } = policy || {}
    const [productRenderOptions, setProductRenderOptions] = useState({ isEmployeeBenefit, isPremiumRelevant, isTermRelevant, isRenewalRelevant, isTrustRelevant, isValueRelevant });

    const getProductTypeProperties = ({ isEmployeeBenefit, isPremiumRelevant, isTermRelevant, isRenewalRelevant, isTrustRelevant, isValueRelevant }) => ({
        isEmployeeBenefit,
        isPremiumRelevant,
        isTermRelevant,
        isRenewalRelevant,
        isTrustRelevant,
        isValueRelevant
    });

    return <React.Fragment>
        <div className="d-flex flex-row row justify-content-between">
            <GridWrapper
                className="col-5 col-lg-3"
                gridTemplateColumns={`auto 1fr`}
            >
                <FormLabel className="col-start-1 my-auto">
                    Client:
                </FormLabel>
                {isLoading
                    ? <Skeleton containerClassName="col-start-2" />
                    : <span className="col-start-2">
                        <FormSelect
                            isLoadingOptions={isLoadingClients}
                            options={clients}
                            defaultValue={policy.clientMappings.map(x => x.clientAccountId)}
                            isMulti
                            onBlur={(newValue) => updatePolicyClients(policy.policyId, newValue.map(x => x.value))}
                        />
                    </span>}
            </GridWrapper>
            <GridWrapper
                className="col-5 col-lg-4"
                gridTemplateColumns={`auto 1fr`}
            >
                <FormLabel className="col-start-1 my-auto">
                    Product:
                </FormLabel>
                {isLoading
                    ? <Skeleton containerClassName="col-start-2" />
                    : <span className="col-start-2">
                        <FormSelect
                            options={productTypes}
                            isLoadingOptions={isLoadingProductTypes}
                            defaultValue={policy.productTypeId}
                            onChange={(productType) => setProductRenderOptions(productType)}
                            onBlur={(productType) => updatePolicy(policy.policyId,
                                "productTypeId",
                                productType.value,
                                getProductTypeProperties(productType)
                            )}
                        />
                    </span>}
            </GridWrapper>
            <GridWrapper
                className="col-12 col-lg-4 order-last order-lg-2 mt-2 mt-lg-0"
                gridTemplateColumns={`auto 1fr`}
            >
                <FormLabel className="col-start-1 my-auto">
                    Provider:
                </FormLabel>
                {isLoading
                    ? <Skeleton containerClassName="col-start-2" />
                    : <span className="col-start-2">
                        <FormInput
                            value={productRenderOptions.isEmployeeBenefit ? "Employer" : policy.provider}
                            disabled={productRenderOptions.isEmployeeBenefit}
                            onBlur={(value) => updatePolicy(policy.policyId, "provider", value)}
                        />
                    </span>
                }
            </GridWrapper>
            <div className="d-flex flex-row col-2 col-lg-1 order-3 order-lg-last align-items-center justify-content-end">
                <IconButton
                    className="me-2"
                    icon={policy?.isCurrentPriority ? 'check' : 'xmark'}
                    variant='warning'
                    onClick={() => updatePolicy(policy.policyId, "isCurrentPriority", !policy.isCurrentPriority)}
                    disabled={isLoading}
                />
                <IconButton
                    icon={'trash-can'}
                    variant='danger'
                    onClick={() => deletePolicy(policy.policyId)}
                    disabled={isLoading}
                />
            </div>
        </div>

        {isPatching
            ? <Skeleton containerClassName="w-100" />
            : !productRenderOptions.isEmployeeBenefit
            && <div className="d-flex align-items-center mt-2">
                <FormLabel className="mb-0 me-2">
                    Cover:
                </FormLabel>
                <span className="w-100">
                    {isLoading
                        ? <Skeleton />
                        : <FormSelect
                            className="w-100"
                            isLoadingOptions={isLoadingAssociatedCoverTypes}
                            options={associatedCoverTypes}
                            defaultValue={policy.chosenCoverMappings.map((x) => x.coverTypeId)}
                            isMulti
                            onBlur={(newValue) => updatePolicyCover(policy.policyId, newValue.map(x => x.value))}
                        />}
                </span>
            </div>}

        {isPatching
            ? <Skeleton />
            : <div className="d-flex row align-items-center">
                {productRenderOptions?.isValueRelevant
                    && <GridWrapper
                        className="col-12 col-md-5 col-lg-3 mt-2"
                        gridTemplateColumns={`auto 1fr`}
                    >
                        <FormLabel className="col-start-1 my-auto">
                            {policy?.isPriority ? "Benefit Value:" : "Sum Assured:"}
                        </FormLabel>
                        {isLoading
                            ? <Skeleton containerClassName="col-start-2" />
                            : <CurrencyInput
                                className="col-start-2"
                                value={policy?.benefitValue}
                                decimalScale={false}
                                fixedDecimalScale={false}
                                onBlur={(value) => updatePolicy(policy.policyId, "benefitValue", value)}
                            />}
                    </GridWrapper>}
                {!productRenderOptions.isEmployeeBenefit
                    && <>
                        <GridWrapper
                            className="col-12 col-md-7 col-lg-4 mt-2"
                            gridTemplateColumns={`auto repeat(2, 1fr)`}
                        >
                            <FormLabel className="col-start-1 my-auto">
                                Premium:
                            </FormLabel>
                            {isLoading
                                ? <Skeleton containerClassName="col-start-2" />
                                : <CurrencyInput
                                    className="col-start-2"
                                    value={policy?.premium}
                                    onBlur={(value) => updatePolicy(policy.policyId, "premium", value)}
                                />}
                            {isLoading
                                ? <Skeleton containerClassName="col-start-3" />
                                : <span className="col-start-3">
                                    <FormSelect
                                        options={["Monthly", "Quarterly", "Annual", "Biannual"].map((frequency, index) => ({ label: frequency, value: index }))}
                                        defaultValue={policy?.premiumFrequency}
                                        onBlur={({ value }) => updatePolicy(policy.policyId, "premiumFrequency", value)}
                                    />
                                </span>}
                        </GridWrapper>
                        {productRenderOptions.isTermRelevant && <GridWrapper
                            className="col-6 col-lg-3 mt-2"
                            gridTemplateColumns={`auto 1fr`}
                        >
                            <FormLabel className="col-start-1 my-auto">
                                Termination Date:
                            </FormLabel>
                            {isLoading
                                ? <Skeleton containerClassName="col-start-2" />
                                : <span className="col-start-2">
                                    <DateInput
                                        value={policy.term}
                                        isClearable
                                        onBlur={(value) => updatePolicy(policy.policyId, "term", value)}
                                    />
                                </span>}
                        </GridWrapper>}
                        {productRenderOptions.isRenewalRelevant && <GridWrapper
                            className="col-6 col-lg-3 mt-2"
                            gridTemplateColumns={`auto 1fr`}
                        >
                            <FormLabel className="col-start-1 my-auto">
                                Renewal Date:
                            </FormLabel>
                            {isLoading
                                ? <Skeleton containerClassName="col-start-2" />
                                : <span className="col-start-2">
                                    <DateInput
                                        value={policy.renewal}
                                        isClearable
                                        onBlur={(value) => updatePolicy(policy.policyId, "renewal", value)}
                                    />
                                </span>}
                        </GridWrapper>}
                        {productRenderOptions.isTrustRelevant && <GridWrapper
                            className="col-6 col-lg-2 mt-2"
                            gridTemplateColumns={`auto 1fr`}
                        >
                            <FormLabel className="col-start-1 my-auto">
                                Trust:
                            </FormLabel>
                            {isLoading
                                ? <Skeleton containerClassName="col-start-2" />
                                : <BooleanSelect className="col-start-2"
                                    defaultValue={policy.isTrust}
                                    onBlur={({ value }) => updatePolicy(policy.policyId, "isTrust", value)}
                                />
                            }
                        </GridWrapper>}
                    </>}
                <RichTextEditor
                    className={"mt-2"}
                    defaultValue={policy.notes}
                    onBlur={(value) => updatePolicy(policy.policyId, "notes", value)}
                />
            </div>}
        {showBorder && <hr />}
    </React.Fragment>;
};

export default PolicyCover;
import { createContext, useContext, useEffect, useState } from "react";
import {
    useLazyFetchAssetProvidersForGridQuery,
    usePatchAssetProviderMutation,
    useCreateAssetProviderMutation,
    useDeleteAssetProviderMutation
} from "../../../services/assets";
import { patchReplace } from "../../../helpers/patchDoc";
import { useFilterContext } from "../../../hooks/FilterContext";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

const ProviderManagementContext = createContext();

export const useProviderManagement = () => useContext(ProviderManagementContext);

const ProviderManagementProvider = ({ children }) => {

    const limit = 50;
    const [sort, setSort] = useState("ProviderName+ASC");
    const [sortProperty, sortDirection] = sort.split("+");
    const { filter } = useFilterContext();
    const [providerIdToDelete, setProviderIdToDelete] = useState(null);
    const [providerIdToEdit, setProviderIdToEdit] = useState(null);
    const [createProviderTrigger, createResult] = useCreateAssetProviderMutation();
    const [deleteProviderTrigger, deleteResult] = useDeleteAssetProviderMutation();
    const [fetchProvidersForGridTrigger, fetchResult] = useLazyFetchAssetProvidersForGridQuery();
    const [patchProviderTrigger] = usePatchAssetProviderMutation();
    const { data, error, isError, isUninitialized } = fetchResult;
    const { results, next, totalCount } = data || { results: [], next: null, totalCount: limit };

    console.log({ results, next, totalCount });

    const clear = () => fetchProvidersForGridTrigger({
        next: null,
        limit,
        sort,
        filter
    });

    const createProvider = () => {
        return new Promise((resolve, reject) => {
            return createProviderTrigger({}, { sort })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    const deleteProvider = () => {
        return new Promise((resolve, reject) => {
            return deleteProviderTrigger({ providerId: providerIdToDelete })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => fetchProvidersForGridTrigger({
        next,
        limit,
        sort,
        filter
    });

    const patchProvider = (property, newValue) => {
        return new Promise((resolve, reject) => {
            let operations = [patchReplace(property, newValue)];

            return patchProviderTrigger({ providerId: providerIdToEdit, operations })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const refetch = (e) => {
        if (typeof e?.preventDefault === "function") {
            e.preventDefault();
        }

        clear();
    }

    const onSortChange = (property) => {
        if (sortProperty === property) {
            setSort(`${property}+${sortDirection === "ASC" ? "DESC" : "ASC"}`);
            return;
        }

        setSort(`${property}+DESC`);
    };

    const sortableHeaderRenderer = ({ label, property, key }) => {
        return <StandardTableSortableHeader
            key={key}
            onClick={() => onSortChange(property)}
            active={sortProperty === property}
            direction={sortDirection}
        >
            {label}
        </StandardTableSortableHeader>
    };

    const columns = [
        {
            property: "ProviderName",
            label: "Provider Name",
            renderer: sortableHeaderRenderer
        },
        {
            property: "FundManagerCode",
            label: "Fund Manager Code",
            renderer: sortableHeaderRenderer
        },
        {
            property: "CustodianCode",
            label: "Custodian Code",
            renderer: sortableHeaderRenderer
        },
        {
            label: ""
        }
    ];

    useEffect(() => {
        !isUninitialized && clear();
    }, [filter, sort]);

    return <ProviderManagementContext.Provider value={{
        columns,
        createResult,
        deleteResult,
        error,
        isError,
        providerToDelete: results.find(x => x.providerId === providerIdToDelete) ?? null,
        providerToEdit: results.find(x => x.providerId === providerIdToEdit) ?? null,
        results,
        totalCount,
        sort,
        isItemLoaded,
        loadMoreItems,
        createProvider,
        deleteProvider,
        patchProvider,
        refetch,
        setProviderIdToDelete,
        setProviderIdToEdit
    }}>
        {children}
    </ProviderManagementContext.Provider>;
};

export default ProviderManagementProvider;
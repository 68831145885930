import { FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import ProviderManagementProvider, { useProviderManagement } from "./hooks/ProviderManagementContext";
import { Button } from "../../components";
import FilterProvidersModal from "./components/FilterProvidersModal";
import { ConfirmModal } from "../../components/modals";
import EditProviderModal from "./components/EditProviderModal";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useFilterContext } from "../../hooks/FilterContext";
import ProvidersGrid from "./components/ProvidersGrid";


const ProvidersHeader = () => {
    const [isFiltersShown, setIsFiltersShown] = useState(false);
    const {
        createProvider,
        createResult,
        deleteProvider,
        providerToDelete,
        setProviderIdToDelete
    } = useProviderManagement();

    const {
        clearFilter,
        isFiltersApplied,
    } = useFilterContext();

    const onCreate = (e) => {
        e.preventDefault();

        toast.promise(createProvider(), {
            loading: "Creating provider...",
            error: "Unable to create provider.",
            success: "Provider created!"
        });
    };

    const onDelete = () => {
        toast.promise(deleteProvider(), {
            loading: "Deleting provider...",
            error: "Unable to delete provider.",
            success: () => {
                setProviderIdToDelete(null);
                return "Provider deleted!";
            }
        });
    };

    return (
        <>
            <PageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                </FiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex flex-fill flex-row-reverse">
                        <Button className="ms-3" onClick={() => setIsFiltersShown(true)}>
                            Filter Providers
                        </Button>

                        <Button
                            onClick={onCreate}
                            disabled={createResult.isLoading}
                            variant="success"
                            className="d-flex align-items-center"
                        >
                            <ThemedIcon variant="light" className="me-1" icon="plus" />
                            Create New Provider
                        </Button>
                    </div>
                </div>
            </PageSubHeader>
            <FilterProvidersModal
                show={isFiltersShown}
                onHide={() => setIsFiltersShown(null)}
            />
            <ConfirmModal
                title={"Delete Provider"}
                message={`Are you sure you want to delete ${providerToDelete?.providerName ?? "this provider"}?`}
                handleClose={() => setProviderIdToDelete(null)}
                handleConfirm={onDelete}
                confirmButtonLabel="Delete"
                confirmButtonVariant="danger"
                show={providerToDelete !== null}
            />
            <EditProviderModal />
        </>
    )
};

const ProvidersManagement = () => (
    <ProviderManagementProvider>
        <Main className="d-flex flex-column">
            <ProvidersHeader />
            <section className="px-4 my-3 h-100">
                <ProvidersGrid />
            </section>
        </Main>
    </ProviderManagementProvider>
);

export default ProvidersManagement;
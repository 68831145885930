import React, { useMemo, useState } from "react";
import { Button } from "../../components";
import AssetSearchPagedSelect from "../../components/forms/selects/AssetSearchPagedSelect";
import { Main, PageSubHeader, ThemedIcon, ThemedSpan } from "../../components/utilities";
import AssetPricesTable from "./components/AssetPricesTable";
import CreatePriceModal from "./components/CreatePriceModal";
import EditPriceModal from "./components/EditPriceModal";
import PricesManagementProvider, { usePricesManagement } from "./hooks/PricesManagementContext";

const PricesManagementHeader = ({ }) => {
    const { selectedAsset, setSelectedAsset, createResult } = usePricesManagement();
    const [showFilters, setShowFilters] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const { data: asset } = selectedAsset || {};

    const disabled = useMemo(() => !selectedAsset || createResult.isLoading,
        [createResult.isLoading, selectedAsset]);
    
    return (
        <>
            <PageSubHeader className="d-flex justify-content-between">
                <ThemedSpan className="h5">{asset?.longAssetName}</ThemedSpan>
                <div className="d-flex w-25 ms-auto">
                    {/* {isFiltersApplied && (
                    <React.Fragment>
                        <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                        <FiltersAppliedWarningMessage className="ms-2 text-muted">
                            Warning - Filters are currently being applied to your results.
                            <br />
                            <a href="#" onClick={resetFilters}>Click here</a> to clear your filters.
                        </FiltersAppliedWarningMessage>
                    </React.Fragment>
                )} */}
                    <div className="flex-grow-1 me-3">
                        <AssetSearchPagedSelect
                            placeholder="Select Asset..."
                            valueObject={selectedAsset}
                            setValue={(value) => setSelectedAsset(value)}
                            disableAnimations
                            isClearable
                        />
                    </div>
                    <div className="flex-shrink-1">
                        <Button variant="success" disabled={disabled} onClick={() => setShowCreate(true)}>
                            <ThemedIcon icon="plus" variant="light" className="me-2" />
                            <span>Create New Price</span>
                        </Button>
                    </div>
                </div>
            </PageSubHeader>
            <CreatePriceModal
                show={showCreate}
                onHide={() => setShowCreate(false)}
            />
            <EditPriceModal/>
        </>
    );
};

export const PricesManagement = () => {

    return (
        <PricesManagementProvider>
            <Main className="d-flex flex-column">
                <PricesManagementHeader />
                <section className="px-4 my-3 h-100">
                    <AssetPricesTable />
                </section>
            </Main>
        </PricesManagementProvider>
    );
};



export default PricesManagement;
import { wealthProApi } from "../../redux/api";
import { socket } from "../../redux/socket";

export const queryTypes = {
    CLIENT: 10,
    STAFF: 20,
    REPORT: 30,
    ACTION: 40
};

const userNotificationsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['Notifications']
}).injectEndpoints({
    endpoints: (builder) => ({
        countUserNotifications: builder.query({
            query: () => `api/messaging/notifications/count`,
            onCacheEntryAdded: async (userId, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                try {
                    socket.emit('subscribe-notifications', { room: userId.replace(/-/g, '')+"_notifications" });
                    await cacheDataLoaded;

                    socket.on("notification", notificationJson => {
                        updateCachedData(data => data + 1);
                    });
                }
                catch (ex) {
                    console.log(ex);
                }

                await cacheEntryRemoved;
                socket.emit('unsubscribe-notifications', { room: userId.replace(/-/g, '')+"_notifications"});
                socket.off('notification');
            },
            providesTags: [{ id: "COUNT", type: "Notifications" }]
        }),
        fetchUserNotifications: builder.query({
            query: ({ page, queryType }) => `api/messaging/notifications?page=${page}&limit=5&queryType=${queryType}`,
            serializeQueryArgs: ({ endpointName, queryArgs: { queryType } }) => {
                return `${endpointName}-${queryType}`;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },
        }),
        readNotification: builder.mutation({
            query: ({ notificationId, isDone, noFurtherActionRequired, page, queryType }) => ({
                url: `api/messaging/notifications/${notificationId}/read`,
                method: 'POST',
                body: {
                    isDone,
                    noFurtherActionRequired,
                    currentPage: page,
                    queryType,
                    currentLimit: 5
                }
            }),
            async onQueryStarted({ notificationId, page, queryType }, { dispatch, queryFulfilled }) {
                try {
                    // the next notification is returned via the read message request
                    var { data: nextNotification } = await queryFulfilled;


                    dispatch(wealthProApi.util.updateQueryData("fetchUserNotifications", { page, queryType }, ({ results, pagination }) => {
                        return {
                            pagination: {
                                ...pagination,
                                totalCount: pagination.totalCount - 1
                            },
                            results: nextNotification
                                ? [...results.filter(x => x.messageId !== notificationId), nextNotification]
                                : results.filter(x => x.messageId !== notificationId)
                        };
                    }));


                }
                catch { }
            }
        })
    })
});

export const {
    useFetchUserNotificationsQuery,
    useReadNotificationMutation,
    useCountUserNotificationsQuery
} = userNotificationsApiEndpoints;
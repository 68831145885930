import React from "react";
import toast from "react-hot-toast";
import Moment from "react-moment";
import FavouriteSection from "./FavouriteSection";
import useFetchRecentReports from "../hooks/useFetchRecentReports";
import { ActionIcon } from "../../../components/utilities";
import useOpenDocument from "../../../hooks/useOpenDocument";

export const RecentReportsListItem = ({ account, onToggle, ...rest }) => {
    const { documentId, masterAccountReference, masterAccountName, reportName, lastOpenedOnDate } = account;
    const { openDocument, isFetching: isOpening } = useOpenDocument();

    const onReportOpenClicked = () => {
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }

        if (!documentId || isOpening)
            return;

        openDocument(documentId);
    };

    return (
        <div className="row">
            <div className="col-md-auto">
                <ActionIcon icon="fa-folder-open" onClick={onReportOpenClicked} />
            </div>
            <div className="col-md-auto font-monospace">{masterAccountReference}</div>
            <div className="col-3">{masterAccountName}</div>
            <div className="col">{reportName}</div>
            <div className="col-md-auto">
                <Moment fromNow utc>{lastOpenedOnDate}</Moment>
            </div>
        </div>
    )
}

const RecentReportsList = (props) => <FavouriteSection label="Recent Reports" hook={useFetchRecentReports} RecordItem={RecentReportsListItem} {...props} />

export default RecentReportsList;
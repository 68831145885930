import React from "react";
import FormSelect from "../FormSelect";
import ProfileIcon from "../../ProfileIcon";
import { components } from "react-select";
import { useFetchAvailableAuthorsListQuery } from "../../../services/clientreports";

const ReportAuthorSelectOption = (props) => {
    const { data: { data }, label } = props;
    if (!data) {
        return (
            <components.Option {...props}><span>{label}</span></components.Option>
        )
    }

    const { currentReportLoad, isOnline } = data;
    return (
        <components.Option {...props}>
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center justify-center">
                    <ProfileIcon user={data} isOnline={isOnline} showOnlineStatusIndicator={true} width={24} height={24} />
                </div>
                <p className="mx-3 my-0 lh-1">
                    <span>{label}</span>
                    <br />
                    <span className="text-muted" style={{ fontSize: '.7rem' }}>Current Workload: {currentReportLoad}</span>
                </p>
            </div>
        </components.Option>
    );
};

const ReportAuthorSelectSingleValue = (props) => {
    const { data: { data, label } } = props;
    if (!data) {
        return (
            <components.SingleValue {...props}><span>{label}</span></components.SingleValue>
        )
    }

    const { isOnline } = data;
    return (
        <components.SingleValue {...props}>
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center justify-center">
                    <ProfileIcon user={data} isOnline={isOnline} showOnlineStatusIndicator={true} width={24} height={24} />
                </div>
                <p className="mx-3 my-0 lh-1">
                    <span>{label}</span>
                </p>
            </div>
        </components.SingleValue>
    );
};

const ReportAuthorSelect = ({ options, components, ...rest }) => {
    const { data, isLoading, isFetching } = useFetchAvailableAuthorsListQuery({ });
    const { results } = data || { results: [] };
    
    return <FormSelect
        options={results}
        isLoadingOptions={isLoading || isFetching}
        components={{
            ...components,
            Option: ReportAuthorSelectOption,
            SingleValue: ReportAuthorSelectSingleValue
        }}
        {...rest}
    />
};

export default ReportAuthorSelect;
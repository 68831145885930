import { useEffect, useState } from "react";
import { useLazyFetchMasterAccountDocumentsQuery } from "../../../services/clientdocuments";

const useProjectDocumentsList = (masterAccountId, projectId) => {
    const [cacheKey, setCacheKey] = useState(new Date().valueOf());
    const [pageLimit, setPageLimit] = useState(50);
    const [load, { data, ...queryResults }] = useLazyFetchMasterAccountDocumentsQuery();
    const { results, pagination: { page, totalCount, totalPages } } = data || { results: [], pagination: { page: 1, totalCount: 0, totalPages: 0 } }
    const hasMore = page < totalPages;

    const clear = () => {
        if (!masterAccountId) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            projectId,
            page: 1,
            limit: pageLimit,
            ignoreReports: true
        }).unwrap().then(_ => setCacheKey(newCacheKey))
    };

    const nextPage = () => {
        if (page >= totalPages) {
            return new Promise((resolve) => resolve(false));
        }
        return load({
            cacheKey,
            masterAccountId,
            projectId,
            page: page + 1,
            limit: pageLimit
        });
    };

    const previousPage = () => {
        if (page <= 1) {
            return new Promise((resolve) => resolve(false));
        }
        return load({
            cacheKey,
            masterAccountId,
            projectId,
            page: page - 1,
            limit: pageLimit
        });
    };

    useEffect(clear, [ masterAccountId ]);

    useEffect(clear, [ projectId ]);

    return {
        clear,  
        data: results,
        hasMore,
        masterAccountId,
        nextPage,
        page,
        pageLimit,
        previousPage,
        setPageLimit,
        totalCount,
        totalPages,
        ...queryResults
    };
};

export default useProjectDocumentsList;
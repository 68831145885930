import { useFetchHeadOfficeUnmatchedPlatformAccountsQuery } from "../../../services/headofficeadmin";
import FormSelect from "../FormSelect";

const HeadOfficeUnmatchedPlatformAccountsSelect = ({
    defaultValue,
    label,
    onBlur,
    onChange,
    ...rest
}) => {

    const { data, isLoading } = useFetchHeadOfficeUnmatchedPlatformAccountsQuery();

    return <FormSelect
        defaultValue={defaultValue}
        isLoadingOptions={isLoading}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        options={data}
        {...rest}
    />;
};

export default HeadOfficeUnmatchedPlatformAccountsSelect;
import React from "react";
import { useAuth } from "react-oidc-context";

const NotAuthenticatedPage = () => {
    const auth = useAuth();
    const { signinRedirect } = auth;

    const onSignInReAttempt = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        signinRedirect();
    }

    return (
        <div className="vh-100 d-flex flex-column flex-fill justify-content-center align-items-center">
            <p className="m-0 text-center">
                <span className="fs-3">Authentication Failed!</span>
                <br />
                <span className="fs-5 text-muted">
                    We were unable to authenticate you. Either your session has expired or your login attempt(s) failed. 
                    <br/>
                    You can try again by clicking <a href="#" onClick={onSignInReAttempt} >here</a>.
                </span>
            </p>
        </div>
    );
};

export default NotAuthenticatedPage;
import React from "react";
import styled from "styled-components";
import { IconButton } from "../../../components/buttons";

const StyledWrapper = styled.div``;

const StyledNameBadge = styled.div``;

const ConnectionListHeader = ({ connection }) => {
    const getDisplayName = () => {
        return `${connection.forenames} ${connection.surname}`;
    };
    
    return <StyledWrapper>
        <StyledNameBadge>{getDisplayName()}</StyledNameBadge>
    </StyledWrapper>
};

export default ConnectionListHeader;
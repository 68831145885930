import React from "react";
import { components } from "react-select";

export const EmailTaxPackRecipientSelectMultiValueLabel = (props) => {
    const { data: { data, label } } = props;
    const { emailAddress } = data;
    return (
        <components.MultiValueLabel {...props}>
            <div>
                {`${label}; ${emailAddress ?? "Email Address not available"}`}
            </div>
        </components.MultiValueLabel>
    );
};

const EmailTaxPackRecipientSelectOption = (props) => {
    const { data: { data, label } } = props;
    const { emailAddress, position, relationshipDescription } = data;
    return (
        <components.Option {...props}>
            <div>
                <p className="m-0">
                    <span>{label}</span>, <span>{position && position.length > 0 ? position : relationshipDescription}</span>
                    <br />
                    <span className="fs-smaller text-muted">{emailAddress ?? "Email Address not available."}</span>
                </p>
            </div>
        </components.Option>
    );
};

export default EmailTaxPackRecipientSelectOption;
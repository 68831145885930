import { wealthProApi } from '../../redux/api';

const investmentOperationApiEndpoints = wealthProApi.enhanceEndpoints({})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchTransactionsInvestmentOperations: builder.query({
            query: (listType) => `api/valuations/definvestmentoperations${listType ? `?listType=${listType}` : ""}`,
        })
    })
});

export const {
    useFetchTransactionsInvestmentOperationsQuery,
} = investmentOperationApiEndpoints;
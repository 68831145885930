import { useFetchDelegatedUsersQuery } from "../../../services/identity";
import FormSelect from "../FormSelect";
import { GenericUserSelectOption, GenericUserSelectSingleValue } from "./GenericUserSelect";

// TODO - Replace with query to Identity 

const UserDelegatesSelect = ({ accessType, onBlur, onChange, defaultValue, isDisabled, ...rest }) => {

    const { data, isLoading } = useFetchDelegatedUsersQuery({ accessType });

    return <FormSelect
        components={{
            Option: GenericUserSelectOption,
            SingleValue: GenericUserSelectSingleValue
        }}
        options={data || []}
        isDisabled={isDisabled === true || isLoading === true}
        isLoadingOptions={isLoading}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        {...rest}
    />
};

export default UserDelegatesSelect;
import React from "react";
import FormSelect from "../FormSelect";

export const FeedbackRatings = [
    { label: 'Excellent', value: 5 },
    { label: 'Very Good', value: 4 },
    { label: 'Good', value: 3 },
    { label: 'Poor', value: 2 },
    { label: 'Very Poor', value: 1 },
    { label: 'Unrated', value: 0 }
];

const FeedbackRatingSelect = ({ options, ...rest }) => <FormSelect options={FeedbackRatings} {...rest} />

export default FeedbackRatingSelect;
import { useCreateDelegatedAccessMutation } from "../../../services/identity";

const useCreateUserDelegate = () => {
    const [_create, results] = useCreateDelegatedAccessMutation();
    const defaultValues = { 
        userId: null,
        delegatedUserId: null,
        readCalendar: false,
        writeCalendar: false,
        readMail: false,
        writeMail: false,
        sendMail: false,
        readMessages: false,
        writeMessages: false,
        readReminders: false,
        writeReminders: false,
        hasClientAccess: false
    };

    const create = (data) => {
        return new Promise((resolve, reject) => {
            return _create(data).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        ...results,
        create,
        defaultValues
    };
};

export default useCreateUserDelegate;
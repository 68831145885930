import styled from "styled-components";
import withProviders from "../../hooks/withProviders";
import ClientEmailsProvider, { useClientEmails } from "./hooks/ClientEmailsContext";
import { Outlet } from "react-router-dom";
import { Main } from "../../components/utilities";
import ClientEmailPreviewList from "./components/ClientEmailPreviewList";
import ClientEmailPreviewListHeader from "./components/ClientEmailPreviewListHeader";
import ClientEmailHeader from "./components/ClientEmailHeader";
import ClientEmailConversationPreviewListHeader from "./components/ClientEmailConversationPreviewListHeader";
import ClientEmailConversationPreviewsList from "./components/ClientEmailConversationPreviewsList";
import ClientEmailConversationMessagesList from "./components/ClientEmailConversationMessagesList";
import ClientEmail from "./components/ClientEmail";
import { darken } from "polished";

const EmailGridLayout = styled(Main)`
    display: flex;
    flex-wrap: wrap;
    display: grid;
    min-height: 100%;
    grid-template-rows:  1fr 9fr;
    grid-template-columns: minmax(auto, 350px) 4fr;
    grid-template-areas: "previews-header header  " 
                         "previews email";
    row-gap: 0;
`;

const ConversationsGridLayout = styled(Main)`
    display: flex;
    flex-wrap: wrap;
    display: grid;
    min-height: 100%;
    grid-template-rows:  1fr 9fr;
    grid-template-columns: minmax(auto, 350px) 4fr minmax(auto, 350px) ;
    grid-template-areas: "previews-header header conversation-previews-header" 
                         "previews email conversation-previews";
    row-gap: 0;
`;

const GridArea = styled.div`
    grid-area: ${props => props.gridArea};
    margin: 0;
`;

const EmailHeaderArea = styled.div`
    grid-area: header;
    background-color: ${props => darken(0.07, props.theme.light)};
    border-bottom: 1px solid ${props => darken(0.15, props.theme.light)};
`;

const EmailContentArea = styled.div`
    grid-area: email;
    background-color: white;
`;

const ClientEmailsLayout = () => {
    const { mailId } = useClientEmails();

    return (
        <EmailGridLayout>
            <GridArea gridArea="previews-header">
                <ClientEmailPreviewListHeader />
            </GridArea>
            <EmailHeaderArea className="p-1 mb-3">
                <ClientEmailHeader />
            </EmailHeaderArea>
            <GridArea className="border" gridArea="previews">
                <ClientEmailPreviewList />
            </GridArea>
            <EmailContentArea>
                {mailId && <ClientEmail />}
            </EmailContentArea>
        </EmailGridLayout>
    );
};

const ClientEmailConversationsLayout = () => {
    const { mailId } = useClientEmails();

    return (
        <ConversationsGridLayout>
            <GridArea gridArea="conversation-previews-header">
                <ClientEmailConversationPreviewListHeader />
            </GridArea>
            <GridArea gridArea="previews-header">
                <ClientEmailPreviewListHeader />
            </GridArea>
            <EmailHeaderArea className="p-1 mb-3">
                <ClientEmailHeader />
            </EmailHeaderArea>
            <GridArea className="border" gridArea="conversation-previews">
                <ClientEmailConversationPreviewsList />
            </GridArea>
            <GridArea className="border" gridArea="previews">
                <ClientEmailConversationMessagesList />
            </GridArea>
            <EmailContentArea>
                {mailId && <ClientEmail />}
            </EmailContentArea>
        </ConversationsGridLayout>
    );
};

const ClientEmails = () => {

    const { showConversations } = useClientEmails();

    return !showConversations ? <ClientEmailsLayout /> : <ClientEmailConversationsLayout />;
};

export default withProviders(ClientEmailsProvider)(ClientEmails);
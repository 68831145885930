import React from "react";
import ClientRoutes from "./client.routes";
import AdministrationRoutes from "./administration.routes";
import InvestmentCommitteeRoutes from "./investment-committee.routes";
import ManagementRoutes from "./management.routes";
import PersonalRoutes from "./personal.routes";
import SettingsRoutes from "./settings.routes";
import { MainLayout } from "../layouts";
import { NotAuthorisedPage, NotFoundPage } from "../pages";
import { NavigationRouteGuard } from "../components/navigation";
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

export const RouterMap = [
    {
        path: "",
        defaultRoute: "/personal/messages",
        element: MainLayout,
        children: [
            ClientRoutes,
            ...PersonalRoutes,
            ...ManagementRoutes,
            ...InvestmentCommitteeRoutes,
            ...AdministrationRoutes,
            ...SettingsRoutes
        ]
    },
    { path: '/unauthorised', element: NotAuthorisedPage },
    { path: '/404', element: NotFoundPage },
];

// TODO: I think this can be simplified but it's working and will reduce the bulk of the App.js file
const Router = createBrowserRouter(
    createRoutesFromElements(
        <React.Fragment>
            {RouterMap.map(({ element: ParentElement, ...parent }) => (
                <Route key={parent.path} path={parent.path} element={
                    <NavigationRouteGuard requiredRoles={parent.roles ?? []}>
                        {ParentElement ? (<ParentElement />) : (<Outlet />)}
                    </NavigationRouteGuard>
                }>
                    {parent.children && parent.children.map(({ element: ChildElement, ...child }) => (
                        <Route key={`${parent.path}/${child.path}`} path={child.path} element={
                            <NavigationRouteGuard requiredRoles={child.roles ?? []}>
                                {ChildElement ? (<ChildElement />) : (<Outlet />)}
                            </NavigationRouteGuard>
                        }>
                            {child.children && child.children.map(({ element: SubChildElement, ...subChild }) => (
                                <Route key={`${parent.path}/${child.path}/${subChild.path}`} path={subChild.path} element={
                                    <NavigationRouteGuard requiredRoles={subChild.roles ?? []}>
                                        {SubChildElement ? (<SubChildElement />) : (<Outlet />)}
                                    </NavigationRouteGuard>
                                }>
                                    {subChild.children && subChild.children.map(({ element: SubSubChildElement, ...subSubChild }) => (
                                        <Route key={`${parent.path}/${child.path}/${subChild.path}/${subSubChild.path}`} path={subSubChild.path} element={
                                            <NavigationRouteGuard requiredRoles={subSubChild.roles ?? []}>
                                                {SubSubChildElement ? (<SubSubChildElement />) : (<Outlet />)}
                                            </NavigationRouteGuard>
                                        }>
                                            {subSubChild.children && subSubChild.children.map(({ element: FloorChildElement, ...floorChild }) => (
                                                <Route key={`${parent.path}/${child.path}/${subChild.path}/${subSubChild.path}/${floorChild.path}`} path={floorChild.path} element={
                                                    <NavigationRouteGuard requiredRoles={floorChild.roles ?? []}>
                                                        {FloorChildElement ? (<FloorChildElement />) : (<Outlet />)}
                                                    </NavigationRouteGuard>
                                                }>
                                                </Route>
                                            ))}
                                            {subSubChild.children && subSubChild.defaultRoute && <Route path="*" element={<Navigate to={subSubChild.defaultRoute} replace />} />}
                                        </Route>
                                    ))}
                                    {subChild.children && subChild.defaultRoute && <Route path="*" element={<Navigate to={subChild.defaultRoute} replace />} />}
                                </Route>
                            ))}
                            {child.children && child.defaultRoute && <Route path="*" element={<Navigate to={child.defaultRoute} replace />} />}
                        </Route>
                    ))}
                    {parent.children && parent.defaultRoute && <Route path="*" element={<Navigate to={parent.defaultRoute} replace />} />}
                </Route>
            ))}
            <Route index element={<Navigate to={RouterMap[0].defaultRoute} replace />} />
        </React.Fragment>
    )
);

export default Router;
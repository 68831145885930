import { wealthProApi } from '../../redux/api';

const masterAccountEmailEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["EmailPreviews"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchMasterAccountEmailPreviews: builder.query({
            query: ({ masterAccountId, ...rest }) => ({
                url: `api/clientdocuments/masteraccounts/${masterAccountId}/emails/search`,
                method: 'POST',
                body: rest
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },
            providesTags: ["EmailPreviews"]
        }),
        fetchMasterAccountEmailConversationPreviews: builder.query({
            query: ({ masterAccountId, ...rest }) => ({
                url: `api/clientdocuments/masteraccounts/${masterAccountId}/emails/conversations/search`,
                method: 'POST',
                body: rest
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },
            providesTags: ["EmailPreviews"]
        }),
        fetchMasterAccountEmailConversationMessagePreviews: builder.query({
            query: ({ masterAccountId, conversationId, ...rest }) => ({
                url: `api/clientdocuments/masteraccounts/${masterAccountId}/emails/conversations/${conversationId}/messages`,
                method: 'POST',
                body: rest
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },
            providesTags: ["EmailPreviews"]
        }),
        patchMasterAccountEmailConversationSubject: builder.mutation({
            query: ({ masterAccountId, conversationId, subject }) => ({
                url: `api/clientdocuments/masteraccounts/${masterAccountId}/emails/conversations/${conversationId}/subject`,
                method: 'PATCH',
                body: { subject }
            }),
            async onQueryStarted({ masterAccountId, conversationId, subject }, { dispatch, queryFulfilled }) {
                try {
                    const updateSubjectResult = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountEmailConversationPreviews', masterAccountId, (data) => {
                        const conversation = data.results.find((conversation) => conversation.conversationId === conversationId);
                        conversation.customSubject = subject;
                    }));

                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.error(error);
                        updateSubjectResult.undo();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }),
        deleteMasterAccountEmailConversationSubject: builder.mutation({
            query: ({ masterAccountId, conversationId }) => ({
                url: `api/clientdocuments/masteraccounts/${masterAccountId}/emails/conversations/${conversationId}/subject`,
                method: 'DELETE'
            }),
            async onQueryStarted({ masterAccountId, conversationId }, { dispatch, queryFulfilled }) {
                try {
                    const resetSubjectResult = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountEmailConversationPreviews', masterAccountId, (data) => {
                        const conversation = data.results.find((conversation) => conversation.conversationId === conversationId);
                        conversation.customSubject = null;
                    }));

                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.error(error);
                        resetSubjectResult.undo();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        })
    })
});

export const {
    useLazyFetchMasterAccountEmailPreviewsQuery,
    useLazyFetchMasterAccountEmailConversationPreviewsQuery,
    useLazyFetchMasterAccountEmailConversationMessagePreviewsQuery,
    usePatchMasterAccountEmailConversationSubjectMutation,
    useDeleteMasterAccountEmailConversationSubjectMutation
} = masterAccountEmailEndpoints;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const hoAdminInstructionCashSourcesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["adminInstructionCash"]
}).injectEndpoints({
    endpoints: (builder) => ({
        patchAdminInstructionCashSource: builder.mutation({
            query: ({ sourceId, operations }) => ({
                url: `api/headofficeadmin/admininstructioncashsources/${sourceId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ instructionId, sourceId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: viewModel } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('getAdminInstructionCashById', { instructionId }, data => {
                        var item = data.cashSources.find(x => x.sourceId === sourceId);
                        Object.assign(item, viewModel);
                        data.cashFromTaxReclaimAmount = (data.cashFromClientAmount + data.cashSources.reduce((partialSum, x) => partialSum + x.cashAmount, 0)) * 0.2;
                    }));
                }
                catch { }
            }
        })
    })
});

export const {
    usePatchAdminInstructionCashSourceMutation
} = hoAdminInstructionCashSourcesApiEndpoints;
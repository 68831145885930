import React, { useState } from "react";
import Button from "../../../../../components/buttons";
import { Modal } from "react-bootstrap";
import {
    DateInput,
    FormCheck
} from "../../../../../components/forms/";
import { components } from "react-select";
import { 
    GenericAdviserSelect,
    GenericUserSelect,
    ReminderTypesSelect,
    ReminderStatusesSelect
} from "../../../../../components/forms/selects";

const ContactReportModal = ({
    confirmButtonLabel = "Run Report",
    confirmButtonVariant = "success",
    handleContactReportArgsClose,
    handleContactReportArgsConfirm,
    contactReportArgsShow,
    ...rest
}) => {

    const [theFromDate, setTheFromDate] = useState(null);
    const [theToDate, setTheToDate] = useState(null);
    const [theAdviserIds, setTheAdviserIds] = useState([]);
    const [theUserIds, setTheUserIds] = useState([]);
    const [theReminderTypeIds, setTheReminderTypeIds] = useState([]);
    const [theReminderStatuses, setTheReminderStatuses] = useState([]);
    const [groupByAdviser, setGroupByAdviser] = useState(true);
    const [groupByUser, setGroupByUser] = useState(false);
    const [theGroupBy, setTheGroupBy] = useState("adviser");
    const [errorMessage, setErrorMessage] = useState(null);

    
    const updateGroupedBy = (groupBy, value) => {

        switch(groupBy) {
            case "user": {
                //value === true ? (setGroupByUser(true), setGroupByAdviser(false)) : (setGroupByUser(false), setGroupByAdviser(true));
                if(value){
                    setGroupByUser(true);
                    setGroupByAdviser(false);
                    setTheGroupBy("user");
                }
                else{
                    setGroupByUser(false);
                    setGroupByAdviser(true);
                    setTheGroupBy("adviser");
                }
                break;
            }
            default: {
                //value === true ? (setGroupByAdviser(true), setGroupByUser(false)) : (setGroupByAdviser(false), setGroupByUser(true));
                if(value){
                    setGroupByAdviser(true);
                    setGroupByUser(false);
                    setTheGroupBy("adviser");
                }
                else{
                    setGroupByAdviser(false);
                    setGroupByUser(true);
                    setTheGroupBy("user");
                }
                break;
            }      
        }

    }
    
    const onClose = () => {
        if (handleContactReportArgsClose && typeof handleContactReportArgsClose === "function") {
            setErrorMessage("");
            setTheFromDate(null);
            setTheToDate(null);
            setTheAdviserIds([]);
            setTheUserIds([]);
            setTheReminderTypeIds([]);
            setTheReminderStatuses([]);
            setGroupByAdviser(true);
            setGroupByUser(false);
            setTheGroupBy("adviser");
            handleContactReportArgsClose();
        }
    }

    const onConfirm = () => {
        if(theFromDate !== null && theToDate !== null){
            if (handleContactReportArgsConfirm && typeof handleContactReportArgsConfirm === "function") {
                const theRequest = {fromDate: theFromDate, toDate: theToDate, adviserIds: theAdviserIds, userIds: theUserIds, reminderTypeIds: theReminderTypeIds, reminderStatuses: theReminderStatuses, groupBy: theGroupBy}
                handleContactReportArgsConfirm(theRequest);
            }
            onClose();
        }
        else{
            setErrorMessage("From Date and To Date must be selected!");
        }
    }

    return (
        <Modal size="med" centered backdrop="static" show={contactReportArgsShow}>
            <Modal.Header>Contact Report</Modal.Header>
            <Modal.Body {...rest}>
            <div className="row mb-3">
                <div className="col">
                        <DateInput
                            label="From"
                            value={theFromDate}
                            onBlur={(value) => setTheFromDate(value)}
                        />
                </div>
                <div className="col">
                        <DateInput
                            label="To"
                            value={theToDate}
                            onBlur={(value) => setTheToDate(value)}
                        />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <GenericAdviserSelect
                        label="Filter Advisers - All Advisers unless selections made"
                        placeholder="Filter Advisers..."
                        defaultValue={theAdviserIds}
                        isMulti={true}
                        onBlur={(values) => setTheAdviserIds(values.map((el) => el.value))}
                        //onBlur={(values) => console.log(values.map((el) => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <GenericUserSelect
                        label="Filter Users - All Users unless selections made"
                        placeholder="Filter Users..."
                        defaultValue={theUserIds}
                        isMulti={true}
                        onBlur={(values) => setTheUserIds(values.map((el) => el.value))}
                        //onBlur={(values) => console.log(values.map((el) => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <ReminderTypesSelect
                        label="Filter Types - All Types unless selections made"
                        placeholder="Filter Types"
                        defaultValue={theReminderTypeIds}
                        isMulti={true}
                        showHidden={true}
                        disableAnimations={true}
                        errorAllowRetry={false}
                        onBlur={(values) => setTheReminderTypeIds(values.map((el) => el.value))}
                        {...rest}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <ReminderStatusesSelect
                        label="Filter Statuses - All Statuses unless selections made"
                        placeholder="Filter Statuses"
                        defaultValue={theReminderStatuses}
                        isMulti={true}
                        disableAnimations={true}
                        errorAllowRetry={false}
                        onBlur={(values) => setTheReminderStatuses(values.map((el) => el.value))}
                    />
                </div>
            </div>
            <div className="col d-flex justify-content-around align-items-center">
                        <FormCheck
                            isChecked={groupByAdviser}
                            label="Group By Adviser"
                            onChange={value => updateGroupedBy("adviser", value)}
                            disableAnimations
                        />
                        <FormCheck
                            isChecked={groupByUser}
                            label="Group By User"
                            onChange={value => updateGroupedBy("user", value)}
                            disableAnimations
                        />
                    </div>
            <div className="row mb-3">
                <div className="text-warning"> {errorMessage} </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmButtonVariant} onClick={onConfirm}>{confirmButtonLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                
            </Modal.Footer>
        </Modal>
    );
};

export default ContactReportModal;
import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";

const messagingApplicationsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["hoMessages"]
}).injectEndpoints({
    endpoints: (builder) => ({
        patchApplicationThread: builder.mutation({
            query: ({ threadId, operations }) => ({
                url: `api/messaging/threads/${threadId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ threadId, applicationId, active, operations }, { dispatch, queryFulfilled }) {
                var result = dispatch(wealthProApi.util.updateQueryData('fetchApplicationMessages', { applicationId, active }, ({ results }) => {
                    results.forEach((item) => {
                        if (item.threadId === threadId) {
                            applyPatch(item, operations, true);
                        }
                    })
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    usePatchApplicationThreadMutation
} = messagingApplicationsApiEndpoints;
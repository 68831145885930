import { wealthProApi } from "../../redux/api";
import { patchReplace } from "../../helpers/patchDoc";
import { applyPatch } from "fast-json-patch";

const investmentCashEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchInvestmentCash: builder.query({
            query: ({ investmentCashId }) => `api/recommendations/InvestmentCash/${investmentCashId}`
        }),
        addInvestmentCash: builder.mutation({
            query: ({ newId, investId }) => ({
                url: `api/recommendations/InvestmentCash/${newId}`,
                method: "PATCH",
                body: [patchReplace("investId", investId)]
            }),
            async onQueryStarted({ newId, investId }, { dispatch, queryFulfilled }) {
                try {
                    let createdIndex;
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchInvestmentCashArray", { investId }, (draft) => {
                        draft.push({
                            investId,
                            taxReclaim: 0,
                            cashAmount: 0
                        });

                        createdIndex = draft.length - 1;
                    }));

                    try {
                        await queryFulfilled
                        dispatch(wealthProApi.util.updateQueryData("fetchInvestmentCashArray", { investId }, (draft) => {
                            draft[createdIndex].id = newId;
                        }));
                    } catch (ex) {
                        console.error(ex, "Error adding investment cash in service")
                        patchResult.undo();
                    }
                } catch (ex) {
                    console.error(ex, "Error adding investment cash in cache");
                }
            }
        }),
        patchInvestmentCash: builder.mutation({
            query: ({ investmentCashId, operations }) => ({
                url: `api/recommendations/InvestmentCash/${investmentCashId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ operations, investId, index }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(
                        wealthProApi.util.updateQueryData("fetchInvestmentCashArray", { investId }, (arr) => {
                            const element = arr[index];

                            applyPatch(element, operations);
                        })
                    )
                    try {
                        await queryFulfilled
                    } catch (ex) {
                        console.error(ex, "Error updating investment cash in service");
                        patchResult.undo();
                    }
                } catch (ex) {
                    console.error(ex, "Error updating investment cash in cache");
                }
            }
        }),
        removeInvestmentCash: builder.mutation({
            query: ({ investmentCashId }) => ({
                url: `api/recommendations/InvestmentCash/${investmentCashId}`,
                method: "PATCH",
                body: [patchReplace("isActive", false)]
            }),
            async onQueryStarted({ investId, indexToRemove }, { dispatch, queryFulfilled }) {
                try {
                    const removeResult = dispatch(
                        wealthProApi.util.updateQueryData("fetchInvestmentCashArray", { investId }, (array) => {
                            array.splice(indexToRemove, 1);
                        })
                    )
                    try {
                        await queryFulfilled
                    } catch (ex) {
                        console.error(ex, "Error deleting investment cash from service")
                        removeResult.undo();
                    }
                } catch (ex) {
                    console.error(ex, "Error deleting investment cash in cache");
                }
            }
        })
    })
})

export const {
    useLazyFetchInvestmentCashQuery,
    useAddInvestmentCashMutation,
    usePatchInvestmentCashMutation,
    useRemoveInvestmentCashMutation
} = investmentCashEndpoints
import React from "react";
import { DateInput, FormTextArea, FormCheck } from "../../../components/forms";
import { ReviewStatusSelect } from "../../../components/forms/selects/";

const ReviewDetails = ({review, onFieldChanged}) => {

    return (
        <div className="row mb-4">
            <div className="col-3">
                <DateInput
                    label="Review Date"
                    value={review.reviewDate}
                    onBlur={(value) => onFieldChanged('reviewDate', value)}
                />
            </div>
            <div className="col-3">
                 <ReviewStatusSelect
                    label="Review Status"
                    defaultValue={review.reviewStatus}
                    onBlur={(selection) => onFieldChanged("reviewStatus", selection.value)}
                />
            </div>
            <div className="col-5">
                <FormTextArea
                    label="Comment"
                    value={review.reviewStatusComment}
                    onBlur={(value) => onFieldChanged('reviewStatusComment', value)}
                />
            </div>
            <div className="col-md-auto d-flex justify-content-center align-items-end">
            <FormCheck
                    label="Delayed"
                    groupClassName={"mx-0"}
                    isChecked={review?.delayed}
                    onChange={(value) => onFieldChanged('delayed', !review.delayed)}
                />
            </div>
        </div>
    )
}

export default ReviewDetails;
import React from "react";
import { ActionIcon, ThemedSpan } from "../../../components/utilities";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import NotificationsSection from "./NotificationsSection";
import useNotifications from "../hooks/useNotifications";
import NotificationOptionsDropdown from "./NotificationOptionsDropdown";
import { queryTypes } from "../../../services/usernotifications";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ClientNotificationsListItem = ({ notification, onToggle, readFn, ...rest }) => {
    const appNavigator = useNavigate();
    const { masterAccountName, accountReference, senderName, sentOnDate, masterAccountId, message } = notification || {};

    const onAccountOpenClicked = () => {
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }
        appNavigator(`/client/${masterAccountId}/messages`)
    };

    return <div className="row" {...rest}>
        <div className="col-md-auto">
            <ActionIcon icon="fa-folder-open" onClick={onAccountOpenClicked} />
        </div>
        <div className="col-md-auto font-monospace">{accountReference}</div>
        <div className="col-3">{masterAccountName}</div>
        <div className="col-2">{senderName}</div>
        <div className="col text-truncate">
            <OverlayTrigger placement="left" overlay={<Tooltip className="position-fixed">
                {message}
            </Tooltip>}>
                <ThemedSpan>{message}</ThemedSpan>
            </OverlayTrigger>
        </div>
        <div className="col-auto text-end">
            <Moment fromNow>{sentOnDate}</Moment>
        </div>
        <div className="col-md-auto">
            <NotificationOptionsDropdown notification={notification} readFn={readFn} />
        </div>
    </div>
};

const ClientNotificationsList = (props) => <NotificationsSection
    label="Client Messages"
    hook={useNotifications}
    queryType={queryTypes.CLIENT}
    RecordItem={ClientNotificationsListItem}
    {...props}
/>;

export default ClientNotificationsList;
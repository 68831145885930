import React from "react";
import useServiceLevelAgreements from "../hooks/useServiceLevelAgreements";
import { VirtualTable } from "../../../components/tables";
import styled from "styled-components";
import {
    StandardTableSortablePropertyHeader,
    ThemedTableHeader,
} from "../../../components/tables/StandardTable";
import SlaAdminTableRow from "./SlaAdminTableRow";
import { useEditSla } from "../hooks/EditSlaContext";
import ServiceLevelAgreementEditModal from "./ServiceLevelAgreementEditModal";

const FixedVirtualTable = styled(VirtualTable)``;

const ServiceLevelAgreementsAdminTable = () => {

    const {
        loadMore,
        isRowLoaded,
        sort,
        sortTable,
        results,
        totalCount,
        error,
        isNextPageLoading,
        isError,
    } = useServiceLevelAgreements();

    const {
        clear,
        isLoading,
        isModalActive,
        serviceLevelAgreement,
        saveChanges,
    } = useEditSla();

    return (
        <React.Fragment>
            <ServiceLevelAgreementEditModal
                serviceLevelAgreement={serviceLevelAgreement}
                show={isModalActive}
                isLoading={isLoading}
                handleSave={(ops) => saveChanges(ops).then(clear)}
                handleClose={clear}
            />

            <FixedVirtualTable
                enableScrollTracking={false}
                row={SlaAdminTableRow}
                header={
                    <thead>
                        <tr>
                            <StandardTableSortablePropertyHeader
                                property="serviceName"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Service
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="reviewPeriodMonths"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Review Months
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="reviewPeriodStart"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Period Start
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="reviewPeriodEnd"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Period End
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="isTransactionFeesApplied"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Fees
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="isVerificationRequired"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Verification
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="daysToVerification"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Verification Days
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="isPreReviewDocumentRequired"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                PRD
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="daysToPreReviewDocument"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                PRD Days
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="isAppointmentRequired"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Appointment
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="daysToSchedule"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Appointment Days
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="isPostMeetingRequired"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Post Meeting
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="daysToPostMeeting"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Post Meeting Days
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="isFollowUpRequired"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Follow Up
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="daysToFollowUpSent"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Follow Up Days
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="incomeDrawdownOption"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Drawdown
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="daysToDrawdown"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Drawdown Days
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="adviserUpdatesOption"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Adviser Updates
                            </StandardTableSortablePropertyHeader>
                            <th></th>
                        </tr>
                    </thead>
                }
                footer={
                    <div className="p-2 p-sticky">
                        <span>{totalCount} SLA(s)</span>
                    </div>
                }
                error={error}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                isLoading={isNextPageLoading}
                itemCount={totalCount}
                itemData={results}
            />
        </React.Fragment>
    );
};

export default ServiceLevelAgreementsAdminTable;

import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const contactDetailsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountContactDetails"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        createContactDetails: builder.mutation({
            query: ({ masterAccountId, ...rest }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/contacts`,
                method: 'POST',
                body: {
                    masterAccountId,
                    ...rest
                }
            }),
            async onQueryStarted({ masterAccountId, data: contactDetails }, { dispatch, queryFulfilled }) {
                try {
                    const { data: createData } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountContactDetails', { masterAccountId }, (data) => {
                        data.push({
                            ...contactDetails,
                            contactDetailId: createData.contactDetailId
                        });
                    }));
                }
                catch { }
            }
        }),
        deleteContactDetails: builder.mutation({
            query: ({ masterAccountId, contactDetailId }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/contacts/${contactDetailId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ contactDetailId, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountContactDetails', { masterAccountId }, (data) => {
                        return data.filter(el => el.contactDetailId !== contactDetailId);
                    }));
                }
                catch { }
            }
        }),
        fetchMasterAccountContactDetailsEmailList: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/contacts/list?types=2&types=4`
        }),
        fetchMasterAccountContactDetailsMobileList: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/contacts/list?types=1`
        }),
        fetchMasterAccountContactDetails: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/contacts`,
            providesTags: (result, error, arg) => {
                return ['masterAccountContactDetails'];
            }
        }),
        patchContactDetails: builder.mutation({
            query: ({ masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/contacts`,
                method: 'PATCH',
                body: {
                    patchRequests: operations
                }
            }),
            async onQueryStarted({ masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountContactDetails', { masterAccountId }, (data) => {
                    operations.forEach(op => {
                        var contact = data.find(el => el.contactDetailId === op.contactDetailId);
                        applyPatch(contact, op.operations, true);
                    });
                }));
                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useCreateContactDetailsMutation,
    useDeleteContactDetailsMutation,
    useFetchMasterAccountContactDetailsEmailListQuery,
    useFetchMasterAccountContactDetailsMobileListQuery,
    useFetchMasterAccountContactDetailsQuery,
    useLazyFetchMasterAccountContactDetailsEmailListQuery,
    useLazyFetchMasterAccountContactDetailsMobileListQuery,
    usePatchContactDetailsMutation
} = contactDetailsApiEndpoints;
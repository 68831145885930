import React, { useEffect, useId, useState } from "react";
import styled from "styled-components";
import FormGroup from "./FormGroup";
import FormInputGroup from "./FormInputGroup";
import FormLabel from "./FormLabel";
import InputErrorMessage from "./InputErrorMessage";
import { InputSpinnerMd } from "../loaders/InputSpinners";
import { Form, InputGroup } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

const StyledNumericFormat = styled(NumericFormat)`
    && {
        ${props => props.loading === 'true' ? InputSpinnerMd : ''}
    }

    &[loading="true"] {
        padding-right: 2.5rem !important;
    }
`;

const PercentageInput = ({
    allowNegative = false,
    disabled,
    disableAnimations = false,
    decimalScale = 2,
    fixedDecimalScale = true,
    id,
    label,
    onBlur,
    onChange,
    showInputGroup = false,
    successTimeout = 1000,
    suffixSymbol = true,
    value = 0,
    ...rest
}) => {
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentValue, setCurrentValue] = useState(value || 0);
    const [originalValue, setOriginalValue] = useState(value || 0);

    const onBlurEvent = (e) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        // check that the value has changed before triggering anything
        // NOTE: also check the function HAS been passed into this component
        if (currentValue === originalValue || !onBlur || typeof (onBlur) !== 'function') {
            return;
        }

        // trigger the loading animation on the input
        if (disableAnimations !== true) {
            setError(_ => null);
            setIsLoading(_ => true);
        }

        // used to safely check the function returns something we can check on
        var possibleResult = onBlur(currentValue, e);

        // we can now safely use the `then` method and handle the response accordingly
        Promise.resolve(possibleResult).then(
            _ => {
                if (disableAnimations === true) {
                    return;
                }
                setOriginalValue(currentValue);
                setIsLoading(_ => false);
                setIsSuccess(_ => true);
            },
            error => {
                if (disableAnimations === true) {
                    return;
                }
                setIsLoading(_ => false);
                setError(_ => error);
            }
        );
    };

    const onChangeEvent = (values, sourceInfo) => {
        const { event } = sourceInfo;
        const { floatValue } = values;

        setCurrentValue(floatValue);
        if (onChange && typeof (onChange) === 'function') {
            onChange(event, values);
        }
    };

    useEffect(() => {
        if (isSuccess === false) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(_ => false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    useEffect(() => {
        if (value != null && value !== currentValue) {
            setCurrentValue(value);
            setOriginalValue(value)
        }
    }, [value]);

    return <FormGroup>
        {label && typeof label === 'string' && <FormLabel htmlFor={componentId}>{label}</FormLabel>}
        {label && typeof label === 'object' && <React.Fragment>{label}</React.Fragment>}
        <FormInputGroup>
            <StyledNumericFormat
                id={componentId}
                displayType="input"
                customInput={Form.Control}
                className="text-end"
                value={currentValue}
                loading={isLoading.toString()}
                disabled={disabled || isLoading}
                allowNegative={allowNegative}
                decimalScale={decimalScale}
                fixedDecimalScale={fixedDecimalScale}
                suffix={suffixSymbol === true ? '%' : undefined}
                isInvalid={error !== null}
                isValid={isSuccess}
                onBlur={onBlurEvent}
                onValueChange={onChangeEvent}
                {...rest}
            />
            {showInputGroup === true && (<InputGroup.Text>%</InputGroup.Text>)}
            <InputErrorMessage error={error} retryCallback={onBlurEvent} />
        </FormInputGroup>
    </FormGroup>
};

export default PercentageInput;
import Moment from "react-moment";
import { ThemedSpan } from "../../../components/utilities";

const UserCalendarMonthEvent = ({ event, ...props }) => {

    const {
        id,
        start,
        end,
        title,
        isAllDay,
        masterAccountId,
        masterAccountName
    } = event || {};

    return <div className="d-flex justify-content-between">
        {masterAccountId && <ThemedSpan className="text-truncate" variant="light">{masterAccountName}</ThemedSpan>}
        {!masterAccountId && <ThemedSpan className="text-truncate" variant="light">{title}</ThemedSpan>}
        {!isAllDay && <ThemedSpan className="ms-1" variant="light">
            <Moment date={start} format="h:mma" /><span className="d-none d-xxl-inline">-<Moment date={end} format="h:mma" /></span>
        </ThemedSpan>}
        {isAllDay && <ThemedSpan variant="light">All Day</ThemedSpan>}
    </div>;
};

export default UserCalendarMonthEvent;
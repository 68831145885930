import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const hoApplicationTransactionsAoiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchApplicationTransaction: builder.mutation({
            query: ({ transactionId, operations }) => ({
                url: `api/headofficeadmin/applicationtransaction/${transactionId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ applicationId, transactionId, operations, objectToAssign }, { dispatch, queryFulfilled }) {
                var result = dispatch(wealthProApi.util.updateQueryData('fetchApplicationTransactions', { applicationId }, (data) => {
                    var item = data.find(x => x.transactionId === transactionId);
                    applyPatch(item, operations, true);

                    if (objectToAssign) {
                        Object.assign(item, objectToAssign)
                    }
                }));
                try {
                    await queryFulfilled;
                }
                catch (err) {
                    result.undo();
                }
            },
            invalidatesTags: ['applicationInfo']
        })
    })
});

export const {
    usePatchApplicationTransactionMutation
} = hoApplicationTransactionsAoiEndpoints;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";

const AssetsTableRowActionColToggle = React.forwardRef(({ children, onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const AssetsTableRowActionCol = ({ deleteAsset, editAsset }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    return (
        <Dropdown id={menuId} className="caret-off w-100">
            <Dropdown.Toggle as={AssetsTableRowActionColToggle} id={toggleId} />
            <Dropdown.Menu>
                <Dropdown.Item onClick={editAsset}>
                    Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={deleteAsset}>
                    Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

};

export default AssetsTableRowActionCol;
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import { Main } from "../../../components/utilities";
import { useProviderManagement } from "../hooks/ProviderManagementContext";
import ProvidersGridHeader from "./ProvidersGridHeader";
import ProvidersGridRow from "./ProvidersGridRow";

const ProvidersGrid = () => {
    const {
        results,
        isItemLoaded,
        loadMoreItems,
        error,
        isError,
        totalCount,
        refetch,
    } = useProviderManagement();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetch} />
        </ErrorMessageWrapper>
    }

    return (
        <VirtualTable
            header={<ProvidersGridHeader />}
            row={ProvidersGridRow}
            itemData={results}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            itemCount={totalCount > results.length ? results.length + 10 : totalCount}
            itemSize={42}
        />
    );
};

export default ProvidersGrid;
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import MomentComponent from "react-moment";
import { IconButton } from "../../../components/buttons";
import { ConfirmModal } from "../../../components/modals";
import useOpenDocument from "../../../hooks/useOpenDocument";
import UploadDocumentModal from "../../uploaddocumentmodal";
import useClientFeeAgreements from "../hooks/useClientFeeAgreements";
import useClientFeeSignatories from "../hooks/useClientFeeSignatories";
import useClientPersons from "../hooks/useClientPersons";
import { DateInput, FormSelect } from "./../../../components/forms/";

const ServiceAgreementProofs = ({ masterAccountId, clientServiceAgreementId, feeAgreements = [], feeSignatories = [], loadingLines = 3 }) => {


    const { clientPersons } = useClientPersons(masterAccountId);
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const { previewClientConsent,
        publishClientConsent,
        updateFeeAgreement,
        deleteClientFeeAgreement
    } = useClientFeeAgreements(masterAccountId);

    const { updateClientFeeSignatoriesMappings } = useClientFeeSignatories(masterAccountId);

    const onFieldChanged = (clientFeeAgreementId, property, value) => updateFeeAgreement(clientFeeAgreementId, property, value);

    const onDeletePressed = (clientFeeAgreementId) => deleteClientFeeAgreement(clientFeeAgreementId);

    const onClientFeeSignatorySelected = (values) => updateClientFeeSignatoriesMappings(values);

    const [showUploadDocument, setShowUploadDocument] = useState(false);
    const [selectedFeeAgreement, setSelectedFeeAgreement] = useState(null);

    const handleUploadDocumentClose = () => setShowUploadDocument(false);
    const [isConfirmationShown, setIsConfirmationShown] = useState(false);

    const handleOnDocumentUploaded = (r) => {
        if (r && r.documentId != null) {
            //Post up to update the fee agreement 
            onFieldChanged(selectedFeeAgreement,"evidenceDocumentId", r.documentId);
        }
    };
    const uploadEvidence = (feeAgreement) => {

        setSelectedFeeAgreement(feeAgreement.clientFeeAgreementId);
        setShowUploadDocument(true);
        
    };

    const handleOnDocumentOpened = (documentId) => {
        if (!documentId || isOpening) {
            return;
        }

        openDocument(documentId);
    };

    const handleOnPublishConsentClicked = () => {
        setIsConfirmationShown(false);
        toast.promise(publishClientConsent(), {
            loading: `Attempting to publish client consent...`,
            success: (data) => {
                return `The client consent form has been published`;
            },
            error: (err) => <div>Failed to publish Client Consent - {err?.data?.message}</div>
        });
    };

    const handleOnPreviewConsentClicked = () => {
        toast.promise(previewClientConsent(), {
            loading: `Building client consent preview`,
            success: (data) => {
                var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))

                var file = new Blob([bytesPdf], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);

                window.open(fileURL, 'Client Consent');
                return `Client consent is open in a new window.`;
            },
            error: (err) => <div>Failed to build Client Consent Preview - {err?.data}</div>

        });
    };

    return (
        <div>
            <UploadDocumentModal
                handleClose={handleUploadDocumentClose}
                onDocumentUploaded={handleOnDocumentUploaded}
                show={showUploadDocument}
            />
             <ConfirmModal
                title="Publish Client Consent Form"
                message="Are you sure you would like to publish the client consent form?"
                handleConfirm={() => handleOnPublishConsentClicked()}
                handleClose={() => setIsConfirmationShown(false)}
                show={isConfirmationShown}
            />
            <div className="row mb-4">
                <div className="col">
                    <FormSelect
                        label="Required Signatures"
                        placeholder="Required Signatures"
                        isMulti={true}
                        options={clientPersons}
                        defaultValue={Array.isArray(feeSignatories) ? feeSignatories.map((el) => el.clientId) : []}
                        onBlur={(values) => onClientFeeSignatorySelected(values)}
                    />
                </div>
            </div>
            
            <div className="row mb-4">
                <div className="col-2">
                    <Button
                        variant="primary"
                        className="w-100"
                        onClick={() => handleOnPreviewConsentClicked()}
                    >
                        View Agreement
                    </Button>
                </div>
                <div className="col-2">
                    <Button
                        variant="primary"
                        className="w-100"
                        onClick={() => setIsConfirmationShown(true)}
                    >
                        Publish Agreement
                    </Button>
                </div>
                
            </div>
            {(feeAgreements.length > 0) && (
                <div className="row mb-2">
                    <div className="col-1">Date Published</div>
                    <div className="col-3">Authorisation Method</div>
                    <div className="col-2">Date Signed</div>
                    <div className="col-2">Evidence</div>
                </div>
            )}
            {/* {isLoadingFeeAgreements === true && <Skeleton count={loadingLines} />} */}
            {   feeAgreements &&
                feeAgreements.length === 0 && (
                    <div className="row">
                        <div className="col">
                            There are no published agreements.  Publish an agreement above to update the evidence.
                        </div>
                    </div>
                )}
            {
                feeAgreements &&
                feeAgreements.map((el, i) => (
                    <div className="row mb-2" key={el.clientFeeAgreementId}>
                        <div className="col-1">
                            <MomentComponent
                                className="ps-1"
                                format="DD/MM/YYYY"
                            >
                                {el.publishedDate}
                            </MomentComponent>
                        </div>
                        <div className="col-3">
                            <FormSelect
                                defaultValue={el.authorisationMethod}
                                options={[
                                    { label: "Physical Signature", value: 0 },
                                    { label: "Electronic Signature", value: 1 },
                                    { label: "Email Confirmation", value: 2 },
                                ]}
                                 onBlur={(values) => onFieldChanged(el.clientFeeAgreementId, 'authorisationMethod', values.value)}
                            />
                        </div>

                        <div className="col-2">
                            <DateInput
                                value={el.signedDate}
                                isClearable={true}
                                
                                onBlur={(value) => onFieldChanged(el.clientFeeAgreementId, 'signedDate', value)}
                            />
                        </div>

                        <div className="col-2">

                        {el.evidenceDocumentId != null && 
                            <Button
                                variant="primary"
                                className="w-100"
                                onClick={() => handleOnDocumentOpened(el.evidenceDocumentId)}
                                >
                                View Evidence
                            </Button>
                        }
                        {el.evidenceDocumentId == null && 
                            <Button
                                variant="primary"
                                className="w-100"
                                onClick={() => uploadEvidence(el)}
                            >
                                <span>Upload Evidence</span>
                            </Button>
                        }
                           
                        </div>
                        <div className="col-md-auto">
                            <IconButton
                                icon="fa-file"
                                variant="primary"
                                onClick={() => handleOnDocumentOpened(el.originalDocumentId)}
                            />
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default ServiceAgreementProofs;

import { useEffect, useState } from "react";
import { useFetchRecentReportDocumentsQuery } from "../../../services/clientdocuments";

const useFetchRecentReports = (initialPage = 1, initialLimit = 10) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(initialPage);
    const [limit, setLimit] = useState(initialLimit);
    const [error, setError] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const { data: response, isError, isFetching, isLoading, isUninitialized, refetch } = useFetchRecentReportDocumentsQuery({ page, limit });

    const nextPage = () => {
        setPage(curr => curr + 1);
    };

    const previousPage = () => {
        setPage(curr => curr - 1);
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        if (page > 1) {
            setPage(_ => 1);
        }
        else {
            refetch();
        }
    };

    useEffect(() => {
        if (!response) {
            return;
        }

        const { results: { pagination, results }, message } = response || {};
        const { totalCount: responseTotalCount, totalPages: responseTotalPages } = pagination || {};
        
        // check for an error
        if (message) {
            setError(_ => message);
            setData(_ => []);
            setTotalCount(_ => 0);
            setTotalPages(_ => 0);
            return;
        }

        setData(results);
        setTotalCount(responseTotalCount);
        setTotalPages(responseTotalPages);
    }, [ response ]);

    return {
        data,
        error,
        isError,
        isLoading: isFetching === true || isLoading === true,
        isUninitialized,
        limit,
        nextPage,
        page,
        previousPage,
        reload,
        setLimit,
        setPage,
        totalCount,
        totalPages
    }
};

export default useFetchRecentReports;
import classNames from "classnames";
import { niceBytes } from "../helpers/fileHelpers";
import { ThemedIcon } from "./utilities";
import { FormInput } from "./forms";
import { DocumentFunctionsSelect, DocumentTypesSelect } from "./forms/selects";

const FileDisplay = ({ 
    className, 
    file, 
    fileData = null,
    onClearFile = () => {},
    onFileDataChanged = () => {}
}) => {
    const { name, size } = file;
    const { id, description, type, typeIcon, function: docFunction } = fileData || { };
    return (
        <div className={classNames("p-3 border border-3 rounded border-opacity-10 mb-2", className)}>
            <div className={classNames("d-flex", { "mb-2": fileData })}>
                <div className="d-flex justify-content-center align-items-center">
                    <ThemedIcon icon={typeIcon ?? 'file'} size="xl" variant="primary" />
                </div>
                <p className="flex-fill m-0 px-3 lh-1">
                    <span>{name}</span>
                    <br />
                    <span className="text-muted fs-smallest">{niceBytes(size)}</span>
                </p>
                {onClearFile != null && <div className="d-flex justify-content-center align-items-center">
                    <ThemedIcon className="has-pointer" icon="fa-xmark" size="xl" variant="danger" onClick={() => onClearFile(file)} />
                </div>}
            </div>
            {fileData && (
                <div>
                    <div className="row mb-2">
                        <FormInput 
                            label="Description"
                            value={description}
                            disableAnimations={true}
                            onChange={(e) => onFileDataChanged(id, 'description', e.target.value)}
                        />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <DocumentTypesSelect
                                label="Type"
                                isDisabled={true}
                                disableAnimations={true} 
                                defaultValue={type}
                                onChange={(e) => onFileDataChanged(id, 'type', e.value)}
                            />
                        </div>
                        <div className="col-6">
                            <DocumentFunctionsSelect
                                label="Function"
                                defaultValue={docFunction}
                                disableAnimations={true} 
                                onChange={(e) => {
                                    onFileDataChanged(id, 'function', e.value);
                                    onFileDataChanged(id, 'selector', e.documentFunction);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileDisplay;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { ClientProjectSelect, MasterAccountSelect } from "../../../components/forms/selects";
import { useEffect, useState } from "react";
import { FormSelect } from "../../../components/forms";
import toast from "react-hot-toast";
import { BasicToast } from "../../../components/toasts";
import MasterAccountProjectSelect from "./MasterAccountProjectSelect";
import useGetMasterAccountByContactDetails from "../hooks/useGetMasterAccountByContactDetails";

const FileMailModal = ({ data, show, onClose, fileMail, fileMailResult }) => {

    const { from } = data || {};
    const { isLoading: isFiling } = fileMailResult || {};
    const { data: initialMasterAccountId, isLoading: isLoadingMasterAccountId } = useGetMasterAccountByContactDetails(from?.emailAddress?.address);

    const [masterAccountId, setMasterAccountId] = useState(initialMasterAccountId);
    const [projectId, setProjectId] = useState(null);

    const handleClose = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setMasterAccountId(null);
        setProjectId(null);
        onClose();
    };

    const onSubmit = (e) => {
        e.preventDefault();

        toast.promise(fileMail({ masterAccountId, projectId }), {
            loading: "Filing mail...",
            error: (err) => err?.data?.message ?? "Unable to file mail.",
            success: "Filed mail!"
        }).then(
            () => {
                handleClose();
            }
        )
    };

    return <Modal centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header>
            File Email
        </Modal.Header>
        <form onSubmit={onSubmit}>
            <Modal.Body>
                <MasterAccountSelect
                    value={masterAccountId}
                    label={"Master Account"}
                    onChange={({ value }) => setMasterAccountId(value)}
                />
                <br />
                <div className="row">
                    <MasterAccountProjectSelect
                        className="col"
                        label={"Project"}
                        disabled={!masterAccountId}
                        masterAccountId={masterAccountId}
                        value={projectId}
                        setValue={selection => setProjectId(selection.value)}
                    />
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="success" disabled={isFiling || !masterAccountId}>File</Button>
            </Modal.Footer>
        </form>
    </Modal>
};

export default FileMailModal;
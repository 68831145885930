import React, { useEffect, useState } from "react";
import Button from "../../components/buttons";
import { Modal } from "react-bootstrap";
import {
    DateInput,
    FormSelect,
    FormInput,
    NumberInput,
    FormCheck,
    CurrencyInput,
} from "../../components/forms";
import AssetSearchPagedSelect from "../../components/forms/selects/AssetSearchPagedSelect";
import useDesignationDetails from "../clienttransactions/hooks/useDesignationDetails";
import useProductDetails from "../clienttransactions/hooks/useProductDetails";
import useProviderDetails from "../clienttransactions/hooks/useProviderDetails";
import useInvestmentOperations from "../clienttransactions/hooks/useInvestmentOperations";
import { useClientContext } from "../../hooks/ClientContext";
import { CenterFormColumnComponent } from "../../components/utilities";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";

export const ClientTransactionEditModal = ({
    transaction = null,
    show = false, 
    handleClose = () => {},
    handleSave = () => {},
}) => {
    
    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>Edit Transaction</Modal.Header>
            <InvestmentTransactionEdit transaction={transaction} handleClose={handleClose} handleSave={handleSave} />
        </Modal>
    );
};

const InvestmentTransactionEdit = ({transaction, handleClose, handleSave}) => {

    const { id } = useClientContext();
    const { designations } = useDesignationDetails(id, "list");
    const { products } = useProductDetails("select");
    const { investmentOperations } = useInvestmentOperations();
    const { providers } = useProviderDetails("select");

    const {
        control,
        errors,
        formState,
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({ defaultValues: transaction });

    const onSubmit = (data) => {
        const { dirtyFields } = formState;
        if (!dirtyFields || Object.keys(dirtyFields).length === 0) {
            handleClose();
        }
        
        const operations = Object.keys(dirtyFields).map((el) => {
            return { op: "replace", path: `/${el}`, value: getValues(el) };
        });
        
        if (!operations || operations.length === 0) {
            handleClose();
        }
        
        toast.promise(handleSave(operations), {
            loading: 'Saving transaction...',
            success: 'Transaction Saved',
            error: (err) =>{
                reset(undefined, { keepValues: true });
                return <div>{err?.data}</div>
            }
        });
    };

    const onClose = () => {  
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    React.useEffect(() => {
        if (!transaction) {
            return;
        }
        reset(transaction);
    }, [transaction]);

    return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
                <>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="designationId"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        ...rest
                                    },
                                }) => (
                                    <FormSelect
                                        label="Designation"
                                        defaultValue={value}
                                        options={designations}
                                        onChange={(values) =>
                                            setValue(
                                                "designationId",
                                                values.value,
                                                { shouldDirty: true }
                                            )
                                        }
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="investmentOperation"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        ...rest
                                    },
                                }) => (
                                    <FormSelect
                                        label="Operation"
                                        defaultValue={value}
                                        options={investmentOperations}
                                        onChange={(values) =>
                                            setValue(
                                                "investmentOperation",
                                                values.value,
                                                { shouldDirty: true }
                                            )
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="productId"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        ...rest
                                    },
                                }) => (
                                    <FormSelect
                                        label="Product"
                                        defaultValue={value}
                                        options={products.map(({label, value}) => 
                                            ({"label": label, "value": value.productId, "custodian": value.defaultCustodianId}))}
                                        onChange={(values) =>{
                                            setValue(
                                                "productId",
                                                values.value,
                                                { shouldDirty: true }
                                            );
                                            setValue(
                                                "custodianId",
                                                values.custodian,
                                                { shouldDirty: true }
                                            ) }
                                        }
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="assetId"
                                control={control}
                                render={({
                                    field: { ref, value, onChange, onBlur, ...rest },
                                }) => (
                                    <AssetSearchPagedSelect
                                        label="Asset"
                                        isCreateable={false}
                                        valueObject={{label: getValues("assetName"), value: value}}
                                        isMulti={false}
                                        errorAllowRetry={false}
                                        disableAnimations={true}
                                        setValue={(value) => {
                                            setValue("assetName", value.label,{ shouldDirty: true });
                                            setValue("assetId", value.value,{ shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                            
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="custodianId"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        ...rest
                                    },
                                }) => (
                                    <FormSelect
                                        label="Custody Partner"
                                        defaultValue={value}
                                        options={providers}
                                        onChange={(values) =>
                                            setValue(
                                                "custodianId",
                                                values.value,
                                                { shouldDirty: true }
                                            ) }
                                    />
                                )}
                            />
                            
                        </div>
                        <div className="col-6">
                            <Controller
                                name="status"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        ...rest
                                    },
                                }) => (
                                    <FormSelect
                                        label="Status"
                                        defaultValue={value}
                                        options={[
                                            {
                                                label: "Recommended",
                                                value: 10,
                                            },
                                            {
                                                label: "Ready To Transact",
                                                value: 20,
                                            },
                                            {
                                                label: "Instructed",
                                                value: 30,
                                            },
                                            {
                                                label: "Uploaded",
                                                value: 40,
                                            },
                                            {
                                                label: "Settled",
                                                value: 50,
                                            },
                                            {
                                                label: "Tax Reclaim",
                                                value: 60,
                                            },
                                            {
                                                label: "Not Taken Up",
                                                value: 70,
                                            },
                                        ]}
                                        onChange={(values) =>
                                            setValue(
                                                "status",
                                                values.value,
                                                { shouldDirty: true }
                                            )
                                        }
                                    />
                                )}
                            />
                           
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="custodianAccountReference"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        onBlur,
                                        ...rest
                                    },
                                }) => (
                                    <FormInput
                                        label="Custodian Reference"
                                        value={value}
                                        onBlur={(values) =>
                                            setValue("custodianAccountReference", values,
                                            { shouldDirty: true })
                                        }
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="providerAccountReference"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        onBlur,
                                        ...rest
                                    },
                                }) => (
                                    <FormInput
                                        label="Provider Reference"
                                        value={value}
                                        onBlur={(values) =>
                                            setValue("providerAccountReference", values,
                                            { shouldDirty: true })
                                        }
                                    />
                                )}
                            />
                            
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="quantity"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        onBlur,
                                        ...rest
                                    },
                                }) => (
                                    <NumberInput
                                        label="Quantity"
                                        value={value}
                                        decimalScale={4}
                                        onBlur={(values) =>
                                            setValue("quantity", values,
                                            { shouldDirty: true })
                                        }
                                    />
                                )}
                            />
                        </div>

                        <div className="col">
                            <Controller
                                name="value"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        onBlur,
                                        ...rest
                                    },
                                }) => (
                                    <CurrencyInput
                                        label="Value"
                                        value={value}
                                        onBlur={(values) =>
                                            setValue("value", values,
                                            { shouldDirty: true })
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        
                    <div className="col">
                            <Controller
                                name="investmentDate"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onBlur,
                                        ...rest
                                    },
                                }) => (
                                    <DateInput
                                        label="Investment Date"
                                        value={value}
                                        onBlur={(values) =>
                                            setValue(
                                                "investmentDate",
                                                values,
                                                { shouldDirty: true }
                                            )
                                        }
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <CenterFormColumnComponent>
                            <Controller
                                name="includeInValuation"
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        value,
                                        onChange,
                                        ...rest
                                    },
                                }) => (
                                    <FormCheck
                                        label="Include in Valuation"
                                        isChecked={value}
                                        errorAllowRetry={false}
                                        disableAnimations
                                        onChange={(value) =>
                                            setValue("includeInValuation", value,
                                            { shouldDirty: true })
                                        }
                                        {...rest}
                                    />
                                )}
                            />
                            </CenterFormColumnComponent>
                        </div>
                    </div>
                </>
            
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" type="submit" disabled={formState.isSubmitted}>
                Save Changes
            </Button>
            <Button variant="light" onClick={onClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </form>
    );
};
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import useCountNotifications from "../hooks/useCountNotifications";

const StyledCounter = styled.span`
    transform: translate(15%, 0%);
    font-size: 0.75rem;
`;

const NotificationsDropdownToggle = React.forwardRef(({ onClick, onToggle, displayCount = true, ...rest }, ref) => {

    const { data, markNotificationsAsNotified } = useCountNotifications();

    const handleOnToggle = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
            markNotificationsAsNotified();
        }
    };

    return <a ref={ref} onClick={handleOnToggle} {...rest}>
        <FontAwesomeIcon icon="fa-bell" size="lg" />
        {data > 0 && displayCount && (
            <StyledCounter className="fa-layers-counter">{data}</StyledCounter>
        )}
    </a>
});

export default NotificationsDropdownToggle;
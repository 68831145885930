import { useFilterContext } from "../../../hooks/FilterContext";
import { useCreateFeeTransactionMutation, useFetchFeeTransactionTypesQuery, useLazyFetchApplicationDefaultFeeAllocationsQuery } from "../../../services/headofficeadmin";
import { useEffect, useMemo } from "react";

const useCreateFeeTransaction = (feeGroup) => {
    const { groupId, applicationId } = feeGroup;
    const { filter } = useFilterContext();
    const { data: transactionTypes, isLoading: isLoadingTransactionTypes } = useFetchFeeTransactionTypesQuery();
    const [fetchDefaultAllocations, {
        data: defaultAllocations,
        isLoading: isLoadingDefaultAllocations,
        isError: isDefaultAllocationsError,
        error: defaultAllocationsError,
    }] = useLazyFetchApplicationDefaultFeeAllocationsQuery();

    const [createTrigger] = useCreateFeeTransactionMutation();

    const feeTransactionAllocationDefaultValues = useMemo(() => ({
        personType: null,
        attributeToId: null,
        allocationPercentage: 0.1
    }), []);

    const feeTransactionDefaultValues = useMemo(() => ({
        groupId: groupId,
        feeDate: new Date(),
        feeTransactionTypeId: null,
        feeTransactionType: {},
        totalFeeAmount: 0,
        totalVatAmount: 0,
        feeReference: null,
        comment: null,
        allocations: defaultAllocations?.map(item => ({
            personType: item.personType,
            attributeToId: item.creditedPersonId,
            allocationPercentage: item.percentage
        })) ?? [{
            personType: null,
            attributeToId: null,
            allocationPercentage: 100
        }]
    }), [defaultAllocations]);



    const createFeeTransaction = (request) => {
        return new Promise((resolve, reject) => {
            return createTrigger({ request, filter })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const retryFetchDefaultAllocations = (e) => {
        if (e && typeof (e) === "function") {
            e.preventDefault();
        }

        fetchDefaultAllocations({ applicationId });
    };

    useEffect(() => {
        if (applicationId) {
            fetchDefaultAllocations({ applicationId });
        }
    }, [applicationId]);

    return {
        defaultAllocations,
        isLoadingDefaultAllocations,
        isDefaultAllocationsError,
        defaultAllocationsError,
        feeTransactionDefaultValues,
        feeTransactionAllocationDefaultValues,
        transactionTypes: transactionTypes || [],
        isLoadingTransactionTypes,
        createFeeTransaction,
        retryFetchDefaultAllocations
    };
};

export default useCreateFeeTransaction;
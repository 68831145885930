import { useEffect } from "react";
import { useLazyFetchHeadOfficeWrapperProductsQuery } from "../../../services/headofficeadmin";
import FormSelect from "../FormSelect";

const HeadOfficeWrapperProductsSelect = ({
    defaultValue,
    label,
    onBlur,
    onChange,
    wrapperId,
    ...rest
}) => {

    const [trigger, { data, isFetching }] = useLazyFetchHeadOfficeWrapperProductsQuery();

    useEffect(() => {
        wrapperId && trigger({ wrapperId });
    }, [wrapperId, trigger]);

    return <FormSelect
        isDisabled={!wrapperId}
        defaultValue={defaultValue}
        isLoadingOptions={isFetching}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        options={data}
        {...rest}
    />;
};

export default HeadOfficeWrapperProductsSelect;
import { usePatchMasterAccountDashboardLoginMutation } from "../../../services/clientauth";

const useAccountActivationControl = () => {
    const [_patch, { isLoading, ...patchResult }] = usePatchMasterAccountDashboardLoginMutation();

    const patch = (masterAccountId, loginId, isActive) => {
        return new Promise((resolve, reject) => {
            console.log('patch', { masterAccountId, loginId, isActive });
            let operations = [
                { op: 'replace', path: '/isActive', value: isActive }
            ];
            return _patch({ masterAccountId, loginId, operations })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { patch, isLoading, data: { isLoading, ...patchResult } };
};

export default useAccountActivationControl;
import { patchReplace } from "../../../helpers/patchDoc";
import { usePatchClientMessageMutation } from "../../../services/messages";

const useEditMessage = (messageId) => {

    const [patchMessageTrigger, { isLoading: isPatching }] = usePatchClientMessageMutation();

    const patchMessage = (property, newValue, queryParams = {}) => {
        return new Promise((res, rej) => {
            const operations = [patchReplace(property, newValue)];
            return patchMessageTrigger({ messageId, operations, ...queryParams })
                .unwrap()
                .then(res, rej);
        });
    };

    return {
        isPatching,
        patchMessage
    };
};

export default useEditMessage;
import React, { useEffect, useId, useState } from "react";
import styled from "styled-components";
import FormGroup from "./FormGroup";
import FormInputGroup from "./FormInputGroup";
import FormLabel from "./FormLabel";
import InputErrorMessage from "./InputErrorMessage";
import { InputSpinnerMd } from "../loaders/InputSpinners";
import { Form } from "react-bootstrap";

export const StyledFormInput = styled(Form.Control)`
    && {
        ${props => props.loading === 'true' ? InputSpinnerMd : ''}
    }
`;

const FormInput = React.forwardRef(({
    autoComplete = "off",
    disableAnimations = false,
    disableErrorMessage = false,
    disabled,
    horizontal = false,
    id,
    label,
    onBlur,
    onChange,
    size = "md",
    successTimeout = 1000,
    type = "text",
    value,
    errorMessage = null,
    errorAllowRetry = true,
    parentClassName = null,
    ...rest
}, ref) => {
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(errorMessage);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentValue, setCurrentValue] = useState(value || '');
    const [originalValue, setOriginalValue] = useState(value || '');

    const onBlurEvent = (e) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        // check that the value has changed before triggering anything
        // NOTE: also check the function HAS been passed into this component
        if (currentValue === originalValue || !onBlur || typeof (onBlur) !== 'function') {
            return;
        }

        // trigger the loading animation on the input
        if (disableAnimations !== true) {
            setError(_ => null);
            setIsLoading(_ => true);
        }

        // used to safely check the function returns something we can check on
        var possibleResult = onBlur(currentValue, e);

        // we can now safely use the `then` method and handle the response accordingly
        Promise.resolve(possibleResult).then(
            _ => {
                setOriginalValue(currentValue);
                
                if (disableAnimations === true) {
                    return;
                }
                setIsLoading(_ => false);
                setIsSuccess(_ => true);
            },
            error => {
                if (disableAnimations === true) {
                    return;
                }
                setIsLoading(_ => false);
                setError(_ => error);
            }
        );
    };

    const onChangeEvent = (e) => {
        setCurrentValue(e.target.value);
        if (onChange && typeof (onChange) === 'function') {
            onChange(e);
        }
    };

    useEffect(() => {
        if (errorMessage) {
            setError(_ => errorMessage);
        }
        else {
            setError(_ => null);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (isSuccess === false) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(_ => false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    useEffect(() => {
        if (value != null && value !== currentValue) {
            setCurrentValue(value);
            setOriginalValue(value)
        }
    }, [value]);

    return <FormGroup className={parentClassName} horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup className="has-validation" horizontal={horizontal} hasLabel={label ? true : false}>
            <StyledFormInput
                ref={ref}
                id={componentId}
                type={type}
                size={size}
                value={currentValue}
                loading={isLoading.toString()}
                disabled={disabled || isLoading}
                isInvalid={error !== null}
                isValid={isSuccess}
                onBlur={onBlurEvent}
                onChange={onChangeEvent}
                autoComplete={autoComplete}
                {...rest}
            />
            {disableErrorMessage !== true && (
                <InputErrorMessage error={error} retryCallback={onBlurEvent} allowRetry={errorAllowRetry} />
            )} 
        </FormInputGroup>
    </FormGroup>
});

export default FormInput;
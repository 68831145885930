import React from "react";
import FormSelect from "../FormSelect";
import { useFetchServiceAgreementsQuery } from "../../../services/clientserviceterms";

const ServiceAgreementsSelect = ({ options, divisionId = null, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchServiceAgreementsQuery({ });
    return <FormSelect
        options={data}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default ServiceAgreementsSelect;
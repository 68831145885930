import React from "react";
import { Button } from "../../../components";
import {
    FormTextArea,
    FormSelect,
    FormLabel,
    FormCheck
} from "../../../components/forms";
import MomentComponent from "react-moment";
import FFMeetingNotesStatusSelect from "./FFMeetingNotesStatusSelect";
import {IconButton} from "../../../components";
import TargetDate from "./TargetDate";

const FactFindMeetingNotes = ({ review, onFieldChanged }) => {
    return (
        <React.Fragment>
            <div className="row mt-4">
                <div className="col-2">
                    <FFMeetingNotesStatusSelect
                        label="Meeting Note Status"
                        defaultValue={review.meetingNoteStatus}
                        onBlur={(selection) =>
                            onFieldChanged("meetingNoteStatus", selection.value)
                        }
                        isDisabled={!review.meetingNoteRequired}
                    />
                </div>

                <div className="col">
                    <FormTextArea
                        label="Meeting Note"
                        value={review.meetingNoteStatusComment}
                        onBlur={(value) =>
                            onFieldChanged("meetingNoteStatusComment", value)
                        }
                        disabled={!review.meetingNoteRequired || review.meetingNoteStatus == 2}
                    />
                </div>

                <div className="col-md-auto d-flex align-items-end mb-1">
                        <IconButton 
                            className="col-auto"
                            icon={review.meetingNoteRequired ? "fa-x" : "fa-check"}
                            variant={review.meetingNoteRequired ? "danger" : "success"}
                            onClick={(_) =>
                                onFieldChanged(
                                    "meetingNoteRequired",
                                    !review.meetingNoteRequired
                                )
                            }
                        />
                    </div>
            </div>

            <div className="row">
                <div className="col-3">
                {review.factFindArchiveId != null &&
                    review.factFindUpdatedBy &&
                    review.factFindUpdatedDate && (
                            <FormLabel>
                                Fact Find updated by {review.factFindUpdatedBy}{" "}
                                on
                                <MomentComponent
                                    className="ps-1"
                                    format="DD/MM/YYYY HH:mm"
                                >
                                    {review.factFindUpdatedDate}
                                </MomentComponent>
                            </FormLabel>
                    )}
                    {review.factFindArchiveId == null && review.meetingNoteStatus == 2 && (
                        <div className="col-2">
                            <FormLabel style={{color: 'red'}}>
                                Fact Find has not been archived
                            </FormLabel>
                        </div>
                    )}
                    </div>


                <div className="col-5">
                    {review.meetingNotesUpdatedBy &&
                        review.meetingNotesUpdatedDate && (
                            <FormLabel>
                                Meeting notes updated by{" "}
                                {review.meetingNotesUpdatedBy} on
                                <MomentComponent
                                    className="ps-1"
                                    format="DD/MM/YYYY HH:mm"
                                >
                                    {review.meetingNotesUpdatedDate}
                                </MomentComponent>
                            </FormLabel>
                        )}
                </div>

                <div className="col-4 d-flex justify-content-end">
                    {review.meetingNotesUpdatedTargetDate && (
                       <TargetDate date={review.meetingNotesUpdatedDate} targetDate={review.meetingNotesUpdatedTargetDate} />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default FactFindMeetingNotes;

import React from "react";
import ClientCommunicationPreferences from "../../features/clientcommunicationpreferences";
import ClientSalutationPreferences from "../../features/clientsalutationpreferences";
import ClientServicePreferences from "../../features/clientservicepreferences";
import { Main } from "../../components/utilities";
import { useClientContext } from "../../hooks/ClientContext";

const ClientPreferencesPage = () => {
    const { id } = useClientContext();
    return (
        <Main className="p-3">
            <ClientCommunicationPreferences masterAccountId={id} />
            <ClientServicePreferences masterAccountId={id} />
            <ClientSalutationPreferences masterAccountId={id} />
        </Main>
    );
};

export default ClientPreferencesPage;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";

const DeleteFeeTransactionModal = ({ onClose, onDelete, show }) => {

    const onDeleteClick = () => {
        onDelete()
        .then(
            r => {
                onClose();
            }
        );
    };

    return <Modal show={show} centered onHide={onClose}>
        <Modal.Header closeButton>
            Delete Fee Transaction
        </Modal.Header>
        <Modal.Body>
            <ThemedSpan>Are you sure you want to delete this fee transaction?</ThemedSpan>
            <br />
            <ThemedSpan>All related allocations will be lost!</ThemedSpan>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={onDeleteClick}>Delete</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>

};

export default DeleteFeeTransactionModal;
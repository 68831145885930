import { wealthProApi } from '../../redux/api';

const clientAuthResourcesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchMasterAccountResources: builder.query({
            query: ({ masterAccountId }) => `/api/clientauth/master-accounts/${masterAccountId}/dashboard/resources/list`
        }),
    })
});

export const {
    useFetchMasterAccountResourcesQuery
} = clientAuthResourcesApiEndpoints;
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { DateInput, FormCheck, FormInput, FormSelect } from "../../components/forms";
import { ClientProjectSelect, GenericUserSelect, ReminderTypesSelect } from "../../components/forms/selects";
import { MessagePriorities } from "../../helpers/constants";
import { useFilterContext } from "../../hooks/FilterContext";

export const ClientEventFiltersModal = ({ handleClose, onFiltersChanged, show, ...rest }) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();

    const [tempFilters, setTempFilters] = React.useState(filter);

    const handleApply = () => {
        setFilter(tempFilters);
        handleClose();
    }

    const handleClear = () => {
        setTempFilters(defaultFilters);
    }

    const handleChange = (property, value) => {
        setTempFilters({
            ...tempFilters,
            [property]: value
        });
    }

    React.useEffect(() => {
        setTempFilters(filter);
    }, [filter]);

    return <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header>Client Event Filters</Modal.Header>
        <Modal.Body>
            <ClientEventFilters
                filter={tempFilters}
                setFilter={handleChange}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={handleClear}>Clear Filters</Button>
            <Button variant="success" onClick={handleApply}>Apply Filters</Button>
            <Button variant="light" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
}

const ClientEventFilters = ({ filter, setFilter }) => {
    const {
        projectId,
        userIds,
        deadlineDateFrom,
        deadlineDateTo,
        description,
        priorities,
        typeIds
    } = filter;

    const setFilterMulti = (property, selectionArr) =>
        setFilter(property, selectionArr.map(x => x.value))

    return <React.Fragment>
        <div className="row gy-2">
            <div className="col-6">
                <FormInput
                    label="Description"
                    value={description}
                    onChange={(e) => setFilter("description", e.target.value)}
                    disableAnimations
                />
            </div>
            <div className="col-3">
                <DateInput
                    label="Event Date From"
                    value={deadlineDateFrom}
                    isClearable
                    onBlur={(newDate) => setFilter("deadlineDateFrom", newDate)}
                    disableAnimations
                />
            </div>
            <div className="col-3">
                <DateInput
                    label="To"
                    value={deadlineDateTo}
                    isClearable
                    onBlur={(newDate) => setFilter("deadlineDateTo", newDate)}
                    disableAnimations
                />
            </div>
            <div className="col-12">
                <ClientProjectSelect
                    label="Project"
                    value={projectId}
                    setValue={(selection) => setFilter("projectId", selection?.value)}
                    isClearable
                    disableAnimations
                />
            </div>
            <div className="col-12">
                <GenericUserSelect
                    label="User(s)"
                    defaultValue={userIds}
                    onChange={(selections) => setFilterMulti("userIds", selections)}
                    isClearable
                    isMulti
                    disableAnimations
                />
            </div>
            <div className="col-4">
                <FormSelect
                    label="Priorities"
                    defaultValue={priorities}
                    options={Object.keys(MessagePriorities).map(key => ({ label: key, value: MessagePriorities[key] }))}
                    onChange={(selections) => setFilterMulti("priorities", selections)}
                    isClearable
                    isMulti
                    disableAnimations
                />
            </div>
            <div className="col-4">
                <ReminderTypesSelect
                    label="Types"
                    defaultValue={typeIds}
                    onChange={(selections) => setFilterMulti("typeIds", selections)}
                    isClearable
                    isMulti
                    disableAnimations
                />
            </div>
            <div className="col-4 d-flex justify-content-center">
                <FormCheck
                    groupClassName="mt-auto mb-2"
                    label="Show Cancelled"
                    isChecked={filter.showCancelled}
                    onChange={() => setFilter("showCancelled", !filter.showCancelled)}
                    disableAnimations
                />
            </div>
        </div>
    </React.Fragment>
}

export default ClientEventFilters;
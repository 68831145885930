import React from "react";
import Application from "./Application";
import Footer from "./Footer";
import FooterCenter from "./FooterCenter";
import FooterLeft from "./FooterLeft";
import FooterRight from "./FooterRight";
import FooterText from "./FooterText";
import Header from "./Header";
import HeaderBrand from "./HeaderBrand";
import HeaderLinks from "./HeaderLinks";
import HeaderNavigation from "./HeaderNavigation";
import HeaderSearch from "./HeaderSearch";
import FooterLinks from "../../features/footerlinks";
import withProviders from "../../hooks/withProviders";
import { Outlet } from "react-router-dom";
import { FinancialExpressLogo, PoweredByWealthProLogo } from "../../components";
import { ActiveUsersProvider } from "../../hooks/ActiveUsersContext";
import { PageScrollPositionProvider } from "../../hooks/PageScrollPositionContext";
import { PersonalOutlookProvider } from "../../hooks/PersonalOutlookContext";


const MainLayout = () => (
    <Application>
        <Header>
            <HeaderNavigation />
            <HeaderBrand />
            <HeaderLinks />
            <HeaderSearch />
        </Header>
        <Outlet />
        <Footer>
            <FooterLeft>
                <FinancialExpressLogo />
            </FooterLeft>
            <FooterCenter>
                <FooterLinks />
            </FooterCenter>
            <FooterRight>
                <PoweredByWealthProLogo />
            </FooterRight>
            <FooterText />
        </Footer>
    </Application>
);

export default withProviders(PageScrollPositionProvider, ActiveUsersProvider, PersonalOutlookProvider)(MainLayout);
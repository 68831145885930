import { patchReplace } from "../../../helpers/patchDoc";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { useGetAdminInstructionCashByIdQuery, usePatchAdminInstructionCashMutation, usePatchAdminInstructionCashSourceMutation, useRecalculateTransactionsMutation } from "../../../services/headofficeadmin";
import { useRecalculateCashRaisedMutation } from "../../../services/headofficeadmin/hoAdminInstructionCashApiEndpoints";

const useAdminInstructionCash = () => {

    const { application } = useHeadOfficeApplicationContext();
    const { instructionId } = application || {};
    const { data, isError, error, isLoading, isUninitialized, refetch } = useGetAdminInstructionCashByIdQuery({ instructionId });
    const { instructionType } = data || {};
    const [patchTrigger, {isLoading: isPatching}] = usePatchAdminInstructionCashMutation();
    const [patchCashSourceTrigger] = usePatchAdminInstructionCashSourceMutation();
    const [recalculateCashTrigger, { isLoading: isRecalculatingCash }] = useRecalculateCashRaisedMutation();
    const [recalculateTransactionsTrigger, { isLoading: isRecalculatingTransactions }] = useRecalculateTransactionsMutation();

    const patchAdminInstructionCash = (property, newValue, appliesProperty) => {
        return new Promise((resolve, reject) => {
            let operations = [patchReplace(property, newValue)];

            if (appliesProperty) {
                operations.push(patchReplace(appliesProperty, true));
            }

            return patchTrigger({ instructionId, operations })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };


    const patchAdminInstructionCashSource = (sourceId, property, newValue, appliesProperty) => {
        return new Promise((resolve, reject) => {
            let operations = [patchReplace(property, newValue)];

            if (appliesProperty) {
                operations.push(patchReplace(appliesProperty, true));
            }

            return patchCashSourceTrigger({ sourceId, instructionId, operations })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const recalculateCash = () => {
        return new Promise((resolve, reject) => {
            return recalculateCashTrigger({ instructionId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const recalculateTransactions = () => {
        return new Promise((resolve, reject) => {
            return recalculateTransactionsTrigger({ instructionId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const retryGetAdminInstructionCash = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    };


    return {
        data,
        instructionType,
        isError,
        isRecalculatingCash,
        isRecalculatingTransactions,
        isPatching,
        error,
        isLoading: isLoading || isUninitialized,
        patchAdminInstructionCash,
        patchAdminInstructionCashSource,
        recalculateCash,
        recalculateTransactions,
        retryGetAdminInstructionCash,
    };
};

export default useAdminInstructionCash;
import moment from "moment";
import { toNString } from "../../../helpers/guids";
import { useAuth } from "react-oidc-context";
import { useEffect, useMemo, useState } from "react";
import { useCreateProjectMutation } from "../../../services/clientprojects";

const defaultState = {
    masterAccountId: null,
    description: "",
    comment: "",
    adviceType: 0,
    status: 0,
    adviserId: null,
    driverId: null,
    targetDate: moment().format(),
    projectValue: 0,
    campaignId: null,
    campaignStatus: null
};

const useNewProject = (masterAccountId, adviserId = null) => {
    // setup the base state of the new project
    const { user: { profile } } = useAuth();
    const [project, setProject] = useState({ 
        ...defaultState,
        masterAccountId: masterAccountId,
        targetDate: moment().format(),
        driverId: toNString(profile.sub),
        adviserId: adviserId
    });
    const [validationError, setValidationError] = useState(null);
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateProjectMutation();

    const createErrorMessage = useMemo(() => {
        const { message } = createError || {};
        if (message) {
            return message;
        }

        const { data } = createError || {};
        if (data && typeof(data) === "string") {
            return data;
        }

        if (!validationError) {
            return "";
        }

        if (typeof(validationError) === 'string') {
            return validationError;
        }

        if (Array.isArray(validationError)) {
            let completeMessage = "";
            validationError.forEach((el) => {
                completeMessage += ` ${el}`;
            });
            return completeMessage;
        }

        const { message: validationMessage } = validationError || {};
        return validationMessage;
    }, [ validationError, createError ]);

    useEffect(() => {
        if (masterAccountId !== project['masterAccountId']) {
            setProject({
                ...project,
                masterAccountId: masterAccountId
            });
        }
    }, [ masterAccountId ]);

    // this method will create the project on the correct microservice
    const createProject = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        return new Promise((resolve, reject) => {
            setValidationError(_ => null);

            let validationErrors = [];
            if (project['description'].length === 0) {
                validationErrors.push("Project description/name must be set.");
            }

            if (!project['adviserId']) {
                validationErrors.push("Adviser must be set.");
            }

            if (!project['driverId']) {
                validationErrors.push("Driver must be set.");
            }

            if (validationErrors.length > 0) {
                setValidationError(validationErrors);
                reject(validationErrors);
                return;
            }

            return create({ masterAccountId, data: project }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    // this will reset the project back to the base/empty state
    const clearProject = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        setProject({ 
            ...defaultState,
            masterAccountId: masterAccountId,
            targetDate: moment().format()
        });
    };

    // updates the base state that the default report object is housed. 
    // this will update a single property with the specified value
    const updateProject = (property, value) => {
        setProject({
            ...project,
            [property]: value
        });
    };

    return {
        clearProject,
        createError: createError || validationError,
        createErrorMessage,
        createProject,
        isCreating,
        //isErrorCreating: true,
        isErrorCreating: isErrorCreating === true || validationError,
        project,
        updateProject
    };
}

export default useNewProject;
import { wealthProApi } from '../../redux/api';

const recentDocumentEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["recentReportDocuments"]
})
.injectEndpoints({
    endpoints: (builder) => ({ 
        fetchRecentReportDocuments: builder.query({
            query: ({ page = 1, limit = 10 }) => `api/clientdocuments/recentdocuments?page=${page}&limit=${limit}`,
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },         
        })
    })
});

export const {
    useFetchRecentReportDocumentsQuery
} = recentDocumentEndpoints;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";
import { patchReplace } from "../../helpers/patchDoc";

/**
 * Helper class to split up endpoints as we need to update cache for multiple queries here...
 * 
 */

export const deleteMessageOnQueryStarted = async ({ messageId, threadId, masterAccountId, userId }, { dispatch, queryFulfilled }) => {
    try {
        const response = await queryFulfilled;

        const basicPaginationDeleteDispatch = (queryName, props) => dispatch(
            wealthProApi.util.updateQueryData(queryName,
                props,
                ({ results, pagination }) => {
                    return {
                        pagination: {
                            ...pagination,
                            totalCount: pagination.totalCount - 1
                        },
                        results: results.filter(x => x.messageId !== messageId)
                    }
                })
        );

        masterAccountId && basicPaginationDeleteDispatch('fetchClientMessages', { masterAccountId });
        threadId && basicPaginationDeleteDispatch('fetchMessagesByThreadId', { threadId });

        userId && dispatch(wealthProApi.util.updateQueryData('searchUserMessages', { userId }, ({ results, ...rest }) => {
            return {
                results: results.filter(x => x.messageId !== messageId),
                ...rest
            }
        }))

        userId && threadId && dispatch(wealthProApi.util.updateQueryData('fetchThreadMessagesForUser', { userId, threadId }, ({ results, totalCount, ...rest }) => {
            return {
                ...rest,
                results: results.filter(x => x.messageId !== messageId),
                totalCount: totalCount - 1
            };
        }));
    }
    catch { }
};

export const onPatchMessageQueryStarted = async ({ messageId, operations, threadId, masterAccountId, userId }, { queryFulfilled, dispatch }) => {

    const patchDispatch = (queryName, props) => dispatch(
        wealthProApi.util.updateQueryData(queryName,
            props,
            ({ results }) => {
                var message = results.find(el => el.messageId === messageId);
                if (!message) return;
                applyPatch(message, operations, true);
            }
        ));

    var results = [];

    masterAccountId && results.push(patchDispatch('fetchClientMessages', { masterAccountId }));
    threadId && results.push(patchDispatch('fetchMessagesByThreadId', { threadId }));
    userId && results.push(patchDispatch('searchUserMessages', { userId }));
    userId && threadId && results.push(patchDispatch('fetchThreadMessagesForUser', { userId, threadId }));

    try {
        await queryFulfilled;

    }
    catch {
        results.forEach((response) => {
            response?.undo();
        });
    }
};

export const onSendMessageQueryStarted = async ({ messageId, masterAccountId, threadId, userId }, { dispatch, queryFulfilled }) => {

    const basicPaginationSendDispatch = (queryName, props, data) => dispatch(
        wealthProApi.util.updateQueryData(
            queryName,
            props,
            ({ results, pagination }) => {

                var filteredResults = results.filter(x => x.messageId !== messageId);

                return {
                    pagination,
                    results: [data, ...filteredResults]
                };
            }
        )
    );

    try {
        var { data } = await queryFulfilled;

        threadId && basicPaginationSendDispatch('fetchMessagesByThreadId', { threadId }, data);
        masterAccountId && basicPaginationSendDispatch('fetchClientMessages', { masterAccountId }, data);
    }
    catch { }
};

export const updateMessageRecipientsOnQueryStarted = async ({ messageId, threadId, recipients, masterAccountId, userId }, { dispatch, queryFulfilled }) => {
    const mappedRecipients = recipients.map(x => ({ userId: x.value, recipientType: x.userType, name: x.label }));

    const updateRecipientsDispatch = (queryName, props) => dispatch(
        wealthProApi.util.updateQueryData(queryName,
            props,
            ({ results }) => {
                var message = results.find(el => el.messageId === messageId);
                if (!message) return;
                var recipientOperation = patchReplace("recipients", mappedRecipients);
                var operations = [recipientOperation];
                applyPatch(message, operations, true);
            })
    );


    let results = [];
    masterAccountId && results.push(updateRecipientsDispatch('fetchClientMessages', { masterAccountId }));
    threadId && results.push(updateRecipientsDispatch('fetchMessagesByThreadId', { threadId }));
    // userId && results.push(updateRecipientsDispatch('searchUserMessages', { userId }));
    userId && threadId && results.push(updateRecipientsDispatch('fetchThreadMessagesForUser', { userId, threadId }));

    try {
        var { data } = await queryFulfilled;
    }
    catch {
        results.forEach((response) => {
            response?.undo();
        });
    }
};
import React from "react"
import { Modal } from "react-bootstrap"
import { Button } from "../../../components"
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import { FormCheck, FormInput, FormSelect, PercentageInput } from "../../../components/forms";
import HeadOfficeMasterAccountFormSelect from "./HeadOfficeMasterAccountFormSelect";
import ProvidersFormSelectPaged from "./ProvidersFormSelectPaged";
import { BasicToast } from "../../../components/toasts";
import useCreateFeeGroup from "../hooks/useCreateFeeGroup";
import { useFilterContext } from "../../../hooks/FilterContext";
import ApplicationsFormSelect from "./ApplicationsFormSelect";

const CreateFeeGroupModal = ({ show, handleClose }) => {

    const { defaultValues, createFeeGroup } = useCreateFeeGroup();
    const { control, watch, formState: { errors }, handleSubmit, reset, setValue, getValues } = useForm({ defaultValues: defaultValues });

    const watchMasterAccountId = watch("masterAccountId");
    const watchVatable = watch("vatable");

    const onClose = () => {
        reset(defaultValues);
        handleClose();
    };

    const onSubmit = (data) => {
        createFeeGroup(data)
            .then(
                r => {
                    toast.custom((t) => <BasicToast
                        control={t}
                        date={new Date()}
                        icon="fa-solid fa-comments-question-check"
                        title="Fee Group Created"
                        message={`Your fee group was created! You may need to clear existing filters.`}
                    />);
                },
                e => toast.error(e?.data)
            )
            .finally(onClose);
    };

    return <Modal size="lg" centered show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Create a New Fee Group</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="masterAccountId"
                            control={control}
                            rules={{ required: "Master Account is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <HeadOfficeMasterAccountFormSelect
                                label={"Master Account"}
                                defaultValue={value}
                                errorAllowRetry={false}
                                disableAnimations
                                errorMessage={errors?.masterAccountId?.message}
                                onChange={(value) => setValue("masterAccountId", value.value)}
                                {...rest}
                            />
                            }
                        />
                    </div>
                    <div className="col">
                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: "Description is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <FormInput
                                label={"Description"}
                                value={value}
                                errorAllowRetry={false}
                                disableAnimations
                                errorMessage={errors?.description?.message}
                                onChange={(e) => setValue("description", e.target.value)}
                                {...rest}
                            />
                            }
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="providerId"
                            control={control}
                            rules={{ required: "Provider is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <ProvidersFormSelectPaged
                                label={"Provider"}
                                defaultValue={value}
                                errorAllowRetry={false}
                                disableAnimations
                                errorMessage={errors?.providerId?.message}
                                onChange={(value) => setValue("providerId", value.value)}
                                {...rest}
                            />
                            }
                        />
                    </div>
                    <div className="col">
                        <Controller
                            name="applicationId"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <ApplicationsFormSelect
                                label={"Application"}
                                defaultValue={value}
                                errorMessage={errors?.applicationId?.message}
                                onChange={(value) => setValue("applicationId", value.value)}
                                masterAccountId={watchMasterAccountId}
                                {...rest}
                            />
                            }
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="feeType"
                            control={control}
                            rules={{ required: "Fee Type is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <FormSelect
                                label={"Type"}
                                defaultValue={value}
                                options={[
                                    { label: "Intermediation", value: 0 },
                                    { label: "Service", value: 1 },
                                    { label: "Advice", value: 2 },
                                    { label: "Admin", value: 3 }
                                ]}
                                errorAllowRetry={false}
                                disableAnimations
                                errorMessage={errors?.feeType?.message}
                                onChange={(value) => setValue("feeType", value.value)}
                                {...rest}
                            />
                            }
                        />
                    </div>
                    <div className="col d-flex align-items-center">
                        <div className="me-2 flex-fill">
                            <Controller
                                name="vatable"
                                control={control}
                                rules={{ required: false }}
                                render={({ field: { ref, value, onChange, ...rest } }) => <FormCheck
                                    label={"VAT?"}
                                    isChecked={value}
                                    errorAllowRetry={false}
                                    disableAnimations
                                    errorMessage={errors?.vatable?.message}
                                    onChange={(value) => setValue("vatable", value)}
                                    {...rest}
                                />
                                }
                            />
                        </div>
                        <div className="flex-fill">
                            <Controller
                                name="vatRate"
                                control={control}
                                rules={{ required: "Vat Rate is required." }}
                                render={({ field: { ref, value, onChange, ...rest } }) => <PercentageInput
                                    label={"VAT Rate"}
                                    defaultValue={value}
                                    disabled={!watchVatable}
                                    disableAnimations
                                    isAllowed={({ floatValue }) => floatValue >= 0 && floatValue <= 100}
                                    onChange={(_, { floatValue }) => setValue("vatRate", floatValue)}
                                    {...rest}
                                />
                                }
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type="submit">Create</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </form>

    </Modal>
};

export default CreateFeeGroupModal;
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import Moment from "react-moment";
import styled from "styled-components";
import { Button, DateDisplay, IconButton } from "../../../components";
import { FormCheck } from "../../../components/forms";
import { AttachmentsToggle, ThemedParagraph, ThemedSpan } from "../../../components/utilities";
import MessageBox from "./MessageBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MessageActionMenuDropdown from "./MessageActionMenuDropdown";
import EditMessageModal from "../../editmessagemodal";
import { useAuth } from "react-oidc-context";

const MessageHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-column-gap: 20px;
`;

const ReadOnlyMessage = React.forwardRef(({
    isReplying,
    message,
    onDone,
    onReply,
    style
}, ref) => {

    const { user: { profile } } = useAuth();
    const userId = profile.sub.replace(/-/g, "");
    const [showEditModal, setShowEditModal] = useState(false);

    const {
        sentOnDate,
        message: messageText,
        recipients,
        senderName,
        doneByName,
        isDone,
        doneDate,
        threadSubject,
        messagePriority,
        isActionRequired,
        attachments,
        senderId
    } = message;

    const recipientsString = useMemo(() => recipients.map(x => x.displayName).join(", "), [recipients]);

    const replyButtonText = useMemo(() => recipients.length > 1 ? "Reply All" : "Reply", [recipients]);

    return <React.Fragment>
        <MessageBox ref={ref} className="rounded" style={style}>
            <MessageHeader className="align-items-center mb-1">
                <div className="d-flex">
                    <b>
                        <ThemedSpan>
                            {senderName ? `from ${senderName}` : ""} to {recipientsString} at <DateDisplay format="HH:mm DD/MM/YY">{sentOnDate}</DateDisplay>
                        </ThemedSpan>
                    </b>
                    {messagePriority === 30 && <div className="ms-2">
                        <OverlayTrigger overlay={
                            <Tooltip>High Priority</Tooltip>
                        }>
                            <FontAwesomeIcon className="text-danger" icon={"exclamation-circle"} size={"lg"} />
                        </OverlayTrigger>
                    </div>}
                </div>
                <div>
                    <ThemedSpan variant="muted">{threadSubject}</ThemedSpan>
                </div>

                <Button
                    onClick={onReply}
                    variant="success"
                    className="border-dark"
                    disabled={isReplying}
                >
                    {replyButtonText}
                </Button>
            </MessageHeader>
            <div className="row">
                <ThemedParagraph
                    variant="primary"
                >
                    {messageText}
                </ThemedParagraph>
            </div>
            <div className="d-flex mt-auto align-items-end">
                {isActionRequired && <div className="d-flex mt-auto align-items-end">
                    <FormCheck
                        onChange={() => onDone(!isDone)}
                        label={"Done"}
                        isChecked={isDone}
                    />
                    {
                        doneByName && doneDate && <ThemedSpan className="ms-2">
                            ({doneByName} at <Moment format="HH:mm DD/MM/YYYY">{doneDate}</Moment>)
                        </ThemedSpan>

                    }
                </div>}
                <div className="d-flex ms-auto">
                    {Array.isArray(attachments) && attachments.length > 0 && <div className="me-2">
                        <AttachmentsToggle attachments={attachments} />
                    </div>}
                    {senderId === userId && <MessageActionMenuDropdown onEditClick={() => setShowEditModal(true)} />}
                </div>
            </div>
        </MessageBox>
        <EditMessageModal
            data={message}
            onHide={() => setShowEditModal(false)}
            show={showEditModal}
        />
    </React.Fragment>;
});

export default ReadOnlyMessage;
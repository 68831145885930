import { useUpdateCredentialsMutation } from "../../../services/clientauth";

const useUpdateLoginCredentials = () => {
    const [_update, { isLoading, ...updateResult }] = useUpdateCredentialsMutation();

    const update = (masterAccountId, loginId, password) => {
        return new Promise((resolve, reject) => {
            return _update({ masterAccountId, loginId, data: { newPassword: password, isPasswordTemporary: false } })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { update, isLoading, data: { isLoading, ...updateResult } };
};

export default useUpdateLoginCredentials;
import _ from "lodash";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "react-oidc-context";
import { Button } from "../../../components";
import { DateInput, FormInput, NumberInput } from "../../../components/forms";
import { GenericUserSelect, ReportAuthorSelect, ReportStatusSelect, ReportTypesSelect } from "../../../components/forms/selects";
import useReportMessageThread from "../hooks/useReportMessageThread";
import ChangeAuthorMessage from "./ChangeAuthorMessage";

const EditClientAuthorModal = ({ 
    allowAssignAuthorRoles = ["sys_admin_access", "report_assign_author", "report_assign_self"],
    allowSetDeadlineDateRoles = ["sys_admin_access", "report_adjust_deadline"],
    allowAdjustMinutesRoles = ["sys_admin_access", "report_adjust_minutes"],
    allowAdjustTypeRoles = ["sys_admin_access", "report_adjust_type"],
    report = null, 
    show = false, 
    isLoading = false, 
    handleSave = () => {}, 
    handleClose = () => {}
}) => {
    const { user: { profile } } = useAuth();
    const { control, errors, formState, getValues, handleSubmit, reset, setValue, watch } = useForm({ defaultValues: report });
    const isAllowedToAssignAuthor = _.some(profile.roles, item => allowAssignAuthorRoles.includes(item));
    const isAllowedToSetDeadlineDate = _.some(profile.roles, item => allowSetDeadlineDateRoles.includes(item));
    const isAllowedToSetActualMinutes = _.some(profile.roles, item => allowAdjustMinutesRoles.includes(item));
    const isAllowedToSetReportType = _.some(profile.roles, item => allowAdjustTypeRoles.includes(item));

    const {
        message,
        threadId,
        setMessage,
        statuses,
        users,
        currentStatus
    } = useReportMessageThread();

    const authorId = watch('authorId');
    const status = watch('status');

    useEffect(() => {
        if (authorId === report?.authorId && status === report?.status)
            setMessage(null);
    }, [authorId, report?.authorId, report?.status, setMessage, status])

    const onSubmit = (data) => {
        const { dirtyFields } = formState;
        if (!dirtyFields || Object.keys(dirtyFields).length === 0) {
            handleClose();
        }

        const operations = Object.keys(dirtyFields).map(el => {
            return { op: 'replace', path: `/${el}`, value: getValues(el) };
        });

        if (!operations || operations.length === 0) {
            handleClose();
        }

        handleSave(operations, message);
    };

    React.useEffect(() => {
        if (!report) {
            return;
        }
        reset(report);
        setMessage(null);
    }, [ report ])

    const createMessage = (newStatus, newAuthorId = null) => {
        const { selector, defaultRecipients, isAuthorNotified } = newStatus;

        const newRecipients = isAuthorNotified
            ? [...defaultRecipients, newAuthorId ?? authorId]
            : defaultRecipients;

        setMessage(draft => {
            if (draft == null || newAuthorId == null)
                return {
                    authorId: newAuthorId ?? authorId,
                    status: selector,
                    message: {
                        threadId,
                        text: '',
                        recipients: newRecipients.filter(id => id !== profile.sub.replaceAll("-", ""))
                    }
                };

            // Rebuild the recipients
            const { isAuthorNotified, defaultRecipients } = statuses.find(el => el.selector === status);
            const oldDefaultRecipients = isAuthorNotified
                ? [...defaultRecipients, authorId]
                : defaultRecipients;

            return {
                ...draft,
                authorId: newAuthorId ?? authorId,
                status: selector,
                message: {
                    ...draft.message,
                    threadId,
                    // Recipients should be the new status's list with any user-added (non-default + author, if relevant) users from the old list
                    recipients: [
                        ...draft.message.recipients.filter(el => !oldDefaultRecipients.includes(el)),
                        ...newRecipients
                    ]
                }
            };
        })
    }

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Set Report Details</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className="row gy-3">
                        <div className="col-12">
                            <Controller
                                name="reportName"
                                control={control}
                                rules={{ required: "Report Name is required." }}
                                render={({ field: { ref, onChange, ...rest } }) => (
                                    <FormInput 
                                        label="Report Name" 
                                        disableAnimations={true}
                                        disabled={isLoading} 
                                        onChange={(e) => setValue('reportName', e.target.value, { shouldDirty: true })}
                                        {...rest} 
                                    />
                                )}
                            />
                        </div>
                        <div className="col-12">
                            <Controller
                                name="typeId"
                                control={control}
                                rules={{ required: "A Report Type must be selected." }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportTypesSelect
                                        label="Report Type"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={!isAllowedToSetReportType || isLoading}
                                        onChange={(values) => setValue('typeId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller
                                name="authorId"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportAuthorSelect
                                        label="Author"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={!isAllowedToAssignAuthor || isLoading}
                                        placeholder="No Author assigned."
                                        onChange={(values) => {
                                            // Don't dirty any of these changes so they're not picked up by the regular patch report
                                            setValue('authorId', values.value, { shouldDirty: false });

                                            let newStatus;
                                            if (!currentStatus.isTerminalStatus && !currentStatus.isComplianceStatus) {
                                                // Set to Author Assigned if the status is neither terminal nor compliance
                                                newStatus = statuses.find(el => el.selector === 0);

                                                setValue('statusId', newStatus.id, { shouldDirty: false });
                                                setValue('status', 0, { shouldDirty: false });
                                            } else {
                                                newStatus = currentStatus;
                                            }

                                            createMessage(newStatus, values.value);
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6 col-md-3">
                            <Controller
                                name="actualMinutesToComplete"
                                control={control}
                                render={({ field: { ref, onChange, ...rest } }) => (
                                    <NumberInput 
                                        label="Minutes to Complete" 
                                        disableAnimations={true} 
                                        decimalScale={0} 
                                        errorMessage={errors?.actualMinutesToComplete?.message}
                                        errorAllowRetry={false}
                                        disabled={!isAllowedToSetActualMinutes || isLoading}
                                        onChange={(e, value) => setValue('actualMinutesToComplete', value.floatValue, { shouldDirty: true })}
                                        {...rest} />
                                )}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <Controller
                                name="deadlineDate"
                                control={control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Deadline"
                                        placeholder="Choose Report deadline."
                                        disableAnimations={true}
                                        isClearable
                                        disabled={!isAllowedToSetDeadlineDate || isLoading}
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                setValue('deadlineDate', value, { shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller
                                name="statusId"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportStatusSelect
                                        label="Status"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={isLoading || !report?.authorId}
                                        masterAccountId={report?.masterAccountId}
                                        reportId={report?.id}
                                        onChange={(values) => {
                                            setValue('statusId', values.value, { shouldDirty: false });
                                            setValue('status', values.data.selector, { shouldDirty: false });

                                            const newStatus = statuses.find(el => el.id === values.value);

                                            if (newStatus.selector !== report.status || authorId !== report.authorId)
                                                createMessage(newStatus);
                                            else setMessage(null);
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller
                                name="complianceUserId"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect
                                        label="Compliance User"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={!isAllowedToAssignAuthor || isLoading}
                                        placeholder="No Compliance user assigned."
                                        onChange={(values) => setValue('complianceUserId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        {message != null && <ChangeAuthorMessage
                            showWarning={status !== report?.status}
                            message={message}
                            setMessage={setMessage}
                            users={users}
                        />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" disabled={isLoading === true}>{message == null ? 'Save Changes' : 'Save Changes & Send Message'}</Button>
                    <Button variant="light" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditClientAuthorModal;
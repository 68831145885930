import React, { useState } from "react";
import { useCreateAccessMutation } from "../../../services/clientdetails";

const useCreateAccessForm = (masterAccountId) => {
    const [_create, { isLoading, ...rest }] = useCreateAccessMutation();
    const [defaultValues, setDefaultValues] = useState({
        masterAccountId,
        userId: null,
        isAccessBlocked: false
    });

    const create = (data) => {
        return new Promise((resolve, reject) => {
            return _create({ ...data, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    React.useEffect(() => setDefaultValues(curr => ({ ...curr, masterAccountId })), [ masterAccountId ]);

    return {
        ...rest,
        create,
        defaultValues,
        isLoading
    };
};

export default useCreateAccessForm;
import styled from "styled-components";

const ProfileIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    white-space: nowrap;
    overflow: hidden;
`;

export default ProfileIconContainer;
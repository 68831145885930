import React, { useState } from "react";
import Button from "../../../../../components/buttons";
import { Modal } from "react-bootstrap";
import {
    DateInput
} from "../../../../../components/forms/";
import { components } from "react-select";
import { GenericAdviserSelect } from "../../../../../components/forms/selects";

const BusinessStatusReportModal = ({
    confirmButtonLabel = "Run Report",
    confirmButtonVariant = "success",
    handleBusinessStatusReportArgsClose,
    handleBusinessStatusReportArgsConfirm,
    businessStatusReportArgsShow,
    ...rest
}) => {

    const [theFromDate, setTheFromDate] = useState(null);
    const [theToDate, setTheToDate] = useState(null);
    const [theAdviserIds, setTheAdviserIds] = useState(null);

    const [errorMessage, setErrorMessage] = React.useState([]);

    const onClose = () => {
        if (handleBusinessStatusReportArgsClose && typeof handleBusinessStatusReportArgsClose === "function") {
            setErrorMessage("");
            setTheFromDate(null);
            setTheToDate(null);
            setTheAdviserIds(null);
            handleBusinessStatusReportArgsClose();
        }
    }

    const onConfirm = () => {
        if(theFromDate !== null && theToDate !== null){
            if (handleBusinessStatusReportArgsConfirm && typeof handleBusinessStatusReportArgsConfirm === "function") {
                const theRequest = {fromDate: theFromDate, toDate: theToDate, adviserIds: theAdviserIds}
                handleBusinessStatusReportArgsConfirm(theRequest);
            }
            onClose();
        }
        else{
            setErrorMessage("From Date and To Date must be selected!");
        }
    }

    return (
        <Modal size="med" centered backdrop="static" show={businessStatusReportArgsShow}>
            <Modal.Header>Business Status Report</Modal.Header>
            <Modal.Body {...rest}>
            <div className="row mb-3">
                <div className="col">
                        <DateInput
                            label="From"
                            value={theFromDate}
                            onBlur={(value) => setTheFromDate(value)}
                        />
                </div>
                <div className="col">
                        <DateInput
                            label="To"
                            value={theToDate}
                            onBlur={(value) => setTheToDate(value)}
                        />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <GenericAdviserSelect
                        label="Filter Advisers - All Advisers unless selections made"
                        placeholder="Filter Advisers..."
                        defaultValue={theAdviserIds}
                        isMulti={true}
                        onBlur={(values) => setTheAdviserIds(values.map((el) => el.value))}
                        //onBlur={(values) => console.log(values.map((el) => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="text-warning"> {errorMessage} </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmButtonVariant} onClick={onConfirm}>{confirmButtonLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                
            </Modal.Footer>
        </Modal>
    );


};

export default BusinessStatusReportModal;
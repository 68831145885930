import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, DateInput, FormInput, FormSelect, PercentageInput } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useClientAccounts from "../hooks/useClientAccounts";
import useMortgageArrangements from "../hooks/useMortgageArrangements";
import { InfoBubble } from "./FormInfoBubbleWrapper";
import SectionHeader from "./SectionHeader";

const MortgageArrangements = ({ setTotalMortgages }) => {

    const {
        realTimeLoanBalances,
        patchRealTimeLoanBalance,
        data,
        isLoading,
        isError,
        error,
        refetchMortgageArrangements,
        createMortgageArrangement,
        patchMortgageArrangement,
        removeMortgageArrangement,
        updateMortgageBorrowerMappings
    } = useMortgageArrangements();

    const { data: clientAccounts, isLoading: isLoadingClientAccounts } = useClientAccounts({ fetchType: 'list' });

    const totalMortgages = useMemo(() => {
        return realTimeLoanBalances?.reduce((acc, loanBalance) => acc + (loanBalance ?? 0), 0) ?? 0;
    }, [realTimeLoanBalances]);

    useEffect(() => {
        const timeout = setTimeout(() =>
            setTotalMortgages(totalMortgages), 50);

        return () => clearTimeout(timeout);
    }, [setTotalMortgages, totalMortgages]);

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchMortgageArrangements} />
        </ErrorMessageWrapper>;
    }

    return <React.Fragment>
        <div className="d-flex flex-row mt-4">
            <div className="col d-flex">
                <SectionHeader className="me-1 d-flex align-items-center">
                    Existing Mortgage Arrangements
                </SectionHeader>

                <InfoBubble
                    iconClassName={"my-auto mx-1"}
                    showBubble
                />
            </div>
        </div>

        {isLoading
            ? <Skeleton height={30} count={2} />
            : data.length === 0
            && <p className="mt-2">There are no recorded mortgage arrangements. Click <button className="anchor" onClick={createMortgageArrangement}>here</button> to create one</p>}

        {data.map((mortgage, index) =>
            <React.Fragment key={mortgage.mortgageId ?? index}>
                <GridWrapper
                    gridTemplateColumns={"1fr auto"}
                >
                    <div className="d-flex flex-row row col-start-1">
                        {/* First row (xl and above) */}
                        <div className="col-12 col-sm-7 col-md-4 col-lg-4 col-xl-3 mt-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <FormInput
                                    label="Property:"
                                    value={mortgage?.property}
                                    onBlur={(newValue) => patchMortgageArrangement(mortgage.mortgageId, "property", newValue)}
                                />}
                        </div>
                        <div className="col-sm-5 col-md-3 col-lg-3 col-xl-2 mt-0 mt-sm-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <CurrencyInput
                                    label="Value:"
                                    value={mortgage?.value}
                                    onBlur={(newValue) => patchMortgageArrangement(mortgage.mortgageId, "value", newValue)}
                                    fixedDecimalScale
                                    decimalScale={0}
                                />}
                        </div>
                        <div className="col-sm-6 col-md-5 col-lg-5 col-xl-4 mt-2 mt-md-0 mt-0 mt-md-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <FormInput
                                    label="Lender:"
                                    value={mortgage?.lender}
                                    onBlur={(newValue) => patchMortgageArrangement(mortgage.mortgageId, "lender", newValue)}
                                />}
                        </div>
                        <div className="col-sm-6 col-md-5 col-lg-3 col-xl-3 mt-2 mt-xl-0 mt-0 mt-xl-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <FormSelect
                                    label="Borrower:"
                                    options={clientAccounts}
                                    isLoadingOptions={isLoadingClientAccounts}
                                    isMulti
                                    defaultValue={mortgage?.borrowerMappings.map(x => x.clientAccountId)}
                                    onBlur={(newValues) => updateMortgageBorrowerMappings(mortgage.mortgageId, newValues.map(x => x.value))}
                                />}
                        </div>

                        {/* Second row (xxl and above) */}
                        <div className="col-sm-5 col-md-3 col-lg-3 col-xl-2 mt-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <CurrencyInput
                                    label="Loan Balance:"
                                    value={mortgage?.loanBalance}
                                    onChange={(_, { floatValue }) => patchRealTimeLoanBalance(index, floatValue)}
                                    onBlur={(newValue) => patchMortgageArrangement(mortgage.mortgageId, "loanBalance", newValue)}
                                    fixedDecimalScale
                                    decimalScale={0}
                                />}
                        </div>
                        <div className="col-sm-7 col-md-4 col-lg-3 col-xl-4 mt-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <FormSelect
                                    label="Mortgage Type:"
                                    defaultValue={mortgage?.mortgageType}
                                    onBlur={({ value }) => patchMortgageArrangement(mortgage.mortgageId, "mortgageType", value)}
                                    options={["Repayment", "Interest Only"].map((label, index) => ({ label, value: (index + 1) * 10 }))}
                                />}
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-3 col-xl-2 mt-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <DateInput
                                    label="Maturity Date:"
                                    value={mortgage?.maturityDate}
                                    isClearable
                                    onBlur={(newValue) => patchMortgageArrangement(mortgage.mortgageId, "maturityDate", newValue)}
                                />}
                        </div>

                        {/* Third row (xxl and above) */}
                        <div className="col-sm-7 col-md-4 col-lg-3 col-xl-4 mt-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <FormSelect
                                    label="Interest:"
                                    defaultValue={mortgage?.interestRateType}
                                    onBlur={({ value }) => patchMortgageArrangement(mortgage.mortgageId, "interestRateType", value)}
                                    options={["Fixed Rate", "Variable Rate", "Tracker", "Discounted"].map((label, index) => ({ label, value: (index + 1) * 10 }))}
                                />}
                        </div>
                        <div className="col-sm-9 col-lg-7 col-xl-6 align-self-end mt-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <FormInput
                                    label="Main Terms:"
                                    value={mortgage?.mainTerms}
                                    onBlur={(newValue) => patchMortgageArrangement(mortgage.mortgageId, "mainTerms", newValue)}
                                />}
                        </div>
                        <div className="col-sm-3 col-lg-2 col-xl-2 mt-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <PercentageInput
                                    label="Interest Rate:"
                                    value={mortgage.interestRate}
                                    onBlur={(newValue) => patchMortgageArrangement(mortgage.mortgageId, "interestRate", newValue)}
                                />}
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-3 col-xl-2 mt-2">
                            {isLoading || !mortgage.mortgageId
                                ? <Skeleton />
                                : <DateInput
                                    label="Expiry Date:"
                                    value={mortgage.expiryDate}
                                    isClearable
                                    onBlur={(newValue) => patchMortgageArrangement(mortgage.mortgageId, "expiryDate", newValue)}
                                />}
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <IconButton
                            className={`${index !== 0 ? "invisible" : ""}`}
                            icon="plus"
                            variant="success"
                            onClick={createMortgageArrangement}
                        />
                        <IconButton
                            className="mt-1"
                            icon="trash-can"
                            variant="danger"
                            onClick={() => removeMortgageArrangement(mortgage.mortgageId)}
                            disabled={isLoading || !mortgage.mortgageId}
                        />
                    </div>
                </GridWrapper>
            </React.Fragment>
        ).flatMap((mortgage) => [mortgage, <hr key={`${mortgage.mortgageId}-line`} />])
            .slice(0, -1)}
    </React.Fragment>

};

export default MortgageArrangements;
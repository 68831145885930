import { useImpersonateMasterAccountDashboardLoginMutation } from "../../../services/clientauth";

const useCreateImpersonationToken = () => {
    const [_create, { isLoading, ...createResult }] = useImpersonateMasterAccountDashboardLoginMutation();

    const create = (masterAccountId, loginId) => {
        return new Promise((resolve, reject) => {
            return _create({ masterAccountId, loginId })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { create, isLoading, data: { isLoading, ...createResult } };
};

export default useCreateImpersonationToken;
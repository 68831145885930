import { wealthProApi } from "../../redux/api";

const factFindOptionsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchMaritalStatuses: builder.query({
            query: () => 'api/factfind/options/maritalstatuses'
        }),
        fetchEmploymentStatuses: builder.query({
            query: () => 'api/factfind/options/employmentstatuses'
        }),
        fetchRelationships: builder.query({
            query: () => 'api/factfind/options/relationships'
        }),
        fetchExpenditureFrequencies: builder.query({
            query: () => 'api/factfind/options/ExpenditureFrequencies'
        }),
        fetchProductTypes: builder.query({
            query: ({ sort = "DisplayOrder" }) => `api/factfind/options/producttypes?sort=${sort}`
        }),
        fetchCoverTypes: builder.query({
            query: ({ productTypeId, sort = "DisplayOrder" }) => productTypeId == null
                ? `api/factfind/options/covertypes?sort=${sort}`
                : `api/factfind/options/covertypes?productTypeId=${productTypeId}&sort=${sort}`
        }),
        fetchFFDesignations: builder.query({
            query: ({ masterAccountId }) => `api/factfind/options/Designations/${masterAccountId}`
        }),
        fetchFFWrappers: builder.query({
            query: () => `api/factfind/options/Wrappers`
        }),
        fetchFFClientProfiles: builder.query({
            query: () => `api/factfind/options/ClientProfiles`
        }),
        fetchFFStandardPortfolios: builder.query({
            query: () => `api/factfind/options/StandardPortfolios`
        }),
        fetchCustodians: builder.query({
            query: ({ productWrapperCategory }) => `api/factfind/options/Custodians/${productWrapperCategory}`
        }),
        fetchVolatilityOptions: builder.query({
            query: () => `api/factfind/options/VolatilityOptions`
        }),
        fetchAssetTypes: builder.query({
            query: ({ sort }) => `api/factfind/options/assettypes?sort=${sort}`
        }),
        fetchBorrowerTypes: builder.query({
            query: () => `api/factfind/options/BorrowerTypes`
        }),
        fetchPropertyTypes: builder.query({
            query: () => `api/factfind/options/PropertyTypes`
        }),
        fetchMortgageTypes: builder.query({
            query: () => `api/factfind/options/MortgageTypes`
        })
    })
});

export const {
    useFetchEmploymentStatusesQuery,
    useFetchMaritalStatusesQuery,
    useFetchExpenditureFrequenciesQuery,
    useFetchRelationshipsQuery,
    useFetchProductTypesQuery,
    useFetchCoverTypesQuery,
    useFetchFFDesignationsQuery,
    useFetchFFWrappersQuery,
    useFetchFFClientProfilesQuery,
    useFetchFFStandardPortfoliosQuery,
    useLazyFetchCustodiansQuery,
    useFetchVolatilityOptionsQuery,
    useFetchAssetTypesQuery,
    useFetchBorrowerTypesQuery,
    useFetchMortgageTypesQuery,
    useFetchPropertyTypesQuery
} = factFindOptionsApiEndpoints;
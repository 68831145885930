import styled from "styled-components";

const IconLink = styled.a`
    padding: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.55);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    &:hover {
        color: rgba(0, 0, 0, 0.7);
    }
`;

export default IconLink;
import React from "react";

const ProjectReportsTableEmptyRow = ({ columns, onCreateClicked = () => { }, ...rest }) => (
    <tr {...rest}>
        <td colSpan={columns.length}>
            There are no reports available for this Project.
            You can click <a href="#" onClick={onCreateClicked}>here</a> to create one.
        </td>
    </tr>
);

export default ProjectReportsTableEmptyRow;
import React, { useMemo } from "react";
import { VirtualTable } from "../../components/tables";
import { useClientDocuments } from "../../hooks/ClientDocumentsContext";
import ClientDocumentsTableHeader from "./components/ClientDocumentsTableHeader";
import ClientDocumentsTableRow from "./components/ClientDocumentsTableRow";

const ClientDocumentsTable = () => {
    const {
        data,
        error,
        isNextPageLoading,
        isRowLoaded,
        loadMore,
        totalCount
    } = useClientDocuments();

    return (
        <React.Fragment>
            <VirtualTable 
                enableScrollTracking
                row={ClientDocumentsTableRow}
                header={(
                    <thead>
                        <tr>
                            <ClientDocumentsTableHeader property="documentType" className={"text-center"}>Type</ClientDocumentsTableHeader>
                            <ClientDocumentsTableHeader property="functionDescription" width="12.5%">Function</ClientDocumentsTableHeader>
                            <ClientDocumentsTableHeader property="fileName" width="17.5%">File Name</ClientDocumentsTableHeader>
                            <ClientDocumentsTableHeader property="documentDescription" width="25%">Description</ClientDocumentsTableHeader>
                            <ClientDocumentsTableHeader property="projectDescription" width="25%">Project</ClientDocumentsTableHeader>
                            <ClientDocumentsTableHeader property="clientReadable" className={"text-center"}>Readable</ClientDocumentsTableHeader>
                            <ClientDocumentsTableHeader property="dateUploaded">Date Uploaded</ClientDocumentsTableHeader>
                            <ClientDocumentsTableHeader> </ClientDocumentsTableHeader>
                        </tr>
                    </thead>
                )}
                error={error}
                isLoading={isNextPageLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={35}
                itemCount={totalCount}
                itemData={data}
            />
        </React.Fragment>
    );
};

export default ClientDocumentsTable;


/*
 const [selectedRow, setSelectedRow] = React.useState(null);
    const { data: projects } = useClientProjectContext();
    const { data: documentFunctions } = useDocumentFunctionsData();
    const { data, isRowLoaded, loadMore, totalCount } = tableData;

    const onRowChanged = (documentId) => setSelectedRow(_ => documentId);

    const projectsForGrid = React.useMemo(() => {
        if (!projects) {
            return [];
        }

        const newList = [...projects];
        newList.unshift({ "label": "None", "value": null });
        
        return newList;
    }, [projects]);


const baseHeaderProperties = { headerRenderer: ClientDocumentsTableHeader, onHeaderClick: sortTable, sort };
    return (
        <React.Fragment>
            <StandardTable 
                parentClassName="h-100"
                columns={[
                    { label: "Type", property: "typeDescription", ...baseHeaderProperties, headerClassName: "text-center" },
                    { label: "Function", property: "functionDescription", ...baseHeaderProperties },
                    { label: "File Name", property: "fileName", ...baseHeaderProperties },
                    { label: "Description", property: "documentDescription", ...baseHeaderProperties }, 
                    { label: "Project", property: "projectDescription", ...baseHeaderProperties },
                    { label: "Readable", property: "clientReadable", ...baseHeaderProperties, headerClassName: "text-center" },
                    { label: "Date Uploaded", property: "dateUploaded", ...baseHeaderProperties },
                    { label: "", property: "" },
                ]}
                data={data}
                error={error}
                isLoading={isLoading}
                ErrorRowComponent={ClientDocumentsTableErrorRow}
                errorRowComponentProps={{
                    retryFn: reload
                }}
                RowComponent={ClientDocumentsTableRow}
                rowComponentProps={{
                    projects: projectsForGrid,
                    documentFunctions,
                    openDocument,
                    selectedRow,
                    setSelectedRow: onRowChanged,
                    updateClientReadable,
                    updateFunction,
                    updateProject,
                    updateDescription,
                }}
            />
            {hasMore && !isLoading && !isFetching && !isError && (
                <div className="d-flex justify-content-center my-3">
                    <a href="#" onClick={loadMore}><span>Load more</span></a>
                </div>
            )}
            {isFetching && !isLoading && (
                <CenterArea className="my-3">
                    <Spinner />
                </CenterArea>
            )}
        </React.Fragment>
    );
*/
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const icFundRulesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (build) => ({
        fetchClassGroupsForSelect: build.query({
            query: ({ clientProfileId }) => `api/assets/clientprofile/${clientProfileId}/classgroups`
        }),
        fetchFundRules: build.query({
            query: ({ clientProfileId }) => `api/assets/clientprofile/${clientProfileId}/fundspecific`
        }),
        createFundRule: build.mutation({
            query: ({ clientProfileId }) => ({
                url: `api/assets/clientprofile/${clientProfileId}/fundspecific`,
                method: "POST"
            }),
            async onQueryStarted({ clientProfileId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newId } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("fetchFundRules", { clientProfileId }, (draft) => {
                        draft.push({ ruleId: newId, weighting: 1 });
                    }));
                } catch (error) {
                    console.error("Error creating fund specific rule", error);
                }
            }
        }),
        patchFundRule: build.mutation({
            query: ({ ruleId, operations }) => ({
                url: `api/assets/fundspecificrule/${ruleId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ clientProfileId, ruleId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const updateRuleResult = dispatch(wealthProApi.util.updateQueryData("fetchFundRules", { clientProfileId }, (draft) => {
                        applyPatch(draft.find((el) => el.ruleId === ruleId), operations);
                    }));
                    
                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error patching fund specific rule", error);
                        updateRuleResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating query data", error);
                    throw error;
                }
            }
        }),
        deleteFundRule: build.mutation({
            query: ({ ruleId }) => ({
                url: `api/assets/fundspecificrule/${ruleId}`,
                method: "DELETE"
            }),
            async onQueryStarted({ clientProfileId, ruleId }, { dispatch, queryFulfilled }) {
                try {
                    const updateRuleResult = dispatch(wealthProApi.util.updateQueryData("fetchFundRules", { clientProfileId }, (draft) => {
                        draft.splice(draft.findIndex((el) => el.ruleId === ruleId), 1);
                    }));

                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error deleting fund specific rule", error);
                        updateRuleResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating query data", error);
                    throw error;
                }
            }
        })
    })
});

export const {
    useFetchClassGroupsForSelectQuery,
    useLazyFetchFundRulesQuery,
    useCreateFundRuleMutation,
    usePatchFundRuleMutation,
    useDeleteFundRuleMutation
} = icFundRulesApiEndpoints;
import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, FormInput, FormSelect, RichTextEditor } from "../../../components/forms";
import { BooleanSelect } from "../../../components/forms/selects";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useClientAccounts from "../hooks/useClientAccounts";
import useFinalSalaryArrangements from "../hooks/useFinalSalaryArrangements";

const FormRow = ({ children, className }) => <div className={`row mb-2 ${className ?? ""}`}>{children}</div>;

const FinalSalaryArrangements = () => {

    const {
        data,
        isLoading,
        isError,
        error,
        refetch,
        pensionTypes,
        updateFinalSalaryArrangement,
        deleteFinalSalaryArrangement,
    } = useFinalSalaryArrangements();


    const { data: clientAccounts, isLoading: isLoadingClients } = useClientAccounts({ fetchType: "list" });

    const patchArrangement = (id, property, value) => updateFinalSalaryArrangement(id, property, value);

    const removeArrangement = (id) => deleteFinalSalaryArrangement(id);

    const isLoadingOrAdding = useCallback((arrangement) => isLoading || !arrangement.finalSalaryId, [isLoading])

    if (isError)
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetch} />
        </ErrorMessageWrapper>;

    return <React.Fragment>
        {data.map((finalSalaryArrangement, index) => {
            return <div className="container-fluid px-0" key={finalSalaryArrangement.finalSalaryId ?? index}>
                <FormRow>
                    <div className="col-6 col-md">
                        {isLoadingOrAdding(finalSalaryArrangement)
                            ? <Skeleton />
                            : <FormInput
                                label={"Pension Name"}
                                value={finalSalaryArrangement.pensionName}
                                onBlur={(value) => patchArrangement(finalSalaryArrangement.finalSalaryId, "pensionName", value)}
                            />}
                    </div>
                    <div className="col-6 col-md">
                        {isLoadingOrAdding(finalSalaryArrangement)
                            ? <Skeleton />
                            : <FormSelect
                                label={"Type"}
                                options={pensionTypes}
                                isDisabled={true}
                                defaultValue={50}
                            />}
                    </div>
                    <div className="col-6 col-md">
                        {isLoadingOrAdding(finalSalaryArrangement)
                            ? <Skeleton />
                            : <FormSelect
                                label={"Owner"}
                                options={clientAccounts}
                                isLoadingOptions={isLoadingClients}
                                defaultValue={finalSalaryArrangement.clientAccountId}
                                onBlur={(e) => patchArrangement(finalSalaryArrangement.finalSalaryId, "clientAccountId", e.value)}
                            />}
                    </div>
                    <div className="col-1">
                        {isLoadingOrAdding(finalSalaryArrangement)
                            ? <Skeleton />
                            : <FormInput
                                label={"NRA"}
                                value={finalSalaryArrangement.nra}
                                onBlur={(value) => patchArrangement(finalSalaryArrangement.finalSalaryId, "nra", value)}
                            />}
                    </div>
                    <div className="col-auto mt-auto">
                        <IconButton
                            icon={"fa-trash"}
                            variant={"danger"}
                            onClick={() => removeArrangement(finalSalaryArrangement.finalSalaryId)}
                            disabled={isLoadingOrAdding(finalSalaryArrangement)}
                        />
                    </div>
                </FormRow>
                <FormRow className={"gx-5"}>
                    <div className="col-3">
                        <CurrencyInput
                            label={"Annual Income"}
                            value={finalSalaryArrangement.annualIncome}
                            fixedDecimalScale={false}
                            allowNegative={false}
                            onBlur={(value) => patchArrangement(finalSalaryArrangement.finalSalaryId, "annualIncome", value)}
                        />
                    </div>
                    <div className="col-auto">
                        <BooleanSelect
                            label={"In Payment"}
                            defaultValue={finalSalaryArrangement.isInPayment}
                            onBlur={({ value }) => patchArrangement(finalSalaryArrangement.finalSalaryId, "isInPayment", value)}
                        />
                    </div>
                    {!finalSalaryArrangement.isInPayment && <div className="col-3">
                        <CurrencyInput
                            label={"Total Tax Free Cash Entitlement"}
                            value={finalSalaryArrangement.taxFreeCashEntitlement}
                            fixedDecimalScale={false}
                            allowNegative={false}
                            onBlur={(value) => patchArrangement(finalSalaryArrangement.finalSalaryId, "taxFreeCashEntitlement", value)}
                        />
                    </div>}
                </FormRow>
                <FormRow>
                    <RichTextEditor
                        defaultValue={finalSalaryArrangement.notes}
                        onBlur={(value) => patchArrangement(finalSalaryArrangement.finalSalaryId, "notes", value)}
                    />
                </FormRow>
            </div>
        }).flatMap((finalSalaryArrangement, index) => index !== data.length - 1
            ? [finalSalaryArrangement, <hr key={index} />]
            : [finalSalaryArrangement])}
    </React.Fragment>


};

export default FinalSalaryArrangements;
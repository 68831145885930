import { InputGroup } from "react-bootstrap";

const FormInputGroup = ({ children, className, hasLabel, horizontal, ...rest }) => {
    const size = hasLabel === true ? 'col-sm-10' : 'col-sm-12';
    if (horizontal === true) {
        return <div className={className ? `${className} ${size}` : size} {...rest}>
            {children}
        </div>
    }
    return <InputGroup className={className} {...rest}>
        {children}
    </InputGroup>
};

export default FormInputGroup;
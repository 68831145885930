import React from "react";
import ThemedSpan from "./ThemedSpan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Stars = ({ count = 0, color = "#d4af37" }) => {
    if (count <= 0) {
        return (
            <div>
                <ThemedSpan variant="muted">Unrated</ThemedSpan>
            </div>
        )
    }
    return (
        <div>
            {[...Array(5)].map((_, i) => (
                <FontAwesomeIcon 
                    key={i} 
                    style={{ color: color }}
                    icon={i >= count ? 'far fa-star' : 'fas fa-star'} 
                />
            ))}
        </div>
    );
};

export default Stars;
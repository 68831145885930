import _ from "lodash";
import React, { useEffect, useState } from "react";
import Button from "../../components/buttons";
import { Modal } from "react-bootstrap";
import { useFilterContext } from "../../hooks/FilterContext";
import { FormSelect, DateInput, FormInput } from "../../components/forms/";
import { GenericUserSelect } from "../../components/forms/selects";
import { GenericAdviserSelect } from "../../components/forms/selects";

export const AdministrationRemindersFiltersModalButton = ({
    className,
    variant = "primary",
    ...rest
}) => {
    return (
        <div className={className}>
            <Button variant={variant} {...rest}>
                Filter Reminders
            </Button>
        </div>
    );
};

export const AdministrationRemindersFiltersModal = ({
    handleClose,
    onFiltersChanged,
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter((original) => {
            return {
                ...original,
                ...tempFilters,
            };
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters((_) => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters((_) => filter);
        }
    }, [filter]);

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>Admin Reminders Filters</Modal.Header>
            <Modal.Body {...rest}>
                <AdministrationRemindersFilters
                    filter={tempFilters}
                    setFilter={onFilterValueChanged}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>
                    Clear Filters
                </Button>
                <Button variant="success" onClick={onApplyFilters}>
                    Apply Filters
                </Button>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const AdministrationRemindersFilters = ({ filter, setFilter }) => {
    const {
        message,
        reminderDateStart,
        reminderDateEnd,
        completedByIds,
        completedDateStart,
        completedDateEnd,
    } = filter;

    console.log("filter", filter);

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col">
                    <FormInput
                        id="message"
                        label="Message"
                        value={message}
                        placeholder="Message"
                        onBlur={(value) => setFilter("message", value)}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <DateInput
                        label="Reminder Date From"
                        placeholder="Reminder From"
                        isClearable
                        disableAnimations={true}
                        value={reminderDateStart}
                        onBlur={(value, _) =>
                            setFilter("reminderDateStart", value)
                        }
                    />
                </div>
                <div className="col">
                    <DateInput
                        label="Reminder Date To"
                        placeholder="Reminder To"
                        isClearable
                        disableAnimations={true}
                        value={reminderDateEnd}
                        onBlur={(value, _) =>
                            setFilter("reminderDateEnd", value)
                        }
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <GenericUserSelect
                        label="Completed By"
                        isMulti={true}
                        defaultValue={completedByIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "completedByIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <DateInput
                        label="Completed Date From"
                        value={completedDateStart}
                        placeholder="Completed From"
                        isClearable
                        disableAnimations={true}
                        onBlur={(value, _) =>
                            setFilter("completedDateStart", value)
                        }
                    />
                </div>
                <div className="col">
                    <DateInput
                        label="Completed Date To"
                        placeholder="Completed To"
                        isClearable
                        disableAnimations={true}
                        value={completedDateEnd}
                        onBlur={(value, _) =>
                            setFilter("completedDateEnd", value)
                        }
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default AdministrationRemindersFilters;

import _ from "lodash";
import fetchMasterAccountDashboardLoginsCacheSocketHandler from "./sockets/fetchMasterAccountDashboardLoginsCacheSocketHandler";
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

const buildFetchMasterAccountDashboardLogins = ({ masterAccountId, searchText, sort = null, page = 1, limit = 25 }, list = false) => {
    let baseQuery = list === false 
        ? `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins?page=${page}&limit=${limit}`
        : `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/list?page=${page}&limit=${limit}`

    if (sort && sort.length > 0) {
        baseQuery += baseQuery.includes('?') ? `&sort=${sort}` : `?sort=${sort}`;
    }

    if (searchText && searchText.length > 0) {
        var searchTermEncode = encodeURIComponent(searchText);
        baseQuery += `&searchTerm=${searchTermEncode}`;
    }

    return baseQuery;
};

const clientAuthApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["clientAuth"]
}).injectEndpoints({
    endpoints: (builder) => ({
        createMasterAccountDashboardLogin: builder.mutation({
            query: ({ data, masterAccountId }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins`,
                method: 'POST',
                body: data
            })
        }),
        deleteMasterAccountDashboardLogin: builder.mutation({
            query: ({ loginId, masterAccountId }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ loginId, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountDashboardLogins', { masterAccountId }, (data) => {
                        return data.filter(el => el.id !== loginId);
                    }));
                }
                catch { }
            }
        }),
        fetchMasterAccountDashboardLogins: builder.query({
            query: (props) => buildFetchMasterAccountDashboardLogins(props),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            onCacheEntryAdded: fetchMasterAccountDashboardLoginsCacheSocketHandler(true)
        }),
        impersonateMasterAccountDashboardLogin: builder.mutation({
            query: ({ masterAccountId, loginId }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/impersonate`,
                method: 'POST',
                body: { }
            }),
        }),
        patchMasterAccountDashboardLogin: builder.mutation({
            query: ({ masterAccountId, loginId, operations }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ loginId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const fetchEndpoint = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountDashboardLogins', { masterAccountId }, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.id === loginId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));
                
                try {
                    await queryFulfilled;
                }
                catch { 
                    fetchEndpoint.undo();
                }
            }
        }),
        sendMasterAccountDashboardLoginPasswordResetEmail: builder.mutation({
            query: ({ masterAccountId, loginId, emailAddress }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/reset/code`,
                method: 'POST',
                body: { emailAddress }
            })
        }),
        sendMasterAccountDashboardLoginWelcomeEmail: builder.mutation({
            query: ({ masterAccountId, loginId }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/email/welcome`,
                method: 'POST',
                body: { }
            })
        }),
        unlockMasterAccountDashboardLogin: builder.mutation({
            query: ({ masterAccountId, loginId }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/unlock`,
                method: 'POST',
                body: { }
            })
        })
    })
});

export const {
    useCreateMasterAccountDashboardLoginMutation,
    useDeleteMasterAccountDashboardLoginMutation,
    useFetchMasterAccountDashboardLoginsQuery,
    useImpersonateMasterAccountDashboardLoginMutation,
    useLazyFetchMasterAccountDashboardLoginsQuery,
    usePatchMasterAccountDashboardLoginMutation,
    useSendMasterAccountDashboardLoginPasswordResetEmailMutation,
    useSendMasterAccountDashboardLoginWelcomeEmailMutation,
    useUnlockMasterAccountDashboardLoginMutation
} = clientAuthApiEndpoints;
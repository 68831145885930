import React, { useState } from "react";
import CreatePensionArrangementModal from "./CreatePensionArrangementModal";
import usePensionArrangements from "../hooks/usePensionArrangements";
import { IconButton } from "../../../components/buttons";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "./SectionHeader";
import PensionArrangement from "./PensionArrangement";

const PensionsArrangementsList = ({ hasFinalSalaryArrangements }) => {
    const {
        data,
        isError,
        error,
        isLoading,
        createArrangement,
        createFinalSalaryArrangement,
        patchArrangement,
        removeArrangement,
        refetchPensionArrangements
    } = usePensionArrangements();

    const [showModal, setShowModal] = useState(false);

    const createPensionPlan = ({ pensionName, pensionType }) => {
        if (pensionType === 50) { // 50 === final salary
            return createFinalSalaryArrangement({ pensionName });
        }

        return createArrangement({ pensionName, pensionType });
    };

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchPensionArrangements} />
        </ErrorMessageWrapper>;
    }

    return <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-3">
            <SectionHeader>Pension Arrangements</SectionHeader>
            {(data?.length > 0 || hasFinalSalaryArrangements)
                && <IconButton
                    icon={"fa-plus"}
                    variant="success"
                    onClick={() => setShowModal(_ => true)}
                />}
        </div>
        {isLoading
            ? <Skeleton count={4} />
            : (data?.length === 0 && !hasFinalSalaryArrangements)
            && <p className="mt-2">There are no recorded pension arrangements. Click <button className="anchor" onClick={() => setShowModal(_ => true)}>here</button> to create one</p>}

        {data?.map((pensionArrangement, index) => {
            return <PensionArrangement
                key={pensionArrangement.pensionPlanId ?? index}
                patchArrangement={patchArrangement}
                pensionArrangement={pensionArrangement}
                removeArrangement={removeArrangement}
                isLoading={isLoading || !pensionArrangement.pensionPlanId}
            />
        }).flatMap((arrangement, index) => hasFinalSalaryArrangements || index !== data.length - 1
            ? [arrangement, <hr key={index} />]
            : [arrangement])}
        <CreatePensionArrangementModal
            show={showModal}
            setShow={setShowModal}
            onSubmit={createPensionPlan}
        />
    </React.Fragment>;

};

export default PensionsArrangementsList;
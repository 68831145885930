import React, { useEffect } from "react";
import { FeeCollection, InvestmentSelection, ProductSelection, SourceOfFunds } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

const ISASubscription = ({ instructionId }) => {
    const [{ fetchInvest }, { realTimeInstruction, investIsError }] = useInstruction();

    useEffect(() => {
        fetchInvest({ instructionId });
    }, [fetchInvest, instructionId]);

    const retry = () => {
        fetchInvest({ instructionId });
    }

    if (investIsError)
        return <InvestDivestErrorMessage retry={retry} />

    return <div>
        <ProductSelection
            isInvest
            changeOwner
            productLabel="ISA Product:"
        />

        <SourceOfFunds hasAps isIsaSub totalLabel="Total Subscription:" />

        <InvestmentSelection maxInvestmentAmount={realTimeInstruction?.invest?.totalSubscriptionAmount} />

        <FeeCollection />
        {/* <AnnualCostsDisclosureTable /> */}
    </div>;
};

export default ISASubscription;

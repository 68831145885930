import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { IconButton } from "../../../components";
import { FormInput, FormSelect, FormTextArea } from "../../../components/forms";
import useMessageUsers from "../hooks/useMessageUsers";
import MessageBox from "./MessageBox";
import { ThemedSpan } from "../../../components/utilities";
import SendMessageConfirmationModal from "./SendMessageConfirmationModal";

const MessageHeader = styled.div`
    grid-column-gap: 20px;
`;

const HeaderIconWrapper = styled.div`
    width: 40px;
    max-width: auto;
    padding: 0;
`;

const EditableMessage = ({
    messageId,
    isDraftMessage,
    initialMessage = "",
    initialRecipients = [],
    initialSubject = "",
    messageOnBlur,
    messageOnChange,
    recipientsOnChange,
    recipientsOnBlur,
    priorityOnChange,
    requiresActionOnChange,
    threadSubjectOnBlur,
    onSend,
    onDelete,
    isEditing,
    priority,
    requiresAction,
    canEditSubject
}) => {

    const { results } = useMessageUsers();
    const [shouldSend, setShouldSend] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(initialMessage);
    const [showModal, setShowModal] = useState(false);

    const onClickSend = () => {
        setShouldSend(_ => true);
    };

    const recipientOptions = useMemo(() => {
        if (!results || !Array.isArray(results)) {
            return [];
        }

        return results;
    }, [results]);

    const handleOnMessageChange = (e) => {
        setCurrentMessage(e.target.value);
        messageOnChange(e);
    }

    useEffect(() => {
        if (!(isEditing === false && shouldSend === true)) return;

        onSend();

    }, [isEditing, shouldSend]);


    return <React.Fragment>
        <MessageBox className="mb-3 rounded" isDraft={isDraftMessage}>

            <div className="row mb-4">
                <MessageHeader className="row d-flex">
                    <div className="col d-flex flex-row align-items-center">
                        <div className="col-auto">
                            <ThemedSpan variant="" className={"me-3"}>
                                to:
                            </ThemedSpan>
                        </div>

                        <div className="col">
                            <FormSelect
                                isMulti
                                onBlur={recipientsOnChange}
                                defaultValue={initialRecipients.map(({ userId }) => userId)}
                                options={recipientOptions}
                                isLoadingOptions={!recipientOptions}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        {canEditSubject && <FormInput
                            placeholder="Subject"
                            value={initialSubject}
                            onBlur={threadSubjectOnBlur}
                        />}
                    </div>
                    <HeaderIconWrapper className="col-auto">
                        {
                            isDraftMessage && <IconButton
                                variant="danger"
                                icon={"fa-trash"}
                                onClick={onDelete}
                            />
                        }
                    </HeaderIconWrapper>
                    <HeaderIconWrapper className="col-auto">
                        <IconButton
                            onClick={() => setShowModal(true)}
                            icon={"fa-paper-plane"}
                            disabled={initialRecipients.length === 0 || currentMessage === ""}
                        />
                    </HeaderIconWrapper>
                </MessageHeader>

            </div>
            <div className="row">
                <FormTextArea
                    placeholder="New message..."
                    minRows={3}
                    value={initialMessage}
                    onChange={handleOnMessageChange}
                    onBlur={messageOnBlur}
                />
            </div>
        </MessageBox>
        <SendMessageConfirmationModal
            priority={priority}
            requiresAction={requiresAction}
            onRequiresActionChange={requiresActionOnChange}
            onPriorityChange={priorityOnChange}
            show={showModal}
            onClose={() => setShowModal(false)}
            onSend={onClickSend}
            hasClientRecipient={false}
        />
    </React.Fragment>;


};

export default EditableMessage;
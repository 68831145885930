import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const hoApplicationEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['application', 'applicationReminders', 'applicationFees', 'applicationTransactions']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchApplication: builder.query({
            query: ({ applicationId }) => `api/headofficeadmin/Application/${applicationId}`,
            providesTags: ({ applicationId }) => [{ type: 'appliaction', id: applicationId }]
        }),
        searchApplications: builder.query({
            query: (body) => ({
                url: `api/headofficeadmin/Application/query`,
                method: "POST",
                body
            }),
            serializeQueryArgs: ({endpointName}) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};
                
                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },
        }),
        fetchApplicationTransactions: builder.query({
            query: ({ applicationId }) => `api/headofficeadmin/application/${applicationId}/transactions`,
            providesTags: ['applicationTransactions']
        }),
        fetchReminders: builder.query({
            query: ({ applicationId }) => `api/headofficeadmin/application/${applicationId}/reminders`,
            providesTags: ['applicationReminders']
        }),
        patchApplication: builder.mutation({
            query: ({ applicationId, operations }) => ({
                url: `api/headofficeadmin/Application/${applicationId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ applicationId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchApplication', { applicationId }, data => {
                    applyPatch(data, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            },
            invalidatesTags: ['applicationInfo']
        }),
        fetchApplicationFeeTotals: builder.query({
            query: ({ applicationId }) => `api/headofficeadmin/application/${applicationId}/fees`,
            providesTags: ['applicationFees']
        }),
        fetchApplicationDefaultFeeAllocations: builder.query({
            query: ({ applicationId }) => `api/headofficeadmin/application/${applicationId}/allocations`,
            providesTags: ['applicationFees']
        }),
        getApplicationHeaderInfoById: builder.query({
            query: ({ applicationId }) => `api/headofficeadmin/application/${applicationId}/header`,
            providesTags: ['applicationInfo']
        }),
        fetchApplicationPhasingOptions: builder.query({
            query: ({ applicationId }) => `api/headofficeadmin/application/${applicationId}/phasingoptions`
        }),
        setCurrentPhaseTransactionsToReady: builder.mutation({
            query: ({ applicationId, includeTaxReclaim }) => ({
                url: `api/headofficeadmin/application/${applicationId}/transactions/readyToTransact`,
                method: "POST",
                body: {
                    includeTaxReclaim
                }
            }),
            invalidatesTags: ["applicationTransactions"]
        })
    })
});

export const {
    useFetchApplicationQuery,
    useSearchApplicationsQuery,
    useLazySearchApplicationsQuery,
    usePatchApplicationMutation,
    useFetchApplicationTransactionsQuery,
    useFetchRemindersQuery,
    useFetchApplicationFeeTotalsQuery,
    useFetchApplicationDefaultFeeAllocationsQuery,
    useLazyFetchApplicationDefaultFeeAllocationsQuery,
    useGetApplicationHeaderInfoByIdQuery,
    useFetchApplicationPhasingOptionsQuery,
    useSetCurrentPhaseTransactionsToReadyMutation
} = hoApplicationEndpoints;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import FormSelectPaged from "../../../components/forms/FormSelectPaged";
import { FormInput, FormSelect } from "../../../components/forms";
import { useLazySearchProvidersQuery } from "../../../services/products";
import { useProductsGridContext } from "../hooks/ProductsGridContext";

const EditProductModal = ({ product, onHide, show, updateProduct }) => {

    const { wrappersResult } = useProductsGridContext();
    const { data: wrappers, isLoading: isLoadingWrappers } = wrappersResult || {};

    const {
        productName,
        wrapperId,
        providerId,
        providerName,
        defaultCustodianId,
        defaultCustodianName
    } = product || {};

    return (
        <Modal size="lg" centered backdrop="static" onHide={onHide} show={show}>
            <Modal.Header closeButton>
                <Modal.Title>{productName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <FormInput
                            label="Name"
                            value={productName}
                            onBlur={(value) => updateProduct("productName", value)}
                        />
                    </div>
                    <div className="col">
                        <FormSelect
                            label="Wrapper"
                            defaultValue={wrapperId}
                            options={wrappers ?? []}
                            onChange={({ value, label }) => updateProduct("wrapperId", value, {wrapperName: label})}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormSelectPaged
                            label="Provider"
                            additional={{ page: 1, limit: 50, fetchType: "list" }}
                            query={useLazySearchProvidersQuery}
                            searchTermPropertyName={"searchTerm"}
                            onOptionsLoaded={(_, results) => results}
                            setValue={({ value, label }) => updateProduct("providerId", value, { providerName: label })}
                            valueObject={{ value: providerId, label: providerName }}
                        />
                    </div>
                    <div className="col">
                        <FormSelectPaged
                            label="Default Custodian"
                            additional={{ page: 1, limit: 50, fetchType: "list" }}
                            query={useLazySearchProvidersQuery}
                            searchTermPropertyName={"searchTerm"}
                            onOptionsLoaded={(_, results) => results}
                            setValue={({ value, label }) => updateProduct("defaultCustodianId", value, { defaultCustodianName: label })}
                            valueObject={{ value: defaultCustodianId, label: defaultCustodianName }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditProductModal;
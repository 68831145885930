import React, { useMemo } from "react";

export const SummaryTableDataRow = ({ columns, data, ...rest }) => {
    const { groupLabel, typeCounts } = data || { typeCounts: [] };
    return <tr {...rest}>
        <td>{groupLabel}</td>
        {columns.map((el, i) => <td key={i} className="text-end">{typeCounts.find(r => r.reportTypeId === el.property)?.totalCount ?? 0}</td>)}
    </tr>
};

export const SummaryTableEmptyRow = ({ colspan = 1, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={colspan}>Your search did not yield any results.</td>
    </tr>
};

export const SummaryTableLoadingRow = ({ colspan = 1, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={colspan}>Loading...</td>
    </tr>
};

export const SummaryTableTotalsRow = ({ columns, data, ...rest }) => {
    const summaryColumns = useMemo(() => {
        return columns.map(el => {
            let colTotal = 0;
            for (var i = 0; i < data.length; i++) {
                const { typeCounts } = data[i];
                const typeCount = typeCounts.find(tc => tc.reportTypeId === el.property);

                if (typeCount) {
                    colTotal += typeCount.totalCount;
                }
            }

            return {
                ...el,
                totalCount: colTotal
            };
        });
    }, [ columns, data ]);

    return <tr {...rest}>
        <td>Totals</td>
        {summaryColumns.map((el, i) => <td key={i} className="text-end">{el.totalCount}</td>)}
    </tr>
};

const SummaryTable = ({ label = "", data = [], error = null, isLoading = false, showTotals = true }) => {
    const columns = useMemo(() => {
        if (data.length < 1 || !data[0].typeCounts || data[0].typeCounts.length < 1) {
            return [];
        }

        let retval = [];
        let firstCounts = data[0].typeCounts;
        
        for (var i = 0; i < firstCounts.length; i++) {
            retval.push({ label: firstCounts[i].description, property: firstCounts[i].reportTypeId });
        }

        return retval;
    }, [ data ]);
    
    return <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th>{label}</th>
                    {columns.map((el, i) => <th key={el.property} className="text-end">{el.label}</th>)}
                </tr>
            </thead>
            <tbody>
                {isLoading === true && <SummaryTableLoadingRow colspan={columns.length} />}
                {isLoading === false && !error && data.length === 0 && <SummaryTableEmptyRow colspan={columns.length} />}
                {isLoading === false && !error && data && data.map((el, _) => <SummaryTableDataRow key={el.groupKey} columns={columns} data={el} />)}
            </tbody>
            {isLoading === false && !error && data && data.length > 0 && showTotals && (
                <tfoot className="fw-bold">
                    <SummaryTableTotalsRow columns={columns} data={data} />
                </tfoot>
            )}
        </table>
    </div>
};

export default SummaryTable;
const date2String = (date) => {
    if (date == null) return 'Unknown Date';

    return new Date(date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric"
    });
};

const user2String = (user) => {
    if (user == null) return 'Unknown User';

    return `${user?.forenames} ${user?.surname}`;
}

const userAndDate2String = (user, date) => {
    return `${user2String(user)}, ${date2String(date)}`;
}

export {
    date2String,
    user2String,
    userAndDate2String
}
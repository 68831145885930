import { useCallback, useEffect, useMemo, useState } from 'react';
import { patchReplace } from '../../../helpers/patchDoc';
import { useClientContext } from '../../../hooks/ClientContext';
import {
    useFetchClientRetirementPlansQuery,
    useFetchFactFindClientConnectionsQuery,
    useFetchMaritalStatusesQuery,
    useFetchPersonalDetailsQuery,
    usePatchClientPersonalDetailsMutation,
    usePatchClientRetirementPlanMutation,
    useUpdateClientConnectionMappingsMutation
} from '../../../services/clientfactfind';

const employmentStatuses = [
    {
        label: "Employed",
        value: 10
    },
    {
        label: "Self-Employed",
        value: 20
    },
    {
        label: "Unemployed",
        value: 30
    },
    {
        label: "Retired",
        value: 40
    },
    {
        label: "Student",
        value: 50
    }
];

const usePersonalDetails = () => {

    const { id: masterAccountId } = useClientContext();
    const { data: personalDetails, isLoading: isLoadingPersonalDetails, isError: isErrorPersonalDetails, error: personalDetailsError, refetch } = useFetchPersonalDetailsQuery({ masterAccountId });
    const { data: connectionsAndClients, isLoading: isLoadingConnectionsAndClients, isError: isErrorConnectionsAndClients, error: connectionsAndClientsError, refetch: refetchConnectionsAndClients } = useFetchFactFindClientConnectionsQuery({ masterAccountId, includeClientAccounts: true });
    const { data: connections, isLoading: isLoadingConnections, isError: isErrorConnections, error: connectionsError, refetch: refetchConnections } = useFetchFactFindClientConnectionsQuery({ masterAccountId, includeClientAccounts: false });
    const { data: maritalStatuses, isLoading: isLoadingMaritalStatuses } = useFetchMaritalStatusesQuery();
    const [triggerPatchPersonalDetails] = usePatchClientPersonalDetailsMutation();
    const [triggerUpdateConnections] = useUpdateClientConnectionMappingsMutation();

    // Manually handle refetching, this prevents Skeletons appearing when fields are changed (and tags invalidated)
    const [isFetchingPersonalDetails, setIsFetchingPersonalDetails] = useState(false);

    // Refetches on component load
    useEffect(() => {
        setIsFetchingPersonalDetails(true);
        refetch().then(_ => setIsFetchingPersonalDetails(false));
    }, [refetch])

    // selectFromResult is causing an infinite loop that crashes the site, so just leaving as the full record for this map
    const { data: clientAccountRetirementPlanIdMap } = useFetchClientRetirementPlansQuery({ masterAccountId });

    const [triggerPatchRetirementPlan] = usePatchClientRetirementPlanMutation();

    const updatePersonalDetails = (personalDetailsId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return triggerPatchPersonalDetails({ personalDetailsId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updatePersonalDetailsConnectionsMapping = (personalDetailsId, connectionType, connectionIds) => {
        return new Promise((resolve, reject) => {
            return triggerUpdateConnections({ personalDetailsId, connectionType, connectionIds, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    const refetchOptions = useCallback(() => {
        refetchConnectionsAndClients();
    }, [refetchConnectionsAndClients])

    const connectionsAndClientsOptions = useMemo(() =>
        connectionsAndClients?.map(person => ({
            ...person,
            isConnection: connections?.some(conn => conn.value === person.value)
        })), [connections, connectionsAndClients]);
    
    const [realTimeHasWill, setRealTimeHasWill] = useState([]);

    useEffect(() => {
        setRealTimeHasWill(personalDetails?.map(detail => detail.hasWill))
    }, [personalDetails]);

    const updateHasWill = useCallback((index, hasWill) => {
        setRealTimeHasWill(oldArr => [
            ...oldArr.slice(0, index),
            hasWill,
            ...oldArr.slice(index+1)
        ]);
    }, [])

    return {
        personalDetails: personalDetails || [],
        realTimeHasWill,
        updateHasWill,
        isErrorPersonalDetails,
        isLoadingPersonalDetails: isLoadingPersonalDetails || isFetchingPersonalDetails,
        personalDetailsError,
        refetchOptions,
        connectionsOptions: connections || [],
        isLoadingConnections: isLoadingConnections,
        isErrorConnections,
        connectionsError,
        connectionsAndClientsOptions: connectionsAndClientsOptions || [],
        isLoadingConnectionsAndClients,
        isErrorConnectionsAndClients,
        connectionsAndClientsError,
        maritalStatuses: maritalStatuses || [],
        isLoadingMaritalStatuses,
        employmentStatuses,
        updatePersonalDetails,
        updatePersonalDetailsConnectionsMapping
    };
};

export default usePersonalDetails;
import { patchReplace } from "../../../helpers/patchDoc";
import { usePatchApplicationPolicyMutation } from "../../../services/headofficeadmin";

const useApplicationPolicy = (applicationPolicyId, applicationId) => {

    const [patch] = usePatchApplicationPolicyMutation();

    const patchApplicationPolicy = (property, newValue) => {
        return new Promise((res, rej) => {
            const operations = [patchReplace(property, newValue)];
            return patch({applicationPolicyId, applicationId, operations}).unwrap().then(res, rej);
        })
    };

    return {
        patchApplicationPolicy
    };  
};

export default useApplicationPolicy;
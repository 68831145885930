import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, FormCheck, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormLabel } from "../../../components/forms";
import { useCreateDivestTransactionsMutation } from "../../../services/recommendations";
import { useLazyFetchHoldingsQuery } from "../../../services/valuations";
import { useInstruction } from "../contexts/InstructionContext";

const FillFromValuationsButton = ({ forceInvisible = false, selectedProductId, selectedDesignationId, providerRef, custodyRef }) => {
    const [, { divest }] = useInstruction();

    const [showModal, setShowModal] = useState(false);

    const show = () => setShowModal(true);
    const hide = () => setShowModal(false);

    const [fetchHoldingsTrigger, { data: holdings, isFetching: isLoading, isUninitialized, isError }] = useLazyFetchHoldingsQuery();
    const [noneFound, setNoneFound] = useState(false);

    const hideButton = useMemo(() =>
        (forceInvisible || selectedProductId == null || selectedDesignationId == null || providerRef == null || providerRef === "" || custodyRef == null || custodyRef === ""),
        [custodyRef, forceInvisible, providerRef, selectedDesignationId, selectedProductId]);

    const [selectedHoldings, setSelectedHoldings] = useState([]);

    const selectHolding = (checked, assetId) => checked
        ? setSelectedHoldings(prev => [...prev, holdings.find(h => h.assetId === assetId)])
        : setSelectedHoldings(prev => prev.filter(h => h.assetId !== assetId));

    const selectAllHoldings = (checked) => checked
        ? setSelectedHoldings(holdings)
        : setSelectedHoldings([]);

    const fetchHoldings = useCallback(() => {
        fetchHoldingsTrigger({
            productId: selectedProductId,
            designationId: selectedDesignationId,
            providerReference: providerRef,
            custodianReference: custodyRef
        }).unwrap().then(res => {
            if (res == null || res?.length === 0) {
                setNoneFound(true);
            } else {
                setSelectedHoldings(res);
                setNoneFound(false);
            }
        });
    }, [custodyRef, fetchHoldingsTrigger, providerRef, selectedDesignationId, selectedProductId])

    useEffect(() => {
        if (hideButton)
            return;

        fetchHoldings()
    }, [custodyRef, fetchHoldings, hideButton, providerRef, selectedDesignationId, selectedProductId]);

    const [createTransactions] = useCreateDivestTransactionsMutation();

    // Removing setting of transaction type in request, using server-side default instead
    // const isSellSwitchOrTaxFreeCash = useMemo(() => instruction?.instructionType?.instructionType === 3 || instruction?.instructionType?.instructionType === 7,
    //     [instruction?.instructionType?.instructionType])
    // const {
    //     data: transactionTypes,
    // } = useRecsFetchTransactionTypesQuery({ isSelling: isSellSwitchOrTaxFreeCash, listType: "list" });
    // const transactionTypeSelectObjects = useMemo(() =>
    //     transactionTypes?.map(({ id, description }) => ({ value: id, label: description })),
    //     [transactionTypes]);
    // const allowedTransactionTypeOptions = useMemo(() =>
    //     excludeRingFence
    //         ? transactionTypeSelectObjects?.filter(({ value }) =>
    //             value !== transactionTypes?.find(type => type.isRingFenced)?.id)
    //         : transactionTypeSelectObjects,
    //     [excludeRingFence, transactionTypeSelectObjects, transactionTypes]);
    // const [selectedTransactionType, setSelectedTransactionType] = useState(null);
    // useEffect(() => {
    //     // TransactionType 0 means Inspecie to Retain
    //     setSelectedTransactionType(transactionTypes?.find(t => t.transactionType === 0)?.id);
    // }, [transactionTypes])

    const createTransactionsHandler = useCallback(() => {
        let request = { divestId: divest?.id, transactions: selectedHoldings };

        createTransactions(request).unwrap().then(hide);
    }, [createTransactions, divest?.id, selectedHoldings]);


    return <>
        <OverlayTrigger placement="left" overlay={({ ...props }) => (isLoading || isUninitialized)
            ? <></>
            : <Tooltip {...props}>
                {isError
                    ? "Error fetching holdings for this product/designation/provider/custodian, click to retry"
                    : noneFound
                        ? "No holdings found for this product/designation/provider/custodian"
                        : "Fill from valuations"}
            </Tooltip>}>
            <span>
                <Button
                    className={forceInvisible
                        ? 'd-none'
                        : hideButton
                            ? 'invisible'
                            : (isLoading || isUninitialized || !noneFound)
                                ? ''
                                : 'px-3'}
                    variant={(isLoading || isUninitialized)
                        ? 'secondary'
                        : (noneFound || isError)
                            ? 'danger'
                            : 'success'}
                    disabled={(isLoading || isUninitialized || noneFound)}
                    onClick={isError ? fetchHoldings : show}
                >
                    <FontAwesomeIcon
                        icon={(isLoading || isUninitialized)
                            ? 'spinner'
                            : (noneFound || isError)
                                ? 'exclamation'
                                : 'clone'}
                        spinPulse={(isLoading || isUninitialized)}
                    />
                </Button>
            </span>
        </OverlayTrigger>
        <Modal show={showModal} onHide={hide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Fill from Valuations?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Which of the following holdings would you like to select?</p>
                <div className="row">
                    <div className="col">
                        <FormLabel>Asset Name</FormLabel>
                    </div>
                    <div className="col-1">
                        <FormLabel>Quantity</FormLabel>
                    </div>
                    <div className="col-2 text-end">
                        <FormLabel>Value</FormLabel>
                    </div>
                    <div className="col-auto">
                        <FormCheck
                            type="switch"
                            checked={selectedHoldings.length === holdings?.length}
                            onChange={(event) => selectAllHoldings(event.target.checked)}
                        />
                    </div>
                </div>
                {holdings?.map(({ assetId, longAssetName, holdingQuantity, currentValue }) => <div key={assetId} className="row">
                    <div className="col">
                        {longAssetName}
                    </div>
                    <div className="col-1">
                        {holdingQuantity}
                    </div>
                    <div className="col-2 text-end">
                        {Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: 'GBP',
                            maximumFractionDigits: 2,
                        }).format(currentValue)}
                    </div>
                    <div className="col-auto">
                        <FormCheck
                            type="switch"
                            checked={selectedHoldings.some(h => h.assetId === assetId)}
                            onChange={(event) => selectHolding(event.target.checked, assetId)} />
                    </div>
                </div>)}
                {/* {!isSellSwitch && <>
                    <p>What Instruction would you like to use to fill the table?</p>
                    <FormSelect
                        defaultValue={selectedTransactionType}
                        onChange={(selection) => setSelectedTransactionType(selection.value)}
                        options={allowedTransactionTypeOptions ?? []}
                        // isLoadingOptions={optionsIsFetching}
                        disableAnimations
                    />
                </>} */}
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button variant="danger" onClick={hide}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={createTransactionsHandler}>
                        Fill holdings
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    </>
}

export default FillFromValuationsButton;
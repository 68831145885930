import { useMemo } from "react";
import { useCreateFeeTransactionMutation, useFetchApplicationDefaultFeeAllocationsQuery, useFetchFeeTransactionTypesQuery } from "../../../services/headofficeadmin";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { v4 } from "uuid";

const useCreateFeeTransaction = (feeGroup) => {
    const { applicationId } = useHeadOfficeApplicationContext();
    const { groupId } = feeGroup;
    const { data: transactionTypes, isLoading: isLoadingTransactionTypes } = useFetchFeeTransactionTypesQuery();
    const {
        data: defaultAllocations,
        isLoading: isLoadingDefaultAllocations,
        isError: isDefaultAllocationsError,
        error: defaultAllocationsError,
        refetch: refetchDefaultAllocations
    } = useFetchApplicationDefaultFeeAllocationsQuery({ applicationId });

    const [createTrigger] = useCreateFeeTransactionMutation();

    const feeTransactionDefaultValues = useMemo(() => ({
        groupId: groupId,
        feeDate: new Date(),
        feeTransactionTypeId: null,
        feeTransactionType: {},
        totalFeeAmount: 0,
        totalVatAmount: 0,
        feeReference: null,
        comment: null,
        allocations: defaultAllocations?.map(item => ({
            personType: item.personType,
            attributeToId: item.creditedPersonId,
            allocationPercentage: item.percentage,
            rowId: v4()
        })) ?? []
    }), [defaultAllocations]);

    const feeTransactionAllocationDefaultValues = useMemo(() => ({
        personType: null,
        attributeToId: null,
        allocationPercentage: 0.1
    }), []);

    const createFeeTransaction = (request) => {
        return new Promise((resolve, reject) => {
            return createTrigger({request: { ...request, applicationId }})
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const retryFetchDefaultAllocations = (e) => {
        if (e && typeof (e) === "function") {
            e.preventDefault();
        }

        refetchDefaultAllocations();
    };

    return {
        defaultAllocations,
        isLoadingDefaultAllocations,
        isDefaultAllocationsError,
        defaultAllocationsError,
        feeTransactionDefaultValues,
        feeTransactionAllocationDefaultValues,
        transactionTypes: transactionTypes || [],
        isLoadingTransactionTypes,
        createFeeTransaction,
        retryFetchDefaultAllocations
    };
};

export default useCreateFeeTransaction;
import styled from "styled-components";

const MessageBox = styled.div`
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    min-height: 150px;
    overflow: grow;
    background-color: #fff;
    display: flex;
    flex-direction: column;
`;

export default MessageBox;
import React from "react";
import { FormCheck } from "../../../components/forms";
import { useFieldArray } from "react-hook-form";

const ReportCheckArray = ({ control, data, emptyMessage = "There are no results to display.", name, ...props }) => {
    const { fields, append, remove } = useFieldArray({ control, name });

    if (!data || data.length === 0) {
        return (
            <p>{emptyMessage}</p>
        )
    }

    return <div className="row">
        {data.map((el) => (
            <div key={el.id} className="col-4">
                <FormCheck 
                    type="checkbox"
                    label={el.description}
                    disableAnimations={true}
                    isChecked={fields.findIndex(q => q.value === el.id) > -1}
                    onChange={(value) => {
                        if (value === true) {
                            append({ value: el.id, label: el.description });
                        }
                        else {
                            var index = fields.findIndex(q => q.value === el.id);
                            if (index > -1) {
                                remove(index);
                            }
                        }
                    }}
                    {...props}
                />
            </div>
        ))}
    </div>
};

export default ReportCheckArray;
import { useCallback, useEffect, useMemo } from "react";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useLazyFetchAssetGroupsForAnalysisGridQuery } from "../../../services/assets";
import useFundAnalysisColumnDefs from "./useFundAnalysisColumnDefs";

const assetGroupFetchTypes = {
    0: "Volatility",
    1: "Return"
}

const useFundAnalysisGrid = ({
    isVolatility = false,
    volatilityProfile = null,
    isGroupedBySector = false,
    currentSort
}) => {
    const isGroupedByVolatility = useMemo(() => volatilityProfile != null, [volatilityProfile]);

    const {
        filter: filters,
    } = useFilterContext();

    const columnDefs = useFundAnalysisColumnDefs({ isVolatility, isGroupedByVolatility });

    const [fetchAssetGroupsForAnalysisTrigger, { data: assetGroups, isUninitialized: assetGroupsIsUninitialised, isLoading: assetGroupsIsLoading, isFetching: assetGroupsIsFetching, isError: assetGroupsIsError, error: assetGroupsError }] = useLazyFetchAssetGroupsForAnalysisGridQuery({ refetchOnFocus: true });

    useEffect(() => {
        fetchAssetGroupsForAnalysisTrigger({
            fetchType: assetGroupFetchTypes[isVolatility ? 0 : 1],
            volatility: volatilityProfile,
            groupBySector: isGroupedBySector,
            filters,
            sort: currentSort
        });
    }, [currentSort, fetchAssetGroupsForAnalysisTrigger, filters, isGroupedBySector, isVolatility, volatilityProfile]);

    const refetch = useCallback(() => {
        fetchAssetGroupsForAnalysisTrigger({
            fetchType: assetGroupFetchTypes[isVolatility ? 0 : 1],
            volatility: volatilityProfile,
            groupBySector: isGroupedBySector,
            filters,
            sort: currentSort
        });
    }, [currentSort, fetchAssetGroupsForAnalysisTrigger, filters, isGroupedBySector, isVolatility, volatilityProfile])

    return {
        columnDefs,
        isGroupedByVolatility,
        assetGroups,
        assetGroupsIsUninitialised,
        assetGroupsIsLoading,
        assetGroupsIsFetching,
        assetGroupsIsError,
        assetGroupsError,
        refetch
    };
}

export default useFundAnalysisGrid;
import { wealthProApi } from '../../redux/api';
import * as jsonpatch from 'fast-json-patch';

const profileGroupApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["divisions"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchDivisionsList: builder.query({
            query: () => `api/profilegroup/`
        })
    })
})

export const {
    useFetchDivisionsListQuery
} = profileGroupApiEndpoints;
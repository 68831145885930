import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { ActionIcon, ThemedParagraph, ThemedSpan }  from "../../../components/utilities";
import { useNavigate } from "react-router-dom";

const FavouriteSection = ({
    hook,
    initialPage = 1, 
    label = "Favourites", 
    emptyMessage = null,
    maximumPages = 3, 
    openPageOnMaxPagesReached = true, 
    open,
    onToggle,
    pageSize = 7, 
    pageUrl = "/personal/favourites",
    RecordItem,
    ...rest 
}) => {
    const appNavigator = useNavigate();
    const { data, error, isError, isLoading, nextPage, page, reload, totalCount, totalPages, ...hookExtras } = hook();

    const handleFetchMore = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        nextPage();
    };

    const handleOpenFavouritesPage = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }
        appNavigator(pageUrl);
    };

    useEffect(() => {
        if (!open) {
            return;
        }
        reload();
    }, [ open ]);

    if (!hook || !RecordItem) {
        return;
    }

    return <div className="my-1">
        <div className="row mb-1">
            <div className="col">
                <p className="mb-0">{label} {totalCount > 0 && (<ThemedSpan variant="muted">({totalCount})</ThemedSpan>)}</p>
            </div>
            <div className="col-md-auto">
                <ActionIcon icon="fa-rotate" variant="muted" onClick={reload} />
            </div>
        </div>
        {isLoading === true && (
            <div className="mb-2">
                <Skeleton count={3} />
            </div>
        )}
        {isLoading === false && (error || isError === true) && (
            <ThemedParagraph className="px-2 mb-2" variant="danger">
                <span>Oops! There was a problem getting the data.</span> 
                <br/>
                <a href="#" onClick={reload}>Click here to retry.</a>
            </ThemedParagraph>
        )}
        {isLoading === false && !error && isError === false && data.length === 0 && (
            <p className="lh-1">
                <ThemedSpan variant="muted">Ouhh.. it's a bit empty here.</ThemedSpan>
                <br/>
                {emptyMessage && (<ThemedSpan variant="muted">{emptyMessage}</ThemedSpan>)}
            </p>
        )}
        {isLoading === false && !error && isError === false && data.length > 0 && (
            <div className="mb-2">
                {data.map((el, i) => <RecordItem key={i} account={el} onToggle={onToggle} {...hookExtras} />)}
                {totalPages > 1 &&  page < totalPages && (page < maximumPages || openPageOnMaxPagesReached === false) && (
                    <div className="d-flex justify-content-center align-items-end mt-2">
                        <a href="#" onClick={handleFetchMore}>Load more...</a>
                    </div>
                )}
                {totalPages > 1 && page >= maximumPages && openPageOnMaxPagesReached === true && (
                    <div className="d-flex flex-column align-items-center mt-2">
                        <span>Maximum page limit reached.</span>
                        <br/>
                        <a href="#" onClick={handleOpenFavouritesPage}>Click here to view more.</a>
                    </div>
                )}
            </div>
        )}
    </div>
};

export default FavouriteSection;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const pensionsArrangementsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["pensionArrangements"]
}).injectEndpoints({
    endpoints: (builder) => ({
        createPensionArrangement: builder.mutation({
            query: ({ masterAccountId, pensionType, pensionName }) => ({
                url: 'api/factfind/pensionarrangements',
                method: 'POST',
                body: {
                    masterAccountId,
                    pensionType,
                    pensionName
                }
            }),
            invalidatesTags: (result, err, arg) => result
                ? [{ type: "pensionArrangements", id: arg.masterAccountId }, "pensionArrangements"]
                : ["pensionArrangements"]
            // async onQueryStarted({ masterAccountId, pensionType, pensionName }, { queryFulfilled, dispatch }) {
            //     try {
            //         let createdIndex;
            //         const createResult = dispatch(wealthProApi.util.updateQueryData('fetchPensionArrangements', { masterAccountId }, (data) => {
            //             const newPensionArrangement = {
            //                 // pensionPlanId: data.pensionPlanId,
            //                 masterAccountId,
            //                 pensionName,
            //                 pensionType,
            //                 clientAccountId: null,
            //                 currentContributions: null,
            //                 personalContributions: null,
            //                 employerContributions: null,
            //                 valueOfFund: null,
            //                 withdrawalsTaken: null,
            //                 currentAnnualWithdrawals: null,
            //                 drawdownType: null,
            //                 maximumGadIncome: null,
            //                 valuesAt: null,
            //                 crystallisedValue: null,
            //                 uncrystallisedValue: null,
            //                 notes: null
            //             };
            //             createdIndex = data.length;

            //             return [...data, newPensionArrangement];
            //         }));


            //         try {
            //             const { data: response } = await queryFulfilled;
            //             dispatch(wealthProApi.util.updateQueryData('fetchPensionArrangements', { masterAccountId }, data => [
            //                 ...data.slice(0, createdIndex),
            //                 {
            //                     ...data[createdIndex],
            //                     pensionPlanId: response.pensionPlanId
            //                 },
            //                 ...data.slice(createdIndex + 1)
            //             ]));
            //         } catch (err) {
            //             console.error("Error creating pension arrangement in service", err);
            //             createResult.undo();
            //         }
            //     } catch (err) {
            //         console.error("Error creating pension arrangement in cache", err);
            //     }
            // }
        }),
        deletePensionArrangement: builder.mutation({
            query: ({ pensionPlanId }) => ({
                url: `api/factfind/pensionarrangements/${pensionPlanId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ pensionPlanId, masterAccountId }, { queryFulfilled, dispatch }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchPensionArrangements', { masterAccountId }, (data) =>
                        data.filter(x => x.pensionPlanId !== pensionPlanId)));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting pension arrangement in service", err);
                        deleteResult.undo();
                    }
                } catch (err) {
                    console.error("Error deleting pension arrangement in cache", err);
                }
            }
        }),
        updatePensionArrangement: builder.mutation({
            query: ({ pensionPlanId, operations }) => ({
                url: `api/factfind/pensionarrangements/${pensionPlanId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ pensionPlanId, operations, masterAccountId }, { queryFulfilled, dispatch }) {
                var result = dispatch(wealthProApi.util.updateQueryData('fetchPensionArrangements', { masterAccountId }, (data) => {
                    var item = data.find(x => x.pensionPlanId === pensionPlanId);
                    applyPatch(item, operations, true);
                }));

                try {
                    const { data } = await queryFulfilled;

                }
                catch {
                    result.undo();
                }
            }
        }),
        fetchTaxYearEndWithdrawals: builder.query({
            query: ({ pensionPlanId }) => `api/factfind/pensionarrangements/${pensionPlanId}/withdrawals`
        })
    })
});

export const {
    useCreatePensionArrangementMutation,
    useDeletePensionArrangementMutation,
    useUpdatePensionArrangementMutation,
    useFetchTaxYearEndWithdrawalsQuery
} = pensionsArrangementsApiEndpoints;
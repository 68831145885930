import { wealthProApi } from "../../redux/api";

const antiMoneyLaunderingApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['factFindAml']
}).injectEndpoints({
    endpoints: (builder) => ({
        uploadAmlEvidence: builder.mutation({
            query: ({ masterAccountId, request }) => ({
                url: `api/factfind/antimoneylaundering/${masterAccountId}`,
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['factFindAml'],
            async onQueryStarted({ masterAccountId, request }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                dispatch(wealthProApi.util.updateQueryData('fetchAmlSection', { masterAccountId }, (draft) => ({
                    ...draft,
                    evidence: [...draft.evidence, { ...request, documentId: data.documentId, proofOf: data.proofOf }]
                })));
            }
        }),
        removeAmlEvidence: builder.mutation({
            query: ({ masterAccountId, evidenceId }) => ({
                url: `api/factfind/antimoneylaundering/${masterAccountId}/${evidenceId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['factFindAml'],
            async onQueryStarted({ masterAccountId, evidenceId }, { dispatch, queryFulfilled }) {
                try {
                    const removeEvidenceResult = dispatch(wealthProApi.util.updateQueryData('fetchAmlSection', { masterAccountId }, (draft) => ({
                        ...draft,
                        evidence: [
                            ...draft.evidence.slice(0, draft.evidence.findIndex(e => e.evidenceId === evidenceId)),
                            { isRemoving: true, evidenceId },
                            ...draft.evidence.slice(draft.evidence.findIndex(e => e.evidenceId === evidenceId) + 1)
                        ]
                    })));

                    try {
                        await queryFulfilled;

                        dispatch(wealthProApi.util.updateQueryData('fetchAmlSection', { masterAccountId }, (draft) => ({
                            ...draft,
                            evidence: draft.evidence.filter(x => x.evidenceId !== evidenceId)
                        })))
                    } catch (e) {
                        console.error("Error removing evidence in service", e);
                        removeEvidenceResult.undo();
                    }
                } catch (e) {
                    console.error("Error removing evidence in cache", e);
                }
            }
        })
    })
})

export const {
    useUploadAmlEvidenceMutation,
    useRemoveAmlEvidenceMutation
} = antiMoneyLaunderingApiEndpoints;
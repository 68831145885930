import { useNavigate } from "react-router-dom";
import { useCreateAssetGroupMutation } from "../services/assets";

const useCreateAssetGroup = (baseUrl = "..") => {
    const navigate = useNavigate();

    const [createAssetGroupTrigger, createResult] = useCreateAssetGroupMutation();

    const createAssetGroup = () =>
        createAssetGroupTrigger().unwrap()
            .then((assetGroupId) => {
                if (typeof assetGroupId === 'number')
                    navigate(`${baseUrl}/${assetGroupId}`);
                else
                    alert("Received invalid response creating asset group");
            })
            .catch(err => {
                console.error(err, "Error creating asset group");
                alert("Failed to create asset group");
            });

    return {
        createAssetGroup,
        ...createResult
    };
}

export default useCreateAssetGroup;
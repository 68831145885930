import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { FormLabel, FormSelect } from "../../../components/forms";
import { Spinner } from "../../../components/loaders";
import { ThemedSpan } from "../../../components/utilities";
import { userAndDate2String } from "../displayHelpers";
import useFundDetails from "../hooks/useFundDetails";
import CommentaryInput from "./CommentaryInput";

const VolatilityProfileRationale = () => {
    const [{ patchVolatilityProfile }, { assetGroup, assetGroupIsNotReady, isUpdatingVolatilityProfile }, { canEdit, options }] = useFundDetails();

    const modifiedInformationText = useMemo(() => {
        if (assetGroupIsNotReady || isUpdatingVolatilityProfile) return;

        if (assetGroup?.volatilityProfile == null) return;

        return userAndDate2String(assetGroup?.volatilityProfileModifiedBy, assetGroup?.volatilityProfileModifiedDate);
    }, [assetGroup?.volatilityProfile, assetGroup?.volatilityProfileModifiedBy, assetGroup?.volatilityProfileModifiedDate, assetGroupIsNotReady, isUpdatingVolatilityProfile]);

    return <>
        <GridWrapper className="mt-4" gridTemplateColumns={"30ch auto"}>
            <div className="col-start-1 d-flex flex-column">
                <FormLabel>
                    Volatility
                </FormLabel>
                {assetGroupIsNotReady
                    ? <Skeleton />
                    : <FormSelect
                        options={options.volatility}
                        defaultValue={assetGroup?.volatilityProfile}
                        onBlur={(selection) => patchVolatilityProfile(selection.value)}
                        isDisabled={!canEdit}
                    />}
            </div>
            <div className="col-start-2 mt-auto mb-2">
                {(assetGroupIsNotReady || isUpdatingVolatilityProfile)
                    ? <Spinner />
                    : <ThemedSpan variant="muted">
                        {modifiedInformationText}
                    </ThemedSpan>}
            </div>
        </GridWrapper>

        <CommentaryInput
            className={"mt-4"}
            label={"Rationale for Volatility Classification"}
            commentaryType={"volatilityRationale"}
            dateDisplayText={assetGroup?.volatilityRationaleDateDisplayText}
            isApproving={false}
            isPublishing={false}
            readOnly={!canEdit}
        />
    </>
}

export default VolatilityProfileRationale;
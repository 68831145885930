import React from "react";
import FormSelect from "../FormSelect";
import { useFetchReportFeedbackAspectsQuery } from "../../../services/clientreports";

const ReportFeedbackAspectsSelect = ({ options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchReportFeedbackAspectsQuery({ fetchType: 'List' });
    const { results } = data || { results: [] }

    return <FormSelect
        options={results}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default ReportFeedbackAspectsSelect;
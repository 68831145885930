import React from "react";
import { Main } from "../../components/utilities";
import IntroducerAgreement from "./components/IntroducerAgreement";
import ServiceAgreement from "./components/ServiceAgreement";
import { useClientContext } from "../../hooks/ClientContext";

const ClientServiceTerms = () => {
    const { id } = useClientContext();

    return (
        <Main className="p-3">
            <ServiceAgreement masterAccountId={id} />
            <IntroducerAgreement masterAccountId={id} />
        </Main>
    )
}

export default ClientServiceTerms; 
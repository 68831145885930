import { wealthProApi } from '../../redux/api';
import * as jsonpatch from 'fast-json-patch';
import _ from "lodash";
import { applyPatch } from 'fast-json-patch';

const serviceTermsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchServiceAgreements: builder.query({
            query: ({ fetchType = 1 }) => `api/review/ServiceAgreements?sort=ServiceName&fetchType=${fetchType}`
        }),
        searchServiceLevelAgreementsPaged: builder.query({
            query: (data) => ({
                url: `api/review/ServiceAgreements/search-paged`,
                method: 'POST',
                body: data,
            }),
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            serializeQueryArgs: ({endpointName, queryArgs}) => {
                // const { cacheKey } = queryArgs || {};
                // return `${endpointName}-${cacheKey}`
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                }
                else {
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                }             
            }
        }),
        patchServiceLevelAgreement: builder.mutation({
            query: ({ agreementId, operations }) => ({
                url: `api/review/ServiceAgreements/${agreementId}`,
                method: "PATCH",
                body: operations,
            }),
            async onQueryStarted({ agreementId, operations }, { dispatch, queryFulfilled }) {
                
                const adminQuery = dispatch(wealthProApi.util.updateQueryData('searchServiceLevelAgreementsPaged', { }, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.agreementId === agreementId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));

                try {
                   await queryFulfilled;
                } 
                catch(err) {
                    console.log(err);
                    adminQuery.undo();
                }
            },
        }),
        createServiceLevelAgreement: builder.mutation({
            query: ({data}) => ({
                url: `api/review/ServiceAgreements`,
                method: "POST",
                body: data,
            }),
            async onQueryStarted(
                 { },
                { dispatch, queryFulfilled }
            ) {
                try {
                  const { data: createResult } = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData(
                            "searchServiceLevelAgreementsPaged",
                            { },
                            ({results, pagination}) => {
                                return {
                                    results: results.length > 0 ? [createResult,...results] 
                                    : [createResult],
                                    pagination: {
                                        ...pagination,
                                        totalCount: pagination.totalCount + 1
                                    }
                                }
                            }
                        )
                    );
                } catch {
                    //result.undo();
                }
            },
        }),
    })
});

export const {
    useFetchServiceAgreementsQuery,
    useLazySearchServiceLevelAgreementsPagedQuery,
    usePatchServiceLevelAgreementMutation,
    useCreateServiceLevelAgreementMutation,
} = serviceTermsApiEndpoints;
import { useHoFetchApplicationStatusesQuery } from "../../../services/headofficeadmin";
import FormSelect from "../FormSelect";

const HeadOfficeApplicationStatusSelect = ({ label, onChange, onBlur, defaultValue, ...rest }) => {

    const { data, isFetching } = useHoFetchApplicationStatusesQuery();


    return <FormSelect
        defaultValue={defaultValue}
        label={label}
        isLoadingOptions={isFetching}
        onBlur={onBlur}
        onChange={onChange}
        options={data ?? []}
        {...rest}
    />;
};

export default HeadOfficeApplicationStatusSelect;
import React from "react";
import toast from "react-hot-toast";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import useLoginAuthenticator from "../hooks/useLoginAuthenticator";
import useUpdateLoginAuthenticator from "../hooks/useUpdateLoginAuthenticator";
import useClientDashboardLoginDetails from "../hooks/ClientDashboardLoginDetailsContext";
import { useTheme } from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../../components";
import { Spinner } from "../../../components/loaders";
import { FormSelect } from "../../../components/forms";
import { MasterAccountEmailSelect } from "../../../components/forms/selects";
import { ThemedParagraph, ThemedSpan } from "../../../components/utilities";
import { useClientDashboardManagementControls } from "../../../hooks/ClientDashboardManagementContext";

const LoginAuthenticator = ({ availableEmailAddresses, availablePhoneNumbers, data }) => {
    const theme = useTheme();
    const { update, isLoading: isUpdating } = useUpdateLoginAuthenticator();
    const { data: authenticator, isLoading } = useLoginAuthenticator(data?.masterAccountId, data?.id);
    const { isAllowedToChangeAuthenticator } = useClientDashboardManagementControls();
    const { setIsDirty } = useClientDashboardLoginDetails();
    const { control, setError, formState, handleSubmit, reset, setValue, watch } = useForm({ defaultValues: authenticator });
    const { isDirty, errors } = formState;
    const currentAuthenticationType = watch('authenticationType');
    const currentEmailAddress = watch('contactMethod');

    const onSubmit = (data) => {
        const { authenticationType, contactMethod, loginId, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={authenticationType < 0 ? "Delete Authenticator?" : "Change Authenticator?"}
                    message={authenticationType < 0
                        ? "Warning! You are about to remove the authenticator from this login. This is not a recommended action and will leave the clients account more vulnerable to outside risks. Are you sure you want to remove the authenticator?"
                        : "Changing the authenticator setup will change the way the client currently accesses their account. We strongly recommend you confirm with the client before making this change. Are you sure you want to continue and update the authenticator details?"
                    }
                    confirmButtonLabel={authenticationType < 0 ? "Remove Authenticator" : "Save Changes"}
                    confirmButtonVariant="danger"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(update(masterAccountId, loginId, { 
                            clearSessions: false,
                            authenticatorType: authenticationType < 0 ? null : authenticationType,
                            details: authenticationType < 0 ? "" : contactMethod
                        }), {
                            loading: authenticationType < 0 
                                ? `Removing dashboard login Authenticator...` 
                                : `Updating dashboard login Authenticator...`,
                            success: () => {
                                setIsDirty(_ => true);
                                return authenticationType < 0 
                                    ? `The dashboard login Authenticator has been removed.`
                                    : `The dashboard login Authenticator has been updated.`;
                            },
                            error: authenticationType < 0 
                                ? `Failed to remove the dashboard login Authenticator` 
                                : `Failed to update the dashboard login Authenticator.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    React.useEffect(() => {
        let contactMethod = authenticator.authenticationType === 4
            ? availableEmailAddresses.find(el => el.label == authenticator.contactMethod)
            : authenticator.authenticationType === 5
                ? availablePhoneNumbers.find(el => el.label == authenticator.contactMethod)
                : null;

        reset({
            ...authenticator,
            contactMethodId: contactMethod?.value
        });
    }, [ availableEmailAddresses, availablePhoneNumbers, authenticator ]);

    if (!data) {
        return null;
    }

    return (
        <div className="border px-3 py-2 mb-3">
            <legend className="fs-6">Authenticator (Two-factor Authentication)</legend>
            {isLoading === true && (
                <div className="d-flex justify-content-center py-2 w-100">
                    <Spinner />
                </div>
            )}
            {isLoading === false && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-3">
                        <div className="col-4">
                            <Controller 
                                name="authenticationType"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormSelect
                                        label="Two-Factor Method" 
                                        options={[
                                            { label: 'Disabled (Unsecure)', value: -1 },
                                            { label: 'PIN number', value: 0, isDisabled: true },
                                            { label: 'TOTP (Autenticator App)', value: 3 },
                                            { label: 'HOTP (Email)', value: 4 },
                                            { label: 'HOTP (SMS)', value: 5, isDisabled: true }
                                        ]} 
                                        defaultValue={value} 
                                        isDisabled={isLoading || !isAllowedToChangeAuthenticator}
                                        disableAnimations={true}
                                        onChange={(v) => {
                                            setValue('authenticationType', v.value, { shouldDirty: true });
                                        }}
                                        errorMessage={errors?.authenticationType?.message} 
                                        errorAllowRetry={false} 
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        {currentAuthenticationType === 0 && (
                            <div>
                                <p>TODO: PIN input field</p>
                            </div>
                        )}
                        {currentAuthenticationType === 4 && (
                            <div className="col">
                                <Controller 
                                    name="contactMethodId"
                                    control={control}
                                    rules={({ required: "An email address must be supplied." })}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <MasterAccountEmailSelect
                                            masterAccountId={data?.masterAccountId}
                                            label="Target Email Address"   
                                            defaultValue={value} 
                                            disableAnimations={true}
                                            onChange={(v) => {
                                                setValue('contactMethod', v.label, { shouldDirty: true });
                                                setValue('contactMethodId', v.value, { shouldDirty: true });
                                            }}
                                            errorMessage={errors?.contactMethodId?.message} 
                                            errorAllowRetry={false} 
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {currentAuthenticationType === 5 && (
                            <div className="col">
                                TODO: phone number input field
                            </div>
                        )}
                    </div>
                    {currentAuthenticationType < 0 && (
                        <div>
                            <ThemedParagraph variant="danger">Disabling or having no two-factor authentication for a clients dashboard login is not recommended.</ThemedParagraph>
                        </div>
                    )}
                    {(authenticator?.authenticationType === 1  || authenticator?.authenticationType === 2) && !isDirty && (
                        <div className="p-1">
                            <ThemedParagraph variant="success">Authenticator app has been configured successfully.</ThemedParagraph>
                        </div>
                    )}
                    {authenticator?.authenticationType === 3 && !isDirty && (
                        <div className="p-1">
                            <ThemedParagraph variant="information">Account is setup to use a mobile authenticator app but has not been registered yet.</ThemedParagraph>
                        </div>
                    )}
                    {currentAuthenticationType === 4 && data.emailAddress != currentEmailAddress && (
                        <div className="p-1">
                            <ThemedParagraph variant="danger">Warning! It is not recommended to make the email address that receives the two-factor authentication code be different from the email used to login to the account.</ThemedParagraph>
                        </div>
                    )}
                    {isDirty === true && (
                        <div className="d-flex flex-row justify-content-end my-2">
                            <Button variant="danger" type="submit" disabled={isUpdating}>
                                {currentAuthenticationType === -1 ? "Disable Authenticator" : "Update Authenticator"}
                            </Button>
                        </div>
                    )}
                </form>
            )}
        </div>
    );
};

export default LoginAuthenticator;
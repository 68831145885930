import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";

const StyledMessage = styled(Form.Control.Feedback)``;

const InputErrorMessage = ({ allowRetry = true, error, retryCallback, type = "invalid", ...rest }) => {

    const handleRetryClicked = (e) => {
        e.preventDefault();
        if (retryCallback && typeof (retryCallback) === 'function') {
            retryCallback(e);
        }
    };

    const renderErrorMessage = () => {
        if (error && typeof (error) === 'string') {
            return error;
        }

        const { message } = error || { message: 'There was a problem updating the field.' };
        return message;
    };

    return <StyledMessage type={type} {...rest}>
        <span>{renderErrorMessage()}&nbsp;</span>
        {allowRetry && (<a href="#" onClick={handleRetryClicked}>Click here to retry.</a>)} 
    </StyledMessage>
};

export default InputErrorMessage;
import _ from "lodash";
import { useAuth } from "react-oidc-context";
import { toNString } from "../../../helpers/guids";
import { useCreateIntroducerMutation } from "../../../services/clientreviews";

const allowUpdateStatusRoles = ['sys_admin_access', 'sys_introducers_approve'];

const useCreateIntroducer = () => {
    const { user: { profile } } = useAuth();
    const [_create, results] = useCreateIntroducerMutation();
    const isAllowedToUpdateStatus = _.some(profile.roles, item => allowUpdateStatusRoles.includes(item));
    const defaultValues = {
        title: '',
        divisionId: profile?.division && profile?.division.length > 0 ? toNString(profile.division[0]) : null,
        forenames: '',
        surname: '',
        professionId: 3,
        position: '',
        salutation: '',
        company: '',
        address: '',
        phone: '',
        email: '',
        notes: '',
        introducerStatus: 1,
        adviserId: null,
        findersFeeType: 0,
        findersFeeRate: 0,
        serviceFeeType: 0,
        serviceFeeRate: 0,
        adviceFeeType: 0,
        adviceFeeRate: 0,
        statusModifiedById: null,
        statusModifiedDate: null,
        isActive: true
    };

    const create = (data) => {
        return new Promise((resolve, reject) => {
            return _create({ data }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        ...results,
        create,
        defaultValues,
        isAllowedToUpdateStatus
    };
};

export default useCreateIntroducer;
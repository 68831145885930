import React from "react";
import Container from "./components/Container";
import ErrorIcon from "./components/ErrorIcon";
import SquishedProfileIcon from "./components/SquishedProfileIcon";
import useFetchActiveUsers from "./hooks/useFetchActiveUsers";
import { Spinner } from "../../components/loaders";
import { Tooltip } from "../../components/utilities";
import { useClientContext } from "../../hooks/ClientContext";

const ClientTracking = () => {
    const { id } = useClientContext();
    const { data, error, isError, isLoading } = useFetchActiveUsers(id);

    if (isLoading === true) {
        return <Spinner width="32px" height="32px" />
    }

    if (isError === true || error) {
        return <ErrorIcon error={error} />
    }

    return (
        <Container>
            {data && data.map((el, i) => (
                <Tooltip key={i} position="left" tooltip={el.name}>
                    <SquishedProfileIcon order={i} user={el} /> 
                </Tooltip>   
            ))}
        </Container>
    );
};

export default ClientTracking;
import { readableColor } from "polished";

const defaultPrimaryColour = '#0c5da5';

const variantToColour = ({ theme, variant }, defaultColor = '#000') => {
    if (!theme || !variant) {
        return defaultColor;
    }
    return theme[variant] ?? defaultColor;
};

const calculateButtonColour = (props) => {
    const { theme, variant } = props || {};
    
    // when the theme is not set or no props, return the default colour
    if (!theme || !props) {
        return defaultPrimaryColour;
    }

    // reset the "default colour" into the primary colour from the theme
    // NOTE: we will have to pretty much assume that property exists as we develop the "theme system"
    let themePrimaryColour = theme.primary ?? defaultPrimaryColour;

    if (!variant) {
        return themePrimaryColour;
    }

    return theme[variant] ?? themePrimaryColour;
};

const calculateButtonText = (props) => {
    const { theme } = props || {};
    if (!theme || !props) {
        return '#000';  // default to black
    }
    var actualButtonColour = calculateButtonColour(props);
    return readableColor(actualButtonColour, theme.dark, theme.light);
};

export {
    calculateButtonColour,
    calculateButtonText,
    variantToColour
};
import { createContext, useContext, useEffect, useState } from "react";
import { useLazyFetchClientProfilesQuery } from "../services/assets";

const HouseRulesContext = createContext();

export const useHouseRules = () => useContext(HouseRulesContext);

const HouseRulesProvider = ({ children }) => {
    const [currentClientProfile, setCurrentClientProfile] = useState(null);

    const [fetchClientProfiles, { currentData: clientProfiles, isFetching: clientProfilesIsFetching }] = useLazyFetchClientProfilesQuery();

    useEffect(() => {
        fetchClientProfiles()
            .unwrap().then((data) => {
                if (data.length > 0) {
                    setCurrentClientProfile(data[0].value);
                }
            });
    }, [fetchClientProfiles]);

    return <HouseRulesContext.Provider
        value={{
            fetchClientProfiles,
            clientProfiles,
            clientProfilesIsFetching,
            currentClientProfile,
            setCurrentClientProfile
        }}>
        {children}
    </HouseRulesContext.Provider>
}

export default HouseRulesProvider;
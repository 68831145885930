import { wealthProApi } from '../../redux/api';

const reportProductAreaEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchReportProductAreas: builder.query({
            query: ({ fetchType = "Object" }) => fetchType === "List"
                ? `/api/reports/report-product-areas/list`
                : `/api/reports/report-product-areas`
        })
    })
});

export const {
    useFetchReportProductAreasQuery
} = reportProductAreaEndpoints;
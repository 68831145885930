import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, GridWrapper } from "../../../components";
import { FormLabel, RichTextEditor } from "../../../components/forms";
import { Spinner } from "../../../components/loaders";
import { ThemedSpan } from "../../../components/utilities";

const PrdCommentaryInput = ({ className = "", label = null, commentary, isLoading, patchCommentary, isPatching, publishCommentary, isPublishing }) => {
    const dateUserText = useMemo(() => {
        // Possible TODO: Uncomment if Jack wants modified instead of published info
        // return `${commentary?.modifiedByName}, ${commentary?.modifiedDateString}`;

        if (commentary?.statusText === "Draft") {
            if (commentary?.modifiedDateString == null) {
                return "";
            }

            return `${commentary?.modifiedByName}, ${commentary?.modifiedDateString}`;
        }

        return `${commentary?.publishedByName}, ${commentary?.publishedDateString}`;
    }, [commentary?.modifiedByName, commentary?.modifiedDateString, commentary?.publishedByName, commentary?.publishedDateString, commentary?.statusText]);

    const showSpinners = useMemo(() => isPublishing || isPatching || isLoading, [isLoading, isPatching, isPublishing]);

    return <div className={className}>
        <GridWrapper className="pb-2" gridTemplateColumns={"50% 1fr auto auto"}>
            {label
                ? <>
                    <div className="d-flex">
                        {isLoading
                            ? <Spinner />
                            : <FormLabel className={"ps-2 mt-auto"}>
                                {label}
                            </FormLabel>}
                    </div>
                    <ThemedSpan variant="muted" className="my-auto">
                        {showSpinners
                            ? <Spinner />
                            : dateUserText}
                    </ThemedSpan>
                </>
                : <ThemedSpan variant="muted" className="ps-2 my-auto">
                    {showSpinners
                        ? <Spinner className="mx-5 mb-1" />
                        : dateUserText}
                </ThemedSpan>}

            {<ThemedSpan className="col-start-3 mx-5 my-auto" variant="muted">
                {showSpinners
                    ? <Spinner className="ms-5" />
                    : commentary?.statusText}
            </ThemedSpan>}

            <span className="col-start-4 ms-auto d-flex">
                <Button onClick={publishCommentary} variant="success">Publish</Button>
            </span>
        </GridWrapper>

        {isLoading
            ? <Skeleton containerClassName="row pt-3 px-3" height={100} />
            : <RichTextEditor
                defaultValue={commentary?.text ?? ""}
                disabled={isPatching || isPublishing}
                onBlur={patchCommentary}
                height="100%"
            />}
    </div>;
}

export default PrdCommentaryInput;
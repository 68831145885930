import styled, { css } from "styled-components";
import { CurrencyInput, FormCheck, FormInput, FormLabel, FormSelect, FormTextArea, PercentageInput } from "../../../components/forms";
import useProductCosts from "../hooks/useProductCosts";
import Skeleton from "react-loading-skeleton";
import { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorMessage from "../../../components/messages/ErrorMessage";
import { IconButton } from "../../../components";

const FullSizedGrid = css`
    display: grid;
    padding: 10px;
    grid-template-columns: [section] 1fr [postto] 1fr [type] 1fr [apply] 0.5fr [description] 1.5fr [calculate] 1.5fr [defaultcost] 1fr [narrative] 2.5fr [icon] minmax(40px, 0.2fr);
    column-gap: 10px;

`;

const CostsHeaderGrid = styled.div`
    display: none;
    @media (min-width: ${props => props.collapsableWidth}px){
        ${FullSizedGrid}
    }
`;

const CostsRowGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    @media (min-width: ${props => props.collapsableWidth}px){
        ${FullSizedGrid}
    }
`;

const ProductCostsList = ({ productId, instructionTypeId }) => {

    const {
        createProductCost,
        deleteProductCost,
        fetchProductCosts,
        costsData,
        isCostsFetching,
        isCostsLoading,
        isCostsError,
        costTypesData,
        isCostTypesLoading,
        calculateTypesData,
        isCalculateTypesLoading,
        postToTypesData,
        isPostToTypesLoading,
        sectionsData,
        isSectionsLoading,
        updateProduct,
    } = useProductCosts();

    const costs = costsData || [];
    const calculateTypes = calculateTypesData || [];
    const costTypes = costTypesData || [];
    const postToTypes = postToTypesData || [];
    const sections = sectionsData || [];
    const collapsableWidth = 767.98;
    const [showLabels, setShowLabels] = useState(false);

    const handlePercentageValidation = (values) => {
        return (values.floatValue <= 100 && values.floatValue >= 0) || values.floatValue === undefined;
    };

    const onCreateProductCost = () => createProductCost({ instructionTypeId, productId });

    const onDeleteProductCost = (costId) => deleteProductCost({ costId, instructionTypeId, productId });

    const onFieldChange = (costId, field, value) => updateProduct({ costId, property: field, value, instructionTypeId, productId });

    useEffect(() => {
        fetchProductCosts(productId, instructionTypeId);
    }, [productId, instructionTypeId]);

    useEffect(() => {

        const handleResize = () => {
            setShowLabels(window.innerWidth < collapsableWidth);
        }

        window.addEventListener("resize", handleResize);

        setShowLabels(window.innerWidth < collapsableWidth);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isCostsFetching || isCostsLoading) return <Skeleton count={3} />;

    if (isCostsError && !costsData) return <ErrorMessage retryCallback={() => fetchProductCosts(productId, instructionTypeId)} />;

    return <Fragment>
        <CostsHeaderGrid collapsableWidth={collapsableWidth}>
            <FormLabel>Section</FormLabel>
            <FormLabel>Post To</FormLabel>
            <FormLabel>Type</FormLabel>
            <FormLabel>Apply</FormLabel>
            <FormLabel>Description</FormLabel>
            <FormLabel>Calculation</FormLabel>
            <FormLabel>Default Cost</FormLabel>
            <FormLabel>Narrative</FormLabel>
            <IconButton
                variant="success"
                icon="fa-plus"
                size="sm"
                buttonSize="sm"
                onClick={onCreateProductCost}
            />
        </CostsHeaderGrid>
        {showLabels && <CostsRowGrid>
            <IconButton
                variant="success"
                icon="fa-plus"
                onClick={onCreateProductCost}
                className={"w-100"}
            />
            <hr />
        </CostsRowGrid>
        }
        {costs.map((cost, i) => (
            <CostsRowGrid key={i} collapsableWidth={collapsableWidth} className="justify-content-start mb-2">
                <FormSelect
                    size={3}
                    label={showLabels ? "Section" : null}
                    options={sections}
                    defaultValue={cost.section}
                    isLoadingOptions={isSectionsLoading}
                    onBlur={(value) => onFieldChange(cost.costId, "section", value.value)}
                />
                <FormSelect
                    label={showLabels ? "Post To" : null}
                    options={postToTypes}
                    defaultValue={cost.postTo}
                    isLoadingOptions={isPostToTypesLoading}
                    onBlur={(value) => onFieldChange(cost.costId, "postTo", value.value)}
                />
                <FormSelect
                    label={showLabels ? "Cost Type" : null}
                    options={costTypes}
                    defaultValue={cost.costType}
                    isLoadingOptions={isCostTypesLoading}
                    onBlur={(value) => onFieldChange(cost.costId, "costType", value.value)}
                />
                <div className="d-flex justify-content-center align-self-center mb-2">
                    <FormCheck
                        label={showLabels ? "Apply" : " "}
                        isChecked={cost.apply}
                        onChange={(value) => onFieldChange(cost.costId, "apply", !value)}
                    />
                </div>
                <FormInput
                    label={showLabels ? "Description" : null}
                    placeholder="Description..."
                    value={cost.costDescription}
                    onBlur={(value) => onFieldChange(cost.costId, "costDescription", value)}
                />
                <FormSelect
                    label={showLabels ? "Calculate Types" : null}
                    options={calculateTypes}
                    defaultValue={cost.calculation}
                    isLoadingOptions={isCalculateTypesLoading}
                    onChange={(value) => onFieldChange(cost.costId, "calculation", value.value)}
                />
                {
                    // In DB, Fixed enum value equals 10, whereas percentage equals 20
                    cost.calculation === 10
                        ? <CurrencyInput
                            label={showLabels ? "Default Cost Amount" : null}
                            disabled={!cost.calculation}
                            value={cost.defaultCostAmount}
                            onBlur={(value) => onFieldChange(cost.costId, "defaultCostAmount", value)}
                            fixedDecimalScale={false}
                            allowNegative={false}
                        />
                        : <PercentageInput
                            label={showLabels ? "Default Cost Amount" : null}
                            disabled={!cost.calculation}
                            value={cost.defaultCostAmount}
                            onBlur={(value) => onFieldChange(cost.costId, "defaultCostAmount", value !== undefined ? value : null)}
                            fixedDecimalScale={false}
                            isAllowed={handlePercentageValidation}
                        />
                }

                <FormTextArea
                    label={showLabels ? "Narrative" : null}
                    placeholder={"Narrative..."}
                    value={cost.narrative}
                    onBlur={(value) => onFieldChange(cost.costId, "narrative", value)}
                    rows={3}
                />
                <IconButton
                    className={showLabels ? "w-100" : ""}
                    variant="danger"
                    icon="fa-trash-can"
                    onClick={() => onDeleteProductCost(cost.costId)}
                    size="sm"
                    buttonSize="sm"
                />
                {showLabels && <hr />}
            </CostsRowGrid>

        ))}
    </Fragment>;

};

export default ProductCostsList;
import { useCallback, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDropzone } from 'react-dropzone';
import toast from "react-hot-toast";
import DocumentSelect from "../../../components/forms/selects/DocumentSelect";
import { ThemedIcon } from "../../../components/utilities";
import { niceBytes } from "../../../helpers/fileHelpers";

const UploadEvidenceModal = ({ openFileInput, show, data, onConfirm, onHide, triggerAdministrationFn, isAdminRef }) => {
    const [evidenceFiles, setEvidenceFiles] = useState([]);
    const [existingFileDetails, setExistingFileDetails] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const { masterAccountId, businessSheetId } = data || {};

    const close = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        setEvidenceFiles([]);
        setExistingFileDetails(null);
        onHide(e);
    };

    const handleConfirm = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        if (!triggerAdministrationFn) return;

        setIsUploading(true);

        var request = new FormData();

        evidenceFiles.forEach((file) => {
            request.append(file.name, file, file.name);
        });

        for (var key in existingFileDetails) {
            request.append(key, existingFileDetails[key]);
        }

        toast.promise(triggerAdministrationFn(businessSheetId, request).then(() => onConfirm()), {
            loading: "Sending through administration...",
            error: (err) => err?.data?.message ?? "Error sending through administration.",
            success: "Successfully sent through administration!"
        }).finally(() => setIsUploading(false));
    }

    const onClearFile = () => {
        setEvidenceFiles([]);
        setExistingFileDetails(null);
    };

    const onDrop = useCallback((selectedFile) => {
        setEvidenceFiles(selectedFile);
        setExistingFileDetails(null);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const evidence = useMemo(() => ({
        fileName: evidenceFiles[0]?.name,
        fileSize: evidenceFiles[0]?.size,
        fileType: evidenceFiles[0]?.type,
        ...existingFileDetails
    }), [evidenceFiles, existingFileDetails]);

    return <Modal size="lg" show={show} onHide={close} centered>
        <Modal.Header closeButton><Modal.Title>Upload Evidence</Modal.Title></Modal.Header>
        <Modal.Body>
            {evidenceFiles.length === 0 && existingFileDetails == null && (<>
                <DocumentSelect
                    label="Select Existing Document:"
                    className="col mb-1"
                    masterAccountId={masterAccountId}
                    slug="business-sheets"
                    value={evidence}
                    setValue={(selection) => {
                        console.log(selection)
                        setExistingFileDetails(selection.data)
                    }}
                />

                <div {...getRootProps({ className: 'dropzone d-flex px-3 py-2 border border-3 border-opacity-10' })}>
                    <input {...getInputProps()} />
                    <div className="d-flex flex-fill justify-content-center align-items-center py-4">
                        <p className="m-0">Drag and drop a file here, or click to browse for files.</p>
                    </div>
                </div>
            </>)}
            {(evidenceFiles.length > 0 || existingFileDetails != null) && (
                <div className="d-flex p-3 border border-3 border-opacity-10">
                    <div className="d-flex justify-content-center align-items-center">
                        <ThemedIcon icon={"fa-file"} size="2xl" variant="primary" />
                    </div>
                    <p className="flex-fill m-0 px-3 lh-1">
                        <span>{evidence.fileName}</span>
                        <br />
                        <span className="text-muted fs-smallest">{niceBytes(evidence.fileSize)}</span>
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                        <ThemedIcon className="has-pointer" icon="fa-xmark" size="2xl" variant="danger" onClick={onClearFile} />
                    </div>
                </div>
            )}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="light" onClick={close}>Cancel</Button>
            <Button
                variant="success"
                onClick={handleConfirm}
                disabled={(evidenceFiles.length === 0 && existingFileDetails == null) || isUploading}
            >
                Send to Administration
            </Button>

        </Modal.Footer>
    </Modal>
};

export default UploadEvidenceModal;
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { Button } from "../../../components";
import { DateTimeInput, FormInput, FormTextArea } from "../../../components/forms";
import { MasterAccountSelect, ReminderTypesSelect, UserDelegatesSelect } from "../../../components/forms/selects";
import { FileUploadModal } from "../../../components/modals";
import { AttachmentsToggle } from "../../../components/utilities";
import { DelegatedAccessTypes } from "../../../helpers/constants";
import { usePersonalReminders } from "../hooks/PersonalRemindersContext";
import usePersonalReminderRow from "../hooks/usePersonalReminderRow";
import MasterAccountProjectPagedSelect from "./MasterAccountProjectSelect";
import RetryDocumentUploadModal from "./RetryDocumentUploadModal";
import { Spinner } from "../../../components/loaders";
import { patchReplace } from "../../../helpers/patchDoc";
import Skeleton from "react-loading-skeleton";

const EditPersonalReminderModalLoadingPanel = () => {

    const skeletonHeight = 48;

    return <React.Fragment>
        <div className="row mb-4">
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
        </div>
        <div className="row mb-4">
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
        </div>
        <div className="row mb-4">
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
            <div className="col-1">
                <Skeleton height={skeletonHeight} />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <Skeleton height={skeletonHeight * 2.5} />
            </div>
        </div>
    </React.Fragment>

};

const EditPersonalReminderModal = () => {

    const {
        selectedReminder,
        isFetchingReminder,
        reminderError,
        setSelectedReminderId
    } = usePersonalReminders();
    const [showUploadFiles, setShowUploadFiles] = useState(false);
    const [showRetryUpload, setShowRetryUpload] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState([]);

    const {
        id,
        description,
        notes,
        deadlineDate,
        // priority,
        appointmentId,
        projectId,
        project,
        userId,
        typeId,
        type,
        isTypeVisible,
        masterAccountId,
        masterAccountName,
        attachments
    } = selectedReminder || {};

    const {
        patchReminder,
        patchReminderByOperations,
        uploadAttachment,
        uploadFilesResult
    } = usePersonalReminderRow(id);

    const { isLoading: isUploading, data: uploadedAttachments, ...uploadResult } = uploadFilesResult || {};

    const handleUpload = (files, fileInfo) => {
        setFilesToUpload(files);
        toast.promise(uploadAttachment(files, fileInfo, masterAccountId), {
            loading: "Uploading attachment...",
            error: (err) => {
                return "Unable to upload attachment."
            },
            success: (data) => {
                var isError = data.some(x => x.status !== 200);

                if (isError) {
                    return "One or more attachments need further action."
                }

                setShowRetryUpload(false);
                setShowUploadFiles(false);
                return "Attachments uploaded."
            }
        });
    };

    useEffect(() => {
        if (!uploadResult.isSuccess) return;

        if (Array.isArray(uploadedAttachments) && uploadedAttachments.some(x => x.status !== 200)) {
            setShowRetryUpload(true);
        }
    }, [uploadedAttachments]);

    return (
        <React.Fragment>
            <Modal centered backdrop="static" size="lg" show={selectedReminder != null || reminderError != null || isFetchingReminder} onHide={() => setSelectedReminderId(null)}>
                <Modal.Header>{isFetchingReminder
                    ? "Loading..."
                    : reminderError != null
                        ? "Error!"
                        : description}</Modal.Header>
                <Modal.Body>
                    {isFetchingReminder
                        ? <EditPersonalReminderModalLoadingPanel />
                        : reminderError != null
                            ? <div>{reminderError}</div>
                            : <>
                                <div className="row mb-3">
                                    <div className="col">
                                        {isTypeVisible
                                            ? <ReminderTypesSelect
                                                defaultValue={typeId}
                                                onBlur={(value) => patchReminder("typeId", value.value)}
                                                label="Type"
                                            />
                                            : <FormInput
                                                label="Type"
                                                value={type}
                                                readOnly={true}
                                            />}
                                    </div>
                                    <div className="col">
                                        <UserDelegatesSelect
                                            accessType={DelegatedAccessTypes.writeReminders}
                                            label="User"
                                            defaultValue={userId}
                                            onChange={({ value }) => patchReminder("userId", value)}
                                        />

                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <MasterAccountSelect
                                            label={"Account"}
                                            valueObject={{ label: masterAccountName, value: masterAccountId }}
                                            setValue={(selection) => {
                                                patchReminderByOperations([
                                                    patchReplace("masterAccountId", selection.value),
                                                    patchReplace("masterAccountName", selection.label),
                                                    patchReplace("projectId", null),
                                                ]);
                                            }}
                                            isDisabled={appointmentId != null}
                                        />
                                    </div>
                                    <div className="col">
                                        <MasterAccountProjectPagedSelect
                                            label="Project"
                                            disabled={!masterAccountId}
                                            masterAccountId={masterAccountId}
                                            value={projectId}
                                            onBlur={({ value }) => patchReminder("projectId", value)}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <FormInput
                                            value={description}
                                            onBlur={(value) => patchReminder("description", value)}
                                            label="Description"
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <DateTimeInput
                                                    value={deadlineDate}
                                                    isClearable
                                                    label={"Date"}
                                                    onBlur={(value) => patchReminder("deadlineDate", value)}
                                                />
                                            </div>
                                            {/* <div className="col">
                                <FormSelect
                                    label="Priority"
                                    defaultValue={priority}
                                    options={Object.keys(MessagePriorities).map(label => ({ label, value: MessagePriorities[label] }))}
                                    onChange={({ value }) => patchReminder("priority", value)}
                                />
                            </div> */}
                                            <div className="col-auto mt-auto">
                                                <AttachmentsToggle
                                                    attachments={attachments}
                                                    isCreatable
                                                    onUploadFn={() => setShowUploadFiles(true)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <FormTextArea
                                        label="Notes"
                                        value={notes}
                                        onBlur={value => patchReminder("notes", value)}
                                        minRows={3}
                                    />
                                </div>
                            </>}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button variant="light" onClick={() => setSelectedReminderId(null)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <FileUploadModal
                handleConfirm={handleUpload}
                uploadProps={{ masterAccountId }}
                title="Upload Attachments"
                handleClose={() => setShowUploadFiles(false)}
                show={showUploadFiles}
                isUploadingFile={isUploading}
            />
            <RetryDocumentUploadModal
                attachmentsResponse={uploadFilesResult?.data}
                uploadDocuments={handleUpload}
                onClose={() => setShowRetryUpload(false)}
                show={showRetryUpload}
                files={filesToUpload}
            />
        </React.Fragment>
    )
};

export default EditPersonalReminderModal;
import { socket } from "../../../redux/socket";

const DEFAULT_REPORT_ROOM_NAME = "report_templates";
const CREATED_CHANNEL = "reports.templates.created";
const PATCHED_CHANNEL = "reports.templates.patched";

const fetchReportTemplateCacheSocketHandler = () => async (props, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
    const roomName = DEFAULT_REPORT_ROOM_NAME;
    try {
        // this waits till the data has been cached
        await cacheDataLoaded;

        // then we subscribe to the report room to listen for changes
        socket.emit('subscribe-report-templates', { room: roomName });
        
        // setup an event for when the report is created
        // TODO: we will figure out how best to resolve adding to existing records later...
        socket.on(CREATED_CHANNEL, viewModel => {
            updateCachedData((data) => {
                data.newRecordsSinceCache = data.newRecordsSinceCache + 1;
            })
        });

        // setup an event for when the report is patched
        socket.on(PATCHED_CHANNEL, viewModel => {
            updateCachedData((data) => {
                let item = data.results.find(el => el.id === viewModel.id);
                if (item) {
                    Object.assign(item, viewModel)
                }
            });
        });
    }
    catch { }
    await cacheEntryRemoved;
    socket.emit('unsubscribe-report-templates', { room: roomName });
    socket.off(CREATED_CHANNEL);
    socket.off(PATCHED_CHANNEL);
};

export default fetchReportTemplateCacheSocketHandler;
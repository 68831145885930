import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import HeadOfficeMasterAccountSelect, { } from "../../../components/forms/selects/HeadOfficeMasterAccountSelect";
import { DateInput, FormInput } from "../../../components/forms";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useEffect, useState } from "react";
import _ from "lodash";

const CustodianAccountMatchingFilterModals = ({ show, handleClose }) => {

    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const {
        custodianCode,
        platformAccountId,
        masterAccount,
        designation,
        startDate,
        endDate
    } = tempFilters;

    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(_ => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof (handleClose) === 'function') {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters(_ => filter);
        }
    }, [filter, setTempFilters]);

    return (
        <Modal centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Filter Matches</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <FormInput
                            label="Custodian Code"
                            value={custodianCode ?? ""}
                            onChange={(e) => onFilterValueChanged("custodianCode", e.target.value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Platform Account"
                            value={platformAccountId ?? ""}
                            onChange={(e) => onFilterValueChanged("platformAccountId", e.target.value)}
                            disableAnimations
                        />
                    </div>

                </div>
                <div className="row mb-3">
                    <div className="col">
                        <HeadOfficeMasterAccountSelect
                            label="Master Account"
                            valueObject={masterAccount}
                            onChange={value => onFilterValueChanged("masterAccount", value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Designation"
                            value={designation ?? ""}
                            onChange={(e) => onFilterValueChanged("designation", e.target.value)}
                            disableAnimations
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col d-flex align-items-end">
                        <DateInput
                            label="Created Date"
                            value={startDate}
                            onBlur={(value) => onFilterValueChanged("startDate", value)}
                            isClearable
                            disableAnimations
                        />
                        <span className="mx-3 pb-2">to</span>
                        <DateInput
                            value={endDate}
                            onBlur={(value) => onFilterValueChanged("endDate", value)}
                            isClearable
                            disableAnimations
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
                <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustodianAccountMatchingFilterModals;
import React, { useState } from "react";
import FeatureBody from "./components/FeatureBody";
import IconLink from "./components/IconLink";
import IconWrapper from "./components/IconWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActiveUsers = () => {
    const [show, setShow] = useState(false);
    return (
        <React.Fragment>
            <IconWrapper>
                <IconLink>
                    <FontAwesomeIcon 
                        icon="fa-users" 
                        size="lg" 
                        onClick={() => setShow(!show)} 
                    />
                </IconLink>
            </IconWrapper>
            <FeatureBody isOpen={show} setIsOpen={setShow} />
        </React.Fragment>
    )
};

export default ActiveUsers;
import { wealthProApi } from "../../redux/api";

const hoDefinitionEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        hoFetchBusinessSheetStatuses: builder.query({
            query: () => `api/headofficeadmin/BusinessSheetStatuses`
        }),
        hoFetchApplicationStatuses: builder.query({
            query: () => `api/headofficeadmin/ApplicationStatuses`
        }),

    })
});

export const {
    useHoFetchBusinessSheetStatusesQuery,
    useLazyHoFetchBusinessSheetStatusesQuery,
    useHoFetchApplicationStatusesQuery,
} = hoDefinitionEndpoints;
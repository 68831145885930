import { wealthProApi } from "../../redux/api";

const icPrdCommentaryApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (build) => ({
        fetchMasterPrd: build.query({
            query: ({ serviceLevelAgreementId }) => `api/assets/prdCommentary/${serviceLevelAgreementId}`
        }),
        patchPrdCommentary: build.mutation({
            query: ({ serviceLevelAgreementId, type, commentary }) => ({
                url: `api/assets/prdCommentary/${serviceLevelAgreementId}/${type}`,
                method: 'PATCH',
                body: { newCommentary: commentary }
            }),
            async onQueryStarted({ serviceLevelAgreementId, typeString }, { dispatch, queryFulfilled }) {
                try {
                    // Pessimistically update the relevant commentary object
                    const { data } = await queryFulfilled;
                    dispatch(icPrdCommentaryApiEndpoints.util.updateQueryData('fetchMasterPrd', { serviceLevelAgreementId }, (draft) => {
                        draft[typeString] = data[typeString];
                    }));
                } catch (error) {
                    console.error(error);
                }
            }
        }),
        publishPrdCommentary: build.mutation({
            query: ({ serviceLevelAgreementId, type }) => ({
                url: `api/assets/prdCommentary/${serviceLevelAgreementId}/${type}`,
                method: 'POST'
            }),
            async onQueryStarted({ serviceLevelAgreementId, typeString }, { dispatch, queryFulfilled }) {
                try {
                    // Pessimistically update *just the published information* for the relevant commentary object
                    const { data } = await queryFulfilled;
                    dispatch(icPrdCommentaryApiEndpoints.util.updateQueryData('fetchMasterPrd', { serviceLevelAgreementId }, (draft) => {
                        draft[typeString].publishedDateString = data.publishedDateString;
                        draft[typeString].publishedByName = data.publishedByName;
                        draft[typeString].statusText = "Published";
                    }));
                } catch (error) {
                    console.error(error);
                }
            }
        }),
        publishAllPrdCommentaries: build.mutation({
            query: ({ serviceLevelAgreementId }) => ({
                url: `api/assets/prdcommentary/${serviceLevelAgreementId}`,
                method: 'POST'
            }),
            async onQueryStarted({ serviceLevelAgreementId }, { dispatch, queryFulfilled }) {
                try {
                    // Pessimistically update all commentary objects
                    const { data } = await queryFulfilled;
                    dispatch(icPrdCommentaryApiEndpoints.util.updateQueryData('fetchMasterPrd', { serviceLevelAgreementId }, (draft) => {
                        draft.coverLetter = data.coverLetter;
                        draft.economicContext = data.economicContext;
                        draft.discussionBullets = data.discussionBullets;
                        draft.nonStandardPortfolio = data.nonStandardPortfolio;
                    }));
                } catch (error) {
                    console.error(error);
                }
            }
        }),
        fetchPortfolioNoteOptions: build.query({
            query: () => `api/assets/portfolioNote`
        }),
        fetchPortfolioNote: build.query({
            query: ({standardPortfolioId }) => `api/assets/portfolioNote/${standardPortfolioId}`
        }),
        patchPortfolioNote: build.mutation({
            query: ({ standardPortfolioId, note }) => ({
                url: `api/assets/portfolioNote/${standardPortfolioId}`,
                method: 'PATCH',
                body: { newCommentary: note }
            }),
            async onQueryStarted({ standardPortfolioId, note }, { dispatch, queryFulfilled }) {
                try {
                    // Pessimistically update the relevant portfolio note object
                    const { data } = await queryFulfilled;
                    dispatch(icPrdCommentaryApiEndpoints.util.updateQueryData('fetchPortfolioNote', { standardPortfolioId }, (draft) => {
                        draft.text = note;
                        draft.modifiedByName = data.modifiedByName;
                        draft.modifiedDateString = data.modifiedDateString;
                        draft.statusText = "Draft";
                    }));

                } catch (error) {
                    console.error(error);
                }
            }
        }),
        publishPortfolioNote: build.mutation({
            query: ({ standardPortfolioId }) => ({
                url: `api/assets/portfolioNote/${standardPortfolioId}`,
                method: 'POST'
            }),
            async onQueryStarted({ standardPortfolioId }, { dispatch, queryFulfilled }) {
                try {
                    // Pessimistically update *just the published information* for the relevant portfolio note object
                    const { data } = await queryFulfilled;
                    dispatch(icPrdCommentaryApiEndpoints.util.updateQueryData('fetchPortfolioNote', { standardPortfolioId }, (draft) => {
                        draft.publishedDateString = data.publishedDateString;
                        draft.publishedByName = data.publishedByName;
                        draft.statusText = "Published";
                    }));
                } catch (error) {
                    console.error(error);
                }
            }
        })
    })
});

export const {
    useLazyFetchMasterPrdQuery,
    usePatchPrdCommentaryMutation,
    usePublishPrdCommentaryMutation,
    usePublishAllPrdCommentariesMutation,
    useLazyFetchPortfolioNoteOptionsQuery,
    useLazyFetchPortfolioNoteQuery,
    usePatchPortfolioNoteMutation,
    usePublishPortfolioNoteMutation
} = icPrdCommentaryApiEndpoints;
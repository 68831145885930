import { Button, Modal } from "react-bootstrap";
import { FormInput } from "../../../components/forms";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useEffect, useState } from "react";
import _ from "lodash";

const FilterProvidersModal = ({ show, onHide }) => {

    const { filter, setFilter, clearFilter, defaultFilters } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const {
        custodianCode,
        fundManagerCode,
        providerName
    } = tempFilters;

    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setFilter(defaultFilters);
    }

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        onHide();
    };

    useEffect(() => {
        if (!_.isEqual(tempFilters, filter)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    return (
        <Modal backdrop="static" centered size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Providers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-2">
                    <div className="col">
                        <FormInput
                            label="Provider Name"
                            value={providerName ?? ""}
                            onBlur={(value) => onFilterValueChanged("providerName", value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Fund Manager Code"
                            value={fundManagerCode ?? ""}
                            onBlur={(value) => onFilterValueChanged("fundManagerCode", value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Custodian Code"
                            value={custodianCode ?? ""}
                            onBlur={(value) => onFilterValueChanged("custodianCode", value)}
                            disableAnimations
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
                <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );

};

export default FilterProvidersModal;
import { useEffect, useState } from "react";
import { Spinner } from "../../components/loaders";
import { AccordionList, CenterArea, Main } from "../../components/utilities";
import { AssetClassifications, CommentsAndOpinion, MappedShareClasses, PerformanceChart } from "./accordions";
import { FundInfoHeader } from "./components";
import useFundDetails from "./hooks/useFundDetails";

const FundDetails = () => {
    const [openAccordions, setOpenAccordions] = useState(["asset-classifications"]);

    const [{ reload }, { assetGroup, assetGroupIsNotReady }] = useFundDetails();

    const [assetNameDisplayText, setAssetNameDisplayText] = useState();
    const [monitoringDisplayText, setMonitoringDisplayText] = useState();
    const [controlStatusDisplayText, setControlStatusDisplayText] = useState();

    useEffect(() => {
        setAssetNameDisplayText(assetGroup?.assetName);
        setMonitoringDisplayText(assetGroup?.monitoringDisplayText);
        setControlStatusDisplayText(assetGroup?.controlStatusDisplayText);
    }, [assetGroup?.assetName, assetGroup?.controlStatusDisplayText, assetGroup?.monitoringDisplayText]);

    return <>
        <FundInfoHeader
            assetNameDisplayText={assetNameDisplayText}
            monitoringDisplayText={monitoringDisplayText}
            controlStatusDisplayText={controlStatusDisplayText}
        />
        <Main className="p-3">
            {assetGroupIsNotReady
                ? <CenterArea><Spinner /></CenterArea>
                : assetGroup == null
                    ? <>We were unable to find the core asset relating to that ID. Click <button className="anchor" onClick={reload}>here</button> to try again.</>
                    : <AccordionList
                        activeKey={openAccordions}
                        defaultActiveKey={null}
                        onSelect={(eventKeys, _) => setOpenAccordions(eventKeys)}
                        gap={1}
                        alwaysOpen>

                        <AssetClassifications
                            setAssetNameDisplayText={setAssetNameDisplayText}
                            setMonitoringDisplayText={setMonitoringDisplayText}
                            setControlStatusDisplayText={setControlStatusDisplayText}
                        />
                        <CommentsAndOpinion />
                        <MappedShareClasses assetGroupId={assetGroup?.assetGroupId} />
                        <PerformanceChart />
                    </AccordionList>}
        </Main>
    </>
}

export default FundDetails;
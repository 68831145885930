import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { PasswordInput } from "../../../components/forms";
import { ThemedParagraph } from "../../../components/utilities";

const UpdatePasswordModal = ({ 
    theme = {},
    size = "lg",
    isUpdating = false,
    handleConfirm = () => {}, 
    handleClose = () => {},
    show = true
}) => {
    const [password, setPassword] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const _confirm = () => {
        setIsLoading(_ => true);
        handleConfirm(password);
    };

    return (
        <Modal size={size} centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Create/Update Password?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ThemedParagraph theme={theme} variant="danger" className="mb-3">
                    Warning! It is not recommended to manually set a clients password as this could be insecure. If they have forgotten their password, we recommend they use the "Forgot Password" feature so that they can create their own secure password. If you still want to set this accounts password, then enter the new value below.
                </ThemedParagraph>
                <div>
                    <PasswordInput 
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(_ => e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme={theme} variant="danger" onClick={_confirm} disabled={isUpdating || isLoading}>Update Password</Button>
                <Button theme={theme} variant="light" onClick={handleClose} disabled={isLoading}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdatePasswordModal;
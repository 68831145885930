import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { Alert, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import styled from "styled-components";
import { Button, IconButton } from "../../../components";
import { Spinner } from "../../../components/loaders";
import { AccordionBody, AccordionHeader, AccordionListItem, CenterPage } from "../../../components/utilities";
import { useInstruction } from "../contexts/InstructionContext";
import {
    ChangeOwnership,
    ISASubscription,
    ISATransfer,
    InvestmentAccountTransfer,
    InvestmentIncome,
    MortgageEquityRelease,
    NewInvestment,
    PensionTransfer,
    ProtectionPolicy,
    RaiseFee,
    RegularContribution,
    SellSwitch,
    TaxFreeCashPensionIncome
} from "../instructions";
import { InstructionErrorMessage } from "./ErrorMessages";

// Mimics the behavior of the backend enum
const instructionEnumMap = {
    ISASubscription: 0,
    NewInvestment: 1,
    NewRegularContribution: 2,
    SellSwitch: 3,
    ISATransfer: 4,
    InvestmentAccountTransfer: 5,
    PensionTransfer: 6,
    TaxFreeCashAndPensionIncome: 7,
    InvestmentIncome: 8,
    ChangeOwnership: 9,
    RaiseAFee: 10,
    NewProtectionPolicy: 11,
    MortgageAndEquityRelease: 12
};

const FullWidthSpan = styled.span`
    width: 100%;
`

const ReadOnlyAccordionBody = styled(AccordionBody)`
    ${props => props.readOnly ? 'pointer-events: none' : ''};
    ${props => props.readOnly ? 'opacity: 0.7' : ''};
    ${props => props.readOnly ? 'cursor: not-allowed' : ''};
`

const InstructionWrapper = ({ shouldRenderInstruction, forceClose, readOnly }) => {
    const [{
        deleteSelf,
        retry
    }, {
        instructionId,
        instruction,
        instructionIsError,
        instructionIsLoading,
        instructionIsFetching,
        anythingIsLoading
    }] = useInstruction();

    const renderErrors = useCallback(() => {
        if (instruction?.errors?.length > 0)
            return <Alert variant="danger">
                <Alert.Heading>Errors in this instruction</Alert.Heading>
                <ul>
                    {instruction.errors.map((error, index) => <li key={index}>{error.message}</li>)}
                </ul>
            </Alert>
    }, [instruction?.errors]);

    const renderInstruction = useCallback(() => {
        if (instructionIsLoading || instructionIsFetching || anythingIsLoading)
            return <CenterPage>
                <Spinner />
            </CenterPage>;

        if (instructionIsError)
            return <InstructionErrorMessage retry={retry} />

        switch (instruction?.instructionType?.instructionType) {
            case instructionEnumMap.ISASubscription:
                return <ISASubscription instructionId={instructionId} />;
            case instructionEnumMap.NewInvestment:
                return <NewInvestment instructionId={instructionId} />;
            case instructionEnumMap.NewRegularContribution:
                return <RegularContribution instructionId={instructionId} />;
            case instructionEnumMap.SellSwitch:
                return <SellSwitch instructionId={instructionId} />;
            case instructionEnumMap.RaiseAFee:
                return <RaiseFee />
            case instructionEnumMap.InvestmentIncome:
                return <InvestmentIncome instructionId={instructionId} />
            case instructionEnumMap.TaxFreeCashAndPensionIncome:
                return <TaxFreeCashPensionIncome instructionId={instructionId} />
            case instructionEnumMap.InvestmentAccountTransfer:
                return <InvestmentAccountTransfer instructionId={instructionId} />
            case instructionEnumMap.ChangeOwnership:
                return <ChangeOwnership instructionId={instructionId} />
            case instructionEnumMap.PensionTransfer:
                return <PensionTransfer instructionId={instructionId} />
            case instructionEnumMap.ISATransfer:
                return <ISATransfer instructionId={instructionId} />
            case instructionEnumMap.NewProtectionPolicy:
                return <ProtectionPolicy />;
            case instructionEnumMap.MortgageAndEquityRelease:
                return <MortgageEquityRelease />;
            default:
                return <>Not Implemented</>
        }
    }, [anythingIsLoading, instruction?.instructionType?.instructionType, instructionId, instructionIsError, instructionIsFetching, instructionIsLoading, retry]);

    const [isDeleting, setIsDeleting] = useState(false);

    const show = useCallback(() => {
        setIsDeleting(true);
        if (shouldRenderInstruction)
            forceClose();
    }, [forceClose, shouldRenderInstruction]);
    const hide = useCallback(() => setIsDeleting(false), []);

    const label = useMemo(() => {
        // For now just return the instruction type, later retrieve the subscription amount/other info
        return instruction?.instructionType?.description ?? "Loading";
    }, [instruction?.instructionType?.description]);

    return <>
        <AccordionListItem className="my-2" eventKey={instructionId.replace(/-/g, '')}>
            <AccordionHeader>
                <FullWidthSpan className="d-flex justify-content-between">
                    <span className="my-auto">
                        {instruction?.errors?.length > 0
                            ? <FontAwesomeIcon
                                icon="triangle-exclamation"
                                className="me-3"
                                color='orange'
                                size="lg"
                            />
                            : <></>}
                        <span>{label}</span>
                    </span>

                    {!readOnly && <IconButton
                        className="me-3"
                        icon="fa-trash"
                        variant="danger"
                        disabled={instructionIsLoading || instructionIsFetching}
                        onClick={show}
                    />}
                </FullWidthSpan>
            </AccordionHeader>
            <ReadOnlyAccordionBody readOnly={readOnly}>
                {shouldRenderInstruction
                    ? <>
                        {renderErrors()}
                        {renderInstruction()}
                    </>
                    : <></>}
            </ReadOnlyAccordionBody>
        </AccordionListItem>
        <Modal show={isDeleting} onShow={show} onHide={hide} centered>
            <ModalHeader closeButton>Delete '{instruction?.instructionType?.description}'?</ModalHeader>
            <ModalBody>Are you sure you want to delete this instruction?</ModalBody>
            <ModalFooter><ButtonGroup>
                <Button variant="warning" onClick={hide}>No, Cancel</Button>
                <Button variant="danger" onClick={deleteSelf}>Yes, Delete</Button>
            </ButtonGroup></ModalFooter>
        </Modal>
    </>;
}

export default InstructionWrapper;
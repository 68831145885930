import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { readableColor } from "polished";
import React, { useEffect, useId, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { Spinner } from "../../components/loaders";
import FormGroup from "./FormGroup";
import FormLabel from "./FormLabel";
import InputErrorMessage from "./InputErrorMessage";

const CheckboxInput = styled.input`
    &:checked {
        color: ${props => readableColor(props.theme.secondary, props.theme.dark, props.theme.light)};
        background-color: ${props => props.theme.secondary};
        border-color: ${props => props.theme.secondary};
    }
`;

const CheckboxWrapper = styled(Form.Check)`
    && > .form-check-input[type="checkbox"][loading="true"],
    && > svg.fa-check,
    && > svg.fa-triangle-exclamation {
        float: left;
        margin-top: 0.25em;
        border-radius: 50% !important;
    }

    && > svg.fa-check {
        color: ${props => props.theme.success};
    }

    && > svg.fa-triangle-exclamation {
        color: ${props => props.theme.warning};
    }
`;

const CheckboxSymbol = styled(FontAwesomeIcon)`
    width: 1em;
    height: 1em;
`;

const CheckboxSpinner = styled(Spinner)`
    width: 1em;
    height: 1em;
`;

const CheckboxComplete = () => <CheckboxSymbol icon="fa-check" />

const CheckboxWarning = () => <CheckboxSymbol icon="fa-triangle-exclamation" />

const FormCheck = ({
    className = "",
    disableAnimations = false,
    disabled,
    groupClassName,
    id,
    isChecked = false,
    label,
    // Two directional props, typically if vertical is true, so will labelFirst, but this has support for both
    vertical = false,
    labelClassName = "",
    labelFirst = false,
    onChange,
    successTimeout = 1000,
    type = "checkbox",
    onBlur,
    ...rest
}) => {
    const inputRef = useRef();
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentValue, setCurrentValue] = useState(isChecked);

    const onChangeEvent = (e) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        // check that the value has changed before triggering anything
        // NOTE: also check the function HAS been passed into this component
        if (!onChange || typeof (onChange) !== 'function') {
            return;
        }

        // trigger the loading animation on the input
        if (disableAnimations !== true) {
            setError(_ => null);
            setIsLoading(_ => true);
        }

        // used to safely check the function returns something we can check on
        var possibleResult = type === "radio" 
            ? onChange(currentValue, e)
            : onChange(!currentValue, e);

        // we can now safely use the `then` method and handle the response accordingly
        Promise.resolve(possibleResult).then(
            _ => {
                if (disableAnimations === true) {
                    return;
                }

                setIsLoading(_ => false);
                setIsSuccess(_ => true);
            },
            error => {
                if (disableAnimations === true) {
                    return;
                }
                setIsLoading(_ => false);
                setError(_ => error);
            }
        );
    };

    useEffect(() => {
        if (isSuccess === false) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(_ => false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    useEffect(() => {
        if (isChecked !== currentValue) {
            setCurrentValue(isChecked);
        }
    }, [isChecked]);

    return <FormGroup horizontal={!vertical} className={`align-items-center ${groupClassName}`}>
        {labelFirst && label && (<FormLabel className={`col-auto form-check-label ${labelClassName ? labelClassName : ''}`} as={Form.Check.Label} htmlFor={componentId} loading={isLoading.toString()}>{label}</FormLabel>)}
        <CheckboxWrapper className={`col-auto p${labelFirst ? 's' : 'e'}-0 d-flex flex-row justify-content-center`} ref={inputRef} type={type}>
            <Form.Check.Input
                id={componentId}
                type={type}
                className={className}
                as={isSuccess ? CheckboxComplete : isLoading ? CheckboxSpinner : CheckboxInput}
                checked={currentValue}
                loading={isLoading.toString()}
                disabled={disabled || isLoading}
                isValid={isSuccess}
                isInvalid={error !== null}
                onChange={onChangeEvent}
                {...rest}
            />
            <InputErrorMessage error={error} retryCallback={onChangeEvent} />
        </CheckboxWrapper>
        {!labelFirst && label && (<FormLabel className={`col-auto form-check-label ${labelClassName ? labelClassName : ''}`} as={Form.Check.Label} htmlFor={componentId} loading={isLoading.toString()}>{label}</FormLabel>)}
    </FormGroup>
};

export default FormCheck;
import _ from "lodash";
import searchUserDelegatesSocketHandler from "./sockets/searchUserDelegatesSocketHandler";
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

const userDelegatesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createDelegatedAccess: builder.mutation({
            query: (data) => ({
                url: `/api/identity/users/delegates`,
                method: 'POST',
                body: data
            })
        }),
        deleteDelegatedAccess: builder.mutation({
            query: ({ delegateAccessId }) => ({
                url: `/api/identity/users/delegates/${delegateAccessId}`,
                method: 'DELETE',
                body: { }
            }),
        }),
        fetchDelegatedUsers: builder.query({
            query: ({accessType}) => `api/identity/users/delegates/list?accessType=${accessType}`
        }),
        getDelegatedAccessRightsForUser: builder.query({
            query: ({userId}) => `api/identity/users/${userId}/delegates`
        }),
        patchDelegateAccess: builder.mutation({
            query: ({ delegateAccessId, operations }) => ({
                url: `/api/identity/users/delegates/${delegateAccessId}`,
                method: 'PATCH',
                body: operations
            }),
        }),
        searchUserDelegates: builder.query({
            query: (data) => ({
                url: `/api/identity/users/delegates/search`,
                method: 'POST',
                body: data
            }),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            onCacheEntryAdded: searchUserDelegatesSocketHandler()
        })
    })
});

export const {
    useCreateDelegatedAccessMutation,
    useDeleteDelegatedAccessMutation,
    useFetchDelegatedUsersQuery,
    useLazyFetchDelegatedUsersQuery,
    useGetDelegatedAccessRightsForUserQuery,
    useLazyGetDelegatedAccessRightsForUserQuery,
    useLazySearchUserDelegatesQuery,
    usePatchDelegateAccessMutation,
    useSearchUserDelegatesQuery
} = userDelegatesApiEndpoints;
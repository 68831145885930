import { wealthProApi } from "../../redux/api";

const designationEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchDesignations: builder.query({
            query: ({ masterAccountId, listType, productId }) => `api/recommendations/Designation/${masterAccountId}?listType=${listType}${productId != null ? `&productId=${productId}` : ""}`
        })
    })
});

export const {
    useLazyFetchDesignationsQuery,
    useFetchDesignationsQuery
} = designationEndpoints;
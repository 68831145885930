import React, { useMemo } from "react";
import styled from "styled-components";

const GENERIC_ERROR_MESSAGE = "Sorry! We were unable to complete your request.";
const NO_MICROSERVICE_CONNECTION_MESSAGE = "Poor or no connection detected. It is likely that the Service for handling your request is unavailable.";
const NO_MICROSERVICE_CONNECTION_STATUS = 504;

const Message = styled.p`
    color: ${props => props.theme.danger};
    margin: 0;
`;

const ErrorMessage = ({
    className,
    error,
    flex = "row",
    imageSrc = "/images/error-ninja.png",
    imageHeight = 92,
    imageWidth = 128,
    message = GENERIC_ERROR_MESSAGE,
    messageAlign = "start",
    messageSize = "",
    retryCallback,
    ...rest
}) => {
    const ErrorMessage = useMemo(() => {
        if (!error) {
            return message;
        }

        const { data, originalStatus } = error;
        if (originalStatus === NO_MICROSERVICE_CONNECTION_STATUS) {
            return NO_MICROSERVICE_CONNECTION_MESSAGE;
        }

        // TODO: handle more status codes?
        if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
            if (typeof (data) === 'object') {
                return `ERROR: ${JSON.stringify(data)}`;
            }
            else {
                return `ERROR: ${data}`;
            }
        }

        return GENERIC_ERROR_MESSAGE;
    }, [error]);

    const wrapperClassName = flex === "column" ? "d-flex flex-column" : "d-flex";

    return <div className={wrapperClassName}>
        {imageSrc && imageSrc.length > 0 && (
            <div className="d-flex justify-content-center align-items-center">
                <img src={imageSrc} height={imageHeight} width={imageWidth} alt="err-icon" />
            </div>
        )}
        <div className={className || " d-flex flex-column flex-fill justify-content-center align-items-center overflow-auto"} {...rest}>
            <Message>
                <span>{ErrorMessage}</span>
                {retryCallback && typeof (retryCallback) === 'function' && (
                    <React.Fragment>
                        <br />
                        <a href="#" onClick={retryCallback}>Click here to retry.</a>
                    </React.Fragment>
                )}
            </Message>
        </div>
    </div>
};

export default ErrorMessage;
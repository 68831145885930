const acceptedFileTypes = {
    "application/msword": [],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
    "text/csv": [],
    "image/gif": [],
    "image/jpeg": [],
    "image/png": [],
    "application/pdf": [],
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    "application/xml": [],
    "text/plain": [],
    "video/mp4": [],
    "video/mpeg": [],
    "video/x-msvideo": [],
    "application/vnd.ms-outlook": [],
    "message/rfc822": []
};
export {
    acceptedFileTypes
};
import { ButtonGroup, Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { Controller, useForm } from "react-hook-form";
import React, { useMemo } from "react";
import { FormInput, FormLabel, FormSelect, RichTextEditor } from "../../../components/forms";
import { components } from "react-select";
import styled from "styled-components";
import { darken } from "polished";
import { patchReplace } from "../../../helpers/patchDoc";

const ClickableMultiValueLabel = styled.div`
    &:hover {
        background-color: ${props => darken(props.theme.linkDarkenAmount * 1.75, props.theme.light)};
    }
`;

export const EditEmailContactModal = ({ data, show, onClose }) => {

    const {
        editEmailContactModal: {
            deleteEmailContact,
            patchEmailContact
        }
    } = usePersonalOutlookContext();

    const {
        id,
        contactEmailAddress,
        contactName
    } = data || {};

    const handleClose = (e) => {
        e?.preventDefault();

        onClose(e);
    };

    const update = (property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patchEmailContact({ id, operations })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return <Modal backdrop="static" show={show} onHide={handleClose} centered>
        <Modal.Header>Edit Email Contact</Modal.Header>
        <Modal.Body>
            <FormInput
                className="mb-2"
                label={"Email"}
                value={contactEmailAddress}
                onBlur={(value) => update("contactEmailAddress", value)}
            />
            <FormInput
                label={"Display Name"}
                value={contactName}
                onBlur={(value) => update("contactName", value)}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={handleClose} variant="light">Close</Button>
        </Modal.Footer>
    </Modal>;
};

const MultiValueLabel = ({
    data,
    onClick,
    ...rest
}) => {

    const handleOnClick = (e) => {
        if (!onClick || typeof (onClick) !== "function") {
            return;
        }

        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        onClick(data, e);
    };

    return (
        <React.Fragment>
            <ClickableMultiValueLabel onClick={handleOnClick} className="has-pointer">
                <components.MultiValueLabel data={data} {...rest} />
            </ClickableMultiValueLabel>
        </React.Fragment>
    );
};

export default MultiValueLabel;
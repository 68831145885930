import React from "react";
import ReportManagementDetailed from "../../features/reportmanagementdetailed";
import { ClientReportManagementFilterSubHeader } from "../../layouts/ClientReportManagementLayout";

const ReportManagementDetailedPage = () => (
    <React.Fragment>
        <ClientReportManagementFilterSubHeader />
        <section className="px-4 my-3 h-100">
            <ReportManagementDetailed />
        </section>
    </React.Fragment>
);

export default ReportManagementDetailedPage;
import React from "react";
import FormSelect from "../FormSelect";

export const FeedbackRatings = [
    { label: 'Unrated', value: 0 },
    { label: 'Very Poor', value: 1 },
    { label: 'Poor', value: 2 },
    { label: 'Good', value: 3 },
    { label: 'Very Good', value: 4 },
    { label: 'Excellent', value: 5 }
];

const ReportFeedbackRatingsSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={FeedbackRatings.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default ReportFeedbackRatingsSelect;
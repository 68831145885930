import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { useRemindersGrid } from "../hooks/RemindersGridContext";

const RemindersGridHeader = () => {

    const { columns } = useRemindersGrid();

    return (
        <thead>
            <tr>
                {columns.map((item) => item.renderer ? item.renderer(item) : <ThemedTableHeader>{item.label}</ThemedTableHeader>)}
            </tr>
        </thead>
    )
};

export default RemindersGridHeader;
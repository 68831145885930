import React from 'react';
import { NumericFormat } from "react-number-format";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AssetSummary = ({asset}) => {

    const showPriceDate = (asset) => {
        
        
        if(asset?.assetPrice?.priceDate != null){
            return new Date(
                asset?.assetPrice?.priceDate
            ).toLocaleDateString("en-GB")
        }

        return "-";

    }

    if(asset == null || typeof(asset) == 'undefined'){
        return <span style={{fontWeight: "bold"}}>{asset.assetDisplayName}</span>;
    }
    
    return (
        <OverlayTrigger placement="top" overlay={
            <Tooltip>
                {asset.assetPrice != null && 
                    <div style={{textAlign: "left"}}>
                        <b>Price Date:</b> {showPriceDate(asset)}<br/>
                        <b>Currency:</b> {asset.asset.currency}<br/>
                        <b>Exchange Rate:</b> {asset.exchangeRateEntity?.rate}<br/>
                        <b>Average Cost:</b>  {asset.averageUnitCost.toFixed(2)}<br/>
                        <b>Units:</b> {asset.totalUnits}<br/>
                        <b>Price:</b> {asset.priceUsed.toFixed(2)}<br/>
                        <b>Mex:</b> {asset.asset.mexid}<br/>
                        <b>Citi:</b> {asset.asset.citicode}<br/>
                        <b>Sedol:</b> {asset.asset.sedol}<br/>
                        { asset.mvrDate != null && asset.mvrValue > 0 && 
                        <> <b>MVR:</b> <NumericFormat
                            value={asset?.mvrValue?.toFixed(2)}
                            thousandSeparator={true}
                            displayType="text"
                            decimalScale={0}
                            prefix={"£"}
                        /> at {new Date(asset?.mvrDate).toLocaleDateString("en-GB")}<br/>
                        </>}
                        { asset.exitPenaltyDate != null && asset.exitPenaltyValue > 0 && <> <b>Exit Penalty:</b> <NumericFormat
                            value={asset?.exitPenaltyValue?.toFixed(2)}
                            thousandSeparator={true}
                            displayType="text"
                            decimalScale={0}
                            prefix={"£"}
                        /> at {new Date(asset?.exitPenaltyDate).toLocaleDateString("en-GB")}<br/></>}
                        { asset.bonusDate != null && asset.bonusValue > 0 && <> <b>Bonus:</b> <NumericFormat
                            value={asset?.bonusValue?.toFixed(2)}
                            thousandSeparator={true}
                            displayType="text"
                            decimalScale={0}
                            prefix={"£"}
                        /> at { new Date(asset?.bonusDate).toLocaleDateString("en-GB")}<br/></>}
                    </div>
                }
            </Tooltip> }>
            <span style={{fontWeight: "bold", color: asset.warnings.some(x => x.warning == 2) ? "orange" : "black" }}>{asset.assetDisplayName}</span>
        </OverlayTrigger> );

};

export default AssetSummary;
import { Modal } from "react-bootstrap";
import { useReminderForm } from "../hooks/ReminderFormContext";
import ReminderForm from "./ReminderForm";
import { Button } from "../../../components";
import toast from "react-hot-toast";
import React, { useState } from "react";
import RetryDocumentUploadModal from "./RetryDocumentUploadModal";

const ReminderModal = ({ show, onCreated, onHide, showUserSelect, masterAccountId, projectId }) => {

    const { handleCreate, createResults, resetForm } = useReminderForm();
    const { isLoading } = createResults;
    const [showRetryUpload, setShowRetryUpload] = useState(false);

    const handleClose = () => {
        resetForm();
        setShowRetryUpload(false)
        onHide();
    };

    const onCreate = (e) => {
        e.preventDefault();
        toast.promise(handleCreate(), {
            loading: "Creating Event...",
            error: "Unable to create event!",
            success: (data) => {
                if (onCreated) {
                    onCreated(data);
                }

                if (data.documentUploads.some(x => x.status !== 200)) {
                    return "One or more attachments require further attention."
                }

                resetForm();
                onHide(data);
                return "Event created!";
            }
        });
    };

    React.useEffect(() => {
        if (!createResults?.isSuccess) return;

        if (createResults?.data.documentUploads.some(x => x.status !== 200)) {
            setShowRetryUpload(true);
        }
    }, [createResults]);

    return (
        <React.Fragment>
            <Modal backdrop="static" size="lg" centered show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Event</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ReminderForm
                        showUserSelect={showUserSelect}
                        masterAccountId={masterAccountId}
                        projectId={projectId}
                        hideTitle
                        hideSubmit
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>Cancel</Button>
                    <Button variant="success" disabled={isLoading} onClick={onCreate}>Create</Button>
                </Modal.Footer>
            </Modal>
            <RetryDocumentUploadModal
                show={showRetryUpload}
                onClose={handleClose}
            />
        </React.Fragment>
    );
};

export default ReminderModal;
import React from "react";
import ClientCell from "./ClientCell";
import ClientLabel from "./ClientLabel";
import FormInfoBubbleWrapper, { InfoBubble } from "./FormInfoBubbleWrapper";

// Renders a full row of all clients in the factFind, 
// If a fieldKey is specified, shows an InfoBubble with information text
// If useInfoBubbleWrapper is set, it uses the FormInfoBubbleWrapper for this to render in the same column. 
// If not, an extra column is rendered containing only the InfoBubble
const ClientRow = ({ clientModels, label, renderCell, fieldKey = null, maxWidth = null, cellClassName = "", useInfoBubbleWrapper = false }) => {

    const renderInfoBubble = (client, index) => {
        if (useInfoBubbleWrapper)
            return <ClientCell key={client.clientAccountId} className={cellClassName} index={index} maxWidth={maxWidth} isUsingWrapper>
                <FormInfoBubbleWrapper fieldKey={fieldKey} showBubble={index === 0 && fieldKey}>
                    {renderCell(client, index)}
                </FormInfoBubbleWrapper>
            </ClientCell>
        else
            return <React.Fragment key={client.clientAccountId}>
                <ClientCell className={cellClassName} index={index} maxWidth={maxWidth}>
                    {renderCell(client, index)}
                </ClientCell>
                {index === 0 && <InfoBubble
                    iconClassName={`col-start-3 mt-2 me-auto`}
                    showBubble={index === 0 && fieldKey}
                    fieldKey={fieldKey}
                />}
            </React.Fragment>
    }

    return <>
        {label && <ClientLabel>
            {label}
        </ClientLabel>}
        <React.Fragment>
            {clientModels?.map((client, index) => (fieldKey || useInfoBubbleWrapper)
                ? renderInfoBubble(client, index)
                : <ClientCell className={cellClassName} key={client.clientAccountId} index={index} maxWidth={maxWidth}>
                    {renderCell(client, index)}
                </ClientCell>
            )}
        </React.Fragment>
    </>
}

export default ClientRow;
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import Skeleton from "react-loading-skeleton";
import styled, { useTheme } from "styled-components";
import { IconButton } from "../../../components/buttons";
import { CountrySelect, FormCheck, FormInput, FormSelect } from "../../../components/forms";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { StandardTable } from "../../../components/tables";
import useAddressDetails from "../hooks/useAddressDetails";
import BasicFetchError from "./BasicFetchError";
import DetailsWrapper from "./DetailsWrapper";

const ActiveColumn = styled.td`
    width: 70px;

    && > div.row {
        justify-content: center;
    }

    && > div.row > div {
        margin: 0;
    }
`;

const DefaultColumn = styled.td`
    width: 78px;

    && > div.row {
        justify-content: center;
    }

    && > div.row > div {
        margin: 0;
    }
`;

const AddressDetailsTableEmptyRow = ({ columns, onCreateClicked, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            There are no addresses available for this account.
            You can click <a href="#" onClick={onCreateClicked}>here</a> to create one.
        </td>
    </tr>
};

const AddressDetailsTableLoadingRow = ({ columns, loadingLines, ...rest }) => {
    return [...Array(loadingLines)].map((_, i) => (
        <tr key={`loading-${i}`} {...rest}>
            <td colSpan={columns.length}>
                <Skeleton count={1} height={37} />
            </td>
        </tr>
    ));
};

const AddressDetailsTableRow = ({ allowDelete = true, columns, data, onDefaultChanged, onDeleteClicked, onFieldChanged, ...rest }) => {
    const { address, addressId, countryCode, isActiveAddress, isDefault, postcode, type } = data;
    return <tr {...rest}>
        <td className="col-2">
            <FormSelect
                defaultValue={type}
                options={[
                    { label: 'Main Residence', value: 0 },
                    { label: 'Holiday Home', value: 1 },
                    { label: 'Residential Property', value: 2 },
                    { label: 'Commercial Property', value: 3 }
                ]}
                onChange={(values) => onFieldChanged(addressId, 'type', values.value)}
            />
        </td>
        <td>
            <FormInput
                value={address}
                placeholder="Address"
                onBlur={(value) => onFieldChanged(addressId, 'address', value)}
            />
        </td>
        <td className="col-1">
            <FormInput
                value={postcode}
                placeholder="Postcode"
                onBlur={(value) => onFieldChanged(addressId, 'postcode', value)}
            />
        </td>
        <td className="col-2">
            <CountrySelect
                defaultValue={countryCode}
                onBlur={(values) => onFieldChanged(addressId, 'countryCode', values.value)}
            />
        </td>
        <ActiveColumn className="align-middle text-center">
            <FormCheck
                type="checkbox"
                isChecked={isActiveAddress}
                onChange={() => onFieldChanged(addressId, 'isActiveAddress', !isActiveAddress)}
            />
        </ActiveColumn>
        <DefaultColumn className="align-middle text-center">
            <FormCheck
                type="radio"
                name="isAddressDefaultRadio"
                isChecked={isDefault}
                onChange={() => onDefaultChanged(addressId)}
            />
        </DefaultColumn>
        {allowDelete === true && (
            <td className="col-md-auto">
                <IconButton icon="fa-trash-can" variant="danger" onClick={() => onDeleteClicked(data)} />
            </td>
        )} 
    </tr>
};

const getDeleteWarningMessage = (address, postcode) => {
    if (!address) {
        return "Are you sure you want to delete this address?";
    }
    if (!postcode) {
        return `Are you sure you want to delete the address "${address}"?`;
    }

    return `Are you sure you want to delete the address "${address}, ${postcode}"?`;
};

const AddressDetails = ({ masterAccountId, loadingLines = 2 }) => {
    const { 
        createAddress, 
        data, 
        deleteAddress, 
        error, 
        isCreating,
        isError, 
        isLoading,
        reload,
        setDefaultAddress,
        updateAddress 
    } = useAddressDetails(masterAccountId);
    const theme = useTheme();

    const onCreateAddressPressed = (e) => createAddress(e);

    const onDefaultAddressChanged = (addressId) => setDefaultAddress(addressId);

    const onDeleteAddressPressed = (addressToDelete) => {
        const { addressId, address, postcode } = addressToDelete;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal
                    theme={theme}
                    title="Delete Address"
                    message={getDeleteWarningMessage(address, postcode)}
                    handleConfirm={() => deleteAddress(addressId).finally(onClose)}
                    handleClose={onClose}
                />
            }
        });
    }

    const onFieldChanged = (addressId, property, value) => updateAddress(addressId, property, value);

    if (error || isError === true) {
        return <BasicFetchError error={error} retryCallback={reload} />
    }

    return <DetailsWrapper label="Addresses" collapsedByDefault={true}>
        <StandardTable
            className="table-borderless"
            columns={[
                { label: 'Address Type', property: 'typeDescription' },
                { label: 'Address', property: 'address' },
                { label: 'Postcode', property: 'postcode' },
                { label: 'Country', property: 'countryCode' },
                { 
                    label: 'Active', 
                    property: 'isActiveAddress',
                    headerClassName: 'text-center'
                },
                { 
                    label: 'Default', 
                    property: 'isDefault',
                    headerClassName: 'text-center'
                },
                { 
                    label: '', 
                    property: '',
                    headerRenderer: (props) => (
                        <td key={props.key} style={{ width: '32px' }}>
                            <IconButton icon="fa-plus" variant="success" onClick={onCreateAddressPressed} />
                        </td>
                    )
                }
            ]}
            data={data}
            error={error}
            isCreating={isCreating}
            isLoading={isLoading}
            EmptyRowComponent={AddressDetailsTableEmptyRow}
            emptyRowComponentProps={{ onCreateClicked: onCreateAddressPressed }}
            hideHeaderOnEmpty={true}
            LoadingRowComponent={AddressDetailsTableLoadingRow}
            loadingRowComponentProps={{ loadingLines }}
            RowComponent={AddressDetailsTableRow}
            rowComponentProps={{ 
                allowDelete: true,
                onDefaultChanged: onDefaultAddressChanged,
                onDeleteClicked: onDeleteAddressPressed, 
                onFieldChanged 
            }}
        />
    </DetailsWrapper>
};

export default AddressDetails;

const { wealthProApi } = require("../../redux/api");

const businessSheetEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["bizSheetNewMoney"]
}).injectEndpoints({
    endpoints: (builder) => ({
        createBizSheet: builder.mutation({
            query: ({ data }) => ({
                url: `api/recommendations/businesssheet`,
                method: "POST",
                body: data
            })
        }),
        fetchBizSheetsForAccount: builder.query({
            query: ({ masterAccountId }) => `api/recommendations/BusinessSheet/Account/${masterAccountId}?listType=select`,
        }),
        searchBizSheets: builder.query({
            query: ({
                masterAccountId,
                searchTerm,
                page,
                limit
            }) => `api/recommendations/BusinessSheet/Search/Account/${masterAccountId}?searchTerm=${searchTerm}&page=${page}&limit=${limit}&fetchType=0`,
        }),
        fetchBizSheet: builder.query({
            query: ({ bizSheetId }) => `api/recommendations/BusinessSheet/${bizSheetId}`
        }),
        applyBizSheetCosts: builder.mutation({
            query: ({ bizSheetId }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/ProductCosts/Apply`,
                method: "POST"
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: instructions } = await queryFulfilled;

                    try {
                        var patches = [];
                        // Change every instruction's invest and (where appropriate) divest, to represent the updated product costs
                        instructions.forEach(instruction => {
                            patches.push(dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId: instruction.id }, () => instruction)));

                            patches.push(dispatch(wealthProApi.util.updateQueryData("fetchInvest", { instructionId: instruction.id }, () => instruction.invest)));

                            if (instruction.divestId)
                                patches.push(dispatch(wealthProApi.util.updateQueryData("fetchDivest", { instructionId: instruction.id }, () => instruction.divest)));
                        });
                    } catch (error) {
                        console.error("Error updating cache", error);
                        patches.forEach(patchResult =>
                            patchResult.undo())
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }),
        getPdf: builder.query({
            query: ({ bizSheetId }) => `api/recommendations/BusinessSheet/${bizSheetId}/Pdf`,
            async onQueryStarted({ instructionIds }, { dispatch, queryFulfilled }) {
                try {
                    // Remove existing errors, await validation response, replace them (if any returned)
                    // No need to change status
                    const removeErrorsResults = instructionIds.map(instructionId =>
                        dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId }, (draft) => {
                            draft.errors = null;
                        })));

                    try {
                        const { data: response } = await queryFulfilled;

                        if (response.isValid) {
                            // No errors, no need to update cache
                            return
                        }

                        const responses = response.instructionResponses;
                        if (responses == null || Object.keys(responses)?.length === 0) {
                            return;
                        }

                        Object.keys(responses).forEach(instructionId => {
                            dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId: instructionId.replaceAll(/-/g, "") }, (draft) => {
                                draft.errors = responses[instructionId]?.errors;
                            }))
                        });
                    } catch (serviceError) {
                        console.error("Error fetching PDF preview", serviceError);
                        removeErrorsResults.forEach(patchResult => patchResult.undo());
                    }
                }
                catch (cacheError) {
                    console.error("Error removing existing errors", cacheError);
                }
            }
        }),
        // Note - This status is now "Compliance Locked", pending "Await Compliance" and further report status changes
        finaliseBizSheet: builder.mutation({
            query: ({ bizSheetId }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/Finalise`,
                method: "PATCH"
            }),
            async onQueryStarted({ bizSheetId, instructionIds }, { dispatch, queryFulfilled }) {
                try {
                    // Final draft means we need to remove any existing errors from the instructions, pending validation response
                    // Also need to update the status and make the BizSheet ReadOnly
                    const removeErrorsResults = instructionIds.map(instructionId =>
                        dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId }, (draft) => {
                            draft.errors = null;
                        }))
                    );

                    try {
                        const { data: response } = await queryFulfilled;

                        if (response.isValid) {
                            // Need to update statusDescription and readonly if validation passed
                            dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
                                draft.status = 18;
                                draft.statusDescription = "Compliance Locked";
                                draft.isReadOnly = true;
                            }));
                            return;
                        }

                        const responses = response.instructionResponses;
                        if (responses == null || Object.keys(responses)?.length === 0) {
                            return;
                        }

                        Object.keys(responses).forEach(instructionId => {
                            dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId: instructionId.replaceAll(/-/g, "") }, (draft) => {
                                draft.errors = responses[instructionId]?.errors;
                            }))
                        });
                    } catch (error) {
                        console.error("Error validating business sheet", error);
                        removeErrorsResults.forEach(patchResult => patchResult.undo());
                    }
                } catch (error) {
                    console.error("Error removing existing errors", error);

                }
            }
        }),
        makeBizSheetDraft: builder.mutation({
            query: ({ bizSheetId }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/Draft`,
                method: "PATCH"
            }),
            async onQueryStarted({ bizSheetId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    // Need to update statusDescription and readonly if the request is successful
                    dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
                        draft.status = data.status;
                        draft.statusDescription = data.statusDescription;
                        draft.isReadOnly = false;
                    }));
                } catch (error) {
                    console.error("Error making business sheet draft", error);
                }
            }
        }),
        fetchNewMoney: builder.query({
            query: ({ bizSheetId }) => `api/recommendations/BusinessSheet/${bizSheetId}/NewMoney`,
            providesTags: (_result, _error, { bizSheetId }) => [{
                type: "bizSheetNewMoney",
                id: bizSheetId
            }]
        }),
        saveNewMoney: builder.mutation({
            query: ({ bizSheetId, updatedList }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/NewMoney`,
                method: 'POST',
                body: { updatedList }
            }),
            async onQueryStarted({ bizSheetId, updatedList }, { dispatch, queryFulfilled }) {
                try {
                    // Wait for the request to finish then update the sum of the new money
                    await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
                        draft.newMoneySum = updatedList.reduce((acc, curr) => acc + curr.investmentAmount, 0);
                    }));
                } catch (error) {
                    console.error(error, "Error saving New Money changes")
                }
            },
            invalidatesTags: (_result, _error, { bizSheetId }) => [{ type: "bizSheetNewMoney", id: bizSheetId }]
        }),
        sendToAdmin: builder.mutation({
            query: ({ bizSheetId }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}`,
                method: "POST"
            }),
            async onQueryStarted({ bizSheetId, instructionIds }, { dispatch, queryFulfilled }) {
                try {
                    const removeErrorsResults = instructionIds.map(instructionId =>
                        dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId }, (draft) => {
                            draft.errors = null;
                        }))
                    );
                    const updateSheetStatusResult = dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
                        draft.status = 17;
                        draft.statusDescription = "Sent to Client";
                        draft.isReadOnly = true;
                    }));

                    try {
                        const { data: response } = await queryFulfilled;

                        if (response.isValid)
                            return;

                        const responses = response.instructionResponses;
                        if (responses == null || Object.keys(responses)?.length === 0) {
                            return;
                        }

                        Object.keys(responses).forEach(instructionId => {
                            dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId: instructionId.replaceAll(/-/g, "") }, (draft) => {
                                draft.errors = responses[instructionId]?.errors;
                            }))
                        });
                        updateSheetStatusResult.undo();
                    } catch (error) {
                        console.error("Error validating business sheet", error);

                        removeErrorsResults.forEach(patchResult =>
                            patchResult.undo());
                        updateSheetStatusResult.undo();
                    }

                } catch (error) {
                    console.error("Error removing existing errors", error);
                }
            }
        }),
    })
});

export const {
    useFetchBizSheetsForAccountQuery,
    useFetchBizSheetQuery,
    useLazyFetchBizSheetQuery,
    useCreateBizSheetMutation,
    useLazySearchBizSheetsQuery,
    useApplyBizSheetCostsMutation,
    useLazyGetPdfQuery,
    useFinaliseBizSheetMutation,
    useMakeBizSheetDraftMutation,
    useFetchNewMoneyQuery,
    useSaveNewMoneyMutation,
    useSendToAdminMutation
} = businessSheetEndpoints;
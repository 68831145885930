import React from "react";
import UsersGridTableColumn from "./UsersGridTableColumn";
import UsersGridTableOnlineColumn from "./UsersGridTableOnlineColumn";
import { ProfileIcon } from "../../../components";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";

const UsersGridTableRow = ({ data, index, style }) => {
    const { height } = style;
    
    if (index >= data.length) {
        return <TableLoadingRow style={{ height }} />
    }

    const { 
        id,
        displayName,
        emailAddress,
        type,
        location,
        jobTitle,
        isOnline
    } = data[index];
    return (
        <tr>
            <UsersGridTableColumn width={3}>
                <ProfileIcon user={data[index]} isOnline={isOnline} showOnlineStatusIndicator={false} />
            </UsersGridTableColumn>
            <UsersGridTableColumn width={15}>
                {displayName}
            </UsersGridTableColumn>
            <UsersGridTableColumn width={17}>
                {type}
            </UsersGridTableColumn>
            <UsersGridTableColumn width={20}>
                {emailAddress}
            </UsersGridTableColumn>
            <UsersGridTableColumn width={20}>
                {jobTitle}
            </UsersGridTableColumn>
            <UsersGridTableColumn width={20}>
                {location}
            </UsersGridTableColumn>
            <UsersGridTableColumn width={5}>
                <UsersGridTableOnlineColumn isOnline={isOnline} />
            </UsersGridTableColumn>
        </tr>
    );
};

export default UsersGridTableRow;
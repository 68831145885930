import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const clientAccountsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["clientAccountDetails", "clientAccountDetailsList"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        createClientAccountDetails: builder.mutation({
            query: ({ masterAccountId, ...rest }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/client-accounts`,
                method: 'POST',
                body: { masterAccountId, ...rest }
            }),
            async onQueryStarted({ masterAccountId, data: clientAccount }, { dispatch, queryFulfilled }) {
                try {
                    // wait for the request to finish
                    const { data: createData } = await queryFulfilled;

                    // update the main details
                    dispatch(wealthProApi.util.updateQueryData('fetchClientAccountDetails', { masterAccountId }, (data) => {
                        data.push({
                            ...clientAccount,
                            clientAccountId: createData.clientAccountId
                        });
                    }));

                    // update the list objects
                    dispatch(wealthProApi.util.updateQueryData('fetchClientAccountDetailsList', { masterAccountId }, (data) => {
                        data.push({
                            label: `New Account: ${createData.clientAccountId}`,
                            value: createData.clientAccountId,
                            data: {
                                ...clientAccount,
                                clientAccountId: createData.clientAccountId
                            }
                        });
                    }));
                }
                catch { }
            }
        }),
        deleteClientAccountDetails: builder.mutation({
            query: ({ clientAccountId, masterAccountId }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/client-accounts/${clientAccountId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ clientAccountId, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchClientAccountDetails', { masterAccountId }, (data) => {
                        return data.filter(el => el.clientAccountId !== clientAccountId);
                    }));
                }
                catch { }
            }
        }),
        fetchClientAccountDetails: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/client-accounts`,
            providesTags: (result, error, arg) => {
                return ['clientAccountDetails'];
            }
        }),
        fetchClientAccountDetailsList: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/client-accounts/list`,
            providesTags: (result, error, arg) => {
                return ['clientAccountDetailsList'];
            }
        }),
        patchClientAccount: builder.mutation({
            query: ({ clientAccountId, masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/client-accounts/${clientAccountId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ clientAccountId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result1 = dispatch(wealthProApi.util.updateQueryData('fetchClientAccountDetails', { masterAccountId }, (data) => {
                    var clientAccount = data.find(el => el.clientAccountId === clientAccountId);
                    applyPatch(clientAccount, operations, true);
                }));

                const result2 = dispatch(wealthProApi.util.updateQueryData('fetchClientAccountDetailsList', { masterAccountId }, (data) => {
                    var clientAccount = data.find(el => el.value === clientAccountId);
                    applyPatch(clientAccount.data, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result1.undo();
                    result2.undo();
                }
            }
        })
    })
});

export const {
    useCreateClientAccountDetailsMutation,
    useDeleteClientAccountDetailsMutation,
    useFetchClientAccountDetailsQuery,
    useFetchClientAccountDetailsListQuery,
    usePatchClientAccountMutation
} = clientAccountsApiEndpoints;
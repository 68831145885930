import React from "react";
import ListBox from "./components/ListBox";
import ListBoxLink from "./components/ListBoxLink";

const FooterLinks = () => {
    return <ListBox>
        <ListBoxLink to={"/accessibility"}>Accessibility</ListBoxLink>
        <ListBoxLink to={"/cookies"}>Cookie Policy</ListBoxLink>
        <ListBoxLink to={"/legalinformation"}>Legal</ListBoxLink>
        <ListBoxLink to={"/securityandprivacy"}>Security &amp; Privacy</ListBoxLink>
        <ListBoxLink to={"/termsandconditions"}>Terms &amp; Conditions</ListBoxLink>
        <ListBoxLink href="https://status.wealthprodevelopment.co.uk/status/wealthpro" target="_blank">Status</ListBoxLink>
    </ListBox>
};

export default FooterLinks;
import React from "react";
import FormSelect from "../FormSelect";

export const AccountingReportOptions = [
    { value: 1, label: 'AJB Debtors Ledger Report' },
    { value: 2, label: 'AJB Service Fees Billed' },
    { value: 3, label: 'Debtors Ledger Report' }
]
const AccountingReportSelect = ({ options, ...rest}) => {
    return <FormSelect
        options={AccountingReportOptions.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />;
};

export default AccountingReportSelect;
import { debounce } from "lodash";
import React, { useCallback, useEffect, useId, useState } from "react";
import AsyncSelect from 'react-select/async';
import { ControllerFetchType } from "../../helpers/fetchTypes";
import FormGroup from "./FormGroup";
import FormInputGroup from "./FormInputGroup";
import FormLabel from "./FormLabel";

const styles = {
    container: base => ({
        ...base,
        flex: 1,
    })
};

const NewPagedSelect = ({
    className,
    label,
    defaultValue,
    horizontal,
    id,
    pageQueryFn,
    pageQueryParams,
    searchOptionsFn,
    //! Important: This function takes as its argument the defaultValue prop, 
    //! and should return a Promise that fetches the full object and maps it back into this { value, label } format
    fetchValueFn,
    startingPage = 1,
    limit = 5,
    onChange,
    onBlur,
    active = true,
    searchTimeout = 500,
    ...rest
}) => {
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [page, setPage] = useState(startingPage);

    const [pagedState, setPagedState] = useState([]);
    const [paginationState, setPaginationState] = useState();

    const [currentValue, setCurrentValue] = useState(pagedState?.find(({ value: v }) => v === defaultValue));
    const [originalValue, setOriginalValue] = useState(pagedState?.find(({ value: v }) => v === defaultValue));

    // Load options when current page changes
    useEffect(() => {
        pageQueryFn({ page, limit, fetchType: ControllerFetchType.List, ...pageQueryParams }).then((res) => {
            const { pagination, results } = res || {};

            if (results?.length > 0) {
                setPaginationState(pagination);
                setPagedState(prev => {
                    var newArr = prev.filter(({ value: v }) => !results.some(({ value: r }) => r === v));
                    return [
                        ...newArr,
                        ...results
                    ];
                });
            } else {
                setPage(page => page - 1);
            }
        });
    }, [limit, page, pageQueryFn, pageQueryParams]);

    // Set the current and original value when the default value changes
    // Do this by fetching on the default value then updating the paged state to include it if it doesn't exist
    useEffect(() => {
        if (defaultValue == null) {
            setCurrentValue(null);
            setOriginalValue(null);
        }

        fetchValueFn(defaultValue).then((res) => {
            if (res) {
                setCurrentValue(res);
                setOriginalValue(res);
                setPagedState(prev => {

                    return [
                        res,
                        ...prev.filter(({ value: v }) => v !== res.value)
                    ]
                });
            }
        })
    }, [defaultValue, fetchValueFn]);

    // Load next page when the user scrolls to the bottom of the menu
    const loadNextPage = useCallback(() => {
        if (page < paginationState?.totalPages) {
            setPage(page => page + 1);
        }
    }, [page, paginationState?.totalPages]);

    // Search as user types
    const searchOptions = debounce((searchTerm, callback) => {
        searchOptionsFn({ searchTerm: searchTerm }).then((res) => {
            if (res && res?.length > 0) {
                // setPaginationState(pagination);
                // setPagedState(prev => {
                //     var newArr = prev.filter(({ value: v }) => !results.some(({ value: r }) => r === v));
                //     return [
                //         ...newArr,
                //         ...results
                //     ];
                // });
                callback(res);
            }
        })
    }, searchTimeout);

    const changeHandler = useCallback((e) => {
        setCurrentValue(e);
        if (onChange && typeof onChange === 'function') {
            onChange(e);
        }
    }, [onChange]);

    const blurHandler = useCallback(() => {

        if (currentValue?.value === originalValue?.value) {
            return;
        }

        if (typeof onBlur !== "function")
            return;

        /* var res =  */onBlur(currentValue);

        // TODO Handle errors, loading, success tick, etc.

    }, [currentValue, onBlur, originalValue?.value]);

    return <FormGroup className={className ?? ''} horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup horizontal={horizontal} hasLabel={label ? true : false}>
            <AsyncSelect
                id={componentId}
                styles={styles}
                classNamePrefix="Select"
                menuPosition="fixed"
                isDisabled={!active}
                isLoading={!pagedState}
                isSearchable={true}
                defaultOptions={pagedState}
                loadOptions={searchOptions}
                onMenuScrollToBottom={loadNextPage}
                onChange={changeHandler}
                onBlur={blurHandler}
                onInputChange={(e1, e2, e3) => console.log(e1, e2, e3)}
                value={currentValue}
                {...rest}
            />
        </FormInputGroup>

    </FormGroup>;
};


export default NewPagedSelect;
import { css } from "styled-components";

const InputSpinnerMd = css`
    padding-right: calc(${props => props.theme.loadingSpinnerSizeMd} + 0.75rem);
    background-image: url("/images/loading.gif");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(${props => props.theme.loadingSpinnerSizeMd} + 0.375rem) calc(${props => props.theme.loadingSpinnerSizeMd} + 0.375rem);
`;

export {
    InputSpinnerMd
};
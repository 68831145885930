import { 
    useChangeDesignationMappingsMutation,
    useCreateDesignationDetailsMutation,
    useDeleteDesignationDetailsMutation,
    useFetchMasterAccountDesignationDetailsQuery,
    usePatchDesignationDetailsMutation 
} from "../../../services/clientdetails";

const useDesignationDetails = (masterAccountId) => {
    const { data, error, isError, isFetching, isLoading, refetch } = useFetchMasterAccountDesignationDetailsQuery({ masterAccountId });
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateDesignationDetailsMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteDesignationDetailsMutation();
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchDesignationDetailsMutation();
    const [updateMappings, { error: updateMappingError, isError: isErrorUpdatingMapping, isLoading: isUpdatingMappings }] = useChangeDesignationMappingsMutation();

    const createDesignation = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            let data = {
                masterAccountId,
                type: 1,
                designation: ''
            };

            return create({ masterAccountId, data }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteDesignation = (designationId) => {
        return new Promise((resolve, reject) => {
            return remove({ designationId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ masterAccountId });
    };

    const updateDesignation = (designationId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];
            return patch({ designationId, masterAccountId, operations}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateDesignationMappings = (designationId, newMappings) => {
        return new Promise((resolve, reject) => {
            let clientAccountIdList = newMappings.map(el => el.value);
            return updateMappings({ designationId, masterAccountId, data: clientAccountIdList }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        createDesignation,
        createError,
        data: data || [],
        deleteDesignation,
        deleteError: removeError,
        error,
        isError,
        isErrorCreating,
        isErrorRemoving,
        isErrorUpdating,
        isCreating,
        isLoading: isLoading === true || isFetching === true,
        isRemoving,
        isUpdating,
        reload,
        removeError,
        updateDesignation,
        updateDesignationMappings,
        updateError,
    };
};

export default useDesignationDetails;
import { wealthProApi } from "../../redux/api";

const icStandardPortfolioApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (build) => ({
        fetchStandardPortfolios: build.query({
            query: ({ fetchType = "object" }) => `api/assets/standardportfolio?fetchType=${fetchType}`,
        })
    })
});

export const {
    useFetchStandardPortfoliosQuery
} = icStandardPortfolioApiEndpoints;
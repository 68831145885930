import { useState } from "react";
import { Spinner } from "../../components/loaders";
import { AccordionList } from "../../components/utilities";
import { AllocationRules, ClassGroupTable, ClassificationRangeTable } from "./components";
import useGeneralRules from "./hooks/useGeneralRules";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";

const GeneralRules = () => {
    const {
        isLoading,
        isFetching,
        isError,
        refetch
    } = useGeneralRules();

    const [openAccordions, setOpenAccordions] = useState(['max-allocations']);

    if (isLoading || isFetching)
        return <div className="container">
            <Spinner />
        </div>;

    if (isError)
        return <ErrorMessageWrapper>
            <ErrorMessage 
                retryCallback={refetch}
            />
        </ErrorMessageWrapper>
    
    return <>
        <AllocationRules />

        <AccordionList
            className="mt-3"
            activeKeys={openAccordions}
            onSelect={val => setOpenAccordions(val)}
            gap={1}
            alwaysOpen
        >
            <ClassificationRangeTable />

            <ClassGroupTable />
        </AccordionList>
    </>;
}

export default GeneralRules;
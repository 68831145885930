import React from "react";
import moment from "moment";
import ReviewManagementDetailTableRowColumn from "./ReviewManagementDetailTableRowColumn";
import { ThemedIcon } from "../../../components/utilities";

// TODO: this is a direct copy from the old AG Grid renders
// I believe it needs tweaking..?

const ReviewManagementDetailTableOnHoldIconColumn = ({ status, delayed, statusDate, size = "lg" }) => {
    if (status != 3) {
        return (
            <ReviewManagementDetailTableRowColumn className="text-center">
                {!delayed && <span>-</span>}
                {delayed && <ThemedIcon variant="success" icon="fa-circle" size={size} />}
            </ReviewManagementDetailTableRowColumn>
        );
    }

    const currentDate = moment();
    const statusChangedDate = moment("2023-02-25");
    
    return (
        <ReviewManagementDetailTableRowColumn className="text-center">
            {(currentDate.diff(statusChangedDate, 'days') <= 30) && <ThemedIcon variant="warning" icon="fa-circle-xmark" size={size} />}
            {(currentDate.diff(statusChangedDate, 'days') > 30) && <ThemedIcon variant="danger" icon="fa-circle-xmark" size={size} />}
        </ReviewManagementDetailTableRowColumn>
    );
};

export default ReviewManagementDetailTableOnHoldIconColumn;
/**
 * Used in react-select FormSelect components to simulate 'tabbing through' inputs without selecting an option
 * @param {KeyboardEvent} e 
 */
const onTabPressSelect = (e) => {
    const tabElements = Array.from(document
        // Get all elements that can be focusable
        .querySelectorAll('input, textarea, select, button, a, [tabindex]'))
        // Remove any that have a tabIndex of -1 or are invisible/disabled
        .filter(element => element.tabIndex > -1 && element.offsetParent !== null && !element.disabled)
        // Split the elements into two arrays, explicit (> 0) and implicit (0) tabIndexes
        // This allows for sorting on tabIndex while maintaining the order of elements with implicit tabIndex
        .reduce((prev, next) => next.tabIndex > 0
            ? [[...prev[0], next].sort((a, b) => a.tabIndex > b.tabIndex ? -1 : 1), prev[1]]
            : [prev[0], [...prev[1], next]], [[], []])
        // Flatten the 2D array
        .flat();

    if (e.preventDefault && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    // find the current index in the tab list of the currently focused element
    const currentIndex = tabElements.findIndex(e => e === document.activeElement);

    // get the next element in the list ("%" will loop the index around to 0)
    const nextIndex = (currentIndex + 1) % tabElements.length;

    tabElements[nextIndex].focus();
}

export { onTabPressSelect };


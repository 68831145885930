import React from "react";
import MissingAccountsModal from "./components/MissingAccountsModal";
import CustodianTransactionMatchingProvider from "./hooks/CustodianTransactionMatchingContext";
import { Card, Main, ThemedSpan } from "../../components/utilities";
import TransactionExceptions from "./components/TransactionExceptions";
import TransactionPotentialMatchesGrid from "./components/TransactionPotentialMatchesGrid";
import styled from "styled-components";

const SectionWrapper = styled(Card)`
    min-height: 50% !important;
    max-height: 50%;
`;

const CustodianTransactionMatching = () => {



    return (
        <CustodianTransactionMatchingProvider>
            <Main className="d-flex flex-column p-2">
                {/* <SectionWrapper label="Exceptions" cardClassName="mb-2" className="d-flex flex-column justify-content-between"> */}
                <TransactionExceptions />
                {/* </SectionWrapper> */}
                {/* <SectionWrapper label="Potential Matches"> */}
                <TransactionPotentialMatchesGrid />
                {/* </SectionWrapper> */}
            </Main>
            <MissingAccountsModal />
        </CustodianTransactionMatchingProvider>
    );
};

export default CustodianTransactionMatching;
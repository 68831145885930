import { 
    useCreateContactDetailsMutation,
    useDeleteContactDetailsMutation,
    useFetchMasterAccountContactDetailsQuery,
    usePatchContactDetailsMutation
} from "../../../services/clientdetails";

const useContactDetails = (masterAccountId) => {
    const { data, error, isError, isFetching, isLoading, refetch } = useFetchMasterAccountContactDetailsQuery({ masterAccountId });
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateContactDetailsMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteContactDetailsMutation();
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchContactDetailsMutation();


    const createContactDetails = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            // setup this contact details as the default when there is no other default set
            let isDefault = false;
            if (!isLoading && !isError && data) {
                let defaults = data.filter(el => el.isDefault === true);
                isDefault = defaults.length === 0;
            }

            let newDetails = {
                masterAccountId,
                type: 1,
                details: '',
                description: '',
                isDefault: isDefault,
                isActiveDetails: true
            };

            return create({ masterAccountId, data: newDetails }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteContactDetails = (contactDetailId) => {
        return new Promise((resolve, reject) => {
            return remove({ contactDetailId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ masterAccountId });
    };

    const setDefaultContactDetails = (contactDetailId) => {
        return new Promise((resolve, reject) => {
            // generate all the options we want to push up to the api
            let requests = [];
            data.forEach(el => {
                if (el.contactDetailId === contactDetailId && el.isDefault === false) {
                    requests.push({ 
                        contactDetailId: el.contactDetailId,
                        operations: [
                            { op: 'replace', path: `/isDefault`, value: true }
                        ]
                    });
                }

                if (el.contactDetailId !== contactDetailId && el.isDefault === true) {
                    requests.push({ 
                        contactDetailId: el.contactDetailId,
                        operations: [
                            { op: 'replace', path: `/isDefault`, value: false }
                        ]
                    });
                }
            });

            return patch({ masterAccountId, operations: requests }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateContactDetails = (contactDetailId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            let request = [{
                contactDetailId,
                operations
            }];

            return patch({ masterAccountId, operations: request }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        createContactDetails,
        createError,
        data: data || [],
        deleteContactDetails,
        deleteError: removeError,
        error,
        isError,
        isErrorCreating,
        isErrorRemoving,
        isErrorUpdating,
        isCreating,
        isLoading: isLoading === true || isFetching === true,
        isRemoving,
        isUpdating,
        reload,
        removeError,
        setDefaultContactDetails,
        updateError,
        updateContactDetails
    };
};

export default useContactDetails;
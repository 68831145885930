import { wealthProApi } from '../../redux/api';

const reportFeedbackAspectEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchReportFeedbackAspects: builder.query({
            query: ({ fetchType = "Object" }) => `/api/reports/feedbackaspects?fetchType=${fetchType}`
        })
    })
});

export const {
    useFetchReportFeedbackAspectsQuery
} = reportFeedbackAspectEndpoints;
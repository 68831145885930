import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazySearchBizSheetsQuery } from "../../../services/recommendations";

const BusinessSheetSearchContext = React.createContext();

export const useBusinessSheetSearch = () => {
    return useContext(BusinessSheetSearchContext);
};

const pageLimit = 10;

export const BusinessSheetSearchProvider = ({
    value,
    onSelect: selectFn,
    placeholder = "Search Business Sheets...",
    formError,
    showCheckbox = false,
    isChecked,
    setIsChecked,
    showDropdown,
    setShowDropdown,
    children
}) => {
    const { masterAccountId } = useParams();

    const columns = [{
        header: "Business Sheet",
        value: "reportName",
        className: "col my-auto",
    },
    {
        header: "Status",
        value: "status",
        className: "col-3 my-auto text-truncate"
    },
    {
        header: "Date",
        value: "createdDate",
        className: "col-2 my-auto text-truncate"
    },
    {
        header: "Report Status",
        value: "reportStatus",
        className: "col-3 my-auto text-truncate"
    }];

    const minimumCharacters = 3;

    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentValue, setCurrentValue] = useState(value);
    const [trigger, { data, error, isError, isFetching, isLoading, isUninitialized }] = useLazySearchBizSheetsQuery();
    const { results: bizSheets, pagination } = data || {};

    const onLoadMore = () => {
        if (page === pagination?.totalPages) return;
        setPage(prev => prev + 1);
    }

    // NOTE: Will show the latest pageLimit number of business sheets until the search is triggered.
    const fetch = useCallback(() => trigger({
        masterAccountId,
        searchTerm,
        page,
        limit: pageLimit,
    }).unwrap(), [masterAccountId, page, searchTerm, trigger])

    useEffect(() => {
        const searchTimeout = setTimeout(fetch, 250);

        return () => clearTimeout(searchTimeout);
    }, [fetch]);

    const onSelect = (sheet) => {
        setShowDropdown(false);
        setCurrentValue(sheet.reportName);
        selectFn(sheet);
    }

    // Clear the currently selected value from searchTerm when dropdown opens
    // This prevents the search from hiding sheets that don't match the current value
    useEffect(() => {
        if (currentValue == null)
            return;

        setSearchTerm(showDropdown ? "" : currentValue);
    }, [currentValue, showDropdown]);

    useEffect(() => {
        setCurrentValue(old => old === value ? old : value);
    }, [value])

    return <BusinessSheetSearchContext.Provider value={{
        showCheckbox,
        isChecked,
        setIsChecked,
        charactersRemaining: minimumCharacters - searchTerm.length,
        columns,
        bizSheets,
        error,
        formError,
        isError,
        isFetching,
        isLoading,
        isUninitialized,
        loadMore: onLoadMore,
        minimumCharacters,
        page,
        pageLimit,
        pagination,
        searchTerm,
        setSearchTerm,
        placeholder,
        onSelect
    }}>
        {children}
    </BusinessSheetSearchContext.Provider>
};
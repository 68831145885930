import { Spinner } from "../../components/loaders";
import { EditableTable } from "../../components/tables";
import useWrapperRules from "./hooks/useWrapperRules";

const WrapperRulesTable = () => {
    const {
        wrapperRules,
        isLoading,
        isFetching,
        createWrapperRule,
        isCreatingWrapperRule,
        patchWrapperRule,
        columns,
    } = useWrapperRules();

    if (isLoading || isFetching)
        return <Spinner />

    return <EditableTable
        data={wrapperRules}
        columns={columns}
        idProperty={"ruleId"}
        createFn={createWrapperRule}
        isCreating={isCreatingWrapperRule}
        patchRowFn={patchWrapperRule}
        typeDescription={"Wrapper Rules"}
    />
}

export default WrapperRulesTable;
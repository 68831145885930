import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Button from "../../../components/buttons";
import { FormLabel, FormSelect } from "../../../components/forms";
import NewPagedSelect from "../../../components/forms/NewPagedSelect";
import { patchReplace } from "../../../helpers/patchDoc";
import useFundDetails from "../hooks/useFundDetails";
import FundSedolSearch from "./FundSedolSearch";

const AssetDetails = ({ setAssetNameDisplayText, setMonitoringDisplayText }) => {
    const [{ patchAssetGroup, patchAssetGroupSingle, fetchSectors, searchSectors, fetchSector, makeAssetPassive }, { assetGroup, assetGroupIsNotReady }, { canEdit, options }] = useFundDetails();

    const [realTimeMonitoring, setRealTimeMonitoring] = useState(assetGroup?.monitoring);
    const [showMonitoringWarning, setShowMonitoringWarning] = useState(false);
    const shouldMakeAssetPassive = useRef(null);

    useEffect(() => {
        setRealTimeMonitoring(assetGroup?.monitoring);
    }, [assetGroup?.monitoring])

    return <>
        <Modal
            show={showMonitoringWarning}
            size="md"
            backdrop="static"
            onHide={() => setShowMonitoringWarning(false)}
            centered
        >
            <Modal.Header><Modal.Title>Are You Sure?</Modal.Title></Modal.Header>
            <Modal.Body>
                You are about to change the Monitoring of this asset to 'Passive', which will remove it from all Core Funds management.
                <br />
                <strong>This action cannot be undone.</strong>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => {
                    shouldMakeAssetPassive.current = false;
                    setRealTimeMonitoring(assetGroup?.monitoring);
                    setMonitoringDisplayText(options.monitoring.find(opt => opt.value === assetGroup?.monitoring)?.label);
                    setShowMonitoringWarning(false);
                }}>Cancel</Button>
                <Button variant="success" onClick={() => {
                    shouldMakeAssetPassive.current = true;
                    setShowMonitoringWarning(false);
                }}>I'm Sure!</Button>
            </Modal.Footer>
        </Modal>

        <div className="row">
            {/* Sedol Search is kept in its own component for readability */}
            <FundSedolSearch setAssetNameDisplayText={setAssetNameDisplayText} disabled={!canEdit} />

            <div className="col-6 col-md-4 col-xl-3 flex-column mt-2">
                <FormLabel>
                    Sector/Benchmark
                </FormLabel>
                {assetGroupIsNotReady
                    ? <Skeleton />
                    : <NewPagedSelect
                        defaultValue={assetGroup?.sectorCodeOverride}
                        pageQueryFn={fetchSectors}
                        limit={10}
                        searchOptionsFn={searchSectors}
                        fetchValueFn={fetchSector}
                        onBlur={(selection) => patchAssetGroup([
                            patchReplace("sectorCodeOverride", selection.value),
                            patchReplace("sectorCodeIsOverridden", assetGroup?.assetSectorCode !== selection.value)
                        ])}
                        isDisabled={!canEdit}
                    />}
            </div>
            <div className="col-6 col-md-5 col-lg-4 col-xl-3 flex-column mt-2">
                <FormLabel>
                    Monitoring
                </FormLabel>
                {assetGroupIsNotReady
                    ? <Skeleton />
                    : <FormSelect
                        options={options.monitoring}
                        defaultValue={realTimeMonitoring}
                        onChange={(selection) => {
                            setMonitoringDisplayText(selection.label);
                        }}
                        onBlur={(selection) => {
                            return new Promise((resolve, reject) => {
                                if (selection.value !== 2)
                                    return patchAssetGroupSingle("monitoring", selection.value).then(resolve, reject);

                                setShowMonitoringWarning(true);
                                setRealTimeMonitoring(selection.value)

                                const modalCloseInterval = setInterval(() => {
                                    if (shouldMakeAssetPassive.current != null) {
                                        clearInterval(modalCloseInterval);

                                        if (shouldMakeAssetPassive.current === true)
                                            return makeAssetPassive().then(resolve, reject);
                                        else
                                            return reject();
                                    }
                                }, 100);
                            }).finally(() => {
                                shouldMakeAssetPassive.current = null;
                            });
                        }}
                        isDisabled={!canEdit}
                    />}
            </div>

            <div className="col-4 col-md-3 col-xl-2 flex-column mt-2">
                <FormLabel>
                    Management
                </FormLabel>
                {assetGroupIsNotReady
                    ? <Skeleton />
                    : <FormSelect
                        options={options.management}
                        defaultValue={assetGroup?.management}
                        onBlur={(selection) => patchAssetGroupSingle("management", selection.value)}
                        isDisabled={!canEdit}
                    />}
            </div>
            <div className="col-8 col-md-5 col-xl-4 flex-column mt-2">
                <FormLabel>
                    Asset Class
                </FormLabel>
                {assetGroupIsNotReady
                    ? <Skeleton />
                    : <FormSelect
                        options={options.assetClass}
                        defaultValue={assetGroup?.assetClass}
                        onBlur={(selection) => patchAssetGroupSingle("assetClass", selection.value)}
                        isDisabled={!canEdit}
                    />}
            </div>
            <div className="col-6 col-md-4 flex-column mt-2">
                <FormLabel>
                    Region
                </FormLabel>
                {assetGroupIsNotReady
                    ? <Skeleton />
                    : <FormSelect
                        options={options.region}
                        defaultValue={assetGroup?.region}
                        onBlur={(selection) => patchAssetGroupSingle("region", selection.value)}
                        isDisabled={!canEdit}
                    />}
            </div>
            <div className="col-3 col-xl-2 flex-column mt-2">
                <FormLabel>
                    Style
                </FormLabel>
                {assetGroupIsNotReady
                    ? <Skeleton />
                    : <FormSelect
                        options={options.style}
                        defaultValue={assetGroup?.style}
                        onBlur={(selection) => patchAssetGroupSingle("style", selection.value)}
                        isDisabled={!canEdit}
                    />}
            </div>
        </div>
    </>;
}

export default AssetDetails;
import { wealthProApi } from "../../redux/api";

const productEndpoints = wealthProApi
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchAllProducts: builder.query({
        query: ({ listType }) => `api/recommendations/Product${listType ? `?listType=${listType}` : ""}`
      }),
      fetchProductProductCosts: builder.query({
        query: ({ productId, listType }) => `api/recommendations/Product/${productId}/ProductCosts/${listType ? `?listType=${listType}` : ""}`
      })
    })
  });

export const {
  useLazyFetchAllProductsQuery,
  useFetchAllProductsQuery,
  useLazyFetchProductProductCostsQuery
} = productEndpoints
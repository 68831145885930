import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const icWrapperRulesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (build) => ({
        fetchWrapperRules: build.query({
            query: ({ clientProfileId }) => `api/assets/clientprofile/${clientProfileId}/wrapperspecific`
        }),
        createWrapperRule: build.mutation({
            query: ({ clientProfileId }) => ({
                url: `api/assets/clientprofile/${clientProfileId}/wrapperspecific`,
                method: "POST"
            }),
            async onQueryStarted({ clientProfileId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newId, ...rest } = await queryFulfilled;

                    console.log(newId, rest);

                    dispatch(wealthProApi.util.updateQueryData("fetchWrapperRules", { clientProfileId }, (draft) => {
                        draft.push({ ruleId: newId, weighting: 1 });
                    }));
                } catch (error) {
                    console.error("Error creating wrapper specific rule", error);
                }
            }
        }),
        patchWrapperRule: build.mutation({
            query: ({ ruleId, operations }) => ({
                url: `api/assets/wrapperspecificrule/${ruleId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ clientProfileId, ruleId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const updateRuleResult = dispatch(wealthProApi.util.updateQueryData("fetchWrapperRules", { clientProfileId }, (draft) => {
                        applyPatch(draft.find((el) => el.ruleId === ruleId), operations);
                    }));
                    
                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error patching wrapper specific rule", error);
                        updateRuleResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating query data", error);
                    throw error;
                }
            }
        }),
        deleteWrapperRule: build.mutation({
            query: ({ ruleId }) => ({
                url: `api/assets/wrapperspecificrule/${ruleId}`,
                method: "DELETE"
            }),
            async onQueryStarted({ clientProfileId, ruleId }, { dispatch, queryFulfilled }) {
                try {
                    const updateRuleResult = dispatch(wealthProApi.util.updateQueryData("fetchWrapperRules", { clientProfileId }, (draft) => {
                        draft.splice(draft.findIndex((el) => el.ruleId === ruleId), 1);
                    }));

                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error deleting wrapper specific rule", error);
                        updateRuleResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating query data", error);
                    throw error;
                }
            }
        })
    })
});

export const {
    useLazyFetchWrapperRulesQuery,
    useCreateWrapperRuleMutation,
    usePatchWrapperRuleMutation,
    useDeleteWrapperRuleMutation
} = icWrapperRulesApiEndpoints;
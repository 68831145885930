import Skeleton from "react-loading-skeleton";
import { FixedCell } from "../../../components/tables/StandardTable";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { niceBytes } from "../../../helpers/fileHelpers";
import { useApplicationDocuments } from "../hooks/ApplicationDocumentsContext";
import moment from "moment";
import Moment from "react-moment";
import useOpenHeadOfficeDocument from "../../../hooks/useOpenHeadOfficeDocument";
import ApplicationDocumentsGridRowActionCol from "./ApplicationDocumentsGridRowActionCol";

const TableRowBaseCell = styled(FixedCell)`
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
`;

const DocumentTypeTableColumn = ({ documentType, icon }) => {

    const calculateIcon = () => {
        if (icon) {
            return icon;
        };

        switch (documentType) {
            case 0: // Word
                return "fa-file-word";
            case 1: // Excel
                return "fa-file-excel";
            case 2: // Outlook
            case 3: // Email
                return "fa-file-code";
            case 4: // PDF
                return "fa-file-pdf";
            case 5: // Text
                return "fa-file-lines";
            case 6: // Image
                return "fa-file-image";
            case 7: // Video
                return "fa-file-video";
            default:
                return "fa-file";
        };
    };

    return (
        <TableRowBaseCell className="align-middle text-center action-col">
            <div>
                <FontAwesomeIcon
                    size="xl"
                    icon={calculateIcon()}
                    className={classNames({
                        'text-word': documentType === 0,
                        'text-excel': documentType === 1,
                        'text-code': documentType === 2 || documentType === 3,
                        'text-pdf': documentType === 4,
                        'text-lines': documentType === 5,
                        'text-image': documentType === 6,
                        'text-video': documentType === 7,
                        'text-file': typeof (documentType) !== 'number',
                    })}
                />
            </div>
        </TableRowBaseCell>
    );
};

const ApplicationDocumentsGridRow = ({ data, index, style: { height, width } }) => {

    const { isClearing } = useApplicationDocuments();
    const { openDocument } = useOpenHeadOfficeDocument();

    if (!data[index] || isClearing) {
        return <tr style={{ height, width }}>
            <td colSpan={6}>
                <Skeleton height={height - 16} />
            </td>
        </tr>;
    }

    const {
        documentId,
        documentType,
        fileSize,
        description,
        fileName,
        userName,
        uploadedDate
    } = data[index];

    return (
        <tr style={{ height, width }}>
            <DocumentTypeTableColumn width={2.5} documentType={documentType} />
            <FixedCell width={15} className="text-truncate">{fileName}</FixedCell>
            <FixedCell width={7.5} className="text-truncate">{niceBytes(fileSize)}</FixedCell>
            <FixedCell width={15} className="text-truncate">{userName}</FixedCell>
            <FixedCell width={48.5} className="text-truncate">{description}</FixedCell>
            <FixedCell width={10} className="text-truncate">
                {moment(uploadedDate).isValid()
                    ? <Moment format="DD/MM/YYYY" date={uploadedDate} />
                    : <span className="px-2">-</span>}
            </FixedCell>
            <FixedCell width={1.5}>
                <ApplicationDocumentsGridRowActionCol
                    documentId={documentId}
                    openDocument={() => openDocument(documentId, fileName)}
                />
            </FixedCell>
        </tr>
    )
};

export default ApplicationDocumentsGridRow;
import React from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import DateDisplay from "../../DateDisplay";
import ChatBubbleRecipientsTooltip from "./ChatBubbleRecipientsTooltip";
import { toNString } from "../../../helpers/guids";
import { useAuth } from "react-oidc-context";
import { IconButton } from "../../buttons";

const ChatBubbleInner = React.forwardRef(({ 
    data, 
    isLoading, 
    style, 
    allowReply = false,
    onReplyClick = () => {}
}, ref) => {
    const auth = useAuth();
    const [hidden, setHidden] = React.useState(true);
    const { user: { profile: { sub } } } = auth;
    const { height, ...rest } = style;

    const onHoverSet = (v) => allowReply && setHidden(v);
    
    if (isLoading) {
        return (
            <div ref={ref} style={{ minHeight: height, ...rest }} className="chat chat-loading">
                <div className="chat-bubble">
                    <div className="chat-bubble-content rounded-lg chat-bubble-loading">
                        <Skeleton />
                    </div>
                </div>
            </div>
        );
    }

    const { senderId, senderName, sentOnDate, message, recipients } = data;
    const userId = toNString(sub);
    const isSender = senderId === userId;
    return (
        <div ref={ref} style={{ minHeight: height, ...rest }} className={classNames(
            'chat', {
                'chat-start': !isSender,
                'chat-end': isSender
            }
        )} onMouseEnter={() => onHoverSet(false)} onMouseLeave={() => onHoverSet(true)}>
            <div className="chat-header px-1">
                <ChatBubbleRecipientsTooltip 
                    sender={{ id: senderId, name: senderName ?? "the System", sentDate: sentOnDate }}
                    recipients={recipients}
                />
                <span className="chat-header-sender-name">{senderName ?? "System"}</span> 
                <span className="chat-header-sent-date"><DateDisplay>{sentOnDate}</DateDisplay></span>
            </div>
            <div className={classNames(
                'chat-bubble', {
                    'hover': !hidden,
                    'flex-row': isSender && !hidden,
                    'flex-row-reverse': !isSender && !hidden
                }
            )}>
                <div className={classNames('chat-options', {
                    'd-flex align-items-center': !hidden
                })}>
                    <IconButton 
                        icon="fa-reply" 
                        variant="primary"
                        className="rounded"
                        onClick={() => allowReply && onReplyClick(data)}
                    />
                </div>
                <div className={classNames(
                    'chat-bubble-content rounded-lg', {
                        'chat-bubble-receiver': !isSender,
                        'chat-bubble-sender': isSender
                    }
                )}>
                    <p className="m-0">
                        {message}
                    </p>
                </div>
            </div>
        </div>
    );
});

const ChatBubble = ({ 
    data = [], 
    index, 
    style, 
    allowReply = false,
    onReplyClick = () => {}, 
    setRowHeight = () => {} 
}) => {
    const bubbleRef = React.useRef();
    const isLoading = index >= data.length;
    
    React.useEffect(() => {
        if (bubbleRef.current) {
            setRowHeight(index, bubbleRef.current.clientHeight);
        }
    }, [ bubbleRef, data ]);

    return (
        <ChatBubbleInner 
            ref={bubbleRef} 
            data={isLoading ? null : data[index]} 
            isLoading={isLoading}
            style={style}
            allowReply={allowReply}
            onReplyClick={onReplyClick}
        />
    );
};

export default ChatBubble;
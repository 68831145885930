import React from "react";
import Button from "./index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = React.forwardRef(({ buttonSize = "md", icon, size = "sm", iconClassName = "", ...rest }, ref) => {
    return <Button ref={ref} size={buttonSize} {...rest}>
        <FontAwesomeIcon className={iconClassName} icon={icon} size={size} />
    </Button>
});

export default IconButton;
import React, { useEffect, useRef } from "react";
import { Spinner } from "../../../components/loaders";
import { AccordionList, CenterPage } from "../../../components/utilities";
import { useBizSheet } from "../contexts/BizSheetContext";
import { InstructionProvider } from "../contexts/InstructionContext";
import { BizSheetErrorMessage } from "./ErrorMessages";
import InstructionWrapper from "./InstructionWrapper";

export const Instructions = () => {
    const [{ refetchSheet }, { sheet, sheetIsLoading, sheetIsError }, { openInstructions, setOpenInstructions, shouldRenderInstruction }] = useBizSheet();

    const oldLength = useRef(sheet?.instructions?.length ?? 0);
    const bottomOfListRef = useRef(null);

    const scrollToBottom = () => {
        bottomOfListRef.current?.scrollIntoView(true);
    }

    useEffect(() => {
        if (sheet?.instructions?.length - 1 === oldLength.current) {
            scrollToBottom();
        }
        oldLength.current = sheet?.instructions?.length ?? 0;
    }, [sheet?.instructions?.length]);

    if (sheetIsLoading)
        return <CenterPage>
            <Spinner />
        </CenterPage>

    if (sheetIsError)
        return <BizSheetErrorMessage retry={refetchSheet} />

    return <>

        <AccordionList
            className="d-grid"
            activeKey={openInstructions}
            defaultActiveKey={null}
            onSelect={(eventKeys, _) => setOpenInstructions(eventKeys)}
            gap={1}
            alwaysOpen>
            {sheet?.instructions.map((instruction, key) =>
                <React.Fragment key={instruction?.id ?? key}>
                    <InstructionProvider instructionId={instruction.id} >
                        <InstructionWrapper
                            className="mb-2"
                            shouldRenderInstruction={shouldRenderInstruction(instruction?.id)}
                            forceClose={() => setOpenInstructions(old => old?.filter(x => x !== instruction?.id) ?? [])}
                            readOnly={sheet?.isReadOnly} />
                    </InstructionProvider>
                </React.Fragment>)}
            <span ref={bottomOfListRef} />
        </AccordionList>
    </>
}

export default Instructions;
import { usePatchNotesMutation } from "../../../services/clientfactfind";

const useNotes = () => {

    const [patch] = usePatchNotesMutation();

    const updateNotes = (notesId, newText) => {
        return new Promise((resolve, reject) => {
            return patch({ notesId, newText })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        updateNotes
    };
};

export default useNotes;
import React, { useState } from "react";
import withProviders from "../../hooks/withProviders";
import PersonalMessages from "../../features/personalmessages";
import MessageManagementProvider from "../../hooks/MessageManagementContext";
import { ThemedIcon, Main, PageSubHeader, FiltersAppliedWarningMessage } from "../../components/utilities";
import PageHeader, { PageHeaderTop } from "../../components/layout/PageHeader";
import { PageHeaderTitle } from "../../components/utilities/PageHeader";
import { Button } from "../../components";
import { PersonalMessagesFilterModal } from "../../features/personalmessages";
import { useMessageManagementContext } from "../../hooks/MessageManagementContext";
import { MessageThreadModal } from "../../features/messagethread";
import CreatePersonalMessageModal, { CreatePersonalMessageButton } from "../../features/createpersonalmessagemodal";
import { Spinner } from "../../components/loaders";


const PersonalMessagesPage = () => {
    const { userId, selectedThreadId, closeThreadModal, isFiltersApplied, clearFilter, isClearing } = useMessageManagementContext();
    const [isFiltersShown, setIsFiltersShown] = useState(false);
    const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);

    return <React.Fragment>
        <PersonalMessagesFilterModal
            handleClose={() => setIsFiltersShown(false)}
            show={isFiltersShown}
        />
        <CreatePersonalMessageModal
            show={showCreateMessageModal}
            onHide={() => setShowCreateMessageModal(false)}
        />
        <MessageThreadModal
            handleClose={closeThreadModal}
            show={selectedThreadId !== null}
            threadId={selectedThreadId}
            userId={userId}
        />
        <PageHeader>
            <PageHeaderTop>
                <PageHeaderTitle>
                    <span>Messages, Tasks and Notifications</span>
                </PageHeaderTitle>
            </PageHeaderTop>
        </PageHeader>
        <Main className="d-flex flex-column">
            <PageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                </FiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex align-items-center flex-fill flex-row-reverse">
                        <Button onClick={() => setIsFiltersShown(true)}>
                            Filter Messages
                        </Button>
                        {/* <UserDelegatesSelect
                            className="me-4 w-100"
                            defaultValue={userId}
                            onChange={({ value }) => setUserId(value)}
                            accessType={DelegatedAccessTypes.readMessages}
                        /> */}
                        <CreatePersonalMessageButton className={"me-4"} onClick={() => setShowCreateMessageModal(true)} />
                        {isClearing && <Spinner className={"me-4"} />}
                    </div>
                </div>
            </PageSubHeader>
            <section className="px-4 my-3 h-100">
                <PersonalMessages />
            </section>
        </Main>
    </React.Fragment>
};

export default withProviders(MessageManagementProvider)(PersonalMessagesPage);


// import React from "react";
// import PersonalMessages from "../../features/personalmessages";
// import { PersonalMessagesLayout } from "../../layouts";

// const PersonalMessagesPage = () => (
//     <PersonalMessagesLayout>
//         <PersonalMessages />
//     </PersonalMessagesLayout>
// );

// export default PersonalMessagesPage;
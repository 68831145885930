import { wealthProApi } from "../../redux/api";

const messageTypesApiEndpoints = wealthProApi.injectEndpoints({
        endpoints: (builder) => ({
            fetchMessageTypes: builder.query({
                query: () => ({
                    url: `api/messaging/messagetypes`
                })
            })
        })
    });

export const {
    useFetchMessageTypesQuery
} = messageTypesApiEndpoints;
import React from "react";
import toast from "react-hot-toast";
import useCreateAccessForm from "../hooks/useCreateAccessForm";
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { FormSelect } from "../../../components/forms";
import { GenericUserSelect } from "../../../components/forms/selects";
import { useForm, Controller } from "react-hook-form";

const CreateAccessModal = ({ 
    show,
    masterAccountId,
    handleClose = () => {},
    handleOnCreated = () => {}
}) => {
    const { create, isLoading, defaultValues } = useCreateAccessForm(masterAccountId);
    const { control, handleSubmit, reset, setValue } = useForm({ defaultValues });

    const onSubmit = (data) => {
        toast.promise(create(data), {
            loading: 'Creating new Access Record...',
            success: (data) => {
                console.log('done?', data);
                handleOnCreated(data);
            },
            error: (err) => <div>{err?.data?.message ?? err?.message ?? "An unknown error has occured."}</div>
        });
    };

    React.useEffect(() => reset(defaultValues), [ defaultValues ]);

    return (
        <Modal size="md" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Create Master Account Access</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="userId"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect
                                        label="User"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={isLoading}
                                        placeholder="User to Grant or Deny Access"
                                        onChange={(values) => setValue('userId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="isAccessBlocked"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormSelect
                                        label="Is Access Blocked?"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={isLoading}
                                        options={[
                                            { label: 'Allow Access', value: false },
                                            { label: 'Block Access', value: true }
                                        ]}
                                        onChange={(values) => setValue('isAccessBlocked', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" disabled={isLoading === true}>Create</Button>
                    <Button variant="light" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateAccessModal;
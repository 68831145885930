import React, { useState } from "react";
import CreateProjectModal from "../createprojectmodal";
import Button, { IconButton } from "../../components/buttons";
import { ClientProjectSelect } from "../../components/forms/selects";
import { ThemedParagraph } from "../../components/utilities";
import { useClientContext } from "../../hooks/ClientContext";

export const ClientProjectSearch = ({ 
    autoSelect = true, 
    buttonSize = "lg",
    formError = null,
    label = "Select Project:", 
    labelPosition = "side", 
    onProjectCreated = () => console.warn('onProjectCreated has not been assigned.'), 
    onProjectSelected = () => console.warn('onProjectSelected has not been assigned.'),
    value
}) => {
    const [isCreateShown, setIsCreateShown] = useState(false);
    return <React.Fragment>
        <CreateProjectModal
            show={isCreateShown}
            handleClose={() => setIsCreateShown(false)}
            onProjectCreated={(e) => onProjectCreated(e, autoSelect)}
        />
        <div className="row">
            <div className="col d-flex justify-content-start align-items-center">
                {label && label.length > 0 && labelPosition === 'side' && (
                    <ThemedParagraph className="m-0 me-3" variant="muted">{label}</ThemedParagraph>
                )}
                <div className="flex-fill">
                    <ClientProjectSelect 
                        label={label && label.length > 0 && labelPosition !== 'side' ? label : null} 
                        value={value}
                        setValue={(e) => onProjectSelected(e, e.data)}
                        errorMessage={formError}
                    />
                </div>
            </div>
            <div className={`col-md-auto d-flex justify-content-end ${formError != null ? 'align-items-center' : 'align-items-end'}`}>
                {buttonSize === 'lg' && (
                    <Button variant="success" onClick={() => setIsCreateShown(true)}>Create new Project</Button>
                )}
                {buttonSize !== 'lg' && (
                    <IconButton icon="fa-plus" variant="success" onClick={() => setIsCreateShown(true)} />
                )}
            </div>
        </div>
    </React.Fragment>
};

export default ClientProjectSearch;
import { StandardTableSortableHeader, ThemedTableHeader } from "../../../components/tables/StandardTable";
import { useMessageManagementContext } from "../../../hooks/MessageManagementContext";

const PersonalMessagesVirtualTableHeader = () => {

    const { columns, onClickSort, sortDirection, sortProperty } = useMessageManagementContext();

    return <thead>
        <tr>
            {columns.map(({ label, property, className }, key) => (
                <StandardTableSortableHeader
                    key={key}
                    onClick={() => onClickSort(property)}
                    active={property === sortProperty}
                    direction={sortDirection}
                    className={className}
                    isSticky={true}
                >
                    {label}
                </StandardTableSortableHeader>
            ))}
            <ThemedTableHeader/>
        </tr>
    </thead>
};

export default PersonalMessagesVirtualTableHeader;
import React from "react";
import styled from "styled-components";

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${props => props.gridTemplateColumns};
    grid-row-gap: ${props => props.gridRowGap};
    grid-column-gap: ${props => props.gridColumnGap};
    grid-template-rows: ${props => props.gridTemplateRows};
`;

const GridWrapper = ({
  children,
  gridTemplateColumns,
  gridRowGap = "10px",
  gridColumnGap = "10px",
  gridTemplateRows = "auto",
  ...rest
}) => {
  return (
    <Grid
      gridTemplateColumns={gridTemplateColumns}
      gridRowGap={gridRowGap}
      gridColumnGap={gridColumnGap}
      gridTemplateRows={gridTemplateRows}
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default GridWrapper;



import { useClearMasterAccountDashboardSessionsMutation } from "../../../services/clientauth";

const useDeleteSessions = () => {
    const [_clear, { isLoading, ...clearResult }] = useClearMasterAccountDashboardSessionsMutation();

    const clear = (masterAccountId, loginId) => {
        return new Promise((resolve, reject) => {
            return _clear({ masterAccountId, loginId })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { clear, isLoading, data: { isLoading, ...clearResult } };
};

export default useDeleteSessions;
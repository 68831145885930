import React from "react";
import Button from "../buttons";
import FileUploader from "../FileUploader";
import { Modal } from "react-bootstrap";
import { acceptedFileTypes } from "../../helpers/uploads";

export const defaultUploadProps = {
    accept: acceptedFileTypes
};

const FileUploadModal = ({
    title = "Upload Files",
    confirmButtonLabel = "Confirm",
    confirmButtonVariant = "success",
    handleConfirm = () => {},
    handleClose = () => {},
    isUploadingFile,
    show,
    showAdditionalProps = true,
    uploadProps = defaultUploadProps
}) => {
    const [filesToAdd, setFilesToAdd] = React.useState([]);
    const [fileDataToAdd, setFileDataToAdd] = React.useState([]);

    const onConfirm = () => handleConfirm(filesToAdd, fileDataToAdd)?.finally(_ => handleClose());

    const onClose = () => {
        setFilesToAdd([]);
        handleClose();
    };

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FileUploader 
                    onFilesChanged={({ files, info }) => {
                        setFilesToAdd(_ => files);
                        setFileDataToAdd(_ => info);
                    }}
                    showAdditionalProps={showAdditionalProps}
                    uploadProps={uploadProps}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmButtonVariant} onClick={onConfirm} disabled={isUploadingFile}>{confirmButtonLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FileUploadModal;
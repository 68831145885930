import { useCallback, useEffect, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { 
    useCreateOtherDebtMutation, 
    useDeleteOtherDebtMutation, 
    useFetchOtherDebtsQuery, 
    usePatchOtherDebtMutation, 
    useUpdateOtherDebtBorrowerMappingsMutation 
} from "../../../services/clientfactfind";

const useOtherDebts = () => {

    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isError, error, isFetching, isUninitialized, refetch } = useFetchOtherDebtsQuery({ masterAccountId });
    const [create] = useCreateOtherDebtMutation();
    const [patch] = usePatchOtherDebtMutation();
    const [remove] = useDeleteOtherDebtMutation();
    const [updateBorrowerMappings] = useUpdateOtherDebtBorrowerMappingsMutation();

    const [realTimeBalanceDues, setRealTimeBalanceDues] = useState(data?.map(debt => debt.balanceDue));

    useEffect(() => {
        setRealTimeBalanceDues(data?.map(debt => debt.balanceDue));
    }, [data])

    const patchRealTimeBalanceDue = useCallback((index, newValue) => {
        setRealTimeBalanceDues(oldArr => [
            ...oldArr.slice(0, index),
            newValue,
            ...oldArr.slice(index + 1)
        ]);
    }, [])

    const createOtherDebt = () => {
        return new Promise((resolve, reject) => {
            return create({ masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const patchOtherDebt = (debtId, property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patch({ debtId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => {
                        setRealTimeBalanceDues(data?.map(debt => debt.balanceDue));
                        return reject(e);
                    }
                );
        });
    };

    const removeOtherDebt = (debtId) => {
        return new Promise((resolve, reject) => {
            return remove({ debtId, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const refetchOtherDebts = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch();
    };

    const updateOtherDebtBorrowerMappings = (debtId, ids) => {
        return new Promise((resolve, reject) => {
            return updateBorrowerMappings({ debtId, ids, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        realTimeBalanceDues,
        patchRealTimeBalanceDue,
        data: data || [],
        isLoading: isLoading || isFetching || isUninitialized,
        isError,
        error,
        refetchOtherDebts,
        createOtherDebt,
        patchOtherDebt,
        removeOtherDebt,
        updateOtherDebtBorrowerMappings
    };
};

export default useOtherDebts;
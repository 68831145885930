import { Modal } from "react-bootstrap"
import { Button } from "../../../components"
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import toast from "react-hot-toast";

const RetryMatchingModal = ({ show, onClose }) => {

    const { retryTransactionMatching, retryMatchingResult: isLoading } = useCustodianTransactionMatching();

    const onConfirm = (e) => {
        typeof (e?.preventDefault) === "function" && e.preventDefault();

        toast.promise(retryTransactionMatching(), {
            loading: "Re-matching transactions...",
            error: (err) => err?.data?.message ?? "Error re-matching some transactions",
            success:"Transactions re-matched."
        })
        .then(() => {
            onClose();
        })
    };

    return <Modal centered backdrop="static" size="sm" show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Re-match Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>You are about to re-run the matching process for the below exceptions.</p>
            <p>Do you wish to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" onClick={onConfirm} disabled={isLoading}>Confirm</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
};

export default RetryMatchingModal;
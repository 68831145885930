import { useFetchHOAdminDesignationsQuery } from "../../../services/headofficeadmin";

const useDesignations = (listType) => {

    const {
        data: designations, 
        designationError, 
        designationIsError,
        designationIsFetching, 
        designationIsLoading } = useFetchHOAdminDesignationsQuery(listType);

    return {
        designations,
        designationError,
        designationIsError,
        designationIsFetching,
        designationIsLoading
    };
};

export default useDesignations;
import { AccordionBody, AccordionHeader, AccordionListItem } from "../../../components/utilities";
import { AssetDetails, BenchmarkComparisonTable, VolatilityProfileRationale } from "../components";

const AssetClassifications = ({
    setAssetNameDisplayText,
    setMonitoringDisplayText
}) => {

    return <AccordionListItem eventKey="asset-classifications">
        <AccordionHeader>Asset Classifications</AccordionHeader>
        <AccordionBody>
            {/* First Section - Fund details and classification options */}
            <AssetDetails setAssetNameDisplayText={setAssetNameDisplayText} setMonitoringDisplayText={setMonitoringDisplayText} />

            {/* Second section - Asset returns and volatility statistics */}
            <BenchmarkComparisonTable />

            {/* Third section - Volatility selection and rationale */}
            <VolatilityProfileRationale />

        </AccordionBody>
    </AccordionListItem>
}

export default AssetClassifications;
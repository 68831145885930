import React, { useMemo } from 'react';
import Table from 'react-bootstrap/Table';
import { NumericFormat } from 'react-number-format';
import styled from "styled-components";
import { useInstruction } from '../contexts/InstructionContext';

const TableHeader = styled.th`
    text-align: center !important;
`;
const TableData = styled.td`
    text-align: center !important;
`;

const AnnualCostsDisclosureComparisonTable = () => {
    const [, { invest, divest }] = useInstruction();

    const cellValue = (value) => {
        return (
            <React.Fragment>
                <NumericFormat
                    value={value ?? 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
                <NumericFormat
                    // todo
                    value={0}
                    displayType="text"
                    thousandSeparator={false}
                    prefix=" ("
                    suffix="%)"
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            </React.Fragment>
        );
    };

    const beforeServiceOngoing = divest?.ongoingServiceAmount;
    const beforeProductOngoing = divest?.ongoingProductAmount;
    const beforeServiceAdHoc = divest?.adHocServiceAmount;
    const beforeProductAdHoc = divest?.adHocProductAmount;
    const existingTotal = useMemo(() =>
        beforeServiceOngoing + beforeProductOngoing + beforeServiceAdHoc + beforeProductAdHoc,
        [beforeProductAdHoc, beforeProductOngoing, beforeServiceAdHoc, beforeServiceOngoing]);

    const afterServiceOngoing = invest?.ongoingServiceAmount;
    const afterProductOngoing = invest?.ongoingProductAmount;
    const afterServiceAdHoc = invest?.adHocServiceAmount;
    const afterProductAdHoc = invest?.adHocProductAmount;
    const recommendedTotal = useMemo(() =>
        afterServiceOngoing + afterProductOngoing + afterServiceAdHoc + afterProductAdHoc,
        [afterProductAdHoc, afterProductOngoing, afterServiceAdHoc, afterServiceOngoing]);

    return (
        <Table className="mt-5" bordered hover striped>
            <thead>
                <tr>
                    <TableHeader rowSpan={2} className={"align-top text-center"}>{"Annual Cost Comparison"}</TableHeader>
                    <TableHeader colSpan={2}>{"Existing Scenario"}</TableHeader>
                    <TableHeader colSpan={2}>{"Recommended Scenario"}</TableHeader>
                </tr>
                <tr>
                    <TableHeader>{"Service"}</TableHeader>
                    <TableHeader>{"Product"}</TableHeader>

                    <TableHeader>{"Service"}</TableHeader>
                    <TableHeader>{"Product"}</TableHeader>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <TableData>{"Ongoing Costs"}</TableData>
                    <TableData>{cellValue(beforeServiceOngoing)}</TableData>
                    <TableData>{cellValue(beforeProductOngoing)}</TableData>
                    <TableData>{cellValue(afterServiceOngoing)}</TableData>
                    <TableData>{cellValue(afterProductOngoing)}</TableData>
                </tr>
                <tr>
                    <TableData>{"Ad-Hoc Costs"}</TableData>
                    <TableData>{cellValue(beforeServiceAdHoc)}</TableData>
                    <TableData>{cellValue(beforeProductAdHoc)}</TableData>
                    <TableData>{cellValue(afterServiceAdHoc)}</TableData>
                    <TableData>{cellValue(afterProductAdHoc)}</TableData>
                </tr>
                <tr>
                    <TableData>{"Total Costs"}</TableData>
                    <TableData colSpan={2}>{cellValue(existingTotal)}</TableData>
                    <TableData colSpan={2}>{cellValue(recommendedTotal)}</TableData>
                </tr>
            </tbody>
        </Table>
    )
}

export default AnnualCostsDisclosureComparisonTable;
import ClientReviewManagementLayout from "../layouts/ClientReviewManagementLayout";
import { NavigationTabs } from "../components/navigation";
import {
    ReviewManagementDetailedPage,
    ReviewManagementSummaryPage,
    ReviewManagementTargetsPage
} from "../pages/management-client-reviews";

const ManagementReviewsRoutes = {
    path: 'reviews',
    pathBase: '/management/reviews',
    defaultRoute: "detailed",
    roles: [],
    element: ClientReviewManagementLayout,
    children: [
        { 
            path: 'detailed', 
            icon: 'fa-table', 
            label: 'Detailed View', 
            roles: [],
            element: ReviewManagementDetailedPage
        },
        { 
            path: 'summary', 
            icon: 'fa-file-signature', 
            label: 'Summary View', 
            roles: [],
            element: ReviewManagementSummaryPage
        },
        { 
            path: 'targets', 
            icon: 'fa-file-signature', 
            label: 'Target Rates', 
            roles: [],
            element: ReviewManagementTargetsPage
        }
    ]
};

export const ManagementReviewRouteTabs = () => <NavigationTabs pathBase={ManagementReviewsRoutes.pathBase} options={ManagementReviewsRoutes} />

export default ManagementReviewsRoutes;
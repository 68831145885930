import React from "react";
import { 
    useLazyFetchReportFeedbackQuery,
    useUpdateReportFeedbackRecordMutation,
    useUpdateReportFeedbackRecordRecipientsMutation 
} from "../../../services/clientreports"

const useReportFeedback = (masterAccountId, reportId) => {
    const [isDirty, setIsDirty] = React.useState(false);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [patch, patchResults] = useUpdateReportFeedbackRecordMutation();
    const [patchRecipients, patchRecipientResults] = useUpdateReportFeedbackRecordRecipientsMutation();
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazyFetchReportFeedbackQuery();

    const clear = () => {
        if (!masterAccountId || !reportId) {
            return;
        }

        setIsDirty(_ => true);
        setIsLoadingState(_ => true);
        load({ masterAccountId, reportId }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
            setIsDirty(_prev => false);
        });
    };

    const updateRecipients = (feedbackId, values) => {
        return new Promise((resolve, reject) => {
            return patchRecipients({ masterAccountId, reportId, feedbackId, recipients: values }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateRecord = (feedbackId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [{ op: 'replace', path: `/${property}`, value }];
            return patch({ masterAccountId, reportId, feedbackId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    React.useEffect(clear, [ masterAccountId, reportId ]);

    return {
        ...loadResults,
        data: data || [],
        isDirty,
        isFetching: isDirty === true ? false : isFetching,
        isLoading: isDirty === true ? true : isLoading,
        isLoadingState,
        updateRecipients,
        updateRecipientResults: patchRecipientResults,
        updateRecord,
        updateResult: patchResults
    };
};

export default useReportFeedback;
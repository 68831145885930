import React from "react";
import ServiceAgreementDetails from "./ServiceAgreementDetails";
import PortfolioBreakdown from "./PortfolioBreakdown";
import ServiceAgreementProofs from "./ServiceAgreementProofs";
import ServiceAgreementsWrapper from "./ServiceAgreementsWrapper";

const ServiceAgreement = ({masterAccountId, loadingLines = 3}) => {

    return (
        <ServiceAgreementsWrapper label="Service Agreement">
            <div>
                <ServiceAgreementDetails masterAccountId={masterAccountId} />
            </div>
        </ServiceAgreementsWrapper>
    );
};

export default ServiceAgreement;

import styled from "styled-components";

const Main = styled.main`
    grid-area: main;
`;

/**
 * Special Main Component to be used when the primary display is a full sized AG Grid component
 * This will stop the last few rows & status panel being chopped off the end whilst retaining
 * the original default styling of MAIN that is used on other areas (not using AG Grid)
 */
export const AgGridMain = styled(Main)`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export default Main;
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "../components";
import { variantToColour } from "../helpers/theme";

const Header = styled.h1`
    font-weight: bold;
    color: ${props => variantToColour({ theme: props.theme, variant: 'primary' })};
`;

const ErrorCode = styled.span`
    font-weight: bold;
    font-size: 12rem;
    color: ${props => variantToColour({ theme: props.theme, variant: 'muted' })}
`;


const NotFoundPage = ({ dashboardLink = "/" }) => {
    const navigate = useNavigate();

    const onNavigateToDashboard = () => navigate(dashboardLink, { replace: true });

    return <div className="vh-100 d-flex flex-column flex-md-row justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center p-md-3">
            <ErrorCode>404</ErrorCode>
        </div>
        <div className="px-3">
            <Header>Whoops!</Header>
            <h2>This is not the page you're looking for.</h2>
            <p>Please check you have the correct URL. Get in touch with the system administrator if you think there is a problem.</p>
            <div className="d-flex flex-column flex-md-row gap-2">
                <Button variant="primary" onClick={onNavigateToDashboard}>Back to Dashboard</Button>
            </div>
        </div>
    </div>
};

export default NotFoundPage;
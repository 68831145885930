import { useEffect, useState } from "react";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useLazyFetchAssetGroupsForManagementGridQuery } from "../../../services/assets"
import { useCallback } from "react";

const useFundManagementGrid = () => {
    const {
        filter: filters,
    } = useFilterContext();

    const [sortBy, setSortBy] = useState(null);

    const [fetchAssetGroupsForManagementTrigger, { currentData: assetGroups, isUninitialized: assetGroupsIsUninitialised, isLoading: assetGroupsIsLoading, isFetching: assetGroupsIsFetching, isError: assetGroupsIsError, error: assetGroupsError }] = useLazyFetchAssetGroupsForManagementGridQuery({ refetchOnFocus: true });

    useEffect(() => {
        fetchAssetGroupsForManagementTrigger({
            filters,
            sort: sortBy
        });
    }, [fetchAssetGroupsForManagementTrigger, filters, sortBy]);

    const sort = useCallback((field) => {
        setSortBy(existing => {
            if (existing === field)
                return `${field}+Desc`;
            if (existing === `${field}+Desc`)
                return ""
            else
                return field;
        })
    }, []);

    const refetch = useCallback(() => {
        fetchAssetGroupsForManagementTrigger({
            filters
        });
    }, [fetchAssetGroupsForManagementTrigger, filters])

    return {
        assetGroups,
        assetGroupsIsUninitialised,
        assetGroupsIsLoading,
        assetGroupsIsFetching,
        assetGroupsIsError,
        assetGroupsError,
        sort,
        currentSort: sortBy,
        refetch
    };
}

export default useFundManagementGrid;
import React from "react";
import SalutationList from "./components/SalutationList";
import { Accordion } from "../../components";
import { SalutationPreferencesProvider } from "./hooks/useSalutationPreferences";

const ClientSalutationPreferences = ({ label = "Salutation Preferences", masterAccountId }) => {
    return (
        <Accordion defaultActiveKey="1" gap={1} label={label}>
            <SalutationPreferencesProvider masterAccountId={masterAccountId}>
                <SalutationList />
            </SalutationPreferencesProvider>
        </Accordion>
    );
};

export default ClientSalutationPreferences;
import { patchReplace } from "../../../helpers/patchDoc";
import { useCreateTaxYearEndWithdrawalMutation, useDeleteTaxYearEndWithdrawalMutation, useFetchTaxYearEndWithdrawalsQuery, usePatchTaxYearEndWithdrawalMutation } from "../../../services/clientfactfind";

const useTaxYearEndWithdrawals = (pensionPlanId) => {

    const { data, isLoading, isError, error, refetch, isFetching } = useFetchTaxYearEndWithdrawalsQuery({ pensionPlanId });
    const [createTrigger, { isLoading: isCreating }] = useCreateTaxYearEndWithdrawalMutation();
    const [deleteTrigger] = useDeleteTaxYearEndWithdrawalMutation();
    const [updateTrigger] = usePatchTaxYearEndWithdrawalMutation();

    const createWithdrawal = () => {
        return new Promise((resolve, reject) => {
            return createTrigger({ pensionPlanId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const deleteWithdrawal = (withdrawalId) => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ withdrawalId, pensionPlanId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const reload = (e) => {
        if(e && typeof(e.preventDefault) === "function"){
            e.preventDefault();
        }

        refetch();
    }

    const updateWithdrawal = (withdrawalId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return updateTrigger({ withdrawalId, operations, pensionPlanId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        data: data || [],
        isLoading: isLoading || isFetching,
        isError,
        error,
        refetch: reload,
        createWithdrawal,
        isCreating,
        deleteWithdrawal,
        updateWithdrawal
    };
};

export default useTaxYearEndWithdrawals;
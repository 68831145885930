import { useTargetsQueryMutation } from "../../../services/clientreviews";

const useReviewTargetSuccessRatesGroup = (parameters) => {

    const [search, searchResult] = useTargetsQueryMutation()

    const onLoadOptions = () => search(parameters);

    return { search: onLoadOptions, ...searchResult };

};

export default useReviewTargetSuccessRatesGroup;
import React from "react";
import FormSelect from "../../../components/forms/FormSelect";
import useProducts from "../hooks/useProducts";

const ProductSelect = ({ products: optionsOverride, ...rest }) => {

    const { products, productsIsLoading, productsIsFetching } = useProducts("select");

    return <FormSelect 
        options={products || []} 
        isLoadingOptions={productsIsLoading || productsIsFetching}
        {...rest} />
};

export default ProductSelect;
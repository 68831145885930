import React from "react";
import moment from "moment";
import DetailsWrapper from "./DetailsWrapper";
import Skeleton from "react-loading-skeleton";
import useMasterAccountDetails from "../hooks/useMasterAccountDetails";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { CountrySelect, DateInput, FormCheck, FormInput, FormSelect, FormTextArea } from "../../../components/forms";
import { BusinessSourceSelect, MasterAccountStatusSelect, RiskGradingSelect } from "../../../components/forms/selects";

const AccountDetails = ({ masterAccountId }) => {
    const { data, error, isError, isLoading, reload, updateMasterAccount, updateMasterAccountFields } = useMasterAccountDetails(masterAccountId);

    const handleRetry = (e) => {
        e.preventDefault();
        reload(e);
    };

    const onFieldChanged = (property, value) => updateMasterAccount(property, value);

    if (isLoading === true) {
        return <DetailsWrapper>
            <div className="row my-2">
                <div className="col"><Skeleton height={37} /></div>
                <div className="col"><Skeleton height={37} /></div>
                <div className="col"><Skeleton height={37} /></div>
                <div className="col"><Skeleton height={37} /></div>
            </div>
            <div className="row mb-2">
                <div className="col"><Skeleton height={37} /></div>
                <div className="col"><Skeleton height={37} /></div>
                <div className="col"><Skeleton height={37} /></div>
                <div className="col"><Skeleton height={37} /></div>
            </div>
        </DetailsWrapper>
    }

    if (error || isError === true) {
        return <DetailsWrapper>
            <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                <ErrorMessage error={error} retryCallback={handleRetry} />
            </ErrorMessageWrapper>
        </DetailsWrapper>
    }

    return <DetailsWrapper label="Account">
        <div className="row mb-3">
            <div className="col">
                <FormInput
                    id="accountName"
                    label="Account"
                    value={data.accountName}
                    placeholder="Account name"
                    onBlur={(value) => onFieldChanged('accountName', value)}
                />
            </div>
            <div className="col-2">
                <MasterAccountStatusSelect
                    label="Status"
                    defaultValue={data.status}
                    onBlur={(values) => onFieldChanged('status', values.value)}
                />
            </div>
            <div className="col-2">
                <DateInput
                    label="Status Date"
                    value={data.statusDate}
                    onBlur={(value) => onFieldChanged('statusDate', value)}
                />
            </div>
        </div>
        <div className="row mb-2">
        <div className="col">
                <BusinessSourceSelect
                    label="Business Source"
                    defaultValue={data.businessSourceId}
                    onBlur={(values) => updateMasterAccountFields([
                        { property: `businessSourceId`, value: values.value },
                        { property: `businessSourceDate`, value: moment().format() }
                    ])}
                />
            </div>
            <div className="col">
                <RiskGradingSelect
                    label="Risk Grading"
                    defaultValue={data.riskGrade}
                    onBlur={(values) => updateMasterAccountFields([
                        { property: `riskGrade`, value: values.value },
                        { property: `riskGradeDate`, value: moment().format() }
                    ])}
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Resident"
                    defaultValue={data.isOverseas}
                    options={[
                        { label: 'UK Resident', value: false },
                        { label: 'Overseas', value: true }
                    ]}
                    // onFieldChanged('isOverseas', values.value)
                    onBlur={(values) => updateMasterAccountFields([
                        { property: `isOverseas`, value: values.value },
                        { property: `countryCode`, value: values.value === true ? null : 'GB' }
                    ])}
                />
            </div>
            <div className="col">
                <CountrySelect
                    label="Country"
                    hideGb={data.isOverseas}
                    defaultValue={data.countryCode}
                    isDisabled={data.isOverseas === false}
                    onBlur={(values) => onFieldChanged('countryCode', values.value)}
                />
            </div>
            <div className="col-md-auto d-flex justify-content-center align-items-end">
                <FormCheck
                    type="checkbox"
                    label="Vulnerable Client"
                    isChecked={data.isVulnerable}
                    onChange={(_) => onFieldChanged('isVulnerable', !data.isVulnerable)}
                />
            </div>
        </div>
        <div className="row mb-2">
            <div className="col">
                <FormTextArea
                    minRows={3}
                    maxRows={7}
                    label="Notes"
                    value={data.notes}
                    placeholder="Enter your notes here."
                    onBlur={(value) => onFieldChanged('notes', value)}
                />
            </div>
        </div>
    </DetailsWrapper>
};

export default AccountDetails;
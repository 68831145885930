import { wealthProApi } from "../../redux/api";

const userCalendarEventsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['CalendarEvents']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchCalendarEventsByUserId: builder.query({
            query: ({ userId, monthIndex }) => `api/clientdocuments/users/${userId}/events?monthIndex=${monthIndex}`,
            providesTags: (result, error, arg) => {
                return ['CalendarEvents'];
            }
        }),
        getCalendarEvent: builder.query({
            query: ({ eventId }) => `api/clientdocuments/calendar/events/${eventId}`
        }),
        getCalendarEventById: builder.query({
            query: ({ userId, eventId }) => `api/clientdocuments/users/${userId}/events/${eventId}`,
            providesTags: (res, error, { eventId }) => [{ type: 'CalendarEvents', id: eventId }]
        }),
        createCalendarEvent: builder.mutation({
            query: ({ userId, request }) => ({
                url: `api/clientdocuments/users/${userId}/events`,
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ monthIndex, userId }, { dispatch, queryFulfilled }) {
                try {
                    var { data } = await queryFulfilled;
                    var result = dispatch(wealthProApi.util.updateQueryData('fetchCalendarEventsByUserId', { monthIndex, userId }, ({ value }) => {
                        value.push(data);
                    }));
                }
                catch { }
            }
        }),
        patchCalendarEvent: builder.mutation({
            query: ({ userId, id, request }) => ({
                url: `api/clientdocuments/users/${userId}/events/${id}`,
                method: 'PATCH',
                body: request
            }),
            async onQueryStarted({ userId, id, request }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedEvent } = await queryFulfilled;
                    const result = dispatch(wealthProApi.util.updateQueryData('getCalendarEventById', { userId, eventId: id }, data => {
                        return updatedEvent;
                    }));
                }
                catch { }
            }
        }),
        deleteCalendarEvent: builder.mutation({
            query: ({ userId, id }) => ({
                url: `api/clientdocuments/users/${userId}/events/${id}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ userId, monthIndex, id }, { dispatch, queryFulfilled }) {
                console.log({ userId, monthIndex, id })
                var result = dispatch(wealthProApi.util.updateQueryData('fetchCalendarEventsByUserId', { monthIndex, userId }, (data) => {
                    return {
                        ...data,
                        value: data.value.filter(x => x.id !== id)
                    };
                }));
                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        fetchEventDelegatedMasterAccountsForUser: builder.query({
            query: ({ page = 1, limit = 25, sort = "AccountName", searchText }) => (
                `api/clientdocuments/calendar/events/delegatedAccounts?page=${page}&limit=${limit}&sort=${sort}${searchText ? `searchTerm=${searchText}` : ""}`
            )
        })
    })
});

export const {
    useFetchCalendarEventsByUserIdQuery,
    useCreateCalendarEventMutation,
    usePatchCalendarEventMutation,
    useLazyGetCalendarEventQuery,
    useLazyGetCalendarEventByIdQuery,
    useGetCalendarEventByIdQuery,
    useDeleteCalendarEventMutation,
    useFetchEventDelegatedMasterAccountsForUserQuery,
    useLazyFetchEventDelegatedMasterAccountsForUserQuery
} = userCalendarEventsApiEndpoints;
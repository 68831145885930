import { IconButton } from "../../../components/buttons"
import { DateInput, FormInput, FormSelect } from "../../../components/forms"
import { useClientContext } from "../../../hooks/ClientContext"
import useClientAccountDetails from "../hooks/useClientAccountDetails"

const ClientAccountForm = ({ client, showLabel = false, allowDelete = true, ...rest }) => {
    const { id } = useClientContext();

    const {
        deleteClientAccount,
        updateClientAccount
    } = useClientAccountDetails(id)

    return <div className="row mb-2" {...rest}>
        <div className="col-1">
            <FormInput
                label={showLabel ? "Title" : null}
                value={client.title}
                placeholder="Title"
                onBlur={(value) => updateClientAccount(client.clientAccountId, 'title', value)}
            />
        </div>
        <div className="col">
            <FormInput
                label={showLabel ? "Forenames" : null}
                value={client.forenames}
                placeholder="Forenames"
                onBlur={(value) => updateClientAccount(client.clientAccountId, 'forenames', value)}
            />
        </div>
        <div className="col">
            <FormInput
                label={showLabel ? "Surname" : null}
                value={client.surname}
                placeholder="Surname"
                onBlur={(value) => updateClientAccount(client.clientAccountId, 'surname', value)}
            />
        </div>
        <div className="col-2">
            <FormSelect
                label={showLabel ? "Status" : null}
                defaultValue={client.status}
                options={[
                    { label: 'Active', value: 0 },
                    { label: 'Deceased', value: 1 },
                    { label: 'POA Activated', value: 2 },
                    { label: 'Inactive', value: 3 }
                ]}
                onChange={(values) => updateClientAccount(client.clientAccountId, 'status', values.value)}
            />
        </div>
        <div className="col-2">
            <DateInput
                label={showLabel ? "Status Date" : null}
                value={client.statusDate}
                onBlur={(value) => updateClientAccount(client.clientAccountId, 'statusDate', value)}
            />
        </div>
        {allowDelete && <div className="col-md-auto">
            <IconButton icon="fa-trash-can" variant="danger" onClick={() => deleteClientAccount(client.clientAccountId)} />
        </div>}
    </div>
}

export default ClientAccountForm;
import React from "react";
import AccountDetails from "./components/AccountDetails";
import AddressDetails from "./components/AddressDetails";
import ClientAccounts from "./components/ClientAccounts";
import ContactDetails from "./components/ContactDetails";
import DesignationDetails from "./components/DesignationDetails";
import { Main } from "../../components/utilities";
import { useClientContext } from "../../hooks/ClientContext";

const ClientDetails = () => {
    const { id } = useClientContext();

    return <Main className="p-3">
        <AccountDetails masterAccountId={id} />
        <ClientAccounts masterAccountId={id} />
        <AddressDetails masterAccountId={id} />
        <ContactDetails masterAccountId={id} />
        <DesignationDetails masterAccountId={id} />
    </Main>
};

export default ClientDetails;
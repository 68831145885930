import Skeleton from "react-loading-skeleton";
import { IconButton } from "../../../components";
import { FormInput } from "../../../components/forms";
import { AttachmentsToggle } from "../../../components/utilities";
import { useMessageThreadContext } from "../hooks/MessageThreadContext";
import MasterAccountProjectPagedSelect from "./MasterAccountProjectSelect";

const ThreadHeader = () => {

    const { getThreadResult, createDraft, retryGetThread, updateThread } = useMessageThreadContext();
    const { data, isLoading, isFetching, isError } = getThreadResult || {};
    const { subject, masterAccountId, projectId, threadAttachments } = data || {};

    if (isLoading || isFetching) {
        return (
            <div className="mb-3 d-flex justify-content-between">
                <Skeleton height={32} width={196} />
                <IconButton
                    icon="fa-plus"
                    variant="success"
                    onClick={createDraft}
                />
            </div>
        );
    }

    if (isError) {
        return (
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <span>Unable to retrieve thread information. Click <a href={"#"} onClick={retryGetThread}>here</a> to retry.</span>
                <IconButton
                    icon="fa-plus"
                    variant="success"
                    onClick={createDraft}
                />
            </div>
        );
    }

    return (
        <div className="d-flex justify-content-between mb-4 align-items-center">
            <div className="me-3 flex-grow w-25">
                <FormInput
                    placeholder="Subject..."
                    value={subject}
                    onBlur={(value) => updateThread("subject", value)}
                />
            </div>
            <div className="d-flex align-items-center w-50 justify-content-end">
                {masterAccountId && <div className="flex-fill">
                    <MasterAccountProjectPagedSelect
                        className="me-3"
                        masterAccountId={masterAccountId}
                        value={projectId}
                        onBlur={(selection) => updateThread("projectId", selection?.value || null)}
                        isClearable
                    />
                </div>}
                {Array.isArray(threadAttachments) && threadAttachments.length > 0 && (
                    <AttachmentsToggle
                        className={"me-3"}
                        menuDirection="start"
                        attachments={threadAttachments}
                    />
                )}
                <IconButton
                    icon="fa-plus"
                    variant="success"
                    onClick={createDraft}
                />
            </div>
        </div>
    );
};

export default ThreadHeader;
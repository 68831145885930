import { useMemo } from "react";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

const FeeGroupingTableHeader = ({ sort, setSort }) => {

    const property = useMemo(() => sort?.split("+")[0], [sort]);
    const direction = useMemo(() => sort?.split("+")[1], [sort]);

    return (
        <thead>
            <tr>
                <StandardTableSortableHeader
                    active={property === "feeType"}
                    onClick={() => setSort("feeType")}
                    direction={direction}
                >
                    Fee Type
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "providerName"}
                    onClick={() => setSort("providerName")}
                    direction={direction}
                >
                    Provider
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "masterAccountName"}
                    onClick={() => setSort("masterAccountName")}
                    direction={direction}
                >
                    Client
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "feeDate"}
                    onClick={() => setSort("feeDate")}
                    direction={direction}
                >
                    Fee Date
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "totalFeeValue"}
                    onClick={() => setSort("totalFeeValue")}
                    direction={direction}
                >
                    Total Fees
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "TotalVatValue"}
                    onClick={() => setSort("TotalVatValue")}
                    direction={direction}
                >
                    Total VAT
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "totalDebtValue"}
                    onClick={() => setSort("totalDebtValue")}
                    direction={direction}
                >
                    Outstanding Debt
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "description"}
                    onClick={() => setSort("description")}
                    direction={direction}
                >
                    Description
                </StandardTableSortableHeader>
                <StandardTableSortableHeader/>
            </tr>
        </thead>
    );
};

export default FeeGroupingTableHeader;
import _ from "lodash"
import React from "react";
import ProjectReportsTableEmptyRow from "./components/ProjectReportsTableEmptyRow";
import ProjectReportsTableLoadingRow from "./components/ProjectReportsTableLoadingRow";
import ProjectReportsTableRow from "./components/ProjectReportsTableRow";
import useProjectReportsList from "./hooks/useProjectReportsList";
import withProviders from "../../hooks/withProviders";
import CreateReportModal from "../createreportmodal";
import ClientReportsHistoryModal from "../clientreportshistorymodal";
import StandardTable, { ThemedTableHeaderActionColumn } from "../../components/tables/StandardTable";
import { EditClientAuthorModal } from "../clientreportseditmodal";
import { EditReportProvider, useEditReport } from "../clientreportseditmodal/hooks/EditReportContext";
import { useClientProjectContext } from "../../hooks/ClientProjectContext";
import { useReportHistory, ReportHistoryProvider } from "../clientreportshistorymodal/hooks/ReportHistoryContext";
import { NumericFormat } from "react-number-format";
import { AccordionSection, ThemedIcon, ThemedSpan } from "../../components/utilities";
import { IconButton } from "../../components";

const ClientProjectsReportsTable = ({ label = "Reports", onProjectChanged = () => { } }) => {
    const [showCreateReport, setShowCreateReport] = React.useState(false);
    const { selectedValue } = useClientProjectContext();
    const { projectId } = selectedValue || { projectId: null };
    const { clear: clearSelectedReport, isLoading: isReportUpdating, isModalActive, report, saveChanges } = useEditReport();
    const { clear: clearSelectedHistoryReport, isModalActive: isReportHistoryShown, report: selectedHistoryReport } = useReportHistory();
    const { clear, data, newRecordsSinceCache, error, isNewRecords, isLoading, isUninitialized } = useProjectReportsList(projectId);

    const onReportCreated = (report) => {
        if (report && report.projectId && !_.isEqual(report.projectId, projectId)) {
            onProjectChanged(report.projectId);
        }
        else {
            clear();
        }
    };

    if (!selectedValue) {
        return null;
    }

    return (
        <React.Fragment>
            <CreateReportModal 
                show={showCreateReport}
                defaultProjectId={projectId}
                handleClose={() => setShowCreateReport(_ => false)}
                onReportCreated={onReportCreated}
            />
            <EditClientAuthorModal 
                report={report}
                show={isModalActive}
                isLoading={isReportUpdating}
                handleSave={(ops, message) => saveChanges(ops, message).then(clearSelectedReport)}
                handleClose={clearSelectedReport}
            />
            <ClientReportsHistoryModal 
                report={selectedHistoryReport}
                show={isReportHistoryShown}
                handleClose={clearSelectedHistoryReport}
            />
            <AccordionSection label={(
                <div className="d-flex w-100">
                    <div className="flex-fill">{label}</div>
                    {isNewRecords && (
                        <React.Fragment>
                            {newRecordsSinceCache > 1
                                ? (<ThemedSpan variant="danger" className="mx-2"><NumericFormat displayType="text" value={newRecordsSinceCache} thousandSeparator="," /> new records available.</ThemedSpan>)
                                : (<ThemedSpan variant="danger" className="mx-2"><NumericFormat displayType="text" value={newRecordsSinceCache} thousandSeparator="," /> new record available.</ThemedSpan>)
                            }
                            <ThemedIcon variant={isNewRecords ? 'danger' : 'muted'} icon="fas fa-sync" className="has-pointer mx-2" onClick={() => clear()} />)
                        </React.Fragment>
                    )}
                </div>
            )} defaultActiveKey="1">
                <StandardTable 
                    className="table-borderless table-sm"
                    columns={[
                        { label: 'Name', property: 'reportName' },
                        { label: 'Status', property: 'statusDescription' },
                        { label: 'Author', property: 'authorName' },
                        { label: 'Compliance', property: 'complianceOfficerName' },
                        { label: 'Compliant', property: 'compliantDate' },
                        { label: 'Sent', property: 'sentDate' },
                        { label: 'Media', property: 'sentByMediaType' },
                        { label: 'Created', property: 'createdDate' },
                        { 
                            label: '', 
                            property: '',
                            headerRenderer: (props) => (
                                <ThemedTableHeaderActionColumn key={props.key}>
                                    <IconButton className="btn-sm" icon="fa-plus" variant="success" onClick={() => setShowCreateReport(_ => true)} />
                                </ThemedTableHeaderActionColumn>
                            )
                        }
                    ]}
                    data={data}
                    error={error}
                    isLoading={isLoading || isUninitialized}
                    isTableResponsive={false}
                    EmptyRowComponent={ProjectReportsTableEmptyRow}
                    emptyRowComponentProps={{ onCreateClicked: () => setShowCreateReport(_ => true) }}
                    hideHeaderOnEmpty={true}
                    LoadingRowComponent={ProjectReportsTableLoadingRow}
                    RowComponent={ProjectReportsTableRow}
                    rowComponentProps={{ project: selectedValue }}
                />
            </AccordionSection>
        </React.Fragment>
    )
};

export default withProviders(EditReportProvider, ReportHistoryProvider)(ClientProjectsReportsTable);
import { useEffect, useState } from "react";
import { useLazyFetchMasterAccountProjectsListQuery } from "../../../services/clientprojects";

const useMasterAccountProjects = (masterAccountId) => {
    const [cacheKey, setCacheKey] = useState(new Date().valueOf());
    const [pageLimit, setPageLimit] = useState(25);
    const [load, { data, isLoading, isFetching, ...queryResults }] = useLazyFetchMasterAccountProjectsListQuery();
    const { results, pagination: { page, totalCount, totalPages } } = data || { results: [], pagination: { page: 1, totalCount: 0, totalPages: 0 } }
    const hasMore = page < totalPages;

    const clear = () => {
        if (!masterAccountId) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            searchText: "",
            page: 1,
            pageLimit
        }).unwrap().then(_ => setCacheKey(newCacheKey))
    };

    const nextPage = () => {
        if (page >= totalPages) {
            return new Promise((resolve) => resolve(false));
        }
        return load({
            cacheKey,
            masterAccountId,
            searchText: "",
            page: page + 1,
            pageLimit
        });
    };

    const previousPage = () => {
        if (page <= 1) {
            return new Promise((resolve) => resolve(false));
        }
        return load({
            cacheKey,
            masterAccountId,
            searchText: "",
            page: page - 1,
            pageLimit
        });
    };

    useEffect(clear, [masterAccountId]);

    return {
        clear,
        nextPage,
        previousPage,
        data: results,
        hasMore,
        page,
        isLoading,
        isFetching
    };
};

export default useMasterAccountProjects;
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useCreateFinalSalaryArrangementMutation, useCreatePensionArrangementMutation, useDeletePensionArrangementMutation, useFetchPensionArrangementsQuery, useUpdatePensionArrangementMutation } from "../../../services/clientfactfind";

const drawdownTypes = [
    {
        label: "Not Applicable",
        value: 10
    },
    {
        label: "Flexi-Access",
        value: 20
    },
    {
        label: "Capped Drawdown",
        value: 30
    },
];

const pensionTypes = [
    {
        label: "Stakeholder",
        value: 10
    },
    {
        label: "Personal Pension",
        value: 20
    },
    {
        label: "SIPP",
        value: 30
    },
    {
        label: "Workplace Pension",
        value: 40
    },
    {
        label: "Final Salary",
        value: 50
    },
];

const usePensionArrangements = () => {

    const { id: masterAccountId } = useClientContext();
    const { data, isError, isLoading, error, isFetching, isUninitialized, refetch } = useFetchPensionArrangementsQuery({ masterAccountId });
    const [triggerCreate] = useCreatePensionArrangementMutation();
    const [triggerCreateFinalSalary] = useCreateFinalSalaryArrangementMutation();
    const [triggerDelete] = useDeletePensionArrangementMutation();
    const [triggerPatch] = useUpdatePensionArrangementMutation();

    const createArrangement = ({ pensionName, pensionType }) => {
        return new Promise((resolve, reject) => {
            return triggerCreate({ pensionName, pensionType, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const createFinalSalaryArrangement = ({ pensionName }) => {
        return new Promise((resolve, reject) => {
            return triggerCreateFinalSalary({ pensionName, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    const patchArrangement = (pensionPlanId, property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return triggerPatch({ pensionPlanId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };


    const refetchPensionArrangements = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch();
    };

    const removeArrangement = (pensionPlanId) => {
        return new Promise((resolve, reject) => {
            return triggerDelete({ pensionPlanId, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        data: data || [],
        pensionTypes: pensionTypes,
        isError,
        error,
        isLoading: isLoading,
        drawdownTypes,
        createArrangement,
        createFinalSalaryArrangement,
        patchArrangement,
        removeArrangement,
        refetchPensionArrangements
    };
};

export default usePensionArrangements;
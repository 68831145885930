import React from "react";
import FormSelect from "../FormSelect";

export const AdviceTypes = [
    { label: 'Investment', value: 0 },
    { label: 'Pension', value: 1 },
    { label: 'Service Review', value: 2 },
    { label: 'Estate Agency', value: 3 },
    { label: 'Adviser Review', value: 4 },
    { label: 'Protection', value: 5 },
    { label: 'Mortgage', value: 6 },
    { label: 'Execution Only', value: 7 },
    { label: 'DB Scheme', value: 8 },
    { label: 'Basic Review', value: 9 }
];

const AdviceTypesSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={AdviceTypes.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default AdviceTypesSelect;
import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { useProviderManagement } from "../hooks/ProviderManagementContext";

const ProvidersGridHeader = () => {

    const { columns } = useProviderManagement();

    return <thead>
        <tr>
            {columns.map((col, key) => col.renderer
                ? col.renderer({ ...col, key })
                : <ThemedTableHeader key={key} className={col.className}>{col.label}</ThemedTableHeader>
            )}
        </tr>
    </thead>;

};

export default ProvidersGridHeader;
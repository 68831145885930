import { wealthProApi } from '../../redux/api';

const reportCategoryEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchReportCategories: builder.query({
            query: ({ fetchType = "Object" }) => fetchType === "List"
                ? `/api/reports/report-categories/list`
                : `/api/reports/report-categories`
        })
    })
});

export const {
    useFetchReportCategoriesQuery
} = reportCategoryEndpoints;
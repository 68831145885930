import Skeleton from "react-loading-skeleton";
import { useCorporateActionPairs } from "../hooks/CorporateActionPairsContext";
import styled from "styled-components";
import moment from "moment";
import Moment from "react-moment";
import CorporateActionPairsGridRowActionCol from "./CorporateActionPairsGridRowActionCol";

const TableCell = styled.td`
    width: ${props => props.width}%;
    max-width: 50px;
    vertical-align: middle;
`;

const CorporateActionPairsGridRow = ({ index, data, style: { height, width } }) => {

    const { columns, setPairToDeleteId, setPairToEditId } = useCorporateActionPairs();

    if (!data[index]) {
        return (
            <tr style={{ height, width }}>
                <td colSpan={columns.length}>
                    <Skeleton height={height - 10} />
                </td>
            </tr>
        );
    }

    const {
        pairId,
        actionDate,
        sedolIn,
        sedolOut,
        description
    } = data[index];

    return <tr onDoubleClick={() => setPairToEditId(pairId)} style={{ height, width }}>
        <TableCell width={8.5}>{moment(actionDate).isValid() ? <Moment date={actionDate} format={"DD/MM/YYYY"} /> : <span className="px-2">-</span>}</TableCell>
        <TableCell width={8.5}>{sedolOut}</TableCell>
        <TableCell width={8.5}>{sedolIn}</TableCell>
        <TableCell width={72.5} className="text-truncate">{description}</TableCell>
        <TableCell align="right">
            <CorporateActionPairsGridRowActionCol 
                deleteFn={() => setPairToDeleteId(pairId)}
                editFn={() => setPairToEditId(pairId)}
            />
        </TableCell>
    </tr>

};

export default CorporateActionPairsGridRow;
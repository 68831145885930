import { useSendMasterAccountDashboardLoginPasswordResetEmailMutation } from "../../../services/clientauth";

const useCreatePasswordResetEmail = () => {
    const [_send, { isLoading, ...sendResult }] = useSendMasterAccountDashboardLoginPasswordResetEmailMutation();

    const send = (masterAccountId, loginId, emailAddress) => {
        return new Promise((resolve, reject) => {
            return _send({ masterAccountId, loginId, emailAddress })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { send, isLoading, data: { isLoading, ...sendResult } };
};

export default useCreatePasswordResetEmail;
import React, { useEffect } from "react";
import SummaryTable from "./SummaryTable";
import useReportsGroupedByAdviser from "../hooks/useReportsGroupedByAdviser";
import { useFilterContext } from "../../../hooks/FilterContext";

const ReportCountAdviserGroup = () => {
    const { filter } = useFilterContext();
    const { search, ...rest } = useReportsGroupedByAdviser(filter);
    const { data, error, isLoading, isFetching } = rest;
    
    useEffect(() => {
        const delayedRequest = setTimeout(() => search(filter), 500);
        return () => clearTimeout(delayedRequest);
    }, [ filter ]);

    return <SummaryTable label="Adviser" data={data} error={error} isLoading={isLoading === true || isFetching === true} />
};

export default ReportCountAdviserGroup;
import styled from "styled-components";

const FooterCenter = styled.div`
    grid-area: fmiddle;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: end;

    @media (max-width: 575.98px), (max-width: 767.98px) {
        align-items: unset;
        justify-content: unset;
    }
`;

export default FooterCenter;
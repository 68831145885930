import { applyPatch } from "fast-json-patch";
import { wealthProApi } from '../../redux/api';
import { useCacheKeyMergeHandler, useCacheKeySerializeQueryArgsHandler, useCacheKeyTransformResponseHandler } from "../../redux/helpers";
import fetchReportCacheSocketHandler from "./sockets/fetchReportCacheSocketHandler";

const buildFetchMasterAccountReportsQuery = ({ masterAccountId, searchText = "", sort = null, page = 1, limit = 25 }, list = false) => {
    let baseQuery = list === false
        ? `api/reports/master-accounts/${masterAccountId}/reports?page=${page}&limit=${limit}`
        : `api/reports/master-accounts/${masterAccountId}/reports/list?page=${page}&limit=${limit}`;

    if (sort && sort.length > 0) {
        baseQuery += baseQuery.includes('?') ? `&sort=${sort}` : `?sort=${sort}`;
    }

    if (searchText && searchText.length > 0) {
        var searchTermEncode = encodeURIComponent(searchText);
        baseQuery += `&searchTerm=${searchTermEncode}`;
    }

    return baseQuery;
};

const reportEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["clientReports"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        createReport: builder.mutation({
            query: ({ data, masterAccountId }) => ({
                url: `/api/reports/master-accounts/${masterAccountId}/reports`,
                method: 'POST',
                body: data
            })
        }),
        fetchMasterAccountReports: builder.query({
            query: (props) => buildFetchMasterAccountReportsQuery(props),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            onCacheEntryAdded: fetchReportCacheSocketHandler(true)
        }),
        fetchMasterAccountReportsList: builder.query({
            query: (props) => buildFetchMasterAccountReportsQuery(props, true),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            onCacheEntryAdded: fetchReportCacheSocketHandler(true)
        }),
        fetchReportAdviserGrouping: builder.query({
            query: (data) => ({
                url: `/api/reports/reports/groups/adviser`,
                method: 'POST',
                body: {
                    ...data,
                    authorAssignedStartDate: data.authorAssignedStartDate && typeof (data.authorAssignedStartDate) === 'number' ? new Date(data.authorAssignedStartDate) : data.authorAssignedStartDate
                }
            })
        }),
        fetchReportAuthorGrouping: builder.query({
            query: (data) => ({
                url: `/api/reports/reports/groups/author`,
                method: 'POST',
                body: data
            })
        }),
        getReportHistorySummary: builder.query({
            query: ({ masterAccountId, reportId }) => `/api/reports/master-accounts/${masterAccountId}/reports/${reportId}/history`
        }),
        searchMasterAccountReports: builder.query({
            query: ({ masterAccountId, ...data }) => ({
                url: `/api/reports/master-accounts/${masterAccountId}/reports`,
                method: 'POST',
                body: data
            })
        }),
        searchMasterAccountReportsList: builder.query({
            query: ({ masterAccountId, ...data }) => ({
                url: `/api/reports/master-accounts/${masterAccountId}/reports/list`,
                method: 'POST',
                body: data
            })
        }),
        searchReports: builder.query({
            query: (data) => ({
                url: `/api/reports/reports/search`,
                method: 'POST',
                body: data
            }),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            onCacheEntryAdded: fetchReportCacheSocketHandler(false)
        }),
        fetchReport: builder.query({
            query: ({ masterAccountId, reportId }) => `/api/reports/master-accounts/${masterAccountId}/reports/${reportId}`
        }),
        patchReport: builder.mutation({
            query: ({ masterAccountId, reportId, operations }) => ({
                url: `api/reports/master-accounts/${masterAccountId}/reports/${reportId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, reportId, projectId, operations }, { dispatch, queryFulfilled }) {
                const search = dispatch(wealthProApi.util.updateQueryData('searchReports', { }, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.id === reportId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));

                const maFetch = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountReports', { masterAccountId }, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.id === reportId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));

                const maFetchList = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountReportsList', { masterAccountId }, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.id === reportId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));

                const projectFetchList = dispatch(wealthProApi.util.updateQueryData('fetchProjectReports', { projectId }, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.id === reportId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));

                const individualFetch = dispatch(wealthProApi.util.updateQueryData('fetchReport', { masterAccountId, reportId }, (data) => {
                    applyPatch(data, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    search.undo();
                    maFetch.undo();
                    maFetchList.undo();
                    projectFetchList.undo();
                    individualFetch.undo();
                }
            }
        }),
        patchReportStatus: builder.mutation({
            query: ({ masterAccountId, reportId, ...body }) => ({
                url: `/api/reports/master-accounts/${masterAccountId}/reports/${reportId}/status`,
                method: 'PATCH',
                body
            }),
            async onQueryStarted({ masterAccountId, reportId, projectId, authorId, status }, { dispatch, queryFulfilled }) {
                try {
                    const search = dispatch(wealthProApi.util.updateQueryData('searchReports', {}, (data) => {
                        const { results } = data || { results: [] };
                        var pagedRecord = results.find(el => el.id === reportId);
                        if (pagedRecord) {
                            pagedRecord.status = status;
                            pagedRecord.authorId = authorId;
                        }
                    }));

                    const maFetch = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountReports', { masterAccountId }, (data) => {
                        const { results } = data || { results: [] };
                        var pagedRecord = results.find(el => el.id === reportId);
                        if (pagedRecord) {
                            pagedRecord.status = status;
                            pagedRecord.authorId = authorId;
                        }
                    }));

                    const maFetchList = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountReportsList', { masterAccountId }, (data) => {
                        const { results } = data || { results: [] };
                        var pagedRecord = results.find(el => el.id === reportId);
                        if (pagedRecord) {
                            pagedRecord.status = status;
                            pagedRecord.authorId = authorId;
                        }
                    }));

                    const projectFetchList = dispatch(wealthProApi.util.updateQueryData('fetchProjectReports', { projectId }, (data) => {
                        const { results } = data || { results: [] };
                        var pagedRecord = results.find(el => el.id === reportId);
                        if (pagedRecord) {
                            pagedRecord.status = status;
                            pagedRecord.authorId = authorId;
                        }
                    }));

                    const individualFetch = dispatch(wealthProApi.util.updateQueryData('fetchReport', { masterAccountId, reportId }, (data) => {
                        data.status = status;
                        data.authorId = authorId;
                    }));

                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.error(error);
                        search.undo();
                        maFetch.undo();
                        maFetchList.undo();
                        projectFetchList.undo();
                        individualFetch.undo();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        })
    })
});

export const {
    useCreateReportMutation,
    useFetchMasterAccountReportsQuery,
    useFetchMasterAccountReportsListQuery,
    useFetchReportAdviserGroupingQuery,
    useFetchReportAuthorGroupingQuery,
    useGetReportHistorySummaryQuery,
    useLazyGetReportHistorySummaryQuery,
    useLazyFetchMasterAccountReportsQuery,
    useLazyFetchMasterAccountReportsListQuery,
    useLazyFetchReportAdviserGroupingQuery,
    useLazyFetchReportAuthorGroupingQuery,
    useLazySearchMasterAccountReportsQuery,
    useLazySearchMasterAccountReportsListQuery,
    useLazySearchReportsQuery,
    useSearchMasterAccountReportsQuery,
    useSearchMasterAccountReportsListQuery,
    useSearchReportsQuery,
    useLazyFetchReportQuery,
    usePatchReportMutation,
    usePatchReportStatusMutation
} = reportEndpoints;
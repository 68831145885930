import React from "react";
import ReportCheckArray from "./ReportCheckArray";
import useReportProductAreas from "../hooks/useReportProductAreas";
import { ThemedParagraph } from "../../../components/utilities";

const ReportProductAreasList = ({ control, name }) => {
    const { data, error, isLoading, reload } = useReportProductAreas();

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error!</div>
    }

    return <React.Fragment>
        <ThemedParagraph variant="primary" className="mb-1">Select all Product Areas that apply :-</ThemedParagraph>
        <ReportCheckArray 
            name={name}
            control={control}
            data={data}
            emptyMessage="There are no Product Areas to choose from available."
        />
    </React.Fragment>
};

export default ReportProductAreasList;
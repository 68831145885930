import React from "react";
import ClientReminders from "../../features/clientreminders";
import { AccordionSection, Main } from "../../components/utilities";

const ClientSummaryPage = () => {
    return (
        <Main className="p-3">
            <AccordionSection label="Alerts" className="p-0">
                Widget to display the current "alerts" on an account. This includes adviser not assigned, service terms not agreed, portfolio issues, price issues and past deadlines for projects, reports and tasks
            </AccordionSection>
            <AccordionSection label="Portfolio Summary">
                Widget to display the core investment portoflio information broken down by the Investment Designation.
            </AccordionSection>
            <AccordionSection label="Tasks & Reminders">
                <ClientReminders />
            </AccordionSection>
            <AccordionSection label="Recent Activity">
                Widget that shows recent activity within the client. Recent reports, portfolio changes, new business sheets, etc.
            </AccordionSection>
        </Main>
    );
};

export default ClientSummaryPage;
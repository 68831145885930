import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { FormInput, FormLabel, PercentageInput } from "../../../components/forms";
import ErrorMessage from "../../../components/messages/ErrorMessage";
import ErrorMessageWrapper from "../../../components/messages/ErrorMessageWrapper";
import useFundDetails from "../hooks/useFundDetails";

const BenchmarkComparisonTable = () => {
    const [{ reloadAsset, reloadSector }, { assetGroup,
        assetData, assetLookupIsFetching, assetLookupIsError, assetLookupError,
        sectorData, sectorLookupIsFetching, sectorLookupIsError, sectorLookupError }] = useFundDetails();

    if (assetData == null) {
        return <p className="pt-5">Please enter a valid SEDOL/Citicode to lookup an Asset and compare performance.</p>
    }

    if (assetLookupIsError) {
        return <ErrorMessageWrapper>
            <ErrorMessage
                message={`Could not fetch asset data for ${assetGroup?.assetName ?? "this asset"}: ${assetLookupError}`}
                retryCallback={reloadAsset}
            />
        </ErrorMessageWrapper>
    }

    if (sectorLookupIsError) {
        return <ErrorMessageWrapper>
            <ErrorMessage
                message={`Could not fetch benchmark data for ${assetGroup?.sectorCodeOverride ?? "this sector/benchmark"}: ${sectorLookupError}`}
                retryCallback={reloadSector}
            />
        </ErrorMessageWrapper>
    }

    return <GridWrapper className="mt-4" gridTemplateColumns={"1fr repeat(6, 15ch)"}>
        {/* Labels */}
        <div className="col-start-1">
            <FormLabel>
                Measure
            </FormLabel>
        </div>
        <div className="col-start-2 text-center">
            <FormLabel>
                Volatility (3yr)
            </FormLabel>
        </div>
        <div className="col-start-3 text-center">
            <FormLabel>
                Max DD (3yr)
            </FormLabel>
        </div>
        <div className="col-start-4 text-center">
            <FormLabel>
                Max Gain (3yr)
            </FormLabel>
        </div>
        <div className="col-start-5 text-center">
            <FormLabel>
                Volatility (5yr)
            </FormLabel>
        </div>
        <div className="col-start-6 text-center">
            <FormLabel>
                Max DD (5yr)
            </FormLabel>
        </div>
        <div className="col-start-7 text-center">
            <FormLabel>
                Max Gain (5yr)
            </FormLabel>
        </div>

        {/* Fund Row */}
        <div className="col-start-1">
            {assetLookupIsFetching
                ? <Skeleton />
                : <FormInput
                    value={assetData?.assetName}
                    disabled
                />}
        </div>
        <div className="col-start-2">
            {assetLookupIsFetching
                ? <Skeleton />
                : <PercentageInput
                    // Volatility (3yr)
                    value={assetData?.volatility?.anvol3}
                    allowNegative
                    decimalScale={2}
                    fixedDecimalScale
                    disabled
                />
            }
        </div>
        <div className="col-start-3">
            {assetLookupIsFetching
                ? <Skeleton />
                : <PercentageInput
                    // Max DD (3yr)
                    value={assetData?.volatility?.maxdd3}
                    allowNegative
                    decimalScale={2}
                    fixedDecimalScale
                    disabled
                />}
        </div>
        <div className="col-start-4">
            {assetLookupIsFetching
                ? <Skeleton />
                : <PercentageInput
                    // Max Gain (3yr)
                    value={assetData?.volatility?.maxg3}
                    allowNegative
                    decimalScale={2}
                    fixedDecimalScale
                    disabled
                />}
        </div>
        <div className="col-start-5">
            {assetLookupIsFetching
                ? <Skeleton />
                : <PercentageInput
                    // Volatility (5yr)
                    value={assetData?.volatility?.anvol5}
                    allowNegative
                    decimalScale={2}
                    fixedDecimalScale
                    disabled
                />}
        </div>
        <div className="col-start-6">
            {assetLookupIsFetching
                ? <Skeleton />
                : <PercentageInput
                    // Max DD (5yr)
                    value={assetData?.volatility?.maxdd5}
                    allowNegative
                    decimalScale={2}
                    fixedDecimalScale
                    disabled
                />}
        </div>
        <div className="col-start-7">
            {assetLookupIsFetching
                ? <Skeleton />
                : <PercentageInput
                    // Max Gain (5yr)
                    value={assetData?.volatility?.maxg5}
                    allowNegative
                    decimalScale={2}
                    fixedDecimalScale
                    disabled
                />}
        </div>

        {/* Benchmark Row */}
        {assetGroup?.sectorCodeOverride == null
            ? <p className="col-start-1 col-end-7">
                Please select a sector code to compare the selected asset with the benchmark
            </p>
            : <>
                <div className="col-start-1">
                    {sectorLookupIsFetching
                        ? <Skeleton />
                        : <FormInput
                            value={sectorData?.sectorName ?? "Benchmark not found"}
                            disabled
                        />}
                </div>
                <div className="col-start-2">
                    {sectorLookupIsFetching
                        ? <Skeleton />
                        // Volatility (3yr)
                        : <PercentageInput
                            value={sectorData?.annualVolatility3Years}
                            allowNegative
                            decimalScale={2}
                            fixedDecimalScale
                            disabled
                        />}
                </div>
                <div className="col-start-3">
                    {sectorLookupIsFetching
                        ? <Skeleton />
                        : <PercentageInput
                            // Max DD (3yr)
                            value={sectorData?.maxDrawD3Years}
                            allowNegative
                            decimalScale={2}
                            fixedDecimalScale
                            disabled
                        />}
                </div>
                <div className="col-start-4">
                    {sectorLookupIsFetching
                        ? <Skeleton />
                        : <PercentageInput
                            // Max Gain (3yr)
                            value={sectorData?.maxGain3Years}
                            allowNegative
                            decimalScale={2}
                            fixedDecimalScale
                            disabled
                        />}
                </div>
                <div className="col-start-5">
                    {sectorLookupIsFetching
                        ? <Skeleton />
                        : <PercentageInput
                            // Volatility (5yr)
                            value={sectorData?.annualVolatility5Years}
                            allowNegative
                            decimalScale={2}
                            fixedDecimalScale
                            disabled
                        />}
                </div>
                <div className="col-start-6">
                    {sectorLookupIsFetching
                        ? <Skeleton />
                        : <PercentageInput
                            // Max DD (5yr)
                            value={sectorData?.maxDrawD5Years}
                            allowNegative
                            decimalScale={2}
                            fixedDecimalScale
                            disabled
                        />}
                </div>
                <div className="col-start-7">
                    {sectorLookupIsFetching
                        ? <Skeleton />
                        : <PercentageInput
                            // Max Gain (5yr)
                            value={sectorData?.maxGain5Years}
                            allowNegative
                            decimalScale={2}
                            fixedDecimalScale
                            disabled
                        />}
                </div>
            </>}
    </GridWrapper>
}

export default BenchmarkComparisonTable;
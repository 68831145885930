import React from "react"
import { Card } from "react-bootstrap"
import styled from "styled-components"
import GridWrapper from "../../../components/GridWrapper"
import { FormLabel, PercentageInput } from "../../../components/forms"
import useGeneralRules from "../hooks/useGeneralRules"

const CardHeader = styled(Card.Header)`
    background-color: ${props => props.theme.light};
    color: ${props => props.theme.secondary};

    padding: 1rem 1.2rem;
`;

const AllocationLabel = styled(FormLabel)`
    white-space: normal !important;
    margin: auto auto;
    padding: 0 0.5rem;
    text-align: right;
`

const AllocationRules = () => {
    const {
        generalRules,
        patchGeneralRules,
    } = useGeneralRules();

    return <Card>
        <CardHeader className="border-bottom">Allocation Allowances</CardHeader>
        <Card.Body>
            <div className="d-flex justify-content-evenly">
                <GridWrapper gridTemplateColumns={"auto 12ch"}>
                    <AllocationLabel>Maximum allocation allowed in a single fund: </AllocationLabel>
                    <span className="my-auto">
                        <PercentageInput
                            value={generalRules?.maxFundAllocation}
                            onBlur={(value) => patchGeneralRules("maxFundAllocation", value)}
                            decimalScale={1}
                            fixedDecimalScale
                        />
                    </span>
                </GridWrapper>

                <GridWrapper gridTemplateColumns={"auto 12ch"}>
                    <AllocationLabel>Maximum allocation allowed with a single fund manager: </AllocationLabel>
                    <span className="my-auto">
                        <PercentageInput
                            value={generalRules?.maxManagerAllocation}
                            onBlur={(value) => patchGeneralRules("maxManagerAllocation", value)}
                            decimalScale={1}
                            fixedDecimalScale
                        />
                    </span>
                </GridWrapper>
            </div>
        </Card.Body>
    </Card>
}

export default AllocationRules;
import { useGetServicePreferenceQuery, usePatchServicePreferenceMutation } from "../../../services/clientdetails";

const useServicePreferences = (masterAccountId) => {
    const { data, error, isError, isFetching, isLoading, refetch } = useGetServicePreferenceQuery({ masterAccountId });
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchServicePreferenceMutation();

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ masterAccountId });
    };

    const update = (property, value) => {
        return new Promise((resolve, reject) => {
            const { servicePreferenceId } = data || {};
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            return patch({ masterAccountId, servicePreferenceId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        data: data || null,
        error,
        isError,
        isErrorUpdating,
        isLoading: isFetching || isLoading,
        isUpdating,
        reload,
        updateError,
        update
    };
};

export default useServicePreferences;
import { useHoFetchOrganisationsQuery } from "../../../services/headofficeadmin";

const useOrganisations = () => {
    const {
        data: organisations,
        isLoading: isLoadingOrganisations,
        error: fetchOrganisationsIsError
    } = useHoFetchOrganisationsQuery({ fetchType: "list" });

    return {
        organisations,
        isLoadingOrganisations,
        fetchOrganisationsIsError
    }
}

export default useOrganisations;
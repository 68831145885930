import styled from "styled-components";

const Footer = styled.footer`
    grid-area: footer;
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-rows:     auto auto;
    grid-template-columns:  156px 1fr 156px;
    grid-template-areas:    "fleft fmiddle fright"
                            "fleft ftext fright";

    font-size: .7rem;
    position: relative;
    background-color: #fff;
    padding: .125rem .6rem;

    @media (max-width: 575.98px) {
        padding: .6rem;
        grid-template-rows:     1fr auto;
        grid-template-columns:  1fr 156px;
        grid-template-areas:    "fmiddle fright"
                                "ftext ftext";
    }

    @media (max-width: 767.98px) {
        padding: .6rem;
        grid-template-rows:     auto 1fr;
        grid-template-columns:  1fr 1fr auto;
        grid-template-areas:    "fmiddle fright"
                                "fmiddle fleft"
                                "ftext ftext";
    }
`;

export default Footer;
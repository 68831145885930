import React from "react";
import classNames from "classnames";
import useClientManagement from "../hooks/useClientManagement";
import { VirtualTable } from "../../../components/tables";
import { TableRecordCountDisplayFooter } from "../../../components/tables/VirtualTable";
import { ClientManagementRow } from "./ClientManagementRow";
import { ThemedTableHeader } from "../../../components/tables/StandardTable";

export const ClientManagementGrid = ({ searchTerm }) => {
    const {
        clear: refreshTable,
        results,
        renderCount,
        totalCount,
        columns,
        isLoading,
        error,
        isRowLoaded,
        loadMore
    } = useClientManagement({ searchTerm });

    return (
        <React.Fragment>
            <VirtualTable
                enableScrollTracking
                row={(props) => <ClientManagementRow columns={columns} isLoading={isLoading} {...props} />}
                header={(
                    <thead>
                        <tr>
                            {columns.map((col, key) => col.headerRenderer
                                ? col.headerRenderer({ ...col, key })
                                : <ThemedTableHeader key={key} className={classNames(col.headerClassName, "sticky-header")} style={{ width: col.width }}>
                                    {col.label}
                                </ThemedTableHeader>)}
                        </tr>
                    </thead>
                )}
                footer={
                    <TableRecordCountDisplayFooter 
                        totalCount={totalCount}
                        isNewRecordsDisplayed={false}
                        newRecordsSinceCache={0}
                        reload={refreshTable}
                    />
                }
                error={error}
                isLoading={isLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={46}
                itemCount={renderCount}
                itemData={results}
            />
        </React.Fragment>
    );
};
import React from "react";
import moment from "moment";
import Moment from "react-moment";
import toast from "react-hot-toast";
import classNames from "classnames";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import useClearSession from "../hooks/useClearSession";
import useClientDashboardLoginSessions from "../hooks/ClientDashboardLoginSessionsContext";
import useClientDashboardLoginDetails from "../hooks/ClientDashboardLoginDetailsContext";
import { confirmAlert } from "react-confirm-alert";
import { useTheme } from "styled-components";
import { Dropdown } from "react-bootstrap";
import { VariableSizeList } from "react-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "../../../components/messages";
import { LoadingMessage, Spinner } from "../../../components/loaders";
import { DropdownMenuItemIcon } from "../../../components/utilities";

const SessionListContext = React.createContext({
    className: null,
    reloadFn: null,
    error: null,
    isFetching: false,
    isLoading: false,
});

const SessionActionDropdownToggler = React.forwardRef(({ ...rest }, ref) => (
    <div ref={ref} {...rest}>
        <FontAwesomeIcon icon="fa-ellipsis" />
    </div>
));

const SessionActionDropdown = ({ data }) => {
    const menuId = React.useId();
    const theme = useTheme();
    const { setIsDirty } = useClientDashboardLoginDetails();
    const { clear: refreshTable } = useClientDashboardLoginSessions();
    const { deleteSession, isLoading } = useClearSession();

    const handleSignOut = () => {
        const { id, masterAccountId, masterAccountLoginId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Sign Out?"}
                    message="Signing out this session will log out any user who is actively using this session and they will be forced to re-login into the Client Dashboard. Are you sure you want to sign out?"
                    confirmButtonLabel="Sign Out"
                    confirmButtonVariant="danger"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(deleteSession(masterAccountId, masterAccountLoginId, id), {
                            loading: `Deleting session...`,
                            success: () => {
                                refreshTable();
                                setIsDirty(_ => true);
                                return `The dashboard login session has been deleted.`;
                            },
                            error: `Failed to deleted the dashboard login session.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    return (
        <Dropdown className="caret-off">
            <Dropdown.Toggle id={menuId} as={SessionActionDropdownToggler} />
            <Dropdown.Menu>
                <Dropdown.Item disabled={isLoading === true} onClick={handleSignOut}>
                    <DropdownMenuItemIcon icon="fa fa-sign-out-alt" size="sm" /> <span className="ms-1">Sign Out</span>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const SessionActiveDisplayPill = ({ expiryDate }) => {
    const type = React.useMemo(() => {
        if (moment().isAfter(moment.utc(expiryDate))) {
            return 1;
        }
        return 0;
    }, [ expiryDate ]);
    
    return (
        <div className={classNames('badge', {
            'bg-success': type === 0,
            'bg-danger': type === 1
        })} style={{ fontSize: '0.9em' }}>
            {{
                0: 'Active',
                1: 'Expired'
            }[type]}
        </div>
    );
};

const SessionTypeDisplayPill = ({ className, type }) => (
    <div className={classNames('badge bg-dark', className)} style={{ fontSize: '0.9em' }}>
        {{
            0: 'Account',
            1: 'JWT',
            2: 'Impersonated Session',
        }[type]}
    </div>
);

const SessionListDetail = ({ data, index, style, setRowHeight }) => {
    const recordRef = React.useRef();
    const isLoading = index >= data.length;

    React.useEffect(() => {
        if (recordRef.current) {
            setRowHeight(index, recordRef.current.clientheight);
        }
    }, [ recordRef, data ]);

    return (
        <SessionListDetailInner 
            ref={recordRef}
            data={isLoading ? null : data[index]}
            isLoading={isLoading}
            style={style}
        />
    );
};

const SessionListDetailInner = React.forwardRef(({ data, isLoading, style }, ref) => {
    const { height, ...rest } = style;
    
    if (isLoading) {
        return <div ref={ref} style={{ minHeight: height, ...rest }}>LOADING...</div>
    }

    const { sessionType, createdDate, expiryDate, lastAccessedDate, lastAccessedByIpAddress } = data;
    return (
        <div ref={ref} style={{ minHeight: height, ...rest }}>
            <div className="border p-3">
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <SessionTypeDisplayPill className="me-1" type={sessionType} />
                        <SessionActiveDisplayPill expiryDate={expiryDate} />
                    </div>
                    <div className="d-flex flex-row">
                        <div className="mx-1 cursor-pointer">
                            <SessionActionDropdown data={data} />
                        </div>
                    </div>
                </div>
                <div>
                    <span className="fw-bold">Session Started on: </span><Moment format="YYYY-MM-DD HH:mm">{createdDate}</Moment>
                </div>
                <div className="d-flex flex-row">
                    <div className="me-3">
                        <span className="fw-bold">Last Accessed: </span><Moment format="YYYY-MM-DD HH:mm">{lastAccessedDate}</Moment>
                    </div>
                    <div>
                        <span className="fw-bold">IP Address: </span>{!lastAccessedByIpAddress || lastAccessedByIpAddress.length <= 0 ? 'Unknown' : lastAccessedByIpAddress}
                    </div>
                </div>
            </div>
        </div>
    );
});

const SessionListInner = React.forwardRef(({ children, style }, ref) => {
    const { className, reloadFn, error, isFetching, isLoading } = React.useContext(SessionListContext);

    if (error) {
        const { message } = error || { message: 'Sorry! We were unable to complete your request.' }
        const { height } = style;
        return (
            <div ref={ref} className={classNames('chat-error-parent', className)} style={{ height }}>
                <ErrorMessage message={message} retryCallback={reloadFn} />
            </div>
        );
    }

    return (
        <div ref={ref} className={classNames(className)} style={style}>
            {/* {!isLoading && reloadFn && typeof(reloadFn) === 'function' && (
                <div className="chat-reloader" onClick={reloadFn}>
                    <FontAwesomeIcon icon="fas fa-sync-alt" />
                </div>
            )} */}
            {isLoading && (
                <LoadingMessage text="Please wait whilst WealthPro fetches the sessions relating to this account." />
            )}
            {!isLoading && (
                <React.Fragment>
                    {children}
                    {isFetching && (
                        <div className="chat-fetching">
                            <Spinner />
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
});

const SessionList = ({ data, recordHeight = 95, recordPadding = 30 }) => {
    const listRef = React.useRef();
    const rowHeightRef = React.useRef({});
    const { 
        clear: refreshTable,
        data: sessions,
        error,
        isLoading,
        isNextPageLoading,
        isRowLoaded,
        loadMore,
        totalCount
    } = useClientDashboardLoginSessions();

    const getRowHeight = (i) => {
        return (rowHeightRef.current[i] || (recordHeight + recordPadding));
    };

    const setRowHeight = (i, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeightRef.current = { ...rowHeightRef.current, [i]: size };
    }

    const DisplayRecord = React.useCallback((props) => <SessionListDetail setRowHeight={setRowHeight} {...props} />, []);

    if (!data) {
        return null;
    }

    return (
        <SessionListContext.Provider value={{
            className: null,
            error,
            reloadFn: refreshTable,
            isLoading,
            isFetching: isNextPageLoading
        }}>
            <AutoSizer>
                {({ height, width }) => (
                    <InfiniteLoader
                        isItemLoaded={isRowLoaded}
                        itemCount={totalCount}
                        loadMoreItems={loadMore}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VariableSizeList
                                ref={(e) => {
                                    listRef.current = e;
                                    ref(e);
                                }}
                                height={height}
                                width={width}
                                itemSize={getRowHeight}
                                itemCount={totalCount}
                                itemData={sessions}
                                innerElementType={SessionListInner}
                                onItemsRendered={onItemsRendered}
                            >
                                {DisplayRecord}
                            </VariableSizeList>
                        )}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </SessionListContext.Provider>
    );
};

export default SessionList;
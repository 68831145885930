import { EditableTable } from "../../../components/tables";
import { AccordionHeader, AccordionListItem } from "../../../components/utilities";
import useGeneralRules from "../hooks/useGeneralRules";
import TableAccordionBody from "./TableAccordionBody";

const ClassGroupTable = () => {
    const {
        generalRules,
        createClassGroupRule,
        isCreatingClassGroup,
        patchClassGroupRule,
        classGroupColumns
    } = useGeneralRules();

    return <AccordionListItem eventKey='class-groups'>
        <AccordionHeader>Class Group Rules</AccordionHeader>
        <TableAccordionBody>
            <EditableTable
                columns={classGroupColumns}
                data={generalRules?.classGroupRules ?? []}
                idProperty={"groupId"}
                createFn={createClassGroupRule}
                isCreating={isCreatingClassGroup}
                patchRowFn={patchClassGroupRule}
                typeDescription={"Class Groups"}
            />
        </TableAccordionBody>
    </AccordionListItem>
}

export default ClassGroupTable;
import React from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { FormSelect } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { patchReplace } from "../../../helpers/patchDoc";
import useProductSelection from "../hooks/useProductSelection";
import FillFromValuationsButton from "./FillFromValuationsButton";
import ProductCostsPopup from "./ProductCostsPopup";

const ProductSelection = ({
    productLabel = "Product:",
    designationLabel = "Designation:",
    providerLabel = "Provider Reference:",
    custodyLabel = "Custody Reference:",
    changeOwner,
    isInvest
}) => {
    const {
        instructionId,
        instruction,
        instructionSection,
        invest,
        divest,
        productOptions,
        isLoadingProductOptions,
        designationOptions,
        providerOptions,
        custodyOptions,
        isFetchingProviders,
        isUninitializedProviders,
        realTimeProductId,
        realTimeDesignationId,
        realTimeProviderRef,
        isLoading,
        isFetchingDesignations,
        isError,
        lastDesignationsArg,
        setRealTimeProductId,
        setRealTimeDesignationId,
        addCustomProvider,
        addCustomCustodian,
        patchService,
        onBlur,
        refetch
    } = useProductSelection({ isInvest, changeOwner });

    if (isError)
        return <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
            <ErrorMessage
                message="Sorry! We were unable to load the products for this instruction type. Please try again"
                retryCallback={refetch}
            />
        </ErrorMessageWrapper>

    return <GridWrapper gridTemplateColumns={"5fr 4fr repeat(2, 2fr) auto"}>
        <GridWrapper gridTemplateColumns={"1fr auto"} gridRowGap="0px">
            {isLoading
                ? <Skeleton />
                : <FormSelect
                    label={productLabel}
                    options={productOptions}
                    isLoadingOptions={isLoadingProductOptions}
                    isDisabled={isLoadingProductOptions}
                    defaultValue={instructionSection?.productId}
                    onChange={(selection) => setRealTimeProductId(selection.value)}
                    onBlur={(selection) =>
                        new Promise((resolve, reject) => {
                            let operations = [patchReplace("productId", selection.value)];

                            // If the product has changed, the new designations list has loaded, and the designation is invalid: reset the designationId
                            if (selection.value === lastDesignationsArg?.productId && !isFetchingDesignations) {
                                if (!designationOptions?.some(({ value }) => value === instructionSection?.designationId))
                                    operations.push(patchReplace("designationId", null));
                            }

                            return patchService(operations)
                                .then(resolve, reject);
                        })}
                />}

            {isLoading
                ? <></>
                : <ProductCostsPopup
                    className={"col-start-2 mt-auto"}
                    instructionId={instructionId}
                    selectedProductId={realTimeProductId}
                    hasProductCosts={(instruction?.productCosts?.length ?? 0) > 0}
                    productName={productOptions?.find(opt => opt.value === invest?.productId)?.label ?? "Selected product"}
                    section={(divest?.id && isInvest) ? "Out" : "In"}
                />}
        </GridWrapper>
        {isLoading
            ? <Skeleton />
            : <FormSelect
                label={designationLabel}
                options={designationOptions ?? []}
                defaultValue={instructionSection?.designationId}
                onChange={(designation) => setRealTimeDesignationId(designation.value)}
                onBlur={(designation) => onBlur("designationId", designation.value)}
                isLoadingOptions={isFetchingDesignations}
                isDisabled={realTimeProductId == null || isFetchingDesignations}
            />}

        {/* New way - FormSelect with options from fetching providers based on Product and Designation */}
        {isLoading
            ? <Skeleton />
            : <FormSelect
                label={providerLabel}
                options={providerOptions}
                defaultValue={instructionSection?.providerRef}
                onCreateOption={addCustomProvider}
                onBlur={(selection) => new Promise((resolve, reject) => {
                    // If custodyRef is still in the new custodian options list, don't update it
                    // If there is only one option in the new custodian options list, update custodyRef to that option
                    // If there are multiple (or 0) options in the new custodian options list, update custodyRef to null
                    if (custodyOptions.some(opt => opt.value === instructionSection?.custodyRef))
                        return onBlur("providerRef", selection.value.toUpperCase())
                            .then(resolve, reject);

                    if (custodyOptions.length === 1)
                        return patchService([patchReplace("providerRef", selection.value.toUpperCase()), patchReplace("custodyRef", custodyOptions[0].value.toUpperCase())])
                            .then(resolve, reject);

                    return patchService([patchReplace("providerRef", selection.value.toUpperCase()), patchReplace("custodyRef", selection.value.toUpperCase())])
                        .then(resolve, reject);
                })}
                isLoadingOptions={isFetchingProviders}
                isDisabled={realTimeProductId == null || realTimeDesignationId == null || isFetchingProviders || isUninitializedProviders}
                isCreateable
            />}

        {isLoading
            ? <Skeleton />
            : <FormSelect
                label={custodyLabel}
                options={custodyOptions}
                defaultValue={custodyOptions.length === 1
                    ? custodyOptions[0].value
                    : instructionSection?.custodyRef}
                onCreateOption={addCustomCustodian}
                onBlur={(selection) => patchService([patchReplace("custodyRef", selection.value), patchReplace("custodyId", selection.custodyId)])}
                isLoadingOptions={isFetchingProviders}
                isDisabled={realTimeProductId == null || realTimeDesignationId == null || isFetchingProviders || isUninitializedProviders || realTimeProviderRef == null}
                isCreateable
            />}

        {/* Old way - Free text input for provider and custody ref */}
        {/* {isLoading
            ? <Skeleton />
            : <FormInput
                label={providerLabel}
                // placeholder={"Unknown"}
                value={instructionSection?.providerRef}
                onChange={e => setRealTimeProviderRef(e.target.value)}
                onBlur={(currentValue) => {
                    if ([null, undefined, "", instructionSection?.providerRef].includes(instructionSection?.custodyRef))
                        return patchService([patchReplace("custodyRef", currentValue), patchReplace("providerRef", currentValue)]);

                    return onBlur("providerRef", currentValue);
                }}
            />}

        {isLoading
            ? <Skeleton />
            : <FormInput
                label={custodyLabel}
                // placeholder={"Unknown"}
                value={[null, undefined, "", instructionSection?.providerRef].includes(instructionSection?.custodyRef)
                    ? realTimeProviderRef
                    : instructionSection?.custodyRef}
                onBlur={(currentValue) => onBlur('custodyRef', currentValue === ""
                    ? realTimeProviderRef
                    : currentValue)}
            />} */}

        <span className="mt-auto">
            <FillFromValuationsButton
                forceInvisible={isInvest}
                selectedProductId={realTimeProductId}
                selectedDesignationId={realTimeDesignationId}
                providerRef={realTimeProviderRef}
                custodyRef={custodyOptions.length === 1
                    ? custodyOptions[0].value
                    : instructionSection?.custodyRef}
            />
        </span>

    </GridWrapper>;
}

export default ProductSelection;
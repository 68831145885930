import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const protectionSectionApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchProtectionSection: builder.mutation({
            query: ({ protectionId, operations }) => ({
                url: `api/factfind/protection/${protectionId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, operations }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchProtectionSection', { masterAccountId }, data => {
                        applyPatch(data, operations, true);
                    }));
                }
                catch { }
            }
        }),
        fetchPolicyCovers: builder.query({
            query: ({ protectionId, isCurrentPriority }) => `api/factfind/protection/${protectionId}/policies?isCurrentPriority=${isCurrentPriority}`
        })
    })
});

export const {
    usePatchProtectionSectionMutation,
    useFetchPolicyCoversQuery
} = protectionSectionApiEndpoints;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken } from "polished";
import styled from "styled-components";
import { calculateButtonColour } from "../../helpers/theme";

const ClickableIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: ${props => calculateButtonColour(props)};
    
    &:hover {
        color: ${props => darken(0.05, calculateButtonColour(props))};
    }

    &:active {
        color: ${props => darken(0.05, calculateButtonColour(props))};
    }

    &:focus {
        color: ${props => darken(0.05, calculateButtonColour(props))};
    }

    ${props => props.disabled && `
        opacity: 0.5;
        pointer-events: none !important;
        cursor: default !important;
    `}
`;

export default ClickableIcon;
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import {
    useFetchRemindersQuery,
    usePatchReminderMutation,
    useCreateReminderMutation,
    useDeleteReminderMutation,
} from "../../../services/headofficeadmin";

const useReminders = () => {
    const { applicationId } = useHeadOfficeApplicationContext();
    const {
        data: reminders,
        error: remindersError,
        isError: remindersIsError,
        isFetching: remindersIsFetching,
        isLoading: remindersIsLoading,
    } = useFetchRemindersQuery({ applicationId });

    const [
        patch,
        { error: updateError, isError: isErrorUpdating, isLoading: isUpdating },
    ] = usePatchReminderMutation();

    const [
        create,
        { error: createError, isError: isErrorCreating, isLoading: isCreating },
    ] = useCreateReminderMutation();

    const [
        remove,
        { error: removeError, isError: isErrorRemoving, isLoading: isRemoving },
    ] = useDeleteReminderMutation();

    const createReminder = (e) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }

        let reminder = {
            applicationId : applicationId,
            message: "",
            reminderDate: null,
            lastModifiedById: null,
            lastModifiedDate: null,
            isCompleted: false,
            completedDate: null,
            isActive: true
        };

        return new Promise((resolve, reject) => {
            return create({data: reminder })
                .unwrap()
                .then(
                    (r) => resolve(r),
                    (e) => reject(e)
                );
        });
    };

    const updateReminder = (reminderId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: "replace", path: `/${property}`, value: value },
            ];

            return patch({ reminderId, applicationId, operations })
                .unwrap()
                .then(
                    (r) => resolve(r),
                    (e) => reject(e)
                );
        });
    };

    const deleteReminder = (reminderId) => {
        return new Promise((resolve, reject) => {
            return remove({ reminderId, applicationId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };


    return {
        reminders: reminders || [],
        remindersError,
        remindersIsError,
        remindersIsFetching,
        remindersIsLoading,
        updateReminder,
        updateError,
        isErrorUpdating,
        isUpdating,
        createReminder,
        isCreating,
        createError,
        isErrorCreating,
        deleteReminder,
        isRemoving,
        removeError,
        isErrorRemoving
    };
};

export default useReminders;

import React from "react";
import { useLazySearchReportsQuery } from "../../../services/clientreports";
import { useFilterContext } from "../../../hooks/FilterContext";

const useReportManagementDetails = (initLimit = 50, initSort = "createdDate+desc") => {
    const { filter, isReady } = useFilterContext();
    
    // setup state and our query state (does not load by default (lazy))
    const [sort, setSort] = React.useState(initSort);
    const [limit, setLimit] = React.useState(initLimit);
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [load, { data, isLoading, isFetching, isError, ...loadResults }] = useLazySearchReportsQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results, newRecordsSinceCache } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isLoading === true;

    const clear = (newSort) => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading || !isReady) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        load({
            ...filter,
            cacheKey: newCacheKey,
            sort: newSort ?? sort,
            limit,
            page: 1,
        }).unwrap().then(_ => {
            setCacheKey(_ => newCacheKey)
            if (newSort) {
                setSort(_ => newSort);
            }
        });
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        // load the next page of results
        load({ cacheKey, sort, limit, page: page + 1, ...filter });
    };

    const sortTable = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";
        let [sortProperty, direction] = sort.split("+");

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    React.useEffect(clear, [ filter, sort, isReady ]);

    return {
        ...loadResults,
        clear,
        data: isError ? [] : results,
        hasMore,
        isFetching,
        isLoading,
        isNextPageLoading,
        isNewRecords: newRecordsSinceCache > 0,
        isRowLoaded,
        limit,
        loadMore: isNextPageLoading ? () => {} : loadMore,
        newRecordsSinceCache,
        page,
        pagination,
        sort,
        sortTable,
        totalCount,
        totalPages
    };
};

export default useReportManagementDetails;
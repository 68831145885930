import { applyPatch } from "fast-json-patch";
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react";
import "../../../services/recommendations";
import { useAddInvestTransactionMutation, useDeleteAllInvestTransactionsMutation, useLazyFetchDivestQuery, useLazyFetchDivestTransactionsQuery, useLazyFetchInstructionQuery, useLazyFetchInvestQuery, useLazyFetchInvestTransactionsQuery, useLazyFetchInvestmentCashArrayQuery, useLazyFetchPolicyQuery, usePatchDivestMutation, usePatchDivestTransactionMutation, usePatchInstructionMutation, usePatchInvestMutation, usePatchInvestTransactionMutation, usePatchInvestmentCashMutation, usePatchPolicyMutation, useRecsFetchPaymentMethodOptionsQuery, useRemoveInvestTransactionMutation } from "../../../services/recommendations";
import { useBizSheet } from "./BizSheetContext";

const InstructionContext = React.createContext();

const realTimeInstructionReducer = (state, action) => {
    switch (action.type) {
        case 'init':
            return action.value;
        case 'patch':
            const newInstruction = applyPatch({ ...state }, action.operations).newDocument;
            return newInstruction;
        case 'init-invest':
            return {
                ...state,
                invest: action.value
            };
        case 'patch-invest':
            const newInvest = applyPatch({ ...state.invest }, action.operations).newDocument;
            return {
                ...state,
                invest: newInvest
            };
        case 'init-divest':
            return {
                ...state,
                divest: action.value
            };
        case 'patch-divest':
            const newDivest = applyPatch({ ...state.divest }, action.operations).newDocument;
            return {
                ...state,
                divest: newDivest
            };
        case 'init-investment-cash':
            return {
                ...state,
                invest: {
                    ...state.invest,
                    investmentCash: action.value
                }
            };
        case 'add-investment-cash':
            return {
                ...state,
                invest: {
                    ...state.invest,
                    investmentCash: [
                        ...state.invest.investmentCash,
                        action.value
                    ]
                }
            };
        case 'patch-investment-cash':
            const newInvestmentCash = applyPatch({ ...state.invest.investmentCash[action.index] }, action.operations).newDocument;
            return {
                ...state,
                invest: {
                    ...state.invest,
                    investmentCash: [
                        ...state.invest.investmentCash.slice(0, action.index),
                        newInvestmentCash,
                        ...state.invest.investmentCash.slice(action.index + 1)
                    ]
                }
            };
        case 'delete-investment-cash':
            return {
                ...state,
                invest: {
                    ...state.invest,
                    investmentCash: [
                        ...state.invest.investmentCash.slice(0, action.index),
                        ...state.invest.investmentCash.slice(action.index + 1)
                    ]
                }
            };
        case 'init-policy':
            return {
                ...state,
                policy: action.value
            };
        case 'patch-policy':
            const newPolicy = applyPatch({ ...state.policy }, action.operations).newDocument;

            return {
                ...state,
                policy: newPolicy
            };
        default:
            return state;
    }
}

export const useInstruction = () => {
    return useContext(InstructionContext);
}

export const InstructionProvider = ({ instructionId, children }) => {

    const [{ deleteInstruction }, { sheet }, { setSheetHasErrors }] = useBizSheet();
    const deleteSelf = useCallback(() =>
        deleteInstruction({ instructionId, sheetId: sheet?.id }),
        [deleteInstruction, instructionId, sheet?.id]);

    const [fetchInstruction, { data: instruction, error: instructionError, isError: instructionIsError, isLoading: instructionIsLoading, isUninitialized: instructionIsUninitialised, isFetching: instructionIsFetching }] = useLazyFetchInstructionQuery();
    const [patchInstructionTrigger, { error: patchInstructionError, isError: patchInstructionIsError, isLoading: patchInstructionIsLoading, isUninitialized: patchInstructionIsUninitialised }] = usePatchInstructionMutation();

    const patchInstruction = useCallback((request) => {
        setSheetHasErrors(null);
        return patchInstructionTrigger(request);
    }, [patchInstructionTrigger, setSheetHasErrors]);

    const [realTimeInstruction, dispatch] = useReducer(realTimeInstructionReducer, instruction);
    const realTimePatchInstruction = useCallback((operations) => {
        dispatch({ type: 'patch', operations });
    }, []);

    const [fetchInvest, { data: invest, error: investError, isError: investIsError, isLoading: investIsLoading, isFetching: investIsFetching, isUninitialized: investIsUninitialised }] = useLazyFetchInvestQuery();
    const [patchInvestTrigger, { error: patchInvestError, isError: patchInvestIsError, isLoading: patchInvestIsLoading, isUninitialized: patchInvestIsUninitialised }] = usePatchInvestMutation();

    const fetchInvestAndInit = useCallback(() => {
        return new Promise((resolve, reject) => {
            fetchInvest({ instructionId })
                .unwrap()
                .then(res => {
                    dispatch({ type: 'init-invest', value: res });
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }, [fetchInvest, instructionId]);

    const realTimePatchInvest = useCallback((operations) => {
        dispatch({ type: 'patch-invest', operations });
    }, []);

    const patchInvest = useCallback((request) => {
        setSheetHasErrors(null);
        return patchInvestTrigger(request);
    }, [patchInvestTrigger, setSheetHasErrors]);

    const [createInvestTransaction, { isLoading: isCreatingInvestTransaction, isError: errorCreatingInvestTransaction }] = useAddInvestTransactionMutation();
    const [fetchInvestTransactions, { data: investTransactions, error: investTransactionsError, isError: investTransactionsIsError, isLoading: investTransactionsIsLoading, isFetching: investTransactionsIsFetching, isUninitialized: investTransactionsIsUninitialised }] = useLazyFetchInvestTransactionsQuery();
    const [patchInvestTransactionTrigger] = usePatchInvestTransactionMutation();

    const [deleteInvestTransaction, { isLoading: isDeletingInvestTransaction, isError: errorDeletingInvestTransaction }] = useRemoveInvestTransactionMutation();
    const [deleteAllTransactions] = useDeleteAllInvestTransactionsMutation();

    const patchInvestTransaction = useCallback((request) => {
        setSheetHasErrors(null);
        return patchInvestTransactionTrigger(request);
    }, [patchInvestTransactionTrigger, setSheetHasErrors]);

    const [fetchInvestmentCash, { data: investmentCash, error: investmentCashError, isError: investmentCashIsError, isLoading: investmentCashIsLoading }] = useLazyFetchInvestmentCashArrayQuery();
    const [patchInvestmentCashTrigger] = usePatchInvestmentCashMutation();

    const patchInvestmentCash = useCallback((request) => {
        setSheetHasErrors(null);
        return patchInvestmentCashTrigger(request);
    }, [patchInvestmentCashTrigger, setSheetHasErrors]);

    const fetchInvestmentCashAndInit = useCallback(({ investId }) => {
        return new Promise((resolve, reject) => {
            fetchInvestmentCash({ investId })
                .unwrap()
                .then(res => {
                    dispatch({ type: 'init-investment-cash', value: res });
                    resolve(res);
                })
                .catch(err => reject(err));
        });

    }, [fetchInvestmentCash]);

    const realTimeAddCash = useCallback((value) => {
        dispatch({ type: 'add-investment-cash', value });
    }, []);

    const realTimePatchCash = useCallback((index, operations) => {
        dispatch({ type: 'patch-investment-cash', index, operations });
    }, []);

    const realTimeDeleteCash = useCallback((index) => {
        dispatch({ type: 'delete-investment-cash', index });
    }, []);

    const [fetchDivest, { data: divest, error: divestError, isError: divestIsError, isLoading: divestIsLoading, isFetching: divestIsFetching, isUninitialized: divestIsUninitialised }] = useLazyFetchDivestQuery();
    const [patchDivestTrigger, { error: patchDivestError, isError: patchDivestIsError, isLoading: patchDivestIsLoading, isUninitialized: patchDivestIsUninitialised }] = usePatchDivestMutation();

    const fetchDivestAndInit = useCallback(() => {
        return new Promise((resolve, reject) => {
            fetchDivest({ instructionId })
                .unwrap()
                .then(res => {
                    dispatch({ type: 'init-divest', value: res });
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }, [fetchDivest, instructionId]);

    const realTimePatchDivest = useCallback((operations) => {
        dispatch({ type: 'patch-divest', operations });
    }, []);

    const patchDivest = useCallback((request) => {
        setSheetHasErrors(null);
        return patchDivestTrigger(request);
    }, [patchDivestTrigger, setSheetHasErrors]);

    const [fetchDivestTransactions, { data: divestTransactions, error: divestTransactionsError, isError: divestTransactionsIsError, isLoading: divestTransactionsIsLoading, isFetching: divestTransactionsIsFetching, isUninitialized: divestTransactionsIsUninitialised }] = useLazyFetchDivestTransactionsQuery();
    const [patchDivestTransactionTrigger] = usePatchDivestTransactionMutation();

    const patchDivestTransaction = useCallback((request) => {
        setSheetHasErrors(null);
        return patchDivestTransactionTrigger(request);
    }, [patchDivestTransactionTrigger, setSheetHasErrors]);

    const [fetchPolicy, { data: policy, error: policyError, isError: policyIsError, isLoading: policyIsLoading, isFetching: policyIsFetching, isUninitialized: policyIsUninitialised }] = useLazyFetchPolicyQuery();
    const [patchPolicyTrigger] = usePatchPolicyMutation();

    const fetchPolicyAndInit = useCallback(() => {
        return new Promise((resolve, reject) => {
            fetchPolicy({ instructionId })
                .unwrap()
                .then(res => {
                    dispatch({ type: 'init-policy', value: res });
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }, [fetchPolicy, instructionId]);

    const realTimePatchPolicy = useCallback((operations) => {
        dispatch({ type: 'patch-policy', operations });
    }, []);

    const patchPolicy = useCallback((request) => {
        setSheetHasErrors(null);
        return patchPolicyTrigger({ ...request, instructionId });
    }, [instructionId, patchPolicyTrigger, setSheetHasErrors]);

    const hasRingFenceTransaction = useMemo(() => {
        return realTimeInstruction?.divest?.totalRingFenceAmount !== 0;
    }, [realTimeInstruction?.divest?.totalRingFenceAmount]);

    const anythingIsLoading = useMemo(() =>
        instructionIsLoading || investIsLoading || investTransactionsIsLoading || investmentCashIsLoading || divestIsLoading || divestTransactionsIsLoading || policyIsLoading,
        [divestIsLoading, divestTransactionsIsLoading, instructionIsLoading, investIsLoading, investTransactionsIsLoading, investmentCashIsLoading, policyIsLoading]);

    const fetchUntilFound = useCallback(() => {
        fetchInstruction({ instructionId })
            .unwrap()
            .then(res => {
                if (res == null)
                    fetchUntilFound();
                else
                    dispatch({ type: 'init', value: res })
            });
    }, [fetchInstruction, instructionId]);

    const retry = useCallback(() => {
        fetchInstruction({ instructionId })
            .unwrap()
            .then(res => dispatch({ type: 'init', value: res }));
    }, [fetchInstruction, instructionId]);

    // Need to keep fetching after an instruction is added to return the complete data
    useEffect(() => {
        fetchUntilFound();
    }, [fetchUntilFound])

    const currentDate = useMemo(() => new Date(), []);
    const currentYear = useMemo(() => currentDate.getFullYear(), [currentDate]);
    const taxYearDeadline = useMemo(() => new Date(currentYear, 4, 5), [currentYear]);

    const taxYears = useMemo(() => (currentDate > taxYearDeadline ?
        [`${currentYear}/${currentYear + 1}`, `${currentYear + 1}/${currentYear + 2}`] :
        [`${currentYear - 1}/${currentYear}`, `${currentYear}/${currentYear + 1}`]).map(year => ({
            value: parseInt(year.slice(0, year.indexOf('/'))),
            label: year
        })), [currentDate, currentYear, taxYearDeadline]);

    // Only show Tax Reclaim if the Product is a Pension type
    const showTaxReclaim = useMemo(() =>
        realTimeInstruction?.invest?.product?.productWrapper?.productCategory === 30,
        [realTimeInstruction?.invest?.product]);

    const {
        data: paymentMethodOptions,
        isSuccess: paymentMethodOptionsIsSuccess,
        isLoading: paymentMethodOptionsIsLoading,
        isFetching: paymentMethodOptionsIsFetching,
        isError: paymentMethodOptionsIsError,
        refetch: refetchPaymentMethodOptions
    } = useRecsFetchPaymentMethodOptionsQuery({ listType: "select" });

    const {
        data: paymentMethodObjects,
        isSuccess: paymentMethodObjectsIsSuccess,
        isLoading: paymentMethodObjectsIsLoading,
        isFetching: paymentMethodObjectsIsFetching,
        isError: paymentMethodObjectsIsError,
        refetch: refetchPaymentMethodObjects
    } = useRecsFetchPaymentMethodOptionsQuery({ listType: "list" });

    const incomeFrequencyOptions = useMemo(() => [
        { label: "One-Off", value: 4, multiplier: 1 },
        { label: "Monthly", value: 0, multiplier: 12 },
        { label: "Quarterly", value: 1, multiplier: 4 },
        { label: "Annual", value: 2, multiplier: 1 },
        { label: "Biannual", value: 3, multiplier: 2 },
    ], []);

    // InvestmentAmount calculation based on Income section
    const calculateIncomeTotal = useCallback((withdrawalAmount, incomeAmount, incomeFrequency, taxFreeCashAmount, toCashAccount, retainAmount) =>
        Math.round((withdrawalAmount ?? 0)
        + (incomeAmount ?? 0) * (incomeFrequencyOptions?.find(option => option.value === incomeFrequency)?.multiplier ?? 0)
        + (taxFreeCashAmount ?? 0)
        + (toCashAccount ?? 0)
        + (retainAmount ?? 0), 2),
        [incomeFrequencyOptions])

    const realTimeIncomeTotal = useMemo(() =>
        calculateIncomeTotal(realTimeInstruction?.invest?.clientWithdrawalAmount, realTimeInstruction?.invest?.clientIncomeAmount, realTimeInstruction?.invest?.clientIncomeFrequency, realTimeInstruction?.invest?.taxFreeCashAmount, realTimeInstruction?.invest?.toCashAccount, realTimeInstruction?.invest?.retainAmount),
        [calculateIncomeTotal, realTimeInstruction?.invest?.clientIncomeAmount, realTimeInstruction?.invest?.clientIncomeFrequency, realTimeInstruction?.invest?.clientWithdrawalAmount, realTimeInstruction?.invest?.retainAmount, realTimeInstruction?.invest?.taxFreeCashAmount, realTimeInstruction?.invest?.toCashAccount]);

    const realTimeRemainder = useMemo(() =>
        Math.max((realTimeInstruction?.divest?.totalSaleProceeds ?? 0) - realTimeIncomeTotal, 0),
        [realTimeIncomeTotal, realTimeInstruction?.divest?.totalSaleProceeds]);

    const excludeRingFence = useMemo(() =>
        instruction?.instructionType?.instructionType === 4, // ISA Transfer 
        [instruction?.instructionType?.instructionType]);

    const instructionRef = useRef(null);

    // Context returns a list in the form [ triggers, objects, utilities ] 
    return <InstructionContext.Provider value={
        [
            {
                deleteSelf,
                retry,
                patchInstruction,
                realTimePatchInstruction,
                fetchInvest: fetchInvestAndInit,
                patchInvest,
                realTimePatchInvest,
                createInvestTransaction,
                fetchInvestTransactions,
                patchInvestTransaction,
                deleteInvestTransaction,
                deleteAllTransactions,
                fetchInvestmentCash: fetchInvestmentCashAndInit,
                patchInvestmentCash,
                realTimeAddCash,
                realTimePatchCash,
                realTimeDeleteCash,
                fetchDivest: fetchDivestAndInit,
                patchDivest,
                realTimePatchDivest,
                fetchDivestTransactions,
                patchDivestTransaction,
                fetchPolicy: fetchPolicyAndInit,
                patchPolicy,
                realTimePatchPolicy
            },
            {
                instructionId,
                realTimeInstruction,
                instruction,
                instructionRef,
                instructionIsError,
                instructionError,
                instructionIsLoading,
                instructionIsFetching,
                instructionIsUninitialised,
                patchInstructionError,
                patchInstructionIsError,
                patchInstructionIsLoading,
                patchInstructionIsUninitialised,
                invest,
                investIsError,
                investError,
                investIsLoading,
                investIsFetching,
                investIsUninitialised,
                investTransactions,
                investTransactionsIsError,
                investTransactionsError,
                investTransactionsIsLoading,
                investTransactionsIsFetching,
                investTransactionsIsUninitialised,
                isCreatingInvestTransaction,
                errorCreatingInvestTransaction,
                isDeletingInvestTransaction,
                errorDeletingInvestTransaction,
                patchInvestError,
                patchInvestIsError,
                patchInvestIsLoading,
                patchInvestIsUninitialised,
                investmentCash,
                investmentCashIsError,
                investmentCashError,
                investmentCashIsLoading,
                divest,
                divestIsError,
                divestError,
                divestIsLoading,
                divestIsFetching,
                divestIsUninitialised,
                divestTransactions,
                divestTransactionsIsError,
                divestTransactionsError,
                divestTransactionsIsLoading,
                divestTransactionsIsFetching,
                divestTransactionsIsUninitialised,
                patchDivestError,
                patchDivestIsError,
                patchDivestIsLoading,
                patchDivestIsUninitialised,
                policy,
                policyIsError,
                policyError,
                policyIsLoading,
                policyIsFetching,
                policyIsUninitialised,
                anythingIsLoading,
                paymentMethodOptions,
                paymentMethodOptionsIsSuccess,
                paymentMethodOptionsIsLoading,
                paymentMethodOptionsIsFetching,
                paymentMethodOptionsIsError,
                refetchPaymentMethodOptions,
                paymentMethodObjects,
                paymentMethodObjectsIsSuccess,
                paymentMethodObjectsIsLoading,
                paymentMethodObjectsIsFetching,
                paymentMethodObjectsIsError,
                refetchPaymentMethodObjects,
            },
            {
                incomeFrequencyOptions,
                calculateIncomeTotal,
                realTimeIncomeTotal,
                realTimeRemainder,
                taxYears,
                showTaxReclaim,
                excludeRingFence,
                hasRingFenceTransaction
            }
        ]}>
        {children}
    </InstructionContext.Provider>
}
import _ from "lodash";
import React, { useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "styled-components";
import { IconButton } from "../../../components/buttons";
import { FormInput, FormSelect } from "../../../components/forms";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { StandardTable } from "../../../components/tables";
import useClientAccountDetails from "../hooks/useClientAccountDetails";
import useDesignationDetails from "../hooks/useDesignationDetails";
import BasicFetchError from "./BasicFetchError";
import DetailsWrapper from "./DetailsWrapper";

const DesignationDetailsTableEmptyRow = ({ columns, onCreateClicked, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            There are no investment designations available.
            You can click <a href="#" onClick={onCreateClicked}>here</a> to create one.
        </td>
    </tr>
};

const DesignationDetailsTableLoadingRow = ({ columns, loadingLines, ...rest }) => {
    return [...Array(loadingLines)].map((_, i) => (
        <tr key={`loading-${i}`} {...rest}>
            <td colSpan={columns.length}>
                <Skeleton count={1} height={37} />
            </td>
        </tr>
    ));
};

const DesignationDetailsTableRow = ({ allowDelete = true, clientAccounts, columns, data, onDeleteClicked, onDesignationMappingsChanged, onFieldChanged, ...rest }) => {
    const { clientMappings, designation, designationId, type } = data;
    const clientAccountList = useMemo(() => {
        if (!clientAccounts || !Array.isArray(clientAccounts)) {
            return [];
        } 

        return clientAccounts.map((el) => {
            let name = `${el.title} ${el.forenames} ${el.surname}`;
            return { label: name.trim(), value: el.clientAccountId };
        });
    }, [ clientAccounts ]);
    
    return <tr>
        <td className="col-2">
			<FormSelect
				defaultValue={type}
				options={[
					{ label: 'Jointly Held', value: 0 },
					{ label: 'Individual', value: 1 },
					{ label: 'Dependent', value: 2 },
					{ label: 'Child', value: 3 },
					{ label: 'Grandchild', value: 4 },
					{ label: 'Investment', value: 5 },
					{ label: 'Property', value: 6 },
                    { label: 'Assets in Trust', value: 7 },
                    { label: 'Corporate', value: 8 },
                    { label: 'Other', value: 9 },
				]}
				onBlur={(values) => onFieldChanged(designationId, 'type', values.value)}
			/>
		</td>
		<td className="col">
			<FormInput
				value={designation}
				placeholder="Designation name"
				onBlur={(value) => onFieldChanged(designationId, 'designation', value)}
			/>
		</td>
		<td className="col">
			<FormSelect 
				placeholder="Owners"
				isMulti={true}
				options={clientAccountList}
				defaultValue={Array.isArray(clientMappings) ? clientMappings.map((el) => el.clientAccountId) : []}
				onBlur={(values) => onDesignationMappingsChanged(designationId, values)}
			/>
		</td>
        {allowDelete === true && (
            <td className="col-md-auto">
                <IconButton icon="fa-trash-can" variant="danger" onClick={() => onDeleteClicked(data)} disabled={!_.isEmpty(clientMappings)} />
            </td>
        )}
    </tr>
};

const getDeleteWarningMessage = (designation) => {
    if (!designation) {
        return `Are you sure you want to delete this designation?`;
    }

    return `Are you sure you want to delete the designation "${designation}"?`;
};

const DesignationDetails = ({ masterAccountId, loadingLines = 2 }) => {
    const { data: clientAccounts } = useClientAccountDetails(masterAccountId);
    const { 
        createDesignation,
        data,
        deleteDesignation,
        error,
        isCreating,
        isError, 
        isLoading,
        reload,
        updateDesignation,
        updateDesignationMappings
    } = useDesignationDetails(masterAccountId);
    const theme = useTheme();

    const onCreateDesignationPressed = (e) => createDesignation(e);

    const onDeleteDesignationPressed = (designation) => {
        const { designationId, designation: designationName } = designation;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal
                    theme={theme}
                    title="Delete Designation"
                    message={getDeleteWarningMessage(designationName)}
                    handleConfirm={() => deleteDesignation(designationId).finally(onClose)}
                    handleClose={onClose}
                />
            }
        });
    }

    const onDesignationMappingsChanged = (designationId, newMappings) => updateDesignationMappings(designationId, newMappings);

    const onFieldChanged = (designationId, property, value) => updateDesignation(designationId, property, value);

    if (error || isError === true) {
        return <BasicFetchError error={error} retryCallback={reload} />
    }

    return <DetailsWrapper label="Investment Designations" collapsedByDefault={true}>
        <StandardTable 
            className="table-borderless"
            columns={[
                { label: 'Type', property: 'typeDescription' },
                { label: 'Designation', property: 'designation' },
                { label: 'Owner(s)', property: 'clientMappings' },
                { 
                    label: '', 
                    property: '',
                    headerRenderer: (props) => (
                        <td key={props.key} style={{ width: '32px' }}>
                            <IconButton icon="fa-plus" variant="success" onClick={onCreateDesignationPressed} />
                        </td>
                    )
                }
            ]}
            data={data}
            error={error}
            isCreating={isCreating}
            isLoading={isLoading}
            EmptyRowComponent={DesignationDetailsTableEmptyRow}
            emptyRowComponentProps={{ onCreateClicked: onCreateDesignationPressed }}
            hideHeaderOnEmpty={true}
            LoadingRowComponent={DesignationDetailsTableLoadingRow}
            loadingRowComponentProps={{ loadingLines }}
            RowComponent={DesignationDetailsTableRow}
            rowComponentProps={{ 
                allowDelete: true, 
                clientAccounts, 
                onDeleteClicked: onDeleteDesignationPressed,
                onDesignationMappingsChanged,
                onFieldChanged 
            }}
        />
    </DetailsWrapper>
};

export default DesignationDetails;
import { useMemo } from "react";
import { IconButton } from "../../../components/buttons";
import { ThemedSpan } from "../../../components/utilities";
import ShareClassSedolSearch from "./ShareClassSedolSearch";

const ShareClassRow = ({ shareClass, index, canEdit, updateMapping, deleteRow }) => {

    const createdDate = useMemo(() => new Date(shareClass?.createdDate),
        [shareClass?.createdDate]);

    const createdDateString = useMemo(() =>
        shareClass?.createdDate == null
            ? ""
            : `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
        [createdDate, shareClass?.createdDate]);

    return <>
        <ShareClassSedolSearch row={shareClass} index={index} assetGroupId={shareClass?.assetGroupId} afterSearch={updateMapping} />
        <ThemedSpan variant="muted" className="ms-1 my-auto">{shareClass?.createdBy?.forenames} {shareClass?.createdBy?.surname}</ThemedSpan>
        <ThemedSpan variant="muted" className="ms-1 my-auto">{createdDateString}</ThemedSpan>
        <IconButton
            className={!canEdit ? "invisible" : ""}
            icon="trash"
            variant="danger"
            disabled={shareClass?.isPrimaryMap}
            onClick={deleteRow}
        />
    </>
}

export default ShareClassRow;
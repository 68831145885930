import React from "react";
import toast from "react-hot-toast";
import classNames from "classnames";
import styled from "styled-components";
import ClientAccessBlockedDisplayColumn from "./ClientAccessBlockedDisplayColumn";
import { DateDisplay,  ProfileDisplay } from "../../../components";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClientAccessTableData } from "../hooks/ClientAccessTableDataContext";

const Column = styled.td`
    padding: 0.6rem !important;
    vertical-align: middle;
`; 

const ActionColumnToggle = React.forwardRef(({ className, ...rest }, ref) => {
    return (
        <div ref={ref} className={classNames('d-flex justify-content-center', className)} {...rest}>
            <FontAwesomeIcon icon="fa-ellipsis" />
        </div>
    );
});

const ClientAccessTableRow = ({ data, index, style, dateFormat = "DD/MM/YYYY" }) => {
    const menuId = React.useId();
    const { height } = style;
    const { deleteAccess, updateAccess } = useClientAccessTableData();

    if (index >= data.length) {
		return <TableLoadingRow style={{ height }} />
	}

    const {
        id,
        userId,
        isAccessBlocked,
        createdById,
        createdDate
    } = data[index];

    const onDeleteAccess = () => {
        toast.promise(deleteAccess(id, isAccessBlocked), {
            loading: 'Deleting Access Record...',
            success: (_) => `Access Record has been removed.`,
            error: (err) => <div>{err?.data?.message ?? err?.message ?? "An unknown error has occured."}</div>
        });
    };

    const onUpdateAccess = (isAccessBlocked) => {
        toast.promise(updateAccess(id, isAccessBlocked), {
            loading: 'Updating Access Record...',
            success: (_) => `Access Record has been updated.`,
            error: (err) => <div>{err?.data?.message ?? err?.message ?? "An unknown error has occured."}</div>
        });
    };

    return (
        <tr>
            <Column style={{ height }}>
                <ProfileDisplay userId={userId} />
            </Column>
            <Column>
                <ClientAccessBlockedDisplayColumn isAccessBlocked={isAccessBlocked} />
            </Column>
            <Column>
                <DateDisplay format={dateFormat}>{createdDate}</DateDisplay>
            </Column>
            <Column>
                {createdById ? (<ProfileDisplay userId={createdById} />) : (<span>System</span>)}
            </Column>
            <Column className="cursor-pointer">
                <Dropdown className="caret-off">
                    <Dropdown.Toggle id={menuId} as={ActionColumnToggle} />
                    <Dropdown.Menu>
                        {isAccessBlocked && (
                            <Dropdown.Item onClick={() => onUpdateAccess(false)}>
                                <FontAwesomeIcon icon="fas fa-unlock" size="sm" /> <span className="ms-1">Grant Access</span>
                            </Dropdown.Item>
                        )}
                        {isAccessBlocked === false && (
                            <Dropdown.Item onClick={() => onUpdateAccess(true)}>
                                <FontAwesomeIcon icon="fas fa-user-lock" size="sm" /> <span className="ms-1">Revoke Access</span>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => onDeleteAccess()}>
                            <FontAwesomeIcon icon="fas fa-trash-alt" size="sm" /> <span className="ms-1">Delete Access</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Column>
        </tr>
    );
};

export default ClientAccessTableRow;
import styled from "styled-components";

const LinksContainer = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-around;
    padding: 1rem .5rem;

    & > div > a,
    & > div > span {
        cursor: pointer;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.55);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    }

    & > div > a:hover,
    & > div > a:focus,
    & > div > span:hover {
        color: rgba(0, 0, 0, 0.7);
    }
`;

export default LinksContainer;
import { useClientContext } from '../../../hooks/ClientContext';
import { useFetchLoansAndLiabilitiesSectionQuery} from "../../../services/clientfactfind";

const useLoansLiabilitiesSection = () => {
    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isError, error, isFetching, isUninitialized, refetch } = useFetchLoansAndLiabilitiesSectionQuery({ masterAccountId });

    return {
        data,
        isLoading: isLoading || isFetching || isUninitialized,
        isError,
        error,
        refetchLoansAndLiabilitiesSection: refetch
    };
}

export default useLoansLiabilitiesSection;
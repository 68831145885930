import { Modal } from "react-bootstrap";
import { ThemedSpan } from "../../../components/utilities";
import { Button } from "../../../components";
import toast from "react-hot-toast";

const RecalculateCashRaisedModal = ({ show, onSubmit, onClose, isRecalculating }) => {

    const handleSubmit = (e) => {
        toast.promise(onSubmit(), {
            loading: "Recalculating cash raised...",
            error: (err) => err?.data?.message ?? "Error recalculating cash raised.",
            success: "Cash raised recalculated!"
        }).then(
            r => {
                onClose(e)
            }
        );
    };

    return <Modal centered show={show} onHide={onClose}>
        <Modal.Header closeButton>Recalculate Cash Balance</Modal.Header>
        <Modal.Body>
            <ThemedSpan>
                You are about to recalculate the cash raised based on the application transactions.
                <br />
                <br />
                Do you wish to proceed?
            </ThemedSpan>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" onClick={handleSubmit} disabled={isRecalculating}>Submit</Button>
            <Button variant="light" onClick={onClose}>Close</Button>
        </Modal.Footer>
    </Modal>;
};

export default RecalculateCashRaisedModal;
import { createContext, useContext, useEffect, useState } from "react";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { useLazySearchApplicationDocumentsQuery, useUploadApplicationDocumentsMutation } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";

const ApplicationDocumentsContext = createContext();

export const useApplicationDocuments = () => useContext(ApplicationDocumentsContext);

const ApplicationDocumentsProvider = ({ children }) => {
    const { applicationId, application } = useHeadOfficeApplicationContext();
    const { filter } = useFilterContext();
    const limit = 50;
    const [isClearing, setIsClearing] = useState(false);
    const [sort, setSort] = useState("FileName+ASC");
    const [sortProperty, sortDirection] = sort.split("+");
    const [search, { data, error, isError, isFetching, isUninitialized }] = useLazySearchApplicationDocumentsQuery();
    const [upload, uploadResult] = useUploadApplicationDocumentsMutation();
    const { results, next, totalCount } = data || { results: [], next: null, totalCount: 10 };

    const clear = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setIsClearing(true);

        return search({
            applicationId,
            next: null,
            sort,
            limit,
            ...filter
        }).finally(_ => setIsClearing(false));
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => search({
        applicationId,
        next,
        sort,
        limit,
        ...filter
    });

    const uploadApplicationDocuments = (files) => {
        return new Promise((resolve, reject) => {
            const request = new FormData();
            files?.forEach((file, index) => request.append(file.name, file, file.name));
            return upload({ applicationId, request })
                .unwrap()
                .then(resolve, reject);
        });
    };

    useEffect(() => {
        if (isUninitialized) return;

        clear();
    }, [filter, sort]);

    return <ApplicationDocumentsContext.Provider value={{
        results,
        totalCount,
        isClearing,
        isError,
        isFetching,
        error,
        uploadResult,
        isItemLoaded,
        loadMoreItems,
        retryFn: clear,
        uploadApplicationDocuments
    }}>
        {children}
    </ApplicationDocumentsContext.Provider>
};

export default ApplicationDocumentsProvider;
import _ from "lodash";
import React from "react";
import classNames from "classnames";
import { useClientDocuments } from "../../../hooks/ClientDocumentsContext";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import styled from "styled-components";

const FixedWidthHeader = styled(StandardTableSortableHeader)`
    width: ${props => props.width || 'auto'};
`;

const ClientDocumentsTableHeader = ({ children, property, className, width }) => {
    const { sort, sortTable } = useClientDocuments();
    const sortProperties = sort.split(',');
    const isHeaderSorted = _.some(sortProperties, i => i.includes(property));
    const headerDirection = isHeaderSorted && _.some(sortProperties, i => i.toLowerCase().includes('+desc')) ? "desc" : null;

    const handleClick = () => property == null || sortTable(property);
    
    return (
        <FixedWidthHeader
            className={classNames(className)}
            active={isHeaderSorted}
            direction={headerDirection}
            onClick={handleClick}
            isSticky={true}
            width={width}
        >
            {children}
        </FixedWidthHeader>
    );
};

export default ClientDocumentsTableHeader;
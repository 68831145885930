import React, { useMemo } from "react";
import withProviders from "../hooks/withProviders";
import HouseRulesProvider, { useHouseRules } from "../hooks/HouseRulesContext";
import Skeleton from "react-loading-skeleton";
import { InvestmentCommitteeHouseRulesRouteTabs } from "../routes/investment-committee.houserules.routes";
import { Outlet } from "react-router-dom";
import GridWrapper from "../components/GridWrapper";
import { FormSelect } from "../components/forms";
import PageHeader, { PageHeaderBottom, PageHeaderTop } from "../components/layout/PageHeader";
import { Main, ThemedSpan } from "../components/utilities";
import { PageHeaderTitle } from "../components/utilities/PageHeader";

const InvestmentCommitteeHouseRulesLayout = ({ title = "Investment Committee House Rules" }) => {
    const {
        clientProfiles,
        clientProfilesIsFetching,
        currentClientProfile,
        setCurrentClientProfile
    } = useHouseRules();

    const maxProfileNameLength = useMemo(() =>
        (clientProfiles?.map(profile => profile.label.length)?.reduce((a, b) => Math.max(a, b), 0) ?? 0) + 3,
        [clientProfiles]);

    return (
        <React.Fragment>
            <PageHeader>
                <PageHeaderTop>
                    <PageHeaderTitle>{title}</PageHeaderTitle>
                </PageHeaderTop>
                <PageHeaderBottom>
                    <GridWrapper className="pb-2" gridTemplateColumns={`max-content ${maxProfileNameLength}ch`}>
                        {/* Need two different Themed components for rendering the select options */}
                        <ThemedSpan variant="light">
                            <h4 className="pt-1">Select Client Profile:</h4>
                        </ThemedSpan>
                        {clientProfilesIsFetching
                            ? <Skeleton height={30} />
                            : <ThemedSpan variant="dark">
                                <FormSelect
                                    className="ms-3"
                                    options={clientProfiles}
                                    defaultValue={currentClientProfile}
                                    onChange={(selection) => setCurrentClientProfile(selection.value)}
                                />
                            </ThemedSpan>}
                    </GridWrapper>
                </PageHeaderBottom>
                <InvestmentCommitteeHouseRulesRouteTabs />
            </PageHeader>
            <Main className="p-3">
                {currentClientProfile != null && <Outlet />}
            </Main>
        </React.Fragment>
    );
};

export default withProviders(HouseRulesProvider)(InvestmentCommitteeHouseRulesLayout);
import NavigationTabsPageSubHeader from "../components/layout/NavigationTabsPageSubHeader";
import SystemSettingsLayout, { SystemSettingsUsersSectionLayout } from "../layouts/SystemSettingsLayout";
import { NavigationTabs } from "../components/navigation";
import { IntroducerManagementPage, UserDelegatesPage, UsersPage } from "../pages/settings";

const SettingsRoutes = [{
    path: '/settings/*',
    pathBase: '/settings',
    defaultRoute: 'users',
    roles: ['sys_admin_access'],
    element: SystemSettingsLayout,
    children: [
        {
            path: 'users',
            pathBase: '/settings/users',
            defaultRoute: '',
            icon: 'fa-user-cog',
            label: 'Users & Teams',
            roles: [],
            element: SystemSettingsUsersSectionLayout,
            children: [
                { 
                    path: '', 
                    label: 'Users', 
                    icon: 'fa-user', 
                    element: UsersPage 
                },
                { 
                    path: 'delegates',
                    label: 'Delegate Access', 
                    icon: 'fa-user-friends', 
                    element: UserDelegatesPage 
                }
            ]
        },
        {
            path: 'introducers',
            pathBase: '/settings/introducers',
            icon: 'fa-handshake',
            label: 'Introducer Management',
            roles: [],
            element: IntroducerManagementPage
        }
    ]
}];

export const SystemSettingsRouteTabs = () => <NavigationTabs pathBase={SettingsRoutes[0].pathBase} options={SettingsRoutes[0]} />

export const SystemSettingsUsersRouteTabs = () => (
    <NavigationTabsPageSubHeader 
        top="91"
        pathBase="/settings/users"
        options={SettingsRoutes[0].children[0]}
    />
);

export default SettingsRoutes;
import { wealthProApi } from "../../redux/api";

const expenditureDetailsSectionEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        updateExpenditureFrequency: builder.mutation({
            query: ({ expenditureId, expenditureTypeId, newFrequency }) => ({
                url: `api/factfind/ExpenditureDetails/${expenditureId}/Frequency/${expenditureTypeId}`,
                method: 'PATCH',
                body: { newFrequency }
            }),
            async onQueryStarted({ masterAccountId, expenditureTypeId, newFrequency }, { dispatch, queryFulfilled }) {
                try {
                    const updateResult = dispatch(wealthProApi.util.updateQueryData('fetchExpenditureDetailsSection', { masterAccountId }, (draft) => {
                        const changeIndex = draft.frequencies.findIndex(freq => freq.expenditureTypeId === expenditureTypeId);
                        const freqToChange = draft.frequencies[changeIndex];

                        return {
                            ...draft,
                            frequencies: [
                                ...draft.frequencies.slice(0, changeIndex),
                                {
                                    ...freqToChange,
                                    frequency: newFrequency
                                },
                                ...draft.frequencies.slice(changeIndex + 1)
                            ]
                        };
                    }));
                    try {
                        await queryFulfilled;
                    }
                    catch {
                        updateResult.undo();
                    }
                }
                catch {
                    console.error("Error updating Expenditure Frequency");
                }
            }
        }),
        fetchClientExpenditureDetails: builder.query({
            query: ({ clientExpenditureId }) => `api/factfind/ExpenditureDetails/Client/${clientExpenditureId}`
        }),
        patchClientExpenditureDetails: builder.mutation({
            query: ({ clientExpenditureId, operations }) => ({
                url: `api/factfind/ExpenditureDetails/Client/${clientExpenditureId}`,
                method: 'PATCH',
                body: operations
            })
        }),
        updateExpenditureValue: builder.mutation({
            query: ({ clientExpenditureId, expenditureTypeId, newValue }) => ({
                url: `api/factfind/ExpenditureDetails/Client/${clientExpenditureId}/${expenditureTypeId}`,
                method: 'PATCH',
                body: { newValue }
            }),
            async onQueryStarted({ masterAccountId, clientExpenditureId, expenditureTypeId, newValue }, { dispatch, queryFulfilled }) {
                try {
                    const updateResult = dispatch(wealthProApi.util.updateQueryData('fetchExpenditureDetailsSection', { masterAccountId }, (draft) => {
                        const clientIndex = draft.clients?.findIndex(client => client.clientExpenditureId === clientExpenditureId);
                        const clientToChange = draft.clients[clientIndex];
                        const entryIndex = clientToChange.expenditureEntries.findIndex(freq => freq.expenditureTypeId === expenditureTypeId);
                        const entryToChange = clientToChange.expenditureEntries[entryIndex];

                        return {
                            ...draft,
                            clients: [
                                ...draft.clients.slice(0, clientIndex),
                                {
                                    ...clientToChange,
                                    expenditureEntries: [
                                        ...clientToChange.expenditureEntries.slice(0, entryIndex),
                                        {
                                            ...entryToChange,
                                            value: newValue
                                        },
                                        ...clientToChange.expenditureEntries.slice(entryIndex + 1)
                                    ]
                                },
                                ...draft.clients.slice(clientIndex + 1)
                            ]
                        };
                    }));
                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.error("Error updating Expenditure Details in service", error);
                        updateResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating Expenditure Frequency in cache", error);
                }
            }
        }),
        createNewExpenditureType: builder.mutation({
            query: ({ expenditureId, expenditureTypeId, isDiscretionary }) => ({
                url: `api/factfind/ExpenditureDetails/${expenditureId}/Type/${expenditureTypeId}/${isDiscretionary}`,
                method: 'POST'
            }),
            async onQueryStarted({ masterAccountId, expenditureTypeId, isDiscretionary }, { dispatch, queryFulfilled }) {
                try {
                    const createResult = dispatch(wealthProApi.util.updateQueryData('fetchExpenditureDetailsSection', { masterAccountId }, (draft) => ({
                        ...draft,
                        frequencies: [
                            ...draft.frequencies,
                            {
                                expenditureTypeId,
                                expenditureType: {
                                    expenditureTypeId,
                                    typeEnum: 0,
                                    isDiscretionary
                                }
                            }
                        ],
                        clients: draft.clients.map(client => ({
                            ...client,
                            expenditureEntries: [
                                ...client.expenditureEntries,
                                {
                                    clientExpenditureId: client.clientExpenditureId,
                                    expenditureTypeId,
                                    expenditureType: {
                                        expenditureTypeId,
                                        typeEnum: 0,
                                        isDiscretionary
                                    }
                                }
                            ]
                        }))
                    })));
                    try {
                        await queryFulfilled;
                    } catch (ex) {
                        console.error("Error creating expenditure type in service", ex);
                        createResult.undo();
                    }
                } catch (ex) {
                    console.error("Error creating expenditure type in cache", ex);
                }
            }
        }),
        updateExpenditureTypeDescription: builder.mutation({
            query: ({ expenditureTypeId, newDescription }) => ({
                url: `api/factfind/ExpenditureDetails/Type/${expenditureTypeId}`,
                method: 'PATCH',
                body: { newDescription }
            })
        }),
        deleteExpenditureType: builder.mutation({
            query: ({ expenditureTypeId }) => ({
                url: `api/factfind/ExpenditureDetails/Type/${expenditureTypeId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ masterAccountId, expenditureTypeId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchExpenditureDetailsSection', { masterAccountId }, (draft) => {
                        const delIndex = draft.frequencies.findIndex(freq => freq.expenditureTypeId === expenditureTypeId);

                        return {
                            ...draft,
                            frequencies: [
                                ...draft.frequencies.slice(0, delIndex),
                                ...draft.frequencies.slice(delIndex + 1)
                            ],
                            clients: draft.clients.map(client => {
                                const delIndex = client.expenditureEntries.findIndex(entry => entry.expenditureTypeId === expenditureTypeId);
                                return {
                                    ...client,
                                    expenditureEntries: [
                                        ...client.expenditureEntries.slice(0, delIndex),
                                        ...client.expenditureEntries.slice(delIndex + 1)
                                    ]
                                }
                            })
                        };
                    }));
                    try {
                        await queryFulfilled;
                    } catch (ex) {
                        console.error("Error deleting expenditure type in service", ex);
                        deleteResult.undo();
                    }
                } catch (ex) {
                    console.error("Error deleting expenditure type in cache", ex);
                }
            }
        })
    })
});

export const {
    useFetchClientExpenditureDetailsQuery,
    useLazyFetchClientExpenditureDetailsQuery,
    usePatchClientExpenditureDetailsMutation,
    useUpdateExpenditureFrequencyMutation,
    useUpdateExpenditureValueMutation,
    useCreateNewExpenditureTypeMutation,
    useUpdateExpenditureTypeDescriptionMutation,
    useDeleteExpenditureTypeMutation
} = expenditureDetailsSectionEndpoints
import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, DateInput, FormLabel, FormSelect, FormTextArea } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useClientAccounts from "../hooks/useClientAccounts";
import useOtherDebts from "../hooks/useOtherDebts";
import { InfoBubble } from "./FormInfoBubbleWrapper";
import SectionHeader from "./SectionHeader";

const OtherDebts = ({ setTotalOtherDebts }) => {

    const {
        realTimeBalanceDues,
        patchRealTimeBalanceDue,
        data,
        isLoading,
        isError,
        error,
        refetchOtherDebts,
        createOtherDebt,
        removeOtherDebt,
        patchOtherDebt,
        updateOtherDebtBorrowerMappings
    } = useOtherDebts();


    const { data: clientAccountOptions } = useClientAccounts({ fetchType: 'list' });

    const totalOtherDebts = useMemo(() => {
        return realTimeBalanceDues?.reduce((acc, balanceDue) => acc + balanceDue ?? 0, 0) ?? 0;
    }, [realTimeBalanceDues]);

    useEffect(() => {
        const timeout = setTimeout(() =>
            setTotalOtherDebts(totalOtherDebts), 50);

        return () => clearTimeout(timeout);
    }, [setTotalOtherDebts, totalOtherDebts]);

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchOtherDebts} />
        </ErrorMessageWrapper>
    }

    return <React.Fragment>
        <div className="d-flex flex-row mt-4 justify-content-between">
            <div className="d-flex">
                <SectionHeader className="me-1 d-flex align-items-center">
                    Other Debts
                </SectionHeader>

                <InfoBubble
                    iconClassName={"my-auto mx-1"}
                    showBubble
                />
            </div>
        </div>

        {isLoading
            ? <Skeleton height={30} count={2} />
            : data.length === 0
            && <p className="mt-2">There are no recorded other debts. Click <button className="anchor" onClick={createOtherDebt}>here</button> to create one</p>}

        {data && data.length > 0 && <>
            <GridWrapper className="d-none d-md-grid" gridTemplateColumns={"2fr 2fr 2.5fr 2fr 2fr auto"}>
                <FormLabel className={"mt-auto"}>Debt Type</FormLabel>
                <FormLabel className={"mt-auto"}>Borrower</FormLabel>
                <FormLabel className={"mt-auto"}>Description</FormLabel>
                <FormLabel className={"mt-auto"}>Balance Due</FormLabel>
                <FormLabel className={"mt-auto"}>End Date</FormLabel>
                <IconButton
                    icon="plus"
                    variant="success"
                    onClick={createOtherDebt}
                />
            </GridWrapper>

            {data.map((debt, index) => <React.Fragment key={debt.debtId ?? index}>
                <GridWrapper className="d-none d-md-grid mt-2" gridTemplateColumns={"2fr 2fr 2.5fr 2fr 2fr auto"}>
                    {isLoading || !debt.debtId
                        ? <Skeleton />
                        : <FormSelect
                            label={<FormLabel className={"d-md-none"}>Debt Type</FormLabel>}
                            defaultValue={debt.debtType}
                            options={["Loan", "Credit Card", "Hire Purchase", "Other"].map((label, index) => ({ label, value: (index + 1) * 10 }))}
                            onBlur={({ value }) => patchOtherDebt(debt.debtId, "debtType", value)}
                        />}
                    {isLoading || !debt.debtId
                        ? <Skeleton />
                        : <FormSelect
                            label={<FormLabel className={"d-md-none"}>Borrower</FormLabel>}
                            isMulti
                            options={clientAccountOptions}
                            defaultValue={debt.borrowerMappings.map(x => x.clientAccountId)}
                            onBlur={(newValues) => updateOtherDebtBorrowerMappings(debt.debtId, newValues.map(x => x.value))}
                        />}
                    {isLoading || !debt.debtId
                        ? <Skeleton />
                        : <FormTextArea
                            label={<FormLabel className={"d-md-none"}>Description</FormLabel>}
                            value={debt.description}
                            onBlur={(value) => patchOtherDebt(debt.debtId, "description", value)}
                        />}
                    {isLoading || !debt.debtId
                        ? <Skeleton />
                        : <CurrencyInput
                            label={<FormLabel className={"d-md-none"}>Balance Due</FormLabel>}
                            value={debt.balanceDue}
                            onChange={(e, { floatValue }) => patchRealTimeBalanceDue(index, floatValue)}
                            onBlur={(value) => patchOtherDebt(debt.debtId, "balanceDue", value)}
                            decimalScale={false}
                            fixedDecimalScale={false}
                        />}
                    {isLoading || !debt.debtId
                        ? <Skeleton />
                        : <DateInput
                            label={<FormLabel className={"d-md-none"}>End Date</FormLabel>}
                            value={debt.endDate}
                            isClearable
                            onBlur={(value) => patchOtherDebt(debt.debtId, "endDate", value)}
                        />}
                    <IconButton
                        className="mb-auto"
                        icon="trash"
                        variant="danger"
                        onClick={() => removeOtherDebt(debt.debtId)}
                        disabled={isLoading || !debt.debtId}
                    />
                </GridWrapper>
            </React.Fragment>)}
        </>}
    </React.Fragment>;
};

export default OtherDebts;
import React from "react";
import classNames from "classnames";
import { Button } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateIntroducerButton = ({ className, ...props }) => (
    <Button variant="success" className={classNames('me-2', className)} {...props}>
        <FontAwesomeIcon className="me-1" icon="fa-plus" />
        <span>New Introducer</span>
    </Button>
);

export default CreateIntroducerButton;
import React, { useCallback, useEffect } from "react";
import { GridWrapper } from "../../../components";
import { DivestTransactionTable, FeeCollection, Income, InvestmentSelection, ProductSelection, RingFence } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

// This instruction type has an Invest and a Divest
const PensionTransfer = ({ instructionId }) => {
    const [{ fetchInvest, fetchDivest }, { investIsError, divestIsError }, { realTimeRemainder }] = useInstruction();

    useEffect(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId });
    }, [fetchDivest, fetchInvest, instructionId]);

    const retry = useCallback(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId });
    }, [fetchDivest, fetchInvest, instructionId]);

    if (investIsError || divestIsError)
        return <InvestDivestErrorMessage retry={retry} />

    return <GridWrapper gridTemplateColumns={"100%"}>
        <ProductSelection
            changeOwner
            productLabel={"Transfer from Product"}
        />
        <DivestTransactionTable isSelling={false} />
        <ProductSelection
            isInvest
            changeOwner
            productLabel={"Transfer to Product"}
        />
        <Income inDrawdown taxFreeCash cashAccount />

        <InvestmentSelection maxInvestmentAmount={realTimeRemainder} />
        <FeeCollection />

        <RingFence />

        {/* <AnnualCostsDisclosureComparisonTable /> */}
    </GridWrapper>;
};

export default PensionTransfer;

import { Dropdown } from "react-bootstrap";
import React, { useId } from "react";
import { ActionIcon } from "../../../components/utilities";

const DropdownToggle = React.forwardRef(({ onClick }, ref) => {
    const handleOnToggle = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        onClick(e);

    };
    
    return <a className="d-flex align-items-center" ref={ref} onClick={handleOnToggle} >
        <ActionIcon icon="fa-ellipsis" size="lg" />
    </a>
});

const TransactionFeeAllocationOptionsDropdown = ({ onDelete, onEdit }) => {

    const dropdownId = useId();

    return <Dropdown>
        <Dropdown.Toggle
            id={dropdownId}
            as={DropdownToggle}
        />
        <Dropdown.Menu>
            <Dropdown.Item as={"div"} onClick={onEdit}>Edit</Dropdown.Item>
            <Dropdown.Item as={"div"} onClick={onDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>;
};

export default TransactionFeeAllocationOptionsDropdown;
import _ from "lodash";
import React from "react";
import { useAuth } from "react-oidc-context";
import { usePatchIntroducerMutation } from "../../../services/clientreviews";

const allowUpdateStatusRoles = ['sys_admin_access', 'sys_introducers_approve'];

const useEditIntroducer = (introducer) => {
    const { introducerId } = introducer || { introducerId: null };
    const { user: { profile } } = useAuth();
    const [_patch, results] = usePatchIntroducerMutation();
    const [defaultValues, setDefaultValues] = React.useState(introducer);
    const isAllowedToUpdateStatus = _.some(profile.roles, item => allowUpdateStatusRoles.includes(item));

    const update = (operations) => {
        return new Promise((resolve, reject) => {
            return _patch({ introducerId: introducerId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    React.useEffect(() => {
        setDefaultValues(_ => introducer);
    }, [introducer]);
    
    return {
        ...results,
        update,
        defaultValues,
        isAllowedToUpdateStatus
    }
};

export default useEditIntroducer;
import { applyPatch } from "fast-json-patch";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import {
    useLazyFetchInvestTransactionsQuery,
} from "../../../services/recommendations";
import { useSelectPortfolioMutation } from "../../../services/recommendations/investEndpoints";
import { useInstruction } from "../contexts/InstructionContext";
import { useFetchStandardPortfoliosQuery } from "../../../services/assets"

const realtimeTransactionsReducer = (state, action) => {
    switch (action.type) {
        case 'init':
            return action.value;
        case 'add':
            return [
                ...state,
                action.newTransaction
            ];
        case 'patch':
            if (!state || !state[action.index])
                return state;
            let transactionCopy = { ...state[action.index] };

            applyPatch(transactionCopy, action.operations);

            return [
                ...state.slice(0, action.index),
                transactionCopy,
                ...state.slice(action.index + 1)
            ];
        case 'bulk-patch':
            return state.map(action.patch);
        case 'delete':
            return state?.filter(transaction => transaction.rowTag !== action.rowTag) ?? [];
        default:
            break;
    }
}

const useInvestmentSelection = (isRegularContribution) => {
    const [{
        realTimePatchInvest,
        patchInvest: patchInvestTrigger,
        patchInvestTransaction,
        createInvestTransaction,
        deleteInvestTransaction,
        deleteAllTransactions
    }, {
        realTimeInstruction,
        instructionId,
        invest,
        isCreatingInvestTransaction: isCreating,
        errorCreatingInvestTransaction: errorCreatingRow,
        isDeletingInvestTransaction: isDeleting,
        errorDeletingInvestTransaction: errorDeletingRow,
        paymentMethodObjects
    }, {
        taxYears
    }] = useInstruction();

    const { data: portfolioDropdownOptions } = useFetchStandardPortfoliosQuery({ fetchType: 'list' });
    const optionsWithCustom = useMemo(() =>
        [
            { value: 0, label: "Bespoke" },
            { value: 1, label: "Retain as Cash" },
            ...(portfolioDropdownOptions ?? [])
        ],
        [portfolioDropdownOptions]);

    const monthsPhasedOptions = useMemo(() => [
        { label: "Not Phased", value: 0 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
        { label: "12", value: 12 },
    ], []);

    const [realTimeTransactions, transactionsDispatch] = useReducer(realtimeTransactionsReducer, []);
    const [fetchInvestTransactions, {
        data: investTransactions,
        isLoading,
        isFetching,
        isError
    }] = useLazyFetchInvestTransactionsQuery();

    useEffect(() => {
        if (invest?.id)
            fetchInvestTransactions({ investId: invest?.id });
    }, [fetchInvestTransactions, invest?.id])

    const [selectPortfolioTrigger] = useSelectPortfolioMutation();

    // Tracks the (real-time) allocation of the top row,
    // i.e., 100 - the sum of all other row allocations
    const realTimeAllocationRemainder = useMemo(() =>
        realTimeTransactions == null
            ? 0
            : 100 - realTimeTransactions?.reduce((acc, transaction, index) => index !== 0
                ? acc + transaction.allocation
                : 0, 0),
        [realTimeTransactions]);

    const cachedAllocationRemainder = useMemo(() => investTransactions == null
        ? 0
        : 100 - investTransactions?.reduce((acc, transaction, index) =>
            index !== 0
                ? acc + transaction.allocation
                : 0, 0),
        [investTransactions]);

    useEffect(() => {
        if (investTransactions != null)
            transactionsDispatch({ type: 'init', value: investTransactions });
    }, [investTransactions]);

    const retry = useCallback(() =>
        fetchInvestTransactions({ investId: invest?.id }).unwrap()
            .then(res => {
                if (res != null)
                    transactionsDispatch({ type: 'init', value: res });
            }), [fetchInvestTransactions, invest?.id]);

    const regularContributionOptions = useMemo(() => [
        { label: "Monthly", value: 0 },
        { label: "Quarterly", value: 1 },
        { label: "Annual", value: 2 },
        { label: "Biannual", value: 3 },
    ], []);

    const hasTransactions = useMemo(() =>
        realTimeTransactions?.length > 0,
        [realTimeTransactions?.length]);

    const frequencySelected = useMemo(() =>
        realTimeInstruction?.invest?.regularContributionFrequency != null,
        [realTimeInstruction?.invest?.regularContributionFrequency]);

    const renderInvestmentTable = useMemo(() => (isRegularContribution
        ? frequencySelected
        : hasTransactions),
        [frequencySelected, isRegularContribution, hasTransactions]);

    const tradingAccountPaymentMethodId = useMemo(() =>
        paymentMethodObjects?.find(x => x.investmentAmountIsSource)?.id,
        [paymentMethodObjects])

    const realTimeTotalMinusFees = useMemo(() =>
        realTimeInstruction?.invest?.paymentMethodId === tradingAccountPaymentMethodId
            ? realTimeInstruction?.invest?.totalInvestmentAmount - realTimeInstruction?.invest?.adviceFeeAmount
            : realTimeInstruction?.invest?.totalInvestmentAmount,
        [realTimeInstruction?.invest?.adviceFeeAmount, realTimeInstruction?.invest?.paymentMethodId, realTimeInstruction?.invest?.totalInvestmentAmount, tradingAccountPaymentMethodId]);

    const totalMinusFees = useMemo(() =>
        invest?.paymentMethodId === tradingAccountPaymentMethodId
            ? invest?.totalInvestmentAmount - invest?.adviceFeeAmount
            : invest?.totalInvestmentAmount,
        [invest?.adviceFeeAmount, invest?.paymentMethodId, invest?.totalInvestmentAmount, tradingAccountPaymentMethodId]);

    const patchInvest = useCallback((property, value) =>
        patchInvestTrigger({ investId: invest?.id, instructionId, operations: [patchReplace(property, value)] }).unwrap(),
        [instructionId, invest?.id, patchInvestTrigger]);

    const realTimePatchInvestSingle = useCallback((property, value) => {
        realTimePatchInvest([patchReplace(property, value)])
    }, [realTimePatchInvest]);

    const patchTransaction = useCallback((operations, index) =>
        patchInvestTransaction({
            investId: invest?.id,
            rowTag: index === 0 ? 0 : investTransactions[index]?.rowTag,
            operations,
            index
        }).unwrap(), [invest?.id, investTransactions, patchInvestTransaction]);

    const patchTransactionAndSetPortfolio = useCallback((operations, index) =>
        new Promise((resolve, reject) => {
            if (invest?.portfolioId != null)
                return patchInvest("portfolioId", null).then(() =>
                    patchTransaction(operations, index)
                        .then(resolve, reject), reject);

            return patchTransaction(operations, index)
                .then(resolve, reject);
        }),
        [invest?.portfolioId, patchInvest, patchTransaction]);

    const realTimePatchTransaction = useCallback((operations, index) => {
        transactionsDispatch({ type: 'patch', index, operations });
    }, []);

    const realTimePatchTransactionAndSetPortfolio = useCallback((operations, index) => {
        if (invest?.portfolioId != null)
            patchInvest("portfolioId", null).then(() => {
                realTimePatchInvestSingle("portfolioId", null);
                realTimePatchTransaction(operations, index);
            });
        else
            realTimePatchTransaction(operations, index);
    }, [invest?.portfolioId, patchInvest, realTimePatchInvestSingle, realTimePatchTransaction]);

    const realTimeCalculateInvestmentAmount = useCallback((allocationPercentage) =>
        (allocationPercentage * realTimeTotalMinusFees) / 100,
        [realTimeTotalMinusFees]);

    const calculateInvestmentAmount = useCallback((allocationPercentage) =>
        (allocationPercentage * totalMinusFees) / 100,
        [totalMinusFees]);

    const createTransaction = useCallback(() =>
        new Promise((resolve, reject) => {
            if (invest?.portfolioId != null) {
                realTimePatchInvestSingle("portfolioId", null);
                return patchInvest("portfolioId", null).then(() =>
                    createInvestTransaction({ investId: invest?.id })
                        .unwrap()
                        .then(res => res != null
                            ? transactionsDispatch({ type: 'add', newTransaction: res })
                            : console.error("Nothing returned from add"))
                        .then(resolve, reject));
            }

            return createInvestTransaction({ investId: invest?.id })
                .unwrap()
                .then(res => res != null
                    ? transactionsDispatch({ type: 'add', newTransaction: res })
                    : console.error("Nothing returned from add"))
                .then(resolve, reject);
        }), [createInvestTransaction, invest?.id, invest?.portfolioId, patchInvest, realTimePatchInvestSingle]);

    const deleteTransaction = useCallback((rowTag) =>
        new Promise((resolve, reject) => {
            if (invest?.portfolioId != null) {
                realTimePatchInvestSingle("portfolioId", null);
                return patchInvest("portfolioId", null).then(() =>
                    deleteInvestTransaction({
                        investId: invest?.id,
                        rowTag,
                    }).unwrap()
                        .then(() => transactionsDispatch({ type: 'delete', rowTag }))
                        .then(resolve, reject));
            }

            return deleteInvestTransaction({
                investId: invest?.id,
                rowTag,
            }).unwrap()
                .then(() => transactionsDispatch({ type: 'delete', rowTag }))
                .then(resolve, reject);
        }), [deleteInvestTransaction, invest?.id, invest?.portfolioId, patchInvest, realTimePatchInvestSingle]);

    // Handle portfolio selection, including the creation of the first transaction if selecting a Custom portfolio
    const selectPortfolio = useCallback((portfolioId) =>
        new Promise((resolve, reject) => {
            switch (portfolioId) {
                case 0: // Bespoke
                    return patchInvest("portfolioId", null).then(() => {
                        if (investTransactions?.length === 0)
                            return createTransaction()
                    }).then(resolve, reject);
                case 1: // Retain as Cash
                    return patchInvest("portfolioId", null).then(() => {
                        if (investTransactions?.length !== 0)
                            return deleteAllTransactions({ investId: invest?.id });
                    }).then(resolve, reject);

                default: // Any Standard Portfolio
                    return selectPortfolioTrigger({ instructionId, investId: invest?.id, portfolioId }).unwrap()
                        .then(retry)
                        .then(resolve, reject);
            }
        }), [createTransaction, deleteAllTransactions, instructionId, invest?.id, investTransactions?.length, patchInvest, retry, selectPortfolioTrigger]);

    // Delayed update to de-select portfolio when investment amount becomes 0
    useEffect(() => {
        const deleteTransactionsTimeout = setTimeout(() => {
            if (invest?.totalInvestmentAmount === 0)
                selectPortfolio(1).then(() =>
                    realTimePatchInvestSingle("portfolioId", null));
        }, 150);

        return () => clearTimeout(deleteTransactionsTimeout);
    }, [invest?.totalInvestmentAmount, realTimePatchInvestSingle, selectPortfolio]);

    // Real-time update of totalInvestmentAmount when regularContributionAmount changes
    useEffect(() => {
        const updateTotalInvestmentAmount = setTimeout(() => {
            if (!isRegularContribution)
                return;
            if (!realTimeInstruction?.invest?.regularContributionAmount || realTimeInstruction?.invest?.regularContributionFrequency == null)
                return;

            realTimePatchInvestSingle("totalInvestmentAmount", realTimeInstruction?.invest?.regularContributionAmount);
        }, 150);

        return () => clearTimeout(updateTotalInvestmentAmount);
    }, [isRegularContribution, realTimeInstruction?.invest?.regularContributionAmount, realTimeInstruction?.invest?.regularContributionFrequency, realTimePatchInvestSingle]);

    // onBlur update of totalInvestmentAmount when regularContributionAmount changes
    useEffect(() => {
        const updateTotalInvestmentAmount = setTimeout(() => {
            if (!isRegularContribution)
                return;
            if (!invest?.regularContributionAmount || invest?.regularContributionFrequency == null)
                return;

            if (invest?.totalInvestmentAmount === invest?.regularContributionAmount)
                return;

            patchInvest("totalInvestmentAmount", invest?.regularContributionAmount);
        }, 150);

        return () => clearTimeout(updateTotalInvestmentAmount);
    }, [invest?.regularContributionAmount, invest?.regularContributionFrequency, invest?.totalInvestmentAmount, isRegularContribution, patchInvest]);

    // Real-time update of readonly allocation (row 0)
    useEffect(() => {
        const updateTopRowTimeout = setTimeout(() => {
            if (!realTimeInstruction?.invest?.investTransactions)
                return;

            const newInvestmentAmount = realTimeCalculateInvestmentAmount(realTimeAllocationRemainder);

            realTimePatchTransaction([
                patchReplace("allocation", realTimeAllocationRemainder),
                patchReplace("investmentAmount", newInvestmentAmount)
            ], 0);
        }, 150);

        return () => clearTimeout(updateTopRowTimeout);
    }, [realTimeAllocationRemainder, realTimeCalculateInvestmentAmount, realTimeInstruction?.invest?.investTransactions, realTimePatchTransaction]);

    // onBlur update of readonly allocation (row 0)
    useEffect(() => {
        const updateTopRowTimeout = setTimeout(() => {
            if (!investTransactions || investTransactions?.length === 0)
                return;

            const newInvestmentAmount = calculateInvestmentAmount(cachedAllocationRemainder);

            const { allocation: oldAllocation, investmentAmount: oldInvestmentAmount } = investTransactions[0];

            if (cachedAllocationRemainder === oldAllocation && newInvestmentAmount === oldInvestmentAmount)
                return;

            patchTransaction([
                patchReplace("allocation", cachedAllocationRemainder),
                patchReplace("investmentAmount", newInvestmentAmount)
            ], 0);
        }, 150);

        return () => clearTimeout(updateTopRowTimeout);
    }, [cachedAllocationRemainder, calculateInvestmentAmount, investTransactions, patchTransaction, realTimePatchTransaction]);

    // Real-time update of investmentAmount when allocation changes
    useEffect(() => {
        const updateTransactionValueTimeout = setTimeout(() => {
            if (realTimeTransactions?.length > 0)
                transactionsDispatch({
                    type: 'bulk-patch',
                    patch: (transaction) => ({
                        ...transaction,
                        investmentAmount: transaction?.allocation * realTimeTotalMinusFees / 100
                    })
                });
        }, 150);

        return () => clearTimeout(updateTransactionValueTimeout);
    }, [realTimeTotalMinusFees, realTimeTransactions?.length]);

    // onBlur update of investmentAmount when allocation changes
    useEffect(() => {
        const updateTransactionValueTimeout = setTimeout(() => {
            investTransactions?.forEach((transaction, index) => {
                if (!transaction.rowTag)
                    return;

                const newInvestmentAmount = transaction?.allocation * totalMinusFees / 100;

                if (newInvestmentAmount === transaction?.investmentAmount)
                    return;

                patchTransaction([patchReplace("investmentAmount", newInvestmentAmount)], index);
            });
        }, 150)

        return () => clearTimeout(updateTransactionValueTimeout);
    }, [investTransactions, patchTransaction, totalMinusFees]);

    return {
        taxYears,
        totalMinusFees: realTimeTotalMinusFees,
        investTransactions,
        realTimeTransactions,
        realTimeAllocationRemainder,
        cachedAllocationRemainder,
        portfolioDropdownOptions: optionsWithCustom,
        monthsPhasedOptions,
        errorCreatingRow,
        errorDeletingRow,
        regularContributionOptions,
        renderInvestmentTable,
        isLoading,
        isFetching,
        isError,
        retry,
        selectPortfolio,
        patchInvest,
        realTimePatchInvestSingle,
        createTransaction,
        isCreating,
        deleteTransaction,
        isDeleting,
        patchTransaction: patchTransactionAndSetPortfolio,
        realTimePatchTransaction: realTimePatchTransactionAndSetPortfolio
    };

}

export default useInvestmentSelection;


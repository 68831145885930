import { useCallback, useEffect, useMemo } from "react";
import { usePrdCommentaryContext } from "../../../hooks/PrdCommentaryContext";
import { useLazyFetchPortfolioNoteQuery, usePatchPortfolioNoteMutation, usePublishPortfolioNoteMutation } from "../../../services/assets";

const usePortfolioCommentary = ({ standardPortfolioId }) => {
    const {
        investmentCommitteeId,
    } = usePrdCommentaryContext();

    const [fetchPortfolioNoteTrigger, { data: portfolioNote, isFetching, isUninitialized }] = useLazyFetchPortfolioNoteQuery();

    useEffect(() => {
        fetchPortfolioNoteTrigger({
            investmentCommitteeId,
            standardPortfolioId
        }, true);
    }, [fetchPortfolioNoteTrigger, investmentCommitteeId, standardPortfolioId]);

    // Portfolio-specific note patching, with similar loader convenience functions as other commentaries
    const [patchPortfolioNoteTrigger, { isLoading: isPatching }] = usePatchPortfolioNoteMutation();

    const patchPortfolioNote = useCallback((newNote) =>
        patchPortfolioNoteTrigger({
            investmentCommitteeId,
            standardPortfolioId,
            note: newNote
        }).unwrap(),
        [investmentCommitteeId, patchPortfolioNoteTrigger, standardPortfolioId]);

    // Just one endpoint for publishing an individual portfolio's note
    const [publishPortfolioNoteTrigger, { isLoading: isPublishing }] = usePublishPortfolioNoteMutation();

    const publishPortfolioNote = useCallback(() =>
        publishPortfolioNoteTrigger({
            investmentCommitteeId,
            standardPortfolioId
        }).unwrap(),
        [investmentCommitteeId, publishPortfolioNoteTrigger, standardPortfolioId]);

    const label = useMemo(() => portfolioNote?.standardPortfolioName == null
        ? "Loading..."
        : `${portfolioNote.standardPortfolioName} Portfolio`, [portfolioNote?.standardPortfolioName]);

    const isLoading = useMemo(() => isFetching || isUninitialized, [isFetching, isUninitialized]);
    return {
        portfolioNote,
        label,
        isLoading,
        patchPortfolioNote,
        isPatching,
        publishPortfolioNote,
        isPublishing
    };
}

export default usePortfolioCommentary;
import { useState } from "react";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { FormSelect } from "../../../components/forms";

// Generic component that displays an IconButton alongside a FormSelect that, when pressed, opens the given ModalComponent
const FormSelectOpenModal = ({ ModalComponent, modalProps: { onHide, ...modalProps }, icon, buttonVariant, onIconClickFunction, onClickMutationProps = {}, ...selectProps }) => {
    const [modalShown, setModalShown] = useState(false);
    const [fullModalProps, setFullModalProps] = useState(modalProps);

    const openModal = () => {
        if (onIconClickFunction && typeof (onIconClickFunction) === 'function') {
            var possibleResult = onIconClickFunction({ ...onClickMutationProps });
            Promise.resolve(possibleResult).then(
                r => {
                    setModalShown(true);
                    setFullModalProps(prev => ({
                        ...prev,
                        ...r,
                    }));
                },
                error => { }
            );
        }

        setModalShown(true);
    };

    const hideModal = () => {
        setModalShown(false)
        setFullModalProps(modalProps);

        if (onHide && typeof onHide === 'function')
            onHide();
    };

    return <GridWrapper gridTemplateColumns={"1fr auto"}>
        <ModalComponent
            show={modalShown}
            onHide={hideModal}
            {...fullModalProps}
        />
        <FormSelect
            {...selectProps}
        />
        <IconButton
            className="my-auto"
            icon={icon}
            variant={buttonVariant}
            onClick={openModal}
        />
    </GridWrapper>;
}

export default FormSelectOpenModal;
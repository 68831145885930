import { useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useCreateVolatilityProfileMutation, useDeleteVolatilityProfileMutation, useFetchAgreedVolatilityProfileSectionQuery, useFetchFFClientProfilesQuery, useFetchFFDesignationsQuery, useFetchFFStandardPortfoliosQuery, useFetchFFWrappersQuery, usePatchVolatilityProfileMutation } from "../../../services/clientfactfind";
import useNotes from "./useNotes";

const useAgreedVolatilityProfileSection = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: section, isLoading: sectionIsLoading, isError: sectionIsError, error: sectionError, refetch } = useFetchAgreedVolatilityProfileSectionQuery({ masterAccountId });
    const [createProfileTrigger, { isLoading: isCreating }] = useCreateVolatilityProfileMutation();
    const [patchProfileTrigger] = usePatchVolatilityProfileMutation();
    const [deleteProfileTrigger] = useDeleteVolatilityProfileMutation();

    const { updateNotes: patchNotes } = useNotes();

    const createProfile = () =>
        createProfileTrigger({ masterAccountId, sectionId: section.agreedVolatilityProfileId, profileId: v4() }).unwrap();

    const bulkPatchProfile = (profileId, operations) =>
        patchProfileTrigger({ masterAccountId, profileId, operations }).unwrap()

    const patchProfile = (profileId, property, newValue) =>
        bulkPatchProfile(profileId, [patchReplace(property, newValue)]);

    const deleteProfile = (profileId) =>
        deleteProfileTrigger({ masterAccountId, profileId }).unwrap();

    const { data: designationOptions, isLoading: designationsIsLoading, isFetching: designationsIsFetching, isError: designationsIsError, error: designationsError, refetch: refetchDesignations } = useFetchFFDesignationsQuery({ masterAccountId });
    const { data: wrapperOptions, isLoading: wrappersIsLoading, isFetching: wrappersIsFetching, isError: wrappersIsError, error: wrappersError, refetch: refetchWrappers } = useFetchFFWrappersQuery();
    const { data: profileOptions, isLoading: clientProfilesIsLoading, isFetching: clientProfilesIsFetching, isError: clientProfilesIsError, error: clientProfilesError, refetch: refetchClientProfiles } = useFetchFFClientProfilesQuery();
    const { data: standardPortfolioOptions, isLoading: standardPortfoliosIsLoading, isFetching: standardPortfoliosIsFetching, isError: standardPortfoliosIsError, error: standardPortfoliosError, refetch: refetchStandardPortfolios } = useFetchFFStandardPortfoliosQuery();

    const [isFetching, setIsFetching] = useState(false);

    const refetchAll = useCallback(async () => {
        setIsFetching(true);
        await Promise.all([refetch, refetchDesignations, refetchWrappers, refetchClientProfiles, refetchStandardPortfolios]);
        setIsFetching(false);
    }, [refetch, refetchClientProfiles, refetchDesignations, refetchStandardPortfolios, refetchWrappers]);

    const isLoading = useMemo(() =>
        sectionIsLoading || isFetching,
        [isFetching, sectionIsLoading]);

    const optionsIsLoading = useMemo(() => ({
        designations: designationsIsLoading || designationsIsFetching,
        wrappers: wrappersIsLoading || wrappersIsFetching,
        profileOptions: clientProfilesIsLoading || clientProfilesIsFetching,
        standardPortfolios: standardPortfoliosIsLoading || standardPortfoliosIsFetching
    }), [designationsIsLoading, designationsIsFetching, wrappersIsLoading, wrappersIsFetching, clientProfilesIsLoading, clientProfilesIsFetching, standardPortfoliosIsLoading, standardPortfoliosIsFetching])

    const isError = useMemo(() => sectionIsError
        || designationsIsError
        || wrappersIsError
        || clientProfilesIsError
        || standardPortfoliosIsError, [designationsIsError, clientProfilesIsError, sectionIsError, standardPortfoliosIsError, wrappersIsError]);

    const error = useMemo(() => sectionError
        || designationsError
        || wrappersError
        || clientProfilesError
        || standardPortfoliosError, [designationsError, clientProfilesError, sectionError, standardPortfoliosError, wrappersError])

    // Show first row and one Skeleton row while data is loading
    const { profiles } = useFetchAgreedVolatilityProfileSectionQuery({ masterAccountId }, {
        selectFromResult: ({ data }) => ({ profiles: data?.profiles })
    });

    return [{
        refetchAll,
        patchNotes,
        createProfile,
        patchProfile,
        bulkPatchProfile,
        deleteProfile
    }, {
        section,
        profiles,
        options: {
            designationOptions,
            wrapperOptions,
            profileOptions,
            standardPortfolioOptions: standardPortfolioOptions != null
                ? [{ value: -1, isBespokePortfolio: true, label: "Bespoke" }, ...standardPortfolioOptions]
                : standardPortfolioOptions
        },
        isCreating,
        isLoading,
        optionsIsLoading,
        isError,
        error
    }]
}

export default useAgreedVolatilityProfileSection;
import _ from "lodash";
import { wealthProApi } from "../../redux/api";
import { applyPatch } from "fast-json-patch";

const assetApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        createAsset: build.mutation({
            query: ({ organisationId }) => {
                return {
                    url: 'api/assets/asset',
                    method: 'POST',
                    body: {
                        organisationId
                    }
                }
            },
            async onQueryStarted({ }, { dispatch, queryFulfilled }) {
                try {
                    const { data: asset } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchAssetsForGrid', {}, ({ results, totalCount, ...rest }) => {
                        return {
                            results: [asset, ...results],
                            totalCount: totalCount + 1,
                            ...rest
                        };
                    }))
                }
                catch { }
            }
        }),
        deleteAsset: build.mutation({
            query: ({ assetId }) => ({
                url: `api/assets/asset/${assetId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ assetId }, { dispatch, queryFulfilled }) {

                const result = dispatch(wealthProApi.util.updateQueryData('fetchAssetsForGrid', {}, ({ results, totalCount, ...rest }) => {
                    return {
                        results: results.filter(x => x.assetId !== assetId),
                        totalCount: totalCount - 1,
                        ...rest
                    };
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        fetchAssets: build.query({
            query: ({ page, limit, sort = "AssetName", fetchType = "object" }) => ({
                url: `api/assets/asset?page=${page}&limit=${limit}&sort=${sort}&fetchType=${fetchType}`
            })
        }),
        fetchAssetsForGrid: build.query({
            query: ({ next, limit, sort, request }) => ({
                url: `api/assets/asset/grid?limit=${limit ?? 50}&sort=${sort ?? "AssetName"}${next ? "&next=" + next : ""}`,
                body: request,
                method: 'POST'
            }),
            serializeQueryArgs: ({ endpointName }) => endpointName,
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        fetchPricesForGrid: build.query({
            query: ({ assetId, next, limit = 15, request }) => ({
                url: `api/assets/asset/${assetId}/prices?limit=${limit}${next ? "&next=" + next : ""}`,
                body: request,
                method: 'POST'
            }),
            serializeQueryArgs: ({ endpointName, queryArgs: { assetId } }) => `${endpointName}_${assetId}`,
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        patchAsset: build.mutation({
            query: ({ assetId, operations }) => ({
                url: `api/assets/asset/${assetId}`,
                body: operations,
                method: 'PATCH'
            }),
            async onQueryStarted({ assetId, operations, assignableObject }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchAssetsForGrid', {}, ({ results, totalCount, ...rest }) => {
                    var item = results.find(x => x.assetId === assetId);
                    applyPatch(item, operations, true);

                    if (assignableObject) {
                        Object.assign(item, assignableObject);
                    }
                }));
                console.log(result)
                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        searchAssetsPaged: build.query({ 
            query: ({ searchTerm, page, limit, ...rest }) => {
                const keys = Object.keys(rest);
                const url = `api/assets/asset/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}${keys?.length > 0
                    ? keys.map(key => `&${key}=${rest[key]}`).join("")
                    : ""}`

                return {
                    url
                };
            },
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        searchAssets: build.query({
            query: ({ searchTerm, ...rest }) => {
                const keys = Object.keys(rest);
                const url = `api/assets/asset/search?searchTerm=${searchTerm}${keys?.length > 0
                    ? keys.map(key => `&${key}=${rest[key]}`).join("")
                    : ""}`

                return {
                    url
                };
            }
        }),
        lookupAssetForIC: build.query({
            query: ({ lookupCode }) => `api/assets/asset/ic/lookup?lookupCode=${lookupCode}`
        })
    })
});

export const {
    useCreateAssetMutation,
    useDeleteAssetMutation,
    useLazyFetchAssetsQuery,
    useLazyFetchAssetsForGridQuery,
    useLazyFetchPricesForGridQuery,
    usePatchAssetMutation,
    useLazySearchAssetsQuery,
    useLazySearchAssetsPagedQuery,
    useLazyLookupAssetForICQuery
} = assetApiEndpoints;
import React from "react";
import DeleteSalutationButton from "./DeleteSalutationButton";
import ClientAccountSelect from "../../../components/forms/selects/ClientAccountSelect";
import { FormCheck, FormInput } from "../../../components/forms";
import { StandardTableFormCheckColumn } from "../../../components/tables/StandardTable";

const SalutationListRow = ({ columns, data, index, onDefaultChanged, onDelete, onChange, ...rest }) => {
    const { addressee, clientAccountId, isDefault, isPrimary, masterAccountId, salutationFormal, salutationFromAdviser, salutationId } = data;
    return (
        <tr {...rest}>
            <td className={isPrimary ? "align-middle" : "align-top"}>
                {isPrimary === true && (<span>Primary Account</span>)}
                {isPrimary === false && (
                    <ClientAccountSelect
                        masterAccountId={masterAccountId}
                        placeholder="Client Account"
                        defaultValue={clientAccountId}
                        onBlur={(values) => onChange(salutationId, 'clientAccountId', values.value)}
                    />
                )}
            </td>
            <td>
                <FormInput
                    value={addressee}
                    placeholder="Addressee"
                    onBlur={(value) => onChange(salutationId, 'addressee', value)}
                />
            </td>
            <td>
                <FormInput
                    value={salutationFromAdviser}
                    placeholder="Salutation from the Adviser"
                    onBlur={(value) => onChange(salutationId, 'salutationFromAdviser', value)}
                />
            </td>
            <td>
                <FormInput
                    value={salutationFormal}
                    placeholder="Salutation formally"
                    onBlur={(value) => onChange(salutationId, 'salutationFormal', value)}
                />
            </td>
            <StandardTableFormCheckColumn className="align-middle text-center">
                <FormCheck
                    type="radio"
                    name="isSalutationDefaultRadio"
                    isChecked={isDefault}
                    onChange={(_, e) => onDefaultChanged(e, salutationId)}
                />
            </StandardTableFormCheckColumn>
            <td className="col-md-auto">
                <DeleteSalutationButton salutation={data} />
            </td>
        </tr>
    );
};

export default SalutationListRow;
import { wealthProApi } from '../../redux/api';

const hoDesignationApiEndpoints = wealthProApi.enhanceEndpoints({})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchHOAdminDesignations: builder.query({
            query: ({ masterAccountId, page, limit, fetchType = "list", sort = "DisplayOrder" }) =>
                `api/headofficeadmin/Designation?${masterAccountId != null ? `masterAccountId=${masterAccountId}&` : ""}${page != null ? `page=${page}&` : ""}${limit != null ? `limit=${limit}&` : ""}fetchType=${fetchType}&sort=${sort}`
        })
    })
});

export const {
    useFetchHOAdminDesignationsQuery,
    useLazyFetchHOAdminDesignationsQuery
} = hoDesignationApiEndpoints;
import { useEffect, useState } from "react";
import { useUploadDocumentMutation } from "../../../services/clientdocuments";
import { useLazyPreUploadDocumentCheckQuery } from "../../../services/clientdocuments";

const useUploadDocument = (masterAccountId = null, projectId = null) => {
    const [create, createResults] = useUploadDocumentMutation();
    const [check, checkResults] = useLazyPreUploadDocumentCheckQuery();
    const [defaultValues, setDefaultValues] = useState({
        masterAccountId,
        projectId,
        fileName: "",
        fileSize: 0,
        documentType: null,
        documentFunctionId: null,
        documentDescription: null,
        conflictBehaviour: "rename",
        isClientVisible: false
    });

    const checkFileNameExists = (fileName) => {
        return new Promise((resolve, reject) => {
            return check({ masterAccountId, fileName }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const upload = (data) => {
        return new Promise((resolve, reject) => {
            return create({ data }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    useEffect(() => {
        setDefaultValues((current) => { return { ...current, masterAccountId } });
    }, [masterAccountId]);

    useEffect(() => {
        setDefaultValues((current) => { return { ...current, projectId } });
    }, [projectId]);

    const {
        isLoading: isCheckingFile
    } = checkResults;

    return {
        checkFileNameExists,
        createResults,
        defaultValues,
        isCheckingFile,
        upload
    };
};

export default useUploadDocument;
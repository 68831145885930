import { wealthProApi } from "../../redux/api";

const hoAdminMasterAccountsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: builder => ({
        fetchIntroducersForMasterAccount: builder.query({
            query: ({ masterAccountId }) => `api/headofficeadmin/masteraccounts/${masterAccountId}/introducers`
        }),
        fetchApplicationsForMasterAccount: builder.query({
            query: ({masterAccountId, excludeExistingFees}) => `api/headofficeadmin/masteraccounts/${masterAccountId}/applications?excludeExistingFees=${excludeExistingFees}`
        }),
        searchHeadOfficeMasterAccounts: builder.query({
            query: ({ searchText, page = 1, limit = 100, sort = "AccountName" }) => `api/headofficeadmin/masteraccounts/search?searchTerm=${searchText}&page=${page}&limit=${limit}&sort=${sort}`
        })
    })
});

export const {
    useFetchIntroducersForMasterAccountQuery,
    useFetchApplicationsForMasterAccountQuery,
    useLazySearchHeadOfficeMasterAccountsQuery
} = hoAdminMasterAccountsApiEndpoints;
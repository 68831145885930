import styled from "styled-components";
import { FormLabel } from "../../../components/forms";
import { ThemedSpan } from "../../../components/utilities";
import useFundAnalysisColumnDefs from "../hooks/useFundAnalysisColumnDefs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WrappedLabel = styled(FormLabel)`
    white-space: normal !important;
`;

const SortableHeader = ({ key, sort, direction = null, children, className, ...rest }) => {
    return <span key={key} className="d-flex mx-auto has-pointer" onClick={sort}>
        <WrappedLabel className={`has-pointer ${className}`} {...rest}>
            {children}
        </WrappedLabel>
        {direction != null && <FontAwesomeIcon
            icon={direction.toLowerCase() === "asc" ? "sort-up" : "sort-down"}
            className="my-auto ps-1"
        />}
    </span>
}

const FundAnalysisRow = ({ fund = null, isAverage = false, isVolatility, isGroupedByVolatility, currentSort, sort }) => {
    const columnDefs = useFundAnalysisColumnDefs({ isVolatility, isGroupedByVolatility });

    return columnDefs.map(({ field, formatValue, transformValue, isBestField, isWorstField, isSortable, header }, index) => {
        if (fund == null) { // Header row
            if (!isSortable)
                return <FormLabel key={index} className="text-wrap d-flex">{header}</FormLabel>

            let direction;
            if (currentSort?.toLowerCase().includes(field.toLowerCase()))
                direction = currentSort.toLowerCase().endsWith("desc") ? "desc" : "asc";

            const sortField = `${field.slice(0, 1).toUpperCase()}${field.slice(1)}`;

            return <SortableHeader className="has-pointer" key={index} sort={() => sort(sortField)} direction={direction}>{header}</SortableHeader>
        }

        const value = fund[field];
        const isBest = fund[isBestField];
        const isWorst = fund[isWorstField];

        var formattedValue = formatValue == null ? value : formatValue(value);
        if (fund?.isBenchmark) {
            const className = `pt-3 ${field !== "assetName" ? 'text-end pe-2' : ''}`

            return <ThemedSpan key={`${fund?.assetGroupId}-${field}`} variant={isAverage ? "muted" : "dark"} className={className}>
                <strong>{formattedValue}</strong>
            </ThemedSpan>
        }

        const transformedValue = transformValue == null ? value : transformValue({ value, isBest, isWorst, assetGroupId: fund.assetGroupId });

        return <span key={`${fund?.assetGroupId}=${field}`} className="my-auto text-truncate d-block">{transformedValue}</span>
    })
}

export default FundAnalysisRow;
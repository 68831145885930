import styled from "styled-components";
import { ThemedSpan } from "../../../components/utilities";
import useFeeTotals from "../hooks/useFeeTotals";
import { NumericFormat } from "react-number-format";
import { Spinner } from "../../../components/loaders";
import { ErrorMessage } from "../../../components/messages";
import React from "react";

const FeeTotalsWrapper = styled.div`
    display: flex;
    font-size: 16px;
    & > span:not(:last-child) {
        margin-right: 4rem;
    }
    color: #fff !important;
`;

const RetryClickFont = styled.a`
    color: #ddd !important;
`;

const FeeTotals = () => {

    const { data, isFetching, isLoading, isError, error, retryGetFeeTotals } = useFeeTotals();
    const { totalFees, totalVat, outstandingDebt } = data || {};

    if (isError) {
        return <FeeTotalsWrapper>
            <span>Sorry! We were unable to load the fee totals. <RetryClickFont href="#" onClick={retryGetFeeTotals}>Click here to retry.</RetryClickFont></span>
        </FeeTotalsWrapper>
    }

    return <FeeTotalsWrapper>
        {isFetching && <Spinner className="me-3"/>}
        {!isLoading && <React.Fragment>
            <span>Total Fees: <NumericFormat value={totalFees} decimalScale={2} prefix="£" thousandSeparator displayType="text" /></span>
            <span>Total VAT: <NumericFormat value={totalVat} decimalScale={2} prefix="£" thousandSeparator displayType="text" /></span>
            <span>Outstanding Debt: <NumericFormat value={outstandingDebt} decimalScale={2} prefix="£" thousandSeparator displayType="text" /></span></React.Fragment>}
    </FeeTotalsWrapper>;
};

export default FeeTotals;
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import { ActionIcon, ThemedSpan } from "../../../components/utilities";
import useOpenMasterAccount from "../../../hooks/useOpenMasterAccount";
import { queryTypes } from "../../../services/usernotifications";
import useNotifications from "../hooks/useNotifications";
import NotificationOptionsDropdown from "./NotificationOptionsDropdown";
import NotificationsSection from "./NotificationsSection";

export const ReportNotificationsListItem = ({ notification, onToggle, readFn, ...rest }) => {
    const openAccount = useOpenMasterAccount();
    const { masterAccountName, accountReference, senderName, sentOnDate, statusText, message, masterAccountId, reportId } = notification || {};

    const onAccountOpenClicked = () => {
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }

        openAccount(masterAccountId, { suffix: `reports?masterAccountId=${masterAccountId}&reportId=${reportId}` });
    };

    return <div className="row" {...rest}>
        <div className="col-md-auto">
            <ActionIcon icon="fa-folder-open" onClick={onAccountOpenClicked} />
        </div>
        <div className="col-md-auto font-monospace">{accountReference}</div>
        <div className="col-3">{masterAccountName}</div>
        <div className="col-2">{statusText}</div>
        <div className="col text-truncate">
            <OverlayTrigger placement="left" overlay={<Tooltip className="position-fixed">
                {message}
            </Tooltip>}>
                <ThemedSpan>{message}</ThemedSpan>
            </OverlayTrigger>
        </div>
        <div className="col-auto text-end">
            <Moment fromNow>{sentOnDate}</Moment>
        </div>
        <div className="col-md-auto">
            <NotificationOptionsDropdown notification={notification} readFn={readFn} />
        </div>
    </div>
};

const ReportNotificationsList = (props) => {
    const { onToggle } = props;

    return <NotificationsSection
        label="Report Messages"
        hook={useNotifications}
        queryType={queryTypes.REPORT}
        RecordItem={ReportNotificationsListItem}
        recordItemProps={{ onToggle }}
        {...props}
    />;
}

export default ReportNotificationsList;
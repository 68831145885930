import React, { useState } from "react";
import EditableMessage from "./EditableMessage";
import { useMessageThreadContext } from "../hooks/MessageThreadContext";
import toast from "react-hot-toast";

const DraftMessage = React.forwardRef(({
    message,
    onDelete
}, ref) => {

    const {
        messageId,
        threadId,
        message: messageText
    } = message || {};

    const { send, updateDraftRecipients, updateDraft, uploadMessageAttachment, updateThread } = useMessageThreadContext();
    const [isEditing, setIsEditing] = useState(false);

    const handleMessageOnChange = (e) => {
        if (e.target.value === messageText) {
            setIsEditing(_ => false);
        }
        else {
            setIsEditing(_ => true);
        }
    }

    const handleMessageOnBlur = (value) => {
        return updateDraft(messageId, "message", value)
            .then(
                r => setIsEditing(_ => false)
            );
    };

    const handlePriorityOnChange = ({ value }) => {
        setIsEditing(_ => true);
        updateDraft(messageId, "messagePriority", value)
            .then(
                r => setIsEditing(_ => false)
            );
    };

    const handleProjectOnChange = ({ value }) => {
        setIsEditing(_ => true);
        updateThread("projectId", value)
            .then(
                r => setIsEditing(_ => false)
            );
    }

    const handleRecipientsOnChange = (value) => {
        setIsEditing(_ => true)
        return updateDraftRecipients(messageId, value)
            .then(
                r => setIsEditing(_ => false)
            );
    };

    const handleRequiresActionOnChange = (value) => {
        setIsEditing(_ => true);
        updateDraft(messageId, "isActionRequired", value)
            .then(
                r => setIsEditing(_ => false)
            );
    };

    const handleSendOnClick = () => {
        toast.promise(send(messageId), {
            loading: "Sending message...",
            success: "Message sent!",
            error: err => err?.data ?? err?.error ?? "Unable to send message."
        });
    };

    const handleThreadSubjectOnBlur = (value) => {
        setIsEditing(_ => true);
        return updateDraft(threadId, "subject", value)
            .then(
                r => setIsEditing(_ => false)
            );
    };

    const handleUploadAttachments = (files, info, props = {}) => {
        setIsEditing(true);


        return toast.promise(uploadMessageAttachment(messageId, files, info, props), {
            loading: "Uploading attachment...",
            error: "Unable to upload attachment",
            success: "Attachment uploaded."
        });
    };

    return <EditableMessage
        ref={ref}
        isDraftMessage={true}
        message={message}
        isEditing={isEditing}
        threadSubjectOnBlur={handleThreadSubjectOnBlur}
        messageOnBlur={handleMessageOnBlur}
        messageOnChange={handleMessageOnChange}
        recipientsOnChange={handleRecipientsOnChange}
        requiresActionOnChange={handleRequiresActionOnChange}
        priorityOnChange={handlePriorityOnChange}
        projectOnChange={handleProjectOnChange}
        onSend={handleSendOnClick}
        onDelete={onDelete}
        onAttachmentUpload={handleUploadAttachments}
    />;
});

export default DraftMessage;
import React, { useCallback, useEffect } from "react";
import { DivestTransactionTable, Income, ProductSelection } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

const TaxFreeCashPensionIncome = ({ instructionId }) => {
    const [{ fetchInvest, fetchDivest }, { investIsError, divestIsError }] = useInstruction();

    useEffect(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId });
    }, [fetchDivest, fetchInvest, instructionId]);

    const retry = useCallback(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId });
    }, [fetchDivest, fetchInvest, instructionId]);

    if (investIsError || divestIsError)
        return <InvestDivestErrorMessage retry={retry} />;

    return <React.Fragment>
        <ProductSelection />
        <DivestTransactionTable isSelling />
        <Income taxFreeCash trusteeCashAccount cashAccount />
    </React.Fragment>;
}

export default TaxFreeCashPensionIncome;
import React from "react";
import { Modal } from "react-bootstrap";
import { Button, IconButton } from "../../../components";
import { FormSelect, FormCheck } from "../../../components/forms";

const SendMessageConfirmationModal = ({
    onClose,
    onSend,
    onPriorityChange,
    onRequiresActionChange,
    priority,
    requiresAction,
    show,
}) => {

    return <Modal centered backdrop="static" show={show}>
        <Modal.Header>
            <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row mb-3">
                <FormSelect
                    label={"Priority"}
                    options={["Low Priority", "Medium Priority", "High Priority"].map((label, value) => ({ label, value: (value + 1) * 10 }))}
                    placeholder="Priority..."
                    onChange={onPriorityChange}
                    defaultValue={priority}
                />
            </div>

            <div className="row">
                <FormCheck
                    className="d-flex align-items-center"
                    label={"Is an action required?"}
                    onChange={onRequiresActionChange}
                    isChecked={requiresAction}
                />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <IconButton icon={"fa-paper-plane"} variant="primary" onClick={onSend}></IconButton>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>

};

export default SendMessageConfirmationModal;
import React from "react";
import toast from "react-hot-toast";
import useEditIntroducer from "../hooks/useEditIntroducer";
import IntroducerForm from "./IntroducerForm";
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "react-bootstrap";
import { Button } from "../../../components";

const EditIntroducerModal = ({ 
    show,
    introducer = null,
    handleClose = () => {},
    onUpdatedEvent = () => {}
}) => {
    const { update, defaultValues, isLoading, isAllowedToUpdateStatus } = useEditIntroducer(introducer);
    const { control, formState, getValues, handleSubmit, reset, setValue, watch } = useForm({ defaultValues });
    const { errors } = formState;

    const _close = () => {
        reset(defaultValues);
        handleClose();
    };

    const _closeNoChange = () => {
        toast('No changes detected. Introducer was not updated.', { icon: '⚠️' });
        handleClose();
    };

    const _submit = (_) => {
        const { dirtyFields } = formState;
        if (!dirtyFields || Object.keys(dirtyFields).length === 0) {
            _closeNoChange();
            return;
        }

        const operations = Object.keys(dirtyFields).map(el => {
            return { op: 'replace', path: `/${el}`, value: getValues(el) };
        });

        if (!operations || operations.length === 0) {
            _closeNoChange();
            return;
        }

        toast.promise(update(operations), {
            loading: 'Updating Introducer...',
            success: (r) => {
                onUpdatedEvent(r);
                return `Introducer was updated successfully.`;
            },
            error: (err) => {
                return `There was a problem trying to update the Introducer.`;
            }
        }).finally(_close);
    };

    React.useEffect(() => {
        reset(defaultValues);
    }, [ defaultValues ]);

    return (
        <Modal size="xl" centered backdrop="static" show={show && introducer} onHide={_close}>
            <Modal.Header>
                <Modal.Title>Edit Introducer</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(_submit)}>
                <ModalBody>
                    <IntroducerForm 
                        control={control}
                        errors={errors}
                        isAllowedToUpdateStatus={isAllowedToUpdateStatus}
                        setValue={setValue}
                        watch={watch}
                    />
                </ModalBody>
                <Modal.Footer>
                    <Button variant="muted" disabled={isLoading === true} onClick={() => reset(defaultValues)}>Clear Changes</Button>
                    <Button variant="success" type="submit" disabled={isLoading === true}>Update</Button>
                    <Button variant="light" disabled={isLoading === true} onClick={_close}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditIntroducerModal;
import React from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/loaders";
import { CenterPage, Main } from "../../components/utilities";
import "../../services/recommendations";
import { Instructions } from "./components";
import BizSheetTopBar from "./components/BizSheetTopBar";
import { AllBizSheetsErrorMessage } from "./components/ErrorMessages";
import { BizSheetProvider } from "./contexts/BizSheetContext";
import useFetchDropdownOptions from "./hooks/useFetchDropdownOptions";

const ClientBizSheets = () => {

    const { masterAccountId } = useParams();
    const {
        instructionTypes,
        isLoading,
        isFetching,
        isError,
        retry
    } = useFetchDropdownOptions(masterAccountId);

    return <Main className="p-3">
        {(isFetching || isLoading) ?
            <CenterPage>
                <Spinner />
            </CenterPage>
            : isError
                ? <AllBizSheetsErrorMessage retry={retry} />
                : instructionTypes
                    ? <BizSheetProvider>
                        <BizSheetTopBar
                            instructionTypes={instructionTypes}
                        />
                        <Instructions />
                    </BizSheetProvider>
                    : <></>}
    </Main>;
};

export default ClientBizSheets;
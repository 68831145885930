import React, { useState } from "react";
import withProviders from "../hooks/withProviders";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useFilterContext } from "../hooks/FilterContext";
import { ReviewManagementFiltersModal, ReviewManagementFiltersModalButton } from "../features/reviewmanagementfilters";
import { ClientReviewManagementPageSubHeader, Main, ThemedIcon, ThemedParagraph } from "../components/utilities";
import PageHeader, { PageHeaderTop, PageHeaderBottom } from "../components/layout/PageHeader";
import { ManagementReviewRouteTabs } from "../routes/management.reviews.routes";
import { EditReviewProvider } from "../features/reviewmanagementeditmodal/hooks/EditReviewContext";
import { FilterProvider, ReviewManagementDefaultFilters } from "../hooks/FilterContext";

const ReviewManagementHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const ReviewManagementFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const ClientReviewManagementLayout = () => {

    const [isFiltersShown, setIsFiltersShown] = useState(false);
    const { clearFilter, filter: { masterAccountIds }, isFiltersApplied, setFilter } = useFilterContext();

    return <React.Fragment>
        <ReviewManagementFiltersModal 
            handleClose={() => setIsFiltersShown(false)}
            show={isFiltersShown}
        />
        <PageHeader>
            <PageHeaderTop>
                <ReviewManagementHeaderTitle>
                    <span>Service Reviews Management</span>
                </ReviewManagementHeaderTitle>
            </PageHeaderTop>
            <PageHeaderBottom>
                <ManagementReviewRouteTabs />
            </PageHeaderBottom>
        </PageHeader>
        <Main className="d-flex flex-column">
            <ClientReviewManagementPageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <ReviewManagementFiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                </ReviewManagementFiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex flex-fill justify-content-end align-items-center">
                        <ReviewManagementFiltersModalButton onClick={() => setIsFiltersShown(true)} />
                    </div>
                </div>
            </ClientReviewManagementPageSubHeader>
            <section className="px-4 my-3 h-100">
                <EditReviewProvider>
                    <Outlet />
                </EditReviewProvider>
            </section>
        </Main>
    </React.Fragment>
}

export default withProviders([FilterProvider, { filterStore: "reviews", defaultFilters: ReviewManagementDefaultFilters }])(ClientReviewManagementLayout);
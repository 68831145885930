import { useUpdateAuthenticatorMutation } from "../../../services/clientauth";

const useUpdateLoginAuthenticator = () => {
    const [_update, { isLoading, ...updateResult }] = useUpdateAuthenticatorMutation();

    const update = (masterAccountId, loginId, data) => {
        return new Promise((resolve, reject) => {
            return _update({ masterAccountId, loginId, data })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { update, isLoading, data: { isLoading, ...updateResult } };
};

export default useUpdateLoginAuthenticator;
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";
import PersonalReminderTableRow from "./components/PersonalReminderTableRow";
import { usePersonalReminders } from "./hooks/PersonalRemindersContext";
import PersonalReminderTableHeader from "./components/PersonalReminderTableHeader";
import React from "react";
import EditPersonalReminderModal from "./components/EditPersonalReminderModal";

const PersonalReminders = () => {

    const { clear: refreshTable, error, isError, noResults, results, totalCount, hasItemLoaded, loadMoreItems, clear } = usePersonalReminders();

    if (isError) {
        return (
            <ErrorMessageWrapper>
                <ErrorMessage error={error} retryCallback={clear} />
            </ErrorMessageWrapper>
        );
    }

    return (<React.Fragment>
        <VirtualTable
            enableScrollTracking={false}
            loadMoreItems={loadMoreItems}
            itemSize={42}
            itemCount={totalCount === results.length ? totalCount : results.length + 25}
            itemData={results}
            isItemLoaded={hasItemLoaded}
            row={PersonalReminderTableRow}
            header={<PersonalReminderTableHeader />}
            footer={
                <TableRecordCountDisplayFooter 
                    totalCount={totalCount}
                    isNewRecordsDisplayed={false}
                    newRecordsSinceCache={0}
                    reload={refreshTable}
                    spanRight={6}
                />
            }
        />
        <EditPersonalReminderModal />
    </React.Fragment>
    );
};

export default PersonalReminders;
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import { Main } from "../../../components/utilities";
import { useCorporateActionPairs } from "../hooks/CorporateActionPairsContext";
import CorporateActionPairsGridHeader from "./CorporateActionPairsGridHeader";
import CorporateActionPairsGridRow from "./CorporateActionPairsGridRow";


const CorporateActionPairsGrid = () => {

    const {
        results,
        isItemLoaded,
        loadMoreItems,
        error,
        isError,
        totalCount,
        refetch,
    } = useCorporateActionPairs();


    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetch} />
        </ErrorMessageWrapper>
    }

    return (
        <VirtualTable
            header={<CorporateActionPairsGridHeader />}
            row={CorporateActionPairsGridRow}
            itemData={results}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            itemCount={totalCount > results.length ? results.length + 10 : totalCount}
            itemSize={42}
        />
    )
};

export default CorporateActionPairsGrid;
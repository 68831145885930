import { 
    usePatchClientFeeBreakdownMutation,
    useCreateClientFeeBreakdownMutation,
    useDeleteClientFeeBreakdownMutation
} from "../../../services/clientserviceterms";


const useClientFeeBreakdown = (masterAccountId) => {

    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchClientFeeBreakdownMutation();
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateClientFeeBreakdownMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteClientFeeBreakdownMutation();

    const createClientFeeBreakdown = (e, clientServiceAgreementId) => {

        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        let breakdown = {
            clientSlaId : clientServiceAgreementId,
            portfolioBreakdown: "",
            ownCustodyRate: 0,
            portfolioBreakdownValue: 0,
            feeType: 0,
            feeRate: 0,
            annualAdviceFee: 0,
            modifiedById: null,
            modifiedDate: null,
            isActive: true
        };

        return new Promise((resolve, reject) => {
            return create({ masterAccountId, data: breakdown }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateClientFeeBreakdown = (clientFeeBreakdownId, property, value) => {
        return new Promise((resolve, reject) => {

            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            return patch({ clientFeeBreakdownId, masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateFeeBreakdownValues = (clientFeeBreakdownId, operations) => {
        return new Promise((resolve, reject) => {

            return patch({ clientFeeBreakdownId, masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteClientFeeBreakdown = (clientFeeBreakdownId) => {
        return new Promise((resolve, reject) => {
            return remove({ clientFeeBreakdownId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        updateClientFeeBreakdown,
        updateFeeBreakdownValues,
        createClientFeeBreakdown,
        isCreating,
        deleteClientFeeBreakdown
    }

};

export default useClientFeeBreakdown;
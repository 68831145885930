import { applyPatch } from 'fast-json-patch';
import { wealthProApi } from '../../redux/api';

const hoAdminFeeTransactionsApiEndpoints = wealthProApi
    .injectEndpoints({
        endpoints: (builder) => ({
            createFeeTransaction: builder.mutation({
                query: ({ request }) => ({
                    url: `api/headofficeadmin/feetransactions`,
                    method: 'POST',
                    body: request
                }),
                async onQueryStarted({ request, filter }, { dispatch, queryFulfilled }) {

                    const result = dispatch(wealthProApi.util.updateQueryData('searchFeeGroups', filter, ({ results }) => {
                        let item = results.find(x => x.groupId === request.groupId);

                        if (request.feeTransactionType?.debtorMultiplier > 0) {
                            item.totalFeeValuePounds += request.totalFeeAmount;
                            item.totalVatValuePounds += request.totalVatAmount;
                        }

                        item.totalDebtValuePounds += (request.totalFeeAmount + request.totalVatAmount) * request.feeTransactionType?.debtorMultiplier;
                    }));

                    try {
                        await queryFulfilled;
                    }
                    catch {
                        result.undo();
                    }
                },
                invalidatesTags: ["applicationFees"]
            }),
            deleteFeeTransaction: builder.mutation({
                query: ({ feeTransactionId }) => ({
                    url: `api/headofficeadmin/feetransactions/${feeTransactionId}`,
                    method: 'DELETE'
                }),
                async onQueryStarted({ previousValues, feeTransactionId, filter, groupId }, { dispatch, queryFulfilled }) {
                    if (!previousValues || !filter || !groupId) return;

                    const result = dispatch(wealthProApi.util.updateQueryData('searchFeeGroups', filter, ({ results }) => {
                        var newFeeAllocations = previousValues.filter(x => x.feeTransactionId !== feeTransactionId);
                        var group = results.find(x => x.groupId === groupId);

                        group.totalFeeValuePounds = newFeeAllocations.reduce((acc, curr) => acc + (curr.debtorMultiplier === 1 ? curr.feeValuePounds : 0), 0);
                        group.totalVatValuePounds = newFeeAllocations.reduce((acc, curr) => acc + (curr.debtorMultiplier === 1 ? curr.vatValuePounds : 0), 0);
                        group.totalDebtValuePounds = newFeeAllocations.reduce((acc, curr) => acc + ((curr.feeValuePounds + curr.vatValuePounds) * curr.debtorMultiplier), 0);

                    }));

                    try {
                        await queryFulfilled;
                    }
                    catch {
                        result.undo();
                    }
                },
                invalidatesTags: ["applicationFees"]
            }),
            getFeeTransactionById: builder.query({
                query: ({ feeTransactionId }) => `api/headofficeadmin/feetransactions/${feeTransactionId}`
            }),
            updateFeeTransaction: builder.mutation({
                query: ({ feeTransactionId, request }) => ({
                    url: `api/headofficeadmin/feetransactions/${feeTransactionId}`,
                    method: 'PATCH',
                    body: request
                }),
                async onQueryStarted({ feeTransactionId, request, previousValues, filter }, { dispatch, queryFulfilled }) {

                    const result = dispatch(wealthProApi.util.updateQueryData('searchFeeGroups', filter, ({ results }) => {
                        let item = results.find(x => x.groupId === request.groupId);

                        if (request.feeTransactionType?.debtorMultiplier > 0) {
                            item.totalFeeValuePounds += (request.totalFeeAmount - previousValues.totalFeeAmount);
                            item.totalVatValuePounds += (request.totalVatAmount - previousValues.totalVatAmount);
                        }

                        var totalDifference = (request.totalFeeAmount + request.totalVatAmount) - (previousValues.totalFeeAmount + previousValues.totalVatAmount);
                        item.totalDebtValuePounds += totalDifference * request.feeTransactionType?.debtorMultiplier;
                    }));

                    try {
                        await queryFulfilled;
                    }
                    catch {
                        result.undo();
                    }
                },
                invalidatesTags: ["applicationFees"]
            })
        })
    });

export const {
    useCreateFeeTransactionMutation,
    useDeleteFeeTransactionMutation,
    useGetFeeTransactionByIdQuery,
    useLazyGetFeeTransactionByIdQuery,
    useUpdateFeeTransactionMutation
} = hoAdminFeeTransactionsApiEndpoints;
import { Spinner } from "../../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { CenterPage } from "../../../components/utilities";
import useEmail from "../hooks/useEmail";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import EmailHeader from "./EmailHeader";
import React from "react";
import DraftEmail from "./DraftEmail";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components";
import SentEmail from "./SentEmail";

const EmailContainer = styled.div`
    overflow-y: auto;
`;

const Email = () => {

    const { mailId } = usePersonalOutlookContext();
    const {
        data,
        fileMailResult,
        isLoading,
        error,
        isError,
        reload,
        fileMail
    } = useEmail(mailId);

    const {
        body,
        isDraft,
        attachments
    } = data;

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={reload} />
        </ErrorMessageWrapper>;
    }

    if (isLoading) {
        return <CenterPage>
            <Spinner />
        </CenterPage>;
    }

    return <AutoSizer>
        {({ height, width }) => (
            <EmailContainer className="p-3" style={{ height, width }}>
                {isDraft === true && <DraftEmail data={data} />}
                {isDraft === false && <React.Fragment>
                    <EmailHeader
                        data={data}
                        fileMail={fileMail}
                        fileMailResult={fileMailResult}
                    />
                    <br />
                    <SentEmail attachments={attachments} body={body} />
                </React.Fragment>}

            </EmailContainer>
        )}
    </AutoSizer>;
};

export default Email;
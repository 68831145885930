import useTransactionFeeAllocations from "../hooks/useTransactionFeeAllocations";
import Skeleton from "react-loading-skeleton";
import StandardTable from "../../../components/tables/StandardTable";
import { IconButton } from "../../../components";
import BasicFetchError from "../../clientdetails/components/BasicFetchError";
import { CurrencyInput, DateInput, FormInput, FormSelect } from "../../../components/forms";
import useFeeTransactionTypes from "../hooks/useFeeTransactionTypes";
import React, { useMemo, useState } from "react";
import DeleteFeeTransactionModal from "./DeleteFeeTransactionModal";
import TransactionFeeAllocationOptionsDropdown from "./TransactionFeeAllocationOptionsDropdown";

const TransactionFeeAllocationsTableEmptyRow = ({
    columns,
    onCreateClicked,
    ...rest
}) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            There are no fee allocations available for this fee group.
            You can click <a href="#" onClick={onCreateClicked}>here</a> to create one.
        </td>
    </tr>
};

const TransactionFeeAllocationsTableLoadingRow = ({ columns, loadingLines, ...rest }) => {
    return [...Array(loadingLines)].map((_, i) => (
        <tr key={`loading-${i}`} {...rest}>
            <td colSpan={columns.length}>
                <Skeleton count={1} height={36} />
            </td>
        </tr>
    ));
};

const TransactionFeeAllocationsTableDataRow = ({
    columns,
    data,
    onDefaultChanged,
    onDeleteClicked,
    onEditClicked,
    onFieldChanged,
    feeTransactionTypes,
    isLoadingFeeTransactionTypes,
    ...rest
}) => {

    const {
        allocationId,
        feeTransactionId,
        transactionTypeId,
        transactionTypeDescription,
        attributeToId,
        attributedToName,
        feeDate,
        feeReference,
        feeValuePounds,
        vatValuePounds,
        comment,
        createdById,
        createdByName
    } = data;

    const hasMonthClosed = useMemo(() => {
        const date = new Date(feeDate);
        const currentDate = new Date();
        return date.getMonth() < currentDate.getMonth() && date.getFullYear() <= currentDate.getFullYear();
    }, [feeDate]);

    return <tr {...rest}>
        <td className="col">
            <FormInput
                value={transactionTypeDescription}
                disabled
            />
        </td>
        <td className="col">
            <FormInput
                value={attributedToName}
                disabled
            />
        </td>
        <td className="col">
            <DateInput
                value={feeDate}
                ButtonComponent={null}
                disabled
            />
        </td>
        <td className="col-auto">
            <FormInput
                value={feeReference}
                disabled
            />
        </td>
        <td className="col">
            <CurrencyInput
                value={feeValuePounds}
                disabled
            />
        </td>
        <td className="col">
            <CurrencyInput
                value={vatValuePounds}
                disabled
            />
        </td>
        <td className="col">
            <FormInput
                value={comment}
                disabled
            />
        </td>
        <td className="col">
            <FormInput
                value={createdByName}
                disabled
            />
        </td>
        {!hasMonthClosed === true && <TransactionFeeAllocationOptionsDropdown 
                onEdit={(e) => onEditClicked(e, feeTransactionId)}
                onDelete={() => onDeleteClicked(feeTransactionId)}
            />
       }
        {/* {!hasMonthClosed === true && (
            <td className="col-md-auto">
                <IconButton icon="fa-pen-to-square" variant="warning" onClick={(e) => onEditClicked(e, feeTransactionId)} />
            </td>
        )}
        {!hasMonthClosed === true && (
            <td className="col-md-auto">
                <IconButton icon="fa-trash-can" variant="danger" onClick={() => onDeleteClicked(feeTransactionId)} />
            </td>
        )} */}
    </tr>
};

const TransactionFeeAllocations = ({ groupId, openCreateModal, openEditModal, loadingLines = 4, useAllocations = useTransactionFeeAllocations }) => {

    const {
        data,
        isLoading,
        isFetching,
        isError,
        error,
        deleteAllocationsForFee,
        retryFetchTransactionFeeAllocations
    } = useAllocations(groupId);

    const { data: feeTransactionTypes, isLoading: isLoadingFeeTransactionTypes } = useFeeTransactionTypes();
    const [feeTransactionToDelete, setFeeTransactionToDelete] = useState(null);

    if (error || isError === true) {
        <BasicFetchError error={error} retryCallback={retryFetchTransactionFeeAllocations} />
    }

    return <React.Fragment>
        <StandardTable
            // need margin here as dropdown menu is forcing scroll
            // TODO - find more elegant method to fix this
            className="table-borderless mb-5 mt-4"
            columns={[
                { label: 'Transaction Type', property: 'transactionTypeDescription' },
                { label: 'Attribute', property: 'attributeToName' },
                { label: 'Date', property: 'feeDate' },
                { label: 'Ref.', property: 'feeReference' },
                { label: 'Fee (£)', property: 'feeValuePounds' },
                { label: 'VAT (£)', property: 'vatValuePounds' },
                { label: 'Comment', property: 'comment' },
                { label: 'Recorded By', property: 'createdByName' },
                {
                    label: '',
                    property: '',
                    headerRenderer: (props) => (
                        <td key={props.key} style={{ width: '32px' }}>
                            <IconButton icon="fa-plus" variant="success" onClick={openCreateModal} />
                        </td>
                    )
                }
            ]}
            data={data}
            error={error}
            isLoading={isLoading}
            hideHeaderOnEmpty={true}
            EmptyRowComponent={TransactionFeeAllocationsTableEmptyRow}
            emptyRowComponentProps={{ onCreateClicked: openCreateModal }}
            LoadingRowComponent={TransactionFeeAllocationsTableLoadingRow}
            loadingRowComponentProps={{ loadingLines }}
            RowComponent={TransactionFeeAllocationsTableDataRow}
            rowComponentProps={{
                feeTransactionTypes,
                isLoadingFeeTransactionTypes,
                onEditClicked: openEditModal,
                onDeleteClicked: setFeeTransactionToDelete
            }}
        />
        <DeleteFeeTransactionModal
            show={feeTransactionToDelete !== null}
            onClose={() => setFeeTransactionToDelete(null)}
            onDelete={() => deleteAllocationsForFee(feeTransactionToDelete)}
        />
    </React.Fragment>;
};

export default TransactionFeeAllocations;
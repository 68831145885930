import React, { useState } from "react";
import styled from "styled-components";
import RemindersGrid from "./components/RemindersGrid";
import { AdministrationRemindersFiltersModal, AdministrationRemindersFiltersModalButton } from "../administrationremindersfilters";
import { useFilterContext } from "../../hooks/FilterContext";
import PageHeader from "../../components/layout/PageHeader";
import {  AdministrationRemindersPageSubHeader, Main, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import RemindersGridProvider from "./hooks/RemindersGridContext";

const RemindersHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const RemindersFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const PageHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

const AdministrationReminders = () => {

    const [isFiltersShown, setIsFiltersShown] = useState(false);
    const {
        clearFilter,
        filter,
        isFiltersApplied,
        setFilter,
    } = useFilterContext();

    return (
        <React.Fragment>
            <AdministrationRemindersFiltersModal
                handleClose={() => setIsFiltersShown(false)}
                show={isFiltersShown}
            />
            <PageHeader>
                <PageHeaderTop>
                    <RemindersHeaderTitle>
                        <span>Reminders</span>
                    </RemindersHeaderTitle>
                </PageHeaderTop>
            </PageHeader>
            <Main className="d-flex flex-column">
                <AdministrationRemindersPageSubHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            {isFiltersApplied && (
                                <React.Fragment>
                                    <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                    <RemindersFiltersAppliedWarningMessage className="ms-2 text-muted">
                                        Warning - Filters are currently being applied to your results.
                                        <br />
                                        <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                    </RemindersFiltersAppliedWarningMessage>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex flex-fill justify-content-end align-items-center">
                            <AdministrationRemindersFiltersModalButton
                                onClick={() => setIsFiltersShown(true)}
                            />
                        </div>
                    </div>
                </AdministrationRemindersPageSubHeader>
                <section className="px-4 my-3 h-100">
                    <RemindersGridProvider>
                        <RemindersGrid />
                    </RemindersGridProvider>
                </section>
            </Main>
        </React.Fragment>
    );
};


export default AdministrationReminders;

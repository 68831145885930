import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useCreatePolicyCoverMutation, useDeletePolicyCoverMutation, useFetchPolicyCoversQuery, usePatchPolicyCoverMutation, useUpdateChosenCoverMappingsMutation, useUpdatePolicyClientMappingsMutation } from "../../../services/clientfactfind";

const usePolicyCovers = ({ protectionId, isCurrentPriority }) => {
    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isFetching, isUninitalized, isError, error, refetch } = useFetchPolicyCoversQuery({ protectionId, isCurrentPriority });
    const [create] = useCreatePolicyCoverMutation();
    const [patch] = usePatchPolicyCoverMutation();
    const [remove] = useDeletePolicyCoverMutation();
    const [updateClients] = useUpdatePolicyClientMappingsMutation();
    const [updateCover] = useUpdateChosenCoverMappingsMutation();

    const createPolicyCover = () => {
        return new Promise((resolve, reject) => {
            return create({ masterAccountId, isCurrentPriority, protectionId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const removePolicyCover = (policyId) => {
        return new Promise((resolve, reject) => {
            return remove({ policyId, protectionId, isCurrentPriority })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updatePolicyCover = (policyId, property, value, productTypeProperties) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return patch({ policyId, operations, protectionId, isCurrentPriority, productTypeProperties })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updateChosenCoverMappings = (policyId, ids) => {
        return new Promise((resolve, reject) => {
            return updateCover({ policyId, ids, protectionId, isCurrentPriority })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updatePolicyClientsMappings = (policyId, ids) => {
        return new Promise((resolve, reject) => {
            return updateClients({ policyId, ids, protectionId, isCurrentPriority })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        policyCovers: data || [],
        isLoading: isLoading || isFetching || isUninitalized,
        isError,
        error,
        refetchPolicyCovers: refetch,
        createPolicyCover,
        removePolicyCover,
        updatePolicyCover,
        updateChosenCoverMappings,
        updatePolicyClientsMappings
    };
};

export default usePolicyCovers;
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import GridWrapper from "../../../components/GridWrapper";
import { FormLabel, FormSelect } from "../../../components/forms";
import { NotesEditor } from "../../../components/forms/richtexteditor/RichTextEditor";
import { FormInfoBubbleWrapper, IncomeColumn, SectionHeader } from "../components";
import { useIncomeDetails } from "../contexts/IncomeDetailsContext";
import Skeleton from "react-loading-skeleton";

const TaxPayerStatusSpan = styled.span`
    max-width: 25ch;
`;

const scottishTaxPayerStatuses = [
    { label: "Starter Rate", value: 1 },
    { label: "Basic Rate", value: 7 },
    { label: "Intermediate Rate", value: 2 },
    { label: "Higher Rate", value: 5 },
    { label: "Advanced Rate", value: 3 },
    { label: "Top Rate", value: 4 }
];

const otherUkTaxPayerStatuses = [
    { label: "Basic Rate", value: 7 },
    { label: "Higher Rate", value: 5 },
    { label: "Additional Rate", value: 6 }
]

const IncomeDetailsSection = () => {
    const [{
        patchClientService,
        patchNotes
    }, {
        section,
        filterJoint,
        isLoading,
        employmentProperties,
        otherProperties,
        netMonthlyProperties,
        taxResidencyOptions
    }] = useIncomeDetails();

    const gridTemplateColumns = useMemo(() => `auto repeat(${(section?.clients?.length ?? 1) + (filterJoint ? 0 : 1)}, minmax(10ch, auto))`, [filterJoint, section?.clients?.length]);

    const getTaxPayerStatuses = useCallback((taxResidency) => [
        { label: "Non Tax Payer", value: 0 },
        ...(taxResidency === 0 // Scottish
            ? scottishTaxPayerStatuses
            : taxResidency === 1 // Other UK
                ? otherUkTaxPayerStatuses
                : [])
    ], []);

    const employmentIncomeRowsStart = useMemo(() => 2, []);
    const otherIncomeRowsStart = useMemo(() => employmentIncomeRowsStart
        + Object.keys(employmentProperties).length
        + 3, [employmentIncomeRowsStart, employmentProperties]);
    const taxPayerStatusRow = useMemo(() => otherIncomeRowsStart
        + Object.keys(otherProperties).length
        + 1, [otherIncomeRowsStart, otherProperties]);
    const netMonthlyRowsStart = useMemo(() => taxPayerStatusRow
        + 4, [taxPayerStatusRow]);

    const indexStart = useMemo(() => filterJoint ? 0 : 1, [filterJoint]);
    const colStart = useMemo(() => filterJoint ? 2 : 3, [filterJoint]);

    return <>
        <GridWrapper
            className="m-4"
            gridTemplateColumns={gridTemplateColumns}
        >
            {/* Employment Income Section */}
            <span className={`row-start-${employmentIncomeRowsStart - 1} col-start-1`}>
                <SectionHeader className="mt-2">
                    Employment Income (Gross Annual)
                </SectionHeader>
            </span>

            {/* Labels */}
            {Object.keys(employmentProperties)?.map((property, index) => <span className={`row-start-${employmentIncomeRowsStart + index} col-start-1`} key={index}>
                <FormLabel className="mt-2">
                    {employmentProperties[property].label}
                </FormLabel>
            </span>)}

            {section
                ?.clients
                ?.map((client, index) => <IncomeColumn
                    key={client?.clientAccountId ?? index}
                    clientId={client?.clientAccountId}
                    index={index + indexStart}
                    propertyInfo={employmentProperties}
                    rowStart={employmentIncomeRowsStart}
                    column={index + colStart}
                />)}

            {/* Other Income Section */}
            <span className={`row-start-${otherIncomeRowsStart - 1} col-start-1`}>
                <SectionHeader className="mt-2">
                    Other Income (Gross Annual)
                </SectionHeader>
            </span>

            {/* Labels */}
            {Object.keys(otherProperties)?.map((property, index) => <span className={`row-start-${otherIncomeRowsStart + index} col-start-1`} key={index}>
                <FormLabel className="mt-2">
                    {otherProperties[property].label}
                </FormLabel>
            </span>)}

            {!filterJoint && <IncomeColumn
                clientId={'joint'}
                clientName={"Joint Income"}
                index={0}
                propertyInfo={otherProperties}
                rowStart={otherIncomeRowsStart}
                column={2}
            />}

            {section
                ?.clients
                ?.map((client, index) => <IncomeColumn
                    key={client?.clientAccountId ?? index}
                    clientId={client?.clientAccountId}
                    index={index + indexStart}
                    propertyInfo={otherProperties}
                    rowStart={otherIncomeRowsStart}
                    column={index + colStart}
                />)}

            <span className={`row-start-${taxPayerStatusRow} col-start-1`}>
                <FormInfoBubbleWrapper showBubble>
                    <FormLabel className="mt-2">
                        UK Tax Residency:
                    </FormLabel>
                </FormInfoBubbleWrapper>
            </span>

            {section
                ?.clients
                ?.map((client, index) => <TaxPayerStatusSpan className={`row-start-${taxPayerStatusRow} col-start-${index + colStart}`} key={client?.clientAccountId ?? index}>
                    <FormSelect
                        defaultValue={client.ukTaxResidency}
                        options={taxResidencyOptions}
                        onBlur={(selection) => new Promise((resolve, reject) => {
                            patchClientService(client?.clientAccountId, "ukTaxResidency", selection.value)
                                .then(() => {
                                    // Check if the currently selected tax payer status is valid for the new tax residency and reset if not
                                    const validStatuses = getTaxPayerStatuses(selection.value);
                                    if (!validStatuses.some(status => status.value === client.expectedTaxPayerStatus)) {
                                        patchClientService(client?.clientAccountId, "expectedTaxPayerStatus", null)
                                            .then(resolve)
                                            .catch(reject);
                                    } else {
                                        resolve();
                                    }
                                }).catch(reject);
                        })}
                    />
                </TaxPayerStatusSpan>)}

            <span className={`row-start-${taxPayerStatusRow + 1} col-start-1`}>
                <FormInfoBubbleWrapper showBubble>
                    <FormLabel className="mt-2">
                        Expected UK Tax Payer Status:
                    </FormLabel>
                </FormInfoBubbleWrapper>
            </span>

            {section
                ?.clients
                ?.map((client, index) => <TaxPayerStatusSpan className={`row-start-${taxPayerStatusRow + 1} col-start-${index + colStart}`} key={client?.clientAccountId ?? index}>
                    <FormSelect
                        defaultValue={client.expectedTaxPayerStatus}
                        options={getTaxPayerStatuses(client.ukTaxResidency)}
                        onBlur={(selection) => patchClientService(client?.clientAccountId, "expectedTaxPayerStatus", selection.value)}
                    />
                </TaxPayerStatusSpan>)}

            {/* Monthly Net Section */}
            <span className={`row-start-${netMonthlyRowsStart - 1} col-start-1`}  >
                <SectionHeader className="mt-2">
                    Net Income (Monthly)
                </SectionHeader>
            </span>

            {/* Labels */}
            {Object.keys(netMonthlyProperties)?.map((property, index) => <span className={`row-start-${netMonthlyRowsStart + index} col-start-1`} key={index} >
                <FormLabel className="mt-2">
                    {netMonthlyProperties[property].label}
                </FormLabel>
            </span>)}

            {section && !filterJoint && <IncomeColumn
                clientId={'joint'}
                clientName={"Joint Income"}
                index={0}
                propertyInfo={netMonthlyProperties}
                rowStart={netMonthlyRowsStart}
                column={2}
            />}

            {section
                ?.clients
                ?.map((client, index) => <IncomeColumn
                    key={client?.clientAccountId ?? index}
                    clientId={client?.clientAccountId}
                    index={index + indexStart}
                    propertyInfo={netMonthlyProperties}
                    rowStart={netMonthlyRowsStart}
                    column={index + colStart}
                />)}
        </GridWrapper>

        {(isLoading || !section?.additionalNotes)
            ? <Skeleton height={75} count={1} />
            : <NotesEditor
                className="px-3"
                label="Additional Notes"
                isLoading={isLoading}
                property={'incomeAdditionalNotes'}
                defaultValue={section?.additionalNotes?.notes}
                onBlur={newValue => patchNotes(newValue)}
            />}
    </>
}

export default IncomeDetailsSection;
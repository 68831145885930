import { useLazyNewBusinessForecastReportQuery } from "../../../services/headofficeadmin";

const useNewBusinessForecastReport = () => {

    const [report, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyNewBusinessForecastReportQuery();

    const getNewBusinessForecastReport = (theRequest) => {
        const fromDate = theRequest.fromDate;
        const toDate = theRequest.toDate;
        const adviserIds = theRequest.adviserIds;
        const projectStatuses = theRequest.projectStatuses;
        const campaignIds = theRequest.campaignIds;

        return new Promise((resolve, reject) => {
            
            return report({data: {fromDate: fromDate, toDate: toDate, adviserIds: adviserIds, projectStatuses: projectStatuses, campaignIds: campaignIds}}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    return {
        getNewBusinessForecastReport
    }

};

export default useNewBusinessForecastReport;
import styled from "styled-components";

export const PoweredByWealthProLogo = styled.img.attrs(props => ({
    src: '/content/wp-logo-powered.png'
}))`
    max-height: 52px;
`;

const WealthProLogo = styled.img.attrs(props => ({
    src: '/content/wp-logo.png'
}))``;

export default WealthProLogo;
import React from "react";
import styled from "styled-components";
import moment from "moment";
import Moment from "react-moment"
import useProjectDetails from "../hooks/useProjectDetails";
import { CurrencyInput, DateInput, FormInput } from "../../../components/forms";
import { Spinner } from "../../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { AccordionSection, CenterArea } from "../../../components/utilities";
import { useClientContext } from "../../../hooks/ClientContext";
import { 
    AdviceTypesSelect, 
    CampaignSelect,
    CampaignProjectStatusSelect,
    ClientProjectStatusSelect, 
    GenericAdviserSelect, 
    GenericUserSelect 
} from "../../../components/forms/selects";

const ProjectCreatedDateContainer = styled.div`
    color: ${props => props.theme.muted};
`;

const ProjectDetailsForm = ({ dateFormat = "DD/MM/YYYY", project, onFieldChanged, onFieldOptionsChanged }) => {
    const { 
        projectId,
        description,
        status,
        targetDate,
        projectValue,
        comment,
        adviceType,
        adviserId,
        driverId,
        campaignId,
        campaignStatusId,
        createdDate
    } = project || {};
    const { account } = useClientContext();
    const { divisionId, masterAccountId } = account || {};

    const handleFieldChanged = (property, value) => onFieldChanged(masterAccountId, projectId, property, value);
    
    const handleFieldChanges = (options) => onFieldOptionsChanged(masterAccountId, projectId, options);

    return <React.Fragment>
        <div className="row mb-3">
            <div className="col">
                <FormInput
                    label="Description"
                    value={description}
                    placeholder="Project description"
                    onBlur={(value) => handleFieldChanged('description', value)}
                />
            </div>
            <div className="col">
                <ClientProjectStatusSelect
                    label="Status"
                    defaultValue={status}
                    placeholder="Project Status"
                    onBlur={(values) => handleFieldChanged('status', values.value)}
                />
            </div>
            <div className="col">
                <DateInput
                    label="Target Date"
                    placeholder="Target Date"
                    value={targetDate}
                    onBlur={(value, _) => handleFieldChanged('targetDate', value)}
                />
            </div>
            <div className="col">
                <CurrencyInput
                    label="Revenue"
                    value={projectValue}
                    onBlur={(value) => handleFieldChanged('projectValue', value)}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FormInput
                    label="Comment"
                    value={comment}
                    placeholder="Project comment"
                    onBlur={(value) => handleFieldChanged('comment', value)}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <AdviceTypesSelect
                    label="Advice Type"
                    defaultValue={adviceType}
                    placeholder="Project advice type"
                    onBlur={(values) => handleFieldChanged('adviceType', values.value)}
                />
            </div>
            <div className="col">
                <GenericAdviserSelect
                    label="Adviser"
                    defaultValue={adviserId}
                    divisionId={divisionId}
                    placeholder="Adviser for the Project"
                    onBlur={(values) => handleFieldChanged('adviserId', values.value)}
                />
            </div>
            <div className="col">
                <GenericUserSelect
                    label="Driver"
                    defaultValue={driverId}
                    divisionId={divisionId}
                    placeholder="Driver for the Project"
                    onBlur={(values) => handleFieldChanged('driverId', values.value)}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <CampaignSelect
                    label="Campaign"
                    defaultValue={campaignId}
                    placeholder="Project Campaign"
                    onBlur={(values) => handleFieldChanges([
                        { property: `campaignId`, value: values.value },
                        { property: `campaignStatusId`, value: null }
                    ])}
                />
            </div>
            <div className="col">
                <CampaignProjectStatusSelect 
                    label="Campaign Status"
                    campaignId={campaignId}
                    defaultValue={campaignStatusId}
                    placeholder="Campaign Status"
                    onBlur={(values) => handleFieldChanges([
                        { property: `campaignStatusId`, value: values.value },
                        { property: `campaignStatusDate`, value: moment().format() }
                    ])}
                />
            </div>
            <ProjectCreatedDateContainer className="col d-flex justify-content-end align-items-end">
                Project Created on: <Moment className="ps-1" format={dateFormat}>{createdDate}</Moment>
            </ProjectCreatedDateContainer>
        </div>
    </React.Fragment>
};

const ProjectDetails = ({ label = "Project Details", selectedProject }) => {
    const { projectId } = selectedProject || {};
    const { 
        data, 
        error, 
        isError, 
        isLoading, 
        isUninitialized, 
        reload, 
        updateProject,
        updateProjectOptions 
    } = useProjectDetails(projectId);

    if (!selectedProject) {
        return;
    }

    if (isLoading === true || isUninitialized === true) {
        return <AccordionSection label="Project Details">
            <CenterArea className="flex-column">
                <Spinner className="mb-3" />
                <p className="m-0">Fetching '{selectedProject.description}' details...</p>
            </CenterArea>
        </AccordionSection>
    }

    if (error || isError === true) {
        return <AccordionSection label={label}>
            <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                <ErrorMessage error={error} retryCallback={reload} />
            </ErrorMessageWrapper>
        </AccordionSection>
    }

    if (!data) {
        return <AccordionSection label={label}>
            <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                <ErrorMessage error={"The data was not loaded correctly"} retryCallback={reload} />
            </ErrorMessageWrapper>
        </AccordionSection>
    }

    return <AccordionSection label="Project Details">
        <ProjectDetailsForm project={data} onFieldChanged={updateProject} onFieldOptionsChanged={updateProjectOptions} />
    </AccordionSection>
};

export default ProjectDetails;
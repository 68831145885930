import React from "react";
import classNames from "classnames";
import TableColumn from "./TableColumn";

/* NOTE: APP & GOOGLE were meant to show difference between google and another totp app. However I ditched the idea after it was too late. Most App 2FA for clients will be set to the APP one and not Google.  */
const MFA_PIN_ENUM = 0;
const MFA_TOTP_APP_ENUM = 1;
const MFA_TOTP_GOOGLE_ENUM = 2;
const MFA_TOTP_UNREGISTERED_ENUM = 3;
const MFA_HOTP_EMAIL_ENUM = 4;
const MFA_HOTP_SMS_ENUM = 5;

const MfaTypeDisplayColumn = ({ value = null }) => {
    const _getDisplayText = () => {
        if (value === MFA_PIN_ENUM) {
            return "PIN";
        }
        if (value === MFA_TOTP_APP_ENUM || value == MFA_TOTP_GOOGLE_ENUM) {
            return "Mobile Authenticator";
        }
        if (value === MFA_TOTP_UNREGISTERED_ENUM) {
            return "Unregistered Mobile Authenticator";
        }
        if (value === MFA_HOTP_EMAIL_ENUM) {
            return "Email Verification";
        }
        if (value === MFA_HOTP_SMS_ENUM) {
            return "SMS Verification";
        }
        return "Disabled";
    };

    return (
        <TableColumn>
            {value === null && (
                <div className="badge bg-boolean-pill-false text-boolean-pill-false">
                    {_getDisplayText()}
                </div>
            )}
            {value !== null && (
                <div>
                    {_getDisplayText()}
                </div>
            )}
        </TableColumn>
    );
};

export default MfaTypeDisplayColumn;
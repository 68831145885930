import { 
    useFetchIntroducersQuery,
    useFetchClientIntroducerAgreementQuery,
    usePatchClientIntroducerAgreementMutation,
    useCreateClientIntroducerAgreementMutation
} from "../../../services/clientserviceterms";

const useClientIntroducer = (masterAccountId) => {

    const { data : introducers } = useFetchIntroducersQuery({ masterAccountId });
    const { data : clientIntroducerAgreement, error, isError, isFetching, isLoading, refetch } = useFetchClientIntroducerAgreementQuery({ masterAccountId });
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchClientIntroducerAgreementMutation();
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateClientIntroducerAgreementMutation();

    const updateClientIntroducerAgreement = (clientIntroducerAgreementId, property, value) => {
        return new Promise((resolve, reject) => {

            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            return patch({ clientIntroducerAgreementId, masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const createClientIntroducerAgreement = (e) => {

        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            return create({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        introducers: introducers || [],
        clientIntroducerAgreement: clientIntroducerAgreement || {},
        isLoading,
        isError,
        error,
        reload: refetch,
        updateClientIntroducerAgreement,
        updateError,
        isErrorUpdating,
        isUpdating,
        createClientIntroducerAgreement,
        createError,
        isErrorCreating,
        isCreating
    }

};

export default useClientIntroducer;
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: block;
    margin: ${props => props.margin || '0px auto'};
    max-width: ${props => props.maxWidth || 'unset'};
`;

const ErrorMessageWrapper = ({ children, ...rest }) => {
    return <Wrapper {...rest}>
        {children}
    </Wrapper>
};

export default ErrorMessageWrapper;
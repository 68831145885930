import { wealthProApi } from "../../redux/api";

const hoAdminNewMoneyApiEndpoints = wealthProApi.enhanceEndpoints({
}).injectEndpoints({
    endpoints: (builder) => ({
        searchNewMoney: builder.query({
            query: ({ filter, sort, page, limit }) => ({
                url: `api/headofficeadmin/newmoney/search${sort != null ? `?sort=${encodeURIComponent(sort)}` : ""}`,
                method: "POST",
                body: {
                    ...filter,
                    page,
                    limit
                }
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            }
        }),
        createNewMoney: builder.mutation({
            query: ({ newMoney }) => ({
                url: `api/headofficeadmin/newmoney`,
                method: "POST",
                body: newMoney
            }),
            async onQueryStarted({ newMoney }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("searchNewMoney", {}, (draft) => {
                        draft.results.unshift({
                            ...newMoney,
                            ...data
                        })
                        draft.pagination.totalCount++;
                    }))
                } catch (error) {
                    console.error("Failed to create new money", error);
                }
            }
        }),
        createAndApproveNewMoney: builder.mutation({
            query: ({ newMoney }) => ({
                url: `api/headofficeadmin/newmoney?approve=true`,
                method: "POST",
                body: newMoney
            }),
            async onQueryStarted({ newMoney, filterOut }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    if (!filterOut)
                        dispatch(wealthProApi.util.updateQueryData("searchNewMoney", {}, (draft) => {
                            draft.results.unshift({
                                ...newMoney,
                                ...data
                            });
                            draft.pagination.totalCount++;
                        }));
                } catch (error) {
                    console.error("Failed to create and approve new money", error);
                }
            }
        }),
        approveNewMoney: builder.mutation({
            query: ({ id, updatedNewMoney }) => ({
                url: `api/headofficeadmin/newmoney/${id}/status`,
                method: "PATCH",
                body: {
                    status: 1, // Approved
                    updatedNewMoney
                }
            }),
            async onQueryStarted({ id, updatedNewMoney, filterOut }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("searchNewMoney", {}, (draft) => {
                        const itemIndex = draft.results.findIndex((item) => item.newMoneyId === id);

                        if (filterOut) {
                            draft.results.splice(itemIndex, 1);
                            draft.pagination.totalCount--;
                        } else {
                            draft.results[itemIndex] = {
                                ...draft.results[itemIndex],
                                ...updatedNewMoney,
                                status: 1
                            };
                        }
                    }));
                } catch (error) {
                    console.error("Failed to approve new money", error);
                }
            }
        }),
        cancelNewMoney: builder.mutation({
            query: ({ id }) => ({
                url: `api/headofficeadmin/newmoney/${id}/status`,
                method: "PATCH",
                body: {
                    status: 2, // Not Taken Up
                }
            }),
            async onQueryStarted({ id, filterOut }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("searchNewMoney", {}, (draft) => {
                        const itemIndex = draft.results.findIndex((item) => item.newMoneyId === id);

                        if (filterOut) {
                            draft.results.splice(itemIndex, 1);
                            draft.pagination.totalCount--;
                        } else {
                            draft.results[itemIndex] = {
                                ...draft.results[itemIndex],
                                status: 2
                            };
                        }
                    }));
                } catch (error) {
                    console.error("Failed to cancel new money", error);
                }
            }
        }),
    })
});

export const {
    useLazySearchNewMoneyQuery,
    useCreateNewMoneyMutation,
    useCreateAndApproveNewMoneyMutation,
    useApproveNewMoneyMutation,
    useCancelNewMoneyMutation
} = hoAdminNewMoneyApiEndpoints;
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {} from "./helpers/array";
import {} from "./helpers/dates";
import { Toaster } from 'react-hot-toast';
import { TenantProvider } from "./hooks/TenantContext";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import "./sass/main.scss";

// configure our font awesome library of icons
library.add(far);
library.add(fas);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

// NOTE: we use the tenenant provider now to collect the oidc & theme data from the back-end
root.render(
    <TenantProvider>
        <App />
        <Toaster position='bottom-right' />
    </TenantProvider>
);
import { useFetchInstructionTypesByWrapperIdQuery, useLazyFetchInstructionTypesByWrapperIdQuery } from "../../../services/products";

const useInstructionTypes = (wrapperId) => {
    const { data, isLoading, isFetching, isError, error, refetch } = useFetchInstructionTypesByWrapperIdQuery({ wrapperId, fetchType: "list" })

    const [fetchInstructionTypesTrigger] = useLazyFetchInstructionTypesByWrapperIdQuery();

    const fetchInstructionTypes = ({ page, limit }) => {
        return new Promise((resolve, reject) => {
            return fetchInstructionTypesTrigger({ wrapperId, fetchType: "list", page, limit })
                .unwrap()
                .then(
                    r => resolve({ data: r }),
                    e => reject(e)
                );
        });
    }

    return {
        data: data || [],
        error,
        isLoading,
        isFetching,
        isError,
        refetch,
        fetchInstructionTypes
    };

};

export default useInstructionTypes;
import { React } from "react";
import { NumericFormat } from "react-number-format";
import ValuationAssetRow from "./ValuationAssetRow";

const PortfolioDesignationSummary = ({ designation, wrapper, product }) => {

    const groupBy = (input, key) => {
        return input.reduce((acc, currentValue) => {
            let groupKey = currentValue[key];
            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }
            acc[groupKey].push(currentValue);
            return acc;
        }, {});
    };

    var hasDifference = product.provider.contracts.flatMap(x => x.assets).some(x => x.contractReference != product.providerAccountReference);

    const groupedByContractRef = groupBy(
        product.provider.contracts,
        "contractReference"
    );

    const contracts = Object.values(groupedByContractRef);

    return (
        <div>
            
            {!wrapper.defWrapper?.splitOnContractReference &&
                <div style={{ marginTop: "25px" }}>
                <div>
                    <h5
                        style={{
                            borderBottom: "1px solid #80808040",
                            float: "left",
                            textAlign: "left",
                            width: "50%",
                        }}
                    >
                        <b>{product.product.productName}{(hasDifference) && <span style={{ color: "rgb(143 143 143)" }}>{" " + product.providerAccountReference}</span>}</b>
                    </h5>
                    <h5
                        style={{
                            borderBottom: "1px solid #80808040",
                            float: "right",
                            textAlign: "right",
                            width: "50%",
                        }}
                    >
                        <NumericFormat
                            value={product?.productValue?.toFixed(2)}
                            thousandSeparator={true}
                            decimalScale={0}
                            displayType="text"
                            prefix={"£"}
                        />
                    </h5>
                </div>
                <div style={{ clear: "both" }}></div>
                {product.provider.contracts.map((contract) =>
                    contract.assets
                        .map((asset, assetRowIndex) => (
                            <ValuationAssetRow 
                            key={`assetRowGrouped-${assetRowIndex}`} designation={designation} wrapper={wrapper} product={product} contract={contract} asset={asset} />
                    ))
                )}
            </div>
            }
            {wrapper.defWrapper?.splitOnContractReference && contracts.length > 0 &&
                contracts.map((contract, index) => (
                    <div
                        style={{ marginTop: "25px" }}
                        key={`contractsplit-${index}`}
                    >
                        <div>
                            <h5
                                style={{
                                    borderBottom: "1px solid #80808040",
                                    float: "left",
                                    textAlign: "left",
                                    width: "50%",
                                }}
                            >
                            <b>{product.product.productName}{(hasDifference) && <span style={{ color: "rgb(143 143 143)"}}>{" " + product.providerAccountReference}</span>}</b>
                            </h5>
                            <h5
                                style={{
                                    borderBottom: "1px solid #80808040",
                                    float: "right",
                                    textAlign: "right",
                                    width: "50%",
                                }}
                            >
                                <NumericFormat
                                    value={contract[0].contractValue.toFixed(2)}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    displayType="text"
                                    prefix={"£"}
                                />
                            </h5>
                        </div>
                        <div style={{ clear: "both" }}></div>

                        {contract[0].assets.map((asset, index) => (
                            <ValuationAssetRow key={`assetRow-${index}`} designation={designation} wrapper={wrapper} product={product} contract={contract[0]} asset={asset} />
                        ))}
                    </div>
                ))
            }
        </div>
    );
};

export default PortfolioDesignationSummary;
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { useFetchIntroducersForMasterAccountQuery } from "../../../services/headofficeadmin";

const useIntroducers = (masterAccountId) => {

    const { data, isLoading, isError, error, refetch } = useFetchIntroducersForMasterAccountQuery({ masterAccountId });

    const retryFetchIntroducersForMasterAccount = (e) => {
        if (e && typeof (e) === "function") {
            e.preventDefault();
        }

        refetch();
    };

    return {
        data: data || [],
        isLoading,
        isError,
        error,
        retryFetchIntroducersForMasterAccount
    };
};

export default useIntroducers;
import React from "react";
import useMasterAccountAuditLogs from "./hooks/useMasterAccountAuditLogs";
import { InfinityList } from "../../components/tables";
import { DateDisplay, ProfileDisplay } from "../../components";

const RowLoader = ({ style }) => (
    <div className="pb-2 px-2" style={style}>
        <div className="h-100 px-2 py-1 border border-1 d-flex justify-content-between align-items-center">
            <p className="m-0">Loading...</p>
        </div>
    </div>
);

const Row = ({ data, index, style }) => {
    if (index >= data.length) {
        return <RowLoader style={style} />
    }

    const {
        logDescription,
        resultDescription,
        logDate,
        loggedById
    } = data[index];

    return (
        <div className="pb-2 px-2" style={style}>
            <div className="h-100 px-2 py-1 border border-1 d-flex justify-content-between align-items-center">
                <div className="flex-fill">
                    <p className="m-0">
                        <span>[{logDescription}]&nbsp;</span>
                        <span>[{resultDescription}]</span>
                    </p>
                </div>
                <div className="mx-3">
                    <DateDisplay format="dddd, MMMM Do YYYY, h:mm:ss a">{logDate}</DateDisplay>
                </div>
                {loggedById && (
                    <div>
                        <ProfileDisplay userId={loggedById} />
                    </div>
                )}
            </div>
        </div>
    )
}

const ClientAuditLogs = () => {
    const { 
        data, 
        error, 
        isNextPageLoading, 
        isRowLoaded, 
        loadMore, 
        totalCount 
    } = useMasterAccountAuditLogs();

    return (
        <section className="h-100 my-3 px-4">
            <InfinityList 
                enableScrollTracking={false}
                row={Row}
                error={error}
                isLoading={isNextPageLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={64}
                itemCount={totalCount}
                itemData={data}
            />
        </section>
    );
};

export default ClientAuditLogs;
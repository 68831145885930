import { useCallback, useEffect, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import {
    useCreateMortgageArrangementMutation,
    useDeleteMortgageArrangementMutation,
    useFetchMortgageArrangementsQuery,
    usePatchMortgageArrangementMutation,
    useUpdateMortgageArrangementOwnerMappingsMutation
} from "../../../services/clientfactfind";

const useMortgageArrangements = () => {

    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isError, error, isFetching, isUninitialized, refetch } = useFetchMortgageArrangementsQuery({ masterAccountId });
    const [create] = useCreateMortgageArrangementMutation();
    const [patch] = usePatchMortgageArrangementMutation();
    const [remove] = useDeleteMortgageArrangementMutation();
    const [updateBorrowerMappings] = useUpdateMortgageArrangementOwnerMappingsMutation();

    const [realTimeLoanBalances, setRealTimeLoanBalances] = useState([]);

    useEffect(() => {
        setRealTimeLoanBalances(data?.map(mortgage => mortgage.loanBalance));
    }, [data])

    const patchRealTimeLoanBalance = useCallback((index, newValue) => {
        setRealTimeLoanBalances(oldArr => [
            ...oldArr.slice(0, index),
            newValue,
            ...oldArr.slice(index + 1)
        ]);
    }, [])

    const createMortgageArrangement = () => {
        return new Promise((resolve, reject) => {
            return create({ masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const patchMortgageArrangement = (mortgageId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return patch({ mortgageId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => {
                        setRealTimeLoanBalances(data.map(mortgage => mortgage.loanBalance));
                        return reject(e);
                    }
                );
        });
    };

    const removeMortgageArrangement = (mortgageId) => {
        return new Promise((resolve, reject) => {
            return remove({ mortgageId, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        })
    };

    const updateMortgageBorrowerMappings = (mortgageId, ids) => {
        return new Promise((resolve, reject) => {
            return updateBorrowerMappings({ mortgageId, ids, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        realTimeLoanBalances,
        patchRealTimeLoanBalance,
        data: data || [],
        isLoading: isLoading || isFetching || isUninitialized,
        isError,
        error,
        refetchMortgageArrangements: refetch,
        createMortgageArrangement,
        patchMortgageArrangement,
        removeMortgageArrangement,
        updateMortgageBorrowerMappings
    };
};

export default useMortgageArrangements;
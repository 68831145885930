import React, { useState } from "react";
import styled from "styled-components";
import { AccordionBody, AccordionHeader, AccordionList, AccordionListItem } from "../../components/utilities";
import { ExpenditureDetailsProvider } from "./contexts/ExpenditureDetailsContext";
import { IncomeDetailsProvider } from "./contexts/IncomeDetailsContext";
import {
    AgreedVolatilityProfileSection,
    AntiMoneyLaunderingAccordion,
    AssetsSection,
    BackgroundSection,
    EstatePlanningSection,
    ExpenditureDetailsSection,
    IncomeDetailsSection,
    InvestmentsSection,
    LoansAndLiabilitiesSection,
    NewMortgageApplicationSection,
    PersonalDetailsSection,
    ProtectionSection,
    RiskFactorsSection
} from "./sections";

// NewMortgageApplication needs to be at the bottom,
// and Assets and LoansAndLiabilities need to be immediately after ExpenditureDetails
const CustomPlacementAccordion = styled(AccordionListItem)`
    grid-area: ${props => props.listIndex};
`;

const AssetsIndex = 6;
const LoansAndLiabilitiesIndex = 7;
const MortgageApplicationIndex = 13;

const ClientFactFind = () => {

    const [activeKeys, setActiveKeys] = useState([]);

    return <div className="p-3">
        <AccordionList
            className="d-grid"
            activeKey={activeKeys}
            defaultActiveKey={null}
            onSelect={(eventKeys, _) => setActiveKeys(eventKeys)}
            gap={1}
            alwaysOpen
        >
            <AccordionListItem key={"antimoneylaundering"} eventKey={"antimoneylaundering"}>
                <AntiMoneyLaunderingAccordion renderBody={activeKeys.includes("antimoneylaundering")} />
            </AccordionListItem>

            <AccordionListItem key={"background"} eventKey={"background"}>
                <AccordionHeader>
                    <span>Background</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("background") && <BackgroundSection />}
                </AccordionBody>
            </AccordionListItem>

            <AccordionListItem key={"personaldetails"} eventKey={"personaldetails"} >
                <AccordionHeader>
                    <span>Personal Details, Health, and Family</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("personaldetails") && <PersonalDetailsSection />}
                </AccordionBody>
            </AccordionListItem>

            <IncomeDetailsProvider>
                <AccordionListItem key={"income"} eventKey={"income"}>
                    <AccordionHeader>
                        <span>Income Details</span>
                    </AccordionHeader>
                    <AccordionBody>
                        <IncomeDetailsSection />
                    </AccordionBody>
                </AccordionListItem>

                <ExpenditureDetailsProvider>
                    <AccordionListItem key={"expenditure"} eventKey={"expenditure"}>
                        <AccordionHeader>
                            <span>Expenditure Details</span>
                        </AccordionHeader>
                        <AccordionBody>
                            <ExpenditureDetailsSection />
                        </AccordionBody>
                    </AccordionListItem>

                    <CustomPlacementAccordion key={"mortgage"} eventKey={"mortgage"} listIndex={MortgageApplicationIndex}>
                        <AccordionHeader>
                            <span>New Mortgage Application</span>
                        </AccordionHeader>
                        <AccordionBody>
                            {activeKeys.includes("mortgage") && <NewMortgageApplicationSection />}
                        </AccordionBody>
                    </CustomPlacementAccordion>

                </ExpenditureDetailsProvider>
            </IncomeDetailsProvider>

            <CustomPlacementAccordion key={"assets"} eventKey={"assets"} listIndex={AssetsIndex}>
                <AccordionHeader>
                    <span>Assets</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("assets") && <AssetsSection />}
                </AccordionBody>
            </CustomPlacementAccordion>

            <CustomPlacementAccordion key={"liabilities"} eventKey={"liabilities"} listIndex={LoansAndLiabilitiesIndex}>
                <AccordionHeader>
                    <span>Loans & Other Liabilities</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("liabilities") && <LoansAndLiabilitiesSection />}
                </AccordionBody>
            </CustomPlacementAccordion>

            <AccordionListItem key={"investments"} eventKey={"investments"}>
                <AccordionHeader>
                    <span>Investments & Pensions</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("investments") && <InvestmentsSection />}
                </AccordionBody>
            </AccordionListItem>

            <AccordionListItem key={"riskfactors"} eventKey={"riskfactors"}>
                <AccordionHeader>
                    <span>Risk Factors</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("riskfactors") && <RiskFactorsSection />}
                </AccordionBody>
            </AccordionListItem>

            <AccordionListItem key={"volatilityprofile"} eventKey={"volatilityprofile"}>
                <AccordionHeader>
                    <span>Agreed Volatility Profile</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("volatilityprofile") && <AgreedVolatilityProfileSection />}
                </AccordionBody>
            </AccordionListItem>

            <AccordionListItem key={"estateplanning"} eventKey={"estateplanning"}>
                <AccordionHeader>
                    <span>Estate Planning</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("estateplanning") && <EstatePlanningSection />}
                </AccordionBody>
            </AccordionListItem>

            <AccordionListItem key={"protection"} eventKey={"protection"}>
                <AccordionHeader>
                    <span>Protection</span>
                </AccordionHeader>
                <AccordionBody>
                    {activeKeys.includes("protection") && <ProtectionSection />}
                </AccordionBody>
            </AccordionListItem>
        </AccordionList>
    </div>;
}

export default ClientFactFind
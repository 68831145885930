import React, { useCallback, useEffect, useState, useRef, forwardRef } from "react";
import styled from "styled-components";
import PageHeader from "../../components/layout/PageHeader";
import{
    AccountingReportSelect
} from "../../components/forms/selects";
import AjbDebtorsLedgerReportModal from "./components/accountingreportmodals/ajbdebtorsledgerreportmodal";
import AjbServiceFeesBilledReportModal from "./components/accountingreportmodals/ajbservicefeesbilledreportmodal";
import DebtorsLedgerReportModal from "./components/accountingreportmodals/debtorsledgerreportmodal";
import useAjbServiceFeesBilledReport from "./hooks/useAjbServiceFeesBilledReport";
import useAjbDebtorsLedgerReport from "./hooks/useAjbDebtorsLedgerReport";
import useDebtorsLedgerReport from "./hooks/useDebtorsLedgerReport";
import toast from "react-hot-toast";
import { BasicToast } from "../../components/toasts";

const AdminDashboardHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const PageHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

const AccountingReports = () => {

    const [message, setMessage] = useState("");
    const [areAjbDebtorsLedgerReportArgsShown, setAreAjbDebtorsLedgerReportArgsShown] = useState(false);
    const [areAjbServiceFeesBilledReportArgsShown, setAreAjbServiceFeesBilledReportArgsShown] = useState(false);
    const [areDebtorsLedgerReportArgsShown, setAreDebtorsLedgerReportArgsShown] = useState(false);

    const [selectedReport, setSelectedReport] = useState(null);

    const inputRef = useRef(null);

    const ForwardReportSelect = forwardRef(function ForwardReportSelect(props, ref) {  
        return <AccountingReportSelect
            ref={ref}
            placeholder="Select Report"
            defaultValue={selectedReport}
            isMulti={false}
            isClearable={true}
            //onChange={(value) => setSelectedReport(value) }
            onChange={(value) => openTheDialog(value) }
            {...props}
        />

    });

    const openTheDialog = (selectedReport) => {
        if (selectedReport.value && typeof selectedReport.value === "number") {
            switch (selectedReport.value) {
                case 1:
                    setAreAjbDebtorsLedgerReportArgsShown(true);
                    break;
                case 2:
                    setAreAjbServiceFeesBilledReportArgsShown(true);
                    break;
                case 3:
                    setAreDebtorsLedgerReportArgsShown(true);
            }
        }
        //console.log(inputRef);
        //inputRef.current.focus();
    }

    const { getAjbServiceFeesBilledReport } = useAjbServiceFeesBilledReport();
    const { getAjbDebtorsLedgerReport } = useAjbDebtorsLedgerReport();
    const { getDebtorsLedgerReport } = useDebtorsLedgerReport();

    return (
        <React.Fragment>
            <AjbDebtorsLedgerReportModal
                handleAjbDebtorsLedgerReportArgsClose={
                    () => {
                        setAreAjbDebtorsLedgerReportArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                ajbDebtorsLedgerReportArgsShow={areAjbDebtorsLedgerReportArgsShown}

                handleAjbDebtorsLedgerReportArgsConfirm={
                    //(theReport, theRequest) => 
                    (theRequest) => 
                    toast.promise(getAjbDebtorsLedgerReport(theRequest), {
                        loading: `Creating AJB Debtors Ledger Report`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'AJB Debtors Ledger Report');
                            return `AJB Debtors Ledger Report is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create AJB Debtors Ledger Report - {err?.data}</div>
            
                    })
                }
            />
            <AjbServiceFeesBilledReportModal
                handleAjbServiceFeesBilledReportArgsClose={
                    () => {
                        setAreAjbServiceFeesBilledReportArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                ajbServiceFeesBilledReportArgsShow={areAjbServiceFeesBilledReportArgsShown}

                handleAjbServiceFeesBilledReportArgsConfirm={
                   // (theReport, theRequest) => 
                    (theRequest) => 
                    toast.promise(getAjbServiceFeesBilledReport(theRequest), {
                        loading: `Creating AJB Service Fees Billed Report`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'AJB Debtors Ledger Report');
                            return `AJB Service Fees Billed Report is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create AJB Debtors Ledger Report - {err?.data}</div>
            
                    })
                }
            />
            <DebtorsLedgerReportModal
                handleDebtorsLedgerReportArgsClose={
                    () => {
                        setAreDebtorsLedgerReportArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                debtorsLedgerReportArgsShow={areDebtorsLedgerReportArgsShown}

                handleDebtorsLedgerReportArgsConfirm={
                    //(theReport, theRequest) => 
                    (theRequest) => 
                    toast.promise(getDebtorsLedgerReport(theRequest), {
                        loading: `Creating Debtors Ledger Report`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'Debtors Ledger Report');
                            return `Debtors Ledger Report is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create Debtors Ledger Report - {err?.data}</div>
            
                    })
                }
            />
            {/*<PageHeader>
                <PageHeaderTop>
                    <AdminDashboardHeaderTitle>
                        <span>Accounting Reports</span>
                        {message}
                    </AdminDashboardHeaderTitle>
                </PageHeaderTop>
            </PageHeader>*/}
            <div className="row mt-5">
                <div className="col mx-5">
                   {/* <AccountingReportSelect
                        //ref={ref}
                        placeholder="Select Report"
                        defaultValue={selectedReport}
                        isMulti={false}
                        isClearable={true}
                        //onChange={(value) => setSelectedReport(value) }
                        onChange={(value) => openTheDialog(value) }
                    />*/}
                    <ForwardReportSelect
                        ref={inputRef}
                        //defaultValue={selectedReport}
                        //onChange={(value) => openTheDialog(value) }
                    />
                   
                </div>
            </div>
        </React.Fragment>
    )

};

export default AccountingReports
import React from "react";
import FormSelect from "../../../components/forms/FormSelect";

export const Statuses = [
    { label: 'Awaiting Research', value: 0 },
    { label: 'Research in Progress', value: 1 },
    { label: 'Verified', value: 2 },
    { label: 'Cancelled', value: 3 },
];

const DrawdownStatusSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={Statuses}
        {...rest}
    />
};

export default DrawdownStatusSelect;
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { ReportSelect } from "../../../components/forms/selects";
import { useClientContext } from "../../../hooks/ClientContext";

const SelectReportModal = ({
    show = false,
    onReportSelected,
    handleClose,
    reportType = "Report"
}) => {
    const { id: masterAccountId } = useClientContext();

    const [isSelecting, setIsSelecting] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const selectedReportId = useMemo(() => selectedReport?.id, [selectedReport?.id])

    const handleSelect = () => {
        setIsSelecting(true);
        onReportSelected(selectedReport)
            .finally(() => {
                setIsSelecting(false);
                handleClose();
            });
    }

    const handleCancel = () => {
        setSelectedReport(null);
        handleClose();
    }

    return <Modal centered show={show} backdrop="static" size="lg">
        <Modal.Header>
            <Modal.Title>Select an Existing {reportType}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="container-fluid">
            <ReportSelect
                masterAccountId={masterAccountId}
                value={selectedReportId}
                setValue={(selection) => setSelectedReport(selection.data)}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="success"
                disabled={selectedReport == null || isSelecting}
                onClick={handleSelect}
            >
                Confirm Selection
            </Button>
            <Button variant="light" onClick={handleCancel}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}

export default SelectReportModal;
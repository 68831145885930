import { wealthProApi } from "../../redux/api";

const userNotificationsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        markNotificationsAsNotified: builder.mutation({
            query: () => ({
                url: 'api/messaging/recipients/notified',
                method: 'POST'
            }),
            invalidatesTags: [{ id: "COUNT", type: "Notifications" }]
        })
    })
});

export const {
    useMarkNotificationsAsNotifiedMutation
} = userNotificationsApiEndpoints;
import InvestmentCommitteeFundRoutes from "./investment-committee.funds.routes";
import InvestmentCommitteeHouseRulesRoutes from "./investment-committee.houserules.routes";
import InvestmentCommitteePrdCommentaryRoutes from "./investment-committee.prd.routes";

const InvestmentCommitteeRoutes = [{
    path: "/investment-committee/*",
    defaultRoute: 'funds',
    roles: ['sys_admin_access', 'ic_read'],
    children: [
        InvestmentCommitteeFundRoutes,
        InvestmentCommitteeHouseRulesRoutes,
        InvestmentCommitteePrdCommentaryRoutes
    ]
}];

export default InvestmentCommitteeRoutes;
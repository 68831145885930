import {
    useLazyFetchProductCostsQuery,
    useFetchInstructionTypesByWrapperIdQuery,
    useFetchCalculateTypesQuery,
    useFetchCostTypesQuery,
    useFetchPostToTypesQuery,
    useFetchSectionsQuery,
    usePatchProductCostMutation,
    useCreateProductCostMutation,
    useDeleteProductCostMutation
} from "../../../services/products";

const useProductCosts = () => {

    const [fetch, { isLoading: isCostsLoading, isError: isCostsError, isFetching: isCostsFetching, data: costsData }] = useLazyFetchProductCostsQuery();
    const { data: calculateTypesData, isLoading: isCalculateTypesLoading } = useFetchCalculateTypesQuery();
    const { data: costTypesData, isLoading: isCostTypesLoading } = useFetchCostTypesQuery();
    const { data: postToTypesData, isLoading: isPostToTypesLoading } = useFetchPostToTypesQuery();
    const { data: sectionsData, isLoading: isSectionsLoading } = useFetchSectionsQuery();
    const [createCost, { error: createError, isError: isCreateError, isLoading: isCreateLoading }] = useCreateProductCostMutation();
    const [patchCost, { error: updateError, isError: isUpdateError, isLoading: isUpdateLoading }] = usePatchProductCostMutation();
    const [deleteCost, { error: deleteError, isError: isDeleteError, isLoading: isDeleteLoading }] = useDeleteProductCostMutation();

    const createProductCost = ({ instructionTypeId, productId }) => {
        const newProductCost = {
            instructionTypeId: instructionTypeId,
            productId: productId,
            section: null,
            postTo: null,
            apply: null,
            costType: null,
            costDescription: "",
            calculation: null,
            defaultCostAmount: null,
            narrative: ""
        };


        return new Promise((resolve, reject) => {
            return createCost(newProductCost).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    }

    const deleteProductCost = ({ costId, productId, instructionTypeId }) => {
        return new Promise((resolve, reject) => {

            return deleteCost({ costId, productId, instructionTypeId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    }

    const fetchProductCosts = (productId, instructionTypeId) => {
        fetch({ productId, instructionTypeId });
    }

    const updateProduct = ({ costId, property, value, productId, instructionTypeId }) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];
            return patchCost({ costId, operations, productId, instructionTypeId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    }

    return {
        createProductCost,
        costsData,
        deleteProductCost,
        deleteError,
        isCostsError,
        isCostsFetching,
        isCostsLoading,
        isDeleteError,
        isDeleteLoading,
        fetchProductCosts,
        calculateTypesData,
        isCalculateTypesLoading,
        costTypesData,
        isCostTypesLoading,
        postToTypesData,
        isPostToTypesLoading,
        sectionsData,
        isSectionsLoading,
        updateProduct,
        updateError,
        isUpdateError,
        isUpdateLoading
    };
};

export default useProductCosts;
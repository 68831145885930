import { ButtonGroup } from "react-bootstrap";
import { Button, IconButton } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";
import { memo, useMemo } from "react";
import moment from "moment";
import { useEffect } from "react";
import { Navigate, Views } from "react-big-calendar";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { FormSelect } from "../../../components/forms";
import { useDiaryContext } from "../hooks/DiaryContext";
import styled from "styled-components";
import { Spinner } from "../../../components/loaders";
import UserDelegatesSelect from "../../../components/forms/selects/UserDelegatesSelect";
import { GenericUserSelectOption, GenericUserSelectSingleValue } from "../../../components/forms/selects/GenericUserSelect";

const StyledUserDelegatesSelect = styled(FormSelect)`
    z-index: 1000 !important;
    max-width: 350px;
`;

const UserCalendarToolbar = ({
    date,
    label,
    onNavigate,
    onView,
    view,
    views,
    current,
    next,
    previous
}) => {

    const { selectedCalendarUserId, setSelectedCalendarUserId, calendarDelegateRightsResult } = usePersonalOutlookContext();

    const { data: calendarDelegateRights } = calendarDelegateRightsResult;

    const {
        delegatedUsers,
        monthIndex,
        isFetching
    } = useDiaryContext();

    const { data: delegatedUserOptions, isLoading: isLoadingDelegates } = delegatedUsers || { data: [], isLoading: false };

    const {
        eventModal: {
            setProps
        }
    } = usePersonalOutlookContext();

    const onCreateEventClick = () => {
        setProps({ isNew: true, queryProps: { monthIndex }, show: true });
    }

    const onNavigateClick = (direction, navigateFn, e) => {
        if (view !== Views.MONTH) {
            onNavigate(direction);
            return;
        }

        navigateFn(e);
    };



    const ViewButtons = useMemo(() => {
        return views.map(viewLabel => (
            <Button
                key={viewLabel}
                onClick={() => onView(viewLabel)}
                variant={`${viewLabel === view ? "muted" : "primary"}`}
            >
                {viewLabel[0].toUpperCase() + viewLabel.slice(1)}
            </Button>
        ))
    }, [views, view]);

    useEffect(() => {
        if (view !== Views.MONTH) return;
        var date = moment().add(monthIndex, "month").toDate();
        onNavigate(Navigate.DATE, date);
    }, [monthIndex]);

    return <div className="row m-0 py-1 d-flex px-0 align-items-center">
        <div className="col-12 mb-2 mb-lg-0 col-lg d-flex  align-items-center">
            <ButtonGroup>
                <Button onClick={(e) => onNavigateClick(Navigate.PREVIOUS, previous, e)}>Previous</Button>
                <Button onClick={(e) => onNavigateClick(Navigate.TODAY, current, e)}>Today</Button>
                <Button onClick={(e) => onNavigateClick(Navigate.NEXT, next, e)}>Next</Button>
            </ButtonGroup>
            <div className="flex-grow-1 ms-2">
                <StyledUserDelegatesSelect
                    components={{
                        Option: GenericUserSelectOption,
                        SingleValue: GenericUserSelectSingleValue
                    }}
                    options={delegatedUserOptions}
                    isLoadingOptions={isLoadingDelegates}
                    defaultValue={selectedCalendarUserId}
                    onChange={({ value }) => setSelectedCalendarUserId(value)}
                />
            </div>
        </div>
        <div className="col d-flex justify-content-center align-items-center">
            <ThemedSpan className="h4 me-4" variant="muted">
                {label}
            </ThemedSpan>
            {isFetching && <div className="pb-2"><Spinner /></div>}
        </div>
        <div className="col d-flex justify-content-end align-items-center">
            {calendarDelegateRights?.writeCalendar && <IconButton
                icon="fa-plus"
                variant="success"
                className="me-2"
                onClick={onCreateEventClick}
            />}
            <ButtonGroup className="border">
                {ViewButtons}
            </ButtonGroup>
        </div>
    </div>
};

export default UserCalendarToolbar;
import React from "react";
import useApplications from "../hooks/useApplications";
import { VirtualTable } from "../../../components/tables";
import ApplicationTableRow from "./ApplicationTableRow";
import { Main } from "../../../components/utilities";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import ApplicationTableHeader from "./ApplicationsTableHeader";

const ApplicationsGrid = (loadingLines = 4 ) => {
    const {
        loadMore,
        isRowLoaded,
        sortableHeaderRenderer,
        clear,
        results,
        totalCount,
        columns,
        error,
        isClearing,
        isError,
        isNextPageLoading
    } = useApplications();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>
    }

    return (
        <VirtualTable
            enableScrollTracking={false}
            row={ApplicationTableRow}
            header={<ApplicationTableHeader columns={columns} renderer={sortableHeaderRenderer}/>}

            isItemLoaded={isRowLoaded}
            loadMoreItems={loadMore}
            itemSize={42}
            isLoading={isNextPageLoading}
            itemCount={totalCount === results.length ? totalCount : results.length + 4}
            itemData={results}
            // footer={<ApplicationsTableFooter totalCount={totalCount}/>}
        />

    );
};

export default ApplicationsGrid;
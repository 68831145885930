import React, { useMemo } from "react";
import { ActionIcon, ThemedSpan } from "../../../components/utilities";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import NotificationsSection from "./NotificationsSection";
import useNotifications from "../hooks/useNotifications";
import NotificationOptionsDropdown from "./NotificationOptionsDropdown";
import { queryTypes } from "../../../services/usernotifications";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const StaffNotificationsListItem = ({ notification, onToggle, readFn, setThread, ...rest }) => {
    const appNavigator = useNavigate();
    const { masterAccountName, accountReference, senderName, sentOnDate, masterAccountId, message, threadId } = notification || {};

    const masterAccountDisplay = useMemo(() => {
        if (!masterAccountName || masterAccountName === "") return "No associated account.";

        return masterAccountName;
    }, [masterAccountName]);

    const onMessageOpenClick = () => {
        if (masterAccountId) {
            if (onToggle && typeof (onToggle) === 'function') {
                onToggle();
            }

            appNavigator(`/client/${masterAccountId}/messages`);
            return;
        }

        setThread(threadId);
    };

    return <div className="row" {...rest}>
        <div className="col-md-auto">
            <ActionIcon icon="fa-folder-open" onClick={onMessageOpenClick} />
        </div>
        <div className="col-md-1 font-monospace">{accountReference}</div>
        <div className="col-3">{masterAccountDisplay}</div>
        <div className="col-2">{senderName}</div>
        <div className="col text-truncate">
            <OverlayTrigger placement="left" overlay={<Tooltip className="position-fixed">
                {message}
            </Tooltip>}>
                <ThemedSpan>{message}</ThemedSpan>
            </OverlayTrigger>
        </div>
        <div className="col-auto text-end">
            <Moment fromNow>{sentOnDate}</Moment>
        </div>
        <div className="col-md-auto">
            <NotificationOptionsDropdown notification={notification} readFn={readFn} />
        </div>
    </div>
};

const StaffNotificationsList = (props) => {
    const { setThread } = props;

    return <React.Fragment>
        <NotificationsSection
            label="Staff Messages"
            hook={useNotifications}
            queryType={queryTypes.STAFF}
            RecordItem={StaffNotificationsListItem}
            recordItemProps={{ setThread }}
            {...props}
        />
    </React.Fragment>
};

export default StaffNotificationsList;
import styled from "styled-components";

const FooterSection = styled.div`
    grid-area: footer;

    & > p {
        margin: 0.5rem;
        color: ${props => props.theme.muted};
        text-align: center;
    }
`;

export default FooterSection;
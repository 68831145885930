import React, { useState } from "react";
import CanvasBody from "./components/CanvasBody";
import FooterSection from "./components/FooterSection";
import NavigationDisplay from "./components/NavigationDisplay";
import NavigationSection from "./components/NavigationSection";
import ProfileSection from "./components/ProfileSection";
import NavigationProfile from "../navigationprofile";
import IconLink from "./components/IconLink";
import IconWrapper from "./components/IconWrapper";
import { VersionText } from "../../components/utilities";
import { Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navigation = () => {
    const [show, setShow] = useState(false);
    return (
        <React.Fragment>
            <IconWrapper>
                <IconLink>
                    <FontAwesomeIcon 
                        icon="fa-align-left" 
                        size="lg" 
                        onClick={() => setShow(!show)} 
                    />
                </IconLink>
            </IconWrapper>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <CanvasBody>
                    <ProfileSection>
                        <NavigationProfile 
                            setIsOpen={() => setShow(false)} 
                        />
                    </ProfileSection>
                    <NavigationSection>
                        <NavigationDisplay
                            setIsOpen={() => setShow(false)}
                        />
                    </NavigationSection>
                    <FooterSection className="fs-smaller">
                        <VersionText />
                    </FooterSection>
                </CanvasBody>
            </Offcanvas>
        </React.Fragment>
    )
};

export default Navigation;
import _ from "lodash";
import { useMemo, useState } from "react";
import { Button } from "../../components";
import { FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import { PageHeaderTitle } from "../../components/utilities/PageHeader";
import { useFilterContext } from "../../hooks/FilterContext";
import useCreateAssetGroup from "../../hooks/useCreateAssetGroup";
import { Header, HeaderTop } from "../../layouts/InvestmentCommitteeFundsAnalysisLayout";
import { InvestmentCommitteeFundFiltersModal, InvestmentCommitteeFundFiltersModalButton } from "../icfundfilters";
import { FundManagementGrid } from "./components";

const FundManagement = () => {
    const {
        clearFilter,
        filter,
        defaultFilters
    } = useFilterContext();

    // Since this needs the same context as the analysis filters, filtersApplied needs to be specific to the just management ones
    const managementFilter = useMemo(() => ({
        assetName: filter.assetName,
        monitoring: filter.monitoring,
        standardPortfolio: filter.standardPortfolio,
        controlStatus: filter.controlStatus,
        opinion: filter.opinion,
        guidance: filter.guidance,
        analystId: filter.analystId,
        publishedStartDate: filter.publishedStartDate,
        publishedEndDate: filter.publishedEndDate,
        opinionModifiedStartDate: filter.opinionModifiedStartDate,
        opinionModifiedEndDate: filter.opinionModifiedEndDate,
        publishedById: filter.publishedById
    }), [filter.analystId, filter.assetName, filter.controlStatus, filter.guidance, filter.monitoring, filter.opinion, filter.opinionModifiedEndDate, filter.opinionModifiedStartDate, filter.publishedById, filter.publishedEndDate, filter.publishedStartDate, filter.standardPortfolio]);

    const defaultManagementFilters = useMemo(() => ({
        assetName: defaultFilters.assetName,
        monitoring: defaultFilters.monitoring,
        standardPortfolio: defaultFilters.standardPortfolio,
        controlStatus: defaultFilters.controlStatus,
        opinion: defaultFilters.opinion,
        guidance: defaultFilters.guidance,
        analystId: defaultFilters.analystId,
        publishedStartDate: defaultFilters.publishedStartDate,
        publishedEndDate: defaultFilters.publishedEndDate,
        opinionModifiedStartDate: defaultFilters.opinionModifiedStartDate,
        opinionModifiedEndDate: defaultFilters.opinionModifiedEndDate,
        publishedById: defaultFilters.publishedById
    }), [defaultFilters.analystId, defaultFilters.assetName, defaultFilters.controlStatus, defaultFilters.guidance, defaultFilters.monitoring, defaultFilters.opinion, defaultFilters.opinionModifiedEndDate, defaultFilters.opinionModifiedStartDate, defaultFilters.publishedById, defaultFilters.publishedEndDate, defaultFilters.publishedStartDate, defaultFilters.standardPortfolio]);

    const isFiltersApplied = useMemo(() => !_.isEqual(managementFilter, defaultManagementFilters), [defaultManagementFilters, managementFilter]);

    const [isFiltersShown, setIsFiltersShown] = useState(false);

    const { createAssetGroup, isLoading: isCreating } = useCreateAssetGroup();

    return <>
        <Header>
            <HeaderTop>
                <PageHeaderTitle>Investment Committee Funds Administration</PageHeaderTitle>
            </HeaderTop>
        </Header>
        <InvestmentCommitteeFundFiltersModal
            handleClose={() => setIsFiltersShown(false)}
            show={isFiltersShown}
            isManagement
        />
        <Main>
            <PageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && <>
                            <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                            <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                Warning - Filters are currently being applied to your results.
                                <br />
                                <button className="anchor" onClick={clearFilter}>Click here</button> to clear your filters.
                            </FiltersAppliedWarningMessage>
                        </>}
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        <Button className="me-2" variant="success" onClick={createAssetGroup} disabled={isCreating}>
                            Create New Fund
                        </Button>
                        <InvestmentCommitteeFundFiltersModalButton
                            onClick={() => setIsFiltersShown(true)}
                        />
                    </div>
                </div>
            </PageSubHeader>
            <FundManagementGrid />
        </Main>
    </>
}

export default FundManagement;
import _ from "lodash";
import { wealthProApi } from '../../redux/api';

const masterAccountAuditLogEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountLogs"]
})
.injectEndpoints({
    endpoints: (build) => ({
        fetchMasterAccountAuditLogs: build.query({
            query: ({ masterAccountId, ...rest }) => ({
                url: `api/logs/master-accounts/${masterAccountId}/logs`,
                method: 'POST',
                body: { masterAccountId, ...rest }
            }),
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                }
                else {
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                }            
            }
        })
    })
});

export const {
    useFetchMasterAccountAuditLogsQuery,
    useLazyFetchMasterAccountAuditLogsQuery
} = masterAccountAuditLogEndpoints;
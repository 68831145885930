
import { useEffect } from "react";
import { useLazyFetchMessageUsersForMasterAccountQuery, useLazyFetchMessageUsersQuery } from "../../../services/messages";

const useMessageUsers = (masterAccountId) => {

    const [fetchMasterAccountOptions, { data: masterAccountOptions }] = useLazyFetchMessageUsersForMasterAccountQuery();
    const [fetchUserOptions, { data: userOptions }] = useLazyFetchMessageUsersQuery();

    useEffect(() => {
        if (masterAccountId) {
            fetchMasterAccountOptions({ masterAccountId });
            return;
        }

        fetchUserOptions({});
    }, [masterAccountId]);

    return {
        results: masterAccountId ? masterAccountOptions : userOptions
    };

};

export default useMessageUsers;
import { wealthProApi } from "../../redux/api";

const mailApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        getFiledMailByGraphId: builder.query({
            query: ({ graphId }) => `api/clientdocuments/mail/messages/${graphId}/filed`
        }),
        patchFiledMail: builder.mutation({
            query: ({ graphId, ...request }) => ({
                url: `api/clientdocuments/mail/messages/${graphId}/filed`,
                method: "PATCH",
                body: request
            }),
            async onQueryStarted({ graphId, ...request }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("getFiledMailByGraphId", { graphId }, (draft) => ({
                        ...draft,
                        ...request
                    }) || console.log({ ...draft, ...request })));

                    try {
                        await queryFulfilled
                    } catch (err) {
                        console.error(err, "Error updating filed email");
                        patchResult.undo();
                    }
                } catch (err) {
                    console.error(err, "Error updating filed email locally");
                }
            }
        })
    })
});

export const {
    useGetFiledMailByGraphIdQuery,
    useLazyGetFiledMailByGraphIdQuery,
    usePatchFiledMailMutation
} = mailApiEndpoints;
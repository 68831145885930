import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FavouritesDropdownToggle = React.forwardRef(({ onClick, onToggle, ...rest }, ref) => {
    const handleOnToggle = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }
    };

    return <a ref={ref} onClick={handleOnToggle} {...rest}>
        <FontAwesomeIcon icon="fa-star" size="lg" />
    </a>
});

export default FavouritesDropdownToggle;
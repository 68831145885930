import React, { useState } from "react";
import Container from "./components/Container";
import DisplayNameText from "./components/DisplayNameText";
import EmailAddressText from "./components/EmailAddressText";
import LinksContainer from "./components/LinksContainer";
import LogoutModal from "../logoutmodal";
import ProfileContainer from "./components/ProfileContainer";
import ProfileDetailsContainer from "./components/ProfileDetailsContainer";
import ProfileGroupText from "./components/ProfileGroupText";
import ProfileIconContainer from "./components/ProfileIconContainer";
import ProfileLink from "./components/ProfileLink";
import { ProfileIcon } from "../../components";
import { useAuth } from "react-oidc-context";
import { useActiveUsers } from "../../hooks/ActiveUsersContext";

const NavigationProfile = ({ setIsOpen }) => {
    const [showLogout, setShowLogout] = useState(false);
    const { user: { profile } } = useAuth();
    const { data: { results: users } } = useActiveUsers();
    const myActiveUserDetails = users?.find(el => el.id === profile.sub.replace(/-/gi, ''));

    const handleLogoutModalClosed = () => {
        setShowLogout(false);
        setIsOpen(false);
    };

    const handleNavItemSelected = () => setIsOpen(false);

    return <Container>
        <LogoutModal handleClose={handleLogoutModalClosed} show={showLogout} />
        <ProfileContainer>
            <ProfileIconContainer>
                <ProfileIcon user={profile} width="56" height="56" />
            </ProfileIconContainer>
            <ProfileDetailsContainer>
                <DisplayNameText>{profile.name}</DisplayNameText>
                <ProfileGroupText className="fs-smallest">{myActiveUserDetails?.type ?? "User Account"}</ProfileGroupText>
                <EmailAddressText className="fs-smallest">{profile.email}</EmailAddressText>
            </ProfileDetailsContainer>
        </ProfileContainer>
        {/* <LinksContainer>
            <ProfileLink 
                to="/client/search" 
                icon="fa-magnifying-glass"
                tooltipText="Client Search"
                onSelected={handleNavItemSelected} />
            <ProfileLink 
                to="/personal/favourites" 
                icon="fa-star"
                tooltipText="My Favourites"
                onSelected={handleNavItemSelected} />
            <ProfileLink 
                to="/personal/notifications" 
                icon="fa-bell"
                tooltipText="My Notifications"
                onSelected={handleNavItemSelected} />
            <ProfileLink 
                to="/profile" 
                icon="fa-user"
                tooltipText="My Profile"
                onSelected={handleNavItemSelected} />
            <ProfileLink 
                icon="fa-right-from-bracket"
                tooltipText="Logout"
                onClick={() => setShowLogout(true)} />
        </LinksContainer> */}
    </Container>
};

export default NavigationProfile;
import { Modal } from "react-bootstrap";
import { Button } from "../../components";
import { DateInput, FormCheck } from "../../components/forms";
import useEditMessage from "./hooks/useEditMessage";

const EditMessageModal = ({ data, onHide, show, queryParams = {} }) => {

    const {
        sentOnDate,
        message: messageText,
        recipients,
        senderName,
        doneByName,
        isDone,
        doneDate,
        threadSubject,
        messagePriority,
        isActionRequired,
        attachments,
        deadlineDate,
        messageId,
        threadId,
        masterAccountId
    } = data;

    const { patchMessage, isPatching } = useEditMessage(messageId);

    const updateValue = (property, value) => patchMessage(property, value, { threadId, masterAccountId, ...queryParams });

    return <Modal centered backdrop="static" show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col d-flex">
                    <FormCheck
                        label={"Is Action Required?"}
                        isChecked={isActionRequired}
                        onChange={(value) => updateValue("isActionRequired", value)}
                    />
                </div>
                <div className="col">
                    <DateInput
                        label={"Deadline Date"}
                        disabled={!isActionRequired}
                        isClearable
                        value={deadlineDate}
                        onBlur={value => updateValue("deadlineDate", value)}
                    />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="light" onClick={onHide}>Close</Button>
        </Modal.Footer>
    </Modal>
};

export default EditMessageModal;
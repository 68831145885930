import { useCallback, useEffect, useState } from "react";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import toast from "react-hot-toast";
import { useDiaryContext } from "../hooks/DiaryContext";

const UserCalendarEventWrapper = ({ children, event, ...rest }) => {

    const { isEditable } = event || {};
    const { eventModal: { setProps } } = usePersonalOutlookContext();
    const { getEventById, monthIndex, getEventByIdResult: { isLoading, isFetching, data } } = useDiaryContext();

    const mapToFormDataModel = useCallback((eventModel) => {
        return ({
            id: eventModel.id,
            organizer: eventModel.organizerUserId,
            attendees: eventModel.attendees.map(({ emailAddress }) => ({ label: emailAddress.name ?? emailAddress.address, value: emailAddress.address })) ?? [],
            description: eventModel.description ?? null,
            startDate: eventModel.startDate ?? null,
            duration: eventModel.duration ?? null,
            masterAccount: eventModel.masterAccountId && eventModel.masterAccountName ? { label: eventModel.masterAccountName, value: eventModel.masterAccountId } : null,
            project: eventModel.projectId && eventModel.projectName ? { label: eventModel.projectName, value: eventModel.projectId } : null,
            meetingType: eventModel.meetingType ?? null,
            meetingLocation: eventModel.meetingLocation ?? null,
            body: eventModel.body ?? null,
            onlineMeetingUrl: eventModel.onlineMeetingUrl
        })
    }, []);

    const onClick = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setProps(prev => ({ show: true, isNew: false, isLoading: true, queryProps: { monthIndex } }));
        getEventById(event.id)
            .then(
                null,
                err => toast.error("Unable to get event.")
            );
    };

    useEffect(() => {
        if (!data) {
            return;
        }

        // when an event is successfully loaded
        setProps(prev => ({
            ...prev,
            initialData: mapToFormDataModel(data),
            isNew: false
        }));

    }, [data, isFetching]);

    useEffect(() => {
        setProps(prev => ({ ...prev, isLoading: isLoading || isFetching }))
    }, [isLoading, isFetching]);

    return <div onClick={isEditable ? onClick : () => { }}>
        {children}
    </div>
};

export default UserCalendarEventWrapper;
import React from "react";
import toast from "react-hot-toast";
import MasterAccountEmailSelect from "../../components/forms/selects/MasterAccountEmailSelect";
import MasterAccountResourceSelect from "../../components/forms/selects/MasterAccountResourceSelect";
import useCreateDashboardLogin from "./hooks/useCreateDashboardLogin";
import useIsEmailAddressAvailable from "./hooks/useIsEmailAddressAvailable";
import useIsUsernameAvailable from "./hooks/useIsUsernameAvailable";
import { Modal } from "react-bootstrap";
import { Button } from "../../components";
import { FormCheck, FormInput, FormSelect, PasswordInput } from "../../components/forms";
import { useForm, Controller } from "react-hook-form";

/* method to generate the usernames that have whitespace removed and set to all lower-case */
const stripUsername = (username) => username.replace(/ +/g, '').toLowerCase();

const getCorrectContactDetails = (mfaType, emailAddress, telephoneNumber) => {
    if (mfaType === 4) {
        return emailAddress;
    }
    if (mfaType === 5) {
        return telephoneNumber;
    }
    return '';
};

const ClientDashboardLoginCreateModal = ({
    show = false,
    title = "Create new Dashboard Login",
    masterAccountId,
    onLoginCreated = () => {},
    handleClose = () => {}
}) => {
    const { create, defaultValues, isLoading: isCreating } = useCreateDashboardLogin(masterAccountId);
    const { check: checkEmail, isLoading: isCheckingEmail } = useIsEmailAddressAvailable();
    const { check: checkUsername, isLoading: isCheckingUsername } = useIsUsernameAvailable();
    const { control, setError, formState, handleSubmit, reset, setValue, watch } = useForm({ defaultValues });
    const { errors } = formState;
    const isLoading = isCreating || isCheckingEmail || isCheckingUsername;
    const mfaType = watch('mfaType');
    const autoGeneratePassword = watch('autoGeneratePassword');
    
    const checkEmailAddressAvailability = (email) => {
        checkEmail(email).then(
            r => {
                const { result } = r;
                if (result) {
                    return true;
                }
                setError('emailAddress', { type: "custom", message: 'Email address is already in use.' });
            },
            e => {
                setError('emailAddress', { type: "custom", message: 'Email address is already in use.' });
            }
        );
    };

    const checkUsernameAvailability = (username) => {
        checkUsername(username).then(
            r => {
                const { result } = r;
                if (result) {
                    return true;
                }
                setError('username', { type: "custom", message: 'Username is already in use.' });
            },
            e => {
                setError('username', { type: "custom", message: 'Username is already in use.' });
            }
        );
    };

    const onResourceSelected = (resource) => {
        const { dirtyFields } = formState;
        const { id, displayName } = resource;

        setValue('resourceId', id);
        
        if (!dirtyFields['username']) {
            setValue('username', stripUsername(displayName));
            checkUsernameAvailability(stripUsername(displayName));
        }
    };

    const onSubmit = (data) => {
        const { autoGeneratePassword, emailAddress, telephoneNumber, emailAddressId, masterAccountId, mfaType, password, resourceId, sendWelcomeEmailOnCreate, username, ...permissions } = data;
        let newPassword = autoGeneratePassword === true ? null : password;
        let sendWelcomeEmail = sendWelcomeEmailOnCreate === true ? true : false;
        let authenticatorOptions = mfaType < 0 ? null : {
            authenticatorType: mfaType,
            details: getCorrectContactDetails(mfaType, emailAddress, telephoneNumber)
        };

        toast.promise(create({
            ...permissions,
            resourceId,
            username,
            password: newPassword,
            emailAddress,
            authenticatorOptions,
            sendWelcomeEmail
        }), {
            loading: `Attempting to create new login...`,
            success: (data) => {
                if (data) {
                    onLoginCreated(data);
                }
                reset(defaultValues);
                handleClose();
                return `Login created successfully.`;
            },
            error: (err) => <div>{err?.data?.message ?? "An unknown error occured while trying to create the login."}</div>
        });
    };

    React.useEffect(() => reset(defaultValues), [ defaultValues ]);

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller 
                                name="resourceId"
                                control={control}
                                rules={({ required: "A login needs to be associated with a client account or connection." })}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <MasterAccountResourceSelect
                                        masterAccountId={masterAccountId}
                                        label="Resource (Client/Connection)"    
                                        disableAnimations={true}
                                        defaultValue={value}
                                        onChange={(values) => onResourceSelected(values.data)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller 
                                name="username"
                                control={control}
                                rules={({ required: "A username must be supplied." })}
                                render={({ field: { ref, onBlur, ...rest } }) => (
                                    <FormInput
                                        label="Username"    
                                        disableAnimations={true}
                                        errorMessage={errors?.username?.message} 
                                        errorAllowRetry={false}
                                        onBlur={(e) => checkUsernameAvailability(e)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller 
                                name="emailAddressId"
                                control={control}
                                rules={({ required: "An email address must be supplied." })}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <MasterAccountEmailSelect
                                        masterAccountId={masterAccountId}
                                        label="Email Address"   
                                        defaultValue={value} 
                                        disableAnimations={true}
                                        onChange={(v) => {
                                            setValue('emailAddress', v.label);
                                            setValue('emailAddressId', v.value);
                                            checkEmailAddressAvailability(v.label);
                                        }}
                                        errorMessage={errors?.emailAddress?.message} 
                                        errorAllowRetry={false} 
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="border px-3 py-2 mb-3">
                        <legend className="fs-6">Account Credentials</legend>
                        <div className="row mb-3">
                            <div className="col">
                                <Controller 
                                    name="autoGeneratePassword"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormSelect
                                            label="Password Type" 
                                            options={[
                                                { label: 'Client Created', value: true },
                                                { label: 'Adviser Created (not recommended)', value: false }
                                            ]} 
                                            defaultValue={value} 
                                            disableAnimations={true}
                                            onChange={(v) => {
                                                setValue('password', '');
                                                setValue('autoGeneratePassword', v.value);
                                            }}
                                            errorMessage={errors?.autoGeneratePassword?.message} 
                                            errorAllowRetry={false} 
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col">
                                <Controller 
                                    name="mfaType"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormSelect
                                            label="Two-Factor Method" 
                                            options={[
                                                { label: 'Disabled (Unsecure)', value: -1 },
                                                { label: 'PIN number', value: 0, isDisabled: true },
                                                { label: 'TOTP (Autenticator App)', value: 3 },
                                                { label: 'HOTP (Email)', value: 4 },
                                                { label: 'HOTP (SMS)', value: 5, isDisabled: true }
                                            ]} 
                                            defaultValue={value} 
                                            disableAnimations={true}
                                            onChange={(v) => {
                                                setValue('mfaType', v.value);
                                            }}
                                            errorMessage={errors?.mfaType?.message} 
                                            errorAllowRetry={false} 
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {autoGeneratePassword == false && (
                            <div className="row mb-3">
                                <div className="col">
                                    <Controller 
                                        name="password"
                                        control={control}
                                        render={({ field: { ref, ...rest } }) => (
                                            <PasswordInput
                                                label="Password"    
                                                disableAnimations={true}
                                                errorMessage={errors?.password?.message} 
                                                errorAllowRetry={false} 
                                                {...rest}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="border px-3 py-2">
                        <legend className="fs-6">Account Permissions</legend>
                        <div className="row">
                            <div className="col-6">
                                <Controller 
                                    name="isPortfolioEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Portfolio Access"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isPortfolioEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isValuationsEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Valuations Access"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isValuationsEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isCommunicationsEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Communications Enabled"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isCommunicationsEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isFactFindEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Can View & Edit Fact Find"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isFactFindEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isBusinessSheetsEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Access to Business Sheets"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isBusinessSheetsEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isAdviceReportsEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Access to Advice Reports"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isAdviceReportsEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isTaxPacksEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Access to Tax Packs"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isTaxPacksEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isContractNotesEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Access to Contract Notes"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isContractNotesEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isCustodyStatementsEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Access to Custody Statements"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isCustodyStatementsEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isCostDisclosuresEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Access to Cost Disclosures"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isCostDisclosuresEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller 
                                    name="isPlanningToolsEnabled"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Has Access to Planning Tools"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isPlanningToolsEnabled', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="me-2">
                        <Controller 
                            name="sendWelcomeEmailOnCreate"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <FormCheck
                                    type="checkbox"
                                    label="Send Welcome Email"
                                    disableAnimations={true}
                                    isChecked={value}
                                    onChange={(isChecked) => {
                                        setValue('sendWelcomeEmailOnCreate', isChecked);
                                    }}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <Button variant="success" type="submit" disabled={isLoading}>Create</Button>
                    <Button variant="light" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default ClientDashboardLoginCreateModal;
import React from "react";
import Moment from "react-moment";
import toast from "react-hot-toast";
import UpdatePasswordModal from "./UpdatePasswordModal";
import useLoginCredentials from "../hooks/useLoginCredentials";
import useUpdateLoginCredentials from "../hooks/useUpdateLoginCredentials";
import useClientDashboardLoginDetails from "../hooks/ClientDashboardLoginDetailsContext";
import { useTheme } from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import { Button } from "../../../components";
import { ThemedParagraph, ThemedSpan } from "../../../components/utilities";
import { Spinner } from "../../../components/loaders";
import { useClientDashboardManagementControls } from "../../../hooks/ClientDashboardManagementContext";

const LoginCredentials = ({ data }) => {
    const theme = useTheme();
    const { data: credentials, isLoading } = useLoginCredentials(data?.masterAccountId, data?.id);
    const { update: changePassword, isLoading: isChangingPassword } = useUpdateLoginCredentials();
    const { isAllowedToSetPasswordManually } = useClientDashboardManagementControls();
    const { setIsDirty } = useClientDashboardLoginDetails();

    const updatePassword = () => {
        const { id, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <UpdatePasswordModal 
                    theme={theme}
                    size="md"
                    handleConfirm={(password) => {
                        onClose();
                        toast.promise(changePassword(masterAccountId, id, password), {
                            loading: `Updating dashboard login credentials...`,
                            success: () => {
                                setIsDirty(_ => true);
                                return `The dashboard login credentials have been changed.`;
                            },
                            error: `Failed to change the dashboard login credentials.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    if (!data) {
        return null;
    }

    return (
        <div className="border px-3 py-2 mb-3">
            <legend className="fs-6">Credentials (Account Password)</legend>
            {isLoading === true && (
                <div className="d-flex justify-content-center py-2 w-100">
                    <Spinner />
                </div>
            )}
            {isLoading === false && !credentials && (
                <div className="d-flex flex-column flex-fill py-1">
                    <ThemedSpan variant="muted">There are no credentials configured for this login.</ThemedSpan>
                </div>
            )}
            {isLoading === false && credentials && (
                <div className="d-flex flex-column flex-fill py-1">
                    <div className="d-flex flex-row flex-fill mb-3">
                        <div className="me-3">
                            <span className="fw-bold">Algorithm: </span>{credentials.algorithm}
                        </div>
                        <div>
                            <span className="fw-bold">Hash Iterations: </span>{credentials.hashIterations}
                        </div>
                    </div>
                    <ThemedParagraph variant="muted">
                        Credentials were created on <Moment format="YYYY-MM-DD HH:mm:ss">{credentials.createdDate}</Moment>
                        {credentials.lastLoginAttemptDate 
                            ? (<React.Fragment> and last accessed on <Moment format="YYYY-MM-DD HH:mm:ss">{credentials.lastLoginAttemptDate}</Moment>.</React.Fragment>) 
                            : "."
                        }
                    </ThemedParagraph>
                </div>
            )}
            {isLoading === false && isAllowedToSetPasswordManually === true && (
                <div className="d-flex flex-row justify-content-end my-2">
                    <Button variant="danger" disabled={isChangingPassword} onClick={updatePassword}>{credentials ? "Update Password" : "Create Password"}</Button>
                </div>
            )}
        </div>
    );
};

export default LoginCredentials;
import AdministrationAssetsLayout from "../layouts/AdministrationAssetsLayout";
import { NavigationTabs } from "../components/navigation";
import {
    AssetsManagementPage,
    AssetsPricesManagementPage,
    AssetsProvidersManagementPage,
    AssetsWrappersManagementPage,
    AssetsProductsManagementPage,
    AssetsCorporateActionPairsManagementPage
} from "../pages/administration";

const AdministrationAssetRoutes = {
    path: 'assets/*',
    pathBase: '/administration/assets',
    defaultRoute: 'summary',
    roles: [],
    element: AdministrationAssetsLayout,
    children: [
        {
            path: 'summary',
            icon: 'fa-money-bill-trend-up',
            label: 'Assets',
            roles: [],
            element: AssetsManagementPage
        },
        {
            path: 'prices',
            icon: 'fa-money-bill-trend-up',
            label: 'Prices',
            roles: [],
            element: AssetsPricesManagementPage
        },
        {
            path: 'providers',
            icon: 'fa-hand-holding-dollar',
            label: 'Providers',
            roles: [],
            element: AssetsProvidersManagementPage
        },
        {
            path: 'wrappers',
            icon: 'fa-gift',
            label: 'Wrappers',
            roles: [],
            element: AssetsWrappersManagementPage
        },
        {
            path: 'products',
            icon: 'fa-cart-shopping',
            label: 'Products',
            roles: [],
            element: AssetsProductsManagementPage
        },
        {
            path: 'actionpairs',
            icon: 'fa-handshake',
            label: 'Corporate Action Pairs',
            roles: [],
            element: AssetsCorporateActionPairsManagementPage
        }
    ]
};

export const AdministrationAssetRouteTabs = () => <NavigationTabs pathBase={AdministrationAssetRoutes.pathBase} options={AdministrationAssetRoutes} />

export default AdministrationAssetRoutes;
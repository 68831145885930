import { useCallback, useEffect, useMemo, useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchExpenditureDetailsSectionQuery, useUpdateExpenditureValueMutation } from "../../../services/clientfactfind";
import { useExpenditureDetails } from "../contexts/ExpenditureDetailsContext";

const useExpenditureColumn = (clientExpenditureId) => {
    const { id: masterAccountId } = useClientContext();

    const client = useFetchExpenditureDetailsSectionQuery({ masterAccountId }, {
        selectFromResult: ({ data }) =>
            data?.clients?.find(client => client.clientExpenditureId === clientExpenditureId)
    });

    const [updateExpenditureValue] = useUpdateExpenditureValueMutation();

    const [{
        updateOverallMonthlyExpenditure,
        updateMortgageRentExpenditures
    }, {
        filterCombined,
        realTimeNonDiscTypeInfo: nonDiscExpTypeInfo,
        realTimeDiscTypeInfo: discExpTypeInfo,
        isLoading: sectionIsLoading,
        realTimeFrequencies: expenditureFrequencies
    }] = useExpenditureDetails();

    const monthlyFrequencyId = useMemo(() => expenditureFrequencies?.find(({ label }) => label === "Monthly")?.value, [expenditureFrequencies]);
    const annualFrequencyId = useMemo(() => expenditureFrequencies?.find(({ label }) => label === "Annual")?.value, [expenditureFrequencies]);

    const isLoading = useMemo(() => sectionIsLoading, [sectionIsLoading]);

    const [mutableValues, setMutableValues] = useState();

    useEffect(() => {
        setMutableValues(client?.expenditureEntries);
    }, [client?.expenditureEntries]);

    const [totalMonthlyNonDiscExpenditure, setTotalMonthlyNonDiscExpenditure] = useState(0);
    const [totalAnnualNonDiscExpenditure, setTotalAnnualNonDiscExpenditure] = useState(0);
    const [totalMonthlyDiscExpenditure, setTotalMonthlyDiscExpenditure] = useState(0);
    const [totalAnnualDiscExpenditure, setTotalAnnualDiscExpenditure] = useState(0);

    const [totalMonthlyExpenditure, setTotalMonthlyExpenditure] = useState(0);
    const [totalAnnualExpenditure, setTotalAnnualExpenditure] = useState(0);

    const patchValueCache = useCallback((typeId, newValue) => {
        setMutableValues((oldValues) => [
            ...oldValues.slice(0, oldValues.findIndex(exp => exp.expenditureTypeId === typeId)),
            {
                ...oldValues.find(exp => exp.expenditureTypeId === typeId),
                value: newValue
            },
            ...oldValues.slice(oldValues.findIndex(exp => exp.expenditureTypeId === typeId) + 1)
        ]);
    }, []);

    const patchValueService = useCallback((typeId, newValue) =>
        updateExpenditureValue({ masterAccountId, clientExpenditureId, expenditureTypeId: typeId, newValue })
            .unwrap(), [clientExpenditureId, masterAccountId, updateExpenditureValue]);

    const calculateNonDiscExpenditures = useCallback(() => {
        var monthlyAccumulator = 0;
        var annualAccumulator = 0;

        nonDiscExpTypeInfo?.forEach(expType => {
            switch (expType?.frequency) {
                case monthlyFrequencyId:
                    monthlyAccumulator += mutableValues?.find(exp => exp.expenditureTypeId === expType?.expenditureTypeId)?.value ?? 0;
                    break;
                case annualFrequencyId:
                    annualAccumulator += mutableValues?.find(exp => exp.expenditureTypeId === expType?.expenditureTypeId)?.value ?? 0;
                    break;
                default:
                    break;
            }
        });

        const newAnnual = annualAccumulator + monthlyAccumulator * 12;
        const newMonthly = newAnnual / 12;

        return [newMonthly, newAnnual];
    }, [annualFrequencyId, monthlyFrequencyId, mutableValues, nonDiscExpTypeInfo]);

    const calculateDiscExpenditures = useCallback(() => {
        var monthlyAccumulator = 0;
        var annualAccumulator = 0;

        discExpTypeInfo?.forEach(expType => {
            switch (expType?.frequency) {
                case monthlyFrequencyId:
                    monthlyAccumulator += mutableValues?.find(exp => exp.expenditureTypeId === expType?.expenditureTypeId)?.value ?? 0;
                    break;
                case annualFrequencyId:
                    annualAccumulator += mutableValues?.find(exp => exp.expenditureTypeId === expType?.expenditureTypeId)?.value ?? 0;
                    break;
                default:
                    break;
            }
        });

        const newAnnual = annualAccumulator + monthlyAccumulator * 12;
        const newMonthly = newAnnual / 12;

        return [newMonthly, newAnnual];
    }, [annualFrequencyId, discExpTypeInfo, monthlyFrequencyId, mutableValues])

    // Non-Discretionary useEffect
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (client?.isCombined && filterCombined) {
                setTotalMonthlyNonDiscExpenditure(0);
                setTotalAnnualNonDiscExpenditure(0);
                setTotalMonthlyExpenditure(0);
                setTotalAnnualExpenditure(0);

                updateMortgageRentExpenditures(client?.clientExpenditureId, 0);
            }
            const [newMonthly, newAnnual] = calculateNonDiscExpenditures();
            const [discMonthly, discAnnual] = calculateDiscExpenditures()
            const [newMonthlyTotal, newAnnualTotal] = [newMonthly + discMonthly, newAnnual + discAnnual];

            setTotalMonthlyNonDiscExpenditure(newMonthly);
            setTotalAnnualNonDiscExpenditure(newAnnual);

            setTotalMonthlyExpenditure(newMonthlyTotal);
            setTotalAnnualExpenditure(newAnnualTotal);

            updateOverallMonthlyExpenditure(totalMonthlyNonDiscExpenditure, newMonthly);

            // Calculate the mortgage/rent annual value
            const mortgageRentInfo = nonDiscExpTypeInfo?.find(freq => freq?.expenditureType?.typeEnum === 10);
            const mortgageRentValue = mutableValues?.find(exp => exp.expenditureType?.typeEnum === 10)?.value ?? 0
            const newMortgageRentValue = mortgageRentInfo?.frequency === monthlyFrequencyId
                ? mortgageRentValue * 12
                : mortgageRentValue;

            updateMortgageRentExpenditures(client?.clientExpenditureId, newMortgageRentValue);
        }, 50);

        return () => clearTimeout(timeout);
    }, [calculateDiscExpenditures, calculateNonDiscExpenditures, client?.clientExpenditureId, client?.isCombined, filterCombined, monthlyFrequencyId, mutableValues, nonDiscExpTypeInfo, totalMonthlyNonDiscExpenditure, updateMortgageRentExpenditures, updateOverallMonthlyExpenditure]);

    // Discretionary useEffect
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (client?.isCombined && filterCombined) {
                setTotalMonthlyDiscExpenditure(0);
                setTotalAnnualDiscExpenditure(0);
                setTotalMonthlyExpenditure(0);
                setTotalAnnualExpenditure(0);
            }

            const [newMonthly, newAnnual] = calculateDiscExpenditures();
            const [nonDiscMonthly, nonDiscAnnual] = calculateNonDiscExpenditures()
            const [newMonthlyTotal, newAnnualTotal] = [newMonthly + nonDiscMonthly, newAnnual + nonDiscAnnual];

            setTotalMonthlyDiscExpenditure(newMonthly);
            setTotalAnnualDiscExpenditure(newAnnual);

            setTotalMonthlyExpenditure(newMonthlyTotal);
            setTotalAnnualExpenditure(newAnnualTotal);

            updateOverallMonthlyExpenditure(totalMonthlyDiscExpenditure, newMonthly);

            // Currently calculations aren't stored in db, uncomment if this changes
            // patchService("totalMonthlyDiscExpenditure", newMonthly);
            // patchService("totalAnnualDiscExpenditure", newAnnual);
        }, 50);

        return () => clearTimeout(timeout);
    }, [calculateDiscExpenditures, calculateNonDiscExpenditures, client?.isCombined, filterCombined, totalMonthlyDiscExpenditure, updateOverallMonthlyExpenditure]);

    return [
        {
            patchValueCache,
            patchValueService
        }, {
            client,
            totalAnnualNonDiscExpenditure,
            totalAnnualDiscExpenditure,
            totalAnnualExpenditure,
            totalMonthlyNonDiscExpenditure,
            totalMonthlyDiscExpenditure,
            totalMonthlyExpenditure,
            nonDiscExpTypeInfo,
            discExpTypeInfo,
            isLoading
        }
    ]
}

export default useExpenditureColumn;
import { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateReminderTaskWithAttachmentsMutation } from "../../../services/reminders";
import { MessagePriorities } from "../../../helpers/constants";
import { useAuth } from "react-oidc-context";

const ReminderFormContext = createContext();

export const useReminderForm = () => useContext(ReminderFormContext);

const ReminderFormProvider = ({ children, masterAccountId, projectId }) => {
    const { user: { profile } } = useAuth();
    const [create, createResults] = useCreateReminderTaskWithAttachmentsMutation();
    const [defaultValues, setDefaultValues] = useState({
        masterAccount: null,
        project: null,
        userId: profile.sub.replace(/-/g, ''),
        typeId: "516a87a360c449469b6a74932c2a7348",
        description: "",
        notes: "",
        deadlineDate: null,
        priority: MessagePriorities.Medium,
        attachments: [],
        attachmentsInfo: [],
        status: 0
    });
    const form = useForm({ defaultValues });
    const { watch, reset } = form;
    const formValues = watch();

    const handleCreate = (formData) => {

        const data = getFormData(formData);

        return new Promise((resolve, reject) => {
            return create({ data }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const getFormData = (data) => {
        const reminder = data ?? formValues;
        const formData = new FormData();

        reminder.attachments.forEach(file => {
            formData.append(file.name, file);
        });

        formData.append("fileDetails", JSON.stringify(reminder.attachmentsInfo));

        formData.append("typeId", reminder.typeId);
        formData.append("userId", reminder.userId);
        formData.append("description", reminder.description);
        formData.append("notes", reminder.notes);
        reminder.deadlineDate && formData.append("deadlineDate", reminder.deadlineDate);
        formData.append("priority", reminder.priority);
        formData.append("status", reminder.status);

        let masterAccountIdValue = masterAccountId ?? reminder.masterAccount?.value;
        masterAccountIdValue && formData.append("masterAccountId", masterAccountIdValue);

        let projectIdValue = projectId ?? reminder.project?.value
        projectIdValue && formData.append("projectId", projectIdValue);
        return formData;
    };

    const resetForm = (callbackFn) => {
        reset(defaultValues);
        callbackFn && callbackFn();
    }

    return <ReminderFormContext.Provider value={{
        createResults,
        defaultValues,
        form,
        masterAccountId,
        projectId,
        handleCreate,
        resetForm
    }}>
        {children}
    </ReminderFormContext.Provider>
};

export default ReminderFormProvider;
import { useEffect, useState } from "react";
import { useClientEmails } from "./ClientEmailsContext";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchMasterAccountEmailConversationMessagePreviewsQuery } from "../../../services/useremails/masterAccountEmailEndpoints";

const useClientEmailConversationMessages = (conversationId) => {
    const { searchTerm, attachmentsOnly, setMailId } = useClientEmails();
    const { id: masterAccountId } = useClientContext();
    const limit = 50;
    const [isClearing, setIsClearing] = useState(false);
    const [fetchPreviews, { data, isLoading, isFetching, isError, error, isUninitialized }] = useLazyFetchMasterAccountEmailConversationMessagePreviewsQuery();
    const { results, pagination } = data || { results: [], pagination: { totalCount: 50, page: 0 } };
    const { totalCount, page } = pagination;

    const clear = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setIsClearing(true);

        fetchPreviews({
            page: 1,
            limit,
            masterAccountId,
            searchTerm,
            conversationId,
            attachmentsOnly
        }).unwrap()
            .then(({ results }) => {
                if (results?.length)
                    setMailId(results[0].graphId);
            })
            .finally(_ => setIsClearing(false));
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => fetchPreviews({
        page: page + 1,
        limit,
        masterAccountId,
        searchTerm,
        conversationId,
        attachmentsOnly
    });

    useEffect(() => {
        !isUninitialized && clear();
    }, [searchTerm, conversationId, attachmentsOnly])

    return {
        results,
        error,
        isClearing,
        isError,
        isLoading: isFetching,
        searchTerm,
        totalCount,
        clear,
        isItemLoaded,
        loadMoreItems: page === 0 ? clear : loadMoreItems
    };
};

export default useClientEmailConversationMessages;
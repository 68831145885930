import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useCreateAssetMutation, useDeleteAssetMutation, useLazyFetchAssetsForGridQuery, usePatchAssetMutation } from "../../../services/assets/assetApiEndpoints";
import { patchReplace } from "../../../helpers/patchDoc";


const DefaultAssetsFilter = {
    assetName: null,
    sedol: null,
    provider: null,
    citicode: null
};

const AssetsManagementContext = createContext();

export const useAssetsManagement = () => useContext(AssetsManagementContext);

const AssetsManagementProvider = ({ children }) => {
    const limit = 50;
    const [assetsFilter, setAssetsFilter] = useState(DefaultAssetsFilter);
    const [sort, setSort] = useState("AssetName");
    const [assetIdToDelete, setAssetIdToDelete] = useState(null);
    const [assetIdToEdit, setAssetIdToEdit] = useState(null);

    const [searchAssets, searchAssetsResult] = useLazyFetchAssetsForGridQuery();
    const { data, isError, error, isUninitialized } = searchAssetsResult;
    const { next, results, totalCount } = data || { next: null, results: [], totalCount: limit };

    const [createTrigger, createResult] = useCreateAssetMutation();
    const [deleteTrigger, deleteResult] = useDeleteAssetMutation();
    const [patchTrigger, patchResult] = usePatchAssetMutation();

    const clear = () => {
        searchAssets({
            next: null,
            sort,
            limit,
            request: {
                ...assetsFilter,
                providerId: assetsFilter.provider?.value,
            }
        });
    };

    const createAsset = () => {
        return new Promise((resolve, reject) => {
            return createTrigger({}).unwrap().then(resolve, reject);
        });
    };

    const deleteSelectedAsset = () => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ assetId: assetIdToDelete }).unwrap().then(resolve, reject);
        });
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        return searchAssets({
            next,
            sort,
            limit,
            request: {
                ...assetsFilter,
                providerId: assetsFilter.provider?.value,
            }
        });
    };

    const resetFilters = () => {
        setAssetsFilter(DefaultAssetsFilter);
    };

    const patchSelectedAsset = (property, newValue, assignableObject) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patchTrigger({ assetId: assetIdToEdit, operations, assignableObject }).unwrap().then(resolve, reject);
        });
    };

    useEffect(() => {
        !isUninitialized && clear();
    }, [sort, assetsFilter]);

    const columns = [
        {
            label: "Asset Name",
        },
        {
            label: "Provider",
        },
        {
            label: "Fund Code",
        },
        {
            label: "Citicode",
        },
        {
            label: "SEDOL",
        },
        {
            label: "Sector Code",
        },
        {
            label: "Inc/Acc",
        },
        {
            label: "Finex Subcategory",
        },
        {
            label: "Currency",
        },
        {
            label: "Priced",
            className: "text-center"
        },
        {
            label: "Mid Price",
        },
        {
            label: "Bid Price",
        },
        {
            label: "Offer Price",
        },
        {
            label: "Price Date",
        }
    ];

    return <AssetsManagementContext.Provider value={{
        assetsFilter,
        assetToDelete: assetIdToDelete ? results.find(x => x.assetId === assetIdToDelete) : null,
        assetToEdit: assetIdToEdit ? results.find(x => x.assetId === assetIdToEdit) : null,
        columns,
        createResult,
        data,
        error,
        isError,
        limit,
        results,
        sort,
        totalCount,
        clear,
        createAsset,
        deleteSelectedAsset,
        isItemLoaded,
        loadMoreItems,
        patchSelectedAsset,
        resetFilters,
        setAssetsFilter,
        setAssetIdToDelete,
        setAssetIdToEdit,
        setSort,
    }}>
        {children}
    </AssetsManagementContext.Provider>

};

export default AssetsManagementProvider;
import React from "react";
import AccessLevelDisplayColumn from "./AccessLevelDisplayColumn";
import BooleanPillDisplayColumn from "./BooleanPillDisplayColumn";
import MfaTypeDisplayColumn from "./MfaTypeDisplayColumn";
import TableColumn from "./TableColumn";
import TableColumnAction from "./TableColumnAction";
import { DateDisplay } from "../../../components";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";

const TableRow = ({ data, index, style, dateFormat = "DD/MM/YYYY" }) => {
    const { height } = style;

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length) {
		return <TableLoadingRow style={{ height }} />
	}

    const {
        username,
        emailAddress,
        isPortfolioEnabled,
        isValuationsEnabled,
        isCommunicationsEnabled,
        isFactFindEnabled,
        isBusinessSheetsEnabled,
        isAdviceReportsEnabled,
        isTaxPacksEnabled,
        isContractNotesEnabled,
        isCustodyStatementsEnabled,
        isCostDisclosuresEnabled,
        isPlanningToolsEnabled,
        hasRegistered,
        mfaType,
        isActive,
        isLocked,
        isOnline
    } = data[index];

    const roleOptions = {
        isPortfolioEnabled,
        isValuationsEnabled,
        isCommunicationsEnabled,
        isFactFindEnabled,
        isBusinessSheetsEnabled,
        isAdviceReportsEnabled,
        isTaxPacksEnabled,
        isContractNotesEnabled,
        isCustodyStatementsEnabled,
        isCostDisclosuresEnabled,
        isPlanningToolsEnabled
    };

    return (
        <tr>
            <TableColumn style={{ height }}>
                {username}
            </TableColumn>
            <TableColumn>
                {emailAddress}
            </TableColumn>
            <AccessLevelDisplayColumn data={roleOptions} />
            <BooleanPillDisplayColumn value={hasRegistered} falseText="Unregistered" trueText="Registered" />
            <MfaTypeDisplayColumn value={mfaType} />
            <BooleanPillDisplayColumn value={isActive} falseText="Inactive" trueText="Active" />
            <BooleanPillDisplayColumn value={!isLocked} falseText="Locked" trueText="Unlocked" />
            <BooleanPillDisplayColumn value={isOnline} falseText="Offline" trueText="Online" />
            <TableColumnAction data={data[index]} />
        </tr>
    )
};

export default TableRow;
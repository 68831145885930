import { wealthProApi } from '../../redux/api';

const divisionApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchAvailableDivisions: builder.query({
            query: () => `api/identity/divisions`
        })
    })
});

export const {
    useFetchAvailableDivisionsQuery
} = divisionApiEndpoints;
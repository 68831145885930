import { useUploadReminderAttachmentMutation } from "../../../services/reminders";

const useReminderAttachments = (reminderId) => {

    const [uploadAttachments, uploadAttachmentsResult] = useUploadReminderAttachmentMutation();

    const uploadReminderAttachments = (attachments = [], masterAccountId) => {
        return new Promise((resolve, reject) => {
            const request = new FormData();
            attachments.forEach((attachment) => {
                request.append(attachment.name, attachment);
            });

            uploadAttachments({reminderId, masterAccountId, request})
                .unwrap()
                .then(resolve, reject);
        });
    }

    return {
        uploadReminderAttachments,
        uploadAttachmentsResult
    };
};

export default useReminderAttachments;
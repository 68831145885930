import { ThemedTableHeader } from "../../../components/tables/StandardTable";

const AdviceGridHeaderRow = ({ columns }) => {

    return (
        <thead>
            <tr>
                {columns.map((col, key) => col.headerRenderer
                    ? col.headerRenderer({ ...col, key })
                    : <ThemedTableHeader key={key} className={"sticky-header"} style={{ width: col.width }}>
                        {col.label}
                    </ThemedTableHeader>)}
            </tr>
        </thead>
    );
};

export default AdviceGridHeaderRow;
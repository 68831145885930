import React from "react";
import { Dropdown } from "react-bootstrap";
import { IconButton } from "../../../components";

const MessageActionMenuDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
    <IconButton
        ref={ref}
        className="border"
        variant="light"
        icon="ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const EditMessageActionMenuItem = ({ onClick }) => {
    const onHandleClick = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        if (!onClick || typeof (onClick) !== 'function') {
            return;
        }
        onClick(e);
    };

    return (
        <Dropdown.Item onClick={onHandleClick}>
            Edit
        </Dropdown.Item>
    );
};

const MessageActionMenuDropdown = ({ onEditClick }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    return (
        <Dropdown id={menuId} className="caret-off w-100">
            <Dropdown.Toggle as={MessageActionMenuDropdownToggle} id={toggleId} />
            <Dropdown.Menu>
                <React.Fragment>
                    <EditMessageActionMenuItem
                        onClick={onEditClick}
                    />
                </React.Fragment>
            </Dropdown.Menu>
        </Dropdown>
    );

};

export default MessageActionMenuDropdown;
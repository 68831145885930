import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

const introducerApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchIntroducers: builder.query({
            query: () => `api/review/Introducers?sort=Surname&fetchType=1`
        }),
        searchIntroducers: builder.query({
            query: (body) =>({
                url: `api/review/Introducers/search`,
                method: "POST",
                body
            }),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            forceRefetch: ({ currentArg, previousArg }) => {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },
        }),
        fetchIntroducer: builder.query({
            query: ({ introducerId }) => `api/review/Introducers/${introducerId}`
        }),
        patchIntroducer: builder.mutation({
            query: ({ introducerId, operations }) => ({
                url: `api/review/Introducers/${introducerId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ introducerId, operations }, { dispatch, queryFulfilled }) {
                try {
                    var result = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData('fetchIntroducer', { introducerId }, (data) => {
                            return {...data, ...result.data};
                        }));
                } 
                catch {}
            }
        }),
        createIntroducer: builder.mutation({
            query: ({ data }) => ({
                url: `api/review/Introducers`,
                method: 'POST',
                body: data
            }),
            // async onQueryStarted({}, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: createData } = await queryFulfilled;
            //         dispatch(wealthProApi.util.updateQueryData('fetchIntroducers', {}, (data) => {
            //             data.push({
            //                 ...createData
            //             });
            //         }));
            //     }
            //     catch { }
            // }
        }),
        patchClientIntroducerAgreement: builder.mutation({
            query: ({ clientIntroducerAgreementId, masterAccountId, operations }) => ({
                url: `api/review/ClientIntroducerAgreement/${clientIntroducerAgreementId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ clientIntroducerAgreementId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                try {
                    var result = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData('fetchClientIntroducerAgreement', { masterAccountId }, (data) => {
                            return {...data, ...result.data};
                        }));
                } 
                catch {}
            }
        }),
        createClientIntroducerAgreement: builder.mutation({
            query: ({ masterAccountId }) => ({
                url: `api/review/ClientIntroducerAgreement/${masterAccountId}`,
                method: 'POST',
                body: {}
            }),
            invalidatesTags: ['clientIntroducerAgreements']
        }),
        fetchIntroducerRelationships: builder.query({
            query: ({ introducerId }) => `api/review/Introducers/relationships/${introducerId}`
        }),
    })
});

export const {
    useFetchIntroducersQuery,
    useSearchIntroducersQuery,
    useLazySearchIntroducersQuery,
    useFetchIntroducerQuery,
    usePatchIntroducerMutation,
    useCreateIntroducerMutation,
    useFetchIntroducerRelationshipsQuery
} = introducerApiEndpoints;
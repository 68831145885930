import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { GridWrapper } from "../../../components";
import IconButton from "../../../components/buttons/IconButton";
import { CurrencyInput, FormLabel, FormSelect } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { patchReplace } from "../../../helpers/patchDoc";
import { useInstruction } from "../contexts/InstructionContext";
import useSourceOfFunds from "../hooks/useSourceOfFunds";
import InvestmentCash from "./InvestmentCash";
import { StyledLabel } from "../../../components/forms/FormLabel";

const SourceOfFunds = ({ hasAps = false, isIsaSub = false, totalLabel = "Total Investment:" }) => {
    const [, { realTimeInstruction, invest, investIsLoading, investIsFetching, investIsUninitialised }, { taxYears, showTaxReclaim }] = useInstruction();

    const {
        retry,
        investmentCash,
        isLoading,
        isFetching,
        isError,
        patchInvest,
        realTimePatchInvest,
        realTimePatchInvestSingle,
        createCash,
        realTimePatchCashSingle,
        patchCash,
        bulkPatchCash,
        deleteCash
    } = useSourceOfFunds();

    const gridCols = useMemo(() => "minmax(20%, 250px) repeat(2, 20ch) 15% auto 1fr", []);

    // Set default Tax Year, if appropriate
    useEffect(() => {
        if (invest?.id && invest?.taxYear == null && !(showTaxReclaim))
            patchInvest("taxYear", taxYears[0].value);
    }, [invest?.id, invest?.taxYear, patchInvest, showTaxReclaim, taxYears])

    // Reset the employer contribution when show tax reclaim changes to false
    useEffect(() => {
        if (!showTaxReclaim && ((realTimeInstruction?.invest?.cashFromEmployer ?? 0) !== 0))
            realTimePatchInvest([patchReplace("employerContribution", 0)]);
    }, [realTimeInstruction?.invest?.cashFromEmployer, realTimePatchInvest, showTaxReclaim]);

    // Real-time update Client Tax Reclaim
    useEffect(() => {
        const updateRealTimeTimeout = setTimeout(() => {
            if (!showTaxReclaim)
                realTimePatchInvest([patchReplace("clientTaxReclaim", 0)]);
            else if (realTimeInstruction?.invest?.clientTaxReclaim !== realTimeInstruction?.invest?.cashFromClient / 5)
                realTimePatchInvest([patchReplace("clientTaxReclaim", realTimeInstruction?.invest?.cashFromClient / 5)]);
        }, 150);

        return () => clearTimeout(updateRealTimeTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realTimeInstruction?.invest?.cashFromClient, realTimePatchInvest, showTaxReclaim]);

    // OnBlur update of Client Tax Reclaim
    useEffect(() => {
        const updateTimeout = setTimeout(() => {
            if (!showTaxReclaim)
                patchInvest("clientTaxReclaim", 0);
            else if (invest?.clientTaxReclaim !== invest?.cashFromClient / 5)
                patchInvest("clientTaxReclaim", invest?.cashFromClient / 5);
        }, 150);

        return () => clearTimeout(updateTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invest?.cashFromClient, patchInvest, showTaxReclaim]);

    if (isError)
        return <ErrorMessageWrapper maxWidth="500px">
            <ErrorMessage
                message="Sorry! We were unable to load the source of funds for this instruction. Please try again"
                retryCallback={retry}
            />
        </ErrorMessageWrapper>;

    return <div className="pt-3">
        <GridWrapper gridTemplateColumns={gridCols}>
            <FormLabel className="col-start-1 col-end-4 my-auto justify-self-end">
                {showTaxReclaim ? "Gross Contribution:" : "Additional Client Investment:"}
            </FormLabel>

            {investIsLoading || investIsFetching || investIsUninitialised
                ? <Skeleton />
                : <CurrencyInput
                    value={realTimeInstruction?.invest?.cashFromClient ?? 0}
                    // Changing this to auto-calculate tax reclaim (if applicable) based on the gross contribution
                    onChange={(_, values) => realTimePatchInvestSingle("cashFromClient", values.floatValue)}
                    onBlur={(amount) => patchInvest('cashFromClient', amount)}
                    // value={invest?.cashFromClient ?? 0}
                    // onChange={(_, values) => realTimePatchInvestSingle("cashFromClient", values.floatValue)}
                    // onBlur={(amount) => patchInvest('cashFromClient', amount)}
                    allowNegative={false}
                />}

            {(investIsLoading || investIsFetching || investmentCash?.length === 0)
                ? <span></span>
                : !hasAps && <IconButton
                    icon={"circle-plus"}
                    className={"align-self-center"}
                    variant="success"
                    onClick={createCash}
                />}

            {showTaxReclaim &&
                <GridWrapper gridTemplateColumns={"repeat(2, 10ch minmax(20%, 20ch))"} >
                    <FormLabel className="align-self-center mb-0">Tax Reclaim:</FormLabel>

                    <CurrencyInput
                        className="justify-self-start"
                        value={realTimeInstruction?.invest?.clientTaxReclaim ?? 0}
                        // value={invest?.clientTaxReclaim ?? 0}
                        // onChange={(_, sourceInfo) => realTimePatchInvestSingle("clientTaxReclaim", sourceInfo.floatValue)}
                        // onBlur={(amount) => patchInvest("clientTaxReclaim", amount)}
                        // allowNegative={false}
                        readOnly
                    />
                </GridWrapper>}

            {hasAps && <>
                <FormLabel className="col-start-1 col-end-4 align-self-center ms-auto my-auto">
                    Additional Permitted Subscription:
                </FormLabel>

                {investIsLoading || investIsFetching
                    ? <Skeleton />
                    : <CurrencyInput
                        value={invest?.cashFromAPS ?? 0}
                        onChange={(_, sourceInfo) => realTimePatchInvestSingle("cashFromAPS", sourceInfo.floatValue)}
                        onBlur={(amount) => patchInvest('cashFromAPS', amount)}
                        allowNegative={false}
                    />}

                {(investIsLoading || investIsFetching || investmentCash?.length === 0)
                    ? <span></span>
                    : <IconButton
                        icon={"circle-plus"}
                        className={"align-self-center"}
                        variant="success"
                        onClick={createCash}
                        disabled={investIsLoading || investIsFetching}
                    />}
            </>}

            {investmentCash?.length > 0 && <>
                <FormLabel className={"col-start-1"}>Client:</FormLabel>
                <FormLabel>Provider Reference:</FormLabel>
                <FormLabel>Custody Reference:</FormLabel>
                <FormLabel>Amount:</FormLabel>

                {investmentCash?.map((investmentCash, index) => <React.Fragment key={investmentCash?.id ?? index}>
                    <InvestmentCash
                        index={index}
                        hasTaxReclaim={showTaxReclaim}
                        item={investmentCash}
                        isLoading={isLoading || isFetching || !investmentCash?.id}
                        patchRow={patchCash}
                        bulkPatchRow={bulkPatchCash}
                        realTimePatchRow={realTimePatchCashSingle}
                        deleteRow={deleteCash}
                    />
                </React.Fragment>)}

            </>}

            {showTaxReclaim && <>
                <FormLabel className="col-start-1 col-end-4 my-auto justify-self-end">
                    Gross Employer Contribution:
                </FormLabel>

                {investIsLoading || investIsFetching
                    ? <Skeleton />
                    : <CurrencyInput
                        value={invest?.cashFromEmployer ?? 0}
                        onChange={(_, sourceInfo) => realTimePatchInvestSingle("cashFromEmployer", sourceInfo.floatValue)}
                        onBlur={(amount) => patchInvest('cashFromEmployer', amount)}
                        allowNegative={false}
                    />}
            </>}
        </GridWrapper>

        {investmentCash?.length === 0 && <StyledLabel className="m-2">
            Click <button className="anchor" onClick={createCash}>here</button> to specify a fund source from an existing investment
        </StyledLabel>}

        <GridWrapper className="pt-2" gridTemplateColumns={"minmax(20%, 250px) repeat(2, 20ch) 15% auto 1fr"}>
            <FormLabel className={"col-start-1 col-end-4 justify-self-end align-self-end my-auto"}>{totalLabel}</FormLabel>

            {isLoading || investIsLoading || investIsFetching
                ? <Skeleton />
                : <CurrencyInput
                    value={realTimeInstruction?.invest?.totalSubscriptionAmount ?? 0}
                    readOnly
                />}

            <span className="invisible">
                <IconButton
                    icon="circle-plus"
                />
            </span>

            <GridWrapper className="col-start-6 col-end-8" gridTemplateColumns={"repeat(2, 10ch minmax(20%, 20ch))"}>
                {(showTaxReclaim)
                    && <>
                        <FormLabel className="align-self-end">Tax Reclaim:</FormLabel>
                        <CurrencyInput
                            className="justify-self-start"
                            value={realTimeInstruction?.invest?.taxReclaim ?? 0}
                            readOnly
                        />
                    </>}
                {(isIsaSub || showTaxReclaim)
                    && <>
                        <FormLabel className="justify-self-end align-self-end">Tax Year:</FormLabel>
                        <FormSelect
                            options={taxYears}
                            defaultValue={invest?.taxYear}
                            onBlur={(selection) => patchInvest("taxYear", selection.value)}
                        />
                    </>}
            </GridWrapper>
        </GridWrapper>
    </div>;
};

export default SourceOfFunds;

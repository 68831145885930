import { useFetchPrdReportTypeQuery,
    useFetchReviewFollowUpReportTypeQuery } from "../../../services/clientreports";

const useReportTypes = () => {

    const {data: prdType,  isLoading: loadingPrdType,} = useFetchPrdReportTypeQuery();
    const {data: followUpType,  isLoading: loadingFollowUpType,} = useFetchReviewFollowUpReportTypeQuery();
    
    return {
        prdType,
        loadingPrdType,
        followUpType,
        loadingFollowUpType
    }

}

export default useReportTypes;
import { useState } from "react";
import { FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import { useFilterContext } from "../../hooks/FilterContext";
import React from "react";
import { Button } from "../../components";
import ApplicationDocumentsFilterModal from "./components/ApplicationDocumentsFilterModal";
import { FormInput } from "../../components/forms";
import ApplicationDocumentsProvider, { useApplicationDocuments } from "./hooks/ApplicationDocumentsContext";
import ApplicationDocumentsGrid from "./components/ApplicationDocumentsGrid";
import styled from "styled-components";
import { FileUploadModal } from "../../components/modals";
import toast from "react-hot-toast";

const ApplicationDocumentsLayoutGrid = styled(Main)`
    display: flex;
    flex-wrap: wrap;
    display: grid;
    min-height: 100%;
    grid-template-rows:     auto 1fr;
    grid-template-columns:  1fr;
    grid-template-areas: "grid-header"
                         "grid-data";
    row-gap: 0;
`;

const GridArea = styled.div`
    grid-area: ${props => props.gridArea};
`;

const ApplicationDocumentsHeader = () => {
    const [isFiltersShown, setIsFiltersShown] = useState();
    const [showFileUpload, setShowFileUpload] = useState(false);
    const { uploadApplicationDocuments, uploadResult } = useApplicationDocuments();
    const {
        filter,
        clearFilter,
        isFiltersApplied,
        setFilter
    } = useFilterContext();

    const { fileName } = filter || {};

    const handleDocumentUpload = (files) => toast.promise(uploadApplicationDocuments(files), {
        loading: "Uploading documents...",
        error: "Unable to upload documents.",
        success: "Documents uploaded."
    });

    return <div>
        <PageSubHeader className="py-3">
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    {isFiltersApplied && (
                        <React.Fragment>
                            <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                            <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                Warning - Filters are currently being applied to your results.
                                <br />
                                <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                            </FiltersAppliedWarningMessage>
                        </React.Fragment>
                    )}
                </div>
                <div className="d-flex flex-fill flex-row-reverse">
                    <Button className="ms-3" onClick={() => setIsFiltersShown(true)}>
                        Filter Documents
                    </Button>

                    <Button
                        variant="success"
                        className="d-flex align-items-center ms-3"
                        onClick={() => setShowFileUpload(true)}
                    >
                        Upload Document
                    </Button>
                    <FormInput
                        placeholder="Search files..."
                        value={fileName ?? ""}
                        onBlur={(value) => setFilter(prev => ({
                            ...prev,
                            fileName: value
                        }))}
                    />
                </div>
            </div>
        </PageSubHeader>
        <ApplicationDocumentsFilterModal
            show={isFiltersShown}
            onHide={() => setIsFiltersShown(null)}
        />
        <FileUploadModal
            show={showFileUpload}
            handleClose={() => setShowFileUpload(false)}
            title="Upload Document"
            handleConfirm={handleDocumentUpload}
            isUploadingFile={uploadResult?.isLoading}
        />
    </div>
};


const ApplicationDocuments = () => {

    return <ApplicationDocumentsProvider>
        <ApplicationDocumentsLayoutGrid>
            <GridArea gridArea="grid-header">
                <ApplicationDocumentsHeader />
            </GridArea>
            <GridArea gridArea="grid-data">
                <ApplicationDocumentsGrid />
            </GridArea>
        </ApplicationDocumentsLayoutGrid>
    </ApplicationDocumentsProvider>
};

export default ApplicationDocuments;
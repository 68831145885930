import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { MessagePriorities } from "../../clientmessages/hooks/useAllMessages";
import toast from "react-hot-toast";
import _ from "lodash";
import useOpenDocument from "../../../hooks/useOpenDocument";
import { Button } from "../../../components";
import { DateInput } from "../../../components/forms";

const PersonalMessagesVirtualTableRowActionColToggle = React.forwardRef(({ children, onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const OpenReportActionMenuItem = ({ reportDocumentId }) => {
    const { openDocument, isFetching: isOpening } = useOpenDocument();

    return (
        <Dropdown.Item disabled={isOpening} onClick={() => openDocument(reportDocumentId, 'Message Report')}>
            Open Report
        </Dropdown.Item>
    );
};

const ReadMessageActionMenuItem = ({ label, readOptions, showCheck, read, unread, unreadOptions }) => {
    const onHandleClick = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        if (!showCheck && (!read || typeof (read) !== 'function')) {
            return;
        }

        if (showCheck && (!unread || typeof (unread) !== 'function')) {
            return;
        }

        showCheck ? unread(unreadOptions) : read(readOptions);
    };

    return <Dropdown.Item className="d-flex justify-content-between align-items-center" onClick={onHandleClick}>
        {label}
        {showCheck && <FontAwesomeIcon
            icon="check"
            className="text-success ms-2"
        />}
    </Dropdown.Item>;
};

const UpdateReminderDateActionMenuItem = ({ userReminderDate, updateRecipient }) => {

    const [showModal, setShowModal] = useState(false);

    return <React.Fragment>
        <Dropdown.Item onClick={() => setShowModal(true)}>Set Reminder Date</Dropdown.Item>
        <Modal show={showModal} centered backdrop="static">
            <Modal.Header>
                <Modal.Title>Update Reminder Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row px-4">
                    <DateInput
                        value={userReminderDate}
                        isClearable
                        onBlur={(value) => updateRecipient("reminderDate", value)}
                        label="Remind On"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowModal(false)} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    </React.Fragment>
};

const UserPriorityMessageActionMenuItem = ({ isCurrent, label, priority, updateRecipient }) => {
    const onHandleClick = () => {
        if (!updateRecipient || typeof (updateRecipient) !== 'function') {
            return;
        }

        updateRecipient("recipientPriority", priority)
            .then(
                _ => toast.success("Priority updated."),
                error => toast.error(error?.data)
            );
    };

    return (
        <Dropdown.Item className="d-flex justify-content-between align-items-center" onClick={onHandleClick}>
            {label}
            {isCurrent && <FontAwesomeIcon
                icon="check"
                className="text-success ms-2"
            />}
        </Dropdown.Item>
    );
};

const ViewPersonalMessageThreadActionMenuItem = ({ threadId, setSelectedThreadId }) => {
    const onHandleClick = () => {
        if (!setSelectedThreadId || typeof (setSelectedThreadId) !== 'function') {
            return;
        }
        setSelectedThreadId(threadId);
    };

    return (
        <Dropdown.Item onClick={onHandleClick}>
            Open Conversation
        </Dropdown.Item>
    );
};

const PersonalMessagesVirtualTableRowActionCol = ({ data, isRecipient, readUserMessage, unreadUserMessage, setSelectedThreadId, updateRecipient }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    const {
        isActionRequired,
        isDone,
        messageId,
        threadId,
        userMessagePriority,
        userNoFurtherActionTime,
        userReadTime,
        reportDocumentId,
        userReminderDate
    } = data || {};

    return (
        <Dropdown id={menuId} className="caret-off w-100">
            <Dropdown.Toggle as={PersonalMessagesVirtualTableRowActionColToggle} id={toggleId} />
            <Dropdown.Menu>
                <ViewPersonalMessageThreadActionMenuItem
                    threadId={threadId}
                    setSelectedThreadId={setSelectedThreadId}
                />
                {reportDocumentId && <OpenReportActionMenuItem reportDocumentId={reportDocumentId} />}
                {isRecipient && <React.Fragment>
                    <Dropdown.Divider />
                    <Dropdown.Header>Actions</Dropdown.Header>
                    {isActionRequired && <ReadMessageActionMenuItem
                        showCheck={isDone === true}
                        read={readUserMessage}
                        unread={unreadUserMessage}
                        readOptions={{ isDone: true, noFurtherActionRequired: true }}
                        unreadOptions={{ undoRead: false, undoDone: true, undoNoFurtherActionRequired: false }}
                        label={"Task Complete, no further action required"}
                    />}
                    <ReadMessageActionMenuItem
                        showCheck={userNoFurtherActionTime}
                        read={readUserMessage}
                        unread={unreadUserMessage}
                        readOptions={{ isDone: false, noFurtherActionRequired: true }}
                        unreadOptions={{ undoRead: false, undoDone: true, undoNoFurtherActionRequired: true }}
                        label={"No further action required"}
                    />
                    <UpdateReminderDateActionMenuItem
                        userReminderDate={userReminderDate}
                        updateRecipient={updateRecipient}
                    />
                    <Dropdown.Divider />
                    <Dropdown.Header>Priority</Dropdown.Header>
                    <UserPriorityMessageActionMenuItem
                        label="Low"
                        priority={MessagePriorities.Low}
                        isCurrent={userMessagePriority === MessagePriorities.Low}
                        updateRecipient={updateRecipient}
                    />
                    <UserPriorityMessageActionMenuItem
                        label="Medium"
                        priority={MessagePriorities.Medium}
                        isCurrent={userMessagePriority === MessagePriorities.Medium}
                        updateRecipient={updateRecipient}
                    />
                    <UserPriorityMessageActionMenuItem
                        label="High"
                        priority={MessagePriorities.High}
                        isCurrent={userMessagePriority === MessagePriorities.High}
                        updateRecipient={updateRecipient}
                    />
                </React.Fragment>}
            </Dropdown.Menu>
        </Dropdown>
    );

};

export default PersonalMessagesVirtualTableRowActionCol;
import React from "react";
import Tippy from "@tippyjs/react";
import toast from "react-hot-toast";
import FavouriteSection from "./FavouriteSection";
import useFetchFavouriteClients from "../hooks/useFetchFavouriteClients";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon }  from "../../../components/utilities";
import { useNavigate } from "react-router-dom";

export const FavouriteClientsListItem = ({ account, onToggle, removeFromFavourites }) => {
    const appNavigator = useNavigate();
    const { accountName, accountReference, masterAccountId } = account || {};

    const onAccountOpenClicked = () => {
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }
        appNavigator(`/client/${masterAccountId}`)
    };

    const onAccountUnFavouriteClicked = () => {
        toast.promise(removeFromFavourites(masterAccountId), {
            loading: `Processing...`,
            success: () => `The client has been removed from your favourites.`,
            error: (err) => {
                const { message } = err || { message: 'There was an unkown error.' };
                return message;
            }
        });
    };

    return <div className="row">
        <div className="col-md-auto">
            <ActionIcon icon="fa-folder-open" onClick={onAccountOpenClicked} />
        </div>
        <div className="col-md-auto font-monospace">{accountReference}</div>
        <div className="col">{accountName}</div>
        <div className="col-md-auto">
            <Tippy placement="left" content={(
                <span>Remove from favourites</span>
            )}>
                <div>
                    <FontAwesomeIcon 
                        icon="fa-star-half-stroke"
                        className="text-gold has-pointer"
                        onClick={onAccountUnFavouriteClicked}
                    />
                </div>
            </Tippy>
        </div>
    </div>
};

const FavouriteClientsList = (props) => <FavouriteSection label="Favourite Clients" hook={useFetchFavouriteClients} RecordItem={FavouriteClientsListItem} {...props} />

export default FavouriteClientsList;
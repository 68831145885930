import React from "react";
import Moment from "react-moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChatBubbleRecipientsTooltip = ({ sender, recipients }) => {
    const ref = React.createRef();
    const { name, sentDate } = sender;

    return (
        <div ref={ref}>
            <OverlayTrigger placement="auto" container={ref} overlay={
                <Tooltip className="chat-header-recipients-inner">
                    <div>
                        <p>
                            <span>This message was sent by {name} </span>
                            <Moment fromNow utc>{sentDate}</Moment>
                            <span> to the following recipients:</span>
                        </p>
                        <ul className="list-disc my-1">
                            {recipients.map((el, i) => <li key={i} className="ml-5">{el.displayName}</li>)}
                        </ul>
                    </div>
                </Tooltip>
            }>
                <FontAwesomeIcon className="chat-header-recipients-icon" icon="fas fa-users" />
            </OverlayTrigger>
        </div>
    );
}

export default ChatBubbleRecipientsTooltip;
import React from "react";
import styled from "styled-components";
import { Spinner } from "../../../components/loaders";

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    & > p {
        margin: 1rem 0;
        color: ${props => props.theme.muted};
        font-size: 12px;
    }
`;

const NavigationLoader = () => {
    return <LoadingContainer>
        <Spinner />
        <p>Loading navigation...</p>
    </LoadingContainer>
};

export default NavigationLoader;
import React from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, DateInput, FormLabel, FormSelect, FormTextArea } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useClientAccounts from '../hooks/useClientAccounts';
import useGifts from "../hooks/useGifts";

const EstatePlanningGifts = ({ estatePlanningId, giftIds }) => {

    const {
        data,
        isLoading,
        isError,
        error,
        refetchGifts,
        createGift,
        patchGift,
        removeGift,
        updateGiftedByMappings,
    } = useGifts(estatePlanningId, giftIds);

    const { data: clientAccounts, isLoading: isLoadingClientAccounts } = useClientAccounts({ fetchType: 'list' });

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchGifts} />
        </ErrorMessageWrapper>;
    }

    return <div>
        <div className="d-flex row justify-content-between align-items-center">
            <FormLabel className="col-11">
                Capital Gifts from the Client's Estate
            </FormLabel>
            {data.length > 0 && <div className="d-flex d-lg-none col-1 justify-content-end">
                <IconButton
                    icon="plus"
                    variant="success"
                    onClick={createGift}
                />
            </div>}
        </div>
        {isLoading
            ? <Skeleton height={30} count={2} />
            : data.length === 0
            && <p className="mt-2">There are no recorded gifts within the past 7 years. Click <button className="anchor" onClick={createGift}>here</button> to create one</p>}
        <GridWrapper className="d-none d-lg-grid" gridTemplateColumns={"3fr 2fr 2fr 4fr auto"}>
            {data.length > 0 && <>
                <div className="col-start-1 d-flex align-items-end">
                    <FormLabel>
                        Gifted By
                    </FormLabel>
                </div>
                <div className="col-start-2 d-flex align-items-end">
                    <FormLabel>
                        Date of Gift
                    </FormLabel>
                </div>
                <div className="col-start-3 d-flex align-items-end">
                    <FormLabel>
                        Gift Value
                    </FormLabel>
                </div>
                <div className="col-start-4 d-flex align-items-end">
                    <FormLabel>
                        Description of Gift and Beneficiary
                    </FormLabel>
                </div>
                <div className="col-start-5 d-flex align-items-end">
                    <IconButton
                        icon="plus"
                        variant="success"
                        onClick={createGift}
                    />
                </div>
            </>}
            {data.map((gift, index) => <React.Fragment key={gift?.giftId ?? index}>
                <div className="col-start-1">
                    {isLoading || !gift?.giftId
                        ? <Skeleton height={30} />
                        : <FormSelect
                            isMulti
                            defaultValue={gift.giftedBy.map(x => x.clientAccountId)}
                            onBlur={(newValues) => updateGiftedByMappings(gift.giftId, newValues.map((x => x.value)))}
                            isLoadingOptions={isLoadingClientAccounts}
                            options={clientAccounts}
                        />}
                </div>
                <div className="col-start-2">
                    {isLoading || !gift?.giftId
                        ? <Skeleton height={30} />
                        : <DateInput
                            value={gift.dateOfGift}
                            isClearable
                            onBlur={(value) => patchGift(gift.giftId, "dateOfGift", value)}
                        />}
                </div>
                <div className="col-start-3">
                    {isLoading || !gift?.giftId
                        ? <Skeleton height={30} />
                        : <CurrencyInput
                            value={gift.value}
                            onBlur={(value) => patchGift(gift.giftId, "value", value)}
                            fixedDecimalScale={false}
                            decimalScale={false}
                        />}
                </div>
                <div className="col-start-4">
                    {isLoading || !gift?.giftId
                        ? <Skeleton height={30} />
                        : <FormTextArea
                            value={gift.description}
                            onBlur={(value) => patchGift(gift.giftId, "description", value)}
                        />}
                </div>
                <div className="col-start-5">
                    <IconButton
                        icon="trash-can"
                        variant="danger"
                        onClick={() => removeGift(gift.giftId)}
                        disabled={isLoading || !gift?.giftId}
                    />
                </div>
            </React.Fragment>)}
        </GridWrapper>
        <span className="d-lg-none">
            {data.map((gift, index) => <div key={gift.giftId ?? index}>
                <div className="row">
                    <div className="col-10">
                        {isLoading || !gift?.giftId
                            ? <Skeleton height={50} />
                            : <FormSelect
                                label={"Gifted By"}
                                isMulti
                                defaultValue={gift.giftedBy.map(x => x.clientAccountId)}
                                onBlur={(newValues) => updateGiftedByMappings(gift.giftId, newValues.map((x => x.value)))}
                                isLoadingOptions={isLoadingClientAccounts}
                                options={clientAccounts}
                            />}
                    </div>
                    <div className="col-2 d-flex align-items-end justify-content-end">
                        <IconButton
                            className="mb-1"
                            icon="trash-can"
                            variant="danger"
                            onClick={() => removeGift(gift.giftId)}
                            disabled={isLoading || !gift?.giftId}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        {isLoading || !gift?.giftId
                            ? <Skeleton height={50} />
                            : <DateInput
                                label="Date of Gift"
                                value={gift.dateOfGift}
                                isClearable
                                onBlur={(value) => patchGift(gift.giftId, "dateOfGift", value)}
                            />}
                    </div>
                    <div className="col-6">
                        {isLoading || !gift?.giftId
                            ? <Skeleton height={50} />
                            : <CurrencyInput
                                label="Gift Value"
                                value={gift.value}
                                onBlur={(value) => patchGift(gift.giftId, "value", value)}
                            />}
                    </div>
                </div>
                {isLoading || !gift?.giftId
                    ? <Skeleton height={50} />
                    : <FormTextArea
                        label="Description of Gift and Beneficiary"
                        value={gift.description}
                        onBlur={(value) => patchGift(gift.giftId, "description", value)}
                    />}
            </div>).flatMap(giftRow => [giftRow, <hr />])}
        </span>
    </div>
};

export default EstatePlanningGifts;
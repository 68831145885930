import { wealthProApi } from '../../redux/api';

const hoAdminFeeTransactionTypesApiEndpoints = wealthProApi
    .injectEndpoints({
        endpoints: (builder) => ({
            fetchFeeTransactionTypes: builder.query({
                query: () => `api/headofficeadmin/feetransactiontypes`
            })
        })
    });

export const {
    useFetchFeeTransactionTypesQuery
} = hoAdminFeeTransactionTypesApiEndpoints;
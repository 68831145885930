import React from "react";
import EmailTaxPackRecipientSelectOption, { EmailTaxPackRecipientSelectMultiValueLabel } from "./EmailTaxPackRecipientSelectOption";
import PaperTaxPackRecipientSelectionOption, { PaperTaxPackRecipientSelectMultiValueLabel } from "./PaperTaxPackRecipientSelectOption";
import ServiceAppointmentStylesSelect from "./ServiceAppointmentStylesSelect";
import ServiceAuthorisationTypesSelect from "./ServiceAuthorisationTypesSelect";
import ServiceSchedulingTypesSelect from "./ServiceSchedulingTypesSelect";
import { FormCheck, FormInput, PasswordInput } from "../../../components/forms";
import { GenericAdviserSelect, MasterAccountConnectionsSelect } from "../../../components/forms/selects";

const ServicePreferencesForm = ({ data, onFieldChanged }) => {
    if (!data) {
        return null;
    }

    const { masterAccountId } = data;
    return (
        <React.Fragment>
            <div className="row mb-2">
                <div className="col">
                    <GenericAdviserSelect
                        label="Adviser"
                        placeholder="Primary Adviser"
                        defaultValue={data.adviserId}
                        includeInactive={true}
                        displayOther={true}
                        onBlur={(values) => onFieldChanged('adviserId', values.value)}
                    />
                </div>
                <div className="col">
                    <GenericAdviserSelect
                        label="Review Adviser"
                        placeholder="Review Adviser"
                        defaultValue={data.reviewAdviserId}
                        includeInactive={true}
                        displayOther={true}
                        onBlur={(values) => onFieldChanged('reviewAdviserId', values.value)}
                    />
                </div>
                <div className="col">
                    <ServiceAuthorisationTypesSelect 
                        label="Authorisation"
                        defaultValue={data.authorisationType}
                        onBlur={(values) => onFieldChanged('authorisationType', values.value)}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <ServiceAppointmentStylesSelect 
                        label="Appointment Style"
                        defaultValue={data.appointmentStyle}
                        onBlur={(values) => onFieldChanged('appointmentStyle', values.value)}
                    />
                </div>
                <div className="col">
                    <ServiceSchedulingTypesSelect 
                        label="Scheduling"
                        defaultValue={data.appointmentSchedulingType}
                        onBlur={(values) => onFieldChanged('appointmentSchedulingType', values.value)}
                    />
                </div>
                <div className="col">
                    <PasswordInput 
                        label="Document Password"
                        value={data.defaultDocumentPassword}
                        placeholder="Default document password"
                        onBlur={(value) => onFieldChanged('defaultDocumentPassword', value)}
                    />
                </div>
            </div>
            <div className="row my-3">
                <div className="col">
                    <FormCheck
                        type="checkbox"
                        label="Paper Advice Reports"
                        isChecked={data.requiresPaperAdviceReports}
                        onChange={(_) => onFieldChanged('requiresPaperAdviceReports', !data.requiresPaperAdviceReports)}
                    />
                </div>
                <div className="col">
                    <FormCheck
                        type="checkbox"
                        label="Paper Custody Statements"
                        isChecked={data.requiresPaperCustodyStatements}
                        onChange={(_) => onFieldChanged('requiresPaperCustodyStatements', !data.requiresPaperCustodyStatements)}
                    />
                </div>
                <div className="col">
                    <FormCheck
                        type="checkbox"
                        label="Paper Cost Disclosures"
                        isChecked={data.requiresPaperCostDisclosures}
                        onChange={(_) => onFieldChanged('requiresPaperCostDisclosures', !data.requiresPaperCostDisclosures)}
                    />
                </div>
                <div className="col">
                    <FormCheck
                        type="checkbox"
                        label="Paper Tax Packs"
                        isChecked={data.requiresPaperTaxPacks}
                        onChange={(_) => onFieldChanged('requiresPaperTaxPacks', !data.requiresPaperTaxPacks)}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-2 d-flex align-items-center">
                    <FormCheck
                        type="checkbox"
                        label="Email Copy Tax Pack"
                        isChecked={data.isEmailCopyTaxPackEnabled}
                        onChange={(_) => onFieldChanged('isEmailCopyTaxPackEnabled', !data.isEmailCopyTaxPackEnabled)}
                    />
                </div>
                <div className="col">
                    <MasterAccountConnectionsSelect
                        masterAccountId={masterAccountId}
                        placeholder="Tax Pack Email Recipients"
                        isMulti={true}
                        components={{
                            Option: EmailTaxPackRecipientSelectOption,
                            MultiValueLabel: EmailTaxPackRecipientSelectMultiValueLabel
                        }}
                        defaultValue={data.emailTaxPackRecipients}
                        onBlur={(values) => onFieldChanged('emailTaxPackRecipients', values.map(el => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-2 d-flex align-items-center">
                    <FormCheck
                        type="checkbox"
                        label="Paper Copy Tax Pack"
                        isChecked={data.isSendPhysicalCopiesOfTaxPackEnabled}
                        onChange={(_) => onFieldChanged('isSendPhysicalCopiesOfTaxPackEnabled', !data.isSendPhysicalCopiesOfTaxPackEnabled)}
                    />
                </div>
                <div className="col">
                    <MasterAccountConnectionsSelect
                        masterAccountId={masterAccountId}
                        placeholder="Tax Pack Paper Recipients"
                        isMulti={true}
                        displayProperty="address"
                        components={{
                            Option: PaperTaxPackRecipientSelectionOption,
                            MultiValueLabel: PaperTaxPackRecipientSelectMultiValueLabel
                        }}
                        defaultValue={data.paperTaxPackRecipients}
                        onBlur={(values) => onFieldChanged('paperTaxPackRecipients', values.map(el => el.value))}
                    />
                </div>
            </div>
        </React.Fragment>
    )
};

export default ServicePreferencesForm;
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import styled from "styled-components";
import { IconButton } from "../../../components/buttons";
import { FormSelect } from "../../../components/forms";
import { BusinessSheetStatuses } from "../hooks/useAdviceGrid";
import UploadEvidenceModal from "./UploadEvidenceModal";

const StyledCell = styled.td`
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
    max-width: 0;
`;

const AdviceGridRow = ({ data: { data, statuses, updateStatus, isLoadingStatuses, isFetching, triggerAdministration }, index, style: { height, width } }) => {


    const {
        businessSheetId,
        qualifiedReference,
        masterAccountName,
        statusText,
        status,
        statusDate,
        adviserName,
        description,
        clientApprovalPdfPath
    } = data[index] || {};

    const isStatusAdminSelected = useRef(null);
    const [showUploadEvidenceModal, setShowUploadEvidenceModal] = useState(false);
    const [statusValue, setStatusValue] = useState(status);

    const handleStatusChange = (status) => {
        return new Promise((resolve, reject) => {
            if (status !== BusinessSheetStatuses.Administration) {
                return updateStatus(businessSheetId, status).then(resolve, reject);
            }

            setShowUploadEvidenceModal(true);
            setStatusValue(status);

            const modalCloseInterval = setInterval(() => {
                if (isStatusAdminSelected.current != null) {
                    clearInterval(modalCloseInterval);
                    if (isStatusAdminSelected.current === true) {
                        // console.log(evidenceFiles);
                        // return toast.promise(updateStatus(businessSheetId, status), {
                        //     loading: "Updating advice...",
                        //     error: "Error updating advice",
                        //     success: () => {
                        //         return "Advice updated";
                        //     }
                        // }).then(resolve, reject)
                        resolve();
                        // return updateStatus(businessSheetId, status).then(resolve, reject);
                    }
                    else {
                        return reject();
                    }
                }
            }, 100);
        }).finally(() => {
            isStatusAdminSelected.current = null;
        })
    };

    // const onStatusIconClick = (e) => {
    //     if (e && typeof e.preventDefault === "function") {
    //         e.preventDefault();
    //     }

    //     if (uploadEvidenceStatuses.includes(status)) {
    //         setSelectedRow(data[index]);
    //     }
    // };

    useEffect(() => {
        setStatusValue(status);
    }, [status]);

    if (isFetching || !data[index]) {
        return (
            <tr style={{ height, width }}>
                <td colSpan={7}>
                    <Skeleton height={32} />
                </td>
            </tr>
        );
    }

    return <React.Fragment>
        <tr style={{ height, width }}>
            <StyledCell align="center">
                <IconButton buttonSize="sm" icon={"fa-file-invoice"} onClick={() => alert("Still to implement...")} />
            </StyledCell>
            <StyledCell className="text-truncate">{qualifiedReference}</StyledCell>
            <StyledCell className="text-truncate">{masterAccountName}</StyledCell>
            <StyledCell>
                <FormSelect
                    defaultValue={statusValue}
                    isLoadingOptions={isLoadingStatuses}
                    options={statuses}
                    onBlur={({ value }) => handleStatusChange(value)}
                    className="form-control-sm m-0 p-0"
                    menuPortalTarget={document.body}
                />
            </StyledCell>
            <StyledCell className="text-truncate"><Moment date={statusDate} format="DD/MM/YYYY" /></StyledCell>
            <StyledCell className="text-truncate">{description}</StyledCell>
            <StyledCell className="text-truncate">{adviserName}</StyledCell>
        </tr>
        <UploadEvidenceModal
            data={data[index] || {}}
            show={showUploadEvidenceModal}
            onHide={() => {
                isStatusAdminSelected.current = false;
                setStatusValue(status);
                setShowUploadEvidenceModal(false);
            }}
            isAdminRef={isStatusAdminSelected}
            onConfirm={() => {
                isStatusAdminSelected.current = true;
                setShowUploadEvidenceModal(false);
            }}
            triggerAdministrationFn={triggerAdministration}
        />
    </React.Fragment>
};

export default AdviceGridRow;
import React from "react";
import { AccordionBody, AccordionHeader, AccordionList, AccordionListItem } from "../../../components/utilities";
import ConnectionListHeader from "./ConnectionListHeader";
import EditConnectionForm from "./EditConnectionForm";

const ConnectionList = ({
    activeKey = [],
    connections,
    defaultActiveKey = null,
    onActiveKeyChanged,
    onDelete,
    onUpdate
}) => {
    return (
        <AccordionList
            activeKey={activeKey}
            defaultActiveKey={defaultActiveKey}
            onSelect={onActiveKeyChanged}
            gap={1} 
            alwaysOpen
        >
            {connections.map((el) => (
                <AccordionListItem key={el.connectionId} eventKey={el.connectionId}>
                    <AccordionHeader>
                        <ConnectionListHeader connection={el} />
                    </AccordionHeader>
                    <AccordionBody>
                        <EditConnectionForm 
                            connection={el}
                            deleteConnection={onDelete}
                            updateConnection={onUpdate}
                        />
                    </AccordionBody>
                </AccordionListItem>
            ))}
        </AccordionList>
    );
};

export default ConnectionList;
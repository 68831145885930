import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";
import toast from "react-hot-toast";

const RecalculateTransactionsModal = ({ show, onClose, onSubmit, isRecalculating }) => {

    const handleSubmit = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        toast.promise(onSubmit(), {
            loading: "Recalculating transactions...",
            error: (err) => err?.data?.message ?? "Error recalculating transactions",
            success: "Transactions recalculated!"
        }).then(
            _ => onClose()
        );
    }

    return <Modal centered show={show} onHide={onClose}>

        <Modal.Header>Recalculate Transactions</Modal.Header>

        <Modal.Body>
            <ThemedSpan>Do you wish to recalculate the transactions and their fees to be in line with the current amount to invest?</ThemedSpan>
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={handleSubmit} variant="success" disabled={isRecalculating}>Confirm</Button>
            <Button onClick={onClose} variant="light">Close</Button>
        </Modal.Footer>
    </Modal>

};

export default RecalculateTransactionsModal;
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Button from "../../components/buttons";
import { Modal } from "react-bootstrap";
import { useFilterContext } from "../../hooks/FilterContext";
import {
    FormSelect,
    DateInput,
    FormInput,
} from "../../components/forms/";
import {
    GenericAdviserSelect,
} from "../../components/forms/selects";
import useDivisions from "./hooks/useDivisions";
import HeadOfficeApplicationStatusSelect from "../../components/forms/selects/HeadOfficeApplicationStatusSelect";

export const AdministrationDashboardFiltersModalButton = ({
    className,
    variant = "primary",
    ...rest
}) => {
    return (
        <div className={className}>
            <Button variant={variant} {...rest}>
                Filter Applications
            </Button>
        </div>
    );
};

export const AdministrationDashboardFiltersModal = ({
    handleClose,
    onFiltersChanged,
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);


    const onApplyFilters = () => {
        setFilter((original) => {
            return {
                ...original,
                ...tempFilters,
            };
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters((_) => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters((_) => filter);
        }
    }, [filter]);

    return (
        <Modal size="xl" centered backdrop="static" show={show}>
            <Modal.Header>Admin Dashboard Filters</Modal.Header>
            <Modal.Body {...rest}>
                <AdministrationDashhboardFilters
                    filter={tempFilters}
                    setFilter={onFilterValueChanged}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>
                    Clear Filters
                </Button>
                <Button variant="success" onClick={onApplyFilters}>
                    Apply Filters
                </Button>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const AdministrationDashhboardFilters = ({ filter, setFilter }) => {

    const { divisions, isLoadingDivisions } = useDivisions();

    const {
        reference,
        accountName,
        statuses,
        startDate,
        endDate,
        organisationId,
        divisionId,
        adviserIds,
        filterModel
    } = filter;

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col">
                    <FormInput
                        id="ref"
                        label="Ref"
                        value={reference ?? ""}
                        placeholder="Ref"
                        onBlur={(value) => setFilter("reference", value)}
                        disableAnimations
                    />
                </div>
                <div className="col">
                    <FormInput
                        id="accName"
                        label="Designation"
                        value={accountName ?? ""}
                        placeholder="Designation"
                        onBlur={(value) => setFilter("accountName", value)}
                        disableAnimations
                    />
                </div>
                <div className="col">
                    <HeadOfficeApplicationStatusSelect
                        label="Status"
                        // options={[
                        //     { label: "Await Cash", value: 10 },
                        //     { label: "Await Predecessor", value: 20 },
                        //     { label: "Instructed Sales", value: 30 },
                        //     { label: "Transfer Submitted", value: 40 },
                        //     { label: "Sale Uploaded", value: 50 },
                        // ]}
                        isMulti={true}
                        defaultValue={statuses}
                        onBlur={(values) =>
                            setFilter(
                                "statuses",
                                values.map((el) => el.value)
                            )
                        }
                        disableAnimations
                    />
                </div>
                <div className="col-2">
                    <DateInput
                        label="Status Date From"
                        placeholder="From"
                        isClearable
                        disableAnimations={true}
                        value={startDate}
                        onBlur={(value, _) =>
                            setFilter("startDate", value)
                        }
                    />
                </div>
                <div className="col-2">
                    <DateInput
                        label="To"
                        placeholder="To"
                        isClearable
                        disableAnimations={true}
                        value={endDate}
                        onBlur={(value, _) =>
                            setFilter("endDate", value)
                        }
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <FormSelect
                        label="Organisation"
                        options={[
                            { label: "Save and Invest", value: '67c9544b-cd44-47a7-9c9a-42c4fc9d90f1' }
                        ]}
                        isMulti={false}
                        defaultValue={organisationId}
                        onBlur={(values) =>
                            setFilter(
                                "organisationId",
                                values.map((el) => el.value)
                            )
                        }
                        disableAnimations
                    />
                </div>
                <div className="col">
                    <FormSelect
                        label="Division"
                        options={divisions}
                        isMulti={false}
                        defaultValue={divisionId}
                        isLoadingOptions={isLoadingDivisions}
                        onBlur={(values) =>
                            setFilter(
                                "divisionId",
                                values.value
                            )
                        }
                        disableAnimations
                    />
                </div>
                <div className="col">
                    <GenericAdviserSelect
                        label="Adviser"
                        isMulti={true}
                        defaultValue={adviserIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "adviserIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default AdministrationDashhboardFilters;

import React, { useState } from "react";
import withProviders from "../../hooks/withProviders";
import styled from "styled-components";
import { Button } from "../../components";
import PageHeader from "../../components/layout/PageHeader";
import { PageSubHeader, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import { useFilterContext } from "../../hooks/FilterContext";
import { ProjectManagementFiltersModal } from "../projectmanagementfilters";
import { ProjectManagementGrid } from "./components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterProvider, ProjectManagementDefaultFilters } from "../../hooks/FilterContext";

const ProjectManagementHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const ProjectManagementPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 0.75rem;
`;

const ProjectManagementFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const PageHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

const ProjectManagement = () => {
    const [feePotential, setFeePotential] = useState(null);

    const [isFiltersShown, setIsFiltersShown] = useState(false);

    const {
        clearFilter,
        isFiltersApplied,
    } = useFilterContext();

    return <>
        <ProjectManagementFiltersModal
            handleClose={() => setIsFiltersShown(false)}
            show={isFiltersShown}
        />
        <PageHeader>
            <PageHeaderTop>
                <ProjectManagementHeaderTitle>
                    <span>Projects Management</span>
                </ProjectManagementHeaderTitle>
            </PageHeaderTop>
        </PageHeader>
        <ProjectManagementPageSubHeader>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    {isFiltersApplied && <>
                        <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                        <ProjectManagementFiltersAppliedWarningMessage className="ms-2 text-muted">
                            Warning - Filters are currently being applied to your results.
                            <br />
                            <button className="anchor" onClick={clearFilter}>Click here</button> to clear your filters.
                        </ProjectManagementFiltersAppliedWarningMessage>
                    </>}
                </div>
                <div className="d-flex flex-fill justify-content-end align-items-center flex-row">
                    {feePotential != null
                        ? <b className="me-3">Fee Potential: {feePotential}</b>
                        : <FontAwesomeIcon className="me-4" icon="spinner" spinPulse size="lg" />}
                    <Button variant="primary" onClick={() => setIsFiltersShown(true)}>
                        Filter Projects
                    </Button>
                </div>
            </div>
        </ProjectManagementPageSubHeader>
        <ProjectManagementGrid setFeePotential={setFeePotential} />
    </>;
};


export default withProviders([FilterProvider, { filterStore: "projects", defaultFilters: ProjectManagementDefaultFilters }])(ProjectManagement);

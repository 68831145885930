import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledNavLink = styled(NavLink)``;

const StyledNavLinkParent = styled.div``;

const ProfileLink = ({ to, onClick, onSelected, icon, tooltipText, ...rest }) => {

    const handleActionIcon = () => {
        if (onClick && typeof (onClick) === 'function') {
            onClick();
        }
        if (onSelected && typeof (onSelected) === 'function') {
            onSelected();
        }
    };

    if (!to) {
        if (tooltipText) {
            return <OverlayTrigger placement="top" overlay={
                <Tooltip>
                    {tooltipText}
                </Tooltip>
            }>
                <StyledNavLinkParent>
                    <span onClick={handleActionIcon} {...rest}>
                        <FontAwesomeIcon icon={icon} />
                    </span>
                </StyledNavLinkParent>
            </OverlayTrigger>
        }

        return <StyledNavLinkParent>
            <span onClick={handleActionIcon} {...rest}>
                <FontAwesomeIcon icon={icon} />
            </span>
        </StyledNavLinkParent>
    }

    if (tooltipText) {
        return <OverlayTrigger placement="top" overlay={
            <Tooltip>
                {tooltipText}
            </Tooltip>
        }>
            <StyledNavLinkParent>
                <StyledNavLink to={to} onClick={onSelected} {...rest}>
                    <FontAwesomeIcon icon={icon} />
                </StyledNavLink>
            </StyledNavLinkParent>
        </OverlayTrigger>
    }

    return <StyledNavLinkParent>
        <StyledNavLink to={to} onClick={onSelected} {...rest}>
            <FontAwesomeIcon icon={icon} />
        </StyledNavLink>
    </StyledNavLinkParent>
};

export default ProfileLink;
import { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import IconButton from "../../../components/buttons/IconButton";
import { CurrencyInput, FormInput, FormSelect, NumberInput, PercentageInput } from "../../../components/forms";
import { patchReplace } from "../../../helpers/patchDoc";
import SedolSearch from "./SedolSearch";

const DivestTransaction = ({
    isSelling,
    transaction,
    isLoading,
    realTimeTransactions,
    createTransaction,
    patchTransaction,
    bulkPatchTransaction,
    realTimePatchTransaction,
    realTimeBulkPatchTransaction,
    deleteTransaction,
    index,
    transactionTypeSelectObjects,
    transactionTypes,
    transactionTypesIsReady,
    transactionTypesIsLoading
}) => {
    const realTimeTransaction = useMemo(() => realTimeTransactions?.[index], [index, realTimeTransactions]);

    const patchRow = useCallback((property, value) =>
        patchTransaction(property, value, transaction.rowTag), [patchTransaction, transaction.rowTag]);

    const patchCache = useCallback((property, value) => {
        realTimePatchTransaction(property, value, index)
    }, [index, realTimePatchTransaction]);

    const calculateNewSalePercent = useCallback((saleValue, currentValue) =>
        (saleValue / currentValue) * 100, []);

    const calculateNewSaleValue = useCallback((salePercent, currentValue) =>
        (salePercent / 100) * currentValue, []);

    const rhsIsReadOnly = useMemo(() => isSelling ?
        (realTimeTransaction?.currentValue ?? 0) === 0 :
        (realTimeTransaction?.saleValue ?? 0) === 0,
        [isSelling, realTimeTransaction?.currentValue, realTimeTransaction?.saleValue]);

    const realTimeAllowPercentInput = useMemo(() =>
        isSelling && transactionTypes?.find(type => type.id === realTimeTransaction?.transactionTypeId)?.allowPercentInput,
        [isSelling, realTimeTransaction?.transactionTypeId, transactionTypes]);

    // Needed to allow input to change beyond currentValue, without effecting other calculations
    const [displaySaleValue, setDisplaySaleValue] = useState(realTimeTransaction?.saleValue);

    useEffect(() => {
        setDisplaySaleValue(transaction?.saleValue)
    }, [transaction?.saleValue]);

    useEffect(() => {
        if (realTimeTransaction?.saleValue == null)
            return;
        setDisplaySaleValue(oldDisplayValue => oldDisplayValue == null
            ? realTimeTransaction?.saleValue
            : oldDisplayValue);
    }, [realTimeTransaction?.saleValue]);

    useEffect(() => {
        if (!isSelling)
            return;
        if (realTimeAllowPercentInput) {
            const validNewPercent = Math.min(realTimeTransaction?.salePercent ?? 0, 100);
            const newSaleValue = calculateNewSaleValue(validNewPercent, realTimeTransaction?.currentValue ?? 0);
            setDisplaySaleValue(newSaleValue);
            patchCache("saleValue", newSaleValue);
        }
    }, [calculateNewSaleValue, isSelling, patchCache, realTimeAllowPercentInput, realTimeTransaction?.currentValue, realTimeTransaction?.salePercent]);

    useEffect(() => {
        if (!isSelling || realTimeTransaction?.currentValue == null) return;
        if (!realTimeAllowPercentInput) {
            const validNewValue = Math.min(displaySaleValue ?? 0, realTimeTransaction?.currentValue ?? 0);
            const newSalePercent = calculateNewSalePercent(validNewValue, realTimeTransaction?.currentValue ?? 0);
            realTimeBulkPatchTransaction([
                patchReplace("salePercent", newSalePercent),
                patchReplace("saleValue", validNewValue)
            ], index);
        }
    }, [calculateNewSalePercent, displaySaleValue, index, isSelling, realTimeAllowPercentInput, realTimeBulkPatchTransaction, realTimeTransaction?.currentValue]);

    const realTimeAllowRetainInput = useMemo(() =>
        transactionTypes?.find(type => type.id === realTimeTransaction?.transactionTypeId)?.allowPercentInput,
        [realTimeTransaction?.transactionTypeId, transactionTypes]);

    const cachedAllowRetainInput = useMemo(() =>
        transactionTypes?.find(type => type.id === transaction?.transactionTypeId)?.allowPercentInput,
        [transaction?.transactionTypeId, transactionTypes]);

    useEffect(() => {
        if (transaction?.rowTag != null && !realTimeAllowRetainInput && (realTimeTransaction?.retainPercent ?? 0) !== 0)
            patchCache("retainPercent", 0);
    }, [patchCache, realTimeAllowRetainInput, realTimeTransaction?.retainPercent, transaction?.rowTag]);

    useEffect(() => {
        if (transaction?.rowTag != null && !cachedAllowRetainInput && (transaction?.retainPercent ?? 0) !== 0)
            patchRow("retainPercent", 0);
    }, [cachedAllowRetainInput, patchRow, transaction?.retainPercent, transaction?.rowTag]);

    const currentValueInput = useMemo(() => isLoading
        ? <Skeleton />
        : <CurrencyInput
            className="col-start-3"
            value={transaction?.currentValue}
            onChange={(_, { floatValue }) => patchCache("currentValue", floatValue)}
            onBlur={(newCurrentValue) => {
                let operations = [patchReplace("currentValue", newCurrentValue)];
                if (realTimeAllowPercentInput) {
                    // Keep salePercent the same and change saleValue
                    const newSaleValue = calculateNewSaleValue(realTimeTransaction?.salePercent ?? 0, newCurrentValue ?? 0);
                    setDisplaySaleValue(newSaleValue);
                    operations.push(patchReplace("saleValue", newSaleValue));
                } else {
                    // Keep saleValue the same (or change to newCurrentValue, when currentValue < saleValue) and change salePercent
                    const newSalePercent = calculateNewSalePercent(realTimeTransaction?.saleValue ?? 0, newCurrentValue ?? 0);
                    operations.push(patchReplace("salePercent", newSalePercent));

                    if (newCurrentValue < displaySaleValue) {
                        setDisplaySaleValue(newCurrentValue);
                        operations.push(patchReplace("saleValue", newCurrentValue));
                    }
                }

                return bulkPatchTransaction(operations, transaction?.rowTag);
            }}
            allowNegative={false}
        />,
        [bulkPatchTransaction, calculateNewSalePercent, calculateNewSaleValue, displaySaleValue, isLoading, patchCache, realTimeAllowPercentInput, realTimeTransaction?.salePercent, realTimeTransaction?.saleValue, transaction?.currentValue, transaction?.rowTag]);

    const quantityInput = useMemo(() => isLoading
        ? <Skeleton />
        : <span className={`col-start-${isSelling ? 4 : 5}`}>
            <NumberInput
                value={transaction?.quantity ?? 0}
                onBlur={(currentValue) => patchRow("quantity", currentValue)}
                thousandSeparator
                decimalScale={5}
                disabled={rhsIsReadOnly}
            />
        </span>,
        [isLoading, isSelling, patchRow, rhsIsReadOnly, transaction?.quantity]);

    const instructionSelect = useMemo(() => (isLoading || (transactionTypesIsLoading && transaction?.transactionTypeId != null))
        ? <Skeleton />
        : <FormSelect
            className={`col-start-${isSelling ? 5 : 6}`}
            defaultValue={transaction?.transactionTypeId}
            options={transactionTypeSelectObjects ?? []}
            isLoadingOptions={transactionTypesIsLoading}
            onChange={(currentSelection) => patchCache("transactionTypeId", currentSelection.value)}
            onBlur={(currentSelection) => patchRow("transactionTypeId", currentSelection.value)}
            isDisabled={rhsIsReadOnly}
        />,
        [isLoading, isSelling, patchCache, patchRow, rhsIsReadOnly, transaction?.transactionTypeId, transactionTypeSelectObjects, transactionTypesIsLoading]);

    const salePercentInput = useMemo(() => (isLoading || !transactionTypesIsReady)
        ? <Skeleton />
        : (transaction?.sedol?.toLowerCase() === "cash")
            ? <FormInput
                disabled
                value="N/A"
            />
            : <PercentageInput
                className="col-start-6"
                value={realTimeTransaction?.salePercent}
                onChange={(_, { floatValue }) => {
                    patchCache("salePercent", floatValue);
                    const newSaleValue = calculateNewSaleValue(Math.min(floatValue ?? 0, 100), realTimeTransaction?.currentValue ?? 0);
                    setDisplaySaleValue(newSaleValue);
                }}
                disableAnimations={transaction?.salePercent === 100 && realTimeTransaction?.salePercent >= 100}
                onBlur={(currentValue) => {
                    let operations = [];
                    // Need to update the service with the new sale percent and corresponding value
                    // After minimizing the sale percent to 100, if necessary
                    const validNewPercent = Math.min(currentValue ?? 0, 100);
                    if (validNewPercent !== transaction?.salePercent)
                        operations.push(patchReplace("salePercent", validNewPercent));

                    const newSaleValue = calculateNewSaleValue(validNewPercent, transaction?.currentValue ?? 0);
                    if (newSaleValue !== transaction?.saleValue) {
                        setDisplaySaleValue(newSaleValue);
                        operations.push(patchReplace("saleValue", newSaleValue));
                    }

                    patchCache("salePercent", validNewPercent);

                    if (operations.length > 0)
                        return bulkPatchTransaction(operations, transaction?.rowTag);
                }}
                readOnly={rhsIsReadOnly || realTimeAllowPercentInput == null || !realTimeAllowPercentInput}
            />,
        [bulkPatchTransaction, calculateNewSaleValue, isLoading, patchCache, realTimeAllowPercentInput, realTimeTransaction?.currentValue, realTimeTransaction?.salePercent, rhsIsReadOnly, transaction?.currentValue, transaction?.rowTag, transaction?.salePercent, transaction?.saleValue, transaction?.sedol, transactionTypesIsReady]);

    const saleValueOnChange = useCallback((_, { floatValue }) => {
        setDisplaySaleValue(floatValue);

        if (isSelling) {
            const newSalePercent = calculateNewSalePercent(Math.min(floatValue ?? 0, realTimeTransaction?.currentValue ?? 0), realTimeTransaction?.currentValue ?? 0);
            patchCache("salePercent", newSalePercent);
        } else {
            patchCache("saleValue", floatValue);
        }
    }, [calculateNewSalePercent, isSelling, patchCache, realTimeTransaction?.currentValue]);

    const saleValueOnBlur = useCallback((currentValue) => {
        if (isSelling) {
            let operations = [];
            // Need to update the service with the new sale value and corresponding percent
            // After minimizing the sale value to the current value, if necessary
            const validNewValue = Math.min(currentValue ?? 0, realTimeTransaction?.currentValue ?? 0);
            setDisplaySaleValue(validNewValue);
            if (validNewValue !== transaction?.saleValue)
                operations.push(patchReplace("saleValue", validNewValue));

            const newSalePercent = calculateNewSalePercent(validNewValue, realTimeTransaction?.currentValue ?? 0);
            if (newSalePercent !== transaction?.salePercent)
                operations.push(patchReplace("salePercent", newSalePercent));

            if (operations.length > 0)
                return bulkPatchTransaction(operations, transaction?.rowTag);

            // Need this to not update originalValue when no operations are performed
            return Promise.reject(null);
        } else {
            return patchRow("saleValue", currentValue);
        }

    }, [bulkPatchTransaction, calculateNewSalePercent, isSelling, patchRow, realTimeTransaction?.currentValue, transaction?.rowTag, transaction?.salePercent, transaction?.saleValue]);

    const saleValueInput = useMemo(() => (isLoading || !transactionTypesIsReady)
        ? <Skeleton />
        : <CurrencyInput
            className={`col-start-${isSelling ? 7 : 3}`}
            value={displaySaleValue}
            onChange={saleValueOnChange}
            onBlur={saleValueOnBlur}
            disableAnimations={realTimeTransaction?.currentValue != null && (transaction?.saleValue === transaction?.currentValue && displaySaleValue >= realTimeTransaction?.currentValue)}
            readOnly={isSelling && (rhsIsReadOnly || realTimeAllowPercentInput == null || realTimeAllowPercentInput)}
            allowNegative={false}
        />,
        [displaySaleValue, isLoading, isSelling, realTimeAllowPercentInput, realTimeTransaction?.currentValue, rhsIsReadOnly, saleValueOnBlur, saleValueOnChange, transaction?.currentValue, transaction?.saleValue, transactionTypesIsReady]);

    const bookCostInput = useMemo(() => isLoading
        ? <Skeleton />
        : <CurrencyInput
            className="col-start-4"
            value={transaction?.bookCost}
            onChange={(_, { floatValue }) => patchCache("bookCost", floatValue)}
            onBlur={(currentValue) => patchRow("bookCost", currentValue ?? 0)}
            allowNegative={false}
        />,
        [isLoading, patchCache, patchRow, transaction?.bookCost]);

    const retainPercentInput = useMemo(() => isLoading
        ? <Skeleton />
        : <PercentageInput
            className="col-start-7"
            value={realTimeAllowRetainInput ? transaction?.retainPercent : 0}
            onChange={(_, { floatValue }) => {
                const newValue = Math.min(floatValue, 100);
                patchCache("retainPercent", newValue)
            }}
            onBlur={(currentValue) => {
                const newValue = Math.min(currentValue, 100);
                return patchRow("retainPercent", newValue);
            }}
            readOnly={rhsIsReadOnly || !realTimeAllowRetainInput}
        />,
        [isLoading, realTimeAllowRetainInput, transaction?.retainPercent, rhsIsReadOnly, patchCache, patchRow]);

    return <>
        {/* Table columns shared by Transfer and Sell instructions */}
        <SedolSearch
            realTimeSedol={realTimeTransaction?.sedol ?? ""}
            sedol={transaction?.sedol ?? ""}
            realTimeAssetName={realTimeTransaction?.assetName ?? ""}
            patchRealTime={(operations) => realTimeBulkPatchTransaction(operations, index)}
            patchService={(operations) => bulkPatchTransaction(operations, transaction?.rowTag)}
            isLoading={isLoading}
            fillHoldingInfo
            isSelling={isSelling}
        />

        {/* Table columns unique to Sell/Transfer instructions */}
        {isSelling ? <>
            {currentValueInput}
            {quantityInput}
            {instructionSelect}
            {salePercentInput}
            {saleValueInput}
        </> : <>
            {saleValueInput}
            {bookCostInput}
            {quantityInput}
            {instructionSelect}
            {retainPercentInput}
        </>}

        {index === 0
            ? <IconButton
                icon={"circle-plus"}
                className={"col-start-8 justify-self-center"}
                variant="success"
                onClick={() => createTransaction()}
            />
            : <IconButton
                icon={"fa-trash"}
                className={"col-start-8 justify-self-center"}
                variant="danger"
                onClick={deleteTransaction}
            />}
    </>
}

export default DivestTransaction
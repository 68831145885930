import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { ActionIcon, ThemedSpan } from "../../../components/utilities";
import { useNavigate } from "react-router-dom";

const NotificationsSection = ({ allowRetry = true, totalPagesToDisplay, queryType, hook, label, RecordItem, recordItemProps = {}, openPageOnMaxPagesReached }) => {
    const appNavigator = useNavigate();

    if (!hook) {
        return;
    }

    const { results, isError, isLoading, reload, totalCount, totalPages, page, loadNextPage, read } = hook(queryType);


    const handleOpenFavouritesPage = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        appNavigator("/personal/notifications");
    }

    return (
        <div className="my-2">
            <div className="row mb-1">
                <div className="col">
                    <p className="mb-0">{label} {totalCount > 0 && !isLoading && (<ThemedSpan variant="muted">({totalCount})</ThemedSpan>)}</p>
                </div>
                <div className="col-md-auto">
                    <ActionIcon icon="fa-rotate" variant="muted" onClick={reload} />
                </div>
            </div>
            {isLoading === true && (
                <div className="px-2">
                    <Skeleton count={3} />
                </div>
            )}
            {isLoading === false && isError === true && (
                <p className="px-2 text-danger">
                    <span>Oops! There was a problem getting the data.</span>
                    {allowRetry && allowRetry === true && (
                        <React.Fragment>
                            <br />
                            <a href="#" onClick={reload}>Click here to retry.</a>
                        </React.Fragment>
                    )}
                </p>
            )}
            {isLoading === false && isError === false && results.length === 0 && (
                <p>
                    <span>No {label.toLowerCase()} found.</span>
                    <br />
                    <ThemedSpan variant="muted">We will notify you when any messages come through.</ThemedSpan>
                </p>
            )}
            {isLoading === false && isError === false && results.length > 0 && (
                <div>
                    {results.map((item) => <RecordItem key={item.messageId} notification={item} readFn={read} {...recordItemProps} />)}
                    {totalPages > 1 && page < totalPages && (page < totalPagesToDisplay || openPageOnMaxPagesReached === false) && (
                        <div className="d-flex justify-content-center align-items-end mt-2">
                            <a href="#" onClick={loadNextPage}>Load more...</a>
                        </div>
                    )}
                    {totalPages > 1 && page >= totalPagesToDisplay && openPageOnMaxPagesReached === true && totalPages !== page && (
                        <div className="d-flex flex-column align-items-center mt-2">
                            <span>Maximum page limit reached.</span>
                            <br />
                            <a href="#" onClick={handleOpenFavouritesPage}>Click here to view more.</a>
                        </div>
                    )}
                </div>)}

        </div>
    );
};

export default NotificationsSection;

// NotificationsSection.propTypes = {
//     /**
//      * The title of the notification section. Can be a string or an element.
//      */
//     title: PropTypes.any,
//     /**
//      * The fetch function to fetch data called on render
//      */
//     fetchFn: PropTypes.func.isRequired,
//     /**
//      * The query parameters used for the fetch function
//      */
//     queryParams: PropTypes.object,
//     /**
//      * The column values to display for the grid layout
//      */
//     columnValues: PropTypes.array.isRequired,
//     /**
//      * Whether a folder icon that navigates to the clients details should render
//      */
//     hasNavigateIcon: PropTypes.bool,
//     /**
//      * The onClick function for the navigate icon
//      */
//     navigateFn: PropTypes.func,
//     /**
//      * The max height for the infinite scroll
//      */
//     scrollHeight: PropTypes.any
// };
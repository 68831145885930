import React from "react";
import _ from "lodash";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";import styled from "styled-components";
import SlaAdminCheckTableRowColumn from "./SlaAdminCheckTableRowColumn";
import Moment from "react-moment";
import SlaAdminTableRowActionColumn from "./SlaAdminTableRowActionColumn";

const TableCell = styled.td`
    width: ${props => props.width}%;
    max-width: 50px;
    vertical-align: middle;
`;

const SlaAdminTableRow = ({ data, index, style:  {height, width } }) => {

    if (index >= data.length) {
        return <TableLoadingRow />;
    }

    const {
        serviceName,
        reviewPeriodMonths,
        reviewPeriodStart,
        reviewPeriodEnd,
        isTransactionFeesApplied,
        isVerificationRequired,
        daysToVerification,
        isPreReviewDocumentRequired,
        daysToPreReviewDocument,
        isAppointmentRequired,
        daysToSchedule,
        isPostMeetingRequired,
        daysToPostMeeting,
        isFollowUpRequired,
        daysToFollowUpSent,
        incomeDrawdownOption,
        daysToDrawdown,
        adviserUpdatesOption
    } = data[index];

    return (
        <tr>
            <TableCell className="text-truncate" width={15}>
                {serviceName}
            </TableCell>
            <TableCell className="text-truncate text-center" width={5}>
                {reviewPeriodMonths}
            </TableCell>
            <TableCell className="text-truncate" width={5}>
                {reviewPeriodStart ? (
                    <Moment format="DD/MM/YYYY">{reviewPeriodStart}</Moment>
                ) : (
                    <span>-</span>
                )}
            </TableCell>
            <TableCell className="text-truncate" width={5}>
                {reviewPeriodEnd ? (
                    <Moment format="DD/MM/YYYY">{reviewPeriodEnd}</Moment>
                ) : (
                    <span>-</span>
                )}
            </TableCell>
            <SlaAdminCheckTableRowColumn data={isTransactionFeesApplied} width={5} />
            <SlaAdminCheckTableRowColumn data={isVerificationRequired} width={5} />
            <TableCell className="text-truncate text-center" width={5}>
                {daysToVerification}
            </TableCell>
            <SlaAdminCheckTableRowColumn data={isPreReviewDocumentRequired} width={5} />
            <TableCell className="text-truncate text-center" width={5}>
                {daysToPreReviewDocument}
            </TableCell>
            <SlaAdminCheckTableRowColumn data={isAppointmentRequired} width={5} />
            <TableCell className="text-truncate text-center" width={5}>
                {daysToSchedule}
            </TableCell>
            <SlaAdminCheckTableRowColumn data={isPostMeetingRequired} width={5} />
            <TableCell className="text-truncate text-center" width={5}>
                {daysToPostMeeting}
            </TableCell>
            <SlaAdminCheckTableRowColumn data={isFollowUpRequired} width={5} />
            <TableCell className="text-truncate text-center" width={5}>
                {daysToFollowUpSent}
            </TableCell>
            <SlaAdminCheckTableRowColumn data={incomeDrawdownOption} width={5} />
            <TableCell className="text-truncate text-center" width={5}>
                {daysToDrawdown}
            </TableCell>
            <SlaAdminCheckTableRowColumn data={adviserUpdatesOption} width={5} />
            <SlaAdminTableRowActionColumn serviceLevelAgreement={data[index]}/>
        </tr>
    );
}

export default SlaAdminTableRow;
import React from 'react';
import useServiceLevelAgreements from './hooks/useServiceLevelAgreements';
import { VirtualTable } from "../../components/tables";
import styled from "styled-components";
import { StandardTableSortablePropertyHeader, ThemedTableHeader } from "../../components/tables/StandardTable";
import SlaAdminTableRow from './components/SlaAdminTableRow';
import { EditSlaProvider, useEditSla } from './hooks/EditSlaContext';
import PageHeader, { PageHeaderTop, PageHeaderBottom } from "../../components/layout/PageHeader";
import { Outlet } from "react-router-dom";
import { ClientReviewManagementPageSubHeader, Main, PageSubHeader, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import ServiceLevelAgreementsAdminTable from './components/ServiceLevelAgreementsAdminTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'react-bootstrap';
import CreateServiceLevelAgreementModal from './components/CreateServiceLevelAgreementModal';

const HeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const ServiceLevelAgreementsAdmin = () => {

    const [showCreateModal, setShowCreateModal] = React.useState(false);

    return <React.Fragment>
            <CreateServiceLevelAgreementModal 
                show={showCreateModal}
                handleClose={() => setShowCreateModal(false)}
            />

            <PageHeader>
                <PageHeaderTop>
                    <HeaderTitle>
                        <span>Service Level Agreements Management</span>
                    </HeaderTitle>
                </PageHeaderTop>
            </PageHeader>
            <Main className="d-flex flex-column">
                <PageSubHeader className="p-2">
                    <div className="d-flex align-items-center flex-fill flex-row-reverse">
                        <Button variant="success" className={"me-4"}  onClick={() => setShowCreateModal(true)} > 
                            <FontAwesomeIcon className="me-1" icon={"fa-plus"} />
                            <span>Create SLA</span>
                        </Button>
                    </div>
                </PageSubHeader>
                <section className="px-4 my-3 h-100">
                    <EditSlaProvider>
                        <ServiceLevelAgreementsAdminTable />
                    </EditSlaProvider>
                </section>
            </Main>
        </React.Fragment>
}

export default ServiceLevelAgreementsAdmin;
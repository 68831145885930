import { useEffect } from "react";
import { useLazyGetThreadByIdQuery, usePatchThreadMutation } from "../../../services/messages";
import { useClientContext } from "../../../hooks/ClientContext";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientMessaging } from "./ClientMessagingContext";

const useThread = () => {
    const { id: masterAccountId } = useClientContext();
    const { filters, threadId } = useClientMessaging();
    const [get, { data, isFetching, isError, error }] = useLazyGetThreadByIdQuery();
    const [patchThreadTrigger] = usePatchThreadMutation();

    const getThreadById = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        threadId && get({ threadId });
    };

    const patchThread = (property, newValue) => {
        return new Promise((resolve, reject) => {
            var operations = [patchReplace(property, newValue)];
            return patchThreadTrigger({
                operations,
                masterAccountId,
                threadId,
                ...filters
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    useEffect(getThreadById, [threadId]);

    return {
        patchThread,
        retry: getThreadById,
        data,
        error,
        isError,
        isFetching,
    };
};

export default useThread;
import { useFetchTransactionsInvestmentOperationsQuery } from "../../../services/valuations";

const useInvestmentOperations = () => {

    const { 
        data,
        isLoading,
        isFetching,
        isError,
        error
    } = useFetchTransactionsInvestmentOperationsQuery('select');


    return {
        investmentOperations : data || [],
        isLoading,
        isFetching,
        isError,
        error
    };
};

export default useInvestmentOperations;
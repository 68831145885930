import { useEffect, useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { Spinner } from "../../../components/loaders";
import ErrorMessage from "../../../components/messages/ErrorMessage";
import ErrorMessageWrapper from "../../../components/messages/ErrorMessageWrapper";
import { CenterArea } from "../../../components/utilities";
import useFundAnalysisGrid from "../hooks/useFundAnalysisGrid";
import FundAnalysisRow from "./FundAnalysisRow";

const FundAnalysisGrid = ({
    className = "",
    isVolatility = false,
    volatilityProfile = null,
    groupBySector = false,
    currentSort,
    sort
}) => {
    const {
        columnDefs,
        isGroupedByVolatility,
        assetGroups,
        assetGroupsIsLoading,
        assetGroupsIsFetching,
        assetGroupsIsUninitialised,
        assetGroupsIsError,
        assetGroupsError,
        refetch
    } = useFundAnalysisGrid({ isVolatility, volatilityProfile, isGroupedBySector: groupBySector, currentSort });

    const columnWidth = useMemo(() => {
        return 140 / columnDefs?.length;
    }, [columnDefs?.length]);

    const currentLength = useRef(Math.max(assetGroups?.length ?? 0, 3));

    useEffect(() => {
        currentLength.current = Math.max(assetGroups?.length ?? currentLength.current ?? 0, 3);
    }, [assetGroups?.length]);

    if (assetGroupsIsLoading || assetGroupsIsUninitialised) {
        return <CenterArea>
            <Spinner />
        </CenterArea>
    }

    if (assetGroupsIsError) {
        return <CenterArea>
            <ErrorMessageWrapper>
                <ErrorMessage
                    error={assetGroupsError}
                    retryCallback={refetch}
                />
            </ErrorMessageWrapper>
        </CenterArea>
    }

    if (assetGroups?.length === 0 && !assetGroupsIsFetching) {
        if (isGroupedByVolatility)
            return <p>There are no funds matching your filters for this volatility profile</p>

        return <CenterArea>
            <p>There are no funds matching your filters in this Investment Committee</p>
        </CenterArea>
    }

    return <GridWrapper className={className} gridTemplateColumns={`auto ${isGroupedByVolatility ? '' : "auto"} repeat(${columnDefs?.length - (isGroupedByVolatility ? 1 : 2)}, ${columnWidth}ch)`}>
        {/* Header Row */}
        <FundAnalysisRow
            isVolatility={isVolatility}
            isGroupedByVolatility={isGroupedByVolatility}
            currentSort={currentSort}
            sort={sort}
        />
        {assetGroupsIsFetching
            ? new Array(currentLength.current).fill(undefined).map(_ => columnDefs?.map((_, index) => <Skeleton height="35px" key={index} />))
            : assetGroups?.map((assetGroup, assetIndex) =>
                <FundAnalysisRow
                    fund={assetGroup}
                    isAverage={assetIndex === (assetGroups?.length - 1)}
                    isVolatility={isVolatility}
                    isGroupedByVolatility={isGroupedByVolatility}
                />)}
    </GridWrapper>;
}

export default FundAnalysisGrid;
import React from "react";
import Header from "./Header";
import HeaderBottom from "./HeaderBottom";
import HeaderTop, { ClientTrackingWrapper } from "./HeaderTop";
import ClientInfo from "../../features/clientinfo";
import ClientTracking from "../../features/clienttracking";
import withProviders from "../../hooks/withProviders";
import { Outlet } from "react-router-dom";
import { Spinner } from "../../components/loaders";
import { ErrorCodeDisplay, ErrorMessage } from "../../components/messages";
import { CenterPage, Main } from "../../components/utilities";
import { ClientProvider, useClientContext } from "../../hooks/ClientContext";
import { ClientProjectProvider } from "../../hooks/ClientProjectContext";
import { ClientRouteTabs } from "../../routes/client.routes";

const ClientLayoutErrorDisplay = ({ error, reloadFn }) => {
    const { status, data } = error || { status: 200, data: {} };
    
    if (status === 403) {
        return (
            <Main>
                <CenterPage className="flex-column flex-md-row">
                    <ErrorCodeDisplay code={status}>
                        <p>Sorry, but you are not authorised to view this account. Please speak to your system administrator if you need access.</p>
                    </ErrorCodeDisplay>
                </CenterPage>
            </Main>
        );
    }

    if (status === 404 || !data) {
        return (
            <Main>
                <CenterPage className="flex-column flex-md-row">
                    <ErrorCodeDisplay code={status}>
                        <p>Sorry, but you the account you are trying to access could not be found.</p>
                    </ErrorCodeDisplay>
                </CenterPage>
            </Main>
        );
    }

    return (
        <Main>
            <CenterPage>
                <ErrorMessage 
                    flex="column"
                    error={error}
                    imageHeight={192} 
                    imageWidth={256}
                    retryCallback={reloadFn} 
                />
            </CenterPage>
        </Main>
    );
};

const ClientLayout = () => {
    const { account, error, isFetching, isLoading, isUninitialized, reload } = useClientContext();

    if (isUninitialized === true || isLoading === true || isFetching === true) {
        return (
            <Main>
                <CenterPage>
                    <Spinner />
                </CenterPage> 
            </Main>
        );
    }

    if (error) {
        return <ClientLayoutErrorDisplay error={error} reloadFn={reload} />
    }

    if (!account) {
        return <ClientLayoutErrorDisplay error={{ status: 404, data: null }} reloadFn={reload} />
    }

    return (
        <React.Fragment>
            <Header>
                <HeaderTop>
                    <ClientInfo />
                    <ClientTrackingWrapper>
                        <ClientTracking />
                    </ClientTrackingWrapper>
                </HeaderTop>
                <HeaderBottom>
                    <ClientRouteTabs />
                </HeaderBottom>
            </Header>
            <Outlet />
        </React.Fragment>
    );
};

export default withProviders(ClientProvider, ClientProjectProvider)(ClientLayout);
import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";

const messagesMasterAccountApiEndpoints = wealthProApi
    .enhanceEndpoints({
        addTagTypes: ["ClientMessages", "Conversations"]
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            fetchClientMessages: builder.query({
                query: ({ masterAccountId, page, limit, ...rest }) => ({
                    url: `api/messaging/masteraccounts/${masterAccountId}/messages?page=${page}&limit=${limit}`,
                    method: "POST",
                    body: rest
                }),
                serializeQueryArgs: ({ endpointName, queryArgs }) => {
                    const { masterAccountId } = queryArgs || {};
                    return `${endpointName}/${masterAccountId}`;
                },
                merge: (currentCache, newItems) => {
                    const { pagination: currPage } = currentCache || {};
                    const { pagination: nextPage } = newItems || {};

                    if (!currPage || nextPage.page <= currPage.page) {
                        return newItems;
                    }

                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                },
                forceRefetch: ({ currentArg, previousArg }) => {
                    const { page: currPage, masterAccountId: currMasterAccountId } = currentArg || { page: 1, masterAccountId: null };
                    const { page: prevPage, masterAccountId: prevMasterAccountId } = previousArg || { page: 0, masterAccountId: null };
                    return currPage !== prevPage || currMasterAccountId !== prevMasterAccountId;
                },
                providesTags: ["ClientMessages"]
            }),
            fetchAccountConversationThreads: builder.query({
                query: ({ masterAccountId, page, limit, ...rest }) => ({
                    url: `api/messaging/masteraccounts/${masterAccountId}/threads?page=${page}&limit=${limit}`,
                    method: 'POST',
                    body: rest
                }),
                serializeQueryArgs: ({ endpointName, queryArgs }) => {
                    const { masterAccountId, clientMessagesOnly, ownMessages } = queryArgs;
                    return `${endpointName}?masterAccountId=${masterAccountId}&clientMessagesOnly=${clientMessagesOnly}&ownMessages=${ownMessages}`;
                },
                merge: (currentCache, newItems) => {
                    const { pagination: currPage } = currentCache || {};
                    const { pagination: nextPage } = newItems || {};

                    if (!currPage || nextPage.page <= currPage.page) {
                        return newItems;
                    }

                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                },
                forceRefetch: ({ currentArg, previousArg }) => {
                    const { page: currPage, masterAccountId: currMasterAccountId } = currentArg || { page: 1, masterAccountId: null };
                    const { page: prevPage, masterAccountId: prevMasterAccountId } = previousArg || { page: 0, masterAccountId: null };
                    return currPage !== prevPage || currMasterAccountId !== prevMasterAccountId;
                },
                providesTags: ["Conversations"]
            }),
            fetchMessageUsersForMasterAccount: builder.query({
                query: ({ masterAccountId }) => ({
                    url: `api/messaging/masteraccounts/${masterAccountId}/contacts?sort=DisplayName`
                })
            }),
            fetchMessageClientAccounts: builder.query({
                query: (masterAccountId) => `api/messaging/masteraccounts/${masterAccountId}/clientaccounts`
            })
        })
    });

export const {
    useFetchClientMessagesQuery,
    useLazyFetchClientMessagesQuery,
    useFetchAccountConversationThreadsQuery,
    useLazyFetchAccountConversationThreadsQuery,
    useFetchMessageUsersForMasterAccountQuery,
    useLazyFetchMessageUsersForMasterAccountQuery,
    useFetchMessageClientAccountsQuery
} = messagesMasterAccountApiEndpoints;
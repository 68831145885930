import React from "react";
import FormSelect from "../FormSelect";

export const ReviewStatuses = [
    { label: 'In Progress', value: 0 },
    { label: 'Completed', value: 1 },
    { label: 'Cancelled', value: 2 },
    { label: 'On Hold', value: 3 },
    { label: 'Future', value: 4 }
];

const ReviewStatusSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={ReviewStatuses.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default ReviewStatusSelect;
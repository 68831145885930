import React, { useEffect, useState } from "react";
import moment from "moment";
import Moment from "react-moment";
import styled from "styled-components";
import { Toast, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { variantToColour } from "../../helpers/theme";

export const StyledToast = styled(Toast)`
    background-color: ${props => variantToColour({ theme: props.theme, variant: props.variant })};
`;

const Toaster = ({
    label = "",
    icon, 
    children, 
    variant = "light", 
    show = false, 
    onClose, 
    position = "top-end", 
    ...rest 
}) => {
    const [isToastVisible, setIsToastVisible] = useState(show);
    const [timeSinceOpened, setTimeSinceOpened] = useState(moment());

    useEffect(() => {
        if (show === true) {
            setTimeSinceOpened(_ => moment());
        }
        setIsToastVisible(_ => show);
    }, [ show, setIsToastVisible ]);

    useEffect(() => {
        if (isToastVisible === false && onClose && typeof (onClose) === 'function') {
            onClose();
        }
    }, [ isToastVisible ])

    return <ToastContainer className="p-3" position={position}>
        <StyledToast variant={variant} show={isToastVisible} onClose={() => setIsToastVisible(false)} {...rest}>
            <Toast.Header>
                {icon && (<FontAwesomeIcon className="me-2" icon={icon} />)}
                <strong className="me-auto">{label}</strong>
                <small>
                    <Moment interval={1000} fromNow>{timeSinceOpened}</Moment>
                </small>
            </Toast.Header>
            <Toast.Body>
                {children}
            </Toast.Body>
        </StyledToast>
    </ToastContainer>
};

export default Toaster;
import React, { useCallback, useRef } from "react";
import { useMessageThreadContext } from "../hooks/MessageThreadContext";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import MessageRow from "./MessageRow";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList } from "react-window";


const ThreadMessages = () => {

    const listRef = useRef();
    const rowHeights = useRef({});

    const { results, isLoading, isFetching, isError, error, retry, totalCount, loadMoreItems, hasItemLoaded } = useMessageThreadContext();

    const getRowHeight = (index) => {
        return (rowHeights.current[index] || 150) + 24;
    };

    const setRowHeight = (index, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    };

    const Row = useCallback((props) => <MessageRow setRowHeight={setRowHeight} {...props}/>, [])

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retry} />
        </ErrorMessageWrapper>;
    }

    return (
        <AutoSizer>
            {({ height, width }) => (
                <InfiniteLoader
                    isItemLoaded={hasItemLoaded}
                    itemCount={totalCount}
                    loadMoreItems={loadMoreItems}
                >
                    {({ onItemsRendered, ref }) => (
                        <VariableSizeList
                            ref={(e) => {
                                listRef.current = e;
                                ref(e);
                            }}
                            onItemsRendered={onItemsRendered}
                            height={height}
                            width={width}
                            itemCount={totalCount}
                            itemData={results}
                            itemSize={getRowHeight}
                        >
                            {Row}
                        </VariableSizeList>
                    )}
                </InfiniteLoader>
            )}
        </AutoSizer>
    );


};

export default ThreadMessages;
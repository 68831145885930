import styled from "styled-components";

const StyledRow = styled.div`
    > * {
        width: ${props => 100 / props.numberOfInstructionCols}%;
    }
`;

const InstructionLabelCol = styled.div`
    padding: 0 12px;
    font-weight: bold;
`;

const InstructionLabelRow = ({ className, label, numberOfInstructionCols = 7, value }) => {

    return <StyledRow className={className ? className + " row" : "row my-2"} numberOfInstructionCols={numberOfInstructionCols}>
        <InstructionLabelCol>
            {label}
        </InstructionLabelCol>
        <div />
        <InstructionLabelCol 
        className="text-end pe-3"
        >
            {value}
        </InstructionLabelCol>
    </StyledRow>;
};

export default InstructionLabelRow;
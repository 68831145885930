import React from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import { GenericUserDivisionSelect, GenericAdviserSelect } from "../../../components/forms/selects";
import { FormSelect, FormInput, FormTextArea, PercentageInput, CurrencyInput } from "../../../components/forms";

const IntroducerAdviceFeeRow = ({ control, errors, setValue, watch }) => {
    const adviceFeeType = watch('adviceFeeType');
    return (
        <div className="row mb-2">
            <div className={classNames({
                'col-6': adviceFeeType !== 2,
                'col-12': adviceFeeType === 2
            })}>
                <Controller 
                    name="adviceFeeType"
                    control={control}
                    rules={{ required: 'An Advice Fee Type must be selected.' }}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <FormSelect
                            label="Advice Fee Type"
                            options={[
                                { label: "Fixed", value: 0 },
                                { label: "Percent", value: 1 },
                                { label: "No Fee", value: 2 },
                            ]}
                            defaultValue={value}
                            isMulti={false}
                            disableAnimations={true}
                            errorMessage={errors?.adviceFeeType?.message} 
                            errorAllowRetry={false} 
                            onChange={(value) => {
                                setValue("adviceFeeRate", 0, { shouldDirty: true });
                                setValue("adviceFeeType", value.value, { shouldDirty: true })
                            }}
                            {...rest}
                        />
                    )}
                />
            </div>
            {adviceFeeType !== 2 && (
                <div className="col-6">
                    <Controller 
                        name="adviceFeeRate"
                        control={control}
                        rules={{ }}
                        render={({ field: { ref, value, onChange, ...rest } }) => (
                            <React.Fragment>
                                {adviceFeeType == 1 ? (
                                    <PercentageInput
                                        label="Advice Fee Rate"
                                        value={value}
                                        disableAnimations={true}
                                        errorMessage={errors?.adviceFeeRate?.message} 
                                        errorAllowRetry={false} 
                                        onChange={(e, values) => setValue("adviceFeeRate", values.floatValue, { shouldDirty: true })}
                                        isAllowed={(values) => {
                                            const { floatValue } = values;
                                            return floatValue && floatValue <= 100;
                                        }}
                                        {...rest}
                                    />
                                ) : (
                                    <CurrencyInput
                                        label="Advice Fee Rate"
                                        value={value}
                                        errorMessage={errors?.adviceFeeRate?.message} 
                                        errorAllowRetry={false} 
                                        disableAnimations={true}
                                        onChange={(e, values) => setValue("adviceFeeRate", values.floatValue, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    />
                </div>
            )}
        </div>
    );
};

const IntroducerCompanyAddressRow = ({ control, errors, setValue }) => (
    <div className="row mb-2">
        <div className="col">
            <Controller 
                name="address"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Address"
                        value={value}
                        placeholder="Address"
                        disableAnimations={true}
                        errorMessage={errors?.address?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("address", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
    </div>
);

const IntroducerCompanyInformationRow = ({ control, errors, setValue }) => (
    <div className="row mb-2">
        <div className="col">
            <Controller 
                name="company"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Company"
                        value={value}
                        placeholder="Company"
                        disableAnimations={true}
                        errorMessage={errors?.company?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("company", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
    </div>
);

const IntroducerContactInformationRow = ({ control, errors, setValue }) => (
    <div className="row mb-2">
        <div className="col-6">
            <Controller 
                name="phone"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Phone"
                        value={value}
                        placeholder="Phone"
                        disableAnimations={true}
                        errorMessage={errors?.phone?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("phone", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
        <div className="col-6">
            <Controller 
                name="email"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Email"
                        value={value}
                        placeholder="Email"
                        disableAnimations={true}
                        errorMessage={errors?.email?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("email", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
    </div>
);

const IntroducerDivisionRow = ({ control, errors, setValue }) => (
    <div className="row mb-2">
        <div className="col-6">
            <Controller 
                name="divisionId"
                control={control}
                rules={{ required: 'The inntroducer must be assigned a Division.' }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <GenericUserDivisionSelect
                        defaultValue={value}
                        label="Division"
                        isMulti={false}
                        disableAnimations={true}
                        errorMessage={errors?.divisionId?.message} 
                        errorAllowRetry={false} 
                        onChange={(value) => setValue("divisionId", value.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
        <div className="col-6">
            <Controller 
                name="adviserId"
                control={control}
                rules={{  }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <GenericAdviserSelect
                        defaultValue={value}
                        label="Adviser"
                        isMulti={false}
                        disableAnimations={true}
                        errorMessage={errors?.adviserId?.message} 
                        errorAllowRetry={false} 
                        onChange={(value) => setValue("adviserId", value.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
    </div>
);

const IntroducerFindersFeeRow = ({ control, errors, setValue, watch }) => {
    const findersFeeType = watch('findersFeeType');
    return (
        <div className="row mb-2">
            <div className={classNames({
                'col-6': findersFeeType !== 2,
                'col-12': findersFeeType === 2
            })}>
                <Controller 
                    name="findersFeeType"
                    control={control}
                    rules={{ required: 'A Finders Fee Type must be selected.' }}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <FormSelect
                            options={[
                                { label: "Fixed", value: 0 },
                                { label: "Percent", value: 1 },
                                { label: "No Fee", value: 2 },
                            ]}
                            label="Finders Fee Type"
                            defaultValue={value}
                            isMulti={false}
                            disableAnimations={true}
                            errorMessage={errors?.findersFeeType?.message} 
                            errorAllowRetry={false} 
                            onChange={(selection) =>{
                                setValue("findersFeeRate", 0, { shouldDirty: true });
                                setValue("findersFeeType", selection.value, { shouldDirty: true })
                            }}
                            {...rest}
                        />
                    )}
                />
            </div>
            {findersFeeType !== 2 && (
                <div className="col-6">
                    <Controller 
                        name="findersFeeRate"
                        control={control}
                        rules={{ }}
                        render={({ field: { ref, value, onChange, ...rest } }) => (
                            <React.Fragment>
                                {findersFeeType == 1 ? (
                                    <PercentageInput
                                        label="Finders Fee Rate"
                                        value={value}
                                        disableAnimations={true}
                                        errorMessage={errors?.findersFeeRate?.message} 
                                        errorAllowRetry={false} 
                                        onChange={(e, values) => setValue("findersFeeRate", values.floatValue, { shouldDirty: true })}
                                        isAllowed={(values) => {
                                            const { floatValue } = values;
                                            return floatValue && floatValue <= 100;
                                        }}
                                        {...rest}
                                    />
                                ) : (
                                    <CurrencyInput
                                        label="Finders Fee Rate"
                                        value={value}
                                        disableAnimations={true}
                                        errorMessage={errors?.findersFeeRate?.message} 
                                        errorAllowRetry={false} 
                                        onChange={(e, values) => setValue("findersFeeRate", values.floatValue, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    />
                </div>
            )}
        </div>
    );
};

const IntroducerNameInformationRow = ({ control, errors, setValue }) => (
    <div className="row mb-2">
        <div className="col-2">
            <Controller 
                name="title"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Title"
                        value={value}
                        placeholder="Mr, Miss, Mrs..."
                        disableAnimations={true}
                        errorMessage={errors?.title?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("title", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
        <div className="col-5">
            <Controller 
                name="forenames"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Forenames"
                        value={value}
                        placeholder="Forenames"
                        disableAnimations={true}
                        errorMessage={errors?.forenames?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("forenames", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
        <div className="col-5">
            <Controller 
                name="surname"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Surname"
                        value={value}
                        placeholder="Surname"
                        disableAnimations={true}
                        errorMessage={errors?.surname?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("surname", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
    </div>
);

const IntroducerNotesRow = ({ control, errors, setValue }) => (
    <div className="row mb-2">
        <div className="col">
            <Controller 
                name="notes"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormTextArea
                        label="Notes"
                        value={value}
                        minRows={3}
                        maxRows={3}
                        placeholder="Notes..."
                        disableAnimations={true}
                        errorMessage={errors?.notes?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("notes", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
    </div>
);

const IntroducerServiceFeesRow = ({ control, errors, setValue, watch }) => {
    const serviceFeeType = watch('serviceFeeType');
    return (
        <div className="row mb-2">
            <div className={classNames({
                'col-6': serviceFeeType !== 2,
                'col-12': serviceFeeType === 2
            })}>
                <Controller 
                    name="serviceFeeType"
                    control={control}
                    rules={{ required: 'A Service Fee Type must be selected.' }}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <FormSelect
                            label="Service Fee Type"
                            options={[
                                { label: "Fixed", value: 0 },
                                { label: "Percent", value: 1 },
                                { label: "No Fee", value: 2 },
                            ]}
                            defaultValue={value}
                            isMulti={false}
                            disableAnimations={true}
                            errorMessage={errors?.serviceFeeType?.message} 
                            errorAllowRetry={false} 
                            onChange={(value) => {
                                setValue("serviceFeeRate", 0, { shouldDirty: true });
                                setValue("serviceFeeType", value.value, { shouldDirty: true });
                            }}
                            {...rest}
                        />
                    )}
                />
            </div>
            {serviceFeeType !== 2 && (
                <div className="col-6">
                    <Controller 
                        name="serviceFeeRate"
                        control={control}
                        rules={{ }}
                        render={({ field: { ref, value, onChange, ...rest } }) => (
                            <React.Fragment>
                                {serviceFeeType == 1 ? (
                                    <PercentageInput
                                        label="Service Fee Rate"
                                        value={value}
                                        disableAnimations={true}
                                        errorMessage={errors?.serviceFeeRate?.message} 
                                        errorAllowRetry={false} 
                                        onChange={(e, values) => setValue("serviceFeeRate", values.floatValue, { shouldDirty: true })}
                                        isAllowed={(values) => {
                                            const { floatValue } = values;
                                            return floatValue && floatValue <= 100;
                                        }}
                                        {...rest}
                                    />
                                ) : (
                                    <CurrencyInput
                                        label="Service Fee Rate"
                                        value={value}
                                        errorMessage={errors?.serviceFeeRate?.message} 
                                        errorAllowRetry={false} 
                                        onChange={(e, values) => setValue("serviceFeeRate", values.floatValue, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    />
                </div>
            )}
        </div>
    );
};

const IntroducerStatusInformationRow = ({ control, errors, isAllowedToUpdateStatus, setValue }) => {
    return (
        <div className="row mb-2">
            <div className="col-6">
                <Controller 
                    name="introducerStatus"
                    control={control}
                    rules={{ required: 'A Status must be selected.' }}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <FormSelect
                            label="Status"
                            defaultValue={value}
                            isDisabled={!isAllowedToUpdateStatus}
                            errorMessage={errors?.introducerStatus?.message} 
                            errorAllowRetry={false} 
                            options={[
                                { label: "Approved", value: 0 },
                                { label: "Await Approval", value: 1 },
                                { label: "Rejected", value: 2 },
                                { label: "Inactive", value: 3 },
                            ]}
                            onChange={(values) => setValue('introducerStatus', values.value, { shouldDirty: true })}
                            {...rest}
                        />
                    )}
                />
            </div>
        </div>
    );
};

const IntroducerWorkDetailsRow = ({ control, errors, setValue }) => (
    <div className="row mb-2">
        <div className="col-2">
            <Controller 
                name="professionId"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormSelect
                        label="Profession"
                        defaultValue={value}
                        disableAnimations={true}
                        errorMessage={errors?.professionId?.message} 
                        errorAllowRetry={false} 
                        options={[
                            { label: "Accountant", value: 0 },
                            { label: "Estate Agent", value: 1 },
                            { label: "Solicitor", value: 2 },
                            { label: "Other Professional", value: 3 },
                        ]}
                        onChange={(value) => setValue("professionId", value.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
        <div className="col-5">
            <Controller 
                name="position"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Position"
                        value={value}
                        placeholder="Position"
                        disableAnimations={true}
                        errorMessage={errors?.position?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("position", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
        <div className="col-5">
            <Controller 
                name="salutation"
                control={control}
                rules={{ }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormInput
                        label="Salutation"
                        value={value}
                        placeholder="Salutation"
                        disableAnimations={true}
                        errorMessage={errors?.salutation?.message} 
                        errorAllowRetry={false} 
                        onChange={(e) => setValue("salutation", e.target.value, { shouldDirty: true })}
                        {...rest}
                    />
                )}
            />
        </div>
    </div>
);

const IntroducerForm = ({
    control,
    errors,
    isAllowedToUpdateStatus = false,
    setValue,
    watch
}) => {
    return (
        <React.Fragment>
            <IntroducerStatusInformationRow control={control} errors={errors} isAllowedToUpdateStatus={isAllowedToUpdateStatus} setValue={setValue} />
            <IntroducerNameInformationRow control={control} errors={errors} setValue={setValue} />
            <IntroducerWorkDetailsRow control={control} errors={errors} setValue={setValue} />
            <IntroducerCompanyInformationRow control={control} errors={errors} setValue={setValue} />
            <IntroducerCompanyAddressRow control={control} errors={errors} setValue={setValue} />
            <IntroducerContactInformationRow control={control} errors={errors} setValue={setValue} />
            <IntroducerNotesRow control={control} errors={errors} setValue={setValue} />
            <IntroducerDivisionRow control={control} errors={errors} setValue={setValue} />
            <IntroducerFindersFeeRow control={control} errors={errors} setValue={setValue} watch={watch} />
            <IntroducerAdviceFeeRow control={control} errors={errors} setValue={setValue} watch={watch} />
            <IntroducerServiceFeesRow control={control} errors={errors} setValue={setValue} watch={watch} />
        </React.Fragment>
    );
};

export default IntroducerForm;
import { useLazyFetchReportAdviserGroupingQuery } from "../../../services/clientreports";

const useReportsGroupedByAdviser = (parameters) => {
    const [search, searchResult] = useLazyFetchReportAdviserGroupingQuery()

    const onLoadOptions = () => search(parameters);

    return { search: onLoadOptions, ...searchResult };
};

export default useReportsGroupedByAdviser;
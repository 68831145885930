import {
    useCreateDependantMutation,
    useDeleteDependantMutation,
    usePatchDependantMutation,
    useFetchDependantsQuery,
} from "../../../services/clientfactfind";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";

const useDependants = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: dependants, isLoading: isLoadingDependants, isError: isErrorDependants, error: dependantsError, refetch: refetchDependants } = useFetchDependantsQuery({ masterAccountId });
    const [triggerCreateDependant, { isLoading: isCreating }] = useCreateDependantMutation();
    const [triggerDeleteDependant] = useDeleteDependantMutation();
    const [triggerPatchDependant] = usePatchDependantMutation();

    const createDependant = () => {
        return new Promise((resolve, reject) => {
            return triggerCreateDependant({ masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const deleteDependant = (dependantId) => {
        return new Promise((resolve, reject) => {
            return triggerDeleteDependant({ dependantId, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updateDependant = (dependantId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return triggerPatchDependant({ dependantId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    return {
        dependants: dependants || [],
        refetchDependants,
        isErrorDependants,
        isLoadingDependants,
        dependantsError,
        createDependant,
        isCreating,
        deleteDependant,
        updateDependant,
    };
};

export default useDependants;
import { useEffect } from "react";
import { useLazyFetchUserEmailContactsQuery } from "../../../services/useremails";
import FormSelectPaged from "../FormSelectPaged";
import FormSelectPagedCreatable from "../FormSelectPagedCreatable";

const EmailContactsPagedSelect = ({ userId, setValue, value, ...rest }) => {

    const onOptionsLoaded = (results) => {
        return results;
    }

    return <FormSelectPagedCreatable
        additional={{ userId, page: 1, pageLimit: 25 }}
        isCreatable
        setValue={setValue}
        value={value}
        query={useLazyFetchUserEmailContactsQuery}
        onOptionsLoaded={(_, results) => onOptionsLoaded(results)}
        isMulti={true}
        {...rest}
    />;
};

export default EmailContactsPagedSelect;
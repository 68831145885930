import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import IconButton from "../../../components/buttons/IconButton";
import { CurrencyInput, PercentageInput } from "../../../components/forms";
import { patchReplace } from "../../../helpers/patchDoc";
import { useFetchInvestTransactionsQuery } from "../../../services/recommendations";
import SedolSearch from "./SedolSearch";

const InvestTransaction = ({
    investId,
    index,
    isLoading,
    isCreating,
    isDeleting,
    realtimeItem,
    maxAllocation,
    totalMinusFees,
    addRow,
    deleteRow,
    updateRealTime,
    patchTransaction
}) => {
    // const [search, { isSearching }] = useSearchAssetBySedol();

    const { item } = useFetchInvestTransactionsQuery({ investId }, {
        selectFromResult: ({ data }) => {
            return { item: data[index] }
        }
    });

    // const InvestmentSedol = useMemo(() => isLoading
    //     ? <Skeleton />
    //     : <FormInput
    //         value={realtimeItem?.sedol ?? ""}
    //         disableAnimations={item?.sedol === realtimeItem?.sedol}
    //         onChange={(event) => {
    //             let operations = [patchReplace("sedol", event.target.value)];

    //             updateRealTime(operations, index)
    //         }}
    //         onBlur={(sedol) => {
    //             return new Promise((resolve, reject) => {
    //                 // All SEDOLs should be converted to uppercase onBlur
    //                 sedol = sedol.toUpperCase();

    //                 let operations = [patchReplace("sedol", sedol)];

    //                 switch (sedol) {
    //                     // If the user enters N/A or clears the input, also clear the assetName field
    //                     case 'N/A':
    //                     case '':
    //                         operations.push(patchReplace("assetName", ""));
    //                         break;
    //                     // If the user enters 'CASHXXX', replace the assetName with 'Cash Account (XXX)'
    //                     case sedol.match(/CASH.{3}/)?.input:
    //                         operations.push(patchReplace("assetName", `Cash Account (${sedol.substring(4)})`));
    //                         break;
    //                     // If none of these match, search for the SEDOL the usual way
    //                     default:
    //                         return search(sedol).then(res => {
    //                             // Use the search result to update the transaction's Asset Name (and SEDOL, if appropriate)
    //                             if (res?.sedol !== sedol)
    //                                 operations.push(patchReplace("sedol", res.sedol));

    //                             operations.push(patchReplace("assetName", res.longAssetName));

    //                             resolve(operations);
    //                         });
    //                 }

    //                 resolve(operations);
    //             }).then(res => {
    //                 updateRealTime(res, index);
    //                 patchTransaction(res, index);
    //             })
    //         }}
    //     />, [index, isLoading, item?.sedol, patchTransaction, realtimeItem?.sedol, search, updateRealTime]);

    // const disableAssetNameInput = useMemo(() => {
    //     if (item?.sedol !== realtimeItem?.sedol)
    //         return (realtimeItem?.sedol?.toLowerCase() !== "n/a" || realtimeItem?.sedol?.match(/cash.{3}/i));

    //     return (realtimeItem?.sedol ?? "") === ""
    // }, [item?.sedol, realtimeItem?.sedol])

    // const InvestmentAssetName = useMemo(() => (isLoading || isSearching)
    //     ? <Skeleton />
    //     : <OverlayTrigger
    //         placement="top"
    //         overlay={(props) => disableAssetNameInput
    //             ? <Popover {...props}>
    //                 <Popover.Header>SEDOL Search</Popover.Header>
    //                 <Popover.Body>
    //                     Please enter a SEDOL or Citicode to search for a fund.
    //                     <br />
    //                     <br />
    //                     <ThemedSpan variant="muted">
    //                         You can also add a custom fund by entering "N/A", or a cash fund by entering "CASHXXX" (e.g. CASHEUR).
    //                     </ThemedSpan>
    //                 </Popover.Body>
    //             </Popover>
    //             : <></>}>
    //         <span>
    //             <FormInput
    //                 value={realtimeItem?.assetName ?? ""}
    //                 onBlur={(assetName) => {
    //                     const operations = [patchReplace("assetName", assetName)];
    //                     return patchTransaction(operations, index)
    //                 }}
    //                 disabled={disableAssetNameInput}
    //             />
    //         </span>
    //     </OverlayTrigger>, [disableAssetNameInput, index, isLoading, isSearching, patchTransaction, realtimeItem?.assetName]);

    const [displayAllocation, setDisplayAllocation] = useState(null);

    useEffect(() => {
        setDisplayAllocation(item?.allocation);
    }, [item?.allocation]);

    useEffect(() => {
        if (index === 0 || displayAllocation == null)
            setDisplayAllocation(realtimeItem?.allocation);
    }, [displayAllocation, index, realtimeItem?.allocation]);

    return <>
        <SedolSearch
            realTimeSedol={realtimeItem?.sedol ?? ""}
            sedol={item?.sedol ?? ""}
            realTimeAssetName={realtimeItem?.assetName ?? ""}
            patchRealTime={(operations) => updateRealTime(operations, index)}
            patchService={(operations) => patchTransaction(operations, index)}
            isLoading={isLoading}
        />
        {isLoading
            ? <Skeleton />
            : <PercentageInput
                value={displayAllocation}
                onChange={(_, { floatValue }) => {
                    setDisplayAllocation(floatValue ?? 0);

                    let calculationValue = Math.min(floatValue ?? 0, maxAllocation);

                    const newValue = calculationValue * totalMinusFees / 100;
                    const operations = [
                        patchReplace("allocation", calculationValue),
                        patchReplace("investmentAmount", newValue)
                    ];

                    return updateRealTime(operations, index);
                }}
                onBlur={(amount) => {
                    let calculationValue = Math.min(amount ?? 0, maxAllocation);
                    setDisplayAllocation(calculationValue);

                    const newValue = calculationValue * totalMinusFees / 100;
                    const operations = [
                        patchReplace("allocation", calculationValue),
                        patchReplace("investmentAmount", newValue)
                    ];

                    return patchTransaction(operations, index);
                }}
                disableAnimations={item?.allocation === maxAllocation && displayAllocation >= maxAllocation}
                readOnly={(item?.rowTag ?? 0) === 0}
            />}

        {isLoading
            ? <Skeleton />
            : <CurrencyInput value={realtimeItem?.investmentAmount} readOnly />}

        {item?.rowTag === 0
            ? <IconButton
                icon={"circle-plus"}
                variant={"success"}
                disabled={isCreating}
                onClick={() => addRow()}
            />
            : <IconButton
                icon={"fa-trash"}
                variant={"danger"}
                disabled={isCreating || isDeleting}
                onClick={() => deleteRow(item?.rowTag)}
            />}
    </>;
}

export default InvestTransaction;

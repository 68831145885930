import React, { useCallback, useEffect, useState, useRef, forwardRef } from "react";
import styled from "styled-components";
import PageHeader from "../../components/layout/PageHeader";
import{
    ManagementReportSelect
} from "../../components/forms/selects";
import DetailedLeagueTableModal from "./components/managementreportmodals/detailedleaguetablemodal";
import BusinessStatusReportModal from "./components/managementreportmodals/businessstatusreportmodal";
import ReviewsInProgressReportModal from "./components/managementreportmodals/reviewsinprogressreportmodal"
import NewBusinessForecastReportModal from "./components/managementreportmodals/newbusinessforecastreportmodal";
import NewMoneyReportModal from "./components/managementreportmodals/newmoneyreportmodal";
import ContactReportModal from "./components/managementreportmodals/contactreportmodal"
import useDetailedLeagueTable from "./hooks/useDetailedLeagueTable";
import useBusinessStatusReport from "./hooks/useBusinessStatusReport";
import useReviewsInProgressReport from "./hooks/useReviewsInProgressReport";
import useContactReport from "./hooks/useContactReport";
import useNewBusinessForecastReport from "./hooks/useNewBusinessForecastReport";
import useNewMoneyReport from "./hooks/useNewMoneyReport";

import toast from "react-hot-toast";
import { BasicToast } from "../../components/toasts";

const AdminDashboardHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const PageHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

const ManagementReports = () => {

    const [message, setMessage] = useState("");

    const [areDetailedLeagueTableArgsShown, setAreDetailedLeagueTableArgsShown] = useState(false);
    const [areBusinessStatusReportArgsShown, setAreBusinessStatusReportArgsShown] = useState(false);
    const [areReviewsInProgressReportArgsShown, setAreReviewsInProgressReportArgsShown] = useState(false);
    const [areContactReportArgsShown, setAreContactReportArgsShown] = useState(false);
    const [areNewBusinessForecastReportArgsShown, setAreNewBusinessForecastReportArgsShown] = useState(false);
    const [areNewMoneyReportArgsShown, setAreNewMoneyReportArgsShown] = useState(false);

    const [selectedReport, setSelectedReport] = useState(null);

    const inputRef = useRef(null);

    //const ForwardReportSelect = forwardRef((props, ref) => {
    const ForwardReportSelect = forwardRef(function ForwardReportSelect(props, ref) {  
        return <ManagementReportSelect
            ref={ref}
            placeholder="Select Report"
            defaultValue={selectedReport}
            isMulti={false}
            isClearable={true}
            //onChange={(value) => setSelectedReport(value) }
            onChange={(value) => openTheDialog(value) }
            {...props}
        />

    });

    const openTheDialog = (selectedReport) => {
        if (selectedReport.value && typeof selectedReport.value === "number") {
            switch (selectedReport.value) {
                case 1:
                    setAreDetailedLeagueTableArgsShown(true);
                    break;
                case 2:
                    setAreBusinessStatusReportArgsShown(true);
                    break;
                case 3:
                    setAreContactReportArgsShown(true);
                    break;
                case 7:
                    setAreNewBusinessForecastReportArgsShown(true);
                    break;
                case 8:
                    setAreNewMoneyReportArgsShown(true);
                    break;
                //case 10:
                    //setAreReviewsInProgressReportArgsShown(true);
                    //break;
            }
        }
        //console.log(inputRef);
        //inputRef.current.focus();
    }
    
    const { getDetailedLeagueTableReport } = useDetailedLeagueTable();
    const { getBusinessStatusReport } = useBusinessStatusReport();
    const { getReviewsInProgressReport } = useReviewsInProgressReport();
    const { getNewBusinessForecastReport } = useNewBusinessForecastReport();
    const { getNewMoneyReport } = useNewMoneyReport();
    const { getContactReport } = useContactReport();

    return (
        <React.Fragment>
            <DetailedLeagueTableModal
                handleDetailedLeagueTableArgsClose={
                    () => {
                        setAreDetailedLeagueTableArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                detailedLeagueTableArgsShow={areDetailedLeagueTableArgsShown}

                handleDetailedLeagueTableArgsConfirm={
                    (reportDate) => 
                    toast.promise(getDetailedLeagueTableReport(reportDate), {
                        loading: `Creating Detailed League Table`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'Detailed League Table');
                            return `Detailed League is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create Detailed League Table - {err?.data}</div>
            
                    })
                }
            />
            <BusinessStatusReportModal
                handleBusinessStatusReportArgsClose={
                    () => {
                        setAreBusinessStatusReportArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                businessStatusReportArgsShow={areBusinessStatusReportArgsShown}

                handleBusinessStatusReportArgsConfirm={
                   // (theReport, theRequest) => 
                    (theRequest) => 
                    toast.promise(getBusinessStatusReport(theRequest), {
                        loading: `Creating Business Status Report`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'Business Status Report');
                            return `Business Status Report is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create Business Status Report - {err?.data}</div>
            
                    })
                }
            />
            <ContactReportModal
                handleContactReportArgsClose={
                    () => {
                        setAreContactReportArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                contactReportArgsShow={areContactReportArgsShown}

                handleContactReportArgsConfirm={
                   // (theReport, theRequest) => 
                    (theRequest) => 
                    toast.promise(getContactReport(theRequest), {
                        loading: `Creating Contact Report`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'Contact Report');
                            return `Contact Report is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create Contact Report - {err?.data}</div>
            
                    })
                }
            />
            <NewBusinessForecastReportModal
                handleNewBusinessForecastReportArgsClose={
                    () => {
                        setAreNewBusinessForecastReportArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                newBusinessForecastReportArgsShow={areNewBusinessForecastReportArgsShown}

                handleNewBusinessForecastReportArgsConfirm={
                   // (theReport, theRequest) => 
                    (theRequest) => 
                    toast.promise(getNewBusinessForecastReport(theRequest), {
                        loading: `Creating New Business Forecast Report`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'Contact Report');
                            return `New Business Forecast Report is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create New Business Forecast Report - {err?.data}</div>
            
                    })
                }
            />
            <NewMoneyReportModal
                handleNewMoneyReportArgsClose={
                    () => {
                        setAreNewMoneyReportArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                newMoneyReportArgsShow={areNewMoneyReportArgsShown}

                handleNewMoneyReportArgsConfirm={
                   // (theReport, theRequest) => 
                    (theRequest) => 
                    toast.promise(getNewMoneyReport(theRequest), {
                        loading: `Creating New Money Report`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'Contact Report');
                            return `New Money Report is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create New Money Report - {err?.data}</div>
            
                    })
                }
            />
            <ReviewsInProgressReportModal
                handleReviewsInProgressReportArgsClose={
                    () => {
                        setAreReviewsInProgressReportArgsShown(false);
                        setSelectedReport(null);
                    }
                }
                reviewsInProgressReportArgsShow={areReviewsInProgressReportArgsShown}

                handleReviewsInProgressReportArgsConfirm={
                   // (theReport, theRequest) => 
                    (theRequest) => 
                    toast.promise(getReviewsInProgressReport(theRequest), {
                        loading: `Creating Reviews In Progress Status Report`,
                        success: (data) => {
                            var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))
            
                            var file = new Blob([bytesPdf], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
            
                            window.open(fileURL, 'Reviews In Progress Report');
                            return `Reviews In Progress Report is open in a new window.`;
                        },
                        error: (err) => <div>Failed to create Reviews |In Progress Report - {err?.data}</div>
            
                    })
                }
            />
            {/*<PageHeader>
                <PageHeaderTop>
                    <AdminDashboardHeaderTitle>
                        <span>Management Reports</span>
                        {message}
                    </AdminDashboardHeaderTitle>
                </PageHeaderTop>
            </PageHeader>*/}
            <div className="row mt-5">
                <div className="col mx-5">
                   {/* <AdminReportSelect
                        //ref={ref}
                        placeholder="Select Report"
                        defaultValue={selectedReport}
                        isMulti={false}
                        isClearable={true}
                        //onChange={(value) => setSelectedReport(value) }
                        onChange={(value) => openTheDialog(value) }
                    />*/}
                    <ForwardReportSelect
                        ref={inputRef}
                        //defaultValue={selectedReport}
                        //onChange={(value) => openTheDialog(value) }
                    />
                   
                </div>
                {/*<div className="col">
                   <DetailedLeagueTableModalButton   
                        onClick={ () => openTheDialog(selectedReport)}
                    />
                </div>*/}
            </div>
        </React.Fragment>
    )
};

export default ManagementReports
import React from "react";
import styled from "styled-components";
import { useAuth } from "react-oidc-context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { variantToColour } from "../helpers/theme";

const Header = styled.h1`
    font-weight: bold;
    color: ${props => variantToColour({ theme: props.theme, variant: 'primary' })};
`;

const ErrorCode = styled.span`
    font-weight: bold;
    font-size: 12rem;
    color: ${props => variantToColour({ theme: props.theme, variant: 'muted' })}
`;

const NotAuthorisedPage = ({ dashboardLink = "/", ...rest }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const { user: { profile: { email, name } }, signoutRedirect } = auth;

    const onNavigateToDashboard = () =>  navigate(dashboardLink, { replace: true });

    const onSignOut = () => signoutRedirect({ 
        post_logout_redirect_uri: !searchParams.get('redirect')
            ? window.location.origin
            : decodeURIComponent(searchParams.get('redirect'))
    });

    return (
        <div className="vh-100 d-flex flex-column flex-md-row justify-content-center align-items-center" style={{ "gridArea": "main" }}>
            <div className="d-flex justify-content-center align-items-center p-md-3">
                <ErrorCode>401</ErrorCode>
            </div>
            <div className="px-3">
                <Header>Whoops!</Header>
                <h2>It's a secret to everybody...</h2>
                <p>Sorry, but {name} &lt;{email}&gt; is not authorised to access this content.</p>
                <div className="d-flex flex-column flex-md-row gap-2">
                    <Button variant="primary" onClick={onSignOut}>Sign out and connect with another login</Button>
                    <Button variant="primary" onClick={onNavigateToDashboard}>Back to Dashboard</Button>
                </div>
            </div>
        </div>
    );
};

export default NotAuthorisedPage;
import classNames from "classnames";
import React from "react";
import { ProjectManagementTableRow } from ".";
import { VirtualTable } from "../../../components/tables";
import { TableRecordCountDisplayFooter } from "../../../components/tables/VirtualTable";
import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { useFilterContext } from "../../../hooks/FilterContext";
import useProjectManagement from "../hooks/useProjectManagement";

export const ProjectManagementGrid = ({ setFeePotential }) => {
    const { filter } = useFilterContext();
    const {
        clear: refreshTable,
        results,
        totalCount,
        columns,
        isLoading,
        error,
        isRowLoaded,
        loadMore,
    } = useProjectManagement({ filter, setFeePotential });

    return <div className="p-3">
        <VirtualTable
            enableScrollTracking
            row={(props) => <ProjectManagementTableRow columns={columns} {...props} />}
            header={(
                <thead>
                    <tr>
                        {columns.map((col, key) => col.headerRenderer
                            ? col.headerRenderer({ ...col, key })
                            : <ThemedTableHeader key={key} className={classNames(col.headerClassName, "sticky-header")} style={{ width: col.width }}>
                                {col.label}
                            </ThemedTableHeader>)}
                    </tr>
                </thead>
            )}
            footer={
                <TableRecordCountDisplayFooter 
                    totalCount={totalCount}
                    isNewRecordsDisplayed={false}
                    newRecordsSinceCache={0}
                    reload={refreshTable}
                />
            }
            error={error}
            isLoading={isLoading}
            isItemLoaded={isRowLoaded}
            loadMoreItems={loadMore}
            itemSize={46}
            itemCount={totalCount === results.length ? totalCount : results.length + 3}
            itemData={results}
        />
    </div>;
};
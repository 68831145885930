import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { ActionIconNavLink, ThemedSpan } from "../../../components/utilities";

export const FavouritesMenu = styled.div`
    width: 80vw;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 1rem !important;
`;

export const FavouriteMenuHeader = styled.div`
    border-bottom: 1px solid ${props => darken(0.15, props.theme.light)};
`;

const FavouritesDropdownMenu = React.forwardRef(({ children, ...rest }, ref) => {
    return <FavouritesMenu ref={ref} {...rest}>
        <FavouriteMenuHeader className="row pb-2 mb-2">
            <div className="col">
                <ThemedSpan variant="primary">Favourites</ThemedSpan>
            </div>
            <div className="col-md-auto">
                <ActionIconNavLink to="/personal/favourites" icon="fa-expand" />
            </div>
        </FavouriteMenuHeader>
        {children}
    </FavouritesMenu>
});

export default FavouritesDropdownMenu;
import React from "react";
import ReviewList from "./components/ReviewList";
import { Main } from "../../components/utilities";

const ClientReviews = () => {

    return (
        <Main className="p-3">
            <ReviewList />
        </Main>
    )
}

export default ClientReviews; 
import { wealthProApi } from '../../redux/api';
//import { socket } from "../../redux/socket";
import { applyPatch } from "fast-json-patch";

const masterAccountsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountDetails", "masterAccountSummaryDetails"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        createMasterAccountDetails: builder.mutation({
            query: ({ data }) => ({
                url: `api/clientdetails/master-accounts`,
                method: 'POST',
                body: data
            })
        }),
        getMasterAccountDetails: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}`,
            providesTags: ({ masterAccountId }) => [{ type: 'masterAccountDetails', id: masterAccountId }]
        }),
        getMasterAccountDetailSummary: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/summary`,
            providesTags: (result, err) =>
                result && !err
                    ? [{ type: 'masterAccountSummaryDetails', id: result.masterAccountId }]
                    : ['masterAccountSummaryDetails']
        }),
        patchMasterAccountDetails: builder.mutation({
            query: ({ masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('getMasterAccountDetails', { masterAccountId }, (data) => {
                    applyPatch(data, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useCreateMasterAccountDetailsMutation,
    useGetMasterAccountDetailSummaryQuery,
    useGetMasterAccountDetailsQuery,
    useLazyGetMasterAccountDetailSummaryQuery,
    useLazyGetMasterAccountDetailsQuery,
    usePatchMasterAccountDetailsMutation
} = masterAccountsApiEndpoints;
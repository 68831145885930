import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchClientAccountDetailsQuery } from "../../../services/clientdetails";

const useEditClient = (clientAccountId) => {
    const { id: masterAccountId } = useClientContext();
    const { data: client, isLoading, isFetching, isError, refetch } = useFetchClientAccountDetailsQuery({ masterAccountId }, {
        selectFromResult: ({ data }) => ({
            data: data?.find(client => client.clientAccountId === clientAccountId)
        })
    });

    return [refetch, {
        client,
        isLoading: isLoading || isFetching,
        isError
    }];
}

export default useEditClient;
import Skeleton from "react-loading-skeleton";
import { CurrencyInput } from "../../../components/forms";
import useExpenditureColumn from "../hooks/useExpenditureColumn";
import RowColSpan from "./RowColSpan";
import SectionHeader from "./SectionHeader";

const ExpenditureColumn = ({
    clientExpenditureId,
    column,
    nonDiscRowsStart,
    discRowsStart,
    totalRowsStart }) => {
    const [{
        patchValueCache,
        patchValueService
    }, {
        client,
        totalAnnualNonDiscExpenditure,
        totalAnnualDiscExpenditure,
        totalAnnualExpenditure,
        totalMonthlyNonDiscExpenditure,
        totalMonthlyDiscExpenditure,
        totalMonthlyExpenditure,
        nonDiscExpTypeInfo,
        discExpTypeInfo,
        isLoading
    }] = useExpenditureColumn(clientExpenditureId);

    return <>
        <RowColSpan maxWidth={"25ch"} className="mt-2 pe-2" gridRowStart={nonDiscRowsStart - 1} gridColumnStart={column}>
            <SectionHeader className="mt-0">
                {client?.clientName}
            </SectionHeader>
        </RowColSpan>

        {/* Non-Disc Section */}
        {nonDiscExpTypeInfo?.map((typeInfo, index) => <RowColSpan maxWidth={"25ch"} key={`client-${typeInfo?.expenditureTypeId}`} gridRowStart={nonDiscRowsStart + index} gridColumnStart={column}>
            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    className={"my-auto"}
                    value={client?.expenditureEntries?.find(entry => entry.expenditureTypeId === typeInfo?.expenditureTypeId)?.value}
                    onChange={(_, { floatValue }) => patchValueCache(typeInfo?.expenditureTypeId, floatValue)}
                    onBlur={(newValue) => patchValueService(typeInfo?.expenditureTypeId, newValue)}
                    decimalScale={0}
                    fixedDecimalScale
                />}
        </RowColSpan>)}

        {/* Non-Disc Totals */}
        <RowColSpan maxWidth={"25ch"} gridRowStart={discRowsStart - 3} gridColumnStart={column}>
            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    value={totalAnnualNonDiscExpenditure}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />}
        </RowColSpan>
        <RowColSpan maxWidth={"25ch"} gridRowStart={discRowsStart - 2} gridColumnStart={column}>
            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    value={totalMonthlyNonDiscExpenditure}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />}
        </RowColSpan>

        <RowColSpan className="mt-2 pe-2" gridRowStart={discRowsStart - 1} gridColumnStart={column}>
            <SectionHeader className="mt-0">
                {client?.clientName}
            </SectionHeader>
        </RowColSpan>

        {/* Disc Section */}
        {discExpTypeInfo?.map((typeInfo, index) => <RowColSpan maxWidth={"25ch"} key={`client-${typeInfo?.expenditureTypeId}`} gridRowStart={discRowsStart + index} gridColumnStart={column}>
            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    className={"my-auto"}
                    value={client?.expenditureEntries?.find(entry => entry.expenditureTypeId === typeInfo?.expenditureTypeId)?.value}
                    onChange={(_, { floatValue }) => patchValueCache(typeInfo?.expenditureTypeId, floatValue)}
                    onBlur={(newValue) => patchValueService(typeInfo?.expenditureTypeId, newValue)}
                    decimalScale={0}
                    fixedDecimalScale
                />
            }
        </RowColSpan>)}

        {/* Disc Totals */}
        <RowColSpan maxWidth={"25ch"} gridRowStart={totalRowsStart - 3} gridColumnStart={column}>
            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    value={totalAnnualDiscExpenditure}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />}
        </RowColSpan>
        <RowColSpan maxWidth={"25ch"} gridRowStart={totalRowsStart - 2} gridColumnStart={column}>
            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    value={totalMonthlyDiscExpenditure}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />}
        </RowColSpan>

        {/* Totals */}
        <RowColSpan maxWidth={"25ch"} gridRowStart={totalRowsStart} gridColumnStart={column}>
            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    className={"my-auto"}
                    value={totalAnnualExpenditure}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />
            }
        </RowColSpan>
        <RowColSpan maxWidth={"25ch"} gridRowStart={totalRowsStart + 1} gridColumnStart={column}>
            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    className={"my-auto"}
                    value={totalMonthlyExpenditure}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />
            }
        </RowColSpan>
    </>
}

export default ExpenditureColumn;
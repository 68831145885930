import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { FormLabel } from "../../../components/forms";
import RichTextEditor, { NotesEditor } from "../../../components/forms/richtexteditor/RichTextEditor";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import ClientRetirementPlans from "../components/ClientRetirementPlans";
import FinalSalaryArrangements from "../components/FinalSalaryArrangements";
import { InfoBubble } from "../components/FormInfoBubbleWrapper";
import PensionsArrangementsList from "../components/PensionsArrangementsList";
import useInvestments from "../hooks/useInvestments";

const InvestmentsWrapper = styled.div`
    > .row {
        margin-bottom: 16px;
    }
`;

const InvestmentsSection = () => {
    const {
        investmentsSection,
        isLoadingInvestmentsSection,
        isErrorInvestmentsSection,
        investmentsSectionError,
        hasFinalSalaryArrangements,
        hasPensionArrangements,
        updateNotes,
        refetchInvestments
    } = useInvestments();

    const { clientViewsOnEsg, sourceOfFunds, investmentPlanningObjectives, retirementPlanningObjectives, } = investmentsSection || {};

    if (isErrorInvestmentsSection) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={investmentsSectionError} retryCallback={refetchInvestments} />
        </ErrorMessageWrapper>;
    }

    if (isLoadingInvestmentsSection || !investmentsSection) return <Skeleton height={16} count={4} />;

    return <div className="container-fluid">
        <InvestmentsWrapper>
            <div className="row">
                <NotesEditor
                    label={"Investment Planning Objectives"}
                    property={"investmentPlanningObjectives"}
                    defaultValue={investmentPlanningObjectives.notes}
                    onBlur={(newValue) => updateNotes(investmentPlanningObjectives.notesId, newValue)}
                />
            </div>
            <div className="row">
                <RichTextEditor
                    label={<div className="d-flex justify-content-between">
                        <FormLabel>Client's Views on ESG, Ethical or Other Investments</FormLabel>
                        <InfoBubble
                            showBubble={true}
                            fieldKey={"viewsOnEsg"}
                        />
                    </div>}
                    defaultValue={clientViewsOnEsg.notes}
                    onBlur={(newValue) => updateNotes(clientViewsOnEsg.notesId, newValue)}
                />
            </div>
            <div className="row">
                <RichTextEditor
                    label={<div className="d-flex justify-content-between">
                        <FormLabel>Source of Funds</FormLabel>
                        <InfoBubble
                            showBubble={true}
                            fieldKey={"sourceOfFunds"}
                        />
                    </div>}
                    defaultValue={sourceOfFunds.notes}
                    onBlur={(newValue) => updateNotes(sourceOfFunds.notesId, newValue)}
                />
            </div>
            <div className="row">
                <ClientRetirementPlans />
            </div>
            <div className="row">
                <RichTextEditor
                    label={<div className="d-flex justify-content-between">
                        <FormLabel>Retirement Planning Objectives</FormLabel>
                        <InfoBubble
                            showBubble={true}
                            fieldKey={"retirementPlanningObjectives"}
                        />
                    </div>}
                    defaultValue={retirementPlanningObjectives.notes}
                    onBlur={(newValue) => updateNotes(retirementPlanningObjectives.notesId, newValue)}
                />
            </div>
        </InvestmentsWrapper>
        <PensionsArrangementsList hasFinalSalaryArrangements={hasFinalSalaryArrangements} />
        <FinalSalaryArrangements hasPensionArrangements={hasPensionArrangements} />
    </div>;
};

export default InvestmentsSection;
import React from "react";
import Button from "../../../components/buttons";
import { Toast, ToastContainer } from "react-bootstrap";

const NewConnectionHeader = ({ isButtonDisabled, onCreate }) => {
    return <React.Fragment>
        <div className="d-flex flex-row-reverse">
            <Button 
                variant="success" 
                onClick={onCreate} 
                disabled={isButtonDisabled}>
                Create new Connection
            </Button>
        </div>
    </React.Fragment>
};

export default NewConnectionHeader;
import React from "react";
import ReviewStatusGroup from "./components/ReviewStatusGroup";

const ReviewManagementSummary = () => {
    return <React.Fragment>
        <article>
            <ReviewStatusGroup />
        </article>
    </React.Fragment>
};

export default ReviewManagementSummary;
import React, { useState } from "react";
import toast from "react-hot-toast";
import useSalutationPreferences from "../hooks/useSalutationPreferences";
import { Button, IconButton } from "../../../components";
import { FormSelect } from "../../../components/forms";
import { BasicToast } from "../../../components/toasts";
import { Modal } from "react-bootstrap";
import { useTheme } from "styled-components";
import { confirmAlert } from "react-confirm-alert";

const DeleteSalutationConfirm = ({ handleClose, handleConfirm, onClose, record, salutations, theme = { } }) => {
    const { isDefault } = record;
    const [selectedSalutation, setSelectedSalutation] = useState(null);

    const onCloseSelected = () => {
        if (handleClose && typeof(handleClose) === 'function') {
            handleClose();
        }
        onClose();
    };

    const onConfirmSelected = () => {
        if (handleConfirm && typeof(handleConfirm) === 'function') {
            handleConfirm({ newDefaultSalutation: selectedSalutation });
        }
        onClose();
    };

    return (
        <Modal centered backdrop="static" show={true}>
            <Modal.Header>
                <Modal.Title>Delete Salutation?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="m-0">
                    Are you sure you would like to delete this Salutation?
                </p>
                {isDefault && salutations && salutations.length > 1 && (
                    <React.Fragment>
                        <p className="my-3">The record you are trying to delete is also assigned as the default salutation for the account. Please select a new default, or leave empty to assign the master account salutation as the new default.</p>
                        <FormSelect 
                            options={salutations.map((el) => { 
                                return { value: el.salutationId, label: el.addressee }
                            })}
                            placeholder="Default Salutation"
                            defaultValue={selectedSalutation}
                            onChange={(values) => setSelectedSalutation(values.value)}
                        />
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" theme={theme} onClick={onConfirmSelected}>Delete</Button>
                <Button variant="light" theme={theme} onClick={onCloseSelected}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

const DeleteSalutationButton = ({ salutation }) => {
    const theme = useTheme();
    const { salutationId, isPrimary } = salutation;
    const { data, deleteSalutation } = useSalutationPreferences();

    const handleOnClick = (e) => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: (props) => {
                return <DeleteSalutationConfirm 
                    handleConfirm={handleOnDelete} 
                    record={salutation} 
                    salutations={data.filter(el => el.salutationId !== salutationId)}
                    theme={theme}
                    {...props} 
                />
            }
        });
    };

    const handleOnDelete = ({ newDefaultSalutation }) => {
        deleteSalutation(salutationId, newDefaultSalutation).then(
            _ => {
                toast.custom((t) => <BasicToast 
                    control={t} 
                    date={new Date()} 
                    icon="fa-solid fa-user-minus" 
                    title="Salutation Deleted" 
                    message={`Salutation preference was deleted.`}
                />, { duration: 8000 });
            },
            e => toast.error(e?.data?.message)
        )
    };

    return (
        <IconButton icon="fa-trash-can" variant="danger" disabled={isPrimary} onClick={handleOnClick} />
    );
};

export default DeleteSalutationButton;
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { patchReplace } from "../../../helpers/patchDoc";
import { useLazyFetchProjectMessagesQuery, usePatchRecipientByUserIdMutation, useReadUserMessageMutation, useUnreadUserMessageMutation } from "../../../services/messages";

const useProjectMessagesList = (projectId) => {
    const [selectedThreadId, setSelectedThreadId] = useState(null);

    const [cacheKey, setCacheKey] = useState(new Date().valueOf());
    const [pageLimit, setPageLimit] = useState(25);
    const [load, { data, ...queryResults }] = useLazyFetchProjectMessagesQuery();
    const { results, pagination: { page, totalCount, totalPages } } = data || { results: [], pagination: { page: 1, totalCount: 0, totalPages: 0 } };
    const [isClearing, setIsClearing] = useState(false);

    const clear = () => {
        if (!projectId) {
            return;
        }
        setIsClearing(true);
        
        const newCacheKey = new Date().valueOf();
        load({
            cacheKey: newCacheKey,
            projectId,
            page: 1,
            limit: pageLimit
        }).unwrap().then(_ => setCacheKey(newCacheKey))
            .finally(() => setIsClearing(false));
    };

    const isRowLoaded = (index) => index < results.length;

    const loadMore = () => {
        if (page >= totalPages) {
            return Promise.resolve(false);
        }
        return load({
            cacheKey,
            projectId,
            page: page + 1,
            limit: pageLimit
        });
    };

    useEffect(clear, [load, pageLimit, projectId]);

    const { user: { profile: { sub: userId } } } = useAuth();

    const [readMessageTrigger] = useReadUserMessageMutation();
    const [unreadMessageTrigger] = useUnreadUserMessageMutation();

    const readMessage = (messageId, request) => 
        readMessageTrigger({ userId, messageId, request })
            .unwrap();
    
    const unreadMessage = (messageId, request) =>
        unreadMessageTrigger({ userId, messageId, request })
            .unwrap();

    const [updateRecipientTrigger] = usePatchRecipientByUserIdMutation();

    const updateRecipient = (messageId, userId, property, newValue) => {
        const operations = [patchReplace(property, newValue)];
        return updateRecipientTrigger({ userId, messageId, projectId, operations })
            .unwrap();
    }
    
    return {
        selectedThreadId,
        setSelectedThreadId,
        clear,
        data: results,
        isRowLoaded,
        loadMore,
        page,
        pageLimit,
        totalCount,
        totalPages,
        isClearing,
        readMessage,
        unreadMessage,
        updateRecipient,
        ...queryResults
    };
}

export default useProjectMessagesList;
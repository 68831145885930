import React from "react";
import ReportCheckArray from "./ReportCheckArray";
import useReportCategories from "../hooks/useReportCategories";
import { ThemedParagraph } from "../../../components/utilities";

// TODO: tidy up the loaders, errors, etc

const ReportCategoriesList = ({ control, name }) => {
    const { data, error, isLoading, reload } = useReportCategories();

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error!</div>
    }

    return <React.Fragment>
        <ThemedParagraph variant="primary" className="mb-1">Select all Categories that apply :-</ThemedParagraph>
        <ReportCheckArray 
            name={name}
            control={control}
            data={data}
            emptyMessage="There are no Categories to choose from available."
        />
    </React.Fragment>
};

export default ReportCategoriesList;
import React from "react";

const NoResults = ({ 
    className, 
    flex = "row", 
    imageSrc = "/images/droid.png", 
    imageHeight = 92, 
    imageWidth = 128,
    message = "These are not the search results you are looking for.",
    messageAlign = "center",
    messageSize = "",
    suffixText,
    ...rest 
}) => {
    const wrapperClassName = flex === "column" ? "d-flex flex-column" : "d-flex";
    
    const getMessageClassName = () => {
        if (messageAlign === 'center') {
            return "m-0 text-center";
        }
        
        if (messageAlign === 'end') {
            return "m-0 text-end";
        }

        return "m-0";
    };

    const getMessageHeaderClassName = () => {
        if (messageSize && typeof (messageSize) === 'string' && messageSize.length > 0) {
            return messageSize;
        }
        return "";
    };

    return <div className={wrapperClassName}>
        <div className="d-flex justify-content-center align-items-center">
            <img src={imageSrc} height={imageHeight} width={imageWidth} alt="not-found" />
        </div>
        <div className={className || "d-flex flex-column flex-fill justify-content-center align-items-center"} {...rest}>
            <p className={getMessageClassName()}>
                <span className={getMessageHeaderClassName()}>{message}</span>
                {suffixText && typeof (suffixText) === 'string' && suffixText.length > 0 && (
                    <React.Fragment>
                        <br/>
                        <span className="text-muted">{suffixText}</span>
                    </React.Fragment>
                )}
            </p>
        </div>
    </div>
};

export default NoResults;
import React, { useMemo, useState } from "react";
import useAdminInstructionCash from "./hooks/useAdminInstructionCash";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import IsaSubscriptionCash from "./components/IsaSubscriptionCash";
import NewInvestmentExceptIsaCash from "./components/NewInvestmentExceptIsaCash";
import SellSwitchCash from "./components/SellSwitchCash";
import IsaTransferAndInspecieApsCash from "./components/IsaTransferAndInspecieApsCash";
import InvestmentAccountTransferCash from "./components/InvestmentAccountTransferCash";
import PensionTransferCash from "./components/PensionTransferCash";
import InvestmentIncomeCash from "./components/InvestmentIncomeCash";
import ChangeOwnershipCash from "./components/ChangeOwnershipCash";
import TaxFreeCashAndPensionIncomeCash from "./components/TaxFreeCashAndPensionIncomeCash";
import { CenterArea, ContentWrapper, ThemedSpan } from "../../components/utilities";
import { Main } from "../../components/utilities";
import CashRaisedUpdateModal from "./components/CashRaisedUpdateModal";
import RecalculateTransactionsModal from "./components/RecalculateTransactionsModal";
import RaiseFeeCash from "./components/RaiseFeeCash";
import { Spinner } from "../../components/loaders";
import { useHeadOfficeApplicationContext } from "../../hooks/HeadOfficeApplicationContext";
import { Button, IconButton } from "../../components";
import { Modal } from "react-bootstrap";
import RegularContributionCash from "./components/RegularContributionCash";
import RecalculateCashRaisedModal from "./components/RecalculateCashRaisedModal";

const InstructionTypes = {
    IsaSubscription: 0,
    NewInvestment: 1,
    NewRegularContribution: 2,
    SellSwitch: 3,
    IsaTransfer: 4,
    InvestmentAccountTransfer: 5,
    PensionTransfer: 6,
    TaxFreeCashAndPensionIncome: 7,
    InvestmentIncome: 8,
    ChangeOwnership: 9,
    RaiseAFee: 10,
};

const FeePaymentSources = {
    PurchaseContract: 0,
    TradingAccount: 1,
    Direct: 2,
    NotApplicable: 3,
    ExistingTradingCashBalance: 4
};


const AdministrationCash = () => {
    const { application: { feePaymentMethod, ...rest } } = useHeadOfficeApplicationContext();
    const [showRecalculateTransactionsModal, setShowRecalculateTransactionsModal] = useState(false);
    const [showCashRaisedUpdateModal, setShowCashRaisedUpdateModal] = useState(null);
    const [showRecalculateCashModal, setShowRecalculateCashModal] = useState(false);
    const [isInvestSameAsCash, setIsInvestSameAsCash] = useState(false);
    const [totalCashRaised, setTotalCashRaised] = useState(0);

    const {
        data,
        error,
        instructionType,
        isError,
        isLoading,
        isPatching,
        isRecalculatingCash,
        isRecalculatingTransactions,
        patchAdminInstructionCash,
        patchAdminInstructionCashSource,
        recalculateCash,
        recalculateTransactions,
        retryGetAdminInstructionCash,
    } = useAdminInstructionCash();

    const handleCloseCashRaisedUpdateModal = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        setShowCashRaisedUpdateModal(false);
        setIsInvestSameAsCash(null);
    };

    const handleShowCashRaisedUpdateModal = (isInvestSameAsCash) => {
        setShowCashRaisedUpdateModal(true);
        setIsInvestSameAsCash(isInvestSameAsCash);
    };

    const handleCashRaisedUpdateSubmit = () => {
        patchAdminInstructionCash('investAmount', totalCashRaised)
            .then(
                _ => {
                    recalculateTransactions()
                }
            );
    };

    const CashComponent = useMemo(() => {
        switch (instructionType) {
            case InstructionTypes.IsaSubscription:
                return IsaSubscriptionCash;
            case InstructionTypes.NewInvestment:
                return NewInvestmentExceptIsaCash;
            case InstructionTypes.NewRegularContribution:
                // Not in wireframe
                return RegularContributionCash;
            case InstructionTypes.SellSwitch:
                return SellSwitchCash;
            case InstructionTypes.IsaTransfer:
                return IsaTransferAndInspecieApsCash;
            case InstructionTypes.InvestmentAccountTransfer:
                return InvestmentAccountTransferCash;
            case InstructionTypes.PensionTransfer:
                return PensionTransferCash;
            case InstructionTypes.TaxFreeCashAndPensionIncome:
                return TaxFreeCashAndPensionIncomeCash;
            case InstructionTypes.InvestmentIncome:
                return InvestmentIncomeCash;
            case InstructionTypes.ChangeOwnership:
                return ChangeOwnershipCash;
            case InstructionTypes.RaiseAFee:
                // Not in wireframe
                return RaiseFeeCash;
        }
    }, [instructionType]);

    if (isLoading) {
        return (
            <CenterArea>
                <Spinner />
            </CenterArea>
        );
    }

    if (isError) {
        return (
            <ErrorMessageWrapper>
                <ErrorMessage
                    error={error}
                    retryCallback={retryGetAdminInstructionCash}
                />
            </ErrorMessageWrapper>
        );
    }

    return (
        <Main className="p-3">
            <ContentWrapper label={<div className="d-flex justify-content-between align-items-center">
                <ThemedSpan>Summary: {data?.instructionTypeText}</ThemedSpan>
                {data?.hasCashRecalculation && <IconButton
                    icon={"fa-refresh"}
                    variant="success"
                    onClick={() => setShowRecalculateCashModal(true)}
                />}
            </div>}>
                <CashComponent
                    data={data}
                    updateFn={patchAdminInstructionCash}
                    cashSourceUpdateFn={patchAdminInstructionCashSource}
                    showCashUpdatedModal={handleShowCashRaisedUpdateModal}
                    showRecalculateTransactionsModal={() => setShowRecalculateTransactionsModal(true)}
                    setTotalCashRaised={setTotalCashRaised}
                    deductAdviceFeeFromRemainingBalance={feePaymentMethod !== FeePaymentSources.ExistingTradingCashBalance}
                />
            </ContentWrapper>
            <CashRaisedUpdateModal
                onSubmit={handleCashRaisedUpdateSubmit}
                show={showCashRaisedUpdateModal}
                onClose={handleCloseCashRaisedUpdateModal}
                infoOnly={!isInvestSameAsCash}
                isPatching={isPatching}
            />
            <RecalculateCashRaisedModal
                show={showRecalculateCashModal}
                onClose={() => setShowRecalculateCashModal(false)}
                onSubmit={recalculateCash}
                isRecalculating={isRecalculatingCash}
            />
            <RecalculateTransactionsModal
                show={showRecalculateTransactionsModal}
                onClose={() => setShowRecalculateTransactionsModal(false)}
                onSubmit={recalculateTransactions}
                isRecalculating={isRecalculatingTransactions}
            />
        </Main>
    );
};

export default AdministrationCash;

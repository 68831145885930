import { useMemo } from "react";
import { useHoFetchBusinessSheetStatusesQuery, useHoFetchOrganisationsQuery } from "../../../services/headofficeadmin";

const useFilterOptions = () => {
    const {
        data: organisations,
        isLoading: isLoadingOrganisations,
        error: fetchOrganisationsError
    } = useHoFetchOrganisationsQuery({ fetchType: "list" });

    const {
        data: statuses,
        isLoading: isLoadingStatuses,
        error: fetchStatusesError
    } = useHoFetchBusinessSheetStatusesQuery();

    const isLoading = useMemo(() => isLoadingOrganisations || isLoadingStatuses,
        [isLoadingOrganisations, isLoadingStatuses]);

    const errors = useMemo(() => ({
        organisations: fetchOrganisationsError,
        statuses: fetchStatusesError
    }), [fetchOrganisationsError, fetchStatusesError]);

    return {
        options: {
            organisation: organisations,
            status: statuses
        },
        isLoading,
        errors
    }
}

export default useFilterOptions;
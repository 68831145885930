import { useDeleteMasterAccountDashboardLoginMutation } from "../../../services/clientauth";

const useDeleteAccount = () => {
    const [_delete, { isLoading, ...deleteResult }] = useDeleteMasterAccountDashboardLoginMutation();

    const deleteAccount = (masterAccountId, loginId) => {
        return new Promise((resolve, reject) => {
            return _delete({ masterAccountId, loginId })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { deleteAccount, isLoading, data: { isLoading, ...deleteResult } };
};

export default useDeleteAccount;
import { useCallback, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchClientRetirementPlansQuery, usePatchClientRetirementPlanMutation } from "../../../services/clientfactfind";

const lifeStageData = [
    {
        label: "Young Saver",
        value: 10,
    }, {
        label: "Accumulator",
        value: 20,
    }, {
        label: "Building Wealth",
        value: 30,
    }, {
        label: "Approaching Retirement",
        value: 40,
    }, {
        label: "Post Retirement",
        value: 50,
    }
];

const ltaProtectionTypeData = [
    {
        label: "None",
        value: 10
    },
    {
        label: "Primary",
        value: 20
    },
    {
        label: "Enhanced",
        value: 30
    },
    {
        label: "Fixed 2012",
        value: 40
    },
    {
        label: "Fixed 2014",
        value: 50
    },
    {
        label: "Fixed 2016",
        value: 60
    },
    {
        label: "Individual 2014",
        value: 70
    },
    {
        label: "Individual 2016",
        value: 80
    }
];

const useRetirementPlans = () => {

    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isError, error, refetch } = useFetchClientRetirementPlansQuery({ masterAccountId });
    const [triggerPatch] = usePatchClientRetirementPlanMutation();

    // Commenting out old way of getting personal details to update employment status, as this has been removed
    // const { map: clientAccountPersonalDetailsIdMap } = useFetchPersonalDetailsQuery({ masterAccountId }, {
    //     selectFromResult: (res) => ({
    //         map: res?.data?.map(details => ({
    //             personalDetailsId: details.personalDetailsId,
    //             clientAccountId: details.clientAccountId
    //         }))
    //     })
    // });

    // const [triggerPersonalDetailsPatch] = usePatchClientPersonalDetailsMutation();

    const updatePlan = (retirementPlanId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return triggerPatch({ retirementPlanId, operations })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    // const updateEmploymentStatus = (retirementPlanId, value) => {
    //     return new Promise((resolve, reject) => {
    //         const operations = [patchReplace("employmentStatus", value)];

    //         const clientAccountId = data.find((plan) => plan.retirementPlanId === retirementPlanId).clientAccountId;
    //         const personalDetailsId = clientAccountPersonalDetailsIdMap.find(map => map.clientAccountId === clientAccountId).personalDetailsId;

    //         return triggerPatch({ retirementPlanId, operations }).unwrap()
    //             .then(
    //                 r1 =>
    //                     triggerPersonalDetailsPatch({ personalDetailsId, operations })
    //                         .unwrap()
    //                         .then(
    //                             r2 => resolve({ ...r2, ...r1 }),
    //                             e => reject(e)
    //                         ),
    //                 e => reject(e)
    //             );
    //     })
    // }

    const [isRefetching, setIsRefetching] = useState(false);

    const triggerRefetch = useCallback(() => {
        setIsRefetching(true);
        return new Promise((resolve, reject) => {
            refetch().unwrap()
                .then(
                    r => {
                        setIsRefetching(false);
                        resolve(r);
                    },
                    e => reject(e)
                );
        });
    }, [refetch])

    return {
        data: data || [undefined],
        isLoading: isLoading || isRefetching,
        isError,
        error,
        refetch: triggerRefetch,
        updatePlan,
        ltaProtectionTypes: ltaProtectionTypeData,
        lifeStages: lifeStageData
    };
};

export default useRetirementPlans;
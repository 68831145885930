import { wealthProApi } from "../../redux/api";

const icChartApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (build) => ({
        fetchChartFile: build.query({
            query: ({ assetGroupId }) => `api/assets/chart/${assetGroupId}`,
        }),
        publishChart: build.mutation({
            query: ({ assetGroupId, file }) => ({
                url: `api/assets/chart/${assetGroupId}`,
                method: 'POST',
                body: file,
                formData: true
            }),
        })
    })
});

export const {
    useLazyFetchChartFileQuery,
    usePublishChartMutation
} = icChartApiEndpoints;
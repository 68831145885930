import { Main, ThemedSpan } from "../../../components/utilities";
import { usePricesManagement } from "../hooks/PricesManagementContext";
import { VirtualTable } from "../../../components/tables";
import AssetPricesTableHeader from "./AssetPricesTableHeader";
import AssetPricesTableRow from "./AssetPricesTableRow";

const AssetPricesTable = () => {

    const { selectedAsset, loadMoreItems, isItemLoaded, totalCount, results } = usePricesManagement();
    const { data: asset } = selectedAsset || {};

    if (!selectedAsset) {
        return null;
    }

    return (
        <VirtualTable
            enableScrollTracking={false}
            loadMoreItems={loadMoreItems}
            row={AssetPricesTableRow}
            header={<AssetPricesTableHeader />}
            itemSize={42}
            itemCount={totalCount === results.length ? totalCount : results.length + 10}
            itemData={results}
            isItemLoaded={isItemLoaded}
        />
    )
};

export default AssetPricesTable;
import React, { useEffect } from "react";
import { GridWrapper } from "../../../components";
import { DivestTransactionTable, Income, ProductSelection } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

// This instruction has an Invest and Divest
const ChangeOwnership = ({ instructionId }) => {
    const [{ fetchInvest, fetchDivest }, { investIsError, divestIsError }] = useInstruction();

    useEffect(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId });
    }, [fetchDivest, fetchInvest, instructionId]);

    const retry = () => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId });
    }

    if (investIsError || divestIsError)
        return <InvestDivestErrorMessage retry={retry} />

    return <GridWrapper>
        <ProductSelection
            changeOwner
            productLabel={"Changer Ownership From:"}
        />
        <DivestTransactionTable />
        <ProductSelection
            isInvest
            changeOwner
            productLabel={"Changer Ownership To:"} />
        <Income clientWithdrawal retainAmount showRemainder />
    </GridWrapper>;
};

export default ChangeOwnership;

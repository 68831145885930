import _ from "lodash";
import React, { forwardRef, useEffect, useId, useRef, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import FormGroup from "./FormGroup";
import FormInputGroup from "./FormInputGroup";
import FormLabel from "./FormLabel";
import InputErrorMessage from "./InputErrorMessage";
import Button from "../../components/buttons";
import { InputSpinnerMd } from "../loaders/InputSpinners";
import { InputGroup } from "react-bootstrap";
import { IMask, IMaskInput } from "react-imask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemedIcon } from "../utilities";

const calculateClassName = (className, isClearable, isSuccess, error, baseClassName = "form-control") => {
    let name = baseClassName;

    if (className) {
        name += ` ${className}`;
    }

    if (isClearable === true) {
        name += ` border-end-0`;
    }

    if (isSuccess === true) {
        name += ` is-valid`;
    }

    if (error) {
        name += ` is-invalid`;
    }

    return name;
};

const calculateDateValidity = (value, format, isClearable) => {
    if (isClearable === true && (value === null || value === '')) {
        // NOTE: is this to catch when we want a nullable date?
        return true;
    }

    if (value && typeof (value) === 'string' && value.includes('_')) {
        return false;
    }

    try {
        let momentObj = value instanceof Date || !format
            ? moment(value)
            : moment(value, format);

        if (!momentObj.isValid()) {
            return false;
        }

        return true;
    }
    catch {
        return false;
    }
};

const calculateDisplayValue = (value, inputFormat, displayFormat) => {
    if (!value) {
        return "";
    }
    if (value instanceof Date) {
        return moment(value).format(displayFormat);
    }
    if (typeof (value) === 'string') {
        return moment(value, inputFormat).format(displayFormat);
    }
    return "";
};

const calculateInputGroupAddonClassName = (isSuccess, error) => {
    let name = "border-start-0";
    
    if (isSuccess === true) {
        name += ' border-success'
    }

    if (error) {
        name += ' border-danger'
    }

    return name;
};

const calculateIsClearable = (currentValue, isClearable, isLoading, isSuccess) => {
    return currentValue && isLoading === false && isSuccess === false && isClearable === true;
};

const DateBlocks = {
    DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
    },
    MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
    },
    YYYY: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
    },
    HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
        maxLength: 2
    },
    mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2
    }
};

const DateButton = forwardRef(({ onClick }, ref) => (
    <Button onClick={onClick} ref={ref} zindex="0">
        <FontAwesomeIcon icon="fa-calendar-days" />
    </Button>
));

const DateClearButton = styled(ThemedIcon)`
    cursor: pointer;
`;

const DateFieldInputGroup = styled(FormInputGroup)`
    && > .input-group-text {
        padding: 0;
    }

    && > .input-group-text .btn {
        border-radius: 0;
    }
`;

const StyledDateField = styled(IMaskInput)`
    && {
        ${props => props.loading === 'true' ? InputSpinnerMd : ''}
    }
`;

const DateInput = ({
    ButtonComponent = DateButton,
    className,
    compareGranularity = "day",
    disabled,
    disableAnimations = false,
    displayFormat = "DD/MM/YYYY",
    horizontal = false,
    id,
    inputFormat = "YYYY-MM-DD",
    isClearable = false,
    label,
    onBlur,
    onDateChanged,
    onChange,
    showTimeSelect = false,
    showTimeInput = false,
    successTimeout = 1000,
    triggerEventOnAcceptedDate = false,
    useOnBlurForDateChanged = true,
    value = new Date(),
    errorMessage,
    errorAllowRetry,
    ...rest
}) => {
    const ref = useRef(null);
    const inputRef = useRef(null);
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const displayValue = React.useMemo(() => calculateDisplayValue(value, inputFormat, displayFormat), [value, inputFormat, displayFormat]);
    const isDateValid = React.useMemo(() => calculateDateValidity(displayValue, displayFormat, isClearable), [displayValue, displayFormat, isClearable, value, inputRef.current]);
    const fieldClearable = React.useMemo(() => calculateIsClearable(value, isClearable, isLoading, isSuccess), [value, isClearable, isLoading, isSuccess]);
    const fieldClassName = React.useMemo(() => calculateClassName(className, fieldClearable, isSuccess, error), [className, fieldClearable, isSuccess, error]);
    const groupClassName = React.useMemo(() => calculateInputGroupAddonClassName(isSuccess, error), [isSuccess, error]);

    /**
     * Formats the date to the correct format for the iMask.
     * @param {*} date 
     * @returns 
     */
    const format = (date) => moment(date).format(displayFormat);

    /**
     * Calls the parent method passed into the method. The dateObj should be an object
     * in the format of: { date: xx, moment: yy, value: zz }
     * @param {*} parentFn 
     * @param {*} dateObj 
     */
    const handleParentFunction = (parentFn, dateObj, event) => {
        if (!parentFn || typeof (parentFn) !== 'function') {
            return;
        }

        if(dateObj.date !== null && !dateObj.moment.isValid()){
            return;
        }

        // trigger the loading animation on the input
        if (disableAnimations !== true) {
            setError(_ => null);
            setIsLoading(_ => true);
        }

        var parentFunctionCall = parentFn(dateObj.moment ? dateObj.moment.format(inputFormat) : null, { ...event, ...dateObj });
        Promise.resolve(parentFunctionCall).then(
            _ => {
                if (disableAnimations === true) {
                    return;
                }

                setIsLoading(_ => false);
                setIsSuccess(_ => true);
            },
            e => {
                if (disableAnimations === true) {
                    return;
                }

                setIsLoading(_ => false);
                setError(_ => e);
            }
        );
    };

    /**
     * Compares the value at the parent level with the value held in the input
     * to see if there is a difference. Primarily used in the onChange & onBlur
     * events so we can trigger calls when values are updated and not just entered
     * and exited.
     * @param {*} parentValue 
     * @param {*} inputValue 
     * @returns 
     */
    const hasValueChanged = (parentValue, inputValue) => {
        // do a quick check for null vlaues
        if (_.isEqual(parentValue, inputValue)) {
            return false;
        }

        let parentValueMoment = moment(parentValue);
        let inputValueMoment = inputValue instanceof Date ? moment(inputValue) : moment(inputValue, displayFormat);
        return !inputValueMoment.isSame(parentValueMoment, compareGranularity);
    };

    /**
     * The onBlur event handler for the iMask input field.
     * @param {*} e 
     * @param {*} disableDefault 
     * @returns 
     */
    const onBlurEvent = (e, disableDefault = true) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function' && disableDefault === true) {
            e.preventDefault();
        }

        // check that the date is valid before doing anything then,
        // compare the current value stored in the mask vs. the one passed in via the parent
        if (isDateValid === false || !inputRef.current || hasValueChanged(value, inputRef.current.value) === false) {
            return;
        }

        // call the parent "onBlur" function
        // NOTE: this method call will also check that the fn exists
        handleParentFunction(onBlur, {
            date: new Date(inputRef.current.value),
            moment: moment(inputRef.current.value, displayFormat),
            value: moment(inputRef.current.value, displayFormat).format()
        }, e);
    };

    /**
     * The onChange event handler for the iMask input.
     * @param {*} value 
     * @param {*} mask 
     * @param {*} target 
     */
    const onChangeEvent = (value, mask, target) => {
        // use moment to get the correct date
        // this is mainly because the new Date(value) keeps throwing errors/invalid dates!
        // DATES ARE A PAIN!
        var valueDate = moment(value, displayFormat).toDate();

        // trigger the parent on change event
        handleParentFunction(onChange, {
            date: valueDate,
            moment: moment(value, displayFormat),
            value: moment(value, displayFormat).format()
        }, { ...mask, target });

        // check if we have "completed" writing a date
        if (!value.includes('_') && triggerEventOnAcceptedDate === true) {
            onDateChangedEvent(valueDate, { value, mask, target });
        }
    };

    /**
     * Sets the value to 'null' when the X mark clear button is clicked.
     * @param {*} e 
     */
    const onClearDateEvent = (e) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }


        if (useOnBlurForDateChanged) {
            handleParentFunction(onBlur, {
                date: null,
                moment: null,
                value: null
            }, e);
        }
        else {
            handleParentFunction(onDateChanged, {
                date: null,
                moment: null,
                value: null
            }, e);
        }
    };

    /**
     * the onChange event handler for the React DatePicker component
     * NOTE: this is also the button/calendar dropdown attached to the side
     * @param {*} date 
     * @param {*} e 
     */
    const onDateChangedEvent = (date, e) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        // Need to check the provided date is valid
        let newDisplayDate = calculateDisplayValue(date, inputFormat, displayFormat);
        let isNewDateValid = calculateDateValidity(newDisplayDate, displayFormat, isClearable);

        // check that the date is valid before doing anything then,
        // compare the current value stored in the mask vs. the one passed in via the parent
        if (!isNewDateValid || hasValueChanged(value, date) === false) {
            return;
        }

        if (useOnBlurForDateChanged) {
            handleParentFunction(onBlur, {
                date,
                moment: moment(date),
                value: moment(date).format()
            }, e);
        }
        else {
            handleParentFunction(onDateChanged, {
                date,
                moment: moment(date),
                value: moment(date).format()
            }, e);
        }
    };

    /**
     * Parses the input taken from the iMask component into a
     * moment/date object so it can be handled.
     * @param {*} dateStr 
     * @returns 
     */
    const parse = (dateStr) => moment(dateStr, displayFormat);

    useEffect(() => {
        if (errorMessage) {
            setError(_ => errorMessage);
        }
        else {
            setError(_ => null);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (isSuccess === false) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(_ => false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    return <FormGroup horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <DateFieldInputGroup className="input-group" horizontal={horizontal} hasLabel={label ? true : false}>
            <StyledDateField
                id={componentId}
                className={fieldClassName}
                ref={ref}
                inputRef={inputRef}
                mask={Date}
                lazy={false}
                autofix={true}
                overwrite={true}
                pattern={displayFormat}
                value={displayValue}
                loading={isLoading.toString()}
                disabled={disabled || isLoading}
                blocks={DateBlocks}
                parse={parse}
                format={format}
                onAccept={onChangeEvent}
                onBlur={onBlurEvent}
                {...rest}
            />
            {fieldClearable && !disabled && (
                <InputGroup.Text className={`px-2 bg-transparent ${groupClassName}`}>
                    <DateClearButton
                        icon="fa-xmark"
                        variant="muted"
                        disabled={true}
                        onClick={onClearDateEvent}
                    />
                </InputGroup.Text>
            )}
            {ButtonComponent && (
                <InputGroup.Text className={groupClassName}>
                    <DatePicker
                        disabled={disabled || isLoading}
                        customInput={<ButtonComponent />}
                        selected={value ? new Date(value) : null}
                        onChange={onDateChangedEvent}
                        showTimeInput={showTimeInput}
                        showTimeSelect={showTimeSelect}
                        timeFormat={"HH:mm"}
                        {...rest}
                    />
                </InputGroup.Text>
            )}
            <InputErrorMessage allowRetry={isDateValid && errorAllowRetry} error={error} retryCallback={onBlurEvent} />
        </DateFieldInputGroup>
    </FormGroup>
};

export default DateInput;
import React from "react";
import { FormSelect } from "../../../components/forms";
import useInvestmentOperations from "../hooks/useInvestmentOperations";

const InvestmentOperationSelect = ({ options, masterAccountId, ...rest }) => {
    const { investmentOperations, error, isError, isLoading, isFetching } = useInvestmentOperations();
    return <FormSelect
        options={investmentOperations}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default InvestmentOperationSelect;
import { wealthProApi } from "../../redux/api";

const sectionApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        fetchSections: builder.query({
            query: () => `api/products/section`
        })
    })
});

export const {
    useFetchSectionsQuery
} = sectionApiEndpoints;
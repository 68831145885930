import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const hoRecommendationEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        hoSearchRecommendations: builder.query({
            query: (body) => ({
                url: `api/headofficeadmin/BusinessSheet/query`,
                method: "POST",
                body
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },
        }),
        hoPatchRecommendation: builder.mutation({
            query: ({ businessSheetId, operations }) => ({
                url: `api/headofficeadmin/BusinessSheet/${businessSheetId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ businessSheetId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('hoSearchRecommendations', {}, ({ results }) => {
                    var item = results.find(x => x.businessSheetId === businessSheetId);
                    applyPatch(item, operations, true);

                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        hoTriggerAdministration: builder.mutation({
            query: ({ businessSheetId, request }) => ({
                // need to make the gateway endpoint different from the rest so we can upload larger documents and configure the proxy properly?
                url: `api/headofficeadmin/business-sheet-documents/${businessSheetId}`,
                method: "POST",
                body: request
            })
        })
    })
});

export const {
    useLazyHoSearchRecommendationsQuery,
    useHoSearchRecommendationsQuery,
    useHoPatchRecommendationMutation,
    useHoTriggerAdministrationMutation
} = hoRecommendationEndpoints;
import { 
    useCreateAddressDetailsMutation,
    useDeleteAddressDetailsMutation,
    useFetchAddressDetailsQuery,
    usePatchAddressDetailsMutation 
} from "../../../services/clientdetails";

const useAddressDetails = (masterAccountId) => {
    const { data, error, isError, isFetching, isLoading, refetch } = useFetchAddressDetailsQuery({ masterAccountId });
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateAddressDetailsMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteAddressDetailsMutation();
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchAddressDetailsMutation();

    const createAddress = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        let isDefault = false;

        // setup this address as the default when there is no other default set
        if (!isLoading && !isError && data) {
            let defaults = data.filter(el => el.isDefault === true);
            isDefault = defaults.length === 0;
        }

        let newAddress = {
            masterAccountId,
            type: 0,
            address: '',
            postcode: '',
            countryCode: '',
            latitude: null,
            longitude: null,
            isDefault: isDefault,
            isActiveAddress: true
        };

        return new Promise((resolve, reject) => {
            return create({ masterAccountId, data: newAddress }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteAddress = (addressId) => {
        return new Promise((resolve, reject) => {
            return remove({ addressId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ masterAccountId });
    };

    const setDefaultAddress = (addressId) => {
        return new Promise((resolve, reject) => {
            // generate all the options we want to push up to the api
            let requests = [];
            data.forEach(el => {
                if (el.addressId === addressId && el.isDefault === false) {
                    requests.push({ 
                        addressId: el.addressId,
                        operations: [
                            { op: 'replace', path: `/isDefault`, value: true }
                        ]
                    });
                }

                if (el.addressId !== addressId && el.isDefault === true) {
                    requests.push({ 
                        addressId: el.addressId,
                        operations: [
                            { op: 'replace', path: `/isDefault`, value: false }
                        ]
                    });
                }
            });
            
            return patch({ masterAccountId, operations: requests }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateAddress = (addressId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            let request = [{
                addressId,
                operations
            }];

            return patch({ masterAccountId, operations: request }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        createAddress,
        createError,
        data: data || [],
        deleteAddress,
        deleteError: removeError,
        error,
        isError,
        isErrorCreating,
        isErrorRemoving,
        isErrorUpdating,
        isCreating,
        isLoading: isLoading === true || isFetching === true,
        isRemoving,
        isUpdating,
        reload,
        removeError,
        setDefaultAddress,
        updateError,
        updateAddress
    };
};

export default useAddressDetails;
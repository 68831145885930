import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const estatePlanningGiftsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createGift: builder.mutation({
            query: ({ estatePlanningId }) => ({
                url: `api/factfind/gifts`,
                method: 'POST',
                body: {
                    estatePlanningId
                }
            }),
            async onQueryStarted({ estatePlanningId }, { dispatch, queryFulfilled }) {
                try {
                    let createdIndex;
                    const createResult = dispatch(wealthProApi.util.updateQueryData('fetchGifts', { estatePlanningId }, data => {
                        const newGift = {
                            estatePlanningId,
                            giftId: data.giftId,
                            giftedBy: [],
                            description: null,
                            dateOfGift: null,
                            value: null
                        };
                        createdIndex = data.length;

                        return [...data, newGift];
                    }))

                    try {
                        const { data: response } = await queryFulfilled;

                        dispatch(wealthProApi.util.updateQueryData('fetchGifts', { estatePlanningId }, data => [
                            ...data.slice(0, createdIndex),
                            {
                                ...data[createdIndex],
                                giftId: response.giftId
                            },
                            ...data.slice(createdIndex + 1)
                        ]));
                    } catch (err) {
                        console.error("Error creating gift in service", err);
                        createResult.undo();
                        return;
                    }
                } catch (err) {
                    console.error("Error creating gift in cache", err);
                }
            }
        }),
        deleteGift: builder.mutation({
            query: ({ giftId }) => ({
                url: `api/factfind/gifts/${giftId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ estatePlanningId, giftId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchGifts', { estatePlanningId }, data =>
                        data.filter(x => x.giftId !== giftId)));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting gift in service", err);
                        deleteResult.undo();
                    }
                } catch (err) {
                    console.error("Error deleting gift in cache", err);
                }
            }
        }),
        patchGift: builder.mutation({
            query: ({ giftId, operations }) => ({
                url: `api/factfind/gifts/${giftId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ estatePlanningId, giftId, operations }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData('fetchGifts', { estatePlanningId }, data => {
                        var item = data.find(x => x.giftId === giftId);
                        applyPatch(item, operations, true);
                    }))
                }
                catch { }
            }
        }),
        updateGiftedByMappings: builder.mutation({
            query: ({ giftId, ids }) => ({
                url: `api/factfind/gifts/${giftId}`,
                method: 'POST',
                body: {
                    ids
                }
            }),
            async onQueryStarted({ estatePlanningId, giftId, page, limit }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newMappings } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchGifts', { estatePlanningId, page, limit }, data => {
                        var item = data.find(x => x.giftId === giftId);
                        item.giftedBy = newMappings;
                    }))
                }
                catch (ex) { console.error(ex) }
            }
        }),
    })
});

export const {
    useCreateGiftMutation,
    useDeleteGiftMutation,
    usePatchGiftMutation,
    useUpdateGiftedByMappingsMutation
} = estatePlanningGiftsApiEndpoints;
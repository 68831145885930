import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { useEffect, useState } from "react";
import { useAssetsManagement } from "../hooks/AssetsManagementContext";
import _ from "lodash";
import { FormInput } from "../../../components/forms";
import ProviderPagedSelect from "../../../components/forms/selects/ProviderPagedSelect";

const AssetTableFilterModal = ({ show, onHide }) => {

    const { assetsFilter, setAssetsFilter, resetFilters } = useAssetsManagement();
    const [tempFilters, setTempFilters] = useState(assetsFilter);

    const {
        assetName,
        sedol,
        provider,
        citicode
    } = tempFilters;

    const onApplyFilters = () => {
        setAssetsFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        onHide();
    };

    useEffect(() => {
        if (!_.isEqual(tempFilters, assetsFilter)) {
            setTempFilters(_ => assetsFilter);
        }
    }, [assetsFilter]);

    return (
        <Modal backdrop="static" centered size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Assets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <FormInput
                            label="Asset Name"
                            value={assetName ?? ""}
                            onChange={(e) => onFilterValueChanged("assetName", e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Sedol"
                            value={sedol ?? ""}
                            onChange={(e) => onFilterValueChanged("sedol", e.target.value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <FormInput
                            label="Citicode"
                            value={citicode ?? ""}
                            onChange={(e) => onFilterValueChanged("citicode", e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <ProviderPagedSelect
                            label="Provider"
                            value={provider}
                            setValue={value => onFilterValueChanged("provider", value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={resetFilters}>Clear Filters</Button>
                <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssetTableFilterModal;
import React, { useMemo } from "react";
import { BasicToast } from "../../../components/toasts";
import { useReminderForm } from "../hooks/ReminderFormContext";
import { ThemedIcon, ThemedSpan } from "../../../components/utilities";
import { Controller } from "react-hook-form";
import { MasterAccountSelect, ReminderTypesSelect, UserDelegatesSelect } from "../../../components/forms/selects";
import { DelegatedAccessTypes } from "../../../helpers/constants";
import MasterAccountProjectPagedSelect from "./MasterAccountProjectPagedSelect";
import {  FormInput, FormTextArea, DateTimeInput } from "../../../components/forms";
import { Button, FormLabel } from "react-bootstrap";
import toast from "react-hot-toast";
import FormGroup from "../../../components/forms/FormGroup";
import FileUploader from "../../../components/FileUploader";

const ReminderForm = ({ close, hideSubmit = false, onCreated, showUserSelect, hideTitle }) => {
    const { handleCreate, resetForm, form, masterAccountId, projectId, createResults } = useReminderForm();
    const { control, watch, formState: { errors }, getValues, handleSubmit, reset, setValue } = form;

    const showMasterAccountSelect = useMemo(() => !masterAccountId, [masterAccountId]);
    const showProjectSelect = useMemo(() => !projectId, [projectId]);

    const selectedMasterAccount = watch("masterAccount");
    const status = watch("status");

    const onClose = () => {
        resetForm(close);
    };

    const onSubmit = (data) => {        
        handleCreate(data).then(
            r => {
                if (onCreated && typeof (onCreated) === 'function') {
                    onCreated(r);
                }
                toast.custom((t) => (
                    <BasicToast
                        control={t}
                        date={r?.createdDate}
                        icon="fa-solid fa-list-check"
                        title="Event Generated"
                        message="Event has been created."
                    />
                ), { duration: 8000 })
            },
            e => toast.error(e?.data?.message)
        )
            .finally(onClose);
    };

    React.useEffect(() => {
        setValue("project", null);
    }, [selectedMasterAccount]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {!hideTitle && <p className="mb-2">
                <ThemedIcon icon="fa-list-check" variant="primary" className="me-1" />
                <ThemedSpan variant="muted">Create a new Reminder</ThemedSpan>
            </p>}
            <div className="row mb-2">
                <div className="col">
                    <Controller
                        name="typeId"
                        control={control}
                        rules={({ required: "An event must have a specified Type." })}
                        render={({ field: { ref, value, onChange, ...rest } }) => (
                            <ReminderTypesSelect
                                inputRef={ref}
                                label="Type"
                                menuPosition="absolute"
                                defaultValue={value}
                                disableAnimations={true}
                                errorMessage={errors?.typeId?.message}
                                errorAllowRetry={false}
                                onChange={(e) => setValue('typeId', e.value)}
                                {...rest}
                            />
                        )}
                    />
                </div>
                <div className="col">
                    <Controller
                        name="userId"
                        control={control}
                        rules={({
                            required: false
                        })}
                        render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                            <UserDelegatesSelect
                                disabled={!showUserSelect}
                                label="User"
                                disableAnimations={true}
                                errorMessage={errors?.userId?.message}
                                errorAllowRetry={false}
                                defaultValue={value}
                                onChange={value => setValue("userId", value.value)}
                                accessType={DelegatedAccessTypes.writeReminders}
                                menuPosition="absolute"
                                {...rest}
                            />
                        )}
                    />
                </div>
            </div>

            {showMasterAccountSelect && <div className="row mb-2">
                <div className="col">
                    <Controller
                        name="masterAccount"
                        control={control}
                        rules={({
                            required: false
                        })}
                        render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                            <MasterAccountSelect
                                menuPosition="absolute"
                                label="Account"
                                disableAnimations={true}
                                errorMessage={errors?.masterAccount?.message}
                                errorAllowRetry={false}
                                valueObject={value}
                                setValue={value => setValue("masterAccount", value)}
                                {...rest}
                            />
                        )}
                    />
                </div>
                {showProjectSelect && <div className="col">
                    <Controller
                        name="project"
                        control={control}
                        rules={({
                            required: false
                        })}
                        render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                            <MasterAccountProjectPagedSelect
                                menuPosition="absolute"
                                label="Project"
                                disabled={selectedMasterAccount === null}
                                masterAccountId={selectedMasterAccount?.value}
                                disableAnimations={true}
                                errorMessage={errors?.project?.message}
                                errorAllowRetry={false}
                                valueObject={value}
                                setValue={value => setValue("project", value)}
                                {...rest}
                            />
                        )}
                    />
                </div>}

            </div>}

            <div className="row mb-2">
                <div className="col">
                    <Controller
                        name="description"
                        control={control}
                        rules={({
                            required: "A Description must be given.",
                            maxLength: {
                                value: 256,
                                message: 'Too many characters'
                            }
                        })}
                        render={({ field: { ref, ...rest } }) => (
                            <FormInput
                                label="Description"
                                maxLength="256"
                                disableAnimations={true}
                                errorMessage={errors?.description?.message}
                                errorAllowRetry={false}
                                {...rest}
                            />
                        )}
                    />
                </div>
                <div className="col-auto">
                    <Controller
                        name="deadlineDate"
                        control={control}
                        render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                            <DateTimeInput
                                label="Event Date"
                                disableAnimations={true}
                                disabled={status === 3}
                                isClearable
                                onBlur={(value, e) => {
                                    if (value !== "Invalid date") {
                                        setValue('deadlineDate', value);
                                    }
                                }}
                                {...rest}
                            />
                        )}
                    />
                </div>
                {/* <div className="col-auto d-flex align-items-end">
                    <AttachmentsToggle
                        attachments={attachments.map(x => ({ fileName: x.name, ...x }))}
                        onUploadFn={() => setShowFileUpload(true)}
                        isCreatable
                        canOpenDocument={false}
                        uploadLabel="Add Attachments"
                    />
                </div> */}
            </div>
            <div className="row mb-3">
                <div className="col">
                    <Controller
                        name="notes"
                        control={control}
                        rules={({
                            maxLength: {
                                value: 4096,
                                message: 'Too many characters'
                            }
                        })}
                        render={({ field: { ref, ...rest } }) => (
                            <FormTextArea
                                minRows={3}
                                maxRows={3}
                                maxLength={4096}
                                disableAnimations={true}
                                label="Notes"
                                {...rest}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="row mb-2">
                <Controller
                    name="attachments"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                        <FormGroup>
                            <FormLabel>Attachments</FormLabel>
                            <FileUploader
                                onFilesChanged={({ files, info }) => {
                                    setValue("attachments", files);
                                    setValue("attachmentsInfo", info);
                                }}
                            />
                        </FormGroup>
                    )}
                />
            </div>

            {!hideSubmit && <div className="d-flex">
                <Button variant="success" className="w-100" type="submit">Create Event</Button>
            </div>}

            {/* <FileUploadModal
                show={showFileUpload}
                handleClose={() => setShowFileUpload(false)}
                handleConfirm={handleFileDrop}
                isServerSide={false}
                title="Add Attachments"
            />

            <RetryDocumentUploadModal
                show={showRetryUpload}
                handleClose={() => setShowRetryUpload(false)}
                files={filesToAdd}
                attachmentsResponse={createResults?.data}
            /> */}
        </form>
    );
};

export default ReminderForm;
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";

const notesEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['factFindNotes']
}).injectEndpoints({
    endpoints: (builder) => ({
        patchNotes: builder.mutation({
            query: ({ notesId, newText }) => ({
                url: `api/factfind/Notes/${notesId}`,
                method: 'PATCH',
                body: [patchReplace("notes", newText)]
            }),
            invalidatesTags: (res, err, { notesId }) => res && !err
                ? [{ type: 'factFindNotes', id: notesId }]
                : []
        })
    })
});

export const {
    usePatchNotesMutation
} = notesEndpoints;
import { useGetMasterAccountIdByContactDetailsQuery } from "../../../services/useremails";

const useGetMasterAccountByContactDetails = (contactDetails) => {

    const { data, isLoading } = useGetMasterAccountIdByContactDetailsQuery(contactDetails);

    return {
        data,
        isLoading
    };
};

export default useGetMasterAccountByContactDetails;
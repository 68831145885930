import { AccordionBody, AccordionHeader, AccordionListItem } from "../../../components/utilities";
import usePortfolioCommentary from "../hooks/usePortfolioCommentary";
import PrdCommentaryInput from "./PrdCommentaryInput";

const PortfolioNote = ({ standardPortfolioId }) => {
    const {
        portfolioNote, label, isLoading, patchPortfolioNote, isPatching, publishPortfolioNote, isPublishing
    } = usePortfolioCommentary({ standardPortfolioId });

    return <AccordionListItem eventKey={standardPortfolioId}>
        <AccordionHeader>{label}</AccordionHeader>
        <AccordionBody>
            <PrdCommentaryInput
                commentary={portfolioNote}
                isLoading={isLoading}
                patchCommentary={patchPortfolioNote}
                isPatching={isPatching}
                publishCommentary={publishPortfolioNote}
                isPublishing={isPublishing} />
        </AccordionBody>
    </AccordionListItem>;
};

export default PortfolioNote;
import { useLazySearchProvidersQuery } from "../../../services/products";
import FormSelectPaged from "../FormSelectPaged";

const ProviderPagedSelect = ({
    label,
    setValue,
    additional = { page: 1, limit: 50, fetchType: "list"},
    value, // should be object { label, value }
    isMulti,
    ...rest
}) => {

    return <FormSelectPaged
        label={label}
        additional={additional}
        query={useLazySearchProvidersQuery}
        searchTermPropertyName={"searchTerm"}
        onOptionsLoaded={(_, results) => results}
        setValue={setValue}
        valueObject={value}
        {...rest}
    />;
};

export default ProviderPagedSelect;
import { useLazyReviewsInProgressReportQuery } from "../../../services/headofficeadmin";

const useReviewsInProgressReport = () => {

    const [report, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyReviewsInProgressReportQuery();

    const getReviewsInProgressReport = (theRequest) => {
        const fromDate = theRequest.fromDate;
        const toDate = theRequest.toDate;
        const adviserIds = theRequest.adviserIds;
        console.log(adviserIds);
        return new Promise((resolve, reject) => {
            
            return report({data: {fromDate: fromDate, toDate: toDate, adviserIds: adviserIds}}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    return {
        getReviewsInProgressReport
    }

};

export default useReviewsInProgressReport;
import React from "react";
import { NotificationsDisplay } from "../../features/notifications";
import { PageHeader } from "../../components/utilities";

const PersonalNotificationsPage = () => {
    return <React.Fragment>
        <PageHeader>
            <h2>My Notifications</h2>
        </PageHeader>
        <section className="container my-3">
            <div className="card">
                <div className="card-body">
                    <NotificationsDisplay isOpen openPageOnMaxPagesReached={false}/>
                </div>
            </div>
        </section>
    </React.Fragment>
};

export default PersonalNotificationsPage;
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import { Main } from "../../../components/utilities";
import { useApplicationDocuments } from "../hooks/ApplicationDocumentsContext";
import ApplicationDocumentsGridHeader from "./ApplicationDocumentsGridHeader";
import ApplicationDocumentsGridRow from "./ApplicationDocumentsGridRow";


const ApplicationDocumentsGrid = () => {

    const { error, isError, results, totalCount, isItemLoaded, loadMoreItems, retryFn } = useApplicationDocuments();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retryFn} />
        </ErrorMessageWrapper>;
    }

    return <VirtualTable
            itemData={results}
            itemSize={42}
            itemCount={results.length === totalCount ? totalCount : results.length + 25}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            header={<ApplicationDocumentsGridHeader/>}
            row={ApplicationDocumentsGridRow}
        />
};

export default ApplicationDocumentsGrid;
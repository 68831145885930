import React from "react";
import styled from "styled-components";
import useOpenDocument from "../../../hooks/useOpenDocument";
import { useEditReport } from "../../../features/clientreportseditmodal/hooks/EditReportContext";
import { useReportHistory } from "../../../features/clientreportshistorymodal/hooks/ReportHistoryContext";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReportsTableActionColumnParent = styled.td`
    width: ${({ width }) => width}%;
    cursor: pointer;
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "center"};
`;

const ReportsTableActionColumnParentToggle = React.forwardRef(({ ...rest }, ref) => (
    <div ref={ref} {...rest}>
        <FontAwesomeIcon icon="fa-ellipsis" />
    </div>
))

const ReportsTableActionColumn = ({
    width = null,
    data = null,
}) => {
    const menuId = React.useId();
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const { setReport } = useEditReport();
    const { setReport: setReportHistory } = useReportHistory();

    const handleOpenDocument = () => openDocument(data['documentId']);

    const handleOpenEditModal = () => setReport(data);

    const handleOpenHistoryModal = () => setReportHistory(data);

    if (!data) {
        return (
            <ReportsTableActionColumnParent width={width}>
                <span>-</span>
            </ReportsTableActionColumnParent>
        );
    }

    return (
        <ReportsTableActionColumnParent width={width}>
            <Dropdown className="caret-off">
                <Dropdown.Toggle id={menuId} as={ReportsTableActionColumnParentToggle} />
                <Dropdown.Menu>
                    <Dropdown.Header>{data['reportName']}</Dropdown.Header>
                    <Dropdown.Item disabled={isOpening} onClick={handleOpenDocument}>
                        <FontAwesomeIcon icon="fas fa-file-signature" size="sm" /> <span className="ms-1">Open Report</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleOpenHistoryModal}>
                        <FontAwesomeIcon icon="fas fa-history" size="sm" /> <span className="ms-1">View History</span>
                    </Dropdown.Item>
                    <Dropdown.Header>
                        Tools
                    </Dropdown.Header>
                    <Dropdown.Item onClick={handleOpenEditModal}>
                        <FontAwesomeIcon icon="fa fa-pencil" size="sm" /> <span className="ms-1">Edit Details</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ReportsTableActionColumnParent>
    );
};

export default ReportsTableActionColumn;
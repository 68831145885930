import React from "react";
import FormSelect from "../../../components/forms/FormSelect";
import useProductDetails from "../hooks/useProductDetails";

const ProductSelect = ({ productData: optionsOverride, ...rest }) => {

    const { productData } = useProductDetails("select");

    return <FormSelect 
        options={productData || []} 
        {...rest} />
};

export default ProductSelect;
import { wealthProApi } from "../../redux/api";
import { patchReplace } from "../../helpers/patchDoc";

const assetsSectionApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchAccountAssets: builder.query({
            query: ({assetsSectionId}) => `api/factfind/assets/${assetsSectionId}/accountassets?sort=CreatedDate`
        }),
        patchAssetsSection: builder.mutation({
            query: ({assetsSectionId, operations}) => ({
                url: `api/factfind/assets/${assetsSectionId}`,
                method: 'PATCH',
                body: operations
            })
        }),
        patchAssetsSectionNotes: builder.mutation({
            query: ({ notesId, newText }) => ({
                url: `api/factfind/Notes/${notesId}`,
                method: 'PATCH',
                body: [patchReplace("notes", newText)]
            })
        })
    })
});

export const {
    useFetchAccountAssetsQuery,
    usePatchAssetsSectionMutation,
    usePatchAssetsSectionNotesMutation
} = assetsSectionApiEndpoints;
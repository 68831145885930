import styled from "styled-components";
import { readableColor } from "polished"

export const PageHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

export const PageHeaderBottom = styled.div``;

const PageHeader = styled.section`
    grid-area: pageheader;
    position: sticky;
    top: var(--navbar-size);
    z-index: 5;
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

export default PageHeader;
import { ThemedTableHeader } from "../../../components/tables/StandardTable";

const CustodianAccountMatchingGridHeader = ({ columns = [] }) => {

    return (
        <thead>
            <tr>
                {columns.map((item, key) => (
                    <ThemedTableHeader key={key}>{item.label}</ThemedTableHeader>
                ))}
                <ThemedTableHeader />
            </tr>
        </thead>
    );
};

export default CustodianAccountMatchingGridHeader;
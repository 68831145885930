import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { CompanyLogo } from "../../components";

const BrandContainer = styled.div`
    grid-area: headerbrand;
    margin: 0 0.5rem;
    
    & > div {
        display: flex;
        align-items: center;
    }

    @media (max-width: 767.98px) {
        justify-content: flex-end;
    }
`;

const LogoWrapper = styled(NavLink)``;

const HeaderBrand = () => {
    return <BrandContainer>
        <LogoWrapper to="/personal/messages">
            <CompanyLogo width={128} height={32} />
        </LogoWrapper>
    </BrandContainer>
};

export default HeaderBrand;
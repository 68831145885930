import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { variantToColour } from "../../helpers/theme";

const ThemedBootstrapDropdownMenuItem = styled(Dropdown.Item)`
    color: ${props => variantToColour({ theme: props.theme, variant: props.variant })};

    &&:hover {
        color: ${props => variantToColour({ theme: props.theme, variant: props.variant })};
    }
`;

export default ThemedBootstrapDropdownMenuItem;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const newMortgageApplicationSectionEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchNewMortgageApplicationSection: builder.mutation({
            query: ({ sectionId, operations }) => ({
                url: `api/factfind/NewMortgageApplication/${sectionId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const updateResult = dispatch(wealthProApi.util.updateQueryData("fetchNewMortgageApplicationSection", { masterAccountId }, (draft) => {
                        const copy = { ...draft };
                        applyPatch(copy, operations);

                        return copy;
                    }));

                    try {
                        await queryFulfilled
                    }
                    catch (ex) {
                        console.error("Error patching section in service", ex)
                        updateResult.undo();
                    }
                }
                catch (ex) {
                    console.error("Error patching section in cache", ex)
                }
            }
        })
    })
});

export const {
    usePatchNewMortgageApplicationSectionMutation
} = newMortgageApplicationSectionEndpoints
import { useCheckIsEmailAddressAvailableMutation } from "../../../services/clientauth";

const useIsEmailAddressAvailable = () => {
    const [_check, { isLoading, ...checkResult }] = useCheckIsEmailAddressAvailableMutation();

    const check = (emailAddress) => {
        return new Promise((resolve, reject) => {
            return _check({ emailAddress })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { check, isLoading, data: { isLoading, ...checkResult } };
};

export default useIsEmailAddressAvailable;
import React, { useCallback, useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, DateInput, FormLabel, FormSelect, FormTextArea } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { InfoBubble, SectionHeader } from "../components";
import useAccountAssets from "../hooks/useAccountAssets";
import useClientAccounts from '../hooks/useClientAccounts';
import { patchReplace } from "../../../helpers/patchDoc";

const AccountAssets = ({ assetSectionId, setTotals }) => {

    const {
        data,
        isLoading,
        assetOwnerTotals,
        isError,
        error,
        refetchAccountAssets,
        assetTypes,
        isLoadingAssetTypes,
        createAccountAsset,
        bulkPatchAccountAsset,
        patchAccountAsset,
        removeAccountAsset,
        updateAccountAssetOwnerMappings,
        setAccountAssets
    } = useAccountAssets(assetSectionId);

    const { data: clientAccounts, isLoading: isLoadingClientAccounts } = useClientAccounts({ fetchType: 'list' });

    useEffect(() => {
        if (assetOwnerTotals == null) return;
        setTotals(assetOwnerTotals);
    }, [data]);

    const handleOnChange = useCallback((accountAssetId, property, newValue) => {
        // https://react.dev/learn/updating-arrays-in-state#updating-objects-inside-arrays returning error, so used
        // https://react.dev/learn/updating-arrays-in-state#transforming-an-array which works...
        setAccountAssets(prev =>
            prev.map(accountAsset => {
                if (accountAsset.accountAssetId !== accountAssetId) return accountAsset;

                return {
                    ...accountAsset,
                    [property]: newValue
                };
            }));
    }, [setAccountAssets]);

    const allowCashIsa = useCallback((owners) => owners?.length < 2, [])
    const assetTypesWithoutCashIsa = useMemo(() => assetTypes?.filter(type => !type?.label?.toLowerCase().includes("cash isa")), [assetTypes])

    const allowMaturityDateInput = useCallback((assetTypeId) => {
        const type = assetTypes?.find(x => x.value === assetTypeId);

        if (type == null)
            return false;

        // Anything with "Fixed Term" in the label, "Structured Deposit", "Structured Products", "UK Bank/Building Society", "Non-UK Bank", "NS&I".
        return type?.label?.toLowerCase().includes("fixed term")
            || type?.label?.toLowerCase().includes("structured deposit")
            || type?.label?.toLowerCase().includes("structured products")
            || type?.label?.toLowerCase().includes("uk bank/building society")
            || type?.label?.toLowerCase().includes("non-uk bank")
            || type?.label?.toLowerCase().includes("ns&i");
    }, [assetTypes])

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchAccountAssets} />
        </ErrorMessageWrapper>
    }

    return <React.Fragment>
        <div className="row">
            <div className="col d-flex flex-row">
                <SectionHeader className="me-4 d-flex align-self-center">
                    Cash, Properties, and Additional Investments
                </SectionHeader>
                <InfoBubble
                    iconClassName={"align-self-center"}
                    showBubble
                />
            </div>
            {data.length > 0 && <div className="col-auto align-self-end d-md-none">
                <IconButton
                    className=""
                    icon="plus"
                    variant="success"
                    onClick={createAccountAsset}
                />
            </div>}

        </div>
        {isLoading
            ? <Skeleton height={30} count={2} />
            : data.length === 0
            && <p className="mt-2">There are no recorded assets for this account. Click <button className="anchor" onClick={createAccountAsset}>here</button> to create one</p>}
        {data.length > 0 && <div className="row d-none d-md-flex flex-row justify-content-between mt-2">
            <div className="col-3">
                <FormLabel>
                    Asset Type
                </FormLabel>
            </div>
            <div className="col-2">
                <FormLabel>
                    Owner(s)
                </FormLabel>
            </div>
            <div className="col-3">
                <FormLabel>
                    Description
                </FormLabel>
            </div>
            <div className="col">
                <FormLabel>
                    Value
                </FormLabel>
            </div>
            <div className="col">
                <FormLabel>
                    Maturity Date
                </FormLabel>
            </div>
            <div className="col-auto">
                <IconButton
                    className=""
                    icon="plus"
                    variant="success"
                    onClick={createAccountAsset}
                />
            </div>
        </div>}
        {data.map((asset, index) => <div
            key={asset.accountAssetId ?? index}
            className="row d-flex flex-row justify-content-between mt-2"
        >
            <div className="col-6 col-md-3">
                <FormLabel className="d-md-none">
                    Asset Type
                </FormLabel>
                {isLoading || !asset.accountAssetId
                    ? <Skeleton />
                    : <FormSelect
                        options={allowCashIsa(asset.ownerMappings) ? assetTypes : assetTypesWithoutCashIsa}
                        isLoadingOptions={isLoadingAssetTypes}
                        defaultValue={asset.assetTypeId}
                        onBlur={({ value }) =>
                            new Promise((resolve, reject) => {
                                if (!allowMaturityDateInput(value))
                                    return bulkPatchAccountAsset(asset.accountAssetId, [
                                        patchReplace("assetTypeId", value),
                                        patchReplace("maturityDate", null)
                                    ]).then(resolve, reject);

                                return patchAccountAsset(asset.accountAssetId, "assetTypeId", value)
                                    .then(resolve, reject);
                            })}
                    />}
            </div>
            <div className="col-6 col-md-2">
                <FormLabel className="d-md-none">
                    Owner(s)
                </FormLabel>
                {isLoading || !asset.accountAssetId
                    ? <Skeleton />
                    : <FormSelect
                        options={clientAccounts}
                        isLoadingOptions={isLoadingClientAccounts}
                        isMulti
                        defaultValue={asset.ownerMappings.map(x => x.clientAccountId)}
                        onBlur={(values) => updateAccountAssetOwnerMappings(asset.accountAssetId, values.map(x => x.value))
                            .then(() => {
                                if (values.length > 1) {
                                    return patchAccountAsset(asset.accountAssetId, "assetTypeId", null);
                                }
                            })
                        }

                    />}
            </div>
            <div className="col-5 col-md-3 mt-2 mt-md-0">
                <FormLabel className="d-md-none">
                    Description
                </FormLabel>
                {isLoading || !asset.accountAssetId
                    ? <Skeleton />
                    : <FormTextArea
                        value={asset.description}
                        onBlur={(value) => patchAccountAsset(asset.accountAssetId, "description", value)}
                    />}
            </div>
            <div className="col col-md mt-2 mt-md-0">
                <FormLabel className="d-md-none">
                    Value
                </FormLabel>
                {isLoading || !asset.accountAssetId
                    ? <Skeleton />
                    : <CurrencyInput
                        fixedDecimalScale
                        decimalScale={0}
                        value={asset.value}
                        onBlur={(value) => patchAccountAsset(asset.accountAssetId, "value", value)}
                        onChange={(_, { floatValue }) => handleOnChange(asset.accountAssetId, "value", floatValue)}
                    />}
            </div>
            <div className="col col-md mt-2 mt-md-0">
                <FormLabel className="d-md-none">
                    Maturity Date
                </FormLabel>
                {isLoading || !asset.accountAssetId
                    ? <Skeleton />
                    : <DateInput
                        value={asset.maturityDate ?? ""}
                        isClearable
                        onBlur={(value) => patchAccountAsset(asset.accountAssetId, "maturityDate", value)}
                        disabled={!allowMaturityDateInput(asset?.assetTypeId)}
                    />}
            </div>
            <div className="col-auto mt-auto mt-md-0">
                <IconButton
                    className="w-100"
                    icon="trash-can"
                    variant="danger"
                    onClick={() => removeAccountAsset(asset.accountAssetId)}
                    disabled={isLoading || !asset.accountAssetId}
                />
            </div>
            {(index !== data.length - 1) && <hr className="d-md-none my-2" />}
        </div>)}
    </React.Fragment>
};

export default AccountAssets;
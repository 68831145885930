import { MailSendType, usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useCreateDraftMailMutation, useCreateForwardDraftMailMutation, useCreateReplyDraftMailMutation } from "../../../services/useremails";

const useCreateMail = (parentMessageId = null) => {
    const { selectedMailUserId: userId } = usePersonalOutlookContext();
    const [createDraftMailTrigger, createDraftMailResult] = useCreateDraftMailMutation();
    const [createForwardMailTrigger, createForwardMailResult] = useCreateForwardDraftMailMutation();
    const [createReplyMailTrigger, createReplyMailResult] = useCreateReplyDraftMailMutation();

    const createDefaultDraftMail = (request) => {
        return new Promise((resolve, reject) => {
            return createDraftMailTrigger({ userId, request })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const createForwardDraftMail = (request) => {
        return new Promise((resolve, reject) => {
            return createForwardMailTrigger({
                userId,
                id: parentMessageId,
                request
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const createReplyDraftMail = (request) => {
        return new Promise((resolve, reject) => {
            return createReplyMailTrigger({
                userId,
                id: parentMessageId,
                request
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const createDraftMail = (request, sendType = MailSendType.Default) => {
        switch (sendType) {
            case MailSendType.Default: return createDefaultDraftMail(request);
            case MailSendType.Forward: return createForwardDraftMail(request);
            case MailSendType.Reply: return createReplyDraftMail(request);
            case MailSendType.ReplyAll: return createReplyDraftMail(request);
        }
    };

    return {
        createDraftMail,
        createDraftMailResult
    };
};

export default useCreateMail;
import React from "react";
import Navigation from "../../features/navigation";
import styled from "styled-components";

const NavigationContainer = styled.div`
    grid-area: headernav;
    margin: 0 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        display: flex;
        align-items: center;
    }
`;

const HeaderNavigation = () => {
    return <NavigationContainer>
        <Navigation />
    </NavigationContainer>
};

export default HeaderNavigation;
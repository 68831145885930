import { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { CurrencyInput, DateInput, FormInput, FormLabel, FormSelect, FormTextArea, RichTextEditor } from "../../../components/forms";
import { BooleanSelect } from "../../../components/forms/selects";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { InfoBubble, SectionHeader } from "../components";
import useNewMortgageApplicationSection from "../hooks/useNewMortgageApplicationSection";

const NewMortgageApplicationSection = () => {
    const [{
        patchService,
        patchNotes,
        refetchAll
    }, {
        section,
        options: {
            borrowerTypes,
            propertyTypes,
            mortgageTypes
        },
        isLoading,
        isError,
        error,
        monthlyNetIncome,
        overallMonthlyExpenditure,
        disposableMonthlyIncome,
        mortgageRentValue
    }] = useNewMortgageApplicationSection();

    const [realTimeLoanValues, setRealTimeLoanValues] = useState({
        purchasePrice: section?.purchasePrice,
        deposit: section?.deposit,
        giftAmount: section?.giftAmount
    });

    const updateRealTimePropertyValue = useCallback((property, value) => {
        setRealTimeLoanValues((prev) => ({
            ...prev,
            [property]: value
        }));
    }, []);

    const [realTimeLoanAmount, setRealTimeLoanAmount] = useState(section?.loanAmount);

    useEffect(() => {
        setRealTimeLoanValues({
            purchasePrice: section?.purchasePrice,
            deposit: section?.deposit,
            giftAmount: section?.giftAmount
        });
    }, [section?.deposit, section?.giftAmount, section?.purchasePrice]);

    useEffect(() => {
        if (!section)
            return;

        setRealTimeLoanAmount(prev => {
            let newVal = (realTimeLoanValues?.purchasePrice || 0) - (realTimeLoanValues?.deposit || 0) - (realTimeLoanValues?.giftAmount || 0);

            if (prev !== newVal)
                patchService("loanAmount", newVal);

            return newVal;
        });
    }, [patchService, realTimeLoanValues?.deposit, realTimeLoanValues?.giftAmount, realTimeLoanValues?.purchasePrice, section]);

    return isError ? <ErrorMessageWrapper>
        <ErrorMessage error={error} retryCallback={refetchAll} />
    </ErrorMessageWrapper>
        : <div className="container-fluid">
            <div className="d-flex row">
                <div className="col-6 col-md-3">
                    <CurrencyInput
                        label="Income:"
                        value={monthlyNetIncome}
                        disabled
                    />
                </div>
                <div className="col-6 col-md-3">
                    <CurrencyInput
                        label="Expenditure:"
                        value={overallMonthlyExpenditure}
                        disabled
                    />
                </div>
                <div className="col-6 col-md-3 mt-2 mt-md-0">
                    <CurrencyInput
                        label="Disposable Income:"
                        value={disposableMonthlyIncome}
                        disabled
                    />
                </div>
                <div className="col-6 col-md-3 mt-2 mt-md-0">
                    <CurrencyInput
                        label="Mortgage/Rent:"
                        value={mortgageRentValue}
                        disabled
                    />
                </div>
            </div>

            <div className="row d-flex flex-row mt-2">
                <div className="col-md-4">
                    <FormLabel>
                        Borrower Type:
                    </FormLabel>
                    {isLoading
                        ? <Skeleton />
                        : <FormSelect
                            options={borrowerTypes}
                            defaultValue={section?.borrowerType}
                            onBlur={(selection) => patchService("borrowerType", selection.value)}
                        />}
                </div>
                <div className="col-md-8 mt-2 mt-md-0 ">
                    <div className="d-flex justify-content-between">
                        <FormLabel>
                            Background:
                        </FormLabel>
                        <InfoBubble
                            showBubble
                        />
                    </div>
                    {isLoading
                        ? <Skeleton />
                        : <FormTextArea
                            value={section?.background}
                            onBlur={(newValue) => patchService("background", newValue)}
                        />}
                </div>
            </div>

            <div className="row d-flex flex-row mt-2">
                <div className="col mt-2">
                    <div className="d-flex justify-content-between">
                        <FormLabel>
                            Employer Details:
                        </FormLabel>
                        <InfoBubble
                            showBubble
                        />
                    </div>
                    {isLoading
                        ? <Skeleton />
                        : <FormTextArea
                            value={section?.employerDetails}
                            onBlur={(newValue) => patchService("employerDetails", newValue)}
                        />}
                </div>
            </div>

            <div className="row d-flex flex-row mt-2">
                <div className="col mt-2">
                    <div className="d-flex justify-content-between">
                        <FormLabel>
                            Affordability, Budget, and Source of Funds:
                        </FormLabel>
                        <InfoBubble
                            showBubble
                        />
                    </div>
                    {isLoading
                        ? <Skeleton />
                        : <RichTextEditor
                            defaultValue={section?.affordabilityBudgetSourceOfFunds.notes}
                            onBlur={(newHtmlContent) => patchNotes(newHtmlContent)}
                        />}
                </div>
            </div>

            <SectionHeader className="py-3">
                New Property Details
            </SectionHeader>

            <div className="row">
                <div className="col-12 col-md-12 col-lg-6 col-xxl-7">
                    {isLoading
                        ? <Skeleton />
                        : <FormTextArea
                            label="Property:"
                            value={section.property}
                            onBlur={(newValue) => patchService("property", newValue)}
                        />}
                </div>
                <div className="col-12 col-md-7 col-lg-3 mt-2 mt-lg-0">
                    {isLoading
                        ? <Skeleton />
                        : <FormSelect
                            label="Type:"
                            options={propertyTypes}
                            defaultValue={section?.propertyType}
                            onBlur={(selection) => patchService("propertyType", selection.value)}
                        />}
                </div>
                <div className="col-12 col-md-5 col-lg-3 col-xxl-2 mt-2 mt-lg-0">
                    {isLoading
                        ? <Skeleton />
                        : <DateInput
                            label="Completion Date:"
                            value={section?.completionDate}
                            isClearable
                            onBlur={(newValue) => patchService("completionDate", newValue)}
                        />}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-6 col-lg-2">
                    {isLoading
                        ? <Skeleton />
                        : <CurrencyInput
                            label="Value:"
                            value={section?.value}
                            decimalScale={0}
                            fixedDecimalScale
                            onBlur={(currentValue) => patchService("value", currentValue)}
                        />}
                </div>
                <div className="col-6 col-lg-2">
                    {isLoading
                        ? <Skeleton />
                        : <CurrencyInput
                            label="Purchase Price:"
                            value={section?.purchasePrice}
                            decimalScale={0}
                            fixedDecimalScale
                            onChange={(_, { floatValue: currentValue }) => updateRealTimePropertyValue("purchasePrice", currentValue)}
                            onBlur={(currentValue) => patchService("purchasePrice", currentValue)}
                        />}
                </div>
                <div className="col-6 col-md-4 col-lg-2">
                    {isLoading
                        ? <Skeleton />
                        : <CurrencyInput
                            label="Deposit:"
                            value={section?.deposit}
                            decimalScale={0}
                            fixedDecimalScale
                            onChange={(_, { floatValue: currentValue }) => updateRealTimePropertyValue("deposit", currentValue)}
                            onBlur={(currentValue) => patchService("deposit", currentValue)}
                        />}
                </div>
                <div className="col-6 col-md-4 col-lg-2">
                    {isLoading
                        ? <Skeleton />
                        : <CurrencyInput
                            label="Gift Amount:"
                            value={section?.giftAmount}
                            decimalScale={0}
                            fixedDecimalScale
                            onChange={(_, { floatValue: currentValue }) => updateRealTimePropertyValue("giftAmount", currentValue)}
                            onBlur={(currentValue) => patchService("giftAmount", currentValue)}
                        />}
                </div>
                <div className="col-12 col-md-4 col-lg-2 offset-lg-2">
                    {isLoading
                        ? <Skeleton />
                        : <CurrencyInput
                            label="Loan Amount:"
                            value={realTimeLoanAmount}
                            decimalScale={0}
                            fixedDecimalScale
                            disabled
                        />}
                </div>
            </div>

            <SectionHeader className="py-3">
                Mortgage Objectives and Key Considerations for Suitability
            </SectionHeader>

            <div className="row">
                <div className="col-9 col-md-10 col-lg-4">
                    {isLoading
                        ? <Skeleton />
                        : <FormSelect
                            label="Mortgage Type:"
                            options={mortgageTypes}
                            defaultValue={section?.mortgageType}
                            onBlur={(selection) => patchService("mortgageType", selection.value)}
                        />}
                </div>
                <div className="col-3 col-md-2 col-lg-2 col-xxl-1">
                    {isLoading
                        ? <Skeleton />
                        : <FormInput
                            label="Term:"
                            value={section?.term}
                            onBlur={(newValue) => patchService("term", newValue)}
                        />}
                </div>
                <div className="col-12 col-lg-6 col-xxl-7 mt-2 mt-lg-0">
                    <div className="d-flex justify-content-between">
                        <FormLabel>
                            Reason:
                        </FormLabel>
                        <InfoBubble
                            showBubble
                        />
                    </div>
                    {isLoading
                        ? <Skeleton />
                        : <FormTextArea
                            value={section?.reason}
                            onBlur={(newHtmlContent) => patchService("reason", newHtmlContent)}
                        />}
                </div>
            </div>

            <div className="row d-flex flex-row mt-2">
                <div className="col mt-2">
                    <div className="d-flex justify-content-between">
                        <FormLabel>
                            Interest Rate Preference:
                        </FormLabel>
                        <InfoBubble
                            showBubble
                        />
                    </div>
                    {isLoading
                        ? <Skeleton />
                        : <FormTextArea
                            value={section?.interestRatePreferences}
                            onBlur={(newHtmlContent) => patchService("interestRatePreferences", newHtmlContent)}
                        />}
                </div>
            </div>

            <div className="row d-flex flex-row mt-2">
                <div className="col mt-2">
                    <div className="d-flex justify-content-between">
                        <FormLabel>
                            Specific Requirements:
                        </FormLabel>
                        <InfoBubble
                            showBubble
                        />
                    </div>
                    {isLoading
                        ? <Skeleton />
                        : <RichTextEditor
                            value={section?.specificRequirements}
                            onBlur={(newHtmlContent) => patchService("specificRequirements", newHtmlContent)}
                        />}
                </div>
            </div>

            <GridWrapper className="mt-3" gridTemplateColumns={'auto auto auto 1fr auto'}>
                <FormLabel className="my-auto">
                    Is the Client Increasing Borrowing?
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <BooleanSelect
                        className="my-auto"
                        defaultValue={section?.isIncreasingBorrowing}
                        onBlur={(selection) => patchService("isIncreasingBorrowing", selection.value)}
                    />}
                <FormLabel className="my-auto">
                    Reason:
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <FormTextArea
                        className="my-auto"
                        value={section?.increasingBorrowingReason}
                        onBlur={(newValue) => patchService("increasingBorrowingReason", newValue)}
                    />}
                <InfoBubble
                    showBubble
                />

                <FormLabel className="my-auto">
                    Add Fees to Loan Amount?
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <BooleanSelect
                        className="my-auto"
                        defaultValue={section?.addFeesToLoanAmount}
                        onBlur={(selection) => patchService("addFeesToLoanAmount", selection.value)}
                    />}
                <FormLabel className="my-auto">
                    Details:
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <FormTextArea
                        className="my-auto"
                        value={section?.addFeesToLoanAmountDetails}
                        onBlur={(newValue) => patchService("addFeesToLoanAmountDetails", newValue)}
                    />}
                <InfoBubble
                    showBubble
                />

                <FormLabel className="my-auto">
                    Any Plans to Repay Early?
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <BooleanSelect
                        className="my-auto"
                        defaultValue={section?.plansToRepayEarly}
                        onBlur={(selection) => patchService("plansToRepayEarly", selection.value)}
                    />}
                <FormLabel className="my-auto">
                    Details:
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <FormTextArea
                        className="my-auto"
                        value={section?.plansToRepayEarlyDetails}
                        onBlur={(newValue) => patchService("plansToRepayEarlyDetails", newValue)}
                    />}
                <InfoBubble
                    showBubble
                />

                <FormLabel className="my-auto">
                    Any Plans to Move During Term?
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <BooleanSelect
                        className="my-auto"
                        defaultValue={section?.plansToMoveDuringTerm}
                        onBlur={(selection) => patchService("plansToMoveDuringTerm", selection.value)}
                    />}
                <FormLabel className="my-auto">
                    Details:
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <FormTextArea
                        className="my-auto"
                        value={section?.plansToMoveDuringTermDetails}
                        onBlur={(newValue) => patchService("plansToMoveDuringTermDetails", newValue)}
                    />}
                <InfoBubble
                    showBubble
                />

                <FormLabel className="my-auto">
                    Any Credit Issues?
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <BooleanSelect
                        className="my-auto"
                        defaultValue={section?.creditIssues}
                        onBlur={(selection) => patchService("creditIssues", selection.value)}
                    />}
                <FormLabel className="my-auto">
                    Details:
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <FormTextArea
                        className="my-auto"
                        value={section?.creditIssuesDetails}
                        onBlur={(newValue) => patchService("creditIssuesDetails", newValue)}
                    />}
                <InfoBubble
                    showBubble
                />

                <FormLabel className="my-auto">
                    Any Help to Buy Schemes?
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <BooleanSelect
                        className="my-auto"
                        defaultValue={section?.helpToBuySchemes}
                        onBlur={(selection) => patchService("helpToBuySchemes", selection.value)}
                    />}
                <FormLabel className="my-auto">
                    Details:
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <FormTextArea
                        className="my-auto"
                        value={section?.helpToBuySchemesDetails}
                        onBlur={(newValue) => patchService("helpToBuySchemesDetails", newValue)}
                    />}
                <InfoBubble
                    showBubble
                />
            </GridWrapper>
        </div>
}

export default NewMortgageApplicationSection;
import React from "react";
import TableColumnHeader from "./components/TableColumnHeader";
import TableRow from "./components/TableRow";
import useClientDashboardLoginsData from "./hooks/ClientDashboardLoginsContext";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";

const ClientDashboardLoginsTable = () => {
    const { 
        clear: refreshTable,
        data,
        error,
        isNextPageLoading,
        isNewRecords,
        isRowLoaded,
        loadMore,
        newRecordsSinceCache,
        sort,
        sortTable,
        totalCount
    } = useClientDashboardLoginsData();
    const isNewRecordsDisplayed = isNewRecords && !isNextPageLoading;

    return (
        <React.Fragment>
            <VirtualTable 
                enableScrollTracking={false}
                row={TableRow}
                header={(
                    <thead>
                        <tr>
                            <TableColumnHeader property="username" sort={sort} sortTable={sortTable}>Username</TableColumnHeader>
                            <TableColumnHeader property="emailAddress" sort={sort} sortTable={sortTable}>Email</TableColumnHeader>
                            <TableColumnHeader property="isActive" sort={sort} sortTable={sortTable}>Access Level</TableColumnHeader>
                            <TableColumnHeader property="hasRegistered" sort={sort} sortTable={sortTable}>Registered</TableColumnHeader>
                            <TableColumnHeader property="mfaType" sort={sort} sortTable={sortTable}>2FA Type</TableColumnHeader>
                            <TableColumnHeader property="isActive" sort={sort} sortTable={sortTable}>Active</TableColumnHeader>
                            <TableColumnHeader property="isLocked" sort={sort} sortTable={sortTable}>Locked</TableColumnHeader>
                            <TableColumnHeader property="isOnline" sort={sort} sortTable={sortTable}>Online</TableColumnHeader>
                            <th className="sticky-header">&nbsp;</th>
                        </tr>
                    </thead>
                )}
                footer={
                    <TableRecordCountDisplayFooter 
                        totalCount={totalCount}
                        isNewRecordsDisplayed={isNewRecordsDisplayed}
                        newRecordsSinceCache={newRecordsSinceCache}
                        reload={refreshTable}
                    />
                }
                error={error}
                isLoading={isNextPageLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                itemCount={totalCount}
                itemData={data}
            />
        </React.Fragment>
    );
};

//export default withProviders(ClientDashboardLoginsProvider)(ClientDashboardLoginsTable);
export default ClientDashboardLoginsTable;
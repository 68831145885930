import { useCallback, useEffect, useMemo } from "react";
import { useLazyFetchClientIncomeDetailsQuery } from "../../../services/clientfactfind";
import { useIncomeDetails } from "../contexts/IncomeDetailsContext";

const useIncomeColumn = (clientId) => {
    const [{
        patchCache: patchSectionCache,
        patchService: patchSectionService,
        patchClientCache,
        patchClientService,
        updateNetMonthlyIncome
    }, {
        section,
        isLoading: sectionIsLoading
    }] = useIncomeDetails();

    const [fetchClient, { data, isLoading: clientIsLoading, isFetching: clientIsFetching }] = useLazyFetchClientIncomeDetailsQuery();

    const client = useMemo(() => clientId === 'joint'
        ? section
        : data, [clientId, data, section]);
    const isLoading = useMemo(() => clientId === 'joint'
        ? sectionIsLoading
        : (clientIsLoading || clientIsFetching), [clientId, clientIsFetching, clientIsLoading, sectionIsLoading]);

    useEffect(() => {
        if (clientId !== 'joint')
            fetchClient({ clientIncomeId: clientId });
    }, [clientId, fetchClient])

    const calculateTaxableBenefits = useCallback(() =>
        (client?.grossSalary || 0)
        - (client?.salarySacrifice || 0)
        + (client?.grossBonus || 0)
        + (client?.carAllowance || 0)
        + (client?.otherTaxableBenefits || 0), [client?.carAllowance, client?.grossBonus, client?.grossSalary, client?.otherTaxableBenefits, client?.salarySacrifice]);

    const calculateOtherIncome = useCallback(() =>
        (client?.investmentIncome || 0)
        + (client?.interest || 0)
        + (client?.dividends || 0)
        + (client?.rentalIncome || 0)
        + (client?.statePensionBenefits || 0)
        + (client?.otherPensionsGross || 0), [client?.dividends, client?.interest, client?.investmentIncome, client?.otherPensionsGross, client?.rentalIncome, client?.statePensionBenefits]);

    const calculateMonthlyIncome = useCallback(() =>
        (client?.netSalary || 0)
        + (client?.netPensions || 0)
        + (client?.netStatePensions || 0)
        + (client?.netInvestmentIncome || 0)
        + (client?.netRentalIncome || 0)
        + (client?.netOther || 0), [client?.netInvestmentIncome, client?.netOther, client?.netPensions, client?.netRentalIncome, client?.netSalary, client?.netStatePensions])

    useEffect(() => {
        const timeout = setTimeout(() => {
            const newTaxableBenefits = calculateTaxableBenefits() || 0;
            const newOtherIncome = calculateOtherIncome() || 0;
            const newMonthlyIncome = calculateMonthlyIncome() || 0;

            // All these fields that start with "total" are not recorded in the database, they are purely for frontend calculating
            if (clientId !== 'joint') {
                patchClientCache(clientId, "totalTaxableBenefits", newTaxableBenefits);
                patchClientCache(clientId, "totalOtherIncome", newOtherIncome)
                patchClientCache(clientId, "totalGrossIncome", newTaxableBenefits + newOtherIncome);

                patchClientCache(clientId, "totalMonthlyIncome", newMonthlyIncome);
            } else {
                patchSectionCache("totalTaxableBenefits", newTaxableBenefits);
                patchSectionCache("totalOtherIncome", newOtherIncome)
                patchSectionCache("totalGrossIncome", newTaxableBenefits + newOtherIncome);

                patchSectionCache("totalMonthlyIncome", newMonthlyIncome);
            }

            updateNetMonthlyIncome(clientId, newMonthlyIncome);
        }, 50);

        return () => clearTimeout(timeout);
    }, [calculateMonthlyIncome, calculateOtherIncome, calculateTaxableBenefits, client?.totalMonthlyIncome, clientId, patchClientCache, patchSectionCache, section?.monthlyNetIncome, section?.totalMonthlyIncome, updateNetMonthlyIncome]);

    const patchCache = useCallback((property, newValue) => clientId !== 'joint'
        ? patchClientCache(clientId, property, newValue)
        : patchSectionCache(property, newValue), [clientId, patchClientCache, patchSectionCache]);

    const patchService = useCallback((property, newValue) => clientId !== 'joint'
        ? patchClientService(clientId, property, newValue)
        : patchSectionService(property, newValue), [clientId, patchClientService, patchSectionService]);

    return [{
        patchCache,
        patchService,
    }, {
        client,
        isLoading
    }]
}

export default useIncomeColumn;
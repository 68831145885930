import React from "react";
import FormSelect from "../FormSelect";
import { useFetchReportCategoriesQuery } from "../../../services/clientreports";

const ReportCategoriesSelect = ({ options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchReportCategoriesQuery({ fetchType: 'List' });
    const { results } = data || { results: [] }
    
    return <FormSelect
        options={results}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default ReportCategoriesSelect;
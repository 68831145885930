import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { useWrappersManagement } from "../hooks/WrappersManagementContext";

const WrapperTableErrorRow = ({ ...rest }) => {

    const { error, retryFetchWrappers, columns } = useWrappersManagement();

    return <tr {...rest}>
        <td colSpan={columns.length}>
            <ErrorMessage error={error} retryCallback={retryFetchWrappers} />
        </td>
    </tr>
};

export default WrapperTableErrorRow;
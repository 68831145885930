export const messageCategoryOptions = [
    {
        label: "System",
        value: 0
    },
    {
        label: "Staff",
        value: 1
    },
    {
        label: "Client",
        value: 2
    },
];

export const messagePriorityOptions = [
    {
        label: "Low",
        value: 10
    },
    {
        label: "Medium",
        value: 20
    },
    {
        label: "High",
        value: 30
    }
];

export const messageTypeOptions = [

    {
        label: "Appointment Tracking",
        value: 0
    },
    {
        label: "Report Tracking",
        value: 1
    },
    {
        label: "Business Sheet Tracking",
        value: 2
    },
    {
        label: "Other",
        value: 3
    },
];
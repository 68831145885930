import { create } from "lodash";
import { wealthProApi } from "../../redux/api";
import { applyOperation, applyPatch } from "fast-json-patch";

const hoAdminRemindersApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['reminders']
}).injectEndpoints({
    endpoints: (builder) => ({
        searchReminders: builder.query({
            query: (body) => ({
                url: `api/headofficeadmin/reminders/query`,
                method: "POST",
                body
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            },
            providesTags: ['reminders']
        }),
        createReminder: builder.mutation({
            query: ({ data }) => ({
                url: `api/headofficeadmin/reminders`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['applicationReminders']
        }),
        deleteReminder: builder.mutation({
            query: ({ reminderId }) => ({
                url: `api/headofficeadmin/reminders/${reminderId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ reminderId, applicationId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchReminders', { applicationId }, (data) => {
                        data = data.filter(el => el.reminderId !== reminderId);
                        return data;
                    }));
                }
                catch { }
            }
        }),
        patchReminder: builder.mutation({
            query: ({ reminderId, operations }) => ({
                url: `api/headofficeadmin/reminders/${reminderId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ reminderId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('searchReminders', {}, ({ results }) => {
                        let item = results.find(x => x.reminderId === reminderId);
                        Object.assign(item, data);
                    }));
                }
                catch { }
            },
            invalidatesTags: ['applicationReminders']
        })
    })
});

export const {
    useLazySearchRemindersQuery,
    useCreateReminderMutation,
    useDeleteReminderMutation,
    usePatchReminderMutation
} = hoAdminRemindersApiEndpoints;
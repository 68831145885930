import React from "react";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import { useRemindersGrid } from "../hooks/RemindersGridContext";
import RemindersGridRow from "./RemindersGridRow";
import RemindersGridHeader from "./RemindersGridHeader";
import { Main } from "../../../components/utilities";

const RemindersGrid = () => {

    const { results, totalCount, isItemLoaded, loadMoreItems, error, isError, retry } = useRemindersGrid();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retry} />
        </ErrorMessageWrapper>
    }

    return (
        <VirtualTable
            row={RemindersGridRow}
            header={<RemindersGridHeader />}
            itemData={results}
            itemCount={totalCount === results.length ? totalCount : results.length + 10}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            itemSize={46}
            enableScrollTracking={false}
        />
    );
};

export default RemindersGrid;
import { useEffect, useState } from "react";
import { useClientMessaging } from "./ClientMessagingContext";

const useMessageList = (lazyFetchHook, queryParams = {}) => {
    const limit = 25;
    const { filters } = useClientMessaging();
    const [fetch, fetchResult] = lazyFetchHook();
    const [isClearing, setIsClearing] = useState(false);
    const { data, error, isError, isLoading, isUninitialized } = fetchResult || {};
    const { results, pagination } = data || { results: [], pagination: { totalCount: limit, page: 0 } };
    const { page, totalCount } = pagination;

    const clear = () => {
        setIsClearing(true);
        fetch({ page: 1, limit, ...queryParams, ...filters }, false)
            .unwrap()
            .then(_ => {
                setIsClearing(false);
            });
    };

    const isItemLoaded = (index) => results[index] || results.length < index;

    const loadMoreItems = () => fetch({
        page: page + 1,
        limit,
        ...queryParams,
        ...filters
    }, false);

    const retry = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        clear();
    };

    useEffect(() => {
        !isUninitialized && clear();
    }, [filters]);

    return {
        results,
        error,
        isClearing,
        isError,
        isLoading,
        totalCount: totalCount === results.length ? totalCount : results.length + 10,
        isItemLoaded,
        loadMoreItems,
        retry
    };
};

export default useMessageList;
import React from "react";
import { useLazySearchAssetsPagedQuery } from "../../../services/assets";
import FormSelectPaged from "../FormSelectPaged";

const AssetSearchPagedSelect = ({ valueObject, setValue, isMulti = false, ...rest }) => {

   return <FormSelectPaged
        setValue={setValue}
        valueObject={valueObject}
        additional={{page: 1, limit: 50, searchType: "either", fetchType: "list", sort:"AssetName" }}
        query={useLazySearchAssetsPagedQuery}
        onOptionsLoaded={(_, results) => results}
        isMulti={isMulti}
        searchTermPropertyName={"searchTerm"}
        {...rest}
    />;
};

export default AssetSearchPagedSelect;

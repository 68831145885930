import React from "react";
import FormSelect from "../../../components/forms/FormSelect";
import useProviders from "../hooks/useProviders";

const ProviderSelect = ({ providers: optionsOverride, ...rest }) => {

    const { providers, providerIsLoading, providerIsFetching } = useProviders("select");

    return <FormSelect 
        options={providers || []} 
        isLoadingOptions={providerIsLoading || providerIsFetching}
        {...rest} />
};

export default ProviderSelect;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../components";
import { FormCheck, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { Spinner } from "../../../components/loaders";
import { AttachmentsToggle } from "../../../components/utilities";
import { useClientMessaging } from "../hooks/ClientMessagingContext";
import useThread from "../hooks/useThread";
import { useEffect, useId, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

const ClientMessagesHeader = () => {
    const subjectInputId = useId();

    const {
        createMessage,
        createMessageResult: { isLoading: isCreating },
        hideSystemMessages,
        selectedViewType,
        setHideSystemMessages,
        setSelectedViewType,
        setShowMyMessages,
        showMyMessages,
        showThreads,
        threadId,
        viewTypeOptions
    } = useClientMessaging();

    const { data, isFetching: isLoadingThread, patchThread } = useThread(threadId);
    const { subject, threadAttachments } = data || { threadAttachments: [] };

    return <div className="row flex-fill mx-0">
        <div className="col-3 d-flex align-items-center">
            <FormLabel className={"me-4 mb-0"}>View</FormLabel>
            <div className="flex-grow-1">
                <FormSelect
                    defaultValue={selectedViewType}
                    options={viewTypeOptions}
                    onChange={({ value }) => setSelectedViewType(value)}
                />
            </div>
        </div>
        <div className="col-auto ms-3 d-flex align-items-center">
            <FormCheck
                label={"Show My Messages"}
                isChecked={showMyMessages}
                onChange={(value, _) => setShowMyMessages(value)}
                disableAnimations
            />
        </div>
        <div className="col-auto ms-3 d-flex align-items-center">
            <FormCheck
                label={"Hide System Messages"}
                isChecked={hideSystemMessages}
                onChange={(value, _) => setHideSystemMessages(value)}
                disableAnimations
            />
        </div>
        {threadId && showThreads && !isLoadingThread && <div className="col">
            <FormInput
                // can't pass ref
                id={subjectInputId}
                placeholder="No subject"
                value={subject ?? ""}
                onBlur={(value) => patchThread("subject", value)}
            />
        </div>}
        {isLoadingThread && (
            <div className="col">
                <Skeleton height={32} />
            </div>
        )}
        {Array.isArray(threadAttachments) && !isLoadingThread && threadAttachments.length > 0 && (
            <div className="col-auto">
                <AttachmentsToggle attachments={threadAttachments} menuDirection="start" />
            </div>
        )}
        <div className="col-auto ms-auto">
            {(!showThreads || (showThreads && threadId)) && (
                <Button disabled={isCreating} variant="success" onClick={() => createMessage({ threadId })}>
                    <span className="me-2">New Message</span>
                    <FontAwesomeIcon icon="plus" />
                </Button>
            )}
        </div>
    </div>;
};

export default ClientMessagesHeader;
import { ThemedTableHeader } from "../../../components/tables/StandardTable";

const ApplicationDocumentsGridHeader = () => {


    return <thead>
        <tr>
            <ThemedTableHeader/>
            <ThemedTableHeader>File Name</ThemedTableHeader>
            <ThemedTableHeader>File Size</ThemedTableHeader>
            <ThemedTableHeader>User</ThemedTableHeader>
            <ThemedTableHeader>Description</ThemedTableHeader>
            <ThemedTableHeader>Uploaded Date</ThemedTableHeader>
        </tr>
    </thead>

};

export default ApplicationDocumentsGridHeader;
import React from "react";
import { Spinner } from "../../../components/loaders";
import { CenterPage } from "../../../components/utilities";

const LoadingBar = () => {
    return <CenterPage>
        <Spinner />
    </CenterPage>
};

export default LoadingBar;
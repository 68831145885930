import React from "react";
import FormSelect from "../FormSelect";

export const MediaTypes = [
    { label: 'Email', value: 0 },
    { label: 'Post', value: 1 },
    { label: 'Client Dashboard', value: 2 }
];

const MediaTypeSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={MediaTypes.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default MediaTypeSelect;
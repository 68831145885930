import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const wrapperApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['wrappers']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchWrappers: builder.query({
            query: ({ page, limit, fetchType = 0, sort = "DisplayOrder" }) => {
                if (page && limit) {
                    return {
                        url: `api/products/wrapper?page=${page}&limit=${limit}&fetchType=${fetchType}&sort=${sort}`
                    }
                }
                return {
                    url: `api/products/wrapper?fetchType=${fetchType}&sort=${sort}`
                }
            }
        }),
        fetchWrappersForGrid: builder.query({
            query: ({ sort = "Description" }) => `api/products/wrapper/grid?sort=${sort}`,
            serializeQueryArgs: ({ endpointName }) => endpointName
        }),
        createWrapper: builder.mutation({
            query: ({ }) => ({
                url: 'api/products/wrapper',
                method: 'POST',
                body: {}
            }),
            async onQueryStarted({ }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newWrapper } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData(
                        'fetchWrappersForGrid',
                        {},
                        (results) => [newWrapper, ...results]
                    ));
                }
                catch (ex) { console.log(ex) }
            }
        }),
        deleteWrapper: builder.mutation({
            query: ({ wrapperId }) => ({
                url: `api/products/wrapper/${wrapperId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ wrapperId }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData(
                    'fetchWrappersForGrid',
                    {},
                    (results) => results.filter(x => x.wrapperId !== wrapperId)
                ));

                console.log(result);

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        patchWrapper: builder.mutation({
            query: ({ wrapperId, operations }) => ({
                url: `api/products/wrapper/${wrapperId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ wrapperId, operations, assignableObject }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData(
                    'fetchWrappersForGrid',
                    {},
                    (results) => {
                        let item = results.find(x => x.wrapperId === wrapperId);
                        applyPatch(item, operations, true);

                        if (assignableObject) {
                            Object.assign(item, assignableObject);
                        }
                    }
                ));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        fetchWrapperCategories: builder.query({
            query: () => ({
                url: 'api/products/wrapper/categories'
            })
        }),
        updateWrapperInstructionMaps: builder.mutation({
            query: ({ wrapperId, instructionTypeIds }) => ({
                url: `api/products/wrapper/${wrapperId}/instructionTypes`,
                method: 'POST',
                body: {
                    instructionTypeIds
                }
            }),
            async onQueryStarted({ wrapperId, instructionTypes }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData(
                    'fetchWrappersForGrid',
                    {},
                    (results) => {
                        let item = results.find(x => x.wrapperId === wrapperId);
                        item.instructionTypeMappings = instructionTypes;
                    }
                ));
                try {
                    await queryFulfilled
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useCreateWrapperMutation,
    useDeleteWrapperMutation,
    usePatchWrapperMutation,
    useFetchWrappersForGridQuery,
    useLazyFetchWrappersForGridQuery,
    useFetchWrappersQuery,
    useLazyFetchWrappersQuery,
    useLazyFetchWrapperCategoriesQuery,
    useFetchWrapperCategoriesQuery,
    useUpdateWrapperInstructionMapsMutation
} = wrapperApiEndpoints;
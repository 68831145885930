import React from "react";
import FormSelect from "../FormSelect";
import { useFetchCampaignListQuery } from "../../../services/campaignmanagement";

const CampaignSelect = ({ options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchCampaignListQuery();
    const { results } = data || { results: [] }
    return <FormSelect
        options={results}
        noOptionsMessage={() => "There are no Campaigns available."}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default CampaignSelect;
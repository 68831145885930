import React, { useEffect, useState } from "react";
import { useLazySearchUserMessagesQuery } from "../services/messages";
import { useFilterContext } from "./FilterContext";
import { useAuth } from "react-oidc-context";
import _ from "lodash";

const MessageManagementContext = React.createContext();

export const useMessageManagementContext = () => React.useContext(MessageManagementContext);

const MessageManagementProvider = ({ children }) => {
    const { user: { profile } } = useAuth();
    const currentUserId = profile.sub.replace(/-/g, "");

    const defaultFilter = {
        category: null,
        direction: 2,
        deadlineDateFrom: null,
        deadlineDateTo: null,
        masterAccount: null,
        priorities: [],
        recipients: [{ label: profile.name, value: currentUserId }],
        reminderDateFrom: null,
        reminderDateTo: null,
        senders: [],
        sentDateFrom: null,
        sentDateTo: null,
        showDone: false,
        showHiddenByUser: false,
        subject: null,
        type: null,
        userPriorities: []
    };

    const [filter, setFilter] = useState(defaultFilter);

    const [search, searchResult] = useLazySearchUserMessagesQuery();

    const [isClearing, setIsClearing] = useState(false);
    const [selectedThreadId, setSelectedThreadId] = useState(null);
    const [userId, setUserId] = useState(currentUserId);
    const [sort, setSort] = useState("SentStatus,SentOnDate+DESC");
    const [sortProperty, sortDirection] = sort.split("+");
    const limit = 50;

    const { data, isUninitialized, error, isError, isLoading, isFetching } = searchResult || {};
    const { results, hasMore, page } = data || { page: 0, results: [], hasMore: true };

    const clear = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        if (isUninitialized) return;
        setIsClearing(true);

        search({
            userId, request: {
                page: 1,
                limit,
                sort,
                masterAccountId: filter.masterAccount?.value,
                recipientIds: filter.recipients?.map(x => x.value),
                senderIds: filter.senders?.map(x => x.value),
                ...filter
            }
        })
        .unwrap().finally(() => {
            setIsClearing(false);
        });
    };

    const clearFilter = (e) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }
        setFilter((_) => defaultFilter);
    };

    const closeThreadModal = () => setSelectedThreadId(null);

    const hasItemLoaded = (index) => index < results.length || results[index];

    const loadMoreItems = () => search({
        userId,
        request: {
            page: page + 1,
            limit,
            sort,
            masterAccountId: filter.masterAccount?.value,
            recipientIds: filter.recipients?.map(x => x.value),
            senderIds: filter.senders?.map(x => x.value),
            ...filter
        }
    });

    const onClickSort = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = sortDirection === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    const setNewFilter = ({ newFilter }) => {
        setFilter(newFilter);
    };

    const columns = [
        {
            label: "Sent Date",
            property: "sentOnDate"
        },
        {
            label: "Sender",
            property: "senderName"
        },
        {
            label: "Account",
            property: "masterAccountName"
        },
        {
            label: "Subject",
            property: "subject"
        },
        {
            label: "Status",
            property: "statusText"
        },
        {
            label: "Message",
            property: "message"
        },
        {
            label: "Priority",
            property: "messagePriority",
        },
        {
            label: "User Priority",
            property: "userPriority",
        },
        {
            label: "Deadline",
            property: "deadlineDate"
        },
        {
            label: "Remind On",
            property: "userReminderDate"
        },
        {
            label: "Done",
            property: "isDone"
        },
    ];

    useEffect(clear, [filter, sort, userId]);

    return <MessageManagementContext.Provider value={{
        clear,
        clearFilter,
        closeThreadModal,
        hasItemLoaded,
        loadMoreItems,
        onClickSort,
        setFilter,
        setNewFilter,
        setSelectedThreadId,
        setUserId,
        columns,
        defaultFilters: defaultFilter,
        error,
        filter,
        isClearing,
        isError,
        isFiltersApplied: !_.isEqual(filter, defaultFilter),
        isLoading,
        isFetching,
        results,
        selectedThreadId,
        sortDirection,
        sortProperty,
        totalCount: hasMore ? results.length + 25 : results.length,
        userId,
    }}>
        {children}
    </MessageManagementContext.Provider>
};

export default MessageManagementProvider;


import React from "react";
import styled from "styled-components";
import { variantToColour } from "../../helpers/theme";

const ErrorCodeWrapper = styled.span`
    font-weight: bold;
    font-size: ${props => props.size ?? "12rem"};
    color: ${props => variantToColour({ theme: props.theme, variant: 'muted' })}
`;

const ErrorCode = ({ children, size, theme }) => <ErrorCodeWrapper size={size} theme={theme}>{children}</ErrorCodeWrapper>

export default ErrorCode;
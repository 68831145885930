import { Spinner } from "../../../components/loaders";
import { StandardTable } from "../../../components/tables";
import { CenterPage, Main } from "../../../components/utilities";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import MissingAccountsGridErrorRow from "./MissingAccountsErrorRow";
import MissingAccountsGridRow from "./MissingAccountsGridRow";

const MissingAccountsGrid = () => {
    const { potentialAccountMatches } = useCustodianTransactionMatching();
    const {
        data,
        isLoading,
        isError,
        error
    } = potentialAccountMatches;

    const columns = [
        {
            label: "WP Account"
        },
        {
            label: "Designation"
        },
        {
            label: "Custodian"
        },
        {
            label: "Platform Account"
        },
        {
            label: "Wrapper"
        },
        {
            label: "Product"
        },
        {
            label: "Provider Ref"
        },
        {
            label: "Date Added"
        },
        {
            label: "Score"
        }
    ];

    return (
        <StandardTable
            className="mb-5 mt-3"
            columns={columns}
            data={data}
            error={error}
            isLoading={isLoading}
            ErrorRowComponent={MissingAccountsGridErrorRow}
            RowComponent={MissingAccountsGridRow}
        />
    );
};

export default MissingAccountsGrid;
import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../../components/buttons";
import { AdviceTypesSelect, ClientProjectStatusSelect, GenericAdviserSelect, GenericUserSelect } from "../../components/forms/selects";
import { FormInput, DateInput, CurrencyInput } from "../../components/forms";
import { ThemedParagraph } from "../../components/utilities";
import { useClientContext } from "../../hooks/ClientContext";
import { useClientProjectContext } from "../../hooks/ClientProjectContext";
import useNewProject from "./hooks/useNewProject";

export const CreateProjectModalButton = ({ className, variant = "success", ...rest }) => {
    return <div className={className}>
        <Button variant={variant} {...rest}>Create new Project</Button>
    </div>
};

const CreateProjectModal = ({ handleClose, onProjectCreated, show, ...rest }) => {
    const { account: { divisionId, masterAccountId, adviserId: masterAccountAdviserId } } = useClientContext();
    const { clear: clearProjectList } = useClientProjectContext();
    const {
        project: {
            description,
            comment,
            adviceType,
            status,
            adviserId,
            driverId,
            targetDate,
            projectValue
        },
        clearProject,
        createErrorMessage,
        createProject,
        isCreating,
        isErrorCreating,
        updateProject,
    } = useNewProject(masterAccountId, masterAccountAdviserId);

    const onCreateProjectClicked = (e) => {
        createProject(e).then(
            (project) => {
                if (onProjectCreated && typeof (onProjectCreated) === 'function') {
                    onProjectCreated(project);
                }
                clearProject();
                clearProjectList();
                handleClose();
            },
            e => console.log('there was a problem', e)
        );
    };

    const onFieldChanged = (property, value) => updateProject(property, value);

    return <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Create a New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row mb-3">
                <div className="col">
                    <FormInput
                        label="Description"
                        value={description}
                        disableAnimations={true}
                        onBlur={(value) => onFieldChanged("description", value)}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <FormInput
                        label="Comment"
                        value={comment}
                        disableAnimations={true}
                        onBlur={(value) => onFieldChanged("comment", value)}
                    />
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
                <div className="col">
                    <AdviceTypesSelect
                        label="Advice Type"
                        defaultValue={adviceType}
                        disableAnimations={true}
                        placeholder="Project advice type"
                        onBlur={(values) => onFieldChanged('adviceType', values.value)}
                    />
                </div>
                <div className="col">
                    <ClientProjectStatusSelect
                        label="Project Status"
                        defaultValue={status}
                        disableAnimations={true}
                        placeholder="Project Status"
                        onBlur={(values) => onFieldChanged('status', values.value)}
                    />
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
                <div className="col">
                    <DateInput
                        label="Target Date"
                        value={targetDate}
                        disableAnimations={true}
                        onBlur={(value) => onFieldChanged("targetDate", value)}
                    />
                </div>
                <div className="col">
                    <CurrencyInput
                        label="Revenue"
                        value={projectValue}
                        disableAnimations={true}
                        onBlur={(value) => onFieldChanged("projectValue", value)}
                    />
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
                <div className="col">
                    <GenericAdviserSelect
                        label="Adviser"
                        defaultValue={adviserId}
                        divisionId={divisionId}
                        disableAnimations={true}
                        placeholder="Adviser for the Project"
                        onBlur={(values) => onFieldChanged('adviserId', values.value)}
                    />
                </div>
                <div className="col">
                    <GenericUserSelect
                        label="Driver"
                        defaultValue={driverId}
                        divisionId={divisionId}
                        disableAnimations={true}
                        placeholder="Driver for the Project"
                        onBlur={(values) => onFieldChanged('driverId', values.value)}
                    />
                </div>
            </div>
            {isErrorCreating && (
                <div className="row">
                    <ThemedParagraph className="m-0" variant="danger">Unable to Complete Request. {createErrorMessage}</ThemedParagraph>
                </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" disabled={isCreating} onClick={onCreateProjectClicked}>Create Project</Button>
            <Button variant="light" disabled={isCreating} onClick={handleClose}>Close</Button>
        </Modal.Footer>
    </Modal>
}

export default CreateProjectModal;

/*
TODO: we will add campaigns when the microservice that deals with it is completed. Keep it hidden as it's useless for now!
<Row>
    <Col>
        <FormSelect
            label="Campaign:"
            value={campaignId}
            disableAnimations={true}
            onBlur={(selection) => onFieldChanged("campaignId", selection.value)}
        />
    </Col>
    <Col>
        <FormSelect
            label="Campaign Status:"
            value={campaignStatusId}
            disableAnimations={true}
            onBlur={(selection) => onFieldChanged("campaignStatusId", selection.value)}
        />
    </Col>
</Row>
*/
import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Link = styled(NavLink)``;

const ListBoxLink = ({ href, to, children, ...rest }) => {
    if (href && typeof(href) === 'string') {
        return <li>
            <a href={href} {...rest}>{children}</a>
        </li>
    }

    return <li>
        <Link to={to} {...rest}>{children}</Link>
    </li>
};

export default ListBoxLink;
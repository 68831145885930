import { useLazyFetchReportAuthorGroupingQuery } from "../../../services/clientreports";

const useReportsGroupedByAuthor = (parameters) => {
    const [search, searchResult] = useLazyFetchReportAuthorGroupingQuery()

    const onLoadOptions = () => search(parameters);

    return { search: onLoadOptions, ...searchResult };
};

export default useReportsGroupedByAuthor;
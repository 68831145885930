import React from "react";
import FormSelect from "../../../components/forms/FormSelect";

export const Statuses = [
    { label: 'Awaiting Appointment', value: 0 },
    { label: 'To Be Updated', value: 1 },
    { label: 'Updated', value: 2 },
    { label: 'Cancelled', value: 3 },
];

const FFMeetingNotesStatusSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={Statuses}
        {...rest}
    />
};

export default FFMeetingNotesStatusSelect;
import React from "react";
import withProviders from "../../hooks/withProviders";
import ClientDashboardLoginCreateModal from "../../features/clientdashboardlogincreatemodal";
import ClientDashboardLoginDetailModal from "../../features/clientdashboardlogindetailsmodal";
import ClientDashboardLoginsTable from "../../features/clientdashboardloginstable";
import useClientDashboardLoginsData, { ClientDashboardLoginsProvider } from "../../features/clientdashboardloginstable/hooks/ClientDashboardLoginsContext";
import useClientDashboardLoginDetails, { ClientDashboardLoginDetailsProvider } from "../../features/clientdashboardlogindetailsmodal/hooks/ClientDashboardLoginDetailsContext";
import { Button } from "../../components";
import { FormInput } from "../../components/forms";
import { ClientLayoutPageSubHeader } from "../../components/utilities";

const ClientDashboardLoginsPage = () => {
    const { clear, masterAccountId, searchTerm, setSearchTerm } = useClientDashboardLoginsData();
    const { clear: stopEditing, isModalShown: isEditingDashboardLogin } = useClientDashboardLoginDetails();
    const [isCreateShown, setIsCreateShown] = React.useState(false);
    const [formSearchTerm, setFormSearchTerm] = React.useState(searchTerm);

    const onLoginCreated = () => clear();

    const onLoginEdited = () => clear();

    React.useEffect(() => {
        const updateFilters = setTimeout(() => {
            setSearchTerm(_ => formSearchTerm);
        }, 500);
        return () => clearTimeout(updateFilters);
    }, [ formSearchTerm ]);

    return (
        <React.Fragment>
            <ClientDashboardLoginCreateModal
                masterAccountId={masterAccountId} 
                show={isCreateShown}
                onLoginCreated={onLoginCreated}
                handleClose={() => setIsCreateShown(false)}
            />
            <ClientDashboardLoginDetailModal 
                masterAccountId={masterAccountId}
                show={isEditingDashboardLogin}
                onLoginEdited={onLoginEdited}
                handleClose={(isDirty) => {
                    stopEditing();
                    if (isDirty === true) {
                        onLoginEdited();
                    }
                }}
            />
            <ClientLayoutPageSubHeader>
                <div className="d-flex justify-content-end align-items-center">
                    <FormInput
                        placeholder="Search for a login."
                        value={formSearchTerm}
                        disableAnimations={true}
                        onChange={(e) => setFormSearchTerm(e.target.value)}
                    />
                    <Button variant="success" className="ms-2" onClick={() => setIsCreateShown(_ => true)}>Create new Login</Button>
                </div>
            </ClientLayoutPageSubHeader>
            <section className="h-100 my-3 px-4">
                <ClientDashboardLoginsTable />
            </section>
        </React.Fragment>
    );
};

export default withProviders(ClientDashboardLoginsProvider, ClientDashboardLoginDetailsProvider)(ClientDashboardLoginsPage);
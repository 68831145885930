import React from "react";
import ProfileInfo from "./ProfileInfo";
import { Offcanvas } from "react-bootstrap";
import { useActiveUsers } from "../../../hooks/ActiveUsersContext";

// NOTE: we removed the feature hook for a overall hook/context provider to enable access to user data across the whole app

const FeatureBody = ({ isOpen, setIsOpen }) => {
    const { data, isError, isLoading, offline, online } = useActiveUsers();
    const { results } = data || { results: [] }
    return (
        <Offcanvas show={isOpen} onHide={() => setIsOpen(false)} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Users</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-0">
                {isLoading === true && (
                    <div>Loading...</div>
                )}
                {isLoading === false && isError === true && (
                    <div>ERROR</div>
                )}
                {isLoading === false && isError === false && results.length === 0 && (
                    <div>No data to display..</div>
                )}
                {isLoading === false && isError === false && results.length > 0 && (
                    <React.Fragment>
                        <section>
                            {online.length > 0 && (
                                <div className="m-0 mb-3 fs-smaller text-muted">Online</div>
                            )}
                            {online.map((el, i) => <ProfileInfo key={i} user={el} />)}
                        </section>
                        <section>
                            {offline.length > 0 && (
                                <div className="m-0 mb-3 fs-smaller text-muted">Offline</div>
                            )}
                            {offline.map((el, i) => <ProfileInfo key={i} user={el} />)}
                        </section>
                    </React.Fragment>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default FeatureBody;
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../components/buttons";
import {
    DateInput,
    FormInput,
    FormSelect
} from "../../components/forms/";
import {
    GenericAdviserSelect,
    GenericHeadOfficeUserDivisionSelect,
} from "../../components/forms/selects";
import { useFilterContext } from "../../hooks/FilterContext";

import useFilterOptions from "./hooks/useFilterOptions";

export const AdvicePendingFiltersModalButton = ({
    className,
    variant = "primary",
    ...rest
}) => {
    return (
        <div className={className}>
            <Button variant={variant} {...rest}>
                Filter Recommendations
            </Button>
        </div>
    );
};

export const AdvicePendingFiltersModal = ({
    handleClose,
    onFiltersChanged,
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter((original) => {
            return {
                ...original,
                ...tempFilters,
            };
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters((_) => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters((_) => filter);
        }
    }, [filter]);

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>Advice Pending Filters</Modal.Header>
            <Modal.Body {...rest}>
                <AdvicePendingFilters
                    filter={tempFilters}
                    setFilter={onFilterValueChanged}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>
                    Clear Filters
                </Button>
                <Button variant="success" onClick={onApplyFilters}>
                    Apply Filters
                </Button>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const AdvicePendingFilters = ({ filter, setFilter }) => {

    const { options: { status, organisation } } = useFilterOptions();

    const {
        reference,
        accountName,
        statuses,
        startDate,
        endDate,
        organisationId,
        divisionId,
        adviserIds,
    } = filter;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-3">
                    <FormInput
                        label="Reference"
                        value={reference ?? ""}
                        onChange={(event) => setFilter("reference", event.target.value)}
                    />
                </div>
                <div className="col">
                    <FormInput
                        label="Acc. Name"
                        value={accountName ?? ""}
                        onChange={(event) => setFilter("accountName", event.target.value)}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-6">
                    <FormSelect
                        label="Status"
                        defaultValue={statuses}
                        options={status}
                        onChange={(newArray) => setFilter("statuses", newArray.map(obj => obj.value))}
                        isMulti
                    />
                </div>
                <div className="col-sm-3">
                    <DateInput
                        label="Status Date from"
                        value={startDate}
                        onBlur={(newDate) => setFilter("startDate", newDate)}
                        isClearable
                        disableAnimations
                    />
                </div>
                <div className="col">
                    <DateInput
                        label="to"
                        value={endDate}
                        onBlur={(newDate) => setFilter("endDate", newDate)}
                        isClearable
                        disableAnimations
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <FormSelect
                        label="Organisation"
                        defaultValue={organisationId}
                        options={organisation}
                        onChange={(newSelection) => setFilter("organisationId", newSelection.value)}
                    />
                </div>
                <div className="col">
                    <GenericHeadOfficeUserDivisionSelect
                        label="Division"
                        defaultValue={divisionId}
                        onChange={(newSelection) => setFilter("divisionId", newSelection.value)}
                    />
                </div>
                <div className="col">
                    <GenericAdviserSelect
                        label="Adviser"
                        defaultValue={adviserIds}
                        isMulti
                        onChange={(newValues) => setFilter("adviserIds", newValues.map(x => x.value))}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default AdvicePendingFilters;

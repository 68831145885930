import React from "react";
import styled from "styled-components";
import { readableColor } from "polished";
import { Outlet } from "react-router-dom";
import { AdministrationAssetRouteTabs } from "../routes/administration.assets.routes";

const Header = styled.section`
    position: sticky;
    top: var(--navbar-size);
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

const HeaderBottom = styled.div`

`;

const HeaderTop = styled.div`
    font-size: 16px;
    align-items: center;
    margin-bottom: .75rem;
`;

const AdministrationAssetsLayout = () => (
    <React.Fragment>
        <Header>
            <HeaderTop>
                <span>Assets Database Administration</span>
            </HeaderTop>
            <HeaderBottom>
                <AdministrationAssetRouteTabs />
            </HeaderBottom>
        </Header>
        <Outlet />
    </React.Fragment>
);

export default AdministrationAssetsLayout;
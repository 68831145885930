import React from "react";
import ClientSearch from "../../features/clientsearch";
import styled from "styled-components";

const ClientSearchContainer = styled.div`
    grid-area: clientsearch;
    margin: 0 0.5rem;

    @media (max-width: 767.98px) {
        display: none !important;
    }
`;

const HeaderSearch = () => {
    return <ClientSearchContainer>
        <ClientSearch />
    </ClientSearchContainer>
};

export default HeaderSearch;
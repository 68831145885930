import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useClientContext } from "../../../hooks/ClientContext";
import {
    useCreateMessageMutation,
    useDeleteDraftMessageMutation,
    useFetchClientMessagesQuery,
    useSendClientMessageMutation,
    useToggleMessageIsDoneMutation
} from "../../../services/messages";
import { useClientMessagingContext } from "./ClientMessagingContext";

export const MessagePriorities = {
    Low: 10,
    Medium: 20,
    High: 30
};

const useAllMessages = () => {
    const { viewTypeFilters } = useClientMessagingContext();
    const { id: masterAccountId } = useClientContext();
    const { user: { profile } } = useAuth();
    const userId = profile.sub;
    const [page, setPage] = useState(1);
    const limit = 25;
    const [createMessageTrigger] = useCreateMessageMutation();
    const [deleteDraftMessageTrigger] = useDeleteDraftMessageMutation();
    const [toggleMessageIsDoneTrigger] = useToggleMessageIsDoneMutation();
    const { data, isLoading, isFetching, isError, error, refetch } = useFetchClientMessagesQuery({ masterAccountId, page, limit, ...viewTypeFilters });
    const { results, pagination } = data || {};
    const { pageSize, totalPages, totalCount } = pagination || {};

    const createMessage = ({ threadId, recipients, message, isActionRequired, messagePriority, threadSubject }) => {
        return new Promise((resolve, reject) => {
            const recipientIds = recipients.map(x => x.userId);
            return createMessageTrigger({
                threadId,
                recipientIds,
                recipients,
                masterAccountId,
                message,
                page,
                limit,
                sort: "CreatedDate+desc",
                isActionRequired,
                messagePriority,
                threadSubject,
                ...viewTypeFilters
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const deleteDraftMessage = ({ messageId }) => {
        return new Promise((resolve, reject) => {
            return deleteDraftMessageTrigger({ messageId, page, limit, masterAccountId, ...viewTypeFilters })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const toggleMessageIsDone = ({ messageId, newValue }) => {
        return new Promise((resolve, reject) => {
            return toggleMessageIsDoneTrigger({
                messageId,
                userId,
                masterAccountId,
                page,
                limit,
                newValue,
                ...viewTypeFilters
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        page,
        results: results || [],
        pageSize,
        totalPages,
        totalCount,
        isLoading,
        isFetching,
        isError,
        error,
        refetch,
        createMessage,
        deleteDraftMessage,
        setPage,
        toggleMessageIsDone
    };
};

export default useAllMessages;
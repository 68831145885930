import React, { useCallback, useEffect } from "react";
import { GridWrapper } from "../../../components";
import {
    AnnualCostsDisclosureComparisonTable,
    DivestTransactionTable,
    FeeCollection,
    Income,
    InvestmentSelection,
    ProductSelection,
    RingFence
} from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

// This instruction has an Invest and Divest
const InvestmentAccountTransfer = ({ instructionId }) => {
    const [{ fetchInvest, fetchDivest }, { investIsError, divestIsError }, { realTimeRemainder }] = useInstruction();

    useEffect(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId })
    }, [fetchInvest, fetchDivest, instructionId]);

    const retry = useCallback(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId })
    }, [fetchDivest, fetchInvest, instructionId]);

    if (investIsError || divestIsError)
        return <InvestDivestErrorMessage retry={retry} />

    return <GridWrapper>
        <ProductSelection
            productLabel="Transfer from Product"
        />

        <DivestTransactionTable isSelling={false} />

        <ProductSelection
            isInvest
            changeOwner
            productLabel="Transfer to Product"
        />

        <Income
            clientWithdrawal
            retainAmount
        // setCurrentIncomeAmount={setCurrentIncomeAmount}
        />

        <InvestmentSelection maxInvestmentAmount={realTimeRemainder} />

        <FeeCollection />
        <RingFence />

        {/* <AnnualCostsDisclosureComparisonTable /> */}
    </GridWrapper>;
};

export default InvestmentAccountTransfer;

import { useCheckIsUsernameAvailableMutation } from "../../../services/clientauth";

const useIsUsernameAvailable = () => {
    const [_check, { isLoading, ...checkResult }] = useCheckIsUsernameAvailableMutation();

    const check = (username) => {
        return new Promise((resolve, reject) => {
            return _check({ username })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { check, isLoading, data: { isLoading, ...checkResult } };
};

export default useIsUsernameAvailable;
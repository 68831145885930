import { wealthProApi } from '../../redux/api';

const productsApiEndpoints = wealthProApi.enhanceEndpoints({})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchTransactionProducts: builder.query({
            query: (listType) => `api/valuations/products${listType ? `?listType=${listType}` : ""}`
        })
    })
});

export const {
    useFetchTransactionProductsQuery,
} = productsApiEndpoints;
import { wealthProApi } from "../../redux/api";

const hoAdminTransactionStatusesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchHeadOfficeTransactionStatuses: builder.query({
            query: () => 'api/headofficeadmin/transactionstatuses'
        })
    })
});

export const {
    useLazyFetchHeadOfficeTransactionStatusesQuery,
    useFetchHeadOfficeTransactionStatusesQuery
} = hoAdminTransactionStatusesApiEndpoints;
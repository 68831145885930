import { useFetchTransactionProductsQuery } from "../../../services/valuations/productApiEndpoints";

const useProducts = (listType) => {

    const {
        data: products, 
        productsError, 
        productsIsError,
        productsIsFetching, 
        productsIsLoading } = useFetchTransactionProductsQuery(listType);

    return {
        products,
        productsError,
        productsIsError,
        productsIsFetching,
        productsIsLoading
    };
};

export default useProducts;
import React, { useMemo } from "react";
import { CurrencyInput, DateInput, FormInput, FormSelect, NumberInput } from "../../../components/forms";
import AssetSearchPagedSelect from "../../../components/forms/selects/AssetSearchPagedSelect";
import styled from "styled-components";
import { FixedCell } from "../../../components/tables/StandardTable";
import { NumericFormat } from "react-number-format";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { useAuth } from "react-oidc-context";


const StyledCell = styled(FixedCell)`
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
`;

const ApplicationTransactionRow = ({ data, statuses, isLoadingStatuses, patchFn, hasPhasing, hasTransactionFees }) => {
    const { user: { profile } } = useAuth();
    const canEditAsset = useMemo(() => profile?.roles?.includes("ho_app_prop"), [profile]);

    const { application: { hasBuyTransactions } } = useHeadOfficeApplicationContext();

    const {
        transactionId,
        fullTypeText,
        sedol,
        assetId,
        assetName,
        custodianReference,
        status,
        transactionPhase,
        transactionAmount,
        transactionQuantity,
        transactionDate,
        transactionFee,
        isEditable
    } = data || {};

    const update = (property, newValue, objectToAssign = {}) => patchFn(transactionId, property, newValue, objectToAssign);

    return <tr>
        <StyledCell width={10} className="text-truncate">
            {fullTypeText}
        </StyledCell>
        <StyledCell width={7.5} className="text-truncate">{sedol}</StyledCell>
        <StyledCell width={20} className="text-truncate">
            {canEditAsset
                ? <AssetSearchPagedSelect
                    valueObject={{ label: assetName, value: assetId }}
                    setValue={(option) => update("assetId", option.value, { assetId: option.value, assetName: option.label, sedol: option.data?.sedol })}
                />
                : assetName
            }
        </StyledCell>
        <StyledCell width={7.5} className="text-truncate">
            {canEditAsset
                ? <FormInput
                    value={custodianReference}
                    onBlur={(value) => update("custodianReference", value)}
                />
                : custodianReference}
        </StyledCell>
        <StyledCell width={12.5}>
            <FormSelect
                readOnly={!isEditable}
                options={statuses}
                defaultValue={status}
                isLoadingOptions={isLoadingStatuses}
                onChange={({ value }) => update("status", value)}
            />
        </StyledCell>
        {hasPhasing && <StyledCell width={5} textAlign="right">
            {transactionPhase}
        </StyledCell>}
        <StyledCell width={8.5}>
            <CurrencyInput
                readOnly={!isEditable}
                value={transactionAmount}
                onBlur={(value) => update("transactionAmount", value)}
            />
        </StyledCell>
        <StyledCell width={7.5}>
            <CurrencyInput
                readOnly={!isEditable}
                value={transactionQuantity}
                onBlur={(value) => update("transactionQuantity", value)}
                decimalScale={4}
                prefixSymbol=""
            />
        </StyledCell>
        <StyledCell width={10}>
            <DateInput
                readOnly={!isEditable}
                value={transactionDate}
                onBlur={(value) => update("transactionDate", value)}
                isClearable
            />
        </StyledCell>
        {(hasTransactionFees && hasBuyTransactions) && <StyledCell width={7.5}>
            <NumericFormat
                value={transactionFee}
                prefix={"£"}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
            />
        </StyledCell>}
    </tr>;
};

export default ApplicationTransactionRow;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const accountAssetsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createAccountAsset: builder.mutation({
            query: ({ masterAccountId, assetsSectionId }) => ({
                url: `api/factfind/accountassets`,
                method: 'POST',
                body: {
                    masterAccountId,
                    assetsSectionId
                }
            }),
            async onQueryStarted({ masterAccountId, assetsSectionId }, { dispatch, queryFulfilled }) {
                try {
                    let createdIndex;
                    const createResult = dispatch(wealthProApi.util.updateQueryData('fetchAccountAssets', { assetsSectionId }, data => {
                        const newAccountAsset = {
                            masterAccountId,
                            assetsSectionId,
                            ownerMappings: [],
                            description: null,
                            value: null,
                            assetTypeId: null
                        };
                        createdIndex = data.length;

                        return [...data, newAccountAsset];
                    }));

                    try {
                        const { data: response } = await queryFulfilled;

                        dispatch(wealthProApi.util.updateQueryData('fetchAccountAssets', { assetsSectionId }, data => [
                            ...data.slice(0, createdIndex),
                            {
                                ...data[createdIndex],
                                accountAssetId: response.accountAssetId
                            },
                            ...data.slice(createdIndex + 1)
                        ]));
                    } catch (err) {
                        console.error("Error creating account asset in service", err);
                        createResult.undo();
                    }
                } catch (err) {
                    console.error("Error creating account asset in cache", err);
                }
            }
        }),
        deleteAccountAsset: builder.mutation({
            query: ({ accountAssetId }) => ({
                url: `api/factfind/accountassets/${accountAssetId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ assetsSectionId, accountAssetId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchAccountAssets', { assetsSectionId }, data =>
                        data.filter(x => x.accountAssetId !== accountAssetId)));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting account asset in service", err);
                        deleteResult.undo();
                    }
                } catch (err) {
                    console.error("Error deleting account asset in cache", err);
                }
            }
        }),
        patchAccountAsset: builder.mutation({
            query: ({ accountAssetId, operations }) => ({
                url: `api/factfind/accountassets/${accountAssetId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ assetsSectionId, accountAssetId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const { data: viewModel } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchAccountAssets', { assetsSectionId }, data => {
                        var item = data.find(x => x.accountAssetId === accountAssetId);
                        Object.assign(item, viewModel);
                    }));
                }
                catch { }
            }
        }),
        updateAccountAssetOwnerMappings: builder.mutation({
            query: ({ accountAssetId, ids }) => ({
                url: `api/factfind/accountassets/${accountAssetId}`,
                method: 'POST',
                body: {
                    ids
                }
            }),
            async onQueryStarted({ accountAssetId, assetsSectionId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newMappings } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData('fetchAccountAssets', { assetsSectionId }, data => {
                        var item = data.find(x => x.accountAssetId === accountAssetId);
                        item.ownerMappings = newMappings;
                    }))
                }
                catch { }
            }
        }),
    })
});

export const {
    useCreateAccountAssetMutation,
    useDeleteAccountAssetMutation,
    usePatchAccountAssetMutation,
    useUpdateAccountAssetOwnerMappingsMutation
} = accountAssetsApiEndpoints;
/** Displays a set of navigation tabs in place of the 'subheader' grid-area */
import React from "react";
import styled from "styled-components";
import { darken, readableColor } from "polished";
import { NavigationTabs } from "../navigation";

const ParentSection = styled.section`
    grid-area: pagesubheader;
    position: sticky;
    top: ${props => props?.top ? props.top : 'var(--navbar-size)'};
    z-index: 4;
    padding: 0.75rem 1rem 0;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => darken(0.075, props.theme.primary)};
`;

const NavigationTabsPageSubHeader = ({ top = "154px", pathBase, options, onTabChanged = () => { }, ...rest }) => (
    <ParentSection top={top} {...rest}>
        <NavigationTabs pathBase={pathBase} options={options} onTabChanged={onTabChanged} />
    </ParentSection>
);

export default NavigationTabsPageSubHeader;
import { useCallback, useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchFactFindPdfQuery } from "../../../services/clientfactfind";

const useFactFindPdf = () => {
    const { id: masterAccountId } = useClientContext();

    const [fetchPdfTrigger, { data, isFetching: isFetchingPdf, error: pdfError }] = useLazyFetchFactFindPdfQuery();
    const { data: pdfData } = data || {};
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [isPreview, setIsPreview] = useState(false);

    const showPdf = useCallback((preview = false) => {
        setShowPdfModal(true);
        setIsPreview(preview === true);
        fetchPdfTrigger({ preview: preview === true, masterAccountId });
    }, [fetchPdfTrigger, masterAccountId]);

    const hidePdf = useCallback(() => {
        setShowPdfModal(false);
    }, []);

    const refetchPdf = () => fetchPdfTrigger({ preview: isPreview, masterAccountId });

    return {
        showPdfModal,
        pdfData,
        isPreview,
        isFetchingPdf,
        pdfError,
        showPdf,
        hidePdf,
        refetchPdf,
    };
}

export default useFactFindPdf;
import React from "react";
import Button from "../../components/buttons";
import { useAuth } from "react-oidc-context";
import { Modal } from "react-bootstrap";

const LogoutModal = ({ handleClose, show }) => {
    const { signoutRedirect } = useAuth();

    const handleSignOut = () => signoutRedirect({ post_logout_redirect_uri: window.location.origin });

    return <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Log out from WealthPro?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to Logout?
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleSignOut}>Yes, log out</Button>    
            <Button variant="light" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
};

export default LogoutModal;
import { useState } from "react";
import {
    useCreateApplicationMessageMutation,
    useDeleteApplicationDraftMessageMutation,
    useFetchApplicationMessagesQuery,
    usePatchApplicationMessageMutation,
    usePatchApplicationThreadMutation,
    useSendApplicationMessageMutation,
    useToggleApplicationMessageTodoMutation,
    useUpdateApplicationMessageRecipientsMutation
} from "../../../services/hoapplicationmessages";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { patchReplace } from "../../../helpers/patchDoc";
import { useAuth } from "react-oidc-context";

const useMessages = () => {
    const { applicationId } = useHeadOfficeApplicationContext();
    const { user: { profile } } = useAuth();
    const [active, setActive] = useState(false);
    const [page, setPage] = useState(1);
    const { data, isLoading, isFetching, isError, error, refetch } = useFetchApplicationMessagesQuery({ applicationId, page, active });
    const { results, pagination } = data || {};
    const { totalPages, totalCount } = pagination || {};
    const [createTrigger] = useCreateApplicationMessageMutation();
    const [deleteTrigger] = useDeleteApplicationDraftMessageMutation();
    const [patchTrigger] = usePatchApplicationMessageMutation();
    const [patchThreadTrigger] = usePatchApplicationThreadMutation();
    const [sendTrigger] = useSendApplicationMessageMutation();
    const [toggleDoneTrigger] = useToggleApplicationMessageTodoMutation();
    const [updateRecipientsTrigger] = useUpdateApplicationMessageRecipientsMutation();

    const createMessage = ({
        threadId,
        recipients = [],
        message = "",
        messagePriority,
        isActionRequired = false
    }) => {
        const recipientIds = recipients.map(x => x.value);
        const body = {
            threadId,
            sentStatus: "draft",
            statusText: "",
            message: message,
            recipientIds,
            messagePriority,
            isActionRequired,
            applicationId
        };

        return new Promise((resolve, reject) => {
            return createTrigger(body).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteMessage = (messageId) => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ messageId, active, page: 1, applicationId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const nextPage = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        setPage(prev => prev + 1);
    };

    const patchMessage = (messageId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return patchTrigger({ messageId, active, page, applicationId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const patchThread = (threadId, property, newValue) => {
        return new Promise((resolve, reject) => {
            var operations = [patchReplace(property, newValue)];
            return patchThreadTrigger({
                operations,
                applicationId,
                threadId,
                active
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const reply = (message) => {
        return new Promise((resolve, reject) => {
            let currentUserId = profile.sub.replace(/-/gi, '');
            const newRecipients = message.senderId === currentUserId
                ? message.recipients.filter(x => x.userId !== currentUserId)
                : [...message.recipients.filter(x => x.userId !== currentUserId), { userId: message.senderId }];

            return createTrigger({ recipientIds: newRecipients.map(x => x.userId), threadId: message.threadId, message: "" })
                .unwrap()
                .then(resolve, reject);
        })

    };

    const retryFetchMessages = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        if (page !== 1) {
            setPage(1);
            return;
        }

        refetch();
    };

    const sendMessage = (messageId) => {
        return new Promise((resolve, reject) => {
            return sendTrigger({ messageId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    }

    const toggleMessageIsDone = (messageId, newValue) => {
        return new Promise((resolve, reject) => {
            return toggleDoneTrigger({ messageId, newValue, page, applicationId, active }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateMessageRecipients = (messageId, recipients) => {
        return new Promise((resolve, reject) => {
            return updateRecipientsTrigger({ messageId, active, page, applicationId, recipients }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        results: results || [],
        totalCount,
        totalPages,
        active,
        page,
        isLoading,
        isFetching,
        isError,
        error,
        createMessage,
        deleteMessage,
        nextPage,
        patchMessage,
        patchThread,
        reply,
        retryFetchMessages,
        sendMessage,
        setActive,
        toggleMessageIsDone,
        updateMessageRecipients
    };
};

export default useMessages;
import React from "react";
import { ThemedIcon, ThemedSpan } from "./utilities";

const ICON_POOR = "fa-circle-xmark";
const ICON_GOOD = "fa-circle-check";
const EFFICACY_RED = 2;
const EFFICACY_AMBER = 1;

export const ReportEfficacyNumberDisplay = ({ efficacy }) => {
    const variant = efficacy >= EFFICACY_RED ? 'danger' : efficacy >= EFFICACY_AMBER ? 'warning' : 'success';
    return (
        <ThemedSpan variant={variant}>{efficacy}</ThemedSpan>
    );
};

const ReportEfficacyDisplay = ({ efficacy, size = "lg" }) => {
    const variant = efficacy >= EFFICACY_RED ? 'danger' : efficacy >= EFFICACY_AMBER ? 'warning' : 'success';
    const icon = efficacy >= EFFICACY_RED ? ICON_POOR : efficacy >= EFFICACY_AMBER ? ICON_POOR : ICON_GOOD;

    return (
        <ThemedIcon variant={variant} icon={icon} size={size} />
    );
};

export default ReportEfficacyDisplay;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const taxYearEndWithdrawalsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createTaxYearEndWithdrawal: builder.mutation({
            query: ({ pensionPlanId }) => ({
                url: 'api/factfind/taxyearendwithdrawals',
                method: 'POST',
                body: {
                    pensionPlanId
                }
            }),
            async onQueryStarted({ pensionPlanId }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    const newWithdrawal = {
                        withdrawalId: data.withdrawalId,
                        pensionPlanId,
                        taxYearEnd: null,
                        notes: null,
                        taxFreeCash: null,
                        taxableIncome: null,
                    };

                    dispatch(wealthProApi.util.updateQueryData('fetchTaxYearEndWithdrawals', { pensionPlanId }, (data) => {
                        return [...data, newWithdrawal];
                    }))
                }
                catch { }
            }
        }),
        deleteTaxYearEndWithdrawal: builder.mutation({
            query: ({ withdrawalId }) => ({
                url: `api/factfind/taxyearendwithdrawals/${withdrawalId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ pensionPlanId, withdrawalId }, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchTaxYearEndWithdrawals', { pensionPlanId }, (data) => {
                        return data.filter(x => x.withdrawalId !== withdrawalId);
                    }))
                }
                catch { }
            }
        }),
        patchTaxYearEndWithdrawal: builder.mutation({
            query: ({ withdrawalId, operations }) => ({
                url: `api/factfind/taxyearendwithdrawals/${withdrawalId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ pensionPlanId, withdrawalId, operations }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchTaxYearEndWithdrawals', { pensionPlanId }, (data) => {
                        var item = data.find(x => x.withdrawalId === withdrawalId);
                        applyPatch(item, operations, true);
                    }));
                }
                catch { }
            }
        })
    })
});

export const {
    useCreateTaxYearEndWithdrawalMutation,
    useDeleteTaxYearEndWithdrawalMutation,
    usePatchTaxYearEndWithdrawalMutation
} = taxYearEndWithdrawalsApiEndpoints;
import React, { forwardRef } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { ErrorMessage, NoResults } from "../../../components/messages";
import { useBusinessSheetSearch } from "../hooks/BusinessSheetSearchContext";
import ResultsDisplay from "./ResultsDisplay";

const StyledMenu = styled.div`
    padding: 1rem;
    width: 70vw !important;
    min-width: 350px;
    right: 0;
`;

const SearchMenu = forwardRef(({ allowRetry = true, className, value, hide, ...rest }, ref) => {

    const { charactersRemaining, error, isError, isLoading, isUninitialized, bizSheets, reload } = useBusinessSheetSearch();

    const handleRetry = (e) => {
        e.preventDefault();
        reload();
    }

    return <StyledMenu ref={ref} className={className} {...rest}>
        <h6 className="text-center fw-bold">Business Sheet Search</h6>
        <hr />

        {isUninitialized === true && charactersRemaining > 0 && (
            <p>Please type {charactersRemaining} more characters to begin your search.</p>
        )}
        {isUninitialized === true && charactersRemaining < 1 && (
            <p>Please wait whilst we begin your search.</p>
        )}
        {isUninitialized === false && isLoading === true && (
            <Skeleton count={5} />
        )}
        {isUninitialized === false && isLoading === false && isError === true && (
            <ErrorMessage error={error} retryCallback={handleRetry} />
        )}
        {isUninitialized === false && isLoading === false && isError === false && bizSheets.length === 0 && (
            <NoResults suffixText="No business sheets found for this account." />
        )}
        {isUninitialized === false && isLoading === false && isError === false && bizSheets.length > 0 && (
            <ResultsDisplay hide={hide} />
        )}
    </StyledMenu>
});

export default SearchMenu;
import React, { useEffect, useId, useState } from "react";
import styled from "styled-components";
import FormGroup from "./FormGroup";
import FormLabel from "./FormLabel";
import InputErrorMessage from "./InputErrorMessage";
import { InputSpinnerMd } from "../loaders/InputSpinners";
import { PatternFormat } from "react-number-format";
import { InputGroup } from "react-bootstrap";

const StyledPhoneNumberInput = styled(PatternFormat)`
    && {
        ${props => props.loading === 'true' ? InputSpinnerMd : ''}
    }
`;

const PhoneNumberInput = ({ allowEmptyFormatting = true, className, disabled, format = '##### ### ###', id, label, mask = '_', onFieldChanged, successTimeout = 1000, value, valueIsNumericString = true, ...rest }) => {
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentValue, setCurrentValue] = useState(value || '');
    const [originalValue, setOriginalValue] = useState(value || '');

    const getClassName = () => {
        let name = 'form-control';

        if (className) {
            name += ` ${className}`;
        }
        
        if (isSuccess === true) {
            name += ` is-valid`;
        }

        if (error) {
            name += ` is-invalid`;
        }

        return name;
    };

    const onBlur = (e) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        // check that the value has changed before triggering anything
        // NOTE: also check the function HAS been passed into this component
        if (currentValue === originalValue || !onFieldChanged || typeof (onFieldChanged) !== 'function') {
            return;
        }

        setError(_ => null);
        setIsLoading(_ => true);                              // trigger the loading animation on the input
        var possibleResult = onFieldChanged(currentValue);    // used to safely check the function returns something we can check on

        // we can now safely use the `then` method and handle the response accordingly
        Promise.resolve(possibleResult).then(
            _ => {
                setIsLoading(_ => false);
                setIsSuccess(_ => true);
            },
            error => {
                setIsLoading(_ => false);
                setError(_ => error);
            }
        );
    };

    const onChange = (e) => {
        setCurrentValue(e.value);
    };

    useEffect(() => {
        if (isSuccess === false) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(_ => false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    useEffect(() => {
        if (value && value !== currentValue) {
            setCurrentValue(value);
            setOriginalValue(value)
        }
    }, [value]);

    return <FormGroup>
        {label && typeof label === 'string' && <FormLabel htmlFor={componentId}>{label}</FormLabel>}
        {label && typeof label === 'object' && <React.Fragment>{label}</React.Fragment>}
        <InputGroup>
            <StyledPhoneNumberInput
                id={componentId}
                className={getClassName()}
                value={currentValue}
                loading={isLoading.toString()}
                disabled={disabled || isLoading}
                allowEmptyFormatting={allowEmptyFormatting}
                format={format}
                mask={mask}
                valueIsNumericString={valueIsNumericString}
                onBlur={onBlur}
                onValueChange={onChange}
                {...rest}
            />
            {error && (<InputErrorMessage error={error} retryCallback={onBlur} />)}
        </InputGroup>
    </FormGroup>
};

export default PhoneNumberInput;
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const clientFeeSignatoriesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        updateClientFeeSignatories: builder.mutation({
            query: ({ masterAccountId, data }) => ({
                url: `api/review/ClientFeeSignatories/${masterAccountId}`,
                method: 'POST',
                body: data
            }),
            async onQueryStarted({ masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: mappings } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchClientServiceTerms', { masterAccountId }, (data) => {
                        data.feeSignatories = mappings;
                    }));
                }
                catch { 
                    // TODO: do we invalidate the cache to force a refresh since there was something that happened?
                }
            }
        })
    })
});

export const {
    useUpdateClientFeeSignatoriesMutation
} = clientFeeSignatoriesApiEndpoints;
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const clientPersonsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchClientPersons: builder.query({
            query: ({ masterAccountId }) => `api/review/ClientPersons/${masterAccountId}?sort=Surname`
        }),
    })
});

export const {
    useFetchClientPersonsQuery
} = clientPersonsApiEndpoints;
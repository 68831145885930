import _ from "lodash";
import React from "react";
import { Button } from "../../../components";
import { useForm, Controller } from "react-hook-form";
import {
    CenterFormColumnComponent,
} from "../../../components/utilities";
import { Modal } from "react-bootstrap";
import {
    FormInput,
    FormCheck,
    DateInput,
    NumberInput,
} from "../../../components/forms";
import useServiceLevelAgreements from "../hooks/useServiceLevelAgreements";

const CreateServiceLevelAgreementModal = ({
    show = false,
    handleClose = () => {},
}) => {

    const {createServiceLevelAgreement} = useServiceLevelAgreements();

    const {
        control,
        errors,
        formState,
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({ defaultValues: {
        serviceName:"",
        reviewPeriodStart:null,
        reviewPeriodEnd: null,
        isVerificationRequired: false,
        daysToVerification:0,
        isPreReviewDocumentRequired: false,
        daysToPreReviewDocument: 0,
        isAppointmentRequired: false,
        daysToSchedule: 0,
        isPostMeetingRequired: false,
        daysToPostMeeting: 0,
        isFollowUpRequired: false,
        daysToFollowUpSent: 0,
        isTransactionFeesApplied: false,
        incomeDrawdownOption: false, 
        adviserUpdates: false
    } });

    const onSubmit = (data) => {
        createServiceLevelAgreement(data).then(handleClose());
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Create Service Agreement</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <>
                        <div className="row mb-2">
                            <div className="col">
                                <Controller
                                    name="serviceName"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onBlur,
                                            ...rest
                                        },
                                    }) => (
                                        <FormInput
                                            label="Service Name"
                                            value={value}
                                            placeholder="Enter service name"
                                            onBlur={(value) =>
                                                setValue(
                                                    "serviceName",
                                                    value,
                                                    { shouldDirty: true }
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                            <div className="col">
                                <Controller
                                    name="reviewPeriodStart"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <DateInput
                                            label="Review Period Start"
                                            value={value}
                                            onBlur={(newValue) =>
                                                setValue(
                                                    "reviewPeriodStart",
                                                    newValue,
                                                    { shouldDirty: true }
                                                )
                                            }
                                            isClearable
                                        />
                                    )}
                                />
                            </div>
                            <div className="col">
                                <Controller
                                    name="reviewPeriodEnd"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <DateInput
                                            label="Review Period End"
                                            value={value}
                                            onBlur={(newValue) =>
                                                setValue(
                                                    "reviewPeriodEnd",
                                                    newValue,
                                                    { shouldDirty: true }
                                                )
                                            }
                                            isClearable
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                                <Controller
                                    name="isVerificationRequired"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <CenterFormColumnComponent>
                                            <FormCheck
                                                label="Verification Required?"
                                                isChecked={value}
                                                onChange={(newValue) =>
                                                    setValue(
                                                        "isVerificationRequired",
                                                        newValue,
                                                        { shouldDirty: true }
                                                    )
                                                }
                                            />
                                        </CenterFormColumnComponent>
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <Controller
                                    name="daysToVerification"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onBlur,
                                            ...rest
                                        },
                                    }) => (
                                        <NumberInput
                                            label="Days To Verification"
                                            value={value}
                                            decimalScale={0}
                                            onBlur={(newValue) =>
                                                setValue(
                                                    "daysToVerification",
                                                    newValue,
                                                    { shouldDirty: true }
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                              <Controller
                                    name="incomeDrawdownOption"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <CenterFormColumnComponent>
                                            <FormCheck
                                                label="Drawdown Option"
                                                isChecked={value}
                                                onChange={(newValue) =>
                                                    setValue(
                                                        "incomeDrawdownOption",
                                                        newValue,
                                                        { shouldDirty: true }
                                                    )
                                                }
                                            />
                                        </CenterFormColumnComponent>
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <Controller
                                    name="daysToDrawdown"
                                    control={control}
                                    render={({
                                        field: { ref, value, onBlur, ...rest },
                                    }) => (
                                        <NumberInput
                                            label="Days To Drawdown"
                                            value={value}
                                            decimalScale={0}
                                            onBlur={(newValue) =>
                                                setValue(
                                                    "daysToDrawdown",
                                                    newValue,
                                                    { shouldDirty: true }
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                                <Controller
                                    name="isPreReviewDocumentRequired"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <CenterFormColumnComponent>
                                            <FormCheck
                                                label="PRD Required?"
                                                isChecked={value}
                                                onChange={(newValue) =>
                                                    setValue(
                                                        "isPreReviewDocumentRequired",
                                                        newValue,
                                                        { shouldDirty: true }
                                                    )
                                                }
                                            />
                                        </CenterFormColumnComponent>
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <Controller
                                    name="daysToPreReviewDocument"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onBlur,
                                            ...rest
                                        },
                                    }) => (
                                        <NumberInput
                                            label="Days To PRD"
                                            value={value}
                                            decimalScale={0}
                                            onBlur={(newValue) =>
                                                setValue(
                                                    "daysToPreReviewDocument",
                                                    newValue,
                                                    { shouldDirty: true }
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                                <Controller
                                    name="isAppointmentRequired"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <CenterFormColumnComponent>
                                            <FormCheck
                                                label="Appointment Required?"
                                                isChecked={value}
                                                onChange={(newValue) =>
                                                    setValue(
                                                        "isAppointmentRequired",
                                                        newValue,
                                                        { shouldDirty: true }
                                                    )
                                                }
                                            />
                                        </CenterFormColumnComponent>
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <Controller
                                    name="daysToSchedule"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onBlur,
                                            ...rest
                                        },
                                    }) => (
                                        <NumberInput
                                            label="Days To Appointment"
                                            value={value}
                                            decimalScale={0}
                                            onBlur={(newValue) =>
                                                setValue(
                                                    "daysToVerification",
                                                    newValue,
                                                    { shouldDirty: true }
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                                <Controller
                                    name="isPostMeetingRequired"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <CenterFormColumnComponent>
                                            <FormCheck
                                                label="Post Meeting Required?"
                                                isChecked={value}
                                                onChange={(newValue) =>
                                                    setValue(
                                                        "isPostMeetingRequired",
                                                        newValue,
                                                        { shouldDirty: true }
                                                    )
                                                }
                                            />
                                        </CenterFormColumnComponent>
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <Controller
                                    name="daysToPostMeeting"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onBlur,
                                            ...rest
                                        },
                                    }) => (
                                        <NumberInput
                                            label="Days To Post Meeting"
                                            value={value}
                                            decimalScale={0}
                                            onBlur={(newValue) =>
                                                setValue(
                                                    "daysToPostMeeting",
                                                    newValue,
                                                    { shouldDirty: true }
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                                <Controller
                                    name="isFollowUpRequired"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <CenterFormColumnComponent>
                                            <FormCheck
                                                label="Follow Up Required?"
                                                isChecked={value}
                                                onChange={(newValue) =>
                                                    setValue(
                                                        "isFollowUpRequired",
                                                        newValue,
                                                        { shouldDirty: true }
                                                    )
                                                }
                                            />
                                        </CenterFormColumnComponent>
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <Controller
                                    name="daysToFollowUpSent"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onBlur,
                                            ...rest
                                        },
                                    }) => (
                                        <NumberInput
                                            label="Days To Follow Up"
                                            value={value}
                                            decimalScale={0}
                                            onBlur={(newValue) =>
                                                setValue(
                                                    "daysToFollowUpSent",
                                                    newValue,
                                                    { shouldDirty: true }
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <Controller
                                    name="isTransactionFeesApplied"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <CenterFormColumnComponent>
                                            <FormCheck
                                                label="Transaction Fees"
                                                isChecked={value}
                                                onChange={(newValue) =>
                                                    setValue(
                                                        "isTransactionFeesApplied",
                                                        newValue,
                                                        { shouldDirty: true }
                                                    )
                                                }
                                            />
                                        </CenterFormColumnComponent>
                                    )}
                                />
                            </div>
                            <div className="col">
                                <Controller
                                    name="adviserUpdatesOption"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <CenterFormColumnComponent>
                                            <FormCheck
                                                label="Adviser Updates"
                                                isChecked={value}
                                                onChange={(newValue) =>
                                                    setValue(
                                                        "adviserUpdatesOption",
                                                        newValue,
                                                        { shouldDirty: true }
                                                    )
                                                }
                                            />
                                        </CenterFormColumnComponent>
                                    )}
                                />
                            </div>
                           
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit">
                        Create
                    </Button>
                    <Button variant="light" onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateServiceLevelAgreementModal;

import classNames from "classnames";
import React from "react";
import GridWrapper from "../../../components/GridWrapper";
import { FormLabel } from "../../../components/forms";
import { ThemedSpan } from "../../../components/utilities";
import SectionHeader from "./SectionHeader";

const AntiMoneyLaunderingClientStatuses = ({ clientStatuses }) => {
    return <GridWrapper gridTemplateColumns={`minmax(auto, 15%) repeat(${clientStatuses.length}, 1fr)`}>
        <FormLabel className="col-start-1 row-start-2 ms-2">
            AML Status:
        </FormLabel>

        {clientStatuses.map((status, index) => <React.Fragment key={status.clientAccountId}>
            <SectionHeader className={classNames(
                `col-start-${index + 2} row-start-1 mt-0`,
                { "fw-bold": !status.isValid }
            )}>
                {status.clientName}
            </SectionHeader>
            <ThemedSpan className={classNames(
                `col-start-${index + 2} row-start-2`,
                { "fw-bold": !status.isValid }
            )} variant={!status.isValid ? 'danger' : ''}>
                {status.status}
            </ThemedSpan>
        </React.Fragment>)}
    </GridWrapper>;
};

export default AntiMoneyLaunderingClientStatuses;
import { useCallback, useEffect, useMemo, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useLazyFetchValuationProvidersQuery } from "../../../services/valuations";
import { useInstruction } from "../contexts/InstructionContext";

const ringFenceFeeOptions = [
    { value: 0, label: "Service Fee (%):" },
    { value: 1, label: "Service Fee (£):" }
];

const filterDuplicates = (option, index, array) => array.findIndex(checkOpt => (checkOpt.value === option.value)) === index;

const useRingFence = () => {
    const [{ patchInvest: patchInvestTrigger, realTimePatchInvest }, { realTimeInstruction, instructionId, invest, divest }] = useInstruction();

    const [ringFenceFeeType, setRingFenceFeeType] = useState(invest?.ringFenceFeeType);

    useEffect(() => {
        if (invest?.id == null) return;

        setRingFenceFeeType(old => old !== invest?.ringFenceFeeType
            ? invest?.adviceFeeType
            : old);
    }, [invest?.adviceFeeType, invest?.id, invest?.ringFenceFeeType]);

    const patchInvest = useCallback((operations) =>
        patchInvestTrigger({
            investId: invest?.id,
            instructionId,
            operations
        }).unwrap(),
        [instructionId, invest?.id, patchInvestTrigger]);

    // Real-time update of ringFence fee amount
    useEffect(() => {
        if (invest?.id == null) return;

        switch (ringFenceFeeType) {
            case 0:
                const newValue = realTimeInstruction?.invest?.ringFenceFeePercent * realTimeInstruction?.divest?.totalRingFenceAmount / 100;

                if (newValue !== realTimeInstruction?.invest?.ringFenceFeeAmount)
                    realTimePatchInvest([patchReplace("ringFenceFeeAmount", newValue)]);
                break;
            default:
                break;
        }
    }, [invest?.id, realTimeInstruction?.divest?.totalRingFenceAmount, realTimeInstruction?.invest?.ringFenceFeeAmount, realTimeInstruction?.invest?.ringFenceFeePercent, realTimePatchInvest, ringFenceFeeType]);

    // Real-time update of ringFence fee percent
    useEffect(() => {
        if (invest?.id == null) return;

        switch (ringFenceFeeType) {
            case 1:
                const newPercent = realTimeInstruction?.invest?.ringFenceFeeAmount * 100 / realTimeInstruction?.divest?.totalRingFenceAmount;

                if (newPercent !== realTimeInstruction?.invest?.ringFenceFeePercent)
                    realTimePatchInvest([patchReplace("ringFenceFeePercent", newPercent)]);
                break;
            default:
                break;
        }
    }, [invest?.id, realTimeInstruction?.divest?.totalRingFenceAmount, realTimeInstruction?.invest?.ringFenceFeeAmount, realTimeInstruction?.invest?.ringFenceFeePercent, realTimePatchInvest, ringFenceFeeType]);

    // onBlur update of ringFence fee amount/percent
    useEffect(() => {
        const updateFeesTimeout = setTimeout(() => {
            if (!invest?.id) return;

            let operations = [];

            switch (ringFenceFeeType) {
                case 0:
                    const newValue = invest?.ringFenceFeePercent * divest?.totalRingFenceAmount / 100;
                    if (invest?.ringFenceFeeAmount !== newValue)
                        operations.push(patchReplace("ringFenceFeeAmount", newValue));

                    break;
                case 1:
                    const newPercent = invest?.ringFenceFeeAmount * 100 / divest?.totalRingFenceAmount;
                    if (invest?.ringFenceFeePercent !== newPercent)
                        operations.push(patchReplace("ringFenceFeePercent", newPercent));

                    break;
                default:
                    break;
            }

            if (operations.length > 0)
                patchInvest(operations);
        }, 150)

        return () => clearTimeout(updateFeesTimeout);
    }, [divest?.totalRingFenceAmount, invest?.id, invest?.ringFenceFeeAmount, invest?.ringFenceFeePercent, patchInvest, ringFenceFeeType]);

    // Fetch Custody options when designationId changes
    const [realTimeDesignationId, setRealTimeDesignationId] = useState(null);
    useEffect(() => {
        if (invest?.id == null) return;

        setRealTimeDesignationId(invest?.designationId);
    }, [invest?.designationId, invest?.id]);

    const [fetchProviderMaps, { currentData: providerMaps, isFetching: isLoadingCustodyOptions }, { lastArg: { designationId: lastDesignationId } }] = useLazyFetchValuationProvidersQuery();
    const [customCustodyOptions, setCustomCustodyOptions] = useState([]);
    const custodyOptions = useMemo(() => providerMaps != null
        ? [
            ...customCustodyOptions,
            ...Object.values(providerMaps).flat().filter(filterDuplicates)
        ]
        : customCustodyOptions, [customCustodyOptions, providerMaps])

    // Need to add the chosen custody reference to the custom options on load
    useEffect(() => {
        if (invest?.ringFenceCustodyReference == null) return;
        if (customCustodyOptions.some(opt => opt.value === invest?.ringFenceCustodyReference)) return;

        setCustomCustodyOptions(old => [...old, { label: invest?.ringFenceCustodyReference, value: invest?.ringFenceCustodyReference }]);
    }, [customCustodyOptions, invest?.ringFenceCustodyReference]);

    useEffect(() => {
        if (realTimeDesignationId == null || realTimeDesignationId === lastDesignationId) return;

        fetchProviderMaps({ designationId: realTimeDesignationId });
    }, [customCustodyOptions, fetchProviderMaps, lastDesignationId, realTimeDesignationId]);

    const createCustodyOption = useCallback((newOption) => {
        setCustomCustodyOptions(old => [...old, { label: newOption.toUpperCase(), value: newOption.toUpperCase() }]);
    }, []);

    return {
        setRealTimeDesignationId,
        custodyOptions,
        createCustodyOption,
        isLoadingCustodyOptions,
        ringFenceFeeOptions,
        patchInvest,
        realTimePatchInvest,
        ringFenceFeeType,
        setRingFenceFeeType
    }
}

export default useRingFence;
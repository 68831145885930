import React from "react";
import { usePatchReportMutation, usePatchReportStatusMutation } from "../../../services/clientreports";

const EditReportContext = React.createContext();

export const useEditReport = () => React.useContext(EditReportContext);

export const EditReportProvider = ({ children, showModalOnReportSet = true }) => {
    const [report, _setReport] = React.useState(null);
    const [showModal, _setShowModal] = React.useState(false);
    const [patch, { isLoading: isPatching, ...patchResults }] = usePatchReportMutation();
    const [patchStatus, { isLoading: isPatchingStatus, ...patchStatusResults }] = usePatchReportStatusMutation();

    const isLoading = React.useMemo(() => isPatching || isPatchingStatus, [isPatching, isPatchingStatus]);

    const clear = () => {
        _setReport(_ => null);
        _setShowModal(_ => false);
    };

    const saveChanges = (operations, message) => {
        const { id, masterAccountId, projectId } = report;
        return new Promise((resolve, reject) => {
            // Patch status separate if we have a custom message
            if (message == null)
                return patch({ masterAccountId, reportId: id, projectId, operations }).unwrap().then(
                    r => resolve(r),
                    e => reject(e)
                );

            const { authorId, status, message: { threadId, text, recipients } } = message;

            // Patch non-status properties first, if there are any
            const statusPatch = () => patchStatus({
                masterAccountId,
                reportId: id,
                projectId,
                authorId,
                status,
                messageThread: threadId,
                messageText: text,
                messageRecipients: recipients
            }).unwrap();
            
            return (
                operations.length > 0
                    ? patch({ masterAccountId, reportId: id, projectId, operations }).unwrap()
                        .then(statusPatch)
                    : statusPatch()
            ).then(resolve, reject);
        });
    };

    const setReport = (report) => {
        _setReport(_ => report);
        if (showModalOnReportSet === true) {
            _setShowModal(_ => true);
        }
    };

    return (
        <EditReportContext.Provider value={{
            clear,
            isLoading,
            isModalActive: showModal,
            patch: { isLoading: isPatching, ...patchResults },
            patchStatus: { isLoading: isPatchingStatus, ...patchStatusResults },
            report,
            saveChanges,
            setReport
        }}>
            {children}
        </EditReportContext.Provider>
    );
};
import { useState } from "react";
import WrappersTableRowActionCol from "./WrappersTableRowActionCol";
import { FormInput, FormSelect } from "../../../components/forms";
import { useWrappersManagement } from "../hooks/WrappersManagementContext";
import { Badge } from "../../../components/utilities";
import styled from "styled-components";
import { FixedCell } from "../../../components/tables/StandardTable";

const StyledBadge = styled(Badge)`
    background-color: #e6e6e6;
    font-weight: normal !important;
    color: black !important;
    padding: 6px 8px !important;
`;

const WrappersTableRow = ({ data }) => {

    const {
        wrapperCategories,
        isLoadingWrapperCategories,
        deleteWrapper,
        patchWrapper,
        updateWrapperInstructionMaps,
        instructionTypes,
        isLoadingInstructionTypes,
    } = useWrappersManagement();

    const [isEditable, setIsEditable] = useState(false);

    const {
        description,
        category,
        categoryText,
        wrapperId,
        instructionTypeMappings
    } = data || {};

    const handleDelete = () => deleteWrapper(wrapperId);

    const handleUpdate = (property, newValue, assignableObject) => patchWrapper(wrapperId, property, newValue, assignableObject);

    return <tr onDoubleClick={() => setIsEditable(prev => !prev)}>
        <FixedCell width={15}>
            {isEditable
                ? <FormInput
                    value={description}
                    onBlur={value => handleUpdate("description", value)}
                />
                : description}
        </FixedCell>
        <FixedCell width={10}>
            {isEditable
                ? <FormSelect
                    options={wrapperCategories ?? []}
                    defaultValue={category}
                    isLoadingOptions={isLoadingWrapperCategories}
                    onBlur={({ value, label }) => handleUpdate("category", value, { categoryText: label })}
                />
                : categoryText
            }
        </FixedCell>
        <FixedCell width={72.5}>
            {isEditable
                ? <FormSelect
                    defaultValueObject={instructionTypeMappings}
                    options={instructionTypes ?? []}
                    isLoadingOptions={isLoadingInstructionTypes}
                    onBlur={(values) => updateWrapperInstructionMaps(wrapperId, values)}
                    isMulti
                />
                : instructionTypeMappings.map((type) => <StyledBadge key={type.value} className="me-2 px-2">{type.label}</StyledBadge>)
            }
        </FixedCell>
        <FixedCell width={2.5} align="right">
            <WrappersTableRowActionCol
                setShowEdit={() => setIsEditable(prev => !prev)}
                deleteWrapper={handleDelete}
                showEdit={isEditable}
            />
        </FixedCell>
    </tr>
};

export default WrappersTableRow;
import { useCallback, useEffect, useMemo, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useInstruction } from "../contexts/InstructionContext";

const useFeeCollection = () => {

    const [{
        fetchInvest,
        patchInvest: patchInvestTrigger,
        realTimePatchInvest
    }, {
        instructionId,
        realTimeInstruction,
        invest,
        paymentMethodOptions: options,
        paymentMethodOptionsIsError: optionsIsError,
        paymentMethodOptionsIsFetching: optionsIsFetching,
        paymentMethodOptionsIsLoading: optionsIsLoading,
        paymentMethodOptionsIsSuccess: optionsIsSuccess,
        paymentMethodObjects: objects,
        paymentMethodObjectsIsError: objectsIsError,
        paymentMethodObjectsIsFetching: objectsIsFetching,
        paymentMethodObjectsIsLoading: objectsIsLoading,
        paymentMethodObjectsIsSuccess: objectsIsSuccess,
        refetchPaymentMethodOptions: refetchOptions,
        refetchPaymentMethodObjects: refetchObjects
    }] = useInstruction();

    const [adviceFeeType, setAdviceFeeType] = useState(invest?.adviceFeeType);
    const [serviceFeeType, setServiceFeeType] = useState(invest?.serviceFeeType);

    useEffect(() => {
        if (invest?.id == null) return;

        setAdviceFeeType(old => old !== invest?.adviceFeeType ? invest?.adviceFeeType : old);
        setServiceFeeType(old => old !== invest?.serviceFeeType ? invest?.serviceFeeType : old);
    }, [invest?.adviceFeeType, invest?.id, invest?.serviceFeeType])

    const paymentMethodsIsReady = useMemo(() =>
        objectsIsSuccess && optionsIsSuccess,
        [objectsIsSuccess, optionsIsSuccess]);
    const paymentMethodsIsLoading = useMemo(() =>
        objectsIsLoading || optionsIsLoading
        || objectsIsFetching || optionsIsFetching,
        [objectsIsFetching, objectsIsLoading, optionsIsFetching, optionsIsLoading]);
    const paymentMethodsIsError = useMemo(() =>
        objectsIsError || optionsIsError,
        [objectsIsError, optionsIsError]);

    const availableOptions = useMemo(() =>
        adviceFeeType === 2
            ? options
            : options?.filter(({ value }) => value !== objects?.find(({ methodEnum }) => methodEnum === 3)?.id),
        [adviceFeeType, objects, options])

    const adviceFeeOptions = useMemo(() => [
        { value: 0, label: "Advice Fee (£):" },
        { value: 1, label: "Advice Fee (%):" },
        { value: 2, label: "No Advice Fee" }
    ], []);

    const serviceFeeOptions = useMemo(() => [
        { value: 0, label: "Service Fee (£):" },
        { value: 1, label: "Service Fee (%):" },
        { value: 2, label: "No Service Fee" }
    ], []);

    const patchInvest = useCallback((property, value) =>
        patchInvestTrigger({ investId: invest?.id, instructionId, operations: [patchReplace(property, value)] }).unwrap(),
        [instructionId, invest?.id, patchInvestTrigger]);

    const realTimePatchInvestSingle = useCallback((property, value) => {
        if (!invest?.id) return;

        realTimePatchInvest([patchReplace(property, value)]);
    }, [invest?.id, realTimePatchInvest]);

    const bulkPatchInvest = useCallback((operations) =>
        patchInvestTrigger({ investId: invest?.id, instructionId, operations }).unwrap(),
        [instructionId, invest?.id, patchInvestTrigger]);

    // Real-time update of advice fee percent
    useEffect(() => {
        const adviceFeePercentTimeout = setTimeout(() => {
            if (!invest?.id)
                return;

            if (adviceFeeType !== 0) // £
                return;

            if (realTimeInstruction?.invest?.totalInvestmentAmount === 0)
                return;

            const newPercent = realTimeInstruction?.invest?.adviceFeeAmount * 100 / realTimeInstruction?.invest?.totalInvestmentAmount;

            realTimePatchInvestSingle("adviceFeePercent", newPercent);
        }, 150);

        return () => clearTimeout(adviceFeePercentTimeout);
    }, [adviceFeeType, invest?.id, realTimeInstruction?.invest?.adviceFeeAmount, realTimeInstruction?.invest?.totalInvestmentAmount, realTimePatchInvestSingle]);

    // Real-time update of advice fee amount
    useEffect(() => {
        const adviceFeeAmountTimeout = setTimeout(() => {
            if (!invest?.id)
                return;

            if (adviceFeeType !== 1) // %
                return;

            const newValue = realTimeInstruction?.invest?.adviceFeePercent * realTimeInstruction?.invest?.totalInvestmentAmount / 100;

            realTimePatchInvestSingle("adviceFeeAmount", newValue);
        }, 150);

        return () => clearTimeout(adviceFeeAmountTimeout);
    }, [adviceFeeType, invest?.id, realTimeInstruction?.invest?.adviceFeePercent, realTimeInstruction?.invest?.totalInvestmentAmount, realTimePatchInvestSingle]);

    // Real-time update of service fee percent
    useEffect(() => {
        const serviceFeePercentTimeout = setTimeout(() => {
            if (!invest?.id)
                return;

            if (serviceFeeType !== 0) // £
                return;

            if (realTimeInstruction?.invest?.totalInvestmentAmount === 0)
                return;

            const newPercent = realTimeInstruction?.invest?.serviceFeeAmount * 100 / realTimeInstruction?.invest?.totalInvestmentAmount;

            realTimePatchInvestSingle("serviceFeePercent", newPercent);
        }, 150);

        return () => clearTimeout(serviceFeePercentTimeout);
    }, [invest?.id, realTimeInstruction?.invest?.serviceFeeAmount, realTimeInstruction?.invest?.totalInvestmentAmount, realTimePatchInvestSingle, serviceFeeType]);

    // Real-time update of service fee amount
    useEffect(() => {
        const serviceFeeAmountTimeout = setTimeout(() => {
            if (!invest?.id)
                return;

            if (serviceFeeType !== 1) // %
                return;

            const newValue = realTimeInstruction?.invest?.serviceFeePercent * realTimeInstruction?.invest?.totalInvestmentAmount / 100;

            realTimePatchInvestSingle("serviceFeeAmount", newValue);
        }, 150);

        return () => clearTimeout(serviceFeeAmountTimeout);
    }, [invest?.id, realTimeInstruction?.invest?.serviceFeePercent, realTimeInstruction?.invest?.totalInvestmentAmount, realTimePatchInvestSingle, serviceFeeType]);

    // onBlur update of advice/service fee amount/percent
    useEffect(() => {
        if (!invest?.id) return;

        const updateFeesTimeout = setTimeout(() => {
            let operations = [];
            switch (adviceFeeType) {
                case 0: // £
                    const newPercent = invest?.adviceFeeAmount * 100 / invest?.totalInvestmentAmount;
                    if (invest?.adviceFeePercent !== newPercent)
                        operations.push(patchReplace("adviceFeePercent", newPercent));

                    break;
                case 1: // %
                    const newValue = invest?.adviceFeePercent * invest?.totalInvestmentAmount / 100;
                    if (invest?.adviceFeeAmount !== newValue)
                        operations.push(patchReplace("adviceFeeAmount", newValue));

                    break;
                default:
                    break;
            }

            switch (serviceFeeType) {
                case 0: // £
                    const newPercent = invest?.serviceFeeAmount * 100 / invest?.totalInvestmentAmount;
                    if (invest?.serviceFeePercent !== newPercent)
                        operations.push(patchReplace("serviceFeePercent", newPercent));

                    break;
                case 1: // %
                    const newValue = invest?.serviceFeePercent * invest?.totalInvestmentAmount / 100;
                    if (invest?.serviceFeeAmount !== newValue)
                        operations.push(patchReplace("serviceFeeAmount", newValue));

                    break;
                default:
                    break;
            }

            if (operations.length > 0)
                bulkPatchInvest(operations);
        }, 150)

        return () => clearTimeout(updateFeesTimeout);
    }, [adviceFeeType, bulkPatchInvest, invest?.adviceFeeAmount, invest?.adviceFeePercent, invest?.id, invest?.serviceFeeAmount, invest?.serviceFeePercent, invest?.totalInvestmentAmount, serviceFeeType]);

    // Setting default payment method
    useEffect(() => {
        if (paymentMethodsIsReady && invest?.id && !invest?.paymentMethodId) {
            let defaultPaymentMethod = objects?.find(obj => obj.methodEnum === 0); // "Paid From Purchase Contract"

            if (defaultPaymentMethod)
                patchInvest("paymentMethodId", defaultPaymentMethod.id);
        }
    }, [invest?.id, invest?.paymentMethodId, objects, patchInvest, paymentMethodsIsReady])

    // Setting default fee types
    useEffect(() => {
        if (invest?.id == null) return;

        let operations = [];
        let defaultFeeType = 2;

        if (invest?.adviceFeeType == null) {
            operations.push(patchReplace("adviceFeeType", defaultFeeType));
            setAdviceFeeType(defaultFeeType);
        }

        if (invest?.serviceFeeType == null) {
            operations.push(patchReplace("serviceFeeType", defaultFeeType));
            setServiceFeeType(defaultFeeType);
        }

        if (operations.length > 0) {
            bulkPatchInvest(operations);
            realTimePatchInvest(operations);
        }
    }, [bulkPatchInvest, invest?.adviceFeeType, invest?.id, invest?.serviceFeeType, realTimePatchInvest]);

    const retryOptions = useCallback(() => {
        return Promise.all([refetchObjects(), refetchOptions()]);
    }, [refetchObjects, refetchOptions]);

    const retryInvest = useCallback(() => {
        fetchInvest({ instructionId });
    }, [fetchInvest, instructionId]);

    return {
        adviceFeeType,
        serviceFeeType,
        adviceFeeOptions,
        serviceFeeOptions,
        options: availableOptions,
        objects,
        paymentMethodsIsReady,
        paymentMethodsIsLoading,
        paymentMethodsIsError,
        retryOptions,
        retryInvest,
        patchInvest,
        bulkPatchInvest,
        realTimePatchInvest,
        realTimePatchInvestSingle,
        setAdviceFeeType,
        setServiceFeeType
    }
}

export default useFeeCollection;
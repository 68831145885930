import React, { useState } from "react";
import { FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import { Button } from "../../components";
import { useFilterContext } from "../../hooks/FilterContext";
import CustodianAccountMatchingFilterModals from "./components/CustodianAccountMatchingFilterModals";
import CustodianAccountMatchingGrid from "./components/CustodianAccountMatchingGrid";

export const CustodianAccountMatchingDefaultFilters = {
    custodianCode: null,
    platformAccountId: null,
    masterAccount: null,
    designation: null,
    startDate: null,
    endDate: null,
};

const CustodianAccountMatching = () => {

    const { clearFilter, isFiltersApplied } = useFilterContext();
    const [isFiltersShown, setIsFiltersShown] = useState(false);

    return (
        <React.Fragment>
            <Main className="d-flex flex-column">
                <PageSubHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            {isFiltersApplied && (
                                <React.Fragment>
                                    <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                    <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                        Warning - Filters are currently being applied to your results.
                                        <br />
                                        <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                    </FiltersAppliedWarningMessage>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex flex-fill flex-row-reverse">
                            <Button className="ms-3" onClick={() => setIsFiltersShown(true)}>
                                Filter Matches
                            </Button>
                        </div>
                    </div>
                </PageSubHeader>
                <section className="px-4 my-3 h-100">
                    <CustodianAccountMatchingGrid />
                </section>
            </Main>
            <CustodianAccountMatchingFilterModals
                show={isFiltersShown}
                handleClose={() => setIsFiltersShown(false)}
            />
        </React.Fragment>
    );
};

export default CustodianAccountMatching;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOpenDocument from "../../../hooks/useOpenDocument";
import { ThemedSpan } from "../../../components/utilities";
import { niceBytes } from "../../../helpers/fileHelpers";
import styled from "styled-components";
import { DocumentTypeIcons } from "../../../helpers/constants";

const AttachmentContainer = styled.div`
    height: 100px;
    width: 125px;
    display: flex;
    flex-direction: column;
`;


const Attachment = ({ attachment }) => {

    const { openDocument, isFetching: isOpening } = useOpenDocument();

    const {
        fileName,
        fileSize,
        documentType,
        documentId
    } = attachment || {};

    var docIcon = DocumentTypeIcons[documentType];

    const onAttachmentClick = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        if (isOpening) return;

        return openDocument(documentId, fileName);
    }

    return <AttachmentContainer onClick={onAttachmentClick} className="border p-2 rounded me-2 has-pointer">
        <FontAwesomeIcon className="mt-auto" size={"3x"} color={docIcon?.color ?? "#000000"} icon={docIcon?.icon ?? "file"} />
        <ThemedSpan className="mt-auto text-truncate">{fileName} {niceBytes(fileSize)}</ThemedSpan>
    </AttachmentContainer>
};

export default Attachment;
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchAmlSectionQuery, useRemoveAmlEvidenceMutation } from "../../../services/clientfactfind";


export const useAntiMoneyLaundering = () => {
    const { id: masterAccountId } = useClientContext();

    const { data, isLoading, isFetching, isError, error, refetch } = useFetchAmlSectionQuery({ masterAccountId });
    const [removeTrigger, { isLoading: isRemovingEvidence }] = useRemoveAmlEvidenceMutation();
    const removeEvidence = (evidenceId) => removeTrigger({ masterAccountId, evidenceId }).unwrap();

    const {
        clientStatuses, evidence, isValid, status
    } = data ?? {};

    return {
        isLoading,
        isFetching,
        isError,
        error,
        refetch,
        clientStatuses,
        evidence,
        amlStatus: status,
        isValid,
        removeEvidence,
        isRemovingEvidence,
    };
};

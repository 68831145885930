import { ThemedParagraph, ThemedSpan } from "../../../components/utilities";
import { CurrencyInput, DateInput, FormSelect, FormLabel, FormCheck } from "../../../components/forms";
import Moment from "react-moment";
import { IconButton } from "../../../components";

export const InstructionRowHeaders = () => {

    return <div className="row">
        <div className="col">
            <FormLabel>Instruction</FormLabel>
        </div>
        <div className="col">
            {/*No header for frequency*/}
        </div>
        <div className="col">
            <FormLabel>Cash Amount</FormLabel>
        </div>
        <div className="col">
            <FormLabel>Date Instructed</FormLabel>
        </div>
        <div className="col">
            <FormLabel>Instructed By</FormLabel>
        </div>
        <div className="col">
            <FormLabel>Date Confirmed</FormLabel>
        </div>
        <div className="col">
            <FormLabel>Confirmed By</FormLabel>
        </div>
    </div>;

};

const InstructionRow = ({
    appliesProperty,
    label,
    frequencyProperty,
    cashAmountProperty,
    dateInstructedProperty,
    instructedByProperty,
    instructedDateSetProperty,
    dateConfirmedProperty,
    confirmedByProperty,
    confirmedDateSetProperty,
    recalculateTransactionsButton = false,
    onRecalculateTransactionsClick,
    updateFn,
    data
}) => {

    const updateProperty = (property, newValue, toggleAppliesFlag) => {
        if (updateFn && typeof (updateFn) === "function") {
            if (toggleAppliesFlag) {
                updateFn(property, newValue, appliesProperty);
                return;
            }
            updateFn(property, newValue);
        }
    };

    return <div className="row d-flex align-items-center mb-1">
        <div className="col d-flex flex-row">
            <FormCheck
                isChecked={data[appliesProperty]}
                onChange={newValue => updateProperty(appliesProperty, newValue)}
            />

            <ThemedParagraph className="ms-4">{label}</ThemedParagraph>
        </div>
        <div className="col">
            {frequencyProperty && <FormSelect
                options={["Monthly", "Quarterly", "Annual", "Biannual", "One Off"].map((label, value) => ({ label, value }))}
                defaultValue={data[frequencyProperty]}
                onBlur={({ value }) => updateProperty(frequencyProperty, value, true)}
            />}
        </div>
        <div className="col d-flex">
            {cashAmountProperty && <CurrencyInput
                className={"w-100"}
                value={data[cashAmountProperty]}
                onBlur={newValue => updateProperty(cashAmountProperty, newValue, true)}
            />}
            {recalculateTransactionsButton && <IconButton
                icon="fa-calculator"
                className="rounded-0"
                variant="success"
                onClick={onRecalculateTransactionsClick}
            />}
        </div>
        <div className="col">
            <DateInput
                value={data[dateInstructedProperty]}
                onBlur={newValue => updateProperty(dateInstructedProperty, newValue)}
                isClearable
            />
        </div>
        <div className="col">
            {data[instructedByProperty] && data[instructedDateSetProperty] && <ThemedSpan variant="muted">
                {data[instructedByProperty]}, <Moment format="DD/MM/YY">{data[instructedDateSetProperty]}</Moment>
            </ThemedSpan>}
        </div>
        <div className="col">
            <DateInput
                value={data[dateConfirmedProperty]}
                onBlur={newValue => updateProperty(dateConfirmedProperty, newValue)}
            />
        </div>
        <div className="col">
            {data[confirmedByProperty] && data[confirmedDateSetProperty] && <ThemedSpan variant="muted">
                {data[confirmedByProperty]}, <Moment format="DD/MM/YY">{data[confirmedDateSetProperty]}</Moment>
            </ThemedSpan>}
        </div>
    </div>
};

export default InstructionRow;
import _ from "lodash";
import { wealthProApi } from "../../redux/api";

const projectSummaryEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        searchProjects: builder.query({
            query: (body) => ({
                url: "api/clientprojectsummary/projects",
                method: "POST",
                body,
            }),
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                }
                else {
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                }
                currentCache.feePotential = newItems.feePotential;
            }
        }),
    }),
});

export const { useLazySearchProjectsQuery } = projectSummaryEndpoints;
import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { ActionIconNavLink, ThemedSpan } from "../../../components/utilities";

export const NotificationsMenu = styled.div`
    width: 80vw;
    max-width: 1000px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 1rem !important;
`;

export const NotificationsMenuHeader = styled.div`
    border-bottom: 1px solid ${props => darken(0.15, props.theme.light)};
`;

const NotificationsDropdownMenu = React.forwardRef(({ children, ...rest }, ref) => {
    return <NotificationsMenu ref={ref} {...rest}>
        <NotificationsMenuHeader className="row pb-2 mb-2">
            <div className="col">
                <ThemedSpan variant="primary">Notifications</ThemedSpan>
            </div>
            <div className="col-md-auto">
                <ActionIconNavLink to="/personal/notifications" icon="fa-expand" />
            </div>
        </NotificationsMenuHeader>
        {children}
    </NotificationsMenu>
});

export default NotificationsDropdownMenu;
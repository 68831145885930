import { wealthProApi } from '../../redux/api';

const favouritesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["favouriteclients"]
}).injectEndpoints({
    endpoints: (builder) => ({
        addClientToFavourites: builder.mutation({
            query: (data) => ({
                url: `api/clientdetails/users/favourites`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['favouriteclients'],
            async onQueryStarted({ masterAccountId }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('getMasterAccountDetailSummary', { masterAccountId }, (data) => {
                    data.isFavourited = true;
                }));

                const fetchResult = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccounts', {}, (data) => {
                    var i = data.results.findIndex(el => el.masterAccountId === masterAccountId);
                    if (i >= 0) {
                        data.results[i].isFavourited = true;
                    }
                }));

                const searchResult = dispatch(wealthProApi.util.updateQueryData('searchMasterAccounts', {}, (data) => {
                    var i = data.results.findIndex(el => el.masterAccountId === masterAccountId);
                    if (i >= 0) {
                        data.results[i].isFavourited = true;
                    }
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                    fetchResult.undo();
                    searchResult.undo();
                }
            }
        }),
        fetchFavourites: builder.query({
            query: ({ page, limit, sort = 'CreatedDate' }) => `api/clientdetails/users/favourites?page=${page}&limit=${limit}&sort=${sort}`,
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results)
            },
            forceRefetch({ currentArg, previousArg }) {
                const { page: currPage } = currentArg || { page: 1 };
                const { page: prevPage } = previousArg || { page: 0 };
                return currPage !== prevPage;
            }
        }),
        removeClientFromFavourites: builder.mutation({
            query: (data) => ({
                url: `api/clientdetails/users/favourites`,
                method: 'DELETE',
                body: data
            }),
            invalidatesTags: ['favouriteclients'],
            async onQueryStarted({ masterAccountId, page, limit, sort }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('getMasterAccountDetailSummary', { masterAccountId }, (data) => {
                    data.isFavourited = false;
                }));

                const listResult = dispatch(wealthProApi.util.updateQueryData('fetchFavourites', { page, limit, sort }, (data) => {
                    data.results = data.results.filter(el => el.masterAccountId !== masterAccountId);
                    data.pagination.totalCount = data.pagination.totalCount - 1;
                }));

                const fetchResult = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccounts', {}, (data) => {
                    var i = data.results.findIndex(el => el.masterAccountId === masterAccountId);
                    if (i >= 0) {
                        data.results[i].isFavourited = false;
                    }
                }));

                const searchResult = dispatch(wealthProApi.util.updateQueryData('searchMasterAccounts', {}, (data) => {
                    var i = data.results.findIndex(el => el.masterAccountId === masterAccountId);
                    if (i >= 0) {
                        data.results[i].isFavourited = false;
                    }
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                    fetchResult.undo();
                    listResult.undo();
                    searchResult.undo();
                }
            }
        })
    })
});

export const {
    useAddClientToFavouritesMutation,
    useFetchFavouritesQuery,
    useRemoveClientFromFavouritesMutation,
    usePatchFavouriteMutation
} = favouritesApiEndpoints;
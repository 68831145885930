import React, { useState } from "react";
import Button from "../../../../../components/buttons";
import { Modal } from "react-bootstrap";
import {
    DateInput
} from "../../../../../components/forms/";
import { components } from "react-select";
import { GenericAdviserSelect,
    CampaignSelect, ClientProjectStatusSelect 

} from "../../../../../components/forms/selects";

const NewBusinessForecastReportModal = ({
    confirmButtonLabel = "Run Report",
    confirmButtonVariant = "success",
    handleNewBusinessForecastReportArgsClose,
    handleNewBusinessForecastReportArgsConfirm,
    newBusinessForecastReportArgsShow,
    ...rest
}) => {

    const [theFromDate, setTheFromDate] = useState(null);
    const [theToDate, setTheToDate] = useState(null);
    const [theAdviserIds, setTheAdviserIds] = useState([]);
    const [theProjectStatuses, setTheProjectStatuses] = useState([0,7,8,9,10,11,12]);
    const [theCampaignIds, setTheCampaignIds] = useState([]);

    const [errorMessage, setErrorMessage] = React.useState(null);

    const onClose = () => {
        if (handleNewBusinessForecastReportArgsClose && typeof handleNewBusinessForecastReportArgsClose === "function") {
            setErrorMessage("");
            setTheFromDate(null);
            setTheToDate(null);
            setTheAdviserIds([]);
            setTheProjectStatuses([0,7,8,9,10,11,12]);
            setTheCampaignIds([]);
            handleNewBusinessForecastReportArgsClose();
        }
    }

    const onConfirm = () => {
        if(theFromDate !== null && theToDate !== null){
            if (handleNewBusinessForecastReportArgsConfirm && typeof handleNewBusinessForecastReportArgsConfirm === "function") {
                const theRequest = {fromDate: theFromDate, toDate: theToDate, adviserIds: theAdviserIds, projectStatuses: theProjectStatuses, campaignIds: theCampaignIds}
                handleNewBusinessForecastReportArgsConfirm(theRequest);
            }
            onClose();
        }
        else{
            setErrorMessage("From Date and To Date must be selected!");
        }
    }

    return (
        <Modal size="med" centered backdrop="static" show={newBusinessForecastReportArgsShow}>
            <Modal.Header>New Business Forecast Report</Modal.Header>
            <Modal.Body {...rest}>
            <div className="row mb-3">
                <div className="col">
                        <DateInput
                            label="From"
                            value={theFromDate}
                            onBlur={(value) => setTheFromDate(value)}
                        />
                </div>
                <div className="col">
                        <DateInput
                            label="To"
                            value={theToDate}
                            onBlur={(value) => setTheToDate(value)}
                        />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <GenericAdviserSelect
                        label="Filter Advisers - All Advisers unless selections made"
                        defaultValue={theAdviserIds}
                        isMulti={true}
                        onBlur={(values) => setTheAdviserIds(values.map((el) => el.value))}
                        //onBlur={(values) => console.log(values.map((el) => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <ClientProjectStatusSelect 
                        label="Filter Statuses"
                        defaultValue={theProjectStatuses}
                        isMulti={true}
                        onBlur={(values) => setTheProjectStatuses(values.map((el) => el.value))}
                        //onBlur={(values) => console.log(values.map((el) => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <CampaignSelect
                        label="Filter Campaigns - All Campaigns unless selections made"
                        defaultValue={theCampaignIds}
                        isMulti={true}
                        onBlur={(values) => setTheCampaignIds(values.map((el) => el.value))}
                        //onBlur={(values) => console.log(values.map((el) => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="text-warning"> {errorMessage} </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmButtonVariant} onClick={onConfirm}>{confirmButtonLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                
            </Modal.Footer>
        </Modal>
    );
};

export default NewBusinessForecastReportModal;
import React from "react";
import ClientAccess from "../features/clientaccess";
import ClientAuditLogs from "../features/clientauditlogs";
import ClientBizSheets from "../features/clientbizsheets";
import ClientConnections from "../features/clientconnections";
import ClientDetails from "../features/clientdetails";
import ClientEmails from "../features/clientemails";
import ClientMessages from "../features/clientmessagesnew";
import ClientPortfolio from "../features/clientportfolio";
import ClientProjects from "../features/clientprojects";
import ClientReviews from "../features/clientreviews";
import ClientServiceTerms from "../features/clientserviceterms";
import ClientTransactions from "../features/clienttransactions";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useClientContext } from "../hooks/ClientContext";
import { NavigationTabs } from "../components/navigation";
import { ClientDashboardLoginsPage, ClientDashboardPage, ClientDocumentsPage, ClientEventsPage, ClientFactFindPage, ClientPreferencesPage, ClientReportsPage, ClientSearchPage, ClientSecurityPage, ClientSummaryPage } from "../pages/client";
import { ClientLayout } from "../layouts";

const ClientRoutes = {
    path: '/client/*',
    defaultRoute: 'search',
    roles: [],
    children: [
        {
            path: 'search',
            pathBase: '/client/search',
            element: ClientSearchPage
        },
        {
            path: ':masterAccountId/*',
            pathBase: '/client/:masterAccountId',
            element: ClientLayout,
            children: [
                { 
                    active: false,
                    path: 'summary', 
                    icon: 'fa-chart-simple', 
                    label: 'Summary', 
                    roles: [],
                    element: ClientSummaryPage
                },
                { 
                    path: 'details', 
                    icon: 'fa-user-pen', 
                    label: 'Details', 
                    roles: [],
                    element: ClientDetails
                },
                { 
                    path: 'reviews', 
                    icon: 'fa-calendar', 
                    label: 'Reviews', 
                    roles: [],
                    element: ClientReviews
                },
                {
                    path: 'events',
                    icon: 'fa-calendar-check',
                    label: "Events",
                    roles: [],
                    element: ClientEventsPage
                },
                { 
                    path: 'projects', 
                    icon: 'fa-diagram-project', 
                    label: 'Projects',
                    roles: [],
                    element: ClientProjects
                },
                { 
                    path: 'reports', 
                    icon: 'fa-file-lines', 
                    label: 'Reports', 
                    roles: [],
                    element: ClientReportsPage
                },
                { 
                    path: 'documents', 
                    icon: 'fa-file-lines', 
                    label: 'Documents', 
                    roles: [],
                    element: ClientDocumentsPage
                },
                { 
                    path: 'bizsheets', 
                    icon: 'fa-file-circle-check', 
                    label: 'Business Sheets', 
                    roles: [],
                    element: ClientBizSheets
                },
                { 
                    path: 'messages', 
                    icon: 'fa-comments', 
                    label: 'Messages', 
                    roles: [],
                    element: ClientMessages
                },
                { 
                    path: 'dashboard', 
                    icon: 'fa-tablet-alt', 
                    label: 'Dashboard', 
                    roles: [],
                    element: ClientDashboardPage,
                    defaultRoute: 'logins',
                    children: [
                        { path: 'logins', element: ClientDashboardLoginsPage }
                    ]
                },
                { 
                    path: 'emails', 
                    icon: 'fa-envelope', 
                    label: 'Emails', 
                    roles: [],
                    element: ClientEmails
                },
                { 
                    path: 'transactions', 
                    icon: 'fa-list', 
                    label: 'Transactions', 
                    roles: ["transaction_read"],
                    element: ClientTransactions
                },
                { 
                    path: 'portfolio', 
                    icon: 'fa-chart-line', 
                    label: 'Portfolio', 
                    roles: ["portfolio_read"],
                    element: ClientPortfolio
                },
                { 
                    path: 'factfind', 
                    icon: 'fa-file-pen', 
                    label: 'Fact Find', 
                    roles: ["ff_read"],
                    element: ClientFactFindPage
                },
                { 
                    path: 'connections', 
                    icon: 'fa-users', 
                    label: 'Connections',
                    roles: [],
                    element: ClientConnections
                },
                { 
                    path: 'preferences', 
                    icon: 'fa-user-gear', 
                    label: 'Preferences', 
                    roles: [],
                    element: ClientPreferencesPage
                },
                { 
                    path: 'serviceterms', 
                    icon: 'fa-handshake', 
                    label: 'Service Terms', 
                    roles: [],
                    element: ClientServiceTerms
                },
                { 
                    path: 'security', 
                    icon: 'fa-shield', 
                    label: 'Security & Access', 
                    roles: [],
                    element: ClientSecurityPage,
                    defaultRoute: 'access',
                    children: [
                        { path: 'access', element: ClientAccess },
                        { path: 'logs', element: ClientAuditLogs }
                    ]
                }
            ]
        }
    ]
}

export const ClientRouteTabs = () => {
    const location = useLocation();
    const navigator = useNavigate();
    const { id } = useClientContext();
    const { pathBase } = ClientRoutes.children[1];

    const onTabChanged = ({ path }) => {
        sessionStorage.setItem(`${id}.route`, path);
    };

    React.useEffect(() => {
        // basically checking here if we are coming to a specific tab (example: from reports management to the projects tab directly)
        const match = matchRoutes([{ path: `${pathBase}` }], location); // checks if it's the base path
        if (!match) {
            // find a potential match, so we can cache 
            const route = ClientRoutes.children[1].children.find(el => {
                let p = el.path ?? el.route;
                let m = matchRoutes([{ path: `${pathBase}/${p}/*` }], location);
                return m ? true : false;
            });

            // if we found a route, then we can update the location of the user within that client
            if (route) {
                sessionStorage.setItem(`${id}.route`, route.path);
            }
            else {
                // we didn't find anything, just remove any existing
                sessionStorage.removeItem(`${id}.route`);
            }

            return;
        }

        const lastKnownRoute = sessionStorage.getItem(`${id}.route`);
        navigator(lastKnownRoute ?? 'details');
    }, [ id ]);

    return <NavigationTabs pathBase={pathBase} options={ClientRoutes.children[1]} onTabChanged={onTabChanged} />
}

export default ClientRoutes;
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useCreateFinalSalaryArrangementMutation, useDeleteFinalSalaryArrangementMutation, useFetchFinalSalaryArrangementsQuery, usePatchFinalSalaryArrangementMutation } from "../../../services/clientfactfind";

const pensionTypes = [
    {
        label: "Stakeholder",
        value: 10
    },
    {
        label: "Personal Pension",
        value: 20
    },
    {
        label: "SIPP",
        value: 30
    },
    {
        label: "Workplace Pension",
        value: 40
    },
    {
        label: "Final Salary",
        value: 50
    },
];

const useFinalSalaryArrangements = () => {

    const { id: masterAccountId } = useClientContext();
    const { data, isError, isLoading, isFetching, error, refetch } = useFetchFinalSalaryArrangementsQuery({ masterAccountId });
    const [triggerCreate] = useCreateFinalSalaryArrangementMutation();
    const [triggerDelete] = useDeleteFinalSalaryArrangementMutation();
    const [triggerPatch] = usePatchFinalSalaryArrangementMutation();


    const createFinalSalaryArrangement = () => {
        return new Promise((resolve, reject) => {
            return triggerCreate({ masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const deleteFinalSalaryArrangement = (finalSalaryId) => {
        return new Promise((resolve, reject) => {
            return triggerDelete({ finalSalaryId, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updateFinalSalaryArrangement = (finalSalaryId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return triggerPatch({ finalSalaryId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        data: data || [],
        isLoading: isLoading || isFetching,
        isError,
        error,
        refetch,
        pensionTypes,
        createFinalSalaryArrangement,
        deleteFinalSalaryArrangement,
        updateFinalSalaryArrangement
    }

};

export default useFinalSalaryArrangements;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Alert } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { GridWrapper } from "../../../components";
import { CurrencyInput, DateInput, FormCheck, FormSelect } from "../../../components/forms";
import { useInstruction } from "../contexts/InstructionContext";
import useIncome from "../hooks/useIncome";

const Income = ({
    taxFreeCash = false,
    clientWithdrawal = false,
    incomeCommencementDate = false,
    inDrawdown = false,
    cashAccount = false,
    retainAmount = false,
    showRemainder = false,
    trusteeCashAccount = false
}) => {
    const [, { realTimeInstruction, invest, investIsLoading, investTransactionsIsLoading, divestIsLoading, divestTransactionsIsLoading }, { incomeFrequencyOptions, realTimeRemainder }] = useInstruction();
    const {
        patchInvest,
        realTimePatchInvestSingle,
        showTransferWarning
    } = useIncome();

    const isLoading = useMemo(() =>
        investIsLoading || investTransactionsIsLoading || divestIsLoading || divestTransactionsIsLoading,
        [divestIsLoading, divestTransactionsIsLoading, investIsLoading, investTransactionsIsLoading]);

    const frequencyNotApplicable = useMemo(() =>
        (realTimeInstruction?.invest?.clientIncomeAmount ?? 0) === 0,
        [realTimeInstruction?.invest?.clientIncomeAmount])

    const frequencyOptionsWithNotApplicable = useMemo(() => {
        if (incomeFrequencyOptions == null)
            return null;

        return frequencyNotApplicable
            ? [{ label: "Not Applicable", value: -1 }, ...incomeFrequencyOptions]
            : incomeFrequencyOptions;
    }, [frequencyNotApplicable, incomeFrequencyOptions]);

    return <span className={"d-block mt-3"}>
        {showTransferWarning &&
            <GridWrapper gridTemplateColumns={"minmax(0, 60ch)"}>
                <Alert variant="warning" className="mb-3" >
                    <Alert.Heading>
                        <FontAwesomeIcon icon='exclamation-triangle' size="lg" />
                        <span className="ps-2">
                            Warning
                        </span>
                    </Alert.Heading>
                    <span>
                        You have entered values that exceed the proceeds raised above.<br />
                        Please ensure this is intentional and/or fix the values.
                    </span>
                </Alert>
            </GridWrapper>}

        <GridWrapper gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}>
            {inDrawdown && <div className="align-self-end mb-2">
                {isLoading
                    ? <Skeleton />
                    : <FormCheck
                        label={"In Drawdown"}
                        isChecked={invest?.isInDrawdown}
                        onChange={(isChecked) => patchInvest("isInDrawdown", isChecked)}
                    />}
            </div>}

            {taxFreeCash && (isLoading
                ? <Skeleton />
                : <CurrencyInput
                    label={"Tax Free Cash:"}
                    value={invest?.taxFreeCashAmount}
                    onChange={(_, { floatValue }) => realTimePatchInvestSingle("taxFreeCashAmount", floatValue)}
                    onBlur={(taxFree) => patchInvest("taxFreeCashAmount", taxFree)}
                    allowNegative={false}
                />)}

            {clientWithdrawal && (isLoading
                ? <Skeleton />
                : <CurrencyInput
                    label={"Client Withdrawal:"}
                    value={invest?.clientWithdrawalAmount}
                    onChange={(_, { floatValue }) => realTimePatchInvestSingle("clientWithdrawalAmount", floatValue)}
                    onBlur={(withdrawal) => patchInvest("clientWithdrawalAmount", withdrawal)}
                    allowNegative={false}
                />)}

            {isLoading
                ? <Skeleton />
                : <CurrencyInput
                    label={"Gross Income Amount:"}
                    value={invest?.clientIncomeAmount}
                    onChange={(_, { floatValue }) => realTimePatchInvestSingle("clientIncomeAmount", floatValue)}
                    onBlur={(income) => patchInvest("clientIncomeAmount", income)}
                    allowNegative={false}
                />}

            {isLoading
                ? <Skeleton />
                : <FormSelect
                    label={"Frequency:"}
                    isDisabled={frequencyNotApplicable}
                    defaultValue={frequencyNotApplicable
                        ? -1
                        : invest?.clientIncomeFrequency}
                    onChange={(selection) => realTimePatchInvestSingle("clientIncomeFrequency", selection.value)}
                    onBlur={(selection) => patchInvest("clientIncomeFrequency", selection.value)}
                    options={frequencyNotApplicable ? frequencyOptionsWithNotApplicable : incomeFrequencyOptions}
                />}

            {incomeCommencementDate && (isLoading
                ? <Skeleton />
                : <DateInput
                    label={"Commencing:"}
                    value={invest?.incomeCommencementDate?.substring(0, 10) || null}
                    isClearable
                    onBlur={(date) => patchInvest("incomeCommencementDate", date)}
                />)}

            {cashAccount && (isLoading
                ? <Skeleton />
                : <CurrencyInput
                    label={trusteeCashAccount ? "Trustee Bank Account:" : "Cash Account:"}
                    value={invest?.toCashAccount}
                    onChange={(_, { floatValue }) => realTimePatchInvestSingle("toCashAccount", floatValue)}
                    onBlur={(cashAcc) => patchInvest("toCashAccount", cashAcc)}
                    allowNegative={false}
                />)}

            {retainAmount && (isLoading
                ? <Skeleton />
                : <CurrencyInput
                    label={"Retain to Invest Elsewhere:"}
                    value={invest?.retainAmount}
                    onChange={(_, { floatValue }) => realTimePatchInvestSingle("retainAmount", floatValue)}
                    onBlur={(retain) => patchInvest("retainAmount", retain)}
                    allowNegative={false}
                />)}

            {showRemainder && (isLoading
                ? <Skeleton />
                : <CurrencyInput
                    label={"Remaining in Cash:"}
                    value={realTimeRemainder}
                    disabled
                />)}
        </GridWrapper>
    </span>;
}

export default Income;
import { wealthProApi } from "../../redux/api";
import { applyPatch } from "fast-json-patch";

// TODO - Patch/Delete must have same parameters as fetch query to update cache.

const productCostsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['productcosts']
}).injectEndpoints({
    endpoints: (builder) => ({
        deleteProductCost: builder.mutation({
            query: ({ costId }) => ({
                url: `api/products/productcost/${costId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ costId, productId, instructionTypeId }, { dispatch, queryFulfilled }) {
                dispatch(wealthProApi.util.updateQueryData('fetchProductCosts', { productId, instructionTypeId }, (data) => {
                    const newData = data.filter(cost => cost.costId !== costId);
                    return newData;
                }));
                
                try {
                    await queryFulfilled;
                }
                catch { }
            }
        }),
        fetchProductCosts: builder.query({
            query: ({ productId, instructionTypeId, sort = "Section%2Bdesc,CreatedDate" }) => ({
                url: `api/products/productcost/${productId}?instructionTypeId=${instructionTypeId}&sort=${sort}`
            })
        }),
        patchProductCost: builder.mutation({
            query: ({ costId, operations }) => ({
                url: `api/products/productcost/${costId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ costId, productId, instructionTypeId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchProductCosts', { productId, instructionTypeId }, (data) => {
                    var cost = data.find(c => c.costId === costId);
                    applyPatch(cost, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        createProductCost: builder.mutation({
            query: ({ ...body }) => ({
                url: `api/products/productcost`,
                method: 'POST',
                body: body
            }),
            async onQueryStarted({ ...body }, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdId } = await queryFulfilled;
                    const { productId, instructionTypeId } = body;

                    dispatch(wealthProApi.util.updateQueryData('fetchProductCosts', { productId, instructionTypeId }, (data) => {
                        data.push({
                            ...body,
                            costId: createdId
                        })
                    }));
                }
                catch { }
            }
        })
    })
});

export const {
    useDeleteProductCostMutation,
    useFetchProductCostsQuery,
    useLazyFetchProductCostsQuery,
    usePatchProductCostMutation,
    useCreateProductCostMutation
} = productCostsApiEndpoints;
import React from "react";
import classNames from "classnames";
import ChatBubble from "./ChatBubble";
import ErrorMessage from "../ErrorMessage";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList } from "react-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingMessage, Spinner } from "../../loaders";

const InfiniteChatListContext = React.createContext({
    className: null,
    reloadFn: null,
    error: null,
    isFetching: false,
    isLoading: false,
});

const VariableSizeInfiniteChatList = ({
    allowReply = false,
    baseBubbleSize = 56,
    baseBubblePadding = 30,
    error = null,
    hasItemLoaded = false,
    isFetching = false,
    isLoading = false,
    itemCount = 0,
    itemData = [],
    loadMoreItems = () => {},
    onReplyClick = () => {},
    parentClassName = null,
    retry = null,
}) => {
    const listRef = React.useRef();
    const rowHeightRef = React.useRef({});

    const getRowHeight = (i) => {
        return (rowHeightRef.current[i] || (baseBubbleSize + baseBubblePadding));
    };

    const setRowHeight = (i, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeightRef.current = { ...rowHeightRef.current, [i]: size };
    }

    const DisplayBubble = React.useCallback((props) => <ChatBubble setRowHeight={setRowHeight} allowReply={allowReply} onReplyClick={onReplyClick} {...props} />, []);

    return (
        <InfiniteChatListContext.Provider value={{ 
            className: parentClassName,
            reloadFn: retry,
            error: error,
            isFetching: isFetching,
            isLoading: isLoading
        }}>
            <AutoSizer>
                {({ height, width }) => (
                    <InfiniteLoader
                        isItemLoaded={hasItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VariableSizeList
                                ref={(e) => {
                                    listRef.current = e;
                                    ref(e);
                                }}
                                height={height}
                                width={width}
                                itemSize={getRowHeight}
                                itemCount={itemCount}
                                itemData={itemData}
                                innerElementType={InnerChatList}
                                onItemsRendered={onItemsRendered}
                            >
                                {DisplayBubble}
                            </VariableSizeList>
                        )}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </InfiniteChatListContext.Provider>
    );
};

const InnerChatList = React.forwardRef(({ children, style }, ref) => {
    const { className, reloadFn, error, isFetching, isLoading } = React.useContext(InfiniteChatListContext);

    if (error) {
        const { message } = error || { message: 'Sorry! We were unable to complete your request.' }
        const { height } = style;
        return (
            <div ref={ref} className={classNames('chat-error-parent', className)} style={{ height }}>
                <ErrorMessage message={message} retryCallback={reloadFn} />
            </div>
        );
    }

    return (
        <div ref={ref} className={classNames(className)} style={style}>
            {!isLoading && reloadFn && typeof(reloadFn) === 'function' && (
                <div className="chat-reloader" onClick={reloadFn}>
                    <FontAwesomeIcon icon="fas fa-sync-alt" />
                </div>
            )}
            {isLoading && (
                <LoadingMessage text="Please wait whilst WealthPro gets the messages associated with this Report" />
            )}
            {!isLoading && (
                <React.Fragment>
                    {children}
                    {isFetching && (
                        <div className="chat-fetching">
                            <Spinner />
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
});

export default VariableSizeInfiniteChatList;
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken } from "polished";
import { variantToColour } from "../../helpers/theme";

const ActionIconWrapper = styled.span`
    cursor: pointer;
    color: ${props => variantToColour(props)};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    &:hover {
        color: ${props => darken(props.theme.linkDarkenAmount, variantToColour(props))};
    }

    &:active {
        color: ${props => darken(props.theme.linkDarkenAmount, variantToColour(props))};
    }

    &:focus {
        color: ${props => darken(props.theme.linkDarkenAmount, variantToColour(props))};
    }
`;

const ActionIcon = ({ onClick, variant = "primary", ...rest }) => {
    return <ActionIconWrapper variant={variant} onClick={onClick}>
        <FontAwesomeIcon {...rest}/>
    </ActionIconWrapper>
};

export default ActionIcon;
import classNames from "classnames";
import { AutoFitCell, FixedCell } from "../../../components/tables/StandardTable";
import styled from "styled-components";
import { ReminderStatuses } from "../../../helpers/constants";

const StyledRow = styled.tr`
    background-color: ${({ isCancelled }) => isCancelled ? '#e9ecef' : 'transparent'};
    opacity: ${({ isCancelled }) => isCancelled ? 0.5 : 1};
    position: relative;
`

const EventsTableRow = ({ data, index, columns, ...rest }) => <StyledRow key={`row-${index}`} isCancelled={data?.status === ReminderStatuses.Cancelled}>
    {columns.map(({
        property,
        valueFormatter = (val) => val,
        CellComponent = null,
        className,
        fitContent = false,
        ...col
    }, i) => {
        if (CellComponent)
            return <CellComponent
                {...rest}
                {...data}
                {...col} />;

        const value = data[property];
        const formattedValue = valueFormatter(value);

        const props = {
            key: `row-col-${i}`,
            className: classNames(className, {
                "text-truncate": !fitContent
            }),
            ...col
        };

        return fitContent
            ? <AutoFitCell {...props}>{formattedValue}</AutoFitCell>
            : <FixedCell {...props}>{formattedValue}</FixedCell>;
    })}
</StyledRow>;

export default EventsTableRow;
import { wealthProApi } from "../../redux/api";
import { applyPatch } from "fast-json-patch";

const clientFeeAgreementApiEndpoints = wealthProApi
    .injectEndpoints({
        endpoints: (builder) => ({
            previewClientFeeAgreement: builder.query({
                query: ({ masterAccountId }) => ({
                    url: `api/review/ClientFeeAgreements/clientconsent/preview/${masterAccountId}`,
                    method: 'GET',
                })
            }),
            publishClientFeeAgreement: builder.query({
                query: ({ masterAccountId }) => ({
                    url: `api/review/clientfeeagreements/clientconsent/publish/${masterAccountId}`,
                    method: 'GET',
                    responseType: 'blob' //Force to receive data in a Blob Format
                }),
                async onQueryStarted({ masterAccountId, data: feeAgreement }, { dispatch, queryFulfilled }) {
                    try {
                        const { data: createData } = await queryFulfilled;
                        dispatch(wealthProApi.util.updateQueryData('fetchClientServiceTerms', { masterAccountId }, (data) => {
                            data.feeAgreements.push({
                                ...createData
                            });
                        }));
                    }
                    catch { }
                }
            }),
            deleteClientFeeAgreement: builder.mutation({
                query: ({ clientFeeAgreementId }) => ({
                    url: `api/review/clientfeeagreements/${clientFeeAgreementId}`,
                    method: 'DELETE'
                }),
                async onQueryStarted({ clientFeeAgreementId, masterAccountId }, { dispatch, queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        dispatch(wealthProApi.util.updateQueryData('fetchClientServiceTerms', { masterAccountId }, (data) => {
                            data.feeAgreements = data.feeAgreements.filter(el => el.clientFeeAgreementId !== clientFeeAgreementId);
                            return data;
                        }));
                    }
                    catch { }
                }
            }),
            patchClientFeeAgreement: builder.mutation({
                query: ({ clientFeeAgreementId, masterAccountId, operations }) => ({
                    url: `api/review/clientfeeagreements/${clientFeeAgreementId}`,
                    method: 'PATCH',
                    body: operations
                }),
                async onQueryStarted({ clientFeeAgreementId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                    const result = dispatch(
                        wealthProApi.util.updateQueryData('fetchClientServiceTerms', { masterAccountId }, (data) => {
                            var agreement = data.feeAgreements.find(el => el.clientFeeAgreementId === clientFeeAgreementId);
                            applyPatch(agreement, operations, true);
                    }));
    
                    try {
                        await queryFulfilled;
                    }
                    catch {
                        result.undo();
                    }
                }
            })
        }),
    });

export const {
    useLazyPreviewClientFeeAgreementQuery,
    useLazyPublishClientFeeAgreementQuery,
    useDeleteClientFeeAgreementMutation,
    usePatchClientFeeAgreementMutation
} = clientFeeAgreementApiEndpoints;

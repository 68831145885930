import styled from "styled-components";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import classNames from "classnames";

const TableCell = styled.td`
    width: ${({ width }) => width}%;
    max-width: 50px;
    vertical-align: middle;
`;

export const ProjectManagementTableRow = ({ columns, data, index, style }) => {
    const { height } = style;

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length)
        return <TableLoadingRow />;

    return <tr index={index} style={{ height }}>
        {columns.map((col, colIndex) =>
            <TableCell width={col.width} key={`row-col-${colIndex}`} className={classNames(col.className, "text-truncate")}>
                {col.CellComponent
                    ? <col.CellComponent
                        key={`row-col-${colIndex}`}
                        value={data[index]?.[col.property]}
                        index={index}
                        row={data[index]}
                        {...col}
                    />
                    : col.cellFormatter
                        ? col.cellFormatter(data[index]?.[col.property])
                        : data[index]?.[col.property]}
            </TableCell>)}
    </tr>
};
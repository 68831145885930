import React from "react";
import { Modal } from "react-bootstrap";
import { Button, IconButton } from "../../../components";
import { FormSelect, FormCheck, DateInput } from "../../../components/forms";
import { ThemedIcon, ThemedSpan } from "../../../components/utilities";
import { ClientProjectSelect } from "../../../components/forms/selects";

const SendMessageConfirmationModal = ({
    hasClientRecipient,
    onClose,
    onSend,
    deadlineDate,
    priority,
    projectId,
    requiresAction,
    show,
    updateThread,
    updateMessage
}) => {

    return <Modal centered backdrop="static" show={show}>
        <Modal.Header>
            <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {/* <div className="row mb-3">
                <ThemedSpan>Please fill out the final options below:</ThemedSpan>
            </div> */}
            {
                hasClientRecipient && <div className="d-flex align-items-center mb-3">
                    <ThemedIcon className="me-2" icon="fa-triangle-exclamation" size="2x" variant="danger" />
                    <ThemedSpan variant="danger">This message will be sent to a client!</ThemedSpan>
                </div>
            }
            {projectId !== false && <div className="row mb-3">
                <ClientProjectSelect
                    label={"Project"}
                    setValue={(value) => updateThread("projectId", value.value)}
                    value={projectId}
                />
            </div>}

            <div className="row mb-3">
                <FormSelect
                    label={"Priority"}
                    options={["Low Priority", "Medium Priority", "High Priority"].map((label, value) => ({ label, value: (value + 1) * 10 }))}
                    placeholder="Priority..."
                    onChange={({value}) => updateMessage("messagePriority", value)}
                    defaultValue={priority}
                />
            </div>

            <div className="row mb-3">
                <FormCheck
                    className="d-flex align-items-center"
                    label={"Is an action required?"}
                    onChange={(value) => updateMessage("isActionRequired", value)}
                    isChecked={requiresAction}
                />
            </div>

            {requiresAction && <div className="row">
                <DateInput
                    label="Deadline Date"
                    value={deadlineDate}
                    onBlur={(value) => updateMessage("deadlineDate", value)}
                />
            </div>}
        </Modal.Body>
        <Modal.Footer>
            <IconButton icon={"fa-paper-plane"} variant="primary" onClick={onSend}></IconButton>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>

};

export default SendMessageConfirmationModal;
import React from "react";
import styled from "styled-components";
import toast from "react-hot-toast";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { useTheme } from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserDelegatesData } from "../hooks/UserDelegatesContext";
import { useDeleteDelegatedAccessMutation } from "../../../services/identity";

const ActionColumnParent = styled.td`
    width: ${({ width }) => width}%;
    cursor: pointer;
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
`;

const ActionColumnParentToggle = React.forwardRef(({ ...rest }, ref) => (
    <div ref={ref} {...rest}>
        <FontAwesomeIcon icon="fa-ellipsis" />
    </div>
))

const DelegatesGridTableActionColumn = ({ width = 2.5, data = null }) => {
    const menuId = React.useId();
    const theme = useTheme();
    const { selectDelegate } = useUserDelegatesData();
    const [deleteAccess, { isLoading }] = useDeleteDelegatedAccessMutation();

    const _delete = (delegateAccessId) => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose, ...rest }) => {
                return (
                    <ConfirmModal 
                        theme={theme}
                        show={true}
                        size="md"
                        isUpdating={isLoading}
                        confirmButtonLabel="Delete"
                        confirmButtonVariant="danger"
                        handleConfirm={() => _onDeleteConfirmed(delegateAccessId).finally(onClose)}
                        handleClose={onClose}
                        {...rest}
                    >
                        <p className="m-0">Deleting this record will remove all delegated access for that user. Are you sure you want to continue</p>
                    </ConfirmModal>
                )
            }
        });
    };

    const _onDeleteConfirmed = (delegateAccessId) => {
        return toast.promise(deleteAccess({ delegateAccessId }).unwrap(), {
            loading: 'Deleting delegate access...',
            success: (r) => {
                return `Delegate Access has been deleted.`;
            },
            error: (err) => {
                return `There was a problem trying to delete delegate access.`;
            }
        });
    };

    if (!data) {
        return null;
    }

     return (
        <ActionColumnParent width={width}>
            <Dropdown className="caret-off">
                <Dropdown.Toggle id={menuId} as={ActionColumnParentToggle} />
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => selectDelegate(data)}>
                        Edit
                    </Dropdown.Item>
                    <Dropdown.Item className="text-danger" onClick={() => _delete(data.delegateAccessId)}>
                        Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ActionColumnParent>
    );
};

export default DelegatesGridTableActionColumn;
/**
 * Layout file for the Client Reports Management section
 * Will display the tabs & outlet for each of the sections/features/pages for client report management
 * Detailed Views, Summary Views and Admin pages like uploading Report Templates
 */

import React from "react";
import styled from "styled-components";
import withProviders from "../hooks/withProviders";
import PageHeader, { PageHeaderTop, PageHeaderBottom } from "../components/layout/PageHeader";
import { Outlet } from "react-router-dom";
import { MasterAccountSelect } from "../components/forms/selects";
import { ReportManagementFiltersModal, ReportManagementFiltersModalButton } from "../features/reportmanagementfilters";
import { ClientReportManagementPageSubHeader, ThemedIcon, Main, ThemedParagraph } from "../components/utilities";
import { ManagementReportRouteTabs } from "../routes/management.reports.routes";
import { FilterProvider, useFilterContext, ReportManagementDefaultFilters } from "../hooks/FilterContext";

const ReportManagementHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const ReportManagementFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

export const ClientReportManagementFilterSubHeader = () => {
    const { 
        clearFilter, 
        filter: { masterAccounts }, 
        isFiltersApplied, 
        setFilter,
        setShowFilterModal
    } = useFilterContext();
    return (
        <ClientReportManagementPageSubHeader>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    {isFiltersApplied && (
                        <React.Fragment>
                            <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                            <ReportManagementFiltersAppliedWarningMessage className="ms-2 text-muted">
                                Warning - Filters are currently being applied to your results.
                                <br />
                                <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                            </ReportManagementFiltersAppliedWarningMessage>
                        </React.Fragment>
                    )}
                </div>
                <div className="d-flex flex-fill justify-content-end align-items-center">
                    <MasterAccountSelect
                        className="me-2 w-75"
                        label={null}
                        placeholder="Search for a Client Account"
                        isMulti={true}
                        valueObject={masterAccounts}
                        setValue={(values) => setFilter(original => {
                            return {
                                ...original,
                                masterAccounts: values,
                                masterAccountIds: values.map(el => el.value)
                            }
                        })}
                    />
                    <ReportManagementFiltersModalButton onClick={() => setShowFilterModal(true)} />
                </div>
            </div>
        </ClientReportManagementPageSubHeader>
    );
};

const ClientReportManagementLayout = ({ title = "Client Reports Management" }) => {
    const { showFilterModal, setShowFilterModal } = useFilterContext();
    return (
        <React.Fragment>
            <ReportManagementFiltersModal 
                handleClose={() => setShowFilterModal(false)}
                show={showFilterModal}
            />
            <PageHeader>
                <PageHeaderTop>
                    <ReportManagementHeaderTitle>
                        <span>{title}</span>
                    </ReportManagementHeaderTitle>
                </PageHeaderTop>
                <PageHeaderBottom>
                    <ManagementReportRouteTabs />
                </PageHeaderBottom>
            </PageHeader>
            <Main className="d-flex flex-column">
                <Outlet />
            </Main>
        </React.Fragment>
    )
};

export default withProviders([FilterProvider, { filterStore: "reports", defaultFilters: ReportManagementDefaultFilters }])(ClientReportManagementLayout);
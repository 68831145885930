import { useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchBackgroundSectionQuery } from "../../../services/clientfactfind";
import useNotes from "./useNotes";

const useBackgroundSection = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: section, isError, isLoading, error, refetch } = useFetchBackgroundSectionQuery({ masterAccountId });
    const { updateNotes } = useNotes();

    const patchNotes = (newText) =>
        updateNotes(section?.notesId, newText);

    const [isFetching, setIsFetching] = useState(false);

    const manualRefetch = () => {
        return new Promise((resolve, reject) => {
            setIsFetching(true);
            refetch().then(
                r => {
                    setIsFetching(false);
                    resolve(r);
                },
                e => reject(e)
            )
        })
    };

    return [{
        patchNotes,
        refetch: manualRefetch,
    }, {
        section,
        isError,
        isLoading: isLoading || isFetching,
        error
    }];
};

export default useBackgroundSection;
import React, { useState } from "react";
import styled from "styled-components";
import { ManagementReportingRouteTabs } from "../routes/management.reporting.routes";
import { Outlet } from "react-router-dom";
import { Main, ThemedIcon, ThemedParagraph } from "../components/utilities";
import PageHeader, {
  PageHeaderTop,
  PageHeaderBottom,
} from "../components/layout/PageHeader";

const ReportingManagementHeaderTitle = styled.div`
  flex: 1;
  display: flex;
  font-size: 16px;

  & > span:not(:last-child) {
    margin-right: 1rem;
  }
`;

const ReportingManagementLayout = () => {
  return (
    <React.Fragment>
      <PageHeader>
        <PageHeaderTop>
          <ReportingManagementHeaderTitle>
            <span>Management Reports</span>
          </ReportingManagementHeaderTitle>
        </PageHeaderTop>
        <PageHeaderBottom>
          <ManagementReportingRouteTabs />
        </PageHeaderBottom>
      </PageHeader>
      <Main className="d-flex flex-column">
        <section className="h-100 my-3 px-4">
          <Outlet />
        </section>
      </Main>
    </React.Fragment>
  );
};

export default ReportingManagementLayout;

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { NumberInput, PercentageInput } from "../../../components/forms";

const BorderNumberInput = styled(NumberInput)`
    border-color: ${({ isBest, isWorst }) => ((isBest && isWorst) || (!isBest && !isWorst))
        ? "transparent"
        : isBest
            ? "green"
            : isWorst
                ? "red"
                : "transparent"};
    color: ${({ isBest, isWorst }) => ((isBest && isWorst) || (!isBest && !isWorst))
        ? "inherit"
        : isBest
            ? "green"
            : isWorst
                ? "red"
                : "inherit"};
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    text-align: right;
`;

const BorderPercentInput = styled(PercentageInput)`
    border-color: ${({ isBest, isWorst }) => ((isBest && isWorst) || (!isBest && !isWorst))
        ? "transparent"
        : isBest
            ? "green"
            : isWorst
                ? "red"
                : "transparent"};
    color: ${({ isBest, isWorst }) => ((isBest && isWorst) || (!isBest && !isWorst))
        ? "inherit"
        : isBest
            ? "green"
            : isWorst
                ? "red"
                : "inherit"};
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    text-align: right;
`;

const ReturnProperties = {
    totalReturn1m: {
        header: "1 Month Return"
    },
    totalReturn3m: {
        header: "3 Months Return"
    },
    totalReturn6m: {
        header: "6 Months Return"
    },
    totalReturn1y: {
        header: "1 Year Return"
    },
    totalReturn3y: {
        header: "3 Years Return"
    },
    totalReturn5y: {
        header: "5 Years Return"
    },
    anVol3: {
        header: "3 Years Volatility"
    },
    maxdd3: {
        header: "3 Years Max DD"
    },
    sharpe3: {
        header: "3 Years Sharpe",
        isNumber: true
    }
};

const VolatilityProperties = {
    anVol3: {
        header: "3 Years Volatility"
    },
    maxdd3: {
        header: "3 Years Max DD"
    },
    maxg3: {
        header: "3 Years Max Gain"
    },
    anVol5: {
        header: "5 Years Volatility"
    },
    maxdd5: {
        header: "5 Years Max DD"
    },
    maxg5: {
        header: "5 Years Max Gain"
    },
    anVol10: {
        header: "10 Years Volatility"
    }
};

const makeDefaultPercentageDef = (header, field) => ({
    header,
    field,
    isWorstField: `isWorst${field.slice(0, 1).toUpperCase()}${field.slice(1)}`,
    isBestField: `isBest${field.slice(0, 1).toUpperCase()}${field.slice(1)}`,
    isSortable: true,
    formatValue: (value) => `${value?.toFixed(2)}%`,
    transformValue: ({ value, isBest, isWorst }) => <BorderPercentInput
        value={value}
        disabled
        allowNegative
        isBest={isBest}
        isWorst={isWorst} />
});

const makeDefaultNumberDef = (header, field) => ({
    header,
    field,
    isWorstField: `isWorst${field.slice(0, 1).toUpperCase()}${field.slice(1)}`,
    isBestField: `isBest${field.slice(0, 1).toUpperCase()}${field.slice(1)}`,
    isSortable: true,
    formatValue: (value) => value?.toFixed(2),
    transformValue: ({ value, isBest, isWorst }) => <BorderNumberInput
        value={value}
        disabled
        allowNegative
        isBest={isBest}
        isWorst={isWorst} />
});

const useFundAnalysisColumnDefs = ({ isVolatility, isGroupedByVolatility }) => {
    const navigate = useNavigate();

    const columnDefs = useMemo(() =>
        [{
            header: "Asset Name",
            field: "assetName",
            transformValue: ({ value, assetGroupId }) => <button className="anchor" onClick={() => navigate(`../../${assetGroupId}`)}>{value}</button>
        },
        // This returns false if isGroupedByVolatility is false, so Booleans are filtered out at the end
        (!isGroupedByVolatility) && {
            header: "Classification",
            field: "volatilityProfileDescription",
            transformValue: ({ value }) => <span>{value ?? "Not Assigned"}</span>
        },
        // Map over the relevant properties and create the appropriate column definition
        ...Object.keys(isVolatility ? VolatilityProperties : ReturnProperties).map((field) => {
            const { header, isNumber } = isVolatility ? VolatilityProperties[field] : ReturnProperties[field];
            if (isNumber)
                return makeDefaultNumberDef(header, field);

            return makeDefaultPercentageDef(header, field);
        })].filter(Boolean),
        [isGroupedByVolatility, isVolatility, navigate]);

    return columnDefs;
}

export default useFundAnalysisColumnDefs;
import React from "react";
import Skeleton from "react-loading-skeleton";
import { ThemedParagraph } from "../../../components/utilities";
import { useNavigate } from "react-router-dom";
import { useClientSearch } from "../contexts/ClientSearchContext";

const ResultsLoader = ({ onToggle, totalPagesToDisplay = 3 }) => {
    const appNavigator = useNavigate();
    const { data, isLoading, page, nextPage, totalCount, totalPages } = useClientSearch();

    const handleOpenClientSearchPage = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }
        appNavigator("/client/search");
    };

    if (isLoading === true) {
        return <Skeleton count={3} height={19} />
    }

    return <div className="row fs-smaller mt-2">
        <div className="col-md-auto">
            <ThemedParagraph variant="muted">Displaying {data.length.toLocaleString()} of {totalCount.toLocaleString()}</ThemedParagraph>
        </div>
        {totalPages > 1 && page < totalPages && <div className="col d-flex justify-content-end">
            {page >= totalPagesToDisplay && (
                <a href="#" onClick={handleOpenClientSearchPage}>Maximum page limit reached.</a>
            )}    
            {page < totalPagesToDisplay && (
                <a href="#" onClick={nextPage}>Load more.</a>
            )}
        </div>}
    </div>
};

export default ResultsLoader;
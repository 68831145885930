import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const hoApplicationDocumentsApiEnpdoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        searchApplicationDocuments: builder.query({
            query: ({ applicationId, ...request }) => ({
                url: `api/headofficeadmin/application/${applicationId}/documents`,
                method: 'POST',
                body: request
            }),
            serializeQueryArgs: ({ endpointName, queryArgs: { applicationId } }) => {
                return endpointName + "_" + applicationId;
            },
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        uploadApplicationDocuments: builder.mutation({
            query: ({ applicationId, request }) => ({
                url: `api/headofficeadmin/applicationdocuments/${applicationId}/documents/upload`,
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ applicationId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    data.forEach((fileResponse) => {
                        dispatch(wealthProApi.util.updateQueryData('searchApplicationDocuments', { applicationId }, ({ results, totalCount, ...rest }) => {
                            return {
                                results: [fileResponse?.data, ...results],
                                totalCount: totalCount + 1,
                                ...rest
                            }
                        }))
                    })
                }
                catch { }
            }
        })
    })
});

export const {
    useSearchApplicationDocumentsQuery,
    useLazySearchApplicationDocumentsQuery,
    useUploadApplicationDocumentsMutation
} = hoApplicationDocumentsApiEnpdoints;
import React from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchMasterAccountDashboardLoginsQuery } from "../../../services/clientauth";

const ClientDashboardLoginsContext = React.createContext();

const useClientDashboardLoginsData = () => React.useContext(ClientDashboardLoginsContext);

export const ClientDashboardLoginsProvider = ({ children }) => {
    const { account: { masterAccountId } } = useClientContext();
    const [sort, setSort] = React.useState("CreatedDate+desc");
    const [pageLimit, setPageLimit] = React.useState(50);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazyFetchMasterAccountDashboardLoginsQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results, newRecordsSinceCache } = data || { pagination: { page: 1, pageSize: pageLimit, totalCount: 0, totalPages: 1 }, results: [], newRecordsSinceCache: 0 };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true;

    const clear = () => {
        if (!masterAccountId || isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            searchText: searchTerm,
            sort,
            page: 1,
            limit: pageLimit
        })
        .unwrap()
        .then(
            _ => {
                setCacheKey(_prev => newCacheKey);   
            }
        )
        .finally(setIsLoadingState(_prev => false));
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        setIsLoadingState(_ => true);
        load({ 
            cacheKey, 
            masterAccountId, 
            searchText: searchTerm,
            sort,
            page: page + 1, 
            limit: pageLimit,   
        })
        .unwrap()
        .finally(setIsLoadingState(_prev => false));
    };

    const sortTable = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";
        let [sortProperty, direction] = sort.split("+");

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
        clear();
    };

    React.useEffect(clear, [ masterAccountId, searchTerm ]);

    return (
        <ClientDashboardLoginsContext.Provider value={{
            ...loadResults,
            clear,
            data: results,
            hasMore,
            isFetching,
            isLoading,
            isNextPageLoading,
            isNewRecords: newRecordsSinceCache > 0,
            isRowLoaded,
            limit: pageLimit,
            loadMore: isNextPageLoading ? () => {} : loadMore, 
            masterAccountId,
            newRecordsSinceCache,
            page,
            pagination,
            searchTerm,
            setSearchTerm,
            sort,
            sortTable,
            totalCount,
            totalPages
        }}>
            {children}
        </ClientDashboardLoginsContext.Provider>
    );
};

export default useClientDashboardLoginsData;
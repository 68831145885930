import React from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ClientDocumentsTableRowActionColParent = styled.td`
    cursor: pointer;
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
`;

const ClientDocumentsTableRowActionColToggle = React.forwardRef(({ ...rest }, ref) => {
    return (
        <div ref={ref} {...rest}>
            <FontAwesomeIcon icon="fa-ellipsis" />
        </div>
    );
});

export const DocumentOpenActionMenuItem = ({ documentId, openDocument }) => {

    const onHandleClick = () => {
        if (!openDocument || typeof (openDocument) !== 'function') {
            return;
        }
        openDocument(documentId);
    };

    return (
        <Dropdown.Item onClick={onHandleClick}>
            Open Document
        </Dropdown.Item>
    );
};

const ApplicationDocumentsGridRowActionCol = ({ documentId, openDocument }) => {
    const menuId = React.useId();
    return (
        <ClientDocumentsTableRowActionColParent>
            <Dropdown className="caret-off">
                <Dropdown.Toggle id={menuId} as={ClientDocumentsTableRowActionColToggle} />
                <Dropdown.Menu>
                    <DocumentOpenActionMenuItem
                        documentId={documentId}
                        openDocument={openDocument}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </ClientDocumentsTableRowActionColParent>
    );
};

export default ApplicationDocumentsGridRowActionCol;
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useAddMailAttachmentsMutation, useDeleteMailAttachmentMutation } from "../../../services/useremails";

const useAttachments = () => {
    const { selectedMailUserId: userId } = usePersonalOutlookContext();
    const [addTrigger] = useAddMailAttachmentsMutation();
    const [deleteTrigger] = useDeleteMailAttachmentMutation();

    const addAttachment = (mailId, request) => {
        return new Promise((resolve, reject) => {
            return addTrigger({ userId, mailId, request })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const deleteAttachment = (mailId, attachmentId) => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ userId, mailId, attachmentId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        addAttachment,
        deleteAttachment
    };
};

export default useAttachments;
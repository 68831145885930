import React, { useCallback, useEffect } from "react";
import { Income, ProductSelection } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

const InvestmentIncome = ({ instructionId }) => {

    const [{ fetchInvest }, { investIsError }] = useInstruction();

    useEffect(() => {
        fetchInvest({ instructionId });
    }, [fetchInvest, instructionId]);

    const retry = useCallback(() => {
        fetchInvest({ instructionId });
    }, [fetchInvest, instructionId])

    if (investIsError)
        return <InvestDivestErrorMessage retry={retry} />

    return <React.Fragment>
        <ProductSelection
            isInvest
            changeOwner
        />
        <Income incomeCommencementDate />
    </React.Fragment>;
}

export default InvestmentIncome;
import { wealthProApi } from '../../redux/api';

const designationApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["designations"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchTransactionDesignations: builder.query({
            query: ({masterAccountId}) => `api/valuations/designations/${masterAccountId}/select`
        })
    })
});

export const {
    useFetchTransactionDesignationsQuery
} = designationApiEndpoints;
import React from "react";
import FormSelect from "../../../components/forms/FormSelect";

export const Statuses = [
    { label: 'To Schedule', value: 0 },
    { label: 'Scheduled', value: 1 },
    { label: 'Rescheduled', value: 2 },
    { label: 'Cancelled', value: 3 },
    { label: 'Attended', value: 4 },
];

const ReviewAppointmentStatusSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={Statuses.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default ReviewAppointmentStatusSelect;
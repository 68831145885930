import { useMemo } from "react";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { useFetchApplicationTransactionsQuery } from "../../../services/headofficeadmin/hoApplicationEndpoints";
import { useFetchHeadOfficeTransactionStatusesQuery, useLazyFetchHeadOfficeTransactionStatusesQuery, usePatchApplicationTransactionMutation } from "../../../services/headofficeadmin";
import { patchReplace } from "../../../helpers/patchDoc";

const FeePaymentMethods = {
    PurchaseContract: 0,
    TradingAccount: 1,
    DirectPayment: 2,
    NotApplicable: 3,
    ExistingTradingCashBalance: 4
};

const useTransactions = () => {

    const { applicationId, application } = useHeadOfficeApplicationContext();
    const { data, error, isError, isFetching, isLoading, refetch } = useFetchApplicationTransactionsQuery({ applicationId });
    const { data: statuses, isLoading: isLoadingStatuses } = useFetchHeadOfficeTransactionStatusesQuery();
    const [patchTrigger] = usePatchApplicationTransactionMutation();
    const { hasBuyTransactions } = application || {};
    const hasPhasing = useMemo(() => data?.some(x => x.transactionPhase), [data]);
    const hasTransactionFees = useMemo(() => application?.feePaymentMethod === FeePaymentMethods.PurchaseContract, [application]);

    const patchTransaction = (transactionId, property, newValue, objectToAssign) => {

        return new Promise((resolve, reject) => {
            var operations = [patchReplace(property, newValue)];
            return patchTrigger({ transactionId, operations, applicationId, objectToAssign })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const retryFetchApplicationTransactions = (e) => {
        if (e && typeof (e.preventDefault)) {
            e.preventDefault();
        }

        refetch();
    };

    const tableColumns = [
        { label: 'Type', property: 'fullTypeText' },
        { label: 'SEDOL', property: 'sedol' },
        { label: 'Investment', property: 'assetName' },
        { label: 'Custodian ID', property: 'custodianReference' },
        { label: 'Status', property: 'statusText' },
        ...(hasPhasing ? [{ label: 'Phase', property: 'transactionPhase' }] : []),
        { label: 'Amount', property: 'transactionAmount' },
        { label: 'Quantity', property: 'quantity' },
        { label: 'Date', property: 'transactionDate' },
        ...((hasTransactionFees && hasBuyTransactions) ? [{ label: 'Fee', property: 'transactionFee' }] : [])
    ];

    return {
        tableColumns,
        data: data || [],
        statuses: statuses || [],
        error,
        isError,
        isFetching,
        isLoading,
        isLoadingStatuses,
        hasPhasing,
        hasTransactionFees,
        patchTransaction,
        retryFetchApplicationTransactions
    };
};

export default useTransactions;
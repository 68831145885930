import { useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext"
import { useFetchEstatePlanningSectionQuery, usePatchEstatePlanningSectionMutation, usePatchNotesMutation } from "../../../services/clientfactfind";

const useEstatePlanningSection = () => {
    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isError, error, refetch, isUninitialized } = useFetchEstatePlanningSectionQuery({ masterAccountId });
    const [patch] = usePatchEstatePlanningSectionMutation();
    const [patchNotes] = usePatchNotesMutation();

    const patchEstatePlanningSection = (estatePlanningId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return patch({ estatePlanningId, operations })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const patchNotesSection = (notesId, newText) => {
        return new Promise((resolve, reject) => {
            return patchNotes({ notesId, newText })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const [isFetching, setIsFetching] = useState(false);

    const manualRefetch = () => {
        setIsFetching(true);
        return new Promise((resolve, reject) => {
            refetch().then(
                r => {
                    setIsFetching(false);
                    resolve(r);
                },
                e => reject(e)
            )
        })
    }

    return {
        data: data || {},
        isLoading: isLoading || isFetching || isUninitialized,
        isError,
        error,
        refetchEstatePlanningSection: manualRefetch,
        patchNotesSection,
        patchEstatePlanningSection
    };
}

export default useEstatePlanningSection;
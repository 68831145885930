import React from "react";
import TableColumn from "./TableColumn";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import { DateDisplay } from "../../../components";
import BooleanPillDisplayColumn from "./BooleanPillDisplayColumn";
import useOpenDocument from "../../../hooks/useOpenDocument";
import { ProfileDisplay } from "../../../components";

const TableRow = ({ data, index, style }) => {
    const { height } = style;
    const { openDocument, isFetching: isOpening } = useOpenDocument();

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length) {
		return <TableLoadingRow style={{ height }} />
	}

    const { documentId, description, createdDate, createdById, isActive} = data[index];

    const handleOpenDocument = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        if (isOpening)
            return;

        openDocument(documentId, description);
    };

    return (
        <tr>
            <TableColumn style={{ height }}>
                <a href="#" onClick={handleOpenDocument}>{description}</a>
            </TableColumn>
            <TableColumn>
                <DateDisplay format={createdDate}>{createdDate}</DateDisplay>
            </TableColumn>
            <TableColumn>
                {createdById ? (<ProfileDisplay userId={createdById} />) : (<span>-</span>)}
            </TableColumn>
            <BooleanPillDisplayColumn value={isActive} falseText="Inactive" trueText="Active" />
        </tr>
    );
};

export default TableRow;
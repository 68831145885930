import React from "react";
import styled from "styled-components";
import ActiveUsers from "../../features/activeusers";
import CreateDropdown from "../../features/createdropdown";
import Favourites from "../../features/favourites";
import Notifications from "../../features/notifications";
import ProfileDropdown from "../../features/profiledropdown";

const HeaderLinksContainer = styled.div`
    grid-area: headerlinks;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0.5rem;

    & > div > a {
        display: flex;
        padding: 0.5rem;
        cursor: pointer;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.55);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    }

    & > div > a:hover,
    & > div > a:focus {
        color: rgba(0, 0, 0, 0.7);
    }

    @media (max-width: 767.98px) {
        display: none !important;
    }
`;

const HeaderLinks = () => {
    return <HeaderLinksContainer>
        <CreateDropdown />
        <Favourites />
        <Notifications />
        <ActiveUsers />
        <ProfileDropdown />
    </HeaderLinksContainer>
};

export default HeaderLinks;
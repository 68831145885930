import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "../../../../../components/buttons";
import { Modal } from "react-bootstrap";
import {
    DateInput
} from "../../../../../components/forms/";
import { valuesIn } from "lodash";


{/*export const DetailedLeagueTableModalButton = ({
    className,
    variant = "primary",
    ...rest
}) => {
    return (
        <div className={className}>
            <Button variant={variant} {...rest}>
                Create Report
            </Button>
        </div>
    );
};*/}

export const DetailedLeagueTableModal = ({
    confirmButtonLabel = "Run Report",
    confirmButtonVariant = "success",
    handleDetailedLeagueTableArgsClose,
    handleDetailedLeagueTableArgsConfirm,
    detailedLeagueTableArgsShow,
    ...rest
}) => {

    

    //const [ theReportDate, setTheReportDate ] = useState(new Date());
    const [ theReportDate, setTheReportDate ] = useState(moment().format('YYYY-MM-DD'));

    const onClose = () => {
        if (handleDetailedLeagueTableArgsClose && typeof handleDetailedLeagueTableArgsClose === "function") {
            handleDetailedLeagueTableArgsClose();
        }
    }

    const onConfirm = () => {
        const reportDate = theReportDate;
        if (handleDetailedLeagueTableArgsConfirm && typeof handleDetailedLeagueTableArgsConfirm === "function") {
            handleDetailedLeagueTableArgsConfirm(reportDate);
        }
        onClose();
    }

    

    
    return (
        <Modal size="sm" centered backdrop="static" show={detailedLeagueTableArgsShow}>
            <Modal.Header>Detailed League Table</Modal.Header>
            <Modal.Body {...rest}>
            <div className="row mb-3">
                    <div className="col">
                        <DateInput
                            label="Report Date"
                            value={theReportDate}
                            onBlur={(value) => setTheReportDate(value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmButtonVariant} onClick={onConfirm}>{confirmButtonLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                
            </Modal.Footer>
        </Modal>
    );

};

    export default DetailedLeagueTableModal;
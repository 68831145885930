import { useSummaryQueryMutation } from "../../../services/clientreviews";

const useReviewStatusGroup = (parameters) => {

    const [search, searchResult] = useSummaryQueryMutation()

    const onLoadOptions = () => search(parameters);

    return { 
        search: onLoadOptions,
         ...searchResult 
    };
    
};

export default useReviewStatusGroup;
import React from "react";
import moment from "moment";
import Moment from "react-moment";
import classNames from "classnames";
import useVerificationTokensList from "../hooks/useVerificationTokensList";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList } from "react-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "../../../components/messages";
import { LoadingMessage, Spinner } from "../../../components/loaders";
import { SensitiveTextDisplay, ThemedParagraph } from "../../../components/utilities";

const VerificationTokensListContext = React.createContext({
    className: null,
    reloadFn: null,
    error: null,
    isFetching: false,
    isLoading: false,
});

const VerificationTokensListDetail = ({ data, index, style, setRowHeight }) => { 
    const recordRef = React.useRef();
    const isLoading = index >= data.length;

    React.useEffect(() => {
        if (recordRef.current) {
            setRowHeight(index, recordRef.current.clientheight);
        }
    }, [ recordRef, data ]);

    return (
        <VerificationTokensListDetailInner 
            ref={recordRef}
            data={isLoading ? null : data[index]}
            isLoading={isLoading}
            style={style}
        />
    );
};

const VerificationTokenTypeDisplayPill = ({ type }) => (
    <div className="badge bg-dark" style={{ fontSize: '0.9em' }}>
        {{
            0: 'Welcome Code',
            1: 'Password Reset Code',
            2: 'Impersonation Token',
            3: 'Authenticator Request'
        }[type]}
    </div>
);

const VerificationTokenConsumedDisplayPill = ({ expiryDate, consumedDate }) => {
    const type = React.useMemo(() => {
        if (consumedDate) {
            return 1;
        }
        if (moment().isAfter(moment.utc(expiryDate))) {
            return 2;
        }
        return 0;
    }, [ expiryDate, consumedDate ]);
    
    return (
        <div className={classNames('badge', {
            'bg-warning text-dark': type === 0,
            'bg-success': type === 1,
            'bg-danger': type === 2
        })} style={{ fontSize: '0.9em' }}>
            {{
                0: 'Active',
                1: 'Consumed',
                2: 'Expired',
            }[type]}
        </div>
    );
};

const VerificationTokensListDetailInner = React.forwardRef(({ data, isLoading, style }, ref) => {
    const { height, ...rest } = style;
    
    if (isLoading) {
        return <div ref={ref} style={{ minHeight: height, ...rest }}>LOADING...</div>
    }

    const { type, verificationCode, verificationAttempts, ipAddress, createdDate, expiryDate, consumedDate, consumedByIpAddress } = data;
    return (
        <div ref={ref} style={{ minHeight: height, ...rest }}>
            <div className="border p-3">
                <div className="d-flex mb-3">
                    <div className="me-1">
                        <VerificationTokenTypeDisplayPill type={type} />
                    </div>
                    <div>
                        <VerificationTokenConsumedDisplayPill expiryDate={expiryDate} consumedDate={consumedDate} />
                    </div>
                </div>
                <div className="mb-3">
                    <SensitiveTextDisplay value={verificationCode} />
                </div>
                <ThemedParagraph variant="muted" className="m-0 text-xs">
                    Verification code was created on <Moment format="YYYY-MM-DD HH:mm">{createdDate}</Moment> by {!ipAddress || ipAddress.length <= 0 ? 'an unknown ip-address.' : `ip-address ${ipAddress}.`}
                    {!consumedDate && (<span> There have been {verificationAttempts} attempts made to verify.</span>)}
                    {consumedDate && (<span> This token was consumed on <Moment format="YYYY-MM-DD HH:mm">{consumedDate}</Moment> by {!consumedByIpAddress || consumedByIpAddress.length <= 0 ? 'an unknown ip-address.' : `ip-address ${consumedByIpAddress}.`}</span>)}
                </ThemedParagraph>
            </div>
        </div>
    );
});

const VerificationTokensListInner = React.forwardRef(({ children, style }, ref) => {
    const { className, reloadFn, error, isFetching, isLoading } = React.useContext(VerificationTokensListContext);

    if (error) {
        const { message } = error || { message: 'Sorry! We were unable to complete your request.' }
        const { height } = style;
        return (
            <div ref={ref} className={classNames('chat-error-parent', className)} style={{ height }}>
                <ErrorMessage message={message} retryCallback={reloadFn} />
            </div>
        );
    }

    return (
        <div ref={ref} className={classNames(className)} style={style}>
            {/* {!isLoading && reloadFn && typeof(reloadFn) === 'function' && (
                <div className="chat-reloader" onClick={reloadFn}>
                    <FontAwesomeIcon icon="fas fa-sync-alt" />
                </div>
            )} */}
            {isLoading && (
                <LoadingMessage text="Please wait whilst WealthPro fetches the verification tokens relating to this account." />
            )}
            {!isLoading && (
                <React.Fragment>
                    {children}
                    {isFetching && (
                        <div className="chat-fetching">
                            <Spinner />
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
});

const VerificationTokensList = ({ data, recordHeight = 127, recordPadding = 40 }) => {
    const listRef = React.useRef();
    const rowHeightRef = React.useRef({});
    const { 
        clear: refreshTable,
        data: sessions,
        error,
        isLoading,
        isNextPageLoading,
        isRowLoaded,
        loadMore,
        totalCount
    } = useVerificationTokensList(data?.masterAccountId, data?.id);

    const getRowHeight = (i) => {
        return (rowHeightRef.current[i] || (recordHeight + recordPadding));
    };

    const setRowHeight = (i, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeightRef.current = { ...rowHeightRef.current, [i]: size };
    }

    const DisplayRecord = React.useCallback((props) => <VerificationTokensListDetail setRowHeight={setRowHeight} {...props} />, []);

    if (!data) {
        return null;
    }

    return (
        <VerificationTokensListContext.Provider value={{
            className: null,
            error,
            reloadFn: refreshTable,
            isLoading,
            isFetching: isNextPageLoading
        }}>
            <AutoSizer>
                {({ height, width }) => (
                    <InfiniteLoader
                        isItemLoaded={isRowLoaded}
                        itemCount={totalCount}
                        loadMoreItems={loadMore}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VariableSizeList
                                ref={(e) => {
                                    listRef.current = e;
                                    ref(e);
                                }}
                                height={height}
                                width={width}
                                itemSize={getRowHeight}
                                itemCount={totalCount}
                                itemData={sessions}
                                innerElementType={VerificationTokensListInner}
                                onItemsRendered={onItemsRendered}
                            >
                                {DisplayRecord}
                            </VariableSizeList>
                        )}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </VerificationTokensListContext.Provider>
    );
};

export default VerificationTokensList;
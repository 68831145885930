import React from "react";
import FormSelect from "../FormSelect";
import { useFetchClientBusinessSourcesListQuery } from "../../../services/clientdetails";

const BusinessSourceSelect = ({ options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchClientBusinessSourcesListQuery();
    return <FormSelect
        options={data}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default BusinessSourceSelect;
import React, { useState, useEffect } from "react";
import { useClientContext } from "../../hooks/ClientContext";
import { Main } from "../../components/utilities";
import useValuationDetails from "./hooks/useValuationDetails";
import PortfolioBreakdown from "./components/PortfolioBreakdown";
import PortfolioWrapper from "./components/PortfolioWrapper";
import PortfolioDesignationSummary from "./components/PortfolioDesignationSummary";
import PortfolioDesignationSummaryHeaders from "./components/PortfolioDesignationSummaryHeaders";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from "react-number-format";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import { useNavigate } from 'react-router-dom';
import { ThemedParagraph } from "../../components/utilities";


const ClientPortfolio = ({ transactionsUrl = "transactions" }) => {
    const { id } = useClientContext();
    const { data, error, isError, isLoading, isFetching, reload } = useValuationDetails(id);

    const navigate = useNavigate();

    const navigateToClientTransactions = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        navigate(`../${transactionsUrl}`, { relative: 'path' });
    };

    const [activeKeys, setActiveKeys] = useState([]);

    const handleRetry = (e) => {
        e.preventDefault();
        reload(e);
    };

    //populate the active keys to open all accordions at the same time
    useEffect(() => {
        if (data !== undefined && data != null && data.designations !== undefined) {

            //When the data loads, set the active keys
            var result = data.designations.map(
                (designation) => designation.designationId
            );
            setActiveKeys(result);
        }
    }, [data]);

    
    if (error || isError === true) {
        return (
            <Main className="p-3">
                <PortfolioWrapper>
                    <ErrorMessageWrapper margin="0px auto">
                        <ErrorMessage
                            error={error}
                            retryCallback={handleRetry}
                        />
                    </ErrorMessageWrapper>
                </PortfolioWrapper>
            </Main>
        );
    }
    
    if(data == null && !isLoading && !isFetching){
        return (
            <Main className="p-3">

                <ThemedParagraph variant="muted">
                    Sorry! But, there are no investment transactions available for this account. Please create some  <a href={`../${transactionsUrl}`} onClick={navigateToClientTransactions}>investment transactions</a> to build the client portfolio.
                </ThemedParagraph>
            </Main>
        );
    }
    
    if (isLoading || isFetching) {
        return (
            <Main className="p-3">
                <PortfolioWrapper label="Breakdown">
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                </PortfolioWrapper>
                <PortfolioWrapper label="Designation">
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col-3">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col-3">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col-3">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                </PortfolioWrapper>
            </Main>
        );
    }

    return (
        <Main className="p-3">
            <PortfolioWrapper label="Breakdown" collapsedByDefault={true}>
                <PortfolioBreakdown data={data}/>
            </PortfolioWrapper>

            {data != null && data.designations != null && data.designations.map((el, i) => (
                <PortfolioWrapper 
                    key={`designation-${i}`}
                    label={`${el.designation.designation} - 
                        £${new Intl.NumberFormat('en-GB').format(el.designationValue)
                    }`}>


                    <PortfolioDesignationSummaryHeaders  key={`header-${i}`} />
                    {el.wrappers != null && el.wrappers.map((wrapper, j) =>
                            wrapper.products != null &&
                            wrapper.products.map((product) => (
                                <PortfolioDesignationSummary  key={`item-${j}`} designation={el} wrapper={wrapper} product={product}/>
                            ))
                    )}
                </PortfolioWrapper>
                ))}
        </Main>
    );
};

export default ClientPortfolio;

import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import useReminderAttachments from "../hooks/useReminderAttachments";
import toast from "react-hot-toast";
import { useReminderForm } from "../hooks/ReminderFormContext";

const RetryDocumentUploadModal = ({ onClose, show }) => {

    const { createResults, form: { watch } } = useReminderForm();
    const { isLoading, data } = createResults || {};
    const { documentUploads, id } = data || {}
    const formAttachments = watch("attachments");

    const { uploadAttachmentsResult: { isLoading: isUpdating, data: retryData }, uploadReminderAttachments } = useReminderAttachments(id);


    const fileNamesToUpload = useMemo(() => {
        if (Array.isArray(retryData)) {
            return retryData.map(x => x.fileName)
        }

        if (Array.isArray(documentUploads)) {
            return documentUploads.map(x => x.fileName)
        }

        return [];
    }, [documentUploads, retryData]);

    const handleRetry = (e) => {
        e.preventDefault();

        var filesToUpload = formAttachments.filter(x => fileNamesToUpload.some(y => x.name === y));

        toast.promise(uploadReminderAttachments(filesToUpload), {
            loading: "Retrying attachment upload...",
            error: "Unable to upload attachments",
            success: (data) => {
                if (data.some(x => x.status !== 200)) {
                    return "One or more attachments require further attention."
                }

                onClose(e);
                return "Attachments uploaded.";
            }
        });
    }


    return (
        <Modal centered show={show}>
            <Modal.Header>
                <ThemedSpan className="d-flex align-items-center">
                    <FontAwesomeIcon icon="exclamation-triangle" className="text-danger me-3" size="2x" />
                    Retry Attachments
                </ThemedSpan>

            </Modal.Header>
            <Modal.Body>
                <ThemedSpan>Some attachments failed to upload:</ThemedSpan>
                <ul>
                    {fileNamesToUpload.map(fileName => (
                        <li>{fileName}</li>
                    ))}
                </ul>
                <ThemedSpan>Please retry the upload below.</ThemedSpan>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                <Button disabled={isUpdating} onClick={handleRetry}>Retry</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RetryDocumentUploadModal;
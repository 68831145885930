import { wealthProApi } from "../../redux/api";

const userCalendarMasterAccountApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountDetails"]
}).injectEndpoints({
    endpoints: builder => ({
        getMasterAccountEventPreferences: builder.query({
            query: ({masterAccountId}) => `api/clientdocuments/masterAccounts/${masterAccountId}/eventPreferences`
        })
    })
});

export const {
    useGetMasterAccountEventPreferencesQuery,
    useLazyGetMasterAccountEventPreferencesQuery
} = userCalendarMasterAccountApiEndpoints;
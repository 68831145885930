import { wealthProApi } from "../../redux/api";


const userThreadsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchThreadMessagesForUser: builder.query({
            query: ({ userId, threadId, next = null, limit }) => (
                next ? `api/messaging/users/${userId}/threads/${threadId}/messages?next=${next}&limit=${limit}`
                    : `api/messaging/users/${userId}/threads/${threadId}/messages?limit=${limit}`
            ),
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                const { userId, threadId } = queryArgs || {};
                return `${endpointName}-${userId}-${threadId}`
            },
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isPageOne = !previous;

                if (isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        })
    })
});

export const {
    useLazyFetchThreadMessagesForUserQuery
} = userThreadsApiEndpoints;
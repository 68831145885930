import { patchReplace } from "../../../helpers/patchDoc";
import { usePatchReminderTaskMutation, useUploadReminderAttachmentMutation } from "../../../services/reminders";

const useReminder = (reminderId, cacheKey = null) => {
    const [patch, patchResult] = usePatchReminderTaskMutation();
    const [uploadFiles, uploadFilesResult] = useUploadReminderAttachmentMutation();

    const patchReminderByOperations = (operations) => {
        return new Promise((resolve, reject) => {
            return patch({ reminderId, operations, cacheKey })
                .unwrap()
                .then(resolve, reject);
        })
    }

    const patchReminder = (property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patchReminderByOperations(operations)
                .then(resolve, reject);
        })
    };

    const uploadAttachment = (files = [], fileInfo = [], { masterAccountId, projectId }) => {
        return new Promise((resolve, reject) => {
            var request = new FormData();
            files.forEach((file) => {
                request.append(file.name, file);
            });

            request.append("fileDetails", JSON.stringify(fileInfo));

            return uploadFiles({ reminderId, masterAccountId, projectId, request })
                .unwrap()
                .then(resolve, reject);
        });
    };

    return {
        patchReminder,
        patchReminderByOperations,
        uploadAttachment,
        patchResult,
        uploadFilesResult
    };

};

export default useReminder;
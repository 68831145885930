import PersonalEmailLayout from "../layouts/PersonalEmailLayout";
import {
    PersonalDiaryPage,
    PersonalEmailsPage,
    PersonalFavouritesPage,
    PersonalMessagesPage,
    PersonalNotificationsPage,
    PersonalRemindersPage
} from "../pages/personal";

const PersonalRoutes = [{
    path: "/personal/*",
    defaultRoute: "/personal/messages",
    children: [
        {
            path: 'diary',
            roles: [],
            element: PersonalDiaryPage
        },
        {
            path: 'emails',
            roles: [],
            element: PersonalEmailLayout,
            children: [
                {
                    path: ":folderId",
                    roles: [],
                    element: PersonalEmailsPage
                }
            ]
        },
        {
            path: 'favourites',
            roles: [],
            element: PersonalFavouritesPage
        },
        {
            path: 'messages',
            roles: [],
            element: PersonalMessagesPage
        },
        {
            path: 'notifications',
            roles: [],
            element: PersonalNotificationsPage
        },
        {
            path: 'reminders',
            roles: [],
            element: PersonalRemindersPage
        }
    ]
}];

export default PersonalRoutes;
import styled from "styled-components";

const FooterLeft = styled.div`
    grid-area: fleft;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 575.98px) {
        display: none;
    }
`;

export default FooterLeft;
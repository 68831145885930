import React from "react";
import Button from "../../components/buttons";
import { Modal } from "react-bootstrap";

const ConfirmModal = ({ 
    theme = {},
    size = "lg",
    isUpdating = false,
    title = "Are you sure?", 
    message = "Are you sure you want to perform this action?", 
    confirmButtonLabel = "Confirm",
    confirmButtonVariant = "success",
    handleConfirm = () => {}, 
    handleClose = () => {},
    show = true,
    children = null
}) => {

    const _confirm = () => {
        handleConfirm();
    };

    return (
        <Modal size={size} centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children ?? (<p className="m-0">{message}</p>)}
            </Modal.Body>
            <Modal.Footer>
                <Button theme={theme} variant={confirmButtonVariant} onClick={_confirm} disabled={isUpdating}>{confirmButtonLabel}</Button>
                <Button theme={theme} variant="light" onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
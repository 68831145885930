
import { ConfirmModal } from "../../components/modals";
import { FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import CorporateActionPairsProvider, { useCorporateActionPairs } from "./hooks/CorporateActionPairsContext";
import React, { useState } from "react";
import toast from "react-hot-toast";
import CorporateActionPairsGridFilterModal from "./components/CorporateActionPairsGridFilterModal";
import EditCorporateActionPairModal from "./components/EditCorporateActionPairModal";
import { useFilterContext } from "../../hooks/FilterContext";
import CorporateActionPairsGrid from "./components/CorporateActionPairsGrid";
import { Button } from "../../components";

const CorporateActionPairsHeader = () => {
    const [isFiltersShown, setIsFiltersShown] = useState();
    const {
        createPair,
        createResult,
        deletePair,
        pairToDelete,
        setPairToDeleteId
    } = useCorporateActionPairs();

    const {
        clearFilter,
        isFiltersApplied,
    } = useFilterContext();

    const onCreate = (e) => {
        e.preventDefault();

        toast.promise(createPair(), {
            loading: "Creating action pair...",
            error: "Unable to create action pair.",
            success: "Action pair created!"
        });
    };

    const onDelete = () => {
        toast.promise(deletePair(), {
            loading: "Deleting action pair...",
            error: "Unable to delete action pair.",
            success: () => {
                setPairToDeleteId(null);
                return "Action pair deleted!";
            }
        });
    };

    return (
        <>
            <PageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                </FiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex flex-fill flex-row-reverse">
                        <Button className="ms-3" onClick={() => setIsFiltersShown(true)}>
                            Filter Action Pairs
                        </Button>

                        <Button
                            onClick={onCreate}
                            disabled={createResult.isLoading}
                            variant="success"
                            className="d-flex align-items-center"
                        >
                            <ThemedIcon variant="light" className="me-1" icon="plus" />
                            Create New Action Pair
                        </Button>
                    </div>
                </div>
            </PageSubHeader>
            <CorporateActionPairsGridFilterModal
                show={isFiltersShown}
                onHide={() => setIsFiltersShown(null)}
            />
            <ConfirmModal
                title={"Delete Corporate Action Pair"}
                message={`Are you sure you want to delete the pair Out: ${pairToDelete?.sedolOut && pairToDelete?.sedolIn
                    ? pairToDelete?.sedolOut + ", In: " + pairToDelete?.sedolIn
                    : "this pair"}?`}
                handleClose={() => setPairToDeleteId(null)}
                handleConfirm={onDelete}
                confirmButtonLabel="Delete"
                confirmButtonVariant="danger"
                show={pairToDelete !== null}
            />
            <EditCorporateActionPairModal />
        </>
    )
};


const CorporateActionPairsManagement = () => (
    <CorporateActionPairsProvider>
        <Main className="d-flex flex-column">
            <CorporateActionPairsHeader />
            <section className="px-4 my-3 h-100">
                <CorporateActionPairsGrid />
            </section>
        </Main>
    </CorporateActionPairsProvider>
);

export default CorporateActionPairsManagement;
import { useMemo, useRef, useState } from "react"
import { Button, Navbar, Overlay, Tooltip } from "react-bootstrap";
import Spinner from "./Spinner";

const LoadingToggler = ({ isLoading = false, disabled = false, disabledText, enabledText, ...innerProps }) => {
  const togglerRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipText = useMemo(() => disabled ? disabledText : enabledText, [disabled, disabledText, enabledText])

  if (isLoading) {
    return <Button disabled variant="secondary-outline">
      <Spinner />
    </Button>
  }

  return <>
    <Navbar.Toggle ref={togglerRef} disabled={disabled} onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} {...innerProps} />

    <Overlay target={togglerRef.current} show={showTooltip} placement="top">
      <Tooltip >
        {tooltipText}
      </Tooltip>
    </Overlay>
  </>

}

export default LoadingToggler;
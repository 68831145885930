import { useFetchAgreedVolatilityProfileSectionQuery } from "../../../services/clientfactfind";
import { useLazyExportTestCertificatePdfQuery } from "../../../services/valuations";

const useTestCertificateExport = (masterAccountId) => {
    // Fetch target profiles first for test certificate request
    const { data, isLoading: isLoadingProfiles } = useFetchAgreedVolatilityProfileSectionQuery({ masterAccountId });
    const { profiles } = data || { profiles: [] };

    const [exportTestCertificate, testCertificateResult] = useLazyExportTestCertificatePdfQuery();

    const exportTestCertificatePdf = (endDate) => {
        return new Promise((resolve, reject) => {
            if (profiles.length === 0)
                reject("No profiles found");

            return exportTestCertificate({
                masterAccountId,
                endDate,
                targetProfiles: profiles
            }).unwrap()
                .then(
                    r => {
                        if (r.data)
                            resolve(r.data);
                        else
                            reject("No data returned");
                    },
                    e => reject(e)
                );
        });
    };

    return {
        isLoadingProfiles,
        exportTestCertificatePdf,
        testCertificateResult
    }
}

export default useTestCertificateExport;
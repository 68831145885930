import React from "react";
import store from "../redux/store";
import ProtectedRoute from "../components/ProtectedRoute";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { AuthProvider } from "react-oidc-context";
import { NavigationProvider } from "../hooks/NavigationContext";
import { SessionProvider } from "./SessionContext";
import { SocketProvider } from "../hooks/SocketContext";
import { AppErrorPage, SplashPage } from "../pages";

const TenantContext = React.createContext();

const useTenant = () => React.useContext(TenantContext);

export const TenantProvider = ({ children }) => {
    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        if (data) {
            return;
        }

        fetch('/tenant')
            .then(response => response.json())
            .then(data => {
                const { theme, oidc } = data;
                sessionStorage.setItem('oidc.config', JSON.stringify(oidc));
                setData(_ => {
                    return {
                        theme,
                        oidc: {
                            authority: `${oidc.host}/realms/${oidc.realm}`,
                            client_id: oidc.client,
                            redirect_uri: window.location.origin,
                            onSigninCallback: () => {
                                window.history.replaceState({}, document.title, window.location.pathname);
                            }
                        }
                    }
                });
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsLoading(_ => false);
            });
    }, [data]);

    const { theme, oidc } = data || { theme: null, oidc: null };
    return (
        <TenantContext.Provider value={{ theme, oidc }}>
            {isLoading === true && (
                <SplashPage />
            )}
            {isLoading === false && !data && (
                <AppErrorPage />
            )}
            {isLoading === false && data && (
                <AuthProvider {...oidc}>
                    <ProtectedRoute>
                            <Provider store={store}>
                                <NavigationProvider>
                                    <SocketProvider>
                                        <ThemeProvider theme={theme}>
                                            <SessionProvider>
                                                {children}
                                            </SessionProvider>
                                        </ThemeProvider>
                                    </SocketProvider>
                                </NavigationProvider>
                            </Provider>
                    </ProtectedRoute>
                </AuthProvider>
            )}
        </TenantContext.Provider>
    );
};

export default useTenant;
import { useLazyBusinessStatusReportQuery } from "../../../services/headofficeadmin";

const useBusinessStatusReport = () => {

    const [report, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyBusinessStatusReportQuery();

    const getBusinessStatusReport = (theRequest) => {
        const fromDate = theRequest.fromDate;
        const toDate = theRequest.toDate;
        const adviserIds = theRequest.adviserIds;
        return new Promise((resolve, reject) => {
            
            return report({data: {fromDate: fromDate, toDate: toDate, adviserIds: adviserIds}}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    return {
        getBusinessStatusReport
    }

};

export default useBusinessStatusReport;
import styled from "styled-components";

const ListBox = styled.ul`
    margin-top: 0;
    margin-bottom: .25rem;

    & > li {
        display: inline-block;
        padding: 0 7px;
    }

    & > li > a {
        color: unset;
    }

    & > li:not(:last-child) {
        border-right: 1px solid var(--bs-body-color);
    }

    @media (max-width: 576px), (max-width: 767.98px) {
        & > li {
            display: block;
        }

        & > li:not(:last-child) {
            border: none;
        }
    }
`;

export default ListBox;
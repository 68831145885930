import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import _ from "lodash";

const ProductsGridRowActionColToggle = React.forwardRef(({ children, onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const ProductsGridRowActionCol = ({ deleteProduct, setShowEdit, onShowProductCosts }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    return (
        <Dropdown id={menuId} className="caret-off w-100">
            <Dropdown.Toggle as={ProductsGridRowActionColToggle} id={toggleId} />
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowEdit(true)}>
                    Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={onShowProductCosts}>
                    Product Costs
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={deleteProduct}>
                    Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

};

export default ProductsGridRowActionCol;
import { useEffect, useState } from "react";
import { useCreateHeadOfficePlatformAccountDesignationMappingMutation, useFetchNewPotentialPlatformAccountDesignationMatchesQuery } from "../../../services/headofficeadmin";

const useMissingAccountMatches = () => {

    const [showModal, setShowModal] = useState(false);
    const [createMappingTrigger] = useCreateHeadOfficePlatformAccountDesignationMappingMutation();
    const potentialMatches = useFetchNewPotentialPlatformAccountDesignationMatchesQuery();
    const { data, isError, refetch } = potentialMatches;

    const createPlatformAccountDesignationMapping = (request) => {
        return new Promise((resolve, reject) => {
            return createMappingTrigger(request)
                .unwrap()
                .then(resolve, reject);
        });
    };

    const retryFetch = (e) => {
        if (typeof e?.preventDefault === "function") {
            e.preventDefault();
        }

        refetch();
    };

    useEffect(() => {
        if (data?.length > 0 || isError) {
            return setShowModal(true);
        }

        setShowModal(false);
    }, [data, isError]);

    return {
        ...potentialMatches,
        showModal,
        createPlatformAccountDesignationMapping,
        retryFetch,
        setShowModal
    };
};

export default useMissingAccountMatches;
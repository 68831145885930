const { wealthProApi } = require("../../redux/api");

const recommendationsDefEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        recsFetchInstructionTypes: builder.query({
            query: ({ listType }) => `api/recommendations/InstructionType${listType ? `?listType=${listType}` : ""}`
        }),
        recsFetchInstructionTypeProducts: builder.query({
            query: ({ instructionTypeId, listType }) => `api/recommendations/Product/InstructionType/${instructionTypeId}${listType ? `?listType=${listType}` : ""}`
        }),
        recsFetchInstructionTypeWrappers: builder.query({
            query: ({ instructionTypeId }) => `api/recommendations/ProductWrappers/${instructionTypeId}`
        }),
        recsFetchTransactionTypes: builder.query({
            query: ({ isSelling, listType }) => `api/recommendations/TransactionType?isSelling=${isSelling}${listType ? `&listType=${listType}` : ""}`
        }),
        recsFetchPaymentMethodOptions: builder.query({
            query: ({ listType }) => `api/recommendations/PaymentMethod${listType ? `?listType=${listType}` : ""}`
        }),
        recsFetchPostToOptions: builder.query({
            query: () => `api/recommendations/PostToOption`
        }),
        recsFetchProductCostTypes: builder.query({
            query: () => `api/recommendations/CostType`
        }),
        recsFetchCalculations: builder.query({
            query: () => `api/recommendations/Calculation`
        }),
        recsFetchStandaloneFeeTypes: builder.query({
            query: ({ listType }) => `api/recommendations/StandaloneFeeType${listType ? `?listType=${listType}` : ""}`
        }),
        recsFetchStandardPortfolios: builder.query({
            query: ({ listType }) => `api/recommendations/Portfolio${listType ? `?listType=${listType}` : ""}`
        })
    })
});

export const {
    useRecsFetchCalculationsQuery,
    useRecsFetchInstructionTypeProductsQuery,
    useRecsFetchInstructionTypeWrappersQuery,
    useRecsFetchInstructionTypesQuery,
    useRecsFetchPaymentMethodOptionsQuery,
    useRecsFetchPostToOptionsQuery,
    useRecsFetchProductCostTypesQuery,
    useRecsFetchStandaloneFeeTypesQuery,
    useRecsFetchStandardPortfoliosQuery,
    useRecsFetchTransactionTypesQuery,
} = recommendationsDefEndpoints;
import _ from "lodash";
import React from "react";
import StandardTable from "../../../components/tables/StandardTable";
import useTransactions from "../hooks/useTransactions";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import ApplicationTransactionRow from "./ApplicationTransactionRow";
import { CenterArea, CenterPage } from "../../../components/utilities";
import { Spinner } from "../../../components/loaders";


const ApplicationTransactionsList = () => {

    const {
        tableColumns,
        data,
        statuses,
        isLoadingStatuses,
        isLoading,
        isError,
        error,
        hasPhasing,
        hasTransactionFees,
        patchTransaction,
        retryFetchApplicationTransactions
    } = useTransactions();

    if (isLoading) {
        return <CenterPage>
            <Spinner />
        </CenterPage>;
    }

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retryFetchApplicationTransactions} />
        </ErrorMessageWrapper>
    }

    return <StandardTable
    parentClassName="h-100 p-2"
        data={data}
        columns={tableColumns}
        RowComponent={ApplicationTransactionRow}
        rowComponentProps={{
            statuses,
            isLoadingStatuses,
            hasPhasing,
            hasTransactionFees,
            patchFn: patchTransaction
        }}
    />;

};

export default ApplicationTransactionsList;
import { wealthProApi } from "../../redux/api";

const assetWeightingsApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        createAssetWeighting: build.mutation({
            query: ({ }) => ({
                url: 'api/assets/weighting',
                method: 'POST',
                body: {}
            })
        }),
        deleteAssetWeighting: build.mutation({
            query: ({ weightingId }) => ({
                url: `api/assets/weighting/${weightingId}`,
                method: 'DELETE',
            })
        }),
        patchAssetWeighting: build.mutation({
            query: ({ weightingId, operations }) => ({
                url: `api/assets/weighting/${weightingId}`,
                method: 'PATCH',
                body: operations
            })
        }),
        fetchAssetWeightingsForGrid: build.query({
            query: ({ page, limit, sort, filterModel }) => ({
                url: 'api/assets/weighting/grid',
                method: 'POST',
                body: {
                    page,
                    limit,
                    sort,
                    filterModel
                }
            })
        }),
        fetchWeightingTypes: build.query({
            query: () => 'api/assets/weighting/types'
        })
    })
});

export const {
    useCreateAssetWeightingMutation,
    useDeleteAssetWeightingMutation,
    usePatchAssetWeightingMutation,
    useLazyFetchAssetWeightingsForGridQuery,
    useLazyFetchWeightingTypesQuery
} = assetWeightingsApiEndpoints;
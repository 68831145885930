import { EditableTable } from "../../../components/tables";
import { AccordionHeader, AccordionListItem } from "../../../components/utilities";
import useGeneralRules from "../hooks/useGeneralRules";
import TableAccordionBody from "./TableAccordionBody";

const ClassificationRangeTable = () => {
    const {
        generalRules,
        createClassificationRange,
        isCreatingClassificationRange,
        patchClassificationRange,
        classificationRangeColumns
    } = useGeneralRules();

    return <AccordionListItem eventKey='score-ranges'>
        <AccordionHeader>Classification Score Ranges</AccordionHeader>
        <TableAccordionBody>
            <EditableTable
                columns={classificationRangeColumns}
                data={generalRules?.classificationRanges ?? []}
                idProperty={"rangeId"}
                createFn={createClassificationRange}
                isCreating={isCreatingClassificationRange}
                patchRowFn={patchClassificationRange}
                typeDescription={"Score Ranges"}
                rowComponentProps={{ customCellProps: { fullData: generalRules?.classificationRanges } }}
            />
        </TableAccordionBody>
    </AccordionListItem>
}

export default ClassificationRangeTable;
import _ from "lodash";

export const useCacheKeyMergeHandler = (currentCache, newItems) => {
    if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
        currentCache.pagination = newItems.pagination;
        currentCache.results.push(...newItems.results);
    }
    else {
        currentCache.newRecordsSinceCache = 0;
        currentCache.cacheKey = newItems.cacheKey;
        currentCache.pagination = newItems.pagination;
        currentCache.results = [...newItems.results];
    }             
};

export const useCacheKeyTransformResponseHandler = (response, _, { cacheKey }) => {
    return { cacheKey, newRecordsSinceCache: 0, ...response };
};

export const useCacheKeySerializeQueryArgsHandler = ({ endpointName }) => {
    return endpointName;
};
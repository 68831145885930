import AdministrationApplicationsLayout, { AdministrationApplicationsBaseLayout } from "../layouts/AdministrationApplicationsLayout";
import { NavigationTabs } from "../components/navigation";
import { 
    ApplicationsBrokerFormPage,
    ApplicationsCashPage,
    ApplicationsDocumentsPage,
    ApplicationsFeesPage,
    ApplicationsManagementPage,
    ApplicationsMessagesPage,
    ApplicationsSummaryPage,
    ApplicationsTransactionsPage
} from "../pages/administration";

const AdministrationApplicationRoutes = {
    path: 'applications/*',
    pathBase: '/administration/applications',
    roles: [],
    element: AdministrationApplicationsBaseLayout,
    children: [
        { path: '', element: ApplicationsManagementPage },
        {
            path: ':applicationId/*',
            pathBase: '/administration/applications/:applicationId',
            defaultRoute: 'summary',
            roles: [],
            element: AdministrationApplicationsLayout,
            children: [
                {
                    path: 'summary',
                    icon: 'fa-chart-simple',
                    label: 'Summary',
                    roles: [],
                    hideForPolicy: false,
                    element: ApplicationsSummaryPage
                },
                {
                    path: 'cash',
                    icon: 'fa-sterling-sign',
                    label: 'Cash',
                    roles: [],
                    hideForPolicy: true,
                    element: ApplicationsCashPage
                },
                {
                    path: 'transactions',
                    icon: 'fa-arrow-right-arrow-left',
                    label: 'Transactions',
                    roles: [],
                    hideForPolicy: true,
                    element: ApplicationsTransactionsPage
                },
                {
                    path: 'fees',
                    icon: 'fa-money-bill',
                    label: 'Fees',
                    roles: [],
                    hideForPolicy: false,
                    element: ApplicationsFeesPage
                },
                {
                    path: 'messages',
                    icon: 'fa-comments',
                    label: 'Messages',
                    roles: [],
                    hideForPolicy: false,
                    element: ApplicationsMessagesPage
                },
                {
                    path: 'documents',
                    icon: 'fa-file',
                    label: 'Documents',
                    roles: [],
                    hideForPolicy: false,
                    element: ApplicationsDocumentsPage
                },
                {
                    path: 'brokerform',
                    icon: 'fa-file-circle-check',
                    label: 'Broker Form',
                    roles: [],
                    hideForPolicy: false,
                    element: ApplicationsBrokerFormPage
                }
            ]
        }
    ]
};

const AdministrationApplicationTabRoutes = AdministrationApplicationRoutes.children[1];

export const AdministrationApplicationRouteTabs = ({ isPolicy }) => (
    <NavigationTabs 
        pathBase={AdministrationApplicationTabRoutes.pathBase} 
        options={{
            defaultRoute: AdministrationApplicationTabRoutes.defaultRoute,
            children: isPolicy 
                ? AdministrationApplicationTabRoutes.children.filter(x => !x.hideForPolicy)
                : AdministrationApplicationTabRoutes.children
        }} 
    />
);

export default AdministrationApplicationRoutes;
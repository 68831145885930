import React, { useEffect, useMemo } from "react";
import {
    useCreateMessageForUserMutation,
    useDeleteDraftMessageMutation,
    useLazyFetchThreadMessagesForUserQuery,
    usePatchClientMessageMutation,
    usePatchThreadMutation,
    useSendUserMessageMutation,
    useToggleMessageIsDoneForUserMutation,
    useUpdateMessageRecipientsMutation,
    useUploadMessageAttachmentsForUserMutation
} from "../../../services/messages";
import { patchReplace } from "../../../helpers/patchDoc";
import { useLazyGetThreadByIdQuery } from "../../../services/messages/messagesThreadsApiEndpoints";


const MessageThreadContext = React.createContext();

export const useMessageThreadContext = () => React.useContext(MessageThreadContext);

const MessageThreadProvider = ({ children, threadId, userId }) => {

    const [fetchThreadMessages, threadMessagesResult] = useLazyFetchThreadMessagesForUserQuery();
    const [getThreadTrigger, getThreadResult] = useLazyGetThreadByIdQuery();
    const [createDraftTrigger, createDraftResult] = useCreateMessageForUserMutation();
    const [deleteDraftTrigger, deleteDraftResult] = useDeleteDraftMessageMutation();
    const [sendTrigger, sendResult] = useSendUserMessageMutation();
    const [toggleDoneTrigger, toggleDoneResult] = useToggleMessageIsDoneForUserMutation();
    const [updateRecipientsTrigger, updateRecipientsResult] = useUpdateMessageRecipientsMutation();
    const [updateDraftTrigger, updateDraftResult] = usePatchClientMessageMutation();
    const [updateThreadTrigger, updateThreadResult] = usePatchThreadMutation();
    const [uploadAttachment, uploadAttachmentResult] = useUploadMessageAttachmentsForUserMutation();

    const limit = 10;
    const { data, isLoading, isFetching, isError, error } = threadMessagesResult || {};
    const { results, totalCount, next, previous } = data || { results: [], totalCount: limit };

    const createDraft = () => {
        return new Promise((resolve, reject) => {
            return createDraftTrigger({ userId, threadId })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const deleteDraft = (messageId) => {
        return new Promise((resolve, reject) => {
            return deleteDraftTrigger({ messageId, userId, threadId })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const hasItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => {
        if (!userId) return;

        return new Promise((resolve, reject) => {
            return fetchThreadMessages({ userId, threadId, next, limit })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const retry = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        fetchThreadMessages({ userId, threadId, next, limit });
    };

    const reply = (originalMessage) => {
        const { recipients, senderId } = originalMessage;

        const recipientIds = (senderId === userId
            ? recipients.filter(x => x.userId !== userId)
            : [...recipients.filter(x => x.userId !== userId), { userId: senderId }]
        ).map(x => x.userId);

        return new Promise((resolve, reject) => {
            return createDraftTrigger({ userId, threadId, recipientIds })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const retryGetThread = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        getThreadTrigger({ threadId });
    }

    const send = (messageId) => {
        return new Promise((resolve, reject) => {
            return sendTrigger({ messageId, threadId, userId })
                .unwrap()
                .then(resolve, reject);
        });
    }

    const toggleDone = (messageId, newValue) => {
        return new Promise((resolve, reject) => {
            return toggleDoneTrigger({ userId, messageId, threadId, newValue })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const updateDraft = (messageId, property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return updateDraftTrigger({ messageId, threadId, userId, operations })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const updateDraftRecipients = (messageId, recipients) => {
        return new Promise((resolve, reject) => {
            return updateRecipientsTrigger({ userId, threadId, messageId, recipients })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const updateThread = (property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return updateThreadTrigger({ threadId, userId, operations })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const uploadMessageAttachment = (messageId, attachments, extendedFileData, { masterAccountId }) => {
        const request = new FormData();

        request.append('messageId', messageId);

        if (masterAccountId) {
            request.append('masterAccountId', masterAccountId);
        }

        attachments?.forEach((file, index) => request.append(file.name, file, file.name));

        extendedFileData?.forEach((info, i) => {
            request.append(`fileDetails[${i}].fileName`, info.fileName);
            request.append(`fileDetails[${i}].description`, info.description);
            request.append(`fileDetails[${i}].type`, info.type);
            request.append(`fileDetails[${i}].function`, info.selector);
        });

        return new Promise((resolve, reject) => {
            return uploadAttachment({ request, messageId, threadId, userId })
                .unwrap()
                .then(resolve, reject);
        });
    };

    useEffect(() => {
        if (threadId) getThreadTrigger({ threadId });
    }, []);

    return <MessageThreadContext.Provider value={{
        error,
        getThreadResult,
        createDraftResult,
        deleteDraftResult,
        sendResult,
        updateRecipientsResult,
        updateDraftResult,
        updateThreadResult,
        uploadAttachmentResult,
        isError,
        isFetching,
        isLoading,
        results,
        totalCount: results?.length === totalCount ? totalCount : results?.length + 3,
        threadId,
        threadMessagesResult,
        toggleDoneResult,
        userId,
        createDraft,
        deleteDraft,
        hasItemLoaded,
        loadMoreItems,
        reply,
        retry,
        retryGetThread,
        send,
        toggleDone,
        updateDraftRecipients,
        updateDraft,
        updateThread,
        uploadMessageAttachment
    }}>
        {children}
    </MessageThreadContext.Provider>;
};

export default MessageThreadProvider;


import { wealthProApi } from "../../redux/api";

const archiveApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['factFindArchive']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchFactFindArchiveStatus: builder.query({
            query: ({ masterAccountId }) => `api/factfind/archive/${masterAccountId}`,
            providesTags: ['factFindArchive']
        }),
        archiveFactFind: builder.mutation({
            query: ({ masterAccountId }) => ({
                url: `api/factfind/archive/${masterAccountId}`,
                method: 'POST'
            }),
            invalidatesTags: ['factFindArchive']
        }),
        fetchFactFindPdf: builder.query({
            query: ({ masterAccountId, preview = false }) => `api/factfind/archive/${masterAccountId}/pdf${preview ? '?preview=true' : ''}`
        })
    })
})

export const {
    useFetchFactFindArchiveStatusQuery,
    useArchiveFactFindMutation,
    useLazyFetchFactFindPdfQuery
} = archiveApiEndpoints;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const policyEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchPolicy: builder.mutation({
            query: ({ policyId, operations }) => ({
                url: `api/recommendations/Policy/${policyId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ instructionId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchPolicy", { instructionId }, (draft) => {
                        applyPatch(draft, operations);
                    }))

                    try {
                        await queryFulfilled;
                    } catch (ex) {
                        console.error(ex, "Error updating service");
                        patchResult.undo();
                    }
                } catch (ex) {
                    console.error(ex, "Error updating cache");
                }
            }
        })
    })
});

export const {
    usePatchPolicyMutation
} = policyEndpoints;
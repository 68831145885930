import React from "react";
import { ErrorMessage } from "../../../components/messages";
import { CenterPage } from "../../../components/utilities";

const LoadingError = ({ error = null, retryCallback }) => {
    return <CenterPage>
        <ErrorMessage 
            flex="column"
            error={error}
            imageHeight={192} 
            imageWidth={256}
            retryCallback={retryCallback} 
        />
    </CenterPage>
};

export default LoadingError;
import React from "react";

const ClientAccessTableModalControlsContext = React.createContext();

export const useClientAccessTableModal = () => React.useContext(ClientAccessTableModalControlsContext);

export const withClientAccessTableModalControls = (WrappedComponent) => (props) => (
    <ClientAccessTableModalControlsProvider>
        <WrappedComponent {...props} />
    </ClientAccessTableModalControlsProvider>
);

export const ClientAccessTableModalControlsProvider = ({ children }) => {
    const [showCreate, setShowCreate] = React.useState(false);
    return (
        <ClientAccessTableModalControlsContext.Provider value={{
            showCreate,
            setShowCreate
        }}>
            {children}
        </ClientAccessTableModalControlsContext.Provider>
    );
};
import _ from "lodash";
import React from "react";
import { useCreateMasterAccountDashboardLoginMutation } from "../../../services/clientauth";

const initialValues = {
    resourceId: null,
    username: '',
    emailAddress: '',
    telephoneNumber: '',
    emailAddressId: null,
    password: '',
    mfaType: 4,
    isPortfolioEnabled: true,
    isValuationsEnabled: true,
    isCommunicationsEnabled: true,
    isFactFindEnabled: true,
    isBusinessSheetsEnabled: true,
    isAdviceReportsEnabled: true,
    isTaxPacksEnabled: true,
    isContractNotesEnabled: true,
    isCustodyStatementsEnabled: true,
    isCostDisclosuresEnabled: true,
    isPlanningToolsEnabled: true,
    autoGeneratePassword: true,
    sendWelcomeEmailOnCreate: false
};

const useCreateDashboardLogin = (masterAccountId) => {
    const [_create, createResults] = useCreateMasterAccountDashboardLoginMutation();
    const [defaultValues, setDefaultValues] = React.useState({ masterAccountId, ...initialValues });

    const create = (data) => {
        return new Promise((resolve, reject) => {
            return _create({ data, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    React.useEffect(() => {
        if (!masterAccountId || _.isEqual(defaultValues.masterAccountId, masterAccountId)) {
            return;
        }
        setDefaultValues(_ => ({ masterAccountId, ...initialValues }));
    }, [ masterAccountId ]);

    return { ...createResults, create, defaultValues };
};

export default useCreateDashboardLogin;
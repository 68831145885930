import _ from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";

const PageScrollPositionContext = React.createContext();

export const usePageScrollPosition = () => React.useContext(PageScrollPositionContext);

// TODO: setup session storage to persist the scroll positions when page is refreshed

export const PageScrollPositionProvider = ({ children }) => {
    const location = useLocation();
    const [positions, setPositions] = React.useState({});
    const currentPosition = React.useMemo(() => positions[location.pathname], [ location ]);

    const clearPosition = (path) => {
        if (!positions[path]) {
            return;
        }

        setPositions(current => {
            const newPositions = { ...current };
            delete newPositions[path];
            return newPositions;
        });
    };

    const getPosition = (path) => {
        if (!positions[path]) {
            return null;
        }
        return positions[path];
    };

    const setPosition = (pos, path = null) => {
        if (!path) {
            setPositions({
                ...positions,
                [location.pathname]: pos
            });
        }
        else {
            setPositions({
                ...positions,
                [path]: pos
            });
        }
    };

    return (
        <PageScrollPositionContext.Provider value={{
            clearPosition,
            currentPosition,
            getPosition,
            positions,
            setPosition
        }}>
            {children}
        </PageScrollPositionContext.Provider>
    );
};
import React from "react";
import Skeleton from "react-loading-skeleton";

const ProjectReportsTableLoadingRow = ({ columns, loadingLines = 2, ...rest }) => {
    return [...Array(loadingLines)].map((_, i) => (
        <tr key={`loading-${i}`} {...rest}>
            <td colSpan={columns.length}>
                <Skeleton count={1} height={24} />
            </td>
        </tr>
    ));
};

export default ProjectReportsTableLoadingRow;
import { wealthProApi } from "../../redux/api";

const reminderTypesApiEndpoints = wealthProApi.enhanceEndpoints({}).injectEndpoints({
    endpoints: (builder) => ({
        fetchReminderTypes: builder.query({
            query: ({showHidden}) => `api/reminders/types?showHidden=${showHidden ? true : false}`
        })
    })
});

export const {
    useFetchReminderTypesQuery
} = reminderTypesApiEndpoints;
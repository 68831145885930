import { useFetchTransactionsDefWrappersQuery } from "../../../services/valuations/defWrapperApiEndpoints";

const useWrappers = (listType) => {

    const {
        data: wrappers, 
        defWrappersError, 
        defWrappersIsError,
        defWrappersIsFetching, 
        defWrappersIsLoading } = useFetchTransactionsDefWrappersQuery(listType);

    return {
        wrappers,
        defWrappersError,
        defWrappersIsError,
        defWrappersIsFetching,
        defWrappersIsLoading
    };
};

export default useWrappers;
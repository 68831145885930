import React from "react";
import DelegatesGridTableActionColumn from "./DelegatesGridTableActionColumn";
import DelegatesGridTableColumn from "./DelegatesGridTableColumn";
import DelegatesGridTableColumnBoolean from "./DelegatesGridTableColumnBoolean";
import { DateDisplay, ProfileDisplay } from "../../../components";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";

const DelegatesGridTableRow = ({ data, index, style }) => {
    const { height } = style;

    if (index >= data.length) {
        return <TableLoadingRow style={{ height }} />
    }

    const {
        userId,
        delegatedUserId,
        readCalendar,
        writeCalendar,
        readMail,
        writeMail,
        sendMail,
        readMessages,
        writeMessages,
        readReminders,
        writeReminders,
        hasClientAccess,
        createdDate
    } = data[index];
    return (
        <tr>
            <DelegatesGridTableColumn width={15} style={{ height }}>
                {userId ? (<ProfileDisplay userId={userId} />) : "Unknown User"}
            </DelegatesGridTableColumn>
            <DelegatesGridTableColumn width={15}>
                {delegatedUserId ? (<ProfileDisplay userId={delegatedUserId} />) : "Unknown User"}
            </DelegatesGridTableColumn>
            <DelegatesGridTableColumnBoolean value={readCalendar} />
            <DelegatesGridTableColumnBoolean value={writeCalendar} />
            <DelegatesGridTableColumnBoolean value={readMail} />
            <DelegatesGridTableColumnBoolean value={writeMail} />
            <DelegatesGridTableColumnBoolean value={sendMail} />
            <DelegatesGridTableColumnBoolean value={readMessages} />
            <DelegatesGridTableColumnBoolean value={writeMessages} />
            <DelegatesGridTableColumnBoolean value={readReminders} />
            <DelegatesGridTableColumnBoolean value={writeReminders} />
            <DelegatesGridTableColumnBoolean value={hasClientAccess} />
            <DelegatesGridTableColumn width={8}>
                {createdDate ? (<DateDisplay format="DD/MM/YYYY">{createdDate}</DateDisplay>) : (<span>-</span>)}
            </DelegatesGridTableColumn>
            <DelegatesGridTableActionColumn width={2} data={data[index]} />
        </tr>
    );
};

export default DelegatesGridTableRow;
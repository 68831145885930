import React from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { 
    useDeleteAccessMutation,
    useLazyFetchMasterAccountAccessQuery, 
    useUpdateAccessMutation 
} from "../../../services/clientdetails";

const ClientAccessTableDataContext = React.createContext();

export const useClientAccessTableData = () => React.useContext(ClientAccessTableDataContext);

export const withClientAccessTableDataProvider = (WrappedComponent) => (props) => {
    const { id : masterAccountId } = useClientContext();
    return (
        <ClientAccessTableDataProvider masterAccountId={masterAccountId}>
            <WrappedComponent {...props} />
        </ClientAccessTableDataProvider>
    );
};

export const ClientAccessTableDataProvider = ({ children, masterAccountId, limit = 50 }) => {
    // setup state and our query state (does not load by default (lazy))
    const [sort, setSort] = React.useState("username+asc");
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazyFetchMasterAccountAccessQuery();
    const [_delete, { isLoading: isDeleting, ...deleteResults }] = useDeleteAccessMutation();
    const [_update, { isLoading: isUpdating, ...updateResults }] = useUpdateAccessMutation();


    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true || isUpdating === true || isDeleting === true;

    const clear = () => {
        if (!masterAccountId || isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            sort,
            page: 1,
            limit
        }).unwrap().then(_ => {
            setCacheKey(_prev => newCacheKey)
            setIsLoadingState(_prev => false);
        });
    };

    const deleteAccess = (accessId) => {
        return new Promise((resolve, reject) => {
            return _delete({ masterAccountId, accessId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            )
        });
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (!masterAccountId || isNextPageLoading) {
            return;
        }

        // load the next page of results
        setIsLoadingState(_ => true);
        load({ cacheKey, masterAccountId, sort, page: page + 1, limit: limit  }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
        });
    };

    const sortTable = (property, newDirection) => setSort(_prev => property + (newDirection ?? ""));

    const updateAccess = (accessId, isAccessBlocked) => {
        return new Promise((resolve, reject) => {
            return _update({ masterAccountId, accessId, isAccessBlocked }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            )
        });
    };

    React.useEffect(clear, [ masterAccountId, sort ]);

    return (
        <ClientAccessTableDataContext.Provider value={{
            ...loadResults,
            clear,
            data: results,
            deleteAccess,
            deleteResult: { isLoading: isDeleting, ...deleteResults },
            hasMore,
            isFetching,
            isLoading,
            isNextPageLoading,
            isRowLoaded,
            limit,
            loadMore: isNextPageLoading ? () => {} : loadMore, 
            masterAccountId,
            page,
            pagination,
            sort,
            sortTable,
            totalCount,
            totalPages,
            updateAccess,
            updateResult: { isLoading: isUpdating, ...updateResults }
        }}>
            {children}
        </ClientAccessTableDataContext.Provider>
    );
};
import React, { useMemo } from "react";
import InstructionRow, { InstructionRowHeaders } from "./InstructionRow";
import CashSourceRow from "./CashSourceRow";
import { ThemedSpan } from "../../../components/utilities";
import { NumericFormat } from "react-number-format";
import { DateInput } from "../../../components/forms";

const InvestmentIncomeCash = ({ data, updateFn }) => {

    return <React.Fragment>
        <div className="container-fluid mb-4">
            <div className="row d-flex align-items-center">
                <div className="col-auto">
                    <ThemedSpan>Income Commencement Date:</ThemedSpan>
                </div>
                <div className="col-auto">
                    <DateInput
                        value={data.incomeCommencementDate}
                        onBlur={newValue => updateFn("incomeCommencementDate", newValue)}
                    />
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <InstructionRowHeaders />
            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Income"}
                appliesProperty={"incomeApplies"}
                frequencyProperty={"incomeFrequency"}
                cashAmountProperty={"incomeAmount"}
                dateInstructedProperty={"incomeInstructedDate"}
                instructedDateSetProperty={"incomeInstructedSetDate"}
                instructedByProperty={"incomeInstructedByName"}
                dateConfirmedProperty={"incomeConfirmedDate"}
                confirmedDateSetProperty={"incomeConfirmedSetDate"}
                confirmedByProperty={"incomeConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Natural Income"}
                appliesProperty={"naturalIncome"}
                dateInstructedProperty={"naturalIncomeInstructedDate"}
                instructedDateSetProperty={"naturalIncomeInstructedSetDate"}
                instructedByProperty={"naturalIncomeInstructedByName"}
                dateConfirmedProperty={"naturalIncomeConfirmedDate"}
                confirmedDateSetProperty={"naturalIncomeConfirmedSetDate"}
                confirmedByProperty={"naturalIncomeConfirmedByName"}
            />
        </div>
    </React.Fragment>
};

export default InvestmentIncomeCash;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { createPortal } from "react-dom";
import toast from "react-hot-toast";
import { MessagePriorities, ReminderStatuses } from "../../../helpers/constants";
import { AutoFitCell } from "../../../components/tables/StandardTable";

// Portal to outside the normal content to inherit the normal menu background and z-index
const DropdownMenu = ({ children }) => {
    return createPortal(
        <Dropdown.Menu>
            {children}
        </Dropdown.Menu>,
        document.body
    )
}

const EventsTableActionColumnToggle = React.forwardRef(({ children, onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const EventActionMenuItem = ({ label, onClick }) => {
    const onHandleClick = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        if (typeof (onClick) !== 'function') {
            return;
        }

        onClick(e);
    };

    return <Dropdown.Item onClick={onHandleClick}>
        {label}
    </Dropdown.Item>;
};


const EventPriorityActionMenuItem = ({ isCurrent, label, priority, updateEvent, ...rest }) => {
    const onHandleClick = () => {
        if (!updateEvent || typeof (updateEvent) !== 'function') {
            return;
        }

        updateEvent("priority", priority)
            .then(
                _ => toast.success("Priority updated."),
                error => toast.error(error?.data)
            );
    };

    return (
        <Dropdown.Item className="d-flex justify-content-between align-items-center" onClick={onHandleClick} {...rest}>
            {label}
            {isCurrent && <FontAwesomeIcon
                icon="check"
                className="text-success ms-2"
            />}
        </Dropdown.Item>
    );
};

const EventsTableActionColumn = ({ className = "", patchEvent, setSelectedEvent, ...data }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    const { id, priority, status } = data || {};

    const patch = (key, value) => patchEvent(id, key, value);

    return (
        <AutoFitCell className="text-center">
            <Dropdown id={menuId} className={classNames("caret-off", className)}>
                <Dropdown.Toggle as={EventsTableActionColumnToggle} id={toggleId} />
                <DropdownMenu>
                    {status !== ReminderStatuses.Cancelled && <>
                        <EventActionMenuItem
                            label="Edit"
                            onClick={() => setSelectedEvent(data)}
                        />
                        <Dropdown.Divider />
                    </>}
                    <Dropdown.Header>Actions</Dropdown.Header>
                    {status !== ReminderStatuses.Active && <EventActionMenuItem
                        label="Re-Activate"
                        onClick={() => patch("status", ReminderStatuses.Active)}
                    />}
                    {/* {status === ReminderStatuses.Todo && <EventsStatusActionMenuItem
                        label="Complete"
                        status={ReminderStatuses.Complete}
                        updateEvent={updateEvent}
                    />} */}
                    {status === ReminderStatuses.Active && <EventActionMenuItem
                        label={"Cancel"}
                        onClick={() => patch("status", ReminderStatuses.Cancelled)}
                    />}
                    <Dropdown.Divider />
                    <Dropdown.Header>Priority</Dropdown.Header>
                    <EventPriorityActionMenuItem
                        label="Low"
                        priority={MessagePriorities.Low}
                        isCurrent={priority === MessagePriorities.Low}
                        updateEvent={patch}
                        disabled={status === ReminderStatuses.Cancelled}
                    />
                    <EventPriorityActionMenuItem
                        label="Medium"
                        priority={MessagePriorities.Medium}
                        isCurrent={priority === MessagePriorities.Medium}
                        updateEvent={patch}
                        disabled={status === ReminderStatuses.Cancelled}
                    />
                    <EventPriorityActionMenuItem
                        label="High"
                        priority={MessagePriorities.High}
                        isCurrent={priority === MessagePriorities.High}
                        updateEvent={patch}
                        disabled={status === ReminderStatuses.Cancelled}
                    />
                </DropdownMenu>
            </Dropdown>
        </AutoFitCell>
    );
};

export default EventsTableActionColumn;
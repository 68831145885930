import { useAuth } from "react-oidc-context";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useDeleteDraftMessageMutation, usePatchClientMessageMutation, usePatchThreadMutation, useSendClientMessageMutation, useToggleMessageIsDoneMutation, useUpdateMessageRecipientsMutation, useUploadMessageAttachmentMutation } from "../../../services/messages";
import { useClientMessaging } from "./ClientMessagingContext";
import { useState } from "react";
import toast from "react-hot-toast";

const useMessage = (messageId) => {

    const { user: { profile } } = useAuth();
    const { id: masterAccountId } = useClientContext();
    const { threadId, createMessage } = useClientMessaging();
    const [deleteDraftMessageTrigger, deleteDraftResult] = useDeleteDraftMessageMutation();
    const [patchMessageTrigger, { isLoading: isPatching }] = usePatchClientMessageMutation();
    const [sendMessageTrigger] = useSendClientMessageMutation();
    const [updateRecipientsTrigger, { isLoading: isUpdatingRecipients }] = useUpdateMessageRecipientsMutation();
    const [patchThreadTrigger] = usePatchThreadMutation();
    const [toggleMessageIsDoneTrigger] = useToggleMessageIsDoneMutation();
    const [uploadAttachment, uploadAttachmentResult] = useUploadMessageAttachmentMutation();
    const [isReplying, setIsReplying] = useState(false);

    const deleteDraft = () => {
        return new Promise((res, rej) => {
            return deleteDraftMessageTrigger({ messageId, masterAccountId, threadId })
                .unwrap()
                .then(res, rej);
        })
    };

    const reply = (message) => {
        setIsReplying(true);
        let currentUserId = profile.sub.replace(/-/gi, '');
        const newRecipients = message.senderId === currentUserId
            ? message.recipients.filter(x => x.userId !== currentUserId)
            : [...message.recipients.filter(x => x.userId !== currentUserId), { userId: message.senderId }];

        createMessage({ recipients: newRecipients, threadId: message.threadId, message: "" })
            .then(
                res => setIsReplying(false),
                err => {
                    setIsReplying(false);
                    toast.error("Error creating reply!");
                }
            );
    };

    const sendMessage = () => {
        return new Promise((resolve, reject) => {
            return sendMessageTrigger({
                threadId,
                masterAccountId,
                messageId
            })
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const toggleDone = (newValue) => {
        return new Promise((resolve, reject) => {
            return toggleMessageIsDoneTrigger({
                messageId,
                masterAccountId,
                newValue
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updateMessage = (property, newValue) => {
        return new Promise((res, rej) => {
            const operations = [patchReplace(property, newValue)];
            return patchMessageTrigger({ messageId, operations, masterAccountId, threadId })
                .unwrap()
                .then(res, rej);
        });
    };

    const updateMessageRecipients = (recipients) => {
        return new Promise((res, rej) => {
            return updateRecipientsTrigger({ messageId, threadId, masterAccountId, recipients })
                .unwrap()
                .then(res, rej);
        })
    };

    const updateMessageThread = (threadId, property, newValue) => {
        return new Promise((resolve, reject) => {
            var operations = [patchReplace(property, newValue)];
            return patchThreadTrigger({
                operations,
                masterAccountId,
                threadId,
                messageId
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const uploadMessageAttachment = (files, _, extendedFileData) => {
        const request = new FormData();
        request.append('messageId', messageId);
        request.append('masterAccountId', masterAccountId);

        files?.forEach((file, index) => request.append(file.name, file, file.name));
        extendedFileData?.forEach((info, i) => {
            request.append(`fileDetails[${i}].fileName`, info.fileName);
            request.append(`fileDetails[${i}].description`, info.description);
            request.append(`fileDetails[${i}].type`, info.type);
            request.append(`fileDetails[${i}].function`, info.selector);
        });

        return new Promise((resolve, reject) => {
            return uploadAttachment({ request, messageId, threadId, masterAccountId })
                .unwrap()
                .then(resolve, reject);
        });
    };

    return {
        deleteDraftResult,
        isEditingMessage: isPatching || isUpdatingRecipients,
        isReplying,
        uploadAttachmentResult,
        deleteDraft,
        reply,
        sendMessage,
        toggleDone,
        updateMessage,
        updateMessageRecipients,
        updateMessageThread,
        uploadMessageAttachment
    };

};

export default useMessage;
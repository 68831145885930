import styled from "styled-components";
import { FormLabel } from "../../../components/forms"

// Lines commented out for easy changing back to vertically-centred labels

const ClientLabel = styled(FormLabel)`
    grid-column-start: 1;
    margin-top: 0.5rem !important;
    ${'' /* margin-top: auto !important; */}
    ${'' /* margin-bottom: auto !important; */}
`;

export default ClientLabel;
import { useCallback, useMemo } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchBorrowerTypesQuery, useFetchMortgageTypesQuery, useFetchNewMortgageApplicationSectionQuery, useFetchPropertyTypesQuery, usePatchNewMortgageApplicationSectionMutation } from "../../../services/clientfactfind";
import { useExpenditureDetails } from "../contexts/ExpenditureDetailsContext";
import useNotes from "./useNotes";

const useNewMortgageApplicationSection = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: section, isLoading: sectionIsLoading, isFetching: sectionIsFetching, isError: sectionIsError, error: sectionError, refetch: refetchSection } = useFetchNewMortgageApplicationSectionQuery({ masterAccountId });
    const [patchSection] = usePatchNewMortgageApplicationSectionMutation();
    const { updateNotes } = useNotes();

    // Options
    const { data: borrowerTypes, isLoading: borrowerTypesIsLoading, isFetching: borrowerTypesIsFetching, isError: borrowerTypesIsError, error: borrowerTypesError, refetch: refetchBorrowerTypes } = useFetchBorrowerTypesQuery();
    const { data: propertyTypes, isLoading: propertyTypesIsLoading, isFetching: propertyTypesIsFetching, isError: propertyTypesIsError, error: propertyTypesError, refetch: refetchPropertyTypes } = useFetchPropertyTypesQuery();
    const { data: mortgageTypes, isLoading: mortgageTypesIsLoading, isFetching: mortgageTypesIsFetching, isError: mortgageTypesIsError, error: mortgageTypesError, refetch: refetchMortgageTypes } = useFetchMortgageTypesQuery();

    const patchService = (property, newValue) =>
        patchSection({ masterAccountId, sectionId: section.newMortgageApplicationId, operations: [patchReplace(property, newValue)] }).unwrap();

    const patchNotes = (newText) =>
        updateNotes(section?.affordabilityBudgetSourceOfFunds.notesId, newText);

    const isLoading = useMemo(() => sectionIsLoading || sectionIsFetching
        || borrowerTypesIsLoading || borrowerTypesIsFetching
        || propertyTypesIsLoading || propertyTypesIsFetching
        || mortgageTypesIsLoading || mortgageTypesIsFetching, [borrowerTypesIsFetching, borrowerTypesIsLoading, mortgageTypesIsFetching, mortgageTypesIsLoading, propertyTypesIsFetching, propertyTypesIsLoading, sectionIsFetching, sectionIsLoading]);

    const isError = useMemo(() => sectionIsError
        || borrowerTypesIsError
        || propertyTypesIsError
        || mortgageTypesIsError, [borrowerTypesIsError, mortgageTypesIsError, propertyTypesIsError, sectionIsError]);

    const error = useMemo(() => sectionError
        || borrowerTypesError
        || propertyTypesError
        || mortgageTypesError, [borrowerTypesError, mortgageTypesError, propertyTypesError, sectionError]);

    const refetchAll = useCallback(() => {
        refetchSection();
        refetchBorrowerTypes();
        refetchMortgageTypes();
        refetchPropertyTypes();
    }, [refetchBorrowerTypes, refetchMortgageTypes, refetchPropertyTypes, refetchSection]);

    const [, {
        overallMonthlyExpenditure,
        monthlyNetIncome,
        disposableMonthlyIncome,
        mortgageRentValue
    }] = useExpenditureDetails();

    return [{
        patchService,
        patchNotes,
        refetchAll
    }, {
        section,
        options: {
            borrowerTypes,
            propertyTypes,
            mortgageTypes
        },
        isLoading,
        isError,
        error,
        overallMonthlyExpenditure,
        monthlyNetIncome,
        disposableMonthlyIncome,
        mortgageRentValue
    }];
}

export default useNewMortgageApplicationSection;
import SocketClient from "../helpers/SocketClient";

export const socket = new SocketClient();

const socketMiddleware = (socket) => {
    return (store) => {
        return (next) => (action) => {
            //action.socket = socket;
            return next(action);
        }
    };
};

export default socketMiddleware;
import React from "react";
import styled from "styled-components";

// TODO: we might have smaller input fields, handle that in the 'padding-top' part to calculate better

const CenterFormColumn = styled.div`
    display: flex;
    align-items: center;
    padding-top: 30.8px;
`;

const CenterFormColumnComponent = ({ children, className, ...rest }) => {
    return <CenterFormColumn className={className || 'col'} {...rest}>
        {children}
    </CenterFormColumn>
};

export default CenterFormColumnComponent;
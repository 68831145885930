import { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import { patchReplace } from "../../../helpers/patchDoc";
import {
    useAddInvestmentCashMutation,
    useRemoveInvestmentCashMutation
} from "../../../services/recommendations";
import { useInstruction } from "../contexts/InstructionContext";

const useSourceOfFunds = () => {

    const [{ fetchInvestmentCash, patchInvestmentCash, realTimeAddCash, patchInvest: patchInvestTrigger, realTimePatchInvest, realTimePatchCash, realTimeDeleteCash }, { realTimeInstruction, instructionId, invest, investmentCash, investmentCashIsLoading, investmentCashIsFetching, investmentCashIsError }, { showTaxReclaim }] = useInstruction();

    const [createInvestmentCash] = useAddInvestmentCashMutation();

    const [deleteInvestmentCash] = useRemoveInvestmentCashMutation();

    // For keeping track of whether to patch the service
    const [isDirty, setIsDirty] = useState(false);

    const bulkPatchInvest = useCallback((operations) =>
        patchInvestTrigger({ investId: invest?.id, instructionId, operations }).unwrap(),
        [instructionId, invest?.id, patchInvestTrigger]);

    const patchInvest = useCallback((property, value) =>
        bulkPatchInvest([patchReplace(property, value)]),
        [bulkPatchInvest]);

    const bulkPatchCash = useCallback((investmentCashId, operations, index) =>
        patchInvestmentCash({ investmentCashId, operations, investId: invest?.id, index }).unwrap(),
        [invest?.id, patchInvestmentCash]);

    const patchCash = useCallback((investmentCashId, property, value, index) =>
        bulkPatchCash(investmentCashId, [patchReplace(property, value)], index), [bulkPatchCash]);

    const createCash = useCallback(() => {
        createInvestmentCash({ newId: v4(), investId: invest?.id })
            .unwrap()
            .then((cash) => {
                realTimeAddCash(cash);
            });
    }, [createInvestmentCash, invest?.id, realTimeAddCash])

    const deleteCash = (investmentCashId, cashAmount, index) => {
        deleteInvestmentCash({
            investId: invest?.id,
            investmentCashId,
            indexToRemove: index,
        }).unwrap().then(() => {
            realTimeDeleteCash(index);
            patchInvest("totalSubscriptionAmount", (invest?.totalSubscriptionAmount ?? 0) - (cashAmount ?? 0))
        })
    }

    const realTimePatchCashSingle = useCallback((property, value, index) => {
        setIsDirty(true);
        realTimePatchCash(index, [patchReplace(property, value)]);
    }, [realTimePatchCash]);

    const realTimePatchInvestSingle = useCallback((property, value) => {
        setIsDirty(true);
        realTimePatchInvest([patchReplace(property, value)]);
    }, [realTimePatchInvest]);

    const retry = () =>
        fetchInvestmentCash({ investId: invest?.id });

    useEffect(() => {
        if (invest?.id && investmentCash == null)
            fetchInvestmentCash({ investId: invest?.id });
    }, [fetchInvestmentCash, invest?.id, investmentCash]);

    // Real-time update of total investment/subscription amount and tax reclaim
    useEffect(() => {
        const updateRealTimeTimeout = setTimeout(() => {
            let operations = [];

            if (showTaxReclaim) {
                const newTaxReclaim = (realTimeInstruction?.invest?.investmentCash ?? [])
                    .reduce((prev, current) => prev + current?.taxReclaim,
                        (realTimeInstruction?.invest?.clientTaxReclaim ?? 0));

                if (newTaxReclaim !== realTimeInstruction?.invest?.taxReclaim)
                    operations.push(patchReplace("taxReclaim", (newTaxReclaim ?? 0)));
            }

            const newTotal = (realTimeInstruction?.invest?.investmentCash ?? [])
                .reduce((prev, current) => prev + (current?.cashAmount ?? 0),
                    (realTimeInstruction?.invest?.cashFromAPS ?? 0)
                    + (realTimeInstruction?.invest?.cashFromClient ?? 0)
                    // Following only relevant if showTaxReclaim is true
                    + (realTimeInstruction?.invest?.cashFromEmployer ?? 0));

            if (newTotal !== realTimeInstruction?.invest?.totalSubscriptionAmount) {
                operations.push(patchReplace("totalSubscriptionAmount", (newTotal ?? 0)));

                if (newTotal !== realTimeInstruction?.invest?.totalInvestmentAmount)
                    operations.push(patchReplace("totalInvestmentAmount", (newTotal ?? 0)));
            }

            if (!operations.length)
                return;

            realTimePatchInvest(operations);
        }, 150);

        return () => clearTimeout(updateRealTimeTimeout);
        // Disabling exhaustive-deps as depending on investmentAmount and subscriptionAmount causes multiple updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realTimeInstruction?.invest?.cashFromAPS, realTimeInstruction?.invest?.cashFromClient, realTimeInstruction?.invest?.cashFromEmployer, realTimeInstruction?.invest?.clientTaxReclaim, realTimeInstruction?.invest?.investmentCash, realTimeInstruction?.invest?.taxReclaim, realTimePatchInvest, showTaxReclaim]);

    // onBlur update of total subscription amount and tax reclaim
    useEffect(() => {
        const updateTimeout = setTimeout(() => {
            if (!isDirty) return;

            let operations = [];

            if (showTaxReclaim) {
                const newTaxReclaim = (investmentCash ?? [])
                    .reduce((prev, current) => prev + (current?.taxReclaim ?? 0),
                        (invest?.clientTaxReclaim ?? 0));

                if (newTaxReclaim !== invest?.taxReclaim)
                    operations.push(patchReplace("taxReclaim", (newTaxReclaim ?? 0)));
            }

            const newTotal = (investmentCash ?? [])
                ?.reduce((prev, current) => prev + current.cashAmount,
                    (invest?.cashFromAPS ?? 0) + (invest?.cashFromClient ?? 0) + (invest?.cashFromEmployer ?? 0));

            if (newTotal !== invest?.totalSubscriptionAmount) {
                operations.push(patchReplace("totalSubscriptionAmount", (newTotal ?? 0)));

                if (newTotal !== invest?.totalInvestmentAmount)
                    operations.push(patchReplace("totalInvestmentAmount", (newTotal ?? 0)));
            }

            if (!operations.length)
                return;

            bulkPatchInvest(operations);
        }, 150);

        return () => clearTimeout(updateTimeout);
        // Disabling exhaustive-deps as depending on investmentAmount and subscriptionAmount causes multiple updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkPatchInvest, invest?.cashFromAPS, invest?.cashFromClient, invest?.cashFromEmployer, invest?.clientTaxReclaim, invest?.taxReclaim, investmentCash, isDirty, showTaxReclaim]);

    return {
        retry,
        investmentCash,
        isLoading: investmentCashIsLoading,
        isFetching: investmentCashIsFetching,
        isError: investmentCashIsError,
        patchInvest,
        bulkPatchInvest,
        realTimePatchInvest,
        realTimePatchInvestSingle,
        createCash,
        realTimePatchCashSingle,
        patchCash,
        bulkPatchCash,
        deleteCash
    }
}

export default useSourceOfFunds;
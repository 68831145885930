import { useCallback } from "react";
import useClientConnection from "../hooks/useClientConnection";
import EditClientModal from "./EditClientModal";
import EditConnectionModal from "./EditConnectionModal";
import FormSelectOpenModal from "./FormSelectOpenModal";
import OpenModalMultiValueContainer from "./OpenModalMultiValueContainer";

// Custom MultiValueContainer to open the EditConnectionModal/EditClientModal when an option is clicked
const OpenConnectionModalValueContainer = ({ data, onHide, ...rest }) => {
    return <OpenModalMultiValueContainer
        ModalComponent={data.isConnection ? EditConnectionModal : EditClientModal}
        modalProps={data.isConnection
            ? {
                connectionId: data.value,
                connectionName: data.label,
                size: "xl"
            } : {
                clientId: data.value,
                clientName: data.label,
                size: "xl"
            }}
        onHide={onHide}
        {...rest}
    />
}

// Opens EditConnectionModal when the icon is clicked, and either EditClient or EditConnectionModal when a selected value is clicked, 
const SelectOpenConnectionModal = ({ options, defaultValue, onChange, onBlur, onHide }) => {

    const { createClientConnection } = useClientConnection();

    const createAndSelectConnection = useCallback(() => {
        return new Promise((resolve, reject) => {
            return createClientConnection()
                .then((result) => {
                    try {
                        onBlur([
                            ...options.filter(({ value }) => defaultValue.includes(value)),
                            {
                                value: result?.connectionId,
                                isConnection: true
                            }
                        ]);

                        return resolve(result);
                    } catch {
                        console.error("Error");
                        reject()
                    }
                }, (error) => {
                    console.error(error);
                    reject(error);
                })
        });
    }, [createClientConnection, defaultValue, onBlur, options])

    return <FormSelectOpenModal
        // Icon props
        icon={"plus"}
        buttonVariant={"success"}
        onIconClickFunction={createAndSelectConnection}
        ModalComponent={EditConnectionModal}
        modalProps={{ size: "xl", onHide }}
        // Select props
        options={options}
        defaultValue={defaultValue}
        isMulti
        // ValueContainer Modal
        components={{
            MultiValueContainer: (props) => <OpenConnectionModalValueContainer onHide={onHide} {...props} />
        }}
        // Slightly hacky, removes the ability to backspace a selection *ever*
        // Needed, as far as I can tell, to get the modal's text fields to work without deleting the selection
        backspaceRemovesValue={false}
        tabSelectsValue={false}
        onChange={onChange}
        onBlur={onBlur}
    />
};

export default SelectOpenConnectionModal;
import React from "react";
import TargetSuccessRatesGroup from "./components/TargetSuccessRatesGroup";

const ReviewManagementTargets = () => {
    return <React.Fragment>
        <article>
            <TargetSuccessRatesGroup />
        </article>
    </React.Fragment>
};

export default ReviewManagementTargets;
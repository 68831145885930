import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { useClientContext } from "../../../hooks/ClientContext";
import { useCreateNewExpenditureTypeMutation, useDeleteExpenditureTypeMutation, useFetchExpenditureDetailsSectionQuery, useFetchExpenditureFrequenciesQuery, useUpdateExpenditureFrequencyMutation, useUpdateExpenditureTypeDescriptionMutation } from "../../../services/clientfactfind";
import { useIncomeDetails } from "./IncomeDetailsContext";

const ExpenditureDetailsContext = React.createContext();

export const useExpenditureDetails = () => {
    return useContext(ExpenditureDetailsContext);
}

export const ExpenditureDetailsProvider = ({ children }) => {
    const { id: masterAccountId } = useClientContext();

    const { data: section, isLoading: expenditureIsLoading } = useFetchExpenditureDetailsSectionQuery({ masterAccountId });
    const { data: expenditureFrequencies, isLoading: frequenciesIsLoading, isFetching: frequenciesIsFetching } = useFetchExpenditureFrequenciesQuery();

    const [realTimeFrequencies, setRealTimeFrequencies] = useState(expenditureFrequencies);

    useEffect(() => {
        setRealTimeFrequencies(expenditureFrequencies);
    }, [expenditureFrequencies])

    const [, {
        section: incomeSection,
        isLoading: incomeIsLoading,
        netMonthlyIncomeValue,
    }] = useIncomeDetails();

    const nonDiscExpTypeInfo = useMemo(() => section?.frequencies?.filter(freq => !freq?.expenditureType?.isDiscretionary), [section?.frequencies]);

    const [realTimeNonDiscTypeInfo, setRealTimeNonDiscTypeInfo] = useState(nonDiscExpTypeInfo);

    useEffect(() => {
        setRealTimeNonDiscTypeInfo(nonDiscExpTypeInfo);
    }, [nonDiscExpTypeInfo]);

    const discExpTypeInfo = useMemo(() => section?.frequencies?.filter(freq => freq?.expenditureType?.isDiscretionary), [section?.frequencies]);

    const [realTimeDiscTypeInfo, setRealTimeDiscTypeInfo] = useState(discExpTypeInfo);

    useEffect(() => {
        setRealTimeDiscTypeInfo(discExpTypeInfo);
    }, [discExpTypeInfo])

    const [createType, { isLoading: isCreating }] = useCreateNewExpenditureTypeMutation();
    const [updateFrequency] = useUpdateExpenditureFrequencyMutation();
    const [updateDescription] = useUpdateExpenditureTypeDescriptionMutation();
    const [deleteType] = useDeleteExpenditureTypeMutation();

    const createNewExpenditureType = (isDiscretionary) => {
        return new createType({ masterAccountId, expenditureTypeId: v4(), expenditureId: section?.expenditureId, isDiscretionary }).unwrap()
            .then();
    }

    const changeFrequencyRealTime = useCallback((expenditureTypeId, newFrequency) => {
        // Mapping function to capture the type needing changing and leave all others unmodified
        const changeFrequencyMap = (freq) =>
            freq.expenditureTypeId === expenditureTypeId
                ? ({
                    ...freq,
                    frequency: newFrequency
                })
                : freq;

        setRealTimeFrequencies(oldFrequencies => oldFrequencies.map(changeFrequencyMap));

        setRealTimeNonDiscTypeInfo(oldInfo => oldInfo.map(changeFrequencyMap));

        setRealTimeDiscTypeInfo(oldInfo => oldInfo.map(changeFrequencyMap));
    }, [])

    const changeFrequency = (expenditureTypeId, newFrequency) =>
        updateFrequency({ masterAccountId, expenditureId: section?.expenditureId, expenditureTypeId, newFrequency }).unwrap();

    const changeDescription = (expenditureTypeId, isDiscretionary, newDescription) =>
        updateDescription({ expenditureTypeId, isDiscretionary, newDescription }).unwrap();

    const deleteCustomExpenditureType = (typeId) =>
        deleteType({ masterAccountId, expenditureTypeId: typeId });

    const [overallMonthlyExpenditure, setOverallMonthlyExpenditure] = useState(null);
    const updateOverallMonthlyExpenditure = useCallback((oldValue, newValue) =>
        setOverallMonthlyExpenditure(oldTotal => (oldTotal ?? 0) - (oldValue ?? 0) + (newValue ?? 0)), []);

    const [mortgageRentExpenditures, setMortgageRentExpenditures] = useState({});
    const updateMortgageRentExpenditures = useCallback((clientId, newValue) =>
        setMortgageRentExpenditures(oldObj => ({
            ...oldObj,
            [clientId]: newValue
        })), []);

    const mortgageRentValue = useMemo(() =>
        Object.values(mortgageRentExpenditures)?.reduce((accumulator, exp) => accumulator + (exp ?? 0), 0) ?? 0,
        // Recalculating when total expenditure changes to save passing an array to the dependencies (which doesn't work)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [overallMonthlyExpenditure])

    const monthlyNetIncome = useMemo(() => netMonthlyIncomeValue || 0, [netMonthlyIncomeValue]);
    const disposableMonthlyIncome = useMemo(() => monthlyNetIncome - overallMonthlyExpenditure, [monthlyNetIncome, overallMonthlyExpenditure]);

    const isLoading = useMemo(() => expenditureIsLoading || incomeIsLoading, [expenditureIsLoading, incomeIsLoading]);

    // Only want to show Combined column if there is more than one client column (column where client account ID is present)
    const clientColumns = section?.clients?.filter(client => client.clientAccountId != null);

    return <ExpenditureDetailsContext.Provider value={[{
        createNewExpenditureType,
        changeFrequency,
        changeFrequencyRealTime,
        changeDescription,
        deleteCustomExpenditureType,
        updateOverallMonthlyExpenditure,
        updateMortgageRentExpenditures
    }, {
        filterCombined: clientColumns?.length === 1,
        clientColumns,
        nonDiscExpTypeInfo,
        discExpTypeInfo,
        realTimeNonDiscTypeInfo,
        realTimeDiscTypeInfo,
        overallMonthlyExpenditure,
        mortgageRentValue,
        monthlyNetIncome,
        disposableMonthlyIncome,
        section,
        incomeSection,
        isLoading,
        isCreating,
        frequenciesIsLoading,
        expenditureFrequencies,
        realTimeFrequencies
    }]}>
        {children}
    </ExpenditureDetailsContext.Provider>
}
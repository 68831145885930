import React, { useEffect, useMemo, useState } from "react";
import { EmailContactsPagedSelect, MasterAccountSelect } from "../../../components/forms/selects";
import { DateTimeInput, FormInput, FormLabel, FormSelect, NumberInput, RichTextEditor } from "../../../components/forms";
import { Controller } from "react-hook-form";
import MultiValueLabel, { EditEmailContactModal } from "./MultiValueLabel";
import UserDelegatesSelect from "../../../components/forms/selects/UserDelegatesSelect";
import { DelegatedAccessTypes } from "../../../helpers/constants";
import { MeetingLocations, MeetingTypes } from "../hooks/useEvents";
import MasterAccountProjectPagedSelect from "./MasterAccountProjectPagedSelect";
import { Button } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const FormRow = ({ children }) => <div className="row d-flex align-items-center mb-3">{children}</div>;

const EventForm = ({
    isNew,
    control,
    data,
    watch,
    errors,
    setValue,
    masterAccountChangeFn
}) => {
    const {
        onlineMeetingUrl
    } = data || {};

    const initiallyVideo = data?.meetingLocation === MeetingLocations.Video;

    const duration = watch("duration");
    const masterAccount = watch("masterAccount");
    const meetingLocation = watch("meetingLocation");
    const meetingType = watch("meetingType");
    const organizer = watch("organizer");
    const startDate = watch("startDate");

    const meetingLocationOptions = useMemo(() => Object.keys(MeetingLocations).map(label => ({ label, value: MeetingLocations[label] })), [MeetingLocations]);
    const meetingTypeOptions = useMemo(() => Object.keys(MeetingTypes).map(label => ({ label, value: MeetingTypes[label] })), [MeetingTypes]);

    const [editContactModalProps, setEditContactModalProps] = useState(null);

    const handleEndDateChange = () => {
        if(!startDate || !duration) return;

        var startDateMoment = moment(startDate);
        startDateMoment.add("minutes", duration);

        setValue("endDate", startDateMoment)
    };

    const handleMasterAccountChange = () => {
        if (!masterAccount || !isNew) return;

        masterAccountChangeFn && typeof (masterAccountChangeFn) === "function" && masterAccountChangeFn(masterAccount);
    };

    const onJoinOnlineMeetingClick = (e) => {
        e?.preventDefault();

        onlineMeetingUrl && window.open(onlineMeetingUrl);
    };

    const onMultiValueLabelClick = ({ data }, e) => {
        setEditContactModalProps(data);
    };

    useEffect(handleEndDateChange, [duration, startDate]);

    useEffect(handleMasterAccountChange, [masterAccount]);

    return <React.Fragment>
        <FormRow>
            <div className="col-2 col-lg-1">
                <FormLabel>Type</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="meetingType"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <FormSelect
                        options={meetingTypeOptions}
                        defaultValue={value}
                        errorAllowRetry={false}
                        disableAnimations={true}
                        errorMessage={errors?.meetingType?.message}
                        onChange={(value) => setValue('meetingType', value.value)}
                        {...rest}
                    />
                    }
                />
            </div>
            <div className="col-2 col-lg-1">
                <FormLabel>Location</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="meetingLocation"
                    control={control}
                    rules={{ required: "Event location is required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <FormSelect
                        options={meetingLocationOptions}
                        defaultValue={value}
                        isDisabled={initiallyVideo}
                        errorAllowRetry={false}
                        disableAnimations={true}
                        errorMessage={errors?.meetingLocation?.message}
                        onChange={(value) => setValue('meetingLocation', value.value)}
                        {...rest}
                    />
                    }
                />
            </div>
        </FormRow>
        <FormRow>
            <div className="col-2 col-lg-1">
                <FormLabel>Start Date</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="startDate"
                    control={control}
                    rules={{ required: "Start date is required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <DateTimeInput
                        value={value}
                        onBlur={(value) => setValue('startDate', value)}
                        isClearable={true}
                        errorMessage={errors?.startDate?.message}
                        errorAllowRetry={false}
                        disableAnimations={true}
                        {...rest}
                    />
                    }
                />
            </div>
            <div className="col-2 col-lg-1">
                <FormLabel>Duration</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="duration"
                    control={control}
                    rules={{ required: "Duration is required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <NumberInput
                        value={value}
                        fixedDecimalScale={false}
                        suffix=" mins"
                        onBlur={(value) => setValue('duration', value)}
                        errorMessage={errors?.duration?.message}
                        errorAllowRetry={false}
                        disableAnimations={true}
                        {...rest}
                    />
                    }
                />
            </div>
        </FormRow>
        <FormRow>
            <div className="col-2 col-lg-1">
                <FormLabel>Client</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="masterAccount"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                        <MasterAccountSelect
                            valueObject={value}
                            disableAnimations={true}
                            errorMessage={errors?.masterAccount?.message}
                            errorAllowRetry={false}
                            setValue={(e) => setValue('masterAccount', e)}
                            {...rest}
                        />
                    )}
                />
            </div>
            <div className="col-2 col-lg-1">
                <FormLabel>Project</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="project"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                         <MasterAccountProjectPagedSelect
                            value={value}
                            disableAnimations={true}
                            errorMessage={errors?.project?.message}
                            errorAllowRetry={false}
                            setValue={(e) => setValue('project', e)}
                            masterAccountId={masterAccount?.value}
                            {...rest}
                        />
                    )}
                />
            </div>
        </FormRow>
        <FormRow>
            <div className="col-2 col-lg-1">
                <FormLabel>Organizer</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="organizer"
                    control={control}
                    rules={{ required: "Organizer is required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <UserDelegatesSelect
                        accessType={DelegatedAccessTypes.writeCalendar}
                        defaultValue={value?.value ?? value}
                        errorAllowRetry={false}
                        disableAnimations={true}
                        errorMessage={errors?.organizer?.message}
                        onChange={(value) => setValue('organizer', value)}
                        {...rest}
                    />
                    }
                />
            </div>
        </FormRow>
        <FormRow>
            <div className="col-2 col-lg-1">
                <FormLabel>Attendees</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="attendees"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <EmailContactsPagedSelect
                        key={organizer?.value ?? organizer}
                        userId={organizer?.value ?? organizer}
                        value={value}
                        isMulti={true}
                        errorMessage={errors?.attendees?.message}
                        setValue={(value) => setValue('attendees', value)}
                        errorAllowRetry={false}
                        disableAnimations={true}
                        components={{
                            MultiValueLabel: (props) => (
                                <MultiValueLabel
                                    onClick={onMultiValueLabelClick}
                                    {...props}
                                />
                            )
                        }}
                        {...rest}
                    />}
                />
            </div>
        </FormRow>
        <FormRow>
            <div className="col-2 col-lg-1">
                <FormLabel>Description</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Description is required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <FormInput
                        value={value}
                        errorAllowRetry={false}
                        disableAnimations={true}
                        errorMessage={errors?.description?.message}
                        onBlur={(value) => setValue('description', value)}
                        {...rest}
                    />
                    }
                />
            </div>
            {onlineMeetingUrl && <div className="col-auto">
                <Button href={onlineMeetingUrl} onClick={onJoinOnlineMeetingClick}>
                    <span className="me-2">Join Online Meeting</span>
                    <FontAwesomeIcon icon="video" />
                </Button>
            </div>}
        </FormRow>
        <FormRow>
            <Controller
                name="body"
                control={control}
                rules={{ required: false }}
                render={({ field: { ref, value, onChange, onBlur, ...rest } }) => {
                    return <RichTextEditor
                        key={value}
                        errorMessage={errors?.body?.message}
                        errorAllowRetry={false}
                        disableAnimations={true}
                        defaultValue={value}
                        onBlur={(value) => setValue('body', value)}
                        {...rest}
                    />
                }
                }
            />
        </FormRow>
        <EditEmailContactModal
            show={editContactModalProps !== null}
            data={editContactModalProps}
            onClose={() => setEditContactModalProps(null)}
        />
    </React.Fragment>;
};

export default EventForm;
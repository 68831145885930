import { useCallback, useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchRiskFactorsSectionQuery } from "../../../services/clientfactfind";
import useNotes from "./useNotes";

const useRiskFactors = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: section, isError, isLoading, error, refetch } = useFetchRiskFactorsSectionQuery({ masterAccountId });
    const { updateNotes: updateRiskFactorNotes } = useNotes();

    const [isFetching, setIsFetching] = useState(false);

    const manualRefetch = useCallback(() => {
        return new Promise((resolve, reject) => {
            setIsFetching(true);
            return refetch().unwrap().then(
                r => {
                    setIsFetching(false);
                    return resolve(r)
                },
                e => reject(e)
            )
        })
    }, [refetch])

    return {
        section,
        isError,
        isLoading: isLoading || isFetching,
        error,
        refetch: manualRefetch,
        updateRiskFactorNotes
    };
};

export default useRiskFactors;
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../components/buttons";
import { FormInput, FormSelect } from "../../../components/forms";

const CreatePensionArrangementModal = ({ show, setShow, onSubmit }) => {

    const [pensionName, setPensionName] = useState("");
    const [pensionType, setPensionType] = useState(null);

    const onClose = () => {
        setShow(false);
    }

    const onCreate = () => {
        onSubmit({ pensionName, pensionType });
        setPensionName("");
        setPensionType(null);
        setShow(_ => false);
    }

    return <Modal
        show={show}
        centered
        size="lg"
    >
        <Modal.Header>
            <Modal.Title>
                Create Pension Arrangement
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <FormInput
                label={"Pension Name"}
                value={pensionName}
                onChange={(e) => setPensionName(e.target.value)}
            />
            <FormSelect
                className={"mt-2"}
                label={"Pension Type"}
                options={[
                    {
                        label: "Stakeholder",
                        value: 10
                    },
                    {
                        label: "Personal Pension",
                        value: 20
                    },
                    {
                        label: "SIPP",
                        value: 30
                    },
                    {
                        label: "Workplace Pension",
                        value: 40
                    },
                    {
                        label: "Final Salary",
                        value: 50
                    },
                ]}
                defaultValue={pensionType}
                onChange={(e) => setPensionType(e.value)}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="danger"
                onClick={onClose}
            >
                Cancel
            </Button>
            <Button
                variant={"success"}
                onClick={onCreate}
            >
                Create
            </Button>
        </Modal.Footer>
    </Modal>;

};

export default CreatePensionArrangementModal;
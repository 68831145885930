import React from "react";
import { ReminderDisplayList } from "../reminders";
import { useClientContext } from "../../hooks/ClientContext";
import { useLazyFetchReminderTasksQuery } from "../../services/reminders";

const ClientReminders = () => {
    const { id: masterAccountId } = useClientContext();
    const [loadReminders, loadResults] = useLazyFetchReminderTasksQuery();

    React.useEffect(() => {
        loadReminders({ masterAccountId, showCancelled: false })
    }, [ masterAccountId ]);

    return <ReminderDisplayList 
        projectId={null} 
        displayProject={true}
        refetch={loadReminders} 
        {...loadResults} 
    />
};

export default ClientReminders;
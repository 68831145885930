import FormSelect from "../FormSelect";
import { useEffect, useMemo } from "react";
import { useFetchUsersQuery } from "../../../services/identity";
import { useLazyFetchMessageUsersForMasterAccountQuery } from "../../../services/messages/messagesMasterAccountsApiEndpoints";
import { GenericUserSelectOption } from "./GenericUserSelect";

const MessageUsersSelect = ({ masterAccountId, defaultValue, className, onChange, onBlur, ...rest }) => {
    const { data: users, isFetching: isFetchingUsers } = useFetchUsersQuery({ fetchType: 'List' });
    const [trigger, { data, isFetching }] = useLazyFetchMessageUsersForMasterAccountQuery();

    const userOptions = useMemo(() => {
        if (!users || !users.results) {
            return [];
        }

        return [{
            label: 'System Users',
            options: users.results.map((el) => {
                return {
                    label: el.displayName,
                    value: el.id,
                    isDisabled: false,
                    data: el
                };
            })
        }];
    }, [users]);

    const userAndAccountOptions = useMemo(() => {
        // check we got some data
        if (!data || !Array.isArray(data)) {
            return [];
        }

        // now, lets setup the grouping
        return [
            {
                label: 'Master Account',
                options: data.filter((el) => el.userType === 0)
            },
            {
                label: 'System Users',
                options: data.filter((el) => el.userType === 1)
            }
        ]
    }, [data]);

    useEffect(() => {
        masterAccountId && trigger({ masterAccountId });
    }, [masterAccountId]);

    return <FormSelect
        className={className}
        defaultValue={defaultValue}
        isLoadingOptions={isFetchingUsers || isFetching}
        onBlur={onBlur}
        onChange={onChange}
        options={masterAccountId ? userAndAccountOptions : userOptions}
        components={{
            Option: GenericUserSelectOption
        }}
        {...rest}
    />;
};

export default MessageUsersSelect;
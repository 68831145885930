import React from "react";
import { useAuth } from "react-oidc-context";
import { Modal } from "react-bootstrap";
import { Button } from "../components";

/**
 * Context in charge of monitoring sessions for when 
 * it is about to expire and also once the session has
 * expired to notify the user to re log in.
 */
const SessionContext = React.createContext();

const useSession = () => React.useContext(SessionContext);

export const SessionExpiredModal = React.forwardRef(({ show = false, onSignIn = () => { }, onSignOut = () => { } }, ref) => (
    <Modal ref={ref} centered show={show} backdrop="static" keyboard={false}>
        <Modal.Header>
            <Modal.Title>Session Expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Your session has expired, likely due to inactivity. Please log in again to continue.
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={onSignIn}>Log in to WealthPro</Button>    
            <Button variant="light" onClick={onSignOut}>Sign out</Button>
        </Modal.Footer>
    </Modal>
));

export const SessionProvider = ({ children }) => {
    const modalRef = React.useRef(null);
    const { events, isAuthenticated, signinRedirect, signoutRedirect } = useAuth();
    const [isSessionValid, setIsSessionValid] = React.useState(isAuthenticated);

    const handleSignIn = () => signinRedirect();

    const handleSignOut = () => signoutRedirect();

    React.useEffect(() => {
        return events.addAccessTokenExpired(() => {
            setIsSessionValid(_ => false);
        });
    }, [ events ]);

    return (
        <SessionContext.Provider value={{
            isSessionValid,
            modalRef
        }}>
            <SessionExpiredModal 
                ref={modalRef} 
                show={!isSessionValid} 
                onSignIn={handleSignIn}
                onSignOut={handleSignOut}
            />
            {children}
        </SessionContext.Provider>
    );
};

export default useSession;
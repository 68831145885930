import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const finalSalaryArrangementsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["finalSalaries"]
}).injectEndpoints({
    endpoints: (builder) => ({
        patchFinalSalaryArrangement: builder.mutation({
            query: ({ finalSalaryId, operations }) => ({
                url: `api/factfind/finalsalaryarrangements/${finalSalaryId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ finalSalaryId, operations, masterAccountId }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchFinalSalaryArrangements', { masterAccountId }, (data) => {
                        var item = data.find(x => x.finalSalaryId === finalSalaryId);
                        applyPatch(item, operations, true);
                    }));
                }
                catch { }
            }
        }),
        createFinalSalaryArrangement: builder.mutation({
            query: ({ masterAccountId, pensionName }) => ({
                url: `api/factfind/finalsalaryarrangements`,
                method: 'POST',
                body: {
                    masterAccountId,
                    pensionName
                }
            }),
            invalidatesTags: (result, error, arg) => result
                ? [{ type: "finalSalaries", id: arg.masterAccountId }, "finalSalaries"]
                : ["finalSalaries"]
            // async onQueryStarted({ pensionName, masterAccountId }, { queryFulfilled, dispatch }) {
            //     try {
            //         let createdIndex;
            //         const createResult = dispatch(wealthProApi.util.updateQueryData('fetchFinalSalaryArrangements', { masterAccountId }, (data) => {
            //             const newFinalSalaryArrangement = {
            //                 pensionName,
            //                 masterAccountId,
            //                 clientAccountId: null,
            //                 nra: null,
            //                 annualIncome: null,
            //                 isInPayment: null,
            //                 taxFreeCashEntitlement: null,
            //                 notes: null
            //             };
            //             createdIndex = data.length;

            //             return [newFinalSalaryArrangement, ...data];
            //         }));


            //         try {
            //             const { data: response } = await queryFulfilled;

            //             dispatch(wealthProApi.util.updateQueryData('fetchFinalSalaryArrangements', { masterAccountId }, (data) => [
            //                 ...data.slice(0, createdIndex),
            //                 {
            //                     ...data[createdIndex],
            //                     finalSalaryId: response.finalSalaryId
            //                 },
            //                 ...data.slice(createdIndex + 1)
            //             ]));
            //         } catch (err) {
            //             console.error("Error creating final salary arrangement in service", err);
            //             createResult.undo();
            //         }
            //     } catch (err) {
            //         console.error("Error creating final salary arrangement in cache", err);
            //     }
            // }
        }),
        deleteFinalSalaryArrangement: builder.mutation({
            query: ({ finalSalaryId }) => ({
                url: `api/factfind/finalsalaryarrangements/${finalSalaryId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ finalSalaryId, masterAccountId }, { queryFulfilled, dispatch }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchFinalSalaryArrangements', { masterAccountId }, (data) => {
                        return data.filter(x => x.finalSalaryId !== finalSalaryId);
                    }));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting final salary arrangement in service", err);
                        deleteResult.undo();
                    }
                } catch (err) {
                    console.error("Error deleting final salary arrangement in cache", err);
                }
            }
        })
    })
});

export const {
    useCreateFinalSalaryArrangementMutation,
    usePatchFinalSalaryArrangementMutation,
    useDeleteFinalSalaryArrangementMutation
} = finalSalaryArrangementsApiEndpoints;
import { wealthProApi } from "../../redux/api";

const icShareClassMappingApiEndpoints = wealthProApi/* .enhanceEndpoints({
    addTagTypes: ['shareClasses']
}) */.injectEndpoints({
    endpoints: (build) => ({
        fetchShareClassMappings: build.query({
            query: ({ assetGroupId }) => `api/assets/shareclassmapping/${assetGroupId}`,
            // providesTags: (_res, _err, args) => [{ type: 'shareClasses', id: args.assetGroupId }]
        }),
        createShareClassMapping: build.mutation({
            query: ({ assetGroupId, assetId, oldAssetId }) => ({
                url: `api/assets/shareclassmapping/${assetGroupId}`,
                method: 'POST',
                body: { assetId, oldAssetId }
            }),
            // invalidatesTags: (_res, _err, args) => [{ type: 'shareClasses', id: args.assetGroupId }]
            async onQueryStarted({ assetGroupId, sedol, index }, { dispatch, queryFulfilled }) {
                try {
                    // Update the array pessimistically
                    const { data } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("fetchShareClassMappings", { assetGroupId }, (draft) => {
                        draft[index] = data ?? {
                            ...draft[index],
                            asset: {
                                sedol,
                                assetName: "Code not recognised"
                            }
                        };
                    }));
                } catch (error) {
                    console.error("Failed to update existing data", error);

                    dispatch(wealthProApi.util.updateQueryData("fetchShareClassMappings", { assetGroupId }, (draft) => [
                        ...draft.slice(0, index),
                        {
                            assetGroupId,
                            asset: {
                                sedol,
                                assetName: "Code not recognised"
                            }
                        },
                        ...draft.slice(index + 1)
                    ]));
                }
            }
        }),
        deleteShareClassMapping: build.mutation({
            query: ({ assetGroupId, assetId }) => ({
                url: `api/assets/shareclassmapping/${assetGroupId}`,
                method: 'DELETE',
                body: { assetId }
            }),
            async onQueryStarted({ assetGroupId, assetId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteMappingAction = dispatch(wealthProApi.util.updateQueryData("fetchShareClassMappings", { assetGroupId }, (draft) => {
                        draft = draft.filter(map => map.assetId !== assetId);
                    }))

                    try {
                        await queryFulfilled
                    } catch (err) {
                        console.error("Error deleting mapping");
                        deleteMappingAction.undo();
                    }
                } catch (err) {
                    console.error("Error updating cache", err);
                }
            }
            // invalidatesTags: (_res, _err, args) => [{ type: 'shareClasses', id: args.assetGroupId }]
        })
    })
});

export const {
    useFetchShareClassMappingsQuery,
    useCreateShareClassMappingMutation,
    useDeleteShareClassMappingMutation
} = icShareClassMappingApiEndpoints;
import React from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useLazyGetDocumentOpenUrlQuery } from "../services/clientdocuments";
import { useLazyFetchMasterAccountReportsQuery } from "../services/clientreports";
import useOpenDocument from "./useOpenDocument";

const ClientReportContext = React.createContext();

export const useClientReportsList = () => React.useContext(ClientReportContext);

export const withClientReportProvider = (Component) => (props) => (
    <ClientReportProvider>
        <Component {...props} />
    </ClientReportProvider>
);

export const ClientReportProvider = ({ children }) => {
    // get the master-account we are targeting to list the reports
    const { masterAccountId } = useParams();

     // setup state and our query state (does not load by default (lazy))
    const [sort, setSort] = React.useState("CreatedDate+desc");
    const [pageLimit, setPageLimit] = React.useState(50);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [getDocumentOpenUrl] = useLazyGetDocumentOpenUrlQuery();
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazyFetchMasterAccountReportsQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results, newRecordsSinceCache } = data || { pagination: { page: 1, pageSize: pageLimit, totalCount: 0, totalPages: 1 }, results: [], newRecordsSinceCache: 0 };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true;

    const [isClearing, setIsClearing] = React.useState(false);
    const clear = () => {
        if (!masterAccountId || isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        setIsClearing(_ => true);
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            searchText: searchTerm,
            sort,
            page: 1,
            limit: pageLimit
        }).unwrap().then(_ => {
            setCacheKey(_prev => newCacheKey);
        }).finally(_ => {
            setIsLoadingState(_prev => false);
            setIsClearing(_ => false);
        });
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        setIsLoadingState(_ => true);
        load({ 
            cacheKey, 
            masterAccountId, 
            searchText: searchTerm,
            sort,
            page: page + 1, 
            limit: pageLimit,   
        }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
        });
    };

    const { openDocument, isFetching: isOpening } = useOpenDocument();

    const sortTable = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";
        let [sortProperty, direction] = sort.split("+");

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
        clear();
    };

    React.useEffect(clear, [ searchTerm, sort ]);

    return (
        <ClientReportContext.Provider value={{
            ...loadResults,
            clear,
            data: isClearing ? [] : results,
            hasMore,
            isFetching,
            isLoading,
            isNextPageLoading,
            isNewRecords: newRecordsSinceCache > 0,
            isRowLoaded,
            limit: pageLimit,
            loadMore: isNextPageLoading ? () => {} : loadMore, 
            newRecordsSinceCache,
            openReport: isOpening ? () => {} : openDocument,
            page,
            pagination,
            searchTerm,
            setSearchTerm,
            sort,
            sortTable,
            totalCount,
            totalPages
        }}>
            {children}
        </ClientReportContext.Provider>
    );
};
import { useCallback, useEffect } from "react";
import { PolicyInfo } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

const ProtectionPolicy = () => {
    const [{ fetchPolicy }, { policyIsError }] = useInstruction();

    useEffect(() => {
        fetchPolicy();
    }, [fetchPolicy]);

    const retry = useCallback(() => fetchPolicy(),
        [fetchPolicy]);

    if (policyIsError)
        return <InvestDivestErrorMessage retry={retry} />

    return <PolicyInfo />;
}

export default ProtectionPolicy;
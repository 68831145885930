import React, { useId } from "react";
import styled from "styled-components";
import { lighten } from "polished";
import { Accordion as BootstrapAccordion } from "react-bootstrap";

export const AccordionHeader = styled(BootstrapAccordion.Header)`
    background-color: ${props => props.theme.light};

    & > button {
        color: ${props => props.theme.secondary};
        background-color: ${props => props.theme.light};
    }

    & > button:not(.collapsed) {
        color: ${props => props.theme.secondary};
        background-color: ${props => props.theme.light};
    }

    & > button:focus {
        box-shadow: 0 0 0 0.25rem ${props => lighten(0.55, props.theme.primary)};
    }
`;

export const AccordionBody = styled(BootstrapAccordion.Body)`
    background-color: ${props => props.theme.light};
`;

export const AccordionList = styled(BootstrapAccordion)`
    && > .accordion-item {
        margin-bottom: ${props => props.gap}rem;
    }
`;

export const AccordionListItem = styled(BootstrapAccordion.Item)``;

const Wrapper = ({ children, collapsible, defaultActiveKey = 0, label, ...rest }) => {
    return <BootstrapAccordion defaultActiveKey={defaultActiveKey} {...rest}>
        <BootstrapAccordion.Item eventKey="0">
            {collapsible && (
                <AccordionHeader>
                    {label && (<React.Fragment>{label}</React.Fragment>)}
                </AccordionHeader>
            )}
            <AccordionBody>
                {children}
            </AccordionBody>
        </BootstrapAccordion.Item>
    </BootstrapAccordion>
};

const StyledAccordion = styled(Wrapper)`
    margin-bottom: ${props => props.gap}rem;
`;

const Accordion = ({ children, collapsible = true, colour = "#eee", gap = 0, id, ...rest }) => {
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;

    return <StyledAccordion
        id={componentId}
        collapsible={collapsible}    
        colour={colour}
        gap={gap}
        {...rest}
    >
        {children}
    </StyledAccordion>  
};  

export const AccordionSection = ({ children, defaultActiveKey = "0", gap = 1, ...rest }) => <Accordion defaultActiveKey={defaultActiveKey} gap={gap} {...rest}>{children}</Accordion>

export default Accordion;

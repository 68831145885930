import React from "react";
import { useFilterContext } from "../../../hooks/FilterContext";
import { IconButton } from "../../../components/buttons";
import { GenericUserSelect, ReportFeedbackAspectsSelect, ReportFeedbackRatingsSelect } from "../../../components/forms/selects";

export const FeedbackFilterListTableRow = ({ data, index, onDataChanged, onDataDeleted, ...rest }) => {
    const { feedbackAspectIds, feedbackRatings, userIds } = data;
    return <tr {...rest}>
        <td>
            <ReportFeedbackAspectsSelect
                label={null}
                isMulti={true}
                defaultValue={feedbackAspectIds}
                disableAnimations={true}
                onBlur={(values) => onDataChanged(index, 'feedbackAspectIds', values.map(el => el.value))}
            />
        </td>
        <td className="w-25">
            <ReportFeedbackRatingsSelect
                label={null}
                isMulti={true}
                defaultValue={feedbackRatings}
                disableAnimations={true}
                onBlur={(values) => onDataChanged(index, 'feedbackRatings', values.map(el => el.value))}
            />
        </td>
        <td>
            <GenericUserSelect
                label={null}
                isMulti={true}
                defaultValue={userIds}
                disableAnimations={true}
                onBlur={(values) => onDataChanged(index, 'userIds', values.map(el => el.value))}
            />
        </td>
        <td className="table-action-icon-column">
            <IconButton icon="fa-trash-can" variant="danger" buttonSize="sm" onClick={onDataDeleted} /> 
        </td>
    </tr>
};

const FeedbackFilterList = ({ reportFeedbackFilters, setFilter }) => {
    const handleOnDataAdded = () => {
        setFilter('reportFeedbackFilters', [...reportFeedbackFilters, { feedbackAspectIds: [], feedbackRatings: [], userIds: [] }]);
    };

    const handleOnDataChanged = (index, property, value) => {
        let filters = [...reportFeedbackFilters];
        let modifiedItem = {
            ...filters[index],
            [property]: value
        };

        filters[index] = modifiedItem;
        setFilter('reportFeedbackFilters', filters)
    };

    const handleOnDataDeleted = (index) => {
        let filters = [...reportFeedbackFilters];
        filters.splice(index, 1);
        
        setFilter('reportFeedbackFilters', filters);
    };

    return <div className="table-responsive">
        <table className="table align-middle table-borderless">
            <thead>
                <tr>
                    <th>Feedback Aspect</th>
                    <th className="w-25">Rating</th>
                    <th>Staff Member</th>
                    <th className="table-action-icon-column">
                        <IconButton icon="fa-add" variant="success" buttonSize="sm" onClick={handleOnDataAdded} /> 
                    </th>
                </tr>
            </thead>
            <tbody>
                {reportFeedbackFilters.length === 0 && (
                    <tr>
                        <td colSpan={4}>There are no Feedback Queries currently...</td>
                    </tr>
                )}
                {reportFeedbackFilters.map((el, i) => <FeedbackFilterListTableRow
                    key={i}
                    index={i}
                    data={el}
                    onDataChanged={handleOnDataChanged}
                    onDataDeleted={() => handleOnDataDeleted(i)}
                />)}
            </tbody>
        </table>
    </div>
};

export default FeedbackFilterList;
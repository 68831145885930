import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";

const AllBizSheetsErrorMessage = ({ retry }) => <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
    <ErrorMessage
        message="Sorry! We were unable to load the business sheets for this client. Please try again"
        retryCallback={retry}
    />
</ErrorMessageWrapper>

const BizSheetErrorMessage = ({ retry }) => <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
    <ErrorMessage
        message="Sorry! We were unable to load the instructions for this Business Sheet. Please try again"
        retryCallback={retry}
    />
</ErrorMessageWrapper>

const InstructionErrorMessage = ({ retry }) => <ErrorMessageWrapper maxWidth="500px">
    <ErrorMessage
        message="Sorry! We were unable to load this instruction. Please try again"
        retryCallback={retry}
    />
</ErrorMessageWrapper>

const InvestDivestErrorMessage = ({ retry }) => <ErrorMessageWrapper maxWidth="500px">
    <ErrorMessage
        message="Sorry! We were unable to load the details of this instruction. Please try again"
        retryCallback={retry}
    />
</ErrorMessageWrapper>

const TransactionErrorMessage = ({ retry }) => <ErrorMessageWrapper maxWidth="500px">
    <ErrorMessage
        message="Sorry! We were unable to load the transactions for this instruction. Please try again"
        retryCallback={retry}
    />
</ErrorMessageWrapper>

export {
    AllBizSheetsErrorMessage,
    BizSheetErrorMessage,
    InstructionErrorMessage,
    InvestDivestErrorMessage,
    TransactionErrorMessage
}
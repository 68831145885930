import { wealthProApi } from "../../redux/api";

const calculateTypesApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        fetchCalculateTypes: builder.query({
            query: () => `api/products/calculatetype`
        })
    })
});

export const {
    useFetchCalculateTypesQuery
} = calculateTypesApiEndpoints;
import React from "react";
import DelegatesGridTableHeader from "./components/DelegatesGridTableHeader";
import DelegatesGridTableRow from "./components/DelegatesGridTableRow";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";
import { useUserDelegatesData } from "./hooks/UserDelegatesContext";

const UserDelegatesGrid = () => {
    const {
        clear: refreshTable,
        data,
        error,
        isLoading,
        isNextPageLoading,
        isNewRecords,
        isRowLoaded,
        loadMore,
        newRecordsSinceCache,
        sort,
        sortTable,
        totalCount
    } = useUserDelegatesData();
    const isNewRecordsDisplayed = isNewRecords && !isLoading && !isNextPageLoading;

    return (
        <React.Fragment>
            <VirtualTable 
                enableScrollTracking={false}
                row={DelegatesGridTableRow}
                header={(
                    <thead>
                        <tr>
                            <DelegatesGridTableHeader property="username" sort={sort} sortTable={sortTable}>User</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader property="delegatedUsername" sort={sort} sortTable={sortTable}>Delegated Access</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="ReadCalendar" sort={sort} sortTable={sortTable}>Calendar Read</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="WriteCalendar" sort={sort} sortTable={sortTable}>Calendar Write</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="ReadMail" sort={sort} sortTable={sortTable}>Mail Read</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="WriteMail" sort={sort} sortTable={sortTable}>Mail Write</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="SendMail" sort={sort} sortTable={sortTable}>Mail Send</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="ReadMessages" sort={sort} sortTable={sortTable}>Messages Read</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="WriteMessages" sort={sort} sortTable={sortTable}>Messages Write</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="ReadReminders" sort={sort} sortTable={sortTable}>Reminders Read</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="WriteReminders" sort={sort} sortTable={sortTable}>Reminders Write</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader className="text-center" property="HasClientAccess" sort={sort} sortTable={sortTable}>Client Access</DelegatesGridTableHeader>
                            <DelegatesGridTableHeader property="createdDate" sort={sort} sortTable={sortTable}>Created On</DelegatesGridTableHeader>
                            <th className="sticky-header">&nbsp;</th>
                        </tr>
                    </thead>
                )}
                footer={
                    <TableRecordCountDisplayFooter 
                        totalCount={totalCount}
                        isNewRecordsDisplayed={isNewRecordsDisplayed}
                        newRecordsSinceCache={newRecordsSinceCache}
                        reload={refreshTable}
                        spanRight={8}
                    />
                }
                error={error}
                isLoading={isNextPageLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                itemCount={totalCount}
                itemData={data}
            />
        </React.Fragment>
    );
};

export default UserDelegatesGrid;
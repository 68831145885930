import React from "react";
import styled from "styled-components";
import useOpenDocument from "../../../hooks/useOpenDocument";
import useOpenMasterAccount from "../../../hooks/useOpenMasterAccount";
import { useEditReport } from "../../clientreportseditmodal/hooks/EditReportContext";
import { useReportHistory } from "../../clientreportshistorymodal/hooks/ReportHistoryContext";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemedIcon, ThemedSpan } from "../../../components/utilities";

const ReportManagementDetailTableRowActionColumnParent = styled.td`
    width: ${({ width }) => width}%;
    max-width: 0;
    cursor: pointer;
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
`;

const ReportManagementDetailTableRowActionColumnToggle = React.forwardRef(({ ...rest }, ref) => {
    return (
        <div ref={ref} {...rest}>
            <FontAwesomeIcon icon="fa-ellipsis" />
        </div>
    );
});

const ReportManagementDetailTableRowActionColumn = ({ width = 2.5, data }) => {
    const menuId = React.useId();
    const openMasterAccount = useOpenMasterAccount();
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const { setReport } = useEditReport();
    const { setReport: setReportHistory } = useReportHistory();
    const { documentId, masterAccountId, reportName } = data;

    const handleOpenDocument = () => openDocument(documentId);

    const handleOpenMasterAccount = () => openMasterAccount(masterAccountId);

    const handleOpenHistoryModal = () => setReportHistory(data);

    const handleOpenEditAuthorModal = () => setReport(data);

    return (
        <ReportManagementDetailTableRowActionColumnParent width={width}>
            <Dropdown className="caret-off">
                <Dropdown.Toggle id={menuId} as={ReportManagementDetailTableRowActionColumnToggle} />
                <Dropdown.Menu>
                    <Dropdown.Header>{reportName}</Dropdown.Header>
                    <Dropdown.Item disabled={isOpening} onClick={handleOpenDocument}>
                        <FontAwesomeIcon icon="fas fa-file-signature" size="sm" /> <span className="ms-1">Open Report</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleOpenMasterAccount}>
                        <FontAwesomeIcon icon="fas fa-external-link-alt" size="sm" /> <span className="ms-1">Open Account</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleOpenHistoryModal}>
                        <FontAwesomeIcon icon="fas fa-history" size="sm" /> <span className="ms-1">View History</span>
                    </Dropdown.Item>
                    <Dropdown.Header>
                        Tools
                    </Dropdown.Header>
                    <Dropdown.Item onClick={handleOpenEditAuthorModal}>
                        <FontAwesomeIcon icon="fa fa-pencil" size="sm" /> <span className="ms-1">Assign Details</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ReportManagementDetailTableRowActionColumnParent>
    );
};

export default ReportManagementDetailTableRowActionColumn;
import { useFetchPublishersQuery } from "../../../services/assets";
import { useFetchAnalystsQuery } from "../../../services/assets/icOptionApiEndpoints";

const useFundManagementFilterOptions = () => {
    const { data: publishers, isFetching: isLoadingPublisherOptions } = useFetchPublishersQuery();
    const { data: analysts, isFetching: isLoadingAnalystOptions } = useFetchAnalystsQuery();

    return {
        analysts,
        publishers,
        isLoadingAnalystOptions,
        isLoadingPublisherOptions
    }
}

export default useFundManagementFilterOptions;
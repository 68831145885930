import DateInput from "./DateInput";

const DateTimeInput = ({
    value,
    onBlur,
    isClearable,
    displayFormat = "HH:mm DD/MM/YYYY",
    inputFormat = "YYYY-MM-DD HH:mm",
    ...rest
}) => {

    return <DateInput
        value={value}
        onBlur={onBlur}
        isClearable={isClearable}
        displayFormat={displayFormat}
        inputFormat={inputFormat}
        showTimeSelect={true}
        compareGranularity="minute"
        {...rest}
    />;
};

export default DateTimeInput;
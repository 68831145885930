import { useEffect, useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useDeleteMasterAccountEmailConversationSubjectMutation, useLazyFetchMasterAccountEmailConversationPreviewsQuery, usePatchMasterAccountEmailConversationSubjectMutation } from "../../../services/useremails";
import { useClientEmails } from "./ClientEmailsContext";

const useClientEmailConversations = () => {
    const { searchTerm, attachmentsOnly } = useClientEmails();
    const { id: masterAccountId } = useClientContext();
    const limit = 50;
    const [isClearing, setIsClearing] = useState(false);
    const [fetchPreviews, { data, isLoading, isFetching, isError, error, isUninitialized }] = useLazyFetchMasterAccountEmailConversationPreviewsQuery();
    const { results, pagination } = data || { results: [], pagination: { totalCount: 50, page: 0 } };
    const { totalCount, page } = pagination;

    const [patchSubject] = usePatchMasterAccountEmailConversationSubjectMutation();
    const [deleteSubject] = useDeleteMasterAccountEmailConversationSubjectMutation();

    const clear = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setIsClearing(true);

        fetchPreviews({
            page: 1,
            limit,
            masterAccountId,
            searchTerm,
            attachmentsOnly
        }).finally(_ => setIsClearing(false));
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => fetchPreviews({
        page: page + 1,
        limit,
        masterAccountId,
        searchTerm,
        attachmentsOnly
    });

    useEffect(() => {
        !isUninitialized && clear();
    }, [searchTerm, attachmentsOnly]);

    const updateSubject = (conversationId, subject) => {
        return patchSubject({ masterAccountId, conversationId, subject }).unwrap();
    }

    const clearSubject = (conversationId) => {
        return deleteSubject({ masterAccountId, conversationId }).unwrap();
    }

    return {
        results,
        error,
        isClearing,
        isError,
        isLoading: isFetching,
        searchTerm,
        totalCount,
        clear,
        isItemLoaded,
        loadMoreItems,
        updateSubject,
        clearSubject
    };
};

export default useClientEmailConversations;
import React from "react";
import { Main } from "../../components/utilities";
import ApplicationTransactionsList from "./components/ApplicationTransactionsList";

const ApplicationTransactions = () => {
    return <Main className="py-2">
        <ApplicationTransactionsList />
    </Main>;
};

export default ApplicationTransactions;
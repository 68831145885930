import React from "react";
import toast from "react-hot-toast";
import { useLazyCountMasterAccountDashboardSessionsQuery, usePatchMasterAccountDashboardLoginMutation } from "../../../services/clientauth";
import { useLazyFetchMasterAccountContactDetailsEmailListQuery, useLazyFetchMasterAccountContactDetailsMobileListQuery } from "../../../services/clientdetails";


const ClientDashboardLoginDetailsContext = React.createContext();

const useClientDashboardLoginDetails = () => React.useContext(ClientDashboardLoginDetailsContext);

export const ClientDashboardLoginDetailsProvider = ({ children, showModalOnValueSet = true }) => {
    const [data, _setData] = React.useState(null);
    const [isDirty, setIsDirty] = React.useState(null);
    const [isModalShown, _setShowModal] = React.useState(false);
    const [_patch, { isLoading: isPatching, ...patchResult }] = usePatchMasterAccountDashboardLoginMutation();
    const [count, { data: totalSessions, isLoading: isFetchingSessionCount, ...fetchSessionCountResults }] = useLazyCountMasterAccountDashboardSessionsQuery();
    const [fetchEmails, { data: availableEmailAddresses, isLoading: isFetchingEmails, ...fetchEmailsResults }] = useLazyFetchMasterAccountContactDetailsEmailListQuery();
    const [fetchMobile, { data: availablePhoneNumbers, isLoading: isFetchingMobileNumbers, ...fetchMobileResults }] = useLazyFetchMasterAccountContactDetailsMobileListQuery();
    const isLoading = isFetchingEmails || isFetchingMobileNumbers || isFetchingSessionCount;

    const clear = () => {
        _setData(_ => null);
        _setShowModal(_ => false);
    };

    const setData = (data) => {
        if (!data || !data?.masterAccountId) {
            return;
        }

        let fetchEmailsPromise = fetchEmails({ masterAccountId: data.masterAccountId });
        let fetchMobilePromise = fetchMobile({ masterAccountId: data.masterAccountId });
        let fetchSessionCount = count({ masterAccountId: data.masterAccountId, loginId: data.id });

        toast.promise(Promise.all([fetchEmailsPromise, fetchMobilePromise, fetchSessionCount]), {
            loading: `Getting login details...`,
            success: () => {
                _setData(_ => data);

                if (showModalOnValueSet === true) {
                    _setShowModal(_ => true);
                }

                return;
            },
            error: `There was a problem selecting the login record. Potential failure to retrieve information from the server.`
        });
    };

    const updateData = (masterAccountId, loginId, operations) => {
        return new Promise((resolve, reject) => {
            return _patch({ masterAccountId, loginId, operations })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return (
        <ClientDashboardLoginDetailsContext.Provider value={{
            availableEmailAddresses: availableEmailAddresses || [],
            availablePhoneNumbers: availablePhoneNumbers || [],
            totalSessions: totalSessions || 0,
            clear,
            data,
            info: {
                emails: { data: availableEmailAddresses, isLoading: isFetchingEmails, ...fetchEmailsResults },
                mobile: { data: availablePhoneNumbers, isLoading: isFetchingMobileNumbers, ...fetchMobileResults },
                count: { data: totalSessions, isLoading: isFetchingSessionCount, ...fetchSessionCountResults },
                patch: { isLoading: isPatching, ...patchResult }
            },
            isDirty,
            isLoading: isLoading || isPatching,
            isModalShown,
            reloadSessionCount: () => count({ masterAccountId: data?.masterAccountId, loginId: data?.id }),
            reloadAvailableEmailAddresses: () => fetchEmails({ masterAccountId: data?.masterAccountId }),
            reloadAvailablePhoneNumbers: () => fetchMobile({ masterAccountId: data?.masterAccountId }),
            setData,
            setIsDirty,
            updateData
        }}>
            {children}
        </ClientDashboardLoginDetailsContext.Provider>
    );
};

export default useClientDashboardLoginDetails;
import React from "react";
import toast from "react-hot-toast";
import useCreateIntroducer from "../hooks/useCreateIntroducer";
import IntroducerForm from "./IntroducerForm";
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "react-bootstrap";
import { Button } from "../../../components";

const CreateIntroducerModal = ({ 
    show,
    handleClose = () => {},
    onCreatedEvent = () => {}
}) => {
    const { create, defaultValues, isLoading, isAllowedToUpdateStatus } = useCreateIntroducer();
    const { control, formState: { errors }, handleSubmit, reset, setValue, watch } = useForm({ defaultValues });

    const _close = () => {
        reset(defaultValues);
        handleClose();
    };

    const _submit = (data) => {
        toast.promise(create(data), {
            loading: 'Creating Introducer...',
            success: (r) => {
                onCreatedEvent(r);
                return `Introducer has been created successfully.`;
            },
            error: (err) => {
                return `There was a problem trying to create the Introducer.`;
            }
        }).finally(_close);
    };

    return (
        <Modal size="xl" centered backdrop="static" show={show} onHide={_close}>
            <Modal.Header>
                <Modal.Title>Create a new Introducer</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(_submit)}>
                <ModalBody>
                    <IntroducerForm 
                        control={control}
                        errors={errors}
                        isAllowedToUpdateStatus={isAllowedToUpdateStatus}
                        setValue={setValue}
                        watch={watch}
                    />
                </ModalBody>
                <Modal.Footer>
                    <Button variant="success" type="submit" disabled={isLoading === true}>Create</Button>
                    <Button variant="light" disabled={isLoading === true} onClick={_close}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateIntroducerModal;
import { ErrorMessage } from "../../../components/messages";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";

const MissingAccountsGridErrorRow = ({columns, rest}) => {
    const { potentialAccountMatches } = useCustodianTransactionMatching();
    const {
        error,
        retryFetch
    } = potentialAccountMatches;

    return (
        <tr {...rest}>
            <td colSpan={columns.length}>
                <ErrorMessage error={error} retryCallback={retryFetch} />
            </td>
        </tr>
    );
};

export default MissingAccountsGridErrorRow;
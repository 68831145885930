import React from "react";
import FormSelect from "../FormSelect";

export const IntroducerStatuses = [
    { label: "Approved", value: 0 },
    { label: "Await Approval", value: 1 },
    { label: "Rejected", value: 2 },
    { label: "Inactive", value: 3 }
];

const IntroducerStatusSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={IntroducerStatuses.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default IntroducerStatusSelect;
import React from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { 
    useAddClientToFavouritesMutation,
    useGetMasterAccountDetailSummaryQuery,
    useRemoveClientFromFavouritesMutation 
} from "../services/clientdetails";

const TrackingCookieName = "wp_master_account_tracking";

const ClientContext = React.createContext();

export const useClientContext = () => React.useContext(ClientContext);

export const withClientProvider = (Component) => (props) => (
    <ClientProvider>
        <Component {...props} />
    </ClientProvider>
);
 
export const ClientProvider = ({ children }) => {
    const { masterAccountId } = useParams();
    const [addToFavouriteMutation, addToFavouriteResult] = useAddClientToFavouritesMutation();
    const [removeFromFavouriteMutation, removeFromFavouriteResult] = useRemoveClientFromFavouritesMutation();
    const { data, error, isError, isFetching, isLoading, isUninitialized, refetch } = useGetMasterAccountDetailSummaryQuery({ masterAccountId });

    const addToFavourites = () => {
        return new Promise((resolve, reject) => {
            return addToFavouriteMutation({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const reload = (e) => {
        if (e) {
            e.preventDefault();
        }
        refetch({ masterAccountId });
    };

    const removeFromFavourites = () => {
        return new Promise((resolve, reject) => {
            return removeFromFavouriteMutation({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    React.useEffect(() => {
        if (masterAccountId) {
            Cookies.set(TrackingCookieName, masterAccountId, { });
        }

        return () => {
            Cookies.remove(TrackingCookieName);
        };
    }, [ masterAccountId ]);

    return <ClientContext.Provider value={{
        id: masterAccountId,
        account: data,
        addToFavourites,
        error,
        isError,
        isFetching,
        isLoading,
        isUninitialized,
        reload,
        removeFromFavourites
    }}>
        {children}
    </ClientContext.Provider>
};
import ClientReportManagementLayout from "../layouts/ClientReportManagementLayout";
import { NavigationTabs } from "../components/navigation";
import {
    ReportManagementDetailedPage,
    ReportTemplateManagementPage,
    SummaryByAdviserPage,
    SummaryByAuthorPage
} from "../pages/management-client-reports";

const ManagementReportRoutes = {
    path: 'reports',
    pathBase: '/management/reports',
    defaultRoute: "detailed",
    roles: [],
    element: ClientReportManagementLayout,
    children: [
        { 
            path: 'detailed', 
            icon: 'fa-table', 
            label: 'Detailed View', 
            roles: [],
            element: ReportManagementDetailedPage
        },
        { 
            path: 'adviser-summary', 
            icon: 'fa-user', 
            label: 'Summary by Adviser', 
            roles: ['sys_admin_access', 'report_admin'],
            element: SummaryByAdviserPage
        },
        { 
            path: 'author-summary', 
            icon: 'fa-user-edit', 
            label: 'Summary by Author', 
            roles: ['sys_admin_access', 'report_admin'],
            element: SummaryByAuthorPage
        },
        { 
            path: 'templates', 
            icon: 'fa-pencil-alt', 
            label: 'Report Template Management', 
            roles: ['sys_admin_access', 'sys_def_report_templates', 'report_admin'],
            element: ReportTemplateManagementPage
        }
    ]
};

export const ManagementReportRouteTabs = () => <NavigationTabs pathBase={ManagementReportRoutes.pathBase} options={ManagementReportRoutes} />

export default ManagementReportRoutes;
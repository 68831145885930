import Skeleton from "react-loading-skeleton";
import { CurrencyInput, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useRetirementPlans from "../hooks/useRetirementPlans";
import GridWrapper from "../../../components/GridWrapper";
import React from "react";
import styled from "styled-components";

const TwoDigitFormInput = styled(FormInput)`
    max-width: 10ch;
`;

const ClientRetirementPlans = () => {

    const { data, isLoading, isError, error, refetch, updatePlan, ltaProtectionTypes, lifeStages } = useRetirementPlans();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetch} />
        </ErrorMessageWrapper>;
    }

    return <GridWrapper gridTemplateColumns={"0.5fr 1fr 20ch 1fr 15ch 20ch"}>
        <span className="col-start-2">
            <FormLabel>
                Life Stage
            </FormLabel>
        </span>
        <span className="col-start-3 mx-auto">
            <FormLabel>
                Intended Retirement Age
            </FormLabel>
        </span>
        <span className="col-start-4">
            <FormLabel>
                LTA Protection
            </FormLabel>
        </span>
        <span className="col-start-5 mx-auto">
            <FormLabel>
                State Pension Age
            </FormLabel>
        </span>
        <span className="col-start-6">
            <FormLabel>
                Pension Forecast
            </FormLabel>
        </span>

        {data?.map((plan, index) =>
            <React.Fragment key={plan?.retirementPlanId ?? index}>
                <span className="col-start-1 my-auto">
                    {isLoading || !plan?.retirementPlanId
                        ? <Skeleton />
                        : <FormLabel>
                            {plan?.clientName}
                        </FormLabel>}
                </span>
                <span className="col-start-2">
                    {isLoading || !plan?.retirementPlanId
                        ? <Skeleton />
                        : <FormSelect
                            options={lifeStages}
                            defaultValue={plan?.lifeStage}
                            onBlur={(e) => updatePlan(plan?.retirementPlanId, "lifeStage", e.value)}
                        />}
                </span>
                <span className="col-start-3 mx-auto">
                    {isLoading || !plan?.retirementPlanId
                        ? <Skeleton />
                        : <TwoDigitFormInput
                            type="number"
                            min="0"
                            value={plan?.intendedRetirementAge}
                            onBlur={(value) => updatePlan(plan?.retirementPlanId, "intendedRetirementAge", value)}
                        />}
                </span>
                <span className="col-start-4">
                    {isLoading || !plan?.retirementPlanId
                        ? <Skeleton />
                        : <FormSelect
                            options={ltaProtectionTypes}
                            defaultValue={plan?.ltaProtectionType}
                            onBlur={(e) => updatePlan(plan?.retirementPlanId, "ltaProtectionType", e.value)}
                        />}
                </span>
                <span className="col-start-5 mx-auto">
                    {isLoading || !plan?.retirementPlanId
                        ? <Skeleton />
                        : <TwoDigitFormInput
                            type="number"
                            min="0"
                            value={plan?.statePensionAge}
                            onBlur={(value) => updatePlan(plan?.retirementPlanId, "statePensionAge", value)}
                        />}
                </span>
                <span className="col-start-6">
                    {isLoading || !plan?.retirementPlanId
                        ? <Skeleton />
                        : <CurrencyInput
                            value={plan?.pensionForecast}
                            onBlur={(value) => updatePlan(plan?.retirementPlanId, "pensionForecast", value)}
                        />}
                </span>
            </React.Fragment>)}
    </GridWrapper>
};

export default ClientRetirementPlans;
import { useLazyGetCalendarEventByIdQuery } from "../../../services/usercalendar";

const useDiaryEvent = () => {

    const [getEventTrigger, getEventByIdResult] = useLazyGetCalendarEventByIdQuery();

    const getEventById = (userId, eventId) => {
        return new Promise((resolve, reject) => {
            return getEventTrigger({userId, eventId})
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        getEventById,
        getEventByIdResult
    };
}

export default useDiaryEvent;
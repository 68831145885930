import { useFetchCoverTypesQuery, useFetchProductTypesQuery } from "../../../services/clientfactfind";

const useProductTypes = (productTypeId) => {

    const { data: productTypes, isLoading: isLoadingProductTypes } = useFetchProductTypesQuery({});
    const { data: associatedCoverTypes, isLoading: isLoadingAssociatedCoverTypes } = useFetchCoverTypesQuery({ productTypeId }, { refetchOnMountOrArgChange: true });

    return {
        productTypes: productTypes || [],
        associatedCoverTypes: associatedCoverTypes || [],
        isLoadingProductTypes,
        isLoadingAssociatedCoverTypes
    };
};

export default useProductTypes;
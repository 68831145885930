import { useMemo, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button, { IconButton } from "../../../components/buttons";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { ViewPdfModal } from "../../../components/modals";
import { PageHeader } from "../../../components/utilities";
import { PageHeaderSubTitle, PageHeaderTitle } from "../../../components/utilities/PageHeader";
import useFundDetails from "../hooks/useFundDetails";

const FundInfoHeader = ({ assetNameDisplayText, monitoringDisplayText, controlStatusDisplayText }) => {
    const navigate = useNavigate();

    const [{
        reload,
        showPreview,
        showDraftPreview,
        hidePreview
    }, {
        assetGroup,
        assetGroupIsNotReady,
        assetGroupIsError,
        assetGroupError,
        pdfPreview,
        isGettingPdfPreview,
        shouldShowPdfPreview,
        getPdfPreviewError
    }] = useFundDetails();

    const publishedInfo = useMemo(() =>
        assetGroup?.lastPublishedDate == null
            ? "Never Published"
            : `Last Published: ${new Date(assetGroup?.lastPublishedDate)?.toLocaleDateString()}`,
        [assetGroup?.lastPublishedDate])

    const [showingDraft, setShowingDraft] = useState(false);

    return <>
        <ViewPdfModal
            title="Fact Sheet Preview"
            data={pdfPreview}
            show={shouldShowPdfPreview}
            handleClose={hidePreview}
            isLoading={isGettingPdfPreview}
            error={getPdfPreviewError}
            refetch={() => showPreview(showingDraft)}
            backdrop
        />
        <PageHeader>
            {(!assetGroupIsNotReady && (assetGroupIsError || assetGroup == null))
                ? <ErrorMessageWrapper>
                    <ErrorMessage
                        error={assetGroupError}
                        message={assetGroup == null ? "No Fund Found with that ID" : assetGroupError?.message ?? "Unknown Error"}
                        retryCallback={assetGroup == null ? null : reload}
                    />
                </ErrorMessageWrapper>
                : <>
                    <PageHeaderTitle className="d-flex justify-content-between">
                        <div className="align-self-center">
                            {assetGroupIsNotReady
                                ? "Fund Management: Loading Fund Details..."
                                : `${assetNameDisplayText ?? assetGroup?.assetName ?? "Unnamed Fund"}: ${monitoringDisplayText ?? "No Monitoring Set"}`}
                        </div>
                        {!assetGroupIsNotReady && <>
                            <h5 className="ms-auto my-auto me-2">
                                View Fact Sheet:
                            </h5>
                            <ButtonGroup className="me-3">
                                {assetGroup.controlStatus !== 3 // Only show the draft button if the fund has been modified since last publication
                                    && <Button
                                        size="xl"
                                        variant="warning"
                                        onClick={() => {
                                            setShowingDraft(true);
                                            showDraftPreview()
                                        }}>
                                        Draft
                                    </Button>}
                                {assetGroup?.currentOpinionPublishedDate != null // Only show the published button if the fund has been published before
                                    && <Button
                                        size="xl"
                                        variant="success"
                                        onClick={() => {
                                            setShowingDraft(false);
                                            showPreview()
                                        }}>
                                        Published
                                    </Button>}
                            </ButtonGroup>
                        </>}
                        <IconButton
                            icon="fa-folder-minus"
                            size="xl"
                            onClick={() => navigate(-1)}
                        />
                    </PageHeaderTitle>
                    <PageHeaderSubTitle className="mt-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="me-auto">
                                {`Analyst: ${assetGroupIsNotReady
                                    ? "Loading"
                                    : assetGroup?.investmentAnalyst != null
                                        ? `${assetGroup?.investmentAnalyst.forenames ?? ""} ${assetGroup?.investmentAnalyst.surname ?? ""}`
                                        : "Not Set"}`}
                            </span>
                            <span className="mx-auto">
                                {assetGroupIsNotReady
                                    ? "Loading"
                                    : publishedInfo}
                            </span>
                            <span className="ms-auto">
                                {`Status: ${assetGroupIsNotReady
                                    ? "Loading"
                                    : assetGroup?.controlStatus === 2
                                        ? "Awaiting Publication"
                                        : controlStatusDisplayText}`}
                            </span>
                        </div>
                    </PageHeaderSubTitle>
                </>}
        </PageHeader>
    </>
}

export default FundInfoHeader;
import { wealthProApi } from '../../redux/api';

const hoAdminPlatformAccountDesignationMapsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["platformAccountDesignationMap"]
})
    .injectEndpoints({
        endpoints: (builder) => ({
            fetchNewPotentialPlatformAccountDesignationMatches: builder.query({
                query: () => `api/headofficeadmin/platformAccountDesignationMappings/potential`,
                providesTags: ["platformAccountDesignationMap"]
            }),
            createHeadOfficePlatformAccountDesignationMapping: builder.mutation({
                query: (request) => ({
                    url: 'api/headofficeadmin/platformAccountDesignationMappings',
                    method: 'POST',
                    body: request
                }),
                invalidatesTags: ["platformAccountDesignationMap"]
            }),
            deleteHeadOfficePlatformAccountDesignationMapping: builder.mutation({
                query: ({ mapId }) => ({
                    url: `api/headofficeadmin/platformAccountDesignationMappings/${mapId}`,
                    method: 'DELETE'
                }),
                onQueryStarted: async ({ mapId }, { dispatch, queryFulfilled }) => {
                    var patch = dispatch(wealthProApi.util.updateQueryData(
                        'searchHeadOfficePlatformAccountDesignationMappings',
                        {},
                        ({ results, totalCount, ...rest }) => ({
                            ...rest,
                            results: results.filter(x => x.mapId !== mapId),
                            totalCount: totalCount - 1
                        })
                    ));

                    try {
                        await queryFulfilled;
                    }
                    catch { 
                        patch.undo();
                    }
                }
            }),
            updateHeadOfficePlatformAccountDesignationMapping: builder.mutation({
                query: ({ mapId, request }) => ({
                    url: `api/headofficeadmin/platformAccountDesignationMappings/${mapId}`,
                    method: 'PATCH',
                    body: request
                }),
                onQueryStarted: async ({ mapId }, { dispatch, queryFulfilled }) => {
                    try {
                        var { data } = await queryFulfilled;
                        dispatch(wealthProApi.util.updateQueryData(
                            'searchHeadOfficePlatformAccountDesignationMappings',
                            {},
                            ({ results }) => {
                                var item = results.find(x => x.mapId === mapId);
                                Object.assign(item, data);
                            }
                        ));
                    }
                    catch { }
                }
            }),
            searchHeadOfficePlatformAccountDesignationMappings: builder.query({
                query: ({ request, nextToken, limit = 25, sort = "CreatedDate" }) => ({
                    url: `api/headofficeadmin/platformAccountDesignationMappings/search?sort=${sort}&limit=${limit}${nextToken ? "&nextToken=" + nextToken : ""}`,
                    method: 'POST',
                    body: request
                }),
                serializeQueryArgs: ({ endpointName }) => {
                    return endpointName;
                },
                merge: (currentCache, newItems) => {
                    const { next: currentNext, previous: currentPrevious } = currentCache || {};
                    const { next, previous } = newItems || {};

                    const isSamePage = next === currentNext;
                    const isPageOne = !previous;

                    if (isSamePage || isPageOne) {
                        return newItems;
                    }

                    currentCache.next = next;
                    currentCache.previous = newItems.previous;
                    currentCache.totalCount = newItems.totalCount;
                    currentCache.results.push(...newItems.results);
                },
                forceRefetch: ({ currentArg, previousArg }) => {
                    const { next: currPage } = currentArg || { next: 1 };
                    const { next: prevPage } = previousArg || { next: 0 };
                    return currPage !== prevPage;
                },
                invalidatesTags: ["platformAccountDesignationMap"]
            })
        })
    });

export const {
    useFetchNewPotentialPlatformAccountDesignationMatchesQuery,
    useCreateHeadOfficePlatformAccountDesignationMappingMutation,
    useLazySearchHeadOfficePlatformAccountDesignationMappingsQuery,
    useUpdateHeadOfficePlatformAccountDesignationMappingMutation,
    useDeleteHeadOfficePlatformAccountDesignationMappingMutation
} = hoAdminPlatformAccountDesignationMapsApiEndpoints;
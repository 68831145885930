import React, { useEffect } from "react";
import { Main } from "../../components/utilities";
import PageHeader, { PageHeaderTop } from "../../components/layout/PageHeader";
import UserCalendar from "./components/UserCalendar";
import DiaryContextProvider from "./hooks/DiaryContext";
import { usePersonalOutlookContext } from "../../hooks/PersonalOutlookContext";
import { PageHeaderTitle } from "../../components/utilities/PageHeader";

const PersonalDiary = () => {

    const { getCalendarDelegateRights, selectedCalendarUserId } = usePersonalOutlookContext();

    useEffect(() => {
        getCalendarDelegateRights({ userId: selectedCalendarUserId });
    }, [selectedCalendarUserId]);

    return <DiaryContextProvider>
        <PageHeader>
            <PageHeaderTop>
                <PageHeaderTitle>
                    <span>Diary Management</span>
                </PageHeaderTitle>
            </PageHeaderTop>
        </PageHeader>
        <Main className="my-3 px-2">
            <UserCalendar />
        </Main>
    </DiaryContextProvider>
};

export default PersonalDiary;
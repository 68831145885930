import styled from "styled-components";

export const ClientTrackingWrapper = styled.div`
    @media (hover: none) {
        display: none !important;
    }

    @media (max-width: 767.98px) {
        display: none !important;
    }
`;

const HeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

export default HeaderTop;
import _ from "lodash";
import React from "react";
import { usePatchDelegateAccessMutation } from "../../../services/identity";

const useEditUserDelegate = (delegate) => {
    const { delegateAccessId } = delegate || { delegateAccessId: -1 };
    const [_patch, results] = usePatchDelegateAccessMutation();
    const [defaultValues, setDefaultValues] = React.useState(delegate);

    const update = (operations) => {
        return new Promise((resolve, reject) => {
            return _patch({ delegateAccessId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    React.useEffect(() => {
        setDefaultValues(_ => delegate);
    }, [ delegate ]);

    return {
        ...results,
        update,
        defaultValues
    }
};

export default useEditUserDelegate;
import styled from "styled-components";

const ProfileGroupText = styled.p`
    margin: 0;
    color: ${props => props.theme.muted};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default ProfileGroupText;
import { wealthProApi } from "../../redux/api";

const assetSectorApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        createAssetSector: build.mutation({
            query: ({ organisationId }) => ({
                url: 'api/assets/sector',
                method: 'POST',
                body: {
                    organisationId
                }
            })
        }),
        deleteAssetSector: build.mutation({
            query: ({ sectorId }) => ({
                url: `api/assets/sector/${sectorId}`,
                method: 'DELETE',
            })
        }),
        patchAssetSector: build.mutation({
            query: ({ sectorId, operations }) => ({
                url: `api/assets/sector/${sectorId}`,
                method: 'PATCH',
                body: operations
            })
        }),
        fetchAssetSectorsForGrid: build.query({
            query: ({ page, limit, sort, filterModel }) => ({
                url: 'api/assets/sector/grid',
                method: 'POST',
                body: {
                    page,
                    limit,
                    sort,
                    filterModel
                }
            })
        }),
        fetchAssetSectorsForSelect: build.query({
            query: ({ page, limit }) => `api/assets/sector/select?page=${page}&limit=${limit}`
        }),
        searchAssetSectorsForSelect: build.query({
            query: ({ searchTerm }) => `api/assets/sector/search?searchTerm=${searchTerm}`
        }),
        fetchSectorByCode: build.query({
            query: ({ sectorCode }) => `api/assets/sector/code/${sectorCode}`
        })
    })
});

export const {
    useCreateAssetSectorMutation,
    useDeleteAssetSectorMutation,
    usePatchAssetSectorMutation,
    useLazyFetchAssetSectorsForGridQuery,
    useLazyFetchAssetSectorsForSelectQuery,
    useLazySearchAssetSectorsForSelectQuery,
    useLazyFetchSectorByCodeQuery
} = assetSectorApiEndpoints;
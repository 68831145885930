import { wealthProApi } from "../../redux/api";

const instructionTypeWrapperMapsEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        fetchInstructionTypeWrapperMaps: builder.query({
            query: ({wrapperId, page, limit, sort = "CreatedDate"}) => `api/products/instructiontypewrappermap/grid/${wrapperId}?page=${page}&limit=${limit}&sort=${sort}`
        }),
        createInstructionTypeWrapperMap: builder.mutation({
            query: ({wrapperId}) => ({
                url: 'api/products/instructiontypewrappermap',
                method: 'POST',
                body: {
                    wrapperId
                }
            })
        }),
        deleteInstructionTypeWrapperMap: builder.mutation({
            query: ({mappingId}) => ({
                url: `api/products/instructiontypewrappermap/${mappingId}`,
                method: "DELETE"
            })
        }),
        patchInstructionTypeWrapperMap: builder.mutation({
            query: ({mappingId, operations}) => ({
                url: `api/products/instructiontypewrappermap/${mappingId}`,
                method: "PATCH",
                body: operations
            })
        })
    })
});

export const {
    useCreateInstructionTypeWrapperMapMutation,
    useDeleteInstructionTypeWrapperMapMutation,
    usePatchInstructionTypeWrapperMapMutation,
    useLazyFetchInstructionTypeWrapperMapsQuery
} = instructionTypeWrapperMapsEndpoints;
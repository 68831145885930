import React, { useEffect, useState } from "react";
import {
    useCreateDraftMailMutation,
    useDeleteDraftMailMutation,
    useDeleteUserEmailContactMutation,
    useLazyFetchFolderMessagesForUserQuery,
    useLazyFetchMailFoldersForUserQuery,
    useLazyFetchUserEmailContactsQuery,
    usePatchDraftMailMutation,
    usePatchUserEmailContactMutation,
    useSendDraftMailMutation
} from "../services/useremails";
import { useNavigate, useParams } from "react-router-dom";
import CreateMailModal from "../features/createmailmodal";
import EventModal from "../features/eventModal";
import { useAuth } from "react-oidc-context";
import { useLazyGetDelegatedAccessRightsForUserQuery } from "../services/identity";

export const MailSendType = {
    Default: 0,
    Forward: 1,
    Reply: 2,
    ReplyAll: 3
};

// NOTE: set this up as a context as no doubt we will need access to emails & calenders from various sections other than the "main page"
// TODO: hook in infinite scroll methods so we can fetch more messages, join them together etc. There is a bit more work and I'm just messing about on a random afternoon!

const PersonalOutlookContext = React.createContext();

export const usePersonalOutlookContext = () => React.useContext(PersonalOutlookContext);

export const PersonalOutlookProvider = ({ children }) => {
    // TODO - Clean up
    const { user: { profile } } = useAuth();
    const [mailId, setMailId] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    // Keep here
    const appNavigator = useNavigate();
    const { folderId } = useParams();
    const [getMailDelegateRights, mailDelegateRightsResult] = useLazyGetDelegatedAccessRightsForUserQuery();
    const [getCalendarDelegateRights, calendarDelegateRightsResult] = useLazyGetDelegatedAccessRightsForUserQuery();
    const [eventModalProps, setEventModalProps] = useState({ show: false });
    const [fetchFolders, folders] = useLazyFetchMailFoldersForUserQuery();
    const [fetchMessageSummaries, messageSummaries] = useLazyFetchFolderMessagesForUserQuery();
    const [selectedMailUserId, setSelectedMailUserId] = useState(profile.sub.replace(/-/g, ""));
    const [selectedCalendarUserId, setSelectedCalendarUserId] = useState(profile.sub.replace(/-/g, ""));

    // move to relative areas
    const [deleteDraftEmail, deleteDraftEmailResult] = useDeleteDraftMailMutation();
    const [deleteEmailContact, deleteEmailContactResult] = useDeleteUserEmailContactMutation();
    const [fetchEmailContacts, emailContacts] = useLazyFetchUserEmailContactsQuery();
    const [patchDraftEmail, patchDraftEmailResult] = usePatchDraftMailMutation();
    const [patchEmailContact, patchEmailContactResult] = usePatchUserEmailContactMutation();
    const [sendDraftEmail, sendDraftEmailResult] = useSendDraftMailMutation();

    const _findInboxFolder = (folders) => folders.find(el => el?.displayName && el.displayName === 'Inbox')?.id;

    const _loadFolders = (userId) => {
        fetchFolders({ userId }).unwrap().then(r => selectFolder(_findInboxFolder(r)));
    };

    const selectFolder = (folderId) => {
        if (!folderId) {
            return;
        }
        appNavigator(`personal/emails/${folderId}`);
    };

    useEffect(() => {
        setMailId(null);
    }, [folderId]);

    // useEffect(() => {
    //     if (!folders || !folders?.data || !Array.isArray(folders?.data)) {
    //         console.log('folder data is not in a valid format', folders);
    //         return;
    //     }

    //     var inboxFolder = folders.data.find(el => el.displayName == 'Inbox');
    //     console.log('inbox folder?', inboxFolder);
    //     if (inboxFolder) {
    //         selectFolder(inboxFolder.id);
    //     }
    // }, [folders]);

    return (
        <PersonalOutlookContext.Provider value={{
            getMailDelegateRights,
            getCalendarDelegateRights,
            fetchFolders: _loadFolders,
            fetchMessageSummaries,
            folders,
            messageSummaries,
            selectFolder,
            selectedFolderId: folderId,
            selectedMailUserId,
            setSelectedMailUserId,
            mailDelegateRightsResult,
            selectedCalendarUserId,
            setSelectedCalendarUserId,
            calendarDelegateRightsResult,
            mailId,
            setMailId,
            searchTerm,
            setSearchTerm,
            editEmailContactModal: {
                deleteEmailContact,
                deleteEmailContactResult,
                patchEmailContact,
                patchEmailContactResult
            },
            eventModal: {
                props: eventModalProps,
                setProps: setEventModalProps
            },
            fetchEmailContacts,
            emailContacts,
            patchDraftEmail,
            patchDraftEmailResult,
            deleteDraftEmail: (req) => deleteDraftEmail({ ...req, searchTerm }),
            deleteDraftEmailResult,
            sendDraftEmail: (req) => sendDraftEmail({ ...req, searchTerm }),
            sendDraftEmailResult
        }}>
            {children}
            <EventModal {...eventModalProps} />
        </PersonalOutlookContext.Provider>
    );
};
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { GridWrapper } from "../../../components";
import {
    CurrencyInput,
    FormSelect,
    PercentageInput
} from "../../../components/forms";
import { patchReplace } from "../../../helpers/patchDoc";
import { useBizSheet } from "../contexts/BizSheetContext";
import { useInstruction } from "../contexts/InstructionContext";
import useRingFence from "../hooks/useRingFence";

const RingFence = () => {
    const [, { designations }] = useBizSheet();
    const [, { realTimeInstruction, invest, investIsLoading, divestIsLoading }, { hasRingFenceTransaction }] = useInstruction();

    const {
        setRealTimeDesignationId,
        custodyOptions,
        createCustodyOption,
        isLoadingCustodyOptions,
        ringFenceFeeType,
        setRingFenceFeeType,
        ringFenceFeeOptions,
        patchInvest,
        realTimePatchInvest
    } = useRingFence();

    const [displayFeePercent, setDisplayFeePercent] = useState(realTimeInstruction?.invest?.ringFenceFeePercent);

    useEffect(() => {
        if (ringFenceFeeType === 0 && displayFeePercent > 100)
            return;

        setDisplayFeePercent(realTimeInstruction?.invest?.ringFenceFeePercent)
    }, [displayFeePercent, realTimeInstruction?.invest?.ringFenceFeePercent, ringFenceFeeType]);

    return hasRingFenceTransaction
        ? <GridWrapper gridTemplateColumns={"repeat(5,minmax(auto,20%))"}>
            {investIsLoading
                ? <Skeleton />
                : <FormSelect
                    label="Ringfence Designation:"
                    defaultValue={invest?.ringFenceDesignationId}
                    options={designations ?? []}
                    onChange={(selection) => setRealTimeDesignationId(selection.value)}
                    onBlur={(selection) => patchInvest([patchReplace("ringFenceDesignationId", selection.value)])}
                />}

            {investIsLoading
                ? <Skeleton />
                : <FormSelect
                    label="Custody Reference:"
                    className={"align-self-end"}
                    defaultValue={invest?.ringFenceCustodyReference}
                    options={custodyOptions}
                    isLoadingOptions={isLoadingCustodyOptions}
                    onCreateOption={createCustodyOption}
                    onBlur={(selection) => patchInvest([patchReplace("ringFenceCustodyReference", selection.value)])}
                    isCreateable
                />}

            {investIsLoading
                ? <Skeleton />
                : <div className={"align-self-end"}>
                    <FormSelect
                        label="Service Fee Type:"
                        options={ringFenceFeeOptions}
                        defaultValue={invest?.ringFenceFeeType}
                        onChange={(selection) => setRingFenceFeeType(selection.value)}
                        onBlur={(selection) => patchInvest([patchReplace("ringFenceFeeType", selection.value)])}
                    />
                </div>}

            {investIsLoading || divestIsLoading
                ? <Skeleton />
                : <div className={"align-self-end"}>
                    <PercentageInput
                        className="align-self-end"
                        label="Service Fee Percent:"
                        value={displayFeePercent ?? 0}
                        readOnly={ringFenceFeeType !== 0}
                        onChange={(_, { floatValue }) => {
                            setDisplayFeePercent(floatValue ?? 0);
                            realTimePatchInvest([patchReplace("ringFenceFeePercent", Math.min(floatValue ?? 0, 100))]);
                        }}
                        onBlur={(value) => {
                            const newPercent = Math.min(value ?? 0, 100);

                            setDisplayFeePercent(newPercent);
                            return patchInvest([patchReplace("ringFenceFeePercent", newPercent)]);
                        }}
                        disableAnimations={invest?.ringFenceFeePercent === 100 && realTimeInstruction?.invest?.ringFenceFeePercent >= 100}
                    />
                </div>}

            {investIsLoading || divestIsLoading
                ? <Skeleton />
                : <CurrencyInput
                    className={"align-self-end"}
                    label={"Service Fee Amount:"}
                    allowNegative={false}
                    value={realTimeInstruction?.invest?.ringFenceFeeAmount ?? 0}
                    readOnly={ringFenceFeeType !== 1}
                    onChange={(_, { floatValue }) => {
                        realTimePatchInvest([patchReplace("ringFenceFeeAmount", floatValue)])
                    }}
                    onBlur={(value) => patchInvest([patchReplace("ringFenceFeeAmount", value)])}
                />}

        </GridWrapper>
        : <></>;

}

export default RingFence;
import React, { useMemo } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Spinner } from "../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import { ViewPdfModal } from "../../components/modals";
import { ClientLayoutPageSubHeader, Main } from "../../components/utilities";
import ClientFactFind from "../../features/clientfactfind";
import useArchiveFactFind from "../../features/clientfactfind/hooks/useArchiveFactFind";
import useFactFindPdf from "../../features/clientfactfind/hooks/useFactFindPdf";
import useVerifyFactFind from "../../features/clientfactfind/hooks/useVerifyFactFind";

const ClientFactFindPage = () => {

    const {
        canArchive,
        archiveFactFind,
        isFetching: isFetchingArchiveStatus,
        isArchiving,
        error: archiveError,
        archived,
        archivedInfo,
        refetch: refetchArchiveStatus,
    } = useArchiveFactFind();
    const {
        canVerify,
        verifyFactFind,
        isFetching: isFetchingVerificationStatus,
        isVerifying,
        error: verifyError,
        verified,
        verifiedInfo,
        refetch: refetchVerificationStatus,
    } = useVerifyFactFind();
    const {
        showPdfModal,
        pdfData,
        isPreview,
        isFetchingPdf,
        pdfError,
        showPdf,
        hidePdf,
        refetchPdf
    } = useFactFindPdf();

    const disableVerify = useMemo(() => !canVerify || isFetchingVerificationStatus || isVerifying || (verifyError?.allowRetry === false),
        [canVerify, isFetchingVerificationStatus, isVerifying, verifyError?.allowRetry]);

    return <React.Fragment>
        <ViewPdfModal
            title={`Fact Find ${isPreview ? "Preview" : "Archive"}`}
            data={pdfData}
            show={showPdfModal}
            handleClose={hidePdf}
            isLoading={isFetchingPdf}
            error={pdfError}
            refetch={refetchPdf}
            backdrop
        />
        <ClientLayoutPageSubHeader>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    {archiveError?.isFetchingError
                        ? <ErrorMessageWrapper>
                            <ErrorMessage error={archiveError} retryCallback={refetchArchiveStatus} />
                        </ErrorMessageWrapper>
                        : isFetchingArchiveStatus
                            ? <Spinner />
                            : <ButtonGroup>
                                {archived && <Button onClick={showPdf} disabled={isArchiving} variant="info">
                                    <p className="m-0">
                                        View Archive
                                        <br />
                                        {isArchiving || isFetchingArchiveStatus
                                            ? <Spinner />
                                            : <span className="fs-smallest">
                                                {archivedInfo}
                                            </span>}
                                    </p>
                                </Button>}
                                <Button onClick={() => showPdf(true)} variant="success">
                                    Print Snapshot
                                </Button>
                            </ButtonGroup>
                    }
                </div>

                <div>
                    <ButtonGroup>
                        {verifyError != null
                            ? <ErrorMessageWrapper className="me-3">
                                <ErrorMessage imageSrc={null} error={verifyError} retryCallback={verifyError?.allowRetry
                                    ? verifyError?.isFetchingError
                                        ? refetchVerificationStatus
                                        : verifyFactFind
                                    : null} />
                            </ErrorMessageWrapper>
                            : canVerify
                                ? <Button onClick={verifyFactFind} disabled={disableVerify} variant="success">
                                    {isFetchingVerificationStatus || isVerifying
                                        ? <Spinner />
                                        : <p className="m-0">
                                            {verified ? "Verified" : "Verify"}
                                            <br />
                                            <span className="fs-smallest">
                                                {verifiedInfo}
                                            </span>
                                        </p>}
                                </Button>
                                : <span className="me-2 my-auto">
                                    {verifiedInfo}
                                </span>}
                        {(archiveError != null && !archiveError.isFetchingError)
                            ? <ErrorMessageWrapper>
                                <ErrorMessage error={archiveError} retryCallback={archiveError?.allowRetry ? archiveFactFind : null} />
                            </ErrorMessageWrapper>
                            : canArchive && <Button onClick={archiveFactFind} disabled={isArchiving} variant="primary">
                                {isArchiving ? <Spinner /> : "Archive"}
                            </Button>}
                    </ButtonGroup>
                </div>
            </div>
        </ClientLayoutPageSubHeader>
        <Main className="p-3">
            <ClientFactFind />
        </Main>
    </React.Fragment>;
}

export default ClientFactFindPage;
import { useEffect, useState } from "react";
import { useFilterContext } from "../../../hooks/FilterContext";
import { Button, Modal } from "react-bootstrap";
import _ from "lodash";
import { DateInput, FormInput, FormTextArea } from "../../../components/forms";

const CorporateActionPairsGridFilterModal = ({ show, onHide }) => {

    const { filter, setFilter, clearFilter, defaultFilters } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const {
        description,
        sedolIn,
        sedolOut,
        startDate,
        endDate
    } = tempFilters;

    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setFilter(defaultFilters);
    }

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        onHide();
    };

    useEffect(() => {
        if (!_.isEqual(tempFilters, filter)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    return (
        <Modal backdrop="static" centered size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Corporate Action Pairs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-2">
                    <div className="col">
                        <FormInput
                            label="SEDOL Out"
                            value={sedolOut ?? ""}
                            onBlur={(value) => onFilterValueChanged("sedolOut", value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="SEDOL In"
                            value={sedolIn ?? ""}
                            onBlur={(value) => onFilterValueChanged("sedolIn", value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col-6 d-flex align-items-end">
                        <DateInput
                            label="Action Date"
                            value={startDate}
                            onBlur={(value) => onFilterValueChanged("startDate", value)}
                            isClearable
                            disableAnimations
                        />
                        <span className={"mx-3"}>to</span>
                        <DateInput
                            value={endDate}
                            onBlur={(value) => onFilterValueChanged("endDate", value)}
                            isClearable
                            disableAnimations
                        />
                    </div>
                </div>
                <div className="row">
                    <FormTextArea
                        value={description ?? ""}
                        label="Description"
                        minRows={3}
                        onBlur={(value) => onFilterValueChanged("description", value)}
                        disableAnimations
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
                <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );

};

export default CorporateActionPairsGridFilterModal;
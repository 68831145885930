import { useFetchClientReviewsQuery } from "../../../services/clientreviews";

const useClientReviewList = (masterAccountId) => {
    const { data, ...rest } = useFetchClientReviewsQuery({ masterAccountId: masterAccountId, page: 1, limit: 10});
    const { pagination, results } = data || { pagination: null, results: [] };

    return {
        data: results,
        pagination,
        ...rest
    };
}

export default useClientReviewList;
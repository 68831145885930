import { useDeleteMasterAccountDashboardSessionMutation } from "../../../services/clientauth";

const useClearSession = () => {
    const [_delete, { isLoading, ...deleteResult }] = useDeleteMasterAccountDashboardSessionMutation();

    const deleteSession = (masterAccountId, loginId, sessionId) => {
        return new Promise((resolve, reject) => {
            return _delete({ masterAccountId, loginId, sessionId })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { deleteSession, isLoading, data: { isLoading, ...deleteResult } };
};

export default useClearSession;
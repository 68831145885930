import { useFetchUserClientActivityTrackerQuery } from "../../../services/tracking";

const useFetchActiveUsers = (masterAccountId) => {
    const { data, error, isError, isFetching, isLoading, isUninitialized, refetch } = useFetchUserClientActivityTrackerQuery({ masterAccountId }, {
        pollingInterval: 60000
    });

    const reload = (e) => {
        if (e) {
            e.preventDefault();
        }

        refetch({ masterAccountId });
    };

    return {
        data: data || [],
        error,
        isError,
        isFetching,
        isLoading,
        isUninitialized,
        reload
    };
};

export default useFetchActiveUsers;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const raiseFeeEndpoints = wealthProApi
    .injectEndpoints({
        endpoints: (builder) => ({
            createStandaloneFee: builder.mutation({
                query: ({ instructionId }) => ({
                    url: `api/recommendations/StandaloneFee/${instructionId}`,
                    method: "POST",
                }),
                async onQueryStarted({ instructionId }, { dispatch, queryFulfilled }) {
                    try {
                        const createResult = dispatch(wealthProApi.util.updateQueryData('fetchStandaloneFees', { instructionId }, draft => [
                            ...draft,
                            { instructionId }
                        ]));

                        try {
                            const { data: newArray } = await queryFulfilled;
                            dispatch(wealthProApi.util.updateQueryData('fetchStandaloneFees', { instructionId }, () => newArray));
                        } catch (err) {
                            console.error("Error creating Fee in service", err);
                            createResult.undo();
                        }

                    } catch (err) {
                        console.error("Error creating Fee in cache", err);
                    }
                }
            }),
            patchStandaloneFee: builder.mutation({
                query: ({ feeId, operations }) => ({
                    url: `api/recommendations/StandaloneFee/${feeId}`,
                    method: "PATCH",
                    body: operations
                }),
                async onQueryStarted({ feeId, instructionId, operations }, { dispatch, queryFulfilled }) {
                    try {
                        const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchStandaloneFees", { instructionId }, (draft) => {
                            const standaloneFee = draft.find(f => f.feeId === feeId);
                            applyPatch(standaloneFee, operations);
                        }));

                        try {
                            await queryFulfilled;
                        } catch (ex) {
                            console.error(ex, "Error updating service");
                            patchResult.undo();
                        }
                    } catch (ex) {
                        console.error(ex, "Error updating cache");
                    }
                }
            }),
            deleteStandaloneFee: builder.mutation({
                query: ({ feeId }) => ({
                    url: `api/recommendations/StandaloneFee/${feeId}`,
                    method: "DELETE"
                }),
                async onQueryStarted({ feeId, instructionId }, { dispatch, queryFulfilled }) {
                    try {
                        const deleteResult = dispatch(wealthProApi.util.updateQueryData("fetchStandaloneFees", { instructionId }, (draft) =>
                            draft?.filter(f => f.feeId !== feeId) ?? []
                        ));

                        try {
                            await queryFulfilled;
                        } catch (ex) {
                            console.error(ex, "Error deleting in service");
                            deleteResult.undo();
                        }
                    } catch (ex) {
                        console.error(ex, "Error deleting in cache");
                    }
                }
            }),
        })
    });

export const {
    useCreateStandaloneFeeMutation,
    usePatchStandaloneFeeMutation,
    useDeleteStandaloneFeeMutation
} = raiseFeeEndpoints
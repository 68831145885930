import classNames from "classnames";
import { forwardRef, useId, useState } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownWrapper from "./components/DropdownWrapper";
import SearchMenu from "./components/SearchMenu";
import SearchToggle from "./components/SearchToggle";
import { BusinessSheetSearchProvider } from "./hooks/BusinessSheetSearchContext";

const BusinessSheetSearch = forwardRef(({ disabled, ...props }, ref) => {
    const id = useId();

    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <BusinessSheetSearchProvider {...props} showDropdown={showDropdown} setShowDropdown={setShowDropdown}>
            <DropdownWrapper
                ref={ref}
                id={id + "-dropdown"}
                show={showDropdown}
                onToggle={show => setShowDropdown(show)}
                autoClose={true}
            >
                <Dropdown.Toggle
                    className={classNames({ "is-invalid": props.formError })}
                    id={id + "-dropdownInput"}
                    as={SearchToggle}
                    disabled={disabled}
                />
                <Dropdown.Menu as={SearchMenu} show={showDropdown} hide={() => setShowDropdown(false)} />
            </DropdownWrapper>
        </BusinessSheetSearchProvider>
    );
});

export default BusinessSheetSearch;

import { Button } from "react-bootstrap";
import { Main, PageSubHeader } from "../../components/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WrappersManagementProvider, { useWrappersManagement } from "./hooks/WrappersManagementContext";
import WrappersTable from "./components/WrappersTable";
import { useEffect } from "react";
import toast from "react-hot-toast";

const WrappersManagementHeader = () => {

    const { createWrapper, createWrapperResult: { isLoading, isError } } = useWrappersManagement();

    useEffect(() => {
        isError && toast.error("Unable to create wrapper.", { duration: 8000 });
    }, [isError]);

    return (
        <PageSubHeader>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-fill flex-row-reverse">

                    <Button
                        onClick={createWrapper}
                        disabled={isLoading}
                        variant="success"
                        className="d-flex align-items-center"
                    >
                        <FontAwesomeIcon className="me-1" icon="plus" />
                        Create New Wrapper
                    </Button>
                </div>
            </div>
        </PageSubHeader>
    )
};

const WrappersManagement = () => (
    <WrappersManagementProvider>
        <Main className="d-flex flex-column">
            <WrappersManagementHeader />
            <section className="px-4 my-3 h-100">
                <WrappersTable />
            </section>
        </Main>
    </WrappersManagementProvider>
);

export default WrappersManagement;
import React from "react";
import toast from "react-hot-toast";
import { useLazyGetDocumentOpenUrlQuery } from "../services/clientdocuments";

const useOpenDocument = () => {
    const [getDocumentOpenUrl, results] = useLazyGetDocumentOpenUrlQuery();

    const openDocument = (documentId, fileName = null) => {
        if (!documentId) {
            console.error('Document ID has not been specified.');
        }

        toast.promise(getDocumentOpenUrl({ documentId: documentId }).unwrap(), {
            loading: `Attempting to open ${(fileName !=  null && fileName.length > 0) ? `'${fileName}'` : 'Document'}...`,
            success: (data) => {
                const { url, code, message } = data || { url: 'about_blank' };

                if (code === 200 && url && url.length > 0) {
                    window.open(url);
                }
                
                return code === 200 ? `Success! ✍️` : `${message ?? code === 401 ? 'You do not have permissions to view this file.' : 'An unknown error has occured.'}`;
            },
            error: (err) => <div>{err?.data?.message}</div>,
        });
    };

    return {
        ...results,
        openDocument
    };
};

export default useOpenDocument;
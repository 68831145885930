import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../components";
import { FormCheck, FormInput } from "../../components/forms";
import { GenericAdviserSelect, MasterAccountStatusSelect, MasterAccountTypeSelect } from "../../components/forms/selects";
import { useFilterContext } from "../../hooks/FilterContext";

export const ClientManagementFiltersModal = ({
    handleClose, onFiltersChanged, show, ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter((original) => ({
            ...original,
            ...tempFilters,
        }));
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    useEffect(() => {
        setTempFilters(oldTemp => !_.isEqual(oldTemp, filter)
            ? filter
            : oldTemp
        );
    }, [filter]);

    return <Modal size="xl" centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>Client Management Filters</Modal.Header>
        <Modal.Body {...rest}>
            <ClientManagementFilters
                filter={tempFilters}
                setFilter={onFilterValueChanged} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters}>
                Clear Filters
            </Button>
            <Button variant="success" onClick={onApplyFilters}>
                Apply Filters
            </Button>
            <Button variant="light" onClick={onClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>;
};

export const ClientManagementFilters = ({ filter, setFilter }) => {

    const {
        accountTypes,
        accountStatuses,
        adviserIds,
        reviewAdviserIds,
        hideUnfavourited,
        surname
    } = filter;

    return <>
        <div className="row">
            <div className="col-auto mt-auto">
                <FormCheck
                    groupClassName="ms-3 mt-auto mb-2 me-auto"
                    label="Only show favourited clients"
                    labelFirst
                    isChecked={hideUnfavourited}
                    onChange={(check) => setFilter("hideUnfavourited", check)}
                    disableAnimations
                />
            </div>
            <div className="col">
                <MasterAccountTypeSelect
                    label="Account Type"
                    defaultValue={accountTypes}
                    onChange={(selections) => setFilter("accountTypes", selections.map(v => v.value))}
                    // options={accountTypeOptions}
                    disableAnimations
                    isMulti
                />
            </div>
            <div className="col">
                <MasterAccountStatusSelect
                    label="Account Status"
                    defaultValue={accountStatuses}
                    onChange={(selections) => setFilter("accountStatuses", selections.map(v => v.value))}
                    // options={accountStatusOptions}
                    disableAnimations
                    isMulti
                />
            </div>
        </div>
        <div className="row mt-2">
            <div className="col">
                <FormInput
                    label="Client Surname"
                    value={surname}
                    onChange={(e) => setFilter("surname", e.target.value)}
                    disableAnimations
                />
            </div>
            <div className="col">
                <GenericAdviserSelect
                    label="Adviser"
                    defaultValue={adviserIds}
                    onChange={(selections) => setFilter("adviserIds", selections.map(v => v.value))}
                    disableAnimations
                    isMulti
                />
            </div>
            <div className="col">
                <GenericAdviserSelect
                    label="Review Adviser"
                    defaultValue={reviewAdviserIds}
                    onChange={(selections) => setFilter("reviewAdviserIds", selections.map(v => v.value))}
                    disableAnimations
                    isMulti
                />
            </div>
        </div>
    </>;
};

export default ClientManagementFilters;

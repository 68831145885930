import { createContext, useContext, useEffect, useState } from "react";
import { useLazySearchRemindersQuery, usePatchReminderMutation } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import { patchReplace } from "../../../helpers/patchDoc";

const RemindersGridContext = createContext();

export const useRemindersGrid = () => useContext(RemindersGridContext);

const RemindersGridProvider = ({ children }) => {
    const { filter } = useFilterContext();
    const limit = 100;
    const [isClearing, setIsClearing] = useState(false);
    const [sort, setSort] = useState("ReminderDate+DESC");
    const [sortProperty, sortDirection] = sort.split("+");
    const [search, { data, isError, error, isUninitialized }] = useLazySearchRemindersQuery();
    const { pagination, results } = data || { pagination: { page: 0, pageSize: limit, totalCount: limit }, results: [] };
    const { page, totalCount } = pagination;
    const [patch] = usePatchReminderMutation();

    const clear = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        setIsClearing(true);
        search({
            ...filter,
            page: 1,
            limit,
            sort
        }).finally(() => setIsClearing(false));
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => search({
        ...filter,
        page: page + 1,
        limit,
        sort
    });

    const onClickSort = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = sortDirection === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    const sortableHeaderRenderer = ({ label, property, key }) => {
        return <StandardTableSortableHeader
            key={key}
            onClick={() => onClickSort(property)}
            active={property === sortProperty}
            direction={sortDirection}
        >
            {label}
        </StandardTableSortableHeader>;
    };

    const completeReminder = (reminderId, currentValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace("completedDate", currentValue ? null : new Date())];
            return patch({ reminderId, operations })
                .unwrap()
                .then(resolve, reject);
        });
    };

    useEffect(() => {
        if (isUninitialized) return;

        clear();
    }, [filter, sort]);

    const columns = [
        { label: "Application", property: "CreatedDate", renderer: sortableHeaderRenderer },
        { label: "Message", property: "Message", renderer: sortableHeaderRenderer },
        { label: "Reminder Date", property: "ReminderDate", renderer: sortableHeaderRenderer },
        { label: "Completed Date", property: "CompletedDate", renderer: sortableHeaderRenderer },
        { label: "Completed By", property: "CompletedBy", renderer: sortableHeaderRenderer },
        { label: "Completed?" }
    ];

    return <RemindersGridContext.Provider value={{
        columns,
        error,
        isClearing,
        isError,
        results,
        totalCount,
        completeReminder,
        isItemLoaded,
        loadMoreItems,
        retry: clear
    }}>
        {children}
    </RemindersGridContext.Provider>
};

export default RemindersGridProvider;
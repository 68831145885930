import React, { useEffect, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { Button, IconButton } from "../../../components"
import toast from "react-hot-toast";
import { BasicToast } from "../../../components/toasts";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { CurrencyInput, DateInput, FormInput, FormLabel, FormSelect, PercentageInput } from "../../../components/forms";
import useCreateFeeTransaction from "../hooks/useCreateFeeTransaction";
import { GenericAdviserSelect } from "../../../components/forms/selects";
import { ThemedSpan } from "../../../components/utilities";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useIntroducers from "../hooks/useIntroducers";

const PersonType = {
    Adviser: 0,
    Introducer: 1,
    Client: 2
};

const AttributeToFormSelect = ({ control, index, errors, setValue, masterAccountId, masterAccountName }) => {

    const { data: introducers } = useIntroducers(masterAccountId);
    const output = useWatch({ name: "allocations", control, defaultValue: [] });

    return output[index]?.personType === PersonType.Adviser
        ? <Controller
            name={`allocations.${index}.attributeToId`}
            control={control}
            rules={{ required: "Attribute to is required." }}
            render={({ field: { ref, onChange, value, name, ...rest } }) => {
                return <GenericAdviserSelect
                    defaultValue={value}
                    disableAnimations
                    errorAllowRetry={false}
                    errorMessage={errors?.attributeToId?.message}
                    onChange={(value) => setValue(name, value.value)}
                    {...rest}
                />
            }
            } />
        : output[index]?.personType === PersonType.Introducer
            ? <Controller
                name={`allocations.${index}.attributeToId`}
                control={control}
                rules={{ required: "Attribute to is required." }}
                render={({ field: { ref, onChange, value, name, ...rest } }) => {
                    return <FormSelect
                        options={introducers}
                        defaultValue={value}
                        disableAnimations
                        errorAllowRetry={false}
                        errorMessage={errors?.attributeToId?.message}
                        onChange={(value) => setValue(name, value.value)}
                        {...rest}
                    />
                }}
            />
            : output[index]?.personType === PersonType.Client
                ? <Controller
                    name={`allocations.${index}.attributeToId`}
                    control={control}
                    rules={{ required: "Attribute to is required." }}
                    render={({ field: { ref, onChange, value, name, ...rest } }) => {
                        return <FormInput
                            readOnly
                            value={masterAccountName}
                        />
                    }
                    } />
                : <FormInput
                    readOnly
                />

};

export default AttributeToFormSelect;
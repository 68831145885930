import React from "react";
import styled from "styled-components";
import { readableColor } from "polished";
import { Outlet } from "react-router-dom";
import { PageHeaderTop, PageHeaderBottom } from "../components/layout/PageHeader";
import { SystemSettingsRouteTabs, SystemSettingsUsersRouteTabs } from "../routes/settings.routes";

const Title = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const Header = styled.section`
    grid-area: pageheader;
    z-index: 5;    
    position: relative;
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};

    & h2 {
        font-size: 24px;
    }

    & h2:last-child {
        margin: 0;
    }

    & h3 {
        margin: 0;
        font-size: 20px;
    }
`;

export const SystemSettingsUsersSectionLayout = () => (
    <React.Fragment>
        <SystemSettingsUsersRouteTabs />
        <Outlet />
    </React.Fragment>
);

const SystemSettingsLayout = ({ title = "System Settings" }) => (
    <React.Fragment>
        <Header>
            <PageHeaderTop>
                <Title><span>{title}</span></Title>
            </PageHeaderTop>
            <PageHeaderBottom>
                <SystemSettingsRouteTabs />
            </PageHeaderBottom>
        </Header>
        <Outlet />
    </React.Fragment>
);

export default SystemSettingsLayout;
import React from "react";
import useCreateFeeTransaction from "../hooks/useCreateFeeTransaction";
import TransactionFeeModal from "./TransactionFeeModal";

const CreateTransactionFeeModalWrapper = ({ group, show, handleClose }) => {
    const {
        defaultAllocations,
        isLoadingDefaultAllocations,
        isDefaultAllocationsError,
        defaultAllocationsError,
        feeTransactionDefaultValues,
        feeTransactionAllocationDefaultValues,
        transactionTypes,
        isLoadingTransactionTypes,
        createFeeTransaction,
        retryFetchDefaultAllocations
    } = useCreateFeeTransaction(group);

    return <TransactionFeeModal
        group={group}
        show={show}
        handleClose={handleClose}
        onSubmitFn={createFeeTransaction}
        defaultAllocationsError={defaultAllocationsError}
        isDefaultAllocationsError={isDefaultAllocationsError}
        feeTransactionDefaultValues={feeTransactionDefaultValues}
        feeTransactionAllocationDefaultValues={feeTransactionAllocationDefaultValues}
        transactionTypes={transactionTypes}
        isLoadingTransactionTypes={isLoadingTransactionTypes}
        isLoadingDefaultAllocations={isLoadingDefaultAllocations}
        retryFetchDefaultAllocations={retryFetchDefaultAllocations}
    />;
};

export default CreateTransactionFeeModalWrapper;
import React from "react";
import classNames from "classnames";
import { ThemedSpan } from "../utilities";

const LoadingMessage = ({ className, text = "Please wait while WealthPro gets your results", ...rest }) => {
    return (
        <div className="text-center my-3">
            <ThemedSpan variant="muted" className={classNames('loading-ellipsis', className)} {...rest}>{text}</ThemedSpan>
        </div>
    );
};

export default LoadingMessage;
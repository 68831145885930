import React from "react";
import FormSelect from "../FormSelect";
import { useFetchReportProductAreasQuery } from "../../../services/clientreports";

const ReportProductAreasSelect = ({ options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchReportProductAreasQuery({ fetchType: 'List' });
    const { results } = data || { results: [] }

    return <FormSelect
        options={results}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default ReportProductAreasSelect;
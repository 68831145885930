import React from "react";
import styled from "styled-components";
import Button from "../buttons";
import useNavigationRoutes from "./useNavigationRoutes";
import { lighten } from "polished";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DropdownItemLink = styled(NavLink)`
    &.active {
        color: ${props => props.theme.primary};
        background-color: ${props => lighten(0.55, props.theme.primary)};
    }
`;

const DropdownItemLinkIconWrapper = styled.span`
    margin-right: .5rem;
`;

const DropdownToggleButton = styled(Button)`
    position: relative;
    text-align: left;
    width: 100%;

    &:after {
        position: absolute;
        right: 15px;
        bottom: 45%;
    }
`;

const DropdownItem = ({ children, icon, ...rest }) => (
    <Dropdown.Item as={DropdownItemLink} {...rest}>
        {icon && (
            <DropdownItemLinkIconWrapper>
                <FontAwesomeIcon icon={icon} />
            </DropdownItemLinkIconWrapper>
        )}
        {children}
    </Dropdown.Item>
);

const DropdownToggle = React.forwardRef(({ children, ...props }, ref) => (
    <DropdownToggleButton ref={ref} variant="light" {...props}>{children}</DropdownToggleButton>)
);

const NavigationDropdown = ({ options }) => {
    const { defaultRoute, routes } = useNavigationRoutes(options);
    const [selectedRoute, setSelectedRoute] = React.useState(null);

    React.useEffect(() => {
        setSelectedRoute(routes.find(el => el.path === defaultRoute));
    }, [ defaultRoute ]);

    return (
        <Dropdown>
            <Dropdown.Toggle as={DropdownToggle}>
                {selectedRoute ? selectedRoute.label : 'Please select an option.'}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
                {routes && routes.map((el, i) => (
                    <DropdownItem
                        key={i}
                        to={el.path}
                        icon={el.icon}
                        onClick={() => setSelectedRoute(el)}
                    >
                        {el.label}
                    </DropdownItem>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NavigationDropdown;
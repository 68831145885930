import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../../components/utilities";

const DisplayIcon = styled(FontAwesomeIcon)`
    width: 32px;
    height: 32px;
    color: ${props => props.theme.danger};
`;

const ErrorIcon = ({ error, ...rest }) => {
    return <Tooltip position="left" tooltip={"There was a problem connecting to the room."}>
        <DisplayIcon icon="fa-triangle-exclamation" {...rest} />
    </Tooltip>
};

export default ErrorIcon;
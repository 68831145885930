import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import _ from "lodash";
import styled from "styled-components";

const StyledDropdownMenu = styled(Dropdown.Menu)`
    z-index: 10000;
`

const MissingAccountsGridRowActionColToggle = React.forwardRef(({ children, onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const MissingAccountsGridRowActionCol = ({ canConfirm, confirmFn, setShowEdit }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    return (
        <Dropdown id={menuId} className="caret-off w-100" drop="start">
            <Dropdown.Toggle as={MissingAccountsGridRowActionColToggle} id={toggleId} />
            <StyledDropdownMenu>
                <Dropdown.Item disabled={canConfirm} onClick={confirmFn}>
                    Confirm
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setShowEdit(true)}>
                    Edit
                </Dropdown.Item>
            </StyledDropdownMenu>
        </Dropdown>
    );

};

export default MissingAccountsGridRowActionCol;

const patchAdd = (propName, newValue) => {
    return {
        path: `/${propName}/-`,
        op: 'add',
        value: newValue
    };
};

const patchRemoveFromArray = (propName, newValue) => {
    return {
        path: `/${propName}/-`,
        op: 'remove',
        value: newValue
    };
};

// this function will create a JSON Patch Document object that replaces a field in an entity
// example: { path: "/propName", op: "replace", from: "", value: "newValue" }
const patchReplace = (propName, newValue, previousValue = null) => {
    let patch = {
        path: `/${propName}`,
        op: 'replace',
        value: newValue
    };

    if (previousValue) {
        patch.from = previousValue;
    }

    return patch;
};

const patchRemove = (propName, index) => {
    return {
        path: `/${propName}/${index}`,
        op: 'remove'
    };
}

export {
    patchAdd,
    patchRemoveFromArray,
    patchRemove,
    patchReplace
};
import { useEffect, useState } from "react";
import { useCreateBizSheetMutation } from "../../../services/recommendations";

const useCreateBusinessSheet = (masterAccountId = null, reportId = null) => {
    const [create, createResult] = useCreateBizSheetMutation();
    const [defaultValues, setDefaultValues] = useState({
        masterAccountId,
        reportId,
        borrow: false,
        borrowedSheetId: null,
        borrowedSheetName: null,
    });

    const handleCreate = (data) =>
        create({ data }).unwrap()

    useEffect(() => {
        setDefaultValues((current) => { return { ...current, masterAccountId, reportId } });
    }, [masterAccountId, reportId]);

    return {
        defaultValues,
        handleCreate,
        ...createResult
    }
};

export default useCreateBusinessSheet;
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import { useLazyFetchMasterAccountReportsListQuery } from "../../../services/clientreports";
import FormGroup from "../FormGroup";
import FormInputGroup from "../FormInputGroup";
import FormLabel from "../FormLabel";
import { CalculateClassName, CalculateSelectStyles } from "../FormSelect";
import InputErrorMessage from "../InputErrorMessage";

const ReportDetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 0.875em;
    color: ${(props) => props.theme.muted};

    & > div {
        flex: 1;
    }
`;

const ReportSelectOption = (props) => {
    const { data: { data } } = props;

    if (!data) {
        return (
            <components.Option {...props}>
                <span>{props.label}</span>
            </components.Option>
        )
    }

    const { projectName, reportName, statusDescription, typeDescription } = data;
    return (
        <components.Option {...props}>
            <div>
                <span>{reportName}</span>
                <ReportDetailsRow>
                    <div><span>Type:</span> {typeDescription}</div>
                    <div><span>Status:</span> {statusDescription}</div>
                    <div><span>Project:</span> {projectName}</div>
                </ReportDetailsRow>
            </div>
        </components.Option>
    )
};

export const ReportSelectComponents = {
    Option: ReportSelectOption
};

export const useClientProjectReports = (masterAccountId) => {
    const [cacheKey, setCacheKey] = useState(new Date().valueOf());
    const [selectedValue, setSelectedValue] = useState(null);
    const [pageLimit, setPageLimit] = useState(25);
    const [load, { data, ...queryResults }] = useLazyFetchMasterAccountReportsListQuery();
    const { results, pagination: { page, totalCount, totalPages } } = data || { results: [], pagination: { page: 1, totalCount: 0, totalPages: 0 } }
    const hasMore = page < totalPages;

    const clear = () => {
        if (!masterAccountId) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            searchText: "",
            page: 1,
            pageLimit
        }).unwrap().then(_ => setCacheKey(newCacheKey))
    };

    const nextPage = () => {
        if (page >= totalPages) {
            return new Promise((resolve) => resolve(false));
        }
        return load({
            cacheKey,
            masterAccountId,
            searchText: "",
            page: page + 1,
            pageLimit
        });
    };

    const previousPage = () => {
        if (page <= 1) {
            return new Promise((resolve) => resolve(false));
        }
        return load({
            cacheKey,
            masterAccountId,
            searchText: "",
            page: page - 1,
            pageLimit
        });
    };

    useEffect(clear, [ masterAccountId ]);

    return {
        clear,  
        data: results,
        hasMore,
        masterAccountId,
        nextPage,
        page,
        pageLimit,
        previousPage,
        selectedValue,
        setPageLimit,
        setSelectedValue,
        totalCount,
        totalPages,
        ...queryResults
    };
};

const ReportSelect = React.forwardRef(({ 
    className = "flex-fill",
    components={ ...ReportSelectComponents },
    errorMessage = null,
    horizontal = false,
    id,
    isMulti = false,
    label, 
    masterAccountId, 
    setValue = () => console.warn('set value method has not been assigned.'), 
    value,
    transformOptions = (options) => options,
    ...rest 
}, ref) => {
    const defaultId = useId();
    const componentId = id || defaultId;
    const defaultRef = useRef();
    const componentRef = ref || defaultRef;
    const [error, setError] = useState(null);
    const { clear, data, hasMore, isFetching, isLoading, nextPage } = useClientProjectReports(masterAccountId);

    // NOTE: use the standard FormSelect calculation method instead of copy & paste the code...
    const currentClassName = useMemo(() => CalculateClassName(className, false, error, 'flex-fill'), [ className, error ]);
    const currentStyles = useMemo(() => CalculateSelectStyles(error), [ error ]);

    const getDisplayValue = (selectedValue, data, isMulti) => {
        if (!value || !data || !Array.isArray(data)) {
            return null;
        }

        if (isMulti === true) {
            let selectedValues = Array.isArray(selectedValue) ? selectedValue : [selectedValue];
            return data.filter(({ value }) => selectedValues.includes(value));
        }

        return data.find(({ value }) => value === selectedValue) ?? null;
    }

    const handleOnMenuScrollToBottom = (_) => {
        if (hasMore === false) {
            return;
        }
        nextPage();
    };

    useEffect(() => {
        if (errorMessage) {
            setError(_ => errorMessage);
        }
        else {
            setError(_ => null);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (!value) {
            return;
        }

        var existingValue = data.find(({ value: v }) => v === value);
        if (!existingValue) {
            clear();
        }
    }, [value]);

    const displayValue = useMemo(() => getDisplayValue(value, data, isMulti), [ value, data, isMulti ]);
    return <FormGroup className={className} horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup className={"has-error"} horizontal={horizontal} hasLabel={label ? true : false}>
            <Select
                id={componentId}
                ref={componentRef}
                components={components}
                styles={currentStyles}
                className={currentClassName}
                value={displayValue}
                options={transformOptions(data)}
                isLoading={isLoading || isFetching}
                onChange={setValue}
                onMenuScrollToBottom={handleOnMenuScrollToBottom}
                {...rest}
            />
            <InputErrorMessage error={error} allowRetry={false} />
        </FormInputGroup>
    </FormGroup>
});

export default ReportSelect;
import { useState } from "react"
import { useDispatch } from "react-redux";
import { wealthProApi } from "../../redux/api";
import * as jsonpatch from "fast-json-patch";
import { Form } from "react-bootstrap";
import { patchReplace } from "../../helpers/patchDoc";
import styled from "styled-components";


const LinkButton = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
`;

const StyledError = styled(Form.Text)`
 color: #F00;
`;

const InputWrapper = ({
  className,
  property,
  onChangeEventToPatchDoc,
  patchCache,
  patchService,
  InputComponent,
  inputClassName,
  ...inputProps
}) => {
  const [appliedOperations, setAppliedOperations] = useState([]);

  const [showRepatch, setShowRepatch] = useState(false);

  const handleOnChange = (event) => {
    const patchDoc = typeof onChangeEventToPatchDoc === 'function' ? onChangeEventToPatchDoc(event) : [patchReplace(property, event.target.value)];

    if (patchDoc?.length <= 0)
      return;

    patchCache(patchDoc);

    setAppliedOperations(oldList => [...oldList, ...patchDoc]);
  }

  const handleOnBlur = () => {
    if (appliedOperations?.length <= 0)
      return;

    setShowRepatch(false);
    patchService(appliedOperations)
      .then((res) => {
        setAppliedOperations([]);
      }, (err) => {
        console.error("Error patching", err);
        setShowRepatch(true)
      });
  }

  return <Form.Group className={className}>
    <InputComponent
      className={inputClassName}
      {...inputProps}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
    {showRepatch && <StyledError>
      Error saving, click <LinkButton onClick={handleOnBlur}>here</LinkButton> to retry.
    </StyledError>}
  </Form.Group>
}

export default InputWrapper
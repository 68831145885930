import React from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEditSla } from "../hooks/EditSlaContext";

const SlaAdminTableRowActionColumnParent = styled.td``;

const SlaAdminTableRowActionColumnToggle = React.forwardRef(({ ...rest }, ref) => {
    return (
        <div ref={ref} {...rest}>
            <FontAwesomeIcon icon="fa-ellipsis" className="has-pointer" />
        </div>
    );
});

const SlaAdminTableRowActionColumn = ({ serviceLevelAgreement }) => {

    const menuId = React.useId();
    const { setServiceLevelAgreement } = useEditSla();

    const onHandleClickShowEditModal = () => {
        setServiceLevelAgreement(serviceLevelAgreement);
    };

    return (
        <SlaAdminTableRowActionColumnParent>
            <Dropdown className="caret-off">
                <Dropdown.Toggle id={menuId} as={SlaAdminTableRowActionColumnToggle} />
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onHandleClickShowEditModal}>
                        <FontAwesomeIcon icon="fa fa-pencil" className="has-pointer" size="sm" /> <span className="ms-1">Edit</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </SlaAdminTableRowActionColumnParent>
    );
};

export default SlaAdminTableRowActionColumn;
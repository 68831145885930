import styled from "styled-components";
import { lighten } from "polished";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NavItemIconWrapper = styled.span`
    display: inline-block;
    width: 18px;
    text-align: center;
    margin-right: .5rem;
`;

export const NavigationItemIcon = (props) => {
    return <NavItemIconWrapper>
        <FontAwesomeIcon {...props} />
    </NavItemIconWrapper>
};

export const NavigationItem = styled(NavLink)`
    text-decoration: none;
    margin: .125rem .2rem;
    padding: .6rem;
    color: ${props => props.theme.primary};
    border-radius: .25rem;

    &:hover,
    &.active {
        color: ${props => props.theme.primary};
        background-color: ${props => lighten(0.55, props.theme.primary)};
    }

    &:not(:last-child) {
        margin-bottom: 0.25rem;
    }
`;

export default NavigationItem;
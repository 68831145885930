import React, { useState } from "react";
import styled from "styled-components";
import { FormInput } from "../../components/forms";
import { Main, PageHeader, PageSubHeader, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import { ClientManagementGrid } from "./components";
import { Button } from "../../components";
import { useFilterContext } from "../../hooks/FilterContext";
import { ClientManagementFiltersModal } from "../clientmanagementfilters";

const ClientManagementHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const ClientManagementPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 0.75rem;
`;

const ClientManagementFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const ClientManagement = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isFiltersShown, setIsFiltersShown] = useState(false);

    const {
        clearFilter,
        isFiltersApplied,
    } = useFilterContext();

    return (
        <React.Fragment>
            <ClientManagementFiltersModal
                handleClose={() => setIsFiltersShown(false)}
                show={isFiltersShown}
            />
            <PageHeader>
                <ClientManagementHeaderTitle>
                    <span>Client Management</span>
                </ClientManagementHeaderTitle>
            </PageHeader>
            <Main className="d-flex flex-column">
                <ClientManagementPageSubHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            {isFiltersApplied && (
                                <React.Fragment>
                                    <ThemedIcon className="my-auto" icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                    <ClientManagementFiltersAppliedWarningMessage className="ms-2 text-muted">
                                        Warning - Filters are currently being applied to your results.
                                        <br />
                                        <button className="anchor" onClick={clearFilter}>Click here</button> to clear your filters.
                                    </ClientManagementFiltersAppliedWarningMessage>
                                </React.Fragment>
                            )}
                        </div>
                        <FormInput
                            className="me-2 w-75"
                            parentClassName="ms-auto w-50"
                            label={null}
                            value={searchTerm}
                            placeholder="Search for a client"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            disableAnimations
                            errorMessage={searchTerm.length >= 1 && searchTerm.length < 3 ? "Please enter at least 3 characters to search." : null}
                            errorAllowRetry={false}
                        />
                        <Button className="mb-auto" variant="primary" onClick={() => setIsFiltersShown(true)}>
                            Filter Clients
                        </Button>
                    </div>
                </ClientManagementPageSubHeader>
                <section className="px-4 my-3 h-100">
                    <ClientManagementGrid searchTerm={searchTerm} />
                </section>
            </Main>
        </React.Fragment>
    );
};

export default ClientManagement;

import { wealthProApi } from '../../redux/api';

const hoPoductsApiEndpoints = wealthProApi.enhanceEndpoints({})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchHOAdminProducts: builder.query({
            query: (listType) => `api/headofficeadmin/Product`
        })
    })
});

export const {
    useFetchHOAdminProductsQuery,
} = hoPoductsApiEndpoints;
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { Controller } from "react-hook-form";
import React, { useCallback, useState } from "react";
import { FormInput, FormLabel, FormSelect, RichTextEditor } from "../../../components/forms";
import { EmailContactsPagedSelect, GenericUserSelect } from "../../../components/forms/selects";
import { useEffect } from "react";
import MultiValueLabel, { EditEmailContactModal } from "./MultiValueLabel";
import { useDropzone } from "react-dropzone";
import UserDelegatesSelect from "../../../components/forms/selects/UserDelegatesSelect";
import { DelegatedAccessTypes } from "../../../helpers/constants";
import FileDisplay from "./FileDisplay";

export const uploadProps = {
    maxFiles: 5,
    accept: {
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
        "text/csv": [],
        "image/gif": [],
        "image/jpeg": [],
        "image/png": [],
        "application/pdf": [],
        "application/vnd.ms-excel": [],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
        "application/xml": [],
        "text/plain": [],
        "video/mp4": [],
        "video/mpeg": [],
        "video/x-msvideo": [],
        "application/vnd.ms-outlook": [],
        "message/rfc822": []
    }
};

const DraftMailForm = ({
    control,
    data,
    errors,
    setValue,
    watch,
    isForward = false,
    isReply = false,
    addAttachmentsFn,
    removeAttachmentFn
}) => {

    const [editContactModalProps, setEditContactModalProps] = useState(null);
    const attachments = watch("attachments");
    const from = watch("from");
    const [filesDisplayInfo, setFilesDisplayInfo] = useState(attachments); // for display purposes only
    const [filesToAdd, setFilesToAdd] = useState([]); // for adding new files

    const {
        fetchEmailContacts,
        emailContacts: { data: emailContacts, isLoading: isLoadingEmailContacts },
    } = usePersonalOutlookContext();

    const onMultiValueLabelClick = ({ data }, e) => {
        setEditContactModalProps(data);
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (addAttachmentsFn && typeof (addAttachmentsFn) === "function") {
            // for adding files via API request, so no need to store on client
            addAttachmentsFn(acceptedFiles);
            return;
        }

        // for client side files
        setFilesToAdd(prev => [...prev, ...acceptedFiles]);
    }, []);

    const onRemoveAttachment = (attachment, e) => {

        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        if (removeAttachmentFn && typeof (removeAttachmentFn) === "function") {
            removeAttachmentFn(attachment);
        }

        setFilesToAdd(prev => prev.filter(x => {
            // TODO - potentially remove
            if (x.id && attachment.id) {
                return x.id !== attachment.id;
            }

            return x !== attachment;
        }));
    };

    useEffect(() => {
        fetchEmailContacts({});
    }, []);

    // setup the file dropzone for uploading & checking file validity
    const { getRootProps, getInputProps } = useDropzone({ ...uploadProps, onDrop });

    useEffect(() => {
        setValue("attachments", filesToAdd);
    }, [filesToAdd]);

    useEffect(() => {
        setFilesDisplayInfo(attachments);
    }, [attachments]);

    return <React.Fragment>
        {!isForward && <div className="row d-flex align-items-center mb-2">
            <div className="col-2 col-lg-1">
                <FormLabel>From</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="from"
                    control={control}
                    rules={{ required: "Sender is required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <UserDelegatesSelect
                        accessType={DelegatedAccessTypes.writeMail}
                        defaultValue={value?.value ?? value}
                        errorAllowRetry={false}
                        errorMessage={errors?.from?.message}
                        onChange={(value) => setValue('from', value)}
                        {...rest}
                    />
                    }
                />
            </div>
        </div>}
        <div className="row d-flex align-items-center mb-2">
            <div className="col-2 col-lg-1">
                <FormLabel>To</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="toRecipients"
                    control={control}
                    rules={{ required: "To recipients are required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <EmailContactsPagedSelect
                        key={from?.value ?? from}
                        userId={from?.value ?? from}
                        value={value}
                        errorAllowRetry={false}
                        isMulti={true}
                        errorMessage={errors?.toRecipients?.message}
                        setValue={(value) => setValue('toRecipients', value)}
                        components={{
                            MultiValueLabel: (props) => (
                                <MultiValueLabel
                                    onClick={onMultiValueLabelClick}
                                    {...props}
                                />
                            )
                        }}
                        {...rest}
                    />
                    }
                />
            </div>
        </div>
        {!isForward && <div className="row d-flex align-items-center mb-2">
            <div className="col-2 col-lg-1">
                <FormLabel>Cc</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="ccRecipients"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <EmailContactsPagedSelect
                    key={from?.value ?? from}
                    userId={from?.value ?? from}
                    value={value}
                    errorAllowRetry={false}
                    isMulti={true}
                    errorMessage={errors?.ccRecipients?.message}
                    setValue={(value) => setValue('ccRecipients', value)}
                    components={{
                        MultiValueLabel: (props) => (
                            <MultiValueLabel
                                onClick={onMultiValueLabelClick}
                                {...props}
                            />
                        )
                    }}
                    {...rest}
                />
                    }
                />
            </div>
        </div>}
        {!isForward && !isReply && <div className="row d-flex align-items-center mb-2">
            <div className="col-2 col-lg-1">
                <FormLabel>Subject</FormLabel>
            </div>
            <div className="col">
                <Controller
                    name="subject"
                    control={control}
                    rules={{ required: "Subject is required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <FormInput
                        value={value}
                        errorAllowRetry={false}
                        errorMessage={errors?.subject?.message}
                        onBlur={(value) => setValue('subject', value)}
                        {...rest}
                    />
                    }
                />
            </div>
        </div>}
        {!isForward && <Controller
            name="attachments"
            control={control}
            rules={{ required: false }}
            render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <div {...getRootProps({ className: 'dropzone d-flex mb-2 border border-3 border-opacity-10' })}>
                <input {...getInputProps()} multiple />
                <div className="d-flex flex-fill justify-content-center align-items-center py-1">
                    <p className="m-0">Drag and drop attachments here or click to browse for files.</p>
                </div>
            </div>}
        />}

        <div className="row d-flex align-items-center mb-2">
            <div className="col">
                <Controller
                    name="body"
                    control={control}
                    rules={{ required: "Body is required." }}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <RichTextEditor
                        maxHeight={"300px"}
                        height={"300px"}
                        defaultValue={value}
                        errorAllowRetry={false}
                        disableAnimations
                        errorMessage={errors?.body?.message}
                        onBlur={(value) => setValue('body', value)}
                        {...rest}
                    />
                    }
                />
            </div>
        </div>
        {!isForward && <FileDisplay files={filesDisplayInfo} onDeleteAttachment={onRemoveAttachment} />}
        <EditEmailContactModal
            show={editContactModalProps !== null}
            data={editContactModalProps}
            onClose={() => setEditContactModalProps(null)}
        />
    </React.Fragment>;
};

export default DraftMailForm;
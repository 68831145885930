import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { wealthProApi } from "../redux/api";
import { ErrorMessage, ErrorMessageWrapper } from "../components/messages";
import { CenterPage, Main } from "../components/utilities";
import { Spinner } from "../components/loaders";

const HeadOfficeApplicationContext = React.createContext();

export const useHeadOfficeApplicationContext = () => {
    return useContext(HeadOfficeApplicationContext);
};

export const HeadOfficeApplicationProvider = ({ children }) => {
    const { applicationId } = useParams();
    const [trigger, result] = wealthProApi.endpoints.getApplicationHeaderInfoById.useLazyQuery();
    const { data, error, isError, isFetching, isLoading, isUninitialized } = result || {};

    const reload = (e) => {
        if (e) {
            e.preventDefault();
        }

        trigger({ applicationId });

    };

    useEffect(() => {
        if (applicationId) {
            trigger({ applicationId });
        }
    }, [applicationId]);

    return <HeadOfficeApplicationContext.Provider value={{
        applicationId,
        application: data,
        error,
        isError,
        isFetching,
        isLoading,
        isUninitialized,
        reload
    }}>
        {isError && <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={reload} />
        </ErrorMessageWrapper>}
        {isLoading && <Main><CenterPage><Spinner/></CenterPage></Main>}
        {data && !isError && !isLoading && children}
    </HeadOfficeApplicationContext.Provider>
};
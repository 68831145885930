import _ from "lodash";
import React from "react";
import { useAuth } from "react-oidc-context";
import { toNString } from "../helpers/guids";

const ClientReportControlsContext = React.createContext();

export const allowAssignAuthorRoles = ["report_assign_author"];

export const allowChangeReportCategoryRoles = ["report_doc_management"];

export const allowChangeReportFeedbackRoles = ["report_doc_management"];

export const allowChangeReportTypeRoles = ["report_doc_management"];

export const useClientReportControls = () => React.useContext(ClientReportControlsContext);

export const ClientReportControlsProvider = ({ children }) => {
    const auth = useAuth();
    const { user: { profile: { roles, sub } } } = auth;
    const [targetReport, setTargetReport] = React.useState(null);
    const { adviserId, authorId, complianceUserId, isStatusCompliant, isStatusTerminated } = targetReport || { isStatusCompliant: false, isStatusTerminated: false };
    const isReportEditable = isStatusCompliant === false && isStatusTerminated === false;

    const isAllowedToAssignAuthor = isReportEditable === true && _.some(roles, i => allowAssignAuthorRoles.includes(i));
    const isAllowedToChangeReportCategories = isReportEditable === true && _.some(roles, i => allowChangeReportCategoryRoles.includes(i));
    const isAllowedToChangeReportType = isReportEditable === true && _.some(roles, i => allowChangeReportTypeRoles.includes(i));
    
    const isAllowedToChangeReportFeedback = isReportEditable === true && (
        complianceUserId === toNString(sub) ||
        _.some(roles, i => allowChangeReportFeedbackRoles.includes(i))
    );
    const isAllowedToChangeReportFeedbackResponse = isReportEditable === true && (
        adviserId === toNString(sub) ||
        authorId === toNString(sub)
    );

    return (
        <ClientReportControlsContext.Provider value={{
            isAllowedToAssignAuthor,
            isAllowedToChangeReportCategories,
            isAllowedToChangeReportFeedback,
            isAllowedToChangeReportType,
            isAllowedToChangeReportFeedbackResponse,
            isReportEditable,
            targetReport,
            setTargetReport
        }}>
            {children}
        </ClientReportControlsContext.Provider>
    );
};
import {
    useFetchClientPersonsQuery
} from "../../../services/clientserviceterms";

const useCLientPersons = (masterAccountId) => {

    const { data, error, isError, isFetching, isLoading, refetch } = useFetchClientPersonsQuery({ masterAccountId });

    return {
        clientPersons: data || []
    }
}

export default useCLientPersons;
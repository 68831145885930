import React from "react";
import withProviders from "../../hooks/withProviders";
import styled from "styled-components";
import ReportFeedbackList from "./components/ReportFeedbackList";
import ReportMessagesList from "./components/ReportMessagesList";
import ReportStatusHistory from "./components/ReportStatusHistory";
import { readableColor } from "polished";
import { Modal } from "react-bootstrap";
import { Button } from "../../components";
import { ReportReadonlyMessage } from "../../components/messages";
import { Tabs, TabScreen } from "react-tabs-scrollable";
import { useReportHistory } from "./hooks/ReportHistoryContext";
import { ClientReportControlsProvider, useClientReportControls } from "../../hooks/ClientReportControlsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledNavigationTab, StyledNavigationTabIconContainer } from "../../components/navigation/NavigationTabs";

const ModalBody = styled(Modal.Body)`
    min-height: 66.67vh;
    max-height: 66.67vh;
    display: flex;
    flex-direction: column;
    padding: 0;
`;

const NavigationContainer = styled.div`
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

const NavigationTab = React.forwardRef(({ icon, label, ...rest }, ref) => (
    <StyledNavigationTab ref={ref} {...rest}>
        <StyledNavigationTabIconContainer>
            <FontAwesomeIcon icon={icon} />
        </StyledNavigationTabIconContainer>
        <span>{label}</span>
    </StyledNavigationTab>
));

const ClientReportsHistoryModal = ({ show, handleClose = () => {} }) => {
    const [activeTab, setActiveTab] = React.useState(0);
    const { report } = useReportHistory();
    const { setTargetReport, isReportEditable } = useClientReportControls();
    const { reportName } = report || { reportName: '' };

    const onTabClick = (e, i) => {
        e.preventDefault();
        setActiveTab(i);
    };

    React.useEffect(() => {
        setTargetReport(report);
    }, [ report ]);

    if (!report) {
        return null;
    }

    return (
        <Modal size="xl" centered backdrop="static" show={show} onHide={handleClose} className="overflow-visible">
            <Modal.Header closeButton>
                <Modal.Title>Report History: {reportName}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <NavigationContainer>
                    <Tabs 
                        activeTab={activeTab} 
                        onTabClick={onTabClick}
                        leftBtnIcon={<FontAwesomeIcon icon="fa-chevron-left" />}
                        rightBtnIcon={<FontAwesomeIcon icon="fa-chevron-right" />}
                    >
                        <NavigationTab icon="fa-chart-pie" label="Summary" />
                        <NavigationTab icon="fa-comment-dots" label="Feedback" />
                        <NavigationTab icon="fa-comment-alt" label="Messages & Notifications" />
                    </Tabs>
                </NavigationContainer>
                <TabScreen activeTab={activeTab} index={0} className="overflow-auto">
                    {!isReportEditable && <ReportReadonlyMessage />}
                    <ReportStatusHistory />
                </TabScreen>
                <TabScreen activeTab={activeTab} index={1} className="overflow-auto">
                    {!isReportEditable && <ReportReadonlyMessage />}
                    <div className="pt-3">
                        <ReportFeedbackList />
                    </div>
                </TabScreen>
                <TabScreen activeTab={activeTab} index={2} className="px-2 py-3" style={{ flex: '1 1 auto' }}>
                    <ReportMessagesList />
                </TabScreen>
            </ModalBody>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withProviders(ClientReportControlsProvider)(ClientReportsHistoryModal);
import { useFetchReportProductAreasQuery } from "../../../services/clientreports";

const useReportProductAreas = () => {
    const { data, error, isError, isFetching, isLoading, refetch } = useFetchReportProductAreasQuery({ });
    const { results } = data || { results: [] };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ });
    };

    return {
        data: results,
        error,
        isError,
        isLoading: isFetching || isLoading,
        reload
    };
};

export default useReportProductAreas;
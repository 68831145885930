import fetchReportCacheSocketHandler from "./sockets/fetchReportCacheSocketHandler";
import { wealthProApi } from '../../redux/api';
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

const buildFetchProjectReportsQuery = ({masterAccountId, projectId, page, limit, sort, searchTerm}, list = false) => {
    let baseQuery = list === false
        ? `api/reports/master-accounts/${masterAccountId}/projects/${projectId}/reports?page=${page}&limit=${limit}`
        : `api/reports/master-accounts/${masterAccountId}/projects/${projectId}/reports/list?page=${page}&limit=${limit}`;

    if (sort && sort.length > 0) {
        baseQuery += `&sort=${sort}`;
    }

    if (searchTerm && searchTerm.length > 0) {
        var searchTermEncode = encodeURIComponent(searchTerm);
        baseQuery += `&searchTerm=${searchTermEncode}`;
    }

    return baseQuery;
};

const masterAccountProjectReportEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["clientProjectReports"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchProjectReports: builder.query({
            query: (props) => buildFetchProjectReportsQuery(props),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            onCacheEntryAdded: fetchReportCacheSocketHandler(true)
        }),
        searchMasterAccountProjectReports: builder.query({
            query: ({ projectId, ...data }) => ({
                url: `/api/reports/masteraccountprojects/${projectId}/reports`,
                method: 'POST',
                body: data
            })
        }),
        searchMasterAccountProjectReportsList: builder.query({
            query: ({ projectId, ...data }) => ({
                url: `/api/reports/masteraccountprojects/${projectId}/reports/list`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useFetchProjectReportsQuery,
    useLazyFetchProjectReportsQuery,
    useLazySearchMasterAccountProjectReportsQuery,
    useLazySearchMasterAccountProjectReportsListQuery,
    useSearchMasterAccountProjectReportsQuery,
    useSearchMasterAccountProjectReportsListQuery
} = masterAccountProjectReportEndpoints;
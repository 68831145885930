import React from "react";
import { IconButton } from "../../../components/buttons"
import { FormCheck, FormInput, FormSelect, FormTextArea, PhoneNumberInput } from "../../../components/forms"
import { Spinner } from "../../../components/loaders"
import { CenterFormColumnComponent } from "../../../components/utilities";

const EditConnectionForm = ({ connection, deleteConnection, isRemoving = false, updateConnection }) => {
    if (isRemoving === true) {
        return <Spinner />
    }
    return (
        <React.Fragment>
            <div className="row mb-2">
                <div className="col">
                    <FormInput
                        label="Title"
                        value={connection.title}
                        autoFocus
                        placeholder="Title"
                        onBlur={(value) => updateConnection(connection.connectionId, 'title', value)}
                    />
                </div>
                <div className="col">
                    <FormInput
                        label="Forenames"
                        value={connection.forenames}
                        placeholder="Forenames"
                        onBlur={(value) => updateConnection(connection.connectionId, 'forenames', value)}
                    />
                </div>
                <div className="col">
                    <FormInput
                        label="Surname"
                        value={connection.surname}
                        placeholder="Surname"
                        onBlur={(value) => updateConnection(connection.connectionId, 'surname', value)}
                    />
                </div>
                <CenterFormColumnComponent className="col-md-auto">
                    <FormCheck
                        type="checkbox"
                        label="Active"
                        isChecked={connection.isActiveConnection}
                        onChange={() => updateConnection(connection.connectionId, 'isActiveConnection', !connection.isActiveConnection)}
                    />
                </CenterFormColumnComponent>
                <CenterFormColumnComponent className="col-md-auto">
                    <IconButton icon="fa-trash-can" size="lg" variant="danger" onClick={() => deleteConnection(connection.connectionId)} />
                </CenterFormColumnComponent>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <FormSelect
                        label="Relationship"
                        defaultValue={connection.relationship}
                        isClearable={true}
                        options={[
                            { label: 'Trustee', value: 0 },
                            { label: 'Settler', value: 1 },
                            { label: 'Solicitor', value: 2 },
                            { label: 'Accountant', value: 3 },
                            { label: 'Parent', value: 4 },
                            { label: 'Grandparent', value: 5 },
                            { label: 'Son', value: 6 },
                            { label: 'Grandson', value: 7 },
                            { label: 'Daughter', value: 8 },
                            { label: 'Granddaughter', value: 9 },
                            { label: 'Sibling', value: 10 },
                            { label: 'Brother', value: 11 },
                            { label: 'Sister', value: 12 },
                            { label: 'Family Member', value: 13 },
                            { label: 'Friend', value: 14 },
                            { label: 'Partner', value: 15 },
                        ]}
                        onBlur={(values) => updateConnection(connection.connectionId, 'relationship', values ? values.value : null)}
                    />
                </div>
                <div className="col">
                    <FormInput
                        label="Position"
                        value={connection.position}
                        placeholder="Position"
                        onBlur={(value) => updateConnection(connection.connectionId, 'position', value)}
                    />
                </div>
                <div className="col">
                    <FormInput
                        label="Salutation"
                        value={connection.salutation}
                        placeholder="Salutation"
                        onBlur={(value) => updateConnection(connection.connectionId, 'salutation', value)}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <FormInput
                        label="Address"
                        value={connection.address}
                        placeholder="Address"
                        onBlur={(value) => updateConnection(connection.connectionId, 'address', value)}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <FormInput
                        label="Phone"
                        maxLength="2048"
                        value={connection.phoneNumber}
                        placeholder="Phone Number"
                        onBlur={(value) => updateConnection(connection.connectionId, 'phoneNumber', value)}
                    />
                </div>
                <div className="col">
                    <FormInput
                        label="Email"
                        value={connection.emailAddress}
                        placeholder="Email Address"
                        onBlur={(value) => updateConnection(connection.connectionId, 'emailAddress', value)}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <FormTextArea
                        minRows={3}
                        maxRows={7}
                        label="Notes"
                        value={connection.notes}
                        placeholder="Enter your notes here."
                        onBlur={(value) => updateConnection(connection.connectionId, 'notes', value)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditConnectionForm;
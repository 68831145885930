import styled from "styled-components";
import { PageHeader, ThemedSpan } from "../../../components/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InfinityList } from "../../../components/tables";
import useThreadPreviews from "../hooks/useThreadPreviews";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { MessagePriorities } from "../../../helpers/constants";
import { DateDisplay, IconButton } from "../../../components";
import { useClientMessaging } from "../hooks/ClientMessagingContext";
import Skeleton from "react-loading-skeleton";
import React from "react";

const ConversationListHeader = styled.div`
    background-color: ${props => props.theme.primary};
    color: white;
`;

const ClientConversationPreviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    ${({ isActive }) => isActive && "background-color: lightblue;"}

    &:hover {
        cursor: pointer;
        ${({ isActive }) => !isActive && "background-color: #eee;"}
    }

    width: 100%;
`;

const HeaderColumn = styled.div`
    font-weight: bold;
    margin: 0;
`;

const HighPriorityIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.danger};
`;

const MessageGridThreadsHeaderArea = styled.div`
    grid-area: threads-header;
    margin: 0;
`;

const MessageGridThreadsArea = styled.div`
    grid-area: threads;
    margin: 0;
`;

const LoadingMessagePreview = ({ style = { height: 120, width: "100%" } }) => (
    <div className="border p-1" style={style}>
        <Skeleton
            height={28}
            count={3}
        />
    </div>
);

const MessageThreadPreview = ({ style, index, data }) => {
    const { threadId: selectedThreadId, navigateToThread } = useClientMessaging();

    if (!data[index]) {
        return <LoadingMessagePreview style={style} />;
    };

    const {
        threadId,
        subject,
        message
    } = data[index];

    const {
        messagePreview,
        messagePriority,
        recipients,
        senderName,
        sentOnDate,
        createdDate
    } = message;

    return <ClientConversationPreviewWrapper
        style={style}
        isActive={threadId === selectedThreadId}
        onClick={() => navigateToThread(threadId)}
    >
        <div className="row mx-0">
            {messagePriority === MessagePriorities.High && <HeaderColumn className="d-none d-xl-block col-xl-auto me-1 p-0">
                <HighPriorityIcon icon={"exclamation-circle"} size={"lg"} />
            </HeaderColumn>}
            <HeaderColumn className="col text-truncate p-0">
                <ThemedSpan>
                    {subject ?? "New Conversation"}
                </ThemedSpan>
            </HeaderColumn>
            <HeaderColumn className="col-auto px-1 d-none d-lg-block">
                <DateDisplay>
                    {sentOnDate ?? createdDate}
                </DateDisplay>
            </HeaderColumn>
        </div>
        <div className="row mx-0">
            <div className="col text-truncate p-0">
                <ThemedSpan>
                    From: {senderName}
                </ThemedSpan>
            </div>
        </div>
        <div className="row mx-0">
            <div className="col text-truncate p-0">
                <ThemedSpan>
                    To: {
                        recipients.reduce(
                            (totalString, currentRecipient) => totalString + (totalString.length === 0 ? "" : "; ") + currentRecipient.displayName,
                            "")
                    }
                </ThemedSpan>
            </div>
        </div>
        <div className="row mx-0 mb-1 mt-auto">
            <div className="col text-truncate p-0">
                <ThemedSpan>
                    {messagePreview}
                </ThemedSpan>
            </div>
        </div>
    </ClientConversationPreviewWrapper>;
};

const MessageThreads = () => {

    const {
        createThread,
        isItemLoaded,
        loadMoreItems,
        retry,
        results,
        totalCount,
        error,
        isError,
        isClearing,
        createThreadResult: { isLoading: isCreating }
    } = useThreadPreviews();

    return <React.Fragment>
        <MessageGridThreadsHeaderArea>
            <ConversationListHeader className="border border-bottom-0 rounded-top d-flex h-100 px-2">
                <div className="d-flex align-items-center">
                    <PageHeader className="ps-0">Conversations</PageHeader>
                </div>
                <div className="d-flex align-items-center ms-auto">
                    <IconButton disabled={isCreating} onClick={createThread} className="my-0" icon={"plus"} size="lg" />
                </div>
            </ConversationListHeader>
        </MessageGridThreadsHeaderArea>
        <MessageGridThreadsArea className="border border-right-0 rounded">
            <div className="h-100">
                {isError && <ErrorMessageWrapper>
                    <ErrorMessage error={error} retryCallback={retry} />
                </ErrorMessageWrapper>}
                {isClearing && <React.Fragment>
                    <LoadingMessagePreview />
                    <LoadingMessagePreview />
                    <LoadingMessagePreview />
                    <LoadingMessagePreview />
                    <LoadingMessagePreview />
                </React.Fragment>}
                {!isError && !isClearing && <InfinityList
                    itemData={results}
                    itemSize={120}
                    itemCount={totalCount}
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadMoreItems}
                    row={MessageThreadPreview}
                />}
            </div>
        </MessageGridThreadsArea>
    </React.Fragment>;
};

export default MessageThreads;
import { wealthProApi } from '../../redux/api';

const valuationsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["valuation"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchValuation: builder.query({
            query: ({ masterAccountId }) => ({
                url: `api/valuations/${masterAccountId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => {
                return ['valuation'];
            }
        }),
        exportValuation: builder.query({
            query: ( request ) => ({
                url: `api/valuations/pdf`,
                method: 'POST',
                body: request
            }),
        })
    })
});

export const {
    useFetchValuationQuery,
    useLazyExportValuationQuery,
} = valuationsApiEndpoints;
import React from "react";
import styled from "styled-components";
import withProviders from "../hooks/withProviders";
import { AdministrationFeeRouteTabs } from "../routes/administration.fees.routes";
import { FilterProvider, FeeManagementDefaultFilters } from "../hooks/FilterContext";
import { Outlet } from "react-router-dom";
import { AgGridMain, ThemedParagraph } from "../components/utilities";
import PageHeader, { PageHeaderTop, PageHeaderBottom } from "../components/layout/PageHeader";
import FeeTotals from "../features/headofficefeemanagement/components/FeeTotals";

const FeeManagementHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const FeeManagementHeaderBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const AdministrationFeesLayout = () => {
    return <React.Fragment>
        <PageHeader>
            <PageHeaderTop>
                <FeeManagementHeaderTitle>
                    <span>Fees Administration</span>
                </FeeManagementHeaderTitle>
            </PageHeaderTop>
            <FeeManagementHeaderBottom>
                <AdministrationFeeRouteTabs />
                <FeeTotals/>
            </FeeManagementHeaderBottom>
        </PageHeader>
        <AgGridMain>
            <Outlet />
        </AgGridMain>
    </React.Fragment>
};

export default withProviders([FilterProvider, { defaultFilters: FeeManagementDefaultFilters }])(AdministrationFeesLayout);
import { useFetchFeeTransactionTypesQuery } from "../../../services/headofficeadmin";

const useFeeTransactionTypes = () => {

    const { data, isLoading } = useFetchFeeTransactionTypesQuery();

    return {
        data: data || [],
        isLoading
    };
};

export default useFeeTransactionTypes;
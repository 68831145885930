import { wealthProApi } from "../../redux/api";

const userEmailUserDelegatesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["UserDelegates"]
}).injectEndpoints({
    endpoints: builder => ({
        fetchMailUserDelegates: builder.query({
            query: ({accessType}) => `api/clientdocuments/userdelegates?accessType=${accessType}`,
            providesTags: ["UserDelegates"]
        }),
        getMailDelegatedAccessRightsForUser: builder.query({
            query: ({userId}) => `api/clientdocuments/userdelegates/${userId}`,
            providesTags: ["UserDelegates"]
        })
    })
});

export const {
    useFetchMailUserDelegatesQuery,
    useGetMailDelegatedAccessRightsForUserQuery,
    useLazyGetMailDelegatedAccessRightsForUserQuery
} = userEmailUserDelegatesApiEndpoints;
import { wealthProApi } from "../../redux/api";

const portfolioTestingApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        exportTestCertificatePdf: builder.query({
            query: (request) => ({
                url: `api/valuations/PortfolioTesting/pdf`,
                method: 'POST',
                body: request
            }),
        })
    })
});

export const {
    useLazyExportTestCertificatePdfQuery,
} = portfolioTestingApiEndpoints;
import _ from "lodash"
import fetchReportTemplateCacheSocketHandler from "./sockets/fetchReportTemplateCacheSocketHandler";
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

const buildFetchReportTemplatesQuery = ({ searchText = "", sort = null, page = 1, limit = 25 }, list = false) => {
    let baseQuery = list === false
        ? `api/reports/reports/templates?page=${page}&limit=${limit}`
        : `api/reports/reports/templates/list?page=${page}&limit=${limit}`;

    if (sort && sort.length > 0) {
        baseQuery += baseQuery.includes('?') ? `&sort=${sort}` : `?sort=${sort}`;
    }

    if (searchText && searchText.length > 0) {
        var searchTermEncode = encodeURIComponent(searchText);
        baseQuery += `&searchTerm=${searchTermEncode}`;
    }

    return baseQuery;
}

const reportTemplateEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createTemplate: builder.mutation({
            query: ({ data }) => ({
                url: `api/reports/reports/templates`,
                method: 'POST',
                body: data
            })
        }),
        deleteTemplate: builder.mutation({
            query: ({ reportTemplateId }) => ({
                url: `api/reports/reports/templates/${reportTemplateId}`,
                method: 'DELETE',
                body: { reportTemplateId }
            })
        }),
        fetchReportTemplates: builder.query({
            query: (props) => buildFetchReportTemplatesQuery(props),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            onCacheEntryAdded: fetchReportTemplateCacheSocketHandler()
        }),
        fetchReportTemplatesList: builder.query({
            query: (props) => buildFetchReportTemplatesQuery(props, true),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            onCacheEntryAdded: fetchReportTemplateCacheSocketHandler()
        }),
        patchTemplate: builder.mutation({
            query: ({ reportTemplateId, operations }) => ({
                url: `api/reports/reports/templates/${reportTemplateId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ reportTemplateId, operations }, { dispatch, queryFulfilled }) {
                const fetch = dispatch(wealthProApi.util.updateQueryData('fetchReportTemplates', {}, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.id === reportTemplateId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));

                const fetchList = dispatch(wealthProApi.util.updateQueryData('fetchReportTemplatesList', {}, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.id === reportTemplateId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    fetch.undo();
                    fetchList.undo();
                }
            }
        })
    })
});

export const {
    useCreateTemplateMutation,
    useDeleteTemplateMutation,
    usePatchTemplateMutation,
    useFetchReportTemplatesQuery,
    useFetchReportTemplatesListQuery,
    useLazyFetchReportTemplatesQuery,
    useLazyFetchReportTemplatesListQuery
} = reportTemplateEndpoints;
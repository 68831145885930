import React from "react";
import { useLazyGetReportHistorySummaryQuery } from "../../../services/clientreports";

const useReportStatusHistory = (masterAccountId, reportId) => {
    const [isDirty, setIsDirty] = React.useState(false);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazyGetReportHistorySummaryQuery();

    const clear = () => {
        if (!masterAccountId || !reportId) {
            return;
        }

        setIsDirty(_ => true);
        setIsLoadingState(_ => true);
        load({ masterAccountId, reportId }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
            setIsDirty(_prev => false);
        });
    };

    React.useEffect(clear, [ masterAccountId, reportId ]);

    return {
        ...loadResults,
        data: data || null,
        isDirty,
        isFetching: isDirty === true ? false : isFetching,
        isLoading: isDirty === true ? true : isLoading,
        isLoadingState
    };
};

export default useReportStatusHistory;
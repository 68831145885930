import React from "react";
import { ProfileIcon } from "../../../components";

const ProfileInfo = ({ user, ...rest }) => {
    const { isOnline, type, displayName } = user;
    return (
        <div className="d-flex align-items-center mb-3">
            <div className="d-flex align-items-center justify-center">
                <ProfileIcon user={user} isOnline={isOnline} showOnlineStatusIndicator={true} />
            </div>
            <p className="mx-3 my-0 lh-1">
                <span>{displayName}</span>
                <br />
                <span className="text-muted fs-smallest">{type}</span>
            </p>
        </div>
    );
};

export default ProfileInfo;
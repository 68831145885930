import React from "react";
import Button from "../../../components/buttons";
import { Modal } from "react-bootstrap";

const ReportTypeMappingConfirmModal = ({ handleClose, handleConfirm, onClose, selection, theme = {}, ...props }) => {
    
    const onCloseSelected = () => {
        if (handleClose && typeof(handleClose) === 'function') {
            handleClose();
        }
        onClose();
    };

    const onConfirmSelected = () => {
        if (handleConfirm && typeof(handleConfirm) === 'function') {
            handleConfirm();
        }
        onClose();
    };

    return <Modal centered backdrop="static" show={true}>
        <Modal.Header>
            <Modal.Title>Assign default Categories?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{`The Report Type: '${selection.label}' you have selected contains default Category & Product Area mappings. Would you like to assign these to the report?`}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" theme={theme} onClick={onConfirmSelected}>Yes, Confirm.</Button>
            <Button variant="light" theme={theme} onClick={onCloseSelected}>No, Manually assign.</Button>
        </Modal.Footer>
    </Modal>
};

export default ReportTypeMappingConfirmModal;
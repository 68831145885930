import { useState } from "react";
import { useCreateTemplateMutation } from "../../../services/clientreports";

const useCreateReportTemplate = () => {
    const [_create, createData] = useCreateTemplateMutation();
    const [defaultValues, setDefaultValues] = useState({
        description: '',
        fileName: "",
        fileSize: 0,
    });

    const buildFormData = (data, file) => {
        const newFormData = new FormData();
        const { description, fileName, fileSize } = data;

        newFormData.append('attachment', file, file.name);        
        newFormData.append('description', description);
        newFormData.append('fileName', fileName);
        newFormData.append('fileSize', fileSize);

        return newFormData;
    };

    const create = (data) => {
        return new Promise((resolve, reject) => {
            return _create({ data }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        ...createData,
        buildFormData,
        create,
        defaultValues
    };
};

export default useCreateReportTemplate;
import React from "react";
import styled from "styled-components";
import withProviders from "../hooks/withProviders";
import AdministrationApplicationInfo from "../features/administrationapplicationinfo";
import { AdministrationApplicationRouteTabs } from "../routes/administration.applications.routes";
import { readableColor } from "polished";
import { Outlet, useNavigate } from "react-router-dom";
import { HeadOfficeApplicationProvider, useHeadOfficeApplicationContext } from "../hooks/HeadOfficeApplicationContext";
import { FilterProvider, AdministrationDashboardDefaultFilters } from "../hooks/FilterContext";
import { IconButton } from "../components";
import { Spinner } from "../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../components/messages";
import { CenterPage, Main } from "../components/utilities";

const BaseLayout = () => <Outlet />

export const AdministrationApplicationsBaseLayout = withProviders([FilterProvider, { defaultFilters: AdministrationDashboardDefaultFilters }])(BaseLayout); 

const Header = styled.section`
    grid-area: pageheader;
    position: sticky;
    top: var(--navbar-size);
    z-index: 5;
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

const HeaderBottom = styled.div``;

const HeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

const HeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 0.6rem;
    }
`;

const AdministrationApplicationsLayout = () => {
    const { applicationId, application, error, isError, isLoading, reload } = useHeadOfficeApplicationContext();
    const appNavigator = useNavigate();

    const handleOnClick = () => {
        appNavigator(`/administration/applications`);
    };

    const handleRetry = (e) => {
        e.preventDefault();
        reload(e);
    };

    return (
        <React.Fragment>
            <Header>
                <HeaderTop>
                    <HeaderTitle>
                        <span>Administration:</span>
                        {application && <span>{application.designationName ?? application.masterAccountName} {application.applicationReference}</span>}
                    </HeaderTitle>
                    <div className="d-flex flex-fill justify-content-end align-items-center">
                        <IconButton
                            onClick={handleOnClick}
                            icon={"fa-folder-minus"}
                            buttonSize={"lg"}
                            size={"lg"}
                        />
                    </div>
                </HeaderTop>
                <HeaderBottom>
                    {/* We only want to render the navigation when an application has been selected */}
                    {applicationId && <AdministrationApplicationRouteTabs isPolicy={application.isPolicy}/>}
                </HeaderBottom>
            </Header>
            {(error || isError === true) &&
                <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                    <ErrorMessage error={error} retryCallback={handleRetry} />
                </ErrorMessageWrapper>
            }
            {isLoading && <Main>
                <CenterPage>
                    <Spinner />
                </CenterPage>
            </Main>}
            {isError === false && isLoading === false &&
                <React.Fragment>
                    {application && <AdministrationApplicationInfo application={application} />}
                    <Outlet />
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default withProviders(HeadOfficeApplicationProvider)(AdministrationApplicationsLayout);
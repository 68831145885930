import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import ClientAccountForm from "../../clientdetails/components/ClientAccountForm";
import useEditClient from "../hooks/useEditClient";

const EditClientModal = ({ clientName, clientId, ...modalProps }) => {
    const [retry, { client, isLoading, isError }] = useEditClient(clientId);

    return <Modal {...modalProps} centered>
        <Modal.Header closeButton>{clientName}</Modal.Header>
        <Modal.Body>
            {(isLoading || !client)
                ? <Skeleton />
                : isError
                    ? <ErrorMessageWrapper>
                        <ErrorMessage
                            message="Sorry! We were unable to load this clients' details. Please try again"
                            retryCallback={retry}
                        />
                    </ErrorMessageWrapper>
                    : <ClientAccountForm client={client} allowDelete={false} showLabel />
            }
        </Modal.Body>
    </Modal>
}

export default EditClientModal;
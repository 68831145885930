import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { FormLabel } from "../../../components/forms";
import { NotesEditor } from "../../../components/forms/richtexteditor/RichTextEditor";
import { BooleanSelect } from "../../../components/forms/selects";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import EstatePlanningGifts from "../components/EstatePlanningGifts";
import useEstatePlanningSection from "../hooks/useEstatePlanningSection";

const EstatePlanningSection = () => {
    const { data, isLoading, isError, error, refetchEstatePlanningSection, patchEstatePlanningSection, patchNotesSection } = useEstatePlanningSection();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchEstatePlanningSection} />
        </ErrorMessageWrapper>;
    };

    return <Container fluid={true}>
        <Row>
            <Col xs={6}>
                <Row>
                    <Col xs="auto" className="my-auto">
                        <FormLabel>
                            Is inheritance tax a future consideration:
                        </FormLabel>
                    </Col>
                    <Col xs="auto">
                        {isLoading
                            ? <Skeleton width={100} />
                            : <BooleanSelect
                                defaultValue={data.consideringInheritanceTax}
                                onBlur={({ value }) => patchEstatePlanningSection(data.estatePlanningId, "consideringInheritanceTax", value)}
                            />}
                    </Col>
                </Row>
            </Col>
            <Col xs={6}>
                <Row>
                    <Col xs="auto" className="my-auto">
                        <FormLabel>
                            Have steps been taken to mitigate tax:
                        </FormLabel>
                    </Col>
                    <Col xs="auto">
                        {isLoading
                            ? <Skeleton width={100} />
                            : <BooleanSelect
                                defaultValue={data.takenStepsToMitigateTax}
                                onBlur={({ value }) => patchEstatePlanningSection(data.estatePlanningId, "takenStepsToMitigateTax", value)}

                            />}
                    </Col>
                </Row>
            </Col>
        </Row>

        <div className="row my-4">
            <NotesEditor
                label={"Estate Planning Objectives"}
                property={"estatePlanningObjectives"}
                defaultValue={data?.estatePlanningObjectives?.notes}
                isLoading={isLoading}
                onBlur={(newValue) => patchNotesSection(data?.estatePlanningObjectives?.notesId, newValue)}
            />
        </div>

        <div className="row my-4">
            <EstatePlanningGifts
                estatePlanningId={data?.estatePlanningId}
                giftIds={data?.giftIds}
            />
        </div>
        <div className="row my-4">
            <NotesEditor
                label={"Details of Wills and Estate Distribution on Death"}
                property={"willEstateDistributionDetails"}
                defaultValue={data?.distributionOnDeath?.notes}
                isLoading={isLoading}
                onBlur={(newValue) => patchNotesSection(data?.distributionOnDeath?.notesId, newValue)}
            />
        </div>
        <div className="row my-4">
            <NotesEditor
                label={"Details of any Trusts to which the Client is a Beneficiary, including Payments Received"}
                property={"trustBeneficiaryDetails"}
                defaultValue={data?.trustsDetails?.notes}
                isLoading={isLoading}
                onBlur={(newValue) => patchNotesSection(data?.trustsDetails?.notesId, newValue)}
            />
        </div>
        <div className="row">
            <NotesEditor
                label={"Details of all Expected Inheritances, from All Sources"}
                property={"expectedInheritanceDetails"}
                defaultValue={data?.expectedInheritances?.notes}
                isLoading={isLoading}
                onBlur={(newValue) => patchNotesSection(data?.expectedInheritances?.notesId, newValue)}
            />
        </div>
    </Container>
}

export default EstatePlanningSection;
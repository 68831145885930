import _ from "lodash";
import React from "react";
import classNames from "classnames";
import toast from "react-hot-toast";
import styled, { useTheme } from "styled-components";
import useClientDashboardLoginDetails from "../../clientdashboardlogindetailsmodal/hooks/ClientDashboardLoginDetailsContext";
import useAccountActivationControl from "../hooks/useAccountActivationControl";
import useClientDashboardLoginsData from "../hooks/ClientDashboardLoginsContext";
import useCreateImpersonationToken from "../hooks/useCreateImpersonationToken";
import useCreatePasswordResetEmail from "../hooks/useCreatePasswordResetEmail";
import useCreateWelcomeEmail from "../hooks/useCreateWelcomeEmail";
import useDeleteAccount from "../hooks/useDeleteAccount";
import useDeleteSessions from "../hooks/useDeleteSessions";
import useUnlockAccount from "../hooks/useUnlockAccount";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateDisplay } from "../../../components";
import { DropdownMenuItemIcon } from "../../../components/utilities";
import { useClientDashboardManagementControls } from "../../../hooks/ClientDashboardManagementContext";

import { confirmAlert } from "react-confirm-alert";
import ConfirmModal from "../../../components/modals/ConfirmModal";

const ActionColumnParent = styled.td`
    width: ${({ width }) => width}%;
    cursor: pointer;
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
`;

const ActionColumnParentToggle = React.forwardRef(({ ...rest }, ref) => (
    <div ref={ref} {...rest}>
        <FontAwesomeIcon icon="fa-ellipsis" />
    </div>
));

const TableColumnAction = ({ width = 2.5, data = null }) => {
    const menuId = React.useId();
    const theme = useTheme();
    const { clear: reloadTable } = useClientDashboardLoginsData();
    const { setData: editLogin } = useClientDashboardLoginDetails();
    const { send: sendWelcomeEmail, isLoading: isSendingWelcomeEmail } = useCreateWelcomeEmail();
    const { send: sendPasswordEmail, isLoading: isSendingPasswordEmail } = useCreatePasswordResetEmail();
    const { create: impersonate, isLoading: isCreatingImpersonationToken } = useCreateImpersonationToken();
    const { deleteAccount, isLoading: isDeletingAccount } = useDeleteAccount();
    const { clear: deleteSessions, isLoading: isDeletingSessions } = useDeleteSessions();
    const { path: activateAccount, isLoading: isActivatingAccount } = useAccountActivationControl();
    const { unlock, isLoading: isUnlockingAccount } = useUnlockAccount();
    const { isAllowedToDeleteLogin, isAllowedToImpersonateLogin } = useClientDashboardManagementControls();

    const handleActivateAccount = () => {
        if (!data) { return; }
        const { id, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Activate this Account?"}
                    message="Activating this account will give future access to the Client Dashboard with these credentials. Are you sure you want to activate this account?"
                    confirmButtonLabel="Activate Account"
                    confirmButtonVariant="success"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(activateAccount(masterAccountId, id, true), {
                            loading: `Activating the account...`,
                            success: () => {
                                reloadTable();
                                return `The dashboard login has been activated.`;
                            },
                            error: `Failed to activate the dashboard login.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    const handleClearAccountSessions = () => {
        if (!data) { return; }
        const { id, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Delete Account Sessions?"}
                    message="This is a destructive action that will remove all past and current login sessions from this dashboard login. If the user is currently active on their Dashboard, the session will be terminated and they will be forced to login again. Are you sure you want to delete these sessions?"
                    confirmButtonLabel="Delete Sessions"
                    confirmButtonVariant="danger"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(deleteSessions(masterAccountId, id), {
                            loading: `Deleting account sessions...`,
                            success: () => {
                                reloadTable();
                                return `The sessions have been deleted.`;
                            },
                            error: `Failed to deleted the sessions.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    const handleDeactivateAccount = () => { 
        if (!data) { return; }
        const { id, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Deactivate this Account?"}
                    message="Deactivating this account will prevent future access to the Client Dashboard with these credentials. Are you sure you want to deactivate this account?"
                    confirmButtonLabel="Deactivate Account"
                    confirmButtonVariant="danger"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(activateAccount(masterAccountId, id, false), {
                            loading: `Deactivating the account...`,
                            success: () => {
                                reloadTable();
                                return `The dashboard login has been deactivated.`;
                            },
                            error: `Failed to deactivated the dashboard login.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    const handleDeleteAccount = () => { 
        if (!data) { return; }
        const { id, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Delete this Account?"}
                    message="This is a destructive action that will remove this login from this Master Account, preventing them from logining into the Client Dashboard with these credentials in the future. Are you sure you want to delete this account?"
                    confirmButtonLabel="Delete Account"
                    confirmButtonVariant="danger"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(deleteAccount(masterAccountId, id), {
                            loading: `Deleting the account...`,
                            success: () => {
                                reloadTable();
                                return `The dashboard login has been deleted.`;
                            },
                            error: `Failed to deleted the dashboard login.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    const handleEditAccount = () => {
        if (!data) { return; }
        editLogin(data);
    };

    const handleImpersonate = () => {
        if (!data) { return; }
        const { id, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Impersonate this Account?"}
                    message="You are about to generate a new active session for this account and launch the Client Dashboard as this account. Please ensure that you logout when you are finished."
                    confirmButtonLabel="Impersonate"
                    confirmButtonVariant="success"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(impersonate(masterAccountId, id), {
                            loading: 'Creating impersonation token...',
                            success: (result) => {
                                const { handshakeUrl } = result;
                                if (handshakeUrl) {
                                    window.open(handshakeUrl, '_blank');
                                    return `Impersonation token was created.`;
                                }
                                reloadTable();
                                return `Failed to create the impersonation token.`;
                            },
                            error: `Failed to create the impersonation token.`
                        })
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    const handleSendResetEmail = () => {
        if (!data || !data?.hasRegistered) { return; }
        const { emailAddress, id, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Send Password Reset Email?"}
                    message={`This action will send a 'Password Reset' code email to the email address '${emailAddress}', which will remain valid for approx 30 minutes. Are you sure you want to email the client?`}
                    confirmButtonLabel="Send Email"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(sendPasswordEmail(masterAccountId, id, emailAddress), {
                            loading: `Sending Password reset email to '${emailAddress}'...`,
                            success: `Password reset email was sent.`,
                            error: `Failed to Password reset email.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    const handleSendWelcomeEmail = () => {
        if (!data) { return; }
        const { masterAccountId, emailAddress, id, hasRegistered } = data;

        if (hasRegistered) {
            toast.error('Unable to send a welcome email as the account has already been registered.');
            return;
        }

        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Send Welcome Email?"}
                    message={hasRegistered 
                        ? `This user has already registered/setup their password. Sending a further Welcome email will not send them a new code but will send them a link to get to the dashboard login page. Please be aware that by pressing "Send Email", you will send an email directly to '${emailAddress}'.` 
                        : `This action will send an email '${emailAddress}' that will contain a Welcome code for them to continue registering their account. Are you sure you want to send this email?`}
                    confirmButtonLabel="Send Email"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(sendWelcomeEmail(masterAccountId, id), {
                            loading: `Sending Welcome email to '${emailAddress}'...`,
                            success: `Welcome email was sent.`,
                            error: `Failed to send Welcome email.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    const handleUnlockAccount = () => {
        if (!data || data?.isLocked === false) { return; }
        const { id, masterAccountId } = data;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal 
                    theme={theme}
                    size="md"
                    title={"Unlock this Account?"}
                    message="This account is locked, likely due to too many password/verification code attempts. Unlocking this account will allow valid credentials access to this Master Accounts valuation data again. Are you sure you want to unlock this account?"
                    confirmButtonLabel="Unlock Account"
                    handleConfirm={() => {
                        onClose();
                        toast.promise(unlock(masterAccountId, id), {
                            loading: `Unlocking the account...`,
                            success: () => {
                                reloadTable();
                                return `The dashboard login has been unlocked.`;
                            },
                            error: `Failed to unlock the dashboard login.`
                        });
                    }}
                    handleClose={onClose}
                />
            }
        });
    };

    const { hasRegistered, isActive, isLocked, username, createdDate } = data;
    return (
        <ActionColumnParent width={width}>
            <Dropdown className="caret-off">
                <Dropdown.Toggle id={menuId} as={ActionColumnParentToggle} />
                <Dropdown.Menu>
                    <Dropdown.Header>
                        <span>{username}</span> created <DateDisplay>{createdDate}</DateDisplay>
                    </Dropdown.Header>
                    {isAllowedToImpersonateLogin && (
                        <Dropdown.Item disabled={isCreatingImpersonationToken} onClick={handleImpersonate}>
                            <DropdownMenuItemIcon icon="fas fa-user-secret" size="sm" /> <span className="ms-1">Impersonate</span>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item disabled={isCreatingImpersonationToken} onClick={handleEditAccount}>
                        <DropdownMenuItemIcon icon="fas fa-user-edit" size="sm" /> <span className="ms-1">View/Edit Details</span>
                    </Dropdown.Item>
                    {isLocked === true && (
                         <Dropdown.Item disabled={isUnlockingAccount} onClick={handleUnlockAccount}>
                            <DropdownMenuItemIcon icon="fas fa-unlock" size="sm" /> <span className="ms-1">Unlock Account</span>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Header>
                        Communication
                    </Dropdown.Header>
                    <Dropdown.Item disabled={isSendingPasswordEmail || !hasRegistered} onClick={handleSendResetEmail}>
                        <DropdownMenuItemIcon icon="fas fa-paper-plane" size="sm" /> <span className="ms-1">Send Password Reset</span>
                    </Dropdown.Item>
                    <Dropdown.Item disabled={isSendingWelcomeEmail || hasRegistered === true} onClick={handleSendWelcomeEmail}>
                        <DropdownMenuItemIcon icon="fas fa-paper-plane" size="sm" /> <span className="ms-1">Send Welcome Email</span>
                    </Dropdown.Item>
                    <Dropdown.Header className="text-danger">
                        Danger Zone
                    </Dropdown.Header>
                    {isActive === true && (
                        <Dropdown.Item disabled={isActivatingAccount} className="text-danger" onClick={handleDeactivateAccount}>
                            <DropdownMenuItemIcon icon="fa fa-user-alt-slash" size="sm" /> <span className="ms-1">Deactivate Account</span>
                        </Dropdown.Item>
                    )}
                    {isActive === false && (
                        <Dropdown.Item disabled={isActivatingAccount} className="text-danger" onClick={handleActivateAccount}>
                            <DropdownMenuItemIcon icon="fa fa-user-check" size="sm" /> <span className="ms-1">Activate Account</span>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item disabled={!isAllowedToDeleteLogin || isDeletingSessions} className={classNames({ "text-danger": isAllowedToDeleteLogin && !isDeletingSessions })} onClick={handleClearAccountSessions}>
                        <DropdownMenuItemIcon icon="fa fa-user-clock" size="sm" /> <span className="ms-1">Clear Sessions</span>
                    </Dropdown.Item>
                    <Dropdown.Item disabled={!isAllowedToDeleteLogin || isDeletingAccount} className={classNames({ "text-danger": isAllowedToDeleteLogin && !isDeletingAccount })} onClick={handleDeleteAccount}>
                        <DropdownMenuItemIcon icon="fa fa-trash" size="sm" /> <span className="ms-1">Delete</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ActionColumnParent>
    );
};

export default TableColumnAction;
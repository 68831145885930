import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FormLabel } from "../../components/forms";
import { useClientContext } from "../../hooks/ClientContext";
import BusinessSheetSearch from "../bizsheetsearch";
import ClientReportSearch from "../clientreportsearch";
import CreateReportModal from "../createreportmodal";
import useCreateBusinessSheet from "./hooks/useCreateBusinessSheet";

// Forbid creating biz sheets for Compliant (1/5/12), Cancelled (10), Execution Only (13), and Sent to Client (17) statuses
// Also forbid creating a new sheet for a report with a WP2 status: Finished (7), Follow Up (14), Migrate Recommendations (16)
const disabledReportStatuses = [1, 5, 7, 10, 12, 13, 14, 16, 17];

const CreateBusinessSheetModal = ({ handleClose, show, setSelectedSheetId }) => {
    const { account: { masterAccountId } } = useClientContext();
    const { defaultValues, handleCreate, isLoading: isCreating } = useCreateBusinessSheet(masterAccountId, null);
    const { control, formState: { errors }, handleSubmit, reset, setValue, watch, clearErrors } = useForm({ defaultValues });

    const hasSelectedReport = watch('reportId');
    const isBorrowing = watch('borrow');
    const borrowedSheetName = watch('borrowedSheetName');

    useEffect(() => {
        if (!isBorrowing) {
            clearErrors('borrowedSheetId');
            setValue('borrowedSheetId', null);
            setValue('borrowedSheetName', "");
        }
    }, [clearErrors, isBorrowing, setValue])

    const onClose = () => {
        reset(defaultValues);
        handleClose();
    };

    const onSubmit = (data) => {
        toast.promise(handleCreate(data)
            .then(r => {
                setSelectedSheetId(r.businessSheetId);

                return r.reportName;
            }), {
            loading: "Creating Business Sheet...",
            error: (err) => err?.data?.message ?? "Error creating Business Sheet.",
            success: (reportName) => {
                onClose();
                return `'${reportName}' Business Sheet Created! Please add an Instruction.`
            }
        })
    };

    const filterCompliantOptions = (options) => options.filter((o) => !o.isDisabled && !disabledReportStatuses.includes(o.data.status));

    const [showCreateReport, setShowCreateReport] = useState(false);

    return <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Create a New Business Sheet</Modal.Title>
        </Modal.Header>
        <CreateReportModal
            show={showCreateReport}
            handleClose={() => setShowCreateReport(false)}
            onReportCreated={(e) => {
                clearErrors('reportId');
                setValue('reportId', e.id);
                setValue('projectId', e.projectId);
                setValue('reportName', e.reportName);
            }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="reportId"
                            control={control}
                            rules={({ required: "A Report must be assigned to a Business Sheet." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ClientReportSearch
                                    buttonSize="sm"
                                    label="Report"
                                    labelPosition="top"
                                    formError={errors?.reportId?.message}
                                    value={value}
                                    onReportSelected={(_, report) => {
                                        clearErrors('reportId');
                                        setValue('reportId', report.id);
                                        setValue('projectId', report.projectId);
                                        setValue('reportName', report.reportName);
                                    }}
                                    allowCreate={false}
                                    onClickCreate={() => setShowCreateReport(true)}
                                    reportSelectProps={{ transformOptions: filterCompliantOptions }}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                {hasSelectedReport && <div className="row">
                    <div className="col">
                        <FormLabel>Borrow?</FormLabel>
                        <Controller
                            name="borrowedSheetId"
                            control={control}
                            rules={{ required: { value: isBorrowing, message: "Please select a Business Sheet to borrow." } }}
                            render={({ field: { value, onChange, onBlur, ...rest } }) =>
                                <BusinessSheetSearch
                                    value={borrowedSheetName}
                                    placeholder="Search for a Business Sheet to borrow..."
                                    onSelect={(sheet) => {
                                        clearErrors('borrowedSheetId');
                                        setValue('borrowedSheetName', sheet.reportName);
                                        setValue('borrowedSheetId', sheet.id);
                                    }}
                                    formError={errors?.borrowedSheetId}
                                    showCheckbox
                                    isChecked={isBorrowing}
                                    setIsChecked={(checked) => setValue('borrow', checked)}
                                    disabled={!isBorrowing}
                                    {...rest}
                                />
                            }
                        />
                    </div>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type="submit" disabled={isCreating}>Create Business Sheet</Button>
                <Button variant="light" onClick={onClose} disabled={isCreating}>Close</Button>
            </Modal.Footer>
        </form>
    </Modal>
};

export default CreateBusinessSheetModal;
import styled from "styled-components";
import { darken } from "polished";

const Application = styled.div`
    display: flex;
    flex-wrap: wrap;
    display: grid;
    min-height: 100vh;
    grid-template-rows:     var(--navbar-size) minmax(0, auto) minmax(0, auto) 1fr auto;
    grid-template-columns:  minmax(0, 1fr);
    grid-template-areas:    "header"
                            "pageheader"
                            "pagesubheader"
                            "main"
                            "footer";
    background-color: ${props => darken(0.04, props.theme.light)};
`;

export default Application;
import React, { useEffect, useState } from "react";

import { useFetchValuationQuery } from "../../../services/valuations/valuationsApiEndpoints";

const useValuationDetails = (masterAccountId) => {

    const { data, error, isError, isLoading, isFetching, isUninitialized, refetch } = useFetchValuationQuery({ masterAccountId }, { refetchOnMountOrArgChange: true });

    return {
        data,
        isLoading,
        isFetching,
        isError,
        error,
        reload : refetch
    };

};

export default useValuationDetails;

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { getColoursForInitials } from '../helpers/colours';

export const Initials = styled.span`
    position: relative;

    &&:before {
        content: ${(props) => `"${props.content ? props.content : ''}"`};
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${props => props.width > 20 ? props.width / 32 : props.width / 20}em;
        width: ${props => `${props.width}px`};
        height: ${props => `${props.height}px`};
        padding: 3px;
        border-radius: 50%;
        font-weight: bold;
        color: white;
        background: ${(props) => `${props.bg ? rgba(props.bg, 1) : rgba('#686de0', 1)}`};
    }
`;

export const ProfileIconStatusIndicator = styled.span`
    position: absolute;
    bottom: 0;
    right: 0;
    width: ${props => props.width ?? '12px'};
    height: ${props => props.height ?? '12px'};
    background-color: ${props => props.isOnline ? '#77dd77' : '#E5E4E2'};
    border: 2px solid white;
    border-radius: 50%;
`;

const ProfileIcon = ({ user, isOnline = false, showOnlineStatusIndicator = false, width = 32, height = 32, ...rest }) => {
    const displayInitials = useMemo(() => {
        if (!user) {
            return width <= 20 || height <= 20 
                ? 'Z' 
                : 'ZZ';
        }

        let firstInitial = !user.id 
            ? user.given_name 
                ? user.given_name.charAt(0)
                : user.preferred_username.charAt(0)
            : user.forenames
                ? user.forenames.charAt(0)
                : user.displayName.charAt(0);
        
        let secondInitial = !user.id
            ? user.family_name
                ? user.family_name.charAt(0)
                : firstInitial
            : user.surname
                ? user.surname.charAt(0)
                : firstInitial;

        return width > 20 || height > 20 
            ? `${firstInitial}${secondInitial}`.toUpperCase()
            : `${firstInitial}`.toUpperCase();
    }, [ user ]);

    const backgroundColour = useMemo(() => {
        if (!displayInitials || displayInitials.length === 0) {
            return getColoursForInitials('Z', 'Z');
        }

        return getColoursForInitials(displayInitials.charAt(0), displayInitials.charAt(1));
    }, [ displayInitials ]);

    if (!user || !user.hasAvatar || user.hasAvatar === false) {
        return (
            <Initials content={displayInitials} bg={backgroundColour} width={width} height={height} {...rest}>
                {showOnlineStatusIndicator && (
                    <ProfileIconStatusIndicator isOnline={isOnline} width={width > 24 ? '12px' : '10px'} height={height > 24 ? '12px' : '10px'} />
                )}
            </Initials>
        );
    }
    else {
        // TODO: re-enable profile picture
        // return <img src={user.displayPicture} alt={createProfileInitials()} width={width} height={height} />
        return <Initials content={displayInitials} bg={backgroundColour} width={width} height={height} {...rest} />;
    }
};

export default ProfileIcon;
import { wealthProApi } from '../../redux/api';

const userClientTrackingEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["clientracker"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchUserClientActivityTracker: builder.query({
            query: ({ masterAccountId }) => `/api/tracking/accounts/${masterAccountId}`,
            providesTags: (result, error, arg) => result
                ? [...result.map(({ userId }) => ({ type: 'clientracker', id: userId })), 'clientracker']
                : ['clientracker']
        }),
    })
});

export const {
    useFetchUserClientActivityTrackerQuery
} = userClientTrackingEndpoints;
import { ButtonGroup, Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useForm } from "react-hook-form";
import React, { useCallback, useEffect, useMemo } from "react";
import { ConfirmModal } from "../../../components/modals";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemedSpan } from "../../../components/utilities";
import toast from "react-hot-toast";
import { BasicToast } from "../../../components/toasts";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { DraftMailForm } from "../../createmailmodal";
import useAttachments from "../hooks/useAttachments";

const DraftEmail = ({ data }) => {

    const {
        id
    } = data;

    const appNavigator = useNavigate();
    const { user: { profile } } = useAuth();
    const {
        patchDraftEmail,
        deleteDraftEmail,
        deleteDraftEmailResult: { isLoading: isLoadingDelete, isUpdating: isDeleting },
        sendDraftEmail,
        sendDraftEmailResult: { isLoading: isLoadingSend, isUpdating: isSending },
        selectedFolderId: folderId,
        selectedMailUserId: userId
    } = usePersonalOutlookContext();

    const { addAttachment, deleteAttachment } = useAttachments();

    const mapRecipientsFromData = useCallback((recipientsList) => recipientsList.map(x => ({
        label: x.emailAddress?.name,
        value: x.emailAddress?.address
    })), []);

    const formDefaultValues = useMemo(() => ({
        from: profile ? { value: profile.sub.replace(/-/g, ""), label: profile.name } : null,
        subject: data?.subject,
        body: data?.body?.content,
        masterAccountId: null,
        projectId: null,
        bccRecipients: mapRecipientsFromData(data?.bccRecipients ?? []),
        ccRecipients: mapRecipientsFromData(data?.ccRecipients ?? []),
        toRecipients: mapRecipientsFromData(data?.toRecipients ?? []),
        attachments: data?.attachments ?? []
    }), [data, mapRecipientsFromData]);

    const { setValue, ...formProperties } = useForm({ values: formDefaultValues });

    const mapRecipientsToRequest = useCallback((recipientsList) => recipientsList.map(x => ({
        contactName: x.label,
        contactEmailAddress: x.value,
        isNew: x["__isNew__"]
    })), [])

    const mapRequestFromFormData = useCallback((formData) => ({
        from: formData.from?.data ?? null,
        subject: formData.subject ?? null,
        body: formData.body ?? null,
        masterAccountId: null,
        projectId: null,
        bccRecipients: formData.bccRecipients ? mapRecipientsToRequest(formData.bccRecipients) : null,
        ccRecipients: formData.ccRecipients ? mapRecipientsToRequest(formData.ccRecipients) : null,
        toRecipients: formData.toRecipients ? mapRecipientsToRequest(formData.toRecipients) : null,
    }), [mapRecipientsToRequest]);

    const handleValueChange = (property, newValue) => {

        if (property === "attachments") {
            return;
        }

        return patchDraftEmail({ id, request: mapRequestFromFormData({ [property]: newValue }), userId })
            .unwrap()
            .then(
                _ => setValue(property, newValue)
            );
    };

    const onAddAttachments = (attachments) => {

        var formDataRequest = new FormData();
        attachments.forEach(file => {
            formDataRequest.append(file.name, file);
        });

        toast.promise(addAttachment(id, formDataRequest), {
            loading: "Your attachment is being uploaded",
            success: "Attachment uploaded!",
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                return `There was a problem uploading the attachment. ${display}`;
            }
        });
    };

    const onDeleteAttachmentClick = (attachment) => {
        deleteAttachment(id, attachment.id)
            .then(
                r => toast.custom((t) => <BasicToast
                    control={t}
                    date={r?.createdDate}
                    icon="fa-solid fa-comments-question-check"
                    title="Attachment Deleted"
                    message={`Your attachment was removed from the message.`}
                />),
                e => toast.error(e?.data?.title ?? e?.data ?? "An unknown error occurred attempting to remove the attachment.")
            );
    };

    const onDeleteClick = () => {
        deleteDraftEmail({ id, folderId, userId })
            .unwrap()
            .then(
                r => {
                    toast.custom((t) => <BasicToast
                        control={t}
                        date={r?.createdDate}
                        icon="fa-solid fa-comments-question-check"
                        title="Draft Deleted"
                        message={`Your draft message has been deleted!`}
                    />);
                    appNavigator("..");
                },
                e => toast.error(e?.data?.title ?? e?.data ?? "An unknown error occurred attempting to delete the draft.")
            )
    };

    const onSendClick = () => {
        toast.promise(sendDraftEmail({ id, folderId, userId }),  {
            loading: "Sending message...",
            success: () => {
                appNavigator("..");
                return "Message sent!"
            },
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                return `There was a problem sending the email. ${display}`;
            }
        });
    };

    return <div>
        <div className="row d-flex justify-content-end mb-4">
            <ButtonGroup className="col-auto">
                <Button disabled={isLoadingDelete || isDeleting} variant="danger" onClick={onDeleteClick}>
                    <ThemedSpan variant="light" className="me-2">Delete</ThemedSpan>
                    <FontAwesomeIcon icon="trash" />
                </Button>
                <Button disabled={isLoadingSend || isSending} onClick={onSendClick}>
                    <ThemedSpan variant="light" className="me-2">Send</ThemedSpan>
                    <FontAwesomeIcon icon="paper-plane" />
                </Button>
            </ButtonGroup>
        </div>
        <form>
            <DraftMailForm
                addAttachmentsFn={onAddAttachments}
                removeAttachmentFn={onDeleteAttachmentClick}
                setValue={handleValueChange}
                {...formProperties}
            />
        </form>
    </div>;
};

export default DraftEmail;
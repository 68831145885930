import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const assetPricesApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        createAssetPrice: build.mutation({
            query: ({ assetId, request }) => ({
                url: `api/assets/assets/${assetId}/prices`,
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ assetId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: price } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchPricesForGrid', { assetId }, ({ results, totalCount, ...rest }) => {
                        return {
                            results: [price, ...results],
                            totalCount: totalCount + 1,
                            ...rest
                        };
                    }));
                }
                catch { }
            }
        }),
        deleteAssetPrice: build.mutation({
            query: ({ assetId, priceDate }) => ({
                url: `api/assets/assets/${assetId}/prices/${priceDate}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ assetId, priceDate }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchPricesForGrid', { assetId }, ({ results, totalCount, ...rest }) => {
                    return {
                        results: results.filter(x => x.priceDate !== priceDate), // all prices have same asset ID in cache
                        totalCount: totalCount - 1,
                        ...rest
                    };
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        patchAssetPrice: build.mutation({
            query: ({ assetId, priceDate, operations }) => ({
                url: `api/assets/assets/${assetId}/prices/${priceDate}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ assetId, priceDate, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchPricesForGrid', { assetId }, ({ results }) => {
                    var item = results.find(x => x.priceDate === priceDate);
                    applyPatch(item, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useCreateAssetPriceMutation,
    useDeleteAssetPriceMutation,
    usePatchAssetPriceMutation
} = assetPricesApiEndpoints;
import React, { useState } from "react";
import ConnectionList from "./components/ConnectionList";
import LoadingBar from "./components/LoadingBar";
import LoadingError from "./components/LoadingError";
import NewConnectionHeader from "./components/NewConnectionHeader";
import NoConnectionsMessage from "./components/NoConnectionsMessage";
import useConnectionsList from "./hooks/useConnectionsList";
import { useClientContext } from "../../hooks/ClientContext";
import { ClientLayoutPageSubHeader, Main } from "../../components/utilities";

const ClientConnections = () => {
    const [activeKeys, setActiveKeys] = useState([]);
    const { id } = useClientContext();
    const {
        createConnection,
        data, 
        deleteConnection, 
        error, 
        isCreating,
        isError, 
        isLoading,
        reload,
        updateConnection
    } = useConnectionsList(id);
    
    const onCreateConnection = (e) =>  {
        createConnection(e).then(
            r => {
                const { connectionId } = r || {};
                setActiveKeys(_ => [ connectionId ]);
            }
        );
    };

    const onDeleteConnection = (connectionId) => {
        deleteConnection(connectionId).then(
            _ => setActiveKeys(keys => keys.filter(el => el.connectionId !== connectionId))
        );
    };

    if (isLoading === true) {
        return (
            <Main className="p-3">
                <LoadingBar />
            </Main>
        );
    }

    if (error || isError === true) {
        return <LoadingError error={error} retryCallback={reload} />
    }
    
    return (
        <React.Fragment>
            <ClientLayoutPageSubHeader>
                <NewConnectionHeader
                    onCreate={onCreateConnection}     
                    isButtonDisabled={isLoading === true || isCreating === true || isError === true}
                />
            </ClientLayoutPageSubHeader>
            <Main className="p-3">
                {(!data || data.length === 0) && (<NoConnectionsMessage />)}
                {data && data.length > 0 && (
                    <ConnectionList 
                        activeKey={activeKeys}
                        defaultActiveKey={null}
                        onActiveKeyChanged={(eventKeys, _) => setActiveKeys(eventKeys)}
                        connections={data}
                        onDelete={onDeleteConnection} 
                        onUpdate={updateConnection} 
                    />
                )}
            </Main>
        </React.Fragment>
    );
};

export default ClientConnections;
import { useClientContext } from "../../../hooks/ClientContext";
import {
    useGetProjectQuery,
    usePatchProjectMutation
} from "../../../services/clientprojects";

const useProjectDetails = (projectId) => {
    const { account: { masterAccountId } } = useClientContext();
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchProjectMutation();
    const { data, error, isError, isLoading, isFetching, isUninitialized, refetch } = useGetProjectQuery(
        { masterAccountId, projectId },
        { skip: !projectId }
    );
    
    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ projectId });
    };

    const updateProject = (_masterAccountId, projectId, property, value) => {
        return new Promise((resolve, reject) => {
            // TODO: passing in the project-id, so we can check the correct one gets the update?
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            return patch({ masterAccountId, projectId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateProjectOptions = (_masterAccountId, projectId, options) => {
        return new Promise((resolve, reject) => {
            if (!options || !Array.isArray(options) || options.length === 0) {
                reject("No operations have been selected.");
            }

            let operations = [];
            options.forEach(el => {
                operations.push({ op: 'replace', path: `/${el.property}`, value: el.value })
            });

            return patch({ masterAccountId, projectId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        data,
        error,
        isError,
        isErrorUpdating,
        isLoading: isLoading === true || isFetching === true,
        isUninitialized,
        isUpdating,
        reload,
        updateError,
        updateProject,
        updateProjectOptions
    };
};

export default useProjectDetails;
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Button from "../../components/buttons";
import FeedbackFilterList from "./components/FeedbackFilterList";
import EfficacyStatusSelect from "./components/EfficacyStatusSelect";
import { Modal } from "react-bootstrap";
import { useFilterContext } from "../../hooks/FilterContext";
import { DateInput } from "../../components/forms";
import { ThemedSpan } from "../../components/utilities";
import { 
    GenericAdviserSelect, 
    MediaTypeSelect,
    ReportCategoriesSelect,
    ReportProductAreasSelect,
    ReportStatusSelect, 
    ReportTypesSelect, 
    GenericUserSelect,
    ServiceAgreementsSelect
} from "../../components/forms/selects";

export const ReportManagementFiltersModalButton = ({ className, variant = "primary", ...rest }) => {
    return <div className={className}>
        <Button variant={variant} {...rest}>Filter Reports</Button>
    </div>
};

export const ReportManagementFiltersModal = ({ handleClose, onFiltersChanged, show, ...rest }) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(_ => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof(handleClose) === 'function') {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    return <Modal size="lg" centered backdrop="static" show={show}>
        <Modal.Header>Report Management Filters</Modal.Header>
        <Modal.Body {...rest}>
            <ReportManagementFilters 
                filter={tempFilters}
                setFilter={onFilterValueChanged}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
            <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
};

const ToColumn = () => {
    return <div className="col-1 d-flex align-items-end">
        <ThemedSpan variant="primary">to</ThemedSpan>
    </div>
};

const ReportManagementFilters = ({ filter, setFilter }) => {
    const { 
        adviserIds,
        authorAssignedEndDate,
        authorAssignedStartDate,        
        authorUserIds,
        complianceUserIds,
        efficacy,
        mediaTypes,
        passedComplianceEndDate,
        passedComplianceStartDate,
        reportCategories,
        reportFeedbackFilters,
        reportProductAreas,
        reportStatuses, 
        reportTypes,
        sentToClientEndDate,
        sentToClientStartDate,
        serviceTermIds
    } = filter;

    return <React.Fragment>
        <div className="row mb-3">
            <div className="col">
                <ReportStatusSelect
                    label="Report Status"
                    isMulti={true}
                    defaultValue={reportStatuses}
                    disableAnimations={true}
                    onBlur={(values) => setFilter('reportStatuses', values.map(el => el.value))}
                />
            </div>
            <div className="col-3">
                <EfficacyStatusSelect
                    label="Efficacy"
                    isMulti={true}
                    defaultValue={efficacy}
                    disableAnimations={true}
                    onBlur={(values) => setFilter('efficacy', values.map(el => el.value))}
                />
            </div>
            <div className="col">
                <GenericAdviserSelect 
                    label="Adviser"
                    isMulti={true}
                    defaultValue={adviserIds}
                    disableAnimations={true}
                    onBlur={(values) => setFilter('adviserIds', values.map(el => el.value))}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="mb-3">
                    <ReportTypesSelect 
                        label="Report Type"
                        isMulti={true}
                        defaultValue={reportTypes}
                        disableAnimations={true}
                        onBlur={(values) => setFilter('reportTypes', values.map(el => el.value))}
                    />
                </div>
                <div className="mb-3">
                    <GenericUserSelect
                        label="Report Author"
                        isMulti={true}
                        defaultValue={authorUserIds}
                        disableAnimations={true}
                        onBlur={(values) => setFilter('authorUserIds', values.map(el => el.value))}
                    />
                </div>
                <div className="mb-3">
                    <GenericUserSelect
                        label="Compliance"
                        isMulti={true}
                        defaultValue={complianceUserIds}
                        disableAnimations={true}
                        onBlur={(values) => setFilter('complianceUserIds', values.map(el => el.value))}
                    />
                </div>
                <div className="mb-3">
                    <MediaTypeSelect
                        label="Media"
                        isMulti={true}
                        defaultValue={mediaTypes}
                        disableAnimations={true}
                        onBlur={(values) => setFilter('mediaTypes', values.map(el => el.value))}
                    />
                </div>
                <div className="mb-3">
                    <ReportCategoriesSelect
                        label="Categories"
                        isMulti={true}
                        defaultValue={reportCategories}
                        disableAnimations={true}
                        onBlur={(values) => setFilter('reportCategories', values.map(el => el.value))}
                    />
                </div>
            </div>
            <div className="col">
                <div className="mb-3">
                    <ServiceAgreementsSelect
                        label="Service Terms"
                        isMulti={true}
                        defaultValue={serviceTermIds}
                        disableAnimations={true}
                        onBlur={(values) => setFilter('serviceTermIds', values.map(el => el.value))}
                    />
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <DateInput
                            label="Date Author Assigned"
                            placeholder="Start Date"
                            isClearable
                            disableAnimations={true}
                            value={authorAssignedStartDate}
                            onBlur={(value, _) => setFilter('authorAssignedStartDate', value)}
                        />
                    </div>
                    <ToColumn />
                    <div className="col d-flex align-items-end">
                        <DateInput
                            label={null}
                            placeholder="End Date"
                            isClearable
                            disableAnimations={true}
                            value={authorAssignedEndDate}                          
                            onBlur={(value, _) => setFilter('authorAssignedEndDate', value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <DateInput
                            label="Date Passed Compliance"
                            placeholder="Start Date"
                            isClearable
                            disableAnimations={true}
                            value={passedComplianceStartDate}
                            onBlur={(value, _) => setFilter('passedComplianceStartDate', value)}
                        />
                    </div>
                    <ToColumn />
                    <div className="col d-flex align-items-end">
                        <DateInput
                            label={null}
                            placeholder="End Date"
                            isClearable
                            disableAnimations={true}
                            value={passedComplianceEndDate}
                            onBlur={(value, _) => setFilter('passedComplianceEndDate', value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <DateInput
                            label="Date Sent to Client"
                            placeholder="Start Date"
                            isClearable
                            disableAnimations={true}
                            value={sentToClientStartDate}
                            onBlur={(value, _) => setFilter('sentToClientStartDate', value)}
                        />
                    </div>
                    <ToColumn />
                    <div className="col d-flex align-items-end">
                        <DateInput
                            label={null}
                            placeholder="End Date"
                            isClearable
                            disableAnimations={true}
                            value={sentToClientEndDate}
                            onChange={(value, _) => setFilter('sentToClientEndDate', value)}
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <ReportProductAreasSelect
                        label="Product Areas"
                        isMulti={true}
                        defaultValue={reportProductAreas}
                        disableAnimations={true}
                        onBlur={(values) => setFilter('reportProductAreas', values.map(el => el.value))}
                    />
                </div>
            </div>
        </div>
        <FeedbackFilterList 
            reportFeedbackFilters={reportFeedbackFilters}
            setFilter={setFilter}
        />
    </React.Fragment>
};

export default ReportManagementFilters;
import React from "react";
import styled from "styled-components";

const SplashContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
`;

const SplashText = styled.h1`
    margin: 0;
    font-size: 3.4rem;
    text-align: center;
    background: linear-gradient(to right, #FFF 20%, ${props => props.theme?.primary ?? '#0c5da5'} 40%, ${props => props.theme?.primary ?? '#0c5da5'} 60%, #FFF 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 2s linear infinite;
`;

const SplashPage = () => {
    return (
        <SplashContainer>
            <SplashText>WealthPro</SplashText>
        </SplashContainer>
    );
};

export default SplashPage;
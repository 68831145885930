import { useState } from "react";
import FormSelectPaged from "./FormSelectPaged";
import { useLazySearchHeadOfficeMasterAccountsQuery } from "../../../services/headofficeadmin";

const HeadOfficeMasterAccountFormSelect = ({ isMulti, label, onChange, value }) => {

    return <FormSelectPaged
        setValue={onChange}
        cacheOptions={true}
        label={label}
        isMulti={isMulti}
        value={value}
        query={useLazySearchHeadOfficeMasterAccountsQuery}
    />;
};

export default HeadOfficeMasterAccountFormSelect;
import DelegatesGridTableColumn from "./DelegatesGridTableColumn";
import { ThemedIcon } from "../../../components/utilities";

const DelegatesGridTableColumnBoolean = ({ value }) => (
    <DelegatesGridTableColumn className="text-center" width={6}>
        <ThemedIcon 
            size="lg"
            icon={value === true ? "fa-check-circle" : "fa-times-circle"}
            variant={value === true ? 'success' : 'danger'} 
        />
    </DelegatesGridTableColumn>
);

export default DelegatesGridTableColumnBoolean;
import React, { useEffect } from "react";
import styled from "styled-components";
import PersonalEmailFolders from "../features/personalemailfolders";
import { Main } from "../components/utilities";
import PageHeader, { PageHeaderTop } from "../components/layout/PageHeader";
import { PageHeaderTitle } from "../components/utilities/PageHeader";
import { darken } from "polished";
import { Outlet } from "react-router-dom";
import { usePersonalOutlookContext } from "../hooks/PersonalOutlookContext";

const PersonalEmailFoldersSection = styled.section`
    background-color: ${(props) => darken(0.05, props.theme.body)};
`;

const PersonalEmailLayout = () => {
    const { getMailDelegateRights, selectedMailUserId } = usePersonalOutlookContext();

    useEffect(() => {
        getMailDelegateRights({ userId: selectedMailUserId });
    }, [selectedMailUserId]);

    return (
        <React.Fragment>
            <PageHeader>
                <PageHeaderTop>
                    <PageHeaderTitle>
                        <span>Emails Management</span>
                    </PageHeaderTitle>
                </PageHeaderTop>
            </PageHeader>
            <Main className="row g-0 border-bottom">
                <PersonalEmailFoldersSection className="col-2">
                    <PersonalEmailFolders />
                </PersonalEmailFoldersSection>
                <Outlet />
            </Main>
        </React.Fragment>
    );
};

export default PersonalEmailLayout;

import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { FormCheck, FormLabel, RichTextEditor } from "../../../components/forms";
import { InfoBubble } from "../../../components/forms/richtexteditor/FormInfoBubbleWrapper";
import { Spinner } from "../../../components/loaders";
import { ThemedSpan } from "../../../components/utilities";
import useFundDetails from "../hooks/useFundDetails";

const CommentaryInput = ({
    className,
    label,
    value,
    commentaryType,
    statusDisplayText,
    dateDisplayText,
    isApproving,
    isPublishing,
    checkbox = false,
    enabled = true,
    readOnly = false,
    ...rest }) => {
    const [{ patchAssetGroupSingle, patchCommentary }, { assetGroupIsNotReady, commentaryTypeUpdating }] = useFundDetails();

    const enabledProperty = useMemo(() =>
        `${commentaryType}Enabled`,
        [commentaryType]);

    const shouldShowStatusText = useMemo(() =>
        checkbox ? enabled : true,
        [checkbox, enabled]);

    const shouldShowLoadingSpinner = useMemo(() =>
        assetGroupIsNotReady || (commentaryTypeUpdating === commentaryType) || isApproving || isPublishing,
        [commentaryType, commentaryTypeUpdating, assetGroupIsNotReady, isApproving, isPublishing])

    return <div className={`${className} mt-4`}>
        <GridWrapper gridTemplateColumns={"50% 1fr auto auto"}>
            <div className="d-flex">
                <FormLabel className={"ps-2 mt-auto"}>
                    {label}
                </FormLabel>
                {checkbox && <span className="pb-1 ps-2">
                    <FormCheck
                        isChecked={enabled ?? false}
                        onChange={(checked) => patchAssetGroupSingle(enabledProperty, checked)}
                    />
                </span>}
            </div>

            {shouldShowStatusText && <ThemedSpan variant="muted">
                {shouldShowLoadingSpinner
                    ? <Spinner className="mx-5 mb-1" />
                    : dateDisplayText}
            </ThemedSpan>}

            {shouldShowStatusText && <ThemedSpan className="mx-5" variant="muted">
                {shouldShowLoadingSpinner
                    ? <Spinner className="ms-5" />
                    : statusDisplayText}
            </ThemedSpan>}

            <span className="col-start-4 ms-auto d-flex">
                <InfoBubble
                    iconClassName={"ms-2"}
                    fieldKey={commentaryType}
                    showBubble
                />
            </span>
        </GridWrapper>

        {assetGroupIsNotReady
            ? <Skeleton containerClassName="row pt-3 px-3" height={100} />
            : <RichTextEditor
                defaultValue={value}
                onBlur={(text) => patchCommentary(commentaryType, text)}
                disabled={readOnly || !enabled}
                height="100%"
                {...rest}
            />}
    </div>
}

export default CommentaryInput;
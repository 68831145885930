import _ from "lodash";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";

const NavigationRouteGuard = ({ children, unauthorisedUrl = "/unauthorised", requiredRoles = [] }) => {
    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;

    if (requiredRoles.length > 0 && !_.some(roles, item => requiredRoles.includes(item))) {
        return <Navigate to={`${unauthorisedUrl}?redirect=${encodeURIComponent(window.location.href)}`} replace={true} />
    }

    return children;
};

export default NavigationRouteGuard;
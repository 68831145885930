import { FormSelect } from "../../../components/forms";

const ServiceAuthorisationTypesSelect = ({ options, ...rest }) => {
    return (
        <FormSelect 
            options={[
                { label: 'Physical Signature', value: 0 },
                { label: 'Electronic Signature', value: 1 }
            ]}
            {...rest}
        />
    );
};

export default ServiceAuthorisationTypesSelect;
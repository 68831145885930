import { useState } from "react";
import { components } from "react-select";

const OpenModalMultiValueContainer = ({ ModalComponent, modalProps, onHide, ...rest }) => {
    const [modalShown, setModalShown] = useState(false);

    const openModal = () => setModalShown(true);
    const closeModal = () => {
        setModalShown(false);
        onHide();
    }

    return <>
        <ModalComponent
            show={modalShown}
            onHide={closeModal}
            {...modalProps}
        />
        <span className="has-pointer" onClick={openModal}>
            <components.MultiValueContainer {...rest} />
        </span>
    </>;
};

export default OpenModalMultiValueContainer;
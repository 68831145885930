import { useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useUploadAmlEvidenceMutation } from "../../../services/clientfactfind";

const useAntiMoneyLaunderingEvidence = () => {
    const { id: masterAccountId } = useClientContext();

    const [createEvidence, createResult] = useUploadAmlEvidenceMutation();

    const defaultValues = {
        clientAccountId: null,
        documentType: null,
        expiryDate: null,
        fileName: "No file selected",
        evidence: null
    }

    // Allow user to select existing document
    const [isUsingExisting, setIsUsingExisting] = useState(false);

    const uploadEvidence = (data, includeEvidence) => {
        const request = convertToFormData({
            ...data,
            evidence: includeEvidence ? data.evidence : null
        });
        return new Promise((resolve, reject) => {
            return createEvidence({ masterAccountId, request })
                .unwrap()
                .then(resolve, reject);
        });
    }

    const convertToFormData = (data) => {
        const formData = new FormData();
        const { clientAccountId, documentType, expiryDate, evidence, fileName } = data;

        formData.append("clientAccountId", clientAccountId);
        formData.append("documentType", documentType);
        if (expiryDate != null)
            formData.append("expiryDate", expiryDate);

        if (evidence == null)
            return formData;

        if (isUsingExisting) {
            formData.append("documentId", evidence.documentId);
            return formData;
        }

        const fileExt = evidence.name.split('.')[1];

        formData.append(`${fileName}.${fileExt}`, evidence, `${fileName}.${fileExt}`);

        return formData;
    };

    return {
        createResult,
        defaultValues,
        uploadEvidence,
        isUsingExisting,
        setIsUsingExisting
    };
}

export default useAntiMoneyLaunderingEvidence;
import React from "react";
import styled from "styled-components";
import withProviders from "../hooks/withProviders";
import { AdministrationCustodianRouteTabs } from "../routes/administration.custodian.routes";
import { FilterProvider } from "../hooks/FilterContext";
import { CustodianAccountMatchingDefaultFilters } from "../features/custodianaccountmatching";
import { readableColor } from "polished";
import { Outlet } from "react-router-dom";

const Header = styled.section`
    grid-area: pageheader;
    position: sticky;
    top: var(--navbar-size);
    z-index: 5;
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

const HeaderBottom = styled.div``;

const HeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

const HeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const AdministrationCustodianLayout = () => (
    <React.Fragment>
        <Header>
            <HeaderTop>
                <HeaderTitle>
                    <span>Custodian Management</span>
                </HeaderTitle>
            </HeaderTop>
            <HeaderBottom>
                <AdministrationCustodianRouteTabs />
            </HeaderBottom>
        </Header>
        <Outlet />
    </React.Fragment>
);

export default withProviders([FilterProvider, { defaultFilters: CustodianAccountMatchingDefaultFilters }])(AdministrationCustodianLayout);
import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { variantToColour } from "../../helpers/theme";

export const StyledLabel = styled.label`
    line-height: normal;
    color: ${props => variantToColour({ theme: props.theme, variant: props.variant ? props.variant : 'primary' })};
`;

const FormLabel = ({ as, children, className, horizontal, variant = "primary", ...rest }) => {
    
    const getClassName = () => {
        return horizontal === true
            ? className ? `${className} col-sm-2 col-form-label text-nowrap` : 'col-sm-2 col-form-label text-nowrap'
            : className ? `${className} text-nowrap` : 'text-nowrap'
    };
    
    // handles a custom label that is not a standard string
    // NOTE: this probably wont get used much but it's worth handling
    if (typeof (children) === 'object') {
        return <div className={getClassName()} {...rest}>
            {children}
        </div>
    }

    return <Form.Label 
        as={StyledLabel} 
        variant={variant}
        className={getClassName()}
        {...rest}
    >
        {children}
    </Form.Label>
}

export default FormLabel;
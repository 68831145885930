import { patchReplace } from "../../../helpers/patchDoc";
import {
    useFetchClientReviewQuery,
    usePatchClientReviewMutation,
    useRearrangeClientAppointmentMutation,
    useUploadDrawDownEvidenceMutation
} from "../../../services/clientreviews";

const useClientReview = (reviewId) => {

    const statusTypes = {
        Appointment: 0,
        Billing: 1,
        FollowUpReport: 2,
        MeetingNote: 3,
        PreReviewDocument: 4,
        Report: 5,
        Review: 6,
        ServiceLevelAgreement: 7,
        Verification: 8
    };

    const{
        data: review,
        isLoading: loadingReview,
        isFetching: fetchingReview,
        isError: errorFetchingReview
    } = useFetchClientReviewQuery({reviewId: reviewId});

    const [patch] = usePatchClientReviewMutation();
    const [rearrange] = useRearrangeClientAppointmentMutation();
    const [uploadEvidence, {isLoading : isUploadingDocument}] = useUploadDrawDownEvidenceMutation();

    const patchService = (property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            return patch({ reviewId: reviewId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const rearrangeAppointment = () => {
        return new Promise((resolve, reject) => {
            return rearrange({reviewId: reviewId}).unwrap().then(
                r => {
                    resolve(r);
                },
                e => reject(e)
            );
        });
    };

    const uploadDrawDownEvidence = (evidence) => {
        const request = new FormData();
        evidence?.forEach((file, index) => request.append(file.name, file, file.name));

        return new Promise((resolve, reject) => {
            return uploadEvidence({ request, reviewId : reviewId })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const select = (report, reportType, statusProperty = "StatusId") => {
        return new Promise((resolve, reject) => {
            const operations = [
                patchReplace(`${reportType}${statusProperty}`, report.statusId),
                patchReplace(`${reportType}AuthorId`, report.authorId),
                patchReplace(`${reportType}AuthorAssignedDate`, report.authorAssignedDate),
                patchReplace(`${reportType}CompliantDate`, report.compliantDate),
                patchReplace(`${reportType}SentDate`, report.sentDate),
                patchReplace(`${reportType}ReportId`, report.id),
                patchReplace(`${reportType}GraphId`, report.documentId)
            ];

            return patch({ reviewId, operations })
                .then(resolve, reject);
        })
    }

    return {
        review,
        loadingReview,
        fetchingReview,
        errorFetchingReview,
        isUploadingDocument,
        patchService,
        rearrangeAppointment,
        uploadDrawDownEvidence,
        selectPreReviewDocument: (report) => select(report, "preReviewDocument"),
        selectFollowUpReport: (report) => select(report, "followUp", "ReportStatusId")
    };
}

export default useClientReview;
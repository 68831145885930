import React, { useEffect, useMemo, useState } from "react";
import { Alert, FormLabel } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from 'react-number-format';
import { GridWrapper } from "../../../components";
import { CurrencyInput, FormSelect, PercentageInput } from "../../../components/forms";
import { patchReplace } from "../../../helpers/patchDoc";
import { useInstruction } from "../contexts/InstructionContext";
import useFeeCollection from "../hooks/useFeeCollection";
import { InvestDivestErrorMessage } from "./ErrorMessages";

const FeeCollection = () => {
    const [, { realTimeInstruction, invest, investIsLoading, investIsError }] = useInstruction();

    const {
        adviceFeeType,
        serviceFeeType,
        adviceFeeOptions,
        serviceFeeOptions,
        options,
        objects,
        paymentMethodsIsReady,
        paymentMethodsIsLoading,
        paymentMethodsIsError,
        retryOptions,
        retryInvest,
        patchInvest,
        bulkPatchInvest,
        realTimePatchInvest,
        realTimePatchInvestSingle,
        setAdviceFeeType,
        setServiceFeeType
    } = useFeeCollection();

    const notApplicablePaymentMethodId = useMemo(() =>
        objects?.find(({ methodEnum }) => methodEnum === 3)?.id,
        [objects]);

    const [displayAdviceFeePercent, setDisplayAdviceFeePercent] = useState(realTimeInstruction?.invest?.adviceFeePercent);
    const [displayServiceFeePercent, setDisplayServiceFeePercent] = useState(realTimeInstruction?.invest?.serviceFeePercent);

    const [displayPaymentMethodId, setDisplayPaymentMethodId] = useState(realTimeInstruction?.invest?.paymentMethodId);

    useEffect(() => {
        setDisplayPaymentMethodId(invest?.paymentMethodId);
    }, [invest?.paymentMethodId])

    if (investIsError)
        return <InvestDivestErrorMessage retry={retryInvest} />

    return <GridWrapper className="pt-3" gridTemplateColumns={"minmax(auto, 35%) minmax(auto, 25%) minmax(auto, 40%)"}>
        <GridWrapper gridTemplateColumns={"minmax(auto, 55%) minmax(auto, 20%) minmax(auto, 25%)"}>
            {investIsLoading
                ? <Skeleton />
                : <FormSelect
                    defaultValue={invest?.adviceFeeType}
                    onChange={(selection) => {
                        setAdviceFeeType(selection.value);
                        if (selection.value === 2) { // No Advice Fee
                            let operations = [
                                patchReplace("adviceFeePercent", 0),
                                patchReplace("adviceFeeAmount", 0),
                                patchReplace("paymentMethodId", notApplicablePaymentMethodId)
                            ];
                            realTimePatchInvest(operations);

                            setDisplayPaymentMethodId(notApplicablePaymentMethodId);
                        }
                    }}
                    onBlur={(selection) =>
                        new Promise((resolve, reject) => {
                            let operations = [patchReplace("adviceFeeType", selection.value)];

                            if (selection.value === 2) { // No Advice Fee
                                operations = [...operations, patchReplace("adviceFeePercent", 0), patchReplace("adviceFeeAmount", 0),
                                patchReplace("paymentMethodId", notApplicablePaymentMethodId)];
                            }

                            return bulkPatchInvest(operations)
                                .then(resolve, reject);
                        })}
                    options={adviceFeeOptions ?? []}
                />}
            {investIsLoading
                ? <Skeleton />
                : <PercentageInput
                    value={displayAdviceFeePercent ?? 0}
                    readOnly={adviceFeeType !== 1}
                    onChange={(_, { floatValue }) => {
                        setDisplayAdviceFeePercent(floatValue ?? 0);
                        realTimePatchInvestSingle("adviceFeePercent", Math.min(floatValue ?? 0, 100))
                    }}
                    onBlur={(value) => {
                        const newPercent = Math.min(value ?? 0, 100)
                        setDisplayAdviceFeePercent(newPercent)
                        return patchInvest("adviceFeePercent", newPercent)
                    }}
                    disableAnimations={invest?.adviceFeePercent === 100 && realTimeInstruction?.invest?.adviceFeePercent >= 100}
                />}
            {investIsLoading
                ? <Skeleton />
                : <CurrencyInput
                    allowNegative={false}
                    value={realTimeInstruction?.invest?.adviceFeeAmount ?? 0}
                    readOnly={adviceFeeType !== 0}
                    onChange={(_, { floatValue }) => realTimePatchInvestSingle("adviceFeeAmount", floatValue)}
                    onBlur={(value) => patchInvest("adviceFeeAmount", value)}
                />}

            {investIsLoading
                ? <Skeleton />
                : <FormSelect
                    className={"col-start-1"}
                    defaultValue={invest?.serviceFeeType}
                    onChange={(selection) => {
                        setServiceFeeType(selection.value);
                        if (selection.value === 2) { // No Service Fee
                            let operations = [
                                patchReplace("serviceFeePercent", 0),
                                patchReplace("serviceFeeAmount", 0)
                            ];

                            realTimePatchInvest(operations);
                        }
                    }}
                    onBlur={(selection) => new Promise((resolve, reject) => {
                        let operations = [patchReplace("serviceFeeType", selection.value)];

                        if (selection.value === 2) { // No Service Fee
                            if (invest?.serviceFeeAmount !== 0)
                                operations.push(patchReplace("serviceFeeAmount", 0));
                            if (invest?.serviceFeePercent !== 0)
                                operations.push(patchReplace("serviceFeePercent", 0));
                        }

                        return bulkPatchInvest(operations)
                            .then(resolve, reject);
                    })}
                    options={serviceFeeOptions ?? []}
                />}
            {investIsLoading
                ? <Skeleton />
                : <PercentageInput
                    value={displayServiceFeePercent}
                    readOnly={serviceFeeType !== 1}
                    onChange={(_, { floatValue }) => {
                        setDisplayServiceFeePercent(floatValue ?? 0);
                        realTimePatchInvestSingle("serviceFeePercent", Math.min(floatValue ?? 0, 100))
                    }}
                    onBlur={(value) => {
                        const newPercent = Math.min(value ?? 0, 100)
                        setDisplayServiceFeePercent(newPercent)
                        return patchInvest("serviceFeePercent", newPercent)
                    }}
                    disableAnimations={invest?.serviceFeePercent === 100 && realTimeInstruction?.invest?.serviceFeePercent >= 100}
                />}

            {investIsLoading
                ? <Skeleton />
                : <CurrencyInput
                    allowNegative={false}
                    value={realTimeInstruction?.invest?.serviceFeeAmount ?? 0}
                    readOnly={serviceFeeType !== 0}
                    onChange={(_, { floatValue }) => realTimePatchInvestSingle("serviceFeeAmount", floatValue)}
                    onBlur={(value) => patchInvest("serviceFeeAmount", value)}
                />}
        </GridWrapper>

        {(paymentMethodsIsReady && !investIsLoading)
            ? <FormSelect
                options={options ?? []}
                defaultValue={displayPaymentMethodId}
                onChange={(selection) => realTimePatchInvestSingle("paymentMethodId", selection.value)}
                onBlur={(selection) => patchInvest("paymentMethodId", selection.value)}
                isDisabled={adviceFeeType === 2}
            />
            : (paymentMethodsIsLoading || investIsLoading)
                ? <Skeleton />
                : paymentMethodsIsError &&
                <Alert variant="danger">
                    Error loading options, click <Alert.Link onClick={retryOptions}>here</Alert.Link> to try again
                </Alert>}

        <GridWrapper className="col-start-3 mb-auto" gridTemplateColumns={"repeat(2, minmax(auto, 50%))"}>
            <FormLabel className="col-start-1 mt-1 mx-auto">
                One-Off Costs: {investIsLoading
                    ? <Skeleton />
                    : <NumericFormat
                        prefix="£"
                        value={invest?.oneOffCosts ?? 0}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        allowNegative={false}
                        displayType="text"
                    />}
            </FormLabel>
            <FormLabel className="col-start-2 mt-1">
                Total Set Up Costs: {investIsLoading
                    ? <Skeleton />
                    : <NumericFormat
                        prefix="£"
                        value={realTimeInstruction?.invest?.adviceFeeAmount ?? 0 + invest?.oneOffCosts ?? 0}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        allowNegative={false}
                        displayType="text"
                    />}
            </FormLabel>
        </GridWrapper>
    </GridWrapper>;
};

export default FeeCollection;

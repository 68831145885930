import React, { useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { NumericFormat } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemedIcon, ThemedSpan } from "../utilities";
import { FixedSizeList } from "react-window";
import { usePageScrollPosition } from "../../hooks/PageScrollPositionContext";

/** Context for cross component communication */
const VirtualTableContext = React.createContext({
    top: 0,
    tableClass: '',
    header: <></>,
    footer: <></>
});

/** The virtual table. It basically accepts all of the same params as the original FixedSizeList. */
const VirtualTable = ({ row, header, footer, enableScrollTracking = false, error, tableClass, parentClass, isLoading, itemCount, itemSize = 42, isItemLoaded, loadMoreItems, overscanCount = 0, ...rest }) => {
    const listRef = useRef();
    const [top, setTop] = useState(0);
    const { currentPosition, setPosition } = usePageScrollPosition();
    const { rowIndex, colIndex } = currentPosition || { rowIndex: 0, colIndex: 0 };

    const handleOnItemsRendered = ({ overscanStartIndex, visibleStartIndex, ...rest }, callback) => {
        // check if we are tracking the scroll position before we continue
        if (enableScrollTracking === true) {
            setPosition({ rowIndex: visibleStartIndex, colIndex: 0 });
        }

        // make sure we render the top correctly, this will prevent some graphical glitches with scrolling
        const style = listRef.current && listRef.current._getItemStyle(overscanStartIndex);
        setTop((style && style.top) || 0);

        // call the original callback
        callback && callback({ overscanStartIndex, visibleStartIndex, ...rest });
    };

    return (
        <VirtualTableContext.Provider value={{ top, tableClass, parentClass, header, footer, isLoading, error }}>
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
            >
                {({ onItemsRendered, ref }) => (
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList
                                {...rest}
                                ref={el => (listRef.current = el)}
                                height={height}
                                width={width}
                                itemSize={itemSize}
                                itemCount={itemCount}
                                innerElementType={Inner}
                                initialScrollOffset={enableScrollTracking === true
                                    ? (itemSize * rowIndex)
                                    : 0
                                }
                                onItemsRendered={(props) => handleOnItemsRendered(props, onItemsRendered)}
                                overscanCount={overscanCount}
                            >
                                {row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </VirtualTableContext.Provider>
    )
};

/**
 * Component that displays a default/basic message to indicate that the table fetch
 * did not return any results. This means we can stop showing a basic single header
 * when no results are brought back.
 * @returns 
 */
export const TableEmptyRow = () => {
    return (
        <tr>
            <td colSpan="100%">
                <p className="m-0 text-center">
                    <FontAwesomeIcon icon="fas fa-box-open" size="4x" className="text-muted my-3" />
                    <br />
                    <span className="mx-1 text-muted">No results found.</span>
                </p>
            </td>
        </tr>
    );
};

/**
 * Component that displays the error message in the event the table fetch api call
 * returns an error that we can catch. Just gives a triangle and tries to show
 * the message embedded in the error response.
 * @param {*} param0 
 * @returns 
 */
export const TableErrorRow = ({ error }) => {
    const errMessage = error?.message ?? "An unknown error has occured. Could not get your results."
    return (
        <tr>
            <td colSpan="100%">
                <ThemedIcon variant="danger" icon="fa-exclamation-triangle" size="xl" /> <ThemedSpan variant="danger" className="mx-1">{errMessage}</ThemedSpan>
            </td>
        </tr>
    )
};

/**
 * Component that displays the loading skeleton to show that the table
 * is currently loading instead of displaying nothing when no data
 * is available.
 * @returns 
 */
export const TableLoadingRow = ({ style = {} }) => {
    return (
        <tr>
            <td colSpan="100%" style={{ ...style, zIndex: 0 }}>
                <Skeleton count={1} height={32} style={{ zIndex: 0 }} />
            </td>
        </tr>
    );
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const TableRecordCountDisplayFooter = ({ 
    className = "sticky-footer", 
    totalCount, 
    isNewRecordsDisplayed, 
    newRecordsSinceCache, 
    reload = null,
    spanLeft = 6,
    spanRight = 5 
}) => (
    <tfoot className={classNames(className)}>
        <tr>
            <td colSpan={spanLeft}>
                {totalCount > 1 
                    ? (<span><NumericFormat displayType="text" value={totalCount} thousandSeparator="," /> records found.</span>)
                    : totalCount === 0 || !totalCount
                        ? (<span>No records found.</span>)
                        : (<span><NumericFormat displayType="text" value={totalCount} thousandSeparator="," /> record found.</span>)
                }
            </td>
            <td colSpan={spanRight} className="text-end">
                {isNewRecordsDisplayed && (
                    newRecordsSinceCache > 1
                        ? (<ThemedSpan variant="danger"><NumericFormat displayType="text" value={newRecordsSinceCache} thousandSeparator="," /> new records available.</ThemedSpan>)
                        : (<ThemedSpan variant="danger"><NumericFormat displayType="text" value={newRecordsSinceCache} thousandSeparator="," /> new record available.</ThemedSpan>)
                )}
                {reload && (<ThemedIcon variant={isNewRecordsDisplayed ? 'danger' : 'muted'} icon="fas fa-sync" className="has-pointer mx-2" onClick={() => reload()} />)} 
            </td>
        </tr>
    </tfoot>
);

/**
 * The Inner component of the virtual list. This is the "Magic".
 * Capture what would have been the top elements position and apply it to the table.
 * Other than that, render an optional header and footer.
 * `table table-wp3 ${tableClass ?? ''}`
 **/
const Inner = React.forwardRef(({ children, style }, ref) => {
    const { tableClass, parentClass, header, footer, top, isLoading, error } = React.useContext(VirtualTableContext);
    return (
        <div ref={ref} className={classNames('table-wp-parent', parentClass)} style={style}>
            <table className={classNames(`table table-wp3`, tableClass)} style={{ top: 0, position: 'sticky', width: '100%' }}>
                {header}
                <tbody>
                    {!isLoading && error && (<TableErrorRow error={error} />)}
                    {(!children || (children && Array.isArray(children) && children.length === 0)) && !error && !isLoading && <TableEmptyRow />}
                    {children}
                    {isLoading && (
                        <React.Fragment>
                            <TableLoadingRow />
                            <TableLoadingRow />
                            <TableLoadingRow />
                        </React.Fragment>
                    )}
                </tbody>
                {footer}
            </table>
        </div>
    )
});

export default VirtualTable;
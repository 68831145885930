import useEditFeeTransaction from "../hooks/useEditFeeTransaction";
import TransactionFeeModal from "../../administrationfees/components/TransactionFeeModal";
import { useEffect } from "react";

const EditTransactionFeeModalWrapper = ({ group, show, handleClose, feeTransactionId }) => {

    const {
        feeTransactionDefaultValues,
        feeTransactionAllocationDefaultValues,
        isLoading,
        isError,
        error,
        transactionTypes,
        isLoadingTransactionTypes,
        retryGetFeeTransaction,
        updateFeeTransaction
    } = useEditFeeTransaction(feeTransactionId);

    useEffect(() => console.log(feeTransactionId), [feeTransactionId])

    if(!feeTransactionId) return null;

    return <TransactionFeeModal
        group={group}
        show={show}
        handleClose={handleClose}
        onSubmitFn={updateFeeTransaction}
        onSubmitLabel={"Update Fee"}
        defaultAllocationsError={error}
        isDefaultAllocationsError={isError}
        feeTransactionDefaultValues={feeTransactionDefaultValues}
        feeTransactionAllocationDefaultValues={feeTransactionAllocationDefaultValues}
        transactionTypes={transactionTypes}
        isLoadingTransactionTypes={isLoadingTransactionTypes}
        isLoadingDefaultAllocations={isLoading}
        retryFetchDefaultAllocations={retryGetFeeTransaction}
    />;

};

export default EditTransactionFeeModalWrapper;
import React, { useMemo } from "react";
import { ErrorMessage } from "../../../components/messages";
import { StandardTable } from "../../../components/tables";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import Skeleton from "react-loading-skeleton";
import TransactionPotentialMatchesGridRow from "./TransactionPotentialMatchesGridRow";
import { Card } from "react-bootstrap";
import { CardBody, CardHeader } from "../../../components/utilities/Card";
import styled from "styled-components";

const StyledCard = styled(Card)`
    height: 40%;
`;

const TransactionPotentialMatchesGridErrorRow = ({ columns = [], error, retryFn }) => {

    return <tr>
        <td colSpan={columns.length}>
            <ErrorMessage error={error} retryCallback={retryFn} />
        </td>
    </tr>
};

const TransactionPotentialMatchesGridLoadingRow = ({ columns = [], error, retryFn }) => {

    return <tr>
        <td colSpan={columns.length}>
            <Skeleton height={24} />
        </td>
    </tr>
};

const TransactionPotentialMatchesGrid = () => {

    const { exception, fetchMatchesResult, retryFetchMatches, isFetching: isFetchingException } = useCustodianTransactionMatching();
    const { data, isFetching, error, isUninitialized } = fetchMatchesResult;

    const columns = useMemo(() => [
        {
            label: "Deal Date"
        },
        {
            label: "Operation"
        },
        {
            label: "Fund"
        },
        {
            label: "Client"
        },
        {
            label: "Units"
        },
        {
            label: "Value"
        },
        {
            label: "Best Score"
        },
        {
            label: "Match Type"
        },
    ], []);

    if (isUninitialized || !exception) {
        return;
    }

    return (
        <StyledCard>
            <CardHeader>Potential Matches</CardHeader>
            <CardBody>
                <StandardTable
                    parentClassName="h-100 mt-1"
                    columns={columns}
                    data={data}
                    error={error}
                    isLoading={isFetching || isFetchingException}
                    ErrorRowComponent={TransactionPotentialMatchesGridErrorRow}
                    errorRowComponentProps={{ retryFn: retryFetchMatches }}
                    LoadingRowComponent={TransactionPotentialMatchesGridLoadingRow}
                    RowComponent={TransactionPotentialMatchesGridRow}
                />
            </CardBody>
        </StyledCard>
    );
};

export default TransactionPotentialMatchesGrid;
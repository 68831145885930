import { useCallback, useEffect, useMemo } from "react";
import { IconButton } from "../../../components";
import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { patchReplace } from "../../../helpers/patchDoc";
import { useHouseRules } from "../../../hooks/HouseRulesContext";
import { useCreateWrapperRuleMutation, useDeleteWrapperRuleMutation, useFetchWrappersForSelectQuery, useLazyFetchWrapperRulesQuery, usePatchWrapperRuleMutation } from "../../../services/assets";

const wrapperSelectHeaderRenderer = ({ label, ...props }) => <ThemedTableHeader className="col-xxl-5" {...props}>{label}</ThemedTableHeader>;
const percentageRenderer = ({ label, ...props }) => <ThemedTableHeader className="text-center col-xxl-1" {...props} >{label}</ThemedTableHeader>;

const useWrapperRules = () => {
    const { currentClientProfile } = useHouseRules();

    // CRUD operations for the Rules Grid
    const [fetchWrapperRules, { data: wrapperRules, isLoading, isFetching }] = useLazyFetchWrapperRulesQuery({ refetchOnFocus: true });

    useEffect(() => {
        fetchWrapperRules({ clientProfileId: currentClientProfile })
    }, [currentClientProfile, fetchWrapperRules]);

    const [createWrapperRuleTrigger, { isLoading: isCreatingWrapperRule }] = useCreateWrapperRuleMutation();

    const createWrapperRule = useCallback(() =>
        createWrapperRuleTrigger({ clientProfileId: currentClientProfile }).unwrap(),
        [createWrapperRuleTrigger, currentClientProfile]);

    const [patchWrapperRuleTrigger] = usePatchWrapperRuleMutation();

    const patchWrapperRule = useCallback((ruleId, property, value) =>
        patchWrapperRuleTrigger({ clientProfileId: currentClientProfile, ruleId, operations: [patchReplace(property, value)] }).unwrap(),
        [currentClientProfile, patchWrapperRuleTrigger]);

    const [deleteWrapperRuleTrigger] = useDeleteWrapperRuleMutation();

    const deleteWrapperRule = useCallback((ruleId) =>
        deleteWrapperRuleTrigger({ clientProfileId: currentClientProfile, ruleId }).unwrap(),
        [currentClientProfile, deleteWrapperRuleTrigger]);

    // Columns definitions for the Rules Grid
    const { data: wrapperOptions, isFetching: isFetchingWrappers } = useFetchWrappersForSelectQuery();

    const columns = useMemo(() => [
        {
            label: "Wrapper",
            property: "wrapperId",
            isEditable: true,
            type: 'select',
            headerRenderer: wrapperSelectHeaderRenderer,
            options: wrapperOptions,
            isLoadingOptions: isFetchingWrappers
        },
        {
            label: "Max. Product Allocation",
            property: "maxProductAllocation",
            isEditable: true,
            type: 'percent',
            fixedDecimalScale: 1,
            headerRenderer: percentageRenderer,
        },
        {
            headerRenderer: () => <ThemedTableHeader className="col-auto">
                <IconButton icon="plus" variant="success" onClick={createWrapperRule} />
            </ThemedTableHeader>,
            deleteFn: deleteWrapperRule,
        }
    ], [createWrapperRule, deleteWrapperRule, isFetchingWrappers, wrapperOptions]);

    return {
        wrapperRules,
        isLoading,
        isFetching,
        createWrapperRule,
        isCreatingWrapperRule,
        patchWrapperRule,
        columns,
    }
}

export default useWrapperRules;
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchClientAccountsQuery } from "../../../services/clientfactfind";

const useClientAccounts = ({fetchType = "Object"}) => {
    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isFetching, isError, error, refetch, isUninitialized } = useFetchClientAccountsQuery({ masterAccountId, fetchType });

    return {
        data: data || [],
        isLoading: isLoading || isFetching || isUninitialized,
        isError,
        error,
        refetch
    };
};

export default useClientAccounts;
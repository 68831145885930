import { useFetchHeadOfficeDivisionsQuery } from "../../../services/headofficeadmin";

const useDivisions = () => {

    const { data, isLoading } = useFetchHeadOfficeDivisionsQuery();

    return {
        divisions: data || [],
        isLoadingDivisions: isLoading
    };
};

export default useDivisions;
import React from "react";
import moment from "moment";
import ReviewManagementDetailTableRowColumn from "./ReviewManagementDetailTableRowColumn";
import { ThemedIcon } from "../../../components/utilities";
import { current } from "@reduxjs/toolkit";

// TODO: this is a direct copy from the old AG Grid renders
// I believe it needs tweaking..?

const AMBER_WARNING = 5;

const ReviewManagementDetailTableStatusIconColumn = ({ reviewDate, date, targetDate, score, previousComplete, size = "lg" }) => {

    
    const currentDate = moment();
    const eventDate = date == null ? null : moment(date);
    const eventTargetDate = targetDate == null ? null : moment(targetDate);
    const amberWarningEventTargetDate = targetDate == null ? null : moment(targetDate).add(AMBER_WARNING, 'days');
    var reviewActive = moment(reviewDate).add(1, 'days');

    // return the empty row when the previous stage has not been completed
    if (!previousComplete) {
        return (
            <ReviewManagementDetailTableRowColumn className="text-center">
                <span>-</span>
            </ReviewManagementDetailTableRowColumn>
        );
    }
    
    var inner = null;

    if(eventDate == null && targetDate != null && eventTargetDate.isAfter(currentDate) && currentDate >= reviewActive) inner = <ThemedIcon variant="success" icon="fa-circle" size={size} />;
    if(eventDate == null && targetDate != null && currentDate > eventTargetDate && currentDate <= amberWarningEventTargetDate) inner = <ThemedIcon variant="warning" icon="fa-circle-xmark" size={size} />;
    if(eventDate == null && targetDate != null && currentDate > eventTargetDate && currentDate > amberWarningEventTargetDate) inner = <ThemedIcon variant="danger" icon="fa-circle-xmark" size={size} />;
    if(eventDate != null && score >= 0) inner = <ThemedIcon variant="success" icon="fa-circle-check" size={size} />;
    if(eventDate != null && score < 0 && Math.abs(score) <= AMBER_WARNING) inner = <ThemedIcon variant="warning" icon="fa-circle-check" size={size} />;
    if(eventDate != null && score < -1*AMBER_WARNING) inner = <ThemedIcon variant="danger" icon="fa-circle-check" size={size} />;

    if(inner != null) {
        return (
            <ReviewManagementDetailTableRowColumn className="text-center">
                {inner}
            </ReviewManagementDetailTableRowColumn>
         );
    }   

    return (
        <ReviewManagementDetailTableRowColumn className="text-center">
            <span>-</span>
        </ReviewManagementDetailTableRowColumn>
    );
};

export default ReviewManagementDetailTableStatusIconColumn;
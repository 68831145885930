import { NumericFormat } from "react-number-format";
import { FormSelect } from "../../../components/forms";
import useAccountApplications from "../hooks/useAccountApplications";
import { components } from "react-select";
import styled from "styled-components";

const OptionDetailsRow = styled.div`
    font-size: 0.875em;
    color: ${(props) => props.theme.muted};
`;

const MasterAccountSelectOption = (props) => {
    const { data: { data } } = props;
    const { applicationReference, description, amount, designationName, providerName } = data;

    return (
        <components.Option {...props}>
            <div>
                <div>
                    <span>{applicationReference}</span> - <span>{description}</span>
                </div>
                <OptionDetailsRow className="row text-muted">
                    <div className="col-auto">
                        <span>Amount:</span> <NumericFormat displayType="text" prefix="£" thousandSeparator value={amount}/>
                    </div>
                    <div className="col">
                        <span>Designation:</span> {designationName}
                    </div>
                </OptionDetailsRow>
            </div>
        </components.Option>
    )
};

const ApplicationsFormSelect = ({ errorMessage, isMulti, label, onChange, defaultValue, masterAccountId, ...rest }) => {

    const { data, isLoading } = useAccountApplications(masterAccountId);

    return <FormSelect
        key={masterAccountId}
        disabled={!masterAccountId}
        isMulti={isMulti}
        label={label}
        onChange={onChange}
        defaultValue={defaultValue}
        errorAllowRetry={false}
        disableAnimations
        errorMessage={errorMessage}
        options={data}
        isLoading={isLoading}
        components={{ Option: MasterAccountSelectOption }}
        {...rest}
    />;
};

export default ApplicationsFormSelect;
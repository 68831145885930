import _ from "lodash";
import { wealthProApi } from '../../redux/api';

const buildFetchMasterAccountAccessQuery = ({ masterAccountId, page, limit, sort = null }) => {
    let baseQuery = `api/clientdetails/master-accounts/${masterAccountId}/access?page=${page}&limit=${limit}`;
    if (sort) {
        baseQuery += `&sort=${sort}`;
    }
    return baseQuery;
};

const masterAccountsAccessApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountLogs"]
})
.injectEndpoints({
    endpoints: (build) => ({
        createAccess: build.mutation({
            query: ({ masterAccountId, ...rest }) => ({
                url: `/api/clientdetails/master-accounts/${masterAccountId}/access`,
                method: 'POST',
                body: { masterAccountId, ...rest }
            })
        }),
        deleteAccess: build.mutation({
            query: ({ masterAccountId, accessId }) => ({
                url: `/api/clientdetails/master-accounts/${masterAccountId}/access/${accessId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ masterAccountId, accessId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountAccess', { masterAccountId }, (data) => {
                        data.results = data.results.filter(el => el.id !== accessId);
                    }))
                } catch {}
            }
        }),
        fetchMasterAccountAccess: build.query({
            query: (props) => buildFetchMasterAccountAccessQuery(props),
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                }
                else {
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                }            
            }
        }),
        updateAccess: build.mutation({
            query: ({ masterAccountId, accessId, ...rest }) => ({
                url: `/api/clientdetails/master-accounts/${masterAccountId}/access/${accessId}`,
                method: 'PUT',
                body: { masterAccountId, accessId, ...rest }
            }),
            async onQueryStarted({ masterAccountId, accessId, ...rest }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedAccess } = await queryFulfilled
                    const patchResult = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountAccess', { masterAccountId }, (data) => {
                        var access = data.results.find(el => el.id === accessId);
                        if (access) {
                            Object.assign(access, updatedAccess);
                        }
                    }))
                } catch {}
            }
        })
    })
});

export const {
    useCreateAccessMutation,
    useDeleteAccessMutation,
    useFetchMasterAccountAccessQuery,
    useLazyFetchMasterAccountAccessQuery,
    useUpdateAccessMutation
} = masterAccountsAccessApiEndpoints;
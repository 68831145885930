import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { useFetchApplicationFeeTotalsQuery } from "../../../services/headofficeadmin";

const useFeeTotals = () => {

    const { applicationId } = useHeadOfficeApplicationContext();
    const { data, isError, isFetching, isLoading, error, refetch } = useFetchApplicationFeeTotalsQuery({ applicationId });

    const retryFetchFeeTotals = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    }

    return {
        data: data || {},
        isError,
        isFetching,
        isLoading,
        error,
        retryFetchFeeTotals
    };
};

export default useFeeTotals;
import { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "../../../components/loaders";
import { AccordionBody, AccordionHeader, AccordionList, AccordionListItem } from "../../../components/utilities";
import { usePrdCommentaryContext } from "../../../hooks/PrdCommentaryContext";
import { PortfolioNote, PrdCommentaryInput } from "./index";
import ErrorMessageWrapper from "../../../components/messages/ErrorMessageWrapper";
import ErrorMessage from "../../../components/messages/ErrorMessage";

const PortfolioSpecificNotesTab = () => {
    const {
        masterPrd,
        isFetching,
        patch,
        isPatchingFn,
        publish,
        isPublishingFn,
        portfolioNoteOptions,
        fetchPortfolioNoteOptions,
        refetchPortfolioNoteOptions,
        noteOptionsIsError
    } = usePrdCommentaryContext();

    useEffect(() => {
        fetchPortfolioNoteOptions();
    }, [fetchPortfolioNoteOptions]);

    const patchNonStandard = useCallback((newCommentary) => patch(3, newCommentary), [patch]);
    const nonStandardIsPatching = useMemo(() => isPatchingFn(3), [isPatchingFn]);
    const publishNonStandard = useCallback(() => publish(3), [publish]);
    const nonStandardIsPublishing = useMemo(() => isPublishingFn(3), [isPublishingFn]);

    const [openAccordions, setOpenAccordions] = useState([]);

    if (noteOptionsIsError)
        return <ErrorMessageWrapper className="p-3">
            <ErrorMessage error={noteOptionsIsError} retryCallback={refetchPortfolioNoteOptions} />
        </ErrorMessageWrapper>

    return <AccordionList className="d-grid p-3"
        activeKey={openAccordions}
        defaultActiveKey={null}
        onSelect={(eventKeys, _) => setOpenAccordions(eventKeys)}
        gap={1}
        alwaysOpen>
        {isFetching
            ? <Spinner className="my-auto mb-5" />
            : <AccordionListItem eventKey={"non-standard"}>
                <AccordionHeader>Non-Standard Portfolio (Service-Specific)</AccordionHeader>
                <AccordionBody>
                    <PrdCommentaryInput
                        commentary={masterPrd?.nonStandardPortfolio}
                        isLoading={isFetching}
                        patchCommentary={patchNonStandard}
                        isPatching={nonStandardIsPatching}
                        publishCommentary={publishNonStandard}
                        isPublishing={nonStandardIsPublishing}
                    />
                </AccordionBody>
            </AccordionListItem>}

        {portfolioNoteOptions?.map((portfolioNote) => <PortfolioNote
            key={portfolioNote.standardPortfolioId}
            standardPortfolioId={portfolioNote.standardPortfolioId}
        />)}
    </AccordionList>;
};

export default PortfolioSpecificNotesTab;
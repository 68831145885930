import FormSelectPaged from "./FormSelectPaged";
import { useLazyFetchHeadOfficeAdminUsersQuery } from "../../../services/headofficeadmin";

const HeadOfficeUserFormSelect = ({ isMulti, label, onChange, value, additional }) => {

    return <FormSelectPaged
        setValue={onChange}
        cacheOptions={true}
        label={label}
        isMulti={isMulti}
        value={value}
        additional={additional}
        query={useLazyFetchHeadOfficeAdminUsersQuery}
    />;
};

export default HeadOfficeUserFormSelect;
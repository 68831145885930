import styled from "styled-components";
import { Clickable, StandardTableSortableHeader, StandardTableSortablePropertyHeader, ThemedTableHeader } from "../../../components/tables/StandardTable";
import { usePersonalReminders } from "../hooks/PersonalRemindersContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";

const ClickableHeader = styled(ThemedTableHeader)`
    ${Clickable}
`;

const PersonalReminderTableHeader = () => {

    const { sortAscending, setSortAscending } = usePersonalReminders();

    return (
        <thead>
            <tr>
                <StandardTableSortableHeader
                    active={true}
                    direction={sortAscending ? "ASC" : "DESC"}
                    onClick={() => setSortAscending(prev => !prev)}
                >
                    Date
                </StandardTableSortableHeader>
                <ThemedTableHeader>
                    Type
                </ThemedTableHeader>
                <ThemedTableHeader>
                    Account
                </ThemedTableHeader>
                <ThemedTableHeader>
                    Description
                </ThemedTableHeader>
                <ThemedTableHeader>
                    Notes
                </ThemedTableHeader>
                <ThemedTableHeader>
                    User
                </ThemedTableHeader>
                <ThemedTableHeader className="align-middle text-center">
                    Status
                </ThemedTableHeader>
                <ThemedTableHeader className="align-middle text-center">
                    Priority
                </ThemedTableHeader>
            </tr>
        </thead>
    );
};

export default PersonalReminderTableHeader;
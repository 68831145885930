import React, { useId, useMemo, useState } from "react";
import FormGroup from "../../../components/forms/FormGroup";
import { FormLabel } from "../../../components/forms";
import InputErrorMessage from "../../../components/forms/InputErrorMessage";
import FormSelect, { CalculateClassName, CalculateSelectStyles } from "../../../components/forms/FormSelect";
import useMasterAccountProjects from "../hooks/useMasterAccountProjects";
import { ClientProjectSelectOption } from "../../../components/forms/selects/ClientProjectSelect";

export const ClientProjectSelectComponents = {
    Option: ClientProjectSelectOption
};

/**
 * Generic Project Select wrapped in a context - if we re-use the Reminders form in Management section it needs a different select...
 */

const MasterAccountProjectPagedSelect = ({
    className = "w-100",
    components = { ...ClientProjectSelectComponents },
    errorMessage = null,
    horizontal = false,
    disabled,
    id,
    isMulti = false,
    label,
    masterAccountId,
    setValue = () => console.warn('set value method has not been assigned.'),
    value,
    ...rest
}) => {

    const defaultId = useId();
    const componentId = id || defaultId;
    const [error, setError] = useState(errorMessage);
    const { data, hasMore, isFetching, isLoading, nextPage } = useMasterAccountProjects(masterAccountId);
    const currentClassName = useMemo(() => CalculateClassName(className, false, error), [className, error]);
    const currentStyles = useMemo(() => CalculateSelectStyles(error), [error]);

    const handleOnMenuScrollToBottom = (_) => {
        if (hasMore === false) {
            return;
        }
        nextPage();
    };

    return <FormGroup horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormSelect
            id={componentId}
            isMulti={isMulti}
            isDisabled={disabled}
            components={components}
            styles={currentStyles}
            className={currentClassName}
            defaultValue={value}
            options={data}
            isLoading={isLoading || isFetching}
            onChange={setValue}
            onMenuScrollToBottom={handleOnMenuScrollToBottom}
            {...rest}
        />
        <InputErrorMessage error={error} allowRetry={false} />
    </FormGroup>

};

export default MasterAccountProjectPagedSelect;
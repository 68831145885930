import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownButton } from "react-bootstrap";
import React, { useState, useId } from "react";
import styled from "styled-components";
import { ActionIcon } from "../../../components/utilities";

const DropdownToggle = React.forwardRef(({ onClick, ...rest }, ref) => {
    const handleOnToggle = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        if (onClick && typeof (onClick) === 'function') {
            onClick(e);
        }
    };

    return <a ref={ref} onClick={handleOnToggle} {...rest}>
        <ActionIcon icon="fa-ellipsis" size="lg"/>
    </a>
});

const NotificationOptionsDropdown = ({ notification, readFn }) => {

    const dropdownId = useId();


    const onRead = (e, options) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        readFn({ notificationId: notification.messageId, ...options });
    };

    return <Dropdown id="inner-notifications-dropdown">
        <Dropdown.Toggle
            id={dropdownId}
            as={DropdownToggle}
        />
        <Dropdown.Menu>
            {notification.isActionRequired && !notification.isDone && <Dropdown.Item
                onClick={(e) => onRead(e, { isDone: true, noFurtherActionRequired: true })}
            >
                Task Complete, no further action required
            </Dropdown.Item>}
            <Dropdown.Item onClick={(e) => onRead(e, { isDone: false, noFurtherActionRequired: true })}>No further action required</Dropdown.Item>
            <Dropdown.Item onClick={(e) => onRead(e, { isDone: false, noFurtherActionRequired: false })}>Read, keep message active</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>

};

export default NotificationOptionsDropdown;
import React from "react";
import styled from "styled-components";
import PersonalMessagesVirtualTableHeader from "./PersonalMessagesVirtualTableHeader";
import PersonalMessagesVirtualTableRow from "./PersonalMessagesVirtualTableRow";
import { VirtualTable } from "../../../components/tables";
import { TableRecordCountDisplayFooter } from "../../../components/tables/VirtualTable";
import { useMessageManagementContext } from "../../../hooks/MessageManagementContext";

const FixedVirtualTable = styled(VirtualTable)``;

const PersonalMessagesVirtualTable = () => {
    const { 
        clear: refreshTable,
        results,
        error, 
        isLoading, // TODO: move this for the next page!
        isFetching,
        totalCount, 
        hasItemLoaded, 
        loadMoreItems 
    } = useMessageManagementContext();

    return <React.Fragment>
        <FixedVirtualTable
            enableScrollTracking={false}
            row={PersonalMessagesVirtualTableRow}
            header={<PersonalMessagesVirtualTableHeader />}
            footer={
                <TableRecordCountDisplayFooter 
                    totalCount={totalCount}
                    isNewRecordsDisplayed={false}
                    newRecordsSinceCache={0}
                    reload={refreshTable}
                    spanRight={6}
                />
            }
            error={error}
            isLoading={isLoading}
            isItemLoaded={hasItemLoaded}
            loadMoreItems={loadMoreItems}
            itemSize={42}
            itemCount={isLoading || error ? 0 : totalCount} // TODO: tidy up messaging service and fix the context (this is a hacky fix :])
            itemData={results}
        />
    </React.Fragment>;
};

export default PersonalMessagesVirtualTable;
import _ from "lodash";
import React from "react";
import classNames from "classnames";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

const TableHeader = ({ children, property, className, sort, sortTable }) => {
    const sortProperties = sort.split(',');
    const isHeaderSorted = _.some(sortProperties, i => i.includes(property));
    const headerDirection = isHeaderSorted && _.some(sortProperties, i => i.toLowerCase().includes('+desc')) ? "desc" : null;

    const handleClick = () => {
        if (!sortTable || typeof (sortTable) !== 'function') {
            return;
        }
        sortTable(property);
    };
    
    return (
        <StandardTableSortableHeader 
            className={classNames(className)}
            active={isHeaderSorted}
            direction={headerDirection}
            isSticky={true}
            onClick={handleClick}
        >
            {children}
        </StandardTableSortableHeader>
    );
};

export default TableHeader;
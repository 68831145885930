import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const designationDetailsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountDesignationDetails"]
}).injectEndpoints({
    endpoints: (builder) => ({
        changeDesignationMappings: builder.mutation({
            query: ({ designationId, masterAccountId, data }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/designations/${designationId}/mappings`,
                method: 'PUT',
                body: data
            }),
            async onQueryStarted({ designationId, masterAccountId, data }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountDesignationDetails', { masterAccountId }, (draft) => {
                    var designation = draft.find(el => el.designationId === designationId);
                    if (!designation)
                        return;

                    designation.clientMappings = data.map(id => ({ clientAccountId: id }))
                }));

                try {
                    const { data: designationDto } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountDesignationDetails', { masterAccountId }, (data) => {
                        var designation = data.find(el => el.designationId === designationId);
                        if (!designation) 
                            return;
                        
                        designation.clientMappings = designationDto.clientMappings;
                    }));
                }
                catch (error) {
                    console.error(error, "Error updating designation mappings");
                    patchResult.undo();
                }
            }
        }),
        createDesignationDetails: builder.mutation({
            query: ({ masterAccountId, data }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/designations`,
                method: 'POST',
                body: data
            }),
            async onQueryStarted({ masterAccountId, data: designation }, { dispatch, queryFulfilled }) {
                try {
                    const { data: createData } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountDesignationDetails', { masterAccountId }, (data) => {
                        data.push({
                            ...designation,
                            designationId: createData.designationId
                        });
                    }));
                }
                catch { }
            }
        }),
        deleteDesignationDetails: builder.mutation({
            query: ({ designationId, masterAccountId }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/designations/${designationId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ designationId, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountDesignationDetails', { masterAccountId }, (data) => {
                        return data.filter(el => el.designationId !== designationId);
                    }));
                }
                catch { }
            }
        }),
        fetchMasterAccountDesignationDetails: builder.query({
            query: ({ masterAccountId, sort = 'CreatedDate' }) => 
                `api/clientdetails/master-accounts/${masterAccountId}/designations?sort=${sort}`,
            providesTags: (result, error, arg) => {
                return ['masterAccountDesignationDetails'];
            }
        }),
        patchDesignationDetails: builder.mutation({
            query: ({ designationId, masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/designations/${designationId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ designationId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountDesignationDetails', { masterAccountId }, (data) => {
                    var designation = data.find(el => el.designationId === designationId);
                    applyPatch(designation, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        fetchMasterAccountDesignationsList: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/designations/list`,
        }),
    })
});

export const {
    useChangeDesignationMappingsMutation,
    useCreateDesignationDetailsMutation,
    useDeleteDesignationDetailsMutation,
    useFetchMasterAccountDesignationDetailsQuery,
    usePatchDesignationDetailsMutation,
    useFetchMasterAccountDesignationsListQuery
} = designationDetailsApiEndpoints;
import React from "react";
import SectionHeader from "./SectionHeader";
import { IconButton } from "../../../components/buttons";
import usePolicyCovers from "../hooks/usePolicyCovers";
import PolicyCover from "./PolicyCover";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";

const PolicyCovers = ({ protectionId, isCurrentPriority }) => {

    const {
        policyCovers,
        isLoading,
        isError,
        error,
        refetchPolicyCovers,
        createPolicyCover,
        removePolicyCover,
        updatePolicyCover,
        updateChosenCoverMappings,
        updatePolicyClientsMappings
    } = usePolicyCovers({ protectionId, isCurrentPriority });

    if (isLoading) return <Skeleton height={16} count={4} />;

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchPolicyCovers} />
        </ErrorMessageWrapper>;
    }

    return <React.Fragment>
        <div className="d-flex flex-row align-items-end mb-3 justify-content-between">
            <SectionHeader className="me-4">
                {isCurrentPriority ? "Current Priorities" : "Existing Cover"}
            </SectionHeader>
            {policyCovers?.length > 0 && <IconButton
                icon="plus"
                variant="success"
                onClick={createPolicyCover}
            />}
        </div>
        {isLoading
            ? <Skeleton height={30} count={2} />
            : policyCovers?.length === 0
            && <p className="mt-2">There are no recorded policies. Click <button className="anchor" onClick={createPolicyCover}>here</button> to create one</p>}
        {policyCovers.map((policy, index) => <PolicyCover
            key={policy.policyId ?? index}
            policy={policy}
            showBorder={isCurrentPriority || index !== policyCovers.length - 1}
            isLoading={isLoading || !policy.policyId}
            updatePolicy={updatePolicyCover}
            deletePolicy={removePolicyCover}
            updatePolicyClients={updatePolicyClientsMappings}
            updatePolicyCover={updateChosenCoverMappings}
        />
        )}
    </React.Fragment>;
};

export default PolicyCovers;
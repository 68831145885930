import React from "react";
import FormSelect from "../../../components/forms/FormSelect";
import useWrappers from "../hooks/useWrappers";

const WrapperSelect = ({ wrappers: optionsOverride, ...rest }) => {

    const { wrappers, defWrappersIsLoading, defWrappersIsFetching } = useWrappers("select");

    return <FormSelect 
        options={wrappers || []} 
        isLoadingOptions={defWrappersIsLoading || defWrappersIsFetching}
        {...rest} />
};

export default WrapperSelect;
import React from "react";
import FormSelect from "../FormSelect";

const MasterAccountTypeSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={[
            { label: 'Private Client', value: 0, isDisabled: false },
            // { label: 'Trust', value: 1, isDisabled: true },
            // { label: 'Corporate', value: 2, isDisabled: true }
        ]}
        {...rest}
    />
};

export default MasterAccountTypeSelect;
import { wealthProApi } from "../../redux/api";
import * as jsonpatch from "fast-json-patch";

const masterAccountApiEndpoints = wealthProApi
    .enhanceEndpoints({
        addTagTypes: [
            "clientServiceTerms",
            "clientFeeBreakdowns",
            "clientFeeAgreements",
            "clientFeeSignatories",
            "clientIntroducerAgreements",
        ],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            fetchClientServiceTerms: builder.query({

                query: ({ masterAccountId, page, limit }) => ({
                    url: `api/review/MasterAccounts/${masterAccountId}/agreements/latest`,
                    method: 'GET',
                }),
                providesTags: (result, error, arg) => {
                    return ['clientServiceTerms'];
                }
            }),
            fetchClientFeeBreakdown: builder.query({
                query: ({ masterAccountId, page, limit }) =>
                    `api/review/MasterAccounts/${masterAccountId}/fees/breakdown`,
            }),
            fetchClientFeeAgreements: builder.query({
                query: ({ masterAccountId, page, limit }) =>
                    `api/review/MasterAccounts/${masterAccountId}/fees/agreement`,
            }),
            fetchClientFeeSignatories: builder.query({
                query: ({ masterAccountId, page, limit }) =>
                    `api/review/MasterAccounts/${masterAccountId}/fee/signatories`,
            }),
            fetchClientIntroducerAgreement: builder.query({
                query: ({ masterAccountId, page, limit }) => ({
                    url:`api/review/MasterAccounts/${masterAccountId}/introducers/agreement`,
                    method: 'GET',
                }),
                providesTags: (result, error, arg) => {
                    return ['clientIntroducerAgreements'];
                }
                    
            }),
        }),
    });

export const {
    useFetchClientServiceTermsQuery,
    useFetchClientFeeBreakdownQuery,
    useFetchClientFeeAgreementsQuery,
    useFetchClientFeeSignatoriesQuery,
    useFetchClientIntroducerAgreementQuery,
} = masterAccountApiEndpoints;

import React, { useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { useFetchUserActivityQuery } from "../services/identity";

// This replaces the basic feature hook that displays active users
// By doing this, we allow access to the list of online & offline users throughout the app
// Which means we can implement it into features to show user availbility across multiple features!

const ActiveUsersContext = React.createContext();

export const useActiveUsers = () => React.useContext(ActiveUsersContext);

export const ActiveUsersProvider = ({ children }) => {
    const { user: { profile } } = useAuth();
    const { data, isError, isLoading, refetch } = useFetchUserActivityQuery(null, {
        pollingInterval: 60000
    });

    const online = useMemo(() => {
        if (!data || Array.isArray(data.results) === false) {
            return  [];
        }
        return Array.from(data.results).filter(el => el.id !== profile.sub && el.isOnline === true);
    }, [data]);

    const offline = useMemo(() => {
        if (!data || Array.isArray(data.results) === false) {
            return  [];
        }
        return Array.from(data.results).filter(el => el.id !== profile.sub && el.isOnline === false);
    }, [data]);

    return (
        <ActiveUsersContext.Provider value={{
            data: data || [],
            online,
            offline,
            isError,
            isLoading,
            reload: refetch
        }}>
            {children}
        </ActiveUsersContext.Provider>
    );
};
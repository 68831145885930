import _ from "lodash";
import React, { useEffect, useState } from "react";
import Button from "../../components/buttons";
import { Modal } from "react-bootstrap";
import { useFilterContext } from "../../hooks/FilterContext";
import {
    DateInput,
    FormLabel,
    CheckSwitch,
    FormCheck
} from "../../components/forms";
import {
    GenericAdviserSelect,
    ReviewStatusSelect,
    ServiceAgreementsSelect,
} from "../../components/forms/selects";

export const ReviewManagementFiltersModalButton = ({
    className,
    variant = "primary",
    ...rest
}) => {
    return (
        <div className={className}>
            <Button variant={variant} {...rest}>
                Filter Reviews
            </Button>
        </div>
    );
};

export const ReviewManagementFiltersModal = ({
    handleClose,
    onFiltersChanged,
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter((original) => {
            return {
                ...original,
                ...tempFilters,
            };
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters((_) => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {

        setTempFilters((original) => {
            return {
                ...original,
                ...filter
            };
        });


        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters((_) => filter);
        }
    }, [filter]);
    
    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>Review Management Filters</Modal.Header>
            <Modal.Body {...rest}>
                <ReviewManagementFilters
                    filter={tempFilters}
                    setFilter={onFilterValueChanged}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>
                    Clear Filters
                </Button>
                <Button variant="success" onClick={onApplyFilters}>
                    Apply Filters
                </Button>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const ReviewManagementFilters = ({ filter, setFilter }) => {
    const {
        reviewStatuses,
        reviewDateStart,
        reviewDateEnd,
        serviceTermIds,
        adviserIds,
        completedTasks,
        overdueTasks,
        includeDelayed,
        excludeDelayed,
        onlyDelayed
    } = filter;

    const updateCompletedTasks = (taskType) => {
        if (completedTasks.includes(taskType)) {
            var newCompletedTasks = completedTasks.filter((x) => x != taskType);
            setFilter("completedTasks", newCompletedTasks);
        } else {
            setFilter("completedTasks", [...completedTasks, taskType]);
        }
    };

    const updateOverdueTasks = (taskType) => {
        if (overdueTasks.includes(taskType)) {
            var newoverdueTasks = overdueTasks.filter((x) => x != taskType);
            setFilter("overdueTasks", newoverdueTasks);
        } else {
            setFilter("overdueTasks", [...overdueTasks, taskType]);
        }
    };

    const onDelayedRadioChange = (property) => {

        setFilter('includeDelayed', false);
        setFilter('excludeDelayed', false);
        setFilter('onlyDelayed', false);

        setFilter(property, true);

    }

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col">
                    <ReviewStatusSelect
                        label="Review Status"
                        isMulti={true}
                        defaultValue={reviewStatuses}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "reviewStatuses",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
                <div className="col">
                    <DateInput
                        label="From"
                        isClearable
                        disableAnimations={true}
                        value={reviewDateStart}
                        onBlur={(value, _) => setFilter("reviewDateStart", value)}
                    />
                </div>
                <div className="col">
                    <DateInput
                        label="To"
                        isClearable
                        disableAnimations={true}
                        value={reviewDateEnd}
                        onBlur={(value, _) => setFilter("reviewDateEnd", value)}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <ServiceAgreementsSelect
                        label="Service Terms"
                        isMulti={true}
                        defaultValue={serviceTermIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "serviceTermIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
                <div className="col">
                    <GenericAdviserSelect
                        label="Adviser"
                        isMulti={true}
                        defaultValue={adviserIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "adviserIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <div className="row">
                        <FormLabel>Completed Tasks</FormLabel>
                    </div>

                    <div className="row">
                        <div className="col">
                            <CheckSwitch
                                label="Valuation"
                                checked={completedTasks.includes(0)}
                                onChange={() => {
                                    updateCompletedTasks(0);
                                }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <CheckSwitch
                                label="Pre-review Document"
                                checked={completedTasks.includes(1)}
                                onChange={() => {
                                    updateCompletedTasks(1);
                                }}
                            />
                            <div className="col"></div>

                            <div className="row">
                                <div className="col">
                                    <CheckSwitch
                                        label="Appointment"
                                        onChange={() => {
                                            updateCompletedTasks(2);
                                        }}
                                        checked={completedTasks.includes(2)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <CheckSwitch
                                        label="Meeting Notes"
                                        checked={completedTasks.includes(3)}
                                        onChange={() => {
                                            updateCompletedTasks(3);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <CheckSwitch
                                        label="Follow-up Report"
                                        checked={completedTasks.includes(4)}
                                        onChange={() => {
                                            updateCompletedTasks(4);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="col">
                        <div className="row">
                            <FormLabel>Overdue Tasks</FormLabel>
                        </div>

                        <div className="row">
                            <div className="col">
                                <CheckSwitch
                                    label="Assign Staff"
                                    checked={overdueTasks.includes(5)}
                                    onChange={() => {
                                        updateOverdueTasks(5);
                                    }}
                                />
                            </div>
                        </div>

                         <div className="row">
                            <div className="col">
                                <CheckSwitch
                                    label="Valuation"
                                    checked={overdueTasks.includes(0)}
                                    onChange={() => {
                                        updateOverdueTasks(0);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <CheckSwitch
                                    label="Pre-review Document"
                                    checked={overdueTasks.includes(1)}
                                    onChange={() => {
                                        updateOverdueTasks(1);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <CheckSwitch
                                    label="Appointment"
                                    checked={overdueTasks.includes(2)}
                                    onChange={() => {
                                        updateOverdueTasks(2);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <CheckSwitch
                                    label="Meeting Notes"
                                    checked={overdueTasks.includes(3)}
                                    onChange={() => {
                                        updateOverdueTasks(3);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <CheckSwitch
                                    label="Follow-up Report"
                                    checked={overdueTasks.includes(4)}
                                    onChange={() => {
                                        updateOverdueTasks(4);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="col">
                        <div className="row">
                            <FormLabel>Delayed</FormLabel>
                        </div>

                        <div className="row">
                            <div className="col">
                                <FormCheck
                                    label="Include Delayed"
                                    type="radio"
                                    name="includeDelayedRadio"
                                    isChecked={includeDelayed}
                                    onChange={() => onDelayedRadioChange('includeDelayed')}
                                    disableAnimations
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <FormCheck
                                    label="Exclude Delayed"
                                    type="radio"
                                    name="includeDelayedRadio"
                                    isChecked={excludeDelayed}
                                    onChange={() => onDelayedRadioChange('excludeDelayed')}
                                    disableAnimations
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <FormCheck
                                    label="Only Delayed"
                                    type="radio"
                                    name="includeDelayedRadio"
                                    isChecked={onlyDelayed}
                                    onChange={() => onDelayedRadioChange('onlyDelayed')}
                                    disableAnimations
                                />
                            </div>
                        </div>

                         
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReviewManagementFilters;

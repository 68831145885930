import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const mortgageArrangementsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createMortgageArrangement: builder.mutation({
            query: ({ masterAccountId }) => ({
                url: 'api/factfind/mortgagearrangements',
                method: 'POST',
                body: {
                    masterAccountId
                }
            }),
            async onQueryStarted({ masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    let createdIndex;
                    const createResult = dispatch(wealthProApi.util.updateQueryData('fetchMortgageArrangements', { masterAccountId }, (data) => {
                        const newMortgageArrangement = {
                            masterAccountId,
                            property: null,
                            value: null,
                            lender: null,
                            loanBalance: null,
                            mortgageType: null,
                            maturityDate: null,
                            interestRateType: null,
                            mainTerms: null,
                            interestRate: null,
                            expiryDate: null,
                            borrowerMappings: []
                        };
                        createdIndex = data.length;

                        return [...data, newMortgageArrangement];
                    }))

                    try {
                        var { data: response } = await queryFulfilled;

                        dispatch(wealthProApi.util.updateQueryData('fetchMortgageArrangements', { masterAccountId }, (data) => [
                            ...data.slice(0, createdIndex),
                            {
                                ...data[createdIndex],
                                mortgageId: response.mortgageId
                            },
                            ...data.slice(createdIndex + 1)
                        ]));
                    } catch (err) {
                        console.error("Error creating mortgage arrangement in service", err);
                        createResult.undo();
                    }
                } catch (err) {
                    console.error("Error creating mortgage arrangement in cache", err);
                }
            }
        }),
        deleteMortgageArrangement: builder.mutation({
            query: ({ mortgageId }) => ({
                url: `api/factfind/mortgagearrangements/${mortgageId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ masterAccountId, mortgageId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchMortgageArrangements', { masterAccountId }, (data) =>
                        data.filter(x => x.mortgageId !== mortgageId)));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting mortgage arrangement in service", err);
                        deleteResult.undo();
                    }
                } catch (err) {
                    console.error("Error deleting mortgage arrangement in cache", err);
                }
            }
        }),
        patchMortgageArrangement: builder.mutation({
            query: ({ mortgageId, operations }) => ({
                url: `api/factfind/mortgagearrangements/${mortgageId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, mortgageId, operations }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchMortgageArrangements', { masterAccountId }, (data) => {
                        var item = data.find(x => x.mortgageId === mortgageId);
                        applyPatch(item, operations, true);
                    }));
                }
                catch { }
            }
        }),
        updateMortgageArrangementOwnerMappings: builder.mutation({
            query: ({ mortgageId, ids }) => ({
                url: `api/factfind/mortgagearrangements/${mortgageId}`,
                method: 'POST',
                body: {
                    ids
                }
            }),
            async onQueryStarted({ masterAccountId, mortgageId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: mappings } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchMortgageArrangements', { masterAccountId }, (data) => {
                        var item = data.find(x => x.mortgageId === mortgageId);
                        item.borrowerMappings = mappings;
                    }))
                }
                catch { }
            }
        })
    })
});

export const {
    useCreateMortgageArrangementMutation,
    useDeleteMortgageArrangementMutation,
    usePatchMortgageArrangementMutation,
    useUpdateMortgageArrangementOwnerMappingsMutation
} = mortgageArrangementsApiEndpoints;
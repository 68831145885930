import React from "react";
import styled, { css } from "styled-components";

const TooltipContent = styled.span`
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    font-size: .75rem;
    text-align: center;
    padding: .5rem;
    border-radius: 6px;
    position: absolute;
    z-index: 1;

    // conditionally place the tooltip in the correct place
    ${props => {
        switch (props.position) {
            case "left":
                return css`
                    top: -5px;
                    right: 105%;

                    &::after {
                        content: " ";
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        margin-top: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent transparent black;
                    }
                `;
            case "right":
                return css`
                    top: -5px;
                    left: 105%;

                    &::after {
                        content: " ";
                        position: absolute;
                        top: 50%;
                        right: 100%;
                        margin-top: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent black transparent transparent;
                    }
                `;
            case "top":
                return css`
                    bottom: 100%;
                    left: 50%;
                    margin-left: -60px;

                    &::after {
                        content: " ";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: black transparent transparent transparent;
                    }
                `;
            case "bottom":
            default:
                return css`
                    top: 100%;
                    left: 50%;
                    margin-left: -60px;

                    &::after {
                        content: " ";
                        position: absolute;
                        bottom: 100%;
                        left: 50%;
                        margin-left: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent black transparent;
                    }
                `;
        }
    }}
`;

const TooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover ${TooltipContent} {
        visibility: visible;
    }
`;

const Tooltip = ({ children, position, tooltip, ...props }) => {
    return <TooltipWrapper {...props}>
        {children}
        <TooltipContent position={position || 'bottom'}>
            {tooltip}
        </TooltipContent>
    </TooltipWrapper>
};

export default Tooltip;
import React from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchProjectReportsQuery } from "../../../services/clientreports";

const useProjectReportsList = (projectId, pageLimit = 25) => {
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const { account: { masterAccountId } } = useClientContext();
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazyFetchProjectReportsQuery();

    const { pagination, results, newRecordsSinceCache } = data || { pagination: { page: 1, pageSize: pageLimit, totalCount: 0, totalPages: 1 }, results: [], newRecordsSinceCache: 0 };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true;

    const clear = () => {
        if (!projectId || isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            projectId,
            page: 1,
            limit: pageLimit
        }).unwrap().then(_ => {
            setCacheKey(_prev => newCacheKey);
            setIsLoadingState(_prev => false);
        });
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        setIsLoadingState(_ => true);
        load({ 
            cacheKey, 
            masterAccountId, 
            projectId,
            page: page + 1, 
            limit: pageLimit,   
        }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
        });
    };

    React.useEffect(clear, [ projectId ]);

    return {
        ...loadResults,
        clear,
        data: results,
        hasMore,
        isFetching,
        isLoading,
        isNextPageLoading,
        isNewRecords: newRecordsSinceCache > 0,
        isRowLoaded,
        limit: pageLimit,
        loadMore: isNextPageLoading ? () => {} : loadMore,
        newRecordsSinceCache,
        page,
        pagination,
        totalCount,
        totalPages
    };
};

export default useProjectReportsList;
import { CurrencyInput, FormInput, FormSelect } from "../../../components/forms";
import { patchReplace } from "../../../helpers/patchDoc";
import usePolicy from "../hooks/usePolicy";
import usePolicyOptions from "../hooks/usePolicyOptions";

// const CommissionAmountDisplay = styled(CurrencyInput)`
//     *:first-child {
//         border-top-left-radius: 0;
//         border-bottom-left-radius: 0;
//     }
// `;

const PolicyInfo = ({ isMortgage }) => {
    const {
        instructionTypeId,
        policy,
        frequencies,
        bulkPatch,
        patch,
    } = usePolicy();
    const {
        wrappers,
        designations,
        providers,
        isFetchingWrappers,
        isFetchingDesignations,
        isLoadingProviders
    } = usePolicyOptions({ instructionTypeId });

    return <>
        <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
                <FormSelect
                    label={isMortgage ? "Wrapper" : "Policy Type"}
                    defaultValue={policy?.wrapperId}
                    options={wrappers}
                    isLoadingOptions={isFetchingWrappers}
                    onBlur={(selection) => patch("wrapperId", selection.value)}
                />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0">
                <FormSelect
                    label="Designation"
                    defaultValue={policy?.designationId}
                    options={designations}
                    isLoadingOptions={isFetchingDesignations}
                    onBlur={(selection) => patch("designationId", selection.value)}
                />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-2 mt-lg-0">
                <FormSelect
                    label="Provider"
                    defaultValue={policy?.providerId}
                    options={providers}
                    isLoadingOptions={isLoadingProviders}
                    onBlur={(selection) => bulkPatch([
                        patchReplace("providerId", selection.value),
                        patchReplace("providerName", selection.label)
                    ])}
                />
            </div>
            {/* </div>
        <div className="row mt-2"> */}
            <div className="col-12 col-md-6 col-lg-5 col-xl-4 mt-2">
                <FormInput
                    label={isMortgage ? "Product Name" : "Product Description"}
                    value={policy?.productDescription}
                    onBlur={(value) => patch("productDescription", value)}
                />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-2">
                {isMortgage
                    ? <CurrencyInput
                        label="Loan Amount (£)"
                        value={policy?.amount}
                        onBlur={(value) => patch("amount", value)}
                    />
                    : <div className="row">
                        <div className="col">
                            <CurrencyInput
                                label="Premium (£)"
                                value={policy?.amount}
                                onBlur={(value) => patch("amount", value)}
                            />
                        </div>
                        <div className="col">
                            <FormSelect
                                label="Frequency"
                                defaultValue={policy?.frequency}
                                options={frequencies}
                                onBlur={(selection) => patch("frequency", selection.value)}
                            />
                        </div>
                    </div>}
            </div>
            <div className="d-none d-xl-block col-xl-1"></div>
            {/* <div className="col mt-2 pe-0">
                <PercentageInput
                    label="Commission"
                    value={policy?.commissionRate}
                    onChange={(_, { floatValue }) => realTimePatch("commissionRate", floatValue)}
                    onBlur={(value) => bulkPatch([
                        patchReplace("commissionRate", value),
                        patchReplace("commissionAmount", policy?.amount * frequencyMultiplier * value / 100)
                    ])}
                    isAllowed={({ floatValue }) => isMortgage ? (floatValue || 0) <= 100 : true}
                />
            </div> */}
            <div className="col mt-2 ps-0">
                {/* <FormLabel className="invisible">-</FormLabel> */}
                <CurrencyInput
                    label="Commission Amount (£)"
                    value={policy?.commissionAmount}
                    onBlur={(value) => patch("commissionAmount", value)}
                />
            </div>
            <div className="d-none d-xl-block col-xl-1"></div>
        </div>
    </>
}

export default PolicyInfo;
import Skeleton from "react-loading-skeleton";
import { useProviderManagement } from "../hooks/ProviderManagementContext";
import styled from "styled-components";
import ProvidersRowActionCol from "./ProvidersGridRowActionCol";

const TableCell = styled.td`
    width: ${props => props.width}%;
    max-width: 50px;
    vertical-align: middle;
`;

const ProvidersGridRow = ({ index, style: { height, width }, data }) => {

    const { columns, setProviderIdToDelete, setProviderIdToEdit } = useProviderManagement();

    if (!data[index]) {
        return <tr style={{ height, width }}>
            <td colSpan={columns.length}>
                <Skeleton height={height - 10} />
            </td>
        </tr>;
    }

    const {
        providerId,
        custodianCode,
        fundManagerCode,
        providerName
    } = data[index];

    return <tr style={{ height, width }} onDoubleClick={() => setProviderIdToEdit(providerId)}>
        <TableCell width={25}>{providerName}</TableCell>
        <TableCell width={15}>{fundManagerCode}</TableCell>
        <TableCell width={58}>{custodianCode}</TableCell>
        <TableCell>
            <ProvidersRowActionCol
                deleteFn={() => setProviderIdToDelete(providerId)}
                editFn={() => setProviderIdToEdit(providerId)}
            />
        </TableCell>
    </tr>;
};

export default ProvidersGridRow;
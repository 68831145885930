import DataColumn from "./DataColumn"
import Button, { IconButton } from "./buttons";
import PropTypes from 'prop-types';
import { Row } from "react-bootstrap";

const DataRow = ({
    data,
    columns,
    buttonText,
    buttonFn,
    className,
    dateFromNow
}) => {

    const renderColumnsFromValues = () => {
        return columns.map((c, i) => <DataColumn
            className={c.className}
            data={data}
            propertyKey={c.value}
            icon={c.icon}
            key={i}
            onClick={c.onClick}
            link={c.link ? () => c.link(data) : null}
            dateFromNow={dateFromNow}
        />
        )
    };

    return (
        <Row className={className}>
            {renderColumnsFromValues()}
            {buttonText &&
                <Button
                    className="col-auto border-dark"
                    onClick={buttonFn}
                    variant="light"
                >
                    {buttonText}
                </Button>}
        </Row>
    )


};

export default DataRow;

// DataRow.propTypes = {
//     /**
//      * The single data object to be displayed in this row
//      */
//     data: PropTypes.object.isRequired,
//     /**
//      * List of property values and class names to use for each column.
//      */
//     columns: PropTypes.array.isRequired,
//     /**
//      * Text displayed on the button that renders at the end of the row (if no button wanted, leave empty)
//      */
//      buttonText: PropTypes.string,
//      /**
//       * OnClick handler function for when the button is clicked
//       */
//      buttonFn: PropTypes.func,
//      /**
//       * Class name for the DataRow component
//       */
//      className: PropTypes.string,
//      /**
//       * Whether any dates in the row should display as from now (e.g. 1m ago, 3 days ago)
//       */
//      dateFromNow: PropTypes.bool
// }

import React, { useCallback, useEffect } from "react";
import { GridWrapper } from "../../../components";
import { FeeCollection, InvestmentSelection, ProductSelection, SourceOfFunds } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

const NewInvestment = ({ instructionId }) => {
    const [{ fetchInvest }, { realTimeInstruction, investIsError }] = useInstruction();

    useEffect(() => {
        if (!investIsError)
            fetchInvest({ instructionId });
    }, [fetchInvest, instructionId, investIsError]);

    const retry = useCallback(() => {
        fetchInvest({ instructionId });
    }, [fetchInvest, instructionId]);
    if (investIsError)
        return <InvestDivestErrorMessage retry={retry} />

    return <GridWrapper gridTemplateColumns={"100%"}>
        <ProductSelection
            isInvest
            changeOwner
        />
        <SourceOfFunds hasTaxReclaim />

        <InvestmentSelection maxInvestmentAmount={realTimeInstruction?.invest?.totalSubscriptionAmount} />
        <FeeCollection />
        {/* <AnnualCostsDisclosureTable /> */}
    </GridWrapper>;
};

export default NewInvestment;

import { useFetchTransactionProvidersQuery } from "../../../services/valuations/providersApiEndpoints";

const useProviderDetails = (listType) => {
    const {
        data: providers, 
        providerError,
        providerIsError,
        providerIsFetching,
        providerIsLoading } = useFetchTransactionProvidersQuery(listType);

    return {
        providers,
        providerError,
        providerIsError,
        providerIsFetching,
        providerIsLoading
    };
};

export default useProviderDetails;

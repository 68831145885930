import React, { useEffect, useMemo, useState } from "react";

import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridWrapper, IconButton } from "../../../components";
import { FormLabel } from "../../../components/forms";
import { ButtonGroup, Modal, ModalDialog, OverlayTrigger, Tooltip } from "react-bootstrap";
import useProductCosts from "../hooks/useProductCosts";
import ProductCostRow from "./ProductCostRow";
import styled from "styled-components";
import { useInstruction } from "../contexts/InstructionContext";

const VariantTooltip = styled(Tooltip)`
    .tooltip-inner {
        background-color: var(--bs-${(props) => props?.variant ?? "dark"}) !important;
        color: #fff;
    }
    /* Use the ::before attribute to get the first child of the tooltip-arrow component */
    .tooltip-arrow::before {
        border-${(props) => props.placement}-color: var(--bs-${(props) => props?.variant ?? "dark"}) !important;
    }
`;

const XXLModalDialog = styled(ModalDialog)`
    max-width: 90vw;
    margin-top: 20vh;
    margin-bottom: 20vh;
`

const AnchorButton = styled.button`
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
`

const ProductCostsPopup = ({ className, buttonClassName, instructionId, selectedProductId, hasProductCosts, productName, section }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [, { instruction }] = useInstruction();
    const [{ addRow, patchRow, removeRow, saveChanges, cancelChanges }, { recProductCosts, options, isReady, isError, error, isSaving, isSaved, saveSuccess, saveIsError }] = useProductCosts(instructionId, selectedProductId, hasProductCosts, handleClose, instruction?.productCosts, section);

    const activeError = useMemo(() => {
        if (error) {
            return error[Object.keys(error)?.find(errType => error[errType])]
        }
    }, [error])

    const errorMessage = useMemo(() =>
        activeError?.message ?? "Unknown error"
        , [activeError?.message]);

    const [wasSaving, setWasSaving] = useState(false);
    const [icon, setIcon] = useState("pound-sign");
    const [iconClassName, setIconClassName] = useState("");

    useEffect(() => {
        setIcon(oldIcon => {
            if (isError) {
                setIconClassName("")
                return "exclamation";
            }

            if (saveIsError) {
                setIconClassName("");
                return "rotate-right";
            }

            if (isSaving || !isReady) {
                setWasSaving(isSaving);
                setIconClassName("fa-spin-pulse");
                return "spinner"
            }

            if (saveSuccess && wasSaving) {
                setIconClassName("fa-bounce")
                setTimeout(() => {
                    setWasSaving(isSaving);
                    setIconClassName("");
                    setIcon("pound-sign");
                }, 800);
                return "check";
            }

            setIconClassName("");
            return "pound-sign"
        });
    }, [isError, isReady, isSaving, saveIsError, saveSuccess, wasSaving])

    return <>
        <OverlayTrigger
            placement="bottom"
            overlay={(props) => (isError || saveIsError) ? <VariantTooltip className="mt-2" variant="danger" {...props}>
                {isError ?
                    errorMessage :
                    "There was an error saving your changes, click to retry."
                }
            </VariantTooltip> : <></>}
        >
            <span className={`${!selectedProductId ? "invisible" : ""} ${className}`}>
                <Button
                    className={buttonClassName ?? ''}
                    onClick={saveIsError ? saveChanges : handleShow}
                    variant={(isError || saveIsError)
                        ? "danger"
                        : (isSaving || !isReady)
                            ? "secondary"
                            : "success"}
                    disabled={!isReady || isError}
                >
                    <FontAwesomeIcon
                        icon={icon}
                        color={"white"}
                        transform={"grow-5"}
                        className={iconClassName ?? ''}
                    />
                </Button>
            </span>
        </OverlayTrigger>

        <Modal
            dialogAs={XXLModalDialog}
            show={show}
            onHide={handleClose}
            keyboard={false}
            backdrop="static"
        >
            <Modal.Header><strong>Costs associated with {productName}</strong></Modal.Header>
            <Modal.Body>
                {recProductCosts?.length > 0 ? <GridWrapper gridTemplateColumns={`minmax(auto, 5%) ${section === "Out" ? "minmax(auto, 10%)" : ""} minmax(auto, ${section === "Out" ? "10" : "12.5"}%) minmax(auto, 20%) minmax(auto, ${section === "Out" ? "10" : "12.5"}%) minmax(auto, ${section === "Out" ? "6" : "10"}%) minmax(auto, ${section === "Out" ? "34" : "35"}%) auto`}>
                    <FormLabel className="col-start-1 mx-auto">Apply</FormLabel>
                    {section === "Out" && <FormLabel className="col-start-2">Post To</FormLabel>}
                    <FormLabel className={`col-start-${section === "Out" ? "3" : "2"}`}>Cost Type</FormLabel>
                    <FormLabel className={`col-start-${section === "Out" ? "4" : "3"}`}>Description</FormLabel>
                    <FormLabel className={`col-start-${section === "Out" ? "5" : "4"}`}>Calculation</FormLabel>
                    <FormLabel className={`col-start-${section === "Out" ? "6" : "5"}`}>Cost</FormLabel>
                    <FormLabel className={`col-start-${section === "Out" ? "7" : "6"}`}>Narrative</FormLabel>
                    <IconButton
                        icon={"circle-plus"}
                        className={`col-start-${section === "Out" ? "8" : "7"}`}
                        variant={"success"}
                        onClick={addRow}
                    />


                    {recProductCosts?.map((cost, index) =>
                        <ProductCostRow
                            key={cost?.id ?? index}
                            costId={cost.id}
                            index={index}
                            section={section}
                            cost={cost}
                            options={options}
                            patchCache={(property, newValue) => patchRow(index, property, newValue)}
                            removeRow={() => removeRow(index)}
                        />
                    )}

                </GridWrapper> : <>There are no costs associated with that product. Click <AnchorButton onClick={addRow}>here</AnchorButton> to add a cost</>}
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <OverlayTrigger
                        overlay={(props) => isSaved ? <Tooltip {...props}>
                            No changes to save
                        </Tooltip> : <></>}
                    >
                        <span>
                            <Button
                                variant="success"
                                onClick={saveChanges}
                                disabled={isSaved}
                            >
                                Save Changes
                            </Button>
                        </span>
                    </OverlayTrigger>
                    <Button variant="danger" onClick={cancelChanges}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>

        {/* <Toast show={true} bg={"danger"} >
                <Toast.Header>
                    <FontAwesomeIcon
                        className="me-2"
                        icon="exclamation"
                        color="red"
                    />
                    <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body className="text-white">
                    There was an error saving your changes, click <a href="#" role="button" onClick={saveChanges}>here</a> to retry.
                </Toast.Body>
            </Toast> */}
    </>;
};

export default ProductCostsPopup;
import React from "react";
import { Main } from "../../components/utilities";
import ApplicationSummary from "./components/ApplicationSummary";
import ApplicationReminders from "./components/ApplicationReminders";
import { ContentWrapper } from "../../components/utilities";

const AdministrationSummary = () => {

    return <Main className="p-3">
        <ContentWrapper label="Summary">
            <ApplicationSummary />
        </ContentWrapper>
         <ContentWrapper label="Reminders">
            <ApplicationReminders />
        </ContentWrapper>
    </Main>;

};

export default AdministrationSummary;
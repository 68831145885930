import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import InfiniteLoader from "react-window-infinite-loader";
import useClientEmailPreviews from "../hooks/useClientEmailPreviews";
import ClientEmailPreviewRow from "./ClientEmailPreviewRow";
import { useClientEmails } from "../hooks/ClientEmailsContext";
import { InfinityList } from "../../../components/tables";

const ClientEmailPreviewList = () => {
    const { mailId, setMailId } = useClientEmails();
    const { results, error, isError, isLoading, totalCount, isItemLoaded, loadMoreItems, clear } = useClientEmailPreviews();

    const onPreviewClick = ({ graphId }) => setMailId(graphId);

    const showActive = ({ graphId }) => graphId === mailId;

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>;
    }

    return <InfinityList
        itemData={{ data: results, onClick: onPreviewClick, showActive }}
        itemCount={totalCount === results.length ? totalCount : results.length + 15}
        itemSize={96}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        row={ClientEmailPreviewRow}
        // isLoading={isLoading}
    />;
};

export default ClientEmailPreviewList;
import { createContext, useContext, useEffect, useState } from "react";

export const ClientEmailViewTypes = {
    Emails: 0,
    Conversations: 1
};

const ClientEmailsContext = createContext();

export const useClientEmails = () => useContext(ClientEmailsContext);

const ClientEmailsProvider = ({ children }) => {
    const [attachmentsOnly, setAttachmentsOnly] = useState(false);
    const [conversationId, setConversationId] = useState(null);
    const [mailId, setMailId] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [viewType, setViewType] = useState(ClientEmailViewTypes.Emails);

    useEffect(() => {
        setMailId(null);
    }, [conversationId, viewType]);

    return <ClientEmailsContext.Provider value={{
        attachmentsOnly,
        conversationId,
        mailId,
        searchTerm,
        showConversations: viewType === ClientEmailViewTypes.Conversations,
        viewType,
        setAttachmentsOnly,
        setConversationId,
        setMailId,
        setSearchTerm,
        setViewType
    }}>
        {children}
    </ClientEmailsContext.Provider>;
};

export default ClientEmailsProvider;
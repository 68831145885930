import { useEffect, useState } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import styled from "styled-components"
import { FormInput, FormLabel } from "../../../components/forms"
import { patchReplace } from "../../../helpers/patchDoc"
import useFundDetails from "../hooks/useFundDetails"
import useSearchFundAssetBySedol from "../hooks/useSearchFundAssetBySedol"

const LargePopover = styled(Popover)`
    max-width: 400px;
`;

const FundSedolSearch = ({ setAssetNameDisplayText, disabled = false }) => {
    const [{ patchAssetGroup }, { assetGroup, assetGroupNotReady }] = useFundDetails();

    const [isUpdating, setIsUpdating] = useState(false);
    const [{ searchAndCreateMapping, deleteMapping }, { errorMessage }] = useSearchFundAssetBySedol();

    const [displaySedol, setDisplaySedol] = useState();

    useEffect(() => {
        setDisplaySedol(assetGroup?.sedol)
    }, [assetGroup?.sedol]);

    return <>
        <div className="col-4 col-md-3 col-lg-2 flex-column mt-2">
            <FormLabel>
                SEDOL
            </FormLabel>
            {assetGroupNotReady
                ? <Skeleton />
                : <FormInput
                    value={assetGroup?.sedol}
                    disableAnimations={assetGroup?.sedol === displaySedol}
                    disabled={disabled || isUpdating}
                    onChange={(event) => setDisplaySedol(event.target.value)}
                    onBlur={(input) =>
                        (assetGroup?.sedol === displaySedol)
                            ? Promise.resolve()
                            : new Promise((resolve, reject) => {
                                setIsUpdating(true);

                                // All SEDOLs should be converted to uppercase onBlur
                                input = input.toUpperCase();

                                let operations = [];

                                // The user has cleared the input, so delete the existing mapping to the Asset, and all asset-related properties
                                if (input === "") {
                                    operations.push(patchReplace("sedol", null),
                                        patchReplace("citicode", null),
                                        patchReplace("assetName", null),
                                        patchReplace("assetSectorCode", null),
                                        patchReplace("sectorCodeOverride", null),
                                        patchReplace("sectorCodeIsOverridden", false));

                                    return deleteMapping(assetGroup?.assetGroupId)
                                        .then(() => resolve({ operations }))
                                        .catch(reject);
                                }

                                return searchAndCreateMapping(input, assetGroup?.assetGroupId).then(res => {
                                    // Update the display
                                    setDisplaySedol(res?.sedol ?? res?.citicode);

                                    // And the Core Asset's asset-related properties
                                    operations.push(patchReplace("sedol", res?.sedol ?? res?.citicode),
                                        patchReplace("citicode", res?.citicode ?? null),
                                        patchReplace("assetName", res?.longAssetName ?? ""),
                                        patchReplace("assetSectorCode", res?.sectorCode),
                                        patchReplace("sectorCodeOverride", res?.sectorCode),
                                        patchReplace("sectorCodeIsOverridden", false));

                                    resolve({ operations });
                                }).catch(reject)
                            }).then(async ({ operations }) => {
                                await patchAssetGroup(operations);
                            }).finally(() => setIsUpdating(false))}
                    errorMessage={errorMessage}
                    errorAllowRetry={errorMessage == null}
                />}
        </div>
        <div className="col-8 col-md-7 col-lg-5 col-xl-4 flex-column mt-2">
            <FormLabel>
                Asset Name
            </FormLabel>
            {assetGroupNotReady || isUpdating
                ? <Skeleton />
                : <OverlayTrigger
                    placement="top"
                    overlay={(props) => assetGroup?.sedol === ""
                        ? <LargePopover {...props}>
                            <Popover.Header>Fund Search</Popover.Header>
                            <Popover.Body>
                                Please enter a SEDOL or Citicode to search for an asset.
                            </Popover.Body>
                        </LargePopover>
                        : <></>}>
                    <span>
                        <FormInput
                            className="col-start-2"
                            value={(assetGroup?.assetName ?? "")}
                            onChange={(event) => setAssetNameDisplayText(event.target.value)}
                            onBlur={(assetName) => patchAssetGroup([patchReplace("assetName", assetName)])}
                            disabled={assetGroup?.sedol == null || assetGroup?.sedol === "" || disabled} />
                    </span>
                </OverlayTrigger>}
        </div>
    </>
}

export default FundSedolSearch;
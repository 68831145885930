import _ from "lodash";
import React from "react";
import toast from "react-hot-toast";
import useCreateReportTemplate from "./hooks/useCreateReportTemplate";
import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { Modal } from "react-bootstrap";
import { Button } from "../../components";
import { FormInput } from "../../components/forms";
import { ThemedIcon } from "../../components/utilities";
import { niceBytes } from "../../helpers/fileHelpers";

export const uploadProps = {
    maxFiles: 1,
    accept: {
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": []
    }
};


const CreateReportTemplateModal = ({ 
    show = false,
    handleClose = () => {},
    handleOnCreated = () => {},
    handleOnFailure = () => {}
}) => {
    const [fileData, setFileData] = React.useState([]);
    const { buildFormData, create, defaultValues, isLoading } = useCreateReportTemplate();
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onClearFiles = () => setFileData([]);

    const onClose = () => {
        reset(defaultValues);
        setFileData([]);
        handleClose();
    };

    const onDrop = React.useCallback(async (acceptedFiles) => {
        setFileData([...fileData, ...acceptedFiles]);
    }, []);

    const onSubmit = async (data) => {
        const newFormData = buildFormData(data, fileData[fileData.length - 1]);
        toast.promise(create(newFormData), {
            loading: `Your file is now being processed...`,
            success: (data) => {
                handleOnCreated(data);
                onClose();
                return `Your file has now been uploaded.`;
            },
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                handleOnFailure(err);
                onClose();
                return `There was a problem with uploading the file to the system. ${display}`;
            }
        });
    };

    React.useEffect(() => {
        if (fileData.length < 1) {
            return;
        }

        const myNewFile = fileData[fileData.length - 1];
        reset({
            ...defaultValues,
            fileName: myNewFile.name,
            fileSize: myNewFile.size
        });
    }, [ fileData ]);

    // setup the file dropzone for uploading & checking file validity
    const { getRootProps, getInputProps } = useDropzone({ ...uploadProps, onDrop });
    const { name: fileName, size: fileSize } = fileData.length > 0 ? fileData[0] : { };

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Create a new Report Template</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    {fileData.length === 0 && (
                        <div {...getRootProps({ className: 'dropzone d-flex px-3 py-2 border border-3 border-opacity-10' })}>
                            <input {...getInputProps()} />
                            <div className="d-flex flex-fill justify-content-center align-items-center py-4">
                                <p className="m-0">Drag 'n' drop some files here, or click to select files</p>
                            </div>
                        </div>
                    )}
                    {fileData.length > 0 && (
                        <React.Fragment>
                            <div className="d-flex p-3 border border-3 border-opacity-10 mb-3">
                                <div className="d-flex justify-content-center align-items-center">
                                    <ThemedIcon icon={"fa-file-word"} size="2xl" variant="primary" />
                                </div>
                                <p className="flex-fill m-0 px-3 lh-1">
                                    <span>{fileName}</span>
                                    <br />
                                    <span className="text-muted fs-smallest">{niceBytes(fileSize)}</span>
                                </p>
                                <div className="d-flex justify-content-center align-items-center">
                                    <ThemedIcon className="has-pointer" icon="fa-xmark" size="2xl" variant="danger" onClick={onClearFiles} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <Controller
                                        name="description"
                                        control={control}
                                        rules={{ 
                                            required: 'A file description must be given.',
                                            maxLength: {
                                                value: 2048,
                                                message: 'Template description cannot be more than 2048 characters.'
                                            } 
                                        }}
                                        render={({ field: { ref, ...rest } }) => (
                                            <FormInput 
                                                label="Description" 
                                                disableAnimations={true} 
                                                errorAllowRetry={false}
                                                errorMessage={errors?.description?.message}
                                                {...rest} 
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" disabled={isLoading === true || fileData.length === 0}>Create</Button>
                    <Button variant="light" onClick={onClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateReportTemplateModal;
import React, { useState } from "react";
import { Form } from "react-bootstrap";

const CheckSwitch = ({
    label = "",
    readOnly = false,
    className,
    ...inputProps
}) => {

    return (
        <Form.Check
            type={"switch"}
            disabled={readOnly}
            className={className}
            label={
                <React.Fragment>
                    {label && typeof label === "string" && <Form.Label>{label}</Form.Label>}
                    {label && typeof label === "object" && (<React.Fragment>{label}</React.Fragment>)}
                </React.Fragment>
            }
            {...inputProps}
        />
    );
};



export default CheckSwitch;
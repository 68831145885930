import React, { memo, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { IconButton } from "../../../components";
import { FormInput, FormSelect, FormTextArea } from "../../../components/forms";
import MessageBox from "./MessageBox";
import { AttachmentsToggle, ThemedSpan } from "../../../components/utilities";
import SendMessageConfirmationModal from "./SendMessageConfirmationModal";
import useMessageUsers from "../hooks/useMessageUsers";
import { FileUploadModal } from "../../../components/modals";
import { useMessageThreadContext } from "../hooks/MessageThreadContext";

const MessageHeader = styled.div`
    grid-column-gap: 20px;
`;

/**
 * Base component for editable messages. Initially intended for draft messages and completely new ones,
 * however unsure if we are using completely new messages anymore...
 */

const EditableMessage = React.forwardRef(({
    message,
    isDraftMessage,
    messageOnBlur,
    messageOnChange,
    recipientsOnChange,
    recipientsOnBlur,
    priorityOnChange,
    projectOnChange,
    requiresActionOnChange,
    threadSubjectOnBlur,
    onSend,
    onDelete,
    onAttachmentUpload,
    isEditing,
    canEditSubject
}, ref) => {


    const {
        message: messageText,
        recipients,
        threadSubject,
        masterAccountId,
        attachments
    } = message || { attachments: [] };

    const { uploadAttachmentResult: { isLoading: isUploadingFile } } = useMessageThreadContext();
    const { results: messageUsers } = useMessageUsers(masterAccountId);
    const [shouldSend, setShouldSend] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(messageText);
    const [showModal, setShowModal] = useState(false);
    const [showFileUpload, setShowFileUpload] = useState(false);

    const onClickSend = () => {
        setShouldSend(_ => true);
    };

    const recipientOptions = useMemo(() => {
        // check we got some data
        if (!messageUsers || !Array.isArray(messageUsers)) {
            return [];
        }

        // now, lets setup the grouping
        return [
            {
                label: 'Master Account',
                options: messageUsers.filter((el) => el.userType === 0)
            },
            {
                label: 'System Users',
                options: messageUsers.filter((el) => el.userType === 1)
            }
        ]
    }, [messageUsers]);

    const handleOnMessageChange = (e) => {
        setCurrentMessage(e.target.value);
        messageOnChange(e);
    }

    useEffect(() => {
        if (!(isEditing === false && shouldSend === true)) return;

        onSend();

    }, [isEditing, shouldSend]);

    return <React.Fragment>
        <MessageBox ref={ref} className="mb-3 rounded" isDraft={isDraftMessage}>

            <div className="row mb-4">
                <MessageHeader className="row d-flex">
                    <div className="col d-flex flex-row align-items-center">
                        <div className="col-auto">
                            <ThemedSpan variant="" className={"me-3"}>
                                to:
                            </ThemedSpan>
                        </div>

                        <div className="col">
                            <FormSelect
                                isMulti
                                onChange={recipientsOnChange}
                                onBlur={recipientsOnBlur}
                                defaultValue={recipients.map(({ userId }) => userId)}
                                options={recipientOptions}
                                isLoadingOptions={!recipientOptions}
                                menuPosition="relative"
                                menuShouldScrollIntoView={false}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        {canEditSubject && <FormInput
                            placeholder="Subject"
                            value={threadSubject}
                            onBlur={threadSubjectOnBlur}
                        />}
                    </div>
                    <div className="col-auto d-flex justify-content-end align-items-start p-0">
                        {
                            isDraftMessage && <IconButton
                                className="me-3"
                                variant="danger"
                                icon={"fa-trash"}
                                onClick={onDelete}
                            />
                        }
                        <AttachmentsToggle
                            className={"me-3"}
                            isCreatable
                            menuDirection="start"
                            attachments={attachments}
                            onUploadFn={() => setShowFileUpload(true)}
                        />
                        <IconButton
                            onClick={() => setShowModal(true)}
                            icon={"fa-paper-plane"}
                            disabled={recipients.length === 0 || currentMessage === ""}
                        />
                    </div>
                </MessageHeader>

            </div>
            <div className="row">
                <FormTextArea
                    placeholder="New message..."
                    minRows={3}
                    value={messageText}
                    onChange={handleOnMessageChange}
                    onBlur={messageOnBlur}
                />
            </div>
        </MessageBox>
        <SendModal
            message={message}
            onRequiresActionChange={requiresActionOnChange}
            onPriorityChange={priorityOnChange}
            onProjectChange={projectOnChange}
            show={showModal}
            onClose={() => setShowModal(false)}
            onSend={onClickSend}
        />
        <FileUploadModal
            show={showFileUpload}
            handleClose={() => setShowFileUpload(false)}
            title="Upload Attachments"
            handleConfirm={(files, info) => onAttachmentUpload(files, info, { masterAccountId })}
            isUploadingFile={isUploadingFile}
        />
    </React.Fragment>;

});

const SendModal = memo(props => <SendMessageConfirmationModal {...props} />);

export default EditableMessage;
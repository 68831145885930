import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken, lighten } from "polished";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { FormInput } from "../../../components/forms";
import { ThemedSpan } from "../../../components/utilities";

const EmailLoadingPreview = ({ style }) => (
    <div className="border p-1" style={style}>
        <Skeleton
            height={24}
            count={3}
        />
    </div>
);

const EmailPreview = styled.div`
    &.active{
        background-color: ${props => lighten(props.lightenAmount, props.theme.secondary)};
    }
    &:hover {
        background-color: ${props => lighten(props.lightenAmount + 0.075, props.theme.secondary)};
    }
`;

const IconButton = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: ${props => props.theme[props.variant]};

    &:hover {
        color: ${props => darken(props.theme.linkDarkenAmount, props.theme[props.variant])};
    }
`;

const ClientEmailPreviewRow = ({ index, data: { activeLightenAmount, data, isClearing, onClick, showActive, editButton = false, updateSubject, clearSubject }, style }) => {
    const emailMessage = data[index];

    const [isEditing, setIsEditing] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        if (isEditing)
            inputRef.current.focus();
    }, [isEditing])

    if (!emailMessage || isClearing) {
        return <EmailLoadingPreview style={style} />
    }

    const onPreviewClick = () => {
        onClick(emailMessage);
    }

    const onEditClick = () => {
        setIsEditing(true);
    }

    const onEditBlur = (newSubject) =>
        updateSubject(emailMessage.conversationId, newSubject)
            .finally(() => setTimeout(() => {
                if (document.activeElement !== inputRef.current)
                    setIsEditing(false);
            }, 1250));

    const onClearClick = () => {
        clearSubject(emailMessage.conversationId);
    }

    return <EmailPreview
        key={emailMessage.graphId}
        className={`p-0 py-1 m-0 border-bottom has-pointer ${showActive && showActive(emailMessage) ? "active" : ""}`}
        lightenAmount={activeLightenAmount ?? 0.4}
        style={style}
        onClick={onPreviewClick}
    >
        <div className={`py-3 px-2`}>
            <div className="d-flex justify-content-between">
                <ThemedSpan className="text-truncate">{emailMessage.senderEmailAddress ?? "<No sender>"}</ThemedSpan>
            </div>
            {isEditing
                ? <span onBlur={() => inputRef.current.defaultValue === (emailMessage.customSubject ?? emailMessage.subject) && setIsEditing(false)}>
                    <FormInput
                        ref={inputRef}
                        value={emailMessage.customSubject ?? emailMessage.subject}
                        onBlur={onEditBlur}
                    />
                </span>
                : <p className="m-0 text-truncate">
                    {editButton && emailMessage.customSubject != null && <IconButton className="fa-fw me-1" size="lg" icon="xmark" variant="danger" onClick={onClearClick} />}
                    {editButton && <IconButton className="fa-fw me-1" icon="pen" variant="muted" onClick={onEditClick} />}
                    {emailMessage.customSubject ?? emailMessage.subject ?? "<No subject>"}
                </p>}
            <p className="m-0 text-muted text-truncate">{emailMessage.bodyPreview ?? "No preview available."}</p>
        </div>
    </EmailPreview>
};

export default ClientEmailPreviewRow;
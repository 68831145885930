import styled from "styled-components";
import { Button } from "../../../components";
import { FormCheck, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { useClientEmails, ClientEmailViewTypes } from "../hooks/ClientEmailsContext";
import React from "react";

const StyledFormSelect = styled(FormSelect)`
    width: 250px;
`;

const ClientEmailHeader = () => {

    const { attachmentsOnly, setAttachmentsOnly, searchTerm, setSearchTerm, viewType, setViewType } = useClientEmails();

    return <div className="d-flex justify-content-between p-2">
        <StyledFormSelect
            options={[
                { label: "View Email Messages", value: ClientEmailViewTypes.Emails },
                { label: "View Conversations", value: ClientEmailViewTypes.Conversations },
            ]}
            defaultValue={viewType}
            onChange={(value) => setViewType(value.value)}
        />
        <div className="d-flex">
            <FormCheck
                label="Messages with attachments"
                horizontal
                isChecked={attachmentsOnly}
                onChange={(value) => setAttachmentsOnly(value)}
            />
            <FormInput
                className="ms-3 me-2"
                placeholder="Search Emails..."
                value={searchTerm}
                onBlur={(value) => setSearchTerm(value)}
            />
        </div>
    </div>;
};

export default ClientEmailHeader;
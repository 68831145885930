import { useFetchApplicationsForMasterAccountQuery } from "../../../services/headofficeadmin";

const useAccountApplications = (masterAccountId) => {

    console.log(masterAccountId);

    const { data, isLoading, isFetching } = useFetchApplicationsForMasterAccountQuery({ masterAccountId, excludeExistingFees: true });

    return {
        data: data || [],
        isLoading: isLoading || isFetching
    };
};

export default useAccountApplications;
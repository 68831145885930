import React from "react";
import SalutationListRow from "./SalutationListRow";
import StandardTable from "../../../components/tables/StandardTable";
import useSalutationPreferences from "../hooks/useSalutationPreferences";
import { IconButton } from "../../../components/buttons";

const SalutationList = () => {
    const {
        data,
        error,
        isCreating,
        isError,
        isLoading,
        reload,
        createSalutation,
        deleteSalutation,
        updateSalutation,
        setSalutationAsDefault
    } = useSalutationPreferences();

    const onCreateSalutationPressed = (e) => createSalutation(e);

    const onDefaultSalutationChanged = (e, salutationId) => setSalutationAsDefault(salutationId);

    const onDeleteSalutationPressed = (e, salutationId) => deleteSalutation(salutationId);

    const onFieldChanged = (salutationId, property, value) => updateSalutation(salutationId, property, value);

    return (
        <StandardTable 
            className="table-borderless"
            columns={[
                { label: 'Client Account', property: 'clientAccountId' },
                { label: 'Addressee', property: 'addressee' },
                { label: 'Salutation (Adviser)', property: 'salutationFromAdviser' },
                { label: 'Salutation (Formal)', property: 'salutationFormal' },
                { label: 'Default', property: 'isDefault', headerClassName: 'text-center' },
                { 
                    label: '', 
                    property: '',
                    headerRenderer: (props) => (
                        <td key={props.key} style={{ width: '32px' }}>
                            <IconButton icon="fa-plus" variant="success" onClick={onCreateSalutationPressed} />
                        </td>
                    )
                }
            ]}
            data={data}
            error={error}
            isCreating={isCreating}
            isLoading={isLoading}
            RowComponent={SalutationListRow}
            rowComponentProps={{
                onDefaultChanged: onDefaultSalutationChanged,
                onDelete: onDeleteSalutationPressed,
                onChange: onFieldChanged
            }}
        />
    );
};

export default SalutationList;
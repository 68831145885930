import { wealthProApi } from "../../redux/api";

const estatePlanningApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchGifts: builder.query({
            query: ({ estatePlanningId, page, limit }) => {
                if (page || limit) {
                    return `api/factfind/estateplanning/${estatePlanningId}/gifts?page=${page ?? 1}&limit=${limit ?? 25}`;
                }

                return `api/factfind/estateplanning/${estatePlanningId}/gifts`;
            }
        }),
        patchEstatePlanningSection: builder.mutation({
            query: ({ estatePlanningId, operations }) => ({
                url: `api/factfind/estateplanning/${estatePlanningId}`,
                method: 'PATCH',
                body: operations
            })
        })
    })
});

export const {
    useLazyFetchGiftsQuery,
    usePatchEstatePlanningSectionMutation
} = estatePlanningApiEndpoints;
import React, { useEffect } from "react";
import SummaryTable from "./SummaryTable";
import useReviewTargetSuccessRatesGroup from "../hooks/useReviewTargetSuccessRatesGroup";
import { useFilterContext } from "../../../hooks/FilterContext";

const ReviewStatusGroup = () => {

    const { filter } = useFilterContext();
    const { search, ...rest } = useReviewTargetSuccessRatesGroup(filter);
    const { data, error, isLoading, isFetching } = rest;
    
    useEffect(() => {
        const delayedRequest = setTimeout(() => search(filter), 500);
        return () => clearTimeout(delayedRequest);
    }, [ filter ]);

    return <SummaryTable label="Service Target Success Rates" data={data} error={error} isLoading={isLoading === true || isFetching === true} showTotals={false} showAverages={true} suffix={"%"}/>
};

export default ReviewStatusGroup;
import { wealthProApi } from '../../redux/api';

const businessSourcesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["clientBusinessSources"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchClientBusinessSources: builder.query({
            query: () => `api/clientdetails/business-sources`,
        }),
        fetchClientBusinessSourcesList: builder.query({
            query: () => `api/clientdetails/business-sources/list`,
        })
    })
});

export const {
    useFetchClientBusinessSourcesQuery,
    useFetchClientBusinessSourcesListQuery
} = businessSourcesApiEndpoints;
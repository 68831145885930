import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, DateInput, FormInput, FormSelect, FormTextArea, PercentageInput, RichTextEditor } from "../../../components/forms";
import { BooleanSelect } from "../../../components/forms/selects";
import useClientAccounts from "../hooks/useClientAccounts";
import TaxYearEndWithdrawals from "./TaxYearEndWithdrawals";

const PensionTypeOptions = [{
    label: "Stakeholder",
    value: 10
},
{
    label: "Personal Pension",
    value: 20
},
{
    label: "SIPP",
    value: 30
},
{
    label: "Workplace Pension",
    value: 40
},
// Removing this from the selections as these are added in a different way
/* {
    label: "Final Salary",
    value: 50
} */];

const FormRow = ({ children, className }) => <div className={`row mb-2 ${className ?? ""}`}>{children}</div>;

const PensionArrangement = ({ pensionArrangement, patchArrangement, removeArrangement, isLoading }) => {

    const { data: clientAccounts, isLoading: isLoadingClients } = useClientAccounts({ fetchType: "list" });
    const [withdrawalsTaken, setWithdrawalsTaken] = useState(pensionArrangement.withdrawalsTaken);
    const [currentContributions, setCurrentContributions] = useState(pensionArrangement.currentContributions)

    const calculateYieldValue = useMemo(() => {
        return pensionArrangement.currentAnnualWithdrawals / pensionArrangement.valueOfFund * 100;
    }, [pensionArrangement.currentAnnualWithdrawals, pensionArrangement.valueOfFund]);

    // Used for determining whether to show the withdrawal select onChange.
    // Stakeholder and Workplace pensions do not have the option to withdraw.
    const [realtimePensionType, setRealTimePensionType] = useState(pensionArrangement?.pensionType);
    const showWithdrawalSelect = useMemo(() => ![10, 40].includes(realtimePensionType), [realtimePensionType]);

    useEffect(() => {
        setRealTimePensionType(pensionArrangement?.pensionType)
    }, [pensionArrangement?.pensionType]);

    const [showNominationDate, setShowNominationDate] = useState(pensionArrangement.deathBenefitNomination);

    useEffect(() => {
        setShowNominationDate(pensionArrangement.deathBenefitNomination)
    }, [pensionArrangement.deathBenefitNomination]);

    return <div className="container-fluid px-0">
        <FormRow>
            <div className="col-6 col-md">
                {isLoading
                    ? <Skeleton height={30} />
                    : <FormInput
                        label={"Pension Name"}
                        value={pensionArrangement.pensionName}
                        onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "pensionName", value)}
                    />}
            </div>
            <div className="col-6 col-md">
                {isLoading
                    ? <Skeleton height={30} />
                    : <FormSelect
                        label={"Type"}
                        options={PensionTypeOptions}
                        defaultValue={pensionArrangement.pensionType}
                        onChange={({ value }) => setRealTimePensionType(value)}
                        onBlur={(e) => patchArrangement(pensionArrangement.pensionPlanId, "pensionType", e.value)}
                    />}
            </div>
            <div className="col-6 col-md">
                {isLoading
                    ? <Skeleton height={30} />
                    : <FormSelect
                        label={"Owner"}
                        options={clientAccounts}
                        isLoadingOptions={isLoadingClients}
                        defaultValue={pensionArrangement.clientAccountId}
                        onBlur={(e) => patchArrangement(pensionArrangement.pensionPlanId, "clientAccountId", e.value)}
                    />}
            </div>
            <div className="col-1">
                {isLoading
                    ? <Skeleton height={30} />
                    : <FormInput
                        label={"NRA"}
                        value={pensionArrangement.nra}
                        onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "nra", value)}
                    />}
            </div>
            <div className="col-auto mt-auto">
                <IconButton
                    icon={"fa-trash"}
                    variant={"danger"}
                    onClick={() => removeArrangement(pensionArrangement.pensionPlanId)}
                    disabled={isLoading}
                />
            </div>
        </FormRow>
        {!pensionArrangement.isFinalSalary && <React.Fragment>
            <FormRow className={"gx-5"}>
                <div className="col-auto">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <BooleanSelect
                            label={"Current Contributions"}
                            defaultValue={pensionArrangement.currentContributions}
                            onBlur={({ value }) => patchArrangement(pensionArrangement.pensionPlanId, "currentContributions", value)}
                            onChange={({ value }) => setCurrentContributions(value)}
                        />}
                </div>
                {currentContributions && <div className="col-12 col-md">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <CurrencyInput
                            label={"Personal Contribution (Monthly)"}
                            value={pensionArrangement.personalContributions}
                            decimalScale={0}
                            allowNegative={false}
                            onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "personalContributions", value)}
                        />}
                </div>}
                {currentContributions && <div className="col-12 col-md">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <CurrencyInput
                            label={"Employer Contribution (Monthly)"}
                            value={pensionArrangement.employerContributions}
                            decimalScale={0}
                            allowNegative={false}
                            onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "employerContributions", value)}
                        />}
                </div>}
            </FormRow>
            <FormRow className={"d-flex"}>
                <div className="col-3 col-xl-2">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <BooleanSelect
                            label={"Death Benefit Nomination"}
                            defaultValue={pensionArrangement.deathBenefitNomination}
                            onChange={({ value }) => setShowNominationDate(value)}
                            onBlur={({ value }) => patchArrangement(pensionArrangement.pensionPlanId, "deathBenefitNomination", value)}
                        />}
                </div>
                {showNominationDate
                    ? <div className="col-3 col-xl-2">
                        {isLoading
                            ? <Skeleton height={30} />
                            : <DateInput
                                label={"Nomination Date"}
                                value={pensionArrangement.nominationDate ?? ""}
                                isClearable
                                onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "nominationDate", value)}
                            />}
                    </div>
                    : <></>}
                {showNominationDate
                    ? <div className="col">
                        {isLoading
                            ? <Skeleton height={30} />
                            : <FormTextArea
                                label={"Details of Beneficiaries"}
                                value={pensionArrangement.beneficiaryDetails}
                                onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "beneficiaryDetails", value)}
                            />}
                    </div>
                    : <></>}
            </FormRow>
            <FormRow className={"d-flex"}>
                <div className="col-12 col-lg-3 col-md-4">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <CurrencyInput
                            label={"Value of Fund"}
                            value={pensionArrangement.valueOfFund}
                            decimalScale={0}
                            allowNegative={false}
                            onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "valueOfFund", value)}
                        />}
                </div>
                {showWithdrawalSelect
                    ? <div className='col-3 col-lg-2 offset-lg-1'>
                        {isLoading
                            ? <Skeleton height={30} />
                            : <BooleanSelect
                                label={"Withdrawals Taken"}
                                defaultValue={pensionArrangement.withdrawalsTaken}
                                onBlur={({ value }) => patchArrangement(pensionArrangement.pensionPlanId, "withdrawalsTaken", value)}
                                onChange={({ value }) => setWithdrawalsTaken(value)}
                            />}
                    </div>
                    : <></>}
                {(showWithdrawalSelect && withdrawalsTaken)
                    ? <>
                        <div className="col-12 col-lg-3 col-md-4 offset-lg-1">
                            {isLoading
                                ? <Skeleton height={30} />
                                : <CurrencyInput
                                    label={"Current Annual Withdrawals"}
                                    value={pensionArrangement.currentAnnualWithdrawals}
                                    decimalScale={0}
                                    allowNegative={false}
                                    onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "currentAnnualWithdrawals", value)}
                                />}
                        </div>
                        <div className="col-2 col-md-1 offset-lg-1">
                            {isLoading
                                ? <Skeleton height={30} />
                                : <PercentageInput
                                    label={"Yield"}
                                    disabled={true}
                                    value={calculateYieldValue}
                                    fixedDecimalScale={false}
                                />}
                        </div>
                    </>
                    : <></>}
            </FormRow>
            {(showWithdrawalSelect && withdrawalsTaken) && <FormRow>
                <div className="col-6 col-md-3">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <FormSelect
                            label={"Drawdown Type"}
                            options={[
                                {
                                    label: "Flexi-Access",
                                    value: 20
                                },
                                {
                                    label: "Capped Drawdown",
                                    value: 30
                                },
                            ]}
                            defaultValue={pensionArrangement.drawdownType}
                            onBlur={(e) => patchArrangement(pensionArrangement.pensionPlanId, "drawdownType", e.value)}
                        />}
                </div>
                {// 30 == "Capped Drawdown" in drawdownTypes from usePensionArrangements
                    pensionArrangement.drawdownType === 30 && <div className="col-6 col-md-3">
                        <CurrencyInput
                            label={"Maximum GAD Income Allowed"}
                            value={pensionArrangement.maxGADIncomeAllowed}
                            decimalScale={0}
                            allowNegative={false}
                            onChange={(value) => patchArrangement(pensionArrangement.pensionPlanId, "maxGADIncomeAllowed", value)}
                        />
                    </div>}
            </FormRow>}
            {(showWithdrawalSelect && withdrawalsTaken) && <FormRow className={"mb-2"}>
                <div className="col-12 col-md">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <DateInput
                            label={"Values at"}
                            value={pensionArrangement.valuesAt}
                            isClearable
                            onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "valuesAt", value)}
                        />}
                </div>
                <div className="col-12 col-md">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <CurrencyInput
                            label={"Crystallised Value"}
                            value={pensionArrangement.crystallisedValue}
                            decimalScale={0}
                            allowNegative={false}
                            onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "crystallisedValue", value)}
                        />}
                </div>
                <div className="col-12 col-md">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <CurrencyInput
                            label={"Uncrystallised Value"}
                            value={pensionArrangement.uncrystallisedValue}
                            decimalScale={0}
                            allowNegative={false}
                            onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "uncrystallisedValue", value)}
                        />}
                </div>
                <div className="col-12 col-md">
                    {isLoading
                        ? <Skeleton height={30} />
                        : <CurrencyInput
                            label={"Available Tax Free"}
                            value={pensionArrangement.availableTaxFree}
                            decimalScale={0}
                            allowNegative={false}
                            onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "availableTaxFree", value)}
                        />}
                </div>
            </FormRow>}
            {showWithdrawalSelect && withdrawalsTaken
                && <TaxYearEndWithdrawals pensionPlanId={pensionArrangement.pensionPlanId} />}
        </React.Fragment>}
        <FormRow>
            {isLoading
                ? <Skeleton height={60} />
                : <RichTextEditor
                    label="Additional Notes"
                    defaultValue={pensionArrangement.notes}
                    onBlur={(value) => patchArrangement(pensionArrangement.pensionPlanId, "notes", value)}
                />}
        </FormRow>
    </div>
};

export default PensionArrangement;
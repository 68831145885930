import moment from "moment";
import { useEffect, useState } from "react";
import { useCreateMasterAccountDetailsMutation } from "../../../services/clientdetails";

export const CreateMasterAccountDefaultState = { 
    divisionId: null, 
    accountName: "",
    isOverseas: false, 
    countryCode: "GB",
    type: 0, 
    status: 0, 
    statusDate: moment().format(),
    businessSourceId: "980b4da7d8054d15a0fd3cb89100e511",
    businessSourceDate: moment().format(),
    riskGrade: 0,
    riskGradeDate: moment().format(),
    adviserId: null,
    isFavourited: false
};

const useCreateMasterAccount = (divisionId = null) => {
    const [create, createResults] = useCreateMasterAccountDetailsMutation();
    const [defaultValues, setDefaultValues] = useState({
        ...CreateMasterAccountDefaultState,
        divisionId
    });

    const handleCreate = (data) => {
        return new Promise((resolve, reject) => {
            return create({ data }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    useEffect(() => {
        setDefaultValues((current) => { return { ...current, divisionId } });
    }, [ divisionId ]);

    return {
        createResults,
        defaultValues,
        handleCreate
    }
};

export default useCreateMasterAccount;
import styled from "styled-components";
import { Offcanvas } from "react-bootstrap";

const CanvasBody = styled(Offcanvas.Body)`
    display: grid;
    min-height: 100%;
    background-color: #fff;
    grid-template-rows:     auto 1fr auto;
    grid-template-areas:    "profile"
                            "navigation"
                            "footer";
`;

export default CanvasBody;
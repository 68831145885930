import React, { useCallback, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { GridWrapper } from "../../../components";
import { DivestTransactionTable, FeeCollection, Income, InvestmentSelection, ProductSelection } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

const SellSwitch = ({ instructionId }) => {
    const [{ fetchInvest, fetchDivest }, { investIsError, investIsLoading, investIsFetching, divestIsLoading, divestIsFetching, divestIsError }, { realTimeRemainder }] = useInstruction();

    useEffect(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId });
    }, [fetchDivest, fetchInvest, instructionId]);

    const retry = useCallback(() => {
        fetchInvest({ instructionId });
        fetchDivest({ instructionId });
    }, [fetchDivest, fetchInvest, instructionId]);

    if (investIsError || divestIsError)
        return <InvestDivestErrorMessage retry={retry} />

    if (investIsLoading || investIsFetching || divestIsLoading || divestIsFetching)
        return <Skeleton count={10} />

    return <GridWrapper>
        <ProductSelection
            changeOwner
            productLabel="Sell from Product:" />
        <DivestTransactionTable isSelling />
        <Income clientWithdrawal retainAmount />
        <InvestmentSelection maxInvestmentAmount={realTimeRemainder} />

        {realTimeRemainder > 0 && <FeeCollection />}
        {/* {validDivestAmount
            ? <AnnualCostsDisclosureComparisonTable />
            : <AnnualCostsDisclosureTable isDisposing />} */}
    </GridWrapper>;
};

export default SellSwitch;

import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const hoApplicationPoliciesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchApplicationPolicy: builder.mutation({
            query: ({ applicationPolicyId, operations }) => ({
                url: `api/headofficeadmin/application-policies/${applicationPolicyId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ applicationId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchApplication', { applicationId }, data => {
                    applyPatch(data, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            },
            invalidatesTags: ['applicationInfo']
        })

    })
});

export const {
    usePatchApplicationPolicyMutation
} = hoApplicationPoliciesApiEndpoints;
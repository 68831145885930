import { useSearchFeeGroupTotalsQuery } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";
const useFeeTotals = () => {

    const { filter } = useFilterContext();
    const { data, isLoading, isFetching, isError, error, refetch } = useSearchFeeGroupTotalsQuery({
        ...filter,
        adviserIds: filter?.advisers?.map(x => x.value),
        introducerIds: filter?.introducers?.map(x => x.value),
        masterAccountIds: filter?.masterAccounts?.map(x => x.value),
        providerIds: filter?.providers?.map(x => x.value),
    });

    const retryGetFeeTotals = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    }

    return {
        data,
        isFetching,
        isLoading,
        isError,
        error,
        retryGetFeeTotals
    }
};

export default useFeeTotals;
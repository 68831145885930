import { useMemo } from "react";
import {
    useFetchBizSheetsForAccountQuery,
    useRecsFetchInstructionTypesQuery
} from "../../../services/recommendations";

const useFetchDropdownOptions = (masterAccountId) => {

    const {
        data: sheets,
        isLoading: fetchSheetsIsLoading,
        isFetching: isFetchingBizSheets,
        isError: errorFetchingSheets,
        refetch: refetchSheets
    } = useFetchBizSheetsForAccountQuery({ masterAccountId })

    const {
        data: instructionTypes,
        isLoading: fetchInstructionTypesIsLoading,
        isFetching: fetchingInstructionTypes,
        isError: errorFetchingInstructionTypes,
        refetch: refetchInstructions,
    } = useRecsFetchInstructionTypesQuery({ listType: "List" });

    const retry = () => {
        refetchSheets();
        refetchInstructions();
    }

    const isLoading = useMemo(() => {
        return fetchInstructionTypesIsLoading || fetchSheetsIsLoading;
    }, [fetchInstructionTypesIsLoading, fetchSheetsIsLoading]);

    const isFetching = useMemo(() => {
        return isFetchingBizSheets || fetchingInstructionTypes
    }, [isFetchingBizSheets, fetchingInstructionTypes]);

    const isError = useMemo(() => {
        return errorFetchingSheets || errorFetchingInstructionTypes
    }, [errorFetchingInstructionTypes, errorFetchingSheets]);

    return {
        sheets,
        instructionTypes,
        isLoading,
        isFetching,
        isError,
        retry
    }
};

export default useFetchDropdownOptions;
import { useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { useApplyProductCostsMutation, useLazyFetchRecommendationProductCostsQuery, useLinkProductCostsMutation, useSaveRecommendationProductCostsMutation } from "../../../services/recommendations";

const costTypes = [
    { label: "One Off", value: 0 },
    { label: "Sell", value: 1 },
    { label: "Buy", value: 2 },
    { label: "Inspecie", value: 3 },
    { label: "Drawdown", value: 4 },
    { label: "Annual", value: 5 },
    { label: "Income", value: 6 },
];

const calculations = [
    { label: "Fixed", value: 0 },
    { label: "Percent", value: 1 },
];

const postToOptions = [
    { label: "Before", value: 10 },
    { label: "After", value: 20 },
];

const useProductCosts = (instructionId, productId, hasProductCosts, handleClose, initialCosts, section) => {

    const [fetchRecommendationProductCosts, { data: recProductCosts, isFetching: recProductCostsIsFetching, isSuccess: recProductCostsIsSuccess, isError: recProductCostsIsError, error: recProductCostsError, isUninitialized: recProductCostsIsUninitialised }] = useLazyFetchRecommendationProductCostsQuery();
    const [postUpdatedList, { isLoading: postIsLoading, isSuccess: postIsSuccess, isError: postIsError, error: postError }] = useSaveRecommendationProductCostsMutation();

    // Converts the ProductCost entity to a RecommendationProductCostEntity, linked to the current instruction
    const [linkProductCosts, { error: linkCostsError, isLoading: linkCostsIsLoading, isError: linkCostsIsError, isSuccess: linkCostsIsSuccess, isUninitialized: linkCostsIsUninitialised }] = useLinkProductCostsMutation();

    // Sets the applied flag on the instruction
    const [applyProductCosts, { isError: applyCostsIsError, error: applyCostsError, isLoading: applyCostsIsLoading }] = useApplyProductCostsMutation();

    const isError = useMemo(() =>
        recProductCostsIsError || linkCostsIsError || applyCostsIsError,
        [applyCostsIsError, linkCostsIsError, recProductCostsIsError])
    const error = useMemo(() => isError ? {
        applyCosts: {
            error: applyCostsError,
            message: "Error applying those costs to the current instruction"
        },
        linkCosts: {
            error: linkCostsError,
            message: "Error adding those product costs to the current instruction"
        },
        recProductCosts: {
            error: recProductCostsError,
            message: "Error fetching costs for the current instruction"
        },
    } : null, [applyCostsError, isError, linkCostsError, recProductCostsError]);

    const [selectedProductId, setSelectedProductId] = useState(productId);

    const isLoading = useMemo(() => {
        if (applyCostsIsLoading) {
            return true;
        }

        if (!linkCostsIsUninitialised) {
            return linkCostsIsLoading || recProductCostsIsUninitialised || recProductCostsIsFetching;
        }

        return false;
    },
        [applyCostsIsLoading, linkCostsIsLoading, linkCostsIsUninitialised, recProductCostsIsFetching, recProductCostsIsUninitialised]);

    const [recProductCostsDefault, setRecProductCostsDefault] = useState(initialCosts || []);
    const isReady = useMemo(() => isLoading
        ? false
        : !isError, [isLoading, isError]);

    useEffect(() => {
        if (!recProductCostsIsFetching && recProductCostsIsSuccess)
            setRecProductCostsDefault(recProductCosts);
    }, [recProductCosts, recProductCostsIsFetching, recProductCostsIsSuccess])

    useEffect(() => {
        if (hasProductCosts && recProductCostsIsUninitialised) {
            fetchRecommendationProductCosts({ instructionId, section });
        }
    }, [fetchRecommendationProductCosts, hasProductCosts, instructionId, recProductCostsIsUninitialised, section])

    useEffect(() => {
        if (productId !== selectedProductId) {
            setSelectedProductId(productId);

            if (selectedProductId === undefined)
                return;

            linkProductCosts({ instructionId, productId, section })
                .unwrap()
                .then(() => {
                    fetchRecommendationProductCosts({ instructionId, section });
                });
        }
    }, [fetchRecommendationProductCosts, instructionId, linkProductCosts, productId, section, selectedProductId])

    const addRowInState = () => {
        setRecProductCostsDefault(oldList => [
            ...oldList,
            {
                id: v4(),
                instructionId,
                section,
                apply: true,
                isActive: true
            }
        ]);
    }
    const patchRowInState = (index, property, newValue) => {
        setRecProductCostsDefault(oldList => {
            var listCopy = [...oldList];
            var objCopy = { ...(listCopy)[index] };
            objCopy[property] = newValue;
            listCopy[index] = objCopy;
            return listCopy;
        });
    }
    const removeRowInState = (index) => {
        setRecProductCostsDefault(oldList => {
            var listCopy = [...oldList];
            var objCopy = { ...listCopy[index], isActive: false };
            listCopy.splice(index, 1, objCopy);
            return listCopy;
        })
    }
    const saveChanges = () => {
        handleClose();
        postUpdatedList({ instructionId, updatedList: recProductCostsDefault, section });
    }
    const cancelChanges = () => {
        handleClose();
        // Reset on timeout to wait for close to finish
        setTimeout(() => setRecProductCostsDefault(recProductCosts), 250);
    }

    return [
        {
            addRow: addRowInState,
            patchRow: patchRowInState,
            removeRow: removeRowInState,
            saveChanges,
            cancelChanges
        },
        {
            recProductCosts: recProductCostsDefault?.filter(cost => cost.isActive) ?? [],
            options: [postToOptions, costTypes, calculations],
            isReady,
            isError,
            error,
            isSaving: postIsLoading,
            isSaved: (JSON.stringify(recProductCosts) === JSON.stringify(recProductCostsDefault)),
            saveSuccess: postIsSuccess,
            saveIsError: postIsError
        }
    ];
}

export default useProductCosts;
import _, { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../../components/buttons";
//import FeedbackFilterList from "./components/FeedbackFilterList";
//import EfficacyStatusSelect from "./components/EfficacyStatusSelect";
import { Modal } from "react-bootstrap";
import { useDocumentFilterContext } from "../../hooks/DocumentFilterContext";
import { useClientContext } from "../../hooks/ClientContext";
import { DateInput, 
         FormCheck
} from "../../components/forms";
import ClientProjectSearch from "../clientprojectsearch";
import { 
    GenericUserSelect,
    DocumentTypesSelect,
    DocumentFunctionsSelect,
    ProjectSelect,
    MasterAccountSelect,
    ClientProjectSelect,
    BooleanSelect
} from "../../components/forms/selects";


export const ClientDocumentFiltersModalButton = ({ className, variant = "primary", ...rest }) => {
    return <div className={className}>
        <Button variant={variant} {...rest}>Filter Documents</Button>
    </div>
};

export const ClientDocumentFiltersModal = ({ handleClose, onFiltersChanged, show, ...rest }) => {
    
    const { defaultFilters, filter, setFilter } = useDocumentFilterContext();
    {/*const { defaultFilters, clearFilter, filter: { masterAccountIds }, filter, setFilter } = useDocumentFilterContext();*/}
    
    const [tempFilters, setTempFilters] = useState(filter);

    {/*const { clearFilter, filter: { masterAccountIds }, isFiltersApplied, setFilter } = useDocumentFilterContext();*/}

    {/*<const { control, formState: { errors, touchedFields }, handleSubmit, reset, setValue } = useForm({ defaultValues });*/}

    const { account: { masterAccountId } } = useClientContext();

    //not really needed for all this form stuff but go with it for now
    //const { control, formState: { errors }, getValues, handleSubmit, reset, setValue } = useForm({ defaultValues });
    
    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(_ => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof(handleClose) === 'function') {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    return <Modal size="lg" centered backdrop="static" show={show}>
        <Modal.Header>Client Document Filters</Modal.Header>
        <Modal.Body {...rest}>
            <ClientDocumentFilters 
                filter={tempFilters}
                setFilter={onFilterValueChanged}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
            <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>

};


const ClientDocumentFilters = ({ filter, setFilter }) => {
    const { 
        documentTypes,
        documentFunctions,
        projectIds,
        masterAccountIds,
        uploadedByUserIds,
        dateUploadedFrom,
        dateUploadedTo,
        clientReadable,
        hideReportDocs = true
    } = filter;

    return <React.Fragment>
        <div className="row mb-3">
        <div className="col">
                <BooleanSelect
                    label="Client Access"
                    defaultValue={clientReadable}
                    disableAnimations={true}
                    //isMulti={true}
                    onBlur={(value) => setFilter('clientReadable', value.value)}
                    //onBlur={(value) => setFilter('allowClientAccess', true)}
                />
            </div>
            <div className="col">
                <DocumentTypesSelect
                    label="Document Types"
                    isMulti={true}
                    defaultValue={documentTypes}
                    disableAnimations={true}
                    onBlur={(values) => setFilter('documentTypes', values.map(el => el.value))}
                />
            </div>
            <div className="col">
                <DocumentFunctionsSelect
                    label="Document Functions"
                    isMulti={true}
                    defaultValue={documentFunctions}
                    disableAnimations={true}
                    onBlur={(values) => {
                        if (values.map(el => el.label).some(label => label.includes("Report")))
                            setFilter('hideReportDocs', false);
                        
                        setFilter('documentFunctions', values.map(el => el.value));
                    }}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                {/*<DocumentTypesSelect
                    label="Project"
                    isMulti={true}
                    defaultValue={project}
                    disableAnimations={true}
                    onBlur={(values) => setFilter('project', values.map(el => el.value))}
                />*/}
                {/*<Controller
                    name="projectId"
                    control={control}
                    //rules={({ required: "A Project must be assigned to a Report." })}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <ClientProjectSearch
                            buttonSize="sm"
                            label="Project"
                            labelPosition="top"
                            formError={errors?.projectId?.message} 
                            value={value}
                            onProjectCreated={(project) => setFilter('projectId', project.projectId)}
                            onProjectSelected={(_, project) => setFilter('projectId', project.projectId)}
                            {...rest}
                        />
                    )}
                />*/}
                {/*<Controller
                    name="projectId"
                    control={control}
                    rules={({ required: "A Project must be assigned to a Report." })}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <ClientProjectSearch
                            buttonSize="sm"
                            defaultProjectId={value}
                            label="Project"
                            labelPosition="top"
                            formError={errors?.projectId?.message} 
                            onProjectSelected={(project) => setValue('projectId', project.projectId)}
                            {...rest}
                        />
                    )}
                />*/} 
                {/*<MasterAccountSelect
                    className="me-2 w-75"
                    label="Projects"
                    placeholder="Select..."
                    isMulti={true}
                    value={masterAccountIds}
                        setValue={(values) => setFilter(original => {
                            return {
                                 ...original,
                                    masterAccountIds: values.map(el => el.value)
                            }
                        })}
                />
                <MasterAccountSelect
                    className="me-2 w-75"
                    label="Projects"
                    placeholder="Select..."
                    isMulti={true}
                    defaultValue={uploadedByUserIds}
                    disableAnimations={true}
                    onBlur={(values) => setFilter('masterAccountIds', values.map(el => el.value))}
                />*/}
            </div>
            <div className="row mb-3">
                <div className="col">
                {/*<ProjectSelect
                    className="me-2 w-75"
                    label="Projects"
                    placeholder="Select..."
                    isMulti={true}
                    value={projectIds}
                        setValue={(values) => setFilter(original => {
                            return {
                                 ...original,
                                    projectIds: values.map(el => el.value)
                            }
                        })}
                />*/}
                </div>
                <div className="row mb-3">
                    <div className="col">
                    <ClientProjectSelect 
                        label={"Project"} 
                        isMulti={true}
                        value={projectIds}
                        setValue={(values) => setFilter('projectIds', values.map(el => el.value))}
                        //setValue={(values)=> console.log(values)}
                    />
                    </div>
                </div>
            </div>

        </div>
        <div className="row mb-3">
           <div className="col">
            <GenericUserSelect
                label="Uploaded By"
                isMulti={true}
                defaultValue={uploadedByUserIds}
                disableAnimations={true}
                onBlur={(values) => setFilter('uploadedByUserIds', values.map(el => el.value))}
            />
            </div>
            <div className="col">
            <DateInput
                label="Date Uploaded From"
                //className="form-control"
                //dateFormat="yyyy-MM-dd"
                isClearable
                disableAnimations={true}
                //selected={dateUploadedFrom}
                value={dateUploadedFrom}
                placeholder="Date Uploaded From"
                //onChange={(e) => setFilter('dateUploadedFrom', e.value)}
                onBlur={(value, _) => setFilter('dateUploadedFrom', value)}
             />
            </div>
            <div className="col">
            <DateInput
                label="Date Uploaded To"
                //className="form-control"
                //dateFormat="yyyy-MM-dd"
                isClearable
                disableAnimations={true}
                //selected={dateUploadedTo}
                placeholder="Date Uploaded To"
                value={dateUploadedTo}
                //onChange={(e) => setFilter('dateUploadedTo', e.value)}
                onBlur={(value, _) => setFilter('dateUploadedTo', value)}
             />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
            <FormCheck
                isChecked={hideReportDocs}
                label="Hide Report Documents"
                onChange={value => setFilter("hideReportDocs", value)}
                disableAnimations
                />
            </div>
        </div>

    </React.Fragment>
};

export default ClientDocumentFilters;
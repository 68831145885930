import React from "react";
import { useLazyGetCredentialsForLoginQuery } from "../../../services/clientauth";

const useLoginCredentials = (masterAccountId, loginId) => {
    const [_get, { data, isLoading, ...getResults }] = useLazyGetCredentialsForLoginQuery();

    React.useEffect(() => {
        if (!masterAccountId || !loginId) {
            return;
        }
        _get({ masterAccountId, loginId });
    }, [ masterAccountId, loginId ]);

    return {
        data,
        isLoading,
        info: {
            data,
            isLoading,
            ...getResults
        }
    };
};

export default useLoginCredentials;
import { wealthProApi } from "../../redux/api";

const instructionTypesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["instructiontypes"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchInstructionTypes: builder.query({
            query: ({ page, limit, fetchType, sort }) => {
                if (page && limit) {
                    return `api/products/instructiontype?page=${page}&limit=${limit}&fetchType=${fetchType}&sort=${sort}`;
                }
                
                return `api/products/instructiontype?fetchType=${fetchType}&sort=${sort}`;
            }
        }),
        fetchInstructionTypesByWrapperId: builder.query({
            query: ({ wrapperId, fetchType, sort = "DisplayOrder", page, limit }) => {
                if (page && limit) {
                    return `api/products/instructiontype/${wrapperId}?fetchType=${fetchType}&sort=${sort}&page=${page}&limit=${limit}`;
                }
                return `api/products/instructiontype/${wrapperId}?fetchType=${fetchType}&sort=${sort}`;
            }
        })
    })
});

export const {
    useFetchInstructionTypesQuery,
    useFetchInstructionTypesByWrapperIdQuery,
    useLazyFetchInstructionTypesByWrapperIdQuery,
    useLazyFetchInstructionTypesQuery
} = instructionTypesApiEndpoints;
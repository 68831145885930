import { useSendMasterAccountDashboardLoginWelcomeEmailMutation } from "../../../services/clientauth";

const useCreateWelcomeEmail = () => {
    const [_send, { isLoading, ...sendResult }] = useSendMasterAccountDashboardLoginWelcomeEmailMutation();

    const send = (masterAccountId, loginId) => {
        return new Promise((resolve, reject) => {
            return _send({ masterAccountId, loginId })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { send, isLoading, data: { isLoading, ...sendResult } };
};

export default useCreateWelcomeEmail;
import React from "react";
import FormSelect from "../../../components/forms/FormSelect";

export const EfficacyStatuses = [
    { label: 'Accepted', value: 0 },
    { label: 'Rejected', value: 1 },
];

const EfficacyStatusSelect = ({ options, ...rest }) => {
    return <FormSelect 
        options={EfficacyStatuses.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default EfficacyStatusSelect;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { socket } from "./socket";
import { oidcClient } from "./token";

export const wealthProApi = createApi({
   reducerPath: 'wealthProApi',
   baseQuery: fetchBaseQuery({
      baseUrl: "/",
      prepareHeaders: async (headers, { getState }) => {
         // get the jwt token from our client handler
         let tokenData = oidcClient.getOidcToken();
         if (tokenData) {
            headers.set('Authorization', `${tokenData?.token_type} ${tokenData?.access_token}`);
         }

         // get the socket information so we can assign it to the headers of the request
         // this is useful when setting up the async updates, etc in future builds
         try {
            let socketId = await socket.getId();
            headers.set('socket-id', socketId);
         }
         catch {}

         // return the headers now ready to go up to the portal/gateway
         return headers;
      }
   }),
   refetchOnMountOrArgChange: true,
   endpoints: () => ({})
});
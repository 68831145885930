import React from "react";
import PersonalMessagesFilterModal from "./components/PersonalMessagesFilterModal";
import PersonalMessagesVirtualTable from "./components/PersonalMessagesVirtualTable";

export {
    PersonalMessagesFilterModal
};

const PersonalMessages = () => <PersonalMessagesVirtualTable />

export default PersonalMessages;
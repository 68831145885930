import { configureStore } from "@reduxjs/toolkit";
import { createReduxMiddleware } from "@karmaniverous/serify-deserify";
import { wealthProApi } from "./api";
import socketMiddleware, { socket } from "./socket";
import oidcTokenMiddleware, { oidcClient } from "./token";

const serifyMiddleware = createReduxMiddleware();

export default configureStore({
  reducer: {
    [wealthProApi.reducerPath]: wealthProApi.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    socketMiddleware(socket),
    oidcTokenMiddleware(oidcClient),
    wealthProApi.middleware,
    serifyMiddleware
  ]
});
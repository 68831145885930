import React from "react";
import FormSelect from "../FormSelect";
import { useFetchClientConnectionsListForMasterAccountQuery } from "../../../services/clientdetails";

const MasterAccountConnectionsSelect = ({ options, masterAccountId, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchClientConnectionsListForMasterAccountQuery({ masterAccountId });
    return <FormSelect
        options={data || []}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default MasterAccountConnectionsSelect;
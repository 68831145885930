import { useMemo } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { FormInput } from "../../../components/forms";
import { ThemedSpan } from "../../../components/utilities";
import { patchReplace } from "../../../helpers/patchDoc";
import { useLazyFetchAssetFromValuationsQuery } from "../../../services/valuations";
import { useInstruction } from "../contexts/InstructionContext";
import useSearchAssetBySedol from "../hooks/useSearchAssetBySedol";

const LargePopover = styled(Popover)`
    max-width: 400px;
`

const SedolSearch = ({
    realTimeSedol,
    sedol,
    realTimeAssetName,
    patchRealTime,
    patchService,
    isLoading,
    fillHoldingInfo = false,
    isSelling = null,
}) => {
    const [, { divest }] = useInstruction();

    const [search, { isSearching }] = useSearchAssetBySedol();

    const [fetchAsset, { isFetching }] = useLazyFetchAssetFromValuationsQuery();

    const cashxxxRegex = useMemo(() => /cash.{3}$/i, []);

    const disableAssetNameInput = useMemo(() => {
        if (isFetching)
            return true;

        if (sedol !== realTimeSedol)
            return realTimeSedol?.toLowerCase() !== "n/a" && !realTimeSedol?.match(cashxxxRegex);

        return (realTimeSedol ?? "") === ""
    }, [cashxxxRegex, isFetching, realTimeSedol, sedol]);

    const valueProp = useMemo(() => isSelling
        ? "currentValue"
        : "saleValue", [isSelling])

    return <>
        {isLoading
            ? <Skeleton />
            : <FormInput
                value={realTimeSedol ?? ""}
                disableAnimations={sedol === realTimeSedol}
                onChange={(event) => {
                    let operations = [patchReplace("sedol", event.target.value)];

                    // TODO: Patch asset name depending on input

                    patchRealTime(operations);
                }}
                onBlur={(input) =>
                    new Promise((resolve, reject) => {
                        // All SEDOLs should be converted to uppercase onBlur
                        input = input.toUpperCase();

                        let operations = [patchReplace("sedol", input.substring(0, 7))];

                        switch (input) {
                            // If the user enters N/A or clears the input, also clear the assetName field
                            case 'N/A':
                            case '':
                                operations.push(patchReplace("assetName", ""));
                                break;
                            // A few more custom special cases for inserting non-asset asset names
                            case 'VARIOUS':
                                operations.push(patchReplace("assetName", "Various existing investment holdings"));
                                break;
                            case 'TRUSTEE':
                                operations.push(patchReplace("assetName", "Trustee Cash Account"));
                                break;
                            case 'DISCRET':
                                operations.push(patchReplace("assetName", "Discretionary Portfolio"));
                                break;
                            case 'SHARES':
                                operations.push(patchReplace("assetName", "Share Portfolio"));
                                break;
                            case 'PORTFOLIO':
                                operations.push(patchReplace("assetName", "Existing Investment Portfolio"));
                                operations[0].value = 'PRTFLIO';
                                break;
                            // If the user enters 'CASHXXX', replace the assetName with 'Cash Account (XXX)'
                            case input.match(cashxxxRegex)?.input:
                                operations.push(patchReplace("assetName", `Cash Account (${input.substring(4)})`));
                                break;
                            // If none of these match, search for the SEDOL the usual way
                            default:
                                return search(input).then(res => {
                                    // Use the search result to update the transaction's SEDOL, if appropriate
                                    if (res?.sedol != null && res?.sedol !== input)
                                        operations[0].value = res.sedol;

                                    // And the asset ID, provider ID and name. This will reset the assetId and assetName fields if no asset was found
                                    operations = [...operations, patchReplace("assetId", res?.assetId ?? null), patchReplace("assetName", res?.longAssetName ?? "")];

                                    resolve({ operations, assetId: res?.assetId });
                                    return { operations, assetId: res?.assetId };
                                }).then(({ operations, assetId }) => {
                                    if (fillHoldingInfo && divest?.productId != null && divest?.designationId != null && divest?.providerRef != null && divest?.custodyRef != null) {
                                        // If no asset was found, reset the value, book cost and quantity fields
                                        if (assetId == null) {
                                            operations = [...operations, patchReplace(valueProp, 0), patchReplace("bookCost", 0), patchReplace("quantity", 0)];

                                            patchRealTime(operations);
                                            return patchService(operations).then(resolve, reject);
                                        }

                                        return fetchAsset({
                                            productId: divest?.productId,
                                            designationId: divest?.designationId,
                                            providerRef: divest?.providerRef,
                                            custodyRef: divest?.custodyRef,
                                            assetId
                                        }).unwrap().then(res => {
                                            // If no owned asset was found, also reset the row fields
                                            if (res == null) {
                                                operations = [...operations, patchReplace(valueProp, 0), patchReplace("bookCost", 0), patchReplace("quantity", 0)];

                                                patchRealTime(operations);
                                                return patchService(operations).then(resolve, reject);
                                            }

                                            // Otherwise, populate the fields with the owned asset's values
                                            operations = [...operations, patchReplace(valueProp, res.value), patchReplace("bookCost", res.bookCost), patchReplace("quantity", res.quantity)];

                                            patchRealTime(operations);
                                            return patchService(operations).then(resolve, reject);
                                        }).catch(_ => {
                                            // If no owned asset was found through error, also reset the row fields
                                            operations = [...operations, patchReplace(valueProp, 0), patchReplace("bookCost", 0), patchReplace("quantity", 0)];

                                            patchRealTime(operations);
                                            return patchService(operations).then(resolve, reject);
                                        });
                                    } else {
                                        patchRealTime(operations);
                                        return patchService(operations).then(resolve, reject);
                                    }
                                }).catch(reject);
                        }

                        // If it gets to this point, no search was performed, so reset the various fields
                        operations = [...operations, patchReplace("assetId", null)];
                        if (fillHoldingInfo) {
                            operations = [...operations, patchReplace(valueProp, 0), patchReplace("bookCost", 0), patchReplace("quantity", 0)];
                        }

                        patchRealTime(operations);
                        patchService(operations).then(resolve, reject);
                    })}
            />}
        {(isLoading || isSearching || isFetching)
            ? <Skeleton />
            : <OverlayTrigger
                placement="top"
                overlay={(props) => disableAssetNameInput
                    ? <LargePopover {...props}>
                        <Popover.Header>Fund Search</Popover.Header>
                        <Popover.Body>
                            Please enter a SEDOL or Citicode to search for a fund.
                            {/* <br /> */}
                            <br />
                            <ThemedSpan variant="muted">
                                You can also add a custom fund by entering "N/A", or a cash fund by entering "CASHXXX" (e.g. CASHEUR).
                            </ThemedSpan>
                        </Popover.Body>
                    </LargePopover>
                    : <></>}>
                <span>
                    <FormInput
                        className="col-start-2"
                        value={(realTimeAssetName ?? "")}
                        onBlur={(assetName) => patchService([patchReplace("assetName", assetName)])}
                        disabled={disableAssetNameInput} />
                </span>
            </OverlayTrigger>}
    </>
}

export default SedolSearch;
import React, { useMemo, useState, useEffect } from "react";
import InstructionRow, { InstructionRowHeaders } from "./InstructionRow";
import CashSourceRow from "./CashSourceRow";
import { ThemedSpan } from "../../../components/utilities";
import { NumericFormat } from "react-number-format";
import InstructionLabelRow from "./InstructionLabelRow";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";

const IsaTransferAndInspecieApsCash = ({ data, updateFn, deductAdviceFeeFromRemainingBalance, showCashUpdatedModal, showRecalculateTransactionsModal, setTotalCashRaised }) => {
    const { application: { hasBuyTransactions } } = useHeadOfficeApplicationContext();

    const totalCashTransferred = useMemo(() => data.existingCashBalanceAmount + data.preTransferSalesAmount, [data])
    const totalCashValue = useMemo(() => data.existingCashBalanceAmount + data.preTransferSalesAmount + data.postTransferSalesAmount, [data]);

    const remainingBalance = useMemo(() => {
        var balance = totalCashValue - (
            data.clientWithdrawalAmount
            + data.totalIncomeAmount
            + (deductAdviceFeeFromRemainingBalance ? data.deductAdviceFeeAmount : 0)
            + data.investElsewhereAmount
        );

        return balance;
    }, [data]);

    const [initialTotalCashValue, setInitialTotalCashValue] = useState(totalCashValue);

    const [isInvestmentInitiallyEqualToCashRaised, setIsInvestmentInitiallyEqualToCashRaised] = useState(totalCashValue === data.investAmount);

    useEffect(() => {
        setTotalCashRaised(totalCashValue);
    }, [totalCashValue, setTotalCashRaised]);

    useEffect(() => {
        if (totalCashValue === initialTotalCashValue) return;
        // these will (rightfully) not update in time for the showCashUpdatedModal() call, but the change will reflect for the next run
        // this is the desired behaviour here as we want the values before the update to be passed in
        setInitialTotalCashValue(totalCashValue);
        setIsInvestmentInitiallyEqualToCashRaised(totalCashValue === data.investAmount);
        showCashUpdatedModal(isInvestmentInitiallyEqualToCashRaised);
    }, [totalCashValue]);

    return <React.Fragment>
        <div className="container-fluid mb-4">
            <CashSourceRow
                label={"Existing Cash Balance"}
                data={data}
                updateFn={updateFn}
                appliesProperty={"existingCashBalanceApplies"}
                commentProperty={"existingCashBalanceComment"}
                cashAmountProperty={"existingCashBalanceAmount"}
                receivedDateProperty={"existingCashBalanceReceivedDate"}
                receivedConfirmedByNameProperty={"existingCashBalanceReceivedConfirmedByName"}
                receivedConfirmedSetDateProperty={"existingCashBalanceReceivedConfirmedSetDate"}
            />

            <CashSourceRow
                label={"Pre-transfer Sales"}
                data={data}
                updateFn={updateFn}
                appliesProperty={"preTransferSalesApplies"}
                commentProperty={"preTransferSalesComment"}
                cashAmountProperty={"preTransferSalesAmount"}
                receivedDateProperty={"preTransferSalesReceivedDate"}
                receivedConfirmedByNameProperty={"preTransferSalesReceivedConfirmedByName"}
                receivedConfirmedSetDateProperty={"preTransferSalesReceivedConfirmedSetDate"}
            />

            <div className="row my-2">
                <div className="col-2">
                    <b><ThemedSpan>Total Cash Transferred</ThemedSpan></b>
                </div>
                <div className="col-2 text-end">
                    <b><NumericFormat value={totalCashTransferred} prefix={"£"} thousandSeparator displayType="text" decimalScale={2} fixedDecimalScale /></b>
                </div>
            </div>

            <CashSourceRow
                label={"Post-transfer Sales"}
                data={data}
                updateFn={updateFn}
                appliesProperty={"postTransferSalesApplies"}
                commentProperty={"postTransferSalesComment"}
                cashAmountProperty={"postTransferSalesAmount"}
                receivedDateProperty={"postTransferSalesReceivedDate"}
                receivedConfirmedByNameProperty={"postTransferSalesReceivedConfirmedByName"}
                receivedConfirmedSetDateProperty={"postTransferSalesReceivedConfirmedSetDate"}
            />

            <div className="row my-2">
                <div className="col-2">
                    <b><ThemedSpan>Total Cash Value</ThemedSpan></b>
                </div>
                <div className="col-2 text-end">
                    <b><NumericFormat value={totalCashValue} prefix={"£"} thousandSeparator displayType="text" /></b>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <InstructionRowHeaders />
            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Client Withdrawal"}
                appliesProperty={"clientWithdrawalApplies"}
                cashAmountProperty={"clientWithdrawalAmount"}
                dateInstructedProperty={"clientWithdrawalInstructedDate"}
                instructedDateSetProperty={"clientWithdrawalInstructedSetDate"}
                instructedByProperty={"clientWithdrawalInstructedByName"}
                dateConfirmedProperty={"clientWithdrawalConfirmedDate"}
                confirmedDateSetProperty={"clientWithdrawalConfirmedSetDate"}
                confirmedByProperty={"clientWithdrawalConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Income"}
                appliesProperty={"incomeApplies"}
                frequencyProperty={"incomeFrequency"}
                cashAmountProperty={"incomeAmount"}
                dateInstructedProperty={"incomeInstructedDate"}
                instructedDateSetProperty={"incomeInstructedSetDate"}
                instructedByProperty={"incomeInstructedByName"}
                dateConfirmedProperty={"incomeConfirmedDate"}
                confirmedDateSetProperty={"incomeConfirmedSetDate"}
                confirmedByProperty={"incomeConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Natural Income"}
                appliesProperty={"naturalIncome"}
                dateInstructedProperty={"naturalIncomeInstructedDate"}
                instructedDateSetProperty={"naturalIncomeInstructedSetDate"}
                instructedByProperty={"naturalIncomeInstructedByName"}
                dateConfirmedProperty={"naturalIncomeConfirmedDate"}
                confirmedDateSetProperty={"naturalIncomeConfirmedSetDate"}
                confirmedByProperty={"naturalIncomeConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Deduct Advice Fee"}
                appliesProperty={"deductAdviceFeeApplies"}
                cashAmountProperty={"deductAdviceFeeAmount"}
                dateInstructedProperty={"deductAdviceFeeAmountInstructedDate"}
                instructedDateSetProperty={"deductAdviceFeeAmountInstructedSetDate"}
                instructedByProperty={"deductAdviceFeeAmountInstructedByName"}
                dateConfirmedProperty={"deductAdviceFeeConfirmedDate"}
                confirmedDateSetProperty={"deductAdviceFeeConfirmedSetDate"}
                confirmedByProperty={"deductAdviceFeeConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={hasBuyTransactions ? "Net Amount to Invest" : "Retain In Cash"}
                appliesProperty={"investAmountApplies"}
                cashAmountProperty={"investAmount"}
                dateInstructedProperty={"investAmountInstructedDate"}
                instructedDateSetProperty={"investAmountInstructedSetDate"}
                instructedByProperty={"investAmountInstructedByName"}
                dateConfirmedProperty={"investAmountConfirmedDate"}
                confirmedDateSetProperty={"investAmountConfirmedSetDate"}
                confirmedByProperty={"investAmountConfirmedByName"}
                recalculateTransactionsButton
                onRecalculateTransactionsClick={() => showRecalculateTransactionsModal(true)}
            />

            <InstructionLabelRow
                className={"mt-4 mb-2"}
                label={<ThemedSpan variant={remainingBalance !== 0 ? "danger" : null}>Remaining Balance</ThemedSpan>}
                value={<NumericFormat
                    className={remainingBalance !== 0 ? "text-danger" : null}
                    value={remainingBalance}
                    prefix={"£"}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />}
            />
        </div>
    </React.Fragment>
};

export default IsaTransferAndInspecieApsCash;
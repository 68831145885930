import { wealthProApi } from "../../redux/api";

const projectMessagesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["ProjectMessages"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchProjectMessages: builder.query({
            query: ({ projectId, active = false, page = 1, limit = 100 }) => `/api/messaging/projects/${projectId}/messages?active=${active}&page=${page}&limit=${limit}`,
            serializeQueryArgs: ({ projectId, endpointName }) => `${endpointName}/${projectId}`,
            providesTags: ["ProjectMessages"]
        })
    })
});

export const {
    useLazyFetchProjectMessagesQuery
} = projectMessagesApiEndpoints;
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { NotesEditor } from "../../../components/forms/richtexteditor/RichTextEditor";
import { ExpenditureColumn, FormInfoBubbleWrapper, RowColSpan, SectionHeader } from "../components";
import { useExpenditureDetails } from "../contexts/ExpenditureDetailsContext";
import useNotes from '../hooks/useNotes';

const ExpenditureDetailsSection = () => {
    const [{
        createNewExpenditureType,
        changeFrequency,
        changeFrequencyRealTime,
        changeDescription,
        deleteCustomExpenditureType
    }, {
        filterCombined,
        clientColumns,
        nonDiscExpTypeInfo,
        discExpTypeInfo,
        monthlyNetIncome,
        overallMonthlyExpenditure,
        disposableMonthlyIncome,
        section,
        isLoading,
        isCreating,
        frequenciesIsLoading,
        expenditureFrequencies,
    }] = useExpenditureDetails();

    const { updateNotes } = useNotes();

    const columns = useMemo(() => filterCombined
        ? clientColumns
        : section?.clients, [clientColumns, filterCombined, section?.clients]);

    const gridTemplateColumns = useMemo(() => `minmax(35ch, 25%) minmax(15ch, 15%) repeat(${(columns?.length ?? 1)}, minmax(15ch, 1fr)) auto`, [columns?.length]);

    const nonDiscRowsStart = useMemo(() => 2, []);
    const discRowsStart = useMemo(() => nonDiscRowsStart + (nonDiscExpTypeInfo?.length ?? 10) + 3, [nonDiscExpTypeInfo?.length, nonDiscRowsStart]);

    const totalRowsStart = useMemo(() => discRowsStart + (discExpTypeInfo?.length ?? 5) + 3, [discExpTypeInfo?.length, discRowsStart])
    const incomeRowsStart = useMemo(() => totalRowsStart + 5, [totalRowsStart]);

    return <div className="container-fluid">
        <GridWrapper
            className="m-4"
            gridTemplateColumns={gridTemplateColumns}
            gridColumnGap={"2.5rem"}
        >
            {/* Non-Disc Section */}
            <RowColSpan gridRowStart={nonDiscRowsStart - 1} gridColumnStart={1}>
                <FormInfoBubbleWrapper
                    fieldKey="nonDiscretionaryExpenditure"
                    showBubble
                >
                    <SectionHeader className="mt-2 pe-2">
                        Non-Discretionary Expenditure
                    </SectionHeader>
                </FormInfoBubbleWrapper>
            </RowColSpan>

            {/* Labels */}
            {isLoading
                ? Array(10).fill(undefined).map((_, index) =>
                    <RowColSpan key={`${index}-label`} gridRowStart={nonDiscRowsStart + index} gridColumnStart={1}>
                        <Skeleton width={"100%"} height={"100%"} lineHeight={0} />
                    </RowColSpan>)
                : nonDiscExpTypeInfo?.map((typeInfo, index) =>
                    <RowColSpan key={`${typeInfo?.expenditureTypeId}-label`} gridRowStart={nonDiscRowsStart + index} gridColumnStart={1}>
                        {typeInfo?.expenditureType?.typeEnum === 0
                            ? <FormInput
                                value={typeInfo?.expenditureType.typeDescription}
                                onBlur={(newValue) => changeDescription(typeInfo?.expenditureTypeId, false, newValue)}
                            />
                            : <FormLabel className="mt-2">
                                {`${typeInfo?.expenditureType.typeDescription}:`}
                            </FormLabel>}
                    </RowColSpan>
                )}

            {/* Freqs */}
            {(isLoading || frequenciesIsLoading)
                ? Array(10).fill(undefined).map((_, index) =>
                    <RowColSpan key={`${index}-freq`} gridRowStart={nonDiscRowsStart + index} gridColumnStart={2}>
                        <Skeleton width={"100%"} height={"100%"} lineHeight={0} />
                    </RowColSpan>)
                : nonDiscExpTypeInfo?.map((typeInfo, index) =>
                    <RowColSpan key={`${typeInfo?.expenditureTypeId}-freq`} gridRowStart={nonDiscRowsStart + index} gridColumnStart={2}>
                        <FormSelect
                            defaultValue={typeInfo?.frequency}
                            options={expenditureFrequencies}
                            onChange={(selection) => changeFrequencyRealTime(typeInfo?.expenditureTypeId, selection.value)}
                            onBlur={(selection) => changeFrequency(typeInfo?.expenditureTypeId, selection.value)}
                        />
                    </RowColSpan>)}

            {/* Delete Type buttons */}
            {!isLoading && nonDiscExpTypeInfo?.map((typeInfo, index) =>
                typeInfo?.expenditureType?.typeEnum === 0
                && <RowColSpan key={`${typeInfo?.expenditureTypeId}-delete`} gridRowStart={nonDiscRowsStart + index} gridColumnStart={(columns?.length || 0) + 3}>
                    <IconButton
                        icon="trash-can"
                        variant="danger"
                        onClick={() => deleteCustomExpenditureType(typeInfo?.expenditureTypeId)}
                    />
                </RowColSpan>)}

            {/* Add Type button */}
            <RowColSpan
                gridRowStart={nonDiscRowsStart + (nonDiscExpTypeInfo?.length ?? 5)}
                gridColumnStart={(columns?.length ?? 0) + 3}
            >
                <IconButton
                    icon="plus"
                    variant="success"
                    onClick={() => createNewExpenditureType(false)}
                />
            </RowColSpan>

            {/* Total Labels */}
            <RowColSpan gridRowStart={discRowsStart - 3} gridColumnStart={1}>
                <FormLabel>
                    {`Total Non-Discretionary Expenditure (Annual):`}
                </FormLabel>
            </RowColSpan>
            <RowColSpan gridRowStart={discRowsStart - 2} gridColumnStart={1}>
                <FormLabel>
                    {`Total Non-Discretionary Expenditure (Monthly):`}
                </FormLabel>
            </RowColSpan>

            {/* Disc Section */}
            <RowColSpan gridRowStart={discRowsStart - 1} gridColumnStart={1}>
                <FormInfoBubbleWrapper
                    fieldKey="discretionaryExpenditure"
                    showBubble
                >
                    <SectionHeader className="mt-2 pe-2">
                        Discretionary Expenditure
                    </SectionHeader>
                </FormInfoBubbleWrapper>
            </RowColSpan>

            {/* Labels */}
            {isLoading
                ? Array(5).fill(undefined).map((_, index) =>
                    <RowColSpan key={index} gridRowStart={discRowsStart + index} gridColumnStart={1}>
                        <Skeleton width={"100%"} height={"100%"} lineHeight={0} />
                    </RowColSpan>)
                : discExpTypeInfo?.map((typeInfo, index) =>
                    <RowColSpan key={`${typeInfo?.expenditureTypeId}-label`} gridRowStart={discRowsStart + index} gridColumnStart={1}>
                        {typeInfo?.expenditureType?.typeEnum === 0
                            ? <FormInput
                                value={typeInfo?.expenditureType?.typeDescription}
                                onBlur={(newValue) => changeDescription(typeInfo?.expenditureTypeId, true, newValue)}
                            />
                            : <FormLabel className="mt-2">
                                {`${typeInfo?.expenditureType?.typeDescription}:`}
                            </FormLabel>}
                    </RowColSpan>)}

            {/* Freqs */}
            {(isLoading || frequenciesIsLoading)
                ? Array(5).fill(undefined).map((_, index) =>
                    <RowColSpan key={index} gridRowStart={discRowsStart + index} gridColumnStart={2}>
                        <Skeleton width={"100%"} height={"100%"} lineHeight={0} />
                    </RowColSpan>)
                : discExpTypeInfo?.map((typeInfo, index) =>
                    <RowColSpan key={`${typeInfo?.expenditureTypeId}-freq`} gridRowStart={discRowsStart + index} gridColumnStart={2}>
                        <FormSelect
                            defaultValue={typeInfo?.frequency}
                            options={expenditureFrequencies}
                            onChange={(selection) => changeFrequencyRealTime(typeInfo?.expenditureTypeId, selection.value)}
                            onBlur={(selection) => changeFrequency(typeInfo?.expenditureTypeId, selection.value)}
                        />
                    </RowColSpan>)}

            {/* Delete Type buttons */}
            {!isLoading && discExpTypeInfo?.map((typeInfo, index) =>
                typeInfo?.expenditureType?.typeEnum === 0
                && <RowColSpan key={`${typeInfo?.expenditureTypeId}-del`} gridRowStart={discRowsStart + index} gridColumnStart={columns?.length + 3}>
                    <IconButton
                        icon="trash-can"
                        variant="danger"
                        onClick={() => deleteCustomExpenditureType(typeInfo?.expenditureTypeId)}
                    />
                </RowColSpan>)}

            {/* Add Type button */}
            <RowColSpan
                gridRowStart={discRowsStart + (discExpTypeInfo?.length ?? 5)}
                gridColumnStart={(columns?.length ?? 0) + 3}
            >
                <IconButton
                    icon="plus"
                    variant="success"
                    onClick={() => createNewExpenditureType(true)}
                />
            </RowColSpan>

            {/* Total Labels */}
            <RowColSpan gridRowStart={totalRowsStart - 3} gridColumnStart={1}>
                <FormLabel>
                    {`Discretionary Expenditure (Annual):`}
                </FormLabel>
            </RowColSpan>
            <RowColSpan gridRowStart={totalRowsStart - 2} gridColumnStart={1}>
                <FormLabel>
                    {`Discretionary Expenditure (Monthly):`}
                </FormLabel>
            </RowColSpan>

            {/* Client Columns */}
            {columns?.map((client, index) => <ExpenditureColumn
                key={client.clientExpenditureId}
                clientExpenditureId={client.clientExpenditureId}
                column={index + 3}
                nonDiscRowsStart={nonDiscRowsStart}
                discRowsStart={discRowsStart}
                totalRowsStart={totalRowsStart}
            />)}

            {/* Totals */}
            <RowColSpan gridRowStart={totalRowsStart}>
                <FormLabel>
                    Total Expenditure (Annual):
                </FormLabel>
            </RowColSpan>

            <RowColSpan gridRowStart={totalRowsStart + 1}>
                <FormLabel>
                    Total Expenditure (Monthly):
                </FormLabel>
            </RowColSpan>

            {/* Income */}
            <RowColSpan gridRowStart={incomeRowsStart}>
                <FormLabel>
                    Overall Net Monthly Income:
                </FormLabel>
            </RowColSpan>
            <RowColSpan maxWidth={"25ch"} gridRowStart={incomeRowsStart} gridColumnStart={3}>
                {isLoading
                    ? <Skeleton />
                    : <CurrencyInput
                        className={"my-auto"}
                        value={monthlyNetIncome}
                        decimalScale={0}
                        fixedDecimalScale
                        disabled
                    />}
            </RowColSpan>

            <RowColSpan gridRowStart={incomeRowsStart + 1}>
                <FormLabel>
                    Overall Monthly Expenditure:
                </FormLabel>
            </RowColSpan>
            <RowColSpan maxWidth={"25ch"} gridRowStart={incomeRowsStart + 1} gridColumnStart={3}>
                {isLoading
                    ? <Skeleton />
                    : <CurrencyInput
                        className={"my-auto"}
                        value={overallMonthlyExpenditure}
                        decimalScale={0}
                        fixedDecimalScale
                        disabled
                    />}
            </RowColSpan>

            {monthlyNetIncome > 0 && <>
                <RowColSpan gridRowStart={incomeRowsStart + 3}>
                    <FormLabel>
                        Total Disposable Monthly Income:
                    </FormLabel>
                </RowColSpan>
                <RowColSpan maxWidth={"25ch"} gridRowStart={incomeRowsStart + 3} gridColumnStart={3}>
                    {isLoading
                        ? <Skeleton />
                        : <CurrencyInput
                            className={"my-auto"}
                            value={disposableMonthlyIncome}
                            decimalScale={0}
                            fixedDecimalScale
                            disabled
                        />}
                </RowColSpan>
            </>}
        </GridWrapper>

        {(isLoading || !section.notes)
            ? <Skeleton height={75} count={1} />
            : <NotesEditor
                label={"Additional Notes"}
                defaultValue={section?.notes?.notes}
                onBlur={(newValue) => updateNotes(section?.notes?.notesId, newValue)}
            />}
    </div>
}

export default ExpenditureDetailsSection;
import ReminderFormProvider from "./hooks/ReminderFormContext";
import ReminderForm from "./components/ReminderForm";
import ReminderModal from "./components/ReminderModal";

export const CreateReminderModal = ({ show, onCreated, onHide, masterAccountId, projectId, showUserSelect }) => (
    <ReminderFormProvider masterAccountId={masterAccountId} projectId={projectId}>
        <ReminderModal show={show} onCreated={onCreated} onHide={onHide} showUserSelect={showUserSelect}/>
    </ReminderFormProvider>
);

const CreateReminderForm = ({ masterAccountId, projectId, close, showUserSelect, onCreated }) => (
    <ReminderFormProvider masterAccountId={masterAccountId} projectId={projectId}>
        <ReminderForm close={close} onCreated={onCreated} showUserSelect={showUserSelect} />
    </ReminderFormProvider>
);

export default CreateReminderForm;
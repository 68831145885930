import React from "react";
import styled from "styled-components";
import { lighten } from "polished";
import { Card as BootstrapCard } from "react-bootstrap";

export const CardHeader = styled(BootstrapCard.Header)`
    color: ${props => props.theme.secondary};
    background-color: ${props => props.theme.light};
`;

export const CardBody = styled(BootstrapCard.Body)`
    background-color: ${props => props.theme.light};
`;

export const CardFooter = styled(BootstrapCard.Footer)`
    background-color: ${props => props.theme.light};
`;

const Card = ({ cardClassName, children, footer, id, label, ...rest }) => {
    const defaultId = React.useId();
    const componentId = id || defaultId;

    return (
        <BootstrapCard className={cardClassName} id={componentId}>
            {label && (
                <CardHeader>{label}</CardHeader>
            )}
            <CardBody {...rest}>
                {children}
            </CardBody>
            {footer && <CardFooter>
                {footer}
            </CardFooter>}
        </BootstrapCard>
    );
};

export default Card;
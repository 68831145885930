import { useEffect, useMemo } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useCreateGiftMutation, useDeleteGiftMutation, useLazyFetchGiftsQuery, usePatchGiftMutation, useUpdateGiftedByMappingsMutation } from "../../../services/clientfactfind";

const useGifts = (estatePlanningId, giftIds) => {
    const [fetchGifts, { data, isLoading, isFetching, isUninitialized, isError, error }] = useLazyFetchGiftsQuery();
    const [create] = useCreateGiftMutation();
    const [patch] = usePatchGiftMutation();
    const [remove] = useDeleteGiftMutation();
    const [updateMappings] = useUpdateGiftedByMappingsMutation();

    useEffect(() => {
        if (estatePlanningId != null)
            fetchGifts({ estatePlanningId });
    }, [estatePlanningId, fetchGifts])

    const createGift = () => {
        return new Promise((resolve, reject) => {
            return create({ estatePlanningId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const patchGift = (giftId, property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patch({ giftId, operations, estatePlanningId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const removeGift = (giftId) => {
        return new Promise((resolve, reject) => {
            return remove({ giftId, estatePlanningId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updateGiftedByMappings = (giftId, ids) => {
        return new Promise((resolve, reject) => {
            return updateMappings({ giftId, ids, estatePlanningId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    const loadingData = useMemo(() => (isLoading || isFetching || isUninitialized)
        ? giftIds?.map(giftId => ({ giftId }))
        : data,
        [data, giftIds, isFetching, isLoading, isUninitialized])


    return {
        data: loadingData || [],
        isLoading: isLoading || isFetching || isUninitialized,
        isError,
        error,
        refetchGifts: () => fetchGifts({ estatePlanningId }),
        createGift,
        patchGift,
        removeGift,
        updateGiftedByMappings,
    };

};

export default useGifts;
import { wealthProApi } from "../../redux/api";

const postToTypesApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        fetchPostToTypes: builder.query({
            query: () => `api/products/postto`
        })
    })
});

export const {
    useFetchPostToTypesQuery
} = postToTypesApiEndpoints;
import React from "react";
import { FormCheck } from "../../../components/forms";
import { AccordionSection } from "../../../components/utilities";
import { useClientContext } from "../../../hooks/ClientContext";
import ClientEventsTable from "../../clienteventstable";
import useProjectReminders from "../hooks/useProjectReminders";

const ProjectReminders = ({ label = "Events, Appointments, Phone Calls", onProjectChanged, selectedProject }) => {
    const { id } = useClientContext();
    const { projectId } = selectedProject || { projectId: null };

    const {
        data,
        showCancelled,
        setShowCancelled,
        handlePatchReminder,
        patchResults,
        ...loadResults
    } = useProjectReminders(id, projectId);

    const { results } = data || { results: [] };

    return (
        <AccordionSection label={label} defaultActiveKey="1">
            {results && results.length > 0 && <span className="pe-2 d-flex justify-content-end">
                <FormCheck
                    type="checkbox"
                    label="Show Cancelled"
                    isChecked={showCancelled}
                    onChange={() => setShowCancelled(!showCancelled)}
                    disableAnimations
                />
            </span>}
            {/* <ReminderDisplayList 
                    projectId={projectId} 
                    refetch={loadReminders}
                    displayProject={false} 
                    showCancelled={showCancelled}
                    setShowCancelled={setShowCancelled}
                    {...loadResults} 
                /> */}
            <ClientEventsTable
                className="table-borderless"
                data={results}
                masterAccountId={id}
                projectId={projectId}
                patchEvent={handlePatchReminder}
                {...loadResults}
            />
        </AccordionSection>
    );
};

export default ProjectReminders;
import React from "react";
import classNames from "classnames";

const ClientAccessBlockedDisplayColumn = ({ isAccessBlocked }) => {
    return (
        <div className={classNames('badge', {
            'bg-access-blocked text-access-blocked': isAccessBlocked,
            'bg-access-allowed text-access-allowed' : !isAccessBlocked
        })}>
            {isAccessBlocked ? 'BLOCKED' : 'ALLOWED'}
        </div>
    );
};

export default ClientAccessBlockedDisplayColumn;
import { wealthProApi } from "../../redux/api";

const factFindMasterAccountsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['factFindClientConnections', 'factFindEmploymentStatus', 'factFindNotes', 'pensionArrangements', 'finalSalaries', 'factFindAml']
}).injectEndpoints({
    endpoints: (builder) => ({
        // Sections
        fetchAmlSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/MasterAccounts/${masterAccountId}/AntiMoneyLaundering`,
            providesTags: (res, err, _args) => (res && !err)
                ? ['factFindAml']
                : []
        }),
        fetchBackgroundSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/MasterAccounts/${masterAccountId}/Background`,
            providesTags: (res, err, _args) => (res && !err)
                ? [{ type: 'factFindNotes', id: res.notesId }]
                : []
        }),
        fetchPersonalDetails: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/personaldetails`,
            providesTags: ['factFindEmploymentStatus']
        }),
        fetchIncomeDetailsSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/MasterAccounts/${masterAccountId}/IncomeDetails`,
            providesTags: (res, err, _args) => (res && !err)
                ? [{ type: 'factFindNotes', id: res.additionalNotesId }]
                : []
        }),
        fetchExpenditureDetailsSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/MasterAccounts/${masterAccountId}/ExpenditureDetails`,
            providesTags: (res, err, _args) => (res && !err)
                ? [{ type: 'factFindNotes', id: res.notesId }]
                : []
        }),
        fetchInvestmentsSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/investments`,
            providesTags: (res, err, _args) => (res && !err)
                ? Object.values(res).map(notes => ({ type: 'factFindNotes', id: notes.notesId }))
                : []
        }),
        fetchRiskFactorsSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/MasterAccounts/${masterAccountId}/RiskFactors`,
            providesTags: (res, err, _args) => (res && !err)
                ? [res.assessmentOfKnowledge, res.understandingRiskAndReward, res.capacityForLoss, res.risksOfPensionDrawdown]
                    .map(notes => ({ type: 'factFindNotes', id: notes.notesId }))
                : []
        }),
        fetchAgreedVolatilityProfileSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/MasterAccounts/${masterAccountId}/AgreedVolatilityProfile`,
            providesTags: (res, err, _args) => (res && !err)
                ? [res.dashboardAndPRDComment, res.portfolioStrategyAndObjectives]
                    .map(notes => ({ type: 'factFindNotes', id: notes.notesId }))
                : []
        }),
        fetchEstatePlanningSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/estateplanning`,
            providesTags: (res, err, _args) => (res && !err)
                ? [res.estatePlanningObjectives, res.distributionOnDeath, res.trustsDetails, res.expectedInheritances]
                    .map(notes => ({ type: 'factFindNotes', id: notes.notesId }))
                : []
        }),
        fetchProtectionSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/protection`,
            providesTags: (res, err, _args) => (res && !err)
                ? [{ type: 'factFindNotes', id: res.protectionObjectives.notesId }]
                : []
        }),
        fetchLoansAndLiabilitiesSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/loansandliabilities`,
            providesTags: (res, err, _args) => (res && !err)
                ? [{ type: 'factFindNotes', id: res.generalNotes.notesId }]
                : []
        }),
        fetchFactFindAssetsSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/assets`,
            providesTags: (res, err, _args) => (res && !err)
                ? [{ type: 'factFindNotes', id: res.generalNotes.notesId }]
                : []
        }),
        fetchNewMortgageApplicationSection: builder.query({
            query: ({ masterAccountId }) => `api/factfind/MasterAccounts/${masterAccountId}/NewMortgageApplication`,
            providesTags: (res, err, _args) => (res && !err)
                ? [{ type: 'factFindNotes', id: res.affordabilityBudgetSourceOfFunds.notesId }]
                : []
        }),
        fetchDependants: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/dependants`
        }),
        fetchClientAccounts: builder.query({
            query: ({ masterAccountId, fetchType = "Object" }) => `api/factfind/masteraccounts/${masterAccountId}/clientaccounts?fetchType=${fetchType}`
        }),
        fetchFactFindClientConnections: builder.query({
            query: ({ masterAccountId, includeClientAccounts }) => `api/factfind/masteraccounts/${masterAccountId}/connections?includeClientAccounts=${includeClientAccounts}`,
            providesTags: ["factFindClientConnections"]
        }),
        fetchClientRetirementPlans: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/clientretirementplans`,
            providesTags: ['factFindEmploymentStatus']
        }),
        fetchFinalSalaryArrangements: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/finalsalaryarrangements`,
            providesTags: (result, error, arg) => result
                ? [{ type: "finalSalaries", id: arg.masterAccountId }, "finalSalaries"]
                : ["finalSalaries"]
        }),
        fetchPensionArrangements: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/pensionarrangements`,
            providesTags: (result, error, arg) => result
                ? [{ type: "pensionArrangements", id: arg.masterAccountId}, "pensionArrangements"]
                : ["pensionArrangements"]
        }),
        fetchMortgageArrangements: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/mortgagearrangements`
        }),
        fetchOtherDebts: builder.query({
            query: ({ masterAccountId }) => `api/factfind/masteraccounts/${masterAccountId}/otherdebts`
        }),
    })
});

export const {
    // Sections
    useFetchAmlSectionQuery,
    useFetchBackgroundSectionQuery,
    useFetchPersonalDetailsQuery,
    useFetchIncomeDetailsSectionQuery,
    useFetchExpenditureDetailsSectionQuery,
    useFetchInvestmentsSectionQuery,
    useFetchRiskFactorsSectionQuery,
    useFetchAgreedVolatilityProfileSectionQuery,
    useFetchEstatePlanningSectionQuery,
    useFetchLoansAndLiabilitiesSectionQuery,
    useFetchNewMortgageApplicationSectionQuery,

    useFetchDependantsQuery,
    useFetchClientAccountsQuery,
    useFetchFactFindClientConnectionsQuery,
    useFetchClientRetirementPlansQuery,
    useFetchFinalSalaryArrangementsQuery,
    useFetchPensionArrangementsQuery,
    useFetchProtectionSectionQuery,
    useFetchMortgageArrangementsQuery,
    useFetchOtherDebtsQuery,
    useFetchFactFindAssetsSectionQuery,

    useFetchFactFindVerificationStatusQuery,
    useVerifyFactFindMutation
} = factFindMasterAccountsApiEndpoints;
import { wealthProApi } from "../../redux/api";

const verifyApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['factFindVerification']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchFactFindVerificationStatus: builder.query({
            query: ({ masterAccountId }) => `api/factfind/verify/${masterAccountId}`,
            providesTags: ['factFindVerification']
        }),
        verifyFactFind: builder.mutation({
            query: ({ masterAccountId }) => ({
                url: `api/factfind/verify/${masterAccountId}`,
                method: "POST"
            }),
            invalidatesTags: ['factFindVerification']
        })
    })
})

export const {
    useFetchFactFindVerificationStatusQuery,
    useVerifyFactFindMutation
} = verifyApiEndpoints;
import React, { useMemo, useState } from "react";
import { DateInput, FormInput, FormLabel, FormSelect, NumberInput } from "../../../components/forms";
import { IconButton } from "../../../components";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import { ErrorMessageWrapper, ErrorMessage } from "../../../components/messages"
import Skeleton from "react-loading-skeleton";
import { ThemedSpan, Tooltip } from "../../../components/utilities";
import TransactionExceptionsResolveDropdown from "./TransactionExceptionsResolveDropdown";
import classNames from "classnames";
import { Card } from "react-bootstrap";
import { CardBody, CardFooter, CardHeader } from "../../../components/utilities/Card";
import styled from "styled-components";
import RetryMatchingModal from "./RetryMatchingModal";
import BulkResolveModal from "./BulkResolveModal";

const StyledCard = styled(Card)`
    height: 60%;
`;

const Label = ({ children }) => (
    <FormLabel className={"me-2"}>
        {children}
    </FormLabel>
);

const TransactionExceptionsLoadingPanel = () => (
    <StyledCard className="mb-2">
        <CardHeader>Exceptions</CardHeader>
        <CardBody>
            <div className="max-w-2/3">
                <div className="row mb-2">
                    <Skeleton height={36} />
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <Skeleton height={36} />
                    </div>
                    <div className="col">
                        <Skeleton height={36} />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <Skeleton height={36} />
                    </div>
                    <div className="col">
                        <Skeleton height={36} />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <Skeleton height={36} />
                    </div>
                    <div className="col">
                        <Skeleton height={36} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton height={36} />
                    </div>
                    <div className="col">
                        <Skeleton height={36} />
                    </div>
                </div>
            </div>
        </CardBody>
        <CardFooter>
            <div className="mt-auto d-flex p-1 align-items-center">
                <IconButton
                    className="me-1"
                    icon="fa-backward-fast"
                />
                <IconButton
                    className="me-2"
                    icon="fa-backward-step"
                />
                <IconButton
                    className="me-1"
                    icon="fa-forward-step"
                />
                <IconButton
                    className="me-2"
                    icon="fa-forward-fast"
                />
            </div>
        </CardFooter>
    </StyledCard>

);

const TransactionExceptions = () => {

    const [showBulkModal, setShowBulkModal] = useState(false);
    const [showRetryModal, setShowRetryModal] = useState(false);

    const {
        exception,
        isFetching,
        isError,
        error,
        totalCount,
        page,
        first,
        last,
        next,
        previous,
        retryFetchExceptions,
        setPage
    } = useCustodianTransactionMatching();

    const {
        custodianCode,
        platformAccountId,
        sedol,
        assetName,
        platformTransactionId,
        tradeDate,
        platformTransactionType,
        quantity,
        transactionValue,
        wealthProStatusDescription,
        dateAdded,
        revisionMarker,
        platformClientName,
        platformAccountName,
        wealthProAccountReference
    } = exception || {};

    if (isFetching) {
        return <TransactionExceptionsLoadingPanel />;
    }

    if (isError) {
        return (
            <ErrorMessageWrapper>
                <ErrorMessage error={error} retryCallback={retryFetchExceptions} />
            </ErrorMessageWrapper>
        );
    }

    if (!exception) {
        return <div className="p-1">
            <span className="h5">There are currently no exceptions to match.</span>
        </div>
    }

    return (
        <React.Fragment>
            <StyledCard className={"mb-3"}>
                <CardHeader className="d-flex align-items-center">
                    <span>Exceptions</span>
                    <div className="d-flex ms-auto">
                        <Tooltip position={"left"} tooltip={"Resolve multiple"}>
                            <IconButton className="me-3" icon="layer-group" onClick={() => setShowBulkModal(true)} />
                        </Tooltip>
                        <Tooltip position={"left"} tooltip={"Re-match transactions"}>
                            <IconButton icon="refresh" onClick={() => setShowRetryModal(true)} />
                        </Tooltip>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="row mx-0">
                        <div className="col-7">
                            <div className="row mb-2">
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Client</FormLabel>
                                </div>
                                <div className="col">
                                    <FormInput
                                        readOnly
                                        value={platformClientName}
                                    />
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Account</FormLabel>
                                </div>
                                <div className="col">
                                    <FormInput
                                        readOnly
                                        value={platformAccountName}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Account ID</FormLabel>
                                </div>
                                <div className="col">
                                    <FormInput
                                        readOnly
                                        value={platformAccountId?.trim()}
                                    />
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Operation</FormLabel>
                                </div>
                                <div className="col">
                                    <FormInput
                                        readOnly
                                        value={platformTransactionType}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>SEDOL</FormLabel>
                                </div>
                                <div className="col">
                                    <FormInput
                                        readOnly
                                        value={sedol}
                                    />
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Asset</FormLabel>
                                </div>
                                <div className="col">
                                    <FormInput
                                        readOnly
                                        value={assetName}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Deal Units</FormLabel>
                                </div>
                                <div className="col">
                                    <NumberInput
                                        readOnly
                                        value={quantity}
                                    />
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Deal Value</FormLabel>
                                </div>
                                <div className="col">
                                    <NumberInput
                                        readOnly
                                        value={transactionValue}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Trade Date</FormLabel>
                                </div>
                                <div className="col">
                                    <DateInput
                                        readOnly
                                        ButtonComponent={null}
                                        value={tradeDate}
                                    />
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    <FormLabel>Date Added</FormLabel>
                                </div>
                                <div className="col">
                                    <DateInput
                                        readOnly
                                        ButtonComponent={null}
                                        value={dateAdded}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row mb-2">
                                <div className="col">
                                    <Label>Custodian:</Label>
                                    <span>{custodianCode}</span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <Label>WealthPro Account:</Label>
                                    <span>{wealthProAccountReference}</span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <Label>Status:</Label>
                                    <ThemedSpan variant="danger">{wealthProStatusDescription}</ThemedSpan>
                                </div>
                                <div className="col">
                                    <Label>Revision Marker:</Label>
                                    <span>{revisionMarker ?? "N/a"}</span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <Label>Transaction ID:</Label>
                                    <span>{platformTransactionId}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    <div className={classNames(
                        "py-1",
                        "d-flex flex-row align-items-center justify-content-between"
                    )}>
                        <div className="d-flex">
                            <IconButton
                                className="me-1"
                                icon="fa-backward-fast"
                                onClick={first}
                            />
                            <IconButton
                                className="me-2"
                                icon="fa-backward-step"
                                onClick={previous}
                            />
                            <IconButton
                                className="me-1"
                                icon="fa-forward-step"
                                onClick={next}
                            />
                            <IconButton
                                className="me-2"
                                icon="fa-forward-fast"
                                onClick={last}
                            />
                            <div className="d-flex align-items-center justify-content-start">
                                <span>Transaction</span>
                                <div className="w-25 mx-1">
                                    <NumberInput
                                        decimalScale={0}
                                        className="rounded text-end"
                                        value={page}
                                        onBlur={value => setPage(value)}
                                        isAllowed={values => {
                                            const { floatValue } = values;
                                            return floatValue > 0 && floatValue <= totalCount;
                                        }}
                                    />
                                </div>
                                <span>of {totalCount}</span>
                            </div>
                        </div>

                        <TransactionExceptionsResolveDropdown platformTransactionId={platformTransactionId} />
                    </div>
                </CardFooter>
            </StyledCard>
            <BulkResolveModal
                show={showBulkModal}
                onHide={() => setShowBulkModal(false)}
            />
            <RetryMatchingModal
                show={showRetryModal}
                onClose={() => setShowRetryModal(false)}
            />
        </React.Fragment>
    );

};

export default TransactionExceptions;
import React from "react";
import FormSelect from "../../../components/forms/FormSelect";
import useDesignations from "../hooks/useDesignations";

const DesignationSelect = ({ masterAccountId, ...rest }) => {

    const { designations } = useDesignations({masterAccountId: masterAccountId, fetchType: "select"});

    return <FormSelect 
        options={designations || []} 
        {...rest} />
};

export default DesignationSelect;
import { useProductsGridContext } from "../hooks/ProductsGridContext";
import useProductGridRow from "../hooks/useProductGridRow";
import ProductsGridRowActionCol from "./ProductsGridRowActionCol";
import { useState } from "react";
import EditProductModal from "./EditProductModal";
import Skeleton from "react-loading-skeleton";

const ProductsGridRow = ({ style: { height, width }, index, data }) => {

    const { columns, setSelectedProduct } = useProductsGridContext();
    const product = data[index];
    const { deleteProduct, updateProduct, deleteProductResult } = useProductGridRow(product?.productId);
    const [showEdit, setShowEdit] = useState(false);

    if (!product) {
        return <tr style={{ height, width }}>
            <td colSpan={columns.length + 1}>
                <Skeleton height={height-10}/>
            </td>
        </tr>;
    }

    const {
        productName,
        wrapperName,
        defaultCustodianName,
        providerName
    } = product || {};

    return <>
        <tr style={{ height, width }}>
            <td className="col">
                {productName ?? <span className="px-2">-</span>}
            </td>
            <td className="col">
                {wrapperName ?? <span className="px-2">-</span>}
            </td>
            <td className="col">
                {providerName ?? <span className="px-2">-</span>}
            </td>
            <td className="col">
                {defaultCustodianName ?? <span className="px-2">-</span>}
            </td>
            <td className="col-1 px-3" align="right">
                <ProductsGridRowActionCol
                    deleteProduct={deleteProduct}
                    setShowEdit={setShowEdit}
                    onShowProductCosts={() => setSelectedProduct(product)}
                />
            </td>
        </tr>
        <EditProductModal
            show={showEdit}
            onHide={() => setShowEdit(false)}
            updateProduct={updateProduct}
            product={product}
            
        />
    </>;
};

export default ProductsGridRow;
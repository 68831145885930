import toast from "react-hot-toast";
import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BasicToast } from "../../../components/toasts";

const RetryDocumentUploadModal = ({ 
    attachmentsResponse = [], 
    message, 
    files = [], 
    fileInfos = [],
    onClose = () => {}, 
    uploadDocuments = () => {}, 
    show = false
}) => {

    const fileNamesToUpload = useMemo(() => attachmentsResponse.filter(x => x.status !== 200).map(x => x.fileName), [attachmentsResponse]);

    const handleRetry = (e) => {
        e.preventDefault();

        var filesToUpload = files.filter(x => fileNamesToUpload.some(y => x.name === y));
        var fileInfosToUpload = fileInfos.filter(x => filesToUpload.some(y => y.name === x.id));

        uploadDocuments(message.messageId, filesToUpload, fileInfosToUpload).then(
            r => {

                toast.custom((t) => (
                    <BasicToast
                        control={t}
                        date={r?.createdDate}
                        icon="fa-solid fa-list-check"
                        title={"Documents uploaded"}
                        message={"Message attachments have been uploaded."}
                    />
                ), { duration: 8000 });

                onClose();
            },
            e => toast.error(e?.data?.message ?? "Unable to upload message attachments.")
        );
    }

    return (
        <Modal centered show={show}>
            <Modal.Header>
                <ThemedSpan className="d-flex align-items-center">
                    <FontAwesomeIcon icon="exclamation-triangle" className="text-danger me-3" size="2x" />
                    Retry Attachments
                </ThemedSpan>

            </Modal.Header>
            <Modal.Body>
                <ThemedSpan>Some attachments failed to upload:</ThemedSpan>
                <ul>
                    {attachmentsResponse.filter(x => x.documentId === null).map(attachment => (
                        <li>{attachment.fileName}</li>
                    ))}
                </ul>
                <ThemedSpan>Please retry the upload below.</ThemedSpan>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                <Button onClick={handleRetry}>Retry</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RetryDocumentUploadModal;
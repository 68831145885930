import React, { Fragment, useState } from "react";
import ProductsGrid from "./components/ProductsGrid";
import { ProductCostsModal } from "./components/ProductCosts";
import ProductsGridProvider, { useProductsGridContext } from "./hooks/ProductsGridContext";
import FilterProductsModal from "./components/FilterProductsModal";
import { FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import { Button } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ProductManagementHeader = () => {

    const [isFiltersShown, setIsFiltersShown] = useState();
    const {
        resetFilters,
        isFiltersApplied,
        createProduct,
        createProductResult
    } = useProductsGridContext();

    return (
        <>
            <PageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <a href="#" onClick={resetFilters}>Click here</a> to clear your filters.
                                </FiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex flex-fill flex-row-reverse">
                        <Button className="ms-3" onClick={() => setIsFiltersShown(true)}>
                            Filter Products
                        </Button>

                        <Button
                            onClick={createProduct}
                            disabled={createProductResult?.isLoading}
                            variant="success"
                            className="d-flex align-items-center"
                        >
                            <FontAwesomeIcon className="me-1" icon="plus" />
                            Create New Product
                        </Button>
                    </div>
                </div>
            </PageSubHeader>
            <FilterProductsModal show={isFiltersShown} handleClose={() => setIsFiltersShown(false)} />
        </>
    )
};

const ProductsManagement = () => (
    <ProductsGridProvider>
        <Main className="d-flex flex-column">
            <ProductManagementHeader />
            <section className="px-4 my-3 h-100">
                <ProductsGrid />
            </section>
        </Main>
        <ProductCostsModal />
    </ProductsGridProvider>
);

export default ProductsManagement;
import React from "react";
import FormSelect from "../FormSelect";
import { useLazyFetchCampaignProjectStatusesQuery } from "../../../services/campaignmanagement";

const CampaignProjectStatusSelect = ({ options, campaignId, ...rest }) => {
    const [fetchData, { data, isLoading, isFetching }] = useLazyFetchCampaignProjectStatusesQuery();
    const { results } = data || { results: [] }

    React.useEffect(() => {
        if (!campaignId) {
            return;
        }
        fetchData({ campaignId }).unwrap();
    }, [ campaignId ]);

    return (
        <FormSelect
            options={results}
            noOptionsMessage={() => "There are no Statuses available."}
            isLoadingOptions={isLoading || isFetching}
            {...rest}
        />
    );
};

export default CampaignProjectStatusSelect;
import Skeleton from "react-loading-skeleton";
import { RichTextEditor } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useBackgroundSection from "../hooks/useBackgroundSection";

const BackgroundSection = () => {
    const [{
        patchNotes,
        refetch
    }, {
        section,
        isLoading,
        isError,
        error
    }] = useBackgroundSection();

    return <div className="container-fluid">
        {isLoading
            ? <Skeleton height={16} count={4} />
            : isError || error
                ? <ErrorMessageWrapper>
                    <ErrorMessage error={error} retryCallback={refetch} />
                </ErrorMessageWrapper>
                : <RichTextEditor
                    defaultValue={section?.notes?.notes}
                    onBlur={patchNotes}
                />}
    </div>
}

export default BackgroundSection;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { DateInput, FormCheck, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { MasterAccountSelect, MessageUserDelegatesSelect } from "../../../components/forms/selects";
import { messageCategoryOptions, messagePriorityOptions, messageTypeOptions } from "../utilities/filterOptions";
import { useMessageManagementContext } from "../../../hooks/MessageManagementContext";
import Alert from "react-bootstrap/Alert";
import moment from "moment";

const FormRow = ({ children }) => <div className="row m-3">{children}</div>;

const PersonalMessagesFilterModal = ({ handleClose, show, ...rest }) => {
    const { defaultFilters, filter, setFilter } = useMessageManagementContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const {
        category,
        deadlineDateFrom,
        deadlineDateTo,
        showDone,
        showHiddenByUser,
        masterAccount,
        priorities,
        recipients,
        reminderDateFrom,
        reminderDateTo,
        senders,
        sentDateFrom,
        sentDateTo,
        subject,
        type,
        userPriorities
    } = tempFilters;

    /**
     * Used to limit what the user can filter on if they select certain filters which may be too broad
     */
    const applyFilterLimitations = useMemo(() => {
        return showDone
            && showHiddenByUser
            && recipients.length === 0
            && !masterAccount
            && senders.length === 0;
    }, [showDone, showHiddenByUser, recipients, masterAccount, senders]);

    const areFiltersValid = useMemo(() => {
        if (!applyFilterLimitations) return true;

        var from = moment(sentDateFrom);
        var to = moment(sentDateTo);

        return Math.abs(from.diff(to, "d")) <= 60;
    }, [applyFilterLimitations, sentDateFrom, sentDateTo]);

    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(_ => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof (handleClose) === 'function') {
            handleClose();
        }
    };

    useEffect(() => {
        if (!applyFilterLimitations || !sentDateFrom) return;
        var from = moment(sentDateFrom);
        var to = moment(sentDateTo);

        if (!to.isValid() || Math.abs(from.diff(to, "d")) > 60) {

            from.add(60, "d");
            onFilterValueChanged("sentDateTo", from.toDate())
        }
    }, [applyFilterLimitations, sentDateFrom]);

    useEffect(() => {
        if (!applyFilterLimitations || !sentDateTo) return;

        var from = moment(sentDateFrom);
        var to = moment(sentDateTo);

        if (!from.isValid() || Math.abs(from.diff(to, "d")) > 60) {

            to.add(-60, "d");
            onFilterValueChanged("sentDateFrom", to.toDate())
        }
    }, [applyFilterLimitations, sentDateTo]);

    useEffect(() => {
        if (recipients.length > 1) {
            onFilterValueChanged("showDone", true);
            onFilterValueChanged("showHiddenByUser", true);
        }
    }, [recipients]);

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    return <Modal size="lg" centered backdrop="static" show={show}>
        <Modal.Header>Personal Message Management Filters</Modal.Header>
        <Modal.Body {...rest}>
            <React.Fragment>
                <FormRow>
                    <div className="col">
                        <MasterAccountSelect
                            label="Master Account"
                            valueObject={masterAccount}
                            isMulti={false}
                            setValue={(value) => onFilterValueChanged("masterAccount", value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col">
                        <MessageUserDelegatesSelect
                            masterAccountId={masterAccount?.value}
                            disableAnimations
                            label="Recipients"
                            defaultValueObject={recipients}
                            isMulti={true}
                            onChange={(value) => onFilterValueChanged("recipients", value)}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col">
                        <MessageUserDelegatesSelect
                            masterAccountId={masterAccount?.value}
                            disableAnimations
                            label="Senders"
                            defaultValueObject={senders}
                            isMulti={true}
                            onChange={(value) => onFilterValueChanged("senders", value)}
                        />
                    </div>
                    <div className="col d-flex align-items-end">
                        <DateInput
                            label="Sent Date"
                            isClearable={!applyFilterLimitations}
                            value={sentDateFrom}
                            onBlur={(value) => onFilterValueChanged("sentDateFrom", value)}
                            disableAnimations
                        />
                        <FormLabel className={"mx-2"}>to</FormLabel>
                        <DateInput
                            isClearable={!applyFilterLimitations}
                            value={sentDateTo}
                            onBlur={(value) => onFilterValueChanged("sentDateTo", value)}
                            disableAnimations
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col">
                        <FormInput
                            label="Subject"
                            value={subject ?? ""}
                            onBlur={(value) => onFilterValueChanged("subject", value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col d-flex justify-content-between align-items-end pb-2">
                        <FormCheck
                            label={"Show Done"}
                            isChecked={showDone}
                            onChange={(value) => onFilterValueChanged("showDone", value)}
                            disableAnimations
                        />
                        <FormCheck
                            label={"Show Hidden"}
                            isChecked={showHiddenByUser}
                            onChange={(value) => onFilterValueChanged("showHiddenByUser", value)}
                            disableAnimations
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col">
                        <FormSelect
                            label="Message Type"
                            defaultValue={type}
                            isMulti={false}
                            options={messageTypeOptions}
                            onChange={(value) => onFilterValueChanged("type", value.value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col">
                        <FormSelect
                            label="Message Category"
                            defaultValue={category}
                            isMulti={false}
                            options={messageCategoryOptions}
                            onChange={(value) => onFilterValueChanged("category", value.value)}
                            disableAnimations
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col">
                        <FormSelect
                            label="Message Priority"
                            defaultValue={priorities}
                            isMulti={true}
                            options={messagePriorityOptions}
                            onChange={(value) => onFilterValueChanged("priorities", value.map(x => x.value))}
                            disableAnimations
                        />
                    </div>
                    <div className="col d-flex align-items-end">
                        <DateInput
                            label="Deadline Date"
                            isClearable={true}
                            value={deadlineDateFrom}
                            onBlur={(value) => onFilterValueChanged("deadlineDateFrom", value)}
                            disableAnimations
                        />
                        <FormLabel className={"mx-3"}>to</FormLabel>
                        <DateInput
                            isClearable={true}
                            value={deadlineDateTo}
                            onBlur={(value) => onFilterValueChanged("deadlineDateTo", value)}
                            disableAnimations
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col">
                        <FormSelect
                            label="User Priority"
                            defaultValue={userPriorities}
                            isMulti={true}
                            options={messagePriorityOptions}
                            onChange={(value) => onFilterValueChanged("userPriorities", value.map(x => x.value))}
                            disableAnimations
                        />
                    </div>
                    <div className="col d-flex align-items-end">
                        <DateInput
                            label="Reminder Date"
                            isClearable={true}
                            value={reminderDateFrom}
                            onBlur={(value) => onFilterValueChanged("reminderDateFrom", value)}
                            disableAnimations
                        />
                        <FormLabel className={"mx-2"}>to</FormLabel>
                        <DateInput
                            isClearable={true}
                            value={reminderDateTo}
                            onBlur={(value) => onFilterValueChanged("reminderDateTo", value)}
                            disableAnimations
                        />
                    </div>
                </FormRow>
                <FormRow>
                    {applyFilterLimitations && (
                        <Alert variant="warning">
                            <span>
                                You must select a 'Sent Date' range of 60 days or less in order to view done messages with no filters on accounts, senders or recipients.
                            </span>
                        </Alert>
                    )}
                </FormRow>
            </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
            <Button variant="success" onClick={onApplyFilters} disabled={!areFiltersValid}>Apply Filters</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
};

export default PersonalMessagesFilterModal;
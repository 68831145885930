import { wealthProApi } from "../../redux/api";
import * as jsonpatch from "fast-json-patch";

const recommendationsProductCostEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchRecProductCost: builder.query({
            query: ({ costId }) => `api/recommendations/ProductCost/${costId}`
        }),
        patchRecProductCost: builder.mutation({
            query: ({ costId, operations }) => ({
                url: `api/recommendations/ProductCost/${costId}`,
                body: operations,
                method: "PATCH"
            }),
            async onQueryStarted({ costId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData('fetchRecProductCost', { costId }, (draft) => {
                        jsonpatch.applyPatch(draft, operations);
                    }));
                    try {
                        await queryFulfilled
                    } catch {
                        patchResult.undo();
                    }
                } catch (error) {
                    console.error("Error patching product cost", error);
                }
            }
        }),
        removeRecProductCost: builder.mutation({
            query: ({ costId }) => ({
                url: `api/recommendations/ProductCost/${costId}`,
                method: "DELETE"
            }),
            async onQueryStarted({ instructionId, index, section }, { dispatch, queryFulfilled }) {
                try {
                    const removeRowResult = dispatch(wealthProApi.util.updateQueryData('fetchRecommendationProductCosts', { instructionId, section }, (draft) => {
                        draft.splice(index, 1);
                    }));
                    try {
                        await queryFulfilled
                    } catch {
                        removeRowResult.undo();
                    }
                } catch (error) {
                    console.error("Error removing cost from instruction", error);
                }
            }
        })
    })
})

export const {
    useFetchRecProductCostQuery,
    usePatchRecProductCostMutation,
    useRemoveRecProductCostMutation
} = recommendationsProductCostEndpoints
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const dependantsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createDependant: builder.mutation({
            query: ({ masterAccountId }) => ({
                url: `api/factfind/dependants`,
                method: 'POST',
                body: {
                    masterAccountId
                }
            }),
            async onQueryStarted({ masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    let createdIndex
                    var createResult = dispatch(wealthProApi.util.updateQueryData('fetchDependants', { masterAccountId }, (data) => {
                        const newDependant = {
                            dependantName: null,
                            dateOfBirth: null,
                            relationship: null,
                            relationshipText: null,
                            isLifelongCare: null,
                            notes: ""
                        };
                        createdIndex = data.length;

                        return [...data, newDependant];
                    }))

                    try {
                        const { data: response } = await queryFulfilled;

                        dispatch(wealthProApi.util.updateQueryData('fetchDependants', { masterAccountId }, (data) => ([
                            ...data.slice(0, createdIndex),
                            {
                                ...data[createdIndex],
                                dependantId: response.dependantId
                            },
                            ...data.slice(createdIndex + 1)
                        ])));

                    } catch (err) {
                        console.error("Error creating dependant in service", err);
                        createResult.undo();
                    }
                }
                catch (err) {
                    console.error("Error creating dependant in cache", err);
                }
            }
        }),
        deleteDependant: builder.mutation({
            query: ({ dependantId }) => ({
                url: `api/factfind/dependants/${dependantId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ masterAccountId, dependantId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchDependants', { masterAccountId }, (data) => {
                        return data.filter(x => x.dependantId !== dependantId);
                    }))

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting dependant in service", err);
                        deleteResult.undo();
                    }
                }
                catch (err) {
                    console.error("Error deleting dependant in cache", err);
                }
            }
        }),
        patchDependant: builder.mutation({
            query: ({ dependantId, operations }) => ({
                url: `api/factfind/dependants/${dependantId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, dependantId, operations }, { dispatch, queryFulfilled }) {

                const result = dispatch(wealthProApi.util.updateQueryData('fetchDependants', { masterAccountId }, (data) => {
                    var dependant = data.find(x => x.dependantId === dependantId);
                    applyPatch(dependant, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useCreateDependantMutation,
    useDeleteDependantMutation,
    usePatchDependantMutation
} = dependantsApiEndpoints;
import { useCallback } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useInstruction } from "../contexts/InstructionContext";

const frequencies = [
    { label: "Single", value: 0, multiplier: 1 },
    { label: "Monthly", value: 1, multiplier: 12 },
    { label: "Quarterly", value: 2, multiplier: 4 },
    { label: "Annual", value: 3, multiplier: 1 },
    { label: "Biannual", value: 4, multiplier: 2 },
];

const usePolicy = () => {
    // Policy logic and helper functions
    const [{ patchPolicy }, { instruction, policy }] = useInstruction();
    // const realTimePolicy = useMemo(() => realTimeInstruction?.policy, [realTimeInstruction?.policy]);
    // const frequencyMultiplier = useMemo(() => frequencies.find(option => option.value === realTimePolicy?.frequency)?.multiplier ?? 1,
    // [realTimePolicy?.frequency]);

    // const realTimePatch = useCallback((property, value) => realTimePatchPolicy([patchReplace(property, value)]),
    // [realTimePatchPolicy]);

    const bulkPatch = useCallback((operations) => patchPolicy({
        policyId: policy?.policyId,
        operations
    }).unwrap(), [patchPolicy, policy?.policyId]);

    const patch = useCallback((property, value) => bulkPatch([patchReplace(property, value)]),
        [bulkPatch]);

    return {
        instructionTypeId: instruction.instructionTypeId,
        policy,
        // realTimePolicy,
        frequencies,
        // frequencyMultiplier,
        bulkPatch,
        patch,
        // realTimePatch
    };
}

export default usePolicy;
import { useCallback, useEffect, useState } from "react";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useDeleteHeadOfficePlatformAccountDesignationMappingMutation, useLazySearchHeadOfficePlatformAccountDesignationMappingsQuery, useUpdateHeadOfficePlatformAccountDesignationMappingMutation } from "../../../services/headofficeadmin";

const useCustodianAccountMatches = () => {
    const limit = 15;
    const { filter } = useFilterContext();
    const [deleteMap] = useDeleteHeadOfficePlatformAccountDesignationMappingMutation();
    const [update] = useUpdateHeadOfficePlatformAccountDesignationMappingMutation();
    const [search, { data, error, isUninitialized }] = useLazySearchHeadOfficePlatformAccountDesignationMappingsQuery();
    const { results, next: nextToken, totalCount } = data || { totalCount: limit, results: [], next: null };
    const [matchIdToEdit, setMatchIdToEdit] = useState(null);

    const clear = useCallback(() => {
        return search({
            nextToken: null,
            limit,
            request: filter
        });
    }, [filter, limit, search]);

    const deleteMapping = (mapId) => {
        return new Promise((resolve, reject) => {
            return deleteMap({ mapId })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => {
        return search({
            nextToken,
            limit,
            request: filter
        });
    };

    const updateMapping = (mapId, request) => {
        return new Promise((resolve, reject) => {
            return update({ mapId, request })
                .unwrap()
                .then(resolve, reject);
        });
    };

    useEffect(() => {
        if (isUninitialized) return;

        clear();
    }, [filter, clear]);

    const columns = [
        {
            label: "Master Account"
        },
        {
            label: "Designation"
        },
        {
            label: "Custodian"
        },
        {
            label: "Platform Account"
        },
        {
            label: "Wrapper"
        },
        {
            label: "Product"
        },
        {
            label: "Provider Ref"
        },
        {
            label: "Notes"
        },
        {
            label: "Created Date"
        },
    ];

    return {
        columns,
        error,
        matchIdToEdit,
        matchToEdit: results.find(x => x.mapId === matchIdToEdit) ?? null,
        results,
        totalCount,
        deleteMapping,
        isItemLoaded,
        loadMoreItems,
        setMatchIdToEdit,
        updateMapping
    };
};

export default useCustodianAccountMatches;
import _ from "lodash"
import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { useFilterContext } from "../../../hooks/FilterContext";
import { FormCheck } from "../../../components/forms";
import { GenericUserSelect } from "../../../components/forms/selects";

export const UserDelegatesFilterButton = ({ className, variant = "primary", ...rest }) => (
    <div className={className}>
        <Button variant={variant} {...rest}>
            Filter Delegates
        </Button>
    </div>
);

const UserDelegatesFilterModal = ({
    handeClose = () => {},
    onFiltersChangedEvent = () => {},
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = React.useState(filter);
    const { userIds, delegateUserIds, createdByIds, readCalendar, writeCalendar, readMail, writeMail, sendMail, readMessages, writeMessages, readReminders, writeReminders, hasClientAccess } = tempFilters;

    const _applyFilters = () => { 
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        _close();
    };

    const _clearFilters = () => setTempFilters(_ => defaultFilters);

    const _close = () => handeClose();

    const _onChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    React.useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    
    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>User Delegate Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body {...rest}>
                <div className="row mb-2">
                    <div className="col">
                        <GenericUserSelect 
                            label="Users"
                            isMulti={true}
                            defaultValue={userIds}
                            disableAnimations={true}
                            onBlur={(values) => _onChanged('userIds', values.map(el => el.value))}
                        />
                    </div>
                    <div className="col">
                        <GenericUserSelect 
                            label="Delegated Users"
                            isMulti={true}
                            defaultValue={delegateUserIds}
                            disableAnimations={true}
                            onBlur={(values) => _onChanged('delegateUserIds', values.map(el => el.value))}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <GenericUserSelect 
                            label="Created By"
                            isMulti={true}
                            defaultValue={createdByIds}
                            disableAnimations={true}
                            onBlur={(values) => _onChanged('createdByIds', values.map(el => el.value))}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Read Calendar"
                            disableAnimations={true}
                            isChecked={readCalendar}
                            onChange={(_) => _onChanged('readCalendar', !readCalendar)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Write Calendar"
                            disableAnimations={true}
                            isChecked={readCalendar}
                            onChange={(_) => _onChanged('writeCalendar', !writeCalendar)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Read Mail"
                            disableAnimations={true}
                            isChecked={readMail}
                            onChange={(_) => _onChanged('readMail', !readMail)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Write Mail"
                            disableAnimations={true}
                            isChecked={writeMail}
                            onChange={(_) => _onChanged('writeMail', !writeMail)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Send Mail"
                            disableAnimations={true}
                            isChecked={sendMail}
                            onChange={(_) => _onChanged('sendMail', !sendMail)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Read Messages"
                            disableAnimations={true}
                            isChecked={readMessages}
                            onChange={(_) => _onChanged('readMessages', !readMessages)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Write Messages"
                            disableAnimations={true}
                            isChecked={writeMessages}
                            onChange={(_) => _onChanged('writeMessages', !writeMessages)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Read Reminders"
                            disableAnimations={true}
                            isChecked={readReminders}
                            onChange={(_) => _onChanged('readReminders', !readReminders)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Write Reminders"
                            disableAnimations={true}
                            isChecked={writeReminders}
                            onChange={(_) => _onChanged('writeReminders', !writeReminders)}
                        />
                    </div>
                    <div className="col-4">
                        <FormCheck 
                            type="checkbox"
                            label="Has Client Access"
                            disableAnimations={true}
                            isChecked={hasClientAccess}
                            onChange={(_) => _onChanged('hasClientAccess', !hasClientAccess)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={_clearFilters}>Clear Filters</Button>
                <Button variant="success" onClick={_applyFilters}>Apply Filters</Button>
                <Button variant="light" onClick={_close}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserDelegatesFilterModal;
import React from "react";
import NavigationGroup from "./NavigationGroup";
import NavigationHeader from "./NavigationHeader";
import NavigationItem, { NavigationItemIcon } from "./NavigationItem";
import NavigationItemGroup from "./NavigationItemGroup";
import NavigationLoader from "./NavigationLoader";
import { ErrorMessage, NoResults } from "../../../components/messages";
import { useNavigationContext } from "../../../hooks/NavigationContext";

const NavigationDisplay = ({ setIsOpen }) => {
    const { navigation, error, isLoading, reload } = useNavigationContext();

    const handleRetry = (e) => {
        e.preventDefault();
        reload(e);
    };

    if (isLoading === true) {
        return <NavigationLoader />
    }

    if (isLoading === false && (error || !navigation)) {
        return  <ErrorMessage flex="column" error={error} retryCallback={handleRetry} />
    }

    if (isLoading === false && !error && navigation && navigation.length === 0) {
        return <NoResults flex="column" message="Navigation could not be loaded." messageSize="fs-6" />
    }

    return <React.Fragment>
        {navigation.map((el, i) => (
            <NavigationGroup key={i}>
                <NavigationItem 
                    key={`${i}`} 
                    to={el.activeRoute ?? `/`} 
                    onClick={() => setIsOpen(false)}
                >
                    {el.displayIcon && (<NavigationItemIcon icon={el.displayIcon} />)}
                    {el.description}
                </NavigationItem>
            </NavigationGroup>
        ))}
    </React.Fragment>
};

export default NavigationDisplay;
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { InfinityList } from "../../../components/tables";
import { useClientEmails } from "../hooks/ClientEmailsContext";
import useClientEmailConversations from "../hooks/useClientEmailConversations";
import ClientEmailPreviewRow from "./ClientEmailPreviewRow";

const ClientEmailConversationPreviewsList = () => {
    const { conversationId, setConversationId } = useClientEmails();
    const { results, error, isError, isClearing, totalCount, isItemLoaded, loadMoreItems, clear, updateSubject, clearSubject } = useClientEmailConversations();

    const onPreviewClick = ({ conversationId }) => setConversationId(conversationId);

    const showActive = (message) => message.conversationId === conversationId;

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>;
    }

    return <InfinityList
        itemData={{ data: results, isClearing, onClick: onPreviewClick, showActive, editButton: true, updateSubject, clearSubject }}
        itemCount={totalCount === results.length ? totalCount : results.length + 15}
        itemSize={96}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        row={ClientEmailPreviewRow}
    />;
};

export default ClientEmailConversationPreviewsList;
import { create } from "lodash";
import { wealthProApi } from "../../redux/api";

const hoAdminReportingApiEndpoints = wealthProApi.enhanceEndpoints({
    //addTagTypes: ['adminReports']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchAdminReports: builder.query({
            query: ({ page = 1, limit = 50, sort = "ReportType" }) => `api/headofficeadmin/reporting/reporting?page=${page}&limit=${limit}&sort=${sort}`
                //providesTags: (result, error, arg) => {
                    //return ['adminReports'];
                //}
        }),   
        detailedLeagueTable: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/managementreports/detailedleaguetable`,
                method: "POST",
                body: data
            }),
        }),
        ajbDebtorsLedgerReport: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/accountingreports/ajbdebtorsledgerreport`,
                method: "POST",
                body: data
            }),
        }),
        debtorsLedgerReport: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/accountingreports/debtorsledgerreport`,
                method: "POST",
                body: data
            }),
        }),
        ajbServiceFeesBilledReport: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/accountingreports/ajbservicefeesbilledreport`,
                method: "POST",
                body: data
            }),
        }),
        businessStatusReport: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/managementreports/businessstatusreport`,
                method: "POST",
                body: data
            }),
        }),
        reviewsInProgressReport: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/managementreports/reviewsinprogressreport`,
                method: "POST",
                body: data
            }),
        }),
        
        contactStatisticsReport: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/managementreports/contactstatisticsreport`,
                method: "POST",
                body: data
            }),
        }),
        newMoneyReport: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/managementreports/newmoneyreport`,
                method: "POST",
                body: data
            }),
        }),
        newBusinessForecastReport: builder.query({
            query: ({ data }) => ({
                url: `api/headofficeadmin/managementreports/newbusinessforecastreport`,
                method: "POST",
                body: data
            }),
        })
    })
});

export const {
    useFetchAdminReportsQuery,
    useLazyFetchAdminReportsQuery,
    useLazyDetailedLeagueTableQuery,
    useLazyAjbDebtorsLedgerReportQuery,
    useLazyDebtorsLedgerReportQuery,
    useLazyAjbServiceFeesBilledReportQuery,
    useLazyBusinessStatusReportQuery,
    useLazyReviewsInProgressReportQuery,
    useLazyContactStatisticsReportQuery,
    useLazyNewMoneyReportQuery,
    useLazyNewBusinessForecastReportQuery
} = hoAdminReportingApiEndpoints;
import { socket } from "../../../redux/socket";

const DEFAULT_REPORT_ROOM_NAME = "dashboard_logins";
const CREATED_CHANNEL = "client.master.dashboard.login.created";
const DELETED_CHANNEL = "client.master.dashboard.login.deleted"
const PATCHED_CHANNEL = "client.master.dashboard.login.patched";

const fetchMasterAccountDashboardLoginsCacheSocketHandler = (isMasterAccountRoom) => async (props, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
    const roomName = isMasterAccountRoom ? `${props.masterAccountId.replace(/-/gi, '')}_${DEFAULT_REPORT_ROOM_NAME}` : DEFAULT_REPORT_ROOM_NAME;
    try {
        // this waits till the data has been cached
        await cacheDataLoaded;

        // then we subscribe to the report room to listen for changes
        socket.emit('subscribe-dashboard-logins', { room: roomName });
        
        // setup an event for when the report is created
        // TODO: we will figure out how best to resolve adding to existing records later...
        socket.on(CREATED_CHANNEL, viewModel => {
            updateCachedData((data) => {
                data.newRecordsSinceCache = data.newRecordsSinceCache + 1;
            });
        });

        // TODO: this might muck-up infinite scrolling as the paging will be off
        // we need to re-look at a way to do this....
        socket.on(DELETED_CHANNEL, viewModel => {
            updateCachedData((data) => {
                data.results = data.results.filter(el => el.id !== viewModel.id);
            });
        });

        // setup an event for when the report is patched
        socket.on(PATCHED_CHANNEL, viewModel => {
            updateCachedData((data) => {
                let item = data.results.find(el => el.id === viewModel.id);
                if (item) {
                    Object.assign(item, viewModel)
                }
            });
        });
    }
    catch { }
    await cacheEntryRemoved;
    socket.emit('unsubscribe-dashboard-logins', { room: roomName });
    socket.off(CREATED_CHANNEL);
    socket.off(DELETED_CHANNEL);
    socket.off(PATCHED_CHANNEL);
};

export default fetchMasterAccountDashboardLoginsCacheSocketHandler;
import React from "react";
import toast from "react-hot-toast";
import { CopyToClipboard  } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Component that will hide text in a format of **** but give users the ability to "unhide" the secret text and reveal the data.
 * 
 * Useful when you want to keep data hidden from screen unless specifically asked for
 */
const SensitiveTextDisplay = ({ value = "" }) => {
    const [isShown, setShown] = React.useState(false);

    return (
        <div className="d-flex border bg-light text-dark p-2">
            <div className="flex-fill overflow-auto me-3 py-1">
                {isShown && (<p className="m-0">{value}</p>)}
                {!isShown && (<p className="m-0">********************************</p>)}
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center bg-light text-dark">
                <div className="cursor-pointer me-2">
                    <FontAwesomeIcon icon={isShown ? "fa-eye-slash" : "fa-eye"} onClick={() => setShown(_=> !isShown)} />
                </div>
                <div className="cursor-pointer me-2">
                    <CopyToClipboard text={value} onCopy={() => {
                        toast('Copied the value to your clipboard!', {
                            icon: '📋'
                        });
                    }}>
                        <FontAwesomeIcon icon="fa-copy" />
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    );
};

export default SensitiveTextDisplay;
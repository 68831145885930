import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { useAssetsManagement } from "../hooks/AssetsManagementContext";

const AssetsTableHeader = () => {

    const { columns } = useAssetsManagement();

    return <thead>
        <tr>
            {columns.map((col, key) => col.renderer
                ? col.renderer({ ...col, key })
                : <ThemedTableHeader key={key} className={col.className}>{col.label}</ThemedTableHeader>
            )}
            <ThemedTableHeader/>
        </tr>
    </thead>;
};

export default AssetsTableHeader;
import { wealthProApi } from '../../redux/api';

const providersApiEndpoints = wealthProApi.enhanceEndpoints({})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchTransactionProviders: builder.query({
            query: (listType) => `api/valuations/providers${listType ? `?listType=${listType}` : ""}`
        })
    })
});

export const {
    useFetchTransactionProvidersQuery,
} = providersApiEndpoints;
import { wealthProApi } from "../../redux/api";

const providerApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['providers']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchProviders: builder.query({
            query: ({ page, limit, fetchType, sort = "ProviderName" }) => {
                if (page && limit) {
                    return {
                        url: `api/products/provider?page=${page}&limit=${limit}&fetchType=${fetchType}&sort=${sort}`
                    }
                }
                return {
                    url: `api/products/provider?fetchType=${fetchType}&sort=${sort}`
                }
            }
        }),
        searchProviders: builder.query({
            query: ({ searchTerm, page, limit, fetchType, sort = "ProviderName" }) => {
                if (page && limit) {
                    return {
                        url: `api/products/provider/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}&fetchType=${fetchType}&sort=${sort}`
                    }
                }
                return {
                    url: `api/products/provider/search?searchTerm=${searchTerm}&fetchType=${fetchType}&sort=${sort}`
                }
            }
        })
    })
});

export const {
    useFetchProvidersQuery,
    useLazyFetchProvidersQuery,
    useSearchProvidersQuery,
    useLazySearchProvidersQuery
} = providerApiEndpoints;
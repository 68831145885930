import { useCallback, useState } from "react";
import { useLazySearchAssetsQuery } from "../../../services/assets";

const useSearchAssetBySedol = () => {
    const [sedolSearchTrigger] = useLazySearchAssetsQuery({
        selectFromResult: (res) => res?.data?.results ? {
            ...res,
            data: res.data.results[0]
        } : res
    });

    // const [searchResult, setSearchResult] = useState({ sedol: "", longAssetName: "" });

    const [isSearching, setIsSearching] = useState(false);

    // const [hasSearched, setHasSearched] = useState(false);

    const search = useCallback((input) => {
        setIsSearching(true)
        return new Promise((resolve, reject) => {
            sedolSearchTrigger({ searchTerm: input, page: 1, limit: 1 }).unwrap()
                .then((res) => {
                    if (res?.results?.length > 0) {
                        resolve(res?.results[0]);
                    } else {
                        resolve({ sedol: input, longAssetName: "Code not recognised" });
                    }
                }, _ => resolve({ sedol: input, longAssetName: "Code not recognised" }))
                .catch(reject)
                .then(() => setIsSearching(false));
        });
    }, [sedolSearchTrigger]);

    return [search, { isSearching }];
}

export default useSearchAssetBySedol;
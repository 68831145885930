import _ from "lodash";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { DateDisplay, ProfileDisplay } from "../../../components";
import { IconButton } from "../../../components/buttons";
import { DocumentTypes } from "../../../components/forms/selects/DocumentTypesSelect";
import StandardTable, { ThemedTableHeaderActionColumn } from "../../../components/tables/StandardTable";
import { AccordionSection, ThemedIcon } from "../../../components/utilities";
import { niceBytes } from "../../../helpers/fileHelpers";
import { useClientContext } from "../../../hooks/ClientContext";
import useOpenDocument from "../../../hooks/useOpenDocument";
import UploadDocumentModal from "../../uploaddocumentmodal";
import useProjectDocumentsList from "../hooks/useProjectDocumentsList";

const ProjectDocumentsListListTableEmptyRow = ({ columns, onCreateClicked, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            There are no documents available for this Project.
            You can click <a href="#" onClick={onCreateClicked}>here</a> to upload one.
        </td>
    </tr>
};

const ProjectDocumentsListTableLoadingRow = ({ columns, loadingLines = 2, ...rest }) => {
    return [...Array(loadingLines)].map((_, i) => (
        <tr key={`loading-${i}`} {...rest}>
            <td colSpan={columns.length}>
                <Skeleton count={1} height={24} />
            </td>
        </tr>
    ));
};

const ProjectDocumentsListTableRow = ({ allowDelete = false, columns, data, dateFormat = "DD/MM/YYYY", projectId, onOpenDocumentClicked, ...rest }) => {
    const {
        documentType,
        typeDescription,
        fileName,
        functionDescription,
        dateUploaded,
        uploadedById,
        fileSize
    } = data;

    const calculateDocumentTypeThemeIcon = () => {
        const typeData = DocumentTypes.find(el => el.value === documentType);
        if (!typeData) {
            return `fa-file`;
        }
        return typeData.icon ?? `fa-file`;
    };
    
    return (
        <tr>
            <td>
                <ThemedIcon icon={calculateDocumentTypeThemeIcon()} size="lg" variant="primary" />
                <span className="ms-2">{fileName}</span>
            </td>
            <td>{typeDescription}</td>
            <td>{functionDescription}</td>
            <td>
                {!dateUploaded && (<span>&nbsp;</span>)}
                {dateUploaded && (<DateDisplay format={dateFormat}>{dateUploaded}</DateDisplay>)}
            </td>
            <td>
                <ProfileDisplay userId={uploadedById} />
            </td>
            <td>{niceBytes(fileSize)}</td>
            <td className="col-md-auto">
                <IconButton className="btn-sm" icon="fa-folder-open" onClick={() => onOpenDocumentClicked(data)} />
            </td>
        </tr>
    );
};

const ProjectDocumentsList = ({ label = "Files & Documents", onProjectChanged, selectedProject }) => {
    const { id } = useClientContext();
    const { projectId } = selectedProject || { projectId: null };
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const { data, error, isLoading, isUninitialized, clear } = useProjectDocumentsList(id, projectId);
    const [showUploadDocument, setShowUploadDocument] = useState(false);

    const handleUploadDocumentClose = () => setShowUploadDocument(false);

    const handleUploadDocumentOpen = () => setShowUploadDocument(true);

    const handleOnDocumentUploaded = (r) => {
        if (r && r.projectId && !_.isEqual(r.projectId, projectId) && onProjectChanged && typeof(onProjectChanged) === 'function') {
            onProjectChanged(r.projectId);
        }
        else {
            clear();
        }
    };

    const handleOnDocumentOpened = (data) => {
        const { documentId, fileName } = data || {};

        if (!documentId || isOpening)
            return;

        openDocument(documentId, fileName);
    };

    return (
        <React.Fragment>
            <UploadDocumentModal 
                defaultProjectId={projectId}
                handleClose={handleUploadDocumentClose}
                onDocumentUploaded={handleOnDocumentUploaded}
                show={showUploadDocument}
            />
            <AccordionSection label={label} defaultActiveKey="1">
                <StandardTable 
                    className="table-borderless table-sm"
                    columns={[
                        { label: 'File Name', property: 'documentDescription' },
                        { label: 'Type', property: 'typeDescription' },
                        { label: 'Function', property: 'functionDescription' },
                        { label: 'Date Uploaded', property: 'dateUploaded' },
                        { label: 'Uploaded By', property: 'uploadedByName' },
                        { label: 'File Size', property: 'File Size' },
                        { 
                            label: '', 
                            property: '',
                            headerRenderer: (props) => (
                                <ThemedTableHeaderActionColumn key={props.key}>
                                    <IconButton className="btn-sm" icon="fa-plus" variant="success" onClick={handleUploadDocumentOpen} />
                                </ThemedTableHeaderActionColumn>
                            )
                        }
                    ]}
                    data={data}
                    error={error}
                    isLoading={isLoading || isUninitialized}
                    EmptyRowComponent={ProjectDocumentsListListTableEmptyRow}
                    emptyRowComponentProps={{ onCreateClicked: handleUploadDocumentOpen }}
                    hideHeaderOnEmpty={true}
                    LoadingRowComponent={ProjectDocumentsListTableLoadingRow}
                    RowComponent={ProjectDocumentsListTableRow}
                    rowComponentProps={{ 
                        allowDelete: true,
                        project: selectedProject, 
                        onOpenDocumentClicked: handleOnDocumentOpened
                    }}
                />
            </AccordionSection>
        </React.Fragment>
    )
};

export default ProjectDocumentsList;
import { wealthProApi } from "../../redux/api";

const icOptionApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAnalysts: build.query({
            query: () => `api/assets/options/analysts`
        }),
        fetchPublishers: build.query({
            query: () => `api/assets/options/publishers`
        }),
        fetchWrappersForSelect: build.query({
            query: () => `api/assets/options/wrappers`
        }),
    })
});

export const {
    useFetchAnalystsQuery,
    useFetchPublishersQuery,
    useFetchWrappersForSelectQuery
} = icOptionApiEndpoints;
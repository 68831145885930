import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const addressesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountAddressDetails"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        createAddressDetails: builder.mutation({
            query: ({ masterAccountId, ...rest }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/addresses`,
                method: 'POST',
                body: {
                    masterAccountId,
                    ...rest
                }
            }),
            async onQueryStarted({ masterAccountId, data: address }, { dispatch, queryFulfilled }) {
                try {
                    const { data: createData } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchAddressDetails', { masterAccountId }, (data) => {
                        data.push({
                            ...address,
                            addressId: createData.addressId
                        });
                    }));
                }
                catch { }
            }
        }),
        deleteAddressDetails: builder.mutation({
            query: ({ masterAccountId, addressId }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/addresses/${addressId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ addressId, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchAddressDetails', { masterAccountId }, (data) => {
                        return data.filter(el => el.addressId !== addressId);
                    }));
                }
                catch { }
            }
        }),
        fetchAddressDetails: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/addresses`,
            providesTags: (result, error, arg) => {
                return ['masterAccountAddressDetails'];
            }
        }),
        patchAddressDetails: builder.mutation({
            query: ({ masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/addresses`,
                method: 'PATCH',
                body: {
                    patchRequests: operations
                }
            }),
            async onQueryStarted({ masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchAddressDetails', { masterAccountId }, (data) => {
                    operations.forEach(op => {
                        var address = data.find(el => el.addressId === op.addressId);
                        applyPatch(address, op.operations, true);
                    });
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useCreateAddressDetailsMutation,
    useDeleteAddressDetailsMutation,
    useFetchAddressDetailsQuery,
    usePatchAddressDetailsMutation
} = addressesApiEndpoints;
import React, { useState } from "react";
import moment from "moment";
import Button from "../../../../../components/buttons";
import { Modal } from "react-bootstrap";
import {
    DateInput
} from "../../../../../components/forms/";
import { components } from "react-select";
import { GenericAdviserSelect } from "../../../../../components/forms/selects";

const NewMoneyReportModal = ({
    confirmButtonLabel = "Run Report",
    confirmButtonVariant = "success",
    handleNewMoneyReportArgsClose,
    handleNewMoneyReportArgsConfirm,
    newMoneyReportArgsShow,
    ...rest
}) => {

    const [theAdviserIds, setTheAdviserIds] = useState([]);

    const [errorMessage, setErrorMessage] = React.useState(null);

    const [ theReportDate, setTheReportDate ] = useState(moment().format('YYYY-MM-DD'));

    const onClose = () => {
        if (handleNewMoneyReportArgsClose && typeof handleNewMoneyReportArgsClose === "function") {
            setErrorMessage("");
            setTheAdviserIds([]);
            handleNewMoneyReportArgsClose();
        }
    }

    const onConfirm = () => {
        if(theReportDate !== null){
            if (handleNewMoneyReportArgsConfirm && typeof handleNewMoneyReportArgsConfirm === "function") {
                const theRequest = {reportDate: theReportDate, adviserIds: theAdviserIds}
                handleNewMoneyReportArgsConfirm(theRequest);
            }
            onClose();
        }
        else{
            setErrorMessage("The Report Date must be set!");
        }
    }

    return (
        <Modal size="med" centered backdrop="static" show={newMoneyReportArgsShow}>
            <Modal.Header>New Money Report</Modal.Header>
            <Modal.Body {...rest}>
            <div className="row mb-3">
                    <div className="col">
                        <DateInput
                            label="Report Date"
                            value={theReportDate}
                            onBlur={(value) => setTheReportDate(value)}
                        />
                    </div>
                </div>
            <div className="row mb-3">
                <div className="col">
                    <GenericAdviserSelect
                        label="Filter Advisers - All Advisers unless selections made"
                        defaultValue={theAdviserIds}
                        isMulti={true}
                        onBlur={(values) => setTheAdviserIds(values.map((el) => el.value))}
                        //onBlur={(values) => console.log(values.map((el) => el.value))}
                    />
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col">
                    <AdviserSelectAll

                    />
                </div>

            </div>*/}
            <div className="row mb-3">
                <div className="text-warning"> {errorMessage} </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmButtonVariant} onClick={onConfirm}>{confirmButtonLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                
            </Modal.Footer>
        </Modal>
    );
};

export default NewMoneyReportModal;
import { patchReplace } from "../../../helpers/patchDoc";
import { useDeleteProductMutation, usePatchProductMutation } from "../../../services/products";

const useProductGridRow = (productId) => {

    const [deleteProductTrigger, deleteProductResult] = useDeleteProductMutation();
    const [updateProductTrigger, updateProductResult] = usePatchProductMutation();

    const deleteProduct = () => {
        return new Promise((resolve, reject) => {
            return deleteProductTrigger({ productId })
                .unwrap()
                .then(resolve, reject);
        })
    };

    const updateProduct = (property, newValue, assignableObject = null) => {
        return new Promise((resolve, reject) => {
            let operations = [patchReplace(property, newValue)];
            return updateProductTrigger({ productId, operations, assignableObject })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        deleteProduct,
        updateProduct,
        deleteProductResult,
        updateProductResult
    };
};

export default useProductGridRow;
import _ from "lodash";
import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useLazyFetchReportStatusesListQuery } from "../../../services/clientreports";
import { useFetchUsersListQuery } from "../../../services/identity";
// import { useLazyFetchReportMessagesQuery } from "../../../services/messages/reportMessagesApiEndpoints";
import { useLazyGetThreadByReportIdQuery } from "../../../services/messages/reportMessagesApiEndpoints";
import { useEditReport } from "./EditReportContext";

const useReportMessageThread = () => {
    const {
        report
    } = useEditReport();
    const [threadId, setThreadId] = useState(null);
    const [message, setMessage] = useState(null);

    const { user: { profile: { sub: userId } } } = useAuth();

    const [fetchStatuses, { data, isFetching: isFetchingStatuses, ...fetchStatusesResults }] = useLazyFetchReportStatusesListQuery();
    const { data: users, isFetching: isFetchingUsers, ...fetchUsersResults } = useFetchUsersListQuery({ includeRoles: true });
    const [fetchReportThread] = useLazyGetThreadByReportIdQuery();

    const isFetching = React.useMemo(() => isFetchingStatuses || isFetchingUsers, [isFetchingStatuses, isFetchingUsers])

    const statuses = React.useMemo(() => {
        if (!data || !users?.results || !report)
            return [];

        return data.map(status => {
            // Build the recipients up
            const { adviserId } = report;
            const { isAdviserNotified, rolesNotified } = status.data;
            let defaultRecipients = _.uniq(users.results.filter(user => {
                if (user.value === userId.replaceAll("-", "")) {
                    return false;
                }
                if (isAdviserNotified === true && user.value === adviserId) {
                    return true;
                }
                if (rolesNotified.length > 0 && _.some(user.data.roles, i => rolesNotified.includes(i))) {
                    return true;
                }
                return false;
            }).map(user => user.value));

            return {
                ...status.data,
                defaultRecipients
            }
        })
    }, [data, report, userId, users]);

    const currentStatus = React.useMemo(() => statuses?.find(el => el.id === report?.statusId),
        [report, statuses]);

    React.useEffect(() => {
        if (report) {
            fetchStatuses({ masterAccountId: report.masterAccountId, reportId: report.id }, true);
            fetchReportThread({ reportId: report.id }).unwrap()
                .then(res => {
                    if(res != null){
                        setThreadId(res);
                    }

                });
        }
    }, [fetchReportThread, fetchStatuses, report]);

    return {
        message,
        threadId,
        setMessage,
        statuses,
        currentStatus,
        users: users?.results ?? [],
        isFetching,
        fetchStatuses: { isFetching: isFetchingStatuses, ...fetchStatusesResults },
        fetchUsers: { isFetching: isFetchingUsers, ...fetchUsersResults }
    }
}

export default useReportMessageThread;
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ThemedSpan } from "../../../components/utilities";
import { MessagePriorities } from "../../clientmessages/hooks/useAllMessages";
import styled from "styled-components";
import PersonalMessagesVirtualTableRowActionCol from "./PersonalMessagesVirtualTableRowActionCol";
import useMessageRow from "../hooks/useMessageRow";
import { useMessageManagementContext } from "../../../hooks/MessageManagementContext";
import { useNavigate } from "react-router-dom";
import useOpenMasterAccount from "../../../hooks/useOpenMasterAccount";

const TableCell = styled.td`
    width: ${props => props.width}%;
    max-width: 50px;
    vertical-align: middle;
`;

const PriorityColumn = ({ value }) => {

    if (!value) return <span className="py-1 px-2">-</span>

    const className = [{
        'bg-priority-high text-priority-high': value === MessagePriorities.High,
        'bg-priority-medium text-priority-medium': value === MessagePriorities.Medium,
        'bg-priority-low text-priority-low': value === MessagePriorities.Low
    }];

    return <span className={classNames("badge py-1 px-2", className)}>
        {{
            [MessagePriorities.Low]: "Low",
            [MessagePriorities.Medium]: "Medium",
            [MessagePriorities.High]: "High"
        }[value]}
    </span>;
};

const PersonalMessagesVirtualTableRow = ({ index, data, style: { height, width } }) => {
    const { columns, setSelectedThreadId, userId } = useMessageManagementContext();
    const openMasterAccount = useOpenMasterAccount();

    const messageData = data[index];
    const {
        isActionRequired,
        masterAccountId,
        masterAccountName,
        messageId,
        message,
        subject,
        statusText,
        senderName,
        recipientNames,
        recipientIds,
        sentOnDate,
        isDone,
        messagePriority,
        userMessagePriority,
        deadlineDate,
        userReminderDate
    } = messageData || {};

    const { readUserMessage, unreadUserMessage, updateRecipient } = useMessageRow(messageId);

    const onMasterAccountClick = (e) => {
        if(typeof(e?.preventDefault) === "function"){
            e.preventDefault();
        }

        openMasterAccount(masterAccountId);
    };

    if (!messageData) {
        return <tr style={{ height, width }}>
            <TableCell colSpan={columns.length + 1}><Skeleton height={30} /></TableCell>
        </tr>
    }

    return <tr style={{ height, width }}>
        <TableCell width={7.5} className="text-truncate">
            {moment(sentOnDate).isValid() ? <Moment format="HH:mm DD/MM/YY">{sentOnDate}</Moment> : "Draft"}
        </TableCell>
        <TableCell width={12.5} className="text-truncate">
            <ThemedSpan>{senderName} to {recipientNames.join(", ")}</ThemedSpan>
        </TableCell>
        <TableCell width={12.5} className="text-truncate">
            <ThemedSpan><a href="#" onClick={onMasterAccountClick}>{masterAccountName}</a></ThemedSpan>
        </TableCell>
        <TableCell width={12.5} className="text-truncate">
            <ThemedSpan>{subject}</ThemedSpan>
        </TableCell>
        <TableCell width={10} className="text-truncate">
            <ThemedSpan>{statusText}</ThemedSpan>
        </TableCell>
        <TableCell width={17.5} className="text-truncate">
            <ThemedSpan>{message}</ThemedSpan>
        </TableCell>
        <TableCell width={5}>
            <PriorityColumn value={messagePriority} />
        </TableCell>
        <TableCell width={6.5}>
            <PriorityColumn value={userMessagePriority} />
        </TableCell>
        <TableCell width={6}>
            {moment(deadlineDate).isValid() ? <Moment format="DD/MM/YY">{deadlineDate}</Moment> : <span className="py-1 px-2">-</span>}
        </TableCell>
        <TableCell width={6}>
            {moment(userReminderDate ?? null).isValid() ? <Moment format="DD/MM/YY">{userReminderDate}</Moment> : <span className="py-1 px-2">-</span>}
        </TableCell>
        <TableCell width={2.5} className="align-middle text-center action-col">
            {isActionRequired ? <FontAwesomeIcon
                size="xl"
                icon={isDone ? 'fa-check' : 'fa-xmark'}
                className={classNames({
                    'text-success': isDone,
                    'text-danger': !isDone
                })}
            /> : <span className="py-1 px-2">-</span>}
        </TableCell>
        <TableCell width={1.5} className="align-middle text-center action-col px-4">
            <PersonalMessagesVirtualTableRowActionCol
                data={messageData}
                isRecipient={recipientIds?.some(x => x === userId)}
                readUserMessage={readUserMessage}
                unreadUserMessage={unreadUserMessage}
                setSelectedThreadId={setSelectedThreadId}
                updateRecipient={updateRecipient}
            />
        </TableCell>
    </tr>

};

export default PersonalMessagesVirtualTableRow;
import { useEffect, useId, useMemo, useRef, useState } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import FormGroup from '../FormGroup';
import FormLabel from '../FormLabel';
import InputErrorMessage from '../InputErrorMessage';
// Import SunEditor's default styles
import 'suneditor/dist/css/suneditor.min.css';
// And custom style for setting the z-index
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import styled, { css, useTheme } from 'styled-components';
import "../../../sass/_components.scss";
import { InputSpinnerMd } from '../../loaders/InputSpinners';
import { InfoBubble } from './FormInfoBubbleWrapper';

const DEFAULT_BUTTON_LIST = [
    ['bold', 'underline', 'italic', 'strike'], ['fontColor', 'hiliteColor'], ['outdent', 'indent', 'align', 'list', 'table']
];

const StyledHtmlEditorWrapper = styled.div`
    position: relative;
    ${props => props.focus ? FocusedHtmlEditor : ""}
`;

const FocusedHtmlEditor = css`
    border-radius: .25rem;
    border: rgba(var(--bs-primary-rgb), .5) 1px solid;
    box-shadow: 0 0 0 .25rem rgba(var(--bs-primary-rgb), .25);
`;

const Mask = css`
    position: absolute;
    height: 100%;
    width: 100%;
    transform: translate(0, -100%);
    background-color: rgba(0, 0, 0, 0.05);
`;

const LoadingMask = styled.div`
    ${Mask}
    ${InputSpinnerMd}
`;

const SuccessMask = styled.div`
    ${Mask}
    border-radius: .25rem;
    color: var(--bs-success);
    border: rgba(var(--bs-success-rgb)) 1px solid;
    display: flex;
    align-items: center;
`;

const SuccessCheck = styled(FontAwesomeIcon)`
    margin-top: auto;
    margin-bottom: auto;
    right: 5%;
`;

const RichTextEditor = ({
    className,
    disableAnimations = false,
    disabled = false,
    horizontal = false,
    inline = false,
    defaultValue,
    defaultStyle,
    id,
    label,
    onBlur,
    onChange,
    successTimeout = 1000,
    errorMessage = null,
    errorAllowRetry = true,
    toolbarOptions = null,
    maxHeight = 200,
    ...rest }) => {

    const { fontFamilyBase } = useTheme();
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [originalValue, setOriginalValue] = useState(defaultValue);
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [error, setError] = useState(errorMessage);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const originalValueRef = useRef(originalValue);

    useEffect(() => {
        originalValueRef.current = originalValue;
    }, [originalValue]);

    const [focus, setFocus] = useState(false);

    const editorRef = useRef();

    const getSunEditorInstance = (editor) => {
        editorRef.current = editor;
    }

    const customButtonList = useMemo(() => {
        var presetList = buttonList[toolbarOptions];

        var customList = Array.isArray(toolbarOptions)
            ? toolbarOptions
            : DEFAULT_BUTTON_LIST;

        return presetList ?? customList;
    }, [toolbarOptions]);

    const handleChange = (newHtml) => {
        setCurrentValue(newHtml);
        if (onChange && typeof onChange === 'function')
            onChange(newHtml);
    };

    const handleBlur = (e, newHtml) => {
        setFocus(false);

        if (e && typeof e.preventDefault === 'function')
            e.preventDefault();

        if (onBlur && typeof onBlur === 'function' && newHtml !== originalValueRef.current) {

            if (!disableAnimations) {
                setError(null);
                setIsLoading(true);
            }

            var possibleResult = onBlur(newHtml);

            Promise.resolve(possibleResult).then(
                _ => {
                    if (disableAnimations)
                        return;
                    setIsLoading(false);
                    setIsSuccess(true);
                    setOriginalValue(newHtml);
                },
                error => {
                    if (disableAnimations)
                        return;
                    setIsLoading(false);
                    setIsSuccess(false);
                    setError(error);
                    setCurrentValue(originalValue);
                }
            );
        }
    };

    useEffect(() => {
        if (!isSuccess) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    return <FormGroup
        className={className}
        horizontal={horizontal}
    >
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>
            {label}
        </FormLabel>}
        <StyledHtmlEditorWrapper
            focus={focus}
            isSuccess={isSuccess}
            // Possible temporary fix - ideally the inner wrapper needs this, to be able to be "tabbed into" appropriately, but that's out of my control, so this will do for now
            tabIndex={0}
        >
            <SunEditor
                setOptions={{
                    mode: inline ? "inline" : "classic",
                    buttonList: customButtonList,
                    resizingBar: false,
                    defaultStyle: `font-family:${fontFamilyBase}`,
                    toolbarWidth: "100%",
                    height: "auto",
                    maxHeight: maxHeight,
                    maxWidth: "100%"
                }}
                defaultValue={currentValue}
                disable={disabled || isLoading}
                disableToolbar={disabled || isLoading}
                getSunEditorInstance={getSunEditorInstance}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => setFocus(true)}
                hideToolbar={!focus}
                {...rest}
            />
            {isLoading && <LoadingMask />}
            {isSuccess && <SuccessMask>
                <SuccessCheck
                    className='position-absolute'
                    icon={"check"}
                    size={"lg"}
                />
            </SuccessMask>}

        </StyledHtmlEditorWrapper>

        <InputErrorMessage error={error} retryCallback={handleBlur} allowRetry={errorAllowRetry} />
    </FormGroup>
}

const NotesEditor = ({ className, label, property, defaultValue, isLoading = false, onChange, onBlur, ...rest }) => {

    return <div className={className}>
        <div className="d-flex justify-content-between mt-1">
            <FormLabel className={"mt-auto"}>
                {label}
            </FormLabel>
            <InfoBubble
                fieldKey={property}
                showBubble
            />
        </div>
        {isLoading
            ? <Skeleton containerClassName="row pt-3 px-3" height={100} />
            : <RichTextEditor
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onBlur}
                height="100%"
                {...rest}
            />}
    </div>
}

export default RichTextEditor;

export { NotesEditor };


import React from "react";
import FormSelect from "../FormSelect";

export const ManagementReportOptions = [
    { value: 1, label: 'Detailed League Table' },
    //{ value: 2, label: 'Business Status Report' },
    { value: 3, label: 'Contact Report' },
    //{ value: 4, label: 'Historical Comparison Report' },
    //{ value: 5, label: 'League Table' },
    //{ value: 6, label: 'Mortgage Breakdown' },
    { value: 7, label: 'New Business Forecast Report' },
    { value: 8, label: 'New Money Report' },
    //{ value: 9, label: 'Old World to New World Project Monitor' },
    //{ value: 10, label: 'Reviews In Progress Report' }
    //{ value: 11, label: 'Service Delivery Report' },
]

const ManagementReportSelect = ({ options, ...rest}) => {
    return <FormSelect
        options={ManagementReportOptions.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />;
};

export default ManagementReportSelect;
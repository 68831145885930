import React from "react";
import { useLazySearchServiceLevelAgreementsPagedQuery, useCreateServiceLevelAgreementMutation} from "../../../services/clientserviceterms";

const useServiceLevelAgreements = (initSort = "reviewDate+asc", initLimit = 50) => {

    const { filter } = {};

    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());

    const [sort, setSort] = React.useState(initSort);
    const [limit, setLimit] = React.useState(initLimit);

    const [load, { data, isLoading, isFetching, isError, error, isUninitialized }] = useLazySearchServiceLevelAgreementsPagedQuery();
    const [create, {}] = useCreateServiceLevelAgreementMutation();

    const { pagination, results } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true;

    const clear = (newSort) => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        load({
            ...filter,
            cacheKey: newCacheKey,
            sort,
            limit,
            page: 1,
        }).unwrap().then(_ => {
            setCacheKey(_ => newCacheKey)
            if (newSort) {
                setSort(_ => newSort);
            }
        });
    };

    const reload = (e) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }

        clear();
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        load({
            cacheKey,
            sort,
            limit,
            page: page + 1,
            ...filter,
        });
    };

    const sortTable = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";
        let [sortProperty, direction] = sort.split("+");

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    React.useEffect(clear, [filter, sort]);

    const createServiceLevelAgreement = (data) => {

        return new Promise((resolve, reject) => {
            return create({data})
                .unwrap()
                .then(
                    (r) => resolve(r),
                    (e) => reject(e)
                );
        });
    }

    return {
        results: isFetching ? [] : results,
        sort,
        totalCount,
        isError,
        error,
        isNextPageLoading,
        clear,
        isRowLoaded,
        loadMore,
        sortTable,
        reload,
        createServiceLevelAgreement
    };

}

export default useServiceLevelAgreements;
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const introducerApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchIntroducers: builder.query({
            query: () => `api/review/Introducers?sort=Surname&fetchType=1`
        }),
       
        patchClientIntroducerAgreement: builder.mutation({
            query: ({ clientIntroducerAgreementId, masterAccountId, operations }) => ({
                url: `api/review/ClientIntroducerAgreement/${clientIntroducerAgreementId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ clientIntroducerAgreementId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                try {
                    var result = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData('fetchClientIntroducerAgreement', { masterAccountId }, (data) => {
                            return {...data, ...result.data};
                        }));
                } 
                catch {}
            }
        }),
        createClientIntroducerAgreement: builder.mutation({
            query: ({ masterAccountId }) => ({
                url: `api/review/ClientIntroducerAgreement/${masterAccountId}`,
                method: 'POST',
                body: {}
            }),
            invalidatesTags: ['clientIntroducerAgreements']
        })
    })
});

export const {
    useFetchIntroducersQuery,
    usePatchClientIntroducerAgreementMutation,
    useCreateClientIntroducerAgreementMutation
} = introducerApiEndpoints;
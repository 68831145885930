import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { InfinityList } from "../../../components/tables";
import { useClientEmails } from "../hooks/ClientEmailsContext";
import useClientEmailConversationMessages from "../hooks/useClientEmailConversationMessages";
import ClientEmailPreviewRow from "./ClientEmailPreviewRow";

const ClientEmailConversationMessagesList = () => {
    const { conversationId, mailId, setMailId } = useClientEmails();
    const { results, error, isError, isClearing, totalCount, isItemLoaded, loadMoreItems, clear } = useClientEmailConversationMessages(conversationId);

    const onPreviewClick = ({ graphId }) => setMailId(graphId);

    const showActive = ({ graphId }) => graphId === mailId;


    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>;
    }

    if (!conversationId) {
        return (
            <div className="py-3 px-4 text-center text-muted">
                <span>Select a conversation from the conversations panel.</span>
            </div>
        );
    }

    return <InfinityList
        itemData={{ activeLightenAmount: 0.35, data: results, isClearing, onClick: onPreviewClick, showActive }}
        itemCount={totalCount === results.length ? totalCount : results.length + 15}
        itemSize={96}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        // isLoading={isLoading}
        row={ClientEmailPreviewRow}
    />;

};

export default ClientEmailConversationMessagesList;
import { useLazyDebtorsLedgerReportQuery } from "../../../services/headofficeadmin";

const useDebtorsLedgerReport = () => {

    const [report, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyDebtorsLedgerReportQuery();

    const getDebtorsLedgerReport = (theRequest) => {
        const fromDate = theRequest.fromDate;
        const toDate = theRequest.toDate;
        const providerIds = theRequest.providerIds;
        return new Promise((resolve, reject) => {
            
            return report({data: {fromDate: fromDate, toDate: toDate, providerIds: providerIds}}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    return {
        getDebtorsLedgerReport
    }

};

export default useDebtorsLedgerReport;
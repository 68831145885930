import { forwardRef, useEffect, useState } from "react";
import { Form, FormControl } from "react-bootstrap"
import FormGroup from "../../../components/forms/FormGroup";
import { FormInputGroup, FormLabel } from "../../../components/forms";
import { useId } from "react";
import InputErrorMessage from "../../../components/forms/InputErrorMessage";

const FileInput = forwardRef(({
    disableAnimations = false,
    disabled,
    horizontal = false,
    id,
    label,
    uploadFile,
    size = "md",
    successTimeout = 1000,
    value,
    errorMessage = null,
    errorAllowRetry = true,
    ...rest
}, ref) => {
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(errorMessage);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileUpload = (event) => {
        let file;
        if (event?.target?.files?.length > 0) {
            file = event.target.files[0];
            setSelectedFile(file);
        } else {
            file = selectedFile;
        }

        if (disableAnimations !== true) {
            setError(null);
            setIsLoading(true);
        }

        var possibleResult = uploadFile(file);

        Promise.resolve(possibleResult).then(
            _ => {
                if (disableAnimations === true) {
                    return;
                }
                setIsLoading(false);
                setIsSuccess(true);
            },
            error => {
                if (disableAnimations === true) {
                    return;
                }
                setIsLoading(false);
                setError(error);
            }
        );
    }

    useEffect(() => {
        if (errorMessage) {
            setError(errorMessage);
        }
        else {
            setError(null);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (isSuccess === false) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    return <FormGroup horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup className="has-validation" horizontal={horizontal} hasLabel={label ? true : false}>
            <FormControl
                ref={ref}
                id={componentId}
                type="file"
                size={size}
                value={value}
                onChange={handleFileUpload}
                disabled={disabled || isLoading}
                loading={isLoading.toString()}
                isValid={isSuccess}
                isInvalid={error != null}
                {...rest}
            />
            <InputErrorMessage error={error} retryCallback={handleFileUpload} allowRetry={errorAllowRetry} />
        </FormInputGroup>
    </FormGroup>
})

export default FileInput;
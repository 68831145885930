import React from "react";
import toast from "react-hot-toast";
import useClientDashboardLoginDetails from "../hooks/ClientDashboardLoginDetailsContext";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../../components";
import { FormCheck, FormInput } from "../../../components/forms";
import { MasterAccountEmailSelect, MasterAccountResourceSelect } from "../../../components/forms/selects";
import { useClientDashboardManagementControls } from "../../../hooks/ClientDashboardManagementContext";

const EditLoginDetailsForm = ({ data }) => {
    const [currentEmail, setCurrentEmail] = React.useState("");
    const [currentUsername, setCurrentUsername] = React.useState("");
    const { control, setError, formState, handleSubmit, reset, setValue } = useForm({ defaultValues: { emailAddressId: null, ...data } });
    const { errors } = formState;
    const { availableEmailAddresses, setIsDirty, updateData, isLoading: isUpdating } = useClientDashboardLoginDetails();
    const { 
        checkEmailAddressIsAvailable, 
        checkUsernameIsAvailable,
        isAllowedToChangeEmail,
        isAllowedToChangeResource,
        isAllowedToChangeRoles,
        isAllowedToRenameLogin,
        isLoading 
    } = useClientDashboardManagementControls();
    
    const checkEmailAddressAvailability = (email) => {
        if (email == currentEmail) {
            return;
        }
        checkEmailAddressIsAvailable(email).then(
            r => {
                const { result } = r;
                if (result) {
                    return true;
                }
                setError('emailAddress', { type: "custom", message: 'Email address is already in use.' });
            },
            e => {
                setError('emailAddress', { type: "custom", message: 'Email address is already in use.' });
            }
        );
    };

    const checkUsernameAvailability = (username) => {
        if (username == currentUsername) {
            return;
        }
        checkUsernameIsAvailable(username).then(
            r => {
                const { result } = r;
                if (result) {
                    return true;
                }
                setError('username', { type: "custom", message: 'Username is already in use.' });
            },
            e => {
                setError('username', { type: "custom", message: 'Username is already in use.' });
            }
        );
    };

    const onSubmit = (data) => {
        const { dirtyFields } = formState;
        let operations = [];
        
        Object.keys(dirtyFields).forEach(k => {
            operations.push({ op: 'replace', path: `/${k}`, value: data[k] });
        });

        if (operations.length === 0) {
            toast.error(`No changes detected.`);
            return;
        }

        const { id, masterAccountId } = data;
        toast.promise(updateData(masterAccountId, id, operations), {
            loading: `Updating login details...`,
            success: () => {
                setIsDirty(_ => true);
                return `Master account login details were updated.`;
            },
            error: `Failed to update the login details.`
        });
    };

    React.useEffect(() => {
        if (!data) {
            return;
        }

        if (data?.emailAddress) {
            setCurrentEmail(_ => data.currentEmail);
        }
        if (data?.username) {
            setCurrentUsername(_ => data.username);
        }

        reset({ emailAddressId: null, ...data });
    }, [ data ]);

    React.useEffect(() => {
        if (!data) {
            return;
        }

        let record = availableEmailAddresses.find(el => el.label == data.emailAddress);
        if (record) {
            setValue('emailAddressId', record.value);
        }
    }, [ availableEmailAddresses, data ]);

    if (!data) {
        return null;
    };

    const { masterAccountId } = data;
    return (
        <form className="d-flex flex-column flex-fill" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <div className="row mb-3">
                    <div className="col">
                        <Controller 
                            name="resourceId"
                            control={control}
                            rules={({ required: "A login needs to be associated with a client account or connection." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <MasterAccountResourceSelect
                                    masterAccountId={masterAccountId}
                                    label="Resource (Client/Connection)"
                                    isLoading={isLoading}
                                    isDisabled={isLoading || !isAllowedToChangeResource}
                                    disableAnimations={true}
                                    defaultValue={value}
                                    onChange={(values) => setValue('resourceId', values.value, { shouldDirty: true })}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Controller 
                            name="username"
                            control={control}
                            rules={({ required: "A username must be supplied." })}
                            render={({ field: { ref, onChange, onBlur, ...rest } }) => (
                                <FormInput
                                    label="Username"    
                                    isLoading={isLoading}
                                    disabled={isLoading || !isAllowedToRenameLogin}
                                    disableAnimations={true}
                                    errorMessage={errors?.username?.message} 
                                    errorAllowRetry={false}
                                    onChange={(e) => setValue('username', e.target.value, { shouldDirty: true })}
                                    onBlur={(e) => checkUsernameAvailability(e)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller 
                            name="emailAddressId"
                            control={control}
                            rules={({ required: "An email address must be supplied." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <MasterAccountEmailSelect
                                    masterAccountId={masterAccountId}
                                    label="Email Address"   
                                    defaultValue={value} 
                                    isLoading={isLoading}
                                    isDisabled={isLoading || !isAllowedToChangeEmail}
                                    disableAnimations={true}
                                    onChange={(v) => {
                                        setValue('emailAddress', v.label, { shouldDirty: true });
                                        setValue('emailAddressId', v.value);
                                        checkEmailAddressAvailability(v.label);
                                    }}
                                    errorMessage={errors?.emailAddress?.message} 
                                    errorAllowRetry={false} 
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="border px-3 py-2">
                    <legend className="fs-6">Account Permissions</legend>
                    <div className="row">
                        <div className="col-6">
                            <Controller 
                                name="isPortfolioEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Portfolio Access"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isPortfolioEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isValuationsEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Valuations Access"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isValuationsEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isCommunicationsEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Communications Enabled"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isCommunicationsEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isFactFindEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Can View & Edit Fact Find"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isFactFindEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isBusinessSheetsEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Access to Business Sheets"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isBusinessSheetsEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isAdviceReportsEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Access to Advice Reports"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isAdviceReportsEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isTaxPacksEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Access to Tax Packs"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isTaxPacksEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isContractNotesEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Access to Contract Notes"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isContractNotesEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isCustodyStatementsEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Access to Custody Statements"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isCustodyStatementsEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isCostDisclosuresEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Access to Cost Disclosures"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isCostDisclosuresEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <Controller 
                                name="isPlanningToolsEnabled"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck
                                        type="checkbox"
                                        label="Has Access to Planning Tools"
                                        disabled={isLoading || !isAllowedToChangeRoles}
                                        disableAnimations={true}
                                        isChecked={value}
                                        onChange={(isChecked) => {
                                            setValue('isPlanningToolsEnabled', isChecked, { shouldDirty: true });
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row-reverse">
                <Button variant="success" type="submit" disabled={isLoading || isUpdating}>Save Changes</Button>
            </div>
        </form>
    );
};

export default EditLoginDetailsForm;
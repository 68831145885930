import React from "react";
import DetailsWrapper from "./DetailsWrapper";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";

const BasicFetchError = ({ error, retryCallback }) => {

    return <DetailsWrapper>
        <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
            <ErrorMessage error={error} retryCallback={retryCallback} />
        </ErrorMessageWrapper>
    </DetailsWrapper>
};

export default BasicFetchError;
import ReportingManagementLayout from "../layouts/ReportingManagementLayout";
import { NavigationTabs } from "../components/navigation";
import {
    AccountingReportsPage,
    ManagementReportsPage
} from "../pages/management-reporting";

const ManagementReportingRoutes = {
    path: 'reporting',
    pathBase: '/management/reporting',
    defaultRoute: 'reports',
    roles: [],
    element: ReportingManagementLayout,
    children: [
        { 
            path: "reports", 
            icon: "fa-file-signature", 
            label: "Management Reports",
            roles: [],
            element: ManagementReportsPage
        },
        { 
            path: "accounting", 
            icon: "fa-file-signature", 
            label: "Accounting Reports",
            roles: [],
            element: AccountingReportsPage
        }
    ]
};

export const ManagementReportingRouteTabs = () => <NavigationTabs pathBase={ManagementReportingRoutes.pathBase} options={ManagementReportingRoutes} />

export default ManagementReportingRoutes;
import { Spinner } from "../../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { CenterPage } from "../../../components/utilities";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import UserCalendarToolbar from "./UserCalendarToolbar";
import UserCalendarMonthEvent from "./UserCalendarMonthEvent";
import { useCallback } from "react";
import { useTheme } from "styled-components";
import UserCalendarWeekEvent from "./UserCalendarWeekEvent";
import UserCalendarDayEvent from "./UserCalendarDayEvent";
import UserCalendarAgendaEvent from "./UserCalendarAgendaEvent";
import UserCalendarEventWrapper from "./UserCalendarEventWrapper";
import { useDiaryContext } from "../hooks/DiaryContext";
import styled from "styled-components";
import 'moment/locale/en-gb';

moment.locale('cs');
const localizer = momentLocalizer(moment);

const UserCalendar = () => {

    const { data, isLoading, isError, error, monthIndex, currentMonth, nextMonth, previousMonth, reload } = useDiaryContext();
    const theme = useTheme();

    /**
     * @param event
     * 
     * We want to use the primary colour of the app if:
     *  - The event is a client meeting
     *  - The event is visible by the current user (delegated calendars)
     *  - The event is not all day
     * 
     * We want to use the information colour of the app if:
     *  - Same as primary but it is an all day event
     * 
     * We want to use the muted colour of the app if:
     *  - The event is not visible to the current user
     */
    const getEventColour = useCallback((event) => {
        if (event.masterAccountId && event.isAllDay) {
            return theme.information;
        }

        if (event.masterAccountId && !event.isAllDay) {
            return theme.primary;
        }

        if (!event.masterAccountId) {
            return theme.muted;
        }
    }, []);

    const eventPropGetter = useCallback((event, ...rest) => {
        return ({
            style: {
                backgroundColor: getEventColour(event),
                color: theme.light
            }
        });
    }, []);

    if (isLoading) {
        return <CenterPage>
            <Spinner />
        </CenterPage>;
    }

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={reload} />
        </ErrorMessageWrapper>;
    }

    return <Calendar
            components={{
                toolbar: (props) => <UserCalendarToolbar
                    current={currentMonth}
                    next={nextMonth}
                    previous={previousMonth}
                    {...props}
                />,
                agenda: {
                    event: (props) => <UserCalendarAgendaEvent {...props} />
                },
                day: {
                    event: (props) => <UserCalendarDayEvent {...props} />
                },
                month: {
                    event: (props) => <UserCalendarMonthEvent  {...props} />
                },
                week: {
                    event: (props) => <UserCalendarWeekEvent {...props} />
                },
                eventWrapper: UserCalendarEventWrapper
            }}
            eventPropGetter={eventPropGetter}
            localizer={localizer}
            events={data}
            startAccessor="start"
            endAccessor="end"
        />;
};

export default UserCalendar;
import styled from "styled-components";

const FooterRight = styled.div`
    grid-area: fright;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

export default FooterRight;
import React, { useMemo, useState, useEffect } from "react";
import InstructionRow, { InstructionRowHeaders } from "./InstructionRow";
import CashSourceRow from "./CashSourceRow";
import { ThemedSpan } from "../../../components/utilities";
import { NumericFormat } from "react-number-format";
import InstructionLabelRow from "./InstructionLabelRow";

const TaxFreeCashAndPensionIncomeCash = ({ data, updateFn, cashSourceUpdateFn, showCashUpdatedModal, setTotalCashRaised }) => {

    const totalCashValue = useMemo(() => data.fromCashAccountAmount + data.fromSalesAmount, [data]);

    const remainingBalance = useMemo(() => {
        var balance = totalCashValue - (
            data.toTrusteeCashAmount
            + data.taxFreeCashAmount
            + data.oneOffIncomeAmount
            + data.totalIncomeAmount
        );

        return balance;
    }, [data]);

    const [initialTotalCashValue, setInitialTotalCashValue] = useState(totalCashValue);

    const [isInvestmentInitiallyEqualToCashRaised, setIsInvestmentInitiallyEqualToCashRaised] = useState(totalCashValue === data.investAmount);

    useEffect(() => {
        setTotalCashRaised(totalCashValue);
    }, [totalCashValue, setTotalCashRaised]);

    useEffect(() => {
        if (totalCashValue === initialTotalCashValue) return;
        // these will (rightfully) not update in time for the showCashUpdatedModal() call, but the change will reflect for the next run
        // this is the desired behaviour here as we want the values before the update to be passed in
        setInitialTotalCashValue(totalCashValue);
        setIsInvestmentInitiallyEqualToCashRaised(totalCashValue === data.investAmount);
        showCashUpdatedModal(isInvestmentInitiallyEqualToCashRaised);
    }, [totalCashValue]);

    return <React.Fragment>
        <div className="container-fluid mb-4">
            <CashSourceRow
                label={"From Cash Account"}
                data={data}
                updateFn={updateFn}
                appliesProperty={"fromCashAccountApplies"}
                cashAmountProperty={"fromCashAccountAmount"}
                receivedDateProperty={"fromCashAccountReceivedDate"}
                receivedConfirmedByNameProperty={"fromCashAccountReceivedConfirmedByName"}
                commentProperty={"fromCashAccountComment"}
                receivedConfirmedSetDateProperty={"fromCashAccountReceivedConfirmedSetDate"}
            />

            <CashSourceRow
                label={"From Sales"}
                data={data}
                updateFn={updateFn}
                appliesProperty={"fromSalesApplies"}
                cashAmountProperty={"fromSalesAmount"}
                receivedDateProperty={"fromSalesReceivedDate"}
                receivedConfirmedByNameProperty={"fromSalesReceivedConfirmedByName"}
                commentProperty={"fromSalesComment"}
                receivedConfirmedSetDateProperty={"fromSalesReceivedConfirmedSetDate"}
            />

            <div className="row mt-3">
                <div className="col-2">
                    <b><ThemedSpan>Total Cash</ThemedSpan></b>
                </div>
                <div className="col-2 text-end">
                    <b><NumericFormat value={totalCashValue} prefix={"£"} thousandSeparator displayType="text" decimalScale={2} fixedDecimalScale /></b>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <InstructionRowHeaders />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"To Trustee Cash"}
                appliesProperty={"toTrusteeCashApplies"}
                cashAmountProperty={"toTrusteeCashAmount"}
                dateInstructedProperty={"toTrusteeCashInstructedDate"}
                instructedDateSetProperty={"toTrusteeCashInstructedSetDate"}
                instructedByProperty={"toTrusteeCashInstructedByName"}
                dateConfirmedProperty={"toTrusteeCashConfirmedDate"}
                confirmedDateSetProperty={"toTrusteeCashConfirmedSetDate"}
                confirmedByProperty={"toTrusteeCashConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Income Drawdown"}
                appliesProperty={"incomeDrawdown"}
                dateInstructedProperty={"incomeDrawdownInstructedDate"}
                instructedDateSetProperty={"incomeDrawdownInstructedSetDate"}
                instructedByProperty={"incomeDrawdownInstructedByName"}
                dateConfirmedProperty={"incomeDrawdownConfirmedDate"}
                confirmedDateSetProperty={"incomeDrawdownConfirmedSetDate"}
                confirmedByProperty={"incomeDrawdownConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Tax Free Cash"}
                appliesProperty={"taxFreeCashApplies"}
                cashAmountProperty={"taxFreeCashAmount"}
                dateInstructedProperty={"taxFreeCashInstructedDate"}
                instructedDateSetProperty={"taxFreeCashInstructedSetDate"}
                instructedByProperty={"taxFreeCashInstructedByName"}
                dateConfirmedProperty={"taxFreeCashConfirmedDate"}
                confirmedDateSetProperty={"taxFreeCashConfirmedSetDate"}
                confirmedByProperty={"taxFreeCashConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"One-off Income"}
                appliesProperty={"oneOffIncomeApplies"}
                cashAmountProperty={"oneOffIncomeAmount"}
                dateInstructedProperty={"oneOffIncomeInstructedDate"}
                instructedDateSetProperty={"oneOffIncomeInstructedSetDate"}
                instructedByProperty={"oneOffIncomeInstructedByName"}
                dateConfirmedProperty={"oneOffIncomeConfirmedDate"}
                confirmedDateSetProperty={"oneOffIncomeConfirmedSetDate"}
                confirmedByProperty={"oneOffIncomeConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Income"}
                appliesProperty={"incomeApplies"}
                frequencyProperty={"incomeFrequency"}
                cashAmountProperty={"incomeAmount"}
                dateInstructedProperty={"incomeInstructedDate"}
                instructedDateSetProperty={"incomeInstructedSetDate"}
                instructedByProperty={"incomeInstructedByName"}
                dateConfirmedProperty={"incomeConfirmedDate"}
                confirmedDateSetProperty={"incomeConfirmedSetDate"}
                confirmedByProperty={"incomeConfirmedByName"}
            />

            <InstructionLabelRow
                className={"mt-4 mb-2"}
                label={<ThemedSpan variant={remainingBalance !== 0 ? "danger" : null}>Remaining Balance</ThemedSpan>}
                value={<NumericFormat
                    className={remainingBalance !== 0 ? "text-danger" : null}
                    value={remainingBalance}
                    prefix={"£"}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />}
            />
        </div>
    </React.Fragment>
};

export default TaxFreeCashAndPensionIncomeCash;
import { wealthProApi } from '../../redux/api';
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

const clientAuthVerificationEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        checkIsEmailAddressAvailable: builder.mutation({
            query: ({ emailAddress }) => ({
                url: `/api/clientauth/verification/email-address`,
                method: 'POST',
                body: { emailAddress }
            })
        }),
        checkIsUsernameAvailable: builder.mutation({
            query: ({ username }) => ({
                url: `/api/clientauth/verification/username`,
                method: 'POST',
                body: { username }
            })
        }),
        fetchMasterAccountDashboardVerificationTokens: builder.query({
            query: ({ masterAccountId, loginId, ...rest }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/tokens`,
                method: 'POST',
                body: { masterAccountId, loginId, ...rest }
            }),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
        })
    })
});

export const {
    useCheckIsEmailAddressAvailableMutation,
    useCheckIsUsernameAvailableMutation,
    useFetchMasterAccountDashboardVerificationTokensQuery,
    useLazyFetchMasterAccountDashboardVerificationTokensQuery
} = clientAuthVerificationEndpoints;
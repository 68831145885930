import { wealthProApi } from '../../redux/api';

const hoAdminIntroducersApiEndpoints = wealthProApi.enhanceEndpoints({})
.injectEndpoints({
    endpoints: (builder) => ({
        searchHoAdminIntroducers: builder.query({
            query: ({searchText, page = 1, limit = 100, sort = "Surname, Forenames"}) => {
                return `api/headofficeadmin/introducers/search?searchTerm=${searchText}&page=${page}&limit=${limit}&sort=${sort}`
            }
        })
    })
});

export const {
    useLazySearchHoAdminIntroducersQuery
} = hoAdminIntroducersApiEndpoints;
import React from "react";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { FormLabel } from "../../../components/forms";
import { Spinner } from "../../../components/loaders";
import { AccordionBody, AccordionHeader, AccordionListItem } from "../../../components/utilities";
import ShareClassRow from "../components/ShareClassRow";
import useShareClassMappings from "../hooks/useShareClassMappings";

const MappedShareClasses = ({ assetGroupId }) => {
    const [{ createMappingDraft, updateMapping, deleteRow }, { shareClassRows, isFetchingShareClasses, canEdit }] = useShareClassMappings(assetGroupId);

    return <AccordionListItem eventKey="mapped-share-classes">
        <AccordionHeader>Mapped Share Classes</AccordionHeader>
        <AccordionBody>
            {(isFetchingShareClasses || shareClassRows == null)
                ? <Spinner />
                : shareClassRows?.length === 0
                    ? <p className="pt-3">No share classes have been mapped for this fund, click <button className="anchor" onClick={createMappingDraft}>here</button> to add one.</p>
                    : <GridWrapper gridTemplateColumns={"1fr 3fr 2fr 2fr auto"}>
                        <FormLabel>SEDOL</FormLabel>
                        <FormLabel>Share Class Name</FormLabel>
                        <FormLabel>Added By</FormLabel>
                        <FormLabel>Date Added</FormLabel>
                        <IconButton
                            className={!canEdit ? "invisible" : ""}
                            icon="plus"
                            variant="success"
                            onClick={createMappingDraft}
                        />

                        {shareClassRows.map((row, index) =>
                            <ShareClassRow
                                key={row.assetId ?? index}
                                shareClass={row}
                                index={index}
                                canEdit={canEdit}
                                updateMapping={(updatedData) => updateMapping(index, updatedData)}
                                deleteRow={() => deleteRow(index)}
                            />)}
                    </GridWrapper>}
        </AccordionBody>
    </AccordionListItem>
}

export default MappedShareClasses;
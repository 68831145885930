import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NewMoneyStatuses, useAdminNewMoney } from "../hooks/useAdminNewMoney";
import { NewMoneyApproveModal } from "./NewMoneyApproveModal";

export const NewMoneyStatusCellComponent = ({ value, row }) => {
    const {
        approveNewMoney, cancelNewMoney, canWrite, isLoading
    } = useAdminNewMoney();

    const [showApproveModal, setShowApproveModal] = useState(false);

    const approve = (newMoney) => {
        setShowApproveModal(false);
        approveNewMoney(row.newMoneyId, newMoney);
    }

    const cancel = () => {
        setShowApproveModal(false);
        cancelNewMoney(row.newMoneyId);
    }

    return <td className="text-center align-middle">
        <NewMoneyApproveModal
            show={showApproveModal}
            onHide={() => setShowApproveModal(false)}
            approve={approve}
            cancel={cancel}
            existingNewMoney={row}
            disabled={isLoading}
        />
        {/* {canWrite && <NewMoneyApproveModal
            show={showApproveModal}
            onHide={() => setShowApproveModal(false)}
            onApprove={(updatedDate) => approveNewMoney(row.newMoneyId, updatedDate)}
            newMoneyDescription={row.description}
            newMoneyDate={row.newMoneyDate} />} */}

        <OverlayTrigger
            key="trigger"
            overlay={(props) => <Tooltip {...props}>
                New Money {NewMoneyStatuses.find((status) => status.value === value)?.label}
                {value === 0 // If pending, show action text
                    && <>
                        <br />
                        {canWrite ? "Click to Approve/Cancel" : "Awaiting approval"}
                    </>}
            </Tooltip>}
        >
            {/* Pending, show context menu with approve and cancel options, with small modal for confirmation and checking whether to update date */}
            {value === 0
                ? canWrite
                    /* <Dropdown className="caret-off w-100">
                        <Dropdown.Toggle as={NewMoneyActionToggle} />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={approve}>
                                Approve New Money
                            </Dropdown.Item>
                            <Dropdown.Item onClick={cancel}>
                                Mark as Not Taken Up
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */
                    ? <FontAwesomeIcon
                        onClick={() => setShowApproveModal(true)}
                        icon="pen-to-square"
                        className="text-dark has-pointer"
                        size="lg" />
                    : <FontAwesomeIcon icon="fa-hourglass-half" className="text-secondary" size="xl" />
                // Approved or Not Taken Up, show tick or cross
                : value === 1
                    ? <FontAwesomeIcon icon="fa-check-circle" className="text-success" size="xl" />
                    : <FontAwesomeIcon icon="fa-times-circle" className="text-danger" size="xl" />}
        </OverlayTrigger>
    </td>;
};
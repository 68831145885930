import { useEffect } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchMasterAccountEmailPreviewsQuery } from "../../../services/useremails";
import { useClientEmails } from "./ClientEmailsContext";

const useClientEmailPreviews = () => {
    const { searchTerm, attachmentsOnly } = useClientEmails();
    const { id: masterAccountId } = useClientContext();
    const limit = 50;
    const [fetchPreviews, { data, isLoading, isFetching, isError, error, isUninitialized }] = useLazyFetchMasterAccountEmailPreviewsQuery();
    const { results, pagination } = data || { results: [], pagination: { totalCount: 50, page: 0 } };
    const { totalCount, page } = pagination;

    const clear = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        fetchPreviews({
            page: 1,
            limit,
            masterAccountId,
            searchTerm,
            attachmentsOnly
        });
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => fetchPreviews({
        page: page + 1,
        limit,
        masterAccountId,
        searchTerm,
        attachmentsOnly
    });

    useEffect(() => {
        !isUninitialized && clear();
    }, [searchTerm, attachmentsOnly])

    return {
        results,
        error,
        isError,
        isLoading: isFetching,
        searchTerm,
        totalCount,
        clear,
        isItemLoaded,
        loadMoreItems,
    };
};

export default useClientEmailPreviews;
import { wealthProApi } from '../../redux/api';

const reportAuthorEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchAvailableAuthorsList: builder.query({
            query: () => `/api/reports/available-authors/list`
        })
    })
});

export const {
    useFetchAvailableAuthorsListQuery
} = reportAuthorEndpoints;
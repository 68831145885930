import React, { useContext, useState } from "react";
import { socket } from "../redux/socket";

const SocketContext = React.createContext();

export const useSocketContext = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const [isConnected, setIsConnected] = useState((socket.socket != null));
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const connect = () => {
        if (socket.socket) {
            setIsConnected(_ => true);
            return;
        }

        setIsLoading(_ => true);
        socket.connect().then(() => {
            setIsLoading(_ => false);
            setIsConnected(_ => true);
        });
    };

    return <SocketContext.Provider value={{
        connect,
        isConnected,
        isError,
        isLoading
    }}>
        {children}
    </SocketContext.Provider>
};
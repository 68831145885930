import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-wrap: no-wrap;

    & > div:not(:last-child) {
        margin-right: -15px;
    }
`;

export default Container;
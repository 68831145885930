import _ from "lodash";
import React from "react";
import classNames from "classnames";
import TableColumn from "./TableColumn";

const AccessLevelDisplayColumn = ({ data }) => {
    const {
        isPortfolioEnabled,
        isValuationsEnabled,
        isCommunicationsEnabled,
        isFactFindEnabled,
        isBusinessSheetsEnabled,
        isAdviceReportsEnabled,
        isTaxPacksEnabled,
        isContractNotesEnabled,
        isCustodyStatementsEnabled,
        isCostDisclosuresEnabled,
        isPlanningToolsEnabled
    } = data;

    const hasLimitedAccess = _.some(Object.keys(data), i => data[i] === false);

    return (
        <TableColumn>
            <div className={classNames('badge', {
                'bg-access-limited text-access-limited': hasLimitedAccess === true,
                'bg-access-full text-access-full': hasLimitedAccess === false
            })}>
                {hasLimitedAccess ? 'Limited Access' : 'Full Access'}
            </div>
        </TableColumn>
    );
};

export default AccessLevelDisplayColumn;
import { wealthProApi } from '../../redux/api';

const campaignProjectStatusApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["campaignStatusList"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchCampaignProjectStatuses: builder.query({
            query: ({ campaignId }) => `api/campaignmanagement/campaigns/${campaignId}/status/list`,
            providesTags: (result, error, arg) => {
                return ['campaignStatusList'];
            }
        })
    })
});

export const {
    useFetchCampaignProjectStatusesQuery,
    useLazyFetchCampaignProjectStatusesQuery
} = campaignProjectStatusApiEndpoints;
import { wealthProApi } from '../../redux/api';

const clientAuthAuthenticatorApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["loginAuthenticator"]
}).injectEndpoints({
    endpoints: (builder) => ({
        getAuthenticatorForLogin: builder.query({
            query: ({ masterAccountId, loginId }) => `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/authenticator`,
            providesTags: (result, error, arg) => {
                return ['loginAuthenticator'];
            }
        }),
        updateAuthenticator: builder.mutation({
            query: ({ masterAccountId, loginId, data }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/authenticator`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['loginAuthenticator']
        })
    })
});

export const {
    useGetAuthenticatorForLoginQuery,
    useLazyGetAuthenticatorForLoginQuery,
    useUpdateAuthenticatorMutation
} = clientAuthAuthenticatorApiEndpoints;
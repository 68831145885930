import React from "react";
import styled from "styled-components";
import ErrorCode from "./ErrorCode";
import { variantToColour } from "../../helpers/theme";

const Header = styled.h1`
    font-weight: bold;
    color: ${props => variantToColour({ theme: props.theme, variant: 'primary' })};
`;

const ErrorCodeDisplay = ({ code = 400, subHeader, children }) => {
    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center p-md-3">
                <ErrorCode>{code}</ErrorCode>
            </div>
            <div className="px-3">
                <Header>Whoops!</Header>
                {subHeader && (<h2>It's a secret to everybody...</h2>)}
                {children}
            </div>
        </React.Fragment>
    );
};

export default ErrorCodeDisplay;
import React from "react";
import ReviewDetails from "./ReviewDetails";
import PortfolioValuation from "./PortfolioValuation";
import PreReviewDocument from "./PreReviewDocument";
import ReviewAppointment from "./ReviewAppointment";
import FactFindMeetingNotes from "./FactFindMeetingNotes";
import FollowUpReport from "./FollowUpReport";
import Skeleton from "react-loading-skeleton";
import useClientReview from "../hooks/useClientReview";
import CenterPage from "../../../components/utilities/CenterPage";
import {
    ErrorMessage,
    ErrorMessageWrapper,
} from "../../../components/messages";
import ReviewConclusion from "./ReviewConclusion";
import Drawdown from "./Drawdown";
import useReportTypes from "../hooks/useReportTypes";

const Review = ({ reviewId }) => {
    const {
        review,
        loadingReview,
        errorFetchingReview,
        patchService,
        rearrangeAppointment,
        selectPreReviewDocument,
        selectFollowUpReport
    } = useClientReview(reviewId);

    const {prdType, loadingPrdType, followUpType, loadingFollowUpType} = useReportTypes();

    const onFieldChanged = (property, value) => patchService(property, value);

    const AMBER_WARNING = 5;

    if (errorFetchingReview) {
        return (
            <CenterPage>
                <ErrorMessageWrapper>
                    <ErrorMessage />
                </ErrorMessageWrapper>
            </CenterPage>
        );
    }

    if (review == null || typeof review === "undefined" || loadingReview 
        || loadingPrdType || prdType == null || typeof prdType === "undefined" 
        || loadingFollowUpType || followUpType == null || typeof followUpType === "undefined") {
        return (
            <div>
                <div className="row">
                    <div className="col-3">
                        <Skeleton height={37} />
                    </div>
                    <div className="col-3">
                        <Skeleton height={37} />
                    </div>
                    <div className="col-6">
                        <Skeleton height={37} />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <Skeleton height={37} />
                    </div>
                    <div className="col-6">
                        <Skeleton height={37} />
                    </div>
                </div>
                {/* Remaining 4 rows */}
                {Array(4)
                    .fill()
                    .map((_, index) => {
                        return (
                            <div className="row mt-2" key={index}>
                                <div className="col-3">
                                    <Skeleton height={37} />
                                </div>
                                <div className="col-6">
                                    <Skeleton height={37} />
                                </div>
                                <div className="col-3">
                                    <Skeleton height={37} />
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }

    return (
        <span>
            <ReviewDetails review={review} onFieldChanged={onFieldChanged} />

            <PortfolioValuation
                review={review}
                onFieldChanged={onFieldChanged}
                amberWarning={AMBER_WARNING}
            />
            
            {review.isDrawdownReview && 
                <Drawdown
                review={review}
                onFieldChanged={onFieldChanged}
                amberWarning={AMBER_WARNING} 
                />
            }

            {review.clientServiceLevelAgreement.serviceLevelAgreement
                ?.isPreReviewDocumentRequired && (
                <PreReviewDocument
                    review={review}
                    onFieldChanged={onFieldChanged}
                    select={selectPreReviewDocument}
                    prdType={prdType}
                />
            )}

            {review.clientServiceLevelAgreement.serviceLevelAgreement
                ?.isAppointmentRequired && (
                <ReviewAppointment
                    review={review}
                    onFieldChanged={onFieldChanged}
                    rearrange={rearrangeAppointment}
                />
            )}

            {review.clientServiceLevelAgreement.serviceLevelAgreement
                ?.isPostMeetingRequired && review.appointmentStatus == 4 && (
                <FactFindMeetingNotes
                    review={review}
                    onFieldChanged={onFieldChanged}
                />
            )}

            {review.appointmentStatus == 4 && <ReviewConclusion review={review} onFieldChanged={onFieldChanged} />}

            {review.clientServiceLevelAgreement.serviceLevelAgreement
                ?.isFollowUpRequired && review.appointmentStatus === 4 && (
                <FollowUpReport
                    review={review}
                    onFieldChanged={onFieldChanged}
                    select={selectFollowUpReport}
                    followUpType={followUpType}
                />
            )}
        </span>
    );
};

export default Review;

import { FormSelect } from "../../../components/forms";

const ServiceAppointmentStylesSelect = ({ options, ...rest }) => {
    return (
        <FormSelect 
            options={[
                { label: 'Home Meeting', value: 0 },
                { label: 'Office Meeting', value: 1 },
                { label: 'Phone Call', value: 2 },
                { label: 'Video Meeting', value: 3 },
                { label: 'No Appointment Required', value: 4 }
            ]}
            {...rest}
        />
    );
};

export default ServiceAppointmentStylesSelect;
import React, { useState } from "react";
import { useLazySearchReminderTasksQuery, usePatchReminderTaskMutation } from "../services/reminders";
import { useClientContext } from "./ClientContext";
import { useFilterContext } from "./FilterContext";
import { patchReplace } from "../helpers/patchDoc";

const useClientEvents = () => {
    const [fetchEvents, loadResults] = useLazySearchReminderTasksQuery();
    const [patchEventTrigger, patchResult] = usePatchReminderTaskMutation();

    const [limit, setLimit] = useState(25);

    const { id: masterAccountId } = useClientContext();

    const { filter } = useFilterContext();

    React.useEffect(() => {
        fetchEvents({ limit, cacheKey: new Date().valueOf(), filter: { ...filter, masterAccountId } });
    }, [fetchEvents, filter, limit, masterAccountId]);

    const patchEvent = (reminderId, property, value) => {
        return new Promise((resolve, reject) => {
            patchEventTrigger({ reminderId, operations: patchReplace(property, value) })
                .unwrap()
                .then(resolve, reject);
        });
    }

    return {
        masterAccountId,
        patchEvent,
        patchResult,
        ...loadResults
    };
}

export default useClientEvents;
import _ from "lodash";
import { wealthProApi } from '../../redux/api';

const documentEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["clientDocuments"]
})
.injectEndpoints({
    endpoints: (builder) => ({ 
        getDocumentOpenUrl: builder.query({
            query: ({ documentId, isHeadOffice }) => `/api/clientdocuments/documents/${documentId}/open${isHeadOffice ? `?isHeadOffice=${isHeadOffice}` : ""}`
        }),
        patchDocumentProperties: builder.mutation({
            query: ({ documentId, operations }) => ({
                url: `api/clientdocuments/documents/${documentId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ documentId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    if (data) {
                        dispatch(wealthProApi.util.updateQueryData('searchMasterAccountDocuments', {}, (draft) => ({
                            ...draft,
                            results: draft.results.map(el => el.documentId === documentId ? data : el)
                        })))
                    }
                }
                catch (error) {
                    console.error(error);
                }
            }
        }),
        preUploadDocumentCheck: builder.query({
            query: (data) => ({
                url: `/api/clientdocuments/documents/precheck`,
                method: 'POST',
                body: data
            })
        }),
        searchMasterAccountDocuments: builder.query({
            query: ({ masterAccountId, ...data }) => ({
                url: `/api/clientdocuments/masteraccounts/${masterAccountId}/documents`,
                method: 'POST',
                body: data
            }),
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                }
                else {
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                }            
            }
        }),
        uploadDocument: builder.mutation({
            query: ({ data }) => ({
                url: `/api/clientdocuments/upload`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useGetDocumentOpenUrlQuery,
    useLazyFetchProjectsQuery,
    useLazyGetDocumentOpenUrlQuery,
    useLazyPreUploadDocumentCheckQuery,
    useLazySearchMasterAccountDocumentsQuery,
    useSearchMasterAccountDocumentsQuery,
    usePatchDocumentPropertiesMutation,
    useUploadDocumentMutation
} = documentEndpoints;
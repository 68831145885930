import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";

/**
 * Had to split this from Client Messaging API endpoints
 * Although they target the same service, it was too messy with cache/props etc
 */
const applicationMessagesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["hoMessages"]
}).injectEndpoints({
    endpoints: (builder) => ({
        createApplicationMessage: builder.mutation({
            query: (body) => ({
                url: 'api/messaging/messages',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ["hoMessages"]
        }),
        deleteApplicationDraftMessage: builder.mutation({
            query: ({ messageId }) => ({
                url: `api/messaging/messages/${messageId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ messageId, applicationId, active, page }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchApplicationMessages', { applicationId, page, active }, ({ results, pagination }) => {
                        return {
                            pagination: {
                                totalCount: pagination.totalCount - 1,
                                totalPages: pagination.totalPages,
                                pageSize: pagination.pageSize,
                                page: page
                            },
                            results: results.filter(x => x.messageId !== messageId)
                        }
                    }));
                }
                catch { }
            }
        }),
        patchApplicationMessage: builder.mutation({
            query: ({ messageId, operations }) => ({
                url: `api/messaging/messages/${messageId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ messageId, operations, page, active, applicationId }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchApplicationMessages', { applicationId, page, active }, ({ results }) => {
                    var item = results.find(x => x.messageId === messageId);
                    applyPatch(item, operations, true);
                }));

                try {
                    await queryFulfilled;

                }
                catch {
                    result.undo();
                }
            }
        }),
        sendApplicationMessage: builder.mutation({
            query: ({ messageId }) => ({
                url: `api/messaging/messages/send/${messageId}`,
                method: 'POST'
            }),
            invalidatesTags: ["hoMessages"]
        }),
        toggleApplicationMessageTodo: builder.mutation({
            query: ({ messageId, newValue }) => ({
                url: `api/messaging/messages/isdone/${messageId}?newValue=${newValue}`,
                method: 'PATCH'
            }),
            async onQueryStarted({ messageId, active, page, applicationId }, { dispatch, queryFulfilled }) {
                try {
                    var { data } = await queryFulfilled;
                    var operations = [
                        patchReplace("isDone", data.newValue),
                        patchReplace("doneDate", data.doneDate),
                        patchReplace("doneByName", data.toggledByName),
                        patchReplace("doneById", data.toggledBy)
                    ];

                    dispatch(wealthProApi.util.updateQueryData('fetchApplicationMessages', { applicationId, page, active }, ({ results }) => {
                        var item = results.find(x => x.messageId === messageId);
                        applyPatch(item, operations, true);
                    }));
                }
                catch { }
            }
        }),
        updateApplicationMessageRecipients: builder.mutation({
            query: ({ messageId, recipients, threadId }) => ({
                url: `api/messaging/messages/${messageId}/recipients`,
                method: 'POST',
                body: {
                    recipientIdList: recipients.map(x => x.value),
                    threadId
                }
            }),
            async onQueryStarted({ messageId, active, page, applicationId, recipients }, { dispatch, queryFulfilled }) {
                const mappedRecipients = recipients.map(x => ({ userId: x.value, recipientType: x.userType, name: x.label }));
                var result = dispatch(wealthProApi.util.updateQueryData('fetchApplicationMessages', { applicationId, page, active }, ({ results }) => {
                    var item = results.find(x => x.messageId === messageId);
                    item.recipients = mappedRecipients;

                }));
                try {
                    var { data } = await queryFulfilled;

                }
                catch {
                    result.undo()
                }
            }
        })
    })
});

export const {
    useCreateApplicationMessageMutation,
    useDeleteApplicationDraftMessageMutation,
    usePatchApplicationMessageMutation,
    useSendApplicationMessageMutation,
    useToggleApplicationMessageTodoMutation,
    useUpdateApplicationMessageRecipientsMutation
} = applicationMessagesApiEndpoints;
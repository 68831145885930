import { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import FileDisplay from "../../../components/FileDisplay";
import Button from "../../../components/buttons";
import { DateInput, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import FormGroup from "../../../components/forms/FormGroup";
import { AMLDocumentSelect, ClientAccountSelect } from "../../../components/forms/selects";
import BasicToast from "../../../components/toasts/BasicToast";
import { useClientContext } from "../../../hooks/ClientContext";
import useAntiMoneyLaunderingEvidence from "../hooks/useAntiMoneyLaunderingEvidence";

const uploadProps = {
    maxFiles: 1,
    accept: {
        "image/jpeg": [],
        "image/png": [],
        "application/pdf": [],
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": []
    }
};

const CreateAMLEvidenceModal = ({ show, onClose, documentTypeOptions }) => {
    const { id: masterAccountId } = useClientContext();

    const {
        createResult, defaultValues, uploadEvidence, isUsingExisting, setIsUsingExisting
    } = useAntiMoneyLaunderingEvidence();

    const { control, watch, formState: { errors }, handleSubmit, reset, setValue } = useForm(defaultValues);

    const closeAndReset = () => {
        reset(defaultValues);
        onClose();
    };

    const documentType = watch("documentType");
    const showEvidenceUpload = documentType !== 4;

    const onSubmit = (data) => {
        uploadEvidence(data, showEvidenceUpload).then(
            () => {
                toast.custom((t) => <BasicToast
                    control={t}
                    date={new Date()}
                    icon="fa-solid fa-list-check"
                    title="Evidence Uploaded"
                    message="Anti-Money Laundering evidence has been uploaded." />, { duration: 3000 });
            },
            (e) => toast.error(e?.data?.message ?? "Unable to upload evidence")
        ).finally(closeAndReset);
    };

    const evidence = watch("evidence");
    const fileName = watch("fileName");

    const onDrop = useCallback(acceptedFiles => {
        setValue("evidence", acceptedFiles[0]);
    }, [setValue]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, ...uploadProps });

    useEffect(() => {
        if (!showEvidenceUpload)
            setValue("evidence", null);
    }, [setValue, showEvidenceUpload]);

    useEffect(() => {
        setValue("fileName", evidence?.name?.split('.')?.[0] ?? "No file selected");
    }, [evidence?.name, setValue]);

    const validateExpiryDateInFuture = (dateString) =>
        new Date(dateString) > new Date() || "Expiry Date must be in the future.";

    return <Modal show={show} onHide={closeAndReset} size="xl" backdrop="static">
        <Modal.Header closeButton={!createResult.isLoading}>
            <Modal.Title>Upload Anti-Money Laundering Evidence</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <div className="row mb-3">
                    {/* Client Account Select */}
                    <div className="col">
                        <Controller
                            name="clientAccountId"
                            control={control}
                            rules={{ required: "You must select a client account." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <ClientAccountSelect
                                label={"Client Account"}
                                defaultValue={value}
                                masterAccountId={masterAccountId}
                                errorAllowRetry={false}
                                disableAnimations
                                errorMessage={errors?.clientAccountId?.message}
                                onChange={selection => setValue("clientAccountId", selection.value)}
                                {...rest} />} />
                    </div>
                    {/* Document Type */}
                    <div className="col">
                        <Controller
                            name="documentType"
                            control={control}
                            rules={{ required: "Document Type is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <FormSelect
                                label={"Document Type"}
                                defaultValue={value}
                                options={documentTypeOptions}
                                errorAllowRetry={false}
                                disableAnimations
                                errorMessage={errors?.documentType?.message}
                                onChange={selection => setValue("documentType", selection.value)}
                                {...rest} />} />
                    </div>
                    {/* Expiry Date */}
                    <div className="col">
                        <Controller
                            name="expiryDate"
                            control={control}
                            rules={{
                                required: "Expiry Date is required.",
                                validate: validateExpiryDateInFuture
                            }}
                            render={({ field: { ref, value, onChange, onBlur, disabled, ...rest } }) => <DateInput
                                label={"Expiry Date"}
                                value={value || null}
                                errorAllowRetry={false}
                                isClearable
                                disableAnimations
                                errorMessage={errors?.expiryDate?.message}
                                onChange={newDate => setValue("expiryDate", newDate)}
                                onDateChanged={newDate => setValue("expiryDate", newDate)}
                                useOnBlurForDateChanged={false}
                                {...rest}
                            />}
                        />
                    </div>
                    {/* Evidence Upload (if document type is not Home Visit*/}
                </div>
                {showEvidenceUpload && <div className="row g-1">
                    <div className="col-3">
                        <Controller
                            name="fileName"
                            control={control}
                            rules={{ required: { value: showEvidenceUpload, message: "Evidence File Name is required." } }}
                            render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <FormGroup>
                                <FormLabel>Evidence File Name</FormLabel>
                                <FormInput
                                    value={fileName}
                                    disabled={evidence == null || isUsingExisting}
                                    onChange={(e) => setValue("fileName", e.target.value)}
                                />
                            </FormGroup>} />
                    </div>
                    <div className="col-9">
                        <FormGroup>
                            <FormLabel>Evidence Upload</FormLabel>
                            {evidence == null
                                ? <Controller
                                    name="evidence"
                                    control={control}
                                    rules={{ required: { value: showEvidenceUpload, message: "Evidence is required." } }}
                                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) =>
                                        <div className="row ms-0">
                                            <div {...getRootProps({ className: 'dropzone d-flex mb-2 border border-3 border-opacity-10 has-pointer col' })}>
                                                <input {...getInputProps()} />
                                                <div className="d-flex flex-fill justify-content-center align-items-center py-1">
                                                    <p className="m-0">Drag and Drop AML Evidence here, or click to select a file.</p>
                                                </div>
                                            </div>
                                            <AMLDocumentSelect
                                                className="col"
                                                masterAccountId={masterAccountId}
                                                placeholder="Select Existing Document"
                                                value={value}
                                                setValue={(selection) => {
                                                    setValue("evidence", {
                                                        documentId: selection.value,
                                                        name: selection.data.fileName,
                                                        size: selection.data.fileSize
                                                    });
                                                    setIsUsingExisting(true);
                                                }}
                                                errorMessage={errors?.evidence?.message}
                                            />
                                        </div>}
                                />
                                : <FileDisplay
                                    className="col-auto mx-1"
                                    fileDataIcon={"file"}
                                    file={evidence}
                                    onClearFile={() => {
                                        setValue("evidence", null);
                                        setIsUsingExisting(false);
                                    }} />}

                        </FormGroup>
                        {/* <Controller
                            name="evidence"
                            control={control}
                            rules={{ required: { value: showEvidenceUpload, message: "Evidence is required." } }}
                            render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <FormGroup>
                                <FormLabel>Evidence Upload</FormLabel>
                                {evidence == null
                                    ? <div {...getRootProps({ className: 'dropzone d-flex mb-2 border border-3 border-opacity-10 has-pointer' })}>
                                        <input {...getInputProps()} />
                                        <div className="d-flex flex-fill justify-content-center align-items-center py-1">
                                            <p className="m-0">Drag and Drop AML Evidence here, or click to select a file.</p>
                                        </div>
                                    </div>
                                    : <FileDisplay
                                        className="col-auto mx-1"
                                        fileDataIcon={"file"}
                                        file={evidence}
                                        onClearFile={() => setFile(null)} />}
                            </FormGroup>} /> */}
                    </div>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={closeAndReset} disabled={createResult.isLoading}>
                    Close
                </Button>
                <Button variant="success" type='submit' disabled={createResult?.isLoading}>
                    Upload Evidence
                </Button>
            </Modal.Footer>
        </form>
    </Modal>;
};

export default CreateAMLEvidenceModal;
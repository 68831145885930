import { useState } from "react";
import FormSelectPaged from "../FormSelectPaged";
import { useLazySearchHeadOfficeMasterAccountsQuery } from "../../../services/headofficeadmin";
import { components } from "react-select";
import styled from "styled-components";

export const HeadOfficeMasterAccountOptionDetailsRow = styled.div`
    font-size: 0.875em;
    color: ${(props) => props.theme.muted};
`;

const HeadOfficeMasterAccountSelectOption = (props) => {
    const { data: { data } } = props;
    const { accountName, accountRef, division, adviser } = data;
    return (
        <components.Option {...props}>
            <div>
                <div>
                    <span>{accountRef}</span> - <span>{accountName}</span>
                </div>
                <HeadOfficeMasterAccountOptionDetailsRow className="flex flex-column text-muted">
                    <div className="text-truncate">
                        <span>Division:</span> {division ?? "N/a"}
                    </div>
                    <div>
                        <span>Adviser:</span> {adviser ?? "N/a"}
                    </div>
                </HeadOfficeMasterAccountOptionDetailsRow>
            </div>
        </components.Option>
    )
};

const HeadOfficeMasterAccountSelect = ({ isMulti, label, onChange, value, valueObject, ...rest }) => {

    return <FormSelectPaged
        setValue={onChange}
        cacheOptions={true}
        label={label}
        isMulti={isMulti}
        value={value}
        valueObject={valueObject}
        query={useLazySearchHeadOfficeMasterAccountsQuery}
        searchTermPropertyName="searchText"
        onOptionsLoaded={(_, data) => data}
        components={{ Option: HeadOfficeMasterAccountSelectOption }}
    />;
};

export default HeadOfficeMasterAccountSelect;
import { useCallback, useState } from "react";
import { useCreateShareClassMappingMutation, useDeleteShareClassMappingMutation, useLazySearchAssetsQuery } from "../../../services/assets";

const useMappingSearch = (assetGroupId, index) => {
    const [sedolSearchTrigger] = useLazySearchAssetsQuery();
    const [createMappingTrigger] = useCreateShareClassMappingMutation();
    const [deleteMappingTrigger] = useDeleteShareClassMappingMutation();

    const [errorMessage, setErrorMessage] = useState(null);

    const deleteMapping = useCallback((oldAssetId) => {
        return new Promise((resolve, reject) => {
            deleteMappingTrigger({ assetGroupId, assetId: oldAssetId, index }).unwrap()
                .then(resolve, reject);
        });
    }, [assetGroupId, deleteMappingTrigger, index]);

    const searchAndCreateMapping = useCallback((input, oldAssetId) => {
        setErrorMessage(null);
        return new Promise((resolve, reject) => {
            sedolSearchTrigger({ searchTerm: input }).unwrap()
                .then((res) => {
                    if (res?.length > 0) {
                        // Need to create a mapping between the SEDOL and the asset if possible, and return an error if not
                        createMappingTrigger({
                            assetGroupId,
                            index,
                            sedol: res[0].sedol ?? input,
                            assetId: res[0].assetId,
                            oldAssetId
                        }).unwrap()
                            .then(() => resolve(res[0]),
                                (err) => {
                                    if (err?.status === 409) {
                                        console.log(err);
                                        // Mapping already exists, reject and set the error message
                                        setErrorMessage(err?.data?.message)
                                    }
                                    reject();
                                });
                    } else {
                        // No asset was found, but the sedol has changed, so remove the old mapping (if it exists)
                        if (oldAssetId != null)
                            return deleteMapping(oldAssetId)
                                .then(() => resolve({ asset: { sedol: input, longAssetName: "Code not recognised" } }));
                        else
                            resolve({ asset: { sedol: input, longAssetName: "Code not recognised" } });
                    }
                }, reject);
        });
    }, [assetGroupId, createMappingTrigger, deleteMapping, index, sedolSearchTrigger]);

    return [{ searchAndCreateMapping, deleteMapping }, { errorMessage }];
}

export default useMappingSearch;
import React, { useEffect, useId, useState } from "react";
import styled from "styled-components";
import FormGroup from "./FormGroup";
import FormLabel from "./FormLabel";
import InputErrorMessage from "./InputErrorMessage";
import { InputSpinnerMd } from "../loaders/InputSpinners";
import { InputGroup } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";

export const StyledTextArea = styled(TextareaAutosize)`
    && {
        ${props => props.loading === 'true' ? InputSpinnerMd : ''}
    }
`;

const FormTextArea = ({
    className,
    disabled,
    disableAnimations = false,
    disableErrorMessage = false,
    id,
    label,
    onBlur,
    onChange,
    minRows = 1,
    maxRows,
    successTimeout = 1000,
    value,
    errorMessage = null,
    errorAllowRetry = true,
    ...rest }) => {
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(errorMessage);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentValue, setCurrentValue] = useState(value || '');
    const [originalValue, setOriginalValue] = useState(value || '');

    const getClassName = () => {
        let name = 'form-control';

        if (className) {
            name += ` ${className}`;
        }

        if (isSuccess === true) {
            name += ` is-valid`;
        }

        if (error) {
            name += ` is-invalid`;
        }

        return name;
    };

    const onBlurEvent = (e) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        // check that the value has changed before triggering anything
        // NOTE: also check the function HAS been passed into this component
        if (currentValue === originalValue || !onBlur || typeof (onBlur) !== 'function') {
            return;
        }

         // trigger the loading animation on the input
         if (disableAnimations !== true) {
            setError(_ => null);
            setIsLoading(_ => true);
        }

        // used to safely check the function returns something we can check on
        var possibleResult = onBlur(currentValue, e);

        // we can now safely use the `then` method and handle the response accordingly
        Promise.resolve(possibleResult).then(
            _ => {
                if (disableAnimations === true) {
                    return;
                }
                setIsLoading(_ => false);
                setIsSuccess(_ => true);
            },
            error => {
                if (disableAnimations === true) {
                    return;
                }
                setIsLoading(_ => false);
                setError(_ => error);
            }
        );
    };

    const onChangeEvent = (e) => {
        setCurrentValue(e.target.value);
        if (onChange && typeof (onChange) === 'function') {
            onChange(e);
        }
    };

    useEffect(() => {
        if (errorMessage) {
            setError(_ => errorMessage);
        }
        else {
            setError(_ => null);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (isSuccess === false) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(_ => false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    useEffect(() => {
        if (value != null && value !== currentValue) {
            setCurrentValue(value);
            setOriginalValue(value)
        }
    }, [ value ]);

    return <FormGroup>
        {label && typeof label === 'string' && <FormLabel htmlFor={componentId}>{label}</FormLabel>}
        {label && typeof label === 'object' && <React.Fragment>{label}</React.Fragment>}
        <InputGroup>
            <StyledTextArea
                id={componentId}
                className={getClassName()}
                value={currentValue}
                loading={isLoading.toString()}
                disabled={disabled || isLoading}
                minRows={minRows}
                maxRows={maxRows}
                onBlur={onBlurEvent}
                onChange={onChangeEvent}
                {...rest}
            />
            {disableErrorMessage !== true && (
                <InputErrorMessage error={error} retryCallback={onBlurEvent} allowRetry={errorAllowRetry} />
            )}
        </InputGroup>
    </FormGroup>
};

export default FormTextArea;
import React from "react";
import toast from "react-hot-toast";
import useEditUserDelegate from "../hooks/useEditUserDelegate";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { FormCheck } from "../../../components/forms";
import { GenericUserSelect } from "../../../components/forms/selects";

const EditUserDelegateModal = ({ 
    show,
    delegate = null,
    handleClose = () => {},
    onUpdatedEvent = () => {}
}) => {
    const { update, defaultValues, isLoading } = useEditUserDelegate(delegate);
    const { control, formState, getValues, handleSubmit, reset, setValue } = useForm({ defaultValues });
    const { errors } = formState;

    const _close = () => {
        reset(defaultValues);
        handleClose();
    };

    const _closeNoChange = () => {
        toast('No changes detected. Delegate was not updated.', { icon: '⚠️' });
        handleClose();
    };

    const _submit = (_) => {
        const { dirtyFields } = formState;
        if (!dirtyFields || Object.keys(dirtyFields).length === 0) {
            _closeNoChange();
            return;
        }

        const operations = Object.keys(dirtyFields).map(el => {
            return { op: 'replace', path: `/${el}`, value: getValues(el) };
        });

        if (!operations || operations.length === 0) {
            _closeNoChange();
            return;
        }

        toast.promise(update(operations), {
            loading: 'Creating delegate access...',
            success: (r) => {
                onUpdatedEvent(r);
                return `Delegate Access has been updated.`;
            },
            error: (err) => {
                return `There was a problem trying to update the delegate access properties.`;
            }
        }).finally(_close);
    };

    React.useEffect(() => {
        reset(defaultValues);
    }, [ defaultValues ]);

    return (
        <Modal size="lg" centered backdrop="static" show={show} onHide={_close}>
            <Modal.Header>
                <Modal.Title>Create new Delegate Access</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(_submit)}>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col">
                            <Controller 
                                name="userId"
                                control={control}
                                rules={{ required: 'A user must be selected' }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect 
                                        label="User (who is giving access)"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        errorMessage={errors?.userId?.message} 
                                        errorAllowRetry={false} 
                                        isDisabled={true}
                                        onChange={(values) => setValue('userId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller 
                                name="delegatedUserId"
                                control={control}
                                rules={{ required: 'A delegate user must be selected' }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect 
                                        label="Delegate User (who is getting access)"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        errorMessage={errors?.delegatedUserId?.message} 
                                        errorAllowRetry={false} 
                                        isDisabled={true}
                                        onChange={(values) => setValue('delegatedUserId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-4">
                            <Controller 
                                name="readCalendar"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Read Calendar"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('readCalendar', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="writeCalendar"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Write Calendar"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('writeCalendar', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="readMail"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Read Mail"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('readMail', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="writeMail"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Write Mail"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('writeMail', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="sendMail"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Send Mail"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('sendMail', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="readMessages"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Read Messages"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('readMessages', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="writeMessages"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Write Messages"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('writeMessages', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="readReminders"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Read Reminders"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('readReminders', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="writeReminders"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Write Reminders"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('writeReminders', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="hasClientAccess"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Client Access"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('hasClientAccess', !value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="muted" disabled={isLoading === true} onClick={() => reset(defaultValues)}>Clear Changes</Button>
                    <Button variant="success" type="submit" disabled={isLoading === true}>Update</Button>
                    <Button variant="light" disabled={isLoading === true} onClick={_close}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditUserDelegateModal;
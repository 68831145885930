import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "../../../components";
import { useProductsGridContext } from "../hooks/ProductsGridContext";
import { ThemedTableHeader } from "../../../components/tables/StandardTable";

const ProductsGridHeader = () => {
    const { columns } = useProductsGridContext();

    return <thead>
        <tr>
            {columns.map((col, key) => col.renderer ? col.renderer({...col, key}) : <ThemedTableHeader key={key}>{col.label}</ThemedTableHeader>)}
            <td align="right">

            </td>
        </tr>
    </thead>;
};

export default ProductsGridHeader;
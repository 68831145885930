import { useProductsGridContext } from "../hooks/ProductsGridContext";

const ProductsGridFooter = () => {
    const { totalCount, columns } = useProductsGridContext();
    return <tfoot className="mb-0">
        <tr>
            <td colSpan={columns.length}>{totalCount} Product(s)</td>
        </tr>
    </tfoot>
};

export default ProductsGridFooter;
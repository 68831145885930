import React, { useEffect } from "react";
import Router from "./routes";
import { RouterProvider } from "react-router-dom";
import { useNavigationContext } from "./hooks/NavigationContext";
import { useSocketContext } from "./hooks/SocketContext";
import { AppErrorPage, SplashPage } from "./pages";

const App = () => {
    const { connect, isConnected, error: socketError, isError: isSocketError } = useSocketContext();
    const { error: navigationLoadingError, isError: isNavigationLoadingError, isLoading: isNavigationLoading } = useNavigationContext();

    // use effect that will establish the socket-io connection on start-up or when the connection closes
    useEffect(() => {
        if (isConnected === false) {
            connect();
        }
    }, [isConnected]);

    // show the splash/loading screen whilst we establish the socket connection AND do the initial load on the navigation
    if (isNavigationLoading === true || isConnected === false) {
        return <SplashPage />
    }

    // display an error page if the navigation fails to load
    if (isNavigationLoadingError) {
        return <AppErrorPage errorMessage={navigationLoadingError} />
    }

    // display an error page if the socket-io connection fails to establish
    if (isSocketError) {
        return <AppErrorPage errorMessage={socketError} />
    }

    return (
        <RouterProvider router={Router} />
    )
};

export default App;
import React from "react";
import useCreateMasterAccount from "./hooks/useCreateMasterAccount";
import Button from "../../components/buttons";
import toast from "react-hot-toast";
import { toNString } from "../../helpers/guids";
import { useAuth } from "react-oidc-context";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { BasicToast } from "../../components/toasts";
import { DateInput, FormInput } from "../../components/forms";
import { BusinessSourceSelect, GenericAdviserSelect, GenericUserDivisionSelect, MasterAccountStatusSelect, MasterAccountTypeSelect, RiskGradingSelect } from "../../components/forms/selects";

const CreateClientModal = ({ 
    displayToast = true,
    handleClose, 
    onClientCreated,
    show,
    ...rest
}) => {
    const { user: { profile } } = useAuth();
    const { createResults: { isLoading }, defaultValues, handleCreate } = useCreateMasterAccount(toNString(profile.division[0]));
    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    const onClose = () => {
        reset(defaultValues);
        
        if (handleClose && typeof(handleClose) === 'function') {
            handleClose();
        }
    };

    const onSubmit = (data) => {
        handleCreate(data).then(
            r => {
                if (onClientCreated && typeof (onClientCreated) === 'function') {
                    onClientCreated(r);
                }
                if (displayToast === true) {
                    toast.custom((t) => <BasicToast 
                        control={t} 
                        date={r?.createdDate} 
                        icon="fa-solid fa-user-plus" 
                        title="Account Created" 
                        message={`Account: '${r?.accountName}' has been created.`}
                        isDismissable={true}
                    />);
                }
            },
            e => {
                console.error('error creating', e);
            }
        )
        .finally(onClose);
    };

    return <Modal size="lg" centered backdrop="static" show={show}>
        <Modal.Header>
            <Modal.Title>Create new Account</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <div className="row mb-2">
                    <div className="col">
                        <Controller 
                            name="accountName"
                            control={control}
                            rules={({ required: "A name must be given to the new account." })}
                            render={({ field: { ref, ...rest } }) => (
                                <FormInput
                                    label="Account Name"    
                                    disableAnimations={true}
                                    errorMessage={errors?.accountName?.message} 
                                    errorAllowRetry={false} 
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <Controller 
                            name="type"
                            control={control}
                            rules={({ required: "Account type must be selected." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <MasterAccountTypeSelect
                                    label="Account Type"    
                                    disableAnimations={true}
                                    defaultValue={value}
                                    onChange={(values) => setValue('type', values.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller 
                            name="adviserId"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <GenericAdviserSelect
                                    label="Adviser"
                                    placeholder="Primary Adviser"
                                    disableAnimations={true}
                                    defaultValue={value}
                                    onChange={(values) => setValue('adviserId', values.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <Controller 
                            name="businessSourceId"
                            control={control}
                            rules={({ required: "A business source must be set for a master account." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <BusinessSourceSelect 
                                    label="Business Source"    
                                    disableAnimations={true}
                                    defaultValue={value}
                                    onChange={(values) => setValue('businessSourceId', values.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller 
                            name="divisionId"
                            control={control}
                            rules={({ required: "Account must belong to at least 1 Division." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <GenericUserDivisionSelect 
                                    label="Division"    
                                    disableAnimations={true}
                                    defaultValue={value}
                                    onChange={(values) => setValue('divisionId', values.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <Controller 
                            name="status"
                            control={control}
                            rules={({ required: "Account must have a valid status." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <MasterAccountStatusSelect
                                    label="Status"    
                                    disableAnimations={true}
                                    defaultValue={value}
                                    onChange={(values) => setValue('status', values.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller 
                            name="statusDate"
                            control={control}
                            rules={({ required: "Account must have a valid status date." })}
                            render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                <DateInput 
                                    label="Status Date"    
                                    disableAnimations={true}
                                    onBlur={(value, e) => setValue('statusDate', value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <Controller 
                            name="riskGrade"
                            control={control}
                            rules={({ required: "Account must have valid risk grading." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <RiskGradingSelect
                                    label="Risk Grade"    
                                    disableAnimations={true}
                                    defaultValue={value}
                                    onChange={(values) => setValue('riskGrade', values.value)}
                                    {...rest}
                                />
                            )}
                        />
                        
                    </div>
                    <div className="col">
                        <Controller 
                            name="riskGradeDate"
                            control={control}
                            rules={({ required: "Account must have valid risk grade date." })}
                            render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                <DateInput 
                                    label="Graded Date"    
                                    disableAnimations={true}
                                    onBlur={(value, e) => setValue('riskGradeDate', value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type="submit" disabled={isLoading === true}>Create Account</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </form>
    </Modal>
};

export default CreateClientModal;
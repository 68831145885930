import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import { ThemedIcon } from "../utilities";

const ReportEfficacyIconDisplayColumnParent = styled.td`
    width: ${({ width }) => width}%;
    max-width: 0;
    padding: 0.6rem !important;
    vertical-align: middle;
`;

const ReportEfficacyIconDisplayColumn = ({ efficacy, width = 5, className = "text-center", size = "lg" }) => {
    return (
        <ReportEfficacyIconDisplayColumnParent width={width} className={classNames(className)}>
            {efficacy === 2 && <ThemedIcon variant="danger" icon="fa-circle-xmark" size={size} />}
            {efficacy === 1 && <ThemedIcon variant="warning" icon="fa-circle-xmark" size={size} />}
            {efficacy === 0 &&  <ThemedIcon variant="success" icon="fa-circle-check" size={size} />}
        </ReportEfficacyIconDisplayColumnParent>
    );
};

export default ReportEfficacyIconDisplayColumn;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { useEffect, useState } from "react";
import { useProductsGridContext } from "../hooks/ProductsGridContext";
import _ from "lodash";
import { FormInput, FormSelect } from "../../../components/forms";
import FormSelectPaged from "../../../components/forms/FormSelectPaged";
import { useLazySearchProvidersQuery } from "../../../services/products";

const FilterProductsModal = ({ show, handleClose }) => {

    const { productsFilter, setProductsFilter, resetFilters, wrappersResult } = useProductsGridContext();
    const { data: wrappers, isLoading: isLoadingWrappers } = wrappersResult || {};
    const [tempFilters, setTempFilters] = useState(productsFilter);

    const {
        productName,
        wrapperId,
        provider,
        defaultCustodian
    } = tempFilters;

    const onApplyFilters = () => {
        setProductsFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof (handleClose) === 'function') {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(productsFilter, tempFilters)) {
            setTempFilters(_ => productsFilter);
        }
    }, [productsFilter]);

    return (
        <Modal centered backdrop="static" size="lg" show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Products</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <FormInput
                            label="Product Name"
                            value={productName}
                            onChange={(e) => onFilterValueChanged("productName", e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <FormSelect
                            label="Wrapper"
                            defaultValue={wrapperId}
                            options={wrappers ?? []}
                            onChange={({ value }) => onFilterValueChanged("wrapperId", value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <FormSelectPaged
                            label="Provider"
                            additional={{ page: 1, limit: 50, fetchType: "list" }}
                            query={useLazySearchProvidersQuery}
                            searchTermPropertyName={"searchTerm"}
                            onOptionsLoaded={(_, results) => results}
                            setValue={(value) => onFilterValueChanged("provider", value)}
                            valueObject={provider}
                        />
                    </div>
                    <div className="col">
                        <FormSelectPaged
                            label="Default Custodian"
                            additional={{ page: 1, limit: 50, fetchType: "list" }}
                            query={useLazySearchProvidersQuery}
                            searchTermPropertyName={"searchTerm"}
                            onOptionsLoaded={(_, results) => results}
                            setValue={(value) => onFilterValueChanged("defaultCustodian", value)}
                            valueObject={defaultCustodian}
                        />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="warning" onClick={resetFilters}>Clear Filters</Button>
                <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default FilterProductsModal;
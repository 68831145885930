import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { CurrencyInput, FormSelect, NumberInput } from "../../../components/forms";
import { exceptionResolutionTypes } from "../../../helpers/constants";
import { useMemo, useState } from "react";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import toast from "react-hot-toast";

const BulkResolveModal = ({ show, onHide }) => {

    const { bulkResolveExceptions, bulkResolveResult: { isLoading: isResolving } } = useCustodianTransactionMatching();

    const [request, setRequest] = useState({
        maxValue: 0,
        platformTransactionType: "PURCH",
        resolutionType: null
    });

    const resolutionTypeOptions = useMemo(() => request.platformTransactionType === "PURCH"
        ? [
            { label: "Regular", value: exceptionResolutionTypes.Regular },
            { label: "Exception", value: exceptionResolutionTypes.Exception },
            { label: "Error", value: exceptionResolutionTypes.Error }
        ]
        : [
            { label: "Top-Up Cash", value: exceptionResolutionTypes.TopUpCash },
            { label: "Exception", value: exceptionResolutionTypes.Exception },
            { label: "Error", value: exceptionResolutionTypes.Error }
        ], [request]);

    const onClose = () => {
        setRequest({
            maxValue: 0,
            resolutionType: null,
            platformTransactionType: "PURCH"
        });

        onHide();
    };

    const onSubmit = (e) => {
        if (typeof e?.preventDefault === "function") {
            e.preventDefault();
        }

        toast.promise(bulkResolveExceptions(request), {
            loading: "Resolving exceptions...",
            error: (err) => err?.data?.message ?? err?.message ?? "Unable to resolve exceptions",
            success: "Exceptions resolved!"
        }).then(() => {
            onClose();
        });
    };

    const updateRequest = (property, value) => {
        setRequest(prev => ({
            ...prev,
            [property]: value
        }));
    };

    return <Modal dialogClassName="min-w-25" show={show} onHide={onClose} centered backdrop="static" size="sm">
        <Modal.Header closeButton>
            <Modal.Title>
                Resolve Transactions
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Please select a value for which all transactions with values lower will be resolved using the provided method.</p>
            <div className="row mb-3">
                <div className="col-6">
                    <FormSelect
                        label={"Transaction Type"}
                        options={[
                            {label: "PURCH", value: "PURCH"},
                            {label: "SALE", value: "SALE"},
                        ]}
                        onChange={({ value }) => updateRequest("platformTransactionType", value)}
                        defaultValue={request.platformTransactionType}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <FormSelect
                        label={"Resolution Type"}
                        options={resolutionTypeOptions}
                        onChange={({ value }) => updateRequest("resolutionType", value)}
                        defaultValue={request.resolutionType}
                    />
                </div>
                <div className="col">
                    <CurrencyInput
                        label={"Value"}
                        onChange={(_, { floatValue }) => updateRequest("maxValue", floatValue)}
                        value={request.maxValue}
                    />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" onClick={onSubmit} disabled={isResolving}>Resolve</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>

};

export default BulkResolveModal;
import React, { useContext, useEffect, useState } from "react";
import { wealthProApi } from "../../../redux/api";
import { useAddClientToFavouritesMutation, useRemoveClientFromFavouritesMutation } from "../../../services/clientdetails";

const ClientSearchContext = React.createContext();

export const useClientSearch = () => {
    return useContext(ClientSearchContext);
};

export const ClientSearchProvider = ({ children }) => {
    const timeout = 500;
    const pageTriggerTimeout = 100;
    const minimumSearchCharacters = 3;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(7);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [addToFavouriteMutation, addToFavouriteResult] = useAddClientToFavouritesMutation();
    const [removeFromFavouriteMutation, removeFromFavouriteResult] = useRemoveClientFromFavouritesMutation();
    const [trigger, result] = wealthProApi.endpoints.searchMasterAccounts.useLazyQuery();
    const { data: response, error, isError, isFetching, isLoading, isUninitialized } = result || {};

    const addToFavourites = (masterAccountId) => {
        return new Promise((resolve, reject) => {
            return addToFavouriteMutation({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const handleOnPageChanged = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        // check we have valid input to send
        if (!searchTerm || searchTerm.length < minimumSearchCharacters) {
            return;
        }
        trigger({ page, limit, searchTerm });
    };

    const handleOnSearchTermChanged = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        // check we have valid input to send
        if (!searchTerm || searchTerm.length < minimumSearchCharacters) {
            return;
        }
        trigger({ page, limit, searchTerm });
    };

    const nextPage = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        setPage(curr => curr + 1);
    };

    const previousPage = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        setPage(curr => curr - 1);
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        if (page > 1) {
            setPage(_ => 1);
        }
        else if (searchTerm && searchTerm.length >= minimumSearchCharacters) {
            trigger({ page, limit, searchTerm });
        }
    };

    const removeFromFavourites = (masterAccountId) => {
        return new Promise((resolve, reject) => {
            return removeFromFavouriteMutation({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    useEffect(() => {
        if (!response) {
            return;
        }
        
        const { pagination, results } = response || {};
        const { totalCount: responseTotalCount, totalPages: responseTotalPages } = pagination || {};
        setData(results);
        setTotalCount(responseTotalCount);
        setTotalPages(responseTotalPages);
    }, [ response ]);

    useEffect(() => {
        const delayedRequest = setTimeout(handleOnSearchTermChanged, timeout);
        return () => clearTimeout(delayedRequest);
    }, [ searchTerm ]);

    useEffect(() => {
        const delayedPageRequest = setTimeout(handleOnPageChanged, pageTriggerTimeout);
        return () => clearTimeout(delayedPageRequest);
    }, [ page ]);    

    return <ClientSearchContext.Provider value={{ 
        addToFavourites,
        data,
        error,
        isError,
        isLoading: isLoading === true || isFetching === true,
        isUninitialized,
        limit,
        minimumCharacters: minimumSearchCharacters,
        nextPage,
        page,
        previousPage,
        reload,
        removeFromFavourites,
        setLimit,
        setPage,
        searchTerm,
        setSearchTerm,
        totalCount,
        totalPages
    }}>
        {children}
    </ClientSearchContext.Provider>
};
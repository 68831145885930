import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { FormInput, FormTextArea } from "../../../components/forms";
import {
    HeadOfficeMasterAccountDesignationsSelect,
    HeadOfficeMasterAccountSelect,
    HeadOfficeWrapperProductsSelect,
    HeadOfficeWrappersSelect
} from "../../../components/forms/selects";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const EditCustodianAccountMatchModal = ({ data, onHide, updateFn }) => {
    const [show, setShow] = useState(false);
    const [tempMatch, setTempMatch] = useState(data);

    const {
        mapId,
        custodianCode,
        platformAccountId,
        masterAccountId,
        masterAccountName,
        designationId,
        wrapperId,
        productId,
        notes,
        providerReference
    } = tempMatch || {};

    const handleClose = () => {
        setShow(false);
        onHide();
    }

    const handleMatchChange = (properties) => {
        setTempMatch(prev => ({
            ...prev,
            ...properties
        }));
    };

    const onSubmit = () => {
        toast.promise(updateFn(mapId, tempMatch), {
            loading: "Updating mapping...",
            error: (err) => err?.data ?? "Unable to update mapping.",
            success: () => {
                onHide();
                return "Mapping updated!";
            }
        })
    };

    useEffect(() => {
        data && setTempMatch(data);
    }, [data]);

    useEffect(() => {
        data && !show && setShow(true);
    }, [data]);

    return (
        <Modal dialogClassName="min-w-1/3" centered backdrop="static" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Match</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <FormInput
                            label="Custodian"
                            readOnly
                            value={custodianCode}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Platform Account"
                            value={platformAccountId}
                            readOnly
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Provider Reference"
                            value={providerReference}
                            onChange={(e) => handleMatchChange({ providerReference: e.target.value })}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <HeadOfficeMasterAccountSelect
                            label="Master Account"
                            valueObject={{ label: masterAccountName, value: masterAccountId }}
                            onChange={({ label, value }) => handleMatchChange({ masterAccountId: value, masterAccountName: label, designationId: null })}
                        />
                    </div>
                    <div className="col">
                        <HeadOfficeMasterAccountDesignationsSelect
                            label="Designation"
                            defaultValue={designationId}
                            masterAccountId={masterAccountId}
                            onChange={({ value }) => handleMatchChange({ designationId: value })}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <HeadOfficeWrappersSelect
                            label="Wrapper"
                            defaultValue={wrapperId}
                            onChange={({ value }) => handleMatchChange({ wrapperId: value, productId: null })}
                        />
                    </div>
                    <div className="col">
                        <HeadOfficeWrapperProductsSelect
                            label="Product"
                            defaultValue={productId}
                            wrapperId={wrapperId}
                            onChange={({ value }) => handleMatchChange({ productId: value })}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <FormTextArea
                        label="Notes"
                        value={notes}
                        minRows={4}
                        onChange={e => handleMatchChange({ notes: e.target.value })}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>Cancel</Button>
                <Button variant="success" onClick={onSubmit}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCustodianAccountMatchModal;
import { useLazyNewMoneyReportQuery } from "../../../services/headofficeadmin";

const useNewMoneyReport = () => {

    const [report, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyNewMoneyReportQuery();

    const getNewMoneyReport = (theRequest) => {
        const reportDate = theRequest.reportDate;
        const adviserIds = theRequest.adviserIds;
        return new Promise((resolve, reject) => {
            
            return report({data: {reportDate: reportDate, adviserIds: adviserIds}}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    return {
        getNewMoneyReport
    }

};

export default useNewMoneyReport;
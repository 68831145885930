import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import FormGroup from "../../../components/forms/FormGroup";
import { FormInputGroup, FormLabel } from "../../../components/forms";
import InputErrorMessage from "../../../components/forms/InputErrorMessage";
import { CalculateClassName, CalculateSelectStyles } from "../../../components/forms/FormSelect";
import { AsyncPaginate } from "react-select-async-paginate";

const FormSelectPaged = ({
    additional = { page: 1, pageLimit: 25 },
    cacheKeyDisabled = false,
    cacheOptions = false,
    className,
    components = null,
    defaultOptions = true,
    errorMessage = null,
    horizontal = false,
    id,
    idProperty = "id",
    isMulti = false,
    label,
    loadOptionsOnMenuOpen = true,
    onOptionsLoaded,
    onStartOptionsLoad = null,
    query,
    setValue = () => console.warn('set value method has not been assigned.'),
    value,
    ...rest
}) => {

    const defaultComponentId = useId();
    const componentRef = useRef();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(null);
    const [fetchKey, setFetchKey] = useState(new Date().valueOf());
    const [fetchOptions, { data }] = query();

    // NOTE: use the standard FormSelect calculation method instead of copy & paste the code...
    const currentClassName = useMemo(() => CalculateClassName(className, false, error, 'flex-fill'), [className, error]);
    const currentStyles = useMemo(() => CalculateSelectStyles(error), [error]);

    async function onLoadOptions(searchText, loadedOptions, { page }) {
        const response = await fetchOptions({ searchText, page });

        return {
            options: response.data?.results,
            hasMore: response.data?.pagination?.totalPages > page,
            additional: {
                page: page + 1,
            },
        };
    };

    return <FormGroup className={className} horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup className={"has-error"} horizontal={horizontal} hasLabel={label ? true : false}>
            <AsyncPaginate
                defaultOptions={defaultOptions}
                id={componentId}
                isMulti={isMulti}
                selectRef={componentRef}
                key={fetchKey}
                cacheOptions={cacheOptions}
                styles={currentStyles}
                className={currentClassName}
                classNamePrefix="Select"
                menuPosition={'fixed'}
                value={value}
                loadOptions={onLoadOptions}
                loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
                components={components}
                onChange={setValue}
                additional={additional}
                {...rest}
            />
            <InputErrorMessage error={error} allowRetry={false} />
        </FormInputGroup>
    </FormGroup>

};

export default FormSelectPaged;
import { useDeleteFeeTransactionMutation, useFetchTransactionAllocationsByGroupingQuery, useSearchTransactionAllocationsByGroupingQuery } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";

const useTransactionFeeAllocations = (groupId) => {

    const { filter } = useFilterContext();
    const { data, isError, isFetching, isLoading, error, refetch } = useSearchTransactionAllocationsByGroupingQuery({ groupId, filters: {
        ...filter,
        adviserIds: filter?.advisers.map(x => x.value),
        introducerIds: filter?.introducers.map(x => x.value),
        masterAccountIds: filter?.masterAccounts.map(x => x.value),
        providerIds: filter?.providers.map(x => x.value),
    } });
    
    const [deleteTrigger] = useDeleteFeeTransactionMutation();

    const deleteAllocationsForFee = (feeTransactionId) => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ feeTransactionId, previousValues: data, groupId, filter })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    const retryFetchTransactionFeeAllocations = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    }

    return {
        data: data || [],
        isError,
        isFetching,
        isLoading,
        error,
        deleteAllocationsForFee,
        retryFetchTransactionFeeAllocations
    };

};

export default useTransactionFeeAllocations;
import { usePricesManagement } from "../hooks/PricesManagementContext";
import { ThemedTableHeader } from "../../../components/tables/StandardTable";

const AssetPricesTableHeader = () => {

    const { columns } = usePricesManagement();

    return (
        <thead>
            <tr>
                {columns.map((col, i) => <ThemedTableHeader key={i}>{col.label}</ThemedTableHeader>)}
                <td/>
            </tr>
        </thead>
    );
};

export default AssetPricesTableHeader;
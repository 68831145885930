import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const reportFeedbackEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchReportFeedback: builder.query({
            query: ({ masterAccountId, reportId }) => `/api/reports/master-accounts/${masterAccountId}/reports/${reportId}/feedback`
        }),
        updateReportFeedbackRecord: builder.mutation({
            query: ({ masterAccountId, reportId, feedbackId, operations }) => ({
                url: `/api/reports/master-accounts/${masterAccountId}/reports/${reportId}/feedback/${feedbackId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, reportId, feedbackId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchReportFeedback', { masterAccountId, reportId }, (data) => {
                    var feedback = data.find(el => el.id === feedbackId);
                    applyPatch(feedback, operations, true);
                }));

                try {
                    const { data } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData('fetchReportFeedback', { masterAccountId, reportId }, (draft) => {
                        var feedback = draft.find(el => el.id === feedbackId);
                        feedback.adviserCommentModifiedById = data.adviserCommentModifiedById;
                        feedback.receivedFromUserId = data.receivedFromUserId;
                    }));
                }
                catch {
                    result.undo();
                }
            }
        }),
        updateReportFeedbackRecordRecipients: builder.mutation({
            query: ({ masterAccountId, reportId, feedbackId, recipients }) => ({
                url: `/api/reports/master-accounts/${masterAccountId}/reports/${reportId}/feedback/${feedbackId}/recipients`,
                method: 'PATCH',
                body: { recipients }
            }),
            async onQueryStarted({ masterAccountId, reportId, feedbackId, recipients }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchReportFeedback', { masterAccountId, reportId }, (data) => {
                        var feedback = data.find(el => el.id === feedbackId);
                        if (!feedback) {
                            return;
                        }
                        feedback.recipients = recipients;
                    }));
                }
                catch { }
            } 
        }),
    })
});

export const {
    useFetchReportFeedbackQuery,
    useLazyFetchReportFeedbackQuery,
    useUpdateReportFeedbackRecordMutation,
    useUpdateReportFeedbackRecordRecipientsMutation
} = reportFeedbackEndpoints;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { DateInput, FormCheck, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { usePersonalReminders } from "../hooks/PersonalRemindersContext";
import { MasterAccountSelect, ReminderTypesSelect, UserDelegatesSelect } from "../../../components/forms/selects";
import { DelegatedAccessTypes, MessagePriorities } from "../../../helpers/constants";
import MasterAccountProjectPagedSelect from "./MasterAccountProjectSelect";

const FormRow = ({ children }) => <div className="row m-3">{children}</div>;

const PersonalRemindersFilterModal = ({ handleClose, show, ...rest }) => {
    const { filter: { defaultFilters, filter, setFilter } } = usePersonalReminders();
    const [tempFilters, setTempFilters] = useState(filter);

    const {
        masterAccount,
        project,
        showCancelled,
        users,
        deadlineDateTo,
        deadlineDateFrom,
        description,
        priorities,
        types
    } = tempFilters;


    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(_ => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof (handleClose) === 'function') {
            handleClose();
        }
    };

    const formatMessageUserOptions = (_, messageUsers) => {
        if (!messageUsers || !Array.isArray(messageUsers)) {
            return [];
        }

        return [
            {
                label: 'Master Account',
                options: messageUsers.filter((el) => el.userType === 0)
            },
            {
                label: 'System Users',
                options: messageUsers.filter((el) => el.userType === 1)
            }
        ]
    };

    useEffect(() => {
        onFilterValueChanged("project", null);
    }, [masterAccount]);

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    return <Modal size="lg" centered backdrop="static" show={show}>
        <Modal.Header>
            <Modal.Title>
                Event Filters
            </Modal.Title>
        </Modal.Header>
        <Modal.Body {...rest}>
            <React.Fragment>
                <FormRow>
                    <div className="col">
                        <UserDelegatesSelect
                            accessType={DelegatedAccessTypes.readReminders}
                            onChange={value => onFilterValueChanged("users", value)}
                            defaultValueObject={users}
                            label="Users"
                            isMulti={true}
                        />
                    </div>
                    <div className="col">
                        <MasterAccountSelect
                            onChange={value => onFilterValueChanged("masterAccount", value)}
                            valueObject={masterAccount}
                            label="Master Account"
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col">
                        <MasterAccountProjectPagedSelect
                            valueObject={project}
                            onChange={value => onFilterValueChanged("project", value)}
                            label="Project"
                            disableAnimations
                            disabled={masterAccount === null}
                            masterAccountId={masterAccount?.value}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Description"
                            onChange={e => onFilterValueChanged("description", e.target.value)}
                            value={description}
                            disableAnimations
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col">
                        <ReminderTypesSelect
                            label="Event Type"
                            defaultValue={types}
                            isMulti={true}
                            showHidden={true}
                            onChange={value => onFilterValueChanged("types", value.map(x => x.value))}
                        />
                    </div>
                    <div className="col">
                        <FormSelect
                            label="Priority"
                            defaultValue={priorities}
                            options={Object.keys(MessagePriorities).map(label => ({ label, value: MessagePriorities[label] }))}
                            onChange={value => onFilterValueChanged("priorities", value.map(x => x.value))}
                            isMulti
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col d-flex align-items-end">
                        <DateInput
                            label="Event Date"
                            isClearable={true}
                            value={deadlineDateFrom}
                            onBlur={(value) => onFilterValueChanged("deadlineDateFrom", value)}
                            disableAnimations
                        />
                        <FormLabel className={"mx-3"}>to</FormLabel>
                        <DateInput
                            isClearable={true}
                            value={deadlineDateTo}
                            onBlur={(value) => onFilterValueChanged("deadlineDateTo", value)}
                            disableAnimations
                        />
                    </div>
                    <div className="col d-flex pt-4">
                        <FormCheck
                            isChecked={showCancelled}
                            groupClassName="mx-auto"
                            label="Show Cancelled"
                            onChange={value => onFilterValueChanged("showCancelled", value)}
                            disableAnimations
                        />
                        {/* <FormCheck
                            isChecked={showCompleted}
                            label="Show Completed"
                            onChange={value => onFilterValueChanged("showCompleted", value)}
                            disableAnimations
                        /> */}
                    </div>
                </FormRow>
            </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
            <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
};

export default PersonalRemindersFilterModal;
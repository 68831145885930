import { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { CurrencyInput, FormLabel } from "../../../components/forms";
import { NotesEditor } from "../../../components/forms/richtexteditor/RichTextEditor";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import AccountAssets from "../components/AccountAssets";
import useAssetsSection from "../hooks/useAssetsSection";

const AssetsSection = () => {

    const { data, isLoading, isError, error, refetchAssetsSection, patchAssetsSection, patchAssetNotes } = useAssetsSection();
    const [assetTotals, setAssetTotals] = useState([]);

    const totalsRow = useMemo(() => {
        if (!assetTotals) return {};

        return {
            cashTotal: assetTotals.reduce((sum, item) => sum + item.cashAssets, 0),
            propertyTotal: assetTotals.reduce((sum, item) => sum + item.propertyAssets, 0),
            additionalInvestmentsTotal: assetTotals.reduce((sum, item) => sum + item.additionalInvestments, 0),
            total: assetTotals.reduce((sum, item) => sum + item.totalAssets, 0),
        }
    }, [assetTotals]);

    if (isLoading) return <Skeleton height={16} count={4} />;

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchAssetsSection} />
        </ErrorMessageWrapper>;
    }

    return <div className="container-fluid">
        {assetTotals.length > 0 && <div className="row d-flex flex-row justify-content-between">
            <div className="col-4 col-lg-3 me-lg-auto">
                <strong>
                    <FormLabel>
                        Owner
                    </FormLabel>
                </strong>
            </div>
            <div className="col-2">
                <strong>
                    <FormLabel>
                        Cash Assets
                    </FormLabel>
                </strong>
            </div>
            <div className="col-2">
                <strong>
                    <FormLabel>
                        Additional Investments
                    </FormLabel>
                </strong>
            </div>
            <div className="col-2">
                <strong>
                    <FormLabel>
                        Property Assets
                    </FormLabel>
                </strong>
            </div>
            <div className="col-2">
                <strong>
                    <FormLabel>
                        Total Assets
                    </FormLabel>
                </strong>
            </div>
        </div>}
        {!assetTotals && <Skeleton height={16} count={3} />}
        {assetTotals && assetTotals.map((assetTotalsItem) => <div
            key={assetTotalsItem.clientName.toLowerCase().replaceAll(/\W/g, '')}
            className="d-flex flex-row row justify-content-between"
        >
            <div className="col-4 col-lg-3 me-lg-auto my-auto">
                <FormLabel>
                    {assetTotalsItem.clientName}
                </FormLabel>
            </div>
            <div className="col-2">
                <CurrencyInput
                    fixedDecimalScale
                    decimalScale={0}
                    disabled
                    value={assetTotalsItem.cashAssets}
                />
            </div>
            <div className="col-2">
                <CurrencyInput
                    fixedDecimalScale
                    decimalScale={0}
                    disabled
                    value={assetTotalsItem.additionalInvestments}
                />
            </div>
            <div className="col-2">
                <CurrencyInput
                    fixedDecimalScale
                    decimalScale={0}
                    disabled
                    value={assetTotalsItem.propertyAssets}
                />
            </div>
            <div className="col-2">
                <CurrencyInput
                    fixedDecimalScale
                    decimalScale={0}
                    disabled
                    value={assetTotalsItem.totalAssets}
                />
            </div>
        </div>)}

        {assetTotals.length > 1 && <div className="d-flex flex-row row justify-content-between">
            <div className="col-4 col-lg-3 me-lg-auto my-auto">
                <strong>
                    <FormLabel>
                        Combined Assets
                    </FormLabel>
                </strong>
            </div>
            <div className="col-2">
                <strong>
                    <CurrencyInput
                        fixedDecimalScale
                        decimalScale={0}
                        disabled
                        value={totalsRow.cashTotal}
                    />
                </strong>
            </div>
            <div className="col-2">
                <strong>
                    <CurrencyInput
                        fixedDecimalScale
                        decimalScale={0}
                        disabled
                        value={totalsRow.additionalInvestmentsTotal}
                    />
                </strong>
            </div>
            <div className="col-2">
                <strong>
                    <CurrencyInput
                        fixedDecimalScale
                        decimalScale={0}
                        disabled
                        value={totalsRow.propertyTotal}
                    />
                </strong>
            </div>
            <div className="col-2">
                <strong>
                    <CurrencyInput
                        fixedDecimalScale
                        decimalScale={0}
                        disabled
                        value={totalsRow.total}
                    />
                </strong>
            </div>
        </div>}

        <div className="mt-4 row">
            <div className="col-6 col-md-4 col-lg-3">
                <CurrencyInput
                    label="Cash & Emergency Requirement"
                    fixedDecimalScale
                    decimalScale={0}
                    value={data?.cashAndEmergencyRequirement}
                    onBlur={(value) => patchAssetsSection(data.assetSectionId, "cashAndEmergencyRequirement", value)}
                />
            </div>
            <div className="col">
                <NotesEditor
                    label={"Specify"}
                    defaultValue={data?.specify}
                    onBlur={(value) => patchAssetsSection(data.assetSectionId, "specify", value)}
                />
            </div>
        </div>

        <NotesEditor
            className="mt-2"
            property={"assets"}
            label={"General Notes"}
            defaultValue={data?.generalNotes?.notes}
            onBlur={(value) => patchAssetNotes(data?.generalNotes?.notesId, value)}
        />

        <AccountAssets
            assetSectionId={data?.assetSectionId}
            setTotals={setAssetTotals}
        />
    </div>
}

export default AssetsSection;
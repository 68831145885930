import React, { useId, useState } from "react";
import FavouriteClientsList from "./components/FavouriteClientsList";
import FavouritesDropdownMenu from "./components/FavouritesDropdownMenu";
import FavouritesDropdownToggle from "./components/FavouritesDropdownToggle";
import RecentClientsList from "./components/RecentClientsList";
import RecentReportsList from "./components/RecentReportsList";
import { Dropdown } from "react-bootstrap";

export const FavouritesDisplay = ({ className, open, onToggle = null, ...rest }) => {
    return <div className={className}>
        <FavouriteClientsList onToggle={onToggle} open={open} {...rest} />
        <RecentClientsList onToggle={onToggle} open={open} {...rest} />
        <RecentReportsList onToggle={onToggle} open={open} {...rest} />
    </div>
};

const Favourites = () => {
    const dropdownId = useId();
    const [isOpen, setIsOpen] = useState(false);

    const handleOnToggle = () => setIsOpen(_ => !isOpen);

    return <Dropdown show={isOpen} className="caret-off" onToggle={handleOnToggle}>
        <Dropdown.Toggle
            id={dropdownId}
            as={FavouritesDropdownToggle}
            onToggle={handleOnToggle}
        />
        <Dropdown.Menu
            as={FavouritesDropdownMenu}
            show={isOpen}
        >
            <FavouritesDisplay open={isOpen} onToggle={handleOnToggle} />
        </Dropdown.Menu>
    </Dropdown>
};

export default Favourites;
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchClientMessagesQuery } from "../../../services/messages";
import MessageList from "./MessageList";

const AllMessagesListWrapper = () => {

    const { id: masterAccountId } = useClientContext();

    return <MessageList
        fetchHook={useLazyFetchClientMessagesQuery}
        queryParams={{ masterAccountId }}
    />;
};

export default AllMessagesListWrapper;
import React from "react";
import ClientManagement from "../../features/clientmanagement";
import { useAuth } from "react-oidc-context";
import { FilterProvider } from "../../hooks/FilterContext";
import { useFetchUsersQuery } from "../../services/identity";

const ClientSearchPage = () => {
    const { user: { profile } } = useAuth();
    const { data: advisers } = useFetchUsersQuery({ fetchType: 'List', division: null, userType: 'Adviser' });
    const currentUserId = profile.sub.replace(/-/g, "");

    const defaultFilters = React.useMemo(() => {
        const filterOnUser = advisers?.results?.some(adviser => adviser.value === currentUserId);
        return {
            accountTypes: [0],
            accountStatuses: [0, 2, 3],
            adviserIds: filterOnUser ? [{ label: profile.name, value: currentUserId }] : [],
            reviewAdviserIds: [],
            hideUnfavourited: false,
            surname: "",
        };
    }, [advisers, currentUserId, profile.name]);

    return (
        <FilterProvider defaultFilters={defaultFilters} filterStore="clientmanagement">
            <ClientManagement />
        </FilterProvider>
    );
};

export default ClientSearchPage;
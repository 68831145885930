import { wealthProApi } from "../../redux/api";

const applyRecipientToGridMessage = (recipient, gridMessage) => {
    gridMessage.userMessagePriority = recipient.recipientPriority;
    gridMessage.userReminderDate = recipient.reminderDate;
};

const userMessageRecipientsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["ProjectMessages"]
}).injectEndpoints({
    endpoints: (builder) => ({
        patchRecipientByUserId: builder.mutation({
            query: ({ userId, messageId, operations }) => ({
                url: `api/messaging/users/${userId}/messages/${messageId}/recipients`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ userId, messageId, projectId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData('searchUserMessages', { userId }, ({ results }) => {
                        let item = results.find(x => x.messageId === messageId);

                        applyRecipientToGridMessage(data, item);
                    }));

                    if (projectId) {
                        dispatch(wealthProApi.util.updateQueryData('fetchProjectMessages', { projectId }, ({ results }) => {
                            let item = results.find(x => x.messageId === messageId);
                            let recipient = item.recipients.find(x => x.userId === userId);

                            recipient.recipientPriority = data.recipientPriority;
                            recipient.reminderDate = data.reminderDate;
                        }));
                    }
                }
                catch (error) {
                    console.error(error);
                }
            },
            invalidatesTags: ["ProjectMessages"]
        })
    })
});

export const {
    usePatchRecipientByUserIdMutation
} = userMessageRecipientsApiEndpoints;
import { Spinner } from "../../components/loaders";
import { usePrdCommentaryContext } from "../../hooks/PrdCommentaryContext";
import { PortfolioSpecificNotesTab, PrdCommentaryTab } from "./components";

const PrdCommentary = () => {
    const { commentaryTypeObj } = usePrdCommentaryContext();

    return <span key={commentaryTypeObj?.value}>
        {commentaryTypeObj == null && <Spinner />}
        {commentaryTypeObj?.path?.toLowerCase() === "portfoliospecific"
            ? <PortfolioSpecificNotesTab />
            : <PrdCommentaryTab />}
    </span>
}

export default PrdCommentary;
import React from "react";
import UsersGridTableHeader from "./components/UsersGridTableHeader";
import UsersGridTableRow from "./components/UsersGridTableRow";
import { useUsersGridData } from "./hooks/UsersGridContext";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";

const UsersGrid = () => {
    const {
        clear: refreshTable,
        data,
        error,
        isLoading,
        isNextPageLoading,
        isNewRecords,
        isRowLoaded,
        loadMore,
        newRecordsSinceCache,
        sort,
        sortTable,
        totalCount
    } = useUsersGridData();
    const isNewRecordsDisplayed = isNewRecords && !isLoading && !isNextPageLoading;

    return (
        <React.Fragment>
            <VirtualTable 
                enableScrollTracking={false}
                row={UsersGridTableRow}
                header={(
                    <thead>
                        <tr>
                            <th className="sticky-header">&nbsp;</th>
                            <UsersGridTableHeader property="username" sort={sort} sortTable={sortTable}>Name</UsersGridTableHeader>
                            <UsersGridTableHeader property="type" sort={sort} sortTable={sortTable}>Account Type</UsersGridTableHeader>
                            <UsersGridTableHeader property="emailAddress" sort={sort} sortTable={sortTable}>Email Address</UsersGridTableHeader>
                            <UsersGridTableHeader property="jobTitle" sort={sort} sortTable={sortTable}>Job Title</UsersGridTableHeader>
                            <UsersGridTableHeader property="location" sort={sort} sortTable={sortTable}>Location</UsersGridTableHeader>
                            <th className="sticky-header">&nbsp;</th>
                        </tr>
                    </thead>
                )}
                footer={
                    <TableRecordCountDisplayFooter 
                        totalCount={totalCount}
                        isNewRecordsDisplayed={isNewRecordsDisplayed}
                        newRecordsSinceCache={newRecordsSinceCache}
                        reload={refreshTable}
                        spanLeft={4}
                        spanRight={3}
                    />
                }
                error={error}
                isLoading={isNextPageLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                itemCount={totalCount}
                itemData={data}
            />
        </React.Fragment>
    );
};

export default UsersGrid;
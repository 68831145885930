import { wealthProApi } from '../../redux/api';

const clientAuthCredentialsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["loginCredentials"]
}).injectEndpoints({
    endpoints: (builder) => ({
        getCredentialsForLogin: builder.query({
            query: ({ masterAccountId, loginId }) => `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/credentials`,
            providesTags: (result, error, arg) => {
                return ['loginCredentials'];
            }
        }),
        updateCredentials: builder.mutation({
            query: ({ masterAccountId, loginId, data }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/credentials`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['loginCredentials']
        })
    })
});

export const {
    useGetCredentialsForLoginQuery,
    useLazyGetCredentialsForLoginQuery,
    useUpdateCredentialsMutation
} = clientAuthCredentialsApiEndpoints;
import React from "react";
import styled from "styled-components";
import { readableColor } from "polished";
import { Outlet } from "react-router-dom";
import { InvestmentCommitteeFundsAnalysisNavigation } from "../routes/investment-committee.funds.routes";
import { PageHeaderTitle } from "../components/utilities/PageHeader";

export const Header = styled.section`
    grid-area: pageheader;
    position: sticky;
    top: var(--navbar-size);
    z-index: 5;
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

export const HeaderBottom = styled.div``;

export const HeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

const InvestmentCommitteeFundsAnalysisLayout = () => (
    <React.Fragment>
        <Header>
            <HeaderTop>
                <PageHeaderTitle>Investment Committee Funds Analysis</PageHeaderTitle>
            </HeaderTop>
            <HeaderBottom>
                <InvestmentCommitteeFundsAnalysisNavigation />
            </HeaderBottom>
        </Header>
        <Outlet />
    </React.Fragment>
);

export default InvestmentCommitteeFundsAnalysisLayout;
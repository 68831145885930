import React from "react";
import FormSelect from "../FormSelect";

export const DocumentTypes = [
    { 
        label: 'Word', 
        value: 0,
        icon: 'fa-file-word',
        mimeTypes: [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ]
    },
    { 
        label: 'Excel', 
        value: 1,
        icon: 'fa-file-excel',
        mimeTypes: [
            "text/csv",
            "application/xml",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ]
    },
    { 
        label: 'Outlook Item', 
        value: 2,
        icon: 'fa-file-code',
        mimeTypes: [
            "application/vnd.ms-outlook"
        ]
    },
    { 
        label: 'Email File', 
        value: 3,
        icon: 'fa-file-code',
        mimeTypes: [
            "message/rfc822"
        ]
    },
    { 
        label: 'PDF', 
        value: 4,
        icon: 'fa-file-pdf',
        mimeTypes: [
            "application/pdf"
        ]
    },
    { 
        label: 'Text', 
        value: 5,
        icon: 'fa-file-lines',
        mimeTypes: [
            "text/plain"
        ]
    },
    { 
        label: 'Image', 
        value: 6,
        icon: 'fa-file-image',
        mimeTypes: [
            "image/jpeg",
            "image/png"
        ]
    },
    { 
        label: 'Video', 
        value: 7,
        icon: 'fa-file-video',
        mimeTypes: [
            "video/mp4",
            "video/mpeg",
            "video/x-msvideo"
        ]
    },
    {
        label: 'Other',
        value: 8,
        icon: 'fa-file',
        mimeTypes: []
    }
];

export const getDocumentTypeFromMimeType = (mimeType) => DocumentTypes.find(el => el.mimeTypes.includes(mimeType) ?? DocumentTypes.find(el => el.value === 8));

const DocumentTypesSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={DocumentTypes.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default DocumentTypesSelect;
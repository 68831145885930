import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { DocumentSelect, ReportStatusSelect } from "../../../components/forms/selects";
import { useClientContext } from "../../../hooks/ClientContext";
import { useForm, Controller } from "react-hook-form";
import { ReportAuthorSelect } from "../../../components/forms/selects";
import { DateTimeInput } from "../../../components/forms";

const DocumentSelectModal = ({
    show = false,
    handleClose,
    onDocumentSelected,
    reportType,
    defaultValues
}) => {
    const { id: masterAccountId } = useClientContext();
    
    console.log(defaultValues);

    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({ defaultValues });

    const handleCancel = () => {
        handleClose();
    }

    const onSubmit = (data) => {
        onDocumentSelected(data).then(
            r => {}
        ).finally(handleClose);
    };
    

    return <Modal centered show={show} backdrop="static" size="lg">
        <Modal.Header>
            <Modal.Title>Correct Existing {reportType} Details</Modal.Title>
        </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body className="container-fluid">
                    <div className="row mb-3">
                        <div className="col">

                        <Controller
                            name="documentId"
                            control={control}
                            rules={{ required: "Document is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <DocumentSelect
                                    label="Document"
                                    placeholder="Choose Document"
                                    masterAccountId={masterAccountId}
                                    errorMessage={errors?.documentId?.message}
                                    value={value}
                                    slug={""}
                                    onChange={(selection) => {
                                        setValue('documentId', selection.data.id, { shouldDirty: true });
                                        setValue('id', selection.data.id, { shouldDirty: true });
                                    }}
                                />
                            )}
                        />
                        </div>
                        <div className="col">
                       <Controller
                            name="statusId"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ReportStatusSelect
                                    label="Report Status"
                                    defaultValue={value}
                                    disableAnimations={true}
                                    isClearable={true}
                                    placeholder="Choose Report Status"
                                    onChange={(values) => setValue('statusId', values.value, { shouldDirty: true })}
                                    {...rest}
                                />
                            )}
                        />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                        
                        <Controller
                            name="authorId"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ReportAuthorSelect
                                    label="Author"
                                    defaultValue={value}
                                    disableAnimations={true}
                                    isClearable={true}
                                    placeholder="Choose Author"
                                    onChange={(values) => setValue('authorId', values.value, { shouldDirty: true })}
                                    {...rest}
                                />
                            )}
                        />
                        </div>
                        <div className="col">
                        <Controller
                            name="authorAssignedDate"
                            control={control}
                            render={({  
                                field: {
                                    ref,
                                    value,
                                    onBlur,
                                    ...rest
                                }
                            }) => (
                                <DateTimeInput
                                    label="Author Assigned Date"
                                    placeholder="Choose author assigned date."
                                    value={value}
                                    onBlur={(newValue) => {
                                        setValue('authorAssignedDate', newValue, { shouldDirty: true });
                                    }}
                                    isClearable
                                    {...rest}
                                />
                            )}
                        />
                       
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">

                        <Controller
                            name="compliantDate"
                            control={control}
                            render={({
                                field: {
                                    ref,
                                    value,
                                    onBlur,
                                    ...rest
                                },
                            })  => (
                                <DateTimeInput
                                    label="Compliant Date"
                                    placeholder="Choose Compliant Date."
                                    isClearable
                                    showTimeSelect
                                    value={value}
                                    onBlur={(newValue) => {
                                            setValue('compliantDate', newValue, { shouldDirty: true });
                                        }
                                    }
                                    {...rest}
                                />
                            )}
                        />
                        </div>
                        <div className="col">
                        <Controller
                            name="sentDate"
                            control={control}
                            render={({  field: {
                                ref,
                                value,
                                onChange,
                                ...rest
                            }}) => (
                                <DateTimeInput
                                    label="Sent Date"
                                    placeholder="Choose Sent Date."
                                    showTimeSelect
                                    value={value}
                                    onBlur={(newValue) => {
                                        setValue('sentDate', newValue, { shouldDirty: true });
                                    }}
                                    isClearable
                                />
                            )}
                        />
                        </div>
                    </div>
                </Modal.Body>
        <Modal.Footer>
            <Button
                variant="success"
                type="submit"
                >
                Confirm Selection
            </Button>
            <Button variant="light" onClick={handleCancel}>
                Cancel
            </Button>
        </Modal.Footer>
        </form>
    </Modal>
}

export default DocumentSelectModal;
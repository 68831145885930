import React from "react";
import styled from "styled-components";

const Textblock = styled.div`
    grid-area: ftext;
    text-align: center;

    & > p {
        margin: 0;
    }
`;

const FooterText = () => {
    const currentDate = new Date();
    return <Textblock>
        <p>Copyright Save &amp; Invest Group Limited {currentDate.getFullYear()}. All rights reserved.</p>
    </Textblock>
};

export default FooterText;
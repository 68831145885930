import _ from "lodash"
import React from "react"
import { useAuth } from "react-oidc-context";
import { useCheckIsEmailAddressAvailableMutation, useCheckIsUsernameAvailableMutation } from "../services/clientauth";

const ClientDashboardManagementContext = React.createContext();

export const ROLES_IMPERSONATE_LOGIN = ["sys_admin_access", "client_access", "client_dashboard_impersonator"];
export const ROLES_CHANGE_LOGIN_CREDENTIALS = ["sys_admin_access", "client_access"];
export const ROLES_CHANGE_LOGIN_EMAIL = ["sys_admin_access", "client_access"];
export const ROLES_CHANGE_LOGIN_RESOURCE = ["sys_admin_access", "client_access"];
export const ROLES_CHANGE_LOGIN_ROLES = ["sys_admin_access", "client_access"];
export const ROLES_DELETE_LOGIN = ["sys_admin_access", "client_access", "client_dashboard_delete"];
export const ROLES_RENAME_LOGIN = ["sys_admin_access"];
export const ROLES_SET_PASSWORD_MANUALLY = ["sys_admin_access"];
export const ROLES_UPDATE_AUTHENTICATOR = ["sys_admin_access", "client_access", "client_dashboard_authenticator"];

export const useClientDashboardManagementControls = () => React.useContext(ClientDashboardManagementContext);

export const ClientDashboardManagementControlsProvider = ({ children }) => {
    const auth = useAuth();
    const [_checkEmail, { isLoading: isCheckingEmail }] = useCheckIsEmailAddressAvailableMutation();
    const [_checkUsername, { isLoading: isCheckingUsername }] = useCheckIsUsernameAvailableMutation();
    const { user: { profile: { roles } } } = auth;
    const isAllowedToChangeAuthenticator = _.some(roles, i => ROLES_UPDATE_AUTHENTICATOR.includes(i));
    const isAllowedToChangeCredentials = _.some(roles, i => ROLES_CHANGE_LOGIN_CREDENTIALS.includes(i));
    const isAllowedToChangeEmail = _.some(roles, i => ROLES_CHANGE_LOGIN_EMAIL.includes(i));
    const isAllowedToChangeResource = _.some(roles, i => ROLES_CHANGE_LOGIN_RESOURCE.includes(i));
    const isAllowedToChangeRoles = _.some(roles, i => ROLES_CHANGE_LOGIN_ROLES.includes(i));
    const isAllowedToDeleteLogin = _.some(roles, i => ROLES_DELETE_LOGIN.includes(i));
    const isAllowedToImpersonateLogin = _.some(roles, i => ROLES_IMPERSONATE_LOGIN.includes(i));
    const isAllowedToRenameLogin = _.some(roles, i => ROLES_RENAME_LOGIN.includes(i));
    const isAllowedToSetPasswordManually = _.some(roles, i => ROLES_SET_PASSWORD_MANUALLY.includes(i));
    const isLoading = isCheckingEmail === true || isCheckingUsername === true;

    const checkEmailAddressIsAvailable = (emailAddress) => {
        return new Promise((resolve, reject) => {
            return _checkEmail({ emailAddress })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    const checkUsernameIsAvailable = (username) => {
        return new Promise((resolve, reject) => {
            return _checkUsername({ username })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return (
        <ClientDashboardManagementContext.Provider value={{
            checkEmailAddressIsAvailable,
            checkUsernameIsAvailable,
            isAllowedToChangeAuthenticator,
            isAllowedToChangeCredentials,
            isAllowedToChangeEmail,
            isAllowedToChangeResource,
            isAllowedToChangeRoles,
            isAllowedToDeleteLogin,
            isAllowedToImpersonateLogin,
            isAllowedToRenameLogin,
            isAllowedToSetPasswordManually,
            isLoading
        }}>
            {children}
        </ClientDashboardManagementContext.Provider>
    );
};
import styled from "styled-components";
import { StandardTable } from "."
import { IconButton } from "../buttons";
import { DateInput, FormInput, FormSelect, NumberInput, PercentageInput } from "../forms";

const DeleteCell = styled.td`
    padding: 0.5rem 0.6rem !important;
    vertical-align: middle !important;
`;

export const EmptyRowComponent = ({ columns, createFn, typeDescription, ...rest }) => <tr {...rest}>
    <td colSpan={columns.length}>This profile does not have any {typeDescription}. Click <button className="anchor" onClick={createFn}>here</button> to create one.</td>
</tr>;

export const EditableTableRow = ({ columns, data, patchRowFn, idProperty, customCellProps, ...rest }) => {
    return <tr {...rest}>
        {/* Props prefixed with _ are throwaway, to remove custom props from the DOM */}
        {columns.map(({ isEditable, type, property, deleteFn, CustomCellComponent, label: _label, headerRenderer: _headerRenderer, ...componentProps }, colIndex) => {
            if (CustomCellComponent != null)
                return <td key={`${data[idProperty]}-${property}`}>
                    <CustomCellComponent
                        data={data}
                        patchFn={patchRowFn}
                        {...customCellProps}
                        {...componentProps}
                    />
                </td>;

            // Handling the delete button
            if (deleteFn != null)
                return <DeleteCell key={`${data[idProperty]}-delete`} {...componentProps}>
                    <IconButton
                        icon="trash"
                        variant="danger"
                        onClick={() => deleteFn(data[idProperty])}
                    />
                </DeleteCell>;

            let Component;
            let inputProps = { onBlur: (value) => patchRowFn(data[idProperty], property, value) };
            switch (type) {
                case 'percent':
                    Component = PercentageInput;
                    inputProps.value = data[property] ?? "";
                    break;
                case 'number':
                    Component = NumberInput;
                    inputProps.value = data[property] ?? 0;
                    break;
                case 'select':
                    Component = FormSelect;
                    inputProps.onBlur = (selection) => patchRowFn(data[idProperty], property, selection.value);
                    inputProps.defaultValue = data[property];
                    break;
                case 'date':
                    Component = DateInput;
                    inputProps.value = data[property];
                    break;
                default:
                    Component = FormInput;
                    inputProps.value = data[property];
                    break;
            }

            return <td className="align-middle" key={`${data[idProperty]}-${property}`}>
                {isEditable
                    ? <Component {...componentProps} {...inputProps} />
                    : data[property]}
            </td>;
        })}
    </tr>;
};

const EditableTable = ({ createFn, idProperty, typeDescription, patchRowFn, customCellProps, rowComponentProps, ...rest }) => {
    return <StandardTable
        hideHeaderOnEmpty
        EmptyRowComponent={EmptyRowComponent}
        emptyRowComponentProps={{ createFn, typeDescription }}
        RowComponent={EditableTableRow}
        rowComponentProps={{ patchRowFn, idProperty, customCellProps, ...rowComponentProps }}
        {...rest}
    />
}

export default EditableTable;
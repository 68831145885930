import { React } from "react";
import { useAuth } from "react-oidc-context";

const PortfolioDesignationSummaryHeaders = () => {

    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;
    const canEdit = roles.includes("transaction_write");

    return (
        <div style={{ fontWeight: "bold" }} className="row mb-2">
            <div className="col">Contract Ref</div>
            <div className="col-3">Fund</div>
            <div className="col">Price Date</div>
            <div style={{ textAlign: "right" }} className="col">
                Price
            </div>
            <div style={{ textAlign: "right" }} className="col">
                Units
            </div>
            <div style={{ textAlign: "right" }} className="col">
                Invested
            </div>
            <div style={{ textAlign: "right" }} className="col">
                Value
            </div>
            <div style={{ textAlign: "right" }} className="col">
                Gain
            </div>
            <div style={{ textAlign: "right" }} className="col">
                Annualised Return
            </div>
            <div style={{ textAlign: "right" }} className="col">
                Avg. Time Held
            </div>
            {canEdit && <div style={{ textAlign: "center", minWidth: "91px" }} className="col-md-auto">
                Revise
            </div>}
        </div>
    );
};

export default PortfolioDesignationSummaryHeaders;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Skeleton from "react-loading-skeleton";
import { GridWrapper, IconButton } from "../../../components";
import { CurrencyInput, FormLabel, FormSelect, PercentageInput } from "../../../components/forms";
import { useInstruction } from "../contexts/InstructionContext";
import useInvestmentSelection from "../hooks/useInvestmentSelection";
import { TransactionErrorMessage } from "./ErrorMessages";
import InvestTransaction from "./InvestTransaction";

const InvestmentSelection = ({ isRegularContribution = false, maxInvestmentAmount = 0 }) => {
    const {
        taxYears,
        totalMinusFees,
        investTransactions,
        realTimeTransactions,
        portfolioDropdownOptions,
        monthsPhasedOptions,
        errorCreatingRow,
        errorDeletingRow,
        regularContributionOptions,
        renderInvestmentTable,
        isLoading,
        isError,
        retry,
        patchInvest,
        realTimePatchInvestSingle,
        selectPortfolio,
        createTransaction,
        isCreating,
        deleteTransaction,
        isDeleting,
        patchTransaction,
        realTimePatchTransaction
    } = useInvestmentSelection(isRegularContribution);

    const [, { realTimeInstruction, invest, investIsLoading }] = useInstruction();

    const [showErrorAddingRowAlert, setShowErrorAddingRowAlert] = useState(false);
    const [showErrorDeletingRowAlert, setShowErrorDeletingRowAlert] = useState(false);

    useEffect(() => setShowErrorAddingRowAlert(errorCreatingRow), [errorCreatingRow]);
    useEffect(() => setShowErrorDeletingRowAlert(errorDeletingRow), [errorDeletingRow]);

    // Set default values if not already set
    useEffect(() => {
        if (invest?.id && invest?.monthsPhased == null && !isRegularContribution)
            patchInvest("monthsPhased", 0);
    }, [invest?.id, invest?.monthsPhased, isRegularContribution, patchInvest]);

    useEffect(() => {
        if (invest?.id && invest?.taxYear == null && isRegularContribution)
            patchInvest("taxYear", taxYears[0].value);
    }, [invest?.id, invest?.taxYear, isRegularContribution, patchInvest, taxYears]);

    // Needed to allow the user to type beyond the maximum investment amount without effecting calculations
    const [displayInvestmentAmount, setDisplayInvestmentAmount] = useState(null);

    const firstLoad = useRef(true);

    // Want it to update display amount when max investment amount changes
    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }

        setDisplayInvestmentAmount(maxInvestmentAmount);
    }, [maxInvestmentAmount]);

    useEffect(() => {
        if (displayInvestmentAmount != null)
            return;

        setDisplayInvestmentAmount(realTimeInstruction?.invest?.totalInvestmentAmount);
    }, [displayInvestmentAmount, realTimeInstruction?.invest?.totalInvestmentAmount])

    // Need in case the maxInvestment changes to cause a recalculation of displayInvestmentAmount, without patching the service
    useEffect(() => {
        if (displayInvestmentAmount === null || invest?.id == null)
            return;

        const consistencyCheckTimeout = setTimeout(() => {
            if (displayInvestmentAmount !== invest?.totalInvestmentAmount && displayInvestmentAmount !== realTimeInstruction?.invest?.totalInvestmentAmount)
                patchInvest("totalInvestmentAmount", displayInvestmentAmount);
        }, 500);

        return () => clearTimeout(consistencyCheckTimeout);
    }, [displayInvestmentAmount, invest?.id, invest?.totalInvestmentAmount, patchInvest, realTimeInstruction?.invest?.totalInvestmentAmount]);

    const portfolioId = useMemo(() =>
        portfolioDropdownOptions?.find((option) => option.value === realTimeInstruction?.invest?.portfolioId)?.value,
        [portfolioDropdownOptions, realTimeInstruction?.invest?.portfolioId])
    const portfolioLabel = useMemo(() =>
        portfolioDropdownOptions?.find((option) => option.value === realTimeInstruction?.invest?.portfolioId)?.label,
        [portfolioDropdownOptions, realTimeInstruction?.invest?.portfolioId]);

    const [showPortfolioWarning, setShowPortfolioWarning] = useState(false);
    const shouldSelectPortfolio = useRef(null);

    const [isSelectingPortfolio, setIsSelectingPortfolio] = useState(false);

    // Keep the promise running while the modal is open, then act depending on the button pressed
    const handlePortfolioBlur = useCallback((selection) => {
        // Need an additional loading state to handle the full promise
        setIsSelectingPortfolio(true);

        return new Promise((resolve, reject) => {
            const modalCloseInterval = setInterval(() => {
                if (shouldSelectPortfolio.current != null) {
                    clearInterval(modalCloseInterval);

                    if (shouldSelectPortfolio.current === true)
                        return selectPortfolio(selection.value)
                            .then(resolve, reject);
                    else
                        reject();
                }
            }, 100);
        }).finally(() => {
            shouldSelectPortfolio.current = null;
            setIsSelectingPortfolio(false);
        })
    }, [selectPortfolio]);

    const maxPortfolioWidth = useMemo(() =>
        portfolioDropdownOptions?.reduce((acc, curr) => Math.max(acc, curr?.label?.length), 0) + 5,
        [portfolioDropdownOptions])

    if (isError)
        return <TransactionErrorMessage retry={retry} />

    if (isLoading || investIsLoading)
        return <Skeleton count={4} />;

    return <React.Fragment>
        <GridWrapper className="pt-2" gridTemplateColumns={`minmax(20ch, 10%) ${maxPortfolioWidth}ch minmax(auto, 20%) minmax(auto, 10%)`}>
            {!isRegularContribution && <React.Fragment>
                {investIsLoading
                    ? <Skeleton />
                    : <CurrencyInput
                        allowNegative={false}
                        label={"Investment Amount:"}
                        value={displayInvestmentAmount}
                        onChange={(_, { floatValue }) => {
                            setDisplayInvestmentAmount(floatValue);
                            realTimePatchInvestSingle("totalInvestmentAmount", Math.min(floatValue ?? 0, maxInvestmentAmount));
                        }}
                        disableAnimations={invest?.totalInvestmentAmount === maxInvestmentAmount && displayInvestmentAmount >= maxInvestmentAmount}
                        onBlur={(amount) => {
                            // Update after minimizing to maxInvestmentAmount
                            const validNewInvestmentAmount = Math.min(amount, maxInvestmentAmount);

                            setDisplayInvestmentAmount(validNewInvestmentAmount);
                            if (validNewInvestmentAmount !== invest?.totalInvestmentAmount)
                                return patchInvest("totalInvestmentAmount", validNewInvestmentAmount);

                            // Need this to not reset the original value when no change is made
                            return Promise.reject(null);
                        }}
                    />}
                {investIsLoading
                    ? <Skeleton />
                    : <FormSelect
                        label={"Portfolio:"}
                        defaultValue={realTimeInstruction?.invest?.portfolioId ?? (investTransactions?.length > 0
                            ? 0
                            : 1)}
                        onChange={(selection) => {
                            realTimePatchInvestSingle("portfolioId", selection.value);

                            if (selection.value === 1 && investTransactions?.length === 0)
                                return;
                            setShowPortfolioWarning(true);
                        }}
                        onBlur={handlePortfolioBlur}
                        isDisabled={isSelectingPortfolio || displayInvestmentAmount === 0 || displayInvestmentAmount == null}
                        options={portfolioDropdownOptions ?? []}
                    />}

                {investIsLoading
                    ? <Skeleton />
                    : <FormSelect
                        label={"Months Phased:"}
                        defaultValue={invest?.monthsPhased}
                        onBlur={(selection) => patchInvest("monthsPhased", selection.value)}
                        options={monthsPhasedOptions ?? []}
                    />}
            </React.Fragment>}

            {isRegularContribution && <React.Fragment>
                {investIsLoading
                    ? <Skeleton />
                    : <CurrencyInput
                        label={"Contribution Amount:"}
                        allowNegative={false}
                        value={invest?.regularContributionAmount}
                        onChange={(_, { floatValue }) => {
                            realTimePatchInvestSingle("regularContributionAmount", floatValue);
                        }}
                        onBlur={(amount) => patchInvest("regularContributionAmount", amount)}
                    />}
                {investIsLoading
                    ? <Skeleton />
                    : <FormSelect
                        label={"Portfolio:"}
                        defaultValue={realTimeInstruction?.invest?.portfolioId ?? (investTransactions?.length > 0
                            ? 0
                            : null)}
                        onChange={(selection) => {
                            realTimePatchInvestSingle("portfolioId", selection.value);
                            setShowPortfolioWarning(true);
                        }}
                        onBlur={handlePortfolioBlur}
                        isDisabled={isSelectingPortfolio}
                        options={portfolioDropdownOptions ?? []}
                    />}
                {investIsLoading
                    ? <Skeleton />
                    : <FormSelect
                        label={"Frequency:"}
                        options={regularContributionOptions}
                        defaultValue={invest?.regularContributionFrequency}
                        onChange={(selection) => realTimePatchInvestSingle("regularContributionFrequency", selection.value)}
                        onBlur={(selection) => patchInvest("regularContributionFrequency", selection.value)}
                    />}
                {investIsLoading
                    ? <Skeleton />
                    : <FormSelect
                        label={"Tax Year:"}
                        defaultValue={invest?.taxYear}
                        onBlur={(selection) => patchInvest("taxYear", selection.value)}
                        options={taxYears ?? []}
                    />}
            </React.Fragment>}
        </GridWrapper>

        {renderInvestmentTable && <GridWrapper gridTemplateColumns={"minmax(auto, 20ch) minmax(40ch, 1fr) minmax(auto, 20ch) minmax(auto, 20ch) auto"}>
            <FormLabel className="align-self-end">SEDOL:</FormLabel>
            <FormLabel className="align-self-end">Investment Name:</FormLabel>
            <FormLabel className="align-self-end">Allocation:</FormLabel>
            <FormLabel className="align-self-end">Value:</FormLabel>
            <IconButton icon="plus" className="invisible" />

            {realTimeTransactions?.map((transaction, index) =>
                <InvestTransaction
                    key={`${transaction?.investId}-${transaction?.rowTag ?? index}`}
                    index={index}
                    isLoading={isLoading || transaction?.rowTag == null}
                    isCreating={isCreating}
                    isDeleting={isDeleting}
                    investId={invest?.id}
                    realtimeItem={realTimeTransactions[index]}
                    maxAllocation={realTimeTransactions?.reduce((acc, curr, currIndex) => {
                        if (currIndex === 0 || currIndex === index)
                            return acc;

                        return acc - curr?.allocation;
                    }, 99)}
                    rowTag={transaction?.rowTag}
                    totalMinusFees={totalMinusFees}
                    addRow={createTransaction}
                    deleteRow={deleteTransaction}
                    updateRealTime={realTimePatchTransaction}
                    patchTransaction={patchTransaction}
                />)}

            {showErrorAddingRowAlert
                ? <Alert
                    className="col-start-1 col-end-4"
                    dismissible
                    variant={"danger"}
                    onClose={() => setShowErrorAddingRowAlert(false)}
                >
                    An error occurred whilst trying to add a new row, please try again.
                </Alert>
                : <></>}

            {showErrorDeletingRowAlert ?
                <Alert
                    dismissible
                    variant={"danger"}
                    onClose={() => setShowErrorDeletingRowAlert(false)}
                >
                    An error occurred whilst trying to delete this row, please try again.
                </Alert>
                : <></>}

            <FormLabel className={"align-self-end justify-self-end col-start-1 col-end-3"}>
                Invested Value:
            </FormLabel>
            <PercentageInput value={100} readOnly />
            {investIsLoading
                ? <Skeleton />
                : <CurrencyInput value={totalMinusFees} readOnly />}
        </GridWrapper>}
        {showPortfolioWarning && <Modal
            show={showPortfolioWarning}
            backdrop="static"
            restoreFocus={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {portfolioId === 1 || (portfolioId === 0 && realTimeTransactions?.length !== 0)
                        ? "Deselect Portfolio?"
                        : portfolioId === 0
                            ? "Create Transaction?"
                            : "Assign Portfolio?"}
                </Modal.Title>
                {/* <Modal.Title>{portfolioId === 1 || (portfolioId === 0 && realTimeTransactions?.length !== 0)
                        ? 'Deselect Portfolio?'
                        : 'Create Transaction?'
                    : 'Assign Portfolio?'}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                {portfolioId === 0
                    ? realTimeTransactions?.length !== 0
                        ? <>
                            Would you like to deselect your currently selected portfolio?
                            {investTransactions?.length !== 0
                                ? <>
                                    <br /><br />
                                    NOTE: This will <strong>NOT</strong> delete existing transactions.
                                </>
                                : <></>}
                        </>
                        : <>
                            Would you like to create a transaction without selecting a portfolio?
                        </>
                    : portfolioId === 1
                        ? <>
                            Would you like to retain the invested amount as cash
                            {investTransactions?.length !== 0
                                ? <React.Fragment>
                                    <br /><br />
                                    <strong>WARNING:</strong> This will delete all existing transactions.
                                </React.Fragment>
                                : <></>}
                        </>
                        : <>
                            Would you like to create transactions for the '{portfolioLabel}' portfolio?
                            {investTransactions?.length !== 0
                                ? <React.Fragment>
                                    <br /><br />
                                    <strong>WARNING:</strong> This will delete all existing transactions.
                                </React.Fragment>
                                : <></>}
                        </>}
            </Modal.Body>
            <Modal.Footer>
                {/* <ButtonGroup> */}
                <Button variant="danger" onClick={() => {
                    realTimePatchInvestSingle("portfolioId", invest?.portfolioId);
                    shouldSelectPortfolio.current = false;
                    setShowPortfolioWarning(false);
                }}>No</Button>
                <Button variant="success" onClick={() => {
                    shouldSelectPortfolio.current = true;
                    setShowPortfolioWarning(false);
                }}>Yes</Button>
                {/* </ButtonGroup> */}
            </Modal.Footer>
        </Modal>
        }
    </React.Fragment >;
};

export default InvestmentSelection;
import { wealthProApi } from "../../redux/api";

const userEmailContactDetailsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['ContactDetails']
}).injectEndpoints({
    endpoints: (builder) => ({
        getMasterAccountIdByContactDetails: builder.query({
            query: ({details}) => `api/clientdocuments/contactdetails/${details}/masteraccount`
        })
    })
});

export const {
    useGetMasterAccountIdByContactDetailsQuery
} = userEmailContactDetailsApiEndpoints;
import { wealthProApi } from "../../redux/api";

const remindersReportingApiEndpoints = wealthProApi.enhanceEndpoints({}).injectEndpoints({
    endpoints: (builder) => ({
        contactReport: builder.query({
            query: ({ data }) => ({
                url: `api/reminders/contactreport`,
                method: "POST",
                body: data
            }),
        })
    })
});

export const {
    useLazyContactReportQuery
} = remindersReportingApiEndpoints;
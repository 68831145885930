/**
 * Component that creates an infinite scrollable list, but not in a html table format.
 */
import React, { createContext, useContext, useRef, useState } from "react";
import classNames from "classnames";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "../loaders";
import { ThemedIcon, ThemedSpan } from "../utilities";
import { usePageScrollPosition } from "../../hooks/PageScrollPositionContext";

const InfinityListContext = createContext({
    top: 0,
    className: ''
});

const InfinityList = ({
    row,
    enableScrollTracking = false,
    error,
    itemCount = 0,
    itemSize = 64,
    isItemLoaded = null,
    isLoading = false,
    nextCursor = null,
    loadMoreItems = () => console.error('loadMoreItems must be a configured function.'),
    parentClass = "",
    ...rest
}) => {
    const listRef = useRef();
    const [top, setTop] = useState(0);
    const { currentPosition, setPosition } = usePageScrollPosition();
    const { rowIndex } = currentPosition || { rowIndex: 0, colIndex: 0 };

    const handleOnItemsRendered = ({ overscanStartIndex, visibleStartIndex, ...rest }, callback) => {
        // check if we are tracking the scroll position before we continue
        if (enableScrollTracking === true) {
            setPosition({ rowIndex: visibleStartIndex, colIndex: 0 });
        }

        // make sure we render the top correctly, this will prevent some graphical glitches with scrolling
        const style = listRef.current && listRef.current._getItemStyle(overscanStartIndex);
        setTop((style && style.top) || 0);

        // call the original callback
        callback && callback({ overscanStartIndex, visibleStartIndex, ...rest });
    };

    return (
        <InfinityListContext.Provider value={{ top, className: parentClass, error, isLoading }}>
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
            >
                {({ onItemsRendered, ref }) => (
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList
                                {...rest}
                                ref={el => (listRef.current = el)}
                                height={height}
                                width={width}
                                itemSize={itemSize}
                                itemCount={itemCount}
                                innerElementType={Inner}
                                initialScrollOffset={enableScrollTracking === true
                                    ? (itemSize * rowIndex)
                                    : 0
                                }
                                onItemsRendered={(props) => handleOnItemsRendered(props, onItemsRendered)}
                                overscanCount={5}
                            >
                                {row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </InfinityListContext.Provider>
    );
};

const InfiniteListEmptyDisplay = ({ style }) => (
    <div className="d-flex justify-content-center p-3" style={style}>
        <p className="m-0 text-center">
            <FontAwesomeIcon icon="fas fa-truck-loading" size="4x" className="text-muted my-3" />
            <br />
            <span className="mx-1 text-muted">No results found.</span>
        </p>
    </div> 
);

const InfiniteListErrorDisplay = ({ error, style }) => (
    <div className="d-flex justify-content-center p-3" style={style}>
        <p className="m-0 text-center">
            <ThemedIcon variant="danger" icon="fa-exclamation-triangle" size="3x" />
            <br />
            <ThemedSpan variant="danger">{error?.message ?? "An unknown error has occured. Could not load your results."}</ThemedSpan>
        </p>
    </div> 
);

const InfiniteListLoader = ({ style }) => (
    <div className="d-flex justify-content-center p-3" style={style}>
        <Spinner />
    </div>
);

const Inner = React.forwardRef(({ children, style }, ref) => {
    const { className, isLoading, error } = useContext(InfinityListContext);
    return (
        <div ref={ref} className={classNames(className)} style={style}>
            {!isLoading && error && (<InfiniteListErrorDisplay error={error} />)}
            {(!children || (children && Array.isArray(children) && children.length === 0)) && !error && !isLoading && <InfiniteListEmptyDisplay />}
            {children}
            {isLoading && (<InfiniteListLoader style={style} />)}
        </div>
    );
});

export default InfinityList;
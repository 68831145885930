import { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Spinner } from "../../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import EditConnectionForm from "../../clientconnections/components/EditConnectionForm";
import useClientConnection from "../hooks/useClientConnection";

const EditConnectionModal = ({ connectionId, connectionName, ...modalProps }) => {
    const {
        connection,
        deleteConnection,
        getClientConnection,
        isUninitialized,
        isLoading,
        isError,
        error,
        updateConnection
    } = useClientConnection();

    useEffect(() => {
        if (!connectionId || !modalProps.show) return;

        getClientConnection(connectionId);
    }, [connectionId, getClientConnection, modalProps.show]);

    const displayName = useMemo(() => isLoading || isError
        ? ""
        : (connection?.title || connection?.forenames || connection?.surname) == null
            ? connectionName
            : `${connection?.title} ${connection?.forenames} ${connection?.surname}`,
        [connection?.forenames, connection?.surname, connection?.title, connectionName, isError, isLoading]);

    useEffect(() => {
        if (!modalProps.show) return

        if (isUninitialized || isLoading || isError) return

        if (connection == null && (typeof (modalProps.onHide) === 'function'))
            modalProps.onHide()
    }, [connection, isError, isLoading, isUninitialized, modalProps, modalProps.onHide, modalProps.show]);

    return <Modal {...modalProps} centered>
        <Modal.Header closeButton>{displayName}</Modal.Header>
        <Modal.Body>
            {isLoading || (!connection && !isError)
                ? <Spinner />
                : connection
                    ? <EditConnectionForm
                        connection={connection}
                        deleteConnection={deleteConnection}
                        isRemoving={isLoading}
                        updateConnection={updateConnection}
                    />
                    : isError
                        ? <ErrorMessageWrapper >
                            <ErrorMessage
                                message={"Sorry! There was an error getting this connection's details. Please try again"}
                                error={error}
                                retryCallback={getClientConnection}
                            />
                        </ErrorMessageWrapper>
                        : <></>}
        </Modal.Body>
    </Modal>
};

export default EditConnectionModal;
/* eslint-disable no-extend-native */
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { FormInput, FormLabel, RichTextEditor } from "../../../components/forms";
import { BooleanSelect } from "../../../components/forms/selects";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { InfoBubble } from "../components";
import PolicyCovers from "../components/PolicyCovers";
import useProtectionSection from "../hooks/useProtectionSection";

// Inserts an item between every element of a list
// For inserting html between every result of a .map call
Object.defineProperty(Array.prototype, 'joinWithHtml', {
    value: function (html) {
        return this
            .flatMap(res => [res, html]) // Convert into a list of the form [elt0, html, elt1, html, ..., html]
            .slice(0, -1); // Remove the final <hr />
    }
});

const ProtectionSection = () => {
    const {
        protectionSection,
        isLoading,
        isError,
        error,
        refetchProtectionSection,
        updateProtectionSection,
        updateProtectionSectionNotes,
        updateProtectionNeededProperty
    } = useProtectionSection();

    const { protectionObjectives } = protectionSection || {};

    const [isProtectionNeededRealTime, setIsProtectionNeededRealTime] = useState(protectionSection?.isProtectionNeeded);
    const [doesClientWishAdviceRealTime, setDoesClientWishAdviceRealTime] = useState(protectionSection?.doesClientWishAdvice);

    const [isReason, setIsReason] = useState(!doesClientWishAdviceRealTime);

    const updateForm = ({ value: newDoesClientWishAdvice }) => {
        setIsReason(!newDoesClientWishAdvice);
    }

    const updateProtectionNeededRealTime = useCallback((isProtectionNeeded) => {
        if (!isProtectionNeeded) {
            setDoesClientWishAdviceRealTime(false);
            setIsReason(true);
        }

        setIsProtectionNeededRealTime(isProtectionNeeded);
    }, []);

    useEffect(() => {
        setDoesClientWishAdviceRealTime(protectionSection?.doesClientWishAdvice)
        setIsReason(!protectionSection?.doesClientWishAdvice);
    }, [protectionSection?.doesClientWishAdvice]);

    useEffect(() => {
        setIsProtectionNeededRealTime(protectionSection?.isProtectionNeeded);
    }, [protectionSection?.isProtectionNeeded]);

    if (isLoading) return <Skeleton height={16} count={3} />;

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchProtectionSection} />
        </ErrorMessageWrapper>;
    }

    return <Container fluid={true}>
        {/* Main Section */}
        <Row className="justify-content-between">
            <Col
                xs={12}
                md={6}
                lg={4}
                className="d-flex flex-row justify-content-between justify-content-md-start align-items-center"
            >
                <FormLabel className="me-3 my-auto">
                    Is protection needed?
                </FormLabel>
                {isLoading
                    ? <Skeleton />
                    : <BooleanSelect
                        defaultValue={protectionSection?.isProtectionNeeded}
                        onChange={({ value }) => updateProtectionNeededRealTime(value)}
                        onBlur={({ value }) => updateProtectionNeededProperty(value)}
                    />}
            </Col>
            <Col
                xs={12}
                md={6}
                lg={4}
                className="d-flex flex-row justify-content-between justify-content-md-end justify-content-lg-center align-items-center mt-2 mt-md-0"
            >
                <FormLabel className="me-3 my-auto">
                    Does client wish advice?
                </FormLabel>
                {isLoading
                    ? <Skeleton width="150px" />
                    : <BooleanSelect
                        key={isProtectionNeededRealTime}
                        defaultValue={doesClientWishAdviceRealTime}
                        isDisabled={!isProtectionNeededRealTime}
                        onChange={updateForm}
                        onBlur={({ value }) => updateProtectionSection("doesClientWishAdvice", value)}
                    />
                }
            </Col>
            <Col
                xs={12}
                md={12}
                lg={4}
                className="d-flex flex-row align-items-center mt-2 mt-lg-0"
            >
                {isLoading
                    ? <Skeleton width="150px" />
                    : <FormLabel className="me-3 my-auto">
                        {isReason ? "Reason:" : "Budget:"}
                    </FormLabel>
                }
                {isLoading
                    ? <Skeleton width="150px" />
                    : <span className="w-100">
                        {isReason
                            ? <FormInput
                                value={protectionSection?.reason}
                                onBlur={(value) => updateProtectionSection("reason", value)}
                            />
                            : <FormInput
                                value={protectionSection?.budget}
                                onBlur={(value) => updateProtectionSection("budget", value)}
                            />}
                    </span>
                }
            </Col>
        </Row>
        <div className="mt-3 d-flex flex-row justify-content-between align-items-center">
            <div>
                <FormLabel>
                    Protection Objectives:
                </FormLabel>
            </div>
            <InfoBubble
                showBubble
            />
        </div>
        <RichTextEditor
            defaultValue={protectionObjectives?.notes}
            onBlur={(newValue) => updateProtectionSectionNotes(protectionObjectives.notesId, newValue)}
        />
        <PolicyCovers
            protectionId={protectionSection.protectionSectionId}
            isCurrentPriority={true}
        />
        <PolicyCovers
            protectionId={protectionSection.protectionSectionId}
            isCurrentPriority={false}
        />
    </Container>;
}

export default ProtectionSection;
import FormSelect from "../FormSelect";

const BooleanOptions = [
    {
        value: false,
        label: "No"
    },
    {
        value: true,
        label: "Yes"
    }
];

const BooleanSelect = ({
    className,
    ...rest
}) => {
    return <FormSelect
        className={className}
        options={BooleanOptions}
        {...rest}
    />
};


export default BooleanSelect;
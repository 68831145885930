import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";
import { deleteMessageOnQueryStarted as onDeleteMessageQueryStarted, onPatchMessageQueryStarted, onSendMessageQueryStarted, updateMessageRecipientsOnQueryStarted as onUpdateMessageRecipientsQueryStarted } from "./cacheUpdateHelpers";

const messagesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createMessage: builder.mutation({
            query: ({ threadId, recipientIds, message, masterAccountId, messagePriority, isActionRequired, threadSubject, ...rest }) => ({
                url: 'api/messaging/messages',
                method: 'POST',
                body: {
                    threadId,
                    masterAccountId,
                    sentStatus: "draft",
                    statusText: "",
                    message: message,
                    recipientIds: recipientIds,
                    messagePriority,
                    isActionRequired,
                    threadSubject,
                    isDone: false,
                    ...rest
                },
            }),
            async onQueryStarted({ threadId, recipients, masterAccountId, message, messagePriority, isActionRequired, threadSubject, ...rest }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    const { messageId, data: newMessage } = data;

                    dispatch(
                        wealthProApi.util.updateQueryData('fetchMessagesByThreadId',
                            { threadId },
                            ({ results, pagination }) => {
                                return {
                                    pagination: {
                                        ...pagination,
                                        totalCount: pagination.totalCount + 1
                                    },
                                    results: [newMessage, ...results]
                                }
                            })
                    );

                    dispatch(
                        wealthProApi.util.updateQueryData('fetchClientMessages',
                            { masterAccountId },
                            ({ results, pagination }) => {
                                return {
                                    pagination: {
                                        ...pagination,
                                        totalCount: pagination.totalCount + 1
                                    },
                                    results: [newMessage, ...results]
                                }
                            })
                    );

                }
                catch { }
            },
            invalidatesTags: ["Conversations"]
        }),
        createClientConversation: builder.mutation({
            query: ({ masterAccountId, recipientIds }) => ({
                url: 'api/messaging/messages',
                method: 'POST',
                body: {
                    masterAccountId,
                    sentStatus: "draft",
                    statusText: "",
                    message: "",
                    recipientIds
                },
            })
        }),
        patchClientMessage: builder.mutation({
            query: ({ messageId, operations }) => ({
                url: `api/messaging/messages/${messageId}`,
                method: 'PATCH',
                body: operations
            }),
            onQueryStarted: onPatchMessageQueryStarted
        }),
        deleteDraftMessage: builder.mutation({
            query: ({ messageId }) => ({
                url: `api/messaging/messages/${messageId}`,
                method: 'DELETE'
            }),
            onQueryStarted: onDeleteMessageQueryStarted,
            invalidatesTags: ["Conversations"]
        }),
        updateMessageRecipients: builder.mutation({
            query: ({ messageId, recipients, masterAccountId, threadId }) => ({
                url: `api/messaging/messages/${messageId}/recipients`,
                method: 'POST',
                body: {
                    recipientIdList: recipients.map(x => x.value),
                    masterAccountId,
                    threadId
                }
            }),
            onQueryStarted: onUpdateMessageRecipientsQueryStarted
        }),
        sendClientMessage: builder.mutation({
            query: ({ messageId }) => ({
                url: `api/messaging/messages/send/${messageId}`,
                method: 'POST'
            }),
            onQueryStarted: onSendMessageQueryStarted,
            invalidatesTags: ["Conversations"]
        }),
        toggleMessageIsDone: builder.mutation({
            query: ({ messageId, newValue }) => ({
                url: `api/messaging/messages/isdone/${messageId}?newValue=${newValue}`,
                method: 'PATCH'
            }),
            async onQueryStarted({ messageId, masterAccountId, threadId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    var operations = [
                        patchReplace("isDone", data.newValue),
                        patchReplace("doneDate", data.doneDate),
                        patchReplace("doneByName", data.toggledByName),
                        patchReplace("doneById", data.toggledBy)
                    ];

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'fetchClientMessages',
                            { masterAccountId },
                            ({ results }) => {
                                var message = results.find(x => x.messageId === messageId);
                                applyPatch(message, operations, true);
                            }
                        )
                    );

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'fetchMessagesByThreadId',
                            { threadId },
                            ({ results }) => {
                                var message = results.find(x => x.messageId === messageId);
                                applyPatch(message, operations, true);
                            }
                        )
                    );
                }
                catch { }
            }
        }),
        uploadMessageAttachment: builder.mutation({
            query: ({ request }) => ({
                url: `api/messaging/messages/attachments`,
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ messageId, threadId, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'fetchMessagesByThreadId',
                            { threadId },
                            ({ results }) => {
                                var message = results.find(x => x.messageId === messageId);
                                message.attachments = [...message.attachments, ...data.filter(x => x.status === 200)]
                            }
                        )
                    );

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'getThreadById',
                            { threadId },
                            (thread) => {
                                thread.threadAttachments = [...thread.threadAttachments, ...data.filter(x => x.status === 200)]
                            }
                        ));

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'fetchClientMessages',
                            { masterAccountId },
                            ({ results }) => {
                                var message = results.find(x => x.messageId === messageId);
                                message.attachments = [...message.attachments, ...data.filter(x => x.status === 200)];
                            }
                        )
                    );

                }
                catch { }
            }
        })
    })
});

export const {
    useCreateClientConversationMutation,
    useDeleteDraftMessageMutation,
    usePatchClientMessageMutation,
    useUpdateMessageRecipientsMutation,
    useSendClientMessageMutation,
    useCreateMessageMutation,
    useToggleMessageIsDoneMutation,
    useUploadMessageAttachmentMutation
} = messagesApiEndpoints;
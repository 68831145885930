import React, { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchReportQuery } from "../../../services/clientreports";

const ReportHistoryContext = React.createContext();

export const useReportHistory = () => React.useContext(ReportHistoryContext);

export const ReportHistoryProvider = ({ children, showModalOnReportSet = true }) => {
    const [report, _setReport] = React.useState(null);
    const [showModal, _setShowModal] = React.useState(false);
    const [searchParams, _setSearchParams] = useSearchParams();

    const clear = () => {
        _setReport(_ => null);
        _setShowModal(_ => false);
        _setSearchParams(_ => "");
    };

    const setReport = useCallback((report) => {
        _setReport(_ => report);
        if (showModalOnReportSet === true) {
            _setShowModal(_ => true);
            _setSearchParams(_ => ({ masterAccountId: report.masterAccountId, reportId: report.id }));
        }
    }, [_setSearchParams, showModalOnReportSet]);

    const [fetchReport] = useLazyFetchReportQuery();

    useEffect(() => {
        const masterAccountId = searchParams.get('masterAccountId');
        const reportId = searchParams.get('reportId');

        if (!reportId || !masterAccountId)
            return;

        fetchReport({ masterAccountId, reportId })
            .then(({ data }) => setReport(data))
            .catch(console.error);

    }, [fetchReport, searchParams, setReport]);

    return (
        <ReportHistoryContext.Provider value={{ 
            clear,
            isModalActive: showModal,
            report,
            setReport
        }}>
            {children}
        </ReportHistoryContext.Provider>
    );
};
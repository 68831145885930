import React from "react";
import Tippy from "@tippyjs/react";
import toast from "react-hot-toast";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { useClientContext } from "../../hooks/ClientContext";

const ClientInfo = () => {
    const { account, addToFavourites, removeFromFavourites } = useClientContext();
    const { accountReference, accountName, adviser, reviewAdviser, typeDescription, isFavourited } = account || {};

    const onFavouriteChanged = () => {
        if (isFavourited) {
            toast.promise(removeFromFavourites(), {
                loading: `Processing...`,
                success: () => `The client has been removed from your favourites.`,
                error: (err) => {
                    const { message } = err || { message: 'There was an unkown error.' };
                    return message;
                }
            });
        }
        else {
            toast.promise(addToFavourites(), {
                loading: `Processing...`,
                success: () => `The client has been added to your favourites.`,
                error: (err) => {
                    const { message } = err || { message: 'There was an unkown error.' };
                    return message;
                }
            });
        }
    };

    return <div className="d-flex flex-fill align-items-center">
        <Tippy placement="left" content={(
            <p className="m-0">
                <span className="fw-bold">Type:</span> <span>{typeDescription ?? "Unknown"}</span>
                <br/>
                <span className="fw-bold">Adviser:</span> <span>{adviser ?? "None"}</span>
                <br />
                <span className="fw-bold">Review Adviser:</span> <span>{reviewAdviser ?? "None"}</span>
            </p>
        )}>
            <div className="me-2">
                <FontAwesomeIcon 
                    size="lg" 
                    onClick={onFavouriteChanged}
                    icon={isFavourited ? 'fa-star' : faStar} 
                    className={classnames('has-pointer', {
                        'text-white': !isFavourited,
                        'text-gold': isFavourited
                    })}
                />
            </div>
        </Tippy>
        <h1 className="fs-4 m-0">{accountReference} - {accountName}</h1>
    </div>
};

export default ClientInfo;
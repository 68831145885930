import styled from "styled-components";

const ClientCell = styled.div`
    grid-column-start: ${({ index, isUsingWrapper }) => isUsingWrapper
        ? index + 2
        : index === 0
            ? index + 2
            : index + 3};
    ${({ maxWidth }) => maxWidth ? `max-width: ${maxWidth};` : ""}
`;

export default ClientCell;
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const campaignManagementApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["campaignList"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchCampaignList: builder.query({
            query: () => `api/campaignmanagement/campaigns/list`,
            providesTags: (result, error, arg) => {
                return ['campaignList'];
            }
        })
    })
});

export const {
    useFetchCampaignListQuery
} = campaignManagementApiEndpoints;
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchMessageUsersForMasterAccountQuery } from "../../../services/messages";

const useMessageUsers = () => {

    const { id } = useClientContext();
    const { data } = useFetchMessageUsersForMasterAccountQuery({ masterAccountId: id });

    return {
        results: data || []
    };

};

export default useMessageUsers;
import React, { useEffect, useMemo, useState } from "react";
import { useCreateProductMutation, useFetchWrappersQuery, useLazyFetchProductsForGridQuery } from "../../../services/products";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import _ from "lodash";

const DefaultProductsFilter = {
    productName: null,
    wrapperId: null,
    provider: null,
    defaultCustodian: null
};

const ProductsGridContext = React.createContext();

export const useProductsGridContext = () => React.useContext(ProductsGridContext);

const ProductsGridProvider = ({ children }) => {

    const [searchProducts, {
        data: productsData,
        isError: isProductsError,
        error: productsError,
        isUninitialized: isProductsUninitialized
    }] = useLazyFetchProductsForGridQuery();

    const [createProductTrigger, createProductResult] = useCreateProductMutation();

    const wrappersResult = useFetchWrappersQuery({ fetchType: "list" });

    const limit = 50;
    const [productsFilter, setProductsFilter] = useState(DefaultProductsFilter);
    const [sort, setSort] = useState("ProductName+ASC");
    const [sortProperty, sortDirection] = sort.split("+");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { next, results, totalCount } = productsData || { next: null, results: [], totalCount: limit };

    const createProduct = () => {
        return new Promise((resolve, reject) => {
            return createProductTrigger({ request: { productName: "" } })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const clear = () => {
        searchProducts({
            next: null,
            request: {
                limit,
                sort,
                ...productsFilter,
                providerId: productsFilter.provider?.value,
                defaultCustodianId: productsFilter.defaultCustodian?.value,
            }
        });
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        return searchProducts({
            next: next,
            request: {
                limit,
                sort,
                ...productsFilter,
                providerId: productsFilter.provider?.value,
                defaultCustodianId: productsFilter.defaultCustodian?.value,
            }
        });
    };


    const resetFilters = () => {
        setProductsFilter(DefaultProductsFilter);
    };

    const handleSort = (property) => {
        var sort = property+"+";
        if(sortProperty === property){
            return setSort(sortDirection === "ASC" ? sort + "DESC" : sort + "ASC");
        }

        return setSort(sort + "ASC");
    };

    const sortableHeaderRenderer = ({ label, property, key }) => {
        return <StandardTableSortableHeader
            key={key}
            direction={sortDirection}
            onClick={() => handleSort(property)}
            active={sortProperty === property}
        >
            {label}
        </StandardTableSortableHeader>
    };

    const columns = [
        {
            label: "Product Name",
            property: "ProductName",
            renderer: sortableHeaderRenderer
        },
        {
            label: "Wrapper",
            property: "WrapperName",
            renderer: sortableHeaderRenderer
        },
        {
            label: "Provider",
            property: "ProviderName",
            renderer: sortableHeaderRenderer
        },
        {
            label: "Default Custodian",
            property: "DefaultCustodianName",
            renderer: sortableHeaderRenderer
        }
    ];

    useEffect(() => {
        if (isProductsUninitialized) return;

        clear();
    }, [productsFilter, sort]);


    return <ProductsGridContext.Provider value={{
        clear,
        createProduct,
        isItemLoaded,
        loadMoreItems,
        resetFilters,
        setProductsFilter,
        setSelectedProduct,
        createProductResult,
        isFiltersApplied: !_.isEqual(productsFilter, DefaultProductsFilter),
        DefaultProductsFilter,
        results,
        productsError,
        isProductsError,
        columns,
        totalCount,
        wrappersResult,
        selectedProduct,
        productsFilter
    }}>
        {children}
    </ProductsGridContext.Provider>;

};

export default ProductsGridProvider;
import React from "react";
import Skeleton from "react-loading-skeleton";
import CommunicationPreferencesForm from "./components/CommunicationPreferencesForm";
import useCommunicationPreferences from "./hooks/useCommunicationPreferences";
import { Accordion } from "../../components";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages"; 

const ClientCommunicationPreferencesError = ({ error = "", retryCallback, ...rest }) => {
    const handleRetry = (e) => {
        e.preventDefault();
        if (retryCallback && typeof (retryCallback) === 'function') {
            retryCallback(e);
        }
    };

    return (
        <ErrorMessageWrapper margin="0px auto" maxWidth="600px" {...rest}>
            <ErrorMessage flex="column" error={error} retryCallback={handleRetry} />
        </ErrorMessageWrapper>
    );
};

const ClientCommunicationPreferencesLoader = () => {
    return <Skeleton count={3} height={32} />
};

const ClientCommunicationPreferences = ({ label = "Communication Preferences", masterAccountId }) => {
    const { data, error, isError, isLoading, reload, update } = useCommunicationPreferences(masterAccountId);

    const onFieldChanged = (property, value) => update(property, value);

    return (
        <Accordion defaultActiveKey="0" gap={1} label={label}>
            {isLoading && (
                <ClientCommunicationPreferencesLoader />
            )}
            {!isLoading && (isError || error || !data) && (
                <ClientCommunicationPreferencesError error={error} retryCallback={reload} />
            )}
            {!isLoading && !isError && data && (
                <CommunicationPreferencesForm data={data} onFieldChanged={onFieldChanged} />
            )}
        </Accordion>
    );
};

export default ClientCommunicationPreferences;
import React from "react";
import { components } from "react-select";

export const PaperTaxPackRecipientSelectMultiValueLabel = (props) => {
    const { data: { data, label } } = props;
    const { address, position } = data;
    return (
        <components.MultiValueLabel {...props}>
            <div>
                {`${label}, ${position}, ${address}`}
            </div>
        </components.MultiValueLabel>
    );
};

const PaperTaxPackRecipientSelectionOption = (props) => {
    const { data: { data, label } } = props;
    const { address, position, relationshipDescription } = data;
    return (
        <components.Option {...props}>
            <div>
                <p className="m-0">
                    <span>{label}</span>, <span>{position && position.length > 0 ? position : relationshipDescription}</span>
                    <br />
                    <span className="fs-smaller text-muted">{address ?? "Address not available."}</span>
                </p>
            </div>
        </components.Option>
    );
};

export default PaperTaxPackRecipientSelectionOption;
import _ from "lodash";
import React, { useContext, useState } from "react";

const DocumentFilterContext = React.createContext();

export const useDocumentFilterContext = () => {
    return useContext(DocumentFilterContext);
};

export const withDocumentFilterProvider = (WrappedComponent) => (props) => (
    <DocumentFilterProvider defaultFilters={ClientDocumentDefaultFilters}>
        <WrappedComponent {...props} />
    </DocumentFilterProvider>
);

export const DocumentFilterProvider = ({ children, defaultFilters = {} }) => {
    const [filter, setFilter] = useState(defaultFilters);

    const clearFilter = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        setFilter(_ => defaultFilters);
    }

    const setNewFilter = ({newFilter}) => {
        setFilter(newFilter);
    };

    return <DocumentFilterContext.Provider value={{
        clearFilter,
        defaultFilters,
        filter,
        isFiltersApplied: !_.isEqual(filter, defaultFilters),
        setFilter,
        setNewFilter
    }}>
        {children}
    </DocumentFilterContext.Provider>
};

export const ClientDocumentDefaultFilters = {
    documentTypes: [],
    documentFunctions: [],
    projectIds: [],
    uploadedByUserIds: [],
    dateUploadedFrom: null,
    dateUploadedTo: null,
    masterAccountIds: [],
    clientAccess: null,
    searchTerm: ""
};
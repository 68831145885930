import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { MessagePriorities, ReminderStatuses } from "../../../helpers/constants";
import toast from "react-hot-toast";
import _ from "lodash";
import { usePersonalReminders } from "../hooks/PersonalRemindersContext";

const PersonalRemindersVirtualTableRowActionColToggle = React.forwardRef(({ children, onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const PersonalRemindersStatusActionMenuItem = ({ label, updateReminder, status }) => {
    const onHandleClick = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        if (typeof (updateReminder) !== 'function') {
            return;
        }

        updateReminder("status", status);
    };

    return <Dropdown.Item onClick={onHandleClick}>
        {label}
    </Dropdown.Item>;
};


const PersonalReminderPriorityActionMenuItem = ({ isCurrent, label, priority, updateReminder }) => {
    const onHandleClick = () => {
        if (!updateReminder || typeof (updateReminder) !== 'function') {
            return;
        }

        updateReminder("priority", priority)
            .then(
                _ => toast.success("Priority updated."),
                error => toast.error(error?.data)
            );
    };

    return (
        <Dropdown.Item className="d-flex justify-content-between align-items-center" onClick={onHandleClick}>
            {label}
            {isCurrent && <FontAwesomeIcon
                icon="check"
                className="text-success ms-2"
            />}
        </Dropdown.Item>
    );
};

const EditPersonalReminderActionMenuItem = ({ onClick }) => {
    const onHandleClick = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        if (!onClick || typeof (onClick) !== 'function') {
            return;
        }
        onClick(e);
    };

    return (
        <Dropdown.Item onClick={onHandleClick}>
            Edit
        </Dropdown.Item>
    );
};

const PersonalRemindersVirtualTableRowActionCol = ({ data, updateReminder }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    const { setSelectedReminderId } = usePersonalReminders();

    const {
        id,
        priority,
        status
    } = data || {};

    return (
        <Dropdown id={menuId} className="caret-off w-100">
            <Dropdown.Toggle as={PersonalRemindersVirtualTableRowActionColToggle} id={toggleId} />
            <Dropdown.Menu>
                <EditPersonalReminderActionMenuItem
                    onClick={() => setSelectedReminderId(id)}
                />
                <React.Fragment>
                    <Dropdown.Divider />
                    <Dropdown.Header>Actions</Dropdown.Header>
                    {status !== ReminderStatuses.Active && <PersonalRemindersStatusActionMenuItem
                        label="Re-Activate"
                        status={ReminderStatuses.Active}
                        updateReminder={updateReminder}
                    />}
                    {/* {status === ReminderStatuses.Todo && <PersonalRemindersStatusActionMenuItem
                        label="Complete"
                        status={ReminderStatuses.Complete}
                        updateReminder={updateReminder}
                    />} */}
                    {status === ReminderStatuses.Active && <PersonalRemindersStatusActionMenuItem
                        label={"Cancel"}
                        status={ReminderStatuses.Cancelled}
                        updateReminder={updateReminder}
                    />}
                    <Dropdown.Divider />
                    <Dropdown.Header>Priority</Dropdown.Header>
                    <PersonalReminderPriorityActionMenuItem
                        label="Low"
                        priority={MessagePriorities.Low}
                        isCurrent={priority === MessagePriorities.Low}
                        updateReminder={updateReminder}
                    />
                    <PersonalReminderPriorityActionMenuItem
                        label="Medium"
                        priority={MessagePriorities.Medium}
                        isCurrent={priority === MessagePriorities.Medium}
                        updateReminder={updateReminder}
                    />
                    <PersonalReminderPriorityActionMenuItem
                        label="High"
                        priority={MessagePriorities.High}
                        isCurrent={priority === MessagePriorities.High}
                        updateReminder={updateReminder}
                    />
                </React.Fragment>
            </Dropdown.Menu>
        </Dropdown>
    );

};

export default PersonalRemindersVirtualTableRowActionCol;
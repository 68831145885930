import AdministrationAdviceRoutes from "./administration.advice.routes";
import AdministrationApplicationRoutes from "./administration.applications.routes";
import AdministrationAssetRoutes from "./administration.assets.routes";
import AdministrationCustodianRoutes from "./administration.custodian.routes";
import AdministrationFeesRoutes from "./administration.fees.routes";
import AdministrationNewMoneyRoutes from "./administration.newmoney.routes";
import AdministrationRemindersRoutes from "./administration.reminders.routes";

const AdministrationRoutes = [{
    path: '/administration/*',
    roles: ['sys_admin_access', 'ho_read', 'ho_write'],
    children: [
        AdministrationNewMoneyRoutes,
        AdministrationApplicationRoutes,
        AdministrationFeesRoutes,
        AdministrationRemindersRoutes,
        AdministrationAssetRoutes,
        AdministrationCustodianRoutes,
        AdministrationAdviceRoutes
    ]
}];

export default AdministrationRoutes;
import React from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchMasterAccountAuditLogsQuery } from "../../../services/auditlogs";

const useMasterAccountAuditLogs = (limit = 50) => {
    // setup state and our query state (does not load by default (lazy))
    const { account: { masterAccountId } } = useClientContext();
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazyFetchMasterAccountAuditLogsQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true;

    const clear = () => {
        if (!masterAccountId || isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            page: 1,
            limit
        }).unwrap().then(_ => {
            setCacheKey(_prev => newCacheKey)
            setIsLoadingState(_prev => false);
        });
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        // load the next page of results
        setIsLoadingState(_ => true);
        load({ cacheKey, masterAccountId, page: page + 1, limit: limit  }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
        });
    }

    React.useEffect(clear, [ masterAccountId ]);
    
    return {
        ...loadResults,
        clear,
        data: results,
        hasMore,
        isFetching,
        isLoading,
        isNextPageLoading,
        isRowLoaded,
        limit,
        loadMore: isNextPageLoading ? () => {} : loadMore, 
        page,
        pagination,
        totalCount,
        totalPages
    };
};

export default useMasterAccountAuditLogs;
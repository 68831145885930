import React from "react";
import { ReminderStatuses } from "../../../helpers/constants";
import FormSelect from "../FormSelect";

//need to create the correct object array for the FormSelect from the new ReminderStatuses constants
const RemStatuses  = [];
Object.entries(ReminderStatuses).forEach(([key, value]) => {
    const status = {};
    status.label = key;
    status.value = value;
    RemStatuses.push(status);
});
const ReminderStatusesSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={RemStatuses.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default ReminderStatusesSelect;
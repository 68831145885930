import React from "react";
import styled from "styled-components";
import FormSelect from "../FormSelect";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RiskGradeOptions = [
    { 
        label: 'Not Graded', 
        value: 0,
        color: '#818589'
    },
    { 
        label: 'Very Low Risk', 
        value: 1,
        color: '#44ce1b'
    },
    { 
        label: 'Low Risk', 
        value: 2,
        color: '#bbdb44' 
    },
    { 
        label: 'Medium Risk', 
        value: 3,
        color: '#f7e379' 
    },
    { 
        label: 'High Risk', 
        value: 4,
        color: '#f2a134'
    },
    { 
        label: 'Politically Exposed', 
        value: 5,
        color: '#e51f1f'
    }
];

export const RiskGradeOptionIcon = styled(FontAwesomeIcon)`
    color: ${props => props.color ? props.color : props.theme.primary};
`;

const RiskGradeSelectOption = (props) => {
    const { data: { label, color } } = props;
    return (
        <components.Option className="d-flex align-items-center" {...props}>
            <RiskGradeOptionIcon className="me-1" icon="fa-circle" color={color} />
            <span>{label}</span>
        </components.Option>
    )
};

const RiskGradeSelectedOption = (props) => {
    const { data: { label, color } } = props;
    return (
        <components.SingleValue className="d-flex align-items-center" {...props}>
            <RiskGradeOptionIcon className="me-1" icon="fa-circle" color={color} />
            <span>{label}</span>
        </components.SingleValue>
    )
};

const RiskGradingSelect = ({ components, options, ...rest }) => {
    return <FormSelect
        options={RiskGradeOptions}
        components={{ Option: RiskGradeSelectOption, SingleValue: RiskGradeSelectedOption }}
        {...rest}
    />
};

export default RiskGradingSelect;
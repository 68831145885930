import { useDeleteFeeTransactionMutation, useFetchTransactionAllocationsByGroupingQuery } from "../../../services/headofficeadmin";

const useTransactionFeeAllocations = (groupId) => {

    const { data, isError, isFetching, isLoading, error, refetch } = useFetchTransactionAllocationsByGroupingQuery({ groupId });
    const [deleteTrigger] = useDeleteFeeTransactionMutation();

    const deleteAllocationsForFee = (feeTransactionId) => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({feeTransactionId})
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    const retryFetchTransactionFeeAllocations = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    }

    return {
        data: data || [],
        isError,
        isFetching,
        isLoading,
        error,
        deleteAllocationsForFee,
        retryFetchTransactionFeeAllocations
    };
};

export default useTransactionFeeAllocations;
import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";

const messagingApplicationsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["hoMessages"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchApplicationMessages: builder.query({
            query: ({ applicationId, page, active }) => `api/messaging/applications/${applicationId}/messages?page=${page}&limit=25&active=${active}`,
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                const { applicationId, active } = queryArgs;
                return `${endpointName}?applicationId=${applicationId}&active=${active}`;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage } = currentCache || {};
                const { pagination: nextPage } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { page: currPage, applicationId: currApplicationId } = currentArg || { page: 1, masterAccountId: null };
                const { page: prevPage, applicationId: prevApplicationId } = previousArg || { page: 0, masterAccountId: null };
                return currPage !== prevPage || currApplicationId !== prevApplicationId;
            },
            providesTags: ["hoMessages"]
        })
    })
});

export const {
    useFetchApplicationMessagesQuery
} = messagingApplicationsApiEndpoints;
import { wealthProApi } from '../../redux/api';

const hoAdminWrappersApiEndpoints = wealthProApi
    .injectEndpoints({
        endpoints: (builder) => ({
            fetchHeadOfficeWrappers: builder.query({
                query: () => `api/headofficeadmin/wrappers`
            }),
            fetchHeadOfficeWrapperProducts: builder.query({
                query: ({wrapperId}) => `api/headofficeadmin/wrappers/${wrapperId}/products`
            })
        })
    });

export const {
    useFetchHeadOfficeWrappersQuery,
    useLazyFetchHeadOfficeWrappersQuery,
    useFetchHeadOfficeWrapperProductsQuery,
    useLazyFetchHeadOfficeWrapperProductsQuery
} = hoAdminWrappersApiEndpoints;
import _ from "lodash";
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";
import { socket } from "../../redux/socket";

const buildFetchProjectQuery = ({ masterAccountId, searchText = "", page = 1, limit = 25 }, list = false) => {
    let baseQuery = list === false
        ? `api/clientprojects/master-accounts/${masterAccountId}/projects?page=${page}&limit=${limit}`
        : `api/clientprojects/master-accounts/${masterAccountId}/projects/list?page=${page}&limit=${limit}`;

    if (searchText && searchText.length > 0) {
        var searchTermEncode = encodeURIComponent(searchText);
        baseQuery += `&searchTerm=${searchTermEncode}`;
    }

    return baseQuery;
};

const projectsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountProject", "masterAccountProjects", "masterAccountProjectsList"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        createProject: builder.mutation({
            query: ({ masterAccountId, data }) => ({
                url: `api/clientprojects/master-accounts/${masterAccountId}/projects`,
                method: 'POST',
                body: data
            }),
        }),
        fetchMasterAccountProjects: builder.query({
            query: (props) => buildFetchProjectQuery(props),
            providesTags: (result, error, arg) => {
                return ['masterAccountProjects'];
            }
        }),
        fetchMasterAccountProjectsList: builder.query({
            query: (props) => buildFetchProjectQuery(props, true),
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                }
                else {
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                }             
            },
            onCacheEntryAdded: async ({ masterAccountId }, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                try {
                    await cacheDataLoaded;
                    socket.emit('subscribe-projects', { room: `${masterAccountId.replace(/-/gi, '')}_projects` });
                    socket.on('on-project-update', json => {
                        let contract = JSON.parse(json);
                        let record = {
                            label: contract.description,
                            value: contract.projectId.replace(/-/gi, ''),
                            isDisabled: false,
                            data: contract
                        };

                        updateCachedData((data) => {
                            let item = data.results.find(el => el.value === record.value);
                            if (!item) {
                                data.pagination.totalCount++;
                                data.results = [ record, ...data.results ];
                            }
                            else {
                                Object.assign(item, record)
                            }
                        });
                    });
                }
                catch { }
                await cacheEntryRemoved;
                socket.emit('unsubscribe-projects', { room: `${masterAccountId.replace(/-/gi, '')}_projects` });
                socket.off('on-project-update');
            }
        }),
        getProject: builder.query({
            query: ({ masterAccountId, projectId }) => `api/clientprojects/master-accounts/${masterAccountId}/projects/${projectId}`,
            providesTags: (result, error, arg) => {
                return ['masterAccountProject']
            }
        }),
        patchProject: builder.mutation({
            query: ({ masterAccountId, projectId, operations }) => ({
                url: `api/clientprojects/master-accounts/${masterAccountId}/projects/${projectId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ projectId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                // we need to update two seperate cache points that will have data that needs "refreshed"
                const baseResult = dispatch(wealthProApi.util.updateQueryData('getProject', { masterAccountId, projectId }, (data) => {
                    applyPatch(data, operations, true);
                }));
                const pageResult = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountProjects', { masterAccountId }, (data) => {
                    const { results } = data || { results: [] };
                    var pagedRecord = results.find(el => el.projectId === projectId);
                    if (pagedRecord) {
                        applyPatch(pagedRecord, operations, true);
                    }
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    baseResult.undo();
                    pageResult.undo();
                }
            }
        })
    })
});

export const {
    useCreateProjectMutation,
    
    useFetchMasterAccountProjectsQuery,
    useFetchMasterAccountProjectsListQuery,
    
    useGetProjectQuery,
    
    useLazyFetchMasterAccountProjectsListQuery,
    
    usePatchProjectMutation
} = projectsApiEndpoints;
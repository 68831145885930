import { wealthProApi } from '../../redux/api';
import _ from "lodash";

const queryApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        summaryQuery: builder.mutation({
            query: (data) => ({
                url: `api/review/Query/summary`,
                method: 'POST',
                body: data,
            })
        }),
        targetsQuery: builder.mutation({
            query: (data) => ({
                url: `api/review/Query/targets`,
                method: 'POST',
                body: data,
            })
        }),
        detailed: builder.query({
            query: (data) => ({
                url: `api/review/Query/detailed`,
                method: 'POST',
                body: data,
            }),
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            serializeQueryArgs: ({endpointName, queryArgs}) => {
                // const { cacheKey } = queryArgs || {};
                // return `${endpointName}-${cacheKey}`
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                }
                else {
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                }             
            }
        }),
    }),
});

export const {
    useSummaryQueryMutation,
    useTargetsQueryMutation,
    useLazyDetailedQuery
} = queryApiEndpoints;


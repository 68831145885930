import { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { CurrencyInput } from "../../../components/forms";
import { NotesEditor } from "../../../components/forms/richtexteditor/RichTextEditor";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import MortgageArrangements from "../components/MortgageArrangements";
import OtherDebts from "../components/OtherDebts";
import useLoansLiabilitiesSection from "../hooks/useLoansLiabilitiesSection";
import useNotes from "../hooks/useNotes";

const LoansAndLiabilitiesSection = () => {
    const { data, isLoading, isError, error, refetchLoansAndLiabilitiesSection } = useLoansLiabilitiesSection();
    const { updateNotes } = useNotes();
    const [totalMortgages, setTotalMortgages] = useState(null);
    const [totalOtherDebts, setTotalOtherDebts] = useState(null);

    const totalLiabilities = useMemo(() => totalMortgages + totalOtherDebts, [totalMortgages, totalOtherDebts]);

    if (isLoading) return <Skeleton height={16} count={3} />;

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchLoansAndLiabilitiesSection} />
        </ErrorMessageWrapper>;
    }

    return <div className="container-fluid">
        <div className="d-flex flex-row justify-content-around row">
            <span className={"col-12 col-md-2"}>
                <CurrencyInput
                    label="Total Mortgages:"
                    value={totalMortgages}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />
            </span>
            <span className={"col-12 col-md-2 mt-2 mt-md-0"}>
                <CurrencyInput
                    label="Total Other Debts:"
                    value={totalOtherDebts}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />
            </span>
            <span className="d-md-flex d-lg-grid col-12 col-lg-2 justify-content-between justify-content-md-center mt-2 mt-lg-0">
                <CurrencyInput
                    label={"Total Liabilities:"}
                    value={totalLiabilities}
                    decimalScale={0}
                    fixedDecimalScale
                    disabled
                />
            </span>
        </div>

        <NotesEditor
            className="mt-2"
            label={"General Notes"}
            property={"notes"}
            defaultValue={data?.generalNotes?.notes}
            onBlur={(newValue) => updateNotes(data?.generalNotes.notesId, newValue)}
        />
        <MortgageArrangements setTotalMortgages={setTotalMortgages} />
        <OtherDebts setTotalOtherDebts={setTotalOtherDebts} />
    </div>
}

export default LoansAndLiabilitiesSection;
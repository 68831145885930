import { useCreateFeeGroupMutation } from "../../../services/headofficeadmin";

const useCreateFeeGroup = () => {
    
    const [createTrigger] = useCreateFeeGroupMutation();

    const defaultValues = {
        masterAccountId: null,
        providerId: null,
        applicationId: null,
        description: null,
        vatable: false,
        vatRate: 0.0,
        feeType: null
    };

    const createFeeGroup = (request) => {
        return new Promise((resolve, reject) => {
            return createTrigger(request)
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        defaultValues,
        createFeeGroup
    };
};

export default useCreateFeeGroup;
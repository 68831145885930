import React from "react";
import classNames from "classnames";

const UsersGridTableOnlineColumn = ({ isOnline }) => {
    return (
        <div className={classNames('badge', {
            'bg-boolean-pill-false text-boolean-pill-false': !isOnline,
            'bg-boolean-pill-true text-boolean-pill-true' : isOnline
        })}>
            {isOnline ? 'Online' : 'Offline'}
        </div>
    );
};

export default UsersGridTableOnlineColumn;
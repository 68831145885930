import React from "react";
import styled from "styled-components";
import { ThemedSpan } from "../utilities";

const Container = styled.div`
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 20;
`;

const ReportReadonlyMessage = ({ text = "This report has been completed. It is now Readonly." }) => {
    return (
        <Container>
            <ThemedSpan variant="muted" className="py-3">{text}</ThemedSpan>
        </Container>
    );
};

export default ReportReadonlyMessage;
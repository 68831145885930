import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ThemedSpan from './ThemedSpan';
import styled from 'styled-components';
import { darken } from 'polished';
import toast from 'react-hot-toast';
import useOpenDocument from "../../hooks/useOpenDocument";
import Button, { IconButton } from '../buttons';
import ThemedIcon from './ThemedIcon';
import classNames from 'classnames';

const FileDisplay = styled.div`
    width: 20vw;
    min-width: 300px;
    &:hover {
        background-color: ${props => darken(0.075, props.theme.light)};
    }
`;

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick, variant = "light", toggleType = "icon" }, ref) => (
    <React.Fragment>
        {toggleType === "icon"
            ? <IconButton
                size="lg"
                variant={variant}
                icon={"paperclip"}
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            />
            : <Button
                ref={ref}
                className="w-100"
                variant={variant}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
            </Button>
        }
    </React.Fragment>

));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                {children}
            </div>
        );
    },
);

const Attachment = ({ file, canOpen }) => {

    const { openDocument, isFetching: isOpening } = useOpenDocument();

    const getExtension = () => {
        return file.fileName.substring(file.fileName.lastIndexOf('.') + 1, file.fileName.length) || file.fileName;
    };

    const fileIcon = useMemo(() => AttachmentIconMap[getExtension()], [file]);

    return <FileDisplay
        className={classNames('p-2', { 'has-pointer': canOpen })}
        onClick={canOpen && !isOpening ? () => openDocument(file.documentId, file.fileName) : () => { }}
    >
        <FontAwesomeIcon icon={fileIcon?.icon ?? "file"} color={fileIcon?.color ?? "#333"} className='me-2' size="lg" />
        <ThemedSpan>{file.fileName}</ThemedSpan>
    </FileDisplay>
};

const AttachmentsToggle = ({
    attachments = [],
    className,
    isCreatable = false,
    menuDirection = "up",
    onUploadFn,
    toggleVariant = "light",
    canOpenDocument = true,
    toggleType = "icon",
    uploadLabel = "Upload Attachment",
    disabled = false,
    children
}) => {
    return (
        <Dropdown className={classNames(className, 'border rounded')} drop={menuDirection}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" variant={toggleVariant} toggleType={toggleType}>{children}</Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}  >
                <Dropdown.Header className='p-2'>
                    Attachments
                </Dropdown.Header>
                {isCreatable && <React.Fragment>
                    <Dropdown.Item disabled={disabled} onClick={onUploadFn}>{uploadLabel}</Dropdown.Item>
                    <Dropdown.Divider />
                </React.Fragment>}
                {Array.isArray(attachments) && attachments.length > 0 && attachments.map((file, key) => <Attachment key={key} file={file} canOpen={canOpenDocument} />)}
                {Array.isArray(attachments) && attachments.length === 0 && (
                    <Dropdown.ItemText className='text-muted'>
                        No attachments
                    </Dropdown.ItemText>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export const AttachmentIconMap = {
    "doc": {
        icon: "file-word",
        color: "#2166C3"
    },
    "docx": {
        icon: "file-word",
        color: "#2166C3"
    },
    "doc": {
        icon: "file-2166C3",
        color: "#137bab"
    },
    "xls": {
        icon: "file-excel",
        color: " #148348"
    },
    "xlsx": {
        icon: "file-excel",
        color: " #148348"
    },
    "csv": {
        icon: "file-csv",
        color: "#148348"
    },
    "gif": {
        icon: "file-image",
        color: "#B24BF3"
    },
    "jpeg": {
        icon: "file-image",
        color: "#B24BF3"
    },
    "png": {
        icon: "file-image",
        color: "#B24BF3"
    },
    "pdf": {
        icon: "file-pdf",
        color: "#FA0F00"
    },
    "xml": {
        icon: "file-code",
        color: "#148348"
    },
    "txt": {
        icon: "file-lines",
        color: "#6FCFEB"
    },
    "mp4": {
        icon: "file-video",
        color: "#B24BF3"
    },
    "mpeg": {
        icon: "file-video",
        color: "#B24BF3"
    },
    "msg": {
        icon: "file-lines",
        color: "#0072c6"
    },
    "eml": {
        icon: "file-lines",
        color: "#0072c6"
    },
};

export default AttachmentsToggle;
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FormSelect, PagedSelect } from "../../../components/forms";
import { Col, Modal, ModalDialog, Row } from "react-bootstrap";
import ProductCostsList from "./ProductCostsList";
import useInstructionTypes from "../hooks/useInstructionTypes";
import { Main } from "../../../components/utilities";
import { useProductsGridContext } from "../hooks/ProductsGridContext";
import { Button } from "../../../components";

const ProductsContainer = styled(Main)`
    display: flex;
    flex: 1;
`;


const StyledModal = styled(ModalDialog)`
display: flex;
max-width: 95vw;
margin-top: 20vh;
margin-bottom: 20vh;
`;

const ProductCostsHeader = styled(Row)`
`;

export const ProductCostsModal = () => {

    const { selectedProduct, setSelectedProduct } = useProductsGridContext();

    const showModal = useMemo(() => selectedProduct !== null, [selectedProduct]);

    const onHide = () => setSelectedProduct(null);

    return <Modal
        dialogAs={StyledModal}
        show={showModal}
        onHide={onHide}
    >
        <Modal.Header closeButton>{selectedProduct?.productName} Costs</Modal.Header>

        <Modal.Body>

            {selectedProduct && <ProductCosts
                wrapperId={selectedProduct.wrapperId}
                productId={selectedProduct.productId}
            />}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="light" onClick={onHide}>Close</Button>
        </Modal.Footer>
    </Modal>
}

const ProductCosts = ({ productId, wrapperId }) => {
    const [instructionTypeId, setInstructionTypeId] = useState(null);
    const { fetchInstructionTypes } = useInstructionTypes(wrapperId);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleError = (error) => {
        setIsLoading(_ => false);
    }

    const handleSuccess = (response) => {
        setOptions(_ => response.data);
        setIsLoading(_ => false);
        if (response.data && response.data.length > 0) {
            setInstructionTypeId(_ => response.data[0].value);
        }
    }

    const onChangeEvent = (e) => {
        setInstructionTypeId(_ => e.value);
    }

    useEffect(() => {
        if (wrapperId) {
            setIsLoading(_ => true);
            fetchInstructionTypes({})
                .then(
                    handleSuccess,
                    handleError
                );
        }
    }, [wrapperId]);

    return <div>
        <ProductCostsHeader>
            <FormSelect
                horizontal
                className={"mb-2"}
                label="Select Instruction Type"
                key={instructionTypeId}
                options={options}
                isLoadingOptions={isLoading}
                onChange={onChangeEvent}
                defaultValue={instructionTypeId}
            />
        </ProductCostsHeader>
        {instructionTypeId && <ProductCostsList
            productId={productId}
            instructionTypeId={instructionTypeId}
        />}
    </div>
};

export default ProductCosts;
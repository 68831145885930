import { wealthProApi } from "../../redux/api";

const assetVolatilityApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        fetchAssetVolatilityForGrid: build.query({
            query: ({page, limit, sort, filterModel}) => ({
                url: 'api/assets/assetvolatility/grid',
                method: "POST",
                body: {
                    page,
                    limit,
                    sort,
                    filterModel
                }
            })
        }),
        patchAssetVolatility: build.mutation({
            query: ({assetId, operations}) => ({
                url: `api/assets/assetvolatility/${assetId}`,
                method: "PATCH",
                body: operations
            })
        })
    })
});

export const {
    useLazyFetchAssetVolatilityForGridQuery,
    usePatchAssetVolatilityMutation
} = assetVolatilityApiEndpoints;
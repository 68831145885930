import { 
    
    useCreateReviewForMasterAccountMutation

} from "../../../services/clientreviews";

const useReviews = (masterAccountId) => {

   
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateReviewForMasterAccountMutation();

    const createReview = (e) => {

        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            return create({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        createReview
    }

};

export default useReviews;
import { Alert, Modal, ModalDialog } from "react-bootstrap";
import { Button } from "../../../components";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import MissingAccountsGrid from "./MissingAccountsGrid";
import { useState } from "react";
import styled from "styled-components";


const MissingAccountsModal = ({ }) => {

    const { potentialAccountMatches } = useCustodianTransactionMatching();
    const { data, isLoading, isError, error, showModal, setShowModal } = potentialAccountMatches;
    const [showNote, setShowNote] = useState(true);

    const handleClose = (e) => {
        if (typeof e?.preventDefault === "function") {
            e.preventDefault();
        }

        if(data?.length > 0) return;

        setShowModal(false);
    };

    return (
        <Modal dialogClassName="min-w-90" centered backdrop="static" show={showModal} onHide={handleClose}>
            <Modal.Header >
                <Modal.Title>Missing Accounts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert className="d-flex flex-column py-1" variant="warning" dismissible show={showNote} onClose={() => setShowNote(false)}>
                    <span><b>Note</b></span>
                    <span>
                        The following accounts have not yet had their owner confirmed. The system-selected client matches have been filled in,
                        but must be confirmed or altered before any exceptions can be viewed.
                    </span>
                </Alert>
                <MissingAccountsGrid />
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={data?.length > 0} variant="primary">Continue</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MissingAccountsModal;
import { useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchProtectionSectionQuery, usePatchNotesMutation, usePatchProtectionSectionMutation } from "../../../services/clientfactfind";

const useProtectionSection = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: protectionSection, isLoading, isError, error, refetch, isUninitialized } = useFetchProtectionSectionQuery({ masterAccountId });
    const { protectionSectionId: protectionId } = protectionSection || {};
    const [triggerPatch] = usePatchProtectionSectionMutation();
    const [triggerPatchNotes] = usePatchNotesMutation();

    const updateProtectionSection = (property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return triggerPatch({ protectionId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updateProtectionSectionNotes = (notesId, newText) => {
        return new Promise((resolve, reject) => {
            return triggerPatchNotes({ notesId, newText })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const updateProtectionNeededProperty = (newValue) => {
        if (newValue) {
            return updateProtectionSection("isProtectionNeeded", true);
        }

        return new Promise((resolve, reject) => {
            const operations = [patchReplace("isProtectionNeeded", false), patchReplace("doesClientWishAdvice", false)];
            return triggerPatch({ protectionId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const [isFetching, setIsFetching] = useState(false);

    const manualRefetch = () => {
        return new Promise((resolve, reject) => {
            setIsFetching(true);
            refetch().then(
                r => {
                    setIsFetching(false);
                    resolve(r);
                },
                e => reject(e)
            );
        });
    }

    return {
        protectionSection: protectionSection || {},
        isLoading: isLoading || isFetching || isUninitialized,
        isError,
        error,
        refetchProtectionSection: manualRefetch,
        updateProtectionSection,
        updateProtectionNeededProperty,
        updateProtectionSectionNotes
    };
}

export default useProtectionSection;
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";

const DropdownWrapper = styled(Dropdown)`
    width: 650px;
    margin: 0 0.5rem;

    @media (max-width: 1061.98px) {
        width: 450px;
    }

    @media (max-width: 865.98px) {
        width: 350px;
    }

    @media (max-width: 767.98px) {
        width: 100%;
        margin: 0;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
`;

export default DropdownWrapper;
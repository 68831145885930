import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import CustodianAccountMatchingGridRowActionCol from "./CustodianAccountMatchingGridRowActionCol";
import moment from "moment";
import Moment from "react-moment";

const TableCell = styled.td`
    width: ${props => props.width}%;
    max-width: 50px;
    vertical-align: middle;
`;


const CustodianAccountMatchingGridRow = ({ data, deleteFn, index, setToEdit, style: { height, width }, ...rest }) => {

    const item = data[index];

    const {
        custodianCode,
        platformAccountId,
        masterAccountReference,
        designation,
        wrapperName,
        productName,
        providerReference,
        notes,
        mapId,
        createdDate
    } = item || {};

    const handleEditClick = () => {
        setToEdit(mapId)
    };


    if (!item) {
        return <tr style={{ height, width }}>
            <td colSpan={9}>
                <Skeleton height={36} />
            </td>
        </tr>
    }

    return <tr style={{ height, width }}>
        <TableCell width={10} className="text-truncate">{masterAccountReference}</TableCell>
        <TableCell width={10} className="text-truncate">{designation}</TableCell>
        <TableCell width={7.5} className="text-truncate">{custodianCode}</TableCell>
        <TableCell width={12.5} className="text-truncate">{platformAccountId}</TableCell>
        <TableCell width={10} className="text-truncate">{wrapperName}</TableCell>
        <TableCell width={10} className="text-truncate">{productName}</TableCell>
        <TableCell width={7.5} className="text-truncate">{providerReference}</TableCell>
        <TableCell className="text-truncate" width={20}>
            {notes}
        </TableCell>
        <TableCell width={7.5}>
            {moment(createdDate).isValid()
                ? <Moment date={createdDate} format="DD/MM/YYYY" />
                : <span className="px-2">-</span>}
        </TableCell>
        <TableCell align="right">
            <CustodianAccountMatchingGridRowActionCol
                deleteFn={() => deleteFn(mapId)}
                setToEdit={handleEditClick}
            />
        </TableCell>
    </tr>;
};

export default CustodianAccountMatchingGridRow;
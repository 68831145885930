import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import _ from "lodash";

const WrappersTableRowActionColToggle = React.forwardRef(({ onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const WrappersTableRowActionCol = ({ deleteWrapper, setShowEdit, showEdit }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    return (
        <Dropdown id={menuId} className="caret-off w-100">
            <Dropdown.Toggle as={WrappersTableRowActionColToggle} id={toggleId} />
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowEdit(true)}>
                    {showEdit ? "View-Only" : "Edit"}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={deleteWrapper}>
                    Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

};

export default WrappersTableRowActionCol;
import React, { useId } from "react";
import styled from "styled-components";
import { lighten } from "polished";

const Wrapper = styled.div`
    background-color: #f7f7f7;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 16px;
`;

const Header = styled.div`
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 0.875rem;
    color: #0f78d5;
    background-color: ${props => props.theme.light};
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

const Body = styled.div`
    background-color: ${props => props.theme.light};
    padding: 1rem 1.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border: "1px solid #c7c7c7";
`;

const ContentWrapper = ({children, label}) => {

return (
    <Wrapper>
        <Header>
            {label && (<React.Fragment>{label}</React.Fragment>)}
        </Header>
        <Body>
            {children}
        </Body>
    </Wrapper>
)
   
};

export default ContentWrapper;
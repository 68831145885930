import styled from "styled-components";

const Header = styled.header`
    z-index: 98;
    position: sticky;
    top: 0;
    grid-area: header;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    display: grid;
    grid-template-rows:     var(--navbar-size);
    grid-template-columns:  auto auto auto minmax(0, 1fr);
    grid-template-areas:    "headernav headerbrand clientsearch headerlinks";
    
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    & > div {
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 767.98px) {
        grid-template-rows:     var(--navbar-size);
        grid-template-columns:  auto 1fr;
        grid-template-areas:    "headernav headerbrand";

        box-shadow: unset;
    }
`;

export default Header;
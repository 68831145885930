import React from "react";
import moment from "moment";
import Moment from "react-moment";
import styled from "styled-components";
import { variantToColour } from "../helpers/theme";

const StyledMoment = styled(Moment)`
    color: ${props => variantToColour(props)};
`;

const DateDisplay = ({ 
    format = "DD/MM/YYYY",
    fromNow = true,
    utc = true, 
    days = 7,
    children,
    ...rest 
}) => {
    if (!children || typeof(children) !== 'string') {
        return null;
    }

    const daysDiff = moment().diff(moment(children), 'days');
    if (fromNow === true && daysDiff <= days) {
        return (
            <StyledMoment fromNow utc {...rest}>{children}</StyledMoment>
        );
    }
    else {
        return (
            <StyledMoment format={format} utc={utc} {...rest}>{children}</StyledMoment>
        );
    }
    
};

export default DateDisplay;
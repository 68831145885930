import { wealthProApi } from "../../redux/api";

const policyCoversApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        deletePolicyCover: builder.mutation({
            query: ({ policyId }) => ({
                url: `api/factfind/policycovers/${policyId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ isCurrentPriority, protectionId, policyId }, { queryFulfilled, dispatch }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchPolicyCovers', { protectionId, isCurrentPriority }, (data) =>
                        data.filter(x => x.policyId !== policyId)));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting policy cover in service", err);
                        deleteResult.undo();
                    }
                } catch (err) {
                    console.error("Error deleting policy cover in cache", err);
                }
            }
        }),
        patchPolicyCover: builder.mutation({
            query: ({ policyId, operations }) => ({
                url: `api/factfind/policycovers/${policyId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ isCurrentPriority, protectionId, policyId, operations, productTypeProperties }, { queryFulfilled, dispatch }) {

                try {
                    const { data: viewModel } = await queryFulfilled;

                    if (operations.some(x => x.path === "/isCurrentPriority")) {
                        // add the policy to the cached data matching the new priority setting
                        dispatch(wealthProApi.util.updateQueryData('fetchPolicyCovers', { protectionId, isCurrentPriority: !isCurrentPriority }, (data) => {
                            return [viewModel, ...data];
                        }));

                        // remove the policy from the old cached data
                        dispatch(wealthProApi.util.updateQueryData('fetchPolicyCovers', { protectionId, isCurrentPriority }, (data) => {
                            return data.filter(x => x.policyId !== policyId);
                        }));

                        return;
                    }

                    dispatch(wealthProApi.util.updateQueryData('fetchPolicyCovers', { protectionId, isCurrentPriority }, (data) => {
                        var item = data.find(x => x.policyId === policyId);
                        Object.assign(item, viewModel);

                        if (productTypeProperties) {
                            Object.assign(productTypeProperties, viewModel);
                        }
                    }));
                }
                catch { }
            }
        }),
        createPolicyCover: builder.mutation({
            query: ({ masterAccountId, isCurrentPriority, protectionId }) => ({
                url: `api/factfind/policycovers`,
                method: 'POST',
                body: {
                    masterAccountId,
                    isCurrentPriority,
                    protectionId
                }
            }),
            async onQueryStarted({ masterAccountId, isCurrentPriority, protectionId }, { queryFulfilled, dispatch }) {
                try {
                    let createdIndex
                    const createResult = dispatch(wealthProApi.util.updateQueryData('fetchPolicyCovers', { protectionId, isCurrentPriority }, (data) => {
                        const newPolicyCover = {
                            masterAccountId,
                            isCurrentPriority,
                            protectionSectionId: protectionId,
                            chosenCoverMappings: [],
                            clientMappings: []
                        };
                        createdIndex = data.length;

                        return [...data, newPolicyCover];
                    }));

                    try {
                        const { data: response } = await queryFulfilled;

                        dispatch(wealthProApi.util.updateQueryData('fetchPolicyCovers', { protectionId, isCurrentPriority }, (data) => [
                            ...data.slice(0, createdIndex),
                            {
                                ...data[createdIndex],
                                policyId: response.policyId,
                            },
                            ...data.slice(createdIndex + 1),
                        ]));
                    } catch (err) {
                        console.error("Error creating policy cover in service", err);
                        createResult.undo();
                    }
                } catch (err) {
                    console.error("Error creating policy cover in cache", err);
                }
            }
        }),
        updatePolicyClientMappings: builder.mutation({
            query: ({ policyId, ids }) => ({
                url: `api/factfind/policycovers/clients`,
                method: 'POST',
                body: {
                    policyId,
                    ids
                }
            }),
            async onQueryStarted({ policyId, isCurrentPriority, protectionId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newMappings } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchPolicyCovers', { protectionId, isCurrentPriority }, data => {
                        var item = data.find(x => x.policyId === policyId);
                        item.clientMappings = newMappings;
                    }));
                }
                catch { }
            }
        }),
        updateChosenCoverMappings: builder.mutation({
            query: ({ policyId, ids }) => ({
                url: `api/factfind/policycovers/chosencover`,
                method: 'POST',
                body: {
                    policyId,
                    ids
                }
            }),
            async onQueryStarted({ policyId, isCurrentPriority, protectionId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newMappings } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchPolicyCovers', { protectionId, isCurrentPriority }, data => {
                        var item = data.find(x => x.policyId === policyId);
                        item.chosenCoverMappings = newMappings;
                    }));
                }
                catch { }
            }
        })
    })
});

export const {
    useCreatePolicyCoverMutation,
    useDeletePolicyCoverMutation,
    usePatchPolicyCoverMutation,
    useUpdateChosenCoverMappingsMutation,
    useUpdatePolicyClientMappingsMutation
} = policyCoversApiEndpoints;
import React, { useId, useState } from "react";
import LinkItem from "./components/LinkItem";
import LogoutModal from "../logoutmodal";
import { NavDropdown } from "react-bootstrap";
import { ProfileIcon } from "../../components";
import { useAuth } from "react-oidc-context";
import { UserRoles } from "../../helpers/constants";

const ProfileDropdown = () => {
    const dropdownId = useId();
    const { user: { profile } } = useAuth();
    const [showLogout, setShowLogout] = useState(false);

    return <React.Fragment>
        <LogoutModal handleClose={() => setShowLogout(false)} show={showLogout} />
        <NavDropdown id={dropdownId} className="caret-off" align="end" title={(<ProfileIcon user={profile} />)}>
            {/* {hasRole(UserRoles.sysAdmin) === true && (<LinkItem to="/settings" title="System Settings" />)}
            <NavDropdown.Divider /> */}
            <NavDropdown.Item onClick={() => setShowLogout(true)}>Logout</NavDropdown.Item>
        </NavDropdown>
    </React.Fragment>
    
    
};

export default ProfileDropdown;
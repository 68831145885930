import moment from "moment";
import Moment from "react-moment";
import MissingAccountsGridRowActionCol from "./MissingAccountsGridRowActionCol";
import { useEffect, useMemo, useState } from "react";
import { HeadOfficeMasterAccountDesignationsSelect, HeadOfficeMasterAccountSelect, HeadOfficeUnmatchedPlatformAccountsSelect, HeadOfficeWrapperProductsSelect, HeadOfficeWrappersSelect } from "../../../components/forms/selects";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import toast from "react-hot-toast";
import { FormInput } from "../../../components/forms";

const MissingAccountsGridRow = ({ data }) => {

    const { potentialAccountMatches: { createPlatformAccountDesignationMapping } } = useCustodianTransactionMatching();
    const [isEditable, setIsEditable] = useState(false);
    const [platformAccountMapping, setPlatformAccountMapping] = useState(data);
    console.log(data)
    const {
        custodianCode,
        dateAdded,
        designation,
        designationId,
        masterAccountId,
        masterAccountReference,
        masterAccountName,
        platformAccountId,
        platformAccountGuid,
        platformAccountName,
        productId,
        productName,
        organisationId,
        score,
        wrapperId,
        wrapperName,
        providerReference
    } = platformAccountMapping;

    const isValidMapping = useMemo(() => {
        return !designationId || !platformAccountId || !productId
    }, [designationId, platformAccountId, productId]);

    const handleConfirm = () => {
        const request = {
            organisationId,
            custodianCode,
            designationId,
            platformAccountId,
            productId
        };

        toast.promise(createPlatformAccountDesignationMapping(request), {
            loading: "Creating mapping...",
            error: (err) => {
                return err?.data ?? "Unable to create mapping."
            },
            success: (data) => {
                return "Mapping created!";
            }
        });
    };

    const updateMapping = (keyValues) => {
        // so we can pass in { masterAccountId, masterAccountName } etc and assign both
        setPlatformAccountMapping(prev => ({
            ...prev,
            ...keyValues
        }));
    };

    if (isEditable) {
        return (
            <tr onDoubleClick={() => setIsEditable(false)}>
                <td className="col-2">
                    <HeadOfficeMasterAccountSelect
                        valueObject={{
                            value: masterAccountId,
                            label: masterAccountName
                        }}
                        onChange={({ label, value, data: { accountRef } }) => updateMapping({
                            masterAccountId: value,
                            masterAccountName: label,
                            masterAccountReference: accountRef
                        })}
                    />
                </td>
                <td className="col-2">
                    <HeadOfficeMasterAccountDesignationsSelect
                        defaultValue={designationId}
                        masterAccountId={masterAccountId}
                        onChange={({ value, label }) => updateMapping({
                            designationId: value,
                            designation: label
                        })}
                    />
                </td>
                <td>{custodianCode}</td>
                <td className="col-2">
                    <HeadOfficeUnmatchedPlatformAccountsSelect
                        defaultValue={platformAccountGuid}
                        onChange={({ value, data: { platformAccountId } }) => updateMapping({
                            platformAccountGuid: value,
                            platformAccountId
                        })}
                    />
                </td>
                <td className="col-2">
                    <HeadOfficeWrappersSelect
                        defaultValue={wrapperId}
                        onChange={({ value, label }) => updateMapping({ wrapperId: value, wrapperName: label })}
                    />
                </td>
                <td className="col-2">
                    <HeadOfficeWrapperProductsSelect
                        defaultValue={productId}
                        wrapperId={wrapperId}
                        onChange={({ value, label }) => updateMapping({ productId: value, productName: label })}
                    />
                </td>
                <td className="col-2">
                    <FormInput
                        value={providerReference}
                        onChange={(e) => updateMapping({providerReference: e.target.value})}
                    />
                </td>
                <td>
                    {
                        moment(dateAdded).isValid()
                            ? <Moment format="DD/MM/YYYY" date={dateAdded} />
                            : <span className="px-2">-</span>
                    }
                </td>
                <td align="right">{score}</td>
                <td><MissingAccountsGridRowActionCol
                    canConfirm={isValidMapping}
                    confirmFn={handleConfirm}
                    setShowEdit={setIsEditable}
                />
                </td>
            </tr>
        );
    }

    return (
        <tr onDoubleClick={() => setIsEditable(true)}>
            <td>{masterAccountReference}</td>
            <td>{designation}</td>
            <td>{custodianCode}</td>
            <td>{platformAccountId} - {platformAccountName}</td>
            <td>{wrapperName}</td>
            <td>{productName}</td>
            <td>{providerReference}</td>
            <td>
                {
                    moment(dateAdded).isValid()
                        ? <Moment format="DD/MM/YYYY" date={dateAdded} />
                        : <span className="px-2">-</span>
                }
            </td>
            <td align="right">{score}</td>
            <td><MissingAccountsGridRowActionCol
                canConfirm={isValidMapping}
                confirmFn={handleConfirm}
                setShowEdit={setIsEditable}
            /></td>
        </tr>
    );
};

export default MissingAccountsGridRow;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken } from "polished";
import React from "react";
import styled from "styled-components";

const ClientDocumentsTableRowActionColParent = styled.td`
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
`;

const ClickableIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: ${props => props.theme.muted};

    &:hover {
        color: ${props => darken(props.theme.linkDarkenAmount, props.theme.muted)};
    }
`;

const ClientDocumentsTableRowActionCol = ({ selectDocument }) => {
    return (
        <ClientDocumentsTableRowActionColParent>
            <ClickableIcon
                icon="edit"
                size="lg"
                onClick={selectDocument}
            />
        </ClientDocumentsTableRowActionColParent>
    );
};

export default ClientDocumentsTableRowActionCol;

// const ClientDocumentsTableRowActionColToggle = React.forwardRef(({ ...rest }, ref) => {
//     return (
//         <div ref={ref} {...rest}>
//             <FontAwesomeIcon icon="fa-ellipsis" />
//         </div>
//     );
// });

// export const ClientDocumentOpenActionMenuItem = ({ documentId, userReadable, openDocument }) => {

//     const onHandleClick = () => {
//         if (!userReadable || !openDocument || typeof (openDocument) !== 'function') {
//             return;
//         }
//         openDocument(documentId);
//     };

//     return (
//         <Dropdown.Item disabled={!userReadable} onClick={onHandleClick}>
//             Open Document
//         </Dropdown.Item>
//     );
// };

// export const ClientDocumentEditActionMenuItem = ({ documentId, userReadable, setSelectedRow }) => {

//     const onHandleClick = () => {
//         if (!userReadable || !setSelectedRow || typeof (setSelectedRow) !== 'function') {
//             return;
//         }
//         setSelectedRow(documentId);
//     };

//     return (
//         <Dropdown.Item disabled={!userReadable} onClick={onHandleClick}>
//             Edit
//         </Dropdown.Item>
//     );
// };

// export const ClientDocumentMarkClientVisibleActionMenuItem = ({ documentId, clientReadable, userReadable, updateClientReadable }) => {
    
//     const onHandleClick = () => {
//         if (!userReadable || !updateClientReadable || typeof (updateClientReadable) !== 'function') {
//             return;
//         }
//         updateClientReadable(documentId, !clientReadable);
//     };
    
//     return (
//         <Dropdown.Item disabled={!userReadable} onClick={onHandleClick}>
//             {clientReadable ? 'Mark as Client Unreadable' : 'Mark as Client Readable'}
//         </Dropdown.Item>
//     );
// };

// const ClientDocumentsTableRowActionCol = ({ documentId, openDocument, userReadable, clientReadable, updateClientReadable, setSelectedRow }) => {
//     const menuId = React.useId();
//     return (
//         <ClientDocumentsTableRowActionColParent>
//             <Dropdown className="caret-off">
//                 <Dropdown.Toggle id={menuId} as={ClientDocumentsTableRowActionColToggle} />
//                 <Dropdown.Menu>
//                     <ClientDocumentOpenActionMenuItem 
//                         documentId={documentId}
//                         userReadable={userReadable}
//                         openDocument={openDocument}
//                     />
//                     <Dropdown.Divider />
//                     <ClientDocumentEditActionMenuItem 
//                         documentId={documentId}
//                         userReadable={userReadable}
//                         setSelectedRow={setSelectedRow}
//                     />
//                     <ClientDocumentMarkClientVisibleActionMenuItem 
//                         documentId={documentId}
//                         clientReadable={clientReadable} 
//                         userReadable={userReadable} 
//                         updateClientReadable={updateClientReadable} 
//                     />
//                 </Dropdown.Menu>
//             </Dropdown>
//         </ClientDocumentsTableRowActionColParent>
//     );
// };

// export default ClientDocumentsTableRowActionCol;
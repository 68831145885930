import { useLazyDetailedLeagueTableQuery } from "../../../services/headofficeadmin";

const useDetailedLeagueTable = () => {

    const [report, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyDetailedLeagueTableQuery();
    
    const getDetailedLeagueTableReport = (reportDate) => {
            return new Promise((resolve, reject) => {
                return report({data: {reportDate: reportDate} }).unwrap().then(
                    r => resolve(r),
                    e => reject(e)
                );
            });
    };
    
    return {
        getDetailedLeagueTableReport
    }

};

export default useDetailedLeagueTable;
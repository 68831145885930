import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import { patchReplace } from "../../../helpers/patchDoc";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useHoFetchBusinessSheetStatusesQuery, useHoPatchRecommendationMutation, useHoTriggerAdministrationMutation, useLazyHoSearchRecommendationsQuery } from "../../../services/headofficeadmin";

export const BusinessSheetStatuses = {
    AwaitApproval: 0,
    AdviserChasing: 1,
    OnHold: 2,
    Administration: 3,
    CloseLoop: 4,
    NotTakenUp: 5,
};

const FixedWidthSortableHeader = styled(StandardTableSortableHeader)`
    width: ${props => props.width ?? 'auto'};
`

const useAdviceGrid = (initSort = "reference+desc") => {
    const { filter } = useFilterContext();
    const limit = 50;
    const [sort, setSort] = useState(initSort);
    const [searchTrigger, searchResults] = useLazyHoSearchRecommendationsQuery();
    const { data, isLoading, isError, isFetching, error, isUninitialized } = searchResults;
    const { data: statuses, isLoading: isLoadingStatuses } = useHoFetchBusinessSheetStatusesQuery();
    const { pagination, results } = data || { pagination: { page: 0, totalCount: 10 }, results: [] };
    const { page, totalCount } = pagination;
    const [sortProperty, direction] = sort.split("+");
    const [patchRecommendation] = useHoPatchRecommendationMutation();
    const [triggerAdmin, triggerAdminResult] = useHoTriggerAdministrationMutation();

    const clear = () => searchTrigger({
        ...filter,
        sort,
        page: 1,
        limit
    });

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => searchTrigger({
        ...filter,
        sort,
        page: page + 1,
        limit
    });

    const onClickSort = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        clear();
    };

    const sortableHeaderRenderer = ({ label, property, key, width }) => {
        return <FixedWidthSortableHeader
            key={key}
            width={width}
            onClick={() => onClickSort(property)}
            active={property === sortProperty}
            direction={direction}
            isSticky
        >
            {label}
        </FixedWidthSortableHeader>;
    };

    // Assumes only Status and StatusDate can be updated
    const updateStatus = useCallback((businessSheetId, newValue, evidence = null) => {
        let operations = [patchReplace("status", newValue)];
        if (evidence) {
            operations.push(patchReplace("amlEvidencePath", evidence));
        }

        return patchRecommendation({
            businessSheetId,
            operations
        }).unwrap()
    }, [patchRecommendation])

    const triggerAdministration = (businessSheetId, request) => {
        return new Promise((resolve, reject) => {
            return triggerAdmin({businessSheetId, request})
                .unwrap()
                .then(resolve, reject);
        });
    };

    const columns = [
        { label: "Open PDF", property: "clientApprovalPdfPath", width: "5%" },
        { label: "Reference", property: "qualifiedReference", headerRenderer: sortableHeaderRenderer, width: "7.5%" },
        { label: "Client", property: "masterAccountName", headerRenderer: sortableHeaderRenderer, width: "17.5%" },
        { label: "Status", property: "status", headerRenderer: sortableHeaderRenderer, width: "12.5%" },
        { label: "Status Date", property: "statusDate", headerRenderer: sortableHeaderRenderer, width: "10%" },
        { label: "Description", property: "description", headerRenderer: sortableHeaderRenderer, width: "27.5%" },
        { label: "Adviser", property: "adviserName", headerRenderer: sortableHeaderRenderer, width: "10%" }
    ];

    useEffect(() => {
        if (!isUninitialized) clear();
    }, [sort, filter])

    return {
        columns,
        data: results,
        error,
        isError,
        isFetching,
        isLoading,
        isLoadingStatuses,
        limit,
        page,
        statuses,
        totalCount: totalCount,
        clear,
        isItemLoaded,
        loadMoreItems,
        reload,
        triggerAdministration,
        updateStatus
    };
}

export default useAdviceGrid;
import { useLazyAjbDebtorsLedgerReportQuery } from "../../../services/headofficeadmin";

const useAjbDebtorsLedgerReport = () => {

    const [report, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyAjbDebtorsLedgerReportQuery();

    const getAjbDebtorsLedgerReport = (theRequest) => {
        const fromDate = theRequest.fromDate;
        const toDate = theRequest.toDate;
        return new Promise((resolve, reject) => {
            
            return report({data: {fromDate: fromDate, toDate: toDate}}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    return {
        getAjbDebtorsLedgerReport
    }

};

export default useAjbDebtorsLedgerReport;
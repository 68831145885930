import { wealthProApi } from "../../redux/api";

const userEmailFolderApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['EmailFolders']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchMailFolders: builder.query({
            query: ({  }) => `api/clientdocuments/mail/folders`,
            providesTags: (result, error, arg) => {
                return ['EmailFolders'];
            }
        })
    })
});

export const {
    useFetchMailFoldersQuery,
    useLazyFetchMailFoldersQuery
} = userEmailFolderApiEndpoints;
import React from "react";
import withProviders from "../../hooks/withProviders";
import UserDelegatesGrid from "../../features/userdelegatesgrid";
import EditUserDelegateModal from "../../features/userdelegatesgrid/components/EditUserDelegateModal";
import UserDelegatesFilterModal, { UserDelegatesFilterButton } from "../../features/userdelegatesgrid/components/UserDelegatesFilterModal";
import CreateUserDelegateModal, { CreateUserDelegateModalButton } from "../../features/userdelegatesgrid/components/CreateUserDelegateModal";
import { Main } from "../../components/utilities";
import { UserDelegatsPageSubHeader } from "../../components/utilities/PageSubHeader";
import { UserDelegatesDataProvider, useUserDelegatesData } from "../../features/userdelegatesgrid/hooks/UserDelegatesContext";
import { FilterProvider, UserDelegateSearchDefaultFilters, useFilterContext } from "../../hooks/FilterContext";

const UserDelegatesPage = () => {
    const [isFiltersShown, setIsFiltersShown] = React.useState(false);
    const [isCreateShown, setIsCreateShown] = React.useState(false);
    const { clearFilter, filter: {}, isFiltersApplied } = useFilterContext();
    const { clear, selected, selectDelegate } = useUserDelegatesData();

    return (
        <React.Fragment>
            <UserDelegatesFilterModal 
                handeClose={() => setIsFiltersShown(false)}
                show={isFiltersShown}
            />
            <CreateUserDelegateModal 
                handleClose={() => setIsCreateShown(false)}
                onCreatedEvent={() => clear()}
                show={isCreateShown}
            />
            <EditUserDelegateModal 
                show={selected !== null}
                delegate={selected}
                handleClose={() => selectDelegate(null)}
            />
            <Main className="d-flex flex-column">
                <UserDelegatsPageSubHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">

                        </div>
                        <div className="d-flex flex-fill justify-content-end align-items-center">
                            <CreateUserDelegateModalButton className="me-1" onClick={() => setIsCreateShown(true)} />
                            <UserDelegatesFilterButton onClick={() => setIsFiltersShown(true)} />
                        </div>
                    </div>
                </UserDelegatsPageSubHeader>
                <section className="px-4 my-3 h-100">
                    <UserDelegatesGrid />
                </section>
            </Main>
        </React.Fragment>
        
    );
};

export default withProviders([FilterProvider, { defaultFilters: UserDelegateSearchDefaultFilters }], UserDelegatesDataProvider)(UserDelegatesPage);
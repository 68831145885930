import { 
    useFetchClientFeeSignatoriesQuery,
    useUpdateClientFeeSignatoriesMutation
} from "../../../services/clientserviceterms";

const useClientFeeSignatories = (masterAccountId) => {

    const { data, error, isError, isFetching, isLoading, refetch } = useFetchClientFeeSignatoriesQuery({ masterAccountId });
    const [updateClientFeeSignatories, { }] = useUpdateClientFeeSignatoriesMutation();



    const updateClientFeeSignatoriesMappings = (mappings) => {
        return new Promise((resolve, reject) => {

            let clientIds = mappings.map(el => el.value);

            return updateClientFeeSignatories({masterAccountId, data: { clientIds } }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );

        });
    };

    return {
        signatories: data || [],
        updateClientFeeSignatoriesMappings
    }

};

export default useClientFeeSignatories;
import React from "react";
import { FormCheck } from "../../../components/forms";
import { GenericUserSelect, MasterAccountEmailSelect} from "../../../components/forms/selects"; 

const CommunicationPreferencesForm = ({ data, onFieldChanged }) => {
    if (!data) {
        return null;
    }

    const { masterAccountId } = data;
    return (
        <React.Fragment>
            <div className="row mb-2">
                <div className="col">
                    <FormCheck
                        type="checkbox"
                        label="Stop All Communications"
                        isChecked={data.isAllCommunicationsStopped}
                        onChange={(_) => onFieldChanged('isAllCommunicationsStopped', !data.isAllCommunicationsStopped)}
                    />
                </div>
                <div className="col">
                    <FormCheck
                        type="checkbox"
                        label="Secure Client Website"
                        isChecked={data.isDashboardEnabled}
                        onChange={(_) => onFieldChanged('isDashboardEnabled', !data.isDashboardEnabled)}
                    />
                </div>
                <div className="col">
                    <FormCheck
                        type="checkbox"
                        label="Mobile App"
                        isChecked={data.isMobileAppEnabled}
                        onChange={(_) => onFieldChanged('isMobileAppEnabled', !data.isMobileAppEnabled)}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-2 d-flex align-items-center">
                    <FormCheck
                        type="checkbox"
                        label="Messaging Contacts"
                        isChecked={data.isMessagingEnabled}
                        onChange={(_) => onFieldChanged('isMessagingEnabled', !data.isMessagingEnabled)}
                    />
                </div>
                <div className="col">
                    <GenericUserSelect
                        placeholder="Messaging recipients"
                        isMulti={true}
                        defaultValue={data.messagingRecipients}
                        onBlur={(values) => onFieldChanged('messagingRecipients', values.map(el => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-2 d-flex align-items-center">
                    <FormCheck
                        type="checkbox"
                        label="Email Notifications"
                        isChecked={data.isEmailNotificationsEnabled}
                        onChange={(_) => onFieldChanged('isEmailNotificationsEnabled', !data.isEmailNotificationsEnabled)}
                    />
                </div>
                <div className="col">
                    <MasterAccountEmailSelect
                        masterAccountId={masterAccountId}
                        placeholder="Email Notification recipients"
                        isMulti={true}
                        defaultValue={data.emailNotificationRecipients}
                        onBlur={(values) => onFieldChanged('emailNotificationRecipients', values.map(el => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-2 d-flex align-items-center">
                    <FormCheck
                        type="checkbox"
                        label="Email Market Updates"
                        isChecked={data.isEmailMarketUpdatesEnabled}
                        onChange={(_) => onFieldChanged('isEmailMarketUpdatesEnabled', !data.isEmailMarketUpdatesEnabled)}
                    />
                </div>
                <div className="col">
                    <MasterAccountEmailSelect
                        masterAccountId={masterAccountId}
                        placeholder="Email Market Updates recipients"
                        isMulti={true}
                        defaultValue={data.emailMarketUpdateRecipients}
                        onBlur={(values) => onFieldChanged('emailMarketUpdateRecipients', values.map(el => el.value))}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-2 d-flex align-items-center">
                    <FormCheck
                        type="checkbox"
                        label="Email Marketing"
                        isChecked={data.isEmailMarketingEnabled}
                        onChange={(_) => onFieldChanged('isEmailMarketingEnabled', !data.isEmailMarketingEnabled)}
                    />
                </div>
                <div className="col">
                    <MasterAccountEmailSelect
                        masterAccountId={masterAccountId}
                        placeholder="Email Marketing recipients"
                        isMulti={true}
                        defaultValue={data.emailMarketingRecipients}
                        onBlur={(values) => onFieldChanged('emailMarketingRecipients', values.map(el => el.value))}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default CommunicationPreferencesForm;
import { StandardTable } from "../../../components/tables";
import { useWrappersManagement } from "../hooks/WrappersManagementContext";
import WrapperTableErrorRow from "./WrappersTableErrorRow";
import WrappersTableRow from "./WrappersTableRow";

const WrappersTable = () => {

    const { data, isLoading, error, columns } = useWrappersManagement();

    return <StandardTable
        columns={columns}
        RowComponent={WrappersTableRow}
        ErrorRowComponent={WrapperTableErrorRow}
        data={data}
        isLoading={isLoading}
        error={error}
    />;
};

export default WrappersTable;
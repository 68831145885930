import { useEffect } from "react";
import FormSelect from "../FormSelect";
import { useLazyFetchHOAdminDesignationsQuery } from "../../../services/headofficeadmin";

const HeadOfficeMasterAccountDesignationsSelect = ({
    defaultValue,
    label,
    masterAccountId,
    onBlur,
    onChange,
    ...rest
}) => {

    const [trigger, { data, isFetching }] = useLazyFetchHOAdminDesignationsQuery();

    useEffect(() => {
        if (!masterAccountId) return;
        trigger({ masterAccountId });
    }, [masterAccountId, trigger]);

    return <FormSelect
        defaultValue={defaultValue}
        isDisabled={!masterAccountId}
        isLoadingOptions={isFetching}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        options={data ?? []}
        {...rest}
    />;
};

export default HeadOfficeMasterAccountDesignationsSelect;
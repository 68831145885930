import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { CurrencyInput } from "../../../components/forms";
import useIncomeColumn from "../hooks/useIncomeColumn";
import FormInfoBubbleWrapper from "./FormInfoBubbleWrapper";
import SectionHeader from "./SectionHeader";

const MaxWidthCell = styled(FormInfoBubbleWrapper)`
    max-width: 25ch;
`

const IncomeColumn = ({ clientId, clientName = null, index, propertyInfo, rowStart, column }) => {

    const [{
        patchCache,
        patchService,
    }, {
        client,
        isLoading
    }] = useIncomeColumn(clientId);

    return <>
        <span className={`row-start-${rowStart - 1} col-start-${column} mt-2 pe-2`}>
            {isLoading
                ? <Skeleton />
                : <SectionHeader className="mt-0">
                    {clientName ?? client?.clientName}
                </SectionHeader>}
        </span>

        {Object.keys(propertyInfo).map((property, propIndex) => <span className={`row-start-${parseInt(rowStart) + parseInt(propIndex)} col-start-${column}`} key={`${clientId}-${property}`}>
            {(isLoading || !client)
                ? <Skeleton />
                : <MaxWidthCell showBubble={(index === 0 && !propertyInfo[property].readOnly)}>
                    <CurrencyInput
                        className={`my-auto ${(clientId === 'joint' && !propertyInfo[property].showJoint) ? 'invisible' : ""}`}
                        value={client[property]}
                        onChange={(_, { floatValue }) => patchCache(property, floatValue)}
                        onBlur={(newValue) => patchService(property, newValue)}
                        decimalScale={0}
                        fixedDecimalScale
                        disabled={propertyInfo[property].readOnly}
                    />
                </MaxWidthCell>}
        </span>)}
    </>
}

export default IncomeColumn;
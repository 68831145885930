import { 
    
    useFetchClientServiceTermsQuery,
    useFetchServiceAgreementsQuery,
    usePatchClientServiceLevelAgreementMutation,
    useCreateClientServiceLevelAgreementMutation,
    useRenewTermsMutation

} from "../../../services/clientserviceterms";

const useClientServiceTerms = (masterAccountId) => {

    const { data : clientServiceTerms, error, isError, isFetching, isLoading, refetch } = useFetchClientServiceTermsQuery({ masterAccountId });
    const { data : serviceLevelAgreements } = useFetchServiceAgreementsQuery({ masterAccountId });
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchClientServiceLevelAgreementMutation();
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateClientServiceLevelAgreementMutation();
    const [renew, { error: renewError, isError: isErrorRenewing, isLoading: isRenewing }] = useRenewTermsMutation();

    const updateClientServiceAgreement = (clientServiceAgreementId, property, value) => {
        return new Promise((resolve, reject) => {

            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            return patch({ clientServiceAgreementId, masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const createSLA = (e) => {

        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            return create({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const renewTerms = (e, clientServiceLevelAgreementId) => {

        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            return renew({ clientServiceLevelAgreementId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        clientServiceTerms: clientServiceTerms || {},
        isError,
        error,
        reload: refetch,
        isLoading,
        serviceLevelAgreements : serviceLevelAgreements || [],
        updateClientServiceAgreement,
        createSLA,
        renewTerms
    }

};

export default useClientServiceTerms;
import { useFetchTransactionDesignationsQuery } from "../../../services/valuations/designationApiEndpoints"; 

const useDesignationDetails = (masterAccountId, listType) => {

    const { 
        data
    } = useFetchTransactionDesignationsQuery({masterAccountId, listType});


    return {
        designations : data || []
    };
};

export default useDesignationDetails;
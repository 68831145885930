import React from "react";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";
import { IconButton } from "../../../components/buttons";
import {
    CurrencyInput,
    FormInput,
    FormLabel,
    FormSelect,
    PercentageInput
} from "./../../../components/forms/";
import useClientFeeBreakdown from "./../hooks/useClientFeeBreakdown";

const WrappedFormLabel = styled(FormLabel)`
    text-wrap: normal !important;
`

const PortfolioBreakdown = ({ masterAccountId, clientServiceAgreementId, feeBreakdowns = [], loadingLines = 2 }) => {
    
    const feeTypes = [
        { label: "Fixed", value: 0 },
        { label: "Percent", value: 1 },
        { label: "No Fee", value: 2 },
    ];

    const {
        updateClientFeeBreakdown,
        updateFeeBreakdownValues,
        createClientFeeBreakdown,
        isCreating,
        deleteClientFeeBreakdown,
    } = useClientFeeBreakdown(masterAccountId);

    const onFieldChanged = (clientFeeBreakdownId, property, value) => updateClientFeeBreakdown(clientFeeBreakdownId, property, value);

    const onCreatePressed = (e) => createClientFeeBreakdown(e, clientServiceAgreementId);

    const onDeletePressed = (clientFeeBreakdownId) => deleteClientFeeBreakdown(clientFeeBreakdownId);

    const getTotalFromArray = (propName) => {
        if (feeBreakdowns && feeBreakdowns.length > 0) {
            return feeBreakdowns.reduce(function (a, b) {
                return a + b[propName];
            }, 0);
        }
    };

    const onPortfolioBreakdownValueChanged = (breakdown, newValue) => {
        let operations = [
            {
                op: "replace",
                path: `/portfolioBreakdownValue`,
                value: newValue,
            },
        ];

        // no fee - just update the portfolio breakdown value
        if (breakdown.feeType == 2) {
            // do nothing?
        } else if (breakdown.feeType == 0) {
            // we don't want to overwrite the fixed fee - again do nothing
        } else if (breakdown.feeType == 1) {
            //the fee type is percent - we want to recalculate the fee based on the fee rate to 0 DP
            var newAdviceFee = Math.round((breakdown.feeRate / 100) * newValue);
            operations.push({
                op: "replace",
                path: `/annualAdviceFee`,
                value: newAdviceFee,
            });
        }

        updateFeeBreakdownValues(breakdown.clientFeeBreakdownId, operations);
    };

    const onFeeTypeChanged = (breakdown, newValue) => {
        let operations = [{ op: "replace", path: `/feeType`, value: newValue }];

        // no fee - set the advice fee to 0 and the fee rate to 0
        if (newValue == 2) {
            operations.push({
                op: "replace",
                path: `/annualAdviceFee`,
                value: 0,
            });
            operations.push({ op: "replace", path: `/feeRate`, value: 0 });
        } else if (newValue == 0) {
            // we don't want to overwrite the fixed fee - again do nothing
            operations.push({ op: "replace", path: `/annualAdviceFee`, value: Math.round(breakdown.feeRate) });
        } else if (newValue == 1) {
            //the fee type is percent - we want to recalculate the fee based on the fee rate to 0 DP
            var newAdviceFee = Math.round((breakdown.feeRate / 100) * breakdown.portfolioBreakdownValue);
            operations.push({
                op: "replace",
                path: `/annualAdviceFee`,
                value: newAdviceFee,
            });
        }

        updateFeeBreakdownValues(breakdown.clientFeeBreakdownId, operations);
    };

    const onFeeRateChanged = (breakdown, newValue) => {
        let operations = [];

        if(breakdown.feeType == 0){
            operations.push({
                op: "replace",
                path: `/annualAdviceFee`,
                value: Math.round(newValue),
            });

            operations.push({
                op: "replace",
                path: `/feeRate`,
                value: newValue,
            });
        }
        else if (breakdown.feeType == 1) {
            //the fee type is percent - we want to recalculate the fee based on the fee rate to 0 DP
            operations.push({
                op: "replace",
                path: `/feeRate`,
                value: newValue,
            });

            var newAdviceFee = Math.round((newValue / 100) * breakdown.portfolioBreakdownValue);
            operations.push({
                op: "replace",
                path: `/annualAdviceFee`,
                value: newAdviceFee,
            });
        }

        // only update if it has things to change
        if (operations.length > 0) {
            updateFeeBreakdownValues(
                breakdown.clientFeeBreakdownId,
                operations
            );
        }
    };

    const onAdviceFeeChanged = (breakdown, newValue) => {
        let operations = [
            { op: "replace", path: `/annualAdviceFee`, value: newValue },
        ];

        if (breakdown.feeType == 0) {
            //the fee type is percent - we want to recalculate the fee rate
            operations.push({
                op: "replace",
                path: `/feeRate`,
                value: newValue,
            });
        }

        if (breakdown.feeType == 1) {
            //the fee type is percent - we want to recalculate the fee rate
            var newFeeRate = ((newValue/breakdown.portfolioBreakdownValue)*100).toFixed(2);
            operations.push({
                op: "replace",
                path: `/feeRate`,
                value: newFeeRate,
            });
        }

        // only update if it has things to change
        if (operations.length > 0) {
            updateFeeBreakdownValues(
                breakdown.clientFeeBreakdownId,
                operations
            );
        }
    };

    return (
        <div>
            {(feeBreakdowns.length > 0) && (
                <div className="row mb-2 mt-4">
                    <div className="col"><WrappedFormLabel>Portfolio Breakdown</WrappedFormLabel></div>
                    <div className="col-1"><WrappedFormLabel>Custody</WrappedFormLabel></div>
                    <div className="col-2"><WrappedFormLabel>Portfolio Value</WrappedFormLabel></div>
                    <div className="col-1"><WrappedFormLabel>Fee Type</WrappedFormLabel></div>
                    <div className="col-1"><WrappedFormLabel>Fee Rate</WrappedFormLabel></div>
                    <div className="col-1"><WrappedFormLabel>Custody & Advice Fee</WrappedFormLabel></div>
                    <div className="col-md-auto" style={{minWidth:"46px"}}>
                        <IconButton
                            icon="fa-plus"
                            variant="success"
                            onClick={onCreatePressed}
                        />
                    </div>
                </div>
            )}
            {(isCreating === true) &&  <div className="row mb-4 mt-4">
                    <div className="col">
                        <Skeleton count={2} />
                    </div>
                </div>}
            {(isCreating === false) && feeBreakdowns && feeBreakdowns.length === 0 && (
                <div className="row mb-4 mt-4">
                    <div className="col">
                        There are no portfolio breakdown records for this
                        agreement. You can click{" "}
                        <a href="#" onClick={onCreatePressed}>
                            here
                        </a>{" "}
                        to create one.
                    </div>
                </div>
            )}
            {feeBreakdowns.length > 0 &&
                feeBreakdowns.map((el, i) => (
                    <div className="row mb-2" key={el.clientFeeBreakdownId}>
                        <div className="col">
                            <FormInput
                                value={el.portfolioBreakdown}
                                placeholder="Description"
                                onBlur={(value) =>
                                    onFieldChanged(
                                        el.clientFeeBreakdownId,
                                        "portfolioBreakdown",
                                        value
                                    )
                                }
                            />
                        </div>
                        <div className="col-1">
                            <PercentageInput
                                value={el?.ownCustodyRate ?? 0}
                                onBlur={(value) =>
                                    onFieldChanged(
                                        el.clientFeeBreakdownId,
                                        "ownCustodyRate",
                                        value
                                    )
                                }
                            />
                        </div>

                        <div className="col-2">
                            <CurrencyInput
                                value={el?.portfolioBreakdownValue ?? 0}
                                onBlur={(value) =>
                                    onPortfolioBreakdownValueChanged(el, value)
                                }
                                decimalScale={0}
                            />
                        </div>

                        <div className="col-1">
                            <FormSelect
                                options={feeTypes}
                                defaultValue={el.feeType}
                                isMulti={false}
                                onBlur={(selection) =>
                                    onFeeTypeChanged(el, selection.value)
                                }
                            />
                        </div>
                        <div className="col-1">
                            {el.feeType == 1 ? (
                                <PercentageInput
                                    value={el?.feeRate ?? 0}
                                    onBlur={(value) =>
                                        onFeeRateChanged(el, value)
                                    }
                                    disabled={el.feeType == 2}
                                />
                            ) : (
                                <CurrencyInput
                                    value={el?.feeRate ?? 0}
                                    onBlur={(value) =>
                                        onFeeRateChanged(el, value)
                                    }
                                    disabled={el.feeType == 2}
                                />
                            )}
                        </div>
                        <div className="col-1">
                            <CurrencyInput
                                value={el?.annualAdviceFee ?? 0}
                                onBlur={(value) =>
                                    onAdviceFeeChanged(el, value)
                                }                                
                                decimalScale={0}
                            />
                        </div>
                        <div className="col-md-auto">
                            <IconButton
                                icon="fa-trash-can"
                                variant="danger"
                                onClick={() =>
                                    onDeletePressed(el.clientFeeBreakdownId)
                                }
                            />
                        </div>
                    </div>
                ))}
            {feeBreakdowns.length > 0 && (
                <div className="row mb-2">
                    <div className="col"></div>
                    <div className="col-1"></div>
                    <div className="col-2" style={{ textAlign: "right" }}>
                        <p>
                            Total Portfolio Value :<NumericFormat
                                        value={getTotalFromArray("portfolioBreakdownValue")}
                                        thousandSeparator={true}
                                        displayType="text"
                                        decimalScale={2}
                                        prefix={" £"}
                                        style={{ paddingRight:"14px" }}
                                    />
                        </p>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-2" style={{ textAlign: "right" }}>
                        <p>
                            Total Fee : 
                            <NumericFormat
                                        value={getTotalFromArray("annualAdviceFee")}
                                        thousandSeparator={true}
                                        displayType="text"
                                        decimalScale={2}
                                        prefix={" £"}
                                        style={{ paddingRight:"14px" }}
                                    />
                        </p>
                    </div>
                    <div className="col-md-auto" style={{minWidth:"46px"}}>
                         &nbsp;
                    </div>
                </div>
            )}
        </div>
    );
};

export default PortfolioBreakdown;

import { useState } from "react";
import { AccordionBody, AccordionHeader, ThemedSpan } from "../../../components/utilities";
import { AntiMoneyLaunderingClientStatuses, AntiMoneyLaunderingEvidence, CreateAMLEvidenceModal } from "../components";
import { useAntiMoneyLaundering } from "../hooks/useAntiMoneyLaundering";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { Spinner } from "../../../components/loaders";

const documentTypeOptions = [
    { label: "Electronic Verification", value: 0 },
    { label: "Passport", value: 1 },
    { label: "Driving Licence", value: 2 },
    { label: "Other Photo ID", value: 3 },
    { label: "Home Visit", value: 4 },
    { label: "Bank Statement", value: 5 },
    { label: "Utility Bill", value: 6 },
];

const proofOfOptions = [
    "Photo ID",
    "Photo ID or Address",
    "Address",
    "Name & Address"
];

const AntiMoneyLaunderingAccordion = ({ renderBody }) => {
    const {
        isLoading,
        isFetching,
        isError,
        error,
        refetch,
        clientStatuses,
        evidence,
        amlStatus,
        isValid,
        removeEvidence
    } = useAntiMoneyLaundering();

    const [showModal, setShowModal] = useState(false);

    const onShowModal = () => setShowModal(true);
    const onCloseModal = () => setShowModal(false);

    return <>
        <AccordionHeader>
            {isFetching
                ? <span>Anti-Money Laundering</span>
                : <ThemedSpan className={!isValid ? "fw-bold" : null} variant={!isValid ? "danger" : "primary"}>Anti-Money Laundering ({amlStatus})</ThemedSpan>}
        </AccordionHeader>
        <AccordionBody>
            {renderBody && <>
                <CreateAMLEvidenceModal
                    show={showModal}
                    onClose={onCloseModal}
                    documentTypeOptions={documentTypeOptions}
                />
                {isError
                    ? <ErrorMessageWrapper>
                        <ErrorMessage
                            error={error}
                            onRetry={refetch}
                        />
                    </ErrorMessageWrapper>
                    : isLoading
                        ? <Spinner />
                        : (evidence?.length > 0
                            ? <>
                                <AntiMoneyLaunderingClientStatuses clientStatuses={clientStatuses} />
                                <AntiMoneyLaunderingEvidence
                                    evidence={evidence}
                                    openCreateModal={onShowModal}
                                    documentTypeOptions={documentTypeOptions}
                                    proofOfOptions={proofOfOptions}
                                    remove={removeEvidence}
                                />
                            </>
                            : <>
                                No Anti-Money Laundering evidence has been provided. Click <button className="anchor" onClick={onShowModal}>here</button> to add some.
                            </>)}
            </>}
        </AccordionBody>
    </>;
}

export default AntiMoneyLaunderingAccordion;
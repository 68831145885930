import { wealthProApi } from '../../redux/api';

const projectsManagementApiEndpoints = wealthProApi
.enhanceEndpoints({ })
.injectEndpoints({
    endpoints: (builder) => ({
        fetchProjects: builder.query({
            query: (data) => ({
                url: `api/clientprojects/projects`,
                method: 'POST',
                body: data
            })
        }),
        fetchSummaryByAdvisers: builder.query({
            query: (data) => ({
                url: `api/clientprojects/projects/groupings/adviser`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useFetchProjectsQuery,
    useFetchSummaryByAdvisersQuery,
    useLazyFetchProjectsQuery,
    useLazyFetchSummaryByAdvisersQuery
} = projectsManagementApiEndpoints;
import _ from "lodash";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { NotAuthenticatedPage, NotAuthorisedPage, SplashPage } from "../pages";

export const RouteAuthRoleGuard = ({ children, unauthorisedUrl = "/unauthorised", requiredRoles = [] }) => {
    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;

    if (requiredRoles.length > 0 && !_.some(roles, item => requiredRoles.includes(item))) {
        return <Navigate to={`${unauthorisedUrl}?redirect=${encodeURIComponent(window.location.href)}`} replace={true} />
    }

    return children;
};

const ProtectedRoute = ({ children }) => {
    const auth = useAuth();
    const { isAuthenticated, isLoading, signinRedirect } = auth;

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            signinRedirect();
        }
    }, [ isLoading ])

    if (!isAuthenticated) {
        return isLoading
            ? (<SplashPage />)
            : (<NotAuthenticatedPage />)
    }

    return children;
};

export default ProtectedRoute;
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

//import React from "react";
import { v4 as uuid } from "uuid";
import ClientProjectSearch from "../clientprojectsearch";
import Button from "../../components/buttons";
import toast from "react-hot-toast";
import { BasicToast } from "../../components/toasts";
import { useClientContext } from "../../hooks/ClientContext";
//import { useLazyGetReportOpenUrlQuery } from "../../services/clientreports";
import { DateInput, FormCheck, FormInput, FormSelect } from "../../components/forms";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
//import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useForm, Controller } from "react-hook-form";
import { useTheme } from "styled-components";
//import ClientProjectSearch from "../clientprojectsearch";
import useUploadDocument  from "./hooks/useUploadDocument";
import{
    DocumentFunctionsSelect,
    DocumentTypesSelect,
    //GenericUserSelect
    //ReportTemplateSelect
} from "../../components/forms/selects";
import { DocumentTypes } from "../../components/forms/selects/DocumentTypesSelect";
import SelectFileUploadOptionModal from "./components/SelectFileUploadOptionModal";
import { ThemedIcon } from "../../components/utilities";
import { niceBytes } from "../../helpers/fileHelpers";
//import ReportTemplateSelect from "./components/ReportTemplateSelect";

export const UploadDocumentModalButton = ({ className, variant = "success", ...rest }) => {
    return <div className={className}>
        <Button variant={variant} {...rest}>Upload Document</Button>
    </div>
};

export const uploadProps = {
    maxFiles: 1,
    accept: {
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
        "text/csv": [],
        "image/gif": [],
        "image/jpeg": [],
        "image/png": [],
        "application/pdf": [],
        "application/vnd.ms-excel": [],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
        "application/xml": [],
        "text/plain": [],
        "video/mp4": [],
        "video/mpeg": [],
        "video/x-msvideo": [],
        "application/vnd.ms-outlook": [],
        "message/rfc822": []
    }
};

const UploadDocumentModal = ({ 
    allowProjectSelect = true,
    defaultProjectId = null,
    docExtension = null,
    displayToast = true,
    fileFormName = "attachment",
    handleClose, 
    onCreateProjectClicked, 
    onDocumentUploaded,
    onDocumentUploadFailed,
    show, 
    size = "lg",
    ...rest 
}) => {
    const theme = useTheme(); // NOTE: we need the theme property to pass it to our confirm pop-up
    const { account: { masterAccountId } } = useClientContext();
    const { checkFileNameExists, isCheckingFile, createResults: { isLoading }, defaultValues, upload } = useUploadDocument(masterAccountId, defaultProjectId);
    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });
    const [fileData, setFileData] = useState([]);
    const [fileDataIcon, setFileDataIcon] = useState('fa-file');
    const [checkedFileName, setCheckedFileName] = useState('');
    const [isFileNameValid, setIsFileNameValid] = useState(false);
    const [isFileNameValidOverride, setIsFileNameValidOverride] = useState(false);

    const checkFileNameAsync = async (fileName) => {
        const checkResult = await checkFileNameExists(fileName);
        
        if (!checkResult) {
            setIsFileNameValid(false);
            return false;
        }

        const { fileExists } = checkResult;
        setCheckedFileName(fileName);
        setIsFileNameValid(fileExists ? false : true);
        setIsFileNameValidOverride(false);
        return true;
    };

    const convertToFormData = (data, file) => {
        const newFormData = new FormData();
        const { conflictBehaviour, documentDescription, documentFunctionId, documentType, fileName, fileSize, isClientVisible, masterAccountId, projectId } = data;

        newFormData.append(fileFormName, file, file.name);

        if (conflictBehaviour && conflictBehaviour !== null) {
            newFormData.append('conflictBehaviour', conflictBehaviour);
        }

        if (documentDescription && documentDescription.length > 0) {
            newFormData.append('documentDescription', documentDescription);
        }
        
        newFormData.append('documentFunctionId', documentFunctionId);
        newFormData.append('documentType', documentType);
        newFormData.append('fileName', fileName);
        newFormData.append('fileSize', fileSize);
        newFormData.append('isClientVisible', isClientVisible);
        newFormData.append('masterAccountId', masterAccountId);

        if (projectId && projectId !== null) {
            newFormData.append('projectId', projectId);
        }

        return newFormData;
    };

    const getDocumentTypeFromMimeType = (mimeType) => DocumentTypes.find(el => el.mimeTypes.includes(mimeType));

    const onClearFiles = () => setFileData([]);

    const onClose = () => {
        reset(defaultValues);
        setFileData([]);
        setFileDataIcon('fa-file');
        setCheckedFileName('');
        setIsFileNameValid(false);
        setIsFileNameValidOverride(false);
        handleClose();
    };

    const onDrop = React.useCallback(async (acceptedFiles) => {
        const { name } = acceptedFiles[0];
        const isFileNameValid = await checkFileNameAsync(name);

        if (isFileNameValid === true) {
            setFileData([...fileData, ...acceptedFiles]);
        }
    }, []);

    const onFileNameInputBlur = async (value) => {
        if (_.isEqual(value, checkedFileName)) {
            return;
        }
        await checkFileNameAsync(value);
    };

    const onSubmit = async (data) => {
        const newFormData = convertToFormData(data, fileData[fileData.length - 1]);
        toast.promise(upload(newFormData), {
            loading: `Your file is now being processed...`,
            success: (data) => {
                if (onDocumentUploaded && typeof(onDocumentUploaded) === 'function') {
                    onDocumentUploaded(data);
                }

                onClose();
                return `Your file has now been uploaded.`;
            },
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                if (onDocumentUploadFailed && typeof(onDocumentUploadFailed) === 'function') {
                    onDocumentUploadFailed(err);
                }

                onClose();
                return `There was a problem with uploading the file to the system. ${display}`;
            }
        });
    };

    useEffect(() => {
        if (fileData.length < 1) {
            return;
        }

        const myNewFile = fileData[fileData.length - 1];
        const fileTypeData = getDocumentTypeFromMimeType(myNewFile.type);

        if (!fileTypeData) {
            return;
        }

        setFileDataIcon(fileTypeData.icon ?? 'fa-file');
        reset({
            ...defaultValues,
            fileName: myNewFile.name,
            fileSize: myNewFile.size,
            documentType: fileTypeData.value
        });
    }, [ fileData ]);

    // setup the file dropzone for uploading & checking file validity
    const { getRootProps, getInputProps } = useDropzone({ ...uploadProps, onDrop });
    const { name: fileName, size: fileSize, type: fileType } = fileData.length > 0 ? fileData[0] : { };

    return (
        <Modal size={size} centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Upload a Document</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    {isCheckingFile === true && (
                        <div className="d-flex flex-fill justify-content-center align-items-center py-4">
                            <p className="m-0">Loading... Checking Filename is okay to use...</p>
                        </div>
                    )}
                    {isCheckingFile === false && fileData.length === 0 && (
                        <div {...getRootProps({ className: 'dropzone d-flex px-3 py-2 border border-3 border-opacity-10' })}>
                            <input {...getInputProps()} />
                            <div className="d-flex flex-fill justify-content-center align-items-center py-4">
                                <p className="m-0">Drag 'n' drop some files here, or click to select files</p>
                            </div>
                        </div>
                    )}
                    {isCheckingFile === false && fileData.length > 0 && (
                        <React.Fragment>
                            <div className="d-flex p-3 border border-3 border-opacity-10 mb-3">
                                <div className="d-flex justify-content-center align-items-center">
                                    <ThemedIcon icon={fileDataIcon} size="2xl" variant="primary" />
                                </div>
                                <p className="flex-fill m-0 px-3 lh-1">
                                    <span>{fileName}</span>
                                    <br />
                                    <span className="text-muted fs-smallest">{niceBytes(fileSize)}</span>
                                </p>
                                <div className="d-flex justify-content-center align-items-center">
                                    <ThemedIcon className="has-pointer" icon="fa-xmark" size="2xl" variant="danger" onClick={onClearFiles} />
                                </div>
                            </div>
                            {allowProjectSelect === true && (
                                <div className="row mb-3">
                                    <div className="col">
                                        <Controller
                                            name="projectId"
                                            control={control}
                                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                                <ClientProjectSearch
                                                    buttonSize="sm"
                                                    label="Project"
                                                    labelPosition="top"
                                                    formError={errors?.projectId?.message} 
                                                    value={value}
                                                    onProjectCreated={(project) => setValue('projectId', project.projectId)}
                                                    onProjectSelected={(_, project) => setValue('projectId', project.projectId)}
                                                    {...rest}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row mb-3">
                                <div className="col">
                                    <Controller
                                        name="fileName"
                                        control={control}
                                        rules={{ 
                                            required: 'A file name must be given.',
                                            maxLength: {
                                                value: 1024,
                                                message: 'File name cannot be more than 1024 characters.'
                                            } 
                                        }}
                                        render={({ field: { ref, onBlur, ...rest } }) => (
                                            <FormInput 
                                                label="File Name" 
                                                disableAnimations={true} 
                                                errorAllowRetry={false}
                                                errorMessage={errors?.fileName?.message}
                                                onBlur={onFileNameInputBlur}
                                                {...rest} 
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            {isFileNameValid === false && (
                                <div className="d-flex flex-column p-3 mb-3 border border-2 border-warning border-opacity-10">
                                    <p className="fs-6">The file name you have set already exists for this client. You will have to choose to either rename the file or replace the existing file with this one.</p>
                                    <Controller
                                        name="conflictBehaviour"
                                        control={control}
                                        rules={{ 
                                            required: 'You must select a valid conflict behavior.',
                                        }}
                                        render={({ field: { ref, value, onChange, ...rest } }) => (
                                            <FormSelect 
                                                label={null}
                                                className="mb-2"
                                                disableAnimations={true} 
                                                errorAllowRetry={false}
                                                errorMessage={errors?.conflictBehaviour?.message}
                                                defaultValue={value}
                                                options={[
                                                    { label: 'Auto Rename', value: 2 },
                                                    { label: 'Replace Existing', value: 1 },
                                                ]}
                                                onChange={(values) => {
                                                    setValue('conflictBehaviour', values.value)
                                                    setIsFileNameValidOverride(values.value != null);
                                                }}
                                                {...rest} 
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            <div className="row mb-3">
                                <div className="col">
                                    <Controller
                                        name="documentDescription"
                                        control={control}
                                        rules={{ 
                                            maxLength: {
                                                value: 2048,
                                                message: 'Document description cannot be more than 2048 characters.'
                                            } 
                                        }}
                                        render={({ field: { ref, ...rest } }) => (
                                            <FormInput 
                                                label="Document Description" 
                                                disableAnimations={true} 
                                                errorAllowRetry={false}
                                                errorMessage={errors?.documentDescription?.message}
                                                {...rest} 
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <Controller
                                        name="documentType"
                                        control={control}
                                        rules={{ required: "A Document Type must be selected." }}
                                        render={({ field: { ref, value, onChange, ...rest } }) => (
                                            <DocumentTypesSelect
                                                label="Document Type"
                                                isDisabled={true}
                                                defaultValue={value}
                                                errorMessage={errors?.documentType?.message} 
                                                errorAllowRetry={false}
                                                {...rest}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        name="documentFunctionId"
                                        control={control}
                                        rules={{ required: "A Document Function must be selected." }}
                                        render={({ field: { ref, value, onChange, ...rest } }) => (
                                            <DocumentFunctionsSelect
                                                label="Document Function"
                                                defaultValue={value}
                                                errorMessage={errors?.documentFunctionId?.message} 
                                                errorAllowRetry={false}
                                                onChange={(values) => setValue('documentFunctionId', values.value)}
                                                {...rest}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row-reverse">
                                <Controller 
                                    name="isClientVisible"
                                    control={control}
                                    render={({ field: { ref, value, onChange, ...rest } }) => (
                                        <FormCheck
                                            type="checkbox"
                                            label="Client Readable"
                                            disableAnimations={true}
                                            isChecked={value}
                                            onChange={(isChecked) => {
                                                setValue('isClientVisible', isChecked);
                                            }}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" disabled={ isLoading === true || (isFileNameValid === false && isFileNameValidOverride === false) }>Upload</Button>
                    <Button variant="light" onClick={onClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default UploadDocumentModal;
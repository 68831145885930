import { useCallback, useEffect, useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchFinalSalaryArrangementsQuery, useFetchInvestmentsSectionQuery, useFetchPensionArrangementsQuery, usePatchNotesMutation } from "../../../services/clientfactfind";

const useInvestments = () => {

    const { id } = useClientContext();
    const {
        data: investmentsSection,
        isError: isErrorInvestmentsSection,
        isLoading: isLoadingInvestmentsSection,
        error: investmentsSectionError,
        refetch
    } = useFetchInvestmentsSectionQuery({ masterAccountId: id });

    const { hasFinalSalaryArrangements } = useFetchFinalSalaryArrangementsQuery({ masterAccountId: id }, {
        selectFromResult: r => ({ hasFinalSalaryArrangements: r?.data?.length > 0 })
    });
    const { hasPensionArrangements } = useFetchPensionArrangementsQuery({ masterAccountId: id }, {
        selectFromResult: r => ({ hasPensionArrangements: r?.data?.length > 0 })
    });

    const [triggerPatchNotes] = usePatchNotesMutation();

    const updateNotes = (notesId, newText) => {
        return new Promise((resolve, reject) => {
            return triggerPatchNotes({ notesId, newText })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const [isFetchingInvestmentsSection, setIsFetchingInvestmentsSection] = useState(false);

    const refetchInvestments = useCallback((e) => {
        setIsFetchingInvestmentsSection(true);
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch()
            .then(_ => setIsFetchingInvestmentsSection(false));
    }, [refetch]);

    // Manual refetching on load
    useEffect(() => {
        refetchInvestments();
    }, [refetchInvestments])

    return {
        investmentsSection: investmentsSection || {},
        isErrorInvestmentsSection,
        investmentsSectionError,
        isLoadingInvestmentsSection: isLoadingInvestmentsSection || isFetchingInvestmentsSection,
        hasFinalSalaryArrangements,
        hasPensionArrangements,
        updateNotes,
        refetchInvestments
    };
};

export default useInvestments;
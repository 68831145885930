import { wealthProApi } from '../../redux/api';

const documentFunctionEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["documentFunctions"]
}).injectEndpoints({
    endpoints: (builder) => ({ 
        fetchDocumentFunctions: builder.query({
            query: ({ fetchType = "List" }) => `api/clientdocuments/documentfunctions?fetchType=${fetchType}`,
            providesTags: (result, error, arg) => {
                return ['documentFunctions'];
            }
        })
    })
});

export const {
    useFetchDocumentFunctionsQuery,
    useLazyFetchDocumentFunctionsQuery

} = documentFunctionEndpoints;
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import { useClientDocuments } from "../../../hooks/ClientDocumentsContext";
import useOpenDocument from "../../../hooks/useOpenDocument";
import {
    ClientDocumentTableRowBaseCell,
    ClientDocumentsIsClientReadableColumn,
    ClientDocumentsTableRowActionCol,
    ClientDocumentsTypeTableColumn,
    ClientDocumentsUploadedTableColumn
} from "./ClientDocumentsTableRowColumns";
import EditClientDocumentModal from "./EditClientDocumentModal";

const ClientDocumentsTableRow = ({ data, index, style }) => {
    const navigate = useNavigate();
    const { height } = style;
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const { isFetching, selectedRow, setSelectedRow } = useClientDocuments();
    const isLoading = index >= data.length || isFetching;

    const handleOpenDocument = (e, documentId, fileName) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        if (isOpening) return;

        openDocument(documentId, fileName);
    };

    const handleOpenProject = (e, projectId) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        navigate(`../projects?projectId=${projectId}`, { relative: 'path' });
    };

    // do not return a data row when the data has not yet been collected from the backend
    if (isLoading === true) {
        return <TableLoadingRow style={{ height }} />
    }

    // NOTE: moved this due to accessing a null/loading row causing a react crash/error
    const {
        documentId,
        documentType,
        typeDescription,
        functionDescription,
        fileName,
        documentDescription,
        projectDescription,
        projectId,
        clientReadable,
        uploadedByName,
        dateUploaded
    } = data[index];

    return (
        <tr style={{ height }}>
            <EditClientDocumentModal
                show={selectedRow === documentId}
                onHide={() => setSelectedRow(null)}
                document={data[index]}
            />
            {/* Document Type */}
            <ClientDocumentsTypeTableColumn typeDescription={typeDescription} documentType={documentType} />
            {/* Document Function */}
            <ClientDocumentTableRowBaseCell className="text-truncate" width="12.5%">
                <span>{functionDescription}</span>
            </ClientDocumentTableRowBaseCell>
            {/* Document FileName */}
            <ClientDocumentTableRowBaseCell className="text-truncate" width="17.5%">
                <a href={`open?id=${documentId}`} onClick={(e) => handleOpenDocument(e, documentId, fileName)}>{fileName}</a>
            </ClientDocumentTableRowBaseCell>
            {/* Document Description */}
            <ClientDocumentTableRowBaseCell className="text-truncate" width="25%">
                <span>{documentDescription}</span>
            </ClientDocumentTableRowBaseCell>
            {/* Project Select */}
            <ClientDocumentTableRowBaseCell className="text-truncate" width="25%">
                <a href={`../projects?projectId=${projectId}`} onClick={(e) => handleOpenProject(e, projectId)}>{projectDescription}</a>
            </ClientDocumentTableRowBaseCell>
            {/* Client Readable */}
            <ClientDocumentsIsClientReadableColumn clientReadable={clientReadable} />
            {/* Date uploaded */}
            <ClientDocumentsUploadedTableColumn
                dateUploaded={dateUploaded}
                uploadedByName={uploadedByName}
            />
            {/* Edit Column */}
            <ClientDocumentsTableRowActionCol
                selectDocument={() => setSelectedRow(documentId)}
            />
            {/* Action Column */}
            {/* <ClientDocumentsTableRowActionCol 
                documentId={documentId}
                openDocument={openDocument}
                userReadable={userReadable}
                clientReadable={clientReadable}
                updateClientReadable={updateClientReadable}
                setSelectedRow={handleSelectRow}
            /> */}
        </tr>
    );
};

export default ClientDocumentsTableRow;
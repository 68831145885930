import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { GridWrapper, IconButton } from "../../../components";
import { CurrencyInput, FormLabel, FormSelect } from "../../../components/forms";
import { patchReplace } from "../../../helpers/patchDoc";
import { useBizSheet } from "../contexts/BizSheetContext";
import { useInstruction } from "../contexts/InstructionContext";
import useReferenceSelection from "../hooks/useReferenceSelection";

const InvestmentCash = ({
    index,
    hasTaxReclaim,
    item,
    isLoading,
    patchRow,
    bulkPatchRow,
    realTimePatchRow,
    deleteRow,
}) => {
    const [, { designations }] = useBizSheet();
    const [, { realTimeInstruction }, { showTaxReclaim }] = useInstruction();

    const [realTimeClientId, setRealTimeClientId] = useState();

    useEffect(() => {
        if (item?.clientId == null)
            return;
        setRealTimeClientId(item.clientId)
    }, [item?.clientId])

    const {
        isFetchingProviders,
        isUninitializedProviders,
        providerOptions,
        custodyOptions,
        realTimeProviderRef,
        setRealTimeProviderRef,
        addCustomProvider,
        addCustomCustodian,
    } = useReferenceSelection({
        providerRef: item?.providerRef,
        custodyRef: item?.custodyRef,
        patch: (operations) => bulkPatchRow(item?.id, operations, index),
        realTimeDesignationId: realTimeClientId,
        hasLoaded: item != null,
        isInvestmentCash: true
    });

    const realTimeItem = realTimeInstruction?.invest?.investmentCash?.[index];

    // Real-time update of cash tax reclaim
    useEffect(() => {
        const updateRealTimeTimeout = setTimeout(() => {
            if (!showTaxReclaim) {
                realTimePatchRow("taxReclaim", 0, index);
                return;
            }

            const newTaxReclaim = (realTimeItem?.cashAmount ?? 0) / 5;

            if (newTaxReclaim !== realTimeItem?.taxReclaim)
                realTimePatchRow("taxReclaim", newTaxReclaim, index);
        }, 150);

        return () => clearTimeout(updateRealTimeTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, realTimeItem?.cashAmount, realTimePatchRow, showTaxReclaim]);

    // OnBlur update of cash tax reclaim
    useEffect(() => {
        const updateTimeout = setTimeout(() => {
            if (!showTaxReclaim) {
                patchRow(item.id, "taxReclaim", 0, index);
                return;
            }

            const newTaxReclaim = (realTimeItem?.cashAmount ?? 0) / 5;

            if (newTaxReclaim !== realTimeItem?.taxReclaim)
                patchRow(item.id, "taxReclaim", newTaxReclaim, index);
        }, 150);

        return () => clearTimeout(updateTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, item.id, patchRow, realTimeItem?.cashAmount, realTimePatchRow, showTaxReclaim]);

    return <>
        <span className="col-start-1">
            {isLoading
                ? <Skeleton />
                : <FormSelect
                    className={"col-start-1 align-self-end"}
                    options={designations ?? []}
                    defaultValue={item?.clientId}
                    onChange={(selection) => setRealTimeClientId(selection.value)}
                    onBlur={(selection) => patchRow(item.id, "clientId", selection.value, index)}
                />}
        </span>

        {isLoading
            ? <Skeleton />
            : <FormSelect
                options={providerOptions}
                defaultValue={item?.providerRef}
                onCreateOption={addCustomProvider}
                onChange={(selection) => setRealTimeProviderRef(selection.value)}
                onBlur={(selection) => new Promise((resolve, reject) => {
                    // If custodyRef is still in the new custodian options list, don't update it
                    // If there is only one option in the new custodian options list, update custodyRef to that option
                    // If there are multiple (or 0) options in the new custodian options list, update custodyRef to null
                    if (custodyOptions.some(opt => opt.value === item?.custodyRef))
                        return patchRow(item.id, "providerRef", selection.value.toUpperCase(), index)
                            .then(resolve, reject);

                    if (custodyOptions.length === 1)
                        return bulkPatchRow(item.id, [
                            patchReplace("providerRef", selection.value.toUpperCase()), patchReplace("custodyRef", custodyOptions[0].value.toUpperCase())
                        ], index).then(resolve, reject);

                    return bulkPatchRow(item.id, [
                        patchReplace("providerRef", selection.value.toUpperCase()), patchReplace("custodyRef", selection.value.toUpperCase())
                    ], index).then(resolve, reject);
                })}
                isLoadingOptions={isFetchingProviders}
                isDisabled={realTimeClientId == null || isFetchingProviders || isUninitializedProviders}
                isCreateable
            />}

        {isLoading
            ? <Skeleton />
            : <FormSelect
                options={custodyOptions}
                defaultValue={custodyOptions.length === 1 ? custodyOptions[0].value : item?.custodyRef}
                onCreateOption={addCustomCustodian}
                onBlur={(selection) => patchRow(item.id, "custodyRef", selection.value.toUpperCase(), index)}
                isLoadingOptions={isFetchingProviders}
                isDisabled={realTimeClientId == null || isFetchingProviders || isUninitializedProviders || realTimeProviderRef == null}
                isCreateable
            />}

        {isLoading
            ? <Skeleton />
            : <CurrencyInput
                value={item.cashAmount}
                onChange={(_, sourceInfo) => realTimePatchRow("cashAmount", sourceInfo.floatValue, index)}
                onBlur={(amount) => patchRow(item.id, "cashAmount", amount, index)}
                allowNegative={false}
            />}

        <IconButton
            icon={"fa-trash"}
            className={"align-self-center"}
            variant="danger"
            onClick={() => deleteRow(item.id, item?.cashAmount, index)}
            disabled={isLoading}
        />

        {hasTaxReclaim && showTaxReclaim &&
            <GridWrapper gridTemplateColumns={"repeat(2, 10ch minmax(20%, 20ch))"} >
                <FormLabel className="align-self-center mb-0">Tax Reclaim:</FormLabel>

                {isLoading
                    ? <Skeleton />
                    : <CurrencyInput
                        value={realTimeInstruction?.invest?.investmentCash[index]?.taxReclaim ?? 0}
                        readOnly
                    // onChange={(_, sourceInfo) => realTimePatchRow("taxReclaim", sourceInfo.floatValue, index)}
                    // onBlur={(amount) => patchRow(item.id, "taxReclaim", amount, index)}
                    // allowNegative={false}
                    />}
            </GridWrapper>}
    </>;
};

export default InvestmentCash;



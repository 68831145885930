import { patchReplace } from "../../../helpers/patchDoc";
import { usePatchReminderTaskMutation, useUploadReminderAttachmentMutation } from "../../../services/reminders";
import { usePersonalReminders } from "./PersonalRemindersContext";

const usePersonalReminderRow = (reminderId) => {
    const { cacheKey } = usePersonalReminders();
    const [patch, patchResult] = usePatchReminderTaskMutation();
    const [uploadFiles, uploadFilesResult] = useUploadReminderAttachmentMutation();

    const patchReminderByOperations = (operations) => {
        return new Promise((resolve, reject) => {
            return patch({ reminderId, cacheKey, operations })
                .unwrap()
                .then(resolve, reject);
        })
    }

    const patchReminder = (property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patchReminderByOperations(operations)
                .then(resolve, reject);
        })
    };

    const uploadAttachment = (files = [], fileInfo = [], masterAccountId) => {
        return new Promise((resolve, reject) => {
            var request = new FormData();
            files.forEach((file) => {
                request.append(file.name, file);
            });

            request.append("fileDetails", JSON.stringify(fileInfo));

            return uploadFiles({ reminderId, masterAccountId, request, cacheKey })
                .unwrap()
                .then(resolve, reject);
        });
    };

    return {
        patchReminder,
        patchReminderByOperations,
        uploadAttachment,
        patchResult,
        uploadFilesResult
    };
};

export default usePersonalReminderRow;
import styled from "styled-components";
import { readableColor } from "polished";

export const PageHeaderTitle = styled.h2`
    margin: 0;
    font-size: 16px;
`;

export const PageHeaderSubTitle = styled.h3`
    margin: 0;
    font-size: 14px;
`;

const PageHeader = styled.section`
    grid-area: pageheader;
    z-index: 5;    
    position: relative;
    padding: 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};

    & h2 {
        font-size: 24px;
    }

    & h2:last-child {
        margin: 0;
    }

    & h3 {
        margin: 0;
        font-size: 20px;
    }
`;

export default PageHeader;
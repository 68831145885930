import React from "react";
import withProviders from "../../hooks/withProviders";
import IntroducerDashboard from "../../features/introducerdashboard"
import { FilterProvider, IntroducerDashboardDefaultFilters } from "../../hooks/FilterContext";
import { Main } from "../../components/utilities";

const IntroducerManagementPage = () => (
    <Main className="d-flex flex-column">
        <IntroducerDashboard />
    </Main>
)

export default withProviders([FilterProvider, { defaultFilters: IntroducerDashboardDefaultFilters }])(IntroducerManagementPage);
import { wealthProApi } from '../../redux/api';

const hoProvidersApiEndpoints = wealthProApi.enhanceEndpoints({})
.injectEndpoints({
    endpoints: (builder) => ({
        fetchHoAdminProviders: builder.query({
            query: ({page, limit, sort = "ProviderName"}) => `api/headofficeadmin/providers?page=${page}&limit=${limit}&sort=${sort}`
        }),
        searchHoAdminProviders: builder.query({
            query: ({searchText, page = 1, limit = 100, sort = "ProviderName"}) => {
                return `api/headofficeadmin/providers/search?searchTerm=${searchText}&page=${page}&limit=${limit}&sort=${sort}`
            }
        })
    })
});

export const {
    useLazyFetchHoAdminProvidersQuery,
    useLazySearchHoAdminProvidersQuery
} = hoProvidersApiEndpoints;
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLazyFetchPricesForGridQuery } from "../../../services/assets/assetApiEndpoints";
import { useCreateAssetPriceMutation, useDeleteAssetPriceMutation, usePatchAssetPriceMutation } from "../../../services/assets";
import { patchReplace } from "../../../helpers/patchDoc";

const DefaultPriceFilters = {
    startDate: null,
    endDate: null
};

const PricesManagementContext = createContext();

export const usePricesManagement = () => useContext(PricesManagementContext);

const PricesManagementProvider = ({ children }) => {
    const limit = 15;
    const [filters, setFilters] = useState(DefaultPriceFilters);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [priceToEdit, setPriceToEdit] = useState(null);
    const { id: assetId } = selectedAsset?.data || {};
    const [search, searchResults] = useLazyFetchPricesForGridQuery();
    const { data, error, isError, isUninitialized } = searchResults;
    const { totalCount, next, results } = data || { totalCount: 5, next: null, results: [] };

    const [createTrigger, createResult] = useCreateAssetPriceMutation();
    const [deleteTrigger, deleteResult] = useDeleteAssetPriceMutation();
    const [patchTrigger, patchResult] = usePatchAssetPriceMutation();

    const clear = () => {
        return search({
            assetId,
            limit,
            next: null,
            request: filters,
        });
    };

    const clearFilters = () => setFilters(DefaultPriceFilters);

    const createPrice = (data) => {
        return new Promise((resolve, reject) => {
            return createTrigger({ assetId, request: data })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const deletePrice = (priceDate) => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ assetId, priceDate })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        return search({
            assetId,
            next,
            limit,
            request: filters
        });
    };

    const patchPrice = (priceDate, property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patchTrigger({ assetId, priceDate, operations })
                .unwrap()
                .then(resolve, reject);
        });
    }

    useEffect(() => {
        if (!selectedAsset || isUninitialized) return;

        clear();
    }, [selectedAsset, filters]);

    const columns = useMemo(() => [
        {
            label: "Price Date",
        },
        {
            label: "Bid Price",
        },
        {
            label: "Mid Price",
        },
        {
            label: "Offer Price",
        }
    ], []);

    return (
        <PricesManagementContext.Provider value={{
            columns,
            error,
            isError,
            results,
            selectedAsset,
            priceToEdit: results.find(x => x.priceDate === priceToEdit),
            totalCount,
            priceFilters: {
                filters,
                clearFilters,
                setFilters
            },
            createPrice,
            createResult,
            deletePrice,
            isItemLoaded,
            loadMoreItems,
            patchPrice,
            setSelectedAsset,
            setPriceToEdit
        }}>
            {children}
        </PricesManagementContext.Provider>
    )
};

export default PricesManagementProvider;
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import { Main } from "../../../components/utilities";
import { useAssetsManagement } from "../hooks/AssetsManagementContext";
import AssetsTableHeader from "./AssetsTableHeader";
import AssetsTableRow from "./AssetsTableRow";

const AssetsTable = () => {
    const { loadMoreItems, isItemLoaded, results, error, isError, columns, clear, totalCount } = useAssetsManagement();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>;
    }

    return (
        <VirtualTable
            enableScrollTracking={false}
            loadMoreItems={loadMoreItems}
            row={AssetsTableRow}
            header={<AssetsTableHeader/>}
            itemSize={42}
            itemCount={totalCount === results.length ? totalCount : results.length + 10}
            itemData={results}
            isItemLoaded={isItemLoaded}
        />
    );
};

export default AssetsTable;
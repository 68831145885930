import AdministrationFeesLayout from "../layouts/AdministrationFeesLayout";
import { NavigationTabs } from "../components/navigation";
import {
    FeeManagementPage,
    FeeManagementReportsPage
} from "../pages/administration";

const AdministrationFeesRoutes = {
    path: 'fees/*',
    pathBase: '/administration/fees',
    defaultRoute: 'editfees',
    roles: [],
    element: AdministrationFeesLayout,
    children: [
        {
            path: 'editfees',
            icon: 'fa-table',
            label: 'Edit Fees',
            roles: [],
            element: FeeManagementPage
        },
    ]
};

/*
        {
            path: 'reports',
            icon: 'fa-file-signature',
            label: 'Reports',
            roles: [],
            element: FeeManagementReportsPage
        }
*/

export const AdministrationFeeRouteTabs = () => <NavigationTabs pathBase={AdministrationFeesRoutes.pathBase} options={AdministrationFeesRoutes}  />

export default AdministrationFeesRoutes;
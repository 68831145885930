import React, { useContext, useEffect, useState } from "react";
import {
    useCreateSalutationPreferenceMutation,
    useDeleteSalutationPreferenceMutation,
    useFetchSalutationPreferencesQuery,
    usePatchSalutationPreferencesMutation
} from "../../../services/clientdetails";

const SalutationPreferencesContext = React.createContext();

const useSalutationPreferences = () => useContext(SalutationPreferencesContext);

export const SalutationPreferencesProvider = ({ children, masterAccountId }) => {
    const { data, error, isError, isFetching, isLoading, refetch } = useFetchSalutationPreferencesQuery({ masterAccountId });
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateSalutationPreferenceMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteSalutationPreferenceMutation();
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchSalutationPreferencesMutation();

    const createSalutation = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            // setup this contact details as the default when there is no other default set
            let isDefault = false;
            if (!isLoading && !isError && data) {
                let defaults = data.filter(el => el.isDefault === true);
                isDefault = defaults.length === 0;
            }

            let newDetails = {
                masterAccountId,
                clientAccountId: null,
                addressee: '',
                salutationFromAdviser: '',
                salutationFormal: '',
                isDefault: isDefault
            };

            return create({ masterAccountId, data: newDetails }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteSalutation = (salutationId, newDefaultSalutation = null) => {
        return new Promise((resolve, reject) => {
            return remove({ salutationId, masterAccountId, newDefaultSalutation }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ masterAccountId });
    };

    const setSalutationAsDefault = (salutationId) => {
        return new Promise((resolve, reject) => {
            // generate all the options we want to push up to the api
            let requests = [];
            data.forEach(el => {
                if (el.salutationId === salutationId && el.isDefault === false) {
                    requests.push({ 
                        salutationId: el.salutationId,
                        patchDocument: [
                            { op: 'replace', path: `/isDefault`, value: true }
                        ]
                    });
                }

                if (el.salutationId !== salutationId && el.isDefault === true) {
                    requests.push({ 
                        salutationId: el.salutationId,
                        patchDocument: [
                            { op: 'replace', path: `/isDefault`, value: false }
                        ]
                    });
                }
            });

            return patch({ masterAccountId, operations: requests }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateSalutation = (salutationId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            let request = [{
                salutationId,
                operations
            }]

            return patch({ masterAccountId, operations: request }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return (
        <SalutationPreferencesContext.Provider value={{
            createError,
            createSalutation,
            data : data || [],
            deleteSalutation,
            error,
            isCreating,
            isError,
            isErrorCreating,
            isErrorRemoving,
            isErrorUpdating,
            isLoading: isLoading === true || isFetching === true,
            isRemoving,
            isUpdating,
            reload,
            removeError,
            setSalutationAsDefault,
            updateError,
            updateSalutation
        }}>
            {children}
        </SalutationPreferencesContext.Provider>
    );
}

export default useSalutationPreferences;
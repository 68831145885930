import { FormSelect } from "../../../components/forms";

const FeeTypeOptions = [
    { label: "Intermediation", value: 0 },
    { label: "Service", value: 1 },
    { label: "Advice", value: 2 },
    { label: "Admin", value: 3 },
    { label: "AdHoc Service", value: 4 },
    { label: "Vatable Service Fee", value: 5 },
];

const FeeTypeFormSelect = ({ defaultValue, isMulti, label, onBlur, onChange }) => {

    return <FormSelect
        label={label}
        isMulti={isMulti}
        options={FeeTypeOptions}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
    />;
};

export default FeeTypeFormSelect;
import React from "react";
import styled from "styled-components";
import withProviders from "../hooks/withProviders";
import PrdCommentaryProvider, { usePrdCommentaryContext } from "../hooks/PrdCommentaryContext";
import ErrorMessage from "../components/messages/ErrorMessage";
import ErrorMessageWrapper from "../components/messages/ErrorMessageWrapper";
import { InvestmentCommitteePrdCommentaryRouteTabs } from "../routes/investment-committee.prd.routes";
import { readableColor } from "polished";
import { Outlet } from "react-router-dom";
import { Button, GridWrapper } from "../components";
import { FormSelect } from "../components/forms";
import { ThemedSpan } from "../components/utilities";
import { PageHeaderTitle } from "../components/utilities/PageHeader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Header = styled.section`
    grid-area: pageheader;
    position: sticky;
    top: var(--navbar-size);
    z-index: 5;
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

const HeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;


const HeaderBottom = styled.div``;

const InvestmentCommitteePrdCommentaryLayout = ({ title = "PRD Content Management" }) => {
    const { serviceLevelAgreementId, setServiceLevelAgreementId, slaOptions, isError, refetchMaster, publish } = usePrdCommentaryContext();
    return (
        <React.Fragment>
             <Header>
                <HeaderTop>
                    <PageHeaderTitle>{title}</PageHeaderTitle>
                </HeaderTop>
                <GridWrapper gridTemplateColumns={"auto auto 1fr auto"} className="pb-3 align-items-center">
                    <ThemedSpan className="pe-2" variant="light">Select Service Level Agreement:</ThemedSpan>
                    <ThemedSpan variant="dark">
                        <FormSelect
                            defaultValue={serviceLevelAgreementId}
                            options={slaOptions}
                            onChange={(selection) => setServiceLevelAgreementId(selection.value)}
                        />
                    </ThemedSpan>
                    {!isError && serviceLevelAgreementId != null && <span className="col-start-4">
                        <OverlayTrigger
                            overlay={(props) => <Tooltip {...props}>Publish all non portfolio-specific commentaries for this SLA</Tooltip>}>
                            <span>
                                <Button onClick={() => publish()} variant="success" size="lg">Publish All</Button>
                            </span>
                        </OverlayTrigger>
                    </span>}
                </GridWrapper>
                <HeaderBottom>
                    {!isError && serviceLevelAgreementId != null && <InvestmentCommitteePrdCommentaryRouteTabs />}
                </HeaderBottom>
            </Header>
            {isError
                ? <ErrorMessageWrapper>
                    <ErrorMessage
                        message="Sorry! Could not load PRD Commentaries."
                        retryCallback={refetchMaster}
                    />
                </ErrorMessageWrapper>
                : serviceLevelAgreementId == null
                    ? <div className="p-3">Please select a Service Level Agreement to view Commentaries</div>
                    : <Outlet />}
        </React.Fragment>
    )
};

export default withProviders(PrdCommentaryProvider)(InvestmentCommitteePrdCommentaryLayout);
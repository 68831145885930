import _ from "lodash";
import React, { useEffect, useState } from "react";
import Button from "../../components/buttons";
import { Modal } from "react-bootstrap";
import { useFilterContext } from "../../hooks/FilterContext";
import { FormInput, FormSelect } from "../../components/forms";
import {
    GenericAdviserSelect, GenericUserDivisionSelect,
} from "../../components/forms/selects";
import IntroducerStatusSelect from "../../components/forms/selects/IntroducerStatusSelect";

export const IntroducerDashboardFiltersModalButton = ({
    className,
    variant = "primary",
    ...rest
}) => {
    return (
        <div className={className}>
            <Button variant={variant} {...rest}>
                Filter Intoducers
            </Button>
        </div>
    );
};

export const IntroducerDashboardFiltersModal = ({
    handleClose,
    onFiltersChanged,
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter((original) => {
            return {
                ...original,
                ...tempFilters,
            };
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters((_) => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters((_) => filter);
        }
    }, [filter]);

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>Introducer Filters</Modal.Header>
            <Modal.Body {...rest}>
                <IntroducerDashboardFilters
                    filter={tempFilters}
                    setFilter={onFilterValueChanged}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>
                    Clear Filters
                </Button>
                <Button variant="success" onClick={onApplyFilters}>
                    Apply Filters
                </Button>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const IntroducerDashboardFilters = ({ filter, setFilter }) => {
    const { introducer, typeIds, statusIds, divisionIds, adviserIds } = filter;

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col">
                    <FormInput
                        id="introducer"
                        label="Introducer"
                        value={introducer}
                        placeholder="Introducer"
                        onBlur={(value) => setFilter("introducer", value)}
                    />
                </div>
                <div className="col">
                    <FormSelect
                        label="Type"
                        isMulti={true}
                        defaultValue={typeIds}
                        options={[
                            { label: "Accountant", value: 0 },
                            { label: "Estate Agent", value: 1 },
                            { label: "Solicitor", value: 2 },
                            { label: "Other Professional", value: 3 },
                        ]}
                        onBlur={(value) => setFilter("typeIds", value.value)}
                    />
                </div>
                <div className="col">
                    <IntroducerStatusSelect
                        label="Status"
                        isMulti={true}
                        defaultValue={statusIds}
                        onBlur={(value) => setFilter("statusIds", value.value)}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <GenericUserDivisionSelect
                        defaultValue={divisionIds}
                        label="Divisions"
                        isMulti={true}
                        onBlur={(value) => setFilter("divisionIds", value.value)}
                    />
                </div>
                <div className="col">
                    <GenericAdviserSelect
                        label="Adviser"
                        defaultValue={adviserIds}
                        isMulti={true}
                        onBlur={(value) => setFilter("adviserIds", value.value)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default IntroducerDashboardFilters;

import { wealthProApi } from '../../redux/api';

const hoAdminUserApiEndpoints = wealthProApi
.injectEndpoints({
    endpoints: (builder) => ({
        fetchHeadOfficeAdminUsers: builder.query({
            query: ({ type = "Adviser", page, limit, fetchType = "list", sort = "Surname, Forenames" }) =>
                `api/headofficeadmin/user?type=${type}&${page != null ? `page=${page}&` : ""}${limit != null ? `limit=${limit}&` : ""}fetchType=${fetchType}&sort=${sort}`
        })
    })
});

export const {
    useLazyFetchHeadOfficeAdminUsersQuery,
    useFetchHeadOfficeAdminUsersQuery
} = hoAdminUserApiEndpoints;
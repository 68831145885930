import { Form } from "react-bootstrap";

const FormGroup = ({ children, className, horizontal, ...rest }) => {
    if (horizontal === true) {
        return <div className={className ? `${className} row` : 'row'} {...rest}>
            {children}
        </div>
    }
    return <Form.Group className={className} {...rest}>
        {children}
    </Form.Group>
}

export default FormGroup;
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Button, IconButton } from "../../../components";
import { FormSelect, FormCheck } from "../../../components/forms";
import { ThemedIcon, ThemedSpan } from "../../../components/utilities";
import { useMessageThreadContext } from "../hooks/MessageThreadContext";
import toast from "react-hot-toast";
import MasterAccountProjectPagedSelect from "./MasterAccountProjectSelect";

const RecipientType = {
    Client: 0,
    Staff: 1
};

const SendMessageConfirmationModal = ({
    onClose,
    onSend,
    onPriorityChange,
    onRequiresActionChange,
    onProjectChange,
    onChange,
    message,
    show,
}) => {

    const { sendResult: { isLoading }, updateThread } = useMessageThreadContext();

    const {
        messagePriority,
        isActionRequired,
        recipients,
        masterAccountId,
        projectId
    } = message || {};

    const hasClientRecipient = useMemo(() => recipients.some(x => x.recipientType === RecipientType.Client), [recipients]);

    return <Modal centered backdrop="static" show={show}>
        <Modal.Header>
            <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {/* <div className="row mb-3">
                <ThemedSpan>Please fill out the final options below:</ThemedSpan>
            </div> */}
            {
                hasClientRecipient && <div className="d-flex align-items-center mb-3">
                    <ThemedIcon className="me-2" icon="fa-triangle-exclamation" size="2x" variant="danger" />
                    <ThemedSpan variant="danger">This message will be sent to a client!</ThemedSpan>
                </div>
            }
            {masterAccountId && <div className="row mb-3">
                <MasterAccountProjectPagedSelect
                    label={"Project"}
                    setValue={(value) => updateThread("projectId", value.value)}
                    value={projectId}
                    masterAccountId={masterAccountId}
                />
            </div>}
            <div className="row mb-3">
                <FormSelect
                    label={"Priority"}
                    options={["Low Priority", "Medium Priority", "High Priority"].map((label, value) => ({ label, value: (value + 1) * 10 }))}
                    placeholder="Priority..."
                    onChange={onPriorityChange}
                    defaultValue={messagePriority}
                />
            </div>

            <div className="row">
                <FormCheck
                    className="d-flex align-items-center"
                    label={"Is an action required?"}
                    onChange={onRequiresActionChange}
                    isChecked={isActionRequired}
                />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <IconButton
                disabled={isLoading}
                iconClassName={isLoading ? "fa-spin" : ""}
                icon={isLoading ? "fa-circle-notch" : "fa-paper-plane"}
                variant="primary"
                onClick={onSend}
            />
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>

};

export default SendMessageConfirmationModal;
import FormSelect from "../FormSelect";
import { useFetchHeadOfficeAdminUsersQuery } from "../../../services/headofficeadmin";
import { ControllerFetchType } from "../../../helpers/fetchTypes";
import { GenericUserSelectOption, GenericUserSelectSingleValue } from "./GenericUserSelect";

const HeadOfficeUserSelect = ({
    defaultValue,
    label,
    onBlur,
    onChange,
    ...rest
}) => {

    const { data, isFetching } = useFetchHeadOfficeAdminUsersQuery({ fetchType: ControllerFetchType.List, sort: "Surname" });


    return <FormSelect
        defaultValue={defaultValue}
        isLoadingOptions={isFetching}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        options={data ?? []}
        components={{
            Option: GenericUserSelectOption,
            SingleValue: GenericUserSelectSingleValue
        }}
        {...rest}
    />;
};

export default HeadOfficeUserSelect;
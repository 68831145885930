import { createContext, useContext, useState } from "react";
import { ControllerFetchType } from "../../../helpers/fetchTypes";
import { patchReplace } from "../../../helpers/patchDoc";
import { StandardTableSortableHeader, ThemedTableHeader } from "../../../components/tables/StandardTable";
import {
    useCreateWrapperMutation,
    useDeleteWrapperMutation,
    usePatchWrapperMutation,
    useFetchInstructionTypesQuery,
    useLazyFetchInstructionTypeWrapperMapsQuery,
    useFetchWrappersForGridQuery,
    useFetchWrapperCategoriesQuery,
    useUpdateWrapperInstructionMapsMutation
} from "../../../services/products";

const WrappersManagementContext = createContext();

export const useWrappersManagement = () => useContext(WrappersManagementContext);

const WrappersManagementProvider = ({ children }) => {
    const [sort, setSort] = useState("DisplayOrder%2BASC");
    const [sortProperty, sortDirection] = sort.split("%2B");
    const { data: wrapperCategories, isLoading: isLoadingWrapperCategories } = useFetchWrapperCategoriesQuery();
    const { data, error, isLoading, isError, isFetching, refetch } = useFetchWrappersForGridQuery({ sort });
    const { data: instructionTypes, isLoading: isLoadingInstructionTypes } = useFetchInstructionTypesQuery({ fetchType: ControllerFetchType.List });

    const [createWrapperTrigger, createWrapperResult] = useCreateWrapperMutation();
    const [deleteWrapperTrigger] = useDeleteWrapperMutation();
    const [patchWrapperTrigger] = usePatchWrapperMutation();
    const [fetchMappingsTrigger] = useLazyFetchInstructionTypeWrapperMapsQuery();
    const [updateInstructionMapsTrigger] = useUpdateWrapperInstructionMapsMutation();

    const createWrapper = () => {
        return new Promise((resolve, reject) => {
            return createWrapperTrigger({})
                .unwrap()
                .then(resolve, reject);
        });
    };

    const deleteWrapper = (wrapperId) => {
        return new Promise((resolve, reject) => {
            return deleteWrapperTrigger({ wrapperId })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const fetchMappings = ({ wrapperId, page, limit, sort, ...rest }) => {
        return new Promise((resolve, reject) => {
            return fetchMappingsTrigger({ wrapperId, page, limit, sort: sort === "" ? null : sort })
                .unwrap()
                .then(resolve, reject);
        });
    }

    const patchWrapper = (wrapperId, property, value, assignableObject) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return patchWrapperTrigger({ wrapperId, operations, assignableObject })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const handleSort = (property) => {
        var sort = property+"%2B";
        if(sortProperty === property){
            return setSort(sortDirection === "ASC" ? sort + "DESC" : sort + "ASC");
        }

        return setSort(sort + "ASC");
    };

    const sortableHeaderRenderer = ({ label, property, key }) => {
        return <StandardTableSortableHeader
            key={key}
            direction={sortDirection}
            onClick={() => handleSort(property)}
            active={sortProperty === property}
        >
            {label}
        </StandardTableSortableHeader>
    };

    const retryFetchWrappers = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            return refetch().unwrap().then(resolve, reject);
        });
    };

    const updateWrapperInstructionMaps = (wrapperId, instructionTypes) => {
        return new Promise((resolve, reject) => {
            return updateInstructionMapsTrigger({
                wrapperId,
                instructionTypes,
                instructionTypeIds: instructionTypes.map(x => x.value)
            }).unwrap().then(resolve, reject);
        });
    }

    const columns = [
        {
            label: "Wrapper Name",
            property: "Description",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Wrapper Category",
            property: "Category",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Instructions",
            headerRenderer: ({ label }) => <ThemedTableHeader key={label} className="col">{label}</ThemedTableHeader>
        }
    ];

    return <WrappersManagementContext.Provider value={{
        columns,
        createWrapperResult,
        data,
        error,
        isLoading,
        isError,
        isFetching,
        wrapperCategories,
        isLoadingWrapperCategories,
        instructionTypes,
        isLoadingInstructionTypes,
        createWrapper,
        deleteWrapper,
        fetchMappings,
        patchWrapper,
        retryFetchWrappers,
        updateWrapperInstructionMaps
    }}>
        {children}
    </WrappersManagementContext.Provider>
};

export default WrappersManagementProvider;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { useCorporateActionPairs } from "../hooks/CorporateActionPairsContext";
import { DateInput, FormInput, FormTextArea } from "../../../components/forms";

const EditCorporateActionPairModal = () => {

    const { pairToEdit, setPairToEditId, patchPair } = useCorporateActionPairs();

    const {
        sedolIn,
        sedolOut,
        description,
        actionDate
    } = pairToEdit || {};

    return (
        <Modal show={pairToEdit !== null} onHide={() => setPairToEditId(null)} backdrop="static" size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Corporate Action Pair</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-2">
                    <div className="col">
                        <FormInput
                            label="SEDOL Out"
                            value={sedolOut}
                            onBlur={(value) => patchPair("sedolOut", value)}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="SEDOL In"
                            value={sedolIn}
                            onBlur={(value) => patchPair("sedolIn", value)}
                        />
                    </div>
                    <div className="col">
                        <DateInput
                            label="Action Date"
                            value={actionDate}
                            onBlur={(value) => patchPair("actionDate", value)}
                            isClearable
                        />
                    </div>
                </div>
                <div className="row">
                    <FormTextArea
                        value={description}
                        label="Description"
                        minRows={3}
                        onBlur={(value) => patchPair("description", value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => setPairToEditId(null)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCorporateActionPairModal;
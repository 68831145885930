import _ from "lodash";
import React from "react";
import classNames from "classnames";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import { useUserDelegatesData } from "../hooks/UserDelegatesContext";

const DelegatesGridTableHeader = ({ children, property, className }) => {
    const { sort, sortTable } = useUserDelegatesData();
    const sortProperties = sort.split(',');
    const isHeaderSorted = _.some(sortProperties, i => i.includes(property));
    const headerDirection = isHeaderSorted && _.some(sortProperties, i => i.toLowerCase().includes('+desc')) ? "desc" : null;

    const handleClick = () => sortTable(property);

    return (
        <StandardTableSortableHeader
            className={classNames(className)}
            active={isHeaderSorted}
            direction={headerDirection}
            onClick={handleClick}
            isSticky={true}
        >
            {children}
        </StandardTableSortableHeader>
    );
};

export default DelegatesGridTableHeader;
import { ThemedSpan } from "../../../components/utilities";

const UserCalendarWeekEvent = ({ event, ...props }) => {

    const {
        id,
        start,
        end,
        title,
        isAllDay,
        masterAccountId,
        masterAccountName
    } = event || {};

    return <div>
        {masterAccountId && <ThemedSpan className="text-truncate" variant="light">{masterAccountName}</ThemedSpan>}
        {!masterAccountId && <ThemedSpan className="text-truncate" variant="light">{title}</ThemedSpan>}
    </div>;
};

export default UserCalendarWeekEvent;
import { Modal } from "react-bootstrap";
import { usePricesManagement } from "../hooks/PricesManagementContext";
import { Button } from "../../../components";
import { useForm, Controller } from "react-hook-form";
import { DateInput, NumberInput } from "../../../components/forms";
import toast from "react-hot-toast";

const DefaultFormValues = {
    priceDate: null,
    bidPrice: null,
    midPrice: null,
    offerPrice: null
};

const CreatePriceModal = ({ show, onHide }) => {
    const { createPrice, createResult } = usePricesManagement();
    const { control, setValue, reset, handleSubmit, formState: { errors } } = useForm({ defaultValues: DefaultFormValues });

    const onClose = () => {
        onHide();
        reset(DefaultFormValues);
    };

    const onSubmit = (data) => {
        toast.promise(createPrice(data), {
            loading: "Creating price...",
            error: "Unable to create price.",
            success: (data) => {
                onClose();
                return "Price created!"
            }
        });
    };

    return <Modal size="lg" centered backdrop="static" show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Create Price</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            control={control}
                            name="priceDate"
                            rules={{ required: "Price Date is required." }}
                            render={({ field: { ref, onChange, onBlur, ...rest } }) => (
                                <DateInput
                                    label="Price Date"
                                    disableAnimations={true}
                                    errorMessage={errors?.priceDate?.message}
                                    errorAllowRetry={false}
                                    onBlur={(value) => setValue("priceDate", value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller
                            control={control}
                            name="bidPrice"
                            rules={{ required: "Bid Price is required." }}
                            render={({ field: { ref, onChange, onBlur, ...rest } }) => (
                                <NumberInput
                                    label="Bid Price"
                                    disableAnimations={true}
                                    errorMessage={errors?.bidPrice?.message}
                                    errorAllowRetry={false}
                                    onChange={(e) => setValue("bidPrice", e.target.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller
                            control={control}
                            name="midPrice"
                            rules={{ required: "Mid Price is required." }}
                            render={({ field: { ref, onChange, onBlur, ...rest } }) => (
                                <NumberInput
                                    label="Mid Price"
                                    disableAnimations={true}
                                    errorMessage={errors?.midPrice?.message}
                                    errorAllowRetry={false}
                                    onChange={(e) => setValue("midPrice", e.target.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller
                            control={control}
                            name="offerPrice"
                            rules={{ required: "Offer Price is required." }}
                            render={({ field: { ref, onChange, onBlur, ...rest } }) => (
                                <NumberInput
                                    label="Offer Price"
                                    disableAnimations={true}
                                    errorMessage={errors?.offerPrice?.message}
                                    errorAllowRetry={false}
                                    onChange={(e) => setValue("offerPrice", e.target.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onClose} disabled={createResult.isLoading}>Cancel</Button>
                <Button variant="success" type="submit" disabled={createResult.isLoading}>Confirm</Button>
            </Modal.Footer>
        </form>
    </Modal>
};

export default CreatePriceModal;
import React from "react";
import FormSelect from "../FormSelect";
import { useFetchReminderTypesQuery } from "../../../services/reminders";

const ReminderTypesSelect = ({ options, showHidden = false, ...rest }) => {
    const { data, isFetching, isLoading } = useFetchReminderTypesQuery({ showHidden });
    return (
        <FormSelect
            options={data ?? []}
            isLoadingOptions={isLoading || isFetching}
            {...rest}
        />
    );
};

export default ReminderTypesSelect;
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useAddDivestTransactionMutation, useCreateStandaloneFeeMutation, useDeleteStandaloneFeeMutation, useFetchStandaloneFeesQuery, usePatchDivestTransactionMutation, usePatchStandaloneFeeMutation, useRecsFetchInstructionTypeProductsQuery, useRecsFetchStandaloneFeeTypesQuery, useRemoveDivestTransactionMutation } from "../../../services/recommendations";
import { useBizSheet } from "../contexts/BizSheetContext";
import { useInstruction } from "../contexts/InstructionContext";

const standaloneFeeArrayReducer = (state, action) => {
    switch (action.type) {
        case "init":
            return action.value;
        case "create":
            return [...state, { amount: 0, vatAmount: 0 }];
        case "update-type":
            return state.map(fee => fee.feeId === action.feeId ? { ...fee, type: action.value } : fee);
        case "update-amount":
            return state.map(fee => fee.feeId === action.feeId ? { ...fee, amount: action.value } : fee);
        case "delete":
            return state?.filter((fee) => fee.feeId !== action.feeId) ?? [];
        default:
            return state;
    }
}

const useRaiseFee = () => {
    const [, { designations, designationsIsLoading: isLoadingDesignations }] = useBizSheet();
    const [{
        fetchDivest,
        fetchDivestTransactions,
        patchDivest: patchDivestTrigger
    }, {
        instruction,
        instructionId,
        divest,
        divestIsLoading,
        divestIsUninitialised,
        divestIsError,
        divestTransactions,
        divestTransactionsIsError,
        divestTransactionsError,
        divestTransactionsIsLoading,
        divestTransactionsIsFetching,
        divestTransactionsIsUninitialised,
    }] = useInstruction();

    const fetchUntilFound = useCallback(() => {
        fetchDivest()
            .then(res => {
                if (res == null)
                    fetchUntilFound();
                else
                    fetchDivestTransactions({ divestId: res.id });
            });
    }, [fetchDivest, fetchDivestTransactions]);

    const { data: fees, isLoading } = useFetchStandaloneFeesQuery({ instructionId });

    const { data: feeTypes, isLoading: isLoadingFeeTypes } = useRecsFetchStandaloneFeeTypesQuery({ listType: "select" });
    const { data: products, isLoading: isLoadingProducts } = useRecsFetchInstructionTypeProductsQuery({ instructionTypeId: instruction?.instructionTypeId, listType: "select" });

    useEffect(() => {
        if (instructionId && fees)
            fetchUntilFound();
    }, [instructionId, fetchUntilFound, fees]);

    const [createFeeTrigger] = useCreateStandaloneFeeMutation();
    const [patchFeeTrigger] = usePatchStandaloneFeeMutation();
    const [deleteFeeTrigger] = useDeleteStandaloneFeeMutation();

    const [addTransactionTrigger, { isLoading: isAddingTransaction }] = useAddDivestTransactionMutation();
    const [removeTransactionTrigger, { isLoading: isRemovingTransaction }] = useRemoveDivestTransactionMutation();

    const addTransaction = () => addTransactionTrigger({ divestId: divest?.id }).unwrap();
    const removeTransaction = () => new Promise((resolve, reject) =>
        removeTransactionTrigger({ divestId: divest?.id, rowTag: divestTransaction?.rowTag }).unwrap()
            .then(() => bulkPatchDivest([
                patchReplace("productId", null),
                patchReplace("designationId", null),
                patchReplace("providerRef", null),
                patchReplace("custodyRef", null)
            ]).then(resolve, reject))
    );

    const [patchTransactionTrigger] = usePatchDivestTransactionMutation();

    const [realTimeFeeArray, dispatch] = useReducer(standaloneFeeArrayReducer, fees ?? []);

    useEffect(() => {
        dispatch({ type: "init", value: fees ?? [] });
    }, [fees]);

    const realTimeVatAmounts = useMemo(() =>
        realTimeFeeArray?.map(fee => {
            if ([20, 30].includes(fee?.type))
                return fee?.amount * 0.2;
            else
                return 0;
        })
        ?? fees?.map(fee => fee?.vatAmount)
        ?? [],
        [fees, realTimeFeeArray]);

    const realTimeFeeTotal = useMemo(() =>
        realTimeFeeArray?.reduce((acc, fee, index) => acc + (fee?.amount ?? 0) + (realTimeVatAmounts[index] ?? 0), 0) ?? 0,
        [realTimeFeeArray, realTimeVatAmounts]);

    const divestTransaction = divestTransactions?.[0];

    const [realTimeRaisedAmount, setRealTimeRaisedAmount] = useState(divestTransaction?.saleValue ?? 0)
    const realTimeCollectedAmount = useMemo(() =>
        Math.min(realTimeRaisedAmount ?? 0, realTimeFeeTotal ?? 0),
        [realTimeFeeTotal, realTimeRaisedAmount]);

    useEffect(() => {
        setRealTimeRaisedAmount(divestTransaction?.saleValue ?? 0)
    }, [divestTransaction?.saleValue]);

    // Remainder is the amount paid by client
    const realTimeRemainder = useMemo(() =>
        Math.max(realTimeFeeTotal - realTimeCollectedAmount, 0),
        [realTimeFeeTotal, realTimeCollectedAmount]);

    const updateFeeType = useCallback((feeId, value) =>
        dispatch({ type: "update-type", feeId, value }),
        []);

    const updateFeeAmount = useCallback((feeId, value) =>
        dispatch({ type: "update-amount", feeId, value }),
        []);

    // Not required for functionality but prevents visual bugs if adding multiple fees in quick succession
    const [isCreatingTimeoutId, setIsCreatingTimeoutId] = useState(null);

    const createFee = useCallback(() => {
        if (isCreatingTimeoutId != null)
            clearTimeout(isCreatingTimeoutId);

        dispatch({ type: "create" });
        createFeeTrigger({ instructionId })
            .unwrap()
            .then(res => {
                setIsCreatingTimeoutId(setTimeout(() => {
                    dispatch({ type: "init", value: res });
                }, 500));
            });
    }, [createFeeTrigger, instructionId, isCreatingTimeoutId]);

    const patchFee = useCallback((feeId, property, value) =>
        patchFeeTrigger({ feeId, instructionId, operations: [patchReplace(property, value)] }).unwrap(), [instructionId, patchFeeTrigger]);

    const patchFeeAmount = useCallback((feeId, value) => {
        const realTimeVatAmount = realTimeVatAmounts?.find((_, index) => fees[index]?.feeId === feeId);
        const cachedVatAmount = fees?.find(fee => fee?.feeId === feeId)?.vatAmount;

        // Update VAT Amount if there is one in the real-time store AND the cached VAT amount is not the same as the new VAT amount
        if (realTimeVatAmount && cachedVatAmount !== value * 0.2)
            return patchFeeTrigger({ feeId, instructionId, operations: [patchReplace("amount", value), patchReplace("vatAmount", value * 0.2)] }).unwrap();
        else
            return patchFee(feeId, "amount", value);
    }, [fees, instructionId, patchFee, patchFeeTrigger, realTimeVatAmounts]);

    const patchFeeType = useCallback((feeId, value) => {
        const realTimeVatAmount = realTimeVatAmounts?.find((_, index) => fees[index]?.feeId === feeId);
        const cachedVatAmount = fees?.find(fee => fee?.feeId === feeId)?.vatAmount;
        const cachedAmount = fees?.find(fee => fee?.feeId === feeId)?.amount;

        let patchDoc = [patchReplace("type", value)];

        if (realTimeVatAmount === 0 && cachedVatAmount !== 0)
            patchDoc.push(patchReplace("vatAmount", 0));
        else if (cachedVatAmount !== cachedAmount * 0.2)
            patchDoc.push(patchReplace("vatAmount", cachedAmount * 0.2));

        return patchFeeTrigger({ feeId, instructionId, operations: patchDoc }).unwrap();
    }, [fees, instructionId, patchFeeTrigger, realTimeVatAmounts]);

    const deleteFee = useCallback((feeId) => {
        dispatch({ type: "delete", feeId });
        deleteFeeTrigger({ feeId, instructionId });
    }, [deleteFeeTrigger, instructionId]);

    const bulkPatchDivest = useCallback((operations) =>
        patchDivestTrigger({ divestId: divest?.id, instructionId, operations }).unwrap(),
        [divest?.id, instructionId, patchDivestTrigger]);

    const patchDivest = useCallback((property, value) =>
        bulkPatchDivest([patchReplace(property, value)]),
        [bulkPatchDivest]);

    const patchTransaction = useCallback((property, value) =>
        patchTransactionTrigger({ divestId: divest?.id, rowTag: 0, operations: [patchReplace(property, value)] }).unwrap(),
        [divest?.id, patchTransactionTrigger]);

    // onBlur update VAT Amounts
    useEffect(() => {
        fees?.forEach((fee) => {
            if (fee?.id == null)
                return;

            if ([20, 30].includes(fee?.type)) {
                if (fee?.vatAmount !== fee?.amount * 0.2)
                    patchFee(fee?.feeId, "vatAmount", fee?.amount * 0.2);
            }
            else if (fee?.vatAmount !== 0)
                patchFee(fee?.feeId, "vatAmount", 0);
        });
    }, [fees, patchFee]);

    // onBlur update raised amount
    // useEffect(() => {
    //     const updateRaisedAmountTimeout = setTimeout(() => {
    //         let feeTotal = fees?.reduce((acc, fee) => acc + fee?.amount + fee?.vatAmount, 0) ?? 0;

    //         if (realTimeCollectedAmount > feeTotal && realTimeRaisedAmount <= feeTotal)
    //             patchTransaction("saleValue", feeTotal);
    //     }, 150);

    //     return () => clearTimeout(updateRaisedAmountTimeout);
    // }, [fees, patchTransaction, realTimeRaisedAmount, realTimeCollectedAmount]);

    return [{
        createFee,
        updateFeeType,
        updateFeeAmount,
        patchFee,
        patchFeeAmount,
        patchFeeType,
        deleteFee,
        bulkPatchDivest,
        patchDivest,
        addTransaction,
        patchTransaction,
        removeTransaction,
        setRealTimeRaisedAmount,
        refetchDivest: fetchUntilFound,
    }, {
        fees,
        realTimeRaisedAmount,
        realTimeFeeTotal,
        realTimeVatAmounts,
        realTimeRemainder,
        realTimeCollectedAmount,
        isLoading: isLoading || divestIsLoading || divestIsUninitialised || divestTransactionsIsLoading || divestTransactionsIsUninitialised,
        divest,
        divestIsError,
        divestTransaction,
        divestTransactionsIsError,
        isAdding: isAddingTransaction,
        isRemoving: isRemovingTransaction,
        feeTypes,
        isLoadingFeeTypes,
        designations,
        isLoadingDesignations,
        products,
        isLoadingProducts
    }];
}

export default useRaiseFee;
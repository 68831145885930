import React from "react";
import withProviders from "../../hooks/withProviders";
import CreateReportModal from "../../features/createreportmodal";
import ClientReportsTable from "../../features/clientreportstable";
import { Button } from "../../components";
import { FormInput } from "../../components/forms";
import { EditReportProvider } from "../../features/clientreportseditmodal/hooks/EditReportContext";
import { ReportHistoryProvider } from "../../features/clientreportshistorymodal/hooks/ReportHistoryContext";
import { ClientLayoutPageSubHeader, Main } from "../../components/utilities";
import { ClientReportProvider, useClientReportsList } from "../../hooks/ClientReportContext";

// TODO: format this to match how the client documents are now presented

const ClientReportsPage = () => {
    const { clear, searchTerm, setSearchTerm } = useClientReportsList();
    const [isCreateShown, setIsCreateShown] = React.useState(false);
    const [formSearchTerm, setFormSearchTerm] = React.useState(searchTerm);
    
    const handleOnReportCreated = (_) => clear();

    React.useEffect(() => {
        const updateFilters = setTimeout(() => {
            setSearchTerm(_ => formSearchTerm);
        }, 500);
        return () => clearTimeout(updateFilters);
    }, [ formSearchTerm ]);

    return (
        <React.Fragment>
            <CreateReportModal 
                handleClose={() => setIsCreateShown(false)}
                show={isCreateShown}
                defaultProjectId={null}
                onReportCreated={handleOnReportCreated}
            />
            <ClientLayoutPageSubHeader>
                <div className="d-flex justify-content-end align-items-center">
                    <FormInput
                        placeholder="Search for a Report."
                        value={formSearchTerm}
                        disableAnimations={true}
                        onChange={(e) => setFormSearchTerm(e.target.value)}
                    />
                    <Button variant="success" className="ms-2" onClick={() => setIsCreateShown(true)}>Create new Report</Button>
                </div>
            </ClientLayoutPageSubHeader>
            <Main className="p-3">
                <ClientReportsTable />
            </Main>
        </React.Fragment>
    );
};

export default withProviders(ClientReportProvider, EditReportProvider, ReportHistoryProvider)(ClientReportsPage);
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from "react-number-format";
import { IconButton } from "../../../components/buttons";
import { CurrencyInput, FormLabel, FormSelect, FormTextArea } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useTaxYearEndWithdrawals from "../hooks/useTaxYearEndWithdrawals";
import SectionHeader from "./SectionHeader";

const FormRow = ({ children, className }) => <div className={`row mb-2 ${className ?? ""}`}>{children}</div>;

const TaxYearEndWithdrawals = ({ pensionPlanId }) => {

    const { data, isLoading, isError, error, updateWithdrawal, deleteWithdrawal, createWithdrawal, isCreating, refetch } = useTaxYearEndWithdrawals(pensionPlanId);
    const [taxableIncomeTotals, setTaxableIncomeTotals] = useState([]);
    const [taxFreeCashTotals, setTaxFreeCashTotals] = useState({});

    const taxableIncomeTotal = useMemo(() => Object.values(taxableIncomeTotals).reduce((total, next) => total + next, 0), [taxableIncomeTotals]);

    const taxFreeCashTotal = useMemo(() => Object.values(taxFreeCashTotals).reduce((total, next) => total + next, 0), [taxFreeCashTotals]);

    const years = useMemo(() => {
        let yearsList = [];
        let currentYear = moment().year() + 1;

        while (currentYear >= 2000) {
            yearsList.push({ label: currentYear, value: currentYear });
            currentYear--;
        }

        return yearsList;
    }, []);

    const onCurrencyValueChange = (setStateFn, withdrawalId, value) => {
        setStateFn(prev => {
            var copy = { ...prev };
            copy[withdrawalId] = value;
            return copy;
        });
    };

    useEffect(() => {
        if (!data || data.length === 0) return;

        setTaxFreeCashTotals(data.reduce((dict, next) => { dict[next.withdrawalId] = next.taxFreeCash; return dict; }, {}));
        setTaxableIncomeTotals(data.reduce((dict, next) => { dict[next.withdrawalId] = next.taxableIncome; return dict; }, {}));

    }, [data]);

    if (isLoading) return <Skeleton height={16} count={2} />;

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetch} />
        </ErrorMessageWrapper>;
    }

    return <FormRow className={"mt-3"}>
        <div className="row">
            <SectionHeader>
                History of Withdrawals
            </SectionHeader>
        </div>
        {
            !isLoading && !isCreating && data && data.length === 0 && <FormRow>
                <p className="mt-2">There are no recorded withdrawals for this pension plan. Click <button className="anchor" onClick={() => createWithdrawal(pensionPlanId)}>here</button> to create one</p>
            </FormRow>
        }
        {
            (isCreating || (data && data.length > 0)) && <FormRow className={"mb-3"}>
                <div className="col-2">
                    <FormLabel>Tax Year End</FormLabel>
                </div>
                <div className="col-5">
                    <FormLabel>Notes</FormLabel>
                </div>
                <div className="col-2">
                    <FormLabel>Tax Free Cash</FormLabel>
                </div>
                <div className="col-2">
                    <FormLabel>Taxable Income</FormLabel>
                </div>
                {data && data.length > 0 && <div className="col-auto">
                    <IconButton
                        icon={"fa-plus"}
                        variant="success"
                        onClick={() => createWithdrawal(pensionPlanId)}
                    />
                </div>}
            </FormRow>
        }
        {data && data.length > 0 && data.map((item) => (
            <FormRow
                key={item.withdrawalId}
            >
                <div className="col-2">
                    <FormSelect
                        defaultValue={item.taxYearEnd}
                        options={years}
                        onBlur={({ value }) => updateWithdrawal(item.withdrawalId, "taxYearEnd", value)}
                    />
                </div>
                <div className="col-5">
                    <FormTextArea
                        value={item.notes}
                        onBlur={(value) => updateWithdrawal(item.withdrawalId, "notes", value)}
                    />
                </div>
                <div className="col-2">
                    <CurrencyInput
                        value={item.taxFreeCash}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        onChange={(_, { floatValue }) => onCurrencyValueChange(setTaxFreeCashTotals, item.withdrawalId, floatValue)}
                        onBlur={(value) => updateWithdrawal(item.withdrawalId, "taxFreeCash", value)}
                    />
                </div>
                <div className="col-2">
                    <CurrencyInput
                        value={item.taxableIncome}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        onChange={(_, { floatValue }) => onCurrencyValueChange(setTaxableIncomeTotals, item.withdrawalId, floatValue)}
                        onBlur={(value) => updateWithdrawal(item.withdrawalId, "taxableIncome", value)}
                    />
                </div>
                <div className="col-auto">
                    <IconButton
                        icon={"fa-trash"}
                        variant="danger"
                        onClick={() => deleteWithdrawal(item.withdrawalId)}
                    />
                </div>
            </FormRow>
        ))}
        {isCreating && <FormRow>
            <div className="col-2">
                <Skeleton height={30} />
            </div>
            <div className="col-5">
                <Skeleton height={30} />
            </div>
            <div className="col-2">
                <Skeleton height={30} />
            </div>
            <div className="col-2">
                <Skeleton height={30} />
            </div>
            <div className="col-auto">
                <IconButton
                    className="invisible"
                    icon={"fa-trash"}
                />
            </div>
        </FormRow>}
        {data && data.length > 0 && <FormRow className={"text-end"}>
            <div className="col-1 offset-8">
                <b><NumericFormat value={taxFreeCashTotal} prefix={"£"} thousandSeparator displayType="text" /></b>
            </div>
            <div className="col-2">
                <b><NumericFormat value={taxableIncomeTotal} prefix={"£"} thousandSeparator displayType="text" /></b>
            </div>
        </FormRow>}
    </FormRow>
};

export default TaxYearEndWithdrawals;
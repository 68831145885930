import { Spinner } from "../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import { EditableTable } from "../../components/tables";
import useFundRules from "./hooks/useFundRules";

const FundRulesTable = () => {
    const {
        fundRules,
        isLoading,
        isFetching,
        isError,
        refetch,
        createFundRule,
        isCreatingFundRule,
        patchRuleClassification,
        patchFundRule,
        columns,
    } = useFundRules();

    if (isLoading || isFetching)
        return <Spinner />

    if (isError)
        return <ErrorMessageWrapper>
            <ErrorMessage
                retryCallback={refetch}
            />
        </ErrorMessageWrapper>

    return <>
        <EditableTable
            columns={columns}
            data={fundRules ?? []}
            idProperty={"ruleId"}
            createFn={createFundRule}
            isCreating={isCreatingFundRule}
            patchRowFn={patchFundRule}
            typeDescription={"Fund Rules"}
            rowComponentProps={{ customCellProps: { fullData: fundRules, patchClassification: patchRuleClassification } }}
        />
    </>
}

export default FundRulesTable;
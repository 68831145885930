import React from "react";
import withProviders from "../../hooks/withProviders";
import useReportTemplatesData, { ReportTemplateProvider } from "../../features/reporttemplatemanagementtable/hooks/ReportTemplatesContext";
import CreateReportTemplateModal from "../../features/createreporttemplatemodal";
import ReportTemplateManagementTable from "../../features/reporttemplatemanagementtable";
import { Button } from "../../components";
import { ClientReportManagementPageSubHeader } from "../../components/utilities";

const ReportTemplateManagementPage = () => {
    const [isCreateShown, setIsCreateShown] = React.useState(false);
    const { clear } = useReportTemplatesData();

    const handleOnTemplateCreated = (_) => clear();

    return (
        <React.Fragment>
            <CreateReportTemplateModal 
                handleClose={() => setIsCreateShown(false)}
                handleOnCreated={handleOnTemplateCreated}
                show={isCreateShown}
            />
            <ClientReportManagementPageSubHeader>
                <div className="d-flex justify-content-end align-items-center">
                    <Button 
                        variant="success" 
                        className="ms-2" 
                        onClick={() => setIsCreateShown(true)}
                    >
                        Create new Template
                    </Button>
                </div>
            </ClientReportManagementPageSubHeader>
            <section className="px-4 my-3 h-100">
                <ReportTemplateManagementTable />
            </section>
        </React.Fragment>
    );
};

export default withProviders(ReportTemplateProvider)(ReportTemplateManagementPage);
import React from "react";
import { useAuth } from "react-oidc-context";
import { useSendApplicationMessageMutation } from "../../services/hoapplicationmessages";
import DraftMessage from "./components/DraftMessage";
import EditableMessage from "./components/EditableMessage";
import MessageBox from "./components/MessageBox";
import NewMessage from "./components/NewMessage";
import ReadOnlyMessage from "./components/ReadOnlyMessage";
import useMessages from "./hooks/useMessages";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import useMessageUsers from "./hooks/useMessageUsers";
import { FormCheck } from "../../components/forms";
import { CenterArea, ContentWrapper, ThemedSpan } from "../../components/utilities";
import { IconButton } from "../../components";
import { FormLabel } from "react-bootstrap";
import { Spinner } from "../../components/loaders";

const ApplicationMessages = () => {
    const { user: { profile } } = useAuth();
    const {
        results,
        totalCount,
        totalPages,
        active,
        page,
        isLoading,
        isFetching,
        isError,
        error,
        createMessage,
        deleteMessage,
        nextPage,
        patchMessage,
        reply,
        retryFetchMessages,
        sendMessage,
        setActive,
        patchThread,
        toggleMessageIsDone,
        updateMessageRecipients
    } = useMessages();

    const handleOnDelete = (messageId, params) => deleteMessage(messageId, params);

    const handleOnDone = (messageId, newValue, params) => toggleMessageIsDone(messageId, newValue, params);

    const handleOnPatch = (messageId, property, newValue, params) => patchMessage(messageId, property, newValue, params);

    const handleOnReply = (message) => {
        const recipients = message.senderId === profile.sub
            ? message.recipients.filter(x => x.userId !== profile.sub)
            : [...message.recipients.filter(x => x.userId !== profile.sub), message.sender];

        let currentUserId = profile.sub.replace(/-/gi, '');
        const newRecipients = message.senderId === currentUserId
            ? message.recipients.filter(x => x.userId !== currentUserId)
            : [...message.recipients.filter(x => x.userId !== currentUserId), { userId: message.senderId }];

        createMessage({ recipients: newRecipients, threadId: message.threadId, message: "" });
    };

    const handleOnSend = (messageId) => sendMessage(messageId);

    const handleOnUpdateRecipients = (messageId, recipients, params) => updateMessageRecipients(messageId, recipients, params);

    if (isLoading) {
        return <CenterArea>
            <Spinner />
        </CenterArea>
    }

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retryFetchMessages} />
        </ErrorMessageWrapper>;
    }

    return <div className="container-fluid p-3">
        <div className="row mb-3 d-flex justify-content-end px-3">
            {/* <div className="col-auto">
                <ThemedSpan>Show Active</ThemedSpan>
            </div> */}
            {/* <div className="col-auto">
                <FormCheck
                    isChecked={active}
                    onChange={() => setActive(prev => !prev)}
                />
            </div> */}
            <div className="col-auto">
                <IconButton
                    icon="fa-plus"
                    variant="success"
                    onClick={createMessage}
                />
            </div>
        </div>
        {results && results.map((message) => (
            message.sentOnDate
                ? <ReadOnlyMessage
                    key={message.messageId}
                    dateSent={message.sentOnDate}
                    message={message.message}
                    onReply={() => reply(message)}
                    recipients={message.recipients}
                    sender={message.senderName}
                    onDone={(value, params) => handleOnDone(message.messageId, value, params)}
                    messageId={message.messageId}
                    isDone={message.isDone}
                    doneByName={message.doneByName}
                    doneDate={message.doneDate}
                    subject={message.threadSubject}
                    priority={message.messagePriority}
                    isActionRequired={message.isActionRequired}
                />
                : <DraftMessage
                    key={message.messageId}
                    page={page}
                    initialMessage={message.message}
                    initialRecipients={message.recipients}
                    messageId={message.messageId}
                    active={false}
                    threadId={message.threadId}
                    priority={message.messagePriority}
                    isActionRequired={message.isActionRequired}
                    subject={message.threadSubject}
                    onDelete={(params) => handleOnDelete(message.messageId, params)}
                    patchMessage={(messageId, property, newValue, params) => handleOnPatch(messageId, property, newValue, params)}
                    patchThread={patchThread}
                    sendMessage={() => handleOnSend(message.messageId)}
                    updateMessageRecipients={handleOnUpdateRecipients}

                />
        ))}

        <div className="d-flex justify-content-center align-items-end mt-2">
            {totalPages !== page && totalPages > 1 && <a href="#" onClick={nextPage}>Load more...</a>}
        </div>
    </div>;
};

export default ApplicationMessages;
import { useReadUserMessageMutation, useUnreadUserMessageMutation } from "../../../services/messages/userMessagesApiEndpoints";
import { usePatchRecipientByUserIdMutation } from "../../../services/messages";
import { patchReplace } from "../../../helpers/patchDoc";
import { useMessageManagementContext } from "../../../hooks/MessageManagementContext";

const useMessageRow = (messageId) => {

    const { userId } = useMessageManagementContext();
    const [readTrigger, readResult] = useReadUserMessageMutation();
    const [unreadTrigger, unreadResult] = useUnreadUserMessageMutation();
    const [updateRecipientTrigger, updateRecipientResult] = usePatchRecipientByUserIdMutation();

    const readUserMessage = (request) => {
        return new Promise((resolve, reject) => {
            return readTrigger({ userId, messageId, request })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const unreadUserMessage = (request) => {
        return new Promise((resolve, reject) => {
            return unreadTrigger({ userId, messageId, request })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const updateRecipient = (property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return updateRecipientTrigger({ userId, messageId, operations })
                .unwrap()
                .then(resolve, reject);
        });
    };

    return {
        readUserMessage,
        unreadUserMessage,
        updateRecipient,
        readResult,
        unreadResult,
        updateRecipientResult
    };
};

export default useMessageRow;
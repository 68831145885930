import React from "react";
import FormSelect from "../FormSelect";
import { useFetchAvailableDivisionsQuery } from "../../../services/identity";

const GenericUserDivisionSelect = ({ options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchAvailableDivisionsQuery();
    
    const dataOptions = React.useMemo(() => {
        if (!data) {
            return [];
        }

        return data.map((el) => {
            return {
                label: el.name,
                value: el.divisionId,
                isDiabled: false,
                data: el
            };
        })
    }, [ data ]);

    return <FormSelect
        options={dataOptions}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default GenericUserDivisionSelect;
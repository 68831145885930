import React from "react";
import VariableSizeInfiniteChatList from "../../../components/messages/bubbles/VariableSizeInfiniteChatList";
import useReportMessages from "../hooks/useReportMessages";
import { useReportHistory } from "../hooks/ReportHistoryContext";

const ReportMessagesList = () => {
    const { report: { masterAccountId, id } } = useReportHistory();
    const { 
        clear: refreshTable,
        data,
        error,
        isLoading,
        isNextPageLoading,
        isRowLoaded,
        loadMore,
        totalCount
    } = useReportMessages(masterAccountId, id);

    return (
        <React.Fragment>
            <VariableSizeInfiniteChatList 
                error={error}
                retry={refreshTable}
                itemCount={totalCount}
                itemData={data}
                loadMoreItems={loadMore}
                isLoading={isLoading}
                isFetching={isNextPageLoading}
                hasItemLoaded={isRowLoaded}
            />
        </React.Fragment>
    );
};

export default ReportMessagesList;
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react"
import { useAuth } from "react-oidc-context";
import { useLazyGetReminderTaskQuery, useLazySearchReminderTasksQuery } from "../../../services/reminders";

const PersonalRemindersContext = React.createContext();

export const usePersonalReminders = () => React.useContext(PersonalRemindersContext);

const PersonalRemindersProvider = ({ children }) => {

    const [selectedReminderId, setSelectedReminderId] = useState(null);
    const [cacheKey, setCacheKey] = useState(new Date().valueOf());
    const [isClearing, setIsClearing] = useState(false);
    const [sortAscending, setSortAscending] = useState(false);
    const limit = 50;
    const { user: { profile } } = useAuth();

    const defaultFilters = useMemo(() => ({
        masterAccount: null,
        project: null,
        showCancelled: false,
        users: [{ label: profile.name, value: profile.sub.replace(/-/g, "") }],
        deadlineDateTo: null,
        deadlineDateFrom: null,
        description: null,
        priorities: null,
        types: null
    }), [profile]);

    const [filter, setFilter] = useState(defaultFilters);
    const [search, searchResults] = useLazySearchReminderTasksQuery();
    const { data, error, isError, isFetching, isLoading, isSuccess, isUninitialized } = searchResults || {};
    const { next, totalCount, results } = data || { next: null, totalCount: limit, results: [] }

    const [getReminder, getReminderResults] = useLazyGetReminderTaskQuery();
    const { currentData: selectedReminder, isFetching: isFetchingReminder, error: reminderError } = getReminderResults || {};

    useEffect(() => {
        if (!selectedReminderId) return;

        getReminder({ reminderId: selectedReminderId });
    }, [getReminder, selectedReminderId]);

    const clear = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        if (isUninitialized) return;

        setIsClearing(true);

        let newCacheKey = new Date().valueOf();

        search({
            next: null,
            cacheKey: newCacheKey,
            limit,
            sortAscending,
            filter: {
                ...filter,
                masterAccountId: filter.masterAccount?.value,
                projectId: filter.project?.value,
                userIds: filter.users?.map(x => x.value),
                typeIds: filter.types
            }
        })
            .unwrap()
            .then(
                r => {
                    setCacheKey(newCacheKey);
                    
                }
            ).finally(() => setIsClearing(false));
    };

    const clearFilter = (e) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }
        setFilter((_) => defaultFilters);
    };

    const hasItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => search({
        next,
        limit,
        sortAscending,
        cacheKey,
        filter: {
            ...filter,
            masterAccountId: filter.masterAccount?.value,
            projectId: filter.project?.value,
            userIds: filter.users?.map(x => x.value),
            typeIds: filter.types
        }
    });

    const setNewFilter = ({ newFilter }) => {
        setFilter(newFilter);
    };

    useEffect(clear, [filter, sortAscending]);

    return <PersonalRemindersContext.Provider value={{
        results,
        error,
        isClearing,
        isError,
        isFetching,
        isLoading,
        cacheKey,
        noResults: isSuccess && totalCount === 0,
        selectedReminder: selectedReminderId ? selectedReminder : null,
        isFetchingReminder,
        reminderError,
        sortAscending,
        totalCount,
        setSelectedReminderId,
        setSortAscending,
        clear,
        loadMoreItems,
        hasItemLoaded,
        filter: {
            defaultFilters,
            isFiltersApplied: !_.isEqual(filter, defaultFilters),
            filter,
            clearFilter,
            setFilter,
            setNewFilter,
        }
    }}>
        {children}
    </PersonalRemindersContext.Provider>
};

export default PersonalRemindersProvider;
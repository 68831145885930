import React, { useMemo } from "react";
import MomentComponent from "react-moment";
import Skeleton from "react-loading-skeleton";
import { CenterFormColumnComponent } from "../../../components/utilities";
import {
    DateInput,
    FormInput,
    FormLabel,
    FormTextArea,
} from "./../../../components/forms/";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import useApplication from "../hooks/useApplication";
import ProductSelect from "./ProductSelect";
import DesignationSelect from "./DesignationSelect";
import HeadOfficeApplicationStatusSelect from "../../../components/forms/selects/HeadOfficeApplicationStatusSelect";
import { useAuth } from "react-oidc-context";
import ApplicationPolicySummary from "./ApplicationPolicySummary";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";

const ApplicationSummary = () => {
    const { user: { profile } } = useAuth();
    const { applicationId, application: { isRaiseFee, productId } } = useHeadOfficeApplicationContext();

    const { data: application, isError, isLoading, error, retry, updateApplication } = useApplication(applicationId);
    const disableProperties = useMemo(() => !profile?.roles?.includes("ho_app_prop"), [profile]);

    const onFieldChanged = (property, value) => updateApplication(property, value);

    if (isLoading === true) {
        return (
            <div>
                <div className="row my-2">
                    <div className="col">
                        <Skeleton height={37} />
                    </div>
                    <div className="col">
                        <Skeleton height={37} />
                    </div>
                    <div className="col">
                        <Skeleton height={37} />
                    </div>
                    <div className="col">
                        <Skeleton height={37} />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <Skeleton height={37} />
                    </div>
                    <div className="col">
                        <Skeleton height={37} />
                    </div>
                    <div className="col">
                        <Skeleton height={37} />
                    </div>
                    <div className="col">
                        <Skeleton height={37} />
                    </div>
                </div>
            </div>
        );
    }

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retry} />
        </ErrorMessageWrapper>
    }

    if (application?.isMortgage || application?.isProtection) {
        return <ApplicationPolicySummary
            application={application}
            disableProperties={disableProperties}
            patchApplication={updateApplication}
        />;
    }

    return (
        <div class="row mb-2">
            <div class="col">
                <div class="row mb-4">
                    <div className="col-3">
                        <HeadOfficeApplicationStatusSelect
                            label="Status"
                            isMulti={false}
                            defaultValue={application?.status}
                            onBlur={(value) =>
                                onFieldChanged("status", value.value)
                            }
                        />
                    </div>
                    <div className="col-2">
                        <DateInput
                            label="Status Date"
                            value={application?.applicationStatusDate}
                            onBlur={(value) =>
                                onFieldChanged(
                                    "applicationStatusDate",
                                    value
                                )
                            }
                            isClearable
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            id="comment"
                            label="Comment"
                            value={application?.comment}
                            placeholder="Comment"
                            onBlur={(value) =>
                                onFieldChanged("comment", value)
                            }
                        />
                    </div>
                    {application?.statusUpdatedBy != null &&
                        application?.statusUpdatedDate != null && (
                            <CenterFormColumnComponent className="col-md-2">
                                <FormLabel>
                                    {application?.statusUpdatedBy}
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY h:mm a"
                                    >
                                        {application?.statusUpdatedDate}
                                    </MomentComponent>
                                </FormLabel>
                            </CenterFormColumnComponent>
                        )}
                </div>
                {!(isRaiseFee && !productId) && <div class="row mb-4">
                    <div className="col-6">
                        <ProductSelect
                            label="Product"
                            defaultValue={application?.productId}
                            isDisabled={disableProperties}
                            onBlur={({ value }) => onFieldChanged("productId", value)}
                        />
                    </div>
                    <div className="col-3">
                        <DesignationSelect
                            label="Designation"
                            isDisabled={disableProperties}
                            masterAccountId={application?.masterAccountId}
                            defaultValue={application?.designationId}
                            onBlur={(value) =>
                                onFieldChanged("designationId", value.value)
                            }
                        />
                    </div>
                    <div className="col-3">
                        <FormInput
                            label={"Provider Reference"}
                            disabled={disableProperties}
                            value={application?.providerReference}
                            onBlur={(value) => onFieldChanged("providerReference", value)}
                        />
                    </div>
                </div>}
                <div class="row mb-4">
                    <div className="col">
                        <FormTextArea
                            value={application?.notes}
                            minRows={3}
                            maxRows={3}
                            onBlur={(value) =>
                                onFieldChanged("notes", value)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationSummary;

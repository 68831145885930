import React, { useCallback, useState } from "react";
import { useLazySearchMasterAccountDocumentsQuery, usePatchDocumentPropertiesMutation } from "../services/clientdocuments";
import useOpenDocument from "./useOpenDocument";

const ClientDocumentsContext = React.createContext();

export const useClientDocuments = () => React.useContext(ClientDocumentsContext);

export const ClientDocumentsProvider = ({ children, masterAccountId, filter }) => {
    // setup state and our query state (does not load by default (lazy))
    const [sort, setSort] = React.useState("DateUploaded+desc");
    const [limit, setLimit] = React.useState(50);
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [load, { data, isLoading, isFetching, isError, ...loadResults }] = useLazySearchMasterAccountDocumentsQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true;

    const clear = () => {
        if (!masterAccountId || isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        load({
            ...filter,
            cacheKey: newCacheKey,
            masterAccountId,
            sort,
            page: 1,
            limit
        }).unwrap().then(_ => {
            setCacheKey(_prev => newCacheKey);
            setIsLoadingState(_prev => false);
        });
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

         // load the next page of results
        setIsLoadingState(_ => true);
        load({ ...filter, cacheKey, masterAccountId, sort, page: page + 1, limit }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
        });
    };

    const sortTable = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";
        let [sortProperty, direction] = sort.split("+");

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    React.useEffect(clear, [ filter, sort ]);

    const { openDocument, isFetching: isOpening } = useOpenDocument();

    const [selectedRow, setSelectedRow] = useState(null);

    const [patchDocumentTrigger, { isLoading: isPatching }] = usePatchDocumentPropertiesMutation();

    const patchDocument = useCallback((documentId, operations) => 
        patchDocumentTrigger({ documentId, operations }).unwrap(),
        [patchDocumentTrigger]);

    return (
        <ClientDocumentsContext.Provider value={{
            ...loadResults,
            clear,
            data: isError ? [] : results,
            hasMore,
            isFetching,
            isLoading,
            isNextPageLoading,
            isRowLoaded,
            limit,
            loadMore: isNextPageLoading ? () => {} : loadMore, 
            openDocument: isOpening ? () => {} : openDocument,
            page,
            pagination,
            sort,
            sortTable,
            totalCount,
            totalPages,
            selectedRow,
            setSelectedRow,
            patchDocument,
            isPatching
            // updateClientReadable,
            // updateFunction,
            // updateProject,
            // updateDescription
        }}>
            {children}
        </ClientDocumentsContext.Provider>
    );
};
import React from "react";
import FormSelect from "../FormSelect";

const MasterAccountStatusSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={[
            { label: 'Active Enquiry', value: 0 },
            { label: 'Expired Enquiry', value: 1 },
            { label: 'Active Client', value: 2 },
            { label: 'New IFA', value: 3 },
            { label: 'Deceased', value: 4 },
            { label: 'Ex-Client', value: 5 },
            { label: 'Disposed', value: 6 },
            { label: 'No Ongoing Service', value: 7 },
        ]}
        {...rest}
    />
};

export default MasterAccountStatusSelect;
import React from "react";
import useReportTemplatesData from "./hooks/ReportTemplatesContext";
import TableHeader from "./components/TableHeader";
import TableRow from "./components/TableRow";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";

const ReportTemplateManagementTable = () => {
    const {
        clear: refreshTable,
        data,
        error,
        isNextPageLoading,
        isNewRecords,
        isRowLoaded,
        loadMore,
        newRecordsSinceCache,
        sort,
        sortTable,
        totalCount
    } = useReportTemplatesData();
    const isNewRecordsDisplayed = isNewRecords && !isNextPageLoading;

    return (
        <React.Fragment>
            <VirtualTable
                enableScrollTracking={true}
                row={TableRow}
                header={(
                    <thead>
                        <tr>
                            <TableHeader property="description" sort={sort} sortTable={sortTable}>Description</TableHeader>
                            <TableHeader property="createdDate" sort={sort} sortTable={sortTable}>Created</TableHeader>
                            <TableHeader property="createdBy" sort={sort} sortTable={sortTable}>Created By</TableHeader>
                            <TableHeader property="isActive" sort={sort} sortTable={sortTable}>Active</TableHeader>
                            <th className="sticky-header">&nbsp;</th>
                        </tr>
                    </thead>
                )}
                footer={
                    <TableRecordCountDisplayFooter 
                        totalCount={totalCount}
                        isNewRecordsDisplayed={isNewRecordsDisplayed}
                        newRecordsSinceCache={newRecordsSinceCache}
                        reload={refreshTable}
                    />
                }
                error={error}
                isLoading={isNextPageLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                itemCount={totalCount}
                itemData={data}
            />
        </React.Fragment>
    )
};

export default ReportTemplateManagementTable;
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchFactFindAssetsSectionQuery, usePatchAssetsSectionMutation } from "../../../services/clientfactfind";
import useNotes from "./useNotes";


const useAssetsSection = () => {

    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isFetching, isError, error, refetch, isUninitialized } = useFetchFactFindAssetsSectionQuery({ masterAccountId });
    const [patch] = usePatchAssetsSectionMutation();

    const { updateNotes: patchAssetNotes } = useNotes();

    const patchAssetsSection = (assetsSectionId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return patch({ assetsSectionId, operations, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        data,
        isLoading: isFetching || isLoading || isUninitialized,
        isError,
        error,
        refetchAssetsSection: refetch,
        patchAssetsSection,
        patchAssetNotes
    };
}

export default useAssetsSection;
import React from "react";
import styled from "styled-components";

const AppErrorPage = ({ errorMessage = null }) => {
    return (
        <div>
            <h1>Error</h1>
            <p>There has been an error loading this website. Please contact Support.</p>
        </div>
    )
};

export default AppErrorPage;
import React, { useState } from "react";
import Button from "../../../../../components/buttons";
import { Modal } from "react-bootstrap";
import { BooleanSelect } from "../../../../../components/forms/selects";
import {
    DateInput
} from "../../../../../components/forms/";


const AjbServiceFeesBilledReportModal = ({
    confirmButtonLabel = "Run Report",
    confirmButtonVariant = "success",
    handleAjbServiceFeesBilledReportArgsClose,
    handleAjbServiceFeesBilledReportArgsConfirm,
    ajbServiceFeesBilledReportArgsShow,
    ...rest
}) => {

    const [theFromDate, setTheFromDate] = useState(null);
    const [theToDate, setTheToDate] = useState(null);
    const [includeProofs, setIncludeProofs] = useState(false);

    const [errorMessage, setErrorMessage] = React.useState("");
 


    const onClose = () => {
        if (handleAjbServiceFeesBilledReportArgsClose && typeof handleAjbServiceFeesBilledReportArgsClose === "function") {
            setErrorMessage("");
            setTheFromDate(null);
            setTheToDate(null);
            setIncludeProofs(false);
            handleAjbServiceFeesBilledReportArgsClose();
        }
    }

    const onConfirm = () => {
        if(theFromDate !== null && theToDate !== null){
            if (handleAjbServiceFeesBilledReportArgsConfirm && typeof handleAjbServiceFeesBilledReportArgsConfirm === "function") {
                const theRequest = { fromDate: theFromDate, toDate: theToDate, includeProofs: includeProofs };
                handleAjbServiceFeesBilledReportArgsConfirm(theRequest);
            }
            onClose();
        }
        else{
            setErrorMessage("From and To dates must be selected!");
        }
    }
    return (
        <Modal size="med" centered backdrop="static" show={ajbServiceFeesBilledReportArgsShow}>
            <Modal.Header>AJB Service Fees Billed Report</Modal.Header>
            <Modal.Body {...rest}>
            <div className="row mb-3">
                    <div className="col">
                        <DateInput
                            label="From"
                            value={theFromDate}
                            onBlur={(value) => setTheFromDate(value)}
                        />
                    </div>
                    <div className="col">
                        <DateInput
                            label="To"
                            rules={{ required: true }}
                            value={theToDate}
                            onBlur={(value) => setTheToDate(value)}
                        />
                    </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <BooleanSelect
                        label="Include Proofs"
                        defaultValue={false}
                        onChange={(e) => setIncludeProofs(e.value)}
                    />
                </div>
            </div>
            <div className="row mb-3">
            <div className="text-warning"> {errorMessage} </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmButtonVariant} onClick={onConfirm}>{confirmButtonLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                
            </Modal.Footer>
        </Modal>
    );

};

export default AjbServiceFeesBilledReportModal;
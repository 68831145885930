import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import MomentComponent from "react-moment";
import IconButton from "./buttons/IconButton";
import styled from "styled-components";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const DataColumn = ({
    className,
    propertyKey,
    data,
    icon,
    onClick,
    link,
    dateFormat = "HH:mm, DD/MM/YYYY",
    dateFromNow = false
}) => {


    if (icon) {
        return <Col className={className}>
            <IconButton
                icon={icon.type}
                size={icon.size}
                variant={icon.variant}
                onClick={icon.onClick}
            />
        </Col>
    }
    else {
        let strings = propertyKey.split('.');
        strings.forEach(key => {
            if (data) {
                data = data[key];
            }

        });
    }

    //if you can parse the string to a date, use the formatter if provided
    if (moment(data, moment.ISO_8601).isValid()) {
        return <MomentComponent
            className={className}
            interval={60000}
            format={dateFromNow ? null : dateFormat}
            fromNow={dateFromNow}
        >
            {data}
        </MomentComponent>
    }

    if (link) {
        return (
            <OnClickColumn
                className={className}
                onClick={link}
            >
                <u>
                    {data}
                </u>
            </OnClickColumn>
        )
    }

    if (onClick) {
        return (
            <OnClickColumn
                className={className}
                onClick={onClick}
            >
                {data}
            </OnClickColumn>
        )
    }

    return (
        <Col
            className={className}
        >
            {data}
        </Col>
    );
};

export default DataColumn;


const OnClickColumn = styled(Col)`
    :hover {
        cursor: pointer;
    }
`;
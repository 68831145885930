import { useCallback } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { 
    useCreateClientConnectionMutation, 
    useDeleteClientConnectionMutation, 
    useLazyGetClientConnectionQuery,
    usePatchClientConnectionMutation
} from "../../../services/clientdetails";

const useClientConnection = () => {
    const { id: masterAccountId } = useClientContext();
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateClientConnectionMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteClientConnectionMutation();
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchClientConnectionMutation();
    const [get, { data: connection, isLoading, isFetching, isUninitialized, error, isError }] = useLazyGetClientConnectionQuery();

    const createClientConnection = () => {
        return new Promise((resolve, reject) => {
            let data = {
                masterAccountId,
                relationship: null,
                title: '',
                forenames: '',
                surname: '',
                position: '',
                salutation: '',
                address: '',
                phoneNumber: '',
                emailAddress: '',
                notes: '',
                isActiveConnection: true
            };

            return create({ masterAccountId, data }).unwrap().then(
                r => resolve({
                    connection: {
                        connectionId: r.connectionId,
                        ...data
                    },
                    connectionId: r.connectionId,
                }),
                e => reject(e)
            );
        });
    };

    const deleteConnection = (connectionId) => {
        return new Promise((resolve, reject) => {
            return remove({ connectionId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const getClientConnection = useCallback((connectionId) => {
        return new Promise((resolve, reject) => {
            get({ masterAccountId, connectionId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    }, [get, masterAccountId]);

    const updateConnection = (connectionId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];
            return patch({ connectionId, masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        connection,
        createClientConnection,
        createError,
        deleteConnection,
        deleteError: removeError,
        error,
        getClientConnection,
        isCreating,
        isError,
        isErrorCreating,
        isErrorRemoving,
        isErrorUpdating,
        isLoading: isLoading || isFetching || isCreating,
        isRemoving,
        isUpdating,
        isUninitialized,
        removeError,
        updateError,
        updateConnection
    };
};

export default useClientConnection;
import React, { useMemo, useEffect, useState } from "react";
import InstructionRow, { InstructionRowHeaders } from "./InstructionRow";
import CashSourceRow from "./CashSourceRow";
import { ThemedSpan } from "../../../components/utilities";
import { NumericFormat } from "react-number-format";
import InstructionLabelRow from "./InstructionLabelRow";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";

const NewInvestmentExceptIsaCash = ({ data, updateFn, cashSourceUpdateFn, showCashUpdatedModal, showRecalculateTransactionsModal, setTotalCashRaised, deductAdviceFeeFromRemainingBalance }) => {
    const { application: { hasBuyTransactions } } = useHeadOfficeApplicationContext();

    const totalCashValue = useMemo(() => {
        return data.cashSources.reduce((total, curr) => total + curr.cashAmount, 0)
            + data.cashFromClientAmount
            + data.cashFromEmployerAmount
            + data.cashFromTaxReclaimAmount;
    }, [data]);

    const remainingBalance = useMemo(() => {
        var balance = totalCashValue - (
            + (deductAdviceFeeFromRemainingBalance ? data.deductAdviceFeeAmount : 0)
            + data.investAmount
        );

        return balance;
    }, [data]);

    const [initialTotalCashValue, setInitialTotalCashValue] = useState(totalCashValue);

    const [isInvestmentInitiallyEqualToCashRaised, setIsInvestmentInitiallyEqualToCashRaised] = useState(totalCashValue === data.investAmount);

    useEffect(() => {
        setTotalCashRaised(totalCashValue);
    }, [totalCashValue, setTotalCashRaised]);

    useEffect(() => {
        if (totalCashValue === initialTotalCashValue) return;
        // these will (rightfully) not update in time for the showCashUpdatedModal() call, but the change will reflect for the next run
        // this is the desired behaviour here as we want the values before the update to be passed in
        setInitialTotalCashValue(totalCashValue);
        setIsInvestmentInitiallyEqualToCashRaised(totalCashValue === data.investAmount);
        showCashUpdatedModal(isInvestmentInitiallyEqualToCashRaised);
    }, [totalCashValue]);

    return <React.Fragment>
        <div className="container-fluid mb-4">
            <CashSourceRow
                data={data}
                updateFn={updateFn}
                label={"Cash from Client"}
                appliesProperty={"cashFromClientApplies"}
                cashAmountProperty={"cashFromClientAmount"}
                receivedDateProperty={"cashFromClientReceivedDate"}
                receivedConfirmedByNameProperty={"cashFromClientReceivedConfirmedByName"}
                commentProperty={"cashFromClientComment"}
                receivedConfirmedSetDateProperty={"cashFromClientReceivedConfirmedSetDate"}
            />

            <CashSourceRow
                data={data}
                updateFn={updateFn}
                label={"Cash from Employer"}
                appliesProperty={"cashFromEmployerApplies"}
                cashAmountProperty={"cashFromEmployerAmount"}
                receivedDateProperty={"cashFromEmployerReceivedDate"}
                receivedConfirmedByNameProperty={"cashFromEmployerReceivedConfirmedByName"}
                commentProperty={"cashFromEmployerComment"}
                receivedConfirmedSetDateProperty={"cashFromEmployerReceivedConfirmedSetDate"}
            />

            {
                data.cashSources && data.cashSources.map((source) => <CashSourceRow
                    key={source.sourceId}
                    data={source}
                    updateFn={(property, newValue, appliesProperty) => cashSourceUpdateFn(source.sourceId, property, newValue, appliesProperty)}
                    label={`Cash from ${source.providerReference}`}
                    appliesProperty={"cashApplies"}
                    cashAmountProperty={"cashAmount"}
                    receivedDateProperty={"cashReceivedDate"}
                    receivedConfirmedByNameProperty={"cashReceivedConfirmedByName"}
                    commentProperty={"comment"}
                    receivedConfirmedSetDateProperty={"cashReceivedConfirmedSetDate"}
                />)
            }

            <CashSourceRow
                data={data}
                updateFn={updateFn}
                isTaxReclaim
                label={"Tax Reclaim"}
                appliesProperty={"cashFromTaxReclaimApplies"}
                cashAmountProperty={"cashFromTaxReclaimAmount"}
                receivedDateProperty={"cashFromTaxReclaimReceivedDate"}
                receivedConfirmedByNameProperty={"cashFromTaxReclaimReceivedConfirmedByName"}
                commentProperty={"cashFromTaxReclaimComment"}
                receivedConfirmedSetDateProperty={"cashFromTaxReclaimReceivedConfirmedSetDate"}
            />

            <div className="row mt-3">
                <div className="col-2">
                    <b><ThemedSpan>Total Cash</ThemedSpan></b>
                </div>
                <div className="col-2 text-end">
                    <b><NumericFormat value={totalCashValue} prefix={"£"} thousandSeparator displayType="text" decimalScale={2} fixedDecimalScale /></b>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <InstructionRowHeaders />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={"Deduct Advice Fee"}
                appliesProperty={"deductAdviceFeeApplies"}
                cashAmountProperty={"deductAdviceFeeAmount"}
                dateInstructedProperty={"deductAdviceFeeAmountInstructedDate"}
                instructedDateSetProperty={"deductAdviceFeeAmountInstructedSetDate"}
                instructedByProperty={"deductAdviceFeeAmountInstructedByName"}
                dateConfirmedProperty={"deductAdviceFeeConfirmedDate"}
                confirmedDateSetProperty={"deductAdviceFeeConfirmedSetDate"}
                confirmedByProperty={"deductAdviceFeeConfirmedByName"}
            />

            <InstructionRow
                data={data}
                updateFn={updateFn}
                label={hasBuyTransactions ? "Net Amount to Invest" : "Retain In Cash"}
                appliesProperty={"investAmountApplies"}
                cashAmountProperty={"investAmount"}
                dateInstructedProperty={"investAmountInstructedDate"}
                instructedDateSetProperty={"investAmountInstructedSetDate"}
                instructedByProperty={"investAmountInstructedByName"}
                dateConfirmedProperty={"investAmountConfirmedDate"}
                confirmedDateSetProperty={"investAmountConfirmedSetDate"}
                confirmedByProperty={"investAmountConfirmedByName"}
                recalculateTransactionsButton
                onRecalculateTransactionsClick={() => showRecalculateTransactionsModal(true)}
            />
            <InstructionLabelRow
                className={"mt-4 mb-2"}
                label={<ThemedSpan variant={remainingBalance !== 0 ? "danger" : null}>Remaining Balance</ThemedSpan>}
                value={<NumericFormat
                    className={remainingBalance !== 0 ? "text-danger" : null}
                    value={remainingBalance}
                    prefix={"£"}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />}
            />
        </div>
    </React.Fragment>
};

export default NewInvestmentExceptIsaCash;
import _ from "lodash";
import React from "react";
import toast from "react-hot-toast";
import useCreateUserDelegate from "../hooks/useCreateUserDelegate";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GenericUserSelect } from "../../../components/forms/selects";
import { FormCheck } from "../../../components/forms";

export const CreateUserDelegateModalButton = ({ className, variant = "success", ...rest }) => (
    <div className={className}>
        <Button variant={variant} {...rest}>
            <FontAwesomeIcon className="me-1" icon="fa-plus" />
            New Delegate Access
        </Button>
    </div>
);

const CreateUserDelegateModal = ({ 
    show,
    handleClose = () => {},
    onCreatedEvent = () => {}
}) => {
    const { create, defaultValues, isLoading } = useCreateUserDelegate();
    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    const _close = () => {
        reset(defaultValues);
        handleClose();
    };

    const _submit = (data) => {
        toast.promise(create(data), {
            loading: 'Creating delegate access...',
            success: (r) => {
                onCreatedEvent(r);
                return `Delegate Access has been created successfully.`;
            },
            error: (err) => {
                return `There was a problem trying to create delegate access.`;
            }
        }).finally(_close);
    };

    return (
        <Modal size="lg" centered backdrop="static" show={show} onHide={_close}>
            <Modal.Header>
                <Modal.Title>Create new Delegate Access</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(_submit)}>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col">
                            <Controller 
                                name="userId"
                                control={control}
                                rules={{ required: 'A user must be selected' }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect 
                                        label="User (who is giving access)"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        errorMessage={errors?.userId?.message} 
                                        errorAllowRetry={false} 
                                        onChange={(values) => setValue('userId', values.value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller 
                                name="delegatedUserId"
                                control={control}
                                rules={{ required: 'A delegate user must be selected' }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect 
                                        label="Delegate User (who is getting access)"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        errorMessage={errors?.delegatedUserId?.message} 
                                        errorAllowRetry={false} 
                                        onChange={(values) => setValue('delegatedUserId', values.value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-4">
                            <Controller 
                                name="readCalendar"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Read Calendar"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('readCalendar', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="writeCalendar"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Write Calendar"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('writeCalendar', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="readMail"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Read Mail"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('readMail', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="writeMail"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Write Mail"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('writeMail', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="sendMail"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Send Mail"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('sendMail', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="readMessages"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Read Messages"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('readMessages', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="writeMessages"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Write Messages"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('writeMessages', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="readReminders"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Read Reminders"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('readReminders', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="writeReminders"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Write Reminders"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('writeReminders', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller 
                                name="hasClientAccess"
                                control={control}
                                rules={{ }}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FormCheck 
                                        type="checkbox"
                                        label="Client Access"
                                        isChecked={value}
                                        disableAnimations={true}
                                        onChange={(_) => setValue('hasClientAccess', !value)}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" disabled={isLoading === true}>Create</Button>
                    <Button variant="light" disabled={isLoading === true} onClick={_close}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateUserDelegateModal;
import { Modal, Spinner } from "react-bootstrap";
import { ErrorMessage, ErrorMessageWrapper } from "../messages";

const ViewPdfModal = ({
    title,
    data,
    show,
    handleClose,
    isLoading,
    error,
    refetch,
    headerProps = {},
    bodyProps = {},
    ...modalProps
}) => <Modal
    show={show}
    onHide={handleClose}
    size="xl"
    contentClassName={(isLoading || error) ? "" : "h-100"}
    dialogClassName="h-100 pb-5"
    backdrop='static'
    {...modalProps}>
        <Modal.Header closeButton {...headerProps}>{title}</Modal.Header>
        <Modal.Body {...bodyProps}>
            {isLoading || data == null
                ? <Spinner animation="border" className="d-flex mx-auto" />
                : error == null
                    ? <iframe src={`data:application/pdf;base64,${data}`} height="100%" width="100%" title={title} />
                    : <ErrorMessageWrapper>
                        <ErrorMessage
                            error={error}
                            retryCallback={refetch} />
                    </ErrorMessageWrapper>}
        </Modal.Body>
    </Modal>;

export default ViewPdfModal;
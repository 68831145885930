import React from "react";
import moment from "moment";
import Moment from "react-moment";
import ProjectReportsTableColumn from "./ProjectReportsTableColumn";
import ReportsTableActionColumn from "../../../components/tables/columns/ReportsTableActionColumn";
import useOpenDocument from "../../../hooks/useOpenDocument";
import { DateDisplay, ProfileDisplay } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";

const ProjectReportsTableRow = ({ data, project, dateFormat = "DD/MM/YYYY" }) => {
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const { target } = project;
    const {
        documentId,
        typeDescription,
        reportName,
        statusDescription,
        authorId,
        complianceUserId,
        compliantDate,
        sentDate,
        sentByMediaType,
        createdDate
    } = data;

    const calculateDateDisplayColourVariant = (dateToCompare) => {
        let displayDate = moment(dateToCompare || '');
        let projectDate = moment(target || '');

        if (projectDate.isBefore(displayDate, 'day')) {
            return 'danger';
        }
        else if (projectDate.isSameOrAfter(displayDate, 'day')) {
            return 'success';
        }
        else {
            return 'dark';
        }
    };

    const onOpenReport = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        if (isOpening)
            return;

        openDocument(documentId, `${reportName}.docx`);
    };

    return (
        <tr>
            <ProjectReportsTableColumn>
                <p className="m-0 lh-1">
                    <a href={`open?id=${documentId}`} onClick={onOpenReport}>{reportName}</a>
                    <br />
                    <ThemedSpan variant="muted" className="fs-smallest">{typeDescription}</ThemedSpan>
                </p>
            </ProjectReportsTableColumn>
            <ProjectReportsTableColumn>
                {statusDescription}
            </ProjectReportsTableColumn>
            <ProjectReportsTableColumn>
                {!authorId
                    ? "Not Assigned"
                    : (<ProfileDisplay userId={authorId} />)
                }
            </ProjectReportsTableColumn>
            <ProjectReportsTableColumn>
                {!complianceUserId
                    ? "Not Assigned"
                    : (<ProfileDisplay userId={complianceUserId} />)
                }
            </ProjectReportsTableColumn>
            <ProjectReportsTableColumn className="col-1">
                {!compliantDate && (<span>-</span>)}
                {compliantDate && <DateDisplay variant={calculateDateDisplayColourVariant(compliantDate)} format={dateFormat}>{compliantDate}</DateDisplay>}
            </ProjectReportsTableColumn>
            <ProjectReportsTableColumn className="col-1">
                {!sentDate && (<span>-</span>)}
                {sentDate && (<DateDisplay variant={calculateDateDisplayColourVariant(sentDate)} format={dateFormat}>{sentDate}</DateDisplay>)}
            </ProjectReportsTableColumn>
            <ProjectReportsTableColumn>
                {sentByMediaType ?? "-"}
            </ProjectReportsTableColumn>
            <ProjectReportsTableColumn>
                <Moment date={createdDate} format={dateFormat} />
            </ProjectReportsTableColumn>
            <ReportsTableActionColumn className="col-md-auto text-center" data={data} />
        </tr>
    );
};

export default ProjectReportsTableRow;
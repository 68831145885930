import React from "react";
import styled from "styled-components";
import ClientDocumentsTable from "../../features/clientdocumentstable";
import withClientDocumentsProvider from "../../features/clientdocumentstable/hooks/withClientDocumentsProvider";
import UploadDocumentModal, { UploadDocumentModalButton } from "../../features/uploaddocumentmodal";
import { ClientDocumentFiltersModal, ClientDocumentFiltersModalButton } from "../../features/clientdocumentsfilter";
import { FormInput } from "../../components/forms";
import { ClientLayoutPageSubHeader, Main, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import { useClientDocuments } from "../../hooks/ClientDocumentsContext";
import { useDocumentFilterContext, withDocumentFilterProvider } from "../../hooks/DocumentFilterContext";

const ClientdocumentFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const ClientDocumentsPage = () => {
    const { clear } = useClientDocuments();
    const { clearFilter, isFiltersApplied, setFilter, filter } = useDocumentFilterContext();
    const [isUploadShown, setIsUploadShown] = React.useState(false);
    const [isFiltersShown, setIsFiltersShown] = React.useState(false);
    const [formSearchTerm, setSearchTerm] = React.useState(filter.searchTerm);

    const onDocumentUploadedEvent = () => clear();

    React.useEffect(() => {
        const updateFilters = setTimeout(() => {
            if (filter.searchTerm !== formSearchTerm)
                setFilter({ ...filter, searchTerm: formSearchTerm });
        }, 500);
        return () => clearTimeout(updateFilters);
    }, [formSearchTerm])

    return (
        <React.Fragment>
            <ClientDocumentFiltersModal 
                handleClose={() => setIsFiltersShown(false)}
                show={isFiltersShown}
            />
            <UploadDocumentModal 
                handleClose={() => setIsUploadShown(false)}
                show={isUploadShown}
                onDocumentUploaded={onDocumentUploadedEvent}
            />
            <Main className="d-flex flex-column">
                <ClientLayoutPageSubHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            {isFiltersApplied && (
                                <React.Fragment>
                                    <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                    <ClientdocumentFiltersAppliedWarningMessage className="ms-2 text-muted">
                                        Warning - Filters are currently being applied to your results.
                                        <br />
                                        <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                    </ClientdocumentFiltersAppliedWarningMessage>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex flex-fill justify-content-end align-items-center">
                            <FormInput
                                placeholder="Search for a Document."
                                value={formSearchTerm}
                                disableAnimations={true}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <ClientDocumentFiltersModalButton  variant="success" className="ms-2" onClick={() => setIsFiltersShown(true)} />
                            <UploadDocumentModalButton variant="success" className="ms-2" onClick={() => setIsUploadShown(true)} />
                        </div>
                    </div>
                </ClientLayoutPageSubHeader>
                <section className="px-4 my-3 h-100">
                    <ClientDocumentsTable />
                </section>
            </Main>
        </React.Fragment>
    );
};

export default withDocumentFilterProvider(withClientDocumentsProvider(ClientDocumentsPage));
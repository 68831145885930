import { useState } from "react";
import FormSelectPaged from "./FormSelectPaged";
import { useLazySearchHoAdminIntroducersQuery } from "../../../services/headofficeadmin";

const IntroducersFormSelectPaged = ({  isMulti, label, onChange, value }) => {

    return <FormSelectPaged
        setValue={onChange}
        cacheOptions={true}
        label={label}
        value={value}
        isMulti={isMulti}
        query={useLazySearchHoAdminIntroducersQuery}
    />;
};

export default IntroducersFormSelectPaged;
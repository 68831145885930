import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const incomeDetailsSectionEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchIncomeDetailsSection: builder.mutation({
            query: ({ incomeDetailsId, operations }) => ({
                url: `api/factfind/IncomeDetails/${incomeDetailsId}`,
                method: 'PATCH',
                body: operations
            })
        }),
        fetchClientIncomeDetails: builder.query({
            query: ({ clientIncomeId }) => `api/factfind/IncomeDetails/Client/${clientIncomeId}`
        }),
        patchClientIncomeDetails: builder.mutation({
            query: ({ clientIncomeId, operations }) => ({
                url: `api/factfind/IncomeDetails/Client/${clientIncomeId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ clientIncomeId, operations, masterAccountId }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchIncomeDetailsSection', { masterAccountId }, (data) => {
                    var item = data.clients.find(x => x.clientAccountId === clientIncomeId);
                    applyPatch(item, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    usePatchIncomeDetailsSectionMutation,
    useLazyFetchClientIncomeDetailsQuery,
    usePatchClientIncomeDetailsMutation
} = incomeDetailsSectionEndpoints;
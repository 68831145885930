import { useMemo } from "react";
import { usePrdCommentaryContext } from "../../../hooks/PrdCommentaryContext";

const usePrdCommentary = () => {
    const {
        masterPrd,
        isFetching,
        commentaryTypeObj,
        patch,
        isPatchingFn,
        publish,
        isPublishingFn
    } = usePrdCommentaryContext();

    const patchCommentary = (newCommentary) => patch(commentaryTypeObj?.value, `${newCommentary}`);
    const isPatching = useMemo(() => isPatchingFn(commentaryTypeObj?.value), [isPatchingFn, commentaryTypeObj?.value]);
    
    const publishCommentary = () => publish(commentaryTypeObj?.value);
    const isPublishing = useMemo(() => isPublishingFn(commentaryTypeObj?.value), [isPublishingFn, commentaryTypeObj?.value]);

    const commentary = useMemo(() => masterPrd?.[commentaryTypeObj?.path], [commentaryTypeObj?.path, masterPrd])

    return { isFetching, commentary, label: commentaryTypeObj?.label, patchCommentary, isPatching, publishCommentary, isPublishing };
}

export default usePrdCommentary;
import _, { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import Button from "../../components/buttons";
import { Modal } from "react-bootstrap";
import { useClientContext } from "../../hooks/ClientContext";
import { DateInput, FormInput, FormCheck, FormSelect } from "../../components/forms";
import { MasterAccountDesignationsSelect } from "../../components/forms/selects";
import InvestmentOperationSelect from "./components/InvestmentOperationSelect";
import ProviderSelect from "./components/ProviderSelect";
import WrapperSelect from "./components/WrapperSelect";
import ProductSelect from "./components/ProductSelect";
import { useFilterContext } from "../../hooks/FilterContext";
import AssetSearchPagedSelect from "../../components/forms/selects/AssetSearchPagedSelect";

export const ClientTransactionsFiltersModalButton = ({
    className,
    variant = "primary",
    ...rest
}) => {
    return (
        <div className={className}>
            <Button variant={variant} {...rest}>
                Filter Transactions
            </Button>
        </div>
    );
};

export const ClientTransactionsFiltersModal = ({
    handleClose,
    masterAccountId,
    onFiltersChanged,
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter((original) => {
            return {
                ...original,
                ...tempFilters,
            };
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters((_) => defaultFilters);
    };  

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters((_) => filter);
        }
    }, [filter]);

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>Transaction Filters</Modal.Header>
            <Modal.Body {...rest}>
                <InvestmentTransactionFilters
                    filter={tempFilters}
                    setFilter={onFilterValueChanged}
                    masterAccountId={masterAccountId}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>
                    Clear Filters
                </Button>
                <Button variant="success" onClick={onApplyFilters}>
                    Apply Filters
                </Button>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const InvestmentTransactionFilters = ({
    filter,
    setFilter,
    masterAccountId,
}) => {
    const {
        designationIds,
        investmentOperationIds,
        providerIds,
        wrapperIds,
        productIds,
        assetIds,
        assetIdObjects,
        investmentPartnerIds,
        statusIds,
        custodianReference,
        providerReference,
        dateFrom,
        dateTo,
        includeInValuation,
        isActive,
    } = filter;

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col">
                    <MasterAccountDesignationsSelect
                        label="Designations"
                        isMulti={true}
                        defaultValue={designationIds}
                        masterAccountId={masterAccountId}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "designationIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
                <div className="col">
                    <InvestmentOperationSelect
                        label="Investment Operations"
                        isMulti={true}
                        defaultValue={investmentOperationIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "investmentOperationIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <ProviderSelect
                        label="Providers"
                        isMulti={true}
                        defaultValue={providerIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "providerIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
                <div className="col">
                    <WrapperSelect
                        label="Wrappers"
                        isMulti={true}
                        defaultValue={wrapperIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "wrapperIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <ProductSelect
                        label="Products"
                        isMulti={true}
                        defaultValue={productIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "productIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
                <div className="col">
                    <AssetSearchPagedSelect
                        label="Assets"
                        isMulti={true}
                        value={assetIds}
                        valueObject={assetIdObjects}
                        disableAnimations={true}
                        setValue={(values) => {
                            setFilter(
                                "assetIds",
                                values.map((el) => el.value)
                            );
                            // Needed by PagedSelect to get selected values to display
                            setFilter(
                                "assetIdObjects",
                                values
                            );
                        }}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <FormSelect
                        label="Investment Partner"
                        isMulti={true}
                        options={[
                            {
                                label: "AjBell Securities",
                                value: 2066,
                            },
                        ]}
                        defaultValue={investmentPartnerIds}
                        disableAnimations={true}
                        onBlur={(values) =>
                            setFilter(
                                "investmentPartnerIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
                <div className="col">
                    <FormSelect
                        label="Status"
                        defaultValue={statusIds}
                        options={[
                            {
                                label: "Recommended",
                                value: 10,
                            },
                            {
                                label: "Ready To Transact",
                                value: 20,
                            },
                            {
                                label: "Instructed",
                                value: 30,
                            },
                            {
                                label: "Uploaded",
                                value: 40,
                            },
                            {
                                label: "Settled",
                                value: 50,
                            },
                            {
                                label: "Tax Reclaim",
                                value: 60,
                            },
                            {
                                label: "Not Taken Up",
                                value: 70,
                            },
                        ]}
                        onBlur={(values) =>
                            setFilter(
                                "statusIds",
                                values.map((el) => el.value)
                            )
                        }
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                <FormInput
                        label="Custodian Reference"
                        value={custodianReference}
                        disableAnimations={true}
                        onBlur={(value) => setFilter("custodianReference", value)}
                    />
                </div>
                <div className="col">
                    <FormInput
                        label="Provider Reference"
                        value={providerReference}
                        disableAnimations={true}
                        onBlur={(value) => setFilter("providerReference", value)}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <DateInput
                        label="Date From"
                        value={dateFrom}
                        onBlur={(value) => setFilter("dateFrom", value)}
                        isClearable
                    />
                </div>
                <div className="col">
                    <DateInput
                        label="Date To"
                        value={dateTo}
                        onBlur={(value) => setFilter("dateTo", value)}
                        isClearable
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <FormCheck
                        label="Included in Valuation"
                        isChecked={includeInValuation}
                        errorAllowRetry={false}
                        disableAnimations
                        onChange={(value) => setFilter("includeInValuation", value)}

                    />
                </div>
                <div className="col">
                    <FormCheck
                        label="Active"
                        isChecked={isActive}
                        errorAllowRetry={false}
                        disableAnimations
                        onChange={(value) => setFilter("isActive", value)}

                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default InvestmentTransactionFilters;

import React, { useEffect } from "react"
import { Modal } from "react-bootstrap"
import { Button, IconButton } from "../../../components"
import toast from "react-hot-toast";
import { BasicToast } from "../../../components/toasts";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CurrencyInput, DateInput, FormInput, FormLabel, FormSelect, PercentageInput } from "../../../components/forms";
import { ThemedSpan } from "../../../components/utilities";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import AttributeToFormSelect from "./AttributeToFormSelect";
import { v4 } from "uuid";

const TransactionFeeModal = ({ 
    group, 
    show, 
    handleClose, 
    isLoadingDefaultAllocations,
    isDefaultAllocationsError,
    defaultAllocationsError,
    feeTransactionDefaultValues,
    feeTransactionAllocationDefaultValues,
    transactionTypes,
    isLoadingTransactionTypes,
    onSubmitFn,
    onSubmitLabel = "Create Fee",
    retryFetchDefaultAllocations
}) => {

    const { control, watch, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues: feeTransactionDefaultValues });

    const validateAllocationPercentage = (allocations) => 100 === allocations.reduce((total, curr) => total + curr.allocationPercentage, 0)
        ? null
        : "The allocations do not sum to 100%.";

    const { fields, append, remove } = useFieldArray({
        control,
        name: "allocations",
        rules: {
            validate: validateAllocationPercentage
        }
    });

    const watchTotalValue = watch("totalFeeAmount");
    const watchAllocationsValue = watch("allocations");

    const getTotalPercentageFromAllocations = (allocationsValues) => allocationsValues.reduce((total, curr) => total + curr.allocationPercentage, 0);

    const getRemainingPenceFromFlooredAllocations = () => {
        return watchAllocationsValue.reduce((total, curr) => total - Math.floor((watchTotalValue * (curr.allocationPercentage))) / 100, watchTotalValue);
    };

    const isAllocationPercentageAllowed = (item, floatValue) => {
        var listOfAllocations = watchAllocationsValue.slice(1).filter(x => x.rowId !== item.rowId);
        return (floatValue >= 0.00 && floatValue <= 100 - getTotalPercentageFromAllocations(listOfAllocations));
    };

    const onClose = () => {
        reset(feeTransactionDefaultValues);
        handleClose();
    };

    const onSubmit = (data) => {
        toast.promise(onSubmitFn({ ...data, vatRate: group.vatRate, vatable: group.vatable }), {
            loading: "Updating fees...",
            error: (err) => err?.data?.message ?? "Unable to update fees.",
            success: "Fees updated!"
        }).finally(onClose);
    };

    const resetFirstValue = () => {
        setValue(`allocations.0.allocationPercentage`, 100.0 - watchAllocationsValue.slice(1).reduce((total, curr) => total + curr.allocationPercentage, 0));
    };

    useEffect(() => resetFirstValue(), [watchAllocationsValue]);

    useEffect(() => reset(feeTransactionDefaultValues), [reset, feeTransactionDefaultValues]);

    return <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Transaction Fees</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="feeTransactionTypeId"
                            control={control}
                            rules={{ required: "Type is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <FormSelect
                                options={transactionTypes.map(item => ({value: item.typeId, label: item.description, ...item}))}
                                isLoadingOptions={isLoadingTransactionTypes}
                                label={"Type"}
                                defaultValue={value}
                                errorAllowRetry={false}
                                errorMessage={errors?.feeTransactionTypeId?.message}
                                onChange={(value) => {
                                    setValue("feeTransactionTypeId", value.value);
                                    setValue("feeTransactionType", value);
                                }}
                                {...rest}
                            />
                            }
                        />
                    </div>
                    <div className="col">
                        <Controller
                            name="feeDate"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { ref, value, onChange, onBlur, ...rest } }) => <DateInput
                                value={value}
                                label="Date"
                                isClearable
                                disableAnimations={true}
                                onBlur={(value, _) => setValue("feeDate", value)}
                                {...rest}
                            />}
                        />
                    </div>

                    <div className="col">
                        <Controller
                            name="totalFeeAmount"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <CurrencyInput
                                value={value}
                                label="Fee Amount"
                                disableAnimations
                                onChange={(_, { floatValue }) => setValue("totalFeeAmount", floatValue)}
                                {...rest}
                            />
                            }
                        />
                    </div>

                    <div className="col">
                        <Controller
                            name="totalVatAmount"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest }, ...other }) => {
                                return <CurrencyInput
                                    value={group.vatable ? watchTotalValue * (group.vatRate / 100) : 0}
                                    label="VAT Amount"
                                    disabled
                                    {...rest}
                                />
                            }}
                        />
                    </div>

                    <div className="col">
                        <Controller
                            name="feeReference"
                            control={control}
                            rules={{ required: "Reference is required." }}
                            render={({ field: { ref, ...rest } }) => <FormInput
                                label="Fee Ref"
                                disableAnimations={true}
                                errorAllowRetry={false}
                                errorMessage={errors?.feeReference?.message}
                                {...rest}
                            />}
                        />
                    </div>

                    <div className="col">
                        <Controller
                            name="comment"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { ref, ...rest } }) => <FormInput
                                label="Comment"
                                disableAnimations={true}
                                errorAllowRetry={false}
                                {...rest}
                            />}
                        />
                    </div>
                </div>

                {isLoadingDefaultAllocations && <Skeleton count={4} height={16} />}

                {isDefaultAllocationsError && <ErrorMessageWrapper>
                    <ErrorMessage error={defaultAllocationsError} retryCallback={retryFetchDefaultAllocations} />
                </ErrorMessageWrapper>}

                {feeTransactionDefaultValues.allocations.length === 0 && <div className="row p-2 d-flex justify-content-center">
                    <span>
                        There are no fee allocations.
                        You can click <a href="#" onClick={() => {
                            append(feeTransactionAllocationDefaultValues);
                            // resetFirstValue();
                        }}>here</a> to create one.
                    </span>
                </div>}
                {feeTransactionDefaultValues.allocations.length > 0 && <div className="row p-2 d-flex justify-content-center">
                    <div className="row d-flex mb-1 align-items-center">
                        <FormLabel className="col">
                            Attribute Type
                        </FormLabel>
                        <FormLabel className="col">
                            Attribute To
                        </FormLabel>
                        <FormLabel className="col">
                            Percentage
                        </FormLabel>
                        <FormLabel className="col">
                            Fee Amount
                        </FormLabel>
                        <FormLabel className="col">
                            VAT Amount
                        </FormLabel>
                        <IconButton
                            className="col-auto"
                            icon={"fa-plus"}
                            variant="success"
                            onClick={() => append({...feeTransactionAllocationDefaultValues, rowId: v4()})}
                        />
                    </div>
                    {
                        fields.map((item, index) => {
                            return <div className="row mt-1" key={item.id}>
                                <div className="col">
                                    <Controller
                                        name={`allocations.${index}.personType`}
                                        control={control}
                                        rules={{ required: "Type is required." }}
                                        render={({ field: { name, ref, value, onChange, ...rest } }) => {
                                            return <FormSelect
                                                options={["Adviser", "Introducer", "Client"].map((label, value) => ({ label, value }))}
                                                defaultValue={value}
                                                errorAllowRetry={false}
                                                errorMessage={errors?.personType?.message}
                                                onChange={(value) => setValue(name, value.value)}
                                                {...rest}
                                            />
                                        }}
                                    />
                                </div>
                                <div className="col">
                                    <AttributeToFormSelect
                                        control={control}
                                        index={index}
                                        setValue={setValue}
                                        errors={errors}
                                        masterAccountId={group?.masterAccountId}
                                        masterAccountName={group?.masterAccountName}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        name={`allocations.${index}.allocationPercentage`}
                                        control={control}
                                        rules={{ required: "Allocation is required." }}
                                        render={({ field: { ref, value, onChange, name, ...rest } }) => {
                                            return <PercentageInput
                                                value={value}
                                                readOnly={index === 0}
                                                disableAnimations
                                                isAllowed={({floatValue}) => isAllocationPercentageAllowed(item, floatValue)}
                                                onChange={(_, { floatValue }) => {
                                                    setValue(name, floatValue);
                                                    resetFirstValue();
                                                }}
                                                {...rest}
                                            />
                                        }}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        name={`allocations.${index}.allocationPercentage`}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field: { ref, value, onChange, name, ...rest } }) => <CurrencyInput
                                            value={index === fields.length - 1
                                                ? Math.floor((watchTotalValue * (value))) / 100 + getRemainingPenceFromFlooredAllocations()
                                                : Math.floor((watchTotalValue * (value))) / 100}
                                            disableAnimations
                                            readOnly
                                            {...rest}
                                        />}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        name={`allocations.${index}.allocationPercentage`}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field: { ref, value, onChange, name, ...rest } }) => <CurrencyInput
                                            value={group.vatable ? (watchTotalValue * (value / 100)) * (group.vatRate / 100) : 0}
                                            disableAnimations
                                            readOnly
                                            {...rest}
                                        />}
                                    />
                                </div>
                                <IconButton
                                    className="col-auto"
                                    icon="fa-trash"
                                    variant="danger"
                                    onClick={() => {
                                        remove(index);
                                        // resetFirstValue();
                                    }}
                                />
                            </div>
                        })
                    }
                    <ThemedSpan className="mt-3" variant="danger">{errors?.allocations?.root?.message}</ThemedSpan>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type="submit">{onSubmitLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </form>
    </Modal>
};

export default TransactionFeeModal;
import React, { forwardRef } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FormInputGroup } from "../../../components/forms";
import InputErrorMessage from "../../../components/forms/InputErrorMessage";
import { useBusinessSheetSearch } from "../hooks/BusinessSheetSearchContext";

const SearchToggle = forwardRef(({ onClick, queryFn, ...rest }, ref) => {

    const { searchTerm, setSearchTerm, placeholder, formError, showCheckbox, isChecked, setIsChecked } = useBusinessSheetSearch();

    const handleFormOnClickEvent = (e) => {
        e.preventDefault();
        onClick(e);
    };

    const handleFormOnValueChanged = (e) => {
        setSearchTerm(_ => e.target.value);
    };

    return <FormInputGroup>
        {showCheckbox && <InputGroup.Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />}
        <FormControl
            ref={ref}
            value={searchTerm}
            onClick={handleFormOnClickEvent}
            onChange={handleFormOnValueChanged}
            placeholder={placeholder}
            {...rest}
        />
        <InputErrorMessage error={formError} allowRetry={false} />
    </FormInputGroup>

});

export default SearchToggle;
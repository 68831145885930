// eslint-disable-next-line no-extend-native
Date.prototype.yyyymmddHyphenated = function () {
    var month = ('0' + (this.getUTCMonth() + 1)).slice(-2);
    var day = ('0' + this.getUTCDate()).slice(-2);
    var year = this.getUTCFullYear();

    if (year === "0001" && month === "01" && day === "01")
        return "";

    return year + "-" + month + "-" + day;
}

export { };
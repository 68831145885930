import React from "react";
import { useLazyExportValuationQuery } from "../../../services/valuations/valuationsApiEndpoints";

const useValuationExport = (masterAccountId) => {

    const [exportValuation, { isError, error, isUninitialized, isLoading }] = useLazyExportValuationQuery();

    const exportValuationPdf = (endDate, isBasic) => {
        return new Promise((resolve, reject) => {
            return exportValuation({
                masterAccountId : masterAccountId,
                endDate : endDate,
                PriceType : 1,
                isBasic : isBasic
             })
             .unwrap()
             .then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        exportValuationPdf
    }
};

export default useValuationExport;
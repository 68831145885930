import { useEffect, useState } from "react";
import {
    useCreateClientConnectionMutation,
    useDeleteClientConnectionMutation,
    useFetchClientConnectionsForMasterAccountQuery,
    usePatchClientConnectionMutation
} from "../../../services/clientdetails";

const useConnectionsList = (masterAccountId) => {
    const { data, error, isError, isFetching, isLoading, refetch } = useFetchClientConnectionsForMasterAccountQuery({ masterAccountId });
    const [errorMessage, setErrorMessage] = useState()
    const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateClientConnectionMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteClientConnectionMutation();
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchClientConnectionMutation();

    const clearErrorDisplay = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        setIsErrorDisplayed(_ => false);
    };

    const createConnection = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            let data = {
                masterAccountId,
                relationship: null,
                title: '',
                forenames: '',
                surname: '',
                position: '',
                salutation: '',
                address: '',
                phoneNumber: '',
                emailAddress: '',
                notes: '',
                isActiveConnection: true
            };

            return create({ masterAccountId, data }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteConnection = (connectionId) => {
        return new Promise((resolve, reject) => {
            return remove({ connectionId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ masterAccountId });
    };

    const updateConnection = (connectionId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];
            return patch({ connectionId, masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    // useEffect(() => {
    //     console.log('there was an error!');
    // }, [ createError, removeError, updateError ]);

    return {
        clearErrorDisplay,
        createConnection,
        createError,
        data: data || [],
        deleteConnection,
        deleteError: removeError,
        error,
        errorMessage,
        isError,
        isErrorCreating,
        isErrorDisplayed,
        isErrorRemoving,
        isErrorUpdating,
        isCreating,
        isLoading: isLoading === true || isFetching === true,
        isRemoving,
        isUpdating,
        reload,
        removeError,
        updateError,
        updateConnection
    };
};

export default useConnectionsList;
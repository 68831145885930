import Skeleton from "react-loading-skeleton";
import ReadOnlyMessage from "./ReadOnlyMessage";
import MessageBox from "./MessageBox";
import React, { useEffect, useRef } from "react";
import DraftMessage from "./DraftMessage";
import { useMessageThreadContext } from "../hooks/MessageThreadContext";

const LoadingMessage = React.forwardRef(({ index, style }, ref) => {
    return <div style={style} className="px-3">
        <MessageBox ref={ref} className="rounded">
            <div className="d-flex justify-content-between mb-3">
                <Skeleton height={25} width={175} />
                <Skeleton height={25} width={75} />
            </div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </MessageBox>
    </div>;
});

const MessageRow = ({ data, setRowHeight, index, style }) => {

    const rowRef = useRef();

    var message = data[index];

    const { deleteDraft, reply, toggleDone } = useMessageThreadContext();


    useEffect(() => {
        if (rowRef.current && setRowHeight) {
            setRowHeight(index, rowRef.current.clientHeight);
        }
    }, [rowRef, data]);

    if (!message) {
        return <LoadingMessage ref={rowRef} index={index} style={style} />
    }

    if (!message.sentOnDate) {
        return <div style={style} className="px-3">
            <DraftMessage
                ref={rowRef}
                message={message}
                onDelete={() => deleteDraft(message.messageId)}
            />
        </div>
    }


    return (
        <div style={style} className="px-3">
            <ReadOnlyMessage
                ref={rowRef}
                message={message}
                onReply={() => reply(message)}
                onDone={(value) => toggleDone(message.messageId, value)}
            />
        </div>
    );
};

export default MessageRow;
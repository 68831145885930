import InvestmentCommitteeFundsAnalysisLayout from "../layouts/InvestmentCommitteeFundsAnalysisLayout";
import InvestmentCommitteeFundsLayout from "../layouts/InvestmentCommitteeFundsLayout";
import { NavigationTabs } from "../components/navigation";
import {
    FundAnalysisReturnPage,
    FundAnalysisVolatilityPage,
    FundDetailsPage,
    FundManagementPage
} from "../pages/investment-committee";

const InvestmentCommitteeFundRoutes = {
    path: 'funds/*',
    pathBase: '/investment-committee/funds',
    defaultRoute: 'analysis',
    roles: [],
    element: InvestmentCommitteeFundsLayout,
    children: [
        {
            path: 'management',
            roles: [],
            element: FundManagementPage
        },
        {
            path: ':assetGroupId',
            roles: [],
            element: FundDetailsPage
        },
        {
            path: 'analysis',
            pathBase: '/investment-committee/funds/analysis',
            defaultRoute: 'return',
            roles: [],
            element: InvestmentCommitteeFundsAnalysisLayout,
            children: [
                {
                    path: 'return',
                    icon: 'fa-chart-simple', 
                    label: 'Total Return',
                    roles: [],
                    element: FundAnalysisReturnPage
                },
                {
                    path: 'volatility',
                    icon: 'fa-chart-line', 
                    label: 'Volatility',
                    roles: [],
                    element: FundAnalysisVolatilityPage
                }
            ]
        }
    ]
};

export const InvestmentCommitteeFundsAnalysisNavigation = () => <NavigationTabs pathBase={InvestmentCommitteeFundRoutes.children[2].pathBase} options={InvestmentCommitteeFundRoutes.children[2]} />

export default InvestmentCommitteeFundRoutes;
import React, { useMemo } from "react";
import ResultsDisplay from "./ResultsDisplay";
import ResultsLoader from "./ResultsLoader";
import { ErrorMessage, NoResults } from "../../../components/messages";
import { ThemedParagraph } from "../../../components/utilities";
import { useClientSearch } from "../contexts/ClientSearchContext";

// The search results wrapper`s main job is to switch the display based on the state of the search
// For example, displaying error messages or no search results
// If you want to see how the results are displayed, checkout the "ResultsDisplay" component!
const SearchResultsWrapper = ({ onToggle = null, ...rest }) => {
    const { 
        data, 
        error, 
        isError,
        isLoading,
        isUninitialized, 
        minimumCharacters, 
        reload, 
        searchTerm 
    } = useClientSearch();

    // calculates and stores the number of remaining characters required to execute a search
    const charactersRemaining = useMemo(() => {
        let retval = minimumCharacters - searchTerm.length;
        return retval < 0 ? 0 : retval;
    }, [ minimumCharacters, searchTerm ]);

    // displays to the user how many characters are required before we begin the search
    if (isUninitialized === true) {
        return <div className="py-2">
            {charactersRemaining > 0 && <ThemedParagraph variant="muted">Please type {charactersRemaining} more characters to begin your search.</ThemedParagraph>}
            {charactersRemaining < 1 && <ThemedParagraph variant="muted">Please wait whilst we begin your search...</ThemedParagraph>}
        </div>
    }

    if (error || isError === true) {
        return <ErrorMessage error={error} retryCallback={reload} />
    }

    if (isLoading === false && data && data.length < 1) {
        return <NoResults suffixText="Please adjust your search parameters to try again." />
    }

    return <React.Fragment>
        {data && data.length > 0 && <ResultsDisplay data={data} onToggle={onToggle} {...rest} />}
        <ResultsLoader onToggle={onToggle} />
    </React.Fragment>
};

export default SearchResultsWrapper;
import classNames from "classnames";
import React from "react";
import { Button } from "react-bootstrap";
import { FormCheck } from "../../components/forms";
import { ClientLayoutPageSubHeader, FiltersAppliedWarningMessage, Main, ThemedIcon } from "../../components/utilities";
import { ClientEventFiltersModal } from "../../features/clienteventsfilter";
import ClientEventsTable from "../../features/clienteventstable";
import { ClientEventsDefaultFilters, FilterProvider, useFilterContext } from "../../hooks/FilterContext";
import useClientEvents from "../../hooks/useClientEvents";
import withProviders from "../../hooks/withProviders";

const ClientEventsPage = () => {
    const {
        data,
        masterAccountId,
        patchEvent,
        patchResult,
        ...loadResults
    } = useClientEvents();
    const { results } = data || { results: [] };
    const {
        clearFilter,
        isFiltersApplied
    } = useFilterContext();

    const [isFiltersShown, setIsFiltersShown] = React.useState(false);

    return <React.Fragment>
        <ClientEventFiltersModal
            handleClose={() => setIsFiltersShown(false)}
            show={isFiltersShown}
        />
        <Main>
            <ClientLayoutPageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className={classNames("d-flex", { "invisible": !isFiltersApplied })}>
                        <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                        <FiltersAppliedWarningMessage className="ms-2 text-muted">
                            Warning - Filters are currently being applied to your results.
                            <br />
                            <button className="anchor" onClick={clearFilter}>Click here</button> to clear your filters.
                        </FiltersAppliedWarningMessage>
                    </div>
                    <Button variant="success" onClick={() => setIsFiltersShown(true)}>Filter Events</Button>
                </div>
            </ClientLayoutPageSubHeader>
            <section className="p-3">
                <ClientEventsTable
                    data={results}
                    masterAccountId={masterAccountId}
                    showProject
                    patchEvent={patchEvent}
                    {...loadResults}
                />
            </section>
        </Main>
    </React.Fragment>
}

export default withProviders([FilterProvider, { filterStore: "events", defaultFilters: ClientEventsDefaultFilters }])(ClientEventsPage);
import React, { useCallback, useMemo } from "react";
import { VirtualTable } from "../../../components/tables";
import useCustodianAccountMatches from "../hooks/useCustodianAccountMatches";
import CustodianAccountMatchingGridHeader from "./CustodianAccountMatchingGridHeader";
import CustodianAccountMatchingGridRow from "./CustodianAccountMatchingGridRow";
import EditCustodianAccountMatchModal from "./EditCustodianAccountMatchModal";

const CustodianAccountMatchingGrid = () => {

    const { columns, matchToEdit, results, totalCount, deleteMapping, isItemLoaded, loadMoreItems, setMatchIdToEdit, updateMapping } = useCustodianAccountMatches();

    const RowComponent = useCallback((props) => <CustodianAccountMatchingGridRow
        deleteFn={deleteMapping}
        setToEdit={setMatchIdToEdit}
        {...props}
    />, [deleteMapping, setMatchIdToEdit]);

    return <React.Fragment>
        <VirtualTable
            enableScrollTracking={false}
            loadMoreItems={loadMoreItems}
            itemSize={42}
            itemCount={totalCount}
            itemData={results}
            isItemLoaded={isItemLoaded}
            row={RowComponent}
            header={<CustodianAccountMatchingGridHeader columns={columns} />}
        />
        <EditCustodianAccountMatchModal
            data={matchToEdit}
            onHide={() => setMatchIdToEdit(null)}
            updateFn={updateMapping}
        />
    </React.Fragment>;
};

export default CustodianAccountMatchingGrid;
import { useEffect, useMemo } from "react";
import { useFetchFeeTransactionTypesQuery, useLazyGetFeeTransactionByIdQuery, useUpdateFeeTransactionMutation } from "../../../services/headofficeadmin";
import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";

const useEditFeeTransaction = (feeTransactionId) => {
    const { applicationId } = useHeadOfficeApplicationContext();
    const { data: transactionTypes, isLoading: isLoadingTransactionTypes } = useFetchFeeTransactionTypesQuery();
    const [fetchTrigger, { data, isLoading, isError, error, refetch }] = useLazyGetFeeTransactionByIdQuery();
    const [updateTrigger] = useUpdateFeeTransactionMutation();

    const feeTransactionDefaultValues = useMemo(() => data ?? ({
        groupId: null,
        feeDate: new Date(),
        feeTransactionTypeId: null,
        feeTransactionType: {},
        totalFeeAmount: 0,
        totalVatAmount: 0,
        feeReference: null,
        comment: null,
        allocations: []
    }), [data]);

    const feeTransactionAllocationDefaultValues = useMemo(() => ({
        personType: null,
        attributeToId: null,
        allocationPercentage: 0.1
    }), []);

    const retryGetFeeTransaction = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    };

    const updateFeeTransaction = (request) => {
        return new Promise((resolve, reject) => {
            return updateTrigger({ feeTransactionId, request: { ...request, applicationId }, previousValues: data })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    useEffect(() => {
        if (!feeTransactionId) return;

        fetchTrigger({ feeTransactionId });
    }, [feeTransactionId]);

    return {
        feeTransactionDefaultValues,
        feeTransactionAllocationDefaultValues,
        isLoading,
        isError,
        error,
        transactionTypes,
        isLoadingTransactionTypes,
        retryGetFeeTransaction,
        updateFeeTransaction
    };
};

export default useEditFeeTransaction;
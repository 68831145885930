import React from "react";
import { confirmAlert } from "react-confirm-alert";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "styled-components";
import { IconButton } from "../../../components/buttons";
import { DateInput, FormInput, FormSelect } from "../../../components/forms";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { StandardTable } from "../../../components/tables";
import useClientAccountDetails from "../hooks/useClientAccountDetails";
import BasicFetchError from "./BasicFetchError";
import DetailsWrapper from "./DetailsWrapper";

const ClientAccountsTableEmptyRow = ({ columns, onCreateClicked, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            There are no client accounts available.
            You can click <a href="#" onClick={onCreateClicked}>here</a> to create one.
        </td>
    </tr>
};

const ClientAccountsTableLoadingRow = ({ columns, loadingLines, ...rest }) => {
    return [...Array(loadingLines)].map((_, i) => (
        <tr key={`loading-${i}`} {...rest}>
            <td colSpan={columns.length}>
                <Skeleton count={1} height={37} />
            </td>
        </tr>
    ));
};

const ClientAccountsTableRow = ({ allowDelete = true, columns, data, masterAccountId, onDeleteClicked, onFieldChanged, ...rest }) => {
    const { clientAccountId, forenames, status, statusDate, surname, title } = data;
    return <tr {...rest}>
        <td className="col-1">
            <FormInput
                label={null}
                value={title}
                placeholder="Title"
                onBlur={(value) => onFieldChanged(clientAccountId, 'title', value)}
            />
        </td>
        <td>
            <FormInput
                label={null}
                value={forenames}
                placeholder="Forenames"
                onBlur={(value) => onFieldChanged(clientAccountId, 'forenames', value)}
            />
        </td>
        <td>
            <FormInput
                label={null}
                value={surname}
                placeholder="Surname"
                onBlur={(value) => onFieldChanged(clientAccountId, 'surname', value)}
            />
        </td>
        <td className="col-2">
            <FormSelect
                label={null}
                defaultValue={status}
                options={[
                    { label: 'Active', value: 0 },
                    { label: 'Deceased', value: 1 },
                    { label: 'POA Activated', value: 2 },
                    { label: 'Inactive', value: 3 }
                ]}
                onChange={(values) => onFieldChanged(clientAccountId, 'status', values.value)}
            />
        </td>
        <td className="col-2">
            <DateInput
                label={null}
                value={statusDate}
                onBlur={(value) => onFieldChanged(clientAccountId, 'statusDate', value)}
            />
        </td>
        {allowDelete === true && (<td className="col-md-auto">
            <IconButton icon="fa-trash-can" variant="danger" onClick={() => onDeleteClicked(data)} />
        </td>)}
    </tr>
};

const getDeleteWarningMessage = (forenames, surname) => {
    if (!forenames) {
        return 'Are you sure you want to delete this client?';
    }
    if (!surname) {
        return `Are you sure you want to delete "${forenames}"?`;
    }
    return `Are you sure you want to delete "${forenames} ${surname}"?`;
}

const ClientAccounts = ({ masterAccountId, loadingLines = 2 }) => {
    const {
        createClientAccount,
        data,
        deleteClientAccount,
        error,
        isCreating,
        isError,
        isLoading,
        reload,
        updateClientAccount
    } = useClientAccountDetails(masterAccountId);
    const theme = useTheme();

    const onCreateClientAccountPressed = (e) => createClientAccount(e);

    const onDeleteClientAccountPressed = (clientAccount) => {
        const { clientAccountId, forenames, surname } = clientAccount;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal
                    theme={theme}
                    title="Delete Client"
                    message={getDeleteWarningMessage(forenames, surname)}
                    handleConfirm={() => deleteClientAccount(clientAccountId).finally(onClose)}
                    handleClose={onClose}
                />
            }
        });
    }

    const onFieldChanged = (clientAccountId, property, value) => updateClientAccount(clientAccountId, property, value);

    if (error || isError === true) {
        return <BasicFetchError error={error} retryCallback={reload} />
    }

    return <DetailsWrapper label="Clients">
        <StandardTable 
            className="table-borderless"
            columns={[
                { label: 'Title', property: 'title' },
                { label: 'Forenames', property: 'forenames' },
                { label: 'Surname', property: 'surname' },
                { label: 'Status', property: 'statusDescription' },
                { label: 'Status Date', property: 'statusDate' },
                { 
                    label: '', 
                    property: '',
                    headerRenderer: (props) => (
                        <td key={props.key} style={{ width: '32px' }}>
                            <IconButton icon="fa-plus" variant="success" onClick={onCreateClientAccountPressed} />
                        </td>
                    )
                }
            ]}
            data={data}
            error={error}
            isCreating={isCreating}
            isLoading={isLoading}
            EmptyRowComponent={ClientAccountsTableEmptyRow}
            emptyRowComponentProps={{ onCreateClicked: onCreateClientAccountPressed }}
            hideHeaderOnEmpty={true}
            LoadingRowComponent={ClientAccountsTableLoadingRow}
            loadingRowComponentProps={{ loadingLines }}
            RowComponent={ClientAccountsTableRow}
            rowComponentProps={{ 
                allowDelete: true, 
                masterAccountId, 
                onDeleteClicked: onDeleteClientAccountPressed, 
                onFieldChanged 
            }}
        />
    </DetailsWrapper>
};

export default ClientAccounts;
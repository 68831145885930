import { wealthProApi } from "../../redux/api";

const costTypesApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        fetchCostTypes: builder.query({
            query: () => `api/products/costtype`
        })
    })
});

export const {
    useFetchCostTypesQuery
} = costTypesApiEndpoints;
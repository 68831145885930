import { useEffect, useState } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import styled from "styled-components"
import { FormInput } from "../../../components/forms"
import useMappingSearch from "../hooks/useMappingSearch"

const LargePopover = styled(Popover)`
    max-width: 400px;
`;

const ShareClassSedolSearch = ({ assetGroupId, row, index, afterSearch }) => {

    const [isUpdating, setIsUpdating] = useState(false);
    const [{ searchAndCreateMapping, deleteMapping }, { errorMessage }] = useMappingSearch(assetGroupId, index);

    const [displaySedol, setDisplaySedol] = useState();

    useEffect(() => {
        setDisplaySedol(row?.asset?.sedol)
    }, [row?.asset?.sedol]);

    return <>
        <FormInput
            value={row?.asset?.sedol ?? ""}
            disableAnimations={row?.asset?.sedol === displaySedol}
            disabled={row?.isPrimaryMap}
            onChange={(input) => setDisplaySedol(input.target.value)}
            onBlur={(input) =>
                new Promise((resolve, reject) => {
                    setIsUpdating(true);

                    // All SEDOLs should be converted to uppercase onBlur
                    input = input.toUpperCase();
                    setDisplaySedol(input);

                    // The user has cleared the input, so delete the existing mapping to the Asset
                    if (input === "") {
                        return deleteMapping(row?.asset?.assetId).then(() => afterSearch({ assetId: null, asset: null, createdBy: null, createdDate: null })).then(resolve, reject);
                    }

                    return searchAndCreateMapping(input, row?.asset?.assetId, row?.asset?.sedol).then(res => {
                        // Update the display
                        setDisplaySedol(res?.sedol ?? res?.citicode);
                        afterSearch(res);
                    }).then(resolve, reject)
                }).finally(() => setIsUpdating(false))}
            errorMessage={errorMessage}
            errorAllowRetry={errorMessage == null}
        />

        {isUpdating
            ? <Skeleton />
            : <OverlayTrigger
                placement="top"
                overlay={(props) => (row?.asset?.sedol ?? "") === "" && !row?.isPrimaryMap
                    ? <LargePopover {...props}>
                        <Popover.Header>Fund Search</Popover.Header>
                        <Popover.Body>
                            Please enter a SEDOL or Citicode to search for an asset.
                        </Popover.Body>
                    </LargePopover>
                    : <></>}>
                <span>
                    <FormInput
                        className="col-start-2"
                        value={row?.asset?.longAssetName ?? ""}
                        disabled
                    />
                </span>
            </OverlayTrigger>}
    </>
}

export default ShareClassSedolSearch;
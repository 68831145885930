import { wealthProApi } from "../../redux/api";

const hoAdminOrganisationApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        hoFetchOrganisations: builder.query({
            query: ({ fetchType, sort = null }) => `api/headofficeadmin/organisation?fetchType=${fetchType}${sort != null ? `&sort=${encodeURIComponent(sort)}` : ""}`
        }),
    })
})

export const {
    useHoFetchOrganisationsQuery,
} = hoAdminOrganisationApiEndpoints;
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const portfolioBreakdownApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createClientFeeBreakdown: builder.mutation({
            query: ({ data }) => ({
                url: `api/review/ClientFeeBreakdown`,
                method: 'POST',
                body: data
            }),
            async onQueryStarted({ masterAccountId, data: breakdown }, { dispatch, queryFulfilled }) {
                try {
                    const { data: createData } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchClientServiceTerms', { masterAccountId }, (data) => {
                        data.feeBreakdowns.push({
                            ...createData
                        });
                    }));
                }
                catch { }
            }
        }),
        deleteClientFeeBreakdown: builder.mutation({
            query: ({ clientFeeBreakdownId }) => ({
                url: `api/review/ClientFeeBreakdown/${clientFeeBreakdownId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ clientFeeBreakdownId, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchClientServiceTerms', { masterAccountId }, (data) => {
                        data.feeBreakdowns = data.feeBreakdowns.filter(el => el.clientFeeBreakdownId !== clientFeeBreakdownId);
                        return data;
                    }));
                }
                catch { }
            }
        }),
        patchClientFeeBreakdown: builder.mutation({
            query: ({ clientFeeBreakdownId, masterAccountId, operations }) => ({
                url: `api/review/ClientFeeBreakdown/${clientFeeBreakdownId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ clientFeeBreakdownId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(
                    wealthProApi.util.updateQueryData('fetchClientServiceTerms', { masterAccountId }, (data) => {
                        var breakdown = data.feeBreakdowns.find(el => el.clientFeeBreakdownId === clientFeeBreakdownId);
                        applyPatch(breakdown, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
   useCreateClientFeeBreakdownMutation,
   useDeleteClientFeeBreakdownMutation,
   usePatchClientFeeBreakdownMutation
} = portfolioBreakdownApiEndpoints;
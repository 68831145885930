import { wealthProApi } from "../../redux/api";

const formatQueryParams = (args) => {
    const notNullParams = Object.entries(args).filter(([_key, value]) => value != null);
    if (notNullParams.length === 0)
        return "";

    return "?" + notNullParams.map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join("&");
};

const userMailUserApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['MailEvents']
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchMailFoldersForUser: builder.query({
            query: ({ userId }) => `api/clientdocuments/users/${userId}/folders`
        }),
        fetchFolderMessagesForUser: builder.query({
            query: ({ userId, folderId, ...queryParams }) => `api/clientdocuments/users/${userId}/folders/${folderId}/messages${formatQueryParams(queryParams)}`,
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                const { userId, folderId, searchTerm } = queryArgs;
                return `${endpointName}-${userId}-${folderId}-${searchTerm ?? "none"}`;
            },
            merge: (currentCache, newItems) => {

                // if page 1
                if (currentCache.context !== newItems.context || currentCache.nextLink === newItems.nextLink) {
                    return newItems;
                }

                currentCache.nextLink = newItems.nextLink;
                currentCache.previousLink = newItems.previousLink;
                currentCache.context = newItems.context;
                currentCache.value.push(...newItems.value);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                return currentArg !== previousArg || currentArg.folderId !== previousArg.folderId;
            },
        }),
        getMailMessageById: builder.query({
            query: ({ userId, mailId }) => `api/clientdocuments/users/${userId}/messages/${mailId}`,
            providesTags: (result, error, arg) => [{ type: 'EmailMessage', id: result?.id }],
            async onQueryStarted({ userId, folderId, mailId, searchTerm }, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                let messageWasUnread;

                dispatch(wealthProApi.util.updateQueryData('fetchFolderMessagesForUser', { folderId, userId, searchTerm }, ({ value, ...rest }) => {
                    var item = value.find(x => x.id === mailId);
                    if (item && !item.isRead) {
                        messageWasUnread = true;
                        item.isRead = true;
                    }
                }));

                dispatch(wealthProApi.util.updateQueryData('fetchMailFoldersForUser', { userId }, (draft) => {
                    var folder = draft.find(x => x.id === folderId);
                    if (folder && messageWasUnread) {
                        folder.unreadItemCount--;
                    }
                }));
            }
        }),
        createDraftMail: builder.mutation({
            query: ({ userId, request }) => ({
                url: `api/clientdocuments/users/${userId}/messages`,
                method: "POST",
                body: request,
                formData: true
            }),
            invalidatesTags: ["EmailContacts"],
        }),
        createReplyDraftMail: builder.mutation({
            query: ({ userId, id, request }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${id}/createReply`,
                method: "POST",
                body: request,
                formData: true
            }),
            invalidatesTags: ["EmailContacts"],
        }),
        createForwardDraftMail: builder.mutation({
            query: ({ userId, id, request }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${id}/createForward`,
                method: "POST",
                body: request,
                formData: true
            }),
            invalidatesTags: ["EmailContacts"],
        }),
        moveMailToFolder: builder.mutation({
            query: ({ userId, id, request }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${id}/move`,
                method: "POST",
                body: request
            }),
            async onQueryStarted({ userId, id, folderId, searchTerm }, { dispatch, queryFulfilled }) {
                var patch = dispatch(wealthProApi.util.updateQueryData('fetchFolderMessagesForUser', { userId, folderId, searchTerm }, ({ value, ...rest }) => {
                    return {
                        value: value.filter(x => x.id !== id),
                        ...rest
                    };
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    patch.undo();
                }
            }
        }),
        patchDraftMail: builder.mutation({
            query: ({ userId, id, request }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${id}`,
                method: "PATCH",
                body: request
            })
        }),
        deleteDraftMail: builder.mutation({
            query: ({ userId, id }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${id}`,
                method: "DELETE"
            }),
            async onQueryStarted({ id, folderId, userId, searchTerm }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchFolderMessagesForUser', { folderId, userId, searchTerm }, ({ value, ...rest }) => {
                        return {
                            value: value.filter(x => x.id !== id),
                            ...rest
                        };
                    }))
                }
                catch { }
            },
            invalidatesTags: ["EmailFolders"]
        }),
        sendDraftMail: builder.mutation({
            query: ({ userId, id, ...rest }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${id}/send`,
                method: "POST",
                body: rest
            }),
            async onQueryStarted({ id, folderId, userId, searchTerm }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchFolderMessagesForUser', { folderId, userId, searchTerm }, ({ value, ...rest }) => {
                        return {
                            value: value.filter(x => x.id !== id),
                            ...rest
                        };
                    }));
                }
                catch { }
            },
            invalidatesTags: ["EmailFolders", "EmailPreviews"]
        }),
        fileMail: builder.mutation({
            query: ({ userId, mailId, request }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${mailId}/file`,
                method: "PATCH",
                body: request
            }),
            async onQueryStarted({ mailId, folderId, userId, searchTerm, request }, { dispatch, queryFulfilled }) {
                const { masterAccountId, projectId } = request || {};
                var fetchResult = dispatch(wealthProApi.util.updateQueryData('fetchFolderMessagesForUser', { folderId, userId, searchTerm }, ({ value, ...rest }) => {
                    var item = value.find(x => x.id === mailId);
                    item.isFiled = true;
                    item.masterAccountId = masterAccountId;
                    item.projectId = projectId;
                }));

                var getResult = dispatch(wealthProApi.util.updateQueryData('getMailMessageById', { userId, mailId }, data => {
                    data.isFiled = true;
                    data.masterAccountId = masterAccountId;
                    data.projectId = projectId;
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    fetchResult.undo();
                    getResult.undo();
                }
            }
        }),
        addMailAttachments: builder.mutation({
            query: ({ userId, mailId, request }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${mailId}/attachments`,
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ userId, mailId }, { dispatch, queryFulfilled }) {
                try {
                    var { data } = await queryFulfilled;

                    var result = dispatch(wealthProApi.util.updateQueryData('getMailMessageById', { userId, mailId }, email => {
                        email.attachments.push(...data);
                    }));
                }
                catch { }
            }
        }),
        deleteMailAttachment: builder.mutation({
            query: ({ userId, mailId, attachmentId }) => ({
                url: `api/clientdocuments/users/${userId}/messages/${mailId}/attachments/${attachmentId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ userId, mailId, attachmentId }, { dispatch, queryFulfilled }) {
                var result = dispatch(wealthProApi.util.updateQueryData('getMailMessageById', { userId, mailId }, data => {
                    data.attachments = data.attachments.filter(x => x.id !== attachmentId);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useLazyFetchFolderMessagesForUserQuery,
    useLazyFetchMailFoldersForUserQuery,
    useFetchFolderMessagesForUserQuery,
    useFetchMailFoldersForUserQuery,
    useGetMailMessageByIdQuery,
    useCreateDraftMailMutation,
    usePatchDraftMailMutation,
    useDeleteDraftMailMutation,
    useSendDraftMailMutation,
    useFileMailMutation,
    useCreateForwardDraftMailMutation,
    useCreateReplyDraftMailMutation,
    useAddMailAttachmentsMutation,
    useDeleteMailAttachmentMutation,
    useMoveMailToFolderMutation
} = userMailUserApiEndpoints;
import { useMemo } from "react";
import Table from "react-bootstrap/Table"
import { NumericFormat } from "react-number-format";
import styled from "styled-components";
import { useInstruction } from "../contexts/InstructionContext";

const TableHeader = styled.th`
    text-align: center !important;
`;
const TableData = styled.td`
    text-align: center !important;
`;

const AnnualCostsDisclosureTable = ({ isDisposing }) => {
    const [, { realTimeInstruction, invest, divest }] = useInstruction();

    const ongoingService = useMemo(() => isDisposing ? divest?.ongoingServiceAmount : invest?.ongoingServiceAmount,
        [divest?.ongoingServiceAmount, invest?.ongoingServiceAmount, isDisposing]);
    const ongoingProduct = useMemo(() => isDisposing ? divest?.ongoingProductAmount : invest?.ongoingProductAmount,
        [divest?.ongoingProductAmount, invest?.ongoingProductAmount, isDisposing]);

    const adHocService = useMemo(() => isDisposing ? divest?.adHocServiceAmount : invest?.adHocServiceAmount,
        [divest?.adHocServiceAmount, invest?.adHocServiceAmount, isDisposing]);
    const adHocProduct = useMemo(() => isDisposing ? divest?.adHocProductAmount : invest?.adHocProductAmount,
        [divest?.adHocProductAmount, invest?.adHocProductAmount, isDisposing]);

    const cell = (value) => <>
        <NumericFormat
            value={value ?? 0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"£"}
            decimalScale={2}
            fixedDecimalScale={true}
        />
        <NumericFormat
            value={100 * (value ?? 0) / (isDisposing
                ? (realTimeInstruction?.divest?.totalSaleProceeds ?? value)
                : (realTimeInstruction?.invest?.totalInvestmentAmount ?? value))}
            displayType="text"
            thousandSeparator={false}
            prefix=" ("
            suffix="%)"
            decimalScale={2}
            fixedDecimalScale={true}
        />
    </>


    return <Table className="mt-5" bordered hover striped>
        <thead>
            <tr>
                <TableHeader>Annual Costs Disclosure {isDisposing && "(DISPOSAL)"}</TableHeader>
                <TableHeader>Service Costs</TableHeader>
                <TableHeader>Product Costs</TableHeader>
            </tr>
        </thead>

        <tbody>
            <tr>
                <TableData>Ongoing Costs</TableData>
                <TableData>{cell(ongoingService)}</TableData>
                <TableData>{cell(ongoingProduct)}</TableData>
            </tr>
            <tr>
                <TableData>Ad Hoc Costs</TableData>
                <TableData>{cell(adHocService)}</TableData>
                <TableData>{cell(adHocProduct)}</TableData>
            </tr>
        </tbody>
    </Table>
}

export default AnnualCostsDisclosureTable;
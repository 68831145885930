import React from "react";
import FormSelect from "../FormSelect";
import { useFetchMasterAccountResourcesQuery } from "../../../services/clientauth";

const MasterAccountResourceSelect = ({ options, masterAccountId, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchMasterAccountResourcesQuery(
        { masterAccountId },
        { skip: !masterAccountId }
    );

    const { results } = data || { results: [] }
    return <FormSelect
        options={results}
        noOptionsMessage={() => "There are no resources (clients or connections) for this account."}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default MasterAccountResourceSelect;
import { useCallback, useEffect, useMemo, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import {
    useLazyFetchDesignationsQuery,
    useRecsFetchInstructionTypeProductsQuery
} from "../../../services/recommendations";
import { useLazyFetchOwnedProductsQuery, useLazyFetchValuationDesignationsQuery } from "../../../services/valuations";
import { useInstruction } from "../contexts/InstructionContext";
import useReferenceSelection from "./useReferenceSelection";

const filterDuplicates = (({ value }, index, arr) =>
    index === arr.findIndex((duplicate) => duplicate.value === value)
);

const useProductSelection = ({ isInvest, changeOwner }) => {
    const { id: masterAccountId } = useClientContext();
    const [{ patchInvest, realTimePatchInvest, patchDivest, realTimePatchDivest }, { instructionId, instruction, instructionIsFetching, invest, investIsFetching, divest, divestIsFetching }] = useInstruction();

    const [fetchOwnedProducts, { data: ownedProductOptions, isError: ownedObjectsIsError, isFetching: isFetchingOwnedObjects, isUninitialized: ownedObjectsIsUninitialized }] = useLazyFetchOwnedProductsQuery();

    useEffect(() => {
        fetchOwnedProducts({ masterAccountId, wrapperIds: instruction?.instructionType?.wrapperIds })
    }, [fetchOwnedProducts, instruction?.instructionType?.wrapperIds, masterAccountId])

    const { data: otherProductObjects, isError: otherObjectsIsError, isFetching: isFetchingOtherObjects, refetch: refetchObjects } = useRecsFetchInstructionTypeProductsQuery({ instructionTypeId: instruction?.instructionTypeId, listType: "list" });

    // Map the objects to react-select options
    const otherProductOptions = useMemo(() => otherProductObjects?.map(({ id, productName }) =>
        ({ value: id, label: productName })) || [], [otherProductObjects]);

    const productObjects = useMemo(() => [
        ...(ownedProductOptions || []),
        ...(otherProductObjects?.filter(({ id }) => !ownedProductOptions?.some(({ id: ownedId }) => id === ownedId)) || [])
    ], [otherProductObjects, ownedProductOptions]);

    const isLoadingProductOptions = useMemo(() => ownedObjectsIsUninitialized || isFetchingOwnedObjects || isFetchingOtherObjects,
        [isFetchingOtherObjects, isFetchingOwnedObjects, ownedObjectsIsUninitialized]);

    const isError = useMemo(() => ownedObjectsIsError || otherObjectsIsError,
        [ownedObjectsIsError, otherObjectsIsError]);

    const refetch = useCallback(() => {
        var refetches = [refetchObjects()]
        if (!ownedObjectsIsUninitialized)
            refetches.push(fetchOwnedProducts({ masterAccountId, wrapperIds: instruction?.instructionType?.wrapperIds }).unwrap())

        return Promise.all(refetches)
    }, [refetchObjects, ownedObjectsIsUninitialized, fetchOwnedProducts, masterAccountId, instruction?.instructionType?.wrapperIds]);

    const instructionSection = useMemo(() => isInvest
        ? invest
        : divest,
        [divest, invest, isInvest]);

    const [realTimeProductId, setRealTimeProductId] = useState(instructionSection?.productId);
    const [realTimeProductDefaultCustodianId, setRealTimeProductDefaultCustodianId] = useState(null);

    // Update the real-time product default custodian ID when the product ID changes
    useEffect(() => {
        if (realTimeProductId == null)
            return;

        const product = productObjects?.find(({ id }) => id === realTimeProductId);

        setRealTimeProductDefaultCustodianId(product?.defaultCustodianId);
    }, [productObjects, realTimeProductId]);

    const productOptions = useMemo(() => {
        // Start with the products from the two fetches
        let validProductOptions = [
            // Filter out duplicates from this owned product list (will ask to do this backend at some point)
            ...(ownedProductOptions?.filter(filterDuplicates) || []),
            // Filter out owned products from the other product list
            ...(otherProductOptions?.filter(({ value: id }) => !ownedProductOptions?.some(({ value: ownedId }) => id === ownedId)) || [])
        ];

        // Add the current product to the list if it's not already there (i.e, it has been made inactive since selection)
        if (instructionSection?.productId != null && !validProductOptions.map(({ value }) => value).includes(instructionSection?.productId)) {
            validProductOptions.push({ value: instructionSection?.productId, label: instructionSection?.product?.productName });
        }

        return validProductOptions;
    }, [instructionSection?.product?.productName, instructionSection?.productId, otherProductOptions, ownedProductOptions]);

    const [fetchOwnerDesignations, { data: ownerDesignationOptions, isFetching: isFetchingOwnerDesignations, isUninitialized: ownerDesignationsIsUninitialized }] = useLazyFetchValuationDesignationsQuery();
    const [fetchDesignations, { data: otherDesignationOptions, isFetching: isFetchingOtherDesignations, isUninitialized: otherDesignationsIsUninitialized }, { lastArg: lastDesignationsArg }] = useLazyFetchDesignationsQuery();

    const isFetchingDesignations = useMemo(() =>
        isFetchingOwnerDesignations || isFetchingOtherDesignations || (realTimeProductId && ownerDesignationsIsUninitialized) || (realTimeProductId && otherDesignationsIsUninitialized),
        [isFetchingOwnerDesignations, isFetchingOtherDesignations, realTimeProductId, ownerDesignationsIsUninitialized, otherDesignationsIsUninitialized]);

    useEffect(() => {
        const refetchDesignationsTimeout = setTimeout(() => new Promise((resolve, reject) => {
            if (realTimeProductId) {
                fetchOwnerDesignations({ masterAccountId, productId: realTimeProductId })
                    .unwrap()
                    .then(() => fetchDesignations({ masterAccountId, productId: realTimeProductId, listType: "select" })
                        .unwrap()
                        .then(resolve));
            }
            resolve()
        }), 150)

        return () => clearTimeout(refetchDesignationsTimeout);
    }, [fetchDesignations, fetchOwnerDesignations, masterAccountId, realTimeProductId]);

    const [realTimeDesignationId, setRealTimeDesignationId] = useState(instructionSection?.designationId);

    const designationOptions = useMemo(() => {
        // Start with the designations from the two fetches

        // Remove duplicates from the owner list
        const filteredOwnedOptions = ownerDesignationOptions?.filter(filterDuplicates) || [];

        // Filter out owner designations from the other designation list
        const filteredOtherOptions = otherDesignationOptions?.filter(({ value: id }) =>
            !filteredOwnedOptions.some(({ value: ownedId }) => id === ownedId)) || [];

        let validOptions = [
            ...filteredOwnedOptions,
            ...filteredOtherOptions
        ];

        // Add the current designation to the list if it's not already there (i.e, it has been made inactive since selection)
        if (!validOptions.some(({ value }) => value === instructionSection?.designationId)) {
            // Needs to have been a pre-set designation to keep it in the list
            if (instructionSection?.designation != null)
                validOptions = [
                    { value: instructionSection?.designationId, label: instructionSection?.designation?.designation },
                    ...validOptions
                ];
        }

        return validOptions;
    }, [instructionSection?.designation, instructionSection?.designationId, otherDesignationOptions, ownerDesignationOptions]);

    useEffect(() => {
        setRealTimeDesignationId(instructionSection?.designationId)
    }, [instructionSection?.designationId])

    useEffect(() => {
        setRealTimeProductId(instructionSection?.productId);
    }, [instructionSection?.productId]);

    // Need this to real-time show/hide Tax Reclaim if the product chosen is a pension
    useEffect(() => {
        const productChangedTimeout = setTimeout(() => {
            if (isLoadingProductOptions)
                return;

            if (realTimeProductId) {
                if (isInvest)
                    realTimePatchInvest([patchReplace("product", productObjects?.find(({ id }) => id === realTimeProductId))]);
                else
                    realTimePatchDivest([patchReplace("product", productObjects?.find(({ id }) => id === realTimeProductId))]);
            }
        }, 150);

        return () => clearTimeout(productChangedTimeout);
    }, [isInvest, isLoadingProductOptions, productObjects, realTimePatchDivest, realTimePatchInvest, realTimeProductId])

    const patchService = useCallback((operations) => {
        return new Promise((resolve, reject) => {
            if (changeOwner === false) {
                const investPatch = patchInvest({ investId: invest?.id, instructionId, operations }).unwrap();
                const divestPatch = patchDivest({ divestId: divest?.id, instructionId, operations }).unwrap();

                return Promise.all([investPatch, divestPatch]).then(resolve, reject);
            }

            if (isInvest) {
                return patchInvest({ investId: invest?.id, instructionId, operations })
                    .unwrap()
                    .then(resolve, reject);
            }

            return patchDivest({ divestId: divest?.id, instructionId, operations })
                .unwrap()
                .then(resolve, reject);
        });
    }, [changeOwner, divest?.id, instructionId, invest?.id, isInvest, patchDivest, patchInvest])

    const onBlur = useCallback((property, value) =>
        patchService([patchReplace(property, value)]), [patchService]);

    const isLoading = useMemo(() => instructionIsFetching || investIsFetching || divestIsFetching,
        [divestIsFetching, instructionIsFetching, investIsFetching]);

    const {
        isFetchingProviders,
        isUninitializedProviders,
        providerOptions,
        custodyOptions,
        realTimeProviderRef,
        setRealTimeProviderRef,
        addCustomProvider,
        addCustomCustodian,
    } = useReferenceSelection({
        providerRef: instructionSection?.providerRef,
        custodyRef: instructionSection?.custodyRef,
        patch: patchService,
        realTimeDesignationId,
        realTimeProductId,
        realTimeProductDefaultCustodianId,
        hasLoaded: instructionSection != null
    });

    return {
        instructionId,
        instruction,
        instructionSection,
        invest,
        divest,
        productOptions,
        isLoadingProductOptions,
        designationOptions,
        providerOptions,
        custodyOptions,
        isFetchingProviders,
        isUninitializedProviders,
        realTimeProductId,
        realTimeDesignationId,
        realTimeProviderRef,
        isLoading,
        isFetchingDesignations,
        isError,
        lastDesignationsArg,
        setRealTimeProductId,
        setRealTimeDesignationId,
        setRealTimeProviderRef,
        addCustomProvider,
        addCustomCustodian,
        patchService,
        onBlur,
        refetch
    }
}

export default useProductSelection 
import { wealthProApi } from "../../redux/api";
import { applyPatch } from "fast-json-patch";

const agreedVolatilityProfileSectionEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createVolatilityProfile: builder.mutation({
            query: ({ sectionId, profileId }) => ({
                url: `api/factfind/AgreedVolatilityProfile/${sectionId}/Profile/${profileId}`,
                method: 'POST'
            }),
            async onQueryStarted({ masterAccountId, sectionId, profileId }, { dispatch, queryFulfilled }) {
                try {
                    const createResult = dispatch(wealthProApi.util.updateQueryData("fetchAgreedVolatilityProfileSection", { masterAccountId }, (draft) => ({
                        ...draft,
                        profiles: [
                            ...(draft.profiles ?? []),
                            {
                                volatilityProfileId: profileId,
                                agreedVolatilityProfileSectionId: sectionId,
                                isActive: true
                            }
                        ]
                    })));
                    try {
                        await queryFulfilled
                    } catch (ex) {
                        console.error("Error creating profile in service", ex);
                        createResult.undo();
                    }
                } catch (ex) {
                    console.error("Error adding new profile to cache", ex);
                }
            }
        }),
        patchVolatilityProfile: builder.mutation({
            query: ({ profileId, operations }) => ({
                url: `api/factfind/VolatilityProfile/${profileId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, profileId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchAgreedVolatilityProfileSection", { masterAccountId }, (draft) => {
                        const changeIndex = draft.profiles.findIndex(prof => prof.volatilityProfileId === profileId);
                        const changeProfile = { ...draft.profiles[changeIndex] };

                        const { newDocument } = applyPatch(changeProfile, operations);

                        return {
                            ...draft,
                            profiles: [
                                ...draft.profiles.slice(0, changeIndex),
                                newDocument,
                                ...draft.profiles.slice(changeIndex + 1)
                            ]
                        };
                    }));

                    try {
                        await queryFulfilled;
                    } catch (ex) {
                        console.error("Error patching profile in service", ex);
                        patchResult.undo();
                    }
                } catch (ex) {
                    console.error("Error patching profile in cache", ex);
                }
            }
        }),
        deleteVolatilityProfile: builder.mutation({
            query: ({ profileId }) => ({
                url: `api/factfind/VolatilityProfile/${profileId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ masterAccountId, profileId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData("fetchAgreedVolatilityProfileSection", { masterAccountId }, (draft) => ({
                        ...draft,
                        profiles: [
                            ...draft.profiles.slice(0, draft.profiles.findIndex(prof => prof.volatilityProfileId === profileId)),
                            ...draft.profiles.slice(draft.profiles.findIndex(prof => prof.volatilityProfileId === profileId) + 1)
                        ]
                    })));
                    try {
                        await queryFulfilled;
                    } catch (ex) {
                        console.error("Error deleting profile in service", ex);
                        deleteResult.undo();
                    }
                } catch (ex) {
                    console.error("Error deleting profile from cache", ex);
                }
            }
        })
    })
});

export const {
    useCreateVolatilityProfileMutation,
    usePatchVolatilityProfileMutation,
    useDeleteVolatilityProfileMutation
} = agreedVolatilityProfileSectionEndpoints;
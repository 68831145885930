import React from "react";
import classNames from "classnames";
import TableColumn from "./TableColumn";

const BooleanPillDisplayColumn = ({ 
    falseText = "False",
    trueText = "True",
    value = false
}) => {
    return (
        <TableColumn>
            <div className={classNames('badge', {
                'bg-boolean-pill-true text-boolean-pill-true' : value === true,
                'bg-boolean-pill-false text-boolean-pill-false' : value !== true
            })}>
                {value === true ? trueText : falseText}
            </div>
        </TableColumn>
    );
};

export default BooleanPillDisplayColumn;
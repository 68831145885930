import { wealthProApi } from "../../redux/api";

const assetFinexUniverseApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        createAssetFinexUniverse: build.mutation({
            query: ({ organisationId }) => ({
                url: 'api/assets/finexuniverse',
                method: 'POST',
                body: {
                    organisationId
                }
            })
        }),
        deleteAssetFinexUniverse: build.mutation({
            query: ({ finexUniverseId }) => ({
                url: `api/assets/finexuniverse/${finexUniverseId}`,
                method: 'DELETE'
            })
        }),
        fetchAssetFinexUniverses: build.query({
            query: ({ page, limit, sort, filterModel }) => ({
                url: `api/assets/finexuniverse/grid`,
                method: 'POST',
                body: {
                    page,
                    limit,
                    sort,
                    filterModel
                }
            })
        }),
        patchAssetFinexUniverse: build.mutation({
            query: ({ finexUniverseId, operations }) => ({
                url: `api/assets/finexuniverse/${finexUniverseId}`,
                method: 'PATCH',
                body: operations

            })
        })
    })
});

export const {
    useCreateAssetFinexUniverseMutation,
    useDeleteAssetFinexUniverseMutation,
    useLazyFetchAssetFinexUniversesQuery,
    usePatchAssetFinexUniverseMutation
} = assetFinexUniverseApiEndpoints;
import { wealthProApi } from "../../redux/api";

const clientRetirementPlansApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['factFindEmploymentStatus']
}).injectEndpoints({
    endpoints: (builder) => ({
        patchClientRetirementPlan: builder.mutation({
            query: ({ retirementPlanId, operations }) => ({
                url: `api/factfind/clientretirementplans/${retirementPlanId}`,
                method: 'PATCH',
                body: operations
            }),
            invalidatesTags:  ['factFindEmploymentStatus']
        })
    })
});

export const {
    usePatchClientRetirementPlanMutation
} = clientRetirementPlansApiEndpoints;
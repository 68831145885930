import { useFetchMessageUsersQuery } from "../../../services/messages";

const useMessageUsers = () => {

    const { data } = useFetchMessageUsersQuery({ userType: "Staff" });

    return {
        results: data || []
    };

};

export default useMessageUsers;
import React from "react";
import { useFetchMasterAccountDesignationsListQuery } from "../../../services/clientdetails";
import FormSelect from "../FormSelect";

const MasterAccountDesignationsSelect = ({ options, masterAccountId, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchMasterAccountDesignationsListQuery({ masterAccountId });
    return <FormSelect
        options={data}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default MasterAccountDesignationsSelect;
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router-dom";
import { useLazyFetchMasterPrdQuery, useLazyFetchPortfolioNoteOptionsQuery, usePatchPrdCommentaryMutation, usePublishAllPrdCommentariesMutation, usePublishPrdCommentaryMutation } from "../services/assets";
import { useFetchServiceAgreementsQuery } from "../services/clientserviceterms";

const PrdCommentaryContext = createContext();

const commentaryTypes = [
    { label: "Cover Letter:", path: "coverLetter", value: 0 },
    { label: "Economic Context:", path: "economicContext", value: 1 },
    { label: "Discussion Bullets:", path: "discussionBullets", value: 2 },
    { label: "Non-Standard Portfolio:", path: "nonStandardPortfolio", value: 3 },
    { label: "N/A", path: "portfolioSpecific", value: null }
];

export const usePrdCommentaryContext = () => useContext(PrdCommentaryContext);

const PrdCommentaryProvider = ({ children }) => {
    // URL params for requests
    const match = useMatch('/investment-committee/prdcommentary/:commentaryType');
    const { commentaryType } = useMemo(() => match?.params ?? {}, [match?.params]);

    const commentaryTypeObj = useMemo(() => {
        if (commentaryType == null) {
            return null;
        }

        return commentaryTypes.find((type) => type.path.toLowerCase() === commentaryType);
    }, [commentaryType]);

    // Fetch SLA options from the reviews service
    const { data: slaObjects, isFetching: isFetchingSlaOptions, isError: slaOptionsIsError, error: slaOptionsError, refetch: refetchSlaOptions } = useFetchServiceAgreementsQuery({ fetchType: 0 });
    const slaOptions = useMemo(() => slaObjects
        ?.filter(obj => obj.isPreReviewDocumentRequired)
        ?.map(obj => ({ label: obj.serviceName, value: obj.agreementId })), [slaObjects])

    const [serviceLevelAgreementId, setServiceLevelAgreementId] = useState(null);

    useEffect(() => {
        if (!isFetchingSlaOptions && !slaOptionsIsError && slaOptions?.length > 0) {
            setServiceLevelAgreementId(slaOptions[0].value);
        }
    }, [isFetchingSlaOptions, slaOptionsIsError, slaOptions?.length, slaOptions]);

    // Fetch the master prd commentaries list for the selected service level agreement
    const [fetchMasterPrdTrigger, { currentData: masterPrd, isFetching, isError, error }] = useLazyFetchMasterPrdQuery({ refetchOnReconnect: true });

    const fetchMasterPrd = useCallback(() =>
        fetchMasterPrdTrigger({
            serviceLevelAgreementId
        }).unwrap(),
        [fetchMasterPrdTrigger, serviceLevelAgreementId]);

    const refetchMaster = useCallback(() => refetchSlaOptions().then(res => res?.data && fetchMasterPrd(), _ => null), [refetchSlaOptions, fetchMasterPrd]);

    useEffect(() => {
        if (serviceLevelAgreementId)
            fetchMasterPrd();
    }, [fetchMasterPrd, serviceLevelAgreementId]);

    // Patching commentaries with HTML text
    const [patchCommentaryTrigger, { isLoading: isPatching, originalArgs: patchArgs }] = usePatchPrdCommentaryMutation();

    // Whether or not the given commentary is currently being patched, for displaying Loaders
    const isPatchingFn = useCallback((type) => isPatching && patchArgs?.type === type,
        [isPatching, patchArgs?.type]);

    const patch = useCallback((type, commentary) =>
        patchCommentaryTrigger({
            serviceLevelAgreementId,
            type,
            typeString: commentaryTypes.find(t => t.value === type)?.path,
            commentary
        }).unwrap(),
        [patchCommentaryTrigger, serviceLevelAgreementId]);

    // Publishing has two endpoints, one for an individual commentary type, and one for all commentaries
    // These are combined into one exposed 'publish' function, for simplicity
    const [publishCommentaryTrigger, { isLoading: isPublishing, originalArgs: publishArgs }] = usePublishPrdCommentaryMutation();
    const [publishAllCommentariesTrigger, { isLoading: isPublishingAll }] = usePublishAllPrdCommentariesMutation();

    const publish = useCallback((type) => type == null
        ? publishAllCommentariesTrigger({
            serviceLevelAgreementId
        }).unwrap()
        : publishCommentaryTrigger({
            serviceLevelAgreementId,
            type,
            typeString: commentaryTypes.find(t => t.value === type)?.path,
        }).unwrap(),
        [publishAllCommentariesTrigger, publishCommentaryTrigger, serviceLevelAgreementId]);

    // Whether or not the given commentary is currently being published, for displaying Loaders
    const isPublishingFn = useCallback((type) => isPublishingAll || (isPublishing && publishArgs?.type === type),
        [isPublishing, isPublishingAll, publishArgs?.type]);

    // Need to fetch possible portfolio-specific notes
    const [fetchPortfolioNoteOptionsTrigger, { currentData: portfolioNoteOptions, isFetching: noteOptionsIsFetching, isError: noteOptionsIsError, error: noteOptionsError }] = useLazyFetchPortfolioNoteOptionsQuery();

    const fetchPortfolioNoteOptions = useCallback((preferCacheValue) =>
        fetchPortfolioNoteOptionsTrigger({}, preferCacheValue ?? true).unwrap(),
        [fetchPortfolioNoteOptionsTrigger]);

    const refetchPortfolioNoteOptions = useCallback(() => fetchPortfolioNoteOptions(false), [fetchPortfolioNoteOptions]);

    return <PrdCommentaryContext.Provider
        value={{
            serviceLevelAgreementId,
            setServiceLevelAgreementId,
            slaOptions,
            isFetchingSlaOptions,
            masterPrd,
            isFetching: isFetching || isFetchingSlaOptions,
            isError: isError || slaOptionsIsError,
            error: error || slaOptionsError,
            refetchMaster,
            refetchSlaOptions,
            commentaryTypeObj,
            patch,
            isPatchingFn,
            publish,
            isPublishingFn,
            portfolioNoteOptions,
            refetchPortfolioNoteOptions,
            fetchPortfolioNoteOptions,
            noteOptionsIsFetching,
            noteOptionsIsError,
            noteOptionsError
        }}>
        {children}
    </PrdCommentaryContext.Provider>
}

export default PrdCommentaryProvider;
import { useUnlockMasterAccountDashboardLoginMutation } from "../../../services/clientauth";

const useUnlockAccount = () => {
    const [_unlock, { isLoading, ...unlockResult }] = useUnlockMasterAccountDashboardLoginMutation();

    const unlock = (masterAccountId, loginId) => {
        return new Promise((resolve, reject) => {
            return _unlock({ masterAccountId, loginId })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { unlock, isLoading, data: { isLoading, ...unlockResult } };
};

export default useUnlockAccount;
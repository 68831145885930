import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import GridWrapper from "../../../components/GridWrapper";

const HiddenIcon = styled(FontAwesomeIcon)`
    visibility: hidden;
`

const InfoBubble = ({ className, iconClassName, fieldKey, showBubble, ...rest }) => {
    // const { fieldInfo, fieldInfoIsLoading } = useInfoBubbleText();
    const fieldInfo = useMemo(() => [], []);
    const fieldInfoIsLoading = false;

    const infoText = useMemo(() =>
        fieldInfoIsLoading
            ? "Loading..."
            : fieldInfo?.find(obj => obj.fieldKey === fieldKey)?.description ?? ""
        , [fieldInfo, fieldInfoIsLoading, fieldKey]);

    const IconComponent = useMemo(() => showBubble ? FontAwesomeIcon : HiddenIcon, [showBubble]);

    return <OverlayTrigger
        key="trigger"
        className={className}
        overlay={(props) => <Tooltip {...props}>{infoText}</Tooltip>}
    >
        {<IconComponent
            key={`info-${fieldKey}${!showBubble ? '-hidden' : ''}`}
            className={`has-pointer ${iconClassName ?? ""}`}
            icon="circle-info"
            size="xl"
            {...rest}
        />}
    </OverlayTrigger>;
};

const FormInfoBubbleWrapper = ({ className, fieldKey, children, ...rest }) => {
    return <GridWrapper
        className={className}
        gridTemplateColumns={"1fr auto"}>
        <span className="col-start-1">
            {children}
        </span>
        <InfoBubble
            className="col-start-2"
            iconClassName="mt-2 me-auto"
            fieldKey={fieldKey}
            {...rest}
        />
    </GridWrapper>;
};

export {
    InfoBubble
};

export default FormInfoBubbleWrapper
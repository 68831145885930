import React from "react";
import FormSelect from "../FormSelect";

export const ProjectStatuses = [
    { label: 'In Progress', value: 0 },
    { label: 'Aborted', value: 1 },
    { label: 'Completed', value: 2 },
    { label: 'New Business', value: 3 },
    { label: 'Valuation', value: 4 },
    { label: 'Loop Closed', value: 5 },
    { label: 'Expired', value: 6 },
    { label: 'Planning', value: 7 },
    { label: 'Not Started', value: 8 },
    { label: 'Data Gathering', value: 9 },
    { label: 'Ready to Appoint', value: 10 },
    { label: 'Meeting Scheduled', value: 11 },
    { label: 'Awaiting Paperwork', value: 12 },
    { label: 'Unclassified', value: 13 }
];

const ClientProjectStatusSelect = ({ options, ...rest }) => {
    return <FormSelect
        options={ProjectStatuses.sort((a, b) => a.label > b.label ? 1 : -1)}
        {...rest}
    />
};

export default ClientProjectStatusSelect;
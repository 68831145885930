import _ from "lodash";
import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import { useClientAccessTableData } from "../hooks/ClientAccessTableDataContext";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

const ClientAccessTableActionHeaderWrapper = styled.th`
    width: 36px;
`;

export const ClientAccessTableActionHeader = ({ children, className, ...rest }) => (
    <ClientAccessTableActionHeaderWrapper className={classNames('sticky-header', className)} {...rest}>
        {children}
    </ClientAccessTableActionHeaderWrapper>
);

const ClientAccessTableHeader = ({ children, property, className }) => {
    const { sort, sortTable } = useClientAccessTableData();
    const sortProperties = sort.split(',');
    const isHeaderSorted = _.some(sortProperties, i => i.includes(property)); // isHeaderSorted - flag that says the property is part of the sort query
    const headerDirection = isHeaderSorted && _.some(sortProperties, i => i.toLowerCase().includes('+desc')) ? "desc" : "asc"; // tries to determine what direction you are sorting in

    const handleClick = () => sortTable(property, headerDirection == "desc" ? "+asc" : "+desc");

    return (
        <StandardTableSortableHeader
            className={classNames(className)}
            active={isHeaderSorted}
            direction={headerDirection}
            onClick={handleClick}
            isSticky={true}
        >
            {children}
        </StandardTableSortableHeader>
    );
};

export default ClientAccessTableHeader;
import {
    useFetchApplicationQuery,
    usePatchApplicationMutation
} from "../../../services/headofficeadmin";

const useApplication = (applicationId) => {

    const { data, isLoading, isError, error, refetch } = useFetchApplicationQuery({ applicationId });
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchApplicationMutation();

    const retry = (e) => {
        e?.preventDefault();
        refetch();
    };

    const updateApplication = (property, value) => {

        return new Promise((resolve, reject) => {

            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            let request = [{
                applicationId,
                patchDocument: operations
            }];

            return patch({ applicationId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        data,
        isLoading,
        isError,
        error,
        updateError,
        isErrorUpdating,
        isUpdating,
        retry,
        updateApplication,
    };

};

export default useApplication;
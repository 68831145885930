import { wealthProApi } from '../../redux/api';

const hoAdminDivisionApiEndpoints = wealthProApi
    .injectEndpoints({
        endpoints: (builder) => ({
            fetchHeadOfficeDivisions: builder.query({
                query: () => `api/headofficeadmin/divisions?sort=Description`
            })
        })
    });

export const {
    useLazyFetchHeadOfficeDivisionsQuery,
    useFetchHeadOfficeDivisionsQuery
} = hoAdminDivisionApiEndpoints;
import styled, { css } from "styled-components";

export const TruncateTextClass = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const TextEllipsisContainer = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export default TextEllipsisContainer;
import AdministrationCustodianLayout from "../layouts/AdministrationCustodianLayout";
import { NavigationTabs } from "../components/navigation";
import {
    CustodianAccountMatchingPage,
    CustodianTransactionMatchingPage
} from "../pages/administration";

const AdministrationCustodianRoutes = {
    path: 'custodians/*',
    pathBase: '/administration/custodians',
    defaultRoute: 'transactionmatching',
    roles: [],
    element: AdministrationCustodianLayout,
    children: [
        {
            path: 'transactionmatching',
            icon: 'right-left',
            label: 'Transaction Matches',
            roles: [],
            element: CustodianTransactionMatchingPage
        },
        {
            path: 'accountmatching',
            icon: 'user-group',
            label: 'Account Matches',
            roles: [],
            element: CustodianAccountMatchingPage
        },
    ]
};

export const AdministrationCustodianRouteTabs = () => <NavigationTabs pathBase={AdministrationCustodianRoutes.pathBase} options={AdministrationCustodianRoutes} />

export default AdministrationCustodianRoutes;
import React from "react";
import { useFetchReportStatusesListQuery } from "../../../services/clientreports";
import FormSelect from "../FormSelect";

const ReportStatusSelect = ({ masterAccountId = null, reportId = null, options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchReportStatusesListQuery({ masterAccountId, reportId });

    var displayOptions = data && Array.isArray(data) ? data : data?.results ?? [];
    return <FormSelect
        options={displayOptions}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default ReportStatusSelect;
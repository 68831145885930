import {  VirtualTable } from "../../../components/tables";
import { useProductsGridContext } from "../hooks/ProductsGridContext";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import ProductsGridRow from "./ProductsGridRow";
import ProductsGridHeader from "./ProductsGridHeader";
import { Main } from "../../../components/utilities";
import ProductsGridFooter from "./ProductsGridFooter";

const ProductsGrid = ({ }) => {

    const { loadMoreItems, isItemLoaded, results, productsError, isProductsError, columns, clear, totalCount } = useProductsGridContext();

    if (isProductsError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={productsError} retryCallback={clear} />
        </ErrorMessageWrapper>;
    }

    return (
        <VirtualTable
            enableScrollTracking={false}
            loadMoreItems={loadMoreItems}
            row={ProductsGridRow}
            itemSize={42}
            itemCount={totalCount === results.length ? totalCount : results.length + 25}
            itemData={results}
            isItemLoaded={isItemLoaded}
            header={<ProductsGridHeader />}
            footer={<ProductsGridFooter />}
        />
    );
};

export default ProductsGrid;
import React from "react";
import FormSelect from "../FormSelect";
import { useFetchHeadOfficeDivisionsQuery } from "../../../services/headofficeadmin";

const GenericHeadOfficeUserDivisionSelect = ({ ...rest }) => {
    const { data, isLoading } = useFetchHeadOfficeDivisionsQuery();
    return <FormSelect
        options={data}
        isLoadingOptions={isLoading}
        {...rest}
    />
};

export default GenericHeadOfficeUserDivisionSelect;
import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { useCorporateActionPairs } from "../hooks/CorporateActionPairsContext";

const CorporateActionPairsGridHeader = () => {

    const { columns } = useCorporateActionPairs();

    return <thead>
        <tr>
            {columns.map((col, key) => col.renderer
                ? col.renderer({ ...col, key })
                : <ThemedTableHeader key={key} className={col.className}>{col.label}</ThemedTableHeader>
            )}
        </tr>
    </thead>;

};

export default CorporateActionPairsGridHeader;
import React from "react";
import { useLazyGetAuthenticatorForLoginQuery } from "../../../services/clientauth";

const useLoginAuthenticator = (masterAccountId, loginId) => {
    const [_get, { data, isLoading, ...getResults }] = useLazyGetAuthenticatorForLoginQuery();
    const [defaultValues, setDefaultValues] = React.useState({ masterAccountId: null, loginId: null, id: null, authenticationType: -1, contactMethod: '', createdDate: new Date(), lastModifiedDate: null })

    React.useEffect(() => {
        if (!masterAccountId || !loginId) {
            return;
        }
        _get({ masterAccountId, loginId });
        setDefaultValues(_ => {
            return {
                masterAccountId,
                loginId,
                id: null, 
                authenticationType: -1, 
                contactMethod: '', 
                createdDate: new Date(), 
                lastModifiedDate: null
            };
        });
    }, [ masterAccountId, loginId ]);

    return {
        data: data || defaultValues,
        isLoading,
        info: {
            data,
            isLoading,
            ...getResults
        }
    };
};

export default useLoginAuthenticator;
import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { niceBytes } from "../../../helpers/fileHelpers";
import { Dropdown } from "react-bootstrap";
import { useId } from "react";
import useAttachments from "../../personalemailmessages/hooks/useAttachments";
import toast from "react-hot-toast";
import { BasicToast } from "../../../components/toasts";

const FileDisplayContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    width: 40%;
`;

const FileDisplayBox = styled.div`
    position: relative;
    background-color: rgba(200, 200, 200, 0.75);
    display: flex;
    width: 100%;
    min-width: 100%;
`;

const FileDisplayMenu = React.forwardRef(({ }, ref) => {

    return <div ref={ref}>
        This is the display menu!
    </div>;
});

const ToggleFileDisplay = React.forwardRef(({ files, onClick }, ref) => (
    <Button
        ref={ref}
        className="w-auto border"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        variant="light"
    >
        <ThemedSpan className="d-flex align-items-center justify-content-between">
            <ThemedSpan>{files.length}</ThemedSpan>
            <FontAwesomeIcon icon={"paperclip"} />
        </ThemedSpan>
    </Button>
));

const FileDisplay = ({ onDeleteAttachment, files = [] }) => {

    const id = useId();
    const [showAttachments, setShowAttachments] = useState(false);

    return <div>
        {files.map((file, index) => <FileDisplayBox key={index} className="rounded mb-1 mx-0 p-1 px-2 row">
            <div className="col p-0">
                {file.name} ({niceBytes(file.size)})
            </div>
            <div className="col-auto p-0">
                <FontAwesomeIcon className="has-pointer" size="sm" icon={"xmark"} onClick={(e) => onDeleteAttachment(file, e)}/>
            </div>
        </FileDisplayBox>)}
    </div>;

    return <Dropdown id="files-toggle" autoClose={false} drop={"start"}>
        <Dropdown.Toggle as={ToggleFileDisplay} files={files} id={id} />
        <Dropdown.Menu as={FileDisplayMenu} />
    </Dropdown>;

    return <div>
        <FileDisplayContainer>
            {showAttachments && files.map(file => (<FileDisplayBox className="rounded mb-1 mx-0 p-1 row">
                <div className="col p-0">
                    {file.name} ({niceBytes(file.size)})
                </div>
                <div className="col-auto p-0">
                    <FontAwesomeIcon className="has-pointer" size="sm" icon={"xmark"} />
                </div>
            </FileDisplayBox>))}
        </FileDisplayContainer>
        {files.length > 0 && <Button
            onClick={() => setShowAttachments(prev => !prev)}
        >

        </Button>}
    </div>
};

export default FileDisplay;
import styled from "styled-components";
import withProviders from "../../hooks/withProviders";
import ClientMessagesHeader from "./components/ClientMessagesHeader";
import MessageThreads from "./components/MessageThreads";
import AllMessagesListWrapper from "./components/AllMessagesListWrapper";
import ThreadMessagesListWrapper from "./components/ThreadMessagesListWrapper";
import { darken } from "polished";
import { Main } from "../../components/utilities";
import ClientMessagingProvider, { useClientMessaging } from "./hooks/ClientMessagingContext";

const MessageGridArea = styled(Main)`
    display: flex;
    flex-wrap: wrap;
    display: grid;
    min-height: 100%;
    grid-template-rows:     1fr 9fr;
    grid-template-columns:  ${props => props.showThreads ? `4fr minmax(auto, 350px);` : `1fr;`}
    grid-template-areas: ${props => props.showThreads ? `"header threads-header" "messages threads";` : `"header" "messages";`}
    row-gap: 0;
`;

const MessageGridHeaderArea = styled.div`
    grid-area: header;
    display: flex;
    align-items: center;
    z-index: 4;
    background-color: ${props => darken(0.07, props.theme.light)};
    border-bottom: 1px solid ${props => darken(0.15, props.theme.light)};
`;

const MessageGridMessagesArea = styled.div`
    grid-area: messages;
`;

const ClientMessages = () => {

    const { showThreads, threadId } = useClientMessaging();

    return <MessageGridArea showThreads={showThreads}>
        <MessageGridHeaderArea className="p-1 mb-3">
            <ClientMessagesHeader />
        </MessageGridHeaderArea>

        {showThreads && <MessageThreads/>}
        {<MessageGridMessagesArea>
            {!showThreads && <AllMessagesListWrapper />}
            {showThreads && threadId && <ThreadMessagesListWrapper />}
        </MessageGridMessagesArea>}
    </MessageGridArea>;
};

export default withProviders(ClientMessagingProvider)(ClientMessages);
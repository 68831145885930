import { useHeadOfficeApplicationContext } from "../../../hooks/HeadOfficeApplicationContext";
import { useCreateFeeGroupMutation } from "../../../services/headofficeadmin";

const useFeeGroup = () => {
    const { applicationId, application, ...rest } = useHeadOfficeApplicationContext();
    const { masterAccountId, providerId } = application || {};
    const [createTrigger] = useCreateFeeGroupMutation();

    const defaultValues = {
        masterAccountId: null,
        providerId: null,
        applicationId: null,
        description: null,
        vatable: false,
        vatRate: 0.0,
        feeType: null
    };

    const createFeeGroup = (request) => {
        return new Promise((resolve, reject) => {
            return createTrigger({ ...request, applicationId, masterAccountId, providerId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        defaultValues,
        createFeeGroup
    };

};

export default useFeeGroup;
import React from "react";
import { components } from "react-select";
import styled from "styled-components";
import { useLazySearchMasterAccountsListQuery } from "../../../services/clientsearch";
import FormSelectPaged from "../FormSelectPaged";

const MasterAccountOptionHeaderRow = styled.div``;

const MasterAccountOptionDetailsRow = styled.div`
    font-size: 0.875em;
    color: ${(props) => props.theme.muted};
`;

export const MasterAccountSelectOption = (props) => {
    const { data: { data } } = props;
    const { accountName, accountReference, accountTypeDescription, address, adviser } = data;
    return (
        <components.Option {...props}>
            <div>
                <MasterAccountOptionHeaderRow>
                    <span>{accountReference}</span> - <span>{accountName}</span>
                </MasterAccountOptionHeaderRow>
                <MasterAccountOptionDetailsRow className="row text-muted">
                    <div className="col-3">
                        <span>Type:</span> {accountTypeDescription}
                    </div>
                    <div className="col">
                        <span>Address:</span> {address}
                    </div>
                    <div className="col-3">
                        <span>Adviser:</span> {adviser}
                    </div>
                </MasterAccountOptionDetailsRow>
            </div>
        </components.Option>
    )
};

const MasterAccountSelect = ({
    className = "flex-fill",
    horizontal = false,
    id,
    isMulti = false,
    errorMessage = null,
    label,
    value,
    setValue,
    ...rest
}) => {
    return <FormSelectPaged
        className={className}
        horizontal={horizontal}
        value={value}
        query={useLazySearchMasterAccountsListQuery}
        setValue={setValue}
        onOptionsLoaded={(_, results) => results}
        components={{ Option: MasterAccountSelectOption }}
        isMulti={isMulti}
        errorMessage={errorMessage}
        label={label}
        {...rest}
    />;
}

export default MasterAccountSelect;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const otherDebtsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createOtherDebt: builder.mutation({
            query: ({ masterAccountId }) => ({
                url: 'api/factfind/otherdebts',
                method: 'POST',
                body: {
                    masterAccountId
                }
            }),
            async onQueryStarted({ masterAccountId }, { dispatch, queryFulfilled }) {

                let createdIndex;
                const createResult = dispatch(wealthProApi.util.updateQueryData('fetchOtherDebts', { masterAccountId }, (data) => {
                    const newDebt = {
                        masterAccountId,
                        debtType: null,
                        description: null,
                        balanceDue: null,
                        endDate: null,
                        createdDate: null,
                        borrowerMappings: []
                    };
                    createdIndex = data.length;

                    return [...data, newDebt];
                }));

                try {
                    const { data: response } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData('fetchOtherDebts', { masterAccountId }, (data) => [
                        ...data.slice(0, createdIndex),
                        {
                            ...data[createdIndex],
                            debtId: response.debtId,
                        },
                        ...data.slice(createdIndex + 1)]));
                } catch {
                    createResult.undo();
                }
            }
        }),
        deleteOtherDebt: builder.mutation({
            query: ({ debtId }) => ({
                url: `api/factfind/otherdebts/${debtId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ masterAccountId, debtId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteResult = dispatch(wealthProApi.util.updateQueryData('fetchOtherDebts', { masterAccountId }, (data) =>
                        data.filter(x => x.debtId !== debtId)));

                    try {
                        await queryFulfilled;
                    } catch (err) {
                        console.error("Error deleting other debt in service", err);
                        deleteResult.undo();
                    }
                } catch (err) {
                    console.error("Error deleting other debt in cache", err);
                }
            }
        }),
        patchOtherDebt: builder.mutation({
            query: ({ debtId, operations }) => ({
                url: `api/factfind/otherdebts/${debtId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, debtId, operations }, { dispatch, queryFulfilled }) {
                var result = dispatch(wealthProApi.util.updateQueryData('fetchOtherDebts', { masterAccountId }, (data) => {
                    var item = data.find(x => x.debtId === debtId);
                    applyPatch(item, operations, true);
                }));

                try {
                    await queryFulfilled;

                }
                catch {
                    result.undo();
                }
            }
        }),
        updateOtherDebtBorrowerMappings: builder.mutation({
            query: ({ debtId, ids }) => ({
                url: `api/factfind/otherdebts/${debtId}`,
                method: 'POST',
                body: {
                    ids
                }
            }),
            async onQueryStarted({ masterAccountId, debtId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: mappings } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchOtherDebts', { masterAccountId }, (data) => {
                        var item = data.find(x => x.debtId === debtId);
                        item.borrowerMappings = mappings;
                    }))
                }
                catch { }
            }
        }),
    })
});

export const {
    useCreateOtherDebtMutation,
    useDeleteOtherDebtMutation,
    usePatchOtherDebtMutation,
    useUpdateOtherDebtBorrowerMappingsMutation
} = otherDebtsApiEndpoints;
import { useReviseTransactionMutation } from "../../../services/valuations";

const useTransactionDetails = () => {

    const [revise, {}] = useReviseTransactionMutation();


    const reviseTransaction = (request) => {
        return new Promise((resolve, reject) => {
            return revise({
                request
            })
                .unwrap()
                .then(
                    (r) => resolve(r),
                    (e) => reject(e)
                );
        });
    };

    return {
        reviseTransaction
    };

};

export default useTransactionDetails;

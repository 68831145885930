import { IconButton } from "../../../components"
import { CurrencyInput, FormCheck, FormInput, FormSelect, FormTextArea, PercentageInput } from "../../../components/forms";

const ProductCostRow = ({ costId, index, section, cost, options: [postToOptions, costTypes, calculations], patchCache, removeRow }) => {

    const renderCostInput = () => {
        switch (calculations?.find(type => type.value === cost?.calculation)?.label) {
            case "Fixed":
                return <CurrencyInput
                    className={`col-start-${section === "Out" ? "6" : "5"}`}
                    value={cost?.totalCost ?? 0}
                    disableAnimations
                    onChange={(_event, { floatValue }) => patchCache("totalCost", floatValue)}
                    allowNegative={false}
                />;
            case "Percent":
                return <PercentageInput
                    className={`col-start-${section === "Out" ? "6" : "5"} text-end`}
                    value={cost?.totalCost ?? 0}
                    decimalScale={2}
                    fixedDecimalScale={false}
                    disableAnimations
                    onChange={(_event, { floatValue }) => patchCache("totalCost", floatValue)}
                />
            default:
                return <FormInput className="col-start-6" disabled />
        }
    }

    return <>
        {<span className="mx-auto mt-2 col-start-1">
            <FormCheck
                isChecked={cost?.apply}
                disableAnimations
                onChange={() => patchCache("apply", !cost?.apply)}
            />
        </span>}
        {section === "Out" && <FormSelect
            className="col-start-2"
            options={postToOptions}
            defaultValue={cost?.postTo}
            disableAnimations
            onChange={(selection) => patchCache("postTo", selection.value)}
        />}
        {<FormSelect
            className={`col-start-${section === "Out" ? "3" : "2"}`}
            options={costTypes ?? []}
            defaultValue={cost?.costType}
            disableAnimations
            onChange={(selection) => patchCache("costType", selection.value)}
        />}
        {<FormInput
            className={`col-start-${section === "Out" ? "4" : "3"}`}
            value={cost?.description ?? ""}
            disableAnimations
            onChange={(event) => patchCache("description", event.target.value)}
        />}
        {<FormSelect
            className={`col-start-${section === "Out" ? "5" : "4"}`}
            options={calculations ?? []}
            defaultValue={cost?.calculation}
            disableAnimations
            onChange={(selection) => patchCache("calculation", selection.value)}
        />}
        {renderCostInput()}
        {<FormTextArea
            className={`col-start-${section === "Out" ? "7" : "6"}`}
            value={cost?.narrative ?? ""}
            onChange={(event) => patchCache("narrative", event.target.value)}
        />}
        {<IconButton
            className={`col-start-${section === "Out" ? "8" : "7"}`}
            icon={"fa-trash"}
            variant={"danger"}
            onClick={removeRow}
        />}
    </>
}

export default ProductCostRow;
import React from "react";
import toast from "react-hot-toast";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BasicToast = React.forwardRef(({ action, actionLabel = "Action", control, date, isClearable = true, isDismissable = false, icon, message = "", title = "", ...props }, ref) => {
    return (
        <div className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
                {icon && (
                    <FontAwesomeIcon className="me-2" icon={icon} size="lg" />
                )}
                <strong className="me-auto">{title}</strong>
                {date && (
                    <small><Moment fromNow>{date}</Moment></small>
                )}
                {isClearable === true && (
                    <button 
                        type="button" 
                        className="btn-close" 
                        data-bs-dismiss="toast" 
                        aria-label="Close" 
                        onClick={() => toast.dismiss(control.id)}>
                    </button>
                )} 
            </div>
            <div className="toast-body">{message}</div>
            <div className="toast-footer">
                {action && (
                    <button 
                        type="button" 
                        className="btn btn-md flex-fill"
                        onClick={action}>
                        {actionLabel}
                    </button>
                )} 
                {isDismissable === true && (
                    <button 
                        type="button" 
                        className="btn btn-md flex-fill"
                        data-bs-dismiss="toast" 
                        aria-label="Close"
                        onClick={() => toast.dismiss(control.id)}>
                        Dismiss
                    </button>
                )}
            </div>
        </div>
    );
});

export default BasicToast;
import React, { useState } from "react";
import Button, { IconButton } from "../../components/buttons";
import { ReportSelect } from "../../components/forms/selects";
import { useClientContext } from "../../hooks/ClientContext";
import CreateReportModal from "../createreportmodal";

const ClientReportSearch = ({ 
    autoSelect = true,
    buttonSize = "lg", 
    formError = null,
    label = "Select Project:", 
    labelPosition = "side",
    onReportCreated = () => console.warn('onReportCreated has not been assigned.'), 
    onReportSelected = () => console.warn('onReportSelected has not been assigned.'),
    value,
    allowCreate = true,
    onClickCreate = null,
    reportSelectProps = {},
    ...rest 
}) => {
    const { account: { masterAccountId } } = useClientContext();
    const [isCreateShown, setIsCreateShown] = useState(false);

    const handleShow = () => {
        if (onClickCreate && typeof onClickCreate === 'function') {
            onClickCreate();
        }
        setIsCreateShown(true);
    };

    return <React.Fragment>
        {allowCreate && <CreateReportModal 
            show={isCreateShown}
            handleClose={() => setIsCreateShown(false)}
            onReportCreated={(e) => onReportCreated(e, autoSelect)}
        />}
        <div className="row" {...rest}>
            <div className="col d-flex justify-content-start align-items-center">
                <ReportSelect 
                    masterAccountId={masterAccountId}
                    label={labelPosition === 'side' ? null : label}
                    errorMessage={formError}
                    value={value}
                    setValue={(e) => onReportSelected(e, e.data)}
                    {...reportSelectProps}
                />
            </div>
            <div className={`col-md-auto d-flex justify-content-end ${formError != null ? 'align-items-center' : 'align-items-end'}`}>
                {buttonSize === 'lg' && (
                    <Button variant="success" onClick={handleShow}>Create new Report</Button>
                )}
                {buttonSize !== 'lg' && (
                    <IconButton icon="fa-plus" variant="success" onClick={handleShow} />
                )}
            </div>
        </div>
    </React.Fragment>
};

export default ClientReportSearch;
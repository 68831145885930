import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const clientPersonalDetailsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['factFindEmploymentStatus']
}).injectEndpoints({
    endpoints: (builder) => ({
        patchClientPersonalDetails: builder.mutation({
            query: ({ personalDetailsId, operations }) => ({
                url: `api/factfind/personaldetails/${personalDetailsId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, personalDetailsId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData('fetchPersonalDetails', { masterAccountId }, (data) => {
                        var item = data.find((x) => x.personalDetailsId === personalDetailsId);
                        applyPatch(item, operations, true);
                    }));

                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.error("Error updating FactFind service", error);
                        patchResult.undo();
                    }
                }
                catch (error) {
                    console.error("Error updating cache", error);
                }
            },
            invalidatesTags: ['factFindEmploymentStatus']
        }),
        updateClientConnectionMappings: builder.mutation({
            query: ({ personalDetailsId, connectionType, connectionIds, masterAccountId }) => ({
                url: `api/factfind/personaldetails/${personalDetailsId}`,
                method: 'POST',
                body: {
                    connectionIds,
                    connectionType,
                    masterAccountId
                }
            }),
            async onQueryStarted({ personalDetailsId, connectionType, connectionIds, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    dispatch(wealthProApi.util.updateQueryData('fetchPersonalDetails', { masterAccountId }, (data) => {
                        var personalDetails = data.find((x) => x.personalDetailsId === personalDetailsId);
                        var newConnectionList = connectionIds.map(id => ({
                            personalDetailsId,
                            masterAccountId,
                            personId: id
                        }));

                        switch (connectionType) {
                            case 'PowerOfAttorney':
                                personalDetails.powersOfAttorney = newConnectionList;
                                break;
                            case 'TrustedFriend':
                                personalDetails.trustedFriends = newConnectionList;
                                break;
                            case 'Solicitor':
                                personalDetails.solicitors = newConnectionList;
                                break;
                            case 'Accountant':
                                personalDetails.accountants = newConnectionList;
                                break;
                            case 'StockbrokerOrWealthManager':
                                personalDetails.stockbrokersAndWealthManagers = newConnectionList;
                                break;
                            default:
                                console.error("Unknown Connection Type")
                        }
                    }));
                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error updating FactFind service", error)
                    }
                }
                catch (error) {
                    console.error("Error updating cache", error);
                }
            }

        })
    })
});

export const {
    usePatchClientPersonalDetailsMutation,
    useUpdateClientConnectionMappingsMutation
} = clientPersonalDetailsApiEndpoints;
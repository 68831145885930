import React from "react";
import moment from "moment";
import { FormLabel } from "../../../components/forms";
import MomentComponent from "react-moment";
import classNames from "classnames";

const TargetDate = ({ date, targetDate, amberWarning = 5 }) => {

    const currentDate = moment();
    const eventDate = date == null ? null : moment(date);
    const eventTargetDate = targetDate == null ? null : moment(targetDate);
    const amberWarningEventTargetDate = targetDate == null ? null : moment(targetDate).add(amberWarning, 'days');

    const targetDateClassNames = [{
        'text-deadline-soon': eventDate != null && eventTargetDate.isAfter(eventDate),
        'text-deadline-due': (eventDate != null && eventDate.isAfter(eventTargetDate) && amberWarningEventTargetDate.isAfter(eventDate)) || (eventDate == null && currentDate.isAfter(eventTargetDate) && amberWarningEventTargetDate.isAfter(currentDate)),
        'text-deadline-overdue': (eventDate != null && eventDate.isAfter(amberWarningEventTargetDate)) || (eventDate == null && currentDate.isAfter(amberWarningEventTargetDate)),
        'text-deadline-future': (eventDate == null && eventTargetDate.isAfter(currentDate))
    }]

    return <FormLabel className={classNames(targetDateClassNames)}>
                Target:
                <MomentComponent
                    className="ps-1"
                    format="DD/MM/YYYY">
                    {targetDate}
                </MomentComponent>
            </FormLabel>
    
};

export default TargetDate;
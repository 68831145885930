import { confirmAlert } from "react-confirm-alert";
import styled, { useTheme } from "styled-components";
import { ProfileDisplayModal } from "../../../components/ProfileDisplay";
import ProfileIcon from "../../../components/ProfileIcon";
import { useActiveUsers } from "../../../hooks/ActiveUsersContext";

const ClickableProfileIcon = styled(ProfileIcon)`
    cursor: pointer;
    height: 32px;
    width: 32px;
    margin-top: auto;
    margin-bottom: auto;
`;

const AnalystIconDisplay = ({ analystId }) => {
    const theme = useTheme();
    const { data: { results: users } } = useActiveUsers();
    const user = users && users.length > 0 ? users.find(el => el.id === analystId) : null;

    const onIconClicked = () => {
        confirmAlert({
            closeOnEscape: false,
            customUI: (props) => {
                return <ProfileDisplayModal
                    user={user}
                    theme={theme}
                    {...props}
                />
            }
        });
    };

    if (!user) return <span></span>

    return <ClickableProfileIcon user={user} isOnline={user?.isOnline} showOnlineStatusIndicator={false} onClick={onIconClicked} />
}

export default AnalystIconDisplay;
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import Skeleton from "react-loading-skeleton";
import styled, { useTheme } from "styled-components";
import { IconButton } from "../../../components/buttons";
import { FormCheck, FormInput, FormSelect } from "../../../components/forms";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { StandardTable } from "../../../components/tables";
import useContactDetails from "../hooks/useContactDetails";
import BasicFetchError from "./BasicFetchError";
import DetailsWrapper from "./DetailsWrapper";

const ActiveColumn = styled.td`
    width: 70px;

    && > div.row {
        justify-content: center;
    }

    && > div.row > div {
        margin: 0;
    }
`;

const DefaultColumn = styled.td`
    width: 78px;

    && > div.row {
        justify-content: center;
    }

    && > div.row > div {
        margin: 0;
    }
`;

const ContactDetailsTableEmptyRow = ({ columns, onCreateClicked, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            There are no addresses available for this account.
            You can click <a href="#" onClick={onCreateClicked}>here</a> to create one.
        </td>
    </tr>
};

const ContactDetailsTableLoadingRow = ({ columns, loadingLines, ...rest }) => {
    return [...Array(loadingLines)].map((_, i) => (
        <tr key={`loading-${i}`} {...rest}>
            <td colSpan={columns.length}>
                <Skeleton count={1} height={37} />
            </td>
        </tr>
    ));
};

const ContactDetailsTableRow = ({ allowDelete = true, columns, data, onDefaultChanged, onDeleteClicked, onFieldChanged, ...rest }) => {
    const { contactDetailId, description, details, isActiveDetails, isDefault, type } = data;
    return <tr {...rest}>
        <td className="col-2">
            <FormSelect
                defaultValue={type}
                options={[
                    { label: 'Landline', value: 0 },
                    { label: 'Mobile', value: 1 },
                    { label: 'Email', value: 2 },
                    { label: 'Restricted Email', value: 4 },
                    { label: 'Fax', value: 3 },
                ]}
                onBlur={(values) => onFieldChanged(contactDetailId, 'type', values.value)}
            />
        </td>
        <td>
            <FormInput
                value={details}
                placeholder="Contact details"
                onBlur={(value) => onFieldChanged(contactDetailId, 'details', value)}
            />
        </td>
        <td>
            <FormInput
                value={description}
                placeholder="Description"
                onBlur={(value) => onFieldChanged(contactDetailId, 'description', value)}
            />
        </td>
        <ActiveColumn className="align-middle text-center">
            <FormCheck
                type="checkbox"
                isChecked={isActiveDetails}
                onChange={() => onFieldChanged(contactDetailId, 'isActiveDetails', !isActiveDetails)}
            />
        </ActiveColumn>
        <DefaultColumn className="align-middle text-center">
            <FormCheck
                type="radio"
                name="isContactDetailsDefaultRadio"
                isChecked={isDefault}
                onChange={() => onDefaultChanged(contactDetailId)}
            />
        </DefaultColumn>
        {allowDelete === true && (
            <td className="col-md-auto">
                <IconButton icon="fa-trash-can" variant="danger" onClick={() => onDeleteClicked(data)} />
            </td>
        )}
    </tr>
};

const getDeleteWarningMessage = (description) => {
    if (!description) {
        return `Are you sure you want to delete these contact details?`;
    }

    return `Are you sure you want to delete the contact details "${description}"?`;
};

const ContactDetails = ({ masterAccountId, loadingLines = 2 }) => {
    const { 
        createContactDetails,
        data,
        deleteContactDetails, 
        error, 
        isCreating,
        isError, 
        isLoading, 
        reload,
        setDefaultContactDetails,
        updateContactDetails 
    } = useContactDetails(masterAccountId);
    const theme = useTheme();

    const onCreateContactDetailsPressed = (e) => createContactDetails(e);

    const onDefaultContactDetailsChanged = (contactDetailId) => setDefaultContactDetails(contactDetailId);

    const onDeleteContactDetailsPressed = (contactDetail) => {
        const { contactDetailId, description } = contactDetail;
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return <ConfirmModal
                    theme={theme}
                    title="Delete Contact Details"
                    message={getDeleteWarningMessage(description)}
                    handleConfirm={() => deleteContactDetails(contactDetailId).finally(onClose)}
                    handleClose={onClose}
                />
            }
        });
    }

    const onFieldChanged = (contactDetailId, property, value) => updateContactDetails(contactDetailId, property, value);

    if (error || isError === true) {
        return <BasicFetchError error={error} retryCallback={reload} />
    }

    return <DetailsWrapper label="Contact Details" collapsedByDefault={true}>
        <StandardTable
            className="table-borderless"
            columns={[
                { label: 'Contact Type', property: 'title' },
                { label: 'Details', property: 'forenames' },
                { label: 'Display Name', property: 'surname' },
                { 
                    label: 'Active', 
                    property: 'isActiveDetails',
                    headerClassName: 'text-center'
                },
                { 
                    label: 'Default', 
                    property: 'isDefault',
                    headerClassName: 'text-center'
                },
                { 
                    label: '', 
                    property: '',
                    headerRenderer: (props) => (
                        <td key={props.key} style={{ width: '32px' }}>
                            <IconButton icon="fa-plus" variant="success" onClick={onCreateContactDetailsPressed} />
                        </td>
                    )
                }
            ]}
            data={data}
            error={error}
            isCreating={isCreating}
            isLoading={isLoading}
            EmptyRowComponent={ContactDetailsTableEmptyRow}
            emptyRowComponentProps={{ onCreateClicked: onCreateContactDetailsPressed }}
            hideHeaderOnEmpty={true}
            LoadingRowComponent={ContactDetailsTableLoadingRow}
            loadingRowComponentProps={{ loadingLines }}
            RowComponent={ContactDetailsTableRow}
            rowComponentProps={{ 
                allowDelete: true,
                onDefaultChanged: onDefaultContactDetailsChanged,
                onDeleteClicked: onDeleteContactDetailsPressed,
                onFieldChanged
            }}
        />
    </DetailsWrapper>
};

export default ContactDetails;
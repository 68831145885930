import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { DateInput, FormInput, FormLabel, FormSelect, FormTextArea } from "../../../components/forms";
import { BooleanSelect } from "../../../components/forms/selects";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useDependants from "../hooks/useDependants";
import SectionHeader from "./SectionHeader";

const DependantsList = () => {
    const {
        dependants,
        createDependant,
        deleteDependant,
        updateDependant,
        isErrorDependants,
        dependantsError,
        isLoadingDependants,
        refetchDependants
    } = useDependants();

    const updateProperty = (id, property, value) => updateDependant(id, property, value);

    const Header = () => useMemo(() => <SectionHeader className="my-auto me-2 col-start-1">
        Children & Dependants:
    </SectionHeader>, []);

    if (isLoadingDependants) {
        return <div className="my-5 m-3">
            <Header />
            <Skeleton height={18} count={3} />
        </div>
    }

    if (isErrorDependants) {
        return <div className="my-5 m-3">
            <Header />
            <ErrorMessageWrapper>
                <ErrorMessage error={dependantsError} retryCallback={refetchDependants} />
            </ErrorMessageWrapper>
        </div>;
    };

    return <div className="mt-5">
        <GridWrapper
            className="my-3"
            gridTemplateColumns={"minmax(auto, 97%) auto"}
        >
            <Header />
        </GridWrapper>

        {isLoadingDependants
            ? <Skeleton containerClassName="mt-2" width="100%" height={30} count={2} />
            : dependants?.length === 0
            && <p className="mt-2">There are no recorded dependants. Click <button className="anchor" onClick={createDependant}>here</button> to create one</p>}

        <GridWrapper
            className="my-3"
            gridTemplateColumns={"minmax(auto, 25%) minmax(auto, 12.5%) minmax(auto, 15%) minmax(auto, 15%) minmax(auto, 30%) auto"}
        >
            {dependants?.length > 0 && ["Name", "Date of Birth", "Relationship", "Lifelong Care", "Notes"]
                .map((label, index) =>
                    <FormLabel key={label.replace(' ', '').toLowerCase()} className={`col-start-${index + 1} mt-auto`}>
                        {label}
                    </FormLabel>)}
            {dependants?.length > 0 && <div className="col-start-6">
                <IconButton
                    icon="plus"
                    variant="success"
                    onClick={createDependant}
                />
            </div>}
            {dependants?.map((dependant, index) => <React.Fragment key={dependant.dependantId ?? index}>
                <div className="col-start-1">
                    {!dependant.dependantId
                        ? <Skeleton />
                        : <FormInput
                            value={dependant.dependantName}
                            onBlur={(newValue) => updateProperty(dependant.dependantId, "dependantName", newValue)}
                        />}
                </div>
                <div className="col-start-2">
                    {!dependant.dependantId
                        ? <Skeleton />
                        : <DateInput
                            value={dependant.dateOfBirth}
                            isClearable
                            onBlur={(date) => updateProperty(dependant.dependantId, "dateOfBirth", date)}
                        />}
                </div>
                <div className="col-start-3">
                    {!dependant.dependantId
                        ? <Skeleton />
                        : <FormSelect
                            defaultValue={dependant.relationship}
                            // Restricted ClientRelationshipConnections Enum
                            options={[
                                { label: 'Son', value: 6 },
                                { label: 'Grandson', value: 7 },
                                { label: 'Daughter', value: 8 },
                                { label: 'Granddaughter', value: 9 },
                                { label: 'Sibling', value: 10 },
                                { label: 'Brother', value: 11 },
                                { label: 'Sister', value: 12 },
                                { label: 'Family Member', value: 13 },
                                { label: 'Friend', value: 14 },
                            ]}
                            onBlur={(selection) => updateProperty(dependant.dependantId, "relationship", selection.value)}
                        />}
                </div>
                <div className="col-start-4">
                    {!dependant.dependantId
                        ? <Skeleton />
                        : <BooleanSelect
                            defaultValue={dependant.isLifelongCare}
                            onBlur={({ value }) => updateProperty(dependant.dependantId, "isLifelongCare", value)}
                        />}
                </div>
                <div className="col-start-5">
                    {!dependant.dependantId
                        ? <Skeleton />
                        : <FormTextArea
                            className="w-100"
                            value={dependant?.notes}
                            onBlur={(newValue) => updateProperty(dependant.dependantId, "notes", newValue)}
                        />}
                </div>
                <div className="col-start-6">
                    <IconButton
                        variant="danger"
                        icon="trash-can"
                        onClick={() => deleteDependant(dependant.dependantId)}
                        disabled={!dependant.dependantId}
                    />
                </div>
            </React.Fragment>)}
        </GridWrapper>
    </div>
}

export default DependantsList;
import _ from "lodash";
import { useMemo } from "react";
import { useAuth } from "react-oidc-context";

/** Hook that will filter out the route options based on current user roles  */
const useNavigationRoutes = (options) => {
    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;
    const { defaultRoute, pathBase, children } = options || { defaultRoute: 0, pathBase: '/', children: [] };

    const navigation = useMemo(() => {
        return children.filter(el => {
            // filter out if we set one of the routes to in-active
            if (el.hasOwnProperty('active') && el.active !== true) {
                return false;
            }

            // handle when there are no roles specified, it's all okay
            let requiredRoles = el.roles && Array.isArray(el.roles) ? el.roles : [];
            if (requiredRoles.length < 1) {
                return true;
            }

            // then check the roles list against what the profile/token contains
            return _.some(roles, i => requiredRoles.includes(i));
        })
    }, [ children ]);

    return { defaultRoute, pathBase, children: navigation };
};

export default useNavigationRoutes;
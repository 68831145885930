
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { GridWrapper } from "../../../components";
import IconButton from "../../../components/buttons/IconButton";
import { CurrencyInput, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { patchReplace } from "../../../helpers/patchDoc";
import useRaiseFee from "../hooks/useRaiseFee";
import useReferenceSelection from "../hooks/useReferenceSelection";

const RetainedProceedsDisplay = ({ raised, collected }) => <>
    <FormLabel className="my-auto">Proceeds Retained in Cash:</FormLabel>
    <CurrencyInput
        value={raised - collected}
        disabled
    />
</>;

const RaiseFee = () => {
    const [{
        createFee,
        updateFeeType,
        updateFeeAmount,
        patchFee,
        patchFeeAmount,
        patchFeeType,
        deleteFee,
        bulkPatchDivest,
        patchDivest,
        addTransaction,
        patchTransaction,
        removeTransaction,
        setRealTimeRaisedAmount,
        refetchDivest,
    }, {
        fees,
        realTimeRaisedAmount,
        realTimeFeeTotal,
        realTimeVatAmounts,
        realTimeRemainder,
        realTimeCollectedAmount,
        isLoading,
        divest,
        divestIsError,
        divestTransaction,
        divestTransactionsIsError,
        isAdding,
        isRemoving,
        feeTypes,
        isLoadingFeeTypes,
        designations,
        isLoadingDesignations,
        products,
        isLoadingProducts
    }] = useRaiseFee();

    const [realTimeDesignationId, setRealTimeDesignationId] = useState();
    const [realTimeProductId, setRealTimeProductId] = useState();

    useEffect(() => {
        if (divest == null)
            return;

        setRealTimeDesignationId(divest.designationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divest?.designationId]);

    useEffect(() => {
        if (divest == null)
            return;

        setRealTimeProductId(divest.productId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divest?.productId])

    const {
        isFetchingProviders,
        isUninitializedProviders,
        providerOptions,
        custodyOptions,
        realTimeProviderRef,
        setRealTimeProviderRef,
        addCustomProvider,
        addCustomCustodian,
    } = useReferenceSelection({
        providerRef: divest?.providerRef,
        custodyRef: divest?.custodyRef,
        patch: bulkPatchDivest,
        realTimeDesignationId,
        realTimeProductId,
        hasLoaded: divest != null
    })

    if (isLoading)
        return <Skeleton count={13} />

    return <React.Fragment>
        {fees?.length === 0
            ? <p>
                No fees have been added to this instruction yet. Click <button className="anchor" onClick={createFee}>here</button> to add one.
            </p> : <>
                <GridWrapper gridTemplateColumns={"minmax(auto, 1fr) minmax(auto, 0.5fr) 15ch 15ch auto"} >
                    <FormLabel>
                        Fee Description
                    </FormLabel>
                    <FormLabel>
                        Fee Type
                    </FormLabel>
                    <FormLabel>
                        Fee Amount
                    </FormLabel>
                    <FormLabel>
                        VAT (20%)
                    </FormLabel>
                    <IconButton
                        icon={"circle-plus"}
                        onClick={createFee}
                        disabled={isLoading}
                        variant={"success"}
                    />

                    {fees?.map((fee, index) => <React.Fragment key={fee?.feeId ?? index}>
                        <span className="col-start-1">
                            <FormInput
                                className="col-start-1"
                                value={fee?.description}
                                onBlur={(value) => patchFee(fee?.feeId, "description", value)}
                            />
                        </span>
                        <FormSelect
                            defaultValue={fee?.type}
                            options={feeTypes}
                            isLoadingOptions={isLoadingFeeTypes}
                            onChange={(selection) => updateFeeType(fee?.feeId, selection.value)}
                            onBlur={(selection) => patchFeeType(fee?.feeId, selection.value)}
                        />
                        <CurrencyInput
                            value={fee?.amount}
                            onChange={(_, { floatValue }) => updateFeeAmount(fee?.feeId, floatValue ?? 0)}
                            onBlur={(value) => patchFeeAmount(fee?.feeId, value ?? 0)}
                            allowNegative={false}
                        />
                        <CurrencyInput
                            value={realTimeVatAmounts?.[index] ?? fee?.vatAmount ?? 0}
                            readOnly
                        />
                        <IconButton
                            icon={"fa-trash"}
                            onClick={() => deleteFee(fee?.feeId)}
                            disabled={!fee?.feeId}
                            variant={"danger"}
                        />
                    </React.Fragment>)}

                    <FormLabel className="col-start-2 justify-self-end my-auto">Total:</FormLabel>
                    <CurrencyInput
                        value={realTimeFeeTotal}
                        disabled
                    />
                </GridWrapper>


                <div className=" mt-4 d-flex flex-row justify-content-evenly">
                    <GridWrapper className="px-3" gridTemplateColumns={"1fr 15ch"}>
                        <FormLabel className="my-auto">Amount to be paid directly by the client:</FormLabel>
                        <CurrencyInput
                            value={realTimeRemainder}
                            disabled
                        />
                    </GridWrapper>

                    <GridWrapper className="px-3" gridTemplateColumns={"1fr 15ch"}>
                        <FormLabel className="my-auto">Payment to be collected from investment:</FormLabel>
                        <CurrencyInput
                            value={realTimeCollectedAmount}
                            disabled
                        />
                    </GridWrapper>

                    <GridWrapper className="d-none d-lg-grid px-3" gridTemplateColumns={"1fr 15ch"}>
                        <RetainedProceedsDisplay raised={realTimeRaisedAmount} collected={realTimeCollectedAmount} />
                    </GridWrapper>
                </div>

                <div className="d-flex flex-row justify-content-center">
                    <GridWrapper className="d-lg-none mt-2 mx-auto" gridTemplateColumns={"1fr 15ch"}>
                        <RetainedProceedsDisplay raised={realTimeRaisedAmount} collected={realTimeCollectedAmount} />
                    </GridWrapper>
                </div>
                {/* 
                <GridWrapper className="d-lg-none mt-2 mx-auto" gridTemplateColumns={"25ch 15ch"}>
                    <FormLabel className="my-auto">Total amount to be collected from investment:</FormLabel>
                    <CurrencyInput
                        value={realTimeRaisedAmount - realTimeCollectedAmount}
                        disabled
                    />
                </GridWrapper> */}

                {(divestIsError || divestTransactionsIsError)
                    ? <ErrorMessageWrapper >
                        <ErrorMessage message="Sorry! We were unable to load the details for this instruction. Please try again." retryCallback={refetchDivest} />
                    </ErrorMessageWrapper>
                    : (isAdding || isRemoving)
                        ? <Skeleton count={1} height={42} />
                        : divestTransaction
                            ? <GridWrapper gridTemplateColumns={"minmax(auto, 1fr) minmax(auto, 0.8fr) 20ch 20ch minmax(auto, 1fr) 15ch auto"} className={"mt-3"}>
                                <FormSelect
                                    label={"Fee Product:"}
                                    defaultValue={divest?.productId}
                                    options={products ?? []}
                                    onChange={(selection) => setRealTimeProductId(selection.value)}
                                    onBlur={(selection) => patchDivest("productId", selection.value)}
                                    isLoadingOptions={isLoadingProducts}
                                />

                                <FormSelect
                                    label={"Designations:"}
                                    defaultValue={divest?.designationId}
                                    options={designations ?? []}
                                    onChange={(selection) => setRealTimeDesignationId(selection.value)}
                                    onBlur={(selection) => patchDivest("designationId", selection.value)}
                                    isLoadingOptions={isLoadingDesignations}
                                />

                                <FormSelect
                                    label="Provider Reference:"
                                    options={providerOptions}
                                    defaultValue={divest?.providerRef}
                                    onCreateOption={addCustomProvider}
                                    onChange={(selection) => setRealTimeProviderRef(selection.value)}
                                    onBlur={(selection) => new Promise((resolve, reject) => {
                                        // If custodyRef is still in the new custodian options list, don't update it
                                        // If there is only one option in the new custodian options list, update custodyRef to that option
                                        // If there are multiple (or 0) options in the new custodian options list, update custodyRef to null
                                        if (custodyOptions.some(opt => opt.value === divest?.custodyRef))
                                            return patchDivest("providerRef", selection.value.toUpperCase())
                                                .then(resolve, reject);

                                        if (custodyOptions.length === 1)
                                            return bulkPatchDivest([patchReplace("providerRef", selection.value.toUpperCase()), patchReplace("custodyRef", custodyOptions[0].value.toUpperCase())])
                                                .then(resolve, reject);

                                        return bulkPatchDivest([patchReplace("providerRef", selection.value.toUpperCase()), patchReplace("custodyRef", selection.value.toUpperCase())])
                                            .then(resolve, reject);
                                    })}
                                    isLoadingOptions={isFetchingProviders}
                                    isDisabled={realTimeProductId == null || realTimeDesignationId == null || isFetchingProviders || isUninitializedProviders}
                                    isCreateable
                                />

                                <FormSelect
                                    label="Custody Reference:"
                                    options={custodyOptions}
                                    defaultValue={custodyOptions.length === 1
                                        ? custodyOptions[0].value
                                        : divest?.custodyRef}
                                    onCreateOption={addCustomCustodian}
                                    onBlur={(selection) => bulkPatchDivest([patchReplace("custodyRef", selection.value.toUpperCase()), patchReplace("custodyId", selection.custodyId)])}
                                    isLoadingOptions={isFetchingProviders}
                                    isDisabled={realTimeProductId == null || realTimeDesignationId == null || isFetchingProviders || isUninitializedProviders || realTimeProviderRef == null}
                                    isCreateable
                                />

                                <FormInput
                                    label={"Investment to Sell:"}
                                    value={divestTransaction?.assetName}
                                    onBlur={(currentValue) => patchTransaction("assetName", currentValue ?? "")}
                                />

                                <CurrencyInput
                                    label="Raise Amount:"
                                    value={realTimeRaisedAmount}
                                    onChange={(_, { floatValue }) => setRealTimeRaisedAmount(floatValue ?? 0)}
                                    onBlur={(currentValue) => patchTransaction("saleValue", currentValue ?? 0)}
                                    allowNegative={false}
                                />

                                <IconButton
                                    className="mt-auto"
                                    icon={"fa-trash"}
                                    onClick={removeTransaction}
                                    variant={"danger"}
                                />
                            </GridWrapper>
                            : <p className="mt-3">No products have been registered to pay the above fees. Click <button className="anchor" onClick={addTransaction}>here</button> to add one.</p>}
            </>}
    </React.Fragment>;
}

export default RaiseFee;
const initialColours = {
    'A': '#706fd3',
    'B': '#aaa69d',
    'C': '#6ab04c',
    'D': '#6ab04c',
    'E': '#706fd3',
    'F': '#3742fa',
    'G': '#4834d4',
    'H': '#be2edd',
    'I': '#6ab04c',
    'J': '#22a6b3',
    'K': '#c56cf0',
    'L': '#ff4757',
    'M': '#2bcbba',
    'N': '#6ab04c',
    'O': '#fa8231',
    'P': '#c56cf0',
    'Q': '#33d9b2',
    'R': '#4b6584',
    'S': '#22a6b3',
    'T': '#33d9b2',
    'U': '#4b6584',
    'V': '#be2edd',
    'W': '#be2edd',
    'X': '#ff4757',
    'Y': '#ff4757',
    'Z': '#3742fa',
    'default': '#30336b'
};

export const getColoursForInitials = (firstInitial, secondInitial) => {
    return initialColours[firstInitial] ?? initialColours['default'];
};
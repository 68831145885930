import { useCreateClientAccountDetailsMutation, useDeleteClientAccountDetailsMutation, useFetchClientAccountDetailsQuery, usePatchClientAccountMutation } from "../../../services/clientdetails";
import moment from "moment";

const useClientAccountDetails = (masterAccountId) => {
    const { data, error, isError, isLoading, refetch } = useFetchClientAccountDetailsQuery({ masterAccountId });
    const [create, { error: createError, isError: isErrorCreating, isLoading: isCreating }] = useCreateClientAccountDetailsMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteClientAccountDetailsMutation();
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchClientAccountMutation();

    const createClientAccount = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            let data = {
                masterAccountId,
                status: 0,
                statusDate: moment().format(),
                title: '',
                forenames: '',
                surname: ''
            };

            return create({ masterAccountId, data }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteClientAccount = (clientAccountId) => {
        return new Promise((resolve, reject) => {
            return remove({ clientAccountId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        refetch({ masterAccountId });
    };

    const updateClientAccount = (clientAccountId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];
            return patch({ clientAccountId, masterAccountId, operations}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        createClientAccount,
        createError,
        deleteClientAccount,
        data: data || [],
        error,
        isError,
        isErrorCreating,
        isErrorRemoving,
        isErrorUpdating,
        isCreating,
        isLoading,
        isRemoving,
        isUpdating,
        reload,
        removeError,
        updateClientAccount,
        updateError
    };
};

export default useClientAccountDetails;
import { useFetchTransactionsInvestmentOperationsQuery } from "../../../services/valuations";

const useInvestmentOperations = () => {

    const { 
        data
    } = useFetchTransactionsInvestmentOperationsQuery('select');


    return {
        investmentOperations : data || []
    };
};

export default useInvestmentOperations;
import { patchReplace } from "../../../helpers/patchDoc";
import { useGetFiledMailByGraphIdQuery, usePatchFiledMailMutation } from "../../../services/useremails";

const useEmail = (graphId) => {
    const { data, isFetching, isError, error, refetch } = useGetFiledMailByGraphIdQuery({ graphId });
    const [patchMail] = usePatchFiledMailMutation();

    const reload = (e) => {
        if (e && typeof (e.preventDefault)) {
            e.preventDefault();
        }

        refetch();
    };

    const updateProject = (projectId) =>
        patchMail({ graphId, projectId }).unwrap();

    return {
        data: data || {},
        isFetching,
        isError,
        error,
        reload,
        updateProject
    };
};

export default useEmail;
import { Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { DataRow } from "../../../components";
import { FormLabel } from "../../../components/forms";
import { useBusinessSheetSearch } from "../hooks/BusinessSheetSearchContext";

const ResultsDisplayWrapper = styled.div`
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
    overflow-x: hidden !important;
`;

const ResultsDisplay = ({ hide }) => {

    const { columns, loadMore, pagination, page, isFetching, bizSheets, onSelect } = useBusinessSheetSearch();

    return <ResultsDisplayWrapper>
        <Row className="mb-3 fw-bold">
            {columns.map((col, i) => (<FormLabel className={`${col.className} ${i === 0 ? 'offset-1' : ''}`} key={i}>{col.header}</FormLabel>))}
        </Row>
        <StyledInfiniteScroll
            height={250}
            next={loadMore}
            hasMore={page !== pagination.totalPages}
            dataLength={bizSheets.length}
        >
            {isFetching
                ? <Skeleton count={bizSheets?.length ?? 10} height={42} />
                : bizSheets.map((d, i) => {
                    const folderIcon = {
                        icon: {
                            type: "fa-folder",
                            color: "#1266F1",
                            size: "sm",
                            onClick: () => {
                                onSelect(d);
                                hide();
                            }
                        },
                        className: "col-auto"
                    };

                    return <DataRow
                        className="mb-3"
                        columns={[folderIcon, ...columns]}
                        data={d}
                        key={i}
                    />
                })}
        </StyledInfiniteScroll>
    </ResultsDisplayWrapper>;
};

export default ResultsDisplay;
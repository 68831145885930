import React from "react";
import FormSelect from "../FormSelect";
import { useFetchReportTemplatesListQuery } from "../../../services/clientreports";

const ReportTemplateSelect = ({ options, ...rest }) => {
    const { data, isLoading, isFetching } = useFetchReportTemplatesListQuery({ });
    const { results } = data || { results: [] };
    return (
        <FormSelect 
            options={results}
            isLoadingOptions={isLoading || isFetching}
            {...rest}
        />
    );
};

export default ReportTemplateSelect;
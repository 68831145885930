import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchDesignationsQuery, useRecsFetchInstructionTypeWrappersQuery } from "../../../services/recommendations";
import { useFetchTransactionProvidersQuery } from "../../../services/valuations";

const usePolicyOptions = ({ instructionTypeId }) => {
    const { id: masterAccountId } = useClientContext();
    
    const { data: wrappers, isFetching: isFetchingWrappers } = useRecsFetchInstructionTypeWrappersQuery({ instructionTypeId });
    const { data: designations, isFetching: isFetchingDesignations } = useFetchDesignationsQuery({ masterAccountId, listType: "select" });
    const { data: providers, isFetching: isFetchingProviders } = useFetchTransactionProvidersQuery('select');
    
    return {
        wrappers,
        designations,
        providers,
        isFetchingWrappers,
        isFetchingDesignations,
        isFetchingProviders
    };
}

export default usePolicyOptions;
import { useAuth } from "react-oidc-context";
import { useCountUserNotificationsQuery, useMarkNotificationsAsNotifiedMutation } from "../../../services/usernotifications";

const useCountNotifications = () => {
    const { user: { profile } } = useAuth();
    const { data, isError, isLoading } = useCountUserNotificationsQuery(profile?.sub.replace(/-/g, ''));
    const [triggerSetNotified] = useMarkNotificationsAsNotifiedMutation();

    const markNotificationsAsNotified = () => {
        if (data === 0) return;
        return new Promise((resolve, reject) => {
            return triggerSetNotified()
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        data,
        isError,
        isLoading,
        markNotificationsAsNotified
    };
};

export default useCountNotifications;
import { Modal } from "react-bootstrap";
import Button from "../../../components/buttons";
import { ThemedSpan } from "../../../components/utilities";

const CashRaisedUpdateModal = ({ onClose, onSubmit, show, infoOnly, isPatching }) => {

    const handleSubmit = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        onSubmit(e)
        onClose();
    }

    return <Modal show={show} centered>
        <Modal.Header onHide={onClose}>
            Update Cash Raised
        </Modal.Header>
        <Modal.Body>
            {!infoOnly && <ThemedSpan>Would you like to change the investment amount to reflect the changes made to total cash raised?</ThemedSpan>}
            {infoOnly && <ThemedSpan>You may need to change the investment amount to reflect the changes made to the total cash raised.</ThemedSpan>}
        </Modal.Body>
        <Modal.Footer>
            {!infoOnly && <Button variant="success" onClick={handleSubmit} disabled={isPatching}>Confirm</Button>}
            <Button variant="light" onClick={onClose}>Close</Button>
        </Modal.Footer>
    </Modal>
};

export default CashRaisedUpdateModal;
import _ from "lodash"
import React from "react"
import Moment from "react-moment";
import classNames from "classnames";
import styled from "styled-components";

const ReportDeadlineDateDisplayColumnParent = styled.td`
    width: ${({ width }) => width}%;
    max-width: 0;
    padding: 0.6rem !important;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ReportDeadlineDateDisplayColumn = ({ deadlineDate, status, width = 7.5, statusTerminals = [7, 10, 17] }) => {
    if (!deadlineDate) {
        // TODO: create a link/button to add a deadline date?
        return (
            <ReportDeadlineDateDisplayColumnParent width={width}>
                <span>-</span>
            </ReportDeadlineDateDisplayColumnParent>
        )
    }

    const dateDifference = Math.round((new Date(deadlineDate) - _.now()) / (1000 * 60 * 60 * 24));
    const className = [{
        'bg-deadline-overdue text-deadline-overdue': dateDifference < -5,
        'bg-deadline-due text-deadline-due': dateDifference >= -5 && dateDifference <= 0,
        'bg-deadline-soon text-deadline-soon': dateDifference > 0 && dateDifference <= 5,
        'bg-deadline-future text-deadline-future': dateDifference > 5
    }]

    return statusTerminals.includes(status)
        ? (
            <ReportDeadlineDateDisplayColumnParent width={width}>
                <span className={classNames('badge bg-deadline-future text-deadline-future')}>Completed</span>
            </ReportDeadlineDateDisplayColumnParent>
        )
        : (
            <ReportDeadlineDateDisplayColumnParent width={width}>
                <Moment
                    calendar={{
                        lastDay: 'Yesterday'
                    }}
                    className={classNames('badge', className)} 
                    fromNow 
                    utc
                >
                    {deadlineDate}
                </Moment>
            </ReportDeadlineDateDisplayColumnParent>
        );
};

const ReportDateDisplayColumn = ({ date, width = 7.5, deadlineDate }) => {
    if (!date) {
        return (
            <ReportDeadlineDateDisplayColumnParent width={width}>
                <span>-</span>
            </ReportDeadlineDateDisplayColumnParent>
        )
    }

    const calculateDifference = (d, n) => {
        if (!n) {
            return 0;
        }

        return Math.round((new Date(n) - new Date(d)) / (1000 * 60 * 60 * 24));
    };

    const diff = calculateDifference(date, deadlineDate);
    return (
        <ReportDeadlineDateDisplayColumnParent width={width}>
            <Moment
                format="DD/MM/YYYY"
                calendar={{
                    lastDay: 'Yesterday'
                }}
                className={classNames({
                    'text-deadline-soon': diff >= 0,
                    'text-deadline-due': diff < 0 && diff >= -5,
                    'text-deadline-overdue': diff < -5
                })} 
            >
                {date}
            </Moment>
        </ReportDeadlineDateDisplayColumnParent>
    );
};

export default ReportDateDisplayColumn;
import { wealthProApi } from "../../redux/api";
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

const clientAuthSessionsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["clientAuth", "clientAuthSessionCount"]
}).injectEndpoints({
    endpoints: (builder) => ({
        clearMasterAccountDashboardSessions: builder.mutation({
            query: ({ masterAccountId, loginId }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/sessions/clear`,
                method: 'POST',
                body: { }
            })
        }),
        countMasterAccountDashboardSessions: builder.query({
            query: ({ masterAccountId, loginId }) => `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/sessions/count`,
            providesTags: (result, error, arg) => {
                return ['clientAuthSessionCount'];
            }
        }),
        deleteMasterAccountDashboardSession: builder.mutation({
            query: ({ masterAccountId, loginId, sessionId }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/sessions/${sessionId}`,
                method: 'DELETE',
                body: {}
            }),
            invalidatesTags: ['clientAuthSessionCount']
        }),
        fetchMasterAccountDashboardSessions: builder.query({
            query: ({ masterAccountId, loginId, ...rest }) => ({
                url: `/api/clientauth/master-accounts/${masterAccountId}/dashboard/logins/${loginId}/sessions`,
                method: 'POST',
                body: { masterAccountId, loginId, ...rest }
            }),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
        })
    })
});

export const {
    useClearMasterAccountDashboardSessionsMutation,
    useCountMasterAccountDashboardSessionsQuery,
    useDeleteMasterAccountDashboardSessionMutation,
    useFetchMasterAccountDashboardSessionsQuery,
    useLazyCountMasterAccountDashboardSessionsQuery,
    useLazyFetchMasterAccountDashboardSessionsQuery
} = clientAuthSessionsApiEndpoints;
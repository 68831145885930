import styled from "styled-components";
import { calculateButtonColour, calculateButtonText, variantToColour } from "../../helpers/theme";

export const BackgroundThemedSpan = styled.span`
    color: ${props => calculateButtonText({ theme: props.theme, variant: props.variant })};
    background-color: ${props => calculateButtonColour(props)};
    border-color: ${props => calculateButtonColour(props)};
`

const ThemedSpan = styled.span`
    color: ${props => variantToColour({ theme: props.theme, variant: props.variant })};
`;

export default ThemedSpan;
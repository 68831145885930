import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const transactionEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchInvestTransaction: builder.query({
            query: ({ investId, rowTag }) => {
                return {
                    url: `api/recommendations/Invest/${investId}/Transaction/${rowTag}`,
                    method: "GET",
                }
            }
        }),
        addInvestTransaction: builder.mutation({
            query: ({ investId }) => ({
                url: `api/recommendations/Invest/${investId}/Transaction`,
                method: "POST",
            }),
            async onQueryStarted({ investId }, { dispatch, queryFulfilled }) {
                try {
                    let createdIndex;
                    const createResult = dispatch(wealthProApi.util.updateQueryData("fetchInvestTransactions", { investId }, (draft) => {
                        createdIndex = draft.length;
                        draft.push({
                            investId,
                            allocation: 0,
                            investmentAmount: 0
                        });
                    }));
                    try {
                        const { data } = await queryFulfilled
                        dispatch(wealthProApi.util.updateQueryData("fetchInvestTransactions", { investId }, (draft) => {
                            draft[createdIndex] = data;
                        }));
                    } catch (error) {
                        console.error("Error updating added transaction", error);
                        createResult.undo();
                    }
                } catch (error) {
                    console.error("Error adding transaction to cache", error);
                }
            }
        }),
        patchInvestTransaction: builder.mutation({
            query: ({ investId, rowTag, operations, index }) => {
                return {
                    url: `api/recommendations/Invest/${investId}/Transaction/${rowTag}`,
                    method: "PATCH",
                    body: operations
                };
            },
            async onQueryStarted({ investId, rowTag, operations, index }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(
                        wealthProApi.util.updateQueryData("fetchInvestTransactions", { investId }, (transactions) => {
                            const element = transactions[index];
                            applyPatch(element, operations)
                        })
                    )
                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error patching service", error);
                        patchResult.undo();
                    }
                } catch (error) {
                    console.error("Error patching in cache", error);
                }

            },
        }),
        removeInvestTransaction: builder.mutation({
            query: ({ investId, rowTag }) => ({
                url: `api/recommendations/Invest/${investId}/Transaction/${rowTag}`,
                method: "DELETE",
            }),
            async onQueryStarted({ investId, rowTag }, { dispatch, queryFulfilled }) {
                const removeResult = dispatch(
                    wealthProApi.util.updateQueryData("fetchInvestTransactions", { investId }, (array) => {
                        const elementIndex = array.findIndex(transaction => transaction.rowTag === rowTag);
                        array.splice(elementIndex, 1);
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    removeResult.undo();
                }
            }
        }),
        fetchDivestTransaction: builder.query({
            query: ({ divestId, rowTag }) => `api/recommendations/Divest/${divestId}/Transaction/${rowTag}`
        }),
        addDivestTransaction: builder.mutation({
            query: ({ divestId }) => ({
                url: `api/recommendations/Divest/${divestId}/Transaction`,
                method: "POST"
            }),
            async onQueryStarted({ divestId }, { dispatch, queryFulfilled }) {
                try {
                    let createdIndex;
                    const createResult = dispatch(wealthProApi.util.updateQueryData("fetchDivestTransactions", { divestId }, (draft) => {
                        createdIndex = draft.length;
                        draft.push({
                            divestId,
                        });
                    }));

                    try {
                        const { data } = await queryFulfilled;
                        dispatch(wealthProApi.util.updateQueryData("fetchDivestTransactions", { divestId }, (draft) => {
                            draft[createdIndex] = data;
                        }));
                    } catch (err) {
                        console.error("Error creating Transaction in service", err);
                        createResult.undo();
                    }

                } catch (err) {
                    console.error("Error creating Transaction in cache", err);
                }
            }
        }),
        patchDivestTransaction: builder.mutation({
            query: ({ divestId, rowTag, operations }) => {
                return {
                    url: `api/recommendations/Divest/${divestId}/Transaction/${rowTag}`,
                    method: "PATCH",
                    body: operations
                }
            },
            async onQueryStarted({ divestId, rowTag, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(
                        wealthProApi.util.updateQueryData("fetchDivestTransactions", { divestId }, (transactions) => {
                            const element = transactions.find(transaction => transaction.rowTag === rowTag);
                            applyPatch(element, operations)
                        })
                    );

                    try {
                        await queryFulfilled;
                    }
                    catch (err) {
                        console.error("Error patching service", err);
                        patchResult.undo();
                    }
                } catch (err) {
                    console.error("Error patching in cache", err);
                }
            }
        }),
        removeDivestTransaction: builder.mutation({
            query: ({ divestId, rowTag }) => ({
                url: `api/recommendations/Divest/${divestId}/Transaction/${rowTag}`,
                method: "DELETE"
            }),
            async onQueryStarted({ divestId, rowTag }, { dispatch, queryFulfilled }) {
                const removeResult = dispatch(
                    wealthProApi.util.updateQueryData("fetchDivestTransactions", { divestId }, (array) => {
                        const elementIndex = array.findIndex(transaction => transaction.rowTag === rowTag);
                        array.splice(elementIndex, 1);
                    })
                );
                try {
                    await queryFulfilled
                } catch {
                    removeResult.undo();
                }
            }
        })
    })
})

export const {
    useLazyFetchInvestTransactionQuery,
    useAddInvestTransactionMutation,
    usePatchInvestTransactionMutation,
    useRemoveInvestTransactionMutation,
    useLazyFetchDivestTransactionQuery,
    useAddDivestTransactionMutation,
    usePatchDivestTransactionMutation,
    useRemoveDivestTransactionMutation,
} = transactionEndpoints
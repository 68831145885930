import { createContext, useContext } from "react";
import { useState } from "react";
import { useFetchCalendarEventsByUserIdQuery, useLazyGetCalendarEventByIdQuery } from "../../../services/usercalendar";
import moment from "moment";
import { DelegatedAccessTypes } from "../../../helpers/constants";
import { useAuth } from "react-oidc-context";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useFetchDelegatedUsersQuery } from "../../../services/identity";

const DiaryContext = createContext();

export const useDiaryContext = () => useContext(DiaryContext);

const DiaryContextProvider = ({ children }) => {

    const { user: { profile } } = useAuth();
    const { selectedCalendarUserId: userId } = usePersonalOutlookContext();
    const delegatedUsers = useFetchDelegatedUsersQuery({ accessType: DelegatedAccessTypes.readCalendar })
    const [monthIndex, setMonthIndex] = useState(0);

    const [getEventTrigger, getEventByIdResult] = useLazyGetCalendarEventByIdQuery();

    const { data, isLoading, isFetching, isError, error, refetch } = useFetchCalendarEventsByUserIdQuery({ userId, monthIndex }, {
        selectFromResult: (({ data, ...rest }) => {
            return {
                data: data?.value?.map(x => ({
                    id: x.id,
                    title: x.subject,
                    start: moment(x.start?.dateTime).toDate(),
                    end: moment(x.end?.dateTime).toDate(),
                    isAllDay: x.isAllDay,
                    masterAccountId: x.masterAccountId,
                    masterAccountName: x.masterAccountName,
                    isEditable: x.isEditable
                })),
                ...rest
            };
        })
    });

    const currentMonth = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setMonthIndex(0);
    };

    const getEventById = (eventId) => {
        return new Promise((resolve, reject) => {
            return getEventTrigger({userId, eventId})
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const nextMonth = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setMonthIndex(prev => prev + 1);
    };

    const previousMonth = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setMonthIndex(prev => prev - 1);
    };

    const reload = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    };

    return <DiaryContext.Provider value={{
        delegatedUsers,
        data,
        isLoading,
        isFetching,
        isError,
        error,
        monthIndex,
        currentMonth,
        nextMonth,
        previousMonth,
        reload,
        getEventById,
        getEventByIdResult
    }}>
        {children}
    </DiaryContext.Provider>
};

export default DiaryContextProvider;
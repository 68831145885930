import React from "react";
import { ErrorMessage } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import useAdviceGrid from "../hooks/useAdviceGrid";
import AdviceGridHeaderRow from "./AdviceGridHeaderRow";
import AdviceGridRow from "./AdviceGridRow";

const AdviceGridErrorRow = ({ columns, error, retryFn, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            <ErrorMessage error={error} retryCallback={retryFn} />
        </td>
    </tr>
}

const AdviceGrid = () => {

    const {
        columns,
        data,
        error,
        isFetching,
        isError,
        isLoadingStatuses,
        statuses,
        totalCount,
        isItemLoaded,
        loadMoreItems,
        reload,
        triggerAdministration,
        updateStatus
    } = useAdviceGrid();

    if (isError) {
        return <AdviceGridErrorRow columns={columns} error={error} retryFn={reload} />;
    }

    return (
        <VirtualTable
            enableScrollTracking
            itemData={{
                data,
                statuses,
                updateStatus,
                isFetching,
                isLoadingStatuses,
                triggerAdministration
            }}
            isLoading={isFetching}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            itemCount={totalCount === data.length ? totalCount : data.length + 10}
            itemSize={53}
            row={AdviceGridRow}
            header={<AdviceGridHeaderRow columns={columns} />}
        />
    )
}

export default AdviceGrid;
import { Spinner } from "../../../components/loaders";
import usePrdCommentary from "../hooks/usePrdCommentary";
import PrdCommentaryInput from "./PrdCommentaryInput";

const PrdCommentaryTab = () => {
    const { commentary, isFetching, patchCommentary, isPatching, publishCommentary, isPublishing } = usePrdCommentary();

    return <div className="px-3">
        {isFetching
            ? <Spinner />
            : <PrdCommentaryInput
                className="mt-4"
                commentary={commentary}
                isLoading={isFetching}
                patchCommentary={patchCommentary}
                isPatching={isPatching}
                publishCommentary={publishCommentary}
                isPublishing={isPublishing}
            />}
    </div>;
}

export default PrdCommentaryTab;
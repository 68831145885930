
import { User } from "oidc-client-ts";

export default class OidcTokenClient {
    getOidcToken() {
        var sessionData = sessionStorage.getItem('oidc.config');
        if (!sessionData) {
            return null;
        }

        const oidc = JSON.parse(sessionData);
        const url = oidc.host;
        const realm = oidc.realm;
        const client = oidc.client;

        const oidcStorage = sessionStorage.getItem(`oidc.user:${url}/realms/${realm}:${client}`);
        if (!oidcStorage) {
            return null;
        }

        return User.fromStorageString(oidcStorage);
    }
};
import { useClientContext } from "../../../hooks/ClientContext";
import { useDocumentFilterContext } from "../../../hooks/DocumentFilterContext";
import { ClientDocumentsProvider } from "../../../hooks/ClientDocumentsContext";

const withClientDocumentsProvider = (WrappedComponent) => (props) => {
    const { id : masterAccountId } = useClientContext();
    const { filter } = useDocumentFilterContext();
    return (
        <ClientDocumentsProvider masterAccountId={masterAccountId} filter={filter}>
            <WrappedComponent {...props} />
        </ClientDocumentsProvider>
    );
};

export default withClientDocumentsProvider;
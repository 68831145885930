import React from "react";
import moment from "moment";
import SlaAdminTableRowColumn from "./SlaAdminTableRowColumn";
import { ThemedIcon } from "../../../components/utilities";

// TODO: this is a direct copy from the old AG Grid renders
// I believe it needs tweaking..?

const SlaAdminCheckTableRowColumn = ({ data, size = "lg" }) => {
    if (data == null) {
        return (
            <SlaAdminTableRowColumn className="text-center">
                <span>-</span>
            </SlaAdminTableRowColumn>
        );
    }

    
    return (
        <SlaAdminTableRowColumn className="text-center">
            {data  && <ThemedIcon variant="success" icon="fa-circle-check" size={size} />}
            {!data && <ThemedIcon variant="danger" icon="fa-circle-xmark" size={size} />}
        </SlaAdminTableRowColumn>
    );
};

export default SlaAdminCheckTableRowColumn;
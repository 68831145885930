import Skeleton from "react-loading-skeleton";
import { IconButton } from "../../../components/buttons";
import { FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { NotesEditor } from "../../../components/forms/richtexteditor/RichTextEditor";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { patchReplace } from "../../../helpers/patchDoc";
import { InfoBubble, SectionHeader } from "../components";
import useAgreedVolatilityProfileSection from "../hooks/useAgreedVolatilityProfileSection";

const AgreedVolatilityProfileSection = () => {
    const [{
        refetchAll,
        patchNotes,
        createProfile,
        patchProfile,
        bulkPatchProfile,
        deleteProfile
    }, {
        section,
        profiles,
        options: {
            designationOptions,
            wrapperOptions,
            profileOptions,
            standardPortfolioOptions
        },
        isCreating,
        isLoading,
        optionsIsLoading,
        isError,
        error
    }] = useAgreedVolatilityProfileSection();

    return isError
        ? <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetchAll} />
        </ErrorMessageWrapper>
        : <div className="container-fluid">
            <div className="d-flex justify-content-between">
                <SectionHeader>
                    Targeted Volatility Profile(s)
                </SectionHeader>
                <InfoBubble
                    iconClassName="mt-4"
                    fieldKey={"volatilityProfile"}
                    showBubble
                />
            </div>

            {/* For lg view, show labels above the rows */}
            <div className="row d-none d-lg-flex">
                <div className="col-2">
                    <FormLabel>
                        Designation
                    </FormLabel>
                </div>
                <div className="col-8 col-lg-4 row">
                    <div className="col-8">
                        <FormLabel>
                            Wrapper
                        </FormLabel>
                    </div>
                    <div className="col-4">
                        <FormLabel>
                            Account ID
                        </FormLabel>
                    </div>
                </div>
                <div className="col-2">
                    <FormLabel>
                        Targeted Volatility
                    </FormLabel>
                </div>
                <div className="col-2">
                    <FormLabel>
                        Standard Portfolio
                    </FormLabel>
                </div>
            </div>

            {profiles?.map((profile, index) =>
                <div className="row mt-1 d-flex align-items-center" key={`profile-${profile.volatilityProfileId}`}>
                    {/* For smaller than lg, show labels in each profile */}
                    <div className="col-4 d-lg-none">
                        <FormLabel>
                            Designation
                        </FormLabel>
                    </div>
                    <div className="col-8 d-lg-none row">
                        <div className="col-6 d-lg-none">
                            <FormLabel>
                                Wrapper
                            </FormLabel>
                        </div>
                        <div className="col-6 d-lg-none">
                            <FormLabel>
                                Account ID
                            </FormLabel>
                        </div>
                    </div>

                    <div className="col-4 col-lg-2">
                        {profile?.isOverall
                            ? <>Portfolio Overall</>
                            : isLoading || (isCreating && index === profiles.length - 1)
                                ? <Skeleton />
                                : <FormSelect
                                    options={designationOptions}
                                    isLoadingOptions={optionsIsLoading.designations}
                                    defaultValue={profile?.designationId}
                                    onBlur={(selection) => patchProfile(profile?.volatilityProfileId, "designationId", selection?.value)}
                                    isClearable
                                />}
                    </div>
                    <div className="col-8 col-lg-4 row">
                        <div className="col-6 col-lg-8">
                            {profile?.isOverall
                                ? <>All Wrappers</>
                                : isLoading || (isCreating && index === profiles.length - 1)
                                    ? <Skeleton />
                                    : <FormSelect
                                        options={wrapperOptions}
                                        isLoadingOptions={optionsIsLoading.wrappers}
                                        defaultValue={profile?.wrapperId}
                                        onBlur={(selection) => patchProfile(profile?.volatilityProfileId, "wrapperId", selection?.value)}
                                        isClearable
                                    />}
                        </div>
                        <div className="col-6 col-lg-4">
                            {profile?.isOverall
                                ? <>All Accounts</>
                                : isLoading || (isCreating && index === profiles.length - 1)
                                    ? <Skeleton />
                                    : <FormInput
                                        value={profile?.accountReference}
                                        onBlur={(newValue) => patchProfile(profile?.volatilityProfileId, "accountReference", newValue)}
                                    />}
                        </div>
                    </div>


                    {/* Second row of labels */}
                    <div className="row d-lg-none mt-2 ms-0 px-0">
                        <div className="col-6">
                            <FormLabel>
                                Targeted Volatility
                            </FormLabel>
                        </div>
                        <div className="col-6">
                            <FormLabel>
                                Standard Portfolio
                            </FormLabel>
                        </div>
                    </div>
                    <div className="col-6 col-lg-2">
                        {isLoading || (isCreating && index === profiles.length - 1)
                            ? <Skeleton />
                            : <FormSelect
                                options={profileOptions}
                                isLoadingOptions={optionsIsLoading.profileOptions}
                                defaultValue={profile?.targetProfileId}
                                onBlur={(selection) => patchProfile(profile?.volatilityProfileId, "targetProfileId", selection.value)}
                            />}
                    </div>
                    <div className="col-5 col-lg-3">
                        {isLoading || (isCreating && index === profiles.length - 1)
                            ? <Skeleton />
                            : <FormSelect
                                options={standardPortfolioOptions}
                                isLoadingOptions={optionsIsLoading.standardPortfolios}
                                defaultValue={profile?.isBespokePortfolio ? -1 : profile?.portfolioId}
                                onBlur={(selection) => {
                                    let operations = [patchReplace("isBespokePortfolio", selection?.isBespokePortfolio === true)];

                                    if (selection?.isBespokePortfolio !== true)
                                        operations.push(patchReplace("portfolioId", selection?.value));
                                    else
                                        operations.push(patchReplace("portfolioId", null));

                                    return bulkPatchProfile(profile?.volatilityProfileId, operations);
                                }}
                                isClearable
                            />}
                    </div>
                    <div className="col-auto">
                        {isLoading || (isCreating && index === profiles.length - 1)
                            ? <></>
                            : <IconButton
                                icon={profile?.isOverall ? 'fa-plus' : 'trash-can'}
                                variant={profile?.isOverall ? 'success' : 'danger'}
                                onClick={profile?.isOverall ? createProfile : () => deleteProfile(profile?.volatilityProfileId)}
                            />}
                    </div>
                </div>)
                .flatMap((row, index) => [row, <hr key={`hr-${index}`} className="d-lg-none" />])
                .slice(0, -1)}

            <NotesEditor
                className="pt-3"
                label="Dashboard and PRD Comment"
                property={"dashboardAndPRDComment"}
                isLoading={isLoading}
                defaultValue={section?.dashboardAndPRDComment?.notes}
                onBlur={(newHtmlContent) => patchNotes(section?.dashboardAndPRDComment?.notesId, newHtmlContent)}
            />

            <NotesEditor
                className="pt-3"
                label="Portfolio Strategy and Objectives"
                property={"portfolioStrategyAndObjectives"}
                isLoading={isLoading}
                defaultValue={section?.portfolioStrategyAndObjectives?.notes}
                onBlur={(newHtmlContent) => patchNotes(section?.portfolioStrategyAndObjectives?.notesId, newHtmlContent)}
            />
        </div>

};

export default AgreedVolatilityProfileSection;
import { Modal } from "react-bootstrap";
import { FormInput } from "../../../components/forms";
import { Button } from "../../../components";
import { useProviderManagement } from "../hooks/ProviderManagementContext";

const EditProviderModal = () => {

    const { providerToEdit, setProviderIdToEdit, patchProvider } = useProviderManagement();

    console.log(providerToEdit)

    const {
        custodianCode,
        fundManagerCode,
        providerName
    } = providerToEdit || {};

    return (
        <Modal show={providerToEdit !== null} onHide={() => setProviderIdToEdit(null)} backdrop="static" size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Provider</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-2">
                    <div className="col">
                        <FormInput
                            label="Provider Name"
                            value={providerName}
                            onBlur={(value) => patchProvider("providerName", value)}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Fund Manager Code"
                            value={fundManagerCode}
                            onBlur={(value) => patchProvider("fundManagerCode", value)}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Custodian Code"
                            value={custodianCode}
                            onBlur={(value) => patchProvider("custodianCode", value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => setProviderIdToEdit(null)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditProviderModal;
import React from "react";
import ClientAccessTableRow from "./components/ClientAccessTableRow";
import CreateAccessModal from "./components/CreateAccessModal";
import ClientAccessTableHeader, { ClientAccessTableActionHeader } from "./components/ClientAccessTableHeader";
import { IconButton } from "../../components";
import { VirtualTable } from "../../components/tables";
import { withClientAccessTableDataProvider, useClientAccessTableData } from "./hooks/ClientAccessTableDataContext";
import { withClientAccessTableModalControls, useClientAccessTableModal  } from "./hooks/ClientAccessTableModalControlsContext";

const ClientAccess = () => {
    const { showCreate, setShowCreate } = useClientAccessTableModal();
    const { 
        clear,
        data,
        error, 
        isNextPageLoading, 
        isRowLoaded, 
        loadMore, 
        masterAccountId,
        sort,
        sortTable,
        totalCount 
    } = useClientAccessTableData();

    return (
        <React.Fragment>
            <CreateAccessModal 
                show={showCreate}
                masterAccountId={masterAccountId}
                handleClose={() => setShowCreate(_ => false)}
                handleOnCreated={() => {
                    setShowCreate(_ => false);
                    clear();
                }}
            />
            <section className="h-100 my-3 px-4">
                <VirtualTable 
                    enableScrollTracking={false}
                    row={ClientAccessTableRow}
                    header={(
                        <thead>
                            <tr>
                                <ClientAccessTableHeader property="username" sort={sort} sortTable={sortTable}>User</ClientAccessTableHeader>
                                <ClientAccessTableHeader property="isAccessBlocked" sort={sort} sortTable={sortTable}>Is Blocked</ClientAccessTableHeader>
                                <ClientAccessTableHeader property="createdDate" sort={sort} sortTable={sortTable}>Created Date</ClientAccessTableHeader>
                                <ClientAccessTableHeader property="createdById" sort={sort} sortTable={sortTable}>Created By</ClientAccessTableHeader>
                                <ClientAccessTableActionHeader>
                                    <IconButton icon="fa-plus" variant="success" onClick={() => setShowCreate(_ => true)} />
                                </ClientAccessTableActionHeader>
                            </tr>
                        </thead>
                    )}
                    error={error}
                    isLoading={isNextPageLoading}
                    isItemLoaded={isRowLoaded}
                    loadMoreItems={loadMore}
                    itemSize={50}
                    itemCount={totalCount}
                    itemData={data}
                />
            </section>
        </React.Fragment>
    )
};

export default withClientAccessTableModalControls(withClientAccessTableDataProvider(ClientAccess));
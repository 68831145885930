import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useEffect, useState } from "react";
import _ from "lodash";
import { DocumentTypesSelect, HeadOfficeUserSelect } from "../../../components/forms/selects";
import { DateInput, FormLabel } from "../../../components/forms";

const ApplicationDocumentsFilterModal = ({ onHide, show }) => {
    const {
        clearFilter,
        filter,
        setFilter,
    } = useFilterContext();

    const [tempFilters, setTempFilters] = useState(filter);

    const {
        userId,
        documentType,
        uploadedDateFrom,
        uploadedDateTo
    } = tempFilters;

    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        onHide();
    };

    useEffect(() => {
        if (!_.isEqual(tempFilters, filter)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);


    return <Modal centered show={show} onHide={onClose} backdrop={"static"}>
        <Modal.Header closeButton>
            <Modal.Title>Filter Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row mb-3">
                <HeadOfficeUserSelect
                    label="User"
                    defaultValue={userId}
                    onBlur={({ value }) => onFilterValueChanged("userId", value)}
                    disableAnimations
                />
            </div>
            <div className="row mb-3">
                <DocumentTypesSelect
                    label="Document Type"
                    defaultValue={documentType}
                    onBlur={({ value }) => onFilterValueChanged("documentType", value)}
                    disableAnimations
                />
            </div>
            <div className="row mb-3 d-flex align-items-end">
                <div className="col">
                    <DateInput
                        label="Uploaded Date"
                        value={uploadedDateFrom}
                        onBlur={(value) => onFilterValueChanged("uploadedDateFrom", value)}
                        isClearable
                        disableAnimations
                    />
                </div>
                <div className="col-auto mx-1 px-1">
                    <FormLabel>to</FormLabel>
                </div>
                <div className="col">
                    <DateInput
                        value={uploadedDateTo}
                        onBlur={(value) => onFilterValueChanged("uploadedDateTo", value)}
                        isClearable
                        disableAnimations
                    />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={clearFilter}>Clear Filters</Button>
            <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>

};

export default ApplicationDocumentsFilterModal;
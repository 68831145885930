import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";

const messagesThreadsApiEndpoints = wealthProApi
    .enhanceEndpoints({
        addTagTypes: ['messages', 'threads']
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getThreadById: builder.query({
                query: ({ threadId }) => `api/messaging/threads/${threadId}`,
                providesTags: ['threads']
            }),
            fetchMessagesByThreadId: builder.query({
                query: ({ threadId, page, limit, ...rest }) => ({
                    url: `api/messaging/threads/${threadId}/messages?page=${page}&limit=${limit}`,
                    method: "POST",
                    body: rest
                }),
                serializeQueryArgs: ({ endpointName, queryArgs }) => {
                    const { threadId } = queryArgs || {};
                    return `${endpointName}/${threadId}`;
                },
                merge: (currentCache, newItems) => {
                    const { pagination: currPage } = currentCache || {};
                    const { pagination: nextPage } = newItems || {};

                    if (!currPage || nextPage.page <= currPage.page) {
                        return newItems;
                    }

                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                },
                forceRefetch: ({ currentArg, previousArg }) => {
                    const { page: currPage, masterAccountId: currMasterAccountId } = currentArg || { page: 1, masterAccountId: null };
                    const { page: prevPage, masterAccountId: prevMasterAccountId } = previousArg || { page: 0, masterAccountId: null };
                    return currPage !== prevPage || currMasterAccountId !== prevMasterAccountId;
                },
                providesTags: ['ClientMessages']
            }),
            patchThread: builder.mutation({
                query: ({ threadId, operations }) => ({
                    url: `api/messaging/threads/${threadId}`,
                    method: 'PATCH',
                    body: operations
                }),
                async onQueryStarted({ threadId, masterAccountId, active, clientMessagesOnly, operations, ownMessages, userId }, { dispatch, queryFulfilled }) {

                    let results = [];

                    const patchDispatch = (queryName, props) => dispatch(wealthProApi.util.updateQueryData(
                        queryName,
                        props,
                        ({ results }) => {
                            var item = results.find(x => x.threadId === threadId);
                            applyPatch(item, operations, true);
                        })
                    );

                    masterAccountId && results.push(patchDispatch('fetchAccountConversationThreads', { masterAccountId, active, clientMessagesOnly, ownMessages }));

                    masterAccountId && results.push(dispatch(wealthProApi.util.updateQueryData('fetchClientMessages', { masterAccountId }, ({ results }) => {
                        let items = results.filter(x => x.threadId === threadId);
                        items.forEach((item) => {
                            applyPatch(item, operations, true);
                        })
                    })))

                    results.push(dispatch(wealthProApi.util.updateQueryData('getThreadById', { threadId }, data => { applyPatch(data, operations, true) })));

                    userId && results.push(dispatch(wealthProApi.util.updateQueryData('searchUserMessages', { userId }, ({ results }) => {
                        let items = results.filter(x => x.threadId === threadId);
                        items.forEach((item) => {
                            applyPatch(item, operations, true);
                        })
                    })));

                    userId && results.push(dispatch(wealthProApi.util.updateQueryData('fetchThreadMessagesForUser', { userId, threadId }, ({ results }) => {
                        let items = results.filter(x => x.threadId === threadId);
                        items.forEach((item) => {
                            applyPatch(item, operations, true);
                        })
                    })))


                    try {
                        await queryFulfilled;
                    }
                    catch {
                        console.log(results);
                        results.forEach(x => x.undo())
                    }
                }
            })
        })
    });

export const {
    useGetThreadByIdQuery,
    useLazyGetThreadByIdQuery,
    useFetchMessagesByThreadIdQuery,
    useLazyFetchMessagesByThreadIdQuery,
    usePatchThreadMutation
} = messagesThreadsApiEndpoints;
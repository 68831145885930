import IntroducerTableRow from "./IntroducerTableRow";
import { useIntroducers } from "../hooks/IntroducersContext";
import { VirtualTable } from "../../../components/tables";
import { StandardTableSortablePropertyHeader } from "../../../components/tables/StandardTable";
import { TableRecordCountDisplayFooter } from "../../../components/tables/VirtualTable";

const IntroducersGrid = () => {
    const {
        clear: refreshTable,
        data,
        error,
        isNextPageLoading,
        isNewRecords,
        isRowLoaded,
        loadMore,
        newRecordsSinceCache,
        sort,
        sortTable,
        totalCount
    } = useIntroducers();
    const isNewRecordsDisplayed = isNewRecords && !isNextPageLoading;

    return (
        <VirtualTable
            enableScrollTracking={false}
            loadMoreItems={loadMore}
            row={IntroducerTableRow}
            itemSize={42}
            itemCount={totalCount}
            itemData={data}
            isItemLoaded={isRowLoaded}
            error={error}
            header={
                <thead>
                    <tr>
                        <StandardTableSortablePropertyHeader
                            property="contact"
                            sort={sort}
                            sortTable={sortTable}
                        >
                            Contact
                        </StandardTableSortablePropertyHeader>
                        <StandardTableSortablePropertyHeader
                            property="company"
                            sort={sort}
                            sortTable={sortTable}
                        >
                            Company
                        </StandardTableSortablePropertyHeader>
                        <StandardTableSortablePropertyHeader
                            property="type"
                            sort={sort}
                            sortTable={sortTable}
                        >
                            Type
                        </StandardTableSortablePropertyHeader>
                        <StandardTableSortablePropertyHeader
                            property="profileGroupName"
                            sort={sort}
                            sortTable={sortTable}
                        >
                            Division
                        </StandardTableSortablePropertyHeader>
                        <StandardTableSortablePropertyHeader
                            property="adviser"
                            sort={sort}
                            sortTable={sortTable}
                        >
                            Adviser
                        </StandardTableSortablePropertyHeader>
                        <StandardTableSortablePropertyHeader
                            property="status"
                            sort={sort}
                            sortTable={sortTable}
                        >
                            Status
                        </StandardTableSortablePropertyHeader>
                        <StandardTableSortablePropertyHeader
                            property="statusDate"
                            sort={sort}
                            sortTable={sortTable}
                        >
                            Date
                        </StandardTableSortablePropertyHeader>
                        <th className="sticky-header">&nbsp;</th>
                    </tr>
                </thead>
            }
            footer={
                <TableRecordCountDisplayFooter 
                    totalCount={totalCount}
                    isNewRecordsDisplayed={isNewRecordsDisplayed}
                    newRecordsSinceCache={newRecordsSinceCache}
                    reload={refreshTable}
                />
            }
        />
    );
};

export default IntroducersGrid;

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { wealthProApi } from "../../../redux/api";
import { useFetchIncomeDetailsSectionQuery, usePatchClientIncomeDetailsMutation, usePatchIncomeDetailsSectionMutation } from "../../../services/clientfactfind";
import useNotes from "../hooks/useNotes";
import { applyPatch } from "fast-json-patch";

const IncomeDetailsContext = React.createContext();

const employmentProperties = {
    grossSalary: {
        label: "Gross Salary:"
    },
    salarySacrifice: {
        label: "Salary Sacrifice:"
    },
    grossBonus: {
        label: "Gross Bonus:"
    },
    carAllowance: {
        label: "Car Allowance:"
    },
    otherTaxableBenefits: {
        label: "Other Taxable Benefits:"
    },
    totalTaxableBenefits: {
        label: "Total Taxable Benefits:",
        readOnly: true
    }
};

const otherProperties = {
    investmentIncome: {
        label: "Investment Income:",
        showJoint: true
    },
    interest: {
        label: "Interest:",
        showJoint: true
    },
    dividends: {
        label: "Dividends:",
        showJoint: true
    },
    rentalIncome: {
        label: "Rental Income:",
        showJoint: true
    },
    statePensionBenefits: {
        label: "State Pension/Benefits:",
        showJoint: false
    },
    otherPensionsGross: {
        label: "Other Pensions (Gross):"
    },
    totalOtherIncome: {
        label: "Total Other Income:",
        showJoint: true,
        readOnly: true
    },
    totalGrossIncome: {
        label: "Total Gross Income",
        showJoint: true,
        readOnly: true
    }
};

const netMonthlyProperties = {
    netSalary: {
        label: "Net Salary:"
    },
    netPensions: {
        label: "Net Pensions:"
    },
    netStatePensions: {
        label: "Net State Pension:"
    },
    netInvestmentIncome: {
        label: "Net Investment Income:",
        showJoint: true
    },
    netRentalIncome: {
        label: "Net Rental Income:",
        showJoint: true
    },
    netOther: {
        label: "Net Other (estimated):",
        showJoint: true
    },
    totalMonthlyIncome: {
        label: "Total Net Income:",
        showJoint: true,
        readOnly: true
    }
};

const taxResidencyOptions = ["Scottish", "Other UK", "No"].map((label, value) => ({ label, value }));

export const useIncomeDetails = () => {
    return useContext(IncomeDetailsContext);
}

export const IncomeDetailsProvider = ({ children }) => {
    const { id: masterAccountId } = useClientContext();

    const { data: section, isLoading } = useFetchIncomeDetailsSectionQuery({ masterAccountId });
    const [patchSection] = usePatchIncomeDetailsSectionMutation();
    const [patchClient] = usePatchClientIncomeDetailsMutation();
    const dispatch = useDispatch();

    const patchCache = useCallback((property, newValue) => {
        dispatch(wealthProApi.util.updateQueryData("fetchIncomeDetailsSection", { masterAccountId }, (draft) => ({
            ...draft,
            [property]: newValue
        })));
    }, [dispatch, masterAccountId]);

    const patchService = useCallback((property, newValue) =>
        patchSection({
            incomeDetailsId: section?.incomeDetailsId,
            operations: [patchReplace(property, newValue)]
        }).unwrap(), [patchSection, section?.incomeDetailsId]);

    const patchClientCache = useCallback((clientIncomeId, property, newValue) => {
        dispatch(wealthProApi.util.updateQueryData("fetchClientIncomeDetails", { clientIncomeId }, (draft) => ({
            ...draft,
            [property]: newValue
        })));
        dispatch(wealthProApi.util.updateQueryData("fetchIncomeDetailsSection", { masterAccountId }, (draft) => {
            applyPatch(draft?.clients?.find(client => client.clientAccountId === clientIncomeId), [patchReplace(property, newValue)]);
        }))
    }, [dispatch, masterAccountId]);

    const patchClientService = useCallback((clientIncomeId, property, newValue) =>
        patchClient({
            clientIncomeId,
            operations: [patchReplace(property, newValue)],
            masterAccountId
        }).unwrap(), [masterAccountId, patchClient]);

    const { updateNotes } = useNotes();

    const patchNotesService = useCallback((newText) =>
        updateNotes(section?.additionalNotesId, newText), [section?.additionalNotesId, updateNotes]);

    const [netMonthlyIncomes, setNetMonthlyIncomes] = useState({});
    const netMonthlyIncomeValue = useMemo(() => Object.values(netMonthlyIncomes)?.reduce((acc, value) => acc + value, 0), [netMonthlyIncomes]);

    const updateNetMonthlyIncome = useCallback((clientId, newValue) => {
        setNetMonthlyIncomes(oldObj => ({
            ...oldObj,
            [clientId]: newValue
        }));
    }, []);

    useEffect(() => {
        setNetMonthlyIncomes({
            'joint': section?.totalMonthlyIncome || 0,
            ...section?.clients?.reduce((acc, client) => ({
                [client.clientAccountId]: client?.totalMonthlyIncome || 0,
                ...acc
            }), {})
        });
    }, [section?.clients, section?.totalMonthlyIncome])

    // Filter out the joint client if there is only one client
    const filterJoint = useMemo(() => section?.clients?.length === 1, [section?.clients?.length])

    // Context returns a tuple in the form [ functions, objects ] 
    return <IncomeDetailsContext.Provider value={[{
        patchCache,
        patchService,
        patchClientCache,
        patchClientService,
        patchNotes: patchNotesService,
        updateNetMonthlyIncome
    }, {
        section,
        filterJoint,
        netMonthlyIncomeValue,
        isLoading,
        employmentProperties,
        otherProperties,
        netMonthlyProperties,
        taxResidencyOptions
    }]}>
        {children}
    </IncomeDetailsContext.Provider>
}
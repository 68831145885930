import React from "react";
import styled from "styled-components";
import withProviders from "../../hooks/withProviders";
import CreateIntroducerButton from "./components/CreateIntroducerButton";
import CreateIntroducerModal from "./components/CreateIntroducerModal";
import EditIntroducerModal from "./components/EditIntroducerModal";
import IntroducersGrid from "./components/IntroducersGrid";
import { useIntroducers, IntroducersProvider } from "./hooks/IntroducersContext";
import { useFilterContext } from "../../hooks/FilterContext";
import { IntroducerManagementPageSubHeader, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import { IntroducerDashboardFiltersModal, IntroducerDashboardFiltersModalButton } from "../introducerdashboardfilters";

const IntroducerManagementFiltersAppliedWarningMessage = styled(
    ThemedParagraph
)`
    font-size: 12px !important;
`;

const IntroducerDashboard = () => {
    const [isFiltersShown, setIsFiltersShown] = React.useState(false);
    const [isCreateShown, setIsCreateShown] = React.useState(false);
    const { clear, editIntroducer, selected } = useIntroducers();
    const { clearFilter, filter: {}, isFiltersApplied } = useFilterContext();

    return (
        <React.Fragment>
            <IntroducerDashboardFiltersModal
                handleClose={() => setIsFiltersShown(false)}
                show={isFiltersShown}
            />
            <CreateIntroducerModal 
                show={isCreateShown}
                handleClose={() => setIsCreateShown(false)}
                onCreatedEvent={() => clear()}
            />
            <EditIntroducerModal 
                show={selected !== null}
                introducer={selected}
                handleClose={() => editIntroducer(null)}
                onUpdatedEvent={() => clear()}
            />
            <IntroducerManagementPageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon
                                    icon="fa-triangle-exclamation"
                                    size="2x"
                                    variant="warning"
                                />
                                <IntroducerManagementFiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being
                                    applied to your results.
                                    <br />
                                    <a href="#" onClick={clearFilter}>
                                        Click here
                                    </a>{" "}
                                    to clear your filters.
                                </IntroducerManagementFiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex flex-fill justify-content-end align-items-center">
                        <CreateIntroducerButton onClick={() => setIsCreateShown(true)} />
                        <IntroducerDashboardFiltersModalButton onClick={() => setIsFiltersShown(true)} />
                    </div>
                </div>
            </IntroducerManagementPageSubHeader>
            <section className="px-4 my-3 h-100">
                <IntroducersGrid />
            </section>
        </React.Fragment>
    );
};

export default withProviders(IntroducersProvider)(IntroducerDashboard);
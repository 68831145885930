import React from "react";
import {
    DateInput,
    FormCheck,
    FormInput,
    FormLabel,
} from "./../../../components/forms/";
import { IconButton } from "../../../components/buttons";
import useReminders from "../hooks/useReminders";
import Skeleton from "react-loading-skeleton";

const ApplicationReminders = () => {

    const {
        reminders,
        remindersError,
        remindersIsError,
        remindersIsFetching,
        remindersIsLoading,
        updateReminder,
        updateError,
        isErrorUpdating,
        isUpdating,
        createReminder,
        isCreating,
        createError,
        isErrorCreating,
        deleteReminder,
        isRemoving,
        removeError,
        isErrorRemoving
    } = useReminders();

    const onCreatePressed = (e) => createReminder(e);
    const onDeletePressed = (reminderId) => deleteReminder(reminderId);
    const onFieldChanged = (reminderId, property, value) => updateReminder(reminderId, property, value);

    return (<div className="row mb-2">
                <div className="col">
                    {remindersIsLoading === true && (
                        <div className="row mb-4 mt-4">
                            <div className="col">
                                <Skeleton count={2} />
                            </div>
                        </div>
                    )}
                    {(isCreating === false && remindersIsLoading === false && reminders && reminders.length === 0) && (
                        <div className="row mb-4 mt-4">
                            <div className="col">
                                There are no reminders for this application. You can click{" "}
                                <a href="#" onClick={onCreatePressed}>
                                    here
                                </a>{" "}
                                to create one.
                            </div>
                        </div>
                    )}
                    {reminders.length > 0 && (
                        <div className="row mb-2 mt-4">
                            <div className="col-6"><FormLabel>Description</FormLabel></div>
                            <div className="col-2"><FormLabel>Reminder Date</FormLabel></div>
                            <div className="col-2"><FormLabel>Completed</FormLabel></div>
                            <div className="col"><FormLabel>Completed By</FormLabel></div>
                            <div
                                className="col-md-auto"
                                style={{ minWidth: "46px" }}
                            >
                                <IconButton
                                    icon="fa-plus"
                                    variant="success"
                                    onClick={onCreatePressed}
                                />
                            </div>
                        </div>
                    )}
                    {(isCreating === true) &&  <div className="row mb-4 mt-4">
                        <div className="col">
                            <Skeleton count={2} />
                        </div>
                    </div>}
                    {reminders.length > 0 &&
                        reminders.map((el, i) => (
                            <div
                                className="row mb-2"
                                key={el.clientFeeBreakdownId}
                            >
                                <div className="col-6">
                                    <FormInput
                                        value={el.message}
                                        placeholder="Description"
                                        onBlur={(value) =>
                                            onFieldChanged(
                                                el.reminderId,
                                                "message",
                                                value
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-2">
                                    <DateInput
                                        value={el.reminderDate}
                                        isClearable
                                        onBlur={(value) => onFieldChanged(el.reminderId, 'reminderDate', value)}
                                    />
                                </div>
                                <div className="col-2">
                                    <FormCheck
                                        isChecked={el.completedDate != null}
                                        onChange={(value) => onFieldChanged(el.reminderId, 'completedDate', value ? new Date() : null)}
                                    />
                                    {/* <DateInput
                                        value={el.completedDate}
                                        onBlur={(value) => onFieldChanged(el.reminderId, 'completedDate', value)}
                                    /> */}
                                </div>
                                <div className="col">
                                    <FormInput
                                        value={el.completedBy}
                                        placeholder="N/A"
                                        disabled
                                    />
                                </div>
                                <div className="col-md-auto">
                                    <IconButton
                                        icon="fa-trash-can"
                                        variant="danger"
                                        onClick={() =>
                                            onDeletePressed(
                                                el.reminderId
                                            )
                                        }
                                    />
                                </div>
                            </div>
                    ))}
                </div>
            </div>
    );
};

export default ApplicationReminders;

import { useFetchHOAdminProductsQuery } from "../../../services/headofficeadmin";

const useProductDetails = (listType) => {

    const {
        data: productData, 
        productsError, 
        productsIsError,
        productsIsFetching, 
        productsIsLoading } = useFetchHOAdminProductsQuery(listType);

    return {
        productData,
        productsError,
        productsIsError,
        productsIsFetching,
        productsIsLoading
    };
};

export default useProductDetails;
import styled from "styled-components";

const ReportManagementDetailTableRowColumn = styled.td`
    width: ${({ width }) => width}%;
    max-width: 0;
    padding: 0.6rem !important;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export default ReportManagementDetailTableRowColumn;
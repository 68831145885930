
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const hoAdminInstructionCashApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["adminInstructionCash", "applicationInfo"]
}).injectEndpoints({
    endpoints: (builder) => ({
        getAdminInstructionCashById: builder.query({
            query: ({ instructionId }) => ({
                url: `api/headofficeadmin/admininstruction/${instructionId}`
            })
        }),
        patchAdminInstructionCash: builder.mutation({
            query: ({ instructionId, operations }) => ({
                url: `api/headofficeadmin/admininstruction/${instructionId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ instructionId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: viewModel } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('getAdminInstructionCashById', { instructionId }, data => {
                        Object.assign(data, viewModel);
                    }));
                }
                catch { }
            }
        }),
        recalculateTransactions: builder.mutation({
            query: ({ instructionId }) => ({
                url: `api/headofficeadmin/admininstruction/${instructionId}/recalculatetransactions`,
                method: 'POST'
            }),
            async onQueryStarted({ instructionId }, { dispatch, queryFulfilled }) {
                try {
                    var { data } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('getAdminInstructionCashById', { instructionId }, _ => {
                        return data;
                    }));
                }
                catch { }
            },
            invalidatesTags: ["applicationInfo"]
        }),
        recalculateCashRaised: builder.mutation({
            query: ({ instructionId }) => ({
                url: `api/headofficeadmin/admininstruction/${instructionId}/recalculatecash`,
                method: 'POST'
            }),
            async onQueryStarted({ instructionId }, { dispatch, queryFulfilled }) {
                try {
                    var { data } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('getAdminInstructionCashById', { instructionId }, _ => {
                        return data;
                    }));
                }
                catch { }
            },
        })
    })
});

export const {
    useGetAdminInstructionCashByIdQuery,
    usePatchAdminInstructionCashMutation,
    useRecalculateTransactionsMutation,
    useRecalculateCashRaisedMutation
} = hoAdminInstructionCashApiEndpoints;
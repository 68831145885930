import React, { useEffect } from "react";
import SummaryTable from "./SummaryTable";
import useReportsGroupedByAuthor from "../hooks/useReportsGroupedByAuthor";
import { useFilterContext } from "../../../hooks/FilterContext";

const ReportCountAuthorGroup = () => {
    const { filter } = useFilterContext();
    const { search, ...rest } = useReportsGroupedByAuthor(filter);
    const { data, error, isLoading, isFetching } = rest;

    useEffect(() => {
        const delayedRequest = setTimeout(() => search(filter), 500);
        return () => clearTimeout(delayedRequest);
    }, [ filter ]);

    return <SummaryTable label="Author" data={data} error={error} isLoading={isLoading === true || isFetching === true} />
};

export default ReportCountAuthorGroup;
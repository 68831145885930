import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const salutationPreferencesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountSalutationPreferences"]
}).injectEndpoints({
    endpoints: (builder) => ({
        createSalutationPreference: builder.mutation({
            query: ({ masterAccountId, data }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/salutations`,
                method: 'POST',
                body: {
                    masterAccountId,
                    ...data
                }
            }),
            async onQueryStarted({ masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: createData } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchSalutationPreferences', { masterAccountId }, (data) => {
                        data.push(createData);
                    }));
                }
                catch { }
            }
        }),
        deleteSalutationPreference: builder.mutation({
            query: ({ salutationId, masterAccountId, newDefaultSalutation = null }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/salutations/${salutationId}`,
                method: 'DELETE',
                body: { salutationId, newDefaultSalutation }
            }),
            invalidatesTags: ['masterAccountSalutationPreferences']
        }),
        fetchSalutationPreferences: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/salutations`,
            providesTags: (result, error, arg) => result
                ? [...result.map(({ salutationId }) => ({ type: 'masterAccountSalutationPreferences', id: salutationId })), 'masterAccountSalutationPreferences']
                : ['masterAccountSalutationPreferences']
        }),
        patchSalutationPreferences: builder.mutation({
            query: ({ masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/salutations`,
                method: 'PATCH',
                body: { operations }
            }),
            async onQueryStarted({ masterAccountId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const result = dispatch(wealthProApi.util.updateQueryData('fetchSalutationPreferences', { masterAccountId }, (data) => {
                        operations.forEach(op => {
                            var salutation = data.find(el => el.salutationId === op.salutationId);
                            applyPatch(salutation, op.patchDocument, true);
                        });
                    }));

                    try {
                        await queryFulfilled;
                    }
                    catch {
                        console.error("Error patching salutation preferences");
                        result.undo();
                    }
                }
                catch {
                    console.error("Error updating salutation preferences cache");
                }
            }
        })
    })
});

export const {
    useCreateSalutationPreferenceMutation,
    useDeleteSalutationPreferenceMutation,
    useFetchSalutationPreferencesQuery,
    useLazyFetchSalutationPreferencesQuery,
    usePatchSalutationPreferencesMutation
} = salutationPreferencesApiEndpoints;
import React from "react";
import DOMPurify from 'dompurify';
import { ThemedSpan } from "../../../components/utilities";
import styled from "styled-components";
import { niceBytes } from "../../../helpers/fileHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const AttachmentContainer = styled.div`
    height: 100px;
    width: 125px;
    display: flex;
    flex-direction: column;
`;

const Attachment = ({ attachment }) => {
    const {
        name,
        size,
        contentType,
        contentBytes,
        id
    } = attachment || {};

    var iconProps = AttachmentIconMap[contentType];

    const downloadFile = () => {
        var bytes = Uint8Array.from(window.atob(contentBytes), c => c.charCodeAt(0));
        const blob = new Blob([bytes], {type: contentType});
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = name;

        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        document.body.removeChild(link);
    };

    return <AttachmentContainer onClick={downloadFile} className="border p-2 rounded me-2 has-pointer">
        <FontAwesomeIcon className="mt-auto" size={"3x"} {...iconProps} />
        <ThemedSpan className="mt-auto text-truncate">{name} {niceBytes(size)}</ThemedSpan>
    </AttachmentContainer>
};

const SentEmail = ({ body, attachments }) => {

    const filteredAttachments = attachments?.filter(file => !file.isInline);

    return <React.Fragment>
        <div
            id="email-body"
            className="text-break"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body?.content) }}
        />
        {filteredAttachments && filteredAttachments.length > 0 && <div className="border-top py-2">
            <ThemedSpan>{filteredAttachments.length} attached files.</ThemedSpan>
            <div className="d-flex mt-2">
                {filteredAttachments.map(file => <Attachment key={file.id} attachment={file} />)}
            </div>
        </div>
        }
    </React.Fragment>

};

export default SentEmail;

const AttachmentIconMap = {
    "application/msword": {
        icon: "file-word",
        color: "#2b579a"
    },
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
        icon: "file-word",
        color: "#2b579a"
    },
    "text/csv": {
        icon: "file-csv",
        color: "#008000"
    },
    "image/gif": {
        icon: "file-image",
        color: "#000000"
    },
    "image/jpeg": {
        icon: "file-image",
        color: "#000000"
    },
    "image/png": {
        icon: "file-image",
        color: "#000000"
    },
    "application/pdf": {
        icon: "file-pdf",
        color: "#f40f02"
    },
    "application/vnd.ms-excel": {
        icon: "file-excel",
        color: "#008000"
    },
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
        icon: "file-excel",
        color: "#008000"
    },
    "application/xml": {
        icon: "file-code",
        color: "#008000"
    },
    "text/plain": {
        icon: "file-lines",
        color: "#000000"
    },
    "video/mp4": {
        icon: "file-video",
        color: "#000000"
    },
    "video/mpeg": {
        icon: "file-video",
        color: "#000000"
    },
    "video/x-msvideo": {
        icon: "file-video",
        color: "#000000"
    },
    "application/vnd.ms-outlook": {
        icon: "file-lines",
        color: "#0072c6"
    },
    "message/rfc822": {
        icon: "file-lines",
        color: "#000000"
    }
}
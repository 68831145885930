import { faStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Moment from "react-moment";
import styled from "styled-components";
import { ProfileDisplay } from "../../../components";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import { ThemedSpan } from "../../../components/utilities";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useAddClientToFavouritesMutation, useRemoveClientFromFavouritesMutation } from "../../../services/clientdetails";
import { useLazyFetchMasterAccountsQuery, useLazySearchMasterAccountsQuery } from "../../../services/clientsearch";

// Helper functions to format cells
const formatCurrency = (value) => value?.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 })
const formatUser = (value) => <ProfileDisplay userId={value} />;

const ClientManagementNavigationCell = ({ value, row, destination = null, getDestination }) => {
    return <a href={destination ?? getDestination(row)}>{value}</a>
}

const ClientManagementSplitCell = ({ value, row, cellFormatter, dateProperty, nullText = "N/A", destination = null, getDestination }) => {
    const date = useMemo(() => row[dateProperty]
        ? <Moment format="Do MMM 'YY">{row[dateProperty]}</Moment>
        : null, [row, dateProperty]);

    return <p className="m-0 lh-1 text-truncate">
        <a href={destination ?? getDestination(row)}>
            {value == null
                ? nullText
                : cellFormatter && typeof cellFormatter === 'function'
                    ? cellFormatter(value)
                    : value}
        </a>
        <br />
        <ThemedSpan variant="muted" className="fs-smallest">
            {date}
        </ThemedSpan>
    </p>;
}

const FavouriteIconWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: min-content;
`

const ClientManagementFavouriteCell = ({ value, row, index }) => {
    const [addToFavourites] = useAddClientToFavouritesMutation();
    const [removeFromFavourites] = useRemoveClientFromFavouritesMutation();

    const handleOnFavouriteChanged = () => {
        if (value) {
            toast.promise(removeFromFavourites({ masterAccountId: row.masterAccountId }), {
                loading: `Processing...`,
                success: () => `The client has been removed from your favourites.`,
                error: (err) => {
                    const { message } = err || { message: 'There was an unknown error.' };
                    return message;
                }
            });
        }
        else {
            toast.promise(addToFavourites({ masterAccountId: row.masterAccountId }), {
                loading: `Processing...`,
                success: () => `The client has been added to your favourites.`,
                error: (err) => {
                    const { message } = err || { message: 'There was an unknown error.' };
                    return message;
                }
            });
        }
    }

    return <Tippy placement="left" content={(
        <span>{value ? 'Remove from favourites' : 'Add to favourites'}</span>
    )}>
        <FavouriteIconWrapper>
            <FontAwesomeIcon
                onClick={handleOnFavouriteChanged}
                icon={value ? 'fa-star' : faStar}
                className={classNames('has-pointer', {
                    'text-gold': value
                })}
            />
        </FavouriteIconWrapper>
    </Tippy>
}

const limit = 50;
const defaultResult = { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };

const useClientManagement = ({ searchTerm }) => {
    const { filter } = useFilterContext();

    // Search/fetch handling inc. sorting, and pagination. Fetch is sortable, search sorts by score in backend
    const [searchCacheKey, setSearchCacheKey] = useState(new Date().valueOf());
    const [fetchCacheKey, setFetchCacheKey] = useState(new Date().valueOf());

    const [fetchClients, { currentData: fetchedClients, isFetching, isError: fetchIsError, error: fetchError }] = useLazyFetchMasterAccountsQuery();
    const [searchClients, { currentData: searchedClients, isFetching: isSearching, isError: searchIsError, error: searchError }, { lastArg }] = useLazySearchMasterAccountsQuery();

    // Need a custom bit of logic to handle loading when switching between search and fetch
    // We want to hide the old results when typing in the search bar
    const [lastQueryIsFetch, setLastQueryIsFetch] = useState(null);
    const isLoadingFetch = useMemo(() => !lastQueryIsFetch && isFetching, [isFetching, lastQueryIsFetch]);
    const isLoadingSearch = useMemo(() => (lastQueryIsFetch || lastArg.searchTerm !== searchTerm) && isSearching, [isSearching, lastArg.searchTerm, lastQueryIsFetch, searchTerm])

    const isError = fetchIsError || searchIsError;
    const error = fetchIsError
        ? fetchError
        : searchIsError
            ? searchError
            : null;

    // A bit of state to handle hiding the old results when typing in the search bar
    const [isTyping, setIsTyping] = useState(false);

    const { pagination, results } = (searchTerm.length >= 3
        ? searchedClients
        : fetchedClients)
        || defaultResult;
    const { totalCount, page } = pagination;
    const [sort, setSort] = useState(null);
    const [currentSortProperty, currentSortDirection] = useMemo(() => sort?.split("+") ?? [], [sort]);
    const [isClearing, setIsClearing] = useState(false);

    // If the user types while a fetch/search is in progress it will be skipped by the clear function
    // This state is used to keep track of whether a search has been skipped and to trigger a new search when the fetch/search is complete
    const [skippedSearches, setSkippedSearches] = useState(false);

    const clear = useCallback(() => new Promise((res, rej) => {
        if (isFetching || isSearching)
            setSkippedSearches(true);

        setIsClearing(true)

        const newCacheKey = new Date().valueOf();

        const fetchFn = searchTerm === "" ? fetchClients : searchClients;
        const cacheKeySetter = searchTerm === "" ? setFetchCacheKey : setSearchCacheKey;

        fetchFn({ page: 1, limit, sort, searchTerm, cacheKey: newCacheKey, ...filter })
            .unwrap()
            .then(() => cacheKeySetter(newCacheKey))
            .then(() => setLastQueryIsFetch(searchTerm === ""))
            .then(res, rej)
            .finally(() => setIsClearing(false));
    }), [fetchClients, filter, isFetching, isSearching, searchClients, searchTerm, sort]);

    useEffect(() => {
        if (isFetching || isSearching) return;

        if (skippedSearches) {
            setSkippedSearches(false);
            clear();
        }
    }, [clear, isFetching, isSearching, skippedSearches])

    const isRowLoaded = (index) => index < results.length;

    const loadMore = () => {
        if (isFetching || isSearching) return;

        if (searchTerm !== "") {
            searchClients({ cacheKey: searchCacheKey, searchTerm, page: page + 1, limit, ...filter });
        } else {
            fetchClients({ cacheKey: fetchCacheKey, sort, page: page + 1, limit, ...filter });
        }
    };

    const onClickSort = useCallback((property) => {
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === currentSortProperty) {
            newDirection = currentSortDirection === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    }, [currentSortDirection, currentSortProperty]);

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        clear();
    };

    // Search term useEffect
    // Search should trigger on a timeout to prevent too many requests
    useEffect(() => {
        if ((searchTerm.length > 0 && searchTerm.length < 3) || (lastQueryIsFetch && searchTerm.length === 0))
            return;

        setIsTyping(true);

        const searchTimeout = setTimeout(() => {
            clear().then(() =>
                setIsTyping(false));
        }, 250);

        return () => clearTimeout(searchTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    // Filter/sort useEffect
    // When the filter/sort term changes, clear the results and fetch new ones (as long as the search term is not 1-2 characters)
    useEffect(() => {
        if (searchTerm.length > 0 && searchTerm.length < 3)
            return;

        clear();

        // Disabled eslint warning because we only want to run this effect when the filter/sort term changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, filter]);

    const sortableHeaderRenderer = useCallback(({ headerClassName, label, property, key, sortProperty = property }) => {
        return <StandardTableSortableHeader
            className={headerClassName}
            key={key}
            onClick={() => onClickSort(sortProperty)}
            active={sortProperty === currentSortProperty}
            direction={currentSortDirection}
            isSticky>
            {label}
        </StandardTableSortableHeader>;
    }, [currentSortDirection, onClickSort, currentSortProperty]);

    const columns = [
        {
            label: "Ref",
            property: "accountReference",
            width: 5
        },
        {
            label: "Account Name",
            property: "accountName",
            headerRenderer: sortableHeaderRenderer,
            CellComponent: ClientManagementNavigationCell,
            getDestination: ({ masterAccountId }) => `/client/${masterAccountId}`,
            width: 15
        },
        {
            label: "Account Type",
            property: "accountTypeDescription",
            sortProperty: "accountType",
            headerRenderer: sortableHeaderRenderer,
            width: 10
        },
        {
            label: "Status",
            property: "accountStatusDescription",
            sortProperty: "accountStatus",
            headerRenderer: sortableHeaderRenderer,
            width: 10
        },
        {
            label: "Adviser",
            property: "adviserId",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatUser,
            width: 10
        },
        {
            label: "Review Adviser",
            property: "reviewAdviserId",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatUser,
            width: 10
        },
        {
            label: "Address",
            property: "address",
            width: 15
        },
        {
            label: "Portfolio Value",
            property: "portfolioValue",
            headerRenderer: sortableHeaderRenderer,
            CellComponent: ClientManagementSplitCell,
            dateProperty: "portfolioValueDate",
            getDestination: ({ masterAccountId }) => `/client/${masterAccountId}/portfolio`,
            allowZero: false,
            nullText: "Never Requested",
            cellFormatter: formatCurrency,
            className: "text-end",
            headerClassName: "text-center",
            width: 7.5
        },
        {
            label: "Favourite",
            property: "isFavourited",
            headerRenderer: sortableHeaderRenderer,
            CellComponent: ClientManagementFavouriteCell,
            className: "text-center",
            headerClassName: "text-center",
            width: 5
        }
    ];

    return {
        results,
        sort,
        renderCount: totalCount === results.length ? totalCount : results.length + 3,
        totalCount,
        columns,
        isLoading: isLoadingFetch || isLoadingSearch || isTyping || isClearing,
        isClearing,
        isError,
        error,
        clear,
        isRowLoaded,
        loadMore,
        sortableHeaderRenderer,
        reload
    };
}

export default useClientManagement;
import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { ActionIcon, ActionIconNavLink, ThemedSpan } from "../../../components/utilities"
import { useClientSearch } from "../contexts/ClientSearchContext";

export const SearchMenu = styled.div`
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    padding: 1rem !important;
`;

export const SearchMenuHeader = styled.div`
    border-bottom: 1px solid ${props => darken(0.15, props.theme.light)};
`;

const SearchDropdownMenu = React.forwardRef(({ children, onToggle, ...rest }, ref) => {
    const { reload } = useClientSearch();

    const handleOnNavLinkActionClicked = () => {
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }
    };

    return <SearchMenu ref={ref} {...rest}>
        <SearchMenuHeader className="row pb-2 mb-2">
            <div className="col">
                <ThemedSpan variant="primary">Client Search</ThemedSpan>
            </div>
            <div className="col-md-auto">
                <ActionIcon className="me-2" variant="muted" icon="fa-arrows-rotate" onClick={reload} />
                <ActionIconNavLink to="/client/search" icon="fa-expand" onClick={handleOnNavLinkActionClicked} />
            </div>
        </SearchMenuHeader>
        {children}
    </SearchMenu>
});

export default SearchDropdownMenu;
import { wealthProApi } from '../../redux/api';

const hoProvidersApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchHeadOfficeUnmatchedPlatformAccounts: builder.query({
            query: () => `api/headofficeadmin/platformAccounts/unmatched`
        }),
    })
});

export const {
    useFetchHeadOfficeUnmatchedPlatformAccountsQuery
} = hoProvidersApiEndpoints;
import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const investEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchInvest: builder.mutation({
            query: ({ investId, operations }) => ({
                url: `api/recommendations/Invest/${investId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ instructionId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchInvest", { instructionId }, (invest) => {
                        applyPatch(invest, operations)
                    }));

                    try {
                        await queryFulfilled
                    } catch (ex) {
                        console.error(ex, "Error updating service");
                        patchResult.undo();
                    }
                } catch (ex) {
                    console.error(ex, "Error updating cache");
                }
            }
        }),
        selectPortfolio: builder.mutation({
            query: ({ investId, portfolioId }) => ({
                url: `api/recommendations/Invest/${investId}/Portfolio/${portfolioId}`,
                method: "PUT"
            }),
            async onQueryStarted({ instructionId, portfolioId }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchInvest", { instructionId }, (invest) => {
                        invest.portfolioId = portfolioId;
                    }));

                    try {
                        await queryFulfilled
                    } catch (ex) {
                        console.error(ex, "Error updating service");
                        patchResult.undo();
                    }
                } catch (ex) {
                    console.error(ex, "Error updating cache");
                }
            }
        }),
        fetchInvestTransactions: builder.query({
            query: ({ investId }) => `api/recommendations/Invest/${investId}/Transactions`
        }),
        deleteAllInvestTransactions: builder.mutation({
            query: ({ investId }) => ({
                url: `api/recommendations/Invest/${investId}/Transactions`,
                method: "DELETE",
            }),
            async onQueryStarted({ investId }, { dispatch, queryFulfilled }) {
                const removeAllResult = dispatch(wealthProApi.util.updateQueryData("fetchInvestTransactions", { investId }, (array) => {
                    array.splice(0);
                }));
                try {
                    await queryFulfilled
                }
                catch {
                    removeAllResult.undo();
                }
            }
        }),
        fetchInvestmentCashArray: builder.query({
            query: ({ investId }) => `api/recommendations/Invest/${investId}/InvestmentCash`
        })
    })
})

export const {
    usePatchInvestMutation,
    useSelectPortfolioMutation,
    useFetchInvestTransactionsQuery,
    useDeleteAllInvestTransactionsMutation,
    useLazyFetchInvestTransactionsQuery,
    useLazyFetchInvestmentCashArrayQuery,
    useFetchInvestmentCashArrayQuery
} = investEndpoints
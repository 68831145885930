import React, { useEffect, useMemo } from "react"
import Skeleton from "react-loading-skeleton"
import { GridWrapper } from "../../../components"
import { CurrencyInput, FormLabel, FormSelect } from "../../../components/forms"
import { useInstruction } from "../contexts/InstructionContext"
import useDivestSelection from "../hooks/useDivestSelection"
import DivestTransaction from "./DivestTransaction"
import { TransactionErrorMessage } from "./ErrorMessages"

const DivestTransactionTable = ({ isSelling = false }) => {

    const {
        gridTemplateColsArray,
        divestTransactions,
        divestTransactionsIsLoading,
        realTimeTransactions,
        transactionTypeSelectObjects,
        transactionTypes,
        transactionTypesIsReady,
        transactionTypesIsLoading,
        totalCurrentValue,
        isError,
        isLoading,
        patchDivest,
        createTransaction,
        patchTransaction,
        bulkPatchTransaction,
        realTimePatchTransaction,
        realTimeBulkPatchTransaction,
        deleteTransaction,
        retry
    } = useDivestSelection(isSelling);

    const [, { realTimeInstruction, divest }, { taxYears, excludeRingFence }] = useInstruction();

    // Set default tax year, if appropriate
    useEffect(() => {
        if (divest?.id && divest?.taxYear == null && isSelling)
            patchDivest("taxYear", taxYears[0].value)
    }, [divest?.id, divest?.taxYear, isSelling, patchDivest, taxYears]);

    const allowedTransactionTypeOptions = useMemo(() =>
        excludeRingFence
            ? transactionTypeSelectObjects?.filter(({ value }) =>
                value !== transactionTypes?.find(type => type.isRingFenced)?.id)
            : transactionTypeSelectObjects,
        [excludeRingFence, transactionTypeSelectObjects, transactionTypes]);

    if (isError)
        return <TransactionErrorMessage retry={retry} />

    return <GridWrapper className="mt-3" gridTemplateColumns={`minmax(auto, ${gridTemplateColsArray.join("%) minmax(auto, ")}%) auto`}>
        <FormLabel className="my-auto">SEDOL</FormLabel>
        <FormLabel className="my-auto">Investment Name</FormLabel>

        {isSelling
            ? <>
                <FormLabel className="my-auto">Current (£)</FormLabel>
                <FormLabel className="my-auto">Quantity</FormLabel>
            </>
            : <>
                <FormLabel className="my-auto">Value (£)</FormLabel>
                <FormLabel className="my-auto">Book Cost (£)</FormLabel>
                <FormLabel className="my-auto">Quantity</FormLabel>
            </>}

        <FormLabel className="my-auto">Instruction</FormLabel>

        {isSelling
            ? <>
                <FormLabel className="my-auto">Sale %</FormLabel>
                <FormLabel className="my-auto">Sale Value</FormLabel>
            </>
            : <>
                <FormLabel className="my-auto">Retain %</FormLabel>
            </>}

        <div></div>

        {divestTransactions?.map((transaction, index) =>
            <DivestTransaction
                key={`${transaction?.divestId}-${transaction?.rowTag ?? index}`}
                isLoading={isLoading || divestTransactionsIsLoading || transaction?.rowTag == null}
                transaction={transaction}
                isSelling={isSelling}
                createTransaction={createTransaction}
                patchTransaction={patchTransaction}
                bulkPatchTransaction={bulkPatchTransaction}
                realTimeTransactions={realTimeTransactions}
                realTimeTransaction={realTimeTransactions?.find(t => t?.rowTag === transaction?.rowTag)}
                realTimePatchTransaction={realTimePatchTransaction}
                realTimeBulkPatchTransaction={realTimeBulkPatchTransaction}
                deleteTransaction={() => deleteTransaction(transaction?.rowTag)}
                index={index}
                transactionTypeSelectObjects={allowedTransactionTypeOptions}
                transactionTypes={transactionTypes}
                transactionTypesIsReady={transactionTypesIsReady}
                transactionTypesIsLoading={transactionTypesIsLoading}
            />
        )}

        {divestTransactions?.length !== 0
            ? isSelling
                ? <>
                    <GridWrapper className="col-start-2" gridTemplateColumns="1fr auto">
                        <FormLabel className="col-start-1 align-self-end text-end">Tax Year for Sales:</FormLabel>
                        {isLoading
                            ? <Skeleton />
                            : <FormSelect
                                className="col-start-2 mb-0"
                                options={taxYears ?? []}
                                defaultValue={divest?.taxYear}
                                onBlur={(selection) => patchDivest("taxYear", parseInt(selection.value))}
                            />}
                    </GridWrapper>

                    <div className={"col-start-3 col-end-4"} >
                        <CurrencyInput
                            value={totalCurrentValue}
                            readOnly
                        />
                    </div>

                    <FormLabel className="col-start-5 col-end-7 justify-self-end align-self-end">Total Sale Proceeds:</FormLabel>
                    {isLoading
                        ? <Skeleton />
                        : <CurrencyInput
                            className="col-start-7"
                            readOnly
                            value={realTimeInstruction?.divest?.totalSaleProceeds ?? 0}
                        />}
                </>
                : <>
                    <FormLabel className="col-start-2 col-end-3 align-self-end text-end">Total Cash Proceeds:</FormLabel>
                    {isLoading
                        ? <Skeleton />
                        : <CurrencyInput
                            className="col-start-3"
                            readOnly
                            value={realTimeInstruction?.divest?.totalSaleProceeds ?? 0}
                        />}
                </>
            : <></>}

    </GridWrapper>;
}

export default DivestTransactionTable
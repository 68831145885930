import { wealthProApi } from '../../redux/api';

const hoAdminPlatformTransactionApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["PlatformTransactions"]
}).injectEndpoints({
    endpoints: (builder) => ({
        bulkResolveExceptions: builder.mutation({
            query: (request) => ({
                url: 'api/headofficeadmin/platformtransactions/resolve-bulk',
                method: 'POST',
                body: request
            })
        }),
        fetchHeadOfficePlatformTransactionExceptions: builder.query({
            query: ({ page = 1, limit }) => `api/headofficeadmin/platformTransactions/exceptions?page=${page}&limit=${limit}`,
            providesTags: ["PlatformTransactions"]
        }),
        fetchHeadOfficePotentialTransactionMatches: builder.query({
            query: ({ id }) => `api/headofficeadmin/platformTransactions/${id}/potentialMatches`
        }),
        resolveHeadOfficeException: builder.mutation({
            query: (request) => ({
                url: 'api/headofficeadmin/platformTransactions/resolve',
                method: 'POST',
                body: request
            })
        }),
        retryPlatformTransactionMatching: builder.mutation({
            query: () => ({
                url: 'api/headofficeadmin/platformTransactions/retry-matching',
                method: 'POST'
            })
        })
    })
});

export const {
    useFetchHeadOfficePlatformTransactionExceptionsQuery,
    useLazyFetchHeadOfficePotentialTransactionMatchesQuery,
    useResolveHeadOfficeExceptionMutation,
    useRetryPlatformTransactionMatchingMutation,
    useBulkResolveExceptionsMutation
} = hoAdminPlatformTransactionApiEndpoints;
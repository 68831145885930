import React from "react";
import { useState } from "react";
import EditableMessage from "./EditableMessage";

const DraftMessage = ({
    messageId,
    threadId,
    initialMessage = "",
    initialRecipients = [],
    page,
    active,
    onDelete,
    subject,
    priority,
    isActionRequired,
    patchMessage,
    updateMessageRecipients,
    patchThread,
    sendMessage
}) => {

    const [isEditing, setIsEditing] = useState(false);

    const handleOnDelete = () => {
        onDelete({page});
    };

    const handleMessageOnChange = (e) => {
        if (e.target.value === initialMessage) {
            setIsEditing(_ => false);
        }
        else {
            setIsEditing(_ => true);
        }
    }


    const handleMessageOnBlur = (value) => {
        patchMessage(messageId, "message", value, { page, active })
            .then(
                r => setIsEditing(_ => false)
            );
    };

    const handlePriorityOnChange = ({ value }) => {
        setIsEditing(_ => true);
        patchMessage(messageId, "messagePriority", value, { page, active })
            .then(
                r => setIsEditing(_ => false)
            );
    }

    const handleRecipientsOnChange = (recipients) => {
        setIsEditing(_ => true)
        return updateMessageRecipients(messageId, recipients, { page, active })
            .then(
                r => setIsEditing(_ => false)
            );
    };

    const handleRequiresActionOnChange = (value) => {
        setIsEditing(_ => true);
        patchMessage(messageId, "isActionRequired", value, { page, active })
            .then(
                r => setIsEditing(_ => false)
            );
    };

    const handleSubjectOnChange = (value) => {
        setIsEditing(_ => true);
        patchThread(threadId, "subject", value)
            .then(
                r => setIsEditing(_ => false)
            );
    }

    const handleSendOnClick = () => {
        sendMessage();
    };

    return <EditableMessage
        isDraftMessage={true}
        initialMessage={initialMessage}
        initialSubject={subject}
        initialRecipients={initialRecipients}
        messageOnBlur={handleMessageOnBlur}
        messageOnChange={handleMessageOnChange}
        recipientsOnChange={handleRecipientsOnChange}
        requiresActionOnChange={handleRequiresActionOnChange}
        priorityOnChange={handlePriorityOnChange}
        threadSubjectOnBlur={handleSubjectOnChange}
        onSend={handleSendOnClick}
        onDelete={handleOnDelete}
        isEditing={isEditing}
        subject={subject}
        priority={priority}
        requiresAction={isActionRequired}
        canEditSubject
    />;
};

export default DraftMessage;
import React from "react";
import styled from "styled-components";
import withProviders from "../../hooks/withProviders";
import EditLoginDetailsForm from "./components/EditLoginDetailsForm";
import LoginAuthenticator from "./components/LoginAuthenticator";
import LoginCredentials from "./components/LoginCredentials";
import SessionList from "./components/SessionList";
import VerificationTokensList from "./components/VerificationTokensList";
import useClientDashboardLoginDetails from "./hooks/ClientDashboardLoginDetailsContext";
import { readableColor } from "polished";
import { Modal } from "react-bootstrap";
import { Button } from "../../components";
import { useForm } from "react-hook-form";
import { Tabs, TabScreen } from "react-tabs-scrollable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientDashboardLoginSessionsProvider } from "./hooks/ClientDashboardLoginSessionsContext";
import { StyledNavigationTab, StyledNavigationTabIconContainer } from "../../components/navigation/NavigationTabs";
import toast from "react-hot-toast";

const ModalBody = styled(Modal.Body)`
    min-height: 66.67vh;
    max-height: 66.67vh;
    display: flex;
    flex-direction: column;
    padding: 0;
`;

const NavigationContainer = styled.div`
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

const NavigationTab = React.forwardRef(({ icon, label, ...rest }, ref) => (
    <StyledNavigationTab ref={ref} {...rest}>
        <StyledNavigationTabIconContainer>
            <FontAwesomeIcon icon={icon} />
        </StyledNavigationTabIconContainer>
        <span>{label}</span>
    </StyledNavigationTab>
));

const ClientDashboardLoginDetailsModal = ({ show, handleClose = () => { } }) => {
    const [activeTab, setActiveTab] = React.useState(0);
    const { availableEmailAddresses, availablePhoneNumbers, totalSessions, data, isDirty } = useClientDashboardLoginDetails();
    
    const onClose = () => {
        setActiveTab(_ => 0);
        handleClose(isDirty);
    };

    const onTabClick = (e, i) => {
        e.preventDefault();
        setActiveTab(i);
    };

    if (!data) {
        return null;
    };

    const { username } = data;
    return (
        <Modal size="xl" centered backdrop="static" show={show} onHide={onClose} className="overflow-visible">
            <Modal.Header closeButton>
                <Modal.Title>Dashboard Login: {username}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <NavigationContainer>
                    <Tabs 
                        activeTab={activeTab} 
                        onTabClick={onTabClick}
                        leftBtnIcon={<FontAwesomeIcon icon="fa-chevron-left" />}
                        rightBtnIcon={<FontAwesomeIcon icon="fa-chevron-right" />}
                    >
                        <NavigationTab icon="fa-user-tag" label="Details" />
                        <NavigationTab icon="fa-key" label="Credentials" />
                        <NavigationTab icon="fa-desktop" label={`Sessions (${totalSessions})`} />
                        <NavigationTab icon="fa-user-check" label={`Verification Tokens`} />
                    </Tabs>
                </NavigationContainer>
                <TabScreen activeTab={activeTab} index={0} className="px-3 py-4 d-flex" style={{ flex: '1 1 auto' }}>
                    <EditLoginDetailsForm data={data} />
                </TabScreen>
                <TabScreen activeTab={activeTab} index={1} className="px-3 py-4 overflow-hidden">
                    <LoginCredentials data={data} />
                    <LoginAuthenticator 
                        data={data} 
                        availableEmailAddresses={availableEmailAddresses} 
                        availablePhoneNumbers={availablePhoneNumbers} 
                    />
                </TabScreen>
                <TabScreen activeTab={activeTab} index={2} className="px-2 py-3" style={{ flex: '1 1 auto' }}>
                    <ClientDashboardLoginSessionsProvider masterAccountId={data?.masterAccountId} loginId={data?.id}>
                        <SessionList data={data} />
                    </ClientDashboardLoginSessionsProvider>
                </TabScreen>
                <TabScreen activeTab={activeTab} index={3} className="px-2 py-3" style={{ flex: '1 1 auto' }}>
                    <VerificationTokensList data={data} />
                </TabScreen>
            </ModalBody>
            <Modal.Footer>
                <Button variant="light" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withProviders()(ClientDashboardLoginDetailsModal);
import React from "react";
import withProviders from "../../hooks/withProviders";
import UsersGrid from "../../features/usersgrid";
import { Main } from "../../components/utilities";
import { UsersGridPageSubHeader } from "../../components/utilities/PageSubHeader";
import { UsersGridDataProvider, useUsersGridData } from "../../features/usersgrid/hooks/UsersGridContext";
import { FilterProvider, UsersGridDefaultFilters, useFilterContext } from "../../hooks/FilterContext";

const UsersPage = () => {
    const [isFiltersShown, setIsFiltersShown] = React.useState(false);
    const [isCreateShown, setIsCreateShown] = React.useState(false);
    const { clearFilter, filter: {}, isFiltersApplied } = useFilterContext();
    const { clear, selected, selectUser } = useUsersGridData();

    return (
        <React.Fragment>
            <Main className="d-flex flex-column">
                <UsersGridPageSubHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">

                        </div>
                        <div className="d-flex flex-fill justify-content-end align-items-center">

                        </div>
                    </div>
                </UsersGridPageSubHeader>
                <section className="px-4 my-3 h-100">
                    <UsersGrid />
                </section>
            </Main>
        </React.Fragment>
    );
};

export default withProviders([FilterProvider, { defaultFilters: UsersGridDefaultFilters }], UsersGridDataProvider)(UsersPage);
import { Modal, Spinner } from "react-bootstrap";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";

const BizSheetPdfPreviewModal = ({ data, show, handleClose, isLoading, error, refetch }) => {

    return <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        contentClassName={(isLoading || error) ? "" : "h-100"}
        dialogClassName="h-100 pb-5"
        backdrop='static'>
        <Modal.Header closeButton>Business Sheet Preview</Modal.Header>
        <Modal.Body>
            {error == null
                ? isLoading || data == null
                    ? <Spinner animation="border" className="d-flex mx-auto" />
                    : <iframe src={`data:application/pdf;base64,${data}`} height="100%" width="100%" title="Business Sheet PDF" />
                : <ErrorMessageWrapper>
                    <ErrorMessage
                        message={error.data?.message ?? "Unable to generate a PDF for this Business Sheet"}
                        // error={error.message}
                        retryCallback={refetch}
                    />
                </ErrorMessageWrapper>}
        </Modal.Body>
    </Modal>
}

export default BizSheetPdfPreviewModal;
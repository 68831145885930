import React from "react";
import FormSelect from "../FormSelect";
import { useFetchDocumentFunctionsQuery } from "../../../services/clientdocuments";

const DocumentFunctionsSelect = ({ options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchDocumentFunctionsQuery({ fetchType: 'List' });
    return <FormSelect
        options={data}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default DocumentFunctionsSelect;
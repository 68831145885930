import { wealthProApi } from '../../redux/api';

const clientReviewDefApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchStatuses: builder.query({
            query: ({
                type = 6,
                fetchType = 1,
                sort = "DisplayOrder"
            }) => `api/review/Status?type=${type}&fetchType=${fetchType}&sort=${sort}`
        }),
        fetchServiceTerms: builder.query({
            query: ({
                fetchType = 1,
                sort = "ServiceName"
            }) => `api/review/ServiceAgreements?fetchType=${fetchType}&sort=${sort}`
        }),
    }),
});

export const {
    useFetchStatusesQuery,
    useLazyFetchStatusesQuery,
    useFetchServiceTermsQuery,
} = clientReviewDefApiEndpoints;
import { useLazyContactReportQuery } from "../../../services/reminders"

const useContactReport = () => {

    const [report, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyContactReportQuery();

    const getContactReport = (theRequest) => {
        const fromDate = theRequest.fromDate;
        const toDate = theRequest.toDate;
        const adviserIds = theRequest.adviserIds;
        const userIds = theRequest.userIds;
        const reminderTypeIds = theRequest.reminderTypeIds;
        const reminderStatuses = theRequest.reminderStatuses;
        const groupBy = theRequest.groupBy;
        return new Promise((resolve, reject) => {
            
            return report({data: {fromDate: fromDate, toDate: toDate, adviserIds: adviserIds, userIds: userIds, reminderTypeIds: reminderTypeIds, reminderStatuses: reminderStatuses, groupBy: groupBy}}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    return {
        getContactReport
    }

};

export default useContactReport;
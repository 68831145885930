import React from "react";
import withProviders from "../../hooks/withProviders";
import NavigationTabsPageSubHeader from "../../components/layout/NavigationTabsPageSubHeader";
import { Outlet } from "react-router-dom";
import { Main } from "../../components/utilities";
import { ClientDashboardManagementControlsProvider } from "../../hooks/ClientDashboardManagementContext";

const ClientDashboardPage = () => (
    <React.Fragment>
        <NavigationTabsPageSubHeader
            pathBase={"/client/:masterAccountId/dashboard"}
            options={{
                defaultRoute: 'logins',
                children: [
                    { path: 'logins', label: 'Dashboard Logins', icon: 'fa-id-badge' }
                ]
            }}
        />
        <Main className="d-flex flex-column">
            <Outlet />
        </Main>
    </React.Fragment>
);

export default withProviders(ClientDashboardManagementControlsProvider)(ClientDashboardPage);
import { wealthProApi } from "../../redux/api";

const buildMessageUsersUrlParams = ({ searchTerm,  page, limit, sort, fetchType, userType }) => {
    let params = `sort=${sort}&fetchType=${fetchType}`;

    if (page && limit) {
        params += `&page=${page}&limit=${limit}`;
    }

    
    if (searchTerm) {
        params += `&searchTerm=${searchTerm}`
    }

    if (userType) {
        params += `&userType=${userType}`
    }

    return params;
};

const messageUsersApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchMessageUsers: builder.query({
            query: ({ searchTerm = null, page = null, pageLimit: limit = null, sort = "UserType,DisplayName", fetchType = "list", userType = null }) => ({
                url: `api/messaging/messageusers?${buildMessageUsersUrlParams({ searchTerm, page, limit, sort, fetchType, userType })}`
            }),
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                const { userType, fetchType, sort, searchTerm } = queryArgs;
                return `${endpointName}?sort=${sort}&userType=${userType}&fetchType=${fetchType}&search=${searchTerm}`;
            },
            merge: (currentCache, newItems) => {
                const { pagination: currPage, userType: currUserType, searchTerm: currSearch } = currentCache || {};
                const { pagination: nextPage, userType: nextUserType, searchTerm: prevSearch  } = newItems || {};

                if (!currPage || nextPage.page <= currPage.page || currUserType !== nextUserType || currSearch !== prevSearch) {
                    return newItems;
                }

                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results);
            }
        })
    })
});

export const {
    useFetchMessageUsersQuery,
    useLazyFetchMessageUsersQuery
} = messageUsersApiEndpoints;
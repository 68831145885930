import { wealthProApi } from "../../redux/api";

const assetUniverseApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (build) => ({
        createAssetUniverse: build.mutation({
            query: ({ organisationId }) => ({
                url: 'api/assets/universe',
                method: 'POST',
                body: {
                    organisationId
                }
            })
        }),
        deleteAssetUniverse: build.mutation({
            query: ({ universeId }) => ({
                url: `api/assets/universe/${universeId}`,
                method: 'DELETE'
            })
        }),
        fetchAssetUniverses: build.query({
            query: ({ page, limit, sort = "SortOrder", fetchType = "object" }) => {
                if (page || limit) {
                    return `api/assets/universe?page=${page}&limit=${limit}&sort=${sort}&fetchType=${fetchType}`;
                }
                
                return `api/assets/universe?sort=${sort}&fetchType=${fetchType}`;
            }
        }),
        fetchAssetUniversesForGrid: build.query({
            query: ({ page, limit, sort, filterModel }) => ({
                url: `api/assets/universe/grid`,
                method: 'POST',
                body: {
                    page,
                    limit,
                    sort,
                    filterModel
                }
            })
        }),
        patchAssetUniverse: build.mutation({
            query: ({ universeId, operations }) => ({
                url: `api/assets/universe/${universeId}`,
                method: 'PATCH',
                body: operations

            })
        })
    })
});

export const {
    useCreateAssetUniverseMutation,
    useDeleteAssetUniverseMutation,
    useLazyFetchAssetUniversesQuery,
    useLazyFetchAssetUniversesForGridQuery,
    usePatchAssetUniverseMutation
} = assetUniverseApiEndpoints;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormSelect, FormTextArea } from "../../../components/forms";
import { ThemedSpan } from "../../../components/utilities";

const ChangeAuthorMessage = ({ showWarning, message, setMessage, users }) => {

    const { message: { text, recipients } } = message;

    return <>
        <div className="col-12">
            <FormSelect
                label="Recipients"
                defaultValue={recipients}
                onChange={(selected) => setMessage(curr => ({
                    ...curr,
                    message: {
                        ...curr.message,
                        recipients: selected.map(e => e.value)
                    }
                }))}
                options={users}
                isMulti
            />
        </div>
        {showWarning && <div className="col-12">
            <FontAwesomeIcon icon="fa-triangle-exclamation" className="text-danger mx-2" /><ThemedSpan variant="primary">Warning, you are updating this report's status.</ThemedSpan>
        </div>}
        <div className="col-12">
            <FormTextArea
                placeholder="Message..."
                value={text}
                minRows={3}
                maxRows={6}
                onChange={(e) => setMessage(curr => ({
                    ...curr,
                    message: {
                        ...curr.message,
                        text: e.target.value
                    }
                }))}
            />
        </div>
    </>
};

export default ChangeAuthorMessage;
import { Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { BasicToast } from "../../../components/toasts";

const RetryDocumentUploadModal = ({ attachmentsResponse = [], files, onClose, uploadDocuments, show }) => {

    const fileNamesToUpload = useMemo(() => {
        if (Array.isArray(attachmentsResponse)) {
            return attachmentsResponse.filter(x => x.status !== 200).map(x => x.fileName)
        }

        return [];
    }, [attachmentsResponse]);

    const handleRetry = (e) => {
        e.preventDefault();

        var filesToUpload = files.filter(x => fileNamesToUpload.some(y => x.name === y));

        uploadDocuments(filesToUpload);
    }

    return (
        <Modal centered show={show}>
            <Modal.Header>
                <ThemedSpan className="d-flex align-items-center">
                    <FontAwesomeIcon icon="exclamation-triangle" className="text-danger me-3" size="2x" />
                    Retry Attachments
                </ThemedSpan>

            </Modal.Header>
            <Modal.Body>
                <ThemedSpan>Some attachments failed to upload:</ThemedSpan>
                <ul>
                    {fileNamesToUpload.map(fileName => (
                        <li>{fileName}</li>
                    ))}
                </ul>
                <ThemedSpan>Please retry the upload below.</ThemedSpan>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                <Button onClick={handleRetry}>Retry</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RetryDocumentUploadModal;
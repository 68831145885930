import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const communicationPreferencesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountCommunicationPreferences"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        getCommunicationPreference: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/communication-preferences`,
            providesTags: ({ communicationPreferenceId }) => [{ type: 'masterAccountCommunicationPreferences', id: communicationPreferenceId }]
        }),
        patchCommunicationPreference: builder.mutation({
            query: ({ communicationPreferenceId, masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/communication-preferences/${communicationPreferenceId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('getCommunicationPreference', { masterAccountId }, (data) => {
                    applyPatch(data, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useGetCommunicationPreferenceQuery,
    usePatchCommunicationPreferenceMutation,
} = communicationPreferencesApiEndpoints;
import { createContext, useContext, useEffect, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useAuth } from "react-oidc-context";
import {
    useCreateCorporateActionPairingMutation,
    useDeleteCorporateActionPairingMutation,
    useLazyFetchCorporateActionPairingsQuery,
    usePatchCorporateActionPairingMutation
} from "../../../services/assets";
import { useFilterContext } from "../../../hooks/FilterContext";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

const CorporateActionPairsContext = createContext();

export const useCorporateActionPairs = () => useContext(CorporateActionPairsContext);

const CorporateActionPairsProvider = ({ children }) => {
    const limit = 50;
    const [sort, setSort] = useState("ActionDate+DESC");
    const [sortProperty, sortDirection] = sort.split("+");
    const [pairToDeleteId, setPairToDeleteId] = useState(null);
    const [pairToEditId, setPairToEditId] = useState(null);
    const { user: { profile } } = useAuth();
    const [createPairTrigger, createResult] = useCreateCorporateActionPairingMutation();
    const [deletePairTrigger, deleteResult] = useDeleteCorporateActionPairingMutation();
    const [fetchPairsTrigger, fetchPairsResult] = useLazyFetchCorporateActionPairingsQuery();
    const [patchPairsTrigger] = usePatchCorporateActionPairingMutation();
    const { filter } = useFilterContext();
    const { data, error, isError, isUninitialized } = fetchPairsResult;
    const { results, next, totalCount } = data || { results: [], next: null, totalCount: limit };

    const clear = () => fetchPairsTrigger({
        next: null,
        limit,
        sort,
        filter
    });

    const createPair = () => {
        return new Promise((resolve, reject) => {
            return createPairTrigger({ organisationId: profile.organisation, divisionId: profile.division[0] }) 
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const deletePair = () => {
        return new Promise((resolve, reject) => {
            return deletePairTrigger({ pairId: pairToDeleteId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => fetchPairsTrigger({
        next,
        limit,
        sort,
        filter
    });

    const patchPair = (property, newValue) => {
        return new Promise((resolve, reject) => {
            var operations = [patchReplace(property, newValue)];
            return patchPairsTrigger({ pairId: pairToEditId, operations })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const refetch = (e) => {
        if (typeof e?.preventDefault === "function") {
            e.preventDefault();
        }

        clear();
    }

    const onSortChange = (property) => {
        if(sortProperty === property){
            setSort(`${property}+${sortDirection === "ASC" ? "DESC" : "ASC"}`);
            return;
        }

        setSort(`${property}+DESC`);
    };

    const sortableHeaderRenderer = ({ label, property, key }) => {
        return <StandardTableSortableHeader
            key={key}
            onClick={() => onSortChange(property)}
            active={sortProperty === property}
            direction={sortDirection}
        >
            {label}
        </StandardTableSortableHeader>
    };

    const columns = [
        {
            label: "Action Date",
            property: "ActionDate",
            renderer: sortableHeaderRenderer
        },
        {
            label: "Sedol Out",
            property: "SedolOut",
            renderer: sortableHeaderRenderer
        },
        {
            label: "Sedol In",
            property: "SedolIn",
            renderer: sortableHeaderRenderer
        },
        {
            label: "Description",
            property: "Description",
            renderer: sortableHeaderRenderer
        },
        {
            label: ""
        }
    ];

    useEffect(() => {
        !isUninitialized && clear();
    }, [filter, sort]);

    return <CorporateActionPairsContext.Provider value={{
        columns,
        createResult,
        deleteResult,
        error,
        isError,
        pairToDelete: results.find(x => x.pairId === pairToDeleteId) ?? null,
        pairToEdit: results.find(x => x.pairId === pairToEditId) ?? null,
        results,
        totalCount,
        sort,
        isItemLoaded,
        loadMoreItems,
        createPair,
        deletePair,
        patchPair,
        refetch,
        setPairToDeleteId,
        setPairToEditId
    }}>
        {children}
    </CorporateActionPairsContext.Provider>;
};

export default CorporateActionPairsProvider;
import { useParams } from "react-router-dom";
import { useMoveMailToFolderMutation } from "../../../services/useremails";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useEffect, useState } from "react";
import _ from "lodash";

const useMessageSummaries = () => {

    const { folderId } = useParams();
    const { selectedMailUserId: userId, searchTerm, setSearchTerm, fetchMessageSummaries, messageSummaries } = usePersonalOutlookContext();
    const [isClearing, setIsClearing] = useState(false);
    const [moveToFolderTrigger, moveToFolderResult] = useMoveMailToFolderMutation();
    const { data, isLoading, isFetching, isError, error, isUninitialized } = messageSummaries;
    const { value, nextLink } = data || { value: [], nextLink: null };

    const clear = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }
        setIsClearing(true);

        fetchMessageSummaries({ folderId, userId, searchTerm: !_.isEmpty(searchTerm) ? searchTerm : null }, true)
            .unwrap()
            .finally(_ => setIsClearing(false));
    };

    const isItemLoaded = (index) => value[index] || index < value.length;

    const loadMoreItems = () => {
        fetchMessageSummaries({ folderId, userId, nextLink: nextLink ? nextLink : null });
    };

    const moveMailToDeletedFolder = (id) => {
        return new Promise((res, rej) => {
            return moveToFolderTrigger({ userId, id, folderId, searchTerm, request: { delete: true } })
                .unwrap()
                .then(res, rej);
        });
    };

    useEffect(() => {
        // Folder has changed, refresh if there is no search term, otherwise clear the search term to trigger the other useEffect
        if (searchTerm == null)
            clear();
        else
            setSearchTerm(null);
    }, [folderId]);

    useEffect(() => {
        if (!folderId || isUninitialized || isClearing) return;

        // Refetch immediately (no debounce) if searchTerm is cleared
        if (searchTerm == null || searchTerm === "") {
            clear();
            return;
        }

        const timeout = setTimeout(clear, 500);

        return () => clearTimeout(timeout);
    }, [searchTerm]);

    return {
        searchTerm,
        setSearchTerm,
        isClearing,
        isLoading,
        isError,
        error,
        value,
        totalCount: isClearing ? 0 : (nextLink || isUninitialized ? value.length + 15 : value.length),
        isItemLoaded,
        loadMoreItems,
        moveMailToDeletedFolder,
        moveToFolderResult,
        retry: clear,
    };
};

export default useMessageSummaries;
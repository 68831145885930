import React, { useCallback, useEffect } from "react";
import { GridWrapper } from "../../../components";
import { DivestTransactionTable, FeeCollection, Income, InvestmentSelection, ProductSelection } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

// This instruction has an Invest and Divest
const ISATransfer = ({ instructionId }) => {
	const [{ fetchInvest, fetchDivest }, { investIsError, divestIsError }, { realTimeRemainder }] = useInstruction();

	useEffect(() => {
		fetchInvest({ instructionId });
		fetchDivest({ instructionId });
	}, [fetchDivest, fetchInvest, instructionId]);

	const retry = useCallback(() => {
		fetchInvest({ instructionId });
		fetchDivest({ instructionId });
	}, [fetchDivest, fetchInvest, instructionId])

	if (investIsError || divestIsError)
		return <InvestDivestErrorMessage retry={retry} />

	return <GridWrapper>
		<ProductSelection
			changeOwner
			productLabel={"Transfer from Product:"}
		/>
		<DivestTransactionTable />
		<ProductSelection
			isInvest
			changeOwner
			productLabel={"Transfer to Product:"}
		/>

		<Income clientWithdrawal />

		<InvestmentSelection maxInvestmentAmount={realTimeRemainder} />
		<FeeCollection />

		{/* {(validDivestAmount != null && validDivestAmount !== 0)
			? <AnnualCostsDisclosureComparisonTable />
			: <AnnualCostsDisclosureTable isDisposing />} */}
	</GridWrapper>;
};

export default ISATransfer;

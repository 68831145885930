import React from "react";
import NavigationTabsPageSubHeader from "../../components/layout/NavigationTabsPageSubHeader";
import { Outlet } from "react-router-dom";
import { Main } from "../../components/utilities";

const ClientSecurityPage = () => (
    <React.Fragment>
        <NavigationTabsPageSubHeader
            pathBase={"/client/:masterAccountId/security"}
            options={{
                defaultRoute: 'access',
                children: [
                    { path: 'access', label: 'Client Access', icon: 'fa-user-lock' },
                    { path: 'logs', label: 'Event Logs', icon: 'fa-database' }
                ]
            }}
        />
        <Main className="d-flex flex-column">
            <Outlet />
        </Main>
    </React.Fragment>
);

export default ClientSecurityPage;
import React from "react";
import Moment from "react-moment";
import Tippy from "@tippyjs/react";
import classnames from "classnames";
import styled from "styled-components";
import ClientDocumentsTableRowActionCol from "./ClientDocumentsTableRowActionCol";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ClientDocumentTableRowBaseCell = styled.td`
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
    height: ${props => props.height ?? "auto"};
    width: ${props => props.width ?? "auto"};
    max-width: 0 !important;
`;

export const ClientDocumentsIsClientReadableColumn = ({ clientReadable, width }) => {
    return (
        <ClientDocumentTableRowBaseCell className="align-middle text-center action-col text-truncate" width={width}>
            <FontAwesomeIcon
                size="xl" 
                icon={clientReadable ? 'fa-check' : 'fa-xmark'}
                className={classnames({
                    'text-success': clientReadable,
                    'text-danger': !clientReadable
                })}
            />
        </ClientDocumentTableRowBaseCell>
    );
};

export const ClientDocumentsTypeTableColumn = ({ documentType, typeDescription, icon, width }) => {

    const calculateIcon = () => {
        if (icon) {
            return icon;
        };

        switch(documentType) {
            case 0: // Word
                return "fa-file-word";
            case 1: // Excel
                return "fa-file-excel";
            case 2: // Outlook
            case 3: // Email
                return "fa-file-code";
            case 4: // PDF
                return "fa-file-pdf";
            case 5: // Text
                return "fa-file-lines";
            case 6: // Image
                return "fa-file-image";
            case 7: // Video
                return "fa-file-video";
            default:
                return "fa-file";
        };
    };

    return (
        <ClientDocumentTableRowBaseCell className="align-middle text-center action-col text-truncate" width={width}>
            <Tippy placement="right" content={(
                <span>{typeDescription}</span>
            )}>
                <div>
                    <FontAwesomeIcon 
                        size="xl"
                        icon={calculateIcon()}
                        className={classnames({
                            'text-word': documentType === 0,
                            'text-excel': documentType === 1,
                            'text-code': documentType === 2 || documentType === 3,
                            'text-pdf': documentType === 4,
                            'text-lines': documentType === 5,
                            'text-image': documentType === 6,
                            'text-video': documentType === 7,
                            'text-file': typeof(documentType) !== 'number',
                        })}
                    />
                </div>
            </Tippy>
        </ClientDocumentTableRowBaseCell>
    );
};

export const ClientDocumentsUploadedTableColumn = ({ dateUploaded, uploadedByName, dateFormat = "DD/MM/YYYY" }) => {
    return (
        <ClientDocumentTableRowBaseCell className="align-middle text-truncate">
            <Tippy placement="left" content={(
                <p className="m-0">
                    {uploadedByName && (<span>Document was uploaded by {uploadedByName}.</span>)}
                    {!uploadedByName && (<span>Document was uploaded by the system.</span>)}
                </p>
            )}>
                <div>
                    <Moment date={dateUploaded} format={dateFormat} />
                </div>
            </Tippy>
        </ClientDocumentTableRowBaseCell>
    );
};

export {
    ClientDocumentsTableRowActionCol
};
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";

const servicePreferencesApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountServicePreferences"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        getServicePreference: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/service-preferences`,
            providesTags: ({ servicePreferenceId }) => [{ type: 'masterAccountServicePreferences', id: servicePreferenceId }]
        }),
        patchServicePreference: builder.mutation({
            query: ({ servicePreferenceId, masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/service-preferences/${servicePreferenceId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('getServicePreference', { masterAccountId }, (data) => {
                    applyPatch(data, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useGetServicePreferenceQuery,
    usePatchServicePreferenceMutation
} = servicePreferencesApiEndpoints;
import React, { useState } from "react";
import Button from "../../../../../components/buttons";
import { Modal } from "react-bootstrap";
//import ProviderSelect from "../../../../clienttransactions/components/ProviderSelect";
//import ProviderPagedSelect from "../../../../../components/forms/selects/ProviderPagedSelect";
//import ProviderSearchPagedSelect  from "../../ProviderSearchPagedSelect"
import ProvidersFormSelectPaged from "../../../../headofficefeemanagement/components/ProvidersFormSelectPaged"
import {
    DateInput
} from "../../../../../components/forms/";

const DebtorsLedgerReportModal = ({
    confirmButtonLabel = "Run Report",
    confirmButtonVariant = "success",
    handleDebtorsLedgerReportArgsClose,
    handleDebtorsLedgerReportArgsConfirm,
    debtorsLedgerReportArgsShow,
    ...rest
}) => {


    const [theFromDate, setTheFromDate] = useState(null);
    const [theToDate, setTheToDate] = useState(null);
    const [providers, setProviders] = useState([]);

    const [errorMessage, setErrorMessage] = React.useState("");



    const onClose = () => {
        if (handleDebtorsLedgerReportArgsClose && typeof handleDebtorsLedgerReportArgsClose === "function") {
            setErrorMessage("");
            setTheFromDate(null);
            setTheToDate(null);
            setProviders([]);
            handleDebtorsLedgerReportArgsClose();
        }
    }

    const onConfirm = () => {
        if(theFromDate !== null && theToDate !== null){
            if (handleDebtorsLedgerReportArgsConfirm && typeof handleDebtorsLedgerReportArgsConfirm === "function") {
                const theRequest = {fromDate: theFromDate, toDate: theToDate, providerIds: providers.map((pr) => pr.value) }
                handleDebtorsLedgerReportArgsConfirm(theRequest);
            }
            onClose();
        }
        else{
            setErrorMessage("From and To dates must be selected!");
        }
    }
    return (
        <Modal size="med" centered backdrop="static" show={debtorsLedgerReportArgsShow}>
            <Modal.Header>Debtors Ledger Report</Modal.Header>
            <Modal.Body {...rest}>
            <div className="row mb-3">
                <div className="col">
                        <DateInput
                            label="From"
                            value={theFromDate}
                            onBlur={(value) => setTheFromDate(value)}
                        />
                </div>
                <div className="col">
                        <DateInput
                            label="To"
                            value={theToDate}
                            onBlur={(value) => setTheToDate(value)}
                        />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <ProvidersFormSelectPaged
                        value={providers}
                        label={"Providers - All Providers unless selections made"}
                        onChange={(values) => setProviders(values)}
                        isMulti={true}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="text-warning"> {errorMessage} </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmButtonVariant} onClick={onConfirm}>{confirmButtonLabel}</Button>
                <Button variant="light" onClick={onClose}>Cancel</Button>
                
            </Modal.Footer>
        </Modal>
    );

};

export default DebtorsLedgerReportModal;
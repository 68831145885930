import { useEffect, useMemo, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useCreateAccountAssetMutation, useDeleteAccountAssetMutation, useFetchAccountAssetsQuery, useFetchAssetTypesQuery, usePatchAccountAssetMutation, useUpdateAccountAssetOwnerMappingsMutation } from "../../../services/clientfactfind";

const useAccountAssets = (assetsSectionId) => {
    const { id: masterAccountId } = useClientContext();
    const { data, isLoading, isFetching, isUninitialized, isError, error, refetch } = useFetchAccountAssetsQuery({ assetsSectionId });
    const [accountAssets, setAccountAssets] = useState([]);
    const { data: assetTypes, isLoading: isLoadingAssetTypes, isFetching: isFetchingAssetTypes, isUninitialized: isAssetTypesUninitialized } = useFetchAssetTypesQuery({ sort: "DisplayOrder" });
    const [create] = useCreateAccountAssetMutation();
    const [remove] = useDeleteAccountAssetMutation();
    const [patch] = usePatchAccountAssetMutation();
    const [updateOwnerMappings] = useUpdateAccountAssetOwnerMappingsMutation();

    const assetOwnerTotals = useMemo(() => {
        if (!accountAssets) return;

        let ownerDictionary = {};

        accountAssets.forEach((asset) => {
            // generate a dictionary key made of the client account IDs
            if (asset.ownerMappings.length === 0) return;
            const key = asset.ownerMappings.map(x => x.clientAccountId).sort().join('');

            // initialise the dictionary entry if it does not exist already
            ownerDictionary[key] ??= {
                cashAssets: 0,
                propertyAssets: 0,
                additionalInvestments: 0,
                totalAssets: 0,
                clientName: asset.ownerMappings.map(x => x.clientName).join("; ")
            };

            // update the values depending on the asset category
            switch (asset.assetCategoryText) {
                case "Cash":
                    ownerDictionary[key].cashAssets += asset.value;
                    break;
                case "Property":
                    ownerDictionary[key].propertyAssets += asset.value;
                    break;
                case "Investment":
                    ownerDictionary[key].additionalInvestments += asset.value;
                    break;
                default:
                    break;
            }

            ownerDictionary[key].totalAssets += asset.value;
        });

        // the order will change as the asset owners do, so need to sort them finally at the end...
        const orderedOwnerDictionary = Object.keys(ownerDictionary)
            .sort((a, b) => a.length - b.length || a.localeCompare(b))
            .reduce((obj, key) => {
                obj[key] = ownerDictionary[key];
                return obj;
            }, {});

        return Object.values(orderedOwnerDictionary);
    }, [accountAssets]);

    const createAccountAsset = () => {
        return new Promise((resolve, reject) => {
            return create({ assetsSectionId, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                )
        })
    };

    const bulkPatchAccountAsset = (accountAssetId, operations) => {
        return new Promise((resolve, reject) => {
            return patch({ accountAssetId, operations, masterAccountId, assetsSectionId })
                .unwrap()
                .then(resolve, reject);
        });
    }

    const patchAccountAsset = (accountAssetId, property, value) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, value)];
            return bulkPatchAccountAsset(accountAssetId, operations)
                .then(resolve, reject);
        });
    };

    const refetchAccountAssets = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    }

    const removeAccountAsset = (accountAssetId) => {
        return new Promise((resolve, reject) => {
            return remove({ accountAssetId, assetsSectionId, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                )
        })
    };

    const updateAccountAssetOwnerMappings = (accountAssetId, ids) => {
        return new Promise((resolve, reject) => {
            return updateOwnerMappings({ accountAssetId, ids, assetsSectionId, masterAccountId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                )
        })
    };

    useEffect(() => {
        if (!data) return;
        setAccountAssets([...data]);
    }, [data]);

    return {
        data: accountAssets || [],
        assetOwnerTotals: assetOwnerTotals || [],
        isLoading: isLoading || isFetching || isUninitialized,
        isError,
        error,
        refetchAccountAssets,
        assetTypes,
        isLoadingAssetTypes: isLoadingAssetTypes || isFetchingAssetTypes || isAssetTypesUninitialized,
        createAccountAsset,
        bulkPatchAccountAsset,
        patchAccountAsset,
        removeAccountAsset,
        updateAccountAssetOwnerMappings,
        setAccountAssets
    };
};

export default useAccountAssets;
import {
    useDeleteClientFeeAgreementMutation,
    useFetchClientFeeAgreementsQuery,
    useLazyPreviewClientFeeAgreementQuery,
    useLazyPublishClientFeeAgreementQuery,
    usePatchClientFeeAgreementMutation
} from "../../../services/clientserviceterms";


const useClientFeeAgreements = (masterAccountId) => {

    const { data, error, isError, isFetching, isLoading, refetch } = useFetchClientFeeAgreementsQuery({ masterAccountId });
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchClientFeeAgreementMutation();
    const [remove, { error: removeError, isError: isErrorRemoving, isLoading: isRemoving }] = useDeleteClientFeeAgreementMutation();
    const [preview, { error: previewError, isError: isErrorPreviewing, isLoading: isLoadingPreview }] = useLazyPreviewClientFeeAgreementQuery();
    const [publish, { error: publishError, isError: isErrorPublishing, isLoading: isLoadingPublish }] = useLazyPublishClientFeeAgreementQuery();

    const previewClientConsent = () => {
        return new Promise((resolve, reject) => {

            return preview({masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const publishClientConsent = () => {
        return new Promise((resolve, reject) => {

            return publish({masterAccountId}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };
    
    const updateFeeAgreement = (clientFeeAgreementId, property, value) => {
        return new Promise((resolve, reject) => {

            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];

            return patch({ clientFeeAgreementId, masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const deleteClientFeeAgreement = (clientFeeAgreementId) => {
        return new Promise((resolve, reject) => {
            return remove({ clientFeeAgreementId, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        feeAgreements: data || [],
        isLoadingFeeAgreements: isLoading,
        previewClientConsent,
        publishClientConsent,
        updateFeeAgreement,
        deleteClientFeeAgreement
    }

};

export default useClientFeeAgreements;
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import GridWrapper from "../../../components/GridWrapper";
import { CountrySelect, DateInput, FormInput, FormLabel, FormSelect, RichTextEditor } from "../../../components/forms";
import { BooleanSelect } from "../../../components/forms/selects";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { ClientRow, DependantsList, SectionHeader, SelectOpenConnectionModal } from "../components";
import usePersonalDetails from "../hooks/usePersonalDetails";

const PersonalDetailsSection = () => {

    const {
        personalDetails,
        realTimeHasWill,
        updateHasWill,
        isLoadingPersonalDetails,
        isErrorPersonalDetails,
        personalDetailsError,
        updatePersonalDetails,
        updatePersonalDetailsConnectionsMapping,
        refetchOptions,
        connectionsAndClientsOptions,
        maritalStatuses,
        employmentStatuses,
    } = usePersonalDetails();

    const gridTemplateColumns = useMemo(() =>
        `minmax(20ch, auto) 1fr auto repeat(${(personalDetails?.length ?? 1) - 1}, 1fr) `, [personalDetails?.length])

    if (isLoadingPersonalDetails) return <Skeleton count={6} height={16} />;

    if (isErrorPersonalDetails) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={personalDetailsError} />
        </ErrorMessageWrapper>;
    }

    return <>
        <GridWrapper
            className="m-4"
            gridTemplateColumns={gridTemplateColumns}
        >
            <ClientRow
                clientModels={personalDetails}
                renderCell={(details, index) => <SectionHeader key={index}>{details?.clientName}</SectionHeader>}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Marital Status:"}
                maxWidth={"20ch"}
                renderCell={(details, index) => <FormSelect
                    key={index}
                    options={maritalStatuses}
                    defaultValue={details?.maritalStatus}
                    onBlur={selection => updatePersonalDetails(details?.personalDetailsId, "maritalStatus", selection.value)}
                />}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Date of Birth:"}
                maxWidth={"20ch"}
                renderCell={(details, index) => <DateInput
                    key={index}
                    value={details?.dateOfBirth}
                    isClearable
                    onBlur={date => updatePersonalDetails(details?.personalDetailsId, "dateOfBirth", date)}
                />}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"National Insurance Number:"}
                maxWidth={"15ch"}
                renderCell={(details, index) => <FormInput
                    key={index}
                    value={details?.nationalInsuranceNumber}
                    onBlur={(newValue) => updatePersonalDetails(details?.personalDetailsId, "nationalInsuranceNumber", newValue)}
                />}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Country of Birth:"}
                maxWidth={"20ch"}
                renderCell={(details, index) => <CountrySelect
                    key={index}
                    defaultValue={details?.birthCountryCode}
                    onBlur={selection => updatePersonalDetails(details?.personalDetailsId, "birthCountryCode", selection.value)}
                />}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Nationality:"}
                maxWidth={"max-content"}
                renderCell={(details, index) => <FormInput
                    key={index}
                    value={details?.nationality}
                    onBlur={(newValue) => updatePersonalDetails(details?.personalDetailsId, "nationality", newValue)}
                />}
            />
            {/* Moving Tax Residency status to Income section and changing options */}
            {/* <ClientRow
                clientModels={personalDetails}
                label={"UK Tax Resident:"}
                maxWidth={"15ch"}
                renderCell={(details, index) => <BooleanSelect
                    key={index}
                    defaultValue={details?.isUkTaxResident}
                    onBlur={selection => updatePersonalDetails(details?.personalDetailsId, "isUkTaxResident", selection.value)}
                />}
            /> */}
            <ClientRow
                clientModels={personalDetails}
                label={"Other nationalities or Non-UK tax residences?"}
                maxWidth={"100%"}
                renderCell={(details, index) => <RichTextEditor
                    key={index}
                    defaultValue={details?.otherNationalities}
                    onBlur={(newHtmlContent) => updatePersonalDetails(details?.personalDetailsId, "otherNationalities", newHtmlContent)}
                    inline
                />}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Employment Status:"}
                maxWidth={"35ch"}
                renderCell={(details, index) => <FormSelect
                    key={index}
                    options={employmentStatuses}
                    defaultValue={details?.employmentStatus}
                    onBlur={selection => updatePersonalDetails(details?.personalDetailsId, "employmentStatus", selection.value)}
                />}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Employer Name, Occupation, and Role:"}
                renderCell={(details, index) => <RichTextEditor
                    key={index}
                    defaultValue={details?.employerDetails}
                    onBlur={newValue => updatePersonalDetails(details?.personalDetailsId, "employerDetails", newValue)}
                />}
                fieldKey={"employerDetails"}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"General State of Health:"}
                renderCell={(details, index) => <RichTextEditor
                    key={index}
                    defaultValue={details?.stateOfHealth}
                    onBlur={newValue => updatePersonalDetails(details?.personalDetailsId, "stateOfHealth", newValue)}
                />}
                fieldKey={"stateOfHealth"}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Health Concerns:"}
                renderCell={(details, index) => <RichTextEditor
                    key={index}
                    defaultValue={details?.healthConcerns}
                    onBlur={newValue => updatePersonalDetails(details?.personalDetailsId, "healthConcerns", newValue)}
                />}
                fieldKey={"healthConcerns"}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Are you a Smoker?:"}
                maxWidth="15ch"
                renderCell={(details, index) => <BooleanSelect
                    key={index}
                    defaultValue={details?.isSmoker}
                    onBlur={selection => updatePersonalDetails(details?.personalDetailsId, "isSmoker", selection.value)}
                />}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Do you have a Will?"}
                renderCell={(details, index) => <GridWrapper
                    key={index}
                    gridTemplateColumns={"15ch auto 1fr"}
                >
                    <span className={"col-start-1"}>
                        <BooleanSelect
                            defaultValue={details?.hasWill}
                            onChange={selection => updateHasWill(index, selection.value)}
                            onBlur={selection => {
                                if (selection.value)
                                    return updatePersonalDetails(details?.personalDetailsId, "hasWill", selection.value);
                                else
                                    return Promise.all([updatePersonalDetails(details?.personalDetailsId, "hasWill", false), updatePersonalDetails(details?.personalDetailsId, "willLocation", "")]);
                            }}
                        />
                    </span>
                    <FormLabel className="my-auto col-start-2">
                        Where:
                    </FormLabel>
                    <span className="col-start-3">
                        <FormInput
                            value={realTimeHasWill[index] ? details?.willLocation : ""}
                            onBlur={(newValue) => updatePersonalDetails(details?.personalDetailsId, "willLocation", newValue)}
                            disabled={!realTimeHasWill[index]}
                        />
                    </span>
                </GridWrapper>}
                fieldKey="will"
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Power of Attorney:"}
                renderCell={(details, index) => <SelectOpenConnectionModal
                    key={index}
                    options={connectionsAndClientsOptions.filter(({ value }) => value !== details.clientAccountId)}
                    defaultValue={details?.powersOfAttorney?.map(x => x.personId)}
                    onBlur={newList => updatePersonalDetailsConnectionsMapping(details?.personalDetailsId, "PowerOfAttorney", newList.map((item) => item.value))}
                    onHide={refetchOptions}
                />}
                fieldKey={"powersOfAttorney"}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Trusted Friend:"}
                renderCell={(details, index) => <SelectOpenConnectionModal
                    key={index}
                    options={connectionsAndClientsOptions.filter(({ value }) => value !== details.clientAccountId)}
                    defaultValue={details.trustedFriends.map(x => x.personId)}
                    onBlur={newList => updatePersonalDetailsConnectionsMapping(details?.personalDetailsId, "TrustedFriend", newList.map((item) => item.value))}
                    onHide={refetchOptions}
                />}
                fieldKey="trustedFriends"
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Solicitor:"}
                renderCell={(details, index) => <SelectOpenConnectionModal
                    key={index}
                    options={connectionsAndClientsOptions}
                    defaultValue={details.solicitors.map(x => x.personId)}
                    onBlur={newList => updatePersonalDetailsConnectionsMapping(details?.personalDetailsId, "Solicitor", newList.map((item) => item.value))}
                    onHide={refetchOptions}
                />}
                fieldKey={"solicitors"}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Accountant:"}
                renderCell={(details, index) => <SelectOpenConnectionModal
                    key={index}
                    options={connectionsAndClientsOptions}
                    defaultValue={details.accountants.map(x => x.personId)}
                    onBlur={newList => updatePersonalDetailsConnectionsMapping(details?.personalDetailsId, "Accountant", newList.map((item) => item.value))}
                    onHide={refetchOptions}
                />}
                fieldKey={"accountants"}
            />
            <ClientRow
                clientModels={personalDetails}
                label={"Stockbroker or Wealth Manager:"}
                renderCell={(details, index) => <SelectOpenConnectionModal
                    key={index}
                    options={connectionsAndClientsOptions}
                    defaultValue={details.stockbrokersAndWealthManagers.map(x => x.personId)}
                    onBlur={newList => updatePersonalDetailsConnectionsMapping(details?.personalDetailsId, "StockbrokerOrWealthManager", newList.map((item) => item.value))}
                    onHide={refetchOptions}
                />}
                fieldKey="stockbrokersAndWealthManagers"
            />
        </GridWrapper>
        <DependantsList />
    </>
}

export default PersonalDetailsSection;
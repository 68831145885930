import React from "react";
import { useLazyFetchMasterAccountDashboardVerificationTokensQuery } from "../../../services/clientauth";

const useVerificationTokensList = (masterAccountId, loginId) => {
    const [pageLimit, setPageLimit] = React.useState(50);
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [isDirty, setIsDirty] = React.useState(false);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [load, { data, isLoading, isFetching, ...loadResults }] = useLazyFetchMasterAccountDashboardVerificationTokensQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results, newRecordsSinceCache } = data || { pagination: { page: 1, pageSize: pageLimit, totalCount: 0, totalPages: 1 }, results: [], newRecordsSinceCache: 0 };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = !isDirty && (isFetching === true || isLoading === true || isLoadingState === true); 

    const clear = () => {
        if (!masterAccountId || !loginId || isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsDirty(_ => true);
        setIsLoadingState(_ => true);
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            loginId,
            page: 1,
            limit: pageLimit
        }).unwrap().then(_ => {
            setCacheKey(_prev => newCacheKey);
            setIsLoadingState(_prev => false);
            setIsDirty(_prev => false);
        });
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        setIsLoadingState(_ => true);
        load({ 
            cacheKey, 
            masterAccountId, 
            loginId,
            page: page + 1, 
            limit: pageLimit,   
        }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
        });
    };

    React.useEffect(clear, [ masterAccountId, loginId ]);

    return {
        ...loadResults,
        clear,
        data: results,
        hasMore,
        isDirty,
        isFetching: isDirty === true ? false : isFetching,
        isLoading: isDirty === true ? true : isLoading,
        isNextPageLoading,
        isNewRecords: newRecordsSinceCache > 0,
        isRowLoaded,
        limit: pageLimit,
        loadMore: isNextPageLoading ? () => {} : loadMore, 
        newRecordsSinceCache,
        page,
        pagination,
        totalCount,
        totalPages
    }
};

export default useVerificationTokensList;
import { useGetMasterAccountDetailsQuery, usePatchMasterAccountDetailsMutation } from "../../../services/clientdetails";

const useMasterAccountDetails = (masterAccountId) => {
    const { data, error, isError, isFetching, isLoading, refetch } = useGetMasterAccountDetailsQuery({ masterAccountId });
    const [patch, { error: updateError, isError: isErrorUpdating, isLoading: isUpdating }] = usePatchMasterAccountDetailsMutation();

    const applyPatch = (operations) => {
        return new Promise((resolve, reject) => {
            if (!operations || !Array.isArray(operations)) {
                return reject('Could not identify operations parameter.');
            }

            return patch({ masterAccountId, operations}).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    }

    const reload = (e) => {
        if (e) {
            e.preventDefault();
        }

        refetch({ masterAccountId });
    }

    const updateMasterAccount = (property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [
                { op: 'replace', path: `/${property}`, value: value }
            ];
            return patch({ masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const updateMasterAccountFields = (options) => {
        return new Promise((resolve, reject) => {
            if (!options || !Array.isArray(options) || options.length === 0) {
                reject("No operations have been selected.");
            }

            let operations = [];
            options.forEach(el => {
                operations.push({ op: 'replace', path: `/${el.property}`, value: el.value })
            });

            return patch({ masterAccountId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    return {
        applyPatch,
        data: data || null,
        error,
        isError,
        isErrorUpdating,
        isLoading: isLoading === true || isFetching === true,
        isUpdating,
        reload,
        updateError,
        updateMasterAccount,
        updateMasterAccountFields
    };
};

export default useMasterAccountDetails;
import { wealthProApi } from '../../redux/api';

const reportTypeEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchReportTypes: builder.query({
            query: () => `/api/reports/report-types`
        }),
        fetchReportTypesList: builder.query({
            query: () => `/api/reports/report-types/list`
        }),
        fetchPrdReportType: builder.query({
            query: () => `/api/reports/report-types/prd`
        }),
        fetchReviewFollowUpReportType: builder.query({
            query: () => `/api/reports/report-types/reviewfollowup`
        })
    })
});

export const {
    useFetchReportTypesQuery,
    useFetchReportTypesListQuery,
    useFetchPrdReportTypeQuery,
    useFetchReviewFollowUpReportTypeQuery
} = reportTypeEndpoints;
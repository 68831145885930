import Moment from "react-moment";
import { CurrencyInput } from "../../../components/forms";
import { StandardTableClickableRow } from "../../../components/tables/StandardTable";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const StyledCell = styled.td`
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
    width: ${props => props.width}%;
    max-width: 0;
`;

const ApplicationTableRow = ({ data: results, index, style, ...rest }) => {
    const { height } = style;
    const navigator = useNavigate();

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= results.length) {
        return null;
    }

    const {
        applicationId,
        applicationStatusText,
        applicationStatusDate,
        comment,
        designationName,
        providerName,
        amount,
        description,
        applicationReference
    } = results[index] || {};

    const onRowClick = () => {
        navigator(`/administration/applications/${applicationId}`)
    };

    return <StandardTableClickableRow onClick={onRowClick}>
        <StyledCell className="text-truncate" width={7.5} style={{ height }}>
            {applicationStatusText}
        </StyledCell>
        <StyledCell className="text-truncate" width={7.5} style={{ height }}>
            <Moment className="ms-1 d-none d-xxl-inline" format="DD/MM/YYYY" date={applicationStatusDate} />
        </StyledCell>
        <StyledCell className="text-truncate" width={17.5}>{comment}</StyledCell>
        <StyledCell className="text-truncate" width={15}>{designationName}</StyledCell>
        <StyledCell className="text-truncate" width={15}>{providerName}</StyledCell>
        <StyledCell className="text-truncate" width={10}>
            <CurrencyInput
                displayType={"text"}
                value={amount}
            />
        </StyledCell>
        <StyledCell className="text-truncate" width={12.5}>{description}</StyledCell>
        <StyledCell className="text-truncate" width={10}>{applicationReference}</StyledCell>
    </StandardTableClickableRow>;
};

export default ApplicationTableRow;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";

const CustodianAccountMatchingGridRowActionColToggle = React.forwardRef(({ children, onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

const CustodianAccountMatchingGridRowActionCol = ({ deleteFn, setToEdit }) => {
    const menuId = React.useId();
    const toggleId = React.useId();

    return (
        <Dropdown id={menuId} className="caret-off w-100">
            <Dropdown.Toggle as={CustodianAccountMatchingGridRowActionColToggle} id={toggleId} />
            <Dropdown.Menu>
                <Dropdown.Item onClick={setToEdit}>
                    Edit
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={deleteFn}>
                    Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

};

export default CustodianAccountMatchingGridRowActionCol;
import React, { useEffect, useState } from "react";
import Button from "../../../components/buttons";
import { Modal } from "react-bootstrap";
import {
    DateInput,
    CurrencyInput,
    NumberInput,
    FormLabel,
} from "../../../components/forms";
import { CenterFormColumnComponent } from "../../../components/utilities";


export const ReviseFundModal = ({ handleClose, handleConfirm, asset, onClose, ...rest }) => {

    const [request, setRequest] = useState({
        transactionDate: null,
        quantity: 0,
        value: 0,
    });

    const onRequestValueChanged = (property, value) => {
        setRequest(original => {
            return {
                ...original,
                [property]: value
            };
        })
    }

    const onCloseSelected = () => {
        if (handleClose && typeof(handleClose) === 'function') {
            handleClose(null);
        }
        onClose();
    };

    const onConfirmSelected = () => {
        if (handleConfirm && typeof(handleConfirm) === 'function') {
            handleConfirm(request);
        }
        onClose();
    };

    return (
        <Modal size="lg" centered backdrop="static" show={true}>
            <Modal.Header>Revise</Modal.Header>
            <Modal.Body {...rest}>
                <ReviseFundsModalContent asset={asset} request={request} setRequest={onRequestValueChanged}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={onConfirmSelected}>
                    Submit
                </Button>
                <Button variant="danger" onClick={onCloseSelected}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const ReviseFundsModalContent = ({asset, request, setRequest}) => {

    console.log("in modal content", asset);

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col">
                    <CenterFormColumnComponent className="col-md-2">
                        <FormLabel>{asset?.asset?.longAssetName}</FormLabel>
                    </CenterFormColumnComponent>
                </div>
                <div className="col">
                    <DateInput
                        label="Date"
                        placeholder="Start Date"
                        isClearable
                        disableAnimations={true}
                        value={request.transactionDate}
                        onBlur={(value, _) => setRequest('transactionDate', value)}
                    />
                </div>
                <div className="col">
                    <CurrencyInput
                        label="Value"
                        value={request.value}
                        onBlur={(value) =>
                            setRequest('value', value)
                        }
                        decimalScale={2}
                    />
                </div>
                <div className="col">
                    <NumberInput
                        label="Quantity"
                        value={request.quantity}
                        onBlur={(value) => setRequest("quantity", value)}
                        thousandSeparator
                        decimalScale={5}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReviseFundsModalContent;

import React from "react";
import { FormLabel, RichTextEditor } from "../../../components/forms";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { InfoBubble } from "../components";
import useRiskFactors from "../hooks/useRiskFactors";

const TextEditorLabel = ({ labelText, infoText }) => <div className="d-flex justify-content-between">
    <FormLabel>{labelText}</FormLabel>
    <InfoBubble
        showBubble={true}
    >
        {infoText}
    </InfoBubble>
</div>;

const RiskFactorsSection = () => {
    const { section, isLoading, error, isError, updateRiskFactorNotes, refetch } = useRiskFactors();
    const { assessmentOfKnowledge, understandingRiskAndReward, capacityForLoss, risksOfPensionDrawdown } = section || {};

    if (error || isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={refetch} />
        </ErrorMessageWrapper>
    }

    return <div className="container-fluid">
        <div className="row mb-4">
            {isLoading
                ? <Skeleton />
                : <RichTextEditor
                    label={<TextEditorLabel
                        labelText={"Assessment of Knowledge and Experience:"}
                        infoText={"Replace with information on what the adviser should write in this field."}
                    />}
                    defaultValue={assessmentOfKnowledge?.notes}
                    onBlur={(newValue) => updateRiskFactorNotes(assessmentOfKnowledge.notesId, newValue)}
                />}
        </div>
        <div className="row mb-4">
            {isLoading
                ? <Skeleton />
                : <RichTextEditor
                    label={<TextEditorLabel
                        labelText={"Discussion on Understanding Risk and Reward:"}
                        infoText={"Replace with information on what the adviser should write in this field."}
                    />}
                    defaultValue={understandingRiskAndReward?.notes}
                    onBlur={(newValue) => updateRiskFactorNotes(understandingRiskAndReward.notesId, newValue)}
                />}
        </div>
        <div className="row mb-4">
            {isLoading
                ? <Skeleton />
                : <RichTextEditor
                    label={<TextEditorLabel
                        labelText={"Establishing Capacity for Loss:"}
                        infoText={"Replace with information on what the adviser should write in this field."}
                    />}
                    defaultValue={capacityForLoss?.notes}
                    onBlur={(newValue) => updateRiskFactorNotes(capacityForLoss.notesId, newValue)}
                />}
        </div>
        <div className="row mb-4">
            {isLoading
                ? <Skeleton />
                : <RichTextEditor
                    label={<TextEditorLabel
                        labelText={"Understanding Risks of Pension Drawdown:"}
                        infoText={"Replace with information on what the adviser should write in this field."}
                    />}
                    defaultValue={risksOfPensionDrawdown?.notes}
                    onBlur={(newValue) => updateRiskFactorNotes(risksOfPensionDrawdown.notesId, newValue)}
                />}
        </div>
    </div>;
}

export default RiskFactorsSection;
import React, { useCallback, useEffect } from "react";
import { GridWrapper } from "../../../components";
import { FeeCollection, InvestmentSelection, ProductSelection } from "../components";
import { InvestDivestErrorMessage } from "../components/ErrorMessages";
import { useInstruction } from "../contexts/InstructionContext";

const RegularContribution = ({ instructionId }) => {
    const [{ fetchInvest }, { /* invest, */ investIsError }] = useInstruction();

    useEffect(() => {
        fetchInvest({ instructionId });
    }, [fetchInvest, instructionId]);

    const retry = useCallback(() => {
        fetchInvest({ instructionId });
    }, [fetchInvest, instructionId]);

    // const validContribution = invest?.regularContributionAmount > 0 && invest?.regularContributionFrequency != null;

    if (investIsError)
        return <InvestDivestErrorMessage retry={retry} />

    return <GridWrapper gridTemplateColumns={"100%"}>
        <ProductSelection
            isInvest
            changeOwner
        />
        <InvestmentSelection isRegularContribution />

        <FeeCollection />
        {/* <AnnualCostsDisclosureTable /> */}
    </GridWrapper>;
};

export default RegularContribution;

import { applyPatch } from "fast-json-patch";
import { wealthProApi } from '../../redux/api';

const clientConnectionsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["masterAccountClientConnections", "masterAccountClientConnectionsList", "factFindClientConnections"]
}).injectEndpoints({
    endpoints: (builder) => ({
        createClientConnection: builder.mutation({
            query: ({ masterAccountId, data }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/connections`,
                method: 'POST',
                body: {
                    ...data,
                    masterAccountId
                }
            }),
            async onQueryStarted({ masterAccountId, data: connection }, { dispatch, queryFulfilled }) {
                try {
                    const { data: createData } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchClientConnectionsForMasterAccount', { masterAccountId }, (data) => {
                        data.push({
                            ...connection,
                            connectionId: createData.connectionId
                        });
                    }));
                }
                catch { }
            },
            invalidatesTags: ["factFindClientConnections"]
        }),
        deleteClientConnection: builder.mutation({
            query: ({ connectionId, masterAccountId }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/connections/${connectionId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ connectionId, masterAccountId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchClientConnectionsForMasterAccount', { masterAccountId }, (data) => {
                        return data.filter(el => el.connectionId !== connectionId);
                    }));

                    dispatch(wealthProApi.util.updateQueryData('getClientConnection', { masterAccountId, connectionId }, _ => null));
                }
                catch { }
            },
            invalidatesTags: ["factFindClientConnections"]
        }),
        fetchClientConnectionsForMasterAccount: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/connections`,
            providesTags: (result, error, arg) => {
                if (result && Array.isArray(result)) {
                    return [...result.map(({ connectionId }) => ({ type: 'masterAccountClientConnections', id: connectionId })), 'masterAccountClientConnections']
                }
                return ['masterAccountClientConnections'];
            }
        }),
        fetchClientConnectionsListForMasterAccount: builder.query({
            query: ({ masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/connections/list`,
            providesTags: (result, error, arg) => {
                if (result && Array.isArray(result)) {
                    return [...result.map(({ connectionId }) => ({ type: 'masterAccountClientConnectionsList', id: connectionId })), 'masterAccountClientConnectionsList']
                }
                return ['masterAccountClientConnectionsList'];
            }
        }),
        getClientConnection: builder.query({
            query: ({ connectionId, masterAccountId }) => `api/clientdetails/master-accounts/${masterAccountId}/connections/${connectionId}`
        }),
        patchClientConnection: builder.mutation({
            query: ({ connectionId, masterAccountId, operations }) => ({
                url: `api/clientdetails/master-accounts/${masterAccountId}/connections/${connectionId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ connectionId, masterAccountId, operations }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchClientConnectionsForMasterAccount', { masterAccountId }, (data) => {
                    var connection = data.find(el => el.connectionId === connectionId);
                    applyPatch(connection, operations, true);
                }));
                const singleResult = dispatch(wealthProApi.util.updateQueryData('getClientConnection', { masterAccountId, connectionId }, (data) => {
                    applyPatch(data, operations, true);
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                    singleResult.undo();
                }
            }
        })
    })
});

export const {
    useCreateClientConnectionMutation,
    useDeleteClientConnectionMutation,
    useFetchClientConnectionsForMasterAccountQuery,
    useFetchClientConnectionsListForMasterAccountQuery,
    usePatchClientConnectionMutation,
    useGetClientConnectionQuery,
    useLazyGetClientConnectionQuery
} = clientConnectionsApiEndpoints;
import { wealthProApi } from '../../redux/api';
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

const buildFetchUsersQueryString = (options = {}, baseUrl = 'api/identity/users',) => {
    const queryParams = Object.keys(options)
        .filter(key => options[key] != null)
        .map(key => `${key}=${options[key]}`);

    if (queryParams.length === 0)
        return baseUrl

    return `${baseUrl}?${queryParams.join("&")}`;
    // let url = `${baseUrl}`;
    // if (includeInactive === true) {
    //     url += `${!url.includes('?') ? '?' : '&'}includeInactive=true`;
    // }
    // // if (fetchType && typeof (fetchType) === 'string') {
    // //     url += `${!url.includes('?') ? '?' : '&'}fetchType=${fetchType}`;
    // // }
    // if (division && typeof (division) === 'string') {
    //     url += `${!url.includes('?') ? '?' : '&'}division=${division}`;
    // }
    // if (userType && typeof (userType) === 'string') {
    //     url += `${!url.includes('?') ? '?' : '&'}userType=${userType}`;
    // }
    // return url;
    // `api/identity/users?fetchType=${fetchType}&division=${division}&userType=${userType}`
};

const userApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["users", "onlineusers"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchUserActivity: builder.query({
            query: () => `api/identity/users?polling=true`,
            providesTags: ({ results }, error, arg) => results
                ? [...results.map(({ userId }) => ({ type: 'onlineusers', id: userId })), 'onlineusers']
                : ['onlineusers']
        }),
        fetchUsers: builder.query({
            query: (options) => buildFetchUsersQueryString(options),
            providesTags: (result, error, arg) => {
                //console.log('fetchUsersQuery', result);
                return ['users'];
            }
        }),
        fetchUsersList: builder.query({
            query: (options) => buildFetchUsersQueryString(options, "api/identity/users/list"),
            providesTags: ['users']
        }),
        searchUsers: builder.query({
            query: (data) => ({
                url: `/api/identity/users/search`,
                method: 'POST',
                body: data
            }),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            //onCacheEntryAdded: searchUserDelegatesSocketHandler()
        })
    })
});

export const {
    useFetchUserActivityQuery,
    useFetchUsersQuery,
    useFetchUsersListQuery,
    useLazySearchUsersQuery,
    useSearchUsersQuery
} = userApiEndpoints;
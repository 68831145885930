import React from "react";
import { ReportCountAdviserGroup  } from "../../features/reportmanagementsummary";
import { ClientReportManagementFilterSubHeader } from "../../layouts/ClientReportManagementLayout";

const SummaryByAdviserPage = () => (
    <React.Fragment>
        <ClientReportManagementFilterSubHeader />
        <section className="px-4 my-3 h-100">
            <ReportCountAdviserGroup />
        </section> 
    </React.Fragment>
);

export default SummaryByAdviserPage;
import InvestmentCommitteeHouseRulesLayout from "../layouts/InvestmentCommitteeHouseRulesLayout";
import { NavigationTabs } from "../components/navigation";
import {
    HouseRulesFundRulesPage,
    HouseRulesGeneralPage,
    HouseRulesWrapperRulesPage
} from "../pages/investment-committee";

const InvestmentCommitteeHouseRulesRoutes = {
    path: 'houserules/*',
    pathBase: '/investment-committee/houserules',
    defaultRoute: 'general',
    roles: [],
    element: InvestmentCommitteeHouseRulesLayout,
    children: [
        {
            path: 'general',
            icon: 'fa-house',
            label: 'General',
            roles: [],
            element: HouseRulesGeneralPage
        },
        {
            path: 'fund-specific',
            icon: 'fa-sterling-sign',
            label: 'Fund Specific',
            roles: [],
            element: HouseRulesFundRulesPage
        },
        {
            path: 'wrapper-specific',
            icon: 'fa-box-open',
            label: 'Wrapper Specific',
            roles: [],
            element: HouseRulesWrapperRulesPage
        }
    ]
};

export const InvestmentCommitteeHouseRulesRouteTabs = () => <NavigationTabs pathBase={InvestmentCommitteeHouseRulesRoutes.pathBase} options={InvestmentCommitteeHouseRulesRoutes} />

export default InvestmentCommitteeHouseRulesRoutes;
import React from "react";
import styled from "styled-components";

// const ContentDisplay = styled.div`
//     background-color: white;
//     padding: 1rem 1.25rem;
// `;

// export const ContentDisplayParent = styled.section`
// `;

const ContentDisplayParent = styled.section`
    height: 100%;
`;

const ContentDisplayBody = styled.div`
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    background-color: #fff;
    padding: 1rem 1.25rem;
`;

const ContentDisplay = ({ children }) => {
    return (
        <ContentDisplayParent>
            <ContentDisplayBody>
                {children}
            </ContentDisplayBody>
        </ContentDisplayParent>
    )
};

export default ContentDisplay;
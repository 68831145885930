import { ThemedParagraph, ThemedSpan } from "../../../components/utilities";
import { CurrencyInput, DateInput, FormCheck, FormInput } from "../../../components/forms";
import Moment from "react-moment";

const CashSourceRow = ({
    appliesProperty,
    label,
    cashAmountProperty,
    receivedDateProperty,
    receivedConfirmedByNameProperty,
    receivedConfirmedSetDateProperty,
    commentProperty,
    updateFn,
    data = {},
    isTaxReclaim = false
}) => {

    const updateProperty = (property, newValue, toggleAppliesFlag) => {
        if (updateFn && typeof (updateFn) === "function") {
            if (toggleAppliesFlag) {
                updateFn(property, newValue, appliesProperty);
                return;
            }
            updateFn(property, newValue);
        }
    };

    return <div className="row d-flex align-items-center mb-1">
        <div className="col-2 d-flex flex-row">
            <FormCheck
                isChecked={data[appliesProperty]}
                onChange={newValue => updateProperty(appliesProperty, newValue)}
            />

            <ThemedParagraph className="ms-4">{label}</ThemedParagraph>
        </div>
        <div className="col-2">
            <CurrencyInput
                value={data[cashAmountProperty]}
                onBlur={newValue => updateProperty(cashAmountProperty, newValue, true)}
                disabled={isTaxReclaim}
            />
        </div>
        <div className="col-2">
            <DateInput
                value={data[receivedDateProperty]}
                onBlur={newValue => updateProperty(receivedDateProperty, newValue)}
                isClearable
            />
        </div>
        <div className="col-2">
            {data[receivedConfirmedByNameProperty] && data[receivedConfirmedSetDateProperty] && <ThemedSpan variant="muted">
                {data[receivedConfirmedByNameProperty]}, <Moment format="DD/MM/YY">{data[receivedConfirmedSetDateProperty]}</Moment>
            </ThemedSpan>}
        </div>
        <div className="col-4">
            <FormInput
                value={data[commentProperty]}
                onBlur={newValue => updateProperty(commentProperty, newValue)}
            />
        </div>
    </div>


};

export default CashSourceRow;
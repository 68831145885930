import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const divestEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchDivest: builder.mutation({
            query: ({ divestId, operations }) => ({
                url: `api/recommendations/Divest/${divestId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ instructionId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(
                        wealthProApi.util.updateQueryData("fetchDivest", { instructionId }, (divest) => {
                            applyPatch(divest, operations)
                        })
                    )
                    try {
                        await queryFulfilled
                    } catch (ex) {
                        console.error(ex, "Error updating service")
                        patchResult.undo();
                    }
                } catch (ex) {
                    console.error("Error updating cache", ex);
                }
            },
        }),
        fetchDivestTransactions: builder.query({
            query: ({ divestId }) => `api/recommendations/Divest/${divestId}/Transactions`
        }),
        createDivestTransactions: builder.mutation({
            query: ({ divestId, transactions, transactionTypeId }) => ({
                url: `api/recommendations/Divest/${divestId}/Transactions`,
                method: "POST",
                body: {
                    transactions,
                    defaultTransactionTypeId: transactionTypeId
                }
            }),
            async onQueryStarted({ divestId }, { dispatch, queryFulfilled }) {
                try {
                    // Remove all existing transactions from cache while loading the new ones
                    const deleteExistingResult = dispatch(wealthProApi.util.updateQueryData("fetchDivestTransactions", { divestId }, (_transactions) => []));

                    try {
                        const { data: newTransactions } = await queryFulfilled
                        dispatch(wealthProApi.util.updateQueryData("fetchDivestTransactions", { divestId }, (_transactions) => newTransactions));
                    } catch (ex) {
                        console.error(ex, "Error creating transactions")
                        deleteExistingResult.undo();
                    }
                } catch (ex) {
                    console.error("Error updating cache", ex);
                }
            },
        }),
    })
})

export const {
    usePatchDivestMutation,
    useLazyFetchDivestTransactionsQuery,
    useCreateDivestTransactionsMutation
} = divestEndpoints
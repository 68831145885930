import { Button, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FormInput } from "../../../components/forms";
import { ClientProjectSelect } from "../../../components/forms/selects";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientDocuments } from "../../../hooks/ClientDocumentsContext";

const EditClientDocumentModal = ({ show, document, onHide }) => {
    const { patchDocument, isPatching } = useClientDocuments();

    const { control, handleSubmit, setValue, errors } = useForm({
        defaultValues: document
    });

    const handleCancel = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        onHide();
    };

    const formData2Ops = (formData) => {
        let ops = [];

        if (formData.projectId !== document.projectId)
            ops.push(patchReplace("projectId", formData.projectId));

        if (formData.documentDescription !== document.documentDescription)
            ops.push(patchReplace("documentDescription", formData.documentDescription));

        return ops;
    }

    const saveChanges = (data) => {
        const operations = formData2Ops(data);
        if (operations.length === 0) {
            handleCancel();
            return;
        }

        patchDocument(document.documentId, operations).finally(onHide);
    }


    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            size="lg"
        >
            <Modal.Header>Edit '{document.fileName}' Details</Modal.Header>
            <Form onSubmit={handleSubmit(saveChanges)}>
                <Modal.Body>
                    <div className="row gy-2">
                        <div className="col-12">
                            <Controller
                                control={control}
                                name="projectId"
                                render={({ field: { onChange, onBlur, ...rest } }) => (
                                    <ClientProjectSelect
                                        label="Project"
                                        setValue={(selection) => setValue("projectId", selection.value)}
                                        errorMessage={errors?.projectId}
                                        disabled={isPatching}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-12">
                            <Controller
                                control={control}
                                name="documentDescription"
                                render={({ field: { value, onChange, onBlur, ...rest } }) => (
                                    <FormInput
                                        label="Description"
                                        value={document.documentDescription}
                                        onChange={(e) => setValue("documentDescription", e.target.value)}
                                        disableAnimations
                                        errorMessage={errors?.documentDescription}
                                        disabled={isPatching}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type='submit' disabled={isPatching}>Save Changes</Button>
                    <Button variant="light" type='reset' onClick={handleCancel} disabled={isPatching}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default EditClientDocumentModal;
import { socket } from "../../../redux/socket";

const DEFAULT_ROOM_NAME = "users-delegates";
const CREATED_CHANNEL = "user.delegate.created";
const DELETED_CHANNEL = "user.delegate.deleted";
const PATCHED_CHANNEL = "user.delegate.patched";

const searchUserDelegatesSocketHandler = () => async (props, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
    try {
        await cacheDataLoaded;
        socket.emit('subscribe-users-delegates', { room: DEFAULT_ROOM_NAME });

        socket.on(CREATED_CHANNEL, viewModel => {
            updateCachedData((data) => {
                // TODO: we will figure out how best to resolve adding to existing records later...
                data.newRecordsSinceCache = data.newRecordsSinceCache + 1;
            });
        });

        socket.on(DELETED_CHANNEL, data => {
            updateCachedData((data) => {
                data.pagination.totalCount = data.pagination.totalCount - 1;
                data.results = data.results.filter(el => el.delegateAccessId !== data.id);
            });
        });

        socket.on(PATCHED_CHANNEL, viewModel => {
            updateCachedData((data) => {
                let item = data.results.find(el => el.delegateAccessId === viewModel.delegateAccessId);
                if (item) {
                    Object.assign(item, viewModel)
                }
            });
        });
    }
    catch { }
    await cacheEntryRemoved;
    socket.emit('unsubscribe-users-delegates', { room: DEFAULT_ROOM_NAME });
    socket.off(CREATED_CHANNEL);
    socket.off(DELETED_CHANNEL);
    socket.off(PATCHED_CHANNEL);
};

export default searchUserDelegatesSocketHandler;
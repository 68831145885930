import React, { useEffect, useState } from "react";
import useMissingAccountMatches from "./useMissingAccountMatches";
import {
    useBulkResolveExceptionsMutation,
    useFetchHeadOfficePlatformTransactionExceptionsQuery,
    useLazyFetchHeadOfficePotentialTransactionMatchesQuery,
    useResolveHeadOfficeExceptionMutation,
    useRetryPlatformTransactionMatchingMutation
} from "../../../services/headofficeadmin";

const CustodianTransactionMatchingContext = React.createContext();

export const useCustodianTransactionMatching = () => React.useContext(CustodianTransactionMatchingContext);

const CustodianTransactionMatchingProvider = ({ children }) => {
    const [page, setPage] = useState(1);
    const [selectedApplicationTransactionId, setSelectedApplicationTransactionId] = useState(null);
    const potentialAccountMatches = useMissingAccountMatches();
    const { data, isFetching, isError, error, refetch } = useFetchHeadOfficePlatformTransactionExceptionsQuery({ page, limit: 1 });
    const [bulkResolveTrigger, bulkResolveResult] = useBulkResolveExceptionsMutation();
    const [fetchMatchesTrigger, fetchMatchesResult] = useLazyFetchHeadOfficePotentialTransactionMatchesQuery();
    const [resolveTrigger, resolveResult] = useResolveHeadOfficeExceptionMutation();
    const [retryMatchingTrigger, retryMatchingResult] = useRetryPlatformTransactionMatchingMutation();
    const { results, pagination: { totalCount, page: currentPage } } = data || { results: null, pagination: {} };
    const exception = results ? results[0] : null;

    const first = () => {
        setPage(1);
    }

    const last = () => {
        setPage(totalCount);
    };

    const next = () => {
        if (page >= totalCount) return;

        setPage(prev => prev + 1);
    }

    const previous = () => {
        if (page === 1) return;

        setPage(prev => prev - 1);
    };

    const resolveException = (request) => {
        return new Promise((resolve, reject) => {
            return resolveTrigger(request).unwrap().then(resolve, reject);
        }).then(res => {
            // properly handles refresh

            if (currentPage === totalCount && totalCount !== 1) {
                return previous();
            }
            retryFetchExceptions();
        });
    };

    const bulkResolveExceptions = (request) => {
        return new Promise((resolve, reject) => {
            return bulkResolveTrigger(request).unwrap().then(resolve, reject);
        }).then(res => {
            // properly handles refresh

            if (currentPage === totalCount && totalCount !== 1) {
                return previous();
            }
            retryFetchExceptions();
        });
    }

    const retryFetchExceptions = (e) => {
        typeof (e?.preventDefault) === "function" && e.preventDefault();

        refetch();
    };

    const retryFetchMatches = (e) => {
        typeof (e?.preventDefault) === "function" && e.preventDefault();

        fetchMatchesTrigger({ id: exception?.id });
    }

    const retryTransactionMatching = (e) => {
        typeof (e?.preventDefault) === "function" && e.preventDefault();

        return new Promise((res, rej) => {
            return retryMatchingTrigger().unwrap().then(res, rej);
        });
    };

    useEffect(() => {
        if (!exception) return;

        fetchMatchesTrigger({ id: exception.id });
    }, [exception, fetchMatchesTrigger]);

    useEffect(() => {
        currentPage && setPage(currentPage);
    }, [currentPage]);

    return (
        <CustodianTransactionMatchingContext.Provider value={{
            error,
            exception,
            bulkResolveResult,
            fetchMatchesResult,
            isError,
            isFetching,
            page,
            resolveResult,
            totalCount,
            potentialAccountMatches,
            selectedApplicationTransactionId,
            first,
            last,
            next,
            previous,
            bulkResolveExceptions,
            resolveException,
            retryFetchExceptions,
            retryFetchMatches,
            retryTransactionMatching,
            setPage,
            setSelectedApplicationTransactionId
        }}>
            {children}
        </CustodianTransactionMatchingContext.Provider>
    )

};

export default CustodianTransactionMatchingProvider;
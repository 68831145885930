import React from "react";
import ClientReportTableRowColumn from "./ClientReportTableRowColumn";
import { ThemedIcon } from "../../../components/utilities";

const ClientReportTableRowEfficacyColumn = ({ width = 5, efficacy, size = "lg" }) => (
    <ClientReportTableRowColumn width={width} className="text-center">
        {efficacy === 2 && <ThemedIcon variant="danger" icon="fa-circle-xmark" size={size} />}
        {efficacy === 1 && <ThemedIcon variant="warning" icon="fa-circle-xmark" size={size} />}
        {efficacy === 0 &&  <ThemedIcon variant="success" icon="fa-circle-check" size={size} />}
    </ClientReportTableRowColumn>
);

export default ClientReportTableRowEfficacyColumn;